import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AddressPostalModel, AddressTypes, CompanyAddressModel } from '@seahorse/domain';

@Component({
  selector: 'address-modal',
  templateUrl: './address-modal.component.html',
})
export class AddressModalComponent implements OnInit {
  @Input() addressId: number;
  @Input() address: CompanyAddressModel;
  @Input() showContactField = true;

  @Output() onAddCompanyAddress: EventEmitter<CompanyAddressModel> =
    new EventEmitter<CompanyAddressModel>();
  @Output() onUpdateCompanyAddress: EventEmitter<CompanyAddressModel> =
    new EventEmitter<CompanyAddressModel>();

  isSubmitted = false;
  isNew = false;

  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.isNew = this.address == null;

    if (!this.isNew) {
      this.address.postalModel = new AddressPostalModel();

      if (this.address.value) {
        const postalInformation = this.address.value.split(';');

        if (!postalInformation) {
          return;
        }

        this.address.postalModel.addressLine1 = postalInformation[0];
        this.address.postalModel.addressLine2 = postalInformation[1];
        this.address.postalModel.zip = postalInformation[2];
        this.address.postalModel.city = postalInformation[3];
        this.address.postalModel.state = postalInformation[4];
        this.address.postalModel.country = postalInformation[5];
      }
    } else {
      this.address = new CompanyAddressModel();
      this.address.postalModel = new AddressPostalModel();
      this.address.companyId = +localStorage.getItem('company_id');
    }
  }

  save() {
    if (!this.validateAddress(this.address)) {
      this.isSubmitted = true;
      return;
    }

    this.address = this.prepareAddressPostalCode(this.address);

    if (this.isNew) {
      this.onAddCompanyAddress.emit(this.address);
    } else {
      this.onUpdateCompanyAddress.emit(this.address);
    }

    this.close();
  }

  validateAddress(address: CompanyAddressModel): boolean {
    if (address.type === undefined || !address.name) {
      return false;
    }

    if (address.type !== AddressTypes.Postal && !address.value) {
      return false;
    }

    if (
      address.type === AddressTypes.Postal &&
      !address.postalModel.addressLine1
    ) {
      return false;
    }

    return true;
  }

  close() {
    this.activeModal.close();
  }

  prepareAddressPostalCode(address: CompanyAddressModel): CompanyAddressModel {
    if (address.type === AddressTypes.Postal) {
      address.value =
        address.postalModel.addressLine1 +
        ';' +
        address.postalModel.addressLine2 +
        ';' +
        address.postalModel.zip +
        ';' +
        address.postalModel.city +
        ';' +
        address.postalModel.state +
        ';' +
        address.postalModel.country;
    }

    return address;
  }
}
