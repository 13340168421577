export const DISPLAYOPTIONS = [{ id: 1, name: 'List' }];

export const SORTOPTIONS = [
  { id: true, name: 'Ascending' },
  { id: false, name: 'Desceding' },
];

export const CLONEOBJECTOPTIONS = [
  {
    name: 'basic',
    label: 'shared.label.basic',
    checked: true,
    show: true,
  },
  {
    name: 'documents',
    label: 'shared.label.documents',
    checked: false,
    show: false,
  },
  {
    name: 'messages',
    label: 'shared.label.messages',
    checked: false,
    show: false,
  },
  { name: 'tasks', label: 'shared.label.tasks', checked: false, show: false },
  {
    name: 'taskgroups',
    label: 'taskGroupDisplay.cardTitle',
    checked: false,
    show: false,
  },
];
