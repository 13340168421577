import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import {
  CompanyContactModel,
  CompanyFinancialModel,
  CompanyModel,
} from '@seahorse/domain';
import { FinancialDataService } from '@seahorse/domain';
import { EventModel } from '../../../../shared/models/event.model';
import { Subscription } from 'rxjs';

import { ContactModalComponent } from '../../contact/contact-modal/contact.modal.component';

@Component({
  selector: 'company-financials-card',
  templateUrl: 'company-financials-card.component.html',
})
export class CompanyFinancialsCardComponent implements OnInit {
  @Input() company: CompanyModel;

  @Output() onEvent: EventEmitter<EventModel>;
  @Output() onLoadDebtorNumbers: EventEmitter<string[]> = new EventEmitter<
    string[]
  >();

  canLoadMore: boolean;
  pageIndex = 0;
  pageSize = 5;
  financials: CompanyFinancialModel[];

  private subscription: Subscription;

  constructor(
    private financialDataService: FinancialDataService,
    private modalService: NgbModal
  ) {
    this.company = null;
    this.onEvent = new EventEmitter<EventModel>();
  }

  ngOnInit() {
    this.pageIndex = 0;
    this.loadFinancials();
  }

  loadMore() {
    this.pageIndex += 1;
    this.loadFinancials();
  }

  loadFinancials() {
    this.financialDataService
      .getFinancials(this.company.id, this.pageIndex, this.pageSize)
      .subscribe(
        (result) => {
          if (this.pageIndex > 0) {
            this.financials = this.financials.concat(result.result);
          } else {
            this.financials = result.result;
          }

          this.company.companyFinancials = this.financials;
          this.onLoadDebtorNumbers.emit(
            this.financials.map((x) => x.debtorNumber)
          );
          this.canLoadMore = this.financials.length < result.count;
        },
        () => {}
      );
  }

  selectFinancial(financial: CompanyFinancialModel) {
    const ngbModalOptions: NgbModalOptions = { backdrop: 'static' };
    const modalRef = this.modalService.open(
      ContactModalComponent,
      ngbModalOptions
    );

    if (financial != null) {
      modalRef.componentInstance.financialId = financial.id;
    }
  }

  delete(contact: CompanyContactModel) {
    // this.contactInteractionService.delete(contact);
  }

  reloadFinancials() {
    this.pageIndex = 0;
    this.loadFinancials();
  }
}
