import { Injectable } from '@angular/core';
import { DataContextService, ResultWrapper } from '@seahorse/common';
import { Observable } from 'rxjs';
import { HarbourModel } from '../../superuser-nautical/models/harbour.model';

@Injectable({
  providedIn: 'root',
})
export class NauticalHarbourDataService {
  constructor(private dataContext: DataContextService) {}

  getById(
    id: number,
    annotate?: boolean
  ): Observable<ResultWrapper<HarbourModel>> {
    let url = `nautical/geography/harbour/${id}`;
    if (annotate) url += '?annotate=true';
    return this.dataContext.get<HarbourModel>(url);
  }
}
