<div class="card" *ngIf="hasChild">
  <div class="card-header">
    <span class="text-uppercase">{{
      'companies.company.childCompanies' | translate
    }}</span>
    <div class="float-right">
      <button
        class="btn btn-outline-success"
        type="button"
        (click)="addChild(content)"
      >
        <i class="fa-regular fa-plus"></i>
      </button>
    </div>
  </div>
  <div class="card-body">
    <div *ngFor="let childCompany of childCompanies" class="card-body-group">
      <div class="row">
        <div class="col-sm-6">
          <div class="details-item">
            <span
              ><b>{{ 'companies.company.model.name' | translate }}</b></span
            >:
            <span>{{ childCompany?.name }}</span>
          </div>
        </div>
        <div class="col-sm-6 text-right">
          <button
            class="btn btn-outline-secondary"
            routerLink="/companies/{{ childCompany.id }}"
          >
            <i class="fa-regular fa-external-link-alt"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="card" *ngIf="!hasChild">
  <div class="card-body">
    <div class="text-muted">
      {{ 'companies.company.noChildCompanies' | translate }}
      <div class="float-right">
        <button
          class="btn btn-outline-success"
          type="button"
          (click)="addChild()"
        >
          <i class="fa-regular fa-plus"></i>
        </button>
      </div>
    </div>
  </div>
</div>
