import {
  Directive,
  inject,
  Input,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

@Directive({
  selector: '[shTemplate]',
  standalone: true,
})
export class SeahorseTemplateDirective {
  @Input('shTemplateId') id: string | undefined;
  @Input('shTemplate') type: string | undefined;

  templateRef = inject(TemplateRef, { optional: true });
  vcRef = inject(ViewContainerRef);
}
