import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import * as _ from 'underscore';
import { FormattingService } from '../../core/services/formatting.service';

@Injectable({
  providedIn: 'root',
})
export class LayoutDisplayService {
  private _currentModuleName = '<none>';
  get currentModuleName() {
    return this._currentModuleName;
  }
  set currentModuleName(value) {
    // because various components are setting this value in ngOnInit,
    // the ExpressionChangedAfterItHasBeenCheckedError is thrown. Workaround.
    setTimeout(() => {
      this._currentModuleName = value;
    });
  }

  showBaseLayout = true;
  private navigationReloadSource = new Subject();
  navigationReload$ = this.navigationReloadSource.asObservable();

  constructor(
    private activeRoute: ActivatedRoute,
    private formatting: FormattingService,
    private router: Router,
    private translate: TranslateService
  ) {
    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe((e) => {
        this.handleRouterEvent(e);
      });

    // First time check
    this.handleRouterEvent(this.activeRoute);
  }

  private handleRouterEvent(event) {
    this.extractModuleNameFromRoute(this.activeRoute);

    const showBase = this.getDataElementFromRoute(
      this.activeRoute,
      'ui.showBaseLayout',
      true
    );
    if (showBase !== null) {
      this.showBaseLayout = showBase;
    } else {
      this.showBaseLayout = true;
    }
  }

  private extractModuleNameFromRoute(route: ActivatedRoute) {
    const moduleName = this.getDataElementFromRoute(
      route,
      'ui.headerTitle',
      true
    );

    if (moduleName && moduleName !== '') {
      this.currentModuleName = moduleName;
      document.title = 'SHIPM8 - ' + this.translate.instant(moduleName); 
    }
  }

  private getDataElementFromRoute(
    route: ActivatedRoute,
    itemPath: string,
    iterateChildren = false
  ) {
    let result: any = null;
    if (route.snapshot && route.snapshot.data) {
      const propValue = this.formatting.getPropertyByPath(
        route.snapshot.data,
        itemPath
      );
      if (propValue !== undefined && propValue !== null) {
        result = propValue;
      }
    }

    if (
      route.children &&
      route.children.length > 0 &&
      iterateChildren === true
    ) {
      _.each(route.children, (child) => {
        const childValue = this.getDataElementFromRoute(child, itemPath, true);
        if (childValue !== undefined && childValue !== null) {
          result = childValue;
        }
      });
    }

    return result;
  }

  reloadNavigation() {
    this.navigationReloadSource.next();
  }
}
