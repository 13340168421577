import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ZeehavenIJmuidenMovementModel } from '../../models/zeehaven-ijmuiden-movement.model';

@Component({
  templateUrl: 'zeehaven-ijmuiden-specific-picker-modal.component.html',
})
export class ZeehavenIJmuidenSpecificPickerModalComponent {
  @Input() oilScreen: ZeehavenIJmuidenMovementModel['oilScreen'];
  @Input() oilScreenMessage: ZeehavenIJmuidenMovementModel['oilScreenMessage'];
  @Input() power: ZeehavenIJmuidenMovementModel['power'];
  @Input() powerMessage: ZeehavenIJmuidenMovementModel['powerMessage'];
  @Input() tug: ZeehavenIJmuidenMovementModel['tug'];
  @Input() tugMessage: ZeehavenIJmuidenMovementModel['tugMessage'];
  @Input() fender: ZeehavenIJmuidenMovementModel['fender'];
  @Input() fenderMessage: ZeehavenIJmuidenMovementModel['fenderMessage'];
  @Input() garbage: ZeehavenIJmuidenMovementModel['garbage'];
  @Input() garbageMessage: ZeehavenIJmuidenMovementModel['garbageMessage'];
  @Input() water: ZeehavenIJmuidenMovementModel['water'];
  @Input() waterMessage: ZeehavenIJmuidenMovementModel['waterMessage'];
  @Input() workPlatform: ZeehavenIJmuidenMovementModel['workPlatform'];
  @Input()
  workPlatformMessage: ZeehavenIJmuidenMovementModel['workPlatformMessage'];

  @Output() onPickZeehavenIJmuidenSpecific: EventEmitter<{
    oilScreen: ZeehavenIJmuidenMovementModel['oilScreen'];
    oilScreenMessage: ZeehavenIJmuidenMovementModel['oilScreenMessage'];
    power: ZeehavenIJmuidenMovementModel['power'];
    powerMessage: ZeehavenIJmuidenMovementModel['powerMessage'];
    tug: ZeehavenIJmuidenMovementModel['tug'];
    tugMessage: ZeehavenIJmuidenMovementModel['tugMessage'];
    fender: ZeehavenIJmuidenMovementModel['fender'];
    fenderMessage: ZeehavenIJmuidenMovementModel['fenderMessage'];
    garbage: ZeehavenIJmuidenMovementModel['garbage'];
    garbageMessage: ZeehavenIJmuidenMovementModel['garbageMessage'];
    water: ZeehavenIJmuidenMovementModel['water'];
    waterMessage: ZeehavenIJmuidenMovementModel['waterMessage'];
    workPlatform: ZeehavenIJmuidenMovementModel['workPlatform'];
    workPlatformMessage: ZeehavenIJmuidenMovementModel['workPlatformMessage'];
  }>;

  constructor() {
    this.oilScreen = null;
    this.oilScreenMessage = null;
    this.power = null;
    this.powerMessage = null;
    this.tug = null;
    this.tugMessage = null;
    this.fender = null;
    this.fenderMessage = null;
    this.garbage = null;
    this.garbageMessage = null;
    this.water = null;
    this.waterMessage = null;
    this.workPlatform = null;
    this.workPlatformMessage = null;

    this.onPickZeehavenIJmuidenSpecific = new EventEmitter<{
      oilScreen: ZeehavenIJmuidenMovementModel['oilScreen'];
      oilScreenMessage: ZeehavenIJmuidenMovementModel['oilScreenMessage'];
      power: ZeehavenIJmuidenMovementModel['power'];
      powerMessage: ZeehavenIJmuidenMovementModel['powerMessage'];
      tug: ZeehavenIJmuidenMovementModel['tug'];
      tugMessage: ZeehavenIJmuidenMovementModel['tugMessage'];
      fender: ZeehavenIJmuidenMovementModel['fender'];
      fenderMessage: ZeehavenIJmuidenMovementModel['fenderMessage'];
      garbage: ZeehavenIJmuidenMovementModel['garbage'];
      garbageMessage: ZeehavenIJmuidenMovementModel['garbageMessage'];
      water: ZeehavenIJmuidenMovementModel['water'];
      waterMessage: ZeehavenIJmuidenMovementModel['waterMessage'];
      workPlatform: ZeehavenIJmuidenMovementModel['workPlatform'];
      workPlatformMessage: ZeehavenIJmuidenMovementModel['workPlatformMessage'];
    }>();
  }

  setNull(attribute: string) {
    this[attribute] = null;
  }
}
