import { Component, Input, OnInit } from '@angular/core';

import { NauticalVisitDetailsModel } from '@seahorse/domain';

@Component({
  selector: 'visit-movements',
  templateUrl: './visit-movements.component.html',
  styleUrls: ['visit-movements.component.scss'],
})
export class VisitMovementsComponent implements OnInit {
  @Input() movements: NauticalVisitDetailsModel['portMovements'];
  @Input() loadMovements: boolean;

  constructor() {
    this.movements = null;
    this.loadMovements = false;
  }

  ngOnInit(): void {
    this.movements.map((x) => (x.activeTabIndex = 0));
  }

  setActiveMovementTab(movement: NauticalVisitDetailsModel, tabIndex) {
    movement.activeTabIndex = tabIndex;
  }
}
