import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';
import { CustomValueDisplayPipe } from './custom-value-display.pipe';

@NgModule({
  declarations: [CustomValueDisplayPipe],
  exports: [CustomValueDisplayPipe],
  imports: [SharedModule],
  providers: [CustomValueDisplayPipe],
})
export class CustomContentPipesModule {}
