import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@seahorse/common';
import {
  CustomDataBaseModel,
  CustomDataContentService,
  FieldDefinitionModel,
  FieldType,
  ObjectDefinitionModel,
} from '@seahorse/domain';
import { Subscription } from 'rxjs';
import { CustomDataFormService } from '../../form/custom-data-form.service';
import { FormComponent } from '../../form/form.component';
import { FormModel } from '../../form/form.model';

@Component({
  selector: 'temp-batch-update-modal',
  templateUrl: './batch-update-modal.component.html',
  styleUrls: ['./batch-update-modal.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    NgSelectModule,
    FormComponent,
  ],
})
export class BatchUpdateModalComponent implements OnDestroy, OnInit {
  @Input() objectDefinition: ObjectDefinitionModel;
  @Input() items: CustomDataBaseModel[] = [];
  @Input() singleFieldMode = false;
  @Input() includeStatus = false;

  @ViewChild(FormComponent) formRef: FormComponent;

  formModel: FormModel;
  isSaving = false;
  private subscriptions = new Subscription();

  constructor(
    private _customDataFormService: CustomDataFormService,
    private _customDataContentService: CustomDataContentService,
    private _notificationService: NotificationService,
    private _translateService: TranslateService,
    private _activeModal: NgbActiveModal,
    private _cdRef: ChangeDetectorRef
  ) {}

  onFieldSelected(selected: FieldDefinitionModel) {
    this.formModel = null;
    this._cdRef.detectChanges();

    this.formModel = this._customDataFormService.getFieldModel(
      this.objectDefinition,
      selected.systemCode
    );

    if (selected.fieldType === FieldType.DateTime) {
      this.formModel.fields.map((field) => {
        if (!field.options) {
          field.options = { allowPartialValue: true };
        } else {
          field.options['allowPartialValue'] = true;
        }
      });
    }
  }

  save() {
    const formValues = this.formRef.submit();
    let changedItems = this.items.map((obj) => Object.assign({}, obj));

    if (this.singleFieldMode) {
      changedItems = this.items.map((obj) => ({ ...obj, ...formValues }));
    } else {
      let hasChange = false;
      Object.entries(formValues).forEach(([key, value]) => {
        if (value !== null && value !== undefined && value !== '') {
          changedItems = changedItems.map((obj: any) => {
            const fieldDef = this.formModel.fields.find(
              (fDef) => fDef.key === key
            );
            if (!fieldDef || fieldDef.type !== FieldType.DateTime) {
              obj[key] = value;
            } else {
              // assume is DateTimePartialModel
              let parts = []; // [YYYY-MM-DD, HH:mm:ss]
              if (obj[key] !== undefined && obj[key] !== null) {
                if (obj[key].indexOf('T') > -1) {
                  parts = obj[key].split('T'); // current value is YYYY-MM-DDTHH:mm
                } else if (obj[key].indexOf(':') > -1) {
                  parts = [null, obj[key]]; // current value is HH:mm or HH:mm:ss
                } else {
                  parts = [obj[key]]; // current value is YYYY-MM-DD
                }
              }

              if (parts.length === 0) {
                parts = [null, null];
              } else if (parts.length === 1) {
                parts.push(null);
              }

              if (!value['date']['isEmpty']) {
                parts[0] = value['date']['value'];
              }

              if (!value['time']['isEmpty']) {
                if (parts[1] === undefined || parts[1] === null) {
                  parts[1] = value['time']['value'];
                } else {
                  const timeParts = parts[1].split(':');
                  const newTimeParts = value['time']['value'].split(':');
                  if (timeParts.length === 2 && newTimeParts.length === 3) {
                    parts[1] = newTimeParts[0] + ':' + newTimeParts[1]; // current value is HH:mm, but new value is HH:mm:ss
                  } else if (
                    timeParts.length === 3 &&
                    newTimeParts.length === 2
                  ) {
                    parts[1] =
                      newTimeParts[0] +
                      ':' +
                      newTimeParts[1] +
                      ':' +
                      timeParts[2]; // current value is HH:mm:ss, but new value is HH:mm
                  } else {
                    parts[1] = value['time']['value'];
                  }
                }
              }

              parts = parts.filter((p) => p !== undefined && p !== null);
              if (parts.length === 2) {
                obj[key] = parts[0] + 'T' + parts[1];
              } else if (
                parts.length === 1 &&
                obj[key] !== undefined &&
                obj[key] !== null
              ) {
                // if we only set the time, date is automatically set today
                // if we only set the date, time is automatically convert to 00:00
                // so set the value only if the original value has data, otherwise the empty part will automatically filled
                obj[key] = parts[0];
              }
            }

            return obj;
          });
          hasChange = true;
        }
      });
      if (!hasChange) {
        this._activeModal.dismiss();
      }
    }

    this.isSaving = true;
    this.subscriptions.add(
      this._customDataContentService
        .updateCustomerArrayOfData(
          this.objectDefinition.systemCode,
          changedItems,
          this.includeStatus
        )
        .subscribe(
          (response) => {
            this.isSaving = false;
            this._activeModal.close(response.result);
            this._notificationService.showSuccess(
              this._translateService.instant('shared.terms.updateSuccess')
            );
          },
          () => {
            this.isSaving = false;
            this._notificationService.showError(
              this._translateService.instant('shared.terms.wrong')
            );
          }
        )
    );
  }

  closeInnerModal(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this._activeModal.close();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    if (!this.singleFieldMode) {
      this.formModel = this._customDataFormService.getFormModel(
        this.objectDefinition,
        this.objectDefinition.objectFieldDefinitions
          .filter((field) => !field.isReadOnly)
          .map((field) => field.systemCode)
      );

      this.formModel.fields.map((field) => {
        if (field.type === FieldType.DateTime) {
          if (!field.options) {
            field.options = { allowPartialValue: true };
          } else {
            field.options['allowPartialValue'] = true;
          }
        }
      });

      if (!this.formModel.options) {
        this.formModel.options = { noValidate: true };
      } else {
        this.formModel.options.noValidate = true;
      }
    }
  }
}
