import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import {
  CompanyDepartmentModel,
  CompanyModel,
} from '@seahorse/domain';
import { CompanyDataService } from '@seahorse/domain';
import { EventModel } from '../../../../shared/models/event.model';
import { InteractionService } from '../../../../shared/services/interaction.service';
import { Subscription } from 'rxjs';

import { DepartmentModalComponent } from '../../department/department-modal/department.modal.component';

@Component({
  selector: 'company-departments-card',
  templateUrl: 'company-departments-card.component.html',
})
export class CompanyDepartmentsCardComponent implements OnInit, OnDestroy {
  @Input() companyId: CompanyModel['id'];

  @Output() onEvent: EventEmitter<EventModel>;

  canLoadMore: boolean;
  pageIndex = 0;
  pageSize = 5;
  departments: CompanyDepartmentModel[];

  private subscription: Subscription;

  constructor(
    @Inject('DepartmentInteractionService')
    private departmentInteractionService: InteractionService,
    private companyDataService: CompanyDataService,
    private modalService: NgbModal
  ) {
    this.companyId = null;
    this.onEvent = new EventEmitter<EventModel>();

    this.subscription =
      this.departmentInteractionService.modifyCalled$.subscribe(() => {
        this.reloadDepartments();
      });

    this.departmentInteractionService.deleteCalled$.subscribe(() => {
      this.reloadDepartments();
    });
  }

  ngOnInit() {
    this.pageIndex = 0;
    this.loadDepartments();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  loadMore() {
    this.pageIndex += 1;
    this.loadDepartments();
  }

  loadDepartments() {
    this.companyDataService
      .getDepartments(this.companyId, '', this.pageIndex, this.pageSize)
      .subscribe(
        (result) => {
          if (this.pageIndex > 0) {
            this.departments = this.departments.concat(result.result);
          } else {
            this.departments = result.result;
          }
          this.canLoadMore = this.departments.length < result.count;
        },
        () => {}
      );
  }

  selectDepartment(department: CompanyDepartmentModel) {
    const ngbModalOptions: NgbModalOptions = { backdrop: 'static' };
    const modalRef = this.modalService.open(
      DepartmentModalComponent,
      ngbModalOptions
    );

    if (department != null) {
      modalRef.componentInstance.departmentId = department.id;
    }
  }

  delete(department: CompanyDepartmentModel) {
    // this.departmentInteractionService.delete(department);
  }

  reloadDepartments() {
    this.pageIndex = 0;
    this.loadDepartments();
  }
}
