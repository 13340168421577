import { inject, Injectable } from '@angular/core';
import { ErrorMessage, NotificationService } from '@seahorse/common';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class CaNotificationService extends NotificationService {
  private _toastr = inject(ToastrService);
  
  showInfo(message?: string, title?: string, timeout = 5000) {
    this._toastr.info(
      message ? this.translateService.instant(message) : null,
      title ? this.translateService.instant(title) : null,
      timeout === 0 ? {disableTimeOut: true} : {timeOut: timeout}
    );
  }

  showSuccess(message?: string, title?: string, timeout = 5000) {
    this._toastr.success(
      message ? this.translateService.instant(message) : null,
      title ? this.translateService.instant(title) : null,
      timeout === 0 ? {disableTimeOut: true} : {timeOut: timeout}
    );
  }

  showWarning(message?: string, title?: string, timeout = 5000) {
    this._toastr.warning(
      message ? this.translateService.instant(message) : null,
      title ? this.translateService.instant(title) : null,
      timeout === 0 ? {disableTimeOut: true} : {timeOut: timeout}
    );
  }

  showError(
    message?: string | ErrorMessage | string[] | ErrorMessage[],
    title?: string,
    timeout = 5000
  ) {
    let messageBody: string = null;
    if (!message && !title) {
      messageBody = 'shared.errorMessages.anErrorHasOccurred';
    } else if (Array.isArray(message)) {
      const errorMessages: string[] = [];
      message.forEach((resultMessage) => {
        if (typeof resultMessage === 'string' && resultMessage !== null) {
          errorMessages.push(this.translateService.instant(resultMessage));
        } else if (resultMessage instanceof ErrorMessage) {
          if (resultMessage.message) {
            errorMessages.push(
              this.translateService.instant(resultMessage.message)
            );
          }
        }
      });
      messageBody = errorMessages.join('\n');
    } else if (message instanceof ErrorMessage) {
      messageBody = message.message;
    } else {
      messageBody = message;
    }

    this._toastr.error(
      messageBody ? this.translateService.instant(messageBody) : null,
      title ? this.translateService.instant(title) : null,
      timeout === 0 ? {disableTimeOut: true} : {timeOut: timeout}
    );
  }

  clear() {
    this._toastr.clear();
  }  
}
