<div class="modal-header align-items-center">
  <h1 class="modal-title">{{ title }}</h1>
  <button type="button" class="btn btn-link text-dark" (click)="dismiss()">
    <i class="fa-solid fa-times fa-sm fa-fw"></i>
  </button>
</div>
<div class="modal-body">
  <ca-company-picker
    [(selectedCompany)]="company"
    [selectedCompanyLoading]="selectedCompanyLoading"
    [isRequired]="companyRequired"
    [allowTextOnly]="allowTextOnly"
    [tagFilter]="tagFilter"
  ></ca-company-picker>
  <div class="text-right" *hasPermission="'companies.companies_add'">
    <button class="btn btn-link" (click)="addCompany()">
      {{ 'companies.company.newCompany' | translate }}
    </button>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-link flex-fill" (click)="dismiss()">
    {{ 'shared.terms.cancel' | translate }}
  </button>
  <button
    [disabled]="!allowTextOnly && companyRequired && !company"
    (click)="onConfirm.emit({ company: company }); close()"
    type="button"
    class="btn btn-success flex-fill"
  >
    {{ 'shared.terms.confirm' | translate }}
  </button>
</div>
