import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'underscore';
import { NauticalShipModel } from '@seahorse/domain';
import { NauticalVisitModel } from '@seahorse/domain';
import {
  NauticalVisitPickerDialogComponent,
  ShipPickerMode,
} from '../nautical-visit-picker-dialog/nautical-visit-picker-dialog.component';

@Component({
  selector: 'ca-nautical-visit-card',
  templateUrl: './nautical-visit-card.component.html',
})
export class NauticalVisitCardComponent {
  @Input() nauticalShip: NauticalShipModel;
  @Input() nauticalVisit: NauticalVisitModel;
  @Input() title: string;
  @Input() isLoading = false;

  @Output() onChanged: EventEmitter<{
    nauticalVisit?: NauticalVisitModel;
    nauticalShip?: NauticalShipModel;
  }>;

  constructor(private modal: NgbModal) {
    this.onChanged = new EventEmitter<{
      nauticalVisit?: NauticalVisitModel;
      nauticalShip?: NauticalShipModel;
    }>();
  }

  openNauticalVisitPicker() {
    const m: NgbModalRef = this.modal.open(NauticalVisitPickerDialogComponent, {
      backdrop: 'static',
    });
    m.componentInstance.nauticalShipPickerMode = ShipPickerMode.Editable;
    m.componentInstance.nauticalShip = this.nauticalShip;
    m.componentInstance.nauticalVisit = this.nauticalVisit;
    m.componentInstance.nauticalVisitRequired = false;

    m.result.then(
      (o: {
        nauticalVisit: NauticalVisitModel;
        nauticalShip?: NauticalShipModel;
      }) => {
        this.nauticalShip = o.nauticalShip;
        this.nauticalVisit = o.nauticalVisit;
        this.onChanged.emit({
          nauticalVisit: o.nauticalVisit,
          nauticalShip: o.nauticalShip,
        });
      }
    );
  }

  openNauticalVisit() {
    if (this.nauticalVisit && this.nauticalVisit.id) {
      window.open(`nautical/visit/${this.nauticalVisit.id}`, '_blank');
    }
  }

  unlinkNauticalVisit() {
    this.nauticalVisit = null;
    this.onChanged.emit({
      nauticalVisit: this.nauticalVisit,
      nauticalShip: this.nauticalShip,
    });
  }
}
