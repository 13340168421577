import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  forwardRef,
  Input,
  OnInit,
} from '@angular/core';
import { SeahorseTemplateDirective } from '@seahorse/common';
import { BaseControl } from '../base.control';
import { MultiSelectOption } from '../multi-select/multi-select.model';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'sh-single-select',
  templateUrl: './single-select.control.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SingleSelectControl),
      multi: true,
    },
    {
      provide: BaseControl,
      useExisting: forwardRef(() => SingleSelectControl),
    },
  ],
  styles: [
    `
      .e-list-icon {
        margin-right: 0.5rem;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SingleSelectControl
  extends BaseControl<string[] | number[] | null>
  implements OnInit
{
  @ContentChild(SeahorseTemplateDirective) template?: SeahorseTemplateDirective;
  @Input() allowCustomValue = true;
  @Input() options: MultiSelectOption[] = [];

  fieldMapping = { text: 'label', value: 'value', iconCss: 'icon' };

  override writeValue(value: any) {
    if (value === null || value === undefined) {
      value = [];
    }

    if (!(value instanceof Array)) {
      value = [value];
    }

    super.writeValue(value);
    if (this.control) {
      this.control.setValue(value, { emitEvent: false });
    }
  }

  get templateRef() {
    return this.template?.templateRef;
  }
}
