import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExpenseUpdateModalHeader } from '../../expense-header.model';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import {
  ExpenseFilterModel,
  ExpenseEventType,
  SearchOperators,
} from '@seahorse/domain';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'temp-purchase-invoice-filter-header',
  templateUrl: './purchase-invoice-filter-header.component.html',
  standalone: true,
  imports: [CommonModule, FormsModule, TranslateModule],
})
export class PurchaseInvoiceFilterHeaderComponent
  implements ExpenseUpdateModalHeader
{
  private _connectedDataKey: string[] = null;
  @Input() set connectedDataKey(val: string[]) {
    this._connectedDataKey = val;
    this.resetProperties();
  }
  get connectedDataKey() {
    return this._connectedDataKey;
  }

  filterPurchaseInvoices = true; // when this flag is true, only the exepnses that are not connected to purcahse invoices will be shown
  propertyPaths: string[] = [];

  // interface implementation
  @Input() isLoading = false;
  @Output() filterChange: EventEmitter<ExpenseUpdateModalHeader> =
    new EventEmitter<ExpenseUpdateModalHeader>();
  type: ExpenseEventType;
  data: ExpenseFilterModel;

  constructor() {
    this.type = ExpenseEventType.Filter;
  }

  onFilterChange() {
    this.filterPurchaseInvoices = !this.filterPurchaseInvoices;
    if (this.filterPurchaseInvoices === true) {
      this.data = {
        propertyPaths: this.propertyPaths,
        filterValue: null,
        filterOperator: SearchOperators.NotEquals,
      };
    } else {
      this.data = null;
    }
    this.filterChange.emit(this);
  }

  resetProperties(): void {
    if (this.connectedDataKey) {
      this.propertyPaths = this.connectedDataKey.map(
        (key) => `__ConnectedData.${key}`
      );
    }

    this.data = {
      propertyPaths: this.propertyPaths,
      filterValue: null,
      filterOperator: SearchOperators.NotEquals,
    };
  }
}
