import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'underscore';

import { EventModel } from '../../../../../../shared/models/event.model';
import { TariffGroupModel } from '../../../../../../invoicing-and-tariffs/modules/tariffs/models/tariff-group.model';
import { TariffModel } from '../../../../../../invoicing-and-tariffs/modules/tariffs/models/tariff.model';
import { TariffGroupDataService } from '../../../../../../invoicing-and-tariffs/modules/tariffs/services/tariff-group.data.service';
import { TariffDataService } from '../../../../../../invoicing-and-tariffs/modules/tariffs/services/tariff-data.service';
import { NotificationService } from '@seahorse/common';
import { InvoiceModel } from '@seahorse/domain';

@Component({
  selector: 'invoice-creator-line-card',
  templateUrl: 'invoice-creator-line-card.component.html',
})
export class InvoiceCreatorLineCardComponent implements OnInit {
  @Input() invoice: InvoiceModel;
  @Input() hideTitle: boolean;
  @Input() readOnly: boolean;

  @Output() onEvent: EventEmitter<EventModel>;

  tariffsObject: {
    tariffGroups: TariffGroupModel[];
    tariffs: TariffModel[];
    loadingTariffGroups: boolean;
    loadingTariffs: boolean;
  };

  constructor(
    private notification: NotificationService,
    private translate: TranslateService,
    private tariffGroupData: TariffGroupDataService,
    private tariffData: TariffDataService
  ) {
    this.invoice = null;
    this.hideTitle = false;
    this.readOnly = false;
    this.onEvent = new EventEmitter<EventModel>();

    this.tariffsObject = {
      tariffGroups: [],
      tariffs: [],
      loadingTariffGroups: false,
      loadingTariffs: false,
    };
  }

  ngOnInit() {
    this.tariffsObject.loadingTariffGroups = true;

    this.tariffGroupData.getTariffGroups(-1, -1).subscribe(
      (r) => {
        if (r.hasResult) {
          if (r.result.length) {
            this.tariffsObject.tariffGroups = r.result;
          }
        } else {
          this.notification.showError(
            _.pluck(r.messages, 'message').join('\n'),
            this.translate.instant('shared.terms.failed')
          );
        }
      },

      (e) => {
        this.notification.showError(
          _.pluck(e.error.messages, 'message').join('\n'),
          this.translate.instant('shared.terms.failed')
        );
      },

      () => {
        this.tariffsObject.loadingTariffGroups = false;

        if (this.tariffsObject.tariffGroups.length) {
          this.tariffsObject.loadingTariffs = true;

          let i = 0;

          _.forEach(this.tariffsObject.tariffGroups, (tariffGroup) => {
            this.tariffData
              .getTariffsByGroupId(tariffGroup.id, -1, -1)
              .subscribe(
                (r) => {
                  if (r.hasResult) {
                    if (r.result.length) {
                      this.tariffsObject.tariffs = _.union(
                        this.tariffsObject.tariffs,
                        r.result
                      );
                    }
                  } else {
                    this.notification.showError(
                      _.pluck(r.messages, 'message').join('\n'),
                      this.translate.instant('shared.terms.failed')
                    );
                  }
                },

                (e) => {
                  this.notification.showError(
                    _.pluck(e.error.messages, 'message').join('\n'),
                    this.translate.instant('shared.terms.failed')
                  );
                },

                () => {
                  i++;

                  if (i === this.tariffsObject.tariffGroups.length) {
                    this.tariffsObject.loadingTariffs = false;
                  }
                }
              );
          });
        }
      }
    );
  }
}
