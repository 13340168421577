<div class="modal-header align-items-center">
  <h1 class="modal-title">{{ mappingModel.objectName | uppercase }}</h1>
  <button
    aria-label="Close"
    (click)="onButtonClick('dismiss')"
    type="button"
    class="close"
  >
    <span aria-hidden="true">×</span>
  </button>
</div>

<div class="modal-body">
  <div class="d-flex mb-3">
    <div class="row">
      <div class="col" *ngIf="allowMultiple">
        <div
          class="input-group"
          title="{{ 'shared.terms.selectAll' | translate }}"
        >
          <div class="input-group-prepend">
            <div class="input-group-text">
              <input
                id="checkboxAllObjects"
                [checked]="isAllObjectChecked"
                (change)="selectAll(!isAllObjectChecked)"
                type="checkbox"
              />
            </div>
          </div>
          <label for="checkboxAllObjects" class="input-group-text">{{
            'shared.terms.selectAll' | translate
          }}</label>
        </div>
      </div>
      <div class="col">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i class="fa-regular fa-search"></i>
            </span>
            <input
              type="text"
              [(ngModel)]="searchField"
              class="form-control"
              placeholder="{{ 'shared.terms.filter' | translate }}"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div style="max-height: 240px; overflow-y: auto" class="border rounded">
    <ng-container
      *ngIf="!loadObjects; then objectPicker; else loading"
    ></ng-container>

    <ng-template #objectPicker>
      <table
        class="table table-borderless table-hover mb-0"
        *ngIf="objects && objects.length > 0"
      >
        <tr *ngFor="let object of objects; let i = index">
          <td scope="row">
            <div class="custom-control custom-checkbox">
              <input
                [id]="'checkbox-objectPicker-' + i"
                [checked]="selectedObjectIds.indexOf(object.__Id) > -1"
                (change)="selectObject(object.__Id)"
                type="checkbox"
                class="custom-control-input"
              />
              <label
                [for]="'checkbox-objectPicker-' + i"
                class="custom-control-label w-100"
              >
                {{ object.__DisplayName }}
              </label>
            </div>
          </td>
        </tr>
      </table>
      <div
        class="alert alert-light mb-0"
        *ngIf="!objects || objects.length === 0"
      >
        {{ 'shared.terms.noResult' | translate }}
      </div>
    </ng-template>

    <ng-template #loading>
      <sh-loading-indicator
        class="d-flex justify-content-center align-items-center h-100"
      ></sh-loading-indicator>
    </ng-template>
  </div>
</div>

<div class="modal-footer">
  <button
    (click)="onButtonClick('dismiss')"
    type="button"
    class="btn btn-link w-50"
  >
    {{ 'shared.terms.cancel' | translate }}
  </button>
  <button
    (click)="onButtonClick('ok')"
    type="button"
    class="btn btn-success w-50"
  >
    {{ 'shared.terms.ok' | translate }}
  </button>
</div>
