import {
  CurrencyPipe,
  DatePipe,
  DecimalPipe,
  LowerCasePipe,
  UpperCasePipe,
} from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'underscore';
import { KeyValuePair } from '../types/common.types';
import { PipeModel } from './pipe.model';
import { TruncatePipe } from './truncate.pipe';

const defaultLocale = 'nl-NL';

@Pipe({
  name: 'shGeneralPipeResolver',
  standalone: true,
})
export class GeneralPipeResolverPipe implements PipeTransform {
  transform(input: any, pipe: PipeModel): string {
    if (pipe && input !== undefined && input !== null) {
      try {
        switch (pipe.name) {
          case 'currency': {
            const currencyCode =
              pipe.params && pipe.params.currencyCode
                ? pipe.params.currencyCode
                : 'EUR';
            const digitsInfo =
              pipe.params && pipe.params.digitsInfo
                ? pipe.params.digitsInfo
                : '1.2-2';
            const display =
              pipe.params && pipe.params.display
                ? pipe.params.display
                : 'symbol-narrow';

            return new CurrencyPipe(defaultLocale).transform(
              input,
              currencyCode,
              display,
              digitsInfo,
              defaultLocale
            );
          }
          case 'date': {
            const paramsDate = !pipe.params ? {} : pipe.params;

            return new DatePipe(defaultLocale).transform(
              input,
              paramsDate.format,
              paramsDate.timezone,
              defaultLocale
            );
          }
          case 'time': {
            const paramsTime = !pipe.params ? {} : pipe.params;
            return new DatePipe(defaultLocale).transform(
              Date.parse(`1970-01-01T${input}`),
              paramsTime.format
            );
          }
          case 'json': {
            const paramsJson = !pipe.params ? {} : pipe.params;
            return JSON.stringify(input, paramsJson.replacer, paramsJson.space);
          }
          case 'keyValue': {
            let values = [];
            const displayFields =
              pipe.params &&
              pipe.params.displayFields &&
              pipe.params.displayFields.length > 0
                ? pipe.params.displayFields
                : null;
            const separator =
              pipe.params && pipe.params.separator
                ? pipe.params.separator
                : '|';
            const onlyValue =
              pipe.params && pipe.params.onlyValue === true ? true : false;

            const pairs: KeyValuePair<string, string>[] = [];

            _.each(input, (v, k: string) => {
              // property will shown when the value is defined
              if (v !== undefined && v !== null) {
                // display fields is not defined, show all properties OR show only the property when the display fields is defined
                if (
                  displayFields === null ||
                  _.some(
                    displayFields,
                    (f: string) => f.toLowerCase() === k.toLowerCase()
                  )
                ) {
                  pairs.push(new KeyValuePair(`${k}`, `${v}`));
                }
              }
            });

            if (pairs.length > 0) {
              values = _.map(pairs, (pair) => {
                if (onlyValue === true || pairs.length === 1) {
                  return pair.value;
                } else {
                  return `${pair.key}: ${pair.value}`;
                }
              });
            }

            return values.join(` ${separator} `);
          }
          case 'lowercase':
            return new LowerCasePipe().transform(input);
          case 'number': {
            const paramsNr = !pipe.params ? {} : pipe.params;
            if (!paramsNr.locale) {
              paramsNr.locale = defaultLocale;
            }

            return new DecimalPipe(paramsNr.locale).transform(
              input,
              paramsNr.digitsInfo,
              paramsNr.locale
            );
          }
          case 'shTruncate': {
            const paramsTruncate =
              !pipe.params || !pipe.params.limit ? { limit: 10 } : pipe.params;
            return new TruncatePipe().transform(input, paramsTruncate.limit);
          }
          case 'uppercase':
            return new UpperCasePipe().transform(input);
          default:
            break;
        }
      } catch (e) {
        //
      }
    }

    return input;
  }
}
