import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { DataContextService } from '@seahorse/common';
import { ResultWrapper } from '@seahorse/common';
import { FieldDefinitionModel } from '@seahorse/domain';

@Injectable()
export class FieldDefinitionsDataService {
  constructor(private dataContextService: DataContextService) {}

  getByObjectDefinitionId(
    dataObjectId: FieldDefinitionModel['dataObjectId'],
    pageIndex: number,
    pageSize: number
  ): Observable<ResultWrapper<FieldDefinitionModel>> {
    // TODO return base field definitions
    const urlArgs = [];
    urlArgs.push(`pindex=${pageIndex}`);
    urlArgs.push(`psize=${pageSize}`);

    return this.dataContextService.get<FieldDefinitionModel>(
      `customdata/fielddefinitions/${dataObjectId}?${urlArgs.join('&')}`
    );
  }

  add(
    fieldDefinition: FieldDefinitionModel
  ): Observable<ResultWrapper<FieldDefinitionModel>> {
    return this.dataContextService.post<FieldDefinitionModel>(
      `customdata/fielddefinitions`,
      fieldDefinition
    );
  }

  delete(
    fieldDefinition: FieldDefinitionModel
  ): Observable<ResultWrapper<FieldDefinitionModel>> {
    return this.dataContextService.delete<FieldDefinitionModel>(
      `customdata/fielddefinitions/${fieldDefinition.dataObjectId}/${fieldDefinition.systemCode}`
    );
  }

  update(
    fieldDefinition: FieldDefinitionModel
  ): Observable<ResultWrapper<FieldDefinitionModel>> {
    // fieldDefinition.fieldRule = null;
    return this.dataContextService.put<FieldDefinitionModel>(
      `customdata/fielddefinitions/${fieldDefinition.dataObjectId}`,
      fieldDefinition
    );
  }
}
