import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'underscore';
import { NauticalShipModel } from '@seahorse/domain';
import { NauticalShipPickerDialogComponent } from '../nautical-ship-picker-dialog/nautical-ship-picker-dialog.component';

@Component({
  selector: 'ca-nautical-ship-card',
  templateUrl: './nautical-ship-card.component.html',
})
export class NauticalShipCardComponent {
  @Input() nauticalShip: NauticalShipModel;
  @Input() title: string;
  @Input() isLoading = false;

  @Output() onChanged: EventEmitter<NauticalShipModel>;

  constructor(private modal: NgbModal) {
    this.onChanged = new EventEmitter<NauticalShipModel>();
  }

  openNauticalShipPicker() {
    const m: NgbModalRef = this.modal.open(NauticalShipPickerDialogComponent, {
      backdrop: 'static',
    });
    m.componentInstance.nauticalShipRequired = false;
    if (this.nauticalShip) {
      m.componentInstance.nauticalShip = _.clone(this.nauticalShip);
    }

    m.result
      .then(
        (o: { nauticalShip: NauticalShipModel }) => {
          if (
            o.nauticalShip &&
            this.nauticalShip &&
            o.nauticalShip.id === this.nauticalShip.id
          ) {
            return;
          }

          this.onChanged.emit(o.nauticalShip);
        },
        () => {}
      )
      .catch(() => {})
      .finally(() => {});
  }

  openNauticalShip() {
    if (this.nauticalShip && this.nauticalShip.id) {
      window.open(`nautical/vessels/${this.nauticalShip.id}`, '_blank');
    }
  }

  unlinkNauticalShip() {
    this.nauticalShip = null;
    this.onChanged.emit(null);
  }
}
