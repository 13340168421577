<div class="modal-header">
  <h4 class="modal-title">
    {{
      (isNew ? 'nautical.ship.addShip' : 'nautical.ship.editShip') | translate
    }}
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form #shipForm="ngForm">
  <div class="modal-body">
    <div class="form-group">
      <label>
        <b class="required">{{ 'nautical.ship.model.name' | translate }}</b>
      </label>
      <input
        required
        #name="ngModel"
        type="text"
        name="name"
        [(ngModel)]="ship.name"
        class="form-control"
      />
      <small
        *ngIf="
          (name.value === undefined ||
            name.value === null ||
            name.value === '') &&
          isInvalid
        "
        class="text-danger"
      >
        {{ 'nautical.ship.errors.nameEmpty' | translate }}</small
      >
    </div>

    <form [formGroup]="form">
      <div class="form-group">
        <label class="required">
          <b>{{ 'nautical.ship.model.status' | translate }}</b>
        </label>
        <sh-drop-down-list
          formControlName="status"
          [options]="statusDropDownList"
        ></sh-drop-down-list>
      </div>
    </form>

    <div class="form-group">
      <label
        ><b>{{ 'nautical.ship.model.callSign' | translate }}</b></label
      >
      <input
        #callSign="ngModel"
        type="text"
        name="callSign"
        [(ngModel)]="ship.callSign"
        class="form-control"
      />
      <small
        *ngIf="isInvalid && ship.callSign?.length > 50"
        class="text-danger"
      >
        {{ 'nautical.ship.errors.callSignMaxLength' | translate }}
      </small>
    </div>

    <temp-flag-code-picker-dialog-form
      [(selectedFlagCode)]="ship.flagCode"
      [isRequired]="true"
      [errors]="flagCodeErrors"
    ></temp-flag-code-picker-dialog-form>

    <div class="form-group">
      <label
        ><b>{{ 'nautical.ship.model.mmsi' | translate }}</b></label
      >
      <input
        #mmsi="ngModel"
        type="text"
        name="mmsi"
        [(ngModel)]="ship.mmsi"
        class="form-control"
      />
      <small *ngIf="isInvalid && ship.mmsi?.length > 9" class="text-danger">
        {{ 'nautical.ship.errors.mmsiMaxLength' | translate }}</small
      >
    </div>

    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label
            ><b>{{ 'nautical.ship.model.imo' | translate }}</b></label
          >
          <input
            #imo="ngModel"
            type="text"
            name="imo"
            [(ngModel)]="ship.imo"
            class="form-control"
          />
          <small *ngIf="isInvalid && ship.imo?.length > 7" class="text-danger">
            {{ 'nautical.ship.errors.imoMaxLength' | translate }}</small
          >
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label
            ><b>{{ 'nautical.ship.model.eni' | translate }}</b></label
          >
          <input
            #eni="ngModel"
            type="text"
            name="eni"
            [(ngModel)]="ship.eni"
            class="form-control"
          />
          <small *ngIf="isInvalid && ship.eni?.length > 8" class="text-danger">
            {{ 'nautical.ship.errors.eniMaxLength' | translate }}
          </small>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label
            ><b>{{ 'nautical.ship.model.fisheryNumber' | translate }}</b></label
          >
          <input
            #fisheryNumber="ngModel"
            type="text"
            name="fisheryNumber"
            [(ngModel)]="ship.fisheryNumber"
            class="form-control"
          />
          <small
            *ngIf="isInvalid && ship.fisheryNumber?.length > 50"
            class="text-danger"
          >
            {{
              'nautical.ship.errors.fisheryNumberMaxLength' | translate
            }}</small
          >
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label
            ><b>{{ 'nautical.ship.model.maxDraft' | translate }}</b></label
          >
          <input
            #maxDraft="ngModel"
            type="text"
            name="maxDraft"
            [(ngModel)]="ship.maxDraft"
            class="form-control"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label
            ><b>{{ 'nautical.ship.model.length' | translate }}</b></label
          >
          <input
            #length="ngModel"
            type="text"
            name="length"
            [(ngModel)]="ship.loa"
            class="form-control"
          />
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label
            ><b>{{ 'nautical.ship.model.width' | translate }}</b></label
          >
          <input
            #width="ngModel"
            type="text"
            name="width"
            [(ngModel)]="ship.width"
            class="form-control"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label
            ><b>{{ 'nautical.ship.model.gt' | translate }}</b></label
          >
          <input
            #gt="ngModel"
            type="text"
            name="gt"
            [(ngModel)]="ship.gt"
            class="form-control"
          />
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label
            ><b>{{ 'nautical.ship.model.dwt' | translate }}</b></label
          >
          <input
            #dwt="ngModel"
            type="text"
            name="dwt"
            [(ngModel)]="ship.dwt"
            class="form-control"
          />
        </div>
      </div>
    </div>

    <div class="form-group">
      <label>
        <b class="required">{{
          'nautical.ship.shipType.shipType' | translate
        }}</b>
      </label>
      <ng-select
        required
        #shipType
        name="shipType"
        bindLabel="name"
        [(ngModel)]="ship.shipType"
        [items]="shipTypes"
        (change)="changeShipType($event)"
        placeholder="Select type"
      ></ng-select>
      <small
        *ngIf="
          isInvalid &&
          (ship.shipTypeId === null || ship.shipTypeId === undefined)
        "
        class="text-danger"
      >
        {{ 'nautical.ship.shipType.errors.shipTypeEmpty' | translate }}</small
      >
    </div>

    <!--
        <div class="form-group">
        <label><b>{{'nautical.ship.model.isVerified' | translate}}</b></label>:
        <input required #isVerified="ngModel" type="checkbox" name="isVerified" [(ngModel)]="ship.isVerified" />
        </div>
    -->
  </div>
</form>

<div class="modal-footer d-flex">
  <div class="flex-grow-1">
    <button (click)="close()" type="button" class="btn btn-block btn-link">
      {{ 'shared.terms.cancel' | translate }}
    </button>
  </div>
  <div class="flex-grow-1">
    <button (click)="save()" type="button" class="btn btn-block btn-success">
      {{ 'shared.terms.save' | translate }}
    </button>
  </div>
</div>
