<div *shEmptyPlaceholder="isLoading">
  <div *ngIf="visit" class="p-relative">
    <div class="card-body">
      <div
        class="card-body-group card-body-group-no-border attribute-group"
        *ngIf="attributes && attributes.length > 0"
      >
        <temp-attribute-list [attributes]="attributes"></temp-attribute-list>
      </div>
      <div class="card-body-group">
        <ca-nautical-visit-flow
          [nautical-visit]="visit"
        ></ca-nautical-visit-flow>
      </div>
    </div>
    <div>
      <ul class="summary-buttons">
        <li>
          <a routerLink="/nautical/visit/{{ visit.id }}" class="summary-button">
            <i class="fa-regular fa-exchange-alt fa-fw" aria-hidden="true"></i>
            <span class="summary-button-text">
              {{ 'nautical.visit.visitDetails' | translate }}
            </span>
          </a>
        </li>
        <li>
          <a
            routerLink="/nautical/vessels/{{ visit.ship.id }}"
            class="summary-button"
          >
            <i class="fa-regular fa-ship fa-fw" aria-hidden="true"></i>
            <span class="summary-button-text">
              {{ 'nautical.ship.shipDetails' | translate }}
            </span>
          </a>
        </li>
        <ng-container *ngIf="visit.atd">
          <li
            *hasPermission="'custom_ui.port_of_amsterdam'"
            title="Opgave zeehavengeld Port of Amsterdam"
          >
            <a
              href="/custom-ui/port-of-amsterdam/zhg/new;ucrn={{
                visit.referenceNumber
              }}"
              class="summary-button"
            >
              <span class="summary-button-icon">
                <img src="/assets/images/logos/port-of-amsterdam.svg" />
              </span>
              <span class="summary-button-text"> Opgave ZHG </span>
            </a>
          </li>
        </ng-container>
        <li *ngIf="visit.ship && visit.ship.mmsi && shipPosition">
          <a
            routerLink="/map"
            [queryParams]="{
              lat: shipPosition.latitude,
              lng: shipPosition.longitude,
              mmsi: visit.ship.mmsi
            }"
            class="summary-button has-summary-count"
          >
            <i class="fa-regular fa-map-marked-alt fa-fw"></i>
            <span class="summary-button-text">{{
              'nautical.ship.findOnMap' | translate
            }}</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
