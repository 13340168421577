<table
  class="table table-truncated"
  [ngClass]="{ 'table-clickable': isClickable }"
>
  <thead>
    <tr>
      <th *ngIf="showColumn('position', true)" style="width: 1%"></th>
      <th *ngIf="showColumn('portName', true)">
        {{ 'nautical.terms.port' | translate }}
      </th>
      <th *ngIf="showColumn('displayTime')">
        {{ 'shared.terms.date' | translate }}
      </th>
      <th *ngIf="showColumn('referenceNumber', true)">
        {{ 'nautical.visit.model.crn' | translate }}
      </th>
      <th *ngIf="showColumn('nautical.ship.name')">
        {{ 'shared.terms.name' | translate }}
      </th>
      <th *ngIf="showColumn('nautical.ship.identification')">
        {{ 'nautical.ship.identification' | translate }}
      </th>
      <th *ngIf="showColumn('currentBerth') && !selectedVisit">
        {{ 'nautical.terms.currentBerth' | translate }}
      </th>
      <th *ngIf="showColumn('expectedBerth') && !selectedVisit">
        {{ 'nautical.terms.expectedBerth' | translate }}
      </th>
      <th *ngIf="showColumn('previousBerth') && !selectedVisit">
        {{ 'nautical.terms.previousBerth' | translate }}
      </th>
      <th *ngIf="showColumn('agent')">
        {{ 'nautical.terms.portAgent' | translate }}
      </th>
      <th *ngIf="showColumn('eta', true) && !selectedVisit">
        {{ 'nautical.terms.eta' | translate }}
      </th>
      <th *ngIf="showColumn('ata', true) && !selectedVisit">
        {{ 'nautical.terms.ata' | translate }}
      </th>
      <th *ngIf="showColumn('etd', true) && !selectedVisit">
        {{ 'nautical.terms.etd' | translate }}
      </th>
      <th *ngIf="showColumn('atd', true) && !selectedVisit">
        {{ 'nautical.terms.atd' | translate }}
      </th>
      <th *ngIf="showColumn('portStay')">
        {{ 'nautical.terms.portStay' | translate }}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr
      *ngFor="let visit of visits"
      (click)="selectVisit(visit)"
      [ngClass]="{ selected: selectedVisit && selectedVisit.id === visit.id }"
    >
      <td *ngIf="showColumn('position', true)" class="pl-3 pr-0 text-center">
        <ca-map-direction-indicator
          *ngIf="positions && positions[visit.ship.mmsi]"
          [showSpeed]="false"
          [noResize]="true"
          [position]="positions[visit.ship.mmsi]"
        >
        </ca-map-direction-indicator>
      </td>
      <td *ngIf="showColumn('portName', true)" class="text-nowrap">
        {{ visit.portName }}
      </td>
      <td *ngIf="showColumn('displayTime')" class="text-nowrap">
        <span
          class="text-muted"
          *ngIf="!visit.eta && !visit.ata && !visit.etd && !visit.atd"
          >{{ 'shared.terms.unknown' | translate }}</span
        >
        <span *ngIf="visit.eta && !visit.ata && !visit.atd">
          <span class="text-muted">{{ 'nautical.terms.eta' | translate }}</span>
          {{ visit.eta | date: 'dd-MM-yyyy HH:mm' }}</span
        >
        <span *ngIf="visit.ata && !visit.etd && !visit.atd">
          <span class="text-muted">{{ 'nautical.terms.ata' | translate }}</span>
          {{ visit.ata | date: 'dd-MM-yyyy HH:mm' }}</span
        >
        <span *ngIf="visit.ata && visit.etd && !visit.atd"
          ><span class="text-muted">{{
            'nautical.terms.etd' | translate
          }}</span>
          {{ visit.etd | date: 'dd-MM-yyyy HH:mm' }}</span
        >
        <span *ngIf="visit.atd">
          <span class="text-muted">{{ 'nautical.terms.atd' | translate }}</span>
          {{ visit.atd | date: 'dd-MM-yyyy HH:mm' }}</span
        >
      </td>
      <td
        *ngIf="showColumn('referenceNumber', true)"
        class="text-nowrap"
        [ngClass]="{ 'text-nowrap-button': isCopyable }"
      >
        {{ visit.referenceNumber }}
        <button
          type="button"
          class="btn btn-xs btn-inline-icon float-right"
          (click)="copy(visit.referenceNumber)"
          title="{{ 'shared.terms.copy' | translate }}"
          *ngIf="isCopyable"
        >
          <i class="fa-regular fa-copy fa-fw"></i>
        </button>
      </td>
      <td *ngIf="showColumn('nautical.ship.name')">
        {{ visit.ship.name | uppercase }}
      </td>
      <td
        *ngIf="showColumn('nautical.ship.identification')"
        class="text-nowrap"
        [ngClass]="{ 'text-nowrap-button': isCopyable }"
      >
        <span *ngIf="visit.ship && visit.ship.imo">
          <span class="text-muted">{{
            'nautical.ship.model.imo' | translate
          }}</span>
          {{ visit.ship.imo }}
          <button
            type="button"
            class="btn btn-xs btn-inline-icon float-right"
            (click)="copy(visit.ship.imo)"
            title="{{ 'shared.terms.copy' | translate }}"
            *ngIf="isCopyable"
          >
            <i class="fa-regular fa-copy fa-fw"></i>
          </button>
        </span>
        <span *ngIf="visit.ship && !visit.ship.imo && visit.ship.callSign">
          <span class="text-muted">{{
            'nautical.ship.model.callSign' | translate
          }}</span>
          {{ visit.ship.callSign }}</span
        >
        <span *ngIf="visit.ship && !visit.ship.imo && visit.ship.eni">
          <span class="text-muted">{{
            'nautical.ship.model.eni' | translate
          }}</span>
          {{ visit.ship.eni }}</span
        >
        <span *ngIf="visit.ship && !visit.ship.imo && visit.ship.fisheryNumber">
          <span class="text-muted">{{
            'nautical.ship.model.fisheryNumber' | translate
          }}</span>
          {{ visit.ship.fisheryNumber }}</span
        >
      </td>
      <td
        *ngIf="showColumn('currentBerth') && !selectedVisit"
        class="text-truncate"
      >
        <span
          *ngIf="
            visit.currentMovement &&
            visit.currentMovement.ata &&
            visit.currentMovement.portWayPointTo
          "
        >
          {{ visit.currentMovement.portWayPointTo.code }} -
          {{ visit.currentMovement.portWayPointTo.name }}
        </span>
        <span
          *ngIf="
            visit.currentMovement &&
            !visit.currentMovement.ata &&
            visit.currentMovement.atd &&
            visit.currentMovement.portWayPointFrom
          "
        >
          {{ visit.currentMovement.portWayPointFrom.code }} -
          {{ visit.currentMovement.portWayPointFrom.name }}
        </span>
        <span
          *ngIf="
            visit.currentMovement &&
            !visit.currentMovement.ata &&
            !visit.currentMovement.atd &&
            visit.currentMovement.etd &&
            visit.currentMovement.portWayPointFrom
          "
        >
          {{ visit.currentMovement.portWayPointFrom.code }} -
          {{ visit.currentMovement.portWayPointFrom.name }}
        </span>
      </td>
      <td
        *ngIf="showColumn('expectedBerth') && !selectedVisit"
        class="text-truncate"
      >
        <span
          *ngIf="
            visit.currentMovement &&
            visit.currentMovement.portWayPointTo &&
            !visit.currentMovement.ata
          "
        >
          {{ visit.currentMovement.portWayPointTo.code }} -
          {{ visit.currentMovement.portWayPointTo.name }}
        </span>
      </td>
      <td
        *ngIf="showColumn('previousBerth') && !selectedVisit"
        class="text-truncate"
      >
        <span
          *ngIf="
            visit.currentMovement && visit.currentMovement.portWayPointFrom
          "
        >
          {{ visit.currentMovement.portWayPointFrom.code }} -
          {{ visit.currentMovement.portWayPointFrom.name }}
        </span>
      </td>
      <td *ngIf="showColumn('agent')" class="text-truncate">
        {{ visit.portAgentName }}
      </td>
      <td *ngIf="showColumn('eta', true) && !selectedVisit">
        {{ visit.eta | date: 'dd-MM-yyyy HH:mm' }}
      </td>
      <td *ngIf="showColumn('ata', true) && !selectedVisit">
        {{ visit.ata | date: 'dd-MM-yyyy HH:mm' }}
      </td>
      <td *ngIf="showColumn('etd', true) && !selectedVisit">
        {{ visit.etd | date: 'dd-MM-yyyy HH:mm' }}
      </td>
      <td *ngIf="showColumn('atd', true) && !selectedVisit">
        {{ visit.atd | date: 'dd-MM-yyyy HH:mm' }}
      </td>
      <td *ngIf="showColumn('portStay')">{{ visit.portStay }}</td>
    </tr>
  </tbody>
</table>
