import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationService } from '@seahorse/common';
import { SeahorseFormBuilder, SeahorseFormsModule } from '@seahorse/forms';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { UserAccountService } from '../../../core/account/services/user-account.service';

@Component({
  selector: 'ca-mfa-challenge',
  templateUrl: './mfa-challenge.component.html',
  standalone: true,
  imports: [
    CommonModule,
    SeahorseFormsModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
})
export class MfaChallengeComponent {
  codeControl = inject(SeahorseFormBuilder).control<string | null>(
    null,
    Validators.required
  );

  private _userAccountService = inject(UserAccountService);
  private _notificationService = inject(NotificationService);

  submitChallenge() {
    if (this.codeControl.invalid) {
      return;
    }

    const authorizationCode = this.codeControl.value;

    return this._userAccountService.mfaChallenge({ authorizationCode }).pipe(
      mergeMap((response) => {
        if (!response.hasResult) {
          this._notificationService.showError('shared.terms.error');
          return of(false);
        }

        const { isSuccess } = response.result;

        if (!isSuccess) {
          this._notificationService.showError(
            'user.mfa.invalidVerificationCode'
          );
          return of(false);
        }

        return of(true);
      })
    );
  }
}
