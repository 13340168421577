<div>
  <div class="row" *ngIf="visit && visit.portMovements">
    <div class="col-md-12">
      <div *ngFor="let movement of visit.portMovements">
        <h5>{{movement.movementType | nauticalMovementType}}</h5>
        <div *ngIf="movement.portWayPointFrom">
          <span class="text-muted">From</span>
          {{movement.portWayPointFrom.name}}
          <span *ngIf="movement.etd && !movement.atd">
            <span class="text-muted">estimated</span>
            {{movement.etd | date: 'dd-MM-yyyy HH:mm'}}
          </span>
          <span *ngIf="movement.atd">
            <span class="text-muted">on</span>
            {{movement.atd | date: 'dd-MM-yyyy HH:mm'}}
          </span>
        </div>
        <div *ngIf="movement.portWayPointTo">
          <span class="text-muted">To</span> {{movement.portWayPointTo.name}}
          <span *ngIf="movement.eta && !movement.ata">
            <span class="text-muted">estimated</span>
            {{movement.eta | date: 'dd-MM-yyyy HH:mm'}}
          </span>
          <span *ngIf="movement.ata">
            <span class="text-muted">on</span>
            {{movement.ata | date: 'dd-MM-yyyy HH:mm'}}
          </span>
        </div>
        <div
          *ngIf="visit && visit.portVisitAttributes && visitAttributesForMovement(movement.id).length > 0"
        >
          <h5>Movement Attributes</h5>
          <table class="table table-sm table-striped">
            <thead>
              <tr>
                <th>Category</th>
                <th>Created on</th>
                <th>Name</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let attribute of visitAttributesForMovement(movement.id)"
              >
                <td>{{attribute.category}}</td>
                <td>{{attribute.createdOn | date: 'dd-MM-yyyy HH:mm'}}</td>
                <td>{{attribute.fieldName}}</td>
                <td>{{attribute.fieldValue}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          *ngIf="visit && visit.portVisitEvents && visitEventsForMovement(movement.id).length > 0"
        >
          <h6>Movement Events</h6>
          <table class="table table-sm table-striped">
            <thead>
              <tr>
                <th>Type</th>
                <th>Created on</th>
                <th>Activity</th>
                <th>Start</th>
                <th>End</th>
                <th>Parameters</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let event of visitEventsForMovement(movement.id)">
                <td>{{event.eventType | nauticalVisitEventType}}</td>
                <td>{{event.createdOn | date: 'dd-MM-yyyy HH:mm'}}</td>
                <td>{{event.activityType}}</td>
                <td>{{event.startsOn | date: 'dd-MM-yyyy HH:mm'}}</td>
                <td>{{event.endsOn | date: 'dd-MM-yyyy HH:mm'}}</td>
                <td>{{event.parameters}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div
    class="row"
    *ngIf="visit && visit.portVisitEvents && visitEventsForVisit().length > 0"
  >
    <div class="col-md-12">
      <h2>Visit Events</h2>
      <table
        class="table table-sm table-striped"
        *ngIf="visit && visit.portVisitEvents"
      >
        <thead>
          <tr>
            <th>Type</th>
            <th>Created on</th>
            <th>Activity</th>
            <th>Start</th>
            <th>End</th>
            <th>Parameters</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let event of visitEventsForVisit()">
            <td>{{event.eventType | nauticalVisitEventType}}</td>
            <td>{{event.createdOn | date: 'dd-MM-yyyy HH:mm'}}</td>
            <td>{{event.activityType}}</td>
            <td>{{event.startsOn | date: 'dd-MM-yyyy HH:mm'}}</td>
            <td>{{event.endsOn | date: 'dd-MM-yyyy HH:mm'}}</td>
            <td>{{event.parameters}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
