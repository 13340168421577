import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ResultWrapper } from '@seahorse/common';
import { NotificationService } from '@seahorse/common';
import { CollectionDisplay } from '../../../../../layout/models/collection-display.model';
import { BasePage } from '../../../../../layout/pages/base/base.page';
import { LayoutDisplayService } from '../../../../../layout/services/layout-display.service';
import { PagedDataService } from '../../../../../layout/services/paged-data-service';
import { EventModel } from '../../../../../shared/models/event.model';
import { QueryModel } from '../../../../../shared/models/query.model';
import { ViewModel } from '../../../../../shared/models/view.model';
import { Observable } from 'rxjs';
import * as _ from 'underscore';
import { InvoicingFlowsOverviewStateModel } from '../../models/invoicing-flows-overview-state.model';
import { FlowDataService } from '../../services/flow-data.service';
import { FlowModel } from '@seahorse/domain';

@Component({
  templateUrl: 'invoicing-flows-overview.page.html',
  providers: [PagedDataService],
})
export class InvoicingFlowsOverviewPage
  extends BasePage<InvoicingFlowsOverviewStateModel>
  implements OnInit
{
  moduleName: string;
  isInitial: boolean;
  viewOptions: ViewModel[];
  selectedView: ViewModel['value'];
  invoicingFlowsQuery: QueryModel;
  invoicingFlowsCount: number;
  invoicingFlows: FlowModel[];
  loadingInvoicingFlows: boolean;
  indexedInvoicingFlows: boolean;
  selectedInvoicingFlow: FlowModel;
  CollectionDisplay = CollectionDisplay;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected translate: TranslateService,
    protected notification: NotificationService,
    protected invoicingFlowsOverviewData: PagedDataService<InvoicingFlowsOverviewStateModel>,
    protected layoutDisplay: LayoutDisplayService,
    protected flowData: FlowDataService
  ) {
    super(route, router);

    this.moduleName = null;
    this.isInitial = true;
    this.viewOptions = [
      { value: CollectionDisplay.List, title: 'shared.terms.list' },
      { value: CollectionDisplay.Tiles, title: 'shared.terms.tiles' },
    ];
    this.selectedView = CollectionDisplay.List;
    this.invoicingFlowsQuery = new QueryModel();
    this.invoicingFlowsCount = 0;
    this.invoicingFlows = [];
    this.loadingInvoicingFlows = false;
    this.indexedInvoicingFlows = false;
    this.selectedInvoicingFlow = null;
  }

  ngOnInit() {
    this.translate.get('invoicingFlows.title').subscribe((translateGet) => {
      this.layoutDisplay.currentModuleName = translateGet;
      this.moduleName = translateGet;
    });

    this.invoicingFlowsOverviewData.init(
      'ifo',
      this.invoicingFlowsQuery.pageIndex,
      this.invoicingFlowsQuery.pageSize
    );

    this.searchInvoicingFlows(this.invoicingFlowsQuery);
    this.createDefaultState();
    super.ngOnInit();
  }

  createDefaultState(): InvoicingFlowsOverviewStateModel {
    const stateObject = new InvoicingFlowsOverviewStateModel();
    stateObject.view = this.selectedView;
    stateObject.search = this.invoicingFlowsQuery.search;
    stateObject.pIndex = this.invoicingFlowsQuery.pageIndex;
    stateObject.pSize = this.invoicingFlowsQuery.pageSize;

    this.isInitial = false;

    return stateObject;
  }

  loadData(): void {
    const paging = this.invoicingFlowsOverviewData.getPaging();
    const request = this.getLoadRequest();

    if (request) {
      this.invoicingFlowsOverviewData.loadEntries(request);
    }
  }

  onSearchChanged(query: QueryModel): void {
    this.setState('search', query.search);
    this.resetData();
    this.resetPaging();
    this.loadData();
  }

  onStateChanged(
    newState: InvoicingFlowsOverviewStateModel,
    changes: any[]
  ): void {
    if (newState.id) {
      if (
        this.selectedInvoicingFlow === null ||
        this.selectedInvoicingFlow.id !== newState.id
      ) {
        let entry = _.find(this.invoicingFlows, (e) => e.id === newState.id);

        if (entry) {
          this.selectedInvoicingFlow = entry;
        } else {
          entry = new FlowModel();
          entry.id = newState.id;
          this.selectedInvoicingFlow = entry;
        }
      }
    } else {
      this.selectedInvoicingFlow = null;
    }

    if (newState.view) {
      this.selectedView = newState.view;
    }

    let needLoadData = false;
    const changedKeys = _.pluck(changes, 'key');

    if (_.contains(changedKeys, 'search') || _.contains(changedKeys, 'pSize')) {
      this.resetData();

      needLoadData = true;

      this.invoicingFlowsOverviewData.init(
        'ifo',
        this.invoicingFlowsQuery.pageIndex,
        this.invoicingFlowsQuery.pageSize
      );
    } else {
      const oldIndex = _.find(changes, (c) => {
        return c.key === 'pIndex';
      });

      if (oldIndex) {
        const oldValue = !oldIndex.value
          ? this.invoicingFlowsQuery.pageIndex
          : oldIndex.value;
        const newValue = !newState.pIndex
          ? this.invoicingFlowsQuery.pageIndex
          : newState.pIndex;

        if (oldValue > newValue) {
          this.resetData();

          needLoadData = true;

          this.invoicingFlowsOverviewData.init(
            'ifo',
            this.invoicingFlowsQuery.pageIndex,
            this.invoicingFlowsQuery.pageSize
          );
        } else {
          needLoadData = true;
        }
      }
    }

    if (needLoadData === true) {
      const isChanged = this.invoicingFlowsOverviewData.setPaging(
        newState.pIndex,
        newState.pSize
      );

      if (this.isInitial === true || isChanged === false) {
        this.loadData();
      }
    }
  }

  resetData(): void {
    this.invoicingFlows = [];
    this.indexedInvoicingFlows = false;
    this.invoicingFlowsCount = 0;
  }

  resetPaging(): void {
    const paging = this.invoicingFlowsOverviewData.getPaging();

    this.invoicingFlowsOverviewData.resetPagedDataModel();
    this.setState('pIndex', paging.pIndex);
    this.setState('pSize', paging.pSize);
  }

  setEntry(entry: FlowModel): void {
    const currentId = this.getState('id');
    const newId = entry ? entry.id : null;

    this.selectedInvoicingFlow = entry;

    if (newId !== currentId) {
      this.setState('id', newId);
    }
  }

  private getLoadRequest(): Observable<ResultWrapper<any>> {
    const stateObject = this.getAllStates();

    if (stateObject.search) {
      this.searchInvoicingFlows(this.invoicingFlowsQuery);
    } else {
      return null;
    }
  }

  searchInvoicingFlows(invoicingFlowQuery: QueryModel): void {
    this.loadingInvoicingFlows = true;

    if (!this.isInitial) {
      const query: QueryModel = new QueryModel();
      query.search = this.getState('search');
      query.pageIndex = this.getState('pIndex');
      query.pageSize = this.getState('pSize');

      this.invoicingFlowsQuery = query;
    }

    this.flowData.search(this.invoicingFlowsQuery).subscribe(
      (flowSearch: ResultWrapper<FlowModel[]>) => {
        if (flowSearch.hasResult) {
          this.invoicingFlowsCount = flowSearch.count;

          if (invoicingFlowQuery.pageIndex > 0) {
            this.invoicingFlows = this.invoicingFlows.concat(flowSearch.result);
          } else {
            this.invoicingFlows = flowSearch.result;
          }

          this.indexedInvoicingFlows =
            this.invoicingFlows.length < this.invoicingFlowsCount;
        } else {
          this.notification.showError(
            _.pluck(flowSearch.messages, 'message').join('\n'),
            this.translate.instant('shared.terms.failed')
          );
        }

        this.loadingInvoicingFlows = false;
      },
      (flowSearchError) => {
        this.notification.showError(
          _.pluck(flowSearchError.error.messages, 'message').join('\n'),
          this.translate.instant('shared.terms.failed')
        );

        this.loadingInvoicingFlows = false;
      }
    );
  }

  moreInvoicingFlows(): void {
    this.invoicingFlowsQuery.pageIndex += 1;

    if (this.invoicingFlowsQuery.pageIndex) {
      this.setState('pIndex', this.invoicingFlowsQuery.pageIndex);
    }

    this.searchInvoicingFlows(this.invoicingFlowsQuery);
  }

  action(event: EventModel): void {
    switch (event.action) {
      case 'setView': {
        this.selectedView = event.data;

        if (this.selectedView) {
          this.setState('view', this.selectedView);
        }

        break;
      }

      case 'selectInvoicingFlow': {
        this.selectedInvoicingFlow = event.data;

        if (this.selectedInvoicingFlow) {
          this.setState('id', this.selectedInvoicingFlow.id);
        }

        break;
      }

      case 'searchInvoicingFlows': {
        this.invoicingFlowsQuery.search = event.data;

        if (this.invoicingFlowsQuery.search) {
          this.setState('search', this.invoicingFlowsQuery.search);
        }

        this.searchInvoicingFlows(this.invoicingFlowsQuery);

        break;
      }

      case 'createFlow': {
        this.router.navigate([
          `invoicing-and-tariffs/invoicing-flows/flow/create`,
        ]);

        break;
      }

      case 'viewInvoicingFlow': {
        this.router.navigate([
          `invoicing-and-tariffs/invoicing-flows/flow/${this.selectedInvoicingFlow.id}`,
        ]);

        break;
      }
    }
  }
}
