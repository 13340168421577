import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Component({
  selector: 'ca-custom-ui-datetime-picker-modal',
  templateUrl: 'datetime-picker-modal.component.html',
})
export class CustomUIDatetimePickerModal {
  defaultMinutes = 10;
  error: string = null;
  private _modalHeader: string;
  private _attribute: any = null;
  private _showAddButton: boolean;

  @Input() set attribute(attribute: any) {
    this._attribute = attribute;
    if (
      attribute &&
      isNaN(attribute.addMinutes) === false &&
      attribute.addMinutes > 0
    ) {
      this.defaultMinutes = parseInt(attribute.addMinutes);
    }
  }
  get attribute(): any {
    return this._attribute;
  }

  @Input() set modalHeader(modalHeader: string) {
    this._modalHeader = modalHeader;
  }
  get modalHeader(): string {
    return this._modalHeader;
  }

  @Input() set showAddButton(showAddButton: boolean) {
    this._showAddButton = showAddButton;
  }
  get showAddButton(): boolean {
    return this._showAddButton;
  }

  @Input() modelName: string;

  constructor(public modal: NgbActiveModal) {
    this._showAddButton = false;
  }

  addMinutes() {
    if (this.attribute.fieldValue) {
      this.attribute.fieldValue = moment(
        this.attribute.fieldValue,
        'DD-MM-YYYY HH:mm'
      )
        .add(this.defaultMinutes, 'm')
        .format('DD-MM-YYYY HH:mm');
    }
  }

  setNow() {
    this.attribute.fieldValue = moment(new Date()).format('DD-MM-YYYY HH:mm');
  }

  dismiss(result) {
    this.modal.dismiss(result);
  }

  save() {
    const value = moment(this.attribute.fieldValue, 'DD-MM-YYYY HH:mm');
    if (!this.attribute.fieldValue || value.isValid()) {
      this.attribute.fieldValue = value.format('DD-MM-YYYY HH:mm');
      this.modal.close(this.attribute);
    } else {
      this.error = 'De formaat is fout';
    }
  }
}
