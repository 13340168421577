import { Routes } from '@angular/router';

import { InvoicingFlowsOverviewPage } from './pages/invoicing-flows-overview/invoicing-flows-overview.page';
import { FlowCreatorPage } from './pages/flow-creator/flow-creator.page';
import { FlowDetailsPage } from './pages/flow-details/flow-details.page';
import { StageCreatorPage } from './pages/stage-creator/stage-creator.page';
import { StageDetailsPage } from './pages/stage-details/stage-details.page';

export const InvoicingFlowsRoutes: Routes = [
  {
    path: 'invoicing-flows',
    component: InvoicingFlowsOverviewPage,
    data: {
      ui: {
        headerTitle: 'invoicingFlows.title',
      },
    },
  },
  {
    path: 'invoicing-flows/flow/create',
    component: FlowCreatorPage,
    data: {
      ui: {
        headerTitle: 'invoicingFlow.flowCreator.title',
      },
    },
  },
  {
    path: 'invoicing-flows/flow/:id',
    component: FlowDetailsPage,
    data: {
      ui: {
        headerTitle: 'invoicingDetails.title',
      },
    },
  },
  {
    path: 'invoicing-flows/stage/create',
    component: StageCreatorPage,
    data: {
      ui: {
        headerTitle: 'invoicingFlows.stageCreator.title',
      },
    },
  },
  {
    path: 'invoicing-flows/stage/:id',
    component: StageDetailsPage,
    data: {
      ui: {
        headerTitle: 'invoicingFlows.flowDetails.title',
      },
    },
  },
];
