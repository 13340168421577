import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { LayoutDisplayService } from '../../../layout/services/layout-display.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ca-not-authorized',
  templateUrl: './not-authorized.component.html',
})
export class NotAuthorizedComponent implements OnInit {
  errorMessage: string;
  constructor(
    private layoutDisplay: LayoutDisplayService,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.layoutDisplay.currentModuleName = this.translate.instant(
      'shared.general.notAuthorizedTitle'
    );

    this.route.data.subscribe((data: Data) => {
      this.errorMessage = data['message'];
    });
  }
}
