<div class="list-group">
  <div class="list-wrapper list-group-grid">
    <button
      *ngFor="let icon of icons"
      [class.active]="selectedIcon === icon"
      (click)="selectedIconChange.emit(icon)"
      class="list-group-item list-group-item-action"
    >
      <i [ngClass]="icon" class="fa-duotone fa-2x fa-fw"></i>
    </button>
  </div>
</div>
