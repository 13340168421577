import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { EventModel } from '../../../shared/models/event.model';
@Component({ templateUrl: './icon-picker-dialog.component.html' })
export class IconPickerDialogComponent {
  @Input() title: string = this._translate.instant(
    'uiModules.moduleDefinition.displayIcon'
  );
  @Input() selectedIcon: string;
  @Input() icons: string[];

  constructor(
    private _activeModal: NgbActiveModal,
    private _translate: TranslateService
  ) {}

  submit() {
    const resolve = new EventModel({ data: this.selectedIcon });
    this._activeModal.close(resolve);
  }

  cancel() {
    this._activeModal.dismiss();
  }
}
