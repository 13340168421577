import { Component, Input, Output, EventEmitter } from '@angular/core';

import { EventModel } from '../../../../../../shared/models/event.model';
import * as _ from 'underscore';
import {
  StageModel,
  FlowModel,
  InvoiceTypes,
  StageTypes,
} from '@seahorse/domain';

@Component({
  selector: 'stage-details-form',
  templateUrl: 'stage-details-form.component.html',
})
export class StageDetailsFormComponent {
  @Input() stage: StageModel;
  @Input() loadingStage: boolean;
  @Input() flow: FlowModel;
  @Input() loadingFlow: boolean;

  @Output() onEvent: EventEmitter<EventModel>;

  invoiceTypes;
  stageTypes;

  constructor() {
    this.stage = null;
    this.loadingStage = false;
    this.flow = null;
    this.loadingFlow = false;
    this.onEvent = new EventEmitter<EventModel>();
    this.invoiceTypes = _.filter(_.toArray(InvoiceTypes), (r) => _.isNumber(r));
    this.stageTypes = _.filter(_.toArray(StageTypes), (r) => _.isNumber(r));
  }
}
