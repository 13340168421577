import { CustomDataBaseModel } from '@seahorse/domain';

export class OrderRegistrationCrewMemberModel extends CustomDataBaseModel {
  name: string;
  shengen: boolean;
  payment: boolean;
  flightDetails?: string;

  constructor() {
    super();

    this.name = null;
    this.shengen = false;
    this.payment = false;
    this.flightDetails = null;
  }
}
