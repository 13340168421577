<div class="page-header d-flex justify-content-end px-3 py-1">
  <button
    [disabled]="loadingFlow"
    (click)="onEvent.emit({ action: 'saveFlowThenNewFlow' })"
    type="button"
    class="btn btn-outline-success my-1"
  >
    <i class="fa-regular fa-download ml-1"></i>
    <span class="mx-2">{{
      'invoicingFlow.flowCreator.saveFlowThenNewFlow' | translate
    }}</span>
    <i class="fa-regular fa-redo-alt mr-1"></i>
  </button>
  <button
    [disabled]="loadingFlow"
    (click)="onEvent.emit({ action: 'saveFlowThenNewStage' })"
    type="button"
    class="btn btn-success ml-2 my-1"
  >
    <i class="fa-regular fa-download fa-fw ml-1"></i>
    <span class="ml-2 mr-1">{{
      'invoicingFlow.flowCreator.saveFlowThenNewStage' | translate
    }}</span>
  </button>
</div>
