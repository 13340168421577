<div class="page-nautical-map page-with-header">
  <ca-nautical-map-header
    (onSelectVessel)="selectVessel($event)"
  ></ca-nautical-map-header>
  <div class="map-container">
    <ca-map-box
      #mapbox
      [allowPopUp]="!useLargePopup"
      (markerSelected)="selectedMarker($event)"
    ></ca-map-box>
    <ca-map-popup-large
      #mapPopupLarge
      (close)="selectedMarker(null)"
    ></ca-map-popup-large>
  </div>
</div>
