import { NgModule } from '@angular/core';

import { CoreModule } from '../../../core/core.module';
import { CustomDataServicesModule } from '../../../custom-data/services/custom-data-services.module';

import { CustomTeamDataService } from './custom-team-data.service';
import { CustomMemberDataService } from './custom-member-data.service';

@NgModule({
  imports: [CoreModule, CustomDataServicesModule],
  providers: [CustomTeamDataService, CustomMemberDataService],
})
export class CustomGeneralServicesModule {}
