import { inject, Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  HUB_URL,
  SIGNALR_ACTION,
  SIGNALR_EVENT,
  SIGNALR_SERVER_METHOD,
  SignalRMessage,
} from './signal-r.model';

@Injectable({
  providedIn: 'root',
})
export class SignalRService {
  private hubUrl: string = inject(HUB_URL);
  receiveMessage: BehaviorSubject<SignalRMessage>;

  constructor() {
    this.receiveMessage = new BehaviorSubject<SignalRMessage>(null);
  }

  createConnection(): Observable<signalR.HubConnection> {
    return new Observable<signalR.HubConnection>((subscriber) => {
      try {
        const connection = new signalR.HubConnectionBuilder()
          .withUrl(this.hubUrl, {
            withCredentials: false,
          })
          .build();

        connection
          .start()
          .then(() => {
            subscriber.next(connection);
            this.registerHandler(connection);
          })
          .catch((e) => {
            subscriber.error(e);
            subscriber.complete();
          })
          .finally(() => {
            subscriber.complete();
          });
      } catch (error) {
        subscriber.error(error);
        subscriber.complete();
        console.log(`SignalR connection error: ${error}`);
      }
    });
  }

  // invoke server 'broadcast' method to send a message to all client
  broadcastMessage(
    connection: signalR.HubConnection,
    message: string
  ): Promise<any> {
    return connection.invoke(SIGNALR_SERVER_METHOD.BROADCAST, message);
  }

  // invoke server 'send' method to send a message to itself
  sendMessage(
    connection: signalR.HubConnection,
    message: string
  ): Promise<any> {
    return connection.invoke(
      SIGNALR_SERVER_METHOD.SEND,
      connection.connectionId,
      message
    );
  }

  // listen to the (server) broadcast event
  private registerHandler(connection: signalR.HubConnection): void {
    if (connection) {
      connection.on(
        SIGNALR_EVENT.BROADCAST_MESSAGE,
        (response: SignalRMessage) => {
          if (response) {
            switch (response.action) {
              case SIGNALR_ACTION.DISCONNECT:
                if (response.connectionId === connection.connectionId) {
                  if (
                    response.message !== undefined &&
                    response.message !== null &&
                    response.message.toString().trim() !== ''
                  ) {
                    this.receiveMessage.next(response);
                  }

                  connection.off(SIGNALR_EVENT.BROADCAST_MESSAGE);
                  connection.stop();
                  console.log(`Disconnect SignalR!`);
                }
                break;

              case SIGNALR_ACTION.BROADCAST:
                if (
                  !response.connectionId ||
                  response.connectionId.trim() === '' ||
                  response.connectionId === connection.connectionId
                ) {
                  this.receiveMessage.next(response);
                }
                break;
            }
          }
        }
      );
    }
  }
}
