import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '@seahorse/common';
import {
  CustomDataContentService,
  ObjectDefinitionModel,
} from '@seahorse/domain';
import { EMPTY } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { CustomDataFormService } from '../../form/custom-data-form.service';
import { ModalService } from '../../modal/modal.service';
import { UI_MODULE_CUSTOM_COMPONENTS } from '../models/page.models';

export const addNewObjectProcedure = () => {
  const customDataFormService = inject(CustomDataFormService);
  const modalService = inject(ModalService);
  const notifications = inject(NotificationService);
  const customDataContentService = inject(CustomDataContentService);
  const customComponents = inject(UI_MODULE_CUSTOM_COMPONENTS, {
    optional: true,
  });

  return (objectDefinition: ObjectDefinitionModel, seedData?: any) => {
    const customComponent = customComponents?.find(
      (x) => x.key === objectDefinition.mappingKey
    );

    if (customComponent) {
      const customComponentData = customComponent?.data?.();

      seedData = {
        ...customComponentData,
        ...seedData,
      };

      return modalService.openModal(customComponent.component, seedData).pipe(
        map((response) => {
          // dirty way to check if response is of type EventModel
          return response?.['data'] ?? response;
        })
      );
    }

    const newObject = Object.assign(
      ObjectDefinitionModel.emptyObjectWithDefaults(objectDefinition),
      seedData
    );

    return customDataFormService
      .openFormModal(
        objectDefinition,
        objectDefinition.addConfig?.fields,
        newObject
      )
      .pipe(
        mergeMap((value) => {
          return customDataContentService
            .addCustomerData(objectDefinition.systemCode, value)
            .pipe(
              map((response) => {
                if (!response.hasResult && response.messages) {
                  notifications.showError(
                    response.messages.join('\n'),
                    'shared.terms.failed'
                  );
                  return EMPTY;
                }

                return response.result;
              })
            );
        })
      );
  };
};
