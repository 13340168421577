<div class="modal-header align-items-center">
  <h1 class="modal-title">{{ 'shared.terms.editAttribute' | translate }}</h1>
  <button closeModal type="button" class="btn btn-link text-dark">
    <i class="fa-solid fa-times fa-sm fa-fw"></i>
  </button>
</div>
<div class="modal-body">
  <div class="form-row">
    <div class="form-group col-12">
      <label [for]="attribute.name">
        <b [ngClass]="{ required: attribute.required }">{{
          'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.model.' +
            attribute.name | translate
        }}</b>
      </label>
      <ng-container [ngSwitch]="attribute.type">
        <div *ngSwitchCase="'text'" class="input-group">
          <input
            [(ngModel)]="attribute.value"
            [id]="attribute.name"
            [name]="attribute.name"
            [required]="attribute.required"
            [placeholder]="
              'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.placeholder.' +
                attribute.name | translate
            "
            type="text"
            class="form-control"
          />
        </div>
        <div *ngSwitchCase="'movementType'" class="input-group">
          <select
            [(ngModel)]="attribute.value"
            [id]="attribute.name"
            [name]="attribute.name"
            [required]="attribute.required"
            class="custom-select"
          >
            <option selected [ngValue]="null">
              {{ 'shared.terms.choose' | translate }}...
            </option>
            <optgroup *ngIf="zeehavenIJmuidenVisitMovementTypes.length">
              <option
                *ngFor="
                  let zeehavenIJmuidenVisitMovementType of zeehavenIJmuidenVisitMovementTypes
                "
                [ngValue]="zeehavenIJmuidenVisitMovementType"
              >
                {{
                  zeehavenIJmuidenVisitMovementType
                    | zeehavenIJmuidenVisitMovementType
                }}
              </option>
            </optgroup>
          </select>
        </div>
        <div *ngSwitchCase="'dateTime'">
          <temp-date-time-picker
            [(dateTime)]="attribute.value"
          ></temp-date-time-picker>
        </div>
        <div *ngSwitchCase="'boolean'" class="input-group">
          <div class="custom-control custom-checkbox">
            <input
              [(ngModel)]="attribute.value"
              [id]="attribute.name"
              [name]="attribute.name"
              type="checkbox"
              class="custom-control-input"
            />
            <label [for]="attribute.name" class="custom-control-label">
              {{
                'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.placeholder.' +
                  attribute.name | translate
              }}
            </label>
          </div>
        </div>
        <div *ngSwitchCase="'textarea'" class="input-group">
          <textarea
            [(ngModel)]="attribute.value"
            [id]="attribute.name"
            [name]="attribute.name"
            [required]="attribute.required"
            [placeholder]="
              'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.placeholder.' +
                attribute.name | translate
            "
            class="form-control"
          ></textarea>
        </div>
        <button
          *ngIf="attribute.suggestion"
          (click)="useSuggestion()"
          class="btn btn-sm btn-link"
        >
          {{
            'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.details.useSuggestion'
              | translate
          }}:
          <ng-container [ngSwitch]="attribute.type">
            <ng-container *ngSwitchCase="'text'">{{
              attribute.suggestion
            }}</ng-container>
            <ng-container *ngSwitchCase="'movementType'">{{
              attribute.suggestion | zeehavenIJmuidenVisitMovementType
            }}</ng-container>
            <ng-container *ngSwitchCase="'dateTime'">{{
              attribute.suggestion | date: 'dd-MM-yyyy HH:mm'
            }}</ng-container>
          </ng-container>
        </button>
      </ng-container>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button closeModal type="button" class="btn btn-link btn-block w-50">
    {{ 'shared.terms.cancel' | translate }}
  </button>
  <button
    (click)="onPickAttribute.emit(attribute)"
    [disabled]="attribute.required && !attribute.value"
    closeModal
    type="button"
    class="btn btn-success btn-block w-50"
  >
    {{ 'shared.terms.confirm' | translate }}
  </button>
</div>
