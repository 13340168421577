import { Output, Component, EventEmitter, Input } from '@angular/core';
import * as _ from 'underscore';
import { EventModel } from '../../../../shared/models/event.model';

@Component({
  selector: 'zeehaven-ijmuiden-overview-header',
  templateUrl: './zeehaven-ijmuiden-overview-header.component.html',
})
export class ZeehavenIJmuidenOverviewHeaderComponent {
  @Output() onEvent: EventEmitter<EventModel>;

  @Input() currentView = '';
  history: boolean;

  constructor() {
    this.onEvent = new EventEmitter<EventModel>();
    this.history = false;
  }

  switchView() {
    this.onEvent.emit({ action: 'viewChanged', data: this.currentView });
  }

  print() {
    window.print();
  }
}
