import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FieldDefinitionModel } from '@seahorse/domain';

@Component({
  selector: 'ca-custom-content-overview-header',
  templateUrl: 'custom-content-overview-header.component.html',
})
export class CustomContentOverviewHeaderComponent {
  @Input() dateFrom = null;
  @Input() dateUntil = null;
  @Input() filters = [];
  @Input() searchField: string = null;
  @Input() searchQuery: string = null;
  @Input() searchFields: FieldDefinitionModel[] = [];
  @Input() sortBy: string = null;
  @Input() sortOrder: string = null;
  @Input() sortFields: FieldDefinitionModel[] = [];
  @Input() searchResultCount: number = null;

  @Output() dateChanged = new EventEmitter<any>();
  @Output() filterChanged = new EventEmitter<string>();
  @Output() searchChanged = new EventEmitter<any>();
  @Output() sortChanged = new EventEmitter<any>();

  find() {
    this.searchChanged.emit({
      field: this.searchField,
      query: this.searchQuery,
    });
  }

  onDateChanged(event) {
    this.dateChanged.emit(event);
  }

  onSortChanged() {
    this.sortChanged.emit({ field: this.sortBy, order: this.sortOrder });
  }

  setSelectedFilter(event) {
    this.filterChanged.emit(event);
  }
}
