<table class="table table-clickable">
  <thead *ngIf="showHeader">
    <tr>
      <th scope="col">
        {{ 'companies.financial.model.debtorNumber' | translate }}
      </th>
      <th scope="col">
        {{ 'companies.financial.model.emailAddress' | translate }}
      </th>
      <th scope="col">{{ 'companies.financial.model.iban' | translate }}</th>
      <th scope="col"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let financial of financials">
      <td (click)="onEvent.emit({ action: 'editFinancial', data: financial })">
        {{ financial.debtorNumber }}
      </td>
      <td (click)="onEvent.emit({ action: 'editFinancial', data: financial })">
        {{ financial.emailAddress }}
      </td>
      <td (click)="onEvent.emit({ action: 'editFinancial', data: financial })">
        {{ financial.iban }}
      </td>
      <td class="btn-list">
        <div class="dropdown p-0">
          <button
            *hasPermission="'companies.company_financials_delete'"
            type="button"
            class="btn btn-light dropdown-toggle"
            id="rowMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis-v fa-fw"></i>
          </button>
          <div class="btn dropdown-menu" aria-labelledby="rowMenuButton">
            <button
              class="dropdown-item"
              *hasPermission="'companies.company_financials_delete'"
              (click)="
                onEvent.emit({ action: 'deleteFinancial', data: financial })
              "
            >
              <i class="fa-solid fa-trash fa-fw"></i>
              {{ 'shared.terms.delete' | translate }}
            </button>
          </div>
        </div>
      </td>
    </tr>
  </tbody>
</table>
