import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { DynamicsService } from '@seahorse/common';
import { EmptyPlaceholderComponent } from './empty-placeholder.component';

@Directive({
  selector: '[shEmptyPlaceholder]',
  standalone: true,
})
export class EmptyPlaceholderDirective {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private dynamicsService: DynamicsService
  ) {}

  @Input() shEmptyPlaceholderItems = 'attributes:2';

  @Input() set shEmptyPlaceholder(condition: boolean) {
    if (condition) {
      this.createPlaceholder();
    } else {
      this.show();
    }
  }

  private createPlaceholder() {
    this.viewContainer.clear();

    this.dynamicsService.createComponent(
      {
        component: EmptyPlaceholderComponent,
        props: {
          items: this.shEmptyPlaceholderItems,
        },
      },
      this.viewContainer
    );
  }

  private show() {
    this.viewContainer.clear();
    this.viewContainer.createEmbeddedView(this.templateRef);
  }
}
