<main class="page-with-header">
  <zeehaven-ijmuiden-overview-header
    (onEvent)="action($event)"
  ></zeehaven-ijmuiden-overview-header>
  <section class="container-fluid py-3">
    <ng-container
      *ngIf="loadingVisits || loadingMovements; then loadVisits; else showVisits"
    ></ng-container>
    <ng-template #showVisits>
      <div *ngIf="currentView !== ''">
        <div style="background-color: white">
          <zeehaven-ijmuiden-visit-list
            *ngIf="currentView === 'inPort'"
            [clickable]="false"
            [models]="visits.inPort"
            [visitDisplay]="'inPort'"
          ></zeehaven-ijmuiden-visit-list>
          <zeehaven-ijmuiden-visit-list
            *ngIf="currentView === 'incoming'"
            [models]="visits.incoming"
            [visitDisplay]="'incoming'"
            (onSelectVisit)="action($event)"
          ></zeehaven-ijmuiden-visit-list>
          <zeehaven-ijmuiden-visit-list
            *ngIf="currentView === 'shifting'"
            [models]="visits.shifting"
            [visitDisplay]="'shifting'"
            (onSelectVisit)="action($event)"
          ></zeehaven-ijmuiden-visit-list>
          <zeehaven-ijmuiden-visit-list
            *ngIf="currentView === 'outgoing'"
            [models]="visits.outgoing"
            [visitDisplay]="'outgoing'"
            (onSelectVisit)="action($event)"
          ></zeehaven-ijmuiden-visit-list>
        </div>
      </div>
      <div class="row my-n2" *ngIf="currentView === ''">
        <div class="col-6 py-2">
          <div class="card">
            <div class="card-header">
              {{
              'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.overview.model.inPort'
              | translate }}
            </div>
            <div class="card-body card-body-table zeehavenCardOverflow">
              <zeehaven-ijmuiden-visit-list
                [clickable]="false"
                [models]="visits.inPort"
                [visitDisplay]="'inPort'"
              >
              </zeehaven-ijmuiden-visit-list>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6 py-2">
          <div class="card">
            <div class="card-header">
              <i class="fa-solid fa-long-arrow-alt-down fa-fw ml-n2"></i>
              {{
              'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.overview.model.incoming'
              | translate }}
              <div class="btn-list">
                <button
                  (click)="openShipPickerModal()"
                  class="btn btn-outline-secondary btn-sm"
                  type="button"
                >
                  <i class="fa-regular fa-plus fa-fw"></i>
                  {{ 'shared.terms.add' | translate }}
                </button>
              </div>
            </div>
            <div class="card-body card-body-table zeehavenCardOverflow">
              <zeehaven-ijmuiden-visit-list
                [models]="visits.incoming"
                [visitDisplay]="'incoming'"
                (onSelectVisit)="action($event)"
              ></zeehaven-ijmuiden-visit-list>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6 py-2">
          <div class="card">
            <div class="card-header">
              <i class="fa-regular fa-exchange-alt fa-fw ml-n2"></i>
              {{
              'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.overview.model.shifting'
              | translate }}
              <div class="btn-list">
                <button
                  (click)="openInPortLisPicker(2)"
                  class="btn btn-outline-secondary btn-sm"
                  type="button"
                >
                  <i class="fa-regular fa-plus fa-fw"></i>
                  {{ 'shared.terms.add' | translate }}
                </button>
              </div>
            </div>
            <div class="card-body card-body-table zeehavenCardOverflow">
              <zeehaven-ijmuiden-visit-list
                [models]="visits.shifting"
                [visitDisplay]="'shifting'"
                (onSelectVisit)="action($event)"
              ></zeehaven-ijmuiden-visit-list>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6 py-2">
          <div class="card">
            <div class="card-header">
              <i class="fa-solid fa-long-arrow-alt-up fa-fw ml-n2"></i>
              {{
              'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.overview.model.outgoing'
              | translate }}
              <div class="btn-list">
                <button
                  (click)="openInPortLisPicker(3)"
                  class="btn btn-outline-secondary btn-sm"
                  type="button"
                >
                  <i class="fa-regular fa-plus fa-fw"></i>
                  {{ 'shared.terms.add' | translate }}
                </button>
              </div>
            </div>
            <div class="card-body card-body-table zeehavenCardOverflow">
              <zeehaven-ijmuiden-visit-list
                [models]="visits.outgoing"
                [visitDisplay]="'outgoing'"
                (onSelectVisit)="action($event)"
              ></zeehaven-ijmuiden-visit-list>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #loadVisits>
      <sh-loading-indicator
        class="d-block text-center"
      ></sh-loading-indicator>
    </ng-template>
  </section>
</main>
