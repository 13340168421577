import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationService } from '@seahorse/common';
import { SeahorseFormBuilder, SeahorseFormsModule } from '@seahorse/forms';
import { ModalAction, ModalService } from '@seahorse/temp';
import { EMPTY, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { UserAccountService } from '../../../core/account/services/user-account.service';
import { MfaChallengeComponent } from '../mfa-challenge/mfa-challenge.component';

@Component({
  selector: 'ca-identity-challenge',
  templateUrl: './identity-challenge.component.html',
  standalone: true,
  imports: [
    CommonModule,
    SeahorseFormsModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
})
export class IdentityChallengeComponent {
  passwordControl = inject(SeahorseFormBuilder).control<string | null>(
    null,
    Validators.required
  );

  private _userAccountService = inject(UserAccountService);
  private _notificationService = inject(NotificationService);
  private _modalService = inject(ModalService);

  submitChallenge() {
    if (this.passwordControl.invalid) {
      return EMPTY;
    }

    const password = this.passwordControl.value;

    return this._userAccountService.passwordChallenge({ password }).pipe(
      mergeMap((response) => {
        if (!response.hasResult) {
          this._notificationService.showError('shared.terms.error');
          return of(false);
        }

        const { isSuccess, isMfaRequired } = response.result;

        if (!isSuccess) {
          this._notificationService.showError(
            'account.challenge.invalidPassword'
          );
          return of(false);
        }

        if (!isMfaRequired) {
          return of(true);
        }

        return this.showMfaChallenge();
      })
    );
  }

  private showMfaChallenge() {
    return this._modalService
      .openComponentModal<MfaChallengeComponent>(
        {
          component: MfaChallengeComponent,
        },
        'user.mfa.twofactor'
      )
      .pipe(
        mergeMap((result) => {
          if (result.action === ModalAction.Close) {
            return of(false);
          }

          return result.componentRef.submitChallenge();
        })
      );
  }
}
