import { Input, OnInit, Component, inject, ViewChild } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'underscore';
import { NotificationService } from '@seahorse/common';
import { EventModel } from '../../../shared/models/event.model';
import { AreYouSureModalComponent } from '@seahorse/ui';
import { ExpensesDataService } from '@seahorse/domain';
import { ExpenseModel, OrderRegistrationModel } from '@seahorse/domain';
import { PortCallFileModel } from '../../../port-call-file/models/port-call-file.model';
import { ExpenseFormComponent } from '../expense-form/expense-form.component';

@Component({ templateUrl: './expense-dialog.component.html' })
export class ExpenseDialogComponent implements OnInit {
  @ViewChild('expenseForm') expenseForm: ExpenseFormComponent;

  @Input() title: string;
  @Input() expenseId: ExpenseModel['__Id'];
  @Input() expense: ExpenseModel;
  @Input() orderRegistrations: OrderRegistrationModel[];
  @Input() get portCallFile(): PortCallFileModel['__Id'] | undefined {
    return this.expense.portCallFile;
  }
  set portCallFile(portCallFileInput: PortCallFileModel['__Id'] | undefined) {
    if (portCallFileInput) this.expense.portCallFile = portCallFileInput;
  }
  @Input() newExpense: boolean;
  @Input() skipService: boolean;
  @Input() hideTariffPicker: boolean;
  @Input() hideCommissionFee: boolean;
  @Input() hideOrderRegistrationPicker: boolean;
  @Input() loadingOrderRegistrations: boolean;

  loading: boolean;

  private activeModal = inject(NgbActiveModal);

  constructor(
    private modal: NgbModal,
    private translate: TranslateService,
    private notification: NotificationService,
    private expensesData: ExpensesDataService
  ) {
    this.title = this.translate.instant(
      'customModules.expenses.expenseDialogTitle'
    );
    this.expense = new ExpenseModel();
    this.orderRegistrations = [];
    this.newExpense = false;
    this.skipService = false;
    this.hideTariffPicker = false;
    this.hideCommissionFee = false;
    this.hideOrderRegistrationPicker = false;
    this.loadingOrderRegistrations = false;
    this.loading = true;
  }

  ngOnInit() {
    if (this.expenseId) {
      this.expensesData.getById(this.expenseId).subscribe((result) => {
        this.expense = result.result[0];
        this.loading = false;
      });
    } else {
      this.loading = false;
    }
  }

  addExpense() {
    if (!this.skipService) {
      this.loading = true;

      this.expensesData.add([this.expense]).subscribe(
        (r) => {
          if (r.result && r.result.length > 0) {
            this.notification.showSuccess(
              this.translate.instant('customModules.expenses.expenseAdded'),
              this.translate.instant('shared.terms.success')
            );
            this.activeModal.close(
              new EventModel({ action: 'added', data: r.result[0] })
            );
          } else {
            this.notification.showError(
              _.pluck(r.messages, 'message').join('\n'),
              this.translate.instant('shared.terms.failed')
            );
          }
        },

        (e) => {
          this.notification.showError(
            _.pluck(e.error.messages, 'message').join('\n'),
            this.translate.instant('shared.terms.failed')
          );
        },

        () => (this.loading = false)
      );
    } else {
      this.activeModal.close(
        new EventModel({ action: 'addPromise', data: this.expense })
      );
    }
  }

  updateExpense() {
    if (!this.skipService) {
      this.loading = true;

      this.expensesData.update([this.expense]).subscribe(
        (r) => {
          if (r.result) {
            this.notification.showSuccess(
              this.translate.instant('customModules.expenses.expenseUpdated'),
              this.translate.instant('shared.terms.success')
            );

            this.activeModal.close(
              new EventModel({ action: 'updated', data: r.result[0] })
            );
          } else {
            this.notification.showError(
              _.pluck(r.messages, 'message').join('\n'),
              this.translate.instant('shared.terms.failed')
            );
          }
        },

        (e) => {
          this.notification.showError(
            _.pluck(e.error.messages, 'message').join('\n'),
            this.translate.instant('shared.terms.failed')
          );
        },

        () => (this.loading = false)
      );
    } else {
      this.activeModal.close(
        new EventModel({ action: 'updatePromise', data: this.expense })
      );
    }
  }

  deleteExpense() {
    const areYouSureModal = this.modal.open(AreYouSureModalComponent, {
      backdrop: 'static',
    });

    areYouSureModal.result.then((areYouSure) => {
      if (areYouSure) {
        if (!this.skipService) {
          this.loading = true;

          this.expensesData.delete([this.expense.__Id]).subscribe(
            (r) => {
              if (_.first(r.result)) {
                this.notification.showSuccess(
                  this.translate.instant(
                    'customModules.expenses.expenseDeleted'
                  ),
                  this.translate.instant('shared.terms.success')
                );

                this.activeModal.close(
                  new EventModel({
                    action: 'deleted',
                    data: _.first(r.result),
                  })
                );
              } else {
                this.notification.showError(
                  _.pluck(r.messages, 'message').join('\n'),
                  this.translate.instant('shared.terms.failed')
                );
              }
            },

            (e) => {
              this.notification.showError(
                _.pluck(e.error.messages, 'message').join('\n'),
                this.translate.instant('shared.terms.failed')
              );
            },

            () => (this.loading = false)
          );
        } else {
          this.activeModal.close(
            new EventModel({ action: 'deletePromise', data: this.expense })
          );
        }
      }
    });
  }

  confirmExpense() {
    const expenseFormValid = this.expenseForm.validate();
    if (!expenseFormValid) {
      this.loading = false;
      return;
    }

    this.expense = this.expenseForm.expense;
    this.expense.__Id ? this.updateExpense() : this.addExpense();
  }

  dismissDialog() {
    this.activeModal.dismiss();
  }
}
