<div class="page-with-master-detail">
  <div class="container-fluid py-3">
    <div class="row">
      <div class="col-md-12 col-lg-6 mb-4">
        <div class="card">
          <div class="card-header">
            <span>{{
              'account.register.permissions.wizardTitle' | translate
            }}</span>
          </div>

          <div class="card-body">
            <h5>{{ 'account.register.permissions.wizardText' | translate }}</h5>

            <form>
              <div
                class="row"
                *ngFor="let permission of permissions; let i = index"
              >
                <div class="col-xs-12 col-sm-12 col-md-8">
                  <div class="form-group input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text">
                        <input
                          type="checkbox"
                          class="cursor-pointer"
                          id="permission{{ i }}"
                          name="permission{{ i }}"
                          [(ngModel)]="permission.checked"
                        />
                      </div>
                    </div>
                    <label
                      class="form-control cursor-pointer"
                      for="permission{{ i }}"
                      title="{{ permission.label }}"
                      >{{ permission.label | translate }}</label
                    >
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-8">
                  <label for="seedOrganisations">
                    {{
                      'account.register.permissions.seedOrganisations'
                        | translate
                    }}
                  </label>
                  <ng-select
                    [items]="seedOrganizations"
                    bindLabel="name"
                    bindValue="id"
                    [(ngModel)]="selectedOrganisationId"
                    name="selectedOrganisation"
                    placeholder="{{ 'shared.terms.select' | translate }}"
                  >
                  </ng-select>
                </div>
              </div>

              <div class="d-flex justify-content-end align-items-center">
                <button
                  [disabled]="isLoading"
                  type="button"
                  class="btn btn-success"
                  (click)="save()"
                >
                  {{ 'shared.terms.save' | translate }}
                </button>
              </div>

              <div class="d-flex justify-content-end align-items-center mt-2">
                <sh-loading-indicator
                  class="text-center loader-sm mr-2"
                  *ngIf="isLoading"
                ></sh-loading-indicator>
              </div>
            </form>
          </div>
          <div *ngIf="somethingWentWrong" class="card-footer">
            {{ 'account.onboarding.somethingWentWrong' | translate }}
          </div>
        </div>
      </div>
      <div [class.d-none]="!hasStartedCreatingEnvironment" class="col">
        <ca-create-environment
          [organisationId]="selectedOrganisationId"
        ></ca-create-environment>
      </div>
    </div>
  </div>
</div>
