import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'underscore';
import { ResultWrapper } from '@seahorse/common';
import { NotificationService } from '@seahorse/common';
import { OrderRegistrationsConstant } from '../../../order-registrations/constants/order-registrations.constant';
import { OrderRegistrationPartModel } from '../../../order-registrations/models/order-registration-part.model';
import { CustomDataContentService } from '@seahorse/domain';

@Component({ templateUrl: 'part-dialog.component.html' })
export class PartDialogComponent implements OnInit {
  @Input() title?: string;
  @Input() quantity?: number;

  @Input() set parts(parts: OrderRegistrationPartModel[]) {
    if (parts && parts.length) {
      this._parts = _.clone(parts);
      this.currentPartsIds = _.compact(_.pluck(_.clone(parts), '__Id'));
    } else {
      this._parts = [];
    }
  }
  get parts(): OrderRegistrationPartModel[] {
    return this._parts;
  }

  @Input() set partsIds(ids: OrderRegistrationPartModel['__Id'][]) {
    if (ids && ids.length) {
      this._partsIds = _.compact(_.clone(ids));
      this.partsLoading = true;

      this.customData
        .getCustomerDataByIds(OrderRegistrationsConstant.OBJECTKEYPART, ids)
        .subscribe(
          (r: ResultWrapper<OrderRegistrationPartModel[]>) => {
            if (r.hasResult && r.result.length) {
              this.parts = r.result;
              this.currentPartsIds = _.pluck(r.result, '__Id');
            } else {
              this.notification.showError(
                _.pluck(r.messages, 'message').join('\n'),
                this.translate.instant('shared.terms.failed')
              );
            }
          },

          (e) => {
            this.notification.showError(
              _.pluck(e.error.messages, 'message').join('\n'),
              this.translate.instant('shared.terms.failed')
            );
          },

          () => (this.partsLoading = false)
        );
    } else {
      this._partsIds = [];
    }
  }
  get partsIds(): OrderRegistrationPartModel['__Id'][] {
    return this._partsIds;
  }

  @Output() onConfirm: EventEmitter<{ parts: OrderRegistrationPartModel[] }>;

  currentPartsIds: OrderRegistrationPartModel['__Id'][];
  partsLoading: boolean;
  errorList: string[];

  private _parts: OrderRegistrationPartModel[];
  private _partsIds: OrderRegistrationPartModel['__Id'][];

  constructor(
    private activeModal: NgbActiveModal,
    private translate: TranslateService,
    private notification: NotificationService,
    private customData: CustomDataContentService
  ) {
    this.title = this.translate.instant(
      'customModules.orderRegistrations.part.factoryDialogTitle'
    );
    this.quantity = 0;
    this.parts = [];
    this.partsIds = [];
    this.currentPartsIds = [];
    this.partsLoading = false;
    this.errorList = [];
    this.onConfirm = new EventEmitter<{
      parts: OrderRegistrationPartModel[];
    }>();
    this.partsLoading = false;
    this._parts = [];
    this._partsIds = [];
  }

  ngOnInit() {
    if (this.quantity && this.quantity > this.parts.length) {
      new Promise<void>((res) => {
        this.parts.push(new OrderRegistrationPartModel(this.quantity));
        setTimeout(() => res(), 1);
      }).then(
        () =>
          new Promise(() => {
            (<HTMLElement>(
              document.getElementById(
                `sectionLinkId_${_.findLastIndex(this.parts)}`
              )
            )).click();
          })
      );
    }
  }

  addPart() {
    new Promise<void>((res) => {
      this.parts.push(new OrderRegistrationPartModel(1));
      setTimeout(() => res(), 1);
    }).then(
      () =>
        new Promise(() => {
          (<HTMLElement>(
            document.getElementById(
              `sectionLinkId_${_.findLastIndex(this.parts)}`
            )
          )).click();
        })
    );
  }

  removePart(part: OrderRegistrationPartModel) {
    this.parts = _.without(this.parts, part);

    if (part.__Id) {
      this.partsIds = _.without(this.partsIds, part.__Id);
    }
  }

  confirm() {
    this.errorList = [];

    const messageLists: ResultWrapper<any>['messages'][][] = [];
    const partsUpdated: OrderRegistrationPartModel[] = [];
    let partUpdateFailCount = 0;
    const partsAdded: OrderRegistrationPartModel[] = [];
    let partAddFailCount = 0;
    let partDeletedCount = 0;
    let partDeleteFailCount = 0;

    new Promise<void>((err) => {
      if (this.parts.length) {
        let i = 0;

        _.forEach(this.parts, (part) => {
          i++;

          if (part) {
            if (!part.name) {
              this.errorList.push(
                this.translate.instant(
                  'customModules.orderRegistrations.part.error.nameMissing'
                )
              );
            }

            if (!part.quantity) {
              this.errorList.push(
                this.translate.instant(
                  'customModules.orderRegistrations.part.error.quantityMissing'
                )
              );
            }
          } else {
            this.errorList.push(
              this.translate.instant(
                'customModules.orderRegistrations.part.error.partMissing'
              )
            );
          }

          if (this.parts.length == i && this.errorList.length == 0) {
            err();
          }
        });
      } else {
        err();
      }
    }).then(() =>
      new Promise<void>((upd) => {
        this.partsLoading = true;

        const partUpdates = _.reject(this.parts, (x) => !x.__Id);

        if (partUpdates.length) {
          _.forEach(partUpdates, (partUpdate) => {
            let updMessages: ResultWrapper<any>['messages'][] = [];

            this.customData
              .updateCustomerData(
                OrderRegistrationsConstant.OBJECTKEYPART,
                partUpdate.__Id,
                partUpdate
              )
              .subscribe(
                (updR: ResultWrapper<OrderRegistrationPartModel>) => {
                  if (updR.hasResult) {
                    partsUpdated.push(updR.result);
                  } else {
                    updMessages = updMessages.concat(updR.messages);

                    partUpdateFailCount++;
                  }
                },

                (updE) => {
                  updMessages = updMessages.concat(updE.error.messages);

                  partUpdateFailCount++;
                },

                () => {
                  messageLists.push(updMessages);

                  if (
                    partsUpdated.length + partUpdateFailCount ==
                    partUpdates.length
                  ) {
                    upd();
                  }
                }
              );
          });
        } else {
          upd();
        }
      }).then(() =>
        new Promise<void>((add) => {
          const partAdds = _.filter(this.parts, (x) => !x.__Id);

          if (partAdds.length) {
            _.forEach(partAdds, (partAdd) => {
              let addMessages: ResultWrapper<any>['messages'][] = [];

              this.customData
                .addCustomerData(
                  OrderRegistrationsConstant.OBJECTKEYPART,
                  partAdd
                )
                .subscribe(
                  (addR: ResultWrapper<OrderRegistrationPartModel>) => {
                    if (addR.hasResult) {
                      partsAdded.push(addR.result);
                    } else {
                      addMessages = addMessages.concat(addR.messages);

                      partAddFailCount++;
                    }
                  },

                  (addE) => {
                    addMessages = addMessages.concat(addE.error.messages);

                    partAddFailCount++;
                  },

                  () => {
                    messageLists.push(addMessages);

                    if (
                      partsAdded.length + partAddFailCount ==
                      partAdds.length
                    ) {
                      add();
                    }
                  }
                );
            });
          } else {
            add();
          }
        }).then(() =>
          new Promise<void>((del) => {
            const partsIdDeletes = _.compact(
              _.difference(this.currentPartsIds, this.partsIds)
            );

            if (partsIdDeletes.length) {
              _.forEach(partsIdDeletes, (partsIdDelete) => {
                let delMessages: ResultWrapper<any>['messages'][] = [];

                this.customData
                  .deleteCustomerData(
                    OrderRegistrationsConstant.OBJECTKEYPART,
                    partsIdDelete
                  )
                  .subscribe(
                    (delR: ResultWrapper<OrderRegistrationPartModel>) => {
                      if (delR.hasResult) {
                        partDeletedCount++;
                      } else {
                        delMessages = delMessages.concat(delR.messages);

                        partDeleteFailCount++;
                      }
                    },

                    (delE) => {
                      delMessages = delMessages.concat(delE.error.messages);

                      partDeleteFailCount++;
                    },

                    () => {
                      messageLists.push(delMessages);

                      if (
                        partDeletedCount + partDeleteFailCount ==
                        partsIdDeletes.length
                      ) {
                        del();
                      }
                    }
                  );
              });
            } else {
              del();
            }
          }).then(
            () =>
              new Promise(() => {
                this.partsLoading = false;

                const message = `${
                  partsUpdated.length + partsAdded.length + partDeletedCount
                }/${
                  this.parts.length +
                  _.compact(_.difference(this.currentPartsIds, this.partsIds))
                    .length
                } ${this.translate.instant(
                  'customModules.orderRegistrations.part.partsUpdated'
                )}`;

                if (
                  partUpdateFailCount +
                  partAddFailCount +
                  partDeleteFailCount
                ) {
                  this.notification.showWarning(
                    message,
                    this.translate.instant('shared.terms.failed')
                  );

                  _.forEach(messageLists, (messageList) => {
                    this.notification.showError(
                      _.pluck(messageList, 'message').join('\n')
                    );
                  });
                } else {
                  this.notification.showSuccess(
                    message,
                    this.translate.instant('shared.terms.success')
                  );
                }

                this.activeModal.close(
                  this.onConfirm.emit({
                    parts: _.union(partsUpdated, partsAdded),
                  })
                );
              })
          )
        )
      )
    );
  }
}
