export enum ArrayOperators {
  Any = 1,
  All = 2,
  NotAny = 3,
  NotAll = 4,
}

export enum BooleanOperators {
  True = 1,
  False = 2,
}

export enum ConditionScheduleTypes {
  DateTime = 0,
  Date = 1,
  Day = 2,
  Time = 3,
  TimeOfDay = 4,
  Holiday = 5,
}

export enum DateOperators {
  Between = 1,
  EqualsDate = 2,
  EqualsDateTime = 3,
  GreaterThan = 4,
  SmallerThan = 5,
}

export enum NumericOperators {
  Between = 1,
  Equals = 2,
  GreaterThan = 3,
  NotBetween = 4,
  SmallerThan = 5,
}

export enum ScheduleOperators {
  OnSchedule = 1,
  OutsideSchedule = 2,
}

export enum StringOperators {
  Contains = 1,
  Equals = 2,
  GreaterThan = 3,
  SmallerThan = 4,
  NotEmpty = 5,
  IsEmpty = 6,
}

export enum TimespanOperators {
  Equals = 1,
  GreaterThan = 2,
  SmallerThan = 3,
}
