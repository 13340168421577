import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ActivateAccountComponent } from './core/account/components/activate-account/activate-account.component';
import { CustomDataDefinitionComponent } from './core/account/components/custom-data-definition/custom-data-definition.component';
import { InvoicingSetupComponent } from './core/account/components/invoicing-setup/invoicing-setup.component';
import { LoginIdentityProvidersComponent } from './core/account/components/login-identity-providers/login-identity-providers.component';
import { LoginComponent } from './core/account/components/login.component';
import { RegisterAccountThankYouComponent } from './core/account/components/register-account-thank-you/register-account-thank-you.component';
import { RegisterAccountComponent } from './core/account/components/register-account/register-account.component';
import { ResetPasswordComponent } from './core/account/components/reset-password/reset-password.component';
import { SetPermissionsComponent } from './core/account/components/set-permissions/set-permissions.component';
import { OnboardingGuardService } from './core/services/onboarding-guard.service';
import { NotAuthorizedComponent } from './layout/components/not-authorized/not-authorized.component';
import { NotFoundComponent } from './layout/components/not-found/not-found.component';
import { DefaultThemeComponent } from './themes/components/default-theme/default-theme.component';
import { CustomDashboardComponent } from './dashboard/components/custom-dashboard/custom-dashboard.component';
import { CompanyCustomDashboardComponent } from './dashboard/components/company-custom-dashboard/company-custom-dashboard.component';
import { DashboardResolver } from './dashboard/dashboard.resolver';
import { authGuard, loginGuard, permissionsGuard } from '@seahorse/auth';

//import { DashboardComponent } from './dashboard/components/dashboard.component';
const routes: Routes = [
  {
    path: 'account/login',
    component: LoginComponent,
    canActivate: [loginGuard],
  },
  {
    path: 'account/login/external',
    component: LoginIdentityProvidersComponent,
  },
  {
    path: 'account/reset-password',
    component: ResetPasswordComponent,
  },
  {
    path: 'account/register',
    component: RegisterAccountComponent,
    canActivate: [loginGuard],
  },
  {
    path: 'account/register/thank-you',
    component: RegisterAccountThankYouComponent,
    canActivate: [loginGuard],
  },
  {
    path: 'account/activate',
    component: ActivateAccountComponent,
    canActivate: [loginGuard],
  },
  {
    path: '',
    component: DefaultThemeComponent,
    canActivate: [authGuard],
    children: [
      {
        path: 'onboarding/permissions',
        component: SetPermissionsComponent,
        canActivate: [permissionsGuard, OnboardingGuardService],
      },
      {
        path: 'onboarding/customdata',
        component: CustomDataDefinitionComponent,
        canActivate: [permissionsGuard, OnboardingGuardService],
      },
      {
        path: 'onboarding/invoicing',
        component: InvoicingSetupComponent,
        canActivate: [permissionsGuard, OnboardingGuardService],
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
        canActivate: [permissionsGuard],
        resolve: { isCustomDashboard: DashboardResolver },
      },
      {
        path: 'custom-dashboard',
        component: CustomDashboardComponent,
        canActivate: [permissionsGuard],
        data: {
          ui: {
            headerTitle: 'Dashboard',
          },
        },
      },
      {
        path: 'company-custom-dashboard',
        component: CompanyCustomDashboardComponent,
        data: {
          ui: {
            headerTitle: 'Company custom dashboard',
          },
        },
        canActivate: [permissionsGuard],
      },
      {
        path: 'companies',
        loadChildren: () =>
          import('./company/company.module').then((m) => m.CompanyModule),
        canActivate: [permissionsGuard],
        data: { allowedPermissions: 'companies' },
      },
      {
        path: 'custom-ui',
        loadChildren: () =>
          import('./custom-ui/custom-ui.module').then((m) => m.CustomUIModule),
        canActivate: [permissionsGuard],
      },
      {
        path: 'su/contentdistribution',
        loadChildren: () =>
          import('./content-distribution/content-distribution.module').then(
            (m) => m.ContentDistributionModule
          ),
        canActivate: [permissionsGuard],
        data: { isSuperUserPage: true },
      },
      {
        path: 'invoicing-and-tariffs',
        loadChildren: () =>
          import('./invoicing-and-tariffs/invoicing-and-tariffs.module').then(
            (m) => m.InvoicingAndTariffsModule
          ),
        canActivate: [permissionsGuard],
        data: { allowedPermissions: 'invoices' },
      },
      {
        path: 'map',
        loadChildren: () => import('./map/map.module').then((m) => m.MapModule),
        canActivate: [permissionsGuard],
        data: { allowedPermissions: 'map' },
      },
      {
        path: 'nautical/vessels',
        loadChildren: () =>
          import('./nautical-ship/nautical-ship.module').then(
            (m) => m.NauticalShipModule
          ),
        canActivate: [permissionsGuard],
        data: {
          allowedPermissions: 'ships',
        },
      },
      {
        path: 'nautical/visit',
        loadChildren: () =>
          import('./nautical-visit/nautical-visit.module').then(
            (m) => m.NauticalVisitModule
          ),
        canActivate: [permissionsGuard],
        data: { allowedPermissions: 'port_visits' },
      },
      {
        path: 'reports',
        loadChildren: () =>
          import('./reports/reports.module').then((m) => m.ReportsModule),
        canActivate: [permissionsGuard],
        data: {
          allowedPermissions:
            'reports.reports_nautical,reports.reports_financial,user_management.reports',
        },
      },
      {
        path: 'support',
        loadChildren: () =>
          import('./support/support.module').then((m) => m.SupportModule),
        canActivate: [permissionsGuard],
      },
      {
        path: 'su',
        loadChildren: () =>
          import('./superuser-nautical/superuser-nautical.module').then(
            (m) => m.SuperuserNauticalModule
          ),
        canActivate: [permissionsGuard],
      },
      {
        path: 'port-call-file',
        loadChildren: () =>
          import('./port-call-file/port-call-file.module').then(
            (m) => m.PortCallFileModule
          ),
        canActivate: [permissionsGuard],
        data: {
          allowedPermissions: 'custom_ui.port_call_file',
          redirect: 'dashboard',
        },
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./user/user.module').then((m) => m.UserModule),
        canActivate: [permissionsGuard],
      },
      {
        path: 'user-management',
        loadChildren: () =>
          import('./user-management/user-management.module').then(
            (m) => m.UserManagementModule
          ),
        canActivate: [permissionsGuard],
      },
      {
        path: 'task-definition-list',
        loadChildren: () =>
          import('./task-definition-list/task-definition-list.module').then(
            (m) => m.TaskDefinitionListModule
          ),
        canActivate: [permissionsGuard],
      },
      {
        path: 'task-group',
        loadChildren: () =>
          import('./task-group/task-group.module').then(
            (m) => m.TaskGroupModule
          ),
        canActivate: [permissionsGuard],
      },
      {
        path: 'tasks',
        loadChildren: () =>
          import('./tasks/tasks.module').then((m) => m.TasksModule),
        canActivate: [permissionsGuard],
        data: { allowedPermissions: 'tasks' },
      },
      {
        path: 'customer/customdata',
        loadChildren: () =>
          import('./custom-data/custom-data.module').then(
            (m) => m.CustomDataModule
          ),
        canActivate: [permissionsGuard],
        data: {
          allowedPermissions: 'custom_data.custom_data_admin',
        },
      },
      {
        path: 'customerportal',
        loadChildren: () =>
          import('./customer-portal/customer-portal.module').then(
            (m) => m.CustomerPortalModule
          ),
        canActivate: [permissionsGuard],
        data: {
          allowedPermissions: 'customerportal.customerportal_admin',
        },
      },
      {
        path: 'contentdistribution',
        loadChildren: () =>
          import('./content-distribution/content-distribution.module').then(
            (m) => m.ContentDistributionModule
          ),
        canActivate: [permissionsGuard],
        data: { isSuperUserPage: false },
      },
      {
        path: 'storage',
        loadChildren: () =>
          import('./custom-content/custom-content.module').then(
            (m) => m.CustomContentModule
          ),
        canActivate: [permissionsGuard],
        data: { allowedPermissions: 'custom_data.custom_data_review' },
      },
      {
        path: 'bts-coasting',
        loadChildren: () =>
          import('./bts-coasting/bts-coasting.module').then(
            (m) => m.BtsCoastingModule
          ),
        canActivate: [permissionsGuard],
        canActivateChild: [permissionsGuard],
        data: {
          allowedPermissions: 'bts',
          redirect: 'dashboard',
        },
      },
      {
        path: 'order-registrations',
        loadChildren: () =>
          import('./order-registrations/order-registrations.module').then(
            (m) => m.OrderRegistrationsModule
          ),
        canActivate: [permissionsGuard],
        data: { allowedPermissions: 'custom_ui.order_registrations' },
      },
      {
        path: 'release-notes',
        loadChildren: () =>
          import('./preference/preference.module').then(
            (m) => m.PreferenceModule
          ),
        canActivate: [permissionsGuard],
      },
      {
        path: 'su/service-connections',
        loadChildren: () =>
          import('./service-connections/service-connections.module').then(
            (m) => m.ServiceConnectionsModule
          ),
        canActivate: [permissionsGuard],
      },
      {
        path: 'service-connections',
        loadChildren: () =>
          import('./service-connections/service-connections.module').then(
            (m) => m.ServiceConnectionsModule
          ),
        canActivate: [permissionsGuard],
      },
      {
        path: 'messages',
        loadChildren: () =>
          import('./messages/messages.module').then((m) => m.MessagesModule),
        canActivate: [permissionsGuard],
      },
      {
        path: 'admin',
        loadChildren: () =>
          import('./admin/admin.module').then((m) => m.AdminModule),
        canActivate: [permissionsGuard],
        data: {
          // tslint:disable-next-line: max-line-length
          allowedPermissions:
            'invoices.invoices_configure, custom_data.custom_data_admin, reports_customdata_admin, user_management.reports',
        },
      },
      {
        path: 'module',
        loadChildren: () =>
          import('./custom-modules/custom.module').then((m) => m.CustomModule),
      },
      {
        path: 'su/modules',
        loadChildren: () =>
          import('./custom-modules/custom.module').then((m) => m.CustomModule),
        canActivate: [permissionsGuard],
        data: {
          allowedPermissions: 'superusers.superuser',
        },
      },
      {
        path: 'su/widgets',
        loadChildren: () =>
          import('./widgets/widgets.module').then((m) => m.WidgetsModule),
        canActivate: [permissionsGuard],
        data: {
          allowedPermissions: 'superusers.superuser',
        },
      },
      {
        path: 'jobs',
        loadChildren: () =>
          import('./jobs/jobs.module').then((m) => m.JobsModule),
      },
      {
        path: 'subscriptions',
        loadChildren: () =>
          import('./subscriptions/subscriptions.module').then(
            (m) => m.SubscriptionsModule
          ),
        canActivate: [permissionsGuard],
        data: {
          allowedPermissions: 'general.manage_events',
        },
      },
      // Service routes
      {
        path: 'not-authorized',
        component: NotAuthorizedComponent,
      },
      {
        path: 'not-found',
        component: NotFoundComponent,
        data: {
          message: 'Page not found',
        },
      },
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: '**',
        redirectTo: 'dashboard',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class CustomerAppRoutingModule {}
