import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nauticalMovementType',
})
export class NauticalMovementTypePipe implements PipeTransform {
  transform(value: number): string {
    switch (value) {
      case 1:
        return 'incoming';
      case 2:
        return 'shift';
      case 3:
        return 'outgoing';
      case 4:
        return 'turnaround';
      case 5:
        return 'passthrough';
      default:
        return 'unknown';
    }
  }
}
