<div class="modal-header">
  <div class="modal-title">{{ title }}</div>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="modal.dismiss()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form>
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label
            >{{
              'customers.cvv.shipRequirements.personsLabel' | translate
            }}:</label
          >
          <div class="row">
            <div class="col pr-0">
              <input
                type="text"
                class="form-control"
                value="0"
                title="{{
                  'customers.cvv.shipRequirements.normal' | translate
                }}"
                name="shipRequirementsPersonsMiddle"
                [(ngModel)]="persons.middle"
              />
            </div>
            <div class="col p-0">
              <input
                type="text"
                class="form-control"
                value="0"
                title="{{ 'customers.cvv.shipRequirements.front' | translate }}"
                name="shipRequirementsPersonsFront"
                [(ngModel)]="persons.front"
              />
            </div>
            <div class="col pl-0 pr-3">
              <input
                type="text"
                class="form-control"
                value="0"
                title="{{ 'customers.cvv.shipRequirements.after' | translate }}"
                name="shipRequirementsPersonsAft"
                [(ngModel)]="persons.after"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label
            >{{
              'customers.cvv.shipRequirements.winchesLabel' | translate
            }}:</label
          >
          <div class="row">
            <div class="col pr-0">
              <input
                type="text"
                class="form-control"
                value="0"
                title="{{
                  'customers.cvv.shipRequirements.normal' | translate
                }}"
                name="shipRequirementsWinchMiddle"
                [(ngModel)]="winch.middle"
              />
            </div>
            <div class="col p-0">
              <input
                type="text"
                class="form-control"
                value="0"
                title="{{ 'customers.cvv.shipRequirements.front' | translate }}"
                name="shipRequirementsWinchFront"
                [(ngModel)]="winch.front"
              />
            </div>
            <div class="col pl-0 pr-3">
              <input
                type="text"
                class="form-control"
                value="0"
                title="{{ 'customers.cvv.shipRequirements.after' | translate }}"
                name="shipRequirementsWinchAft"
                [(ngModel)]="winch.after"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="input-group form-group">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1"
              >{{ 'customers.cvv.shipRequirements.motorboat' | translate }}:
            </span>
          </div>
          <input
            type="text"
            class="form-control"
            value="0"
            name="shipRequirementsMotorboat"
            [(ngModel)]="shipRequirements.motorboat"
          />
        </div>
      </div>
      <div class="col-6">
        <div class="input-group">
          <div class="input-group-prepend">
            <div class="input-group-text">
              <input
                type="checkbox"
                id="shipRequirementsHeavyEquipment"
                aria-label="Checkbox for following text input"
                name="shipRequirementsHeavyEquipment"
                [(ngModel)]="shipRequirements.heavyEquipment"
              />
            </div>
          </div>
          <label class="form-control" for="shipRequirementsHeavyEquipment">{{
            'customers.cvv.shipRequirements.heavyEquipment' | translate
          }}</label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h4>{{ 'shared.terms.remarks' | translate }}</h4>
        <div class="form-group">
          <textarea
            rows="3"
            class="form-control"
            name="shipRequirementsRemarks"
            [(ngModel)]="shipRequirements.remarks"
          ></textarea>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="input-group form-group">
          <div class="input-group-prepend">
            <div class="input-group-text">
              <input
                type="checkbox"
                id="shipRequirementsSaveShip"
                aria-label="Checkbox for following text input"
                name="shipRequirementsSaveShip"
                [(ngModel)]="saveDataToShip"
              />
            </div>
          </div>
          <label class="form-control" for="shipRequirementsSaveShip">{{
            'customers.cvv.currentRequirements.saveDataToShip' | translate
          }}</label>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="errors && errors.length > 0">
      <div class="col-12">
        <div class="alert alert-danger">
          <p>De volgende velden zijn fout:</p>
          <ul>
            <li *ngFor="let error of errors">{{ error }}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="d-flex">
      <div class="flex-grow-1">
        <button
          type="button"
          class="btn btn-block btn-link"
          (click)="dismiss(null)"
        >
          {{ 'shared.terms.cancel' | translate }}
        </button>
      </div>
      <div class="flex-grow-1">
        <button
          type="button"
          class="btn btn-block btn-success"
          (click)="save()"
        >
          {{ 'shared.terms.save' | translate }}
        </button>
      </div>
    </div>
  </form>
</div>
