<div class="row">
  <div class="col">
    <ng-container
      *ngIf="!isSingleSelect"
      [ngTemplateOutlet]="sfSelect"
    ></ng-container>

    <ng-container
      *ngIf="isSingleSelect"
      [ngTemplateOutlet]="htmlSelect"
    ></ng-container>
  </div>
</div>

<ng-template #sfSelect>
  <sh-multi-select
    [formControl]="formControl"
    [options]="options"
    [mustMatchOptions]="mustMatch"
    [appendText]="appendText"
    [maxItems]="max"
    [hint]="max && max > 1 ? maxInfo : undefined"
  ></sh-multi-select>
</ng-template>

<ng-template #htmlSelect>
  <div class="input-group">
    <select
      class="custom-select"
      [name]="name"
      [required]="required"
      [ngModel]="value"
      (ngModelChange)="changeValue($event)"
    >
      <option *ngIf="!required" [ngValue]="null"></option>
      <option
        *ngFor="let item of options"
        [value]="item.value"
        [selected]="item.value === value"
      >
        {{ item.label }}
      </option>
    </select>

    <div class="input-group-append" *ngIf="appendText">
      <span class="input-group-text">{{ appendText }}</span>
    </div>
  </div>
</ng-template>
