import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {
  AISMarkerModel,
  MapDataService,
  NauticalShipDataService,
  NauticalShipModel,
} from '@seahorse/domain';
import {
  CardComponent,
  EmptyPlaceholderDirective,
  MapDirectionIndicatorComponent,
} from '@seahorse/ui';
import { AttributeListComponent } from '../../data/attribute-list/attribute-list.component';
import { FieldAttributeModel } from '../../data/field-attribute.model';

@Component({
  selector: 'temp-nautical-ship-box',
  templateUrl: './nautical-ship-box.component.html',
  standalone: true,
  imports: [
    CommonModule,
    CardComponent,
    TranslateModule,
    AttributeListComponent,
    EmptyPlaceholderDirective,
    MapDirectionIndicatorComponent,
  ],
})
export class NauticalShipBoxComponent {
  private _shipId: string;
  set shipId(val: string) {
    if (!val) {
      return;
    }

    this._shipId = val;
    this.loadShip();
  }
  get shipId(): string {
    return this._shipId;
  }

  private _displayFields: string[];
  set displayFields(val: string[]) {
    this._displayFields = val;
  }

  aisMarker?: AISMarkerModel;
  attributes: Partial<FieldAttributeModel>[] = [];
  isLoading = false;
  ship?: NauticalShipModel;
  title = 'nautical.ship.title';

  private _mapDataService = inject(MapDataService);
  private _shipDataService = inject(NauticalShipDataService);
  private _router = inject(Router);

  openDetails() {
    this._router.navigate(['/nautical/vessels', this.shipId]);
  }

  private loadShip() {
    this.isLoading = true;
    this._shipDataService.getById(this.shipId).subscribe((ship) => {
      this.ship = ship;
      this.setAttributes(ship);

      if (this.ship && this.ship.mmsi) {
        this._mapDataService
          .getMarkerForMMSI(this.ship.mmsi)
          .subscribe((aisMarker) => {
            this.aisMarker = aisMarker;
          });
      }

      this.isLoading = false;
    });
  }

  navigate(link: string) {
    this._router.navigateByUrl(link);
  }

  private setAttributes(ship: NauticalShipModel) {
    const addAttribute = (
      fieldName: keyof NauticalShipModel,
      fieldValue: string | number,
      translationKey: string,
      unit: string = ''
    ) => {
      if (
        !this._displayFields ||
        this._displayFields.length === 0 ||
        this._displayFields.includes(fieldName)
      ) {
        this.attributes.push({
          fieldName: translationKey,
          fieldValue: `${fieldValue}${unit}`,
        });
      }
    };

    if (
      ship.shipType &&
      ship.shipType.name &&
      ship.shipType.systemCode !== 'unk'
    ) {
      addAttribute(
        'shipType',
        ship.shipType.name,
        'nautical.ship.shipType.shipType'
      );
    }

    if (ship.flagCode && ship.flagCode !== 'XYZ') {
      addAttribute('flagCode', ship.flagCode, 'nautical.ship.model.flagCode');
    }

    if (ship.imo) {
      addAttribute('imo', ship.imo, 'nautical.ship.model.imo');
    }

    if (ship.eni) {
      addAttribute('eni', ship.eni, 'nautical.ship.model.eni');
    }

    if (ship.fisheryNumber) {
      addAttribute(
        'fisheryNumber',
        ship.fisheryNumber,
        'nautical.ship.model.fisheryNumber'
      );
    }

    if (ship.callSign) {
      addAttribute('callSign', ship.callSign, 'nautical.ship.model.callSign');
    }

    if (ship.mmsi) {
      addAttribute('mmsi', ship.mmsi, 'nautical.ship.model.mmsi');
    }

    if (ship.loa) {
      addAttribute('loa', ship.loa, 'nautical.ship.model.loa', ' m');
    }

    if (ship.gt) {
      addAttribute('gt', ship.gt, 'nautical.ship.model.gt', ' t');
    }

    if (ship.width) {
      addAttribute('width', ship.width, 'nautical.ship.model.width', ' m');
    }

    if (ship.dwt) {
      addAttribute('dwt', ship.dwt, 'nautical.ship.model.dwt', ' t');
    }

    if (ship.maxDraft) {
      addAttribute(
        'maxDraft',
        ship.maxDraft,
        'nautical.ship.model.maxDraft',
        ' dm'
      );
    }

    ship.shipAttributes?.forEach((attr) => {
      if (
        this._displayFields &&
        this._displayFields.includes(attr.category + '.' + attr.fieldName)
      ) {
        this.attributes.push({
          fieldName:
            'nautical.ship.model.' + attr.category + '.' + attr.fieldName,
          fieldValue: attr.fieldValue,
        });
      }
    });
  }
}
