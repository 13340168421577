<div class="card">
  <div class="card-header">
    {{ 'tasks.task.tasks' | translate }}
    <div class="btn-list" *ngIf="allowAdd">
      <div class="input-group">
        <button
          (click)="onClick('addTask')"
          type="button"
          class="btn btn-outline-secondary btn-sm"
        >
          <i class="fa-regular fa-plus fa-fw"></i>
          {{ 'shared.terms.add' | translate }}
        </button>
        <div class="input-group-append">
          <button
            type="button"
            class="btn btn-outline-secondary dropdown-toggle btn-sm"
            data-toggle="dropdown"
          ></button>
          <div class="dropdown-menu">
            <button
              (click)="onClick('addFromTaskDefinitionList')"
              type="button"
              class="btn dropdown-item"
            >
              <i class="fa-regular fa-plus fa-fw"></i>
              {{ 'tasks.task.addFromTaskDefinitionList' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <sh-loading-indicator
    *ngIf="loading"
    class="d-block text-center"
  ></sh-loading-indicator>
  <ng-container *ngIf="!loading">
    <ng-container
      *ngIf="tasks && tasks.length > 0; then showTasks; else noTasks"
    ></ng-container>
    <!-- show no task label -->
    <ng-template #noTasks>
      <div class="card-body">
        {{ 'tasks.task.noTasksCouldBeFound' | translate }}
      </div>
    </ng-template>
    <!-- show task table -->
    <ng-template #showTasks>
      <div class="card-body card-body-table">
        <table
          class="table"
          [ngClass]="{
            'table-clickable':
              allowEdit === true || allowNavigateToTaskPage === true
          }"
        >
          <thead>
            <tr>
              <th class="icon" scope="col"></th>
              <th class="icon" scope="col"></th>
              <th scope="col">{{ 'tasks.task.model.subject' | translate }}</th>
              <!-- <th scope="col">{{ 'shared.terms.description' | translate }}</th> -->
              <th scope="col">
                {{ 'tasks.task.model.assignedTo' | translate }}
              </th>
              <th scope="col">{{ 'tasks.task.model.dueDate' | translate }}</th>
              <th scope="col" width="1%" *ngIf="allowDelete === true"></th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let task of tasks">
              <td
                class="icon"
                (click)="onClick('editTask', task)"
                title="{{ task.taskType | taskType }}"
              >
                <ng-container [ngSwitch]="task.taskType">
                  <i
                    *ngSwitchCase="TaskType.Call"
                    class="fa-solid fa-phone-alt fa-fw"
                  ></i>
                  <i
                    *ngSwitchCase="TaskType.SendEmail"
                    class="fa-solid fa-envelope fa-fw"
                  ></i>
                  <i
                    *ngSwitchCase="TaskType.Visit"
                    class="fa-solid fa-exchange-alt fa-fw"
                  ></i>
                  <i
                    *ngSwitchCase="TaskType.Process"
                    class="fa-solid fa-microchip fa-fw"
                  ></i>
                  <i
                    *ngSwitchCase="TaskType.Website"
                    class="fa-regular a-globe-pointer fa-fw"
                  ></i>
                </ng-container>
              </td>
              <td
                class="icon"
                (click)="onClick('editTask', task)"
                title="{{ task.status | taskStatus }}"
              >
                <ng-container [ngSwitch]="task.status">
                  <i
                    *ngSwitchCase="TaskStatus.Todo"
                    class="fa-solid fa-list-ul fa-fw text-primary"
                  ></i>
                  <i
                    *ngSwitchCase="TaskStatus.Done"
                    class="fa-solid fa-check fa-fw text-success"
                  ></i>
                  <i
                    *ngSwitchCase="TaskStatus.Cancelled"
                    class="fa-solid fa-times fa-fw text-danger"
                  ></i>
                  <i
                    *ngSwitchCase="TaskStatus.InProgress"
                    class="fa-solid fa-spinner fa-fw text-info"
                  ></i>
                </ng-container>
              </td>
              <td
                (click)="onClick('editTask', task)"
                title="{{ task.description }}"
              >
                <b>{{ task.subject }}</b>
              </td>
              <!-- <td (click)="onClick('editTask', task)">{{ task.description | shTruncate: 30 }}</td> -->
              <td (click)="onClick('editTask', task)">
                {{ task.assignedTo | nameFromUserId | async }}
              </td>
              <td (click)="onClick('editTask', task)">
                {{ task.dueDate | utcToLocalDatePipe }}
              </td>
              <td class="btn-list" *ngIf="allowDelete === true">
                <div class="dropdown p-0">
                  <button
                    type="button"
                    class="btn btn-light dropdown-toggle"
                    id="rowMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fa-solid fa-ellipsis-v fa-fw"></i>
                  </button>
                  <div
                    class="btn dropdown-menu"
                    aria-labelledby="rowMenuButton"
                  >
                    <button
                      class="dropdown-item"
                      (click)="onClick('deleteTask', task)"
                    >
                      <i class="fa-solid fa-trash fa-fw"></i>
                      {{ 'shared.terms.delete' | translate }}
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-template>
  </ng-container>

  <ng-container *ngIf="allowNavigateToTaskPage === true">
    <div
      class="card-footer card-footer-clickable"
      *ngIf="tasks && tasks.length > 0"
    >
      <a routerLink="/tasks/overview"
        >{{ 'tasks.task.moreTasks' | translate }} &raquo;</a
      >
    </div>
  </ng-container>
</div>
