import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'underscore';

@Pipe({ 
  name: 'tempCustomListDisplay',
  standalone: true 
})
export class CustomListDisplayPipe implements PipeTransform {
  transform(itemValue: string[], items: { value: string; key: string }[]): any {
    if (
      !itemValue ||
      itemValue.length === 0 ||
      !items ||
      Object.keys(items).length === 0
    ) {
      return itemValue;
    }
    return _.map(itemValue, (itemMap) => {
      const posVal = _.find(items, (itemFind) => itemFind.key === itemMap);
      return posVal ? posVal.value : itemMap;
    });
  }
}
