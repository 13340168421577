import { Routes } from '@angular/router';
import { SystemMessageSummaryComponent } from './components/system-message/system-message-summary/system-message-summary.component';
import { ReleaseNotesOverviewPage } from './pages/release-notes-overview/release-notes-overview.page';
import { SystemMessagesOverviewPage } from './pages/system-messages-overview/system-messages-overview.page';

export const PREFERENCE_ROUTES: Routes = [
  {
    path: 'overview',
    component: ReleaseNotesOverviewPage,
  },
];
