/* eslint-disable @angular-eslint/no-output-rename */
/* eslint-disable @angular-eslint/no-output-on-prefix */
import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FieldType, ObjectDefinitionModel } from '@seahorse/domain';
import { LayoutService } from '@seahorse/layout';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { CustomDataFormService } from '../../form/custom-data-form.service';
import { FormComponent } from '../../form/form.component';
import { FormModel } from '../../form/form.model';
import { SEARCH_PARAMS } from '../search-params.provider';
import { SearchQueryParams } from '../search.model';

@Component({
  selector: 'temp-advanced-search',
  templateUrl: './advanced-search.component.html',
  styles: [
    `
      .page-header-filter-list {
        padding: 10px;
        padding-right: 20px;
      }
    `,
  ],
  standalone: true,
  imports: [CommonModule, FormsModule, FormComponent, TranslateModule],
})
export class AdvancedSearchComponent implements OnInit, OnDestroy {
  private _objectDefinition: ObjectDefinitionModel;
  @Input() set objectDefinition(val: ObjectDefinitionModel) {
    // we need deep copy here, because we are mutating nested collection
    this._objectDefinition = JSON.parse(JSON.stringify(val));
    this._objectDefinition.objectFieldDefinitions
      .filter((x) => x.fieldType === FieldType.LinkedObject)
      .forEach((x) => (x.fieldType = FieldType.LinkedObjects));
  }
  get objectDefinition() {
    return this._objectDefinition;
  }

  @Input() showSearchPaginationOptions = true;
  @Input() excludedFieldSystemCodes: string[] = [];

  @Output('search') onSearch = new EventEmitter<any>();

  @ViewChild(FormComponent, { static: true }) formRef: FormComponent;

  pageSize = 20;
  searchModel = {};
  sidebarOpen = false;
  searchFields = [];
  formModel: FormModel;

  private _subscriptions = new Subscription();

  get advancedSearchConfig() {
    if (!this.objectDefinition) {
      return null;
    }

    return this.objectDefinition.advancedSearchConfig;
  }

  constructor(
    private _layoutService: LayoutService,
    private _customDataFormService: CustomDataFormService,
    private _translate: TranslateService,
    @Inject(SEARCH_PARAMS)
    private _searchParams$: Observable<SearchQueryParams | null>
  ) {}

  ngOnInit() {
    this._subscriptions.add(
      this._layoutService.sidebarToggle$.subscribe(
        (sidebarOpen) => (this.sidebarOpen = sidebarOpen)
      )
    );

    this._subscriptions.add(
      this._searchParams$
        .pipe(filter((params) => params !== null && !!params.aSearch))
        .subscribe((params) => this.setSearchParams(params))
    );

    this.setUpSearch();
    this.formModel = this._customDataFormService.getFormModel(
      this.objectDefinition,
      this.searchFields,
      this.searchModel
    );
    this.formModel.options = {
      noValidate: true,
    };
  }

  private setUpSearch() {
    if (this.advancedSearchConfig.rangeFields) {
      this.advancedSearchConfig.rangeFields.forEach((field) => {
        const sourceField = this.objectDefinition.objectFieldDefinitions.find(
          (x) => x.systemCode === field
        );

        if (sourceField) {
          const fieldCodes = [
            `${field}${this._translate.instant('shared.terms.from')}`,
            `${field}${this._translate.instant('shared.terms.to')}`,
          ];

          fieldCodes.forEach((code, index) => {
            const fieldDefinition = Object.assign({}, sourceField);
            Object.assign(fieldDefinition, {
              systemCode: code,
              name: `${sourceField.name} ${
                index === 0
                  ? this._translate.instant('shared.terms.from')
                  : this._translate.instant('shared.terms.to')
              }`,
            });

            this.objectDefinition.objectFieldDefinitions.push(fieldDefinition);
            this.searchFields.push(code);
          });
        }
      });
    }

    this.searchFields = this.searchFields.concat(
      this.advancedSearchConfig.fields || []
    );

    this.searchFields = this.searchFields.filter((field) => {
      return !this.excludedFieldSystemCodes.some(
        (systemCode) =>
          field === systemCode ||
          new RegExp(`^${systemCode}(From|To)$`).test(field)
      );
    });
  }

  private setSearchParams(search: SearchQueryParams) {
    if (search.pSize) {
      this.pageSize = search.pSize;
    }

    const linkedObjectsFields = this.objectDefinition.objectFieldDefinitions
      .filter((x) => x.fieldType === FieldType.LinkedObjects)
      .map((x) => x.systemCode);

    search.aSearch.forEach((field) => {
      if (linkedObjectsFields.includes(field.fieldName)) {
        const valuesArray = decodeURIComponent(
          field.searchValue.replace(/&/g, ',')
        ).split(',');

        Object.assign(this.searchModel, {
          [field.fieldName]: valuesArray,
        });
      } else {
        Object.assign(this.searchModel, {
          [field.fieldName]: decodeURIComponent(field.searchValue),
        });
      }
    });
  }

  search() {
    const searchValue = this.formRef.submit();
    searchValue['pSize'] = this.pageSize;
    this.onSearch.emit(searchValue);
  }

  reset() {
    this.formRef.reset();
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}
