import { Injectable } from '@angular/core';
import { ResultWrapper } from '@seahorse/common';
import { DataContextService } from '@seahorse/common';
import { QueryModel } from '../../shared/models/query.model';
import {
  ObjectDefinitionModel,
  SystemObjectDefinitionDetailsModel,
  SystemObjectDefinitionModel,
} from '@seahorse/domain';
import { Observable } from 'rxjs';

@Injectable()
export class ObjectDefinitionsDataService {
  constructor(private dataContextService: DataContextService) {}

  searchSystemData(
    query: QueryModel
  ): Observable<ResultWrapper<Array<SystemObjectDefinitionModel>>> {
    let url = 'customdata/objectdefinitions/system';
    let args = null;
    Object.entries(query).forEach(([key, value], i, a) => {
      args = (args ? args + '&' : '?') + key + '=' + value;
      if (args && a.length === i + 1) {
        url += args;
      }
    });
    return this.dataContextService.get<Array<SystemObjectDefinitionModel>>(url);
  }

  getSystemData(
    id: SystemObjectDefinitionModel['id']
  ): Observable<ResultWrapper<SystemObjectDefinitionDetailsModel>> {
    return this.dataContextService.get<SystemObjectDefinitionDetailsModel>(
      `customdata/objectdefinitions/system/${id}`
    );
  }

  getSystemDataAll(): Observable<ResultWrapper<ObjectDefinitionModel[]>> {
    return this.dataContextService.get<ObjectDefinitionModel[]>(
      `customdata/objectdefinitions/system/all`
    );
  }

  search(search: string): Observable<ResultWrapper<ObjectDefinitionModel[]>> {
    return this.dataContextService.get<ObjectDefinitionModel[]>(
      `customdata/objectdefinitions?search=${search}`
    );
  }

  getAll(
    pageIndex?: number,
    pageSize?: number,
    includeSystem = false
  ): Observable<ResultWrapper<ObjectDefinitionModel[]>> {
    const args = [];

    if (pageIndex) {
      args.push(`pindex=${pageIndex}`);
    }

    if (pageSize) {
      args.push(`psize=${pageSize}`);
    }

    if (includeSystem) {
      args.push(`includeSystem=${includeSystem}`);
    }

    return this.dataContextService.get<ObjectDefinitionModel[]>(
      `customdata/objectdefinitions?${args.join('&')}`
    );
  }

  getById(
    objectDefinitionId: ObjectDefinitionModel['id']
  ): Observable<ResultWrapper<ObjectDefinitionModel>> {
    return this.dataContextService.get<ObjectDefinitionModel>(
      `customdata/objectdefinitions/${objectDefinitionId}`
    );
  }

  getBySystemCode(
    systemCode: ObjectDefinitionModel['systemCode']
  ): Observable<ResultWrapper<ObjectDefinitionModel>> {
    return this.dataContextService.get<ObjectDefinitionModel>(
      `customdata/objectdefinitions/systemcode/${systemCode}`
    );
  }

  getByOrganization(
    id: string
  ): Observable<ResultWrapper<ObjectDefinitionModel[]>> {
    return this.dataContextService.get<ObjectDefinitionModel[]>(
      `customdata/objectdefinitions/organizationId/${id}`
    );
  }

  add(
    objectDefinition: ObjectDefinitionModel
  ): Observable<ResultWrapper<ObjectDefinitionModel>> {
    return this.dataContextService.post<ObjectDefinitionModel>(
      `customdata/objectdefinitions`,
      objectDefinition
    );
  }

  delete(
    objectDefinitionId: ObjectDefinitionModel['id']
  ): Observable<ResultWrapper<ObjectDefinitionModel>> {
    return this.dataContextService.delete<ObjectDefinitionModel>(
      `customdata/objectdefinitions/${objectDefinitionId}`
    );
  }

  import(
    objectDefinition: ObjectDefinitionModel,
    isNew: boolean
  ): Observable<ResultWrapper<ObjectDefinitionModel>> {
    return this.dataContextService.post<ObjectDefinitionModel>(
      `customdata/objectdefinitions/import`,
      { objectDefinition, isNew }
    );
  }

  update(
    objectDefinition: ObjectDefinitionModel
  ): Observable<ResultWrapper<ObjectDefinitionModel>> {
    for (let i = 0; i < objectDefinition.objectFieldDefinitions.length; i++) {
      objectDefinition.objectFieldDefinitions[i].fieldRule = null;
    }

    return this.dataContextService.put<ObjectDefinitionModel>(
      `customdata/objectdefinitions/${objectDefinition.id}`,
      objectDefinition
    );
  }
}
