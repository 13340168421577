import { Component, Input, Output, EventEmitter } from '@angular/core';

import { EventModel } from '../../../../../../shared/models/event.model';
import { FlowModel } from '@seahorse/domain';

@Component({
  selector: 'invoicing-flows-summary',
  templateUrl: 'invoicing-flows-summary.component.html',
})
export class InvoicingFlowsSummaryComponent {
  @Input() selectedInvoicingFlow: FlowModel;

  @Output() onEvent: EventEmitter<EventModel>;

  constructor() {
    this.selectedInvoicingFlow = null;
    this.onEvent = new EventEmitter<EventModel>();
  }
}
