<div class="modal-header align-items-center">
  <h1 class="modal-title">
    {{ 'shared.terms.pickerTitle' | translate }}
    {{ 'nautical.geography.port.port' | translate }}
  </h1>
  <button closeModal type="button" class="btn btn-link text-dark">
    <i class="fa-solid fa-times fa-sm fa-fw"></i>
  </button>
</div>
<div class="modal-body">
  <div class="form-row">
    <div class="form-group col-12">
      <label
        class="d-flex justify-content-between"
        for="typeahead-nauticalPort"
      >
        <b [ngClass]="{ required: isRequired }"
          >{{ 'shared.terms.pickerTitle' | translate }}
          {{ 'nautical.geography.port.port' | translate }}</b
        >
        <span *ngIf="nauticalPortsLoading">
          <i class="fa-regular fa-spinner fa-spin fa-fw"></i>
        </span>
      </label>
      <div class="input-group">
        <input
          [(ngModel)]="nauticalPort"
          [class.is-invalid]="nauticalPortsLoadingFailed"
          [ngbTypeahead]="searchnauticalPort"
          [inputFormatter]="nauticalPortFormatter"
          [resultFormatter]="nauticalPortsFormatter"
          [editable]="false"
          [required]="isRequired"
          id="typeahead-nauticalPort"
          name="nauticalPort"
          type="text"
          class="form-control"
        />
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button closeModal type="button" class="btn btn-link flex-fill">
    {{ 'shared.terms.cancel' | translate }}
  </button>
  <button
    [disabled]="isRequired && !nauticalPort"
    (click)="confirm()"
    type="button"
    class="btn btn-success flex-fill"
  >
    {{ 'shared.terms.confirm' | translate }}
  </button>
</div>
