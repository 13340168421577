import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'underscore';
import { ProxyService } from '../../proxy/proxy.service';

@Pipe({ 
  name: 'shCustomDataDefinitionType',
  standalone: true
})
export class CustomDataDefinitionTypePipe implements PipeTransform {
  constructor(private _proxy: ProxyService) {}

  transform(input: string): string {
    if (!input) {
      return input;
    }

    input = input.toLowerCase();

    if (input.length > 3 && input.toLowerCase().indexOf('ct-') === 0) {
      input = input.substr(3);
    }

    if (this._proxy && this._proxy.objectDefinitions) {
      const def = _.find(this._proxy.objectDefinitions, (objectDefinition) => {
        return objectDefinition.systemCode.toLowerCase() === input;
      });

      if (def) {
        if (def.groupName && def.groupName.length > 0) {
          return def.name + ' (' + def.groupName + ')';
        }
        return def.name;
      }
    }

    return null;
  }
}
