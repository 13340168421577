import {
  OnInit,
  Input,
  Output,
  Component,
  Inject,
  EventEmitter,
} from '@angular/core';

import { FinancialDataService } from '@seahorse/domain';
import { CompanyFinancialModel } from '@seahorse/domain';

@Component({
  selector: 'ca-financial-modal',
  templateUrl: './financial-modal.component.html',
})
export class FinancialModalComponent implements OnInit {
  @Input() financial: CompanyFinancialModel;

  @Output() onSelectCompanyFinancial: EventEmitter<CompanyFinancialModel>;

  isSubmitted = false;
  isNew = false;
  financialId: number;

  constructor(
    @Inject('FinancialInteractionService')
    private financialDataService: FinancialDataService
  ) {
    this.onSelectCompanyFinancial = new EventEmitter<CompanyFinancialModel>();
    this.financial = new CompanyFinancialModel();
  }

  ngOnInit() {
    this.isNew = this.financialId == null || this.financialId === undefined;

    if (!this.isNew) {
      this.financialDataService.getById(this.financialId).subscribe(
        (response: { result: any }) => {
          this.financial = response.result;
        },
        () => {}
      );
    } else {
      this.financial.companyId = +localStorage.getItem('company_id');
    }
  }

  save() {
    // if (!CompanyFinancialModel.validate(this.financial)) {
    //   this.isSubmitted = true;
    //   return;
    // }
    // this.financialInteractionService.modify(this.financial);
  }
}
