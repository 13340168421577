<sh-control *ngIf="control" [control]="this">
  <ejs-dropdownlist
    floatLabelType="Always"
    [formControl]="control"
    [placeholder]="placeholder"
    [dataSource]="options"
    [fields]="fieldMapping"
    [id]="formControlName"
    [attr.name]="formControlName"
    [itemTemplate]="templateRef"
    [valueTemplate]="templateRef"
  >
  </ejs-dropdownlist>
</sh-control>
