import { Input, Output, Component, EventEmitter } from '@angular/core';
import * as _ from 'underscore';
import { EventModel } from '../../../../shared/models/event.model';
import { ZeehavenIJmuidenMovementModel } from '../../models/zeehaven-ijmuiden-movement.model';

@Component({
  selector: 'zeehaven-ijmuiden-details-header',
  templateUrl: './zeehaven-ijmuiden-details-header.component.html',
})
export class ZeehavenIJmuidenDetailsHeaderComponent {
  @Input() loading: boolean;
  @Input() status: ZeehavenIJmuidenMovementModel['status'];

  @Output() onEvent: EventEmitter<EventModel>;

  constructor() {
    this.loading = false;
    this.onEvent = new EventEmitter<EventModel>();
  }
}
