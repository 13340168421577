import { CustomDataBaseModel } from '@seahorse/domain';
import { NauticalShipModel } from '@seahorse/domain';
import { NauticalVisitModel } from '@seahorse/domain';
import { NauticalWaypointModel } from '@seahorse/domain';

import { ZeehavenIJmuidenDisplayModel } from './zeehaven-ijmuiden-display.model';

export class ZeehavenIJmuidenVisitModel
  extends CustomDataBaseModel
  implements ZeehavenIJmuidenDisplayModel
{
  agent: string;
  currentBerth: string;
  currentBerthWaypoint: NauticalWaypointModel['id'];
  destination: string;
  origin: string;
  status: string;
  nauticalShip: NauticalShipModel['id'];
  $nautical_portvisit_id?: NauticalVisitModel['id'];
  shipName: string;

  constructor() {
    super();

    this.agent = null;
    this.currentBerth = null;
    this.currentBerthWaypoint = null;
    this.destination = null;
    this.origin = null;
    this.status = null;
    this.nauticalShip = null;
    this.$nautical_portvisit_id = null;
  }
}
