<div class="card">
  <div class="card-header">
    <span *ngIf="!loadVisit && visit.referenceNumber" class="mr-1">{{
      visit.referenceNumber
    }}</span>
    <small class="text-muted">{{ 'shared.terms.details' | translate }}</small>
  </div>
  <div *ngIf="!loadVisit" class="card-body">
    <div class="row no-gutters attribute-group w-100 pt-0 mt-n1">
      <div class="attribute-group-item col-12">
        <dt class="mb-0">{{ 'nautical.visit.model.status' | translate }}</dt>
        <dd *ngIf="visit.status">{{ visit.status | nauticalVisitStatus }}</dd>
      </div>
      <div class="attribute-group-item col-12 col-md-6">
        <dt class="mb-0">{{ 'nautical.terms.port' | translate }}</dt>
        <dd *ngIf="visit.port && visit.port.name">{{ visit.port.name }}</dd>
      </div>
      <div class="attribute-group-item col-12 col-md-6">
        <dt class="mb-0">{{ 'nautical.terms.portAgent' | translate }}</dt>
        <dd *ngIf="visit.portAgent && visit.portAgent.name">
          {{ visit.portAgent.name }}
        </dd>
      </div>
      <div class="attribute-group-item col-12 col-md-6">
        <dt class="mb-0">
          {{ 'nautical.visit.model.nextPort.name' | translate }}
        </dt>
        <dd *ngIf="visit.nextPort && visit.nextPort.name">
          {{ visit.nextPort.name }}
        </dd>
      </div>
      <div class="attribute-group-item col-12 col-md-6">
        <dt class="mb-0">
          {{ 'nautical.visit.model.previousPort.name' | translate }}
        </dt>
        <dd *ngIf="visit.previousPort && visit.previousPort.name">
          {{ visit.previousPort.name }}
        </dd>
      </div>
      <div class="attribute-group-item col-12 col-md-6">
        <dt class="mb-0">{{ 'nautical.terms.eta' | translate }}</dt>
        <dd *ngIf="visit.eta">{{ visit.eta | date: 'dd-MM-yyyy HH:mm' }}</dd>
      </div>
      <div class="attribute-group-item col-12 col-md-6">
        <dt class="mb-0">{{ 'nautical.terms.etd' | translate }}</dt>
        <dd *ngIf="visit.etd">{{ visit.etd | date: 'dd-MM-yyyy HH:mm' }}</dd>
      </div>
      <div class="attribute-group-item col-12 col-md-6">
        <dt class="mb-0">{{ 'nautical.terms.ata' | translate }}</dt>
        <dd *ngIf="visit.ata">{{ visit.ata | date: 'dd-MM-yyyy HH:mm' }}</dd>
      </div>
      <div class="attribute-group-item col-12 col-md-6">
        <dt class="mb-0">{{ 'nautical.terms.atd' | translate }}</dt>
        <dd *ngIf="visit.atd">{{ visit.atd | date: 'dd-MM-yyyy HH:mm' }}</dd>
      </div>
    </div>
  </div>
</div>
