import { BaseModel } from '@seahorse/common';
import { ValidationResult } from '@seahorse/common';
import {
  TaskModel,
  TaskStatus,
} from '../../tasks/modules/task/models/task.model';

export class TaskGroupModel extends BaseModel {
  id: number;
  name: string;
  systemCode: string;
  dueDate: string; // Date
  dueDateTemplate: string;
  status: TaskStatus;
  completedOn?: string; // Date
  remarks?: string;
  objectId?: string;
  objectType?: string;
  tasks: Array<TaskModel>;

  constructor() {
    super();
    this.id = 0;
    this.status = TaskStatus.Todo;
    this.tasks = new Array<TaskModel>();
  }

  validate(errorPrefix?: string): ValidationResult {
    return new ValidationResult(errorPrefix === null);
  }
}
