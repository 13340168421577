<ng-container
  *ngIf="
    loadingCustomDefaultDataSet;
    then showLoadingIndicator;
    else showHasLoaded
  "
></ng-container>

<ng-template #showLoadingIndicator>
  <sh-loading-indicator></sh-loading-indicator>
</ng-template>

<ng-template #showHasLoaded>
  <div
    *ngIf="
      !hideDefaultDataGroupPicker &&
      customDefaultDataSet &&
      customDefaultDataSet.length
    "
    class="input-group"
  >
    <select
      [(ngModel)]="defaultDataGroup"
      (change)="createChecklist()"
      id="defaultDataGroup"
      name="defaultDataGroup"
      class="custom-select"
    >
      <option selected [ngValue]="null">
        {{ 'shared.terms.choose' | translate }}...
      </option>
      <option
        *ngFor="let defaultDataGroupOption of customDefaultDataSet"
        [ngValue]="defaultDataGroupOption"
      >
        {{ defaultDataGroupOption.name }}
      </option>
    </select>
  </div>
  <ng-container
    *ngIf="
      checklist && checklist.length;
      then showChecklist;
      else notFoundMessage
    "
  ></ng-container>
</ng-template>

<ng-template #showChecklist>
  <table class="table table-borderless table-sm table-hover mb-0">
    <tr>
      <td scope="row">
        <div class="custom-control custom-checkbox">
          <input
            id="isAllChecked"
            [checked]="isAllChecked"
            (change)="selectAll(!isAllChecked)"
            type="checkbox"
            class="custom-control-input"
          />
          <label for="isAllChecked" class="custom-control-label w-100">{{
            'shared.terms.all' | translate
          }}</label>
        </div>
      </td>
    </tr>
    <hr class="m-0" />
    <tr *ngFor="let checklistOption of checklist; let i = index">
      <td scope="row">
        <div class="custom-control custom-checkbox">
          <input
            [id]="'checklistOption-' + i"
            [checked]="checklistOption.isChecked"
            (change)="toggleChecklistOption(checklistOption)"
            type="checkbox"
            class="custom-control-input"
          />
          <label
            [for]="'checklistOption-' + i"
            class="custom-control-label w-100"
          >
            {{ checklistOption.value }}
          </label>
        </div>
      </td>
    </tr>
  </table>
</ng-template>

<ng-template #notFoundMessage>
  <ng-container *ngIf="defaultDataGroup">
    {{ 'shared.terms.notFound' | translate }}
  </ng-container>
</ng-template>
