<form #languageForm="ngForm">
  <div class="form-group">
    <label class="control-label"
      ><b>{{ 'shared.terms.language' | translate }}</b></label
    >
    <ng-select
      [items]="languages"
      [searchable]="false"
      [clearable]="false"
      bindLabel="label"
      bindValue="value"
      name="language"
      [(ngModel)]="model.fieldValue"
      placeholder="{{ 'shared.terms.selectLanguage' | translate }}"
    >
      <ng-template ng-label-tmp let-item="item">
        <span class="{{ item.flag }}"></span> {{ item.label }}
      </ng-template>
      <ng-template ng-option-tmp let-item="item">
        <span class="{{ item.flag }}"></span> {{ item.label }}
      </ng-template>
    </ng-select>
  </div>
</form>
