import { Injectable } from '@angular/core';
import { ResultWrapper } from '@seahorse/common';
import { DataContextService } from '@seahorse/common';
import { Observable } from 'rxjs';

import { CompanyDepartmentModel, DataService } from '@seahorse/domain';

@Injectable()
export class DepartmentDataService
  implements DataService<CompanyDepartmentModel>
{
  constructor(private dataContext: DataContextService) {}

  getById(
    departmentId: number
  ): Observable<ResultWrapper<CompanyDepartmentModel>> {
    return this.dataContext.get<CompanyDepartmentModel>(
      `departments/${departmentId}`
    );
  }

  getAll(
    companyId: number
  ): Observable<ResultWrapper<CompanyDepartmentModel[]>> {
    return this.dataContext.get<CompanyDepartmentModel[]>(
      `departments/company/${companyId}`
    );
  }

  add(
    department: CompanyDepartmentModel
  ): Observable<ResultWrapper<CompanyDepartmentModel>> {
    return this.dataContext.post<CompanyDepartmentModel>(
      `departments`,
      department
    );
  }

  update(
    department: CompanyDepartmentModel
  ): Observable<ResultWrapper<CompanyDepartmentModel>> {
    return this.dataContext.put<CompanyDepartmentModel>(
      `departments/${department.id}`,
      department
    );
  }

  delete(
    departmentId: number
  ): Observable<ResultWrapper<CompanyDepartmentModel>> {
    return this.dataContext.delete<CompanyDepartmentModel>(
      `departments/${departmentId}`
    );
  }
}
