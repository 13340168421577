import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'error',
  template: `<div class="modal-header">
      <h4 class="modal-title">
        {{ title ? title : ('shared.terms.confirm' | translate) }}
      </h4>
      <button type="button" class="close" (click)="activeModal.close(false)">
        &times;
      </button>
    </div>
    <div class="modal-body">
      <div *ngIf="body && body.length === 1">{{ body[0] }}</div>
      <ul *ngIf="body && body.length > 1">
        <li *ngFor="let item of body">{{ item }}</li>
      </ul>
    </div>
    <div class="modal-footer">
      <button
        *ngIf="deliveryDateError"
        type="button"
        class="btn btn-success mr-2"
        (click)="activeModal.close(true)"
      >
        Edit quote
      </button>
      <button
        *ngIf="!deliveryDateError"
        type="button"
        class="btn m-btn--pill btn-primary"
        (click)="activeModal.close(false)"
      >
        {{ 'shared.terms.cancel' | translate }}
      </button>
    </div> `,
})
export class ErrorComponent {
  static title: string;
  static body: string[];
  static deliveryDateError = false;
  constructor(public activeModal: NgbActiveModal) {}

  get title() {
    return ErrorComponent.title;
  }

  get body() {
    return ErrorComponent.body;
  }

  get deliveryDateError() {
    return ErrorComponent.deliveryDateError;
  }
}
