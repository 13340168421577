import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'utcToLocalDatePipe',
})
export class UtcToLocalDatePipe implements PipeTransform {
  transform(utcDate: any): string {
    if (utcDate) {
      return moment.utc(utcDate).local().format('DD-MM-YYYY');
    }

    return '';
  }
}
