import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataContextService } from '@seahorse/common';
import { ResultWrapper } from '@seahorse/common';
import { SystemMessageModel } from '../models/system-message.model';
import { DataService } from '@seahorse/domain';

@Injectable()
export class SystemMessageService implements DataService<SystemMessageModel> {
  constructor(private dataContext: DataContextService) {}

  get(
    searchQuery: string = null,
    pageSize: number = null
  ): Observable<ResultWrapper<SystemMessageModel[]>> {
    return this.dataContext.get<SystemMessageModel[]>(
      `su/systemmessage/search?pageSize=${pageSize}&searchTerm=${searchQuery}`
    );
  }

  getById(id: number): Observable<ResultWrapper<SystemMessageModel>> {
    return this.dataContext.get<SystemMessageModel>(`su/systemmessage/${id}`);
  }

  add(
    model: SystemMessageModel
  ): Observable<ResultWrapper<SystemMessageModel>> {
    return this.dataContext.post<SystemMessageModel>(`su/systemmessage`, model);
  }

  update(
    model: SystemMessageModel
  ): Observable<ResultWrapper<SystemMessageModel>> {
    return this.dataContext.put<SystemMessageModel>(
      `su/systemmessage/${model.id}`,
      model
    );
  }

  delete(id: number): Observable<ResultWrapper<SystemMessageModel>> {
    return this.dataContext.delete<SystemMessageModel>(
      `su/systemmessage/${id}`
    );
  }
}
