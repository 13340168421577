import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OrderRegistrationStatus } from '../models/order-registration.model';

@Pipe({ 
  name: 'shOrderRegistrationStatus',
  standalone: true
})
export class OrderRegistrationStatusPipe implements PipeTransform {
  orderRegistrationStatuses: object;

  constructor(private translate: TranslateService) {
    this.orderRegistrationStatuses = {
      [OrderRegistrationStatus.Ordered]:
        'customModules.orderRegistrations.status.ordered',
      [OrderRegistrationStatus.Pending]:
        'customModules.orderRegistrations.status.pending',
      [OrderRegistrationStatus.AwaitingShipment]:
        'customModules.orderRegistrations.status.awaitingShipment',
      [OrderRegistrationStatus.Shipping]:
        'customModules.orderRegistrations.status.shipping',
      [OrderRegistrationStatus.Completed]:
        'customModules.orderRegistrations.status.completed',
      [OrderRegistrationStatus.Cancelled]:
        'customModules.orderRegistrations.status.cancelled',
    };
  }

  transform = (orderRegistrationStatus: OrderRegistrationStatus): string =>
    this.translate.instant(
      this.orderRegistrationStatuses[orderRegistrationStatus]
    );
}
