<div class="page-header">
  <div class="row">
    <div class="col-sm-8 btn-group">
      <div
        class="page-header-filter-list"
        [ngClass]="{
          'page-header-filter-list-visible':showAdvancedSearch,
          'sidebar-open':sidebarOpen
        }"
      >
        <form [formGroup]="form" class="dropdown-header">
          <h6>
            <b>{{'companies.company.findCompanies' | translate}}</b>
          </h6>
          <div class="form-group">
            <label>
              <b>{{ 'companies.company.model.name' | translate }}</b>
            </label>
            <sh-text-input formControlName="name"></sh-text-input>
          </div>
          <div class="form-group">
            <label>
              <b>{{ 'companies.company.model.debtorNumber' | translate }}</b>
            </label>
            <sh-text-input formControlName="debtorNumber"></sh-text-input>
          </div>
          <div class="form-group">
            <label>
              <b>{{ 'companies.company.model.contactName' | translate }}</b>
            </label>
            <sh-text-input formControlName="contactName"></sh-text-input>
          </div>
          <ca-company-picker
            [title]="'companies.company.model.parentCompany' | translate"
            [(selectedCompany)]="selectedParentCompany"
          ></ca-company-picker>
          <div class="form-group">
            <label>
              <b>{{ 'companies.company.model.types' | translate }}</b>
            </label>
            <sh-multi-select
              formControlName="typeIds"
              [options]="companyTypeDropDownList"
            ></sh-multi-select>
          </div>
          <div class="form-group text-right">
            <button (click)="clearAdvancedSearch()" class="btn btn-link" type="button">
              {{ 'shared.terms.clear' | translate }}
            </button>
            <button (click)="advancedSearch()" class="btn btn-primary" type="button">
              {{ 'shared.terms.search' | translate }}
            </button>
          </div>
        </form>
      </div>
      <form class="form-inline mx-3" (ngSubmit)="searchCompanies()">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">{{
              'shared.terms.view' | translate
            }}</span>
          </div>
          <select
            class="form-control"
            [(ngModel)]="selectedView"
            name="ddl_header_data_display"
            (change)="setSelectedView()"
          >
            <option [value]="view.List">
              {{ 'shared.terms.list' | translate }}
            </option>
            <option [value]="view.Tiles">
              {{ 'shared.terms.tiles' | translate }}
            </option>
          </select>
        </div>
        <div class="input-group ml-3">
          <input
            type="text"
            class="form-control"
            placeholder="Find "
            aria-label="Find "
            [(ngModel)]="searchQuery"
            name="searchBox"
          />
          <div class="input-group-append">
            <button
              type="button"
              class="btn btn-outline-secondary"
              (click)="showAdvancedSearch = !showAdvancedSearch"
            >
              <i class="fa-regular fa-chevron-down"></i>
            </button>
            <button class="btn btn-primary mr-3" type="submit">
              <i class="fa-solid fa-search"></i>
            </button>
          </div>
        </div>
        <em *ngIf="searchResultCount !== null">
          <span *ngIf="searchResultCount === 1"
            >{{ searchResultCount }}
            {{ 'shared.terms.itemFound' | translate }}</span
          >
          <span *ngIf="searchResultCount !== 1"
            >{{ searchResultCount }}
            {{ 'shared.terms.itemsFound' | translate }}</span
          >
        </em>
      </form>
    </div>
    <div class="col-sm-4 text-right">
      <div class="btn-group pt-1 pr-3">
        <button
          class="btn btn-primary"
          *hasPermission="'companies.companies_add'"
          type="button"
          (click)="newButtonClicked()"
        >
          <i class="fa-regular fa-plus fa-fw"></i>
          {{ 'shared.terms.new' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
