import { inject } from '@angular/core';
import { CompanyTypeModel } from '@seahorse/domain';
import { FormModel, SeahorseFormBuilder } from '@seahorse/forms';

export type CreateCompanyForm = FormModel<typeof CreateCompanyForm>;

export function CreateCompanyForm() {
  const fb = inject(SeahorseFormBuilder);

  return fb.group({
    companyTypes: fb.nonNullable.control<CompanyTypeModel['id'][]>([]),
  });
}
