import { FieldDefinitionModel } from '@seahorse/domain';
import { ObjectDefinitionModel } from '@seahorse/domain';

export class CustomServiceData extends ObjectDefinitionModel {
  serviceInformationFields: ServiceInformationField[];

  constructor() {
    super();
    this.isAvaible = true;
  }

  static validate(serviceData: CustomServiceData) {
    if (!serviceData.name || serviceData.name.trim().length === 0) {
      return false;
    }

    return true;
  }
}

export class ServiceInformationField extends FieldDefinitionModel {
  label: string;
  checked: boolean;
  isCheckbox: boolean;
  listItems?: string[];

  constructor(
    fieldTypeDefinitionId?: number,
    systemCode?: string,
    name?: string,
    allowEmpty?: boolean,
    visibility?: any,
    isCheckbox?: boolean,
    checked?: boolean,
    additionalData?: string,
    label?: string
  ) {
    super();

    this.fieldTypeDefinitionId = fieldTypeDefinitionId;
    this.systemCode = systemCode;
    this.name = name;
    this.allowEmpty = allowEmpty;
    this.visibility = visibility;
    this.isCheckbox = isCheckbox;
    this.checked = checked;
    this.label = label;
    this.additionalData = additionalData;
  }

  static validate(serviceInformationField: ServiceInformationField) {
    if (
      !serviceInformationField.name ||
      serviceInformationField.name.trim().length === 0 ||
      !serviceInformationField.fieldTypeDefinitionId
    ) {
      return false;
    }

    return true;
  }
}

export class CustomDatadefinitionModel {
  id: number;

  name: string;

  constructor(id?: number, name?: string) {
    this.id = id;
    this.name = name;
  }
}
