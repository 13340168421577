<div class="dp-hidden position-absolute">
  <div class="form-group">
    <div class="input-group">
      <input
        ngbDatepicker
        #datepicker="ngbDatepicker"
        [autoClose]="'outside'"
        [displayMonths]="2"
        [dayTemplate]="t"
        [startDate]="ngbDateStart!"
        (dateSelect)="onDateSelect($event, datepicker)"
        name="datepicker"
        class="d-none"
        outsideDays="hidden"
        tabindex="-1"
      />
      <ng-template #t let-date let-focused="focused">
        <span
          class="custom-day"
          [class.focused]="focused"
          [class.range]="isRange(date)"
          [class.faded]="isHovered(date) || isInside(date)"
          (mouseenter)="ngbDateHover = date"
          (mouseleave)="ngbDateHover = null"
        >
          {{ date.day }}
        </span>
      </ng-template>
    </div>
  </div>
</div>
<div class="input-group">
  <div *ngIf="!hideCalendar" class="input-group-prepend">
    <button
      [title]="'shared.dateTimePeriodPicker.pickPeriod' | translate"
      [disabled]="isReadOnly"
      (click)="datepicker.toggle()"
      type="button"
      class="btn btn-outline-secondary"
    >
      <i class="fa-solid fa-calendar-alt fa-fw"></i>
    </button>
  </div>
  <div
    *ngIf="!hideInput"
    [ngClass]="{ 'wrap-end': !hideCalendar }"
    class="input-group-wrap"
  >
    <temp-date-time-picker
      [(dateTime)]="dateTimeStart"
      [dateTimeFormat]="'dateTimeStartFormat'"
      [invalidBefore]="invalidBefore"
      [invalidAfter]="invalidAfter"
      [isReadOnly]="isReadOnly"
      [isContained]="true"
      [hideTime]="!showTime"
      [hideClear]="false"
      [hideNow]="true"
      [hideCalendar]="true"
    ></temp-date-time-picker>
  </div>
  <div [ngClass]="{ 'is-disabled': isReadOnly }" class="input-group-concat">
    <span class="input-group-text bg-transparent border-0 px-1">
      {{ 'shared.terms.to' | translate }}
    </span>
  </div>
  <div
    *ngIf="!hideInput"
    [ngClass]="{ 'wrap-start': !hideTicker || !hideClear || !hideNow }"
    class="input-group-wrap"
  >
    <temp-date-time-picker
      [(dateTime)]="dateTimeEnd"
      [dateTimeFormat]="'dateTimeEndFormat'"
      [invalidBefore]="invalidBefore"
      [invalidAfter]="invalidAfter"
      [isReadOnly]="isReadOnly"
      [isContained]="true"
      [hideTime]="!showTime"
      [hideClear]="false"
      [hideNow]="true"
      [hideCalendar]="true"
    ></temp-date-time-picker>
  </div>
  <div *ngIf="!hideTicker || !hideClear || !hideNow" class="input-group-append">
    <button
      *ngIf="!hideTicker"
      [title]="'shared.terms.previous' | translate"
      [disabled]="
        isReadOnly ||
        !dateTimeToMoment(dateTimeStart, dateTimeStartFormat) ||
        !dateTimeToMoment(dateTimeEnd, dateTimeStartEnd)
      "
      (click)="setPrevious()"
      type="button"
      class="btn btn-outline-secondary btn-xs"
    >
      <i class="fa-regular fa-angle-left fa-xs fa-fw"></i>
    </button>
    <button
      *ngIf="!hideTicker"
      [title]="'shared.terms.next' | translate"
      [disabled]="
        isReadOnly ||
        !dateTimeToMoment(dateTimeStart, dateTimeStartFormat) ||
        !dateTimeToMoment(dateTimeEnd, dateTimeStartEnd)
      "
      (click)="setNext()"
      type="button"
      class="btn btn-outline-secondary btn-xs"
    >
      <i class="fa-regular fa-angle-right fa-xs fa-fw"></i>
    </button>
    <button
      *ngIf="!hideClear"
      [title]="'shared.terms.clear' | translate"
      [disabled]="isReadOnly"
      (click)="setNull()"
      type="button"
      class="btn btn-outline-secondary btn-xs"
    >
      <i class="fa-solid fa-times fa-xs fa-fw"></i>
    </button>
    <button
      *ngIf="!hideNow"
      [title]="'shared.dateTimePeriodPicker.now' | translate"
      [disabled]="isReadOnly"
      (click)="setNow()"
      type="button"
      class="btn btn-outline-secondary"
    >
      <i class="fa-regular fa-clock fa-fw"></i>
    </button>
  </div>
</div>
