import { Injectable } from '@angular/core';
import { ResultWrapper } from '@seahorse/common';
import { Observable } from 'rxjs/internal/Observable';
import { DataContextService } from '@seahorse/common';
import { TaskDefinitionModel } from '../models/task-definition.model';
import { TaskGroupDefinitionModel } from '../models/task-group-definition.model';
import { TaskDefinitionListModel } from '../models/task-definition-list.model';
import { TaskGroupModel } from '../../task-group/models/task-group.model';

@Injectable({ providedIn: 'root' })
export class TaskDefinitionListDataService {
  constructor(private dataContext: DataContextService) {}

  search(
    query: string
  ): Observable<ResultWrapper<Array<TaskDefinitionListModel>>> {
    return this.dataContext.get<Array<TaskDefinitionListModel>>(
      `task-definition-list?query=${query}`
    );
  }

  getAll(): Observable<ResultWrapper<Array<TaskDefinitionListModel>>> {
    return this.dataContext.get<Array<TaskDefinitionListModel>>(
      'task-definition-list'
    );
  }

  getByMappingKey(
    mappingKey: string
  ): Observable<ResultWrapper<Array<TaskDefinitionListModel>>> {
    return this.dataContext.get<Array<TaskDefinitionListModel>>(
      `task-definition-list/object-type/${mappingKey}`
    );
  }

  getById(
    id: TaskDefinitionListModel['id']
  ): Observable<ResultWrapper<TaskDefinitionListModel>> {
    return this.dataContext.get<TaskDefinitionListModel>(
      `task-definition-list/${id}`
    );
  }

  add(
    taskDefinitionList: TaskDefinitionListModel
  ): Observable<ResultWrapper<TaskDefinitionListModel>> {
    return this.dataContext.post<TaskDefinitionListModel>(
      'task-definition-list',
      taskDefinitionList
    );
  }

  copy(
    taskDefinitionList: TaskDefinitionListModel
  ): Observable<ResultWrapper<TaskDefinitionListModel>> {
    return this.dataContext.post<TaskDefinitionListModel>(
      'task-definition-list/copy',
      taskDefinitionList
    );
  }

  addTaskGroup(
    taskGroupDefinition: TaskGroupDefinitionModel
  ): Observable<ResultWrapper<TaskGroupDefinitionModel>> {
    return this.dataContext.post<TaskGroupDefinitionModel>(
      'task-definition-list/task-group',
      taskGroupDefinition
    );
  }

  addTask(
    taskDefinition: TaskDefinitionModel
  ): Observable<ResultWrapper<TaskDefinitionModel>> {
    return this.dataContext.post<TaskDefinitionModel>(
      'task-definition-list/task',
      taskDefinition
    );
  }

  update(
    taskDefinitionList: TaskDefinitionListModel
  ): Observable<ResultWrapper<TaskDefinitionListModel>> {
    return this.dataContext.put<TaskDefinitionListModel>(
      `task-definition-list/${taskDefinitionList.id}`,
      taskDefinitionList
    );
  }

  updateTaskGroup(
    taskGroupDefinition: TaskGroupDefinitionModel
  ): Observable<ResultWrapper<TaskGroupDefinitionModel>> {
    return this.dataContext.put<TaskGroupDefinitionModel>(
      `task-definition-list/task-group/${taskGroupDefinition.id}`,
      taskGroupDefinition
    );
  }

  updateTask(
    taskDefinition: TaskDefinitionModel
  ): Observable<ResultWrapper<TaskDefinitionModel>> {
    return this.dataContext.put<TaskDefinitionModel>(
      `task-definition-list/task/${taskDefinition.id}`,
      taskDefinition
    );
  }

  delete(id: TaskDefinitionListModel['id']) {
    return this.dataContext.delete<TaskDefinitionListModel>(
      `task-definition-list/${id}`
    );
  }

  deleteTaskGroup(
    taskGroupDefinitionId: TaskGroupDefinitionModel['id']
  ): Observable<ResultWrapper<TaskGroupDefinitionModel>> {
    return this.dataContext.delete<TaskGroupDefinitionModel>(
      `task-definition-list/task-group/${taskGroupDefinitionId}`
    );
  }

  deleteTask(
    taskDefinitionId: TaskDefinitionModel['id']
  ): Observable<ResultWrapper<TaskDefinitionModel>> {
    return this.dataContext.delete<TaskDefinitionModel>(
      `task-definition-list/task/${taskDefinitionId}`
    );
  }

  create(
    ids: Array<TaskDefinitionListModel['id']>,
    objectType?: string,
    objectId?: string
  ): Observable<ResultWrapper<Array<TaskGroupModel>>> {
    return this.dataContext.post<Array<TaskGroupModel>>(
      'task-group/create-from-task-definition-lists',
      { ids: ids, objectType: objectType, objectId: objectId }
    );
  }
}
