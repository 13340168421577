<form #duplicateObject="ngForm">
  <div class="modal-header">
    <div class="modal-title">
      {{ 'shared.terms.duplicate' | translate }}
    </div>
  </div>
  <div class="modal-body">
    <div class="row" *ngFor="let option of cloneobjectoptions; let i = index">
      <div *ngIf="option.show" class="col-xs-12 col-sm-12 col-md-12">
        <div class="form-group input-group">
          <div class="input-group-prepend">
            <div class="input-group-text">
              <input
                type="checkbox"
                class="cursor-pointer"
                id="cloneobjectoptions{{ i }}"
                name="cloneobjectoptions{{ i }}"
                [(ngModel)]="option.checked"
                [disabled]="i === 0"
              />
            </div>
          </div>
          <label
            class="form-control cursor-pointer"
            for="cloneobjectoptions{{ i }}"
            title="{{ option.label }}"
            >{{ option.label | translate }}</label
          >
        </div>
      </div>
    </div>

    <div
      class="row"
      *ngFor="let linkedObject of showLinkedObjectInCheckbox; let i = index"
    >
      <div
        [ngClass]="
          linkedObject.checked && linkedObject.isCustomObject
            ? 'col-6'
            : 'col-12'
        "
      >
        <div class="form-group input-group">
          <div class="input-group-prepend">
            <div class="input-group-text">
              <input
                type="checkbox"
                class="cursor-pointer"
                id="showlinkedobjectincheckbox{{ i }}"
                name="showlinkedobjectincheckbox{{ i }}"
                [(ngModel)]="linkedObject.checked"
              />
            </div>
          </div>
          <label
            class="form-control cursor-pointer"
            for="showlinkedobjectincheckbox{{ i }}"
            title="{{ linkedObject.label }}"
            >{{ linkedObject.label }}</label
          >
        </div>
      </div>
      <div
        *ngIf="linkedObject.checked && linkedObject.isCustomObject"
        [ngClass]="
          linkedObject.checked && linkedObject.isCustomObject
            ? 'col-6'
            : 'col-12'
        "
      >
        <ng-container>
          <ng-select
            name="clone-type"
            [items]="cloneTypeOptions"
            [placeholder]="placeholder"
            [searchable]="true"
            [(ngModel)]="linkedObject.cloneType"
          ></ng-select>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="cancel()">
      {{ 'shared.terms.cancel' | translate }}
    </button>
    <button type="button" class="btn btn-success" (click)="ok()">
      {{ 'shared.terms.ok' | translate }}
    </button>
  </div>
</form>
