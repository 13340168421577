import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import {
  CloneType,
  CustomDataBaseModel,
  FieldDefinitionModel, ObjectDefinitionModel
} from '@seahorse/domain';
import { DataCollectionConfig } from '@seahorse/temp';
import { CLONEOBJECTOPTIONS } from '../../../custom-modules/models/constants';

@Component({
  selector: 'ca-generic-duplicate',
  templateUrl: './generic-duplicate.component.html',
})
export class GenericDuplicateComponent implements OnInit {
  placeholder = this.translateService.instant('shared.terms.clonetype');
  cloneTypeOptions: Array<any> = [
    {
      label: this.translateService.instant('shared.label.duplicate'),
      value: CloneType.Duplicate,
    },
    {
      label: this.translateService.instant('shared.label.link'),
      value: CloneType.Link,
    },
  ];

  @Input()
  object: CustomDataBaseModel;
  @Input()
  hasTasks: boolean;
  @Input()
  hasTaskGroups: boolean;
  @Input()
  hasFiles: boolean;
  @Input()
  hasMessages: boolean;
  @Input()
  fieldDefinitions: Record<
    string,
    FieldDefinitionModel & {
      collectionConfig?: DataCollectionConfig;
    }
  >;
  @Input()
  objectDefinition: ObjectDefinitionModel;

  cloneobjectoptions = CLONEOBJECTOPTIONS;

  showLinkedObjectInCheckbox = [];

  allObjectToBeCloned = [];

  constructor(
    private activeModal: NgbActiveModal,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.cloneobjectoptions[1].show = false;
    this.cloneobjectoptions[2].show = false;
    this.cloneobjectoptions[3].show = false;
    this.cloneobjectoptions[4].show = false;
    this.showLinkedObjectInCheckbox.forEach((object) => (object.show = false));

    const linkedObjects = Object.keys(this.object.__AdditionalData);

    linkedObjects.forEach((key) => {
      const linkedObjectTypeRegex = /^(\$customcontent)/;
      const linkedObjectType =
        this.fieldDefinitions[key].additionalDataModel.mappingKey;
      if (this.object.__AdditionalData[key] !== null) {
        if (!linkedObjectTypeRegex.test(linkedObjectType)) {
          this.showLinkedObjectInCheckbox.push({
            name: key,
            label: key,
            checked: false,
            show: true,
            cloneType: null,
            isCustomObject: false,
          });
        } else {
          this.showLinkedObjectInCheckbox.push({
            name: key,
            label: key,
            checked: false,
            show: true,
            cloneType: null,
            isCustomObject: true,
          });
        }
      }
    });

    if (this.hasFiles) {
      this.cloneobjectoptions[1].show = true;
    }

    if (this.hasMessages) {
      this.cloneobjectoptions[2].show = true;
    }

    if (this.hasTasks) {
      this.cloneobjectoptions[3].show = true;
    }

    if (this.hasTaskGroups) {
      this.cloneobjectoptions[4].show = true;
    }
  }

  cancel() {
    this.activeModal.dismiss();
  }

  ok() {
    this.allObjectToBeCloned.push(
      this.cloneobjectoptions,
      this.showLinkedObjectInCheckbox
    );
    this.activeModal.close(this.allObjectToBeCloned);
  }
}
