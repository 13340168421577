import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import {
  NotificationService,
  ResultStatus,
  ResultWrapper,
} from '@seahorse/common';
import { ChangePasswordModel, UserModel } from '@seahorse/domain';
import { ModalAction, ModalService } from '@seahorse/temp';
import { EMPTY } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { IdentityService } from '../../../core/services/identity.service';
import { UserDataService } from '../../../user/services/user-data.service';
import { IdentityChallengeComponent } from '../identity-challenge/identity-challenge.component';
import { MfaChallengeComponent } from '../mfa-challenge/mfa-challenge.component';

@Component({
  selector: 'ca-user-profile',
  templateUrl: './user-profile.component.html',
})
export class UserProfileComponent {
  changePasswordModel: ChangePasswordModel = new ChangePasswordModel();
  @Input() user: UserModel;

  currentForm: FormType = 0;
  isLoading = false;
  isSubmitted = false;
  passwordChanged = true;
  usernameExists = false;

  constructor(
    private userDataService: UserDataService,
    private identityService: IdentityService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private _modalService: ModalService
  ) {}

  changePassword() {
    if (!this.identityService.isMfaEnabled) {
      this.userDataService
        .changePassword(this.changePasswordModel)
        .subscribe((response) => this.onPasswordChange(response));

      return;
    }

    this._modalService
      .openComponentModal<MfaChallengeComponent>(
        {
          component: MfaChallengeComponent,
        },
        'account.challenge.title'
      )
      .pipe(
        mergeMap((result) => {
          const componentRef = result.componentRef as MfaChallengeComponent;

          if (result.action === ModalAction.Close) {
            return EMPTY;
          }

          return componentRef.submitChallenge().pipe(
            filter((isSuccess) => isSuccess === true),
            mergeMap(() =>
              this.userDataService.changePassword(this.changePasswordModel)
            )
          );
        })
      )
      .subscribe((response) => {
        this._modalService.closeModals();
        this.onPasswordChange(response);
      });
  }

  private onPasswordChange(response: ResultWrapper<boolean>) {
    this.passwordChanged = response.result;

    if (response.result) {
      this.notificationService.showSuccess(
        this.translateService.instant('user.passwordChanged'),
        ''
      );
      this.identityService.clearIdentity();
    }
  }

  updateProfile() {
    if (!UserModel.validate(this.user)) {
      this.isSubmitted = true;
      return;
    }
    this.isLoading = true;
    this.userDataService.updateProfile(this.user).subscribe(
      (response) => {
        if (response.status === ResultStatus.OK && response.hasResult) {
          this.notificationService.showSuccess(
            this.translateService.instant('shared.terms.updateSuccess'),
            this.translateService.instant('shared.terms.success')
          );

          this.usernameExists = false;
          this.identityService.identity = response.result;
        }

        if (response.status === ResultStatus.UserFault) {
          this.usernameExists = true;
        }
      },
      () => {
        //
      },
      () => {
        this.isLoading = false;
      }
    );
  }
}

export enum FormType {
  UserProfile,
  ChangePassword,
}
