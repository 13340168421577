import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import {
  RouteCalculatorRequestModel,
  RouteCalculatorResponseModel,
} from '@seahorse/domain';
import { MapDataService } from '@seahorse/domain';
import { Subscription } from 'rxjs';
import { PortModel } from '@seahorse/domain';

@Component({
  selector: 'ca-map-route-calculator',
  templateUrl: 'route-calculator.component.html',
})
export class MapRouteCalculatorComponent implements OnDestroy {
  private subscriptions$ = Array<Subscription>();
  private selectedFromPort = new PortModel();
  private selectedToPort = new PortModel();

  @Input() speed = 20;
  @Output() calculatedRoutes = new EventEmitter<any>();

  routes: RouteCalculatorResponseModel[] = null;

  get fromPort() {
    return this.selectedFromPort;
  }
  set fromPort(input) {
    this.selectedFromPort = input;
    if (
      this.selectedFromPort.centerPoint &&
      this.selectedFromPort.centerPoint.latitude
    ) {
      this.search.from = {
        latitude: this.selectedFromPort.centerPoint.latitude,
        longitude: this.selectedFromPort.centerPoint.longitude,
      };
    }
  }
  get toPort() {
    return this.selectedToPort;
  }
  set toPort(input) {
    this.selectedToPort = input;
    if (
      this.selectedToPort.centerPoint &&
      this.selectedToPort.centerPoint.latitude
    ) {
      this.search.to = {
        latitude: this.selectedToPort.centerPoint.latitude,
        longitude: this.selectedToPort.centerPoint.longitude,
      };
    }
  }
  search: RouteCalculatorRequestModel = new RouteCalculatorRequestModel();

  constructor(private mapData: MapDataService) {}

  ngOnDestroy() {
    this.subscriptions$.forEach((s) => s.unsubscribe());
  }

  calculate() {
    if (
      this.search &&
      this.search.from &&
      this.search.from.latitude &&
      this.search.to &&
      this.search.to.latitude
    ) {
      this.search.speedInKnots = this.speed;
      this.subscriptions$.push(
        this.mapData.calculateRoute(this.search).subscribe(
          (response) => {
            if (response === null || response.length === 0) {
              this.routes = null;
            } else {
              this.routes = response;
            }
            this.calculatedRoutes.emit(response);
          },
          () => {},
          () => {}
        )
      );
    }
  }
}
