import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../shared/shared.module';

import { LayoutPipesModule } from '../pipes/layout-pipes.module';
import { AddCustomUIModalComponent } from './add-custom-ui-modal/add-custom-ui-modal.component';
import { AddressFormComponent } from './address-form/address-form.component';
import { DataDisplayComponent } from './data-display/data-display.component';
import { DatePeriodPickerComponent } from './date-period-picker/date-period-picker.component';
import { DateTimePeriodPickerComponent } from './date-time-period-picker/date-time-period-picker.component';
import { ConfirmComponent } from './dialogs/confirm.component';
import { ErrorComponent } from './dialogs/error.component';
import { FieldOperatorFormComponent } from './field-operator-form/field-operator-form.component';
import { FileImportWizardComponent } from './file-import-wizard/file-import-wizard.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { FilterListComponent } from './filter-list/filter-list.component';
import { GenericMessageModalComponent } from './generic-message-modal/generic-message-modal.component';
import { HeaderComponent } from './header/header.component';
import { IconPickerDialogComponent } from './icon-picker-dialog/icon-picker-dialog.component';
import { IconPickerGridComponent } from './icon-picker-grid/icon-picker-grid.component';
import { InfiniteScrollComponent } from './infinite-scroll/infinite-scroll.component';
import { HasFeatureDirective, LoadingIndicatorComponent } from '@seahorse/ui';
import { MapDirectionIndicatorComponent } from '@seahorse/ui';
import { ModalComponent } from './modal/modal.component';
import { NotAuthorizedComponent } from './not-authorized/not-authorized.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ObjectTypesModalComponent } from './object-types-modal/object-types-modal.component';
import { OverviewHeaderComponent } from './overview-header/overview-header.component';
import { ResultWrapperErrorComponent } from './result-wrapper-error/result-wrapper-error.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SingleSelectModal } from './single-select-modal.component/single-select-modal.component';
import { StatisticsListComponent } from './statistics-list/statistics-list.component';
import { SummaryComponent } from './summary/summary.component';
import { SystemMessageComponent } from './system-message/system-message.component';
import { FileImportLinkedObjectsModalComponent } from './file-import-linked-objects-modal/file-import-linked-objects-modal.component';
import { BadgeDirective, SeahorseUiModule } from '@seahorse/ui';
import {
  DateTimePickerComponent,
  FieldFormDirective,
  FieldValuePipe,
  FileDragDropDirective,
  FileImportDialogComponent,
  FlagCodePickerDialogFormComponent,
} from '@seahorse/temp';

@NgModule({
  declarations: [
    AddressFormComponent,
    ConfirmComponent,
    DataDisplayComponent,
    DatePeriodPickerComponent,
    DateTimePeriodPickerComponent,
    ErrorComponent,
    FieldOperatorFormComponent,
    FileUploadComponent,
    FilterListComponent,
    GenericMessageModalComponent,
    HeaderComponent,
    ModalComponent,
    NotAuthorizedComponent,
    NotFoundComponent,
    OverviewHeaderComponent,
    ResultWrapperErrorComponent,
    SidebarComponent,
    SingleSelectModal,
    StatisticsListComponent,
    SummaryComponent,
    SystemMessageComponent,
    // _generics templates
    AddCustomUIModalComponent,
    IconPickerDialogComponent,
    IconPickerGridComponent,
    InfiniteScrollComponent,
    FileImportWizardComponent,
    ObjectTypesModalComponent,
    FileImportLinkedObjectsModalComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    LayoutPipesModule,
    NgbModule,
    NgSelectModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    TranslateModule,
    HasFeatureDirective,
    SeahorseUiModule,
    DateTimePickerComponent,
    FieldFormDirective,
    BadgeDirective,
    FieldValuePipe,
    FlagCodePickerDialogFormComponent,
    LoadingIndicatorComponent,
    FileDragDropDirective,
    MapDirectionIndicatorComponent,
    FileImportDialogComponent,
  ],
  exports: [
    AddressFormComponent,
    ConfirmComponent,
    DataDisplayComponent,
    DatePeriodPickerComponent,
    DateTimePeriodPickerComponent,
    ErrorComponent,
    FieldOperatorFormComponent,
    FileImportWizardComponent,
    FileUploadComponent,
    FilterListComponent,
    GenericMessageModalComponent,
    HeaderComponent,
    LoadingIndicatorComponent,
    MapDirectionIndicatorComponent,
    ModalComponent,
    NotAuthorizedComponent,
    NotFoundComponent,
    OverviewHeaderComponent,
    ResultWrapperErrorComponent,
    SidebarComponent,
    SingleSelectModal,
    StatisticsListComponent,
    SummaryComponent,
    SystemMessageComponent,
    // _generics templates
    AddCustomUIModalComponent,
    IconPickerDialogComponent,
    IconPickerGridComponent,
    InfiniteScrollComponent,
  ],
})
export class LayoutComponentsModule {}
