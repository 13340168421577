import {
  ChangeDetectorRef,
  Directive,
  Input,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroupDirective,
  FormGroupName,
  Validators,
} from '@angular/forms';
import { SeahorseFormGroup } from '../forms/form-group';
import { Subscription } from 'rxjs';

@Directive()
export abstract class BaseControl<TValue = unknown>
  implements ControlValueAccessor, OnInit, OnDestroy
{
  @Input() placeholder?: string;
  @Input() formControlName?: string;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('formControl') control: FormControl<TValue> | null = null;

  @Input() appendText?: string;
  @Input() hint?: string;

  disabled = false;
  required = false;

  private _subscriptions = new Subscription();
  private _formGroupDirective = inject(FormGroupDirective, { optional: true });
  private _formGroupNameDirective = inject(FormGroupName, { optional: true });
  private _formGroup?: SeahorseFormGroup;

  private _value: TValue | null = null;
  get value() {
    return this._value;
  }
  set value(value: TValue | null) {
    this._value = value;
  }

  protected cdRef = inject(ChangeDetectorRef);
  onTouchedCb?: () => void;
  onChangeCb?: (value: unknown) => TValue;

  ngOnInit() {
    if (!this.control) {
      this.getControl();
    }
  }

  private getControl() {
    if (!this._formGroupDirective || !this.formControlName) {
      return;
    }

    this._formGroup = this._formGroupDirective.form as SeahorseFormGroup;

    const fullName = this._formGroupNameDirective?.name
      ? `${this._formGroupNameDirective.name}.${this.formControlName}`
      : this.formControlName;

    if (!fullName) {
      throw new Error(
        'Control not found. Please provide formControlName or formGroupName'
      );
    }

    this.control = this._formGroupDirective?.control.get(
      fullName
    ) as FormControl;

    if (this.control) {
      this.setDisabledState(this.control.disabled);
      this.required = this.control.hasValidator(Validators.required);
    }

    this._subscriptions.add(
      this._formGroup?.detectChanges$.subscribe(() =>
        this.cdRef.detectChanges()
      )
    );
  }

  writeValue(value: TValue) {
    this.value = value;
  }

  emitOnChange(value: TValue) {
    if (this.onChangeCb) {
      this.onChangeCb(value);
    }

    if (this.onTouchedCb) {
      this.onTouchedCb();
    }
  }

  showError() {
    if (!this.control) {
      return false;
    }

    return this.control.invalid && this._formGroup?.isSubmitted;
  }

  getError(): string {
    if (!this.formControlName || !this.control || !this.control.errors) {
      return '';
    }

    const keys = Object.keys(this.control.errors);

    if (keys.length === 0) {
      return '';
    }

    const errorKey = keys[0];

    switch (errorKey) {
      case 'required':
        return 'forms.validation.required';
      default:
        return (
          this._formGroup?.validation?.[this.formControlName]?.[errorKey] ||
          'forms.validation.invalid'
        );
    }
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  registerOnTouched(fn: () => void) {
    this.onTouchedCb = fn;
  }

  registerOnChange(fn: (value: unknown) => TValue) {
    this.onChangeCb = fn;
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}
