import { MenuItem } from '../../../layout/models/menu-item.model';

export const navigation: MenuItem[] = [
  {
    route: 'dashboard',
    name: 'layout.sidebar.dashboard',
    fontIcon: 'fa-th-large',
    order: 0,
  },
  {
    route: 'map',
    name: 'layout.sidebar.map',
    fontIcon: 'fa-map-marked-alt',
    permissions: 'map',
    order: 1,
  },
  {
    route: 'nautical/vessels',
    name: 'layout.sidebar.vessels',
    fontIcon: 'fa-ship',
    permissions: 'ships',
    order: 2,
  },
  {
    route: 'nautical/visit',
    name: 'layout.sidebar.visits',
    fontIcon: 'fa-exchange-alt',
    permissions: 'port_visits',
    order: 3,
  },
  {
    route: 'bts-coasting/inbox',
    name: 'BTS',
    permissions: 'bts',
    iconUrl: '../../../../assets/bts-coasting/icons/logo.png',
    order: 4,
  },
  {
    route: 'companies/overview',
    name: 'layout.sidebar.companies',
    fontIcon: 'fa-address-book',
    permissions: 'companies',
    order: 5,
  },
  {
    route: 'custom-ui/cvv/activities',
    name: 'layout.sidebar.activities',
    fontIcon: 'fa-link',
    permissions: 'custom_ui.cvv',
    order: 6,
  },
  {
    route: 'custom-ui/kp/activities',
    name: 'layout.sidebar.activities',
    fontIcon: 'fa-table',
    permissions: 'custom_ui.koperenploeg',
    order: 7,
  },
  {
    route: 'custom-ui/zeehaven-overview',
    name: 'Zeehaven IJmuiden',
    permissions: 'custom_ui.zeehaven_ijmuiden',
    iconUrl: '../../../../assets/images/logos/zeehaven-ijmuiden-square.svg',
    order: 8,
  },
  {
    route: 'custom-ui/beverwijk/overview',
    name: 'Haven Beverwijk',
    permissions: 'custom_ui.haven_beverwijk',
    fontIcon: 'fa-anchor',
    order: 8,
  },
  {
    route: 'invoicing-and-tariffs/invoicing',
    name: 'layout.sidebar.invoicing',
    fontIcon: 'fa-euro-sign',
    permissions: 'invoices',
    order: 9,
    isSecondary: true,
  },
  {
    route: 'reports',
    name: 'layout.sidebar.reports',
    fontIcon: 'fa-chart-line',
    permissions:
      'reports.reports_nautical,reports.reports_financial,user_management.reports',
    order: 10,
    isSecondary: true,
  },
  {
    route: 'tasks/overview',
    name: 'layout.sidebar.tasks',
    fontIcon: 'fa-tasks',
    permissions: 'tasks',
    order: 11,
    isSecondary: true,
  },
  {
    route: 'port-call-file/overview',
    name: 'layout.sidebar.portCallFile',
    fontIcon: 'fa-file-alt',
    permissions: 'custom_ui.port_call_file',
    order: 12,
  },
  {
    route: 'order-registrations/overview',
    name: 'layout.sidebar.orders',
    fontIcon: 'fa-shopping-cart',
    permissions: 'custom_ui.order_registrations',
    order: 13,
  },
  {
    route: 'storage/home',
    name: 'layout.sidebar.storage',
    fontIcon: 'fa-coins',
    permissions: 'custom_data.custom_data_review',
    order: 14,
    isSecondary: true,
  },
  {
    route: 'admin',
    name: 'layout.sidebar.admin',
    fontIcon: 'fa-cogs',
    permissions:
      'invoices.invoices_configure,custom_data.custom_data_admin,reports_customdata_admin,user_management.reports',
    order: 15,
    isSecondary: true,
  },
  {
    route: 'su/home',
    name: 'layout.sidebar.admin',
    fontIcon: 'fa-user-secret',
    permissions:
      'superusers.superuser_nautical_geography,superusers.superuser_nautical_port_visits,superusers.superuser_nautical_ship',
    order: 16,
    isSecondary: true,
  },
];
