import {
  Component,
  EmbeddedViewRef,
  OnDestroy,
  OnInit,
  Type,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  DashboardLayoutComponent,
  PanelModel,
} from '@syncfusion/ej2-angular-layouts';
import { PreferenceModel } from '../../../preference/models/preference.model';
import { PreferenceDataService } from '../../../preference/services/preference-data.service';
import { DynamicsService, NotificationService } from '@seahorse/common';
import { WidgetDataService } from '../../../widgets/services/widget-data.service';
import { InvoiceWidgetComponent } from '../../../widgets/components/invoice-widget/invoice-widget.component';
import { WidgetModel, WidgetTypes } from '../../../widgets/models/widget.model';
import { Subscription } from 'rxjs';
import { TaskWidgetComponent } from '../../../widgets/components/task-widget/task-widget.component';
import { ModalAction, ModalResponse, ModalService } from '@seahorse/temp';
import { WidgetDesignerComponent } from '../../../widgets/components/widget-designer/widget-designer.component';
import { AreYouSureModalComponent } from '@seahorse/ui';

@Component({
  selector: 'ca-company-custom-dashboard',
  templateUrl: './company-custom-dashboard.component.html',
  styleUrls: ['./company-custom-dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CompanyCustomDashboardComponent implements OnInit, OnDestroy {
  @ViewChild('dashboard')
  dashboard: DashboardLayoutComponent;
  cellSpacing: number[] = [20, 20];
  widgetsOnDashboardIds: string[] = [];
  dashboardPreference: PreferenceModel = null;
  enableCustomDashboard = false;

  private subscriptions = new Subscription();

  constructor(
    private _preferenceDataService: PreferenceDataService,
    private _widgetDataService: WidgetDataService,
    private _dynamicsService: DynamicsService,
    private _modalService: ModalService,
    private _notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this._preferenceDataService
        .getDashboardPreference(true)
        .subscribe((result) => {
          if (result.hasResult) {
            this.enableCustomDashboard = true;
            this.dashboardPreference = result.result;
            if (result.result.fieldValue) {
              JSON.parse(result.result.fieldValue).forEach((x) => {
                this.widgetsOnDashboardIds.push(x.id);
                this.dashboard.addPanel({
                  id: x.id,
                  sizeX: x.sizeX,
                  sizeY: x.sizeY,
                  row: x.row,
                  col: x.col,
                });

                this.subscriptions.add(
                  this._widgetDataService.getById(x.id).subscribe((res) => {
                    if (res.hasResult) {
                      this.renderWidget(res.result);
                    } else {
                      this.removeWidget(x.id);
                    }
                  })
                );
              });
            }
          } else {
            this.enableCustomDashboard = false;
          }
        })
    );
  }

  widgetChange() {
    if (this.dashboard.panels?.length) {
      this.dashboardPreference.fieldValue = [];
      this.dashboard.panels.forEach((panel: PanelModel) => {
        this.dashboardPreference.fieldValue.push({
          id: panel.id.toString(),
          sizeX: panel.sizeX,
          sizeY: panel.sizeY,
          row: panel.row,
          col: panel.col,
          enabled: panel.enabled,
          minSizeX: panel.minSizeX,
          minSizeY: panel.minSizeY,
          maxSizeX: panel.maxSizeX,
          maxSizeY: panel.maxSizeY,
        });
      });

      this.dashboardPreference.fieldValue = JSON.stringify(
        this.dashboardPreference.fieldValue
      );
    } else {
      this.dashboardPreference.fieldValue = null;
    }

    this.dashboardPreference.isCompanyPreference = true;

    this._preferenceDataService.save(this.dashboardPreference).subscribe();
  }

  addWidget(widget: WidgetModel) {
    let panel: PanelModel = {
      id: widget.id.toString(),
      sizeX: 1,
      sizeY: 1,
      row: 0,
      col: 0,
    };

    if (widget.configuration['panel']) {
      panel = { ...panel, ...widget.configuration['panel'] };
    }

    this.dashboard.addPanel(panel);

    this.widgetsOnDashboardIds.push(widget.id.toString());

    this.renderWidget(widget, true);

    this.widgetChange();
  }

  removeWidget(id: string) {
    this.subscriptions.add(
      this._modalService.openModal(AreYouSureModalComponent).subscribe({
        next: (y) => {
          if (y) {
            this.dashboard.removePanel(id);
            this.widgetChange();
            this.widgetsOnDashboardIds = this.widgetsOnDashboardIds.filter(
              (x) => x !== id
            );
          }
        },
        error: (e) => {
          this._notificationService.displayErrorNotification(e);
        },
      })
    );
  }

  handleCustomDashboardPreference() {
    if (this.enableCustomDashboard) {
      const newDashboardPreference: PreferenceModel = {
        id: null,
        name: 'custom-dashboard',
        category: 'custom-dashboard',
        fieldType: 1,
        fieldValue: null,
        isCompanyPreference: true,
      };
      this.subscriptions.add(
        this._preferenceDataService
          .save(newDashboardPreference)
          .subscribe((res) => (this.dashboardPreference = res.result))
      );
    } else {
      this.subscriptions.add(
        this._preferenceDataService
          .deleteDashboardPreferences()
          .subscribe(() => {
            this.dashboardPreference = null;
            this.widgetsOnDashboardIds = [];
          })
      );
    }
  }

  renderWidget(widget: WidgetModel, fromLibrary: boolean = false) {
    let component: Type<unknown>;
    let props: object = {
      widget: fromLibrary
        ? widget.configuration
        : JSON.parse(widget.configuration),
    };
    switch (widget.type) {
      case WidgetTypes.Invoice:
        component = InvoiceWidgetComponent;
        props = { ...props, ...{ noBorder: true } };
        break;
      case WidgetTypes.Task:
        component = TaskWidgetComponent;
        break;
    }

    const componentRef = this._dynamicsService.createComponent({
      component: component,
      props: props,
    });
    const panelContent = document.getElementById(widget.id + '_content');
    const card = document.createElement('div');
    card.classList.add('card');
    panelContent.appendChild(card);
    const cardHeader = document.createElement('div');
    cardHeader.classList.add('card-header');
    card.appendChild(cardHeader);
    const buttonList = document.createElement('div');
    buttonList.classList.add('btn-list');
    cardHeader.appendChild(buttonList);
    const editButton = document.createElement('button');
    editButton.id = `${widget.id}_edit`;
    editButton.type = 'button';
    editButton.classList.add('btn', 'btn-sm', 'btn-outline-secondary');
    editButton.addEventListener('click', () => this.editWidget(widget));
    buttonList.appendChild(editButton);
    const editIcon = document.createElement('i');
    editIcon.classList.add('fa-regular', 'fa-pencil', 'fa-fw');
    editButton.appendChild(editIcon);
    const removeButton = document.createElement('button');
    removeButton.id = `${widget.id}_remove`;
    removeButton.type = 'button';
    removeButton.classList.add('btn', 'btn-sm', 'btn-outline-secondary');
    removeButton.addEventListener('click', () =>
      this.removeWidget(widget.id.toString())
    );
    buttonList.appendChild(removeButton);
    const removeIcon = document.createElement('i');
    removeIcon.classList.add('fa-solid', 'fa-trash', 'fa-fw');
    removeButton.appendChild(removeIcon);
    const cardBody = document.createElement('div');
    cardBody.classList.add('card-body', 'p-0');
    card.appendChild(cardBody);
    cardBody.appendChild(
      (componentRef.hostView as EmbeddedViewRef<unknown>)
        .rootNodes[0] as HTMLElement
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  createWidget() {
    this.subscriptions.add(
      this._modalService
        .openModal(WidgetDesignerComponent, null, {
          backdrop: 'static',
          size: 'xl',
        })
        .subscribe({
          next: (modal: ModalResponse<WidgetModel>) => {
            if (modal.action === ModalAction.Confirm && modal.componentRef) {
              this.addWidget(modal.componentRef);
            }
          },
          error: (e) => {
            this._notificationService.displayErrorNotification(e);
          },
        })
    );
  }

  editWidget(widget: WidgetModel) {
    if (widget.id) {
      this.subscriptions.add(
        this._modalService
          .openModal(
            WidgetDesignerComponent,
            { widget: JSON.parse(JSON.stringify(widget)) },
            { backdrop: 'static', size: 'xl' }
          )
          .subscribe({
            next: (modal: ModalResponse<WidgetModel>) => {
              if (modal.action === ModalAction.Confirm && modal.componentRef) {
                this.removeWidget(widget.id.toString());
                this.addWidget(modal.componentRef);
              }
            },
            error: (e) => {
              this._notificationService.displayErrorNotification(e);
            },
          })
      );
    }
  }
}
