export class QueryModel {
  search: string;
  searchField: string;
  pageIndex: number;
  pageSize: number;
  sortField?: string;
  sortAscending?: boolean;

  constructor(pageSize: number = null) {
    this.search = '';
    this.searchField = '';
    this.sortAscending = true;
    this.pageIndex = 0;
    this.pageSize = pageSize ? pageSize : 20;
    this.sortField = '';
  }
}
