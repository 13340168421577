<div class="popup">
  <header class="popup-header">
    <strong>Update centerpoint</strong
    ><button
      class="ml-5 btn btn-success btn-sm"
      type="button"
      (click)="updateCenterpoint()"
    >
      <i class="fa-regular fa-check"></i>
    </button>
  </header>
  <div class="popup-content">
    <strong>Lng: {{ coordinates.longitude }}</strong>
    <strong>Lat: {{ coordinates.latitude }}</strong>
  </div>
</div>
