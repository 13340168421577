import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ExportTemplateModel } from '../../models/export-template.model';
import { CustomDataSearchFieldModel, FileService } from '@seahorse/domain';
import { ContentDistributionDataService } from '../../services/content-distribution-data.service';
import { mimeTypes, NotificationService } from '@seahorse/common';
import { DocumentTypeDataService } from '../../services/document-type.service';
import { ExportOptionItemModel } from '../../models/content-distribtion-export-option.model';
import { map, switchMap } from 'rxjs/operators';
import { FileHelpers } from '@seahorse/common';
import { FilePreviewModel } from '@seahorse/ui';
import { ExportTemplateDataService } from '../../services/export-template.service';

@Component({
  selector: 'ca-template-files',
  templateUrl: './template-files.component.html',
  styleUrls: ['template-files.component.scss'],
})
export class TemplateFilesComponent {
  private _exportOption: ExportOptionItemModel;
  @Input() templates?: ExportTemplateModel[] = [];
  @Input() files?: FilePreviewModel[] = [];
  @Input() objectIds?: any[];
  @Input() objectType: string;
  @Input() searchFields: CustomDataSearchFieldModel[] = null;

  @Input() get exportOption(): ExportOptionItemModel {
    return this._exportOption;
  }
  set exportOption(input: ExportOptionItemModel) {
    this._exportOption = input;
  }

  @Output() delete = new EventEmitter<any>();

  constructor(
    private filesData: FileService,
    private contentDistributionService: ContentDistributionDataService,
    private documentTypeDataService: DocumentTypeDataService,
    private templateData: ExportTemplateDataService,
    private notification: NotificationService
  ) {}

  fileDownload(file: any) {
    this.filesData.getFileById(file.id).subscribe((file) => {
      FileHelpers.downloadDocumentBlob(
        this.filesData.base64ToArrayBuffer(file.result.content),
        file.result.mimeType,
        file.result.name
      );
    });
  }

  templateDownload(temp: any) {
    this.templateData
      .getById(temp.id)
      .pipe(
        switchMap((template) => {
          return this.documentTypeDataService
            .getById(template.result.documentTypeId)
            .pipe(
              switchMap((x) => {
                const documentType = {
                  distributionService: x.result.distributionService,
                  id: x.result.id,
                  name: x.result.name,
                  systemCode: x.result.systemCode,
                  requiresRecipients: null,
                };

                const includedTemplateExportOption = JSON.parse(
                  JSON.stringify(this.exportOption)
                );

                includedTemplateExportOption.documentType = documentType;
                includedTemplateExportOption.templateId = template.result.id;
                includedTemplateExportOption.templateName =
                  template.result.name;

                return this.contentDistributionService.generateExport(
                  includedTemplateExportOption,
                  [temp.objectId],
                  this.objectType,
                  this.searchFields
                );
              })
            );
        })
      )
      .subscribe();
  }

  filePreview(file: any) {
    this.filesData.getFileById(file.id).subscribe((file) => {
      if (file.hasResult) {
        FileHelpers.openDocumentBlob(
          this.filesData.base64ToArrayBuffer(file.result.content),
          file.result.mimeType
        );
      } else {
        this.notification.displayErrorNotification(file.messages);
      }
    });
  }

  templatePreview(temp: any) {
    if (temp.documentType.defaultExtension !== 'pdf') {
      this.templateDownload(temp);
      return;
    }

    this.templateData
      .getById(temp.id)
      .pipe(
        switchMap((template) => {
          return this.documentTypeDataService
            .getById(template.result.documentTypeId)
            .pipe(
              switchMap((doc) => {
                const documentType = {
                  distributionService: doc.result.distributionService,
                  id: doc.result.id,
                  name: doc.result.name,
                  systemCode: doc.result.systemCode,
                  defaultExtension: doc.result.defaultExtension,
                  requiresRecipients: null,
                };

                const includedTemplateExportOption = JSON.parse(
                  JSON.stringify(this.exportOption)
                );

                includedTemplateExportOption.documentType = documentType;
                includedTemplateExportOption.templateId = template.result.id;
                includedTemplateExportOption.templateName =
                  template.result.name;

                return this.contentDistributionService.generateExportPreview(
                  includedTemplateExportOption,
                  [temp.objectId],
                  this.objectType,
                  this.searchFields,
                  mimeTypes[documentType.defaultExtension]
                );
              })
            );
        })
      )
      .subscribe((blob) => FileHelpers.openDocument(blob));
  }

  fileDelete(id: any) {
    this.delete.emit(id);
  }
}
