import { Injectable } from '@angular/core';
import { EnvService } from '@seahorse/common';

@Injectable()
export class CaEnvService extends EnvService {
  apiHost = '';
  apiHostV2 = '';
  apiHostSnelstart = '';
  apiHostBts = '';
  applicationInsightsKey = '';
  enableDebug = true;
  mapBoxAccessToken = '';
  theme = '';
  messageHost = '';
  latestVersion = '';
  scopeUri = '';
  tenantId = '';
  clientId = '';
  authority = '';
  redirectUrl = '';
  useContentV2 = false;
  postLogoutRedirectUri = '';
  sfKey = '';
}
