import { Injectable } from '@angular/core';
import { ResultWrapper } from '@seahorse/common';
import { Observable } from 'rxjs/internal/Observable';
import { DataContextService } from '@seahorse/common';
import { TaskGroupModel } from '../models/task-group.model';
import { ObjectRequestModel } from '../../custom-data/models/object-request.model';
import { CloneLinkedObjectsRequest } from '@seahorse/domain';

@Injectable({ providedIn: 'root' })
export class TaskGroupDataService {
  constructor(private dataContext: DataContextService) {}

  getAll(): Observable<ResultWrapper<Array<TaskGroupModel>>> {
    return this.dataContext.get<Array<TaskGroupModel>>(
      'task-group?pindex=-1&psize=-1'
    );
  }

  getByObject(
    objectType: string,
    objectId: string,
    withTasks?: boolean
  ): Observable<ResultWrapper<Array<TaskGroupModel>>> {
    const objectRequest = {
      objectType: objectType,
      objectId: objectId,
    } as ObjectRequestModel;
    const url = withTasks
      ? 'task-group/object-with-tasks'
      : 'task-group/object';
    return this.dataContext.post<Array<TaskGroupModel>>(url, objectRequest);
  }

  getExpired(): Observable<ResultWrapper<Array<TaskGroupModel>>> {
    return this.dataContext.get<Array<TaskGroupModel>>('task-group/expired');
  }

  getById(id: TaskGroupModel['id']): Observable<ResultWrapper<TaskGroupModel>> {
    return this.dataContext.get<TaskGroupModel>(`task-group/${id}`);
  }

  add(taskGroup: TaskGroupModel): Observable<ResultWrapper<TaskGroupModel>> {
    return this.dataContext.post<TaskGroupModel>('task-group', taskGroup);
  }

  update(taskGroup: TaskGroupModel): Observable<ResultWrapper<TaskGroupModel>> {
    return this.dataContext.put<TaskGroupModel>(
      `task-group/${taskGroup.id}/retain-tasks`,
      taskGroup
    );
  }

  updateWithTasks(
    taskGroup: TaskGroupModel
  ): Observable<ResultWrapper<TaskGroupModel>> {
    return this.dataContext.put<TaskGroupModel>(
      `task-group/${taskGroup.id}`,
      taskGroup
    );
  }

  updateUngrouped(
    ungrouped: TaskGroupModel
  ): Observable<ResultWrapper<TaskGroupModel>> {
    return this.dataContext.put<TaskGroupModel>(
      'task-group/ungrouped',
      ungrouped
    );
  }

  delete(id: TaskGroupModel['id']): Observable<ResultWrapper<TaskGroupModel>> {
    return this.dataContext.delete<TaskGroupModel>(
      `task-group/${id}/retain-tasks`
    );
  }

  deleteWithTasks(
    id: TaskGroupModel['id']
  ): Observable<ResultWrapper<TaskGroupModel>> {
    return this.dataContext.delete<TaskGroupModel>(`task-group/${id}`);
  }

  clone(clonedObjectParams: CloneLinkedObjectsRequest) {
    return this.dataContext.post<TaskGroupModel>(
      'task-group/clone',
      clonedObjectParams
    );
  }
}
