import { TranslateService } from '@ngx-translate/core';
import { ObjectKeyMappingModel } from '../../custom-data/models/object-key-mapping.model';
import * as _ from 'underscore';

import {
  FieldDefinitionModel,
  FieldVisibility,
} from './field-definition.model';
import { FieldType } from './field-type-definition.model';

export class SystemObjectDefinitionModel {
  id: number;
  name: string;
  systemCode: string;
  isAvaible: boolean;
  templateCode: string | null;
  groupName: string | null;
  description: string | null;
  addConfig: DataObjectAddConfigModel | null;
  editConfig: DataObjectEditConfigModel | null;
  deleteConfig: DataObjectDeleteConfigModel | null;
  exportConfig: DataObjectExportConfigModel | null;
  simpleSearchConfig: DataObjectSearchConfigModel | null;
  advancedSearchConfig: DataObjectSearchConfigModel | null;
  customerPortalConfig: DataObjectCustomerPortalConfigModel | null;
  displayName: string | null;

  constructor(override?: Partial<SystemObjectDefinitionModel>) {
    if (override) {
      Object.assign(this, override);
    }
  }
}

export class SystemObjectDefinitionDetailsModel extends SystemObjectDefinitionModel {
  scope: any;
  organisationId: any;
  baseObjectDefinitionId: SystemObjectDefinitionModel['id'];
  baseObjectDefinition: SystemObjectDefinitionModel;
  objectFieldDefinitions: Array<FieldDefinitionModel>;

  constructor(override?: Partial<SystemObjectDefinitionDetailsModel>) {
    super();
    if (override) {
      Object.assign(this, override);
    }
  }
}

export class ObjectDefinitionModel {
  scope: any;
  id: number;
  name: string;
  systemCode: string;
  mappingKey: string;
  isAvaible: boolean;
  templateCode: string;
  groupName: string;
  description: string;
  organisationId: any;
  baseObjectDefinitionId: number;
  baseObjectDefinition: ObjectDefinitionModel;
  displayName: string;
  addConfig: DataObjectAddConfigModel;
  editConfig: DataObjectEditConfigModel;
  deleteConfig: DataObjectDeleteConfigModel;
  exportConfig: DataObjectExportConfigModel;
  connectedDataConfig: DataObjectConnectedDataConfigModel;
  customerPortalConfig: DataObjectCustomerPortalConfigModel;
  inboxDataConfig: DataObjectInboxDataConfigModel;
  simpleSearchConfig: DataObjectSearchConfigModel;
  advancedSearchConfig: DataObjectSearchConfigModel;
  objectFieldDefinitions: FieldDefinitionModel[];
  importAllowed: boolean;
  bindingValue?: string;

  static getAllFieldDefinitions(
    model: ObjectDefinitionModel,
    includeDefaultSystemField?: boolean,
    visibilities?: FieldVisibility[],
    translateService?: TranslateService
  ): FieldDefinitionModel[] {
    let fields: FieldDefinitionModel[] = [];
    if (model) {
      fields = _.union(fields, model.objectFieldDefinitions);

      let currentModel = model;
      while (currentModel.baseObjectDefinition != null) {
        currentModel = currentModel.baseObjectDefinition;
        _.each(
          currentModel.objectFieldDefinitions,
          (field: FieldDefinitionModel) => {
            if (
              !_.some(
                fields,
                (f: FieldDefinitionModel) => f.systemCode === field.systemCode
              )
            ) {
              fields.push(field);
            }
          }
        );
      }
    }

    if (includeDefaultSystemField === true) {
      fields = _.union(
        this.convertSystemFieldsToFieldDefinitions(model, translateService),
        fields
      );
    }

    return !visibilities
      ? fields
      : fields.filter((f) => visibilities.includes(f.visibility));
  }

  static convertSystemFieldsToFieldDefinitions(
    model: ObjectDefinitionModel,
    translateService: TranslateService
  ): FieldDefinitionModel[] {
    let systemFields = [];

    if (model !== undefined && model !== null) {
      let json = null;
      if (model.templateCode) {
        try {
          json = JSON.parse(model.templateCode);
        } catch {
          json = null;
        }
      }
      if (json && json['sort'] && json['sort']['fields']) {
        systemFields = this.convertToFieldDefinition(
          json['sort']['fields'],
          translateService,
          model
        );
      } else {
        systemFields = this.convertToFieldDefinition(
          [
            { code: '__CreatedOn', order: -4 },
            { code: '__CreatedBy', order: -3 },
            { code: '__LastEditedOn', order: -2 },
            { code: '__LastEditedBy', order: -1 },
            { code: '__DisplayName' },
            { code: '__DataObjectKey' },
          ],
          translateService,
          model
        );
      }
    }

    return systemFields;
  }

  static emptyObjectWithDefaults(
    model: ObjectDefinitionModel,
    translateService?: TranslateService
  ): any {
    const result = {};

    _.each(
      this.getAllFieldDefinitions(model, true, null, translateService),
      (field) => {
        if (field.defaultValue && field.defaultValue !== '') {
          switch (field.fieldType) {
            default:
              result[field.systemCode] = field.defaultValue;
              break;
          }
        } else {
          result[field.systemCode] = null;
        }
      }
    );

    return result;
  }

  static hasLinkedObject(
    model: ObjectDefinitionModel,
    objectKey: string
  ): boolean {
    return _.any(
      this.getAllFieldDefinitions(model, true),
      (anyItem: FieldDefinitionModel) =>
        anyItem.fieldCode === '_linkedobject' &&
        anyItem.additionalDataModel &&
        anyItem.additionalDataModel.mappingKey &&
        anyItem.additionalDataModel.mappingKey === objectKey
    );
  }

  private static convertToFieldDefinition(
    fields,
    translateService,
    model: ObjectDefinitionModel
  ) {
    const definitions = [];
    _.each(fields, (f) => {
      const definition = new FieldDefinitionModel();
      definition.systemCode = f['code'];
      definition.name = translateService
        ? translateService.instant(`customData.model.${f['code']}`)
        : f['code'];
      definition.sortOrder = !f['order'] ? 999 : f['order'];

      switch (f['code']) {
        case '__CreatedOn':
        case '__LastEditedOn':
          definition.fieldTypeDefinitionId = 3; // datetime field
          definition.fieldType = FieldType.DateTime;
          break;

        case '__CreatedBy':
        case '__LastEditedBy':
          definition.fieldTypeDefinitionId = 12; // user field
          definition.fieldType = FieldType.SingleLineText;
          break;

        case '__AdditionalData':
        case '__DataFieldFormatting':
          definition.fieldType = FieldType.SingleLineText;
          break;

        case '__DataObjectKey':
          definition.fieldType = FieldType.SingleLineText;
          definition.name = translateService
            ? `${model.name} ${translateService.instant('shared.terms.type')}`
            : model.name;
          break;

        default:
          definition.fieldTypeDefinitionId = 1;
          definition.fieldType = FieldType.SingleLineText;
      }
      definitions.push(definition);
    });
    return definitions;
  }
}

export type DataObjectSeedData = {
  sourceField: string;
  targetField: string;
  defaultValue?: string;
}[];

export type DataObjectSeedDataInventory = Record<string, DataObjectSeedData>;

export interface DataObjectAddConfigModel {
  fields: string[];
  type: DataObjectAddType;
  seedData?: DataObjectSeedDataInventory;
  hasTaskGroup?: boolean;
}

export interface DataObjectCustomerPortalConfigModel {
  relationTypes: { [key: string]: CustomerPortalFieldConfig[] };
}

export interface CustomerPortalFieldConfig {
  fieldName: string;
  fieldVisibility: CustomerPortalFieldVisibility;
  isRequired: boolean;
  isReadonly: boolean;
  alternativeName?: string;
}

export enum CustomerPortalFieldVisibility {
  Visible,
  Hidden,
}

export enum DataObjectAddType {
  Controlled,
  Anywhere,
}

export interface ConnectedDataInformationModel {
  informationType: ConnectedDataInformationTypes;
  targetObjectKey: string;
}

export enum ConnectedDataInformationTypes {
  Ids = 0,
}

export interface DataObjectEditConfigModel {
  type: DataObjectEditType;
}

export enum DataObjectEditType {
  Inline,
  Form,
  Modal,
}

export interface DataObjectDeleteConfigModel {
  type: DataObjectDeleteType;
  remarksRequired: boolean;
}

export enum DataObjectDeleteType {
  Delete,
  Disable,
  SetNull,
  NoAction,
}

export interface DataObjectConnectedDataConfigModel {
  connectedObjectInformation: ConnectedDataInformationModel[];
}

export interface DataObjectExportConfigModel {
  isAllowed: boolean;
  distribution: DataObjectDistributionConfigModel;
}

export interface DataObjectDistributionAddressModel {
  value: string;
  distributionService: string;
  config: string;
}

export interface DataObjectDistributionConfigModel {
  isAllowed: boolean;
  addresses: DataObjectDistributionAddressModel[];
}

export interface DataObjectInboxDataConfigModel {
  autoApproval: boolean;
}

export interface DataObjectSearchConfigModel {
  fields: string[];
  rangeFields: string[];
}

export class ObjectDefinitionConfigurationModel {
  sort: ObjectDefinitionSortFieldsModel;
  link: LinkMappingModel;

  constructor() {
    this.sort = null;
    this.link = null;
  }
}

export class ObjectDefinitionSortFieldsModel {
  fields: SortFieldModel[];

  constructor() {
    this.fields = [];
  }
}

export class SortFieldModel {
  code: string;
  order: number;

  constructor(code: string, order: number) {
    this.code = code;
    this.order = order;
  }
}

export class LinkMappingModel {
  allowTolink: boolean;
  mapping: ObjectKeyMappingModel;

  constructor() {
    this.allowTolink = false;
    this.mapping = null;
  }
}
