<div class="card" *hasPermission="'companies.company_contacts'">
  <div class="card-header">
    {{ 'companies.contact.contacts' | translate }}
    <div class="btn-list">
      <button
        *hasPermission="'companies.company_contacts_add'"
        (click)="onEvent.emit({ action: 'editContact' })"
        type="button"
        class="btn btn-outline-secondary btn-sm"
      >
        <i class="fa-regular fa-plus fa-fw"></i>
        {{ 'shared.terms.add' | translate }}
      </button>
      <!-- <button [routerLink]="[ '/companies/contacts' ]" [queryParams]="{ companyid: companyId }" type="button"
        class="btn btn-outline-secondary btn-sm">
        <i class="fa-regular fa-external-link-alt fa-fw"></i>

      </button> -->
    </div>
  </div>

  <div
    class="card-body card-body-table"
    *ngIf="contacts && contacts.length > 0"
  >
    <ca-company-contacts-list
      [contacts]="contacts"
      (onEvent)="onEvent.emit($event)"
      [showHeader]="true"
    ></ca-company-contacts-list>
  </div>
  <div class="card-body" *ngIf="!contacts || contacts.length === 0">
    <div class="text-center text-muted">
      {{ 'companies.contact.noContacts' | translate }}
    </div>
  </div>
  <div
    class="card-footer card-footer-clickable"
    (click)="loadMore()"
    *ngIf="canLoadMore"
  >
    <span> {{ 'shared.terms.loadMore' | translate }} </span>
  </div>
</div>
