import { InjectionToken } from '@angular/core';

export interface SignalRMessage {
  connectionId: string;
  action: string;
  message: string;
}

export enum SIGNALR_ACTION {
  BROADCAST = 'BROADCAST',
  DISCONNECT = 'DISCONNECT',
}

export enum SIGNALR_EVENT {
  BROADCAST_MESSAGE = 'BroadcastMessage',
}

export enum SIGNALR_SERVER_METHOD {
  BROADCAST = 'Broadcast',
  DISCONNECT = 'Disconnect',
  SEND = 'Send',
}

export const HUB_URL = new InjectionToken<string>('signalR hub url');
