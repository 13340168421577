import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ZeehavenIJmuidenMovementModel } from '../../models/zeehaven-ijmuiden-movement.model';

@Component({ templateUrl: 'dos-form-picker-modal.component.html' })
export class DosFormPickerModalComponent {
  @Input() dosForm: ZeehavenIJmuidenMovementModel['dosForm'];

  @Input() set dosFormDate(d: ZeehavenIJmuidenMovementModel['dosFormDate']) {
    if (d) {
      this._dosFormDate = d;
    }
  }
  get dosFormDate() {
    return this._dosFormDate;
  }

  @Output() onPickDosForm: EventEmitter<{
    dosForm: ZeehavenIJmuidenMovementModel['dosForm'];
    dosFormDate: ZeehavenIJmuidenMovementModel['dosFormDate'];
  }>;

  private _dosFormDate: ZeehavenIJmuidenMovementModel['dosFormDate'];

  constructor() {
    this.dosForm = null;
    this.dosFormDate = null;
    this.onPickDosForm = new EventEmitter<{
      dosForm: ZeehavenIJmuidenMovementModel['dosForm'];
      dosFormDate: ZeehavenIJmuidenMovementModel['dosFormDate'];
    }>();
    this._dosFormDate = null;
  }

  setNow() {
    this.dosFormDate = moment().format('YYYY-MM-DD[T]HH:mm');
  }

  setNull() {
    this.dosFormDate = null;
    this._dosFormDate = null;
  }
}
