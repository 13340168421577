<ng-container *ngIf="activities && activities.length > 0">
  <table class="table table-bordered table-sm table-responsive">
    <thead>
      <tr>
        <th>Schip</th>
        <th style="white-space: nowrap">Bijz. heden</th>
        <th style="max-width: 75px">Dienst</th>
        <th
          *ngIf="activityTypeGroup === 'meren'"
          class="text-center"
          style="min-width: 70px"
        >
          Opvaren
        </th>
        <th
          *ngIf="activityTypeGroup === 'ontm'"
          class="text-center"
          style="min-width: 70px"
        >
          Besteltijd
        </th>
        <th class="text-center" style="min-width: 70px">Van / Tot</th>
        <th *ngIf="activityTypeGroup === 'comm'">Mail</th>
        <th *ngIf="activityTypeGroup === 'mat'" title="Douane">
          {{ 'Douane' | shTruncate: 2 }}
        </th>
        <th *ngIf="activityTypeGroup === 'mat'" title="Aantal">
          {{ 'Aantal' | shTruncate: 2 }}
        </th>
        <th
          *ngIf="activityTypeGroup === 'comm' || activityTypeGroup === 'mat'"
          style="max-width: 75px"
        >
          Order/ PO nummer
        </th>
        <th style="max-width: 75px">Agent</th>
        <th>Ligplaats</th>
        <th style="max-width: 80px">Personen</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let activity of activities">
        <sh-loading-indicator
          class="text-center loader-sm loader"
          *ngIf="currentActivity && currentActivity.__Id == activity.__Id"
        ></sh-loading-indicator>
        <tr
          tempFileDragDrop
          [objectId]="activity.__Id"
          [objectType]="'$customcontent_ct-kp-activity_id'"
          [object]="activity"
          (fileUploaded)="fileUploaded($event, activity.__Id)"
          (requestObjectCreation)="activityCreationRequest(activity)"
          [class.gray-box]="
            currentActivity && currentActivity.__Id == activity.__Id
          "
        >
          <td
            placement="top"
            container="body"
            ngbTooltip="{{ activity.errors }}"
            tooltipClass="tooltip"
            [class.border-error]="activity.errors"
            style="border-top-width: 5px; min-width: 100px"
          >
            <div *ngIf="activity.subject" class="d-flex">
              <div class="flex-grow-1">
                <div
                  class="text-truncate"
                  style="max-width: 120px"
                  title="{{ activity.subject | uppercase }}"
                >
                  <strong *ngIf="!activity.$nautical_portvisit_id">
                    {{ activity.subject | uppercase }}
                  </strong>
                </div>
              </div>
            </div>
            <div
              *ngIf="activity.$nautical_ship || activity.$nautical_ship_id > 0"
              class="d-flex"
            >
              <div class="flex-grow-1">
                <div
                  class="text-truncate"
                  style="max-width: 120px"
                  title="{{
                    (activity.$nautical_ship
                      ? activity.$nautical_ship.name
                      : activity.$nautical_ship_id.toString()
                    ) | uppercase
                  }}"
                >
                  <strong *ngIf="activity.$nautical_portvisit_id" class="mr-2">
                    <a
                      class="text-primary"
                      href="/nautical/visit/{{
                        activity.$nautical_portvisit_id
                      }}"
                      target="_blank"
                      >{{
                        (activity.$nautical_ship
                          ? activity.$nautical_ship.name
                          : activity.$nautical_ship_id.toString()
                        ) | uppercase
                      }}</a
                    >
                  </strong>
                  <strong *ngIf="!activity.$nautical_portvisit_id">
                    {{
                      (activity.$nautical_ship
                        ? activity.$nautical_ship.name
                        : activity.$nautical_ship_id.toString()
                      ) | uppercase
                    }}
                  </strong>
                </div>
                <div>
                  <span class="text-muted"
                    >{{
                      activity.$nautical_ship && activity.$nautical_ship.loa
                        ? activity.$nautical_ship.loa
                        : 0
                    }}m</span
                  >
                </div>
                <!-- only for DoS activity type -->
                <div
                  *ngIf="
                    activity.activityType === activityTypes.DosFormulier.code &&
                    activity.$nautical_ship &&
                    activity.$nautical_ship.imo
                  "
                >
                  <span
                    class="text-muted"
                    title="IMO {{
                      activity.$nautical_ship && activity.$nautical_ship.imo
                    }}"
                    >{{
                      activity.$nautical_ship && activity.$nautical_ship.imo
                    }}</span
                  >
                </div>
                <!-- <div class="d-flex">
                                    <div class="pr-2"
                                        *ngIf="activity.activityType === activityTypes.Meren.code && hasEvent(activity, 'PILOTAGE_ON_ARRIVAL')">
                                        <i class="shipm8-icon shipm8-loodswezen fa-fw"
                                            title="Loods besteld bij aankomst"></i>
                                    </div>
                                    <div class="pr-2"
                                        *ngIf="activity.activityType === activityTypes.Ontmeren.code && hasEvent(activity, 'PILOTAGE_ON_DEPARTURE')">
                                        <i class="shipm8-icon shipm8-loodswezen fa-fw"
                                            title="Loods besteld bij vertrek"></i>
                                    </div>
                                    <div class="pr-2"
                                        *ngIf="activity.activityType === activityTypes.Meren.code && !hasEvent(activity, 'BOATMEN_ON_ARRIVAL')">
                                        <i class="fa-regular fa-ban fa-fw text-danger"
                                            title="De Koperen Ploeg niet besteld bij aankomst"></i>
                                    </div>
                                    <div class="pr-2"
                                        *ngIf="activity.activityType === activityTypes.Ontmeren.code && !hasEvent(activity, 'BOATMEN_ON_DEPARTURE')">
                                        <i class="fa-regular fa-ban fa-fw text-danger"
                                            title="De Koperen Ploeg niet besteld bij vertrek"></i>
                                    </div>
                                    <div class="pr-2">
                                        <i class="fa-regular fa-sign-in-alt fa-fw" title="Sluis in"></i>
                                    </div>
                                    <div class="pr-2">
                                        <i class="fa-regular fa-sign-out-alt fa-fw" title="Sluis uit"></i>
                                    </div>
                                </div> -->
                <!-- only for Materiaal activity type -->
                <div
                  *ngIf="
                    activity.activityType === activityTypes.Materiaal.code &&
                    activity.$nautical_portvisit_id
                  "
                  class="mr-2"
                >
                  <a
                    class="text-muted"
                    href="/nautical/visit/{{ activity.$nautical_portvisit_id }}"
                    target="_blank"
                    >{{
                      !activity.$nautical_portvisit
                        ? activity.$nautical_portvisit_id
                        : activity.$nautical_portvisit.referenceNumber
                    }}</a
                  >
                </div>
              </div>
              <ng-container *ngIf="activity.$nautical_ship">
                <ca-map-direction-indicator
                  [position]="activity.$nautical_ship.position"
                >
                </ca-map-direction-indicator>
              </ng-container>
            </div>
          </td>
          <td style="border-top-width: 5px">
            <button
              type="button"
              class="btn btn-hover-light btn-sm btn-inline btn-block text-left btn-text-select"
              (click)="pickRemarks(activity.remarks, 'activity', activity)"
            >
              <span class="text-muted" *ngIf="!activity.remarks">...</span>
              <span *ngIf="activity.remarks">{{ activity.remarks }}</span>
            </button>
          </td>
          <td
            class="text-truncate"
            style="border-top-width: 5px; max-width: 75px"
            [ngClass]="{ highlight: activity.activityType === 'verhalen' }"
            title="{{ activity.activityType | kpActivityTypeDisplay }}"
          >
            {{ activity.activityType | kpActivityTypeDisplay }}
            <div
              *ngIf="activity.subType"
              title="{{
                activity.subType | kpSubtypeTypeDisplay: activity.activityType
              }}"
            >
              <small class="text-muted">{{
                activity.subType | kpSubtypeTypeDisplay: activity.activityType
              }}</small>
            </div>

            <div
              class="d-flex"
              *ngIf="
                (activity.activityType === activityTypes.Ontmeren.code ||
                  activity.activityType === activityTypes.Boord.code) &&
                activity.$nautical_portvisit_id &&
                portVisitInfos[activity.$nautical_portvisit_id] &&
                portVisitInfos[activity.$nautical_portvisit_id].hosesOnBoard > 0
              "
            >
              <div class="pr-2">
                <i
                  class="fa-regular fa-exclamation-triangle fa-fw text-danger"
                  title="{{
                    'customers.kp.fields.hosesOnBoard' | translate
                  }}: {{
                    portVisitInfos[activity.$nautical_portvisit_id].hosesOnBoard
                  }}"
                ></i>
              </div>
            </div>
            <div
              class="d-flex"
              *ngIf="
                (activity.activityType === activityTypes.Ontmeren.code ||
                  activity.activityType === activityTypes.Boord.code) &&
                activity.$nautical_portvisit_id &&
                portVisitInfos[activity.$nautical_portvisit_id] &&
                portVisitInfos[activity.$nautical_portvisit_id].fendersOnBoard >
                  0
              "
            >
              <div class="pr-2">
                <i
                  class="fa-regular fa-exclamation-triangle fa-fw text-danger"
                  title="{{
                    'customers.kp.fields.fendersOnBoard' | translate
                  }}: {{
                    portVisitInfos[activity.$nautical_portvisit_id]
                      .fendersOnBoard
                  }}"
                ></i>
              </div>
            </div>
            <div
              class="d-flex"
              *ngIf="
                (activity.activityType === activityTypes.Ontmeren.code ||
                  activity.activityType === activityTypes.Boord.code) &&
                activity.$nautical_portvisit_id &&
                portVisitInfos[activity.$nautical_portvisit_id] &&
                portVisitInfos[activity.$nautical_portvisit_id]
                  .reducersOnBoard > 0
              "
            >
              <div class="pr-2">
                <i
                  class="fa-regular fa-exclamation-triangle fa-fw text-danger"
                  title="{{
                    'customers.kp.fields.reducersOnBoard' | translate
                  }}: {{
                    portVisitInfos[activity.$nautical_portvisit_id]
                      .reducersOnBoard
                  }}"
                ></i>
              </div>
            </div>
          </td>
          <td
            class="p-0"
            style="border-top-width: 5px; max-width: 65px"
            *ngIf="activityTypeGroup === 'meren'"
          >
            <div class="text-center">
              <button
                type="button"
                class="btn btn-block btn-hover-light btn-sm btn-inline btn-text-select"
                *ngIf="activity.sailedTime"
                (click)="pickDatetime(activity, 'sailedTime')"
                title="{{ activity.sailedTime | date: 'dd-MM-yyyy HH:mm' }}"
              >
                <strong *ngIf="activity.sailedTime">{{
                  activity.sailedTime | date: 'HH:mm'
                }}</strong>
              </button>
              <button
                type="button"
                class="btn btn-inline btn-sm mt-1 mr-1 btn-success"
                *ngIf="!activity.sailedTime"
                title="'Nu' registreren"
                (click)="setField(activity, 'sailedTime', '#now')"
                style="height: 26px; width: 26px"
              >
                <i class="fa-regular fa-check"></i>
              </button>
              <button
                type="button"
                class="btn btn-inline btn-sm mt-1 btn-secondary"
                *ngIf="!activity.sailedTime"
                (click)="pickDatetime(activity, 'sailedTime')"
                style="height: 26px; width: 26px"
              >
                <i class="fa-regular fa-clock"></i>
              </button>
            </div>
          </td>
          <td
            class="p-0"
            style="border-top-width: 5px; max-width: 65px"
            *ngIf="activityTypeGroup === 'ontm'"
          >
            <div class="text-center">
              <button
                type="button"
                class="btn btn-block btn-hover-light btn-sm btn-inline btn-text-select"
                *ngIf="activity.orderTime"
                (click)="pickDatetime(activity, 'orderTime')"
                title="{{ activity.orderTime | date: 'dd-MM-yyyy HH:mm' }}"
              >
                <strong *ngIf="activity.orderTime">{{
                  activity.orderTime | date: 'HH:mm'
                }}</strong>
              </button>
              <button
                type="button"
                class="btn btn-inline btn-sm mt-1 mr-1 btn-success"
                *ngIf="!activity.orderTime"
                title="'Nu' registreren"
                (click)="setField(activity, 'orderTime', '#now')"
                style="height: 26px; width: 26px"
              >
                <i class="fa-regular fa-check"></i>
              </button>
              <button
                type="button"
                class="btn btn-inline btn-sm mt-1 btn-secondary"
                *ngIf="!activity.orderTime"
                (click)="pickDatetime(activity, 'orderTime')"
                style="height: 26px; width: 26px"
              >
                <i class="fa-regular fa-clock"></i>
              </button>
            </div>
          </td>
          <td class="p-0" style="border-top-width: 5px; max-width: 65px">
            <div class="text-center seperator">
              <button
                type="button"
                class="btn btn-block btn-hover-light btn-sm btn-inline btn-text-select"
                *ngIf="activity.startsOn"
                (click)="pickDatetime(activity, 'startsOn')"
                title="{{ activity.startsOn | date: 'dd-MM-yyyy HH:mm' }}"
              >
                <strong *ngIf="activity.startsOn">{{
                  activity.startsOn | date: 'HH:mm'
                }}</strong>
              </button>
              <button
                type="button"
                class="btn btn-inline btn-sm mt-1 mr-1 btn-success"
                *ngIf="!activity.startsOn"
                title="'Nu' registreren"
                (click)="setField(activity, 'startsOn', '#now')"
                style="height: 26px; width: 26px"
              >
                <i class="fa-regular fa-check"></i>
              </button>
              <button
                type="button"
                class="btn btn-inline btn-sm mt-1 btn-secondary"
                *ngIf="!activity.startsOn"
                (click)="pickDatetime(activity, 'startsOn')"
                style="height: 26px; width: 26px"
              >
                <i class="fa-regular fa-clock"></i>
              </button>
            </div>
            <div class="text-center">
              <button
                type="button"
                class="btn btn-block btn-hover-light btn-sm btn-inline btn-text-select"
                *ngIf="activity.endsOn"
                (click)="pickDatetime(activity, 'endsOn')"
                title="{{ activity.endsOn | date: 'dd-MM-yyyy HH:mm' }}"
              >
                <strong>{{ activity.endsOn | date: 'HH:mm' }}</strong>
              </button>
              <button
                type="button"
                class="btn btn-inline btn-sm mt-1 mr-1 btn-success"
                *ngIf="activity.startsOn && !activity.endsOn"
                title="'Nu' registreren"
                (click)="setField(activity, 'endsOn', '#now')"
                style="height: 26px; width: 26px"
              >
                <i class="fa-regular fa-check"></i>
              </button>
              <button
                type="button"
                class="btn btn-inline btn-sm mt-1 btn-secondary"
                *ngIf="activity.startsOn && !activity.endsOn"
                (click)="pickDatetime(activity, 'endsOn')"
                style="height: 26px; width: 26px"
              >
                <i class="fa-regular fa-clock"></i>
              </button>
            </div>
          </td>
          <td
            *ngIf="activityTypeGroup === 'comm'"
            style="border-top-width: 5px"
          >
            <button
              type="button"
              class="btn btn-block btn-hover-light btn-sm btn-inline btn-text-select"
              *ngIf="
                activity.activityType === activityTypes.Communicatievaren.code
              "
              (click)="pickField(activity, 'mail')"
            >
              <strong>{{ activity.mail === true ? 'v' : '&nbsp;' }}</strong>
            </button>
          </td>
          <td *ngIf="activityTypeGroup === 'mat'" style="border-top-width: 5px">
            <button
              type="button"
              class="btn btn-block btn-hover-light btn-sm btn-inline btn-text-select"
              *ngIf="activity.activityType === activityTypes.Materiaal.code"
              (click)="pickField(activity, 'douane')"
            >
              <span>{{ activity.douane === true ? 'v' : '&nbsp;' }}</span>
            </button>
          </td>
          <td *ngIf="activityTypeGroup === 'mat'" style="border-top-width: 5px">
            <span>{{ activity.quantity }}</span>
          </td>
          <td
            *ngIf="activityTypeGroup === 'comm' || activityTypeGroup === 'mat'"
            style="border-top-width: 5px; max-width: 75px"
          >
            <button
              type="button"
              class="btn btn-hover-light btn-sm btn-inline btn-block text-left btn-text-select"
              (click)="pickField(activity, 'orderNumber')"
              *ngIf="
                activity.orderNumber ||
                isOrderNumberRequired(activity.activityType)
              "
            >
              <span class="text-muted" *ngIf="!activity.orderNumber"
                >Geen order nummer...</span
              >
              <span *ngIf="activity.orderNumber">{{
                activity.orderNumber
              }}</span>
            </button>
          </td>
          <td style="border-top-width: 5px; max-width: 75px">
            <button
              type="button"
              class="btn btn-hover-light btn-inline text-truncate btn-text-select"
              (click)="pickCompany(activity)"
              style="padding: 0; width: 100%"
            >
              <span
                class="text-muted"
                *ngIf="
                  !activity.$companies_company_id ||
                  activity.$companies_company_id === 0
                "
                >seleteer agent...</span
              >
              <span
                *ngIf="
                  activity.$companies_company_id &&
                  activity.$companies_company_id > 0
                "
                title="{{
                  (activity.$companies_company &&
                  activity.$companies_company.name
                    ? activity.$companies_company.name
                    : activity.$companies_company_id.toString()
                  ) | uppercase
                }}"
              >
                {{
                  (activity.$companies_company &&
                  activity.$companies_company.name
                    ? activity.$companies_company.name
                    : activity.$companies_company_id.toString()
                  ) | uppercase
                }}
              </span>
            </button>
          </td>
          <td class="p-0" style="border-top-width: 5px">
            <div class="seperator">
              <button
                type="button"
                class="btn btn-block btn-hover-light btn-sm btn-inline btn-text-select"
                (click)="pickBerth(activity, 'berth1')"
              >
                <span class="text-muted" *ngIf="!activity.berth1"
                  >nog te kiezen</span
                >
                <span
                  class=""
                  *ngIf="activity.berth1"
                  title="{{
                    !activity.berth1Name ? activity.berth1 : activity.berth1Name
                  }}"
                  >{{ activity.berth1 }}</span
                >
              </button>
            </div>
            <div *ngIf="hasBerth2(activity.activityType)">
              <button
                type="button"
                class="btn btn-block btn-hover-light btn-sm btn-inline btn-text-select"
                (click)="pickBerth(activity, 'berth2')"
              >
                <span class="text-muted" *ngIf="!activity.berth2"
                  >nog te kiezen</span
                >
                <span
                  *ngIf="activity.berth2"
                  title="{{
                    !activity.berth2Name ? activity.berth2 : activity.berth2Name
                  }}"
                  >{{ activity.berth2 }}</span
                >
              </button>
            </div>
          </td>
          <td
            class="p-0 alert-warning"
            style="border-top-width: 5px; max-width: 80px"
            (click)="pickPeople(activity)"
            title="{{
              activity.people
                ? (activity.people | nameFromUserId: '':'&#13;' | async)
                : ''
            }}"
          >
            <button
              type="button"
              class="btn btn-hover-light btn-sm btn-block btn-text-select"
              *ngIf="!activity.people || activity.people.length === 0"
            >
              <span class="text-muted">nog niet gepland</span>
            </button>
            <button
              class="btn btn-inline btn-sm mr-1 mt-1 text-truncate btn-hover-light btn-text-select"
              style="width: 100%"
              *ngFor="let person of activity.people | slice: 0:4"
            >
              {{ person | nameFromUserId: 'abbr_lastname' | async }}
            </button>
            <button
              class="btn btn-inline btn-sm mr-1 mt-1 text-truncate btn-hover-light btn-text-select"
              style="width: 100%"
              *ngIf="activity.people && activity.people.length > 4"
            >
              {{ '+' + (activity.people.length - 4) }}
            </button>
          </td>
          <td class="p-0" style="border-top-width: 5px; width: 1%">
            <ul class="tile-buttons tile-buttons-2-columns">
              <li>
                <button
                  class="btn btn-hover-light btn-sm"
                  title="activiteit bewerken"
                  *ngIf="activity.__ObjectState !== objectState.Deleted"
                  (click)="itemClicked('edit', activity)"
                >
                  <i class="fa-regular fa-pencil-alt"></i>
                </button>
              </li>
              <li>
                <button
                  *ngIf="!isLoading && viewList === 'active'"
                  class="btn btn-hover-light btn-sm"
                  title="activiteit verwijderen"
                  (click)="itemClicked('delete', activity)"
                >
                  <i class="fa-regular fa-trash-can"></i>
                </button>
              </li>
              <li>
                <button
                  *ngIf="!isLoading && viewList === 'active'"
                  class="btn btn-hover-light btn-sm"
                  title="activiteit voltooien"
                  (click)="itemClicked('complete', activity)"
                >
                  <i class="fa-regular fa-check"></i>
                </button>
              </li>
              <li>
                <button
                  class="btn btn-hover-light btn-sm"
                  title="activiteit toevoegen"
                  (click)="itemClicked('copy', activity)"
                >
                  <i class="fa-regular fa-plus"></i>
                </button>
              </li>
            </ul>
          </td>
        </tr>
        <tr
          placement="top"
          container="body"
          *ngIf="files[activity.__Id] && files[activity.__Id].length > 0"
        >
          <td colspan="100" class="p-0">
            <div
              class="alert alert-light mb-0"
              *ngFor="let file of files[activity.__Id]"
            >
              <i class="fa-fw fa-regular fa-paperclip"></i>
              {{ file.description }}
              <button
                type="button"
                class="btn btn-light btn-sm float-right"
                title="Bestand verwijderen"
                (click)="removeFile(file.id, activity.__Id)"
              >
                {{ 'shared.terms.delete' | translate }}
              </button>
            </div>
          </td>
        </tr>
        <tr
          placement="top"
          container="body"
          ngbTooltip="{{ activity.errors }}"
          tooltipClass="tooltip"
          [class.gray-box]="
            currentActivity && currentActivity.__Id == activity.__Id
          "
          *ngIf="
            activity.$nautical_ship_id &&
            shipRequirements[activity.$nautical_ship_id] &&
            shipRequirements[activity.$nautical_ship_id].remarks &&
            shipRequirements[activity.$nautical_ship_id].remarks !== ''
          "
        >
          <td
            [class.border-error]="activity.errors"
            class="alert-warning alert"
            colspan="100"
          >
            <button
              type="button"
              class="btn btn-hover-light btn-sm btn-inline btn-block text-left text-muted btn-text-select"
              (click)="
                pickRemarks(
                  shipRequirements[activity.$nautical_ship_id].remarks,
                  'ship',
                  activity
                )
              "
            >
              <span
                >{{ 'customers.kp.objectShip' | translate }}:
                {{ shipRequirements[activity.$nautical_ship_id].remarks }}</span
              >
            </button>
          </td>
        </tr>
        <tr
          placement="top"
          container="body"
          ngbTooltip="{{ activity.errors }}"
          tooltipClass="tooltip"
          [class.gray-box]="
            currentActivity && currentActivity.__Id == activity.__Id
          "
          *ngIf="
            activity.$nautical_portvisit_id &&
            portVisitInfos[activity.$nautical_portvisit_id] &&
            portVisitInfos[activity.$nautical_portvisit_id].remarks &&
            portVisitInfos[activity.$nautical_portvisit_id].remarks !== '' &&
            (activityTypeGroup === 'meren' || activityTypeGroup === 'ontm')
          "
        >
          <td
            [class.border-error]="activity.errors"
            class="alert-info alert"
            colspan="100"
          >
            <button
              type="button"
              class="btn btn-hover-light btn-sm btn-inline btn-block text-left text-muted btn-text-select"
              (click)="
                pickRemarks(
                  portVisitInfos[activity.$nautical_portvisit_id].remarks,
                  'portVisit',
                  activity
                )
              "
            >
              <span
                >{{ 'customers.kp.objectPortVisit' | translate }}:
                {{
                  portVisitInfos[activity.$nautical_portvisit_id].remarks
                }}</span
              >
            </button>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</ng-container>
