import { Component, Input, Output, EventEmitter } from '@angular/core';

import { NauticalVisitModel } from '@seahorse/domain';
import { NauticalVisitDataService } from '@seahorse/domain';
import { NauticalShipModel } from '@seahorse/domain';

@Component({
  selector: 'ca-nautical-visit-tiles',
  templateUrl: './nautical-visit-tiles.component.html',
})
export class NauticalVisitTilesComponent {
  @Input() selectedVisit: NauticalVisitModel = null;
  @Input() visits: NauticalVisitModel[] = [];
  @Output() visitSelected = new EventEmitter<NauticalVisitModel>();

  constructor(private visitData: NauticalVisitDataService) {}

  getShipIdentification(ship: NauticalShipModel) {
    if (!ship) {
      return null;
    } else if (ship.fisheryNumber) {
      return ship.fisheryNumber;
      // } else if (ship.imo) {
      //     return ship.imo;
    } else if (ship.eni) {
      return ship.eni;
    } else if (!ship.imo && ship.callSign) {
      return ship.callSign;
    } else {
      return null;
    }
  }

  selectVisit(visit: NauticalVisitModel) {
    this.selectedVisit = visit;
    this.visitSelected.emit(visit);
  }
}
