import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomDatadefinitionModel } from '../../../../core/models/custom-service-data.model';
import {
  CURRENCIES,
  InvoicingTariffModel,
  TariffOption,
  TARIFFOPTIONS,
  Weekday,
  WEEKDAYS,
  WeekendCalculation,
} from '../../../../core/models/invoicing-tariff.model';
import {
  ResultStatus,
  ResultWrapper,
} from '@seahorse/common';
import { NotificationService } from '@seahorse/common';
import { ProxyServices } from '../../../../core/services/proxy.service';
import { FieldDefinitionModel } from '@seahorse/domain';
import { StageDataService } from '../../../../invoicing-and-tariffs/modules/invoicing-flows/services/stage-data.service';
import {
  CalculationBracketModel,
  CalculationPeriodModel,
  CalculationPricePerUnitModel,
  TIMEPERIODS,
} from '../../../../invoicing-and-tariffs/modules/tariffs/models/business-logic/business-logic-calculation.model';
import {
  ConditionScheduleItem,
  ConditionScheduleModel,
  ConditionScheduleObject,
} from '../../../../invoicing-and-tariffs/modules/tariffs/models/business-logic/business-logic-condition.model';
import {
  BusinessLogicTypes,
  BUSINESSLOGICTYPESCALCULATIONS,
} from '../../../../invoicing-and-tariffs/modules/tariffs/models/business-logic/business-logic-object.model';
import {
  ConditionScheduleTypes,
  ScheduleOperators,
} from '../../../../invoicing-and-tariffs/modules/tariffs/models/business-logic/operators.enum';
import { TariffModel } from '../../../../invoicing-and-tariffs/modules/tariffs/models/tariff.model';
import { TariffDataService } from '../../../../invoicing-and-tariffs/modules/tariffs/services/tariff-data.service';
import { TariffGroupDataService } from '../../../../invoicing-and-tariffs/modules/tariffs/services/tariff-group.data.service';
import { LayoutDisplayService } from '../../../../layout/services/layout-display.service';
import { PreferenceDataService } from '../../../../preference/services/preference-data.service';
import { Guid } from 'guid-typescript';
import { forkJoin, Observable, Subject, zip } from 'rxjs';
import { mergeMap, takeUntil } from 'rxjs/operators';

import { UserAccountService } from '../../services/user-account.service';

@Component({
  selector: 'ca-invoicing-setup',
  templateUrl: './invoicing-setup.component.html',
  styleUrls: ['./invoicing-setup.component.scss'],
})
export class InvoicingSetupComponent implements OnInit {
  saveStarted = false;
  isLoading = false;
  createInvoices = false;
  invoiceEmail: string;
  invoicingTariff: InvoicingTariffModel;
  customDataDefinition: CustomDatadefinitionModel;

  fieldDefinitions: FieldDefinitionModel[];
  selectedFieldDefinitions: FieldDefinitionModel[];
  // invoiceRecipientFieldDefinitions: FieldDefinitionModel[];

  item: any;
  scheduleModel: ConditionScheduleModel;
  hasBL = false;
  bracketModels: CalculationBracketModel[];
  BusinessLogicTypes = BusinessLogicTypes;
  businessLogicTypesList = BUSINESSLOGICTYPESCALCULATIONS;
  currencies = CURRENCIES;
  differentTariffs = TARIFFOPTIONS;
  TariffOption = TariffOption;
  weekendStartsDays = WEEKDAYS.filter((x) => x.id !== Weekday.Monday);
  weekendEndsDays = WEEKDAYS.filter((x) => x.id !== Weekday.Friday);
  timePeriods = TIMEPERIODS;

  private destroy$ = new Subject();

  constructor(
    private accountService: UserAccountService,
    private layoutDisplay: LayoutDisplayService,
    private proxyService: ProxyServices,
    private stageDataService: StageDataService,
    private tariffDataService: TariffDataService,
    private notificationService: NotificationService,
    private tariffGroupDataService: TariffGroupDataService,
    private preferenceService: PreferenceDataService,
    private router: Router
  ) {
    this.customDataDefinition = new CustomDatadefinitionModel();

    this.invoicingTariff = new InvoicingTariffModel();

    this.accountService.customDataObjectCreated$
      .pipe(takeUntil(this.destroy$))
      .subscribe((model: CustomDatadefinitionModel) => {
        if (model) {
          this.customDataDefinition = model;
        }
      });
  }

  ngOnInit() {
    this.layoutDisplay.currentModuleName = 'Invoicing';

    const objectDefinitions = this.proxyService.objectDefinitions.find(
      (x) => x.id === this.customDataDefinition.id
    );

    this.fieldDefinitions = objectDefinitions
      ? objectDefinitions.objectFieldDefinitions
      : [];
  }

  initializeBusinessLogic(businessLogicType) {
    this.hasBL = true;

    this.invoicingTariff.businessLogic.calculations = [];

    if (this.invoicingTariff.businessLogic.calculations.length > 0) {
      this.invoicingTariff.businessLogic.calculations = [];
    }

    switch (businessLogicType.id) {
      case BusinessLogicTypes.CalculationBracket:
        this.bracketModels = [];
        this.initializeBracketBusinessLogic();

        this.selectedFieldDefinitions = this.fieldDefinitions.filter(
          (x) => x.fieldType === 3 || x.fieldType === 7 || x.fieldType === 10
        );
        break;

      case BusinessLogicTypes.CalculationPeriod:
        this.item = new CalculationPeriodModel();
        this.item.businessLogicType = BusinessLogicTypes.CalculationPeriod;
        this.item.calculationOrder = 1;
        this.item.id = Guid.create().toString();
        this.invoicingTariff.businessLogic.calculations.push(
          <CalculationPeriodModel>this.item
        );

        this.selectedFieldDefinitions = this.fieldDefinitions.filter(
          (x) => x.fieldType === 2 || x.fieldType === 11
        );
        break;

      case BusinessLogicTypes.CalculationPricePerUnit:
        this.item = new CalculationPricePerUnitModel();
        this.item.businessLogicType =
          BusinessLogicTypes.CalculationPricePerUnit;
        this.item.calculationOrder = 1;
        this.item.id = Guid.create().toString();
        this.invoicingTariff.businessLogic.calculations.push(
          <CalculationPricePerUnitModel>this.item
        );

        this.selectedFieldDefinitions = this.fieldDefinitions.filter(
          (x) => x.fieldType === 3 || x.fieldType === 7 || x.fieldType === 10
        );
        break;
    }
  }

  initializeBracketBusinessLogic() {
    this.item = new CalculationBracketModel();
    this.item.businessLogicType = BusinessLogicTypes.CalculationBracket;
    this.item.calculationOrder = 1;
    this.item.id = Guid.create().toString();
    this.bracketModels.push(this.item);
  }

  addRow() {
    this.initializeBracketBusinessLogic();
  }

  removeRow(bracketItem: CalculationBracketModel) {
    if (this.bracketModels.length === 1) {
      bracketItem.minValue = null;
      bracketItem.maxValue = null;
    } else {
      this.bracketModels.splice(this.bracketModels.indexOf(bracketItem), 1);
    }
  }

  setWeekendSpecification() {
    if (this.invoicingTariff.tariffOption !== TariffOption.None) {
      this.invoicingTariff.weekendCalculations = new WeekendCalculation();

      this.scheduleModel = new ConditionScheduleModel();
      this.scheduleModel.businessLogicType =
        BusinessLogicTypes.ConditionSchedule;
      this.scheduleModel.operator = ScheduleOperators.OnSchedule;
      this.scheduleModel.sourceFieldMin = 'myDateSourceField';
      this.scheduleModel.items = [];
      this.invoicingTariff.businessLogic.conditions = [];
    }
  }

  save() {
    this.saveStarted = true;

    if (!this.validate()) {
      return;
    }

    this.isLoading = true;

    if (!this.createInvoices) {
      this.removeStepsAndFinish();
    }

    if (
      this.item &&
      this.item.businessLogicType === BusinessLogicTypes.CalculationPricePerUnit
    ) {
      (
        this.invoicingTariff.businessLogic
          .calculations[0] as CalculationPricePerUnitModel
      ).sourceField = this.invoicingTariff.basedOn;
    }

    if (this.scheduleModel) {
      this.setScheduleBLStartItem();

      if (
        this.invoicingTariff.weekendCalculations.weekendEnds -
          this.invoicingTariff.weekendCalculations.weekendStarts >
        1
      ) {
        this.setScheduleBLItemsBetween();
      }

      this.setScheduleBLEndItem();
      this.setScheduleBLFinalItem();

      this.invoicingTariff.businessLogic.conditions.push(this.scheduleModel);
    }

    if (this.invoiceEmail && this.invoicingTariff.currencyCode) {
      const invoice = this.stageDataService.addForInvoicing(this.invoiceEmail);
      const tariffGroup = this.tariffGroupDataService.addForInvoicing(
        this.invoicingTariff.currencyCode
      );

      zip(invoice, tariffGroup)
        .pipe(
          mergeMap((response) => {
            if (
              response[0].status === ResultStatus.OK &&
              response[1].status === ResultStatus.OK
            ) {
              this.invoicingTariff.tariffGroupId = response[1].result.id;

              if (
                this.item.businessLogicType ===
                  BusinessLogicTypes.CalculationBracket &&
                this.bracketModels.length > 0
              ) {
                const observableArray = [] as Array<
                  Observable<ResultWrapper<TariffModel>>
                >;

                let newInvoicingTariff = new InvoicingTariffModel();

                this.bracketModels.forEach((element) => {
                  element.sourceField = this.invoicingTariff.basedOn;

                  this.invoicingTariff.amount = element.amount;

                  if (
                    this.invoicingTariff.businessLogic.calculations.length > 0
                  ) {
                    this.invoicingTariff.businessLogic.calculations = [];
                  }

                  this.invoicingTariff.businessLogic.calculations.push(
                    <CalculationBracketModel>element
                  );

                  newInvoicingTariff = { ...this.invoicingTariff };
                  newInvoicingTariff.businessLogic = {
                    ...this.invoicingTariff.businessLogic,
                  };

                  const tariff =
                    this.tariffDataService.addForInvoicing(newInvoicingTariff);

                  observableArray.push(tariff);
                });

                return forkJoin(observableArray);
              } else {
                return this.tariffDataService.addForInvoicing(
                  this.invoicingTariff
                );
              }
            }
          })
        )
        .subscribe((result) => {
          let success = false;
          if (result instanceof Array) {
            success = result.reduce(
              (acc, cur) => acc && cur.status === ResultStatus.OK,
              true
            );
          } else {
            success = result.status === ResultStatus.OK;
          }

          if (success) {
            this.removeStepsAndFinish();
          } else {
            this.notificationService.showError(
              'Something went wrong!',
              'Failed'
            );
          }
        });
    } else {
      this.notificationService.showSuccess(
        'Onboarding process completed!',
        'Successfully'
      );

      this.router.navigate(['../dashboard']);
    }
  }

  setScheduleBLStartItem() {
    const item = new ConditionScheduleItem();
    item.from = new ConditionScheduleObject();
    item.to = new ConditionScheduleObject();
    item.from.scheduleType = ConditionScheduleTypes.TimeOfDay;
    item.to.scheduleType = ConditionScheduleTypes.TimeOfDay;
    item.from.value =
      WEEKDAYS.find(
        (x) => x.id === this.invoicingTariff.weekendCalculations.weekendStarts
      ).short +
      this.invoicingTariff.weekendCalculations.weekendStartsHour.hour.toString() +
      this.invoicingTariff.weekendCalculations.weekendStartsHour.minute.toString();
    item.to.value =
      WEEKDAYS.find(
        (x) => x.id === this.invoicingTariff.weekendCalculations.weekendStarts
      ).short + '2400';
    this.scheduleModel.items.push(item);
  }

  setScheduleBLItemsBetween() {
    WEEKDAYS.forEach((element) => {
      if (
        element.id !== this.invoicingTariff.weekendCalculations.weekendStarts &&
        element.id !== this.invoicingTariff.weekendCalculations.weekendEnds
      ) {
        const item = new ConditionScheduleItem();
        item.from = new ConditionScheduleObject();
        item.from.scheduleType = ConditionScheduleTypes.Day;
        item.from.value = element.short;
        this.scheduleModel.items.push(item);
      }
    });
  }

  setScheduleBLEndItem() {
    const item = new ConditionScheduleItem();
    item.from = new ConditionScheduleObject();
    item.to = new ConditionScheduleObject();
    item.from.scheduleType = ConditionScheduleTypes.TimeOfDay;
    item.to.scheduleType = ConditionScheduleTypes.TimeOfDay;
    item.from.value =
      WEEKDAYS.find(
        (x) => x.id === this.invoicingTariff.weekendCalculations.weekendEnds
      ).short + '0000';
    item.to.value =
      WEEKDAYS.find(
        (x) => x.id === this.invoicingTariff.weekendCalculations.weekendEnds
      ).short +
      this.invoicingTariff.weekendCalculations.weekendEndsHour.hour.toString() +
      this.invoicingTariff.weekendCalculations.weekendEndsHour.minute.toString();
    this.scheduleModel.items.push(item);
  }

  setScheduleBLFinalItem() {
    const item = new ConditionScheduleItem();
    item.from = new ConditionScheduleObject();
    item.from.scheduleType = ConditionScheduleTypes.Holiday;
    item.from.value = 'nl;-6;6';
    this.scheduleModel.items.push(item);
  }

  validate() {
    if (
      this.createInvoices &&
      (!this.invoiceEmail ||
        this.invoiceEmail.trim().length === 0 ||
        !this.hasBL ||
        (this.item.businessLogicType !==
          BusinessLogicTypes.CalculationBracket &&
          (!this.invoicingTariff.amount || this.invoicingTariff.amount <= 0)) ||
        !this.invoicingTariff.currencyCode ||
        this.invoicingTariff.currencyCode.trim().length === 0 ||
        (this.selectedFieldDefinitions &&
          this.selectedFieldDefinitions.length > 0 &&
          !this.invoicingTariff.basedOn) ||
        !this.invoicingTariff.tariffOption ||
        (this.item.businessLogicType === BusinessLogicTypes.CalculationPeriod &&
          (this.item.period === null ||
            this.item.period === undefined ||
            !this.item.periodQuantity ||
            this.item.periodQuantity <= 0)))
    ) {
      return false;
    }

    return true;
  }

  removeStepsAndFinish() {
    const customData = this.preferenceService.getByName(
      'custom_data',
      'permissions'
    );
    const invoices = this.preferenceService.getByName(
      'invoices',
      'permissions'
    );

    forkJoin([customData, invoices]).subscribe((results) => {
      if (
        results[0].hasResult &&
        results[0].status === ResultStatus.OK &&
        results[1].hasResult &&
        results[1].status === ResultStatus.OK
      ) {
        const customDataDelete = this.preferenceService.delete(
          results[0].result.id
        );
        const invoicesDelete = this.preferenceService.delete(
          results[1].result.id
        );

        forkJoin([customDataDelete, invoicesDelete]).subscribe(
          (deleteResults) => {
            if (
              deleteResults[0].hasResult &&
              deleteResults[0].status === ResultStatus.OK &&
              deleteResults[1].hasResult &&
              deleteResults[1].status === ResultStatus.OK
            ) {
              this.isLoading = false;

              if (this.createInvoices) {
                this.notificationService.showSuccess(
                  'Invoicing completed!',
                  'Successfully'
                );
              }

              this.notificationService.showSuccess(
                'Onboarding process completed!',
                'Successfully'
              );

              this.router.navigate(['../dashboard']);
            } else {
              this.notificationService.showError(
                'Something went wrong!',
                'Failed'
              );
              this.isLoading = false;
            }
          }
        );
      } else {
        this.notificationService.showError('Something went wrong!', 'Failed');
      }
    });
  }
}
