import { inject, Injectable } from '@angular/core';
import { DataContextService } from '@seahorse/common';
import { tap } from 'rxjs/operators';
import { MfaSetupCode } from '@seahorse/domain';
import { IdentityServiceBase } from '@seahorse/auth';

@Injectable({
  providedIn: 'root',
})
export class MfaService {
  private _dataContext = inject(DataContextService);
  private _identityService = inject(IdentityServiceBase);

  initMfa(token?: string) {
    let url = 'account/mfa/init';
    const params = new URLSearchParams();
    if (token) {
      params.append('token', token);
    }
    if (params.toString()) {
      url += `?${params.toString()}`;
    }
    return this._dataContext.get<MfaSetupCode>(url);
  }

  completeMfa(code: string, validateToken?: boolean, token?: string) {
    let url = `account/mfa/complete/${code}`;
    const params = new URLSearchParams();
    if (validateToken !== undefined) {
      params.append('validateToken', validateToken.toString());
    }
    if (token) {
      params.append('token', token);
    }
    if (params.toString()) {
      url += `?${params.toString()}`;
    }
    return this._dataContext.get<boolean>(url);
  }

  removeMfa() {
    return this._dataContext.get<boolean>('account/mfa/remove');
  }

  hasMfa(userId?: string) {
    const url = `account/mfa/active${userId ? `?userId=${userId}` : ''}`;
    return this._dataContext
      .get<boolean>(url)
      .pipe(
        tap(
          (response) => (this._identityService.isMfaEnabled = response.result)
        )
      );
  }

  initMfaWithUserId(userId: string) {
    const url = `account/mfa/init/${userId}`;
    return this._dataContext.get<MfaSetupCode>(url);
  }

  completeMfaWithUserId(userId: string, code: string) {
    const url = `account/mfa/complete/user?userId=${userId}&code=${code}`;
    return this._dataContext.get<boolean>(url);
  }
}
