import { Component, Input, Output, EventEmitter } from '@angular/core';

import * as _ from 'underscore';

import { CollectionDisplay } from '../../../layout/models/collection-display.model';

@Component({
  selector: 'visit-header',
  templateUrl: './visit-header.component.html',
})
export class VisitHeaderComponent {
  view: any;

  @Input() searchQuery: string;
  @Input() searchResultCount: number;
  @Input() selectedView: any;
  @Input() selectedFilter: any;

  @Output() onNewButtonClicked: EventEmitter<any>;
  @Output() onSearchChanged: EventEmitter<string>;
  @Output() onFilterChanged: EventEmitter<string>;
  @Output() onViewChanged: EventEmitter<any>;

  constructor() {
    this.view = CollectionDisplay;
    this.searchQuery = null;
    this.searchResultCount = null;
    this.selectedView = CollectionDisplay.Tiles;
    this.selectedFilter = 'nofilter';
    this.onNewButtonClicked = new EventEmitter();
    this.onSearchChanged = new EventEmitter<string>();
    this.onFilterChanged = new EventEmitter<string>();
    this.onViewChanged = new EventEmitter<any>();
  }

  newButtonClicked() {
    this.onNewButtonClicked.emit();
  }

  searchVisits() {
    this.onSearchChanged.emit(this.searchQuery);
  }

  setSelectedFilter(status: string) {
    this.onFilterChanged.emit(status);
  }

  setSelectedView() {
    this.onViewChanged.emit(this.selectedView);
  }
}
