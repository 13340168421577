<div class="modal-header align-items-center">
  <h1 class="modal-title">{{ title }}</h1>
  <button
    (click)="dismissDialog()"
    type="button"
    class="btn btn-link text-dark"
  >
    <i class="fa-solid fa-times fa-sm fa-fw"></i>
  </button>
</div>
<div *ngIf="!loading" class="modal-body">
  <ca-expense-form
    #expenseForm
    [expense]="expense"
    [orderRegistrations]="orderRegistrations"
    [isLoadingOrderRegistrations]="loadingOrderRegistrations"
    [hideOrderRegistration]="hideOrderRegistrationPicker"
    [hideTariff]="hideTariffPicker"
    [hideCommissionFeeGroup]="hideCommissionFee"
  ></ca-expense-form>
</div>
<div class="modal-footer">
  <button
    (click)="dismissDialog()"
    type="button"
    class="btn btn-link flex-fill"
  >
    {{ 'shared.terms.cancel' | translate }}
  </button>
  <button
    [disabled]="loading"
    *ngIf="!newExpense"
    (click)="deleteExpense()"
    type="button"
    class="btn btn-danger flex-fill"
  >
    <span *ngIf="loading">
      <i class="fa-regular fa-spinner fa-spin fa-fw"></i>
    </span>
    <ng-container *ngIf="!loading">{{
      'shared.terms.delete' | translate
    }}</ng-container>
  </button>
  <button
    [disabled]="loading"
    (click)="confirmExpense()"
    type="button"
    class="btn btn-success flex-fill"
  >
    <span *ngIf="loading">
      <i class="fa-regular fa-spinner fa-spin fa-fw"></i>
    </span>
    <ng-container *ngIf="!loading">{{
      'shared.terms.save' | translate
    }}</ng-container>
  </button>
</div>
