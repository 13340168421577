<div class="card mb-3" *ngFor="let movement of movements">
  <div class="card-body p-0">
    <ng-container>
      <div class="card-header text-uppercase">
        {{ movement.movementType | nauticalMovementType }}
      </div>
      <div class="row border-bottom">
        <div class="col-12 col-lg-12">
          <ul class="nav nav-tabs">
            <li
              [class.active-tab]="movement.activeTabIndex === 0"
              class="nav-item"
            >
              <button
                class="nav-link card-header"
                (click)="setActiveMovementTab(movement, 0)"
              >
                {{ 'nautical.visit.details.portMovements' | translate }}
              </button>
            </li>
            <li
              [class.active-tab]="movement.activeTabIndex === 1"
              class="nav-item"
            >
              <button
                class="nav-link card-header"
                (click)="setActiveMovementTab(movement, 1)"
              >
                {{ 'nautical.visit.portVisitAttributes' | translate }}
              </button>
            </li>
            <li
              [class.active-tab]="movement.activeTabIndex === 2"
              class="nav-item"
            >
              <button
                class="nav-link card-header"
                (click)="setActiveMovementTab(movement, 2)"
              >
                {{ 'nautical.visit.portVisitEvents' | translate }}
              </button>
            </li>
          </ul>

          <div class="card-body card-body-table tab-content">
            <div class="row" *ngIf="movement.activeTabIndex === 0">
              <div class="col-12 col-lg-12">
                <div class="card-body">
                  <p>
                    <span *ngIf="!movement.atd && !movement.etd"
                      >{{ 'nautical.terms.etd' | translate }}
                      {{ 'shared.terms.unknown' | translate | lowercase }}</span
                    >
                    <span *ngIf="!movement.atd && movement.etd"
                      >{{ 'nautical.terms.etd' | translate }}
                      {{ movement.etd | date: 'dd-MM-yyyy HH:mm' }}
                    </span>
                    <span *ngIf="movement.atd"
                      >{{ 'nautical.terms.atd' | translate }}
                      {{ movement.atd | date: 'dd-MM-yyyy HH:mm' }}
                    </span>
                    -
                    <strong
                      *ngIf="
                        movement.portWayPointFrom &&
                        movement.portWayPointFrom.name
                      "
                      >{{ movement.portWayPointFrom.name }}</strong
                    >
                    <strong
                      *ngIf="
                        !movement.portWayPointFrom ||
                        !movement.portWayPointFrom.name
                      "
                      >unkown origin</strong
                    >
                  </p>
                  <p>
                    <span *ngIf="!movement.ata && !movement.eta"
                      >{{ 'nautical.terms.eta' | translate }}
                      {{ 'shared.terms.unknown' | translate | lowercase }}</span
                    >
                    <span *ngIf="!movement.ata && movement.eta"
                      >{{ 'nautical.terms.eta' | translate }}
                      {{ movement.eta | date: 'dd-MM-yyyy HH:mm' }}</span
                    >
                    <span *ngIf="movement.ata"
                      >{{ 'nautical.terms.ata' | translate }}
                      {{ movement.ata | date: 'dd-MM-yyyy HH:mm' }}</span
                    >
                    -
                    <strong
                      *ngIf="
                        movement.portWayPointTo && movement.portWayPointTo.name
                      "
                      >{{ movement.portWayPointTo.name }}</strong
                    >
                    <strong
                      *ngIf="
                        !movement.portWayPointTo ||
                        !movement.portWayPointTo.name
                      "
                      >{{
                        'nautical.flow.unknownDestination' | translate
                      }}</strong
                    >
                  </p>
                </div>
              </div>
            </div>

            <div class="row" *ngIf="movement.activeTabIndex === 1">
              <div class="col-12 col-lg-12">
                <div class="card-body card-body-table">
                  <visit-attributes
                    [attributes]="movement.portVisitAttributes"
                    [loadAttributes]="loadMovements"
                  >
                  </visit-attributes>
                </div>
              </div>
            </div>

            <div class="row" *ngIf="movement.activeTabIndex === 2">
              <div class="col-12 col-lg-12">
                <div class="card-body card-body-table">
                  <visit-events
                    [events]="movement.portVisitEvents"
                    [loadEvents]="loadMovements"
                  ></visit-events>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
