import { EventEmitter } from '@angular/core';
import {
  CustomFieldControl,
  formControlFactory,
  FormField,
} from '@seahorse/temp';
import { map } from 'rxjs/operators';
import { TariffPickerFormComponent } from '../../invoicing-and-tariffs/modules/tariffs/components/tariff-picker-form/tariff-picker-form.component';
import { TariffModel } from '../../invoicing-and-tariffs/modules/tariffs/models/tariff.model';

export const controlFactory: formControlFactory = (field: FormField) => {
  if (field.key === 'tariff') {
    return <CustomFieldControl<TariffPickerFormComponent>>{
      component: TariffPickerFormComponent,
      data: (value: number) => {
        const changeCb = new EventEmitter<TariffModel>();
        const valueChange$ = changeCb.pipe(map(tariff => tariff?.id))

        return {
            showLabels: false,
            tariffId: value,
            selectedTariffChange: changeCb,
            valueChange$
          }
      },
    };
  }
};
