import { Injectable } from '@angular/core';
import { DataContextService } from '@seahorse/common';
import { WidgetModel } from '../models/widget.model';
import { Observable } from 'rxjs';
import { ResultWrapper } from '@seahorse/common';

@Injectable({
  providedIn: 'root',
})
export class WidgetDataService {
  constructor(private dataContext: DataContextService) {}

  add(widget: WidgetModel): Observable<ResultWrapper<WidgetModel>> {
    return this.dataContext.post<WidgetModel>(`widget`, widget);
  }

  get(): Observable<ResultWrapper<WidgetModel[]>> {
    return this.dataContext.get<WidgetModel[]>(`widget`);
  }

  getById(id: string): Observable<ResultWrapper<WidgetModel>> {
    return this.dataContext.get<WidgetModel>(`widget/${id}`);
  }

  delete(id: string): Observable<ResultWrapper<WidgetModel>> {
    return this.dataContext.delete<WidgetModel>(`widget/${id}`);
  }

  update(widget: WidgetModel): Observable<ResultWrapper<WidgetModel>> {
    return this.dataContext.put<WidgetModel>(`widget`, widget);
  }
}
