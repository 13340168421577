<div class="modal-header align-items-center">
  <h1 class="modal-title">{{ 'customUI.teamManager.title' | translate }}</h1>
  <button
    (click)="onSelectTeamAction.emit({ team: null, action: 'dismiss' })"
    type="button"
    class="btn btn-link text-dark"
  >
    <i class="fa-solid fa-times fa-fw fa-sm"></i>
  </button>
</div>

<div class="modal-body">
  <!-- Team picker -->
  <div class="form-group mb-3">
    <label for="team">{{ 'customUI.teamManager.title' | translate }}</label>
    <div class="input-group">
      <select
        [(ngModel)]="selectedTeam"
        (change)="selectTeamMembers(selectedTeam.__Id)"
        required
        id="team"
        name="team"
        class="custom-select"
      >
        <option *ngFor="let team of teamList" [ngValue]="team">
          <ng-container *ngIf="team.__Id; then edit; else add"></ng-container>

          <ng-template #edit>
            {{ team.displayName }}
            {{
              team.active === true
                ? '(' + ('customUI.memberTeamPicker.active' | translate) + ')'
                : ''
            }}
          </ng-template>

          <ng-template #add>
            {{ 'customUI.teamManager.add' | translate }}
          </ng-template>
        </option>
      </select>
      <div class="input-group-append">
        <ng-container *ngIf="selectedTeam.__Id; then delete"></ng-container>

        <ng-template #delete>
          <button
            (click)="
              onSelectTeamAction.emit({ team: selectedTeam, action: 'delete' })
            "
            type="button"
            class="btn btn-secondary btn-hover-danger"
          >
            <i class="fa-solid fa-trash-alt fa-fw"></i>
          </button>
        </ng-template>
      </div>
    </div>
  </div>

  <!-- Team editor -->
  <div class="form-group">
    <label for="name">
      {{ 'shared.terms.name' | translate }}
      <i class="fa-regular fa-asterisk fa-xs fa-fw text-danger mb-1"></i>
    </label>
    <input
      [(ngModel)]="selectedTeam.name"
      required
      type="text"
      id="name"
      name="name"
      class="form-control"
    />
  </div>

  <div class="custom-control custom-switch">
    <input
      [(ngModel)]="selectedTeam.active"
      type="checkbox"
      class="custom-control-input"
      id="isActive"
      name="isActive"
    />
    <label for="isActive" class="custom-control-label">
      {{ 'customUI.memberModel.isActive' | translate }}
    </label>
  </div>

  <div class="d-flex mb-3">
    <div class="input-group ml-1">
      <div class="input-group-prepend">
        <span class="input-group-text">
          <i class="fa-regular fa-search fa-fw fa-sm"></i>
        </span>
      </div>
      <input
        [(ngModel)]="searchField"
        [placeholder]="'customUI.memberPicker.findMembers' | translate"
        type="text"
        name="searchBox"
        class="form-control"
      />
    </div>
  </div>

  <div style="max-height: 240px; overflow-y: auto" class="border rounded">
    <ng-container
      *ngIf="!loadTeamUsers; then memberTeamPicker; else loadingTeam"
    ></ng-container>

    <ng-template #memberTeamPicker>
      <table class="table table-borderless table-hover table-sm mb-0">
        <tr
          *ngFor="
            let member of teamMembers | searchMembers: searchField;
            let i = index
          "
        >
          <td scope="row">
            <div class="custom-control custom-checkbox">
              <input
                [id]="'checkbox-teamManagerMember-' + i"
                [checked]="member.isChecked"
                (change)="selectMember(member.$usermanager_user_id)"
                type="checkbox"
                class="custom-control-input"
              />
              <label
                [for]="'checkbox-teamManagerMember-' + i"
                class="custom-control-label w-100"
              >
                {{ member.$usermanager_user_givenName }}
                {{ member.$usermanager_user_surname }}
              </label>
            </div>
          </td>
        </tr>
      </table>
    </ng-template>

    <ng-template #loadingTeam>
      <sh-loading-indicator
        class="d-flex justify-content-center align-items-center h-100"
      ></sh-loading-indicator>
    </ng-template>
  </div>
</div>

<div class="modal-footer">
  <button
    (click)="onSelectTeamAction.emit({ team: null, action: 'dismiss' })"
    type="button"
    class="btn btn-link w-50"
  >
    {{ 'shared.terms.cancel' | translate }}
  </button>
  <button
    (click)="saveTeam()"
    type="button"
    class="btn btn-success w-50"
    [disabled]="!selectedTeam.name"
  >
    {{ 'shared.terms.save' | translate }}
  </button>
</div>
