import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { WidgetDataService } from '../../services/widget-data.service';
import { WidgetModel, WidgetTypes } from '../../models/widget.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ca-widgets-library',
  templateUrl: './widgets-library.component.html',
  styleUrls: ['./widgets-library.component.scss'],
})
export class WidgetsLibraryComponent implements OnInit, OnChanges, OnDestroy {
  @Input() widget: WidgetModel;
  @Input() widgetsOnDashboardIds: string[];
  @Output() selectedWidget = new EventEmitter<WidgetModel>();

  allWidgets: WidgetModel[] = [];
  widgets: WidgetModel[] = [];

  widgetTypes = WidgetTypes;

  private subscriptions = new Subscription();

  constructor(private _widgetDataService: WidgetDataService) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this._widgetDataService.get().subscribe((result) => {
        this.allWidgets = result.result.map((widget) => ({
          ...widget,
          configuration: JSON.parse(widget.configuration),
        }));
        this.updateWidgets();
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.widgetsOnDashboardIds) {
      this.updateWidgets();
    }
  }

  updateWidgets(): void {
    this.widgets = this.allWidgets.filter(
      (widget) => !this.widgetsOnDashboardIds.includes(widget.id.toString())
    );
  }

  onWidgetClicked(widget: WidgetModel) {
    this.selectedWidget.emit(widget);
    this.widgets = this.widgets.filter((item) => item.id !== widget.id);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
