import {
  Component,
  EventEmitter,
  OnInit,
  OnDestroy,
  Output,
} from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@seahorse/common';
import {
  FleetGroup,
  ShipFleetGroupModel,
} from '../../../map/models/fleet-group.model';
import { GroupService } from '../../../map/services/group.service';
import { MapInteractionService } from '../../../map/services/map-interaction.service';
import { NauticalShipPickerDialogComponent } from '../../../nautical-ship/components/nautical-ship-picker-dialog/nautical-ship-picker-dialog.component';
import { NauticalShipModel } from '@seahorse/domain';
import { ShipModel } from '../../../superuser-nautical/models/ship.model';
import { Subscription } from 'rxjs';

import { GroupModalComponent } from '../group-modal/group-modal.component';

@Component({
  selector: 'ca-map-sidebar-fleet',
  templateUrl: 'map-sidebar-fleet.component.html',
})
export class MapSidebarFleetComponent implements OnInit, OnDestroy {
  private subscriptions$ = Array<Subscription>();
  groups: FleetGroup[];
  shipsWithoutGroup: ShipFleetGroupModel[];

  @Output() onSelectVessel: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public groupService: GroupService,
    public mapInteractionService: MapInteractionService,
    public modalService: NgbModal,
    private notificationService: NotificationService,
    private _modal: NgbModal,
    private _translate: TranslateService
  ) {
    this.subscriptions$.push(
      this.mapInteractionService.shipToFleetAdded$.subscribe(
        (shipFleet) => {
          if (
            this.groups.find((x) => x.id === shipFleet.groupId).shipFleetGroups
              .length === 0
          ) {
            this.groups.find((x) => x.id === shipFleet.groupId).isActive = true;
          }
          this.groups
            .find((x) => x.id === shipFleet.groupId)
            .shipFleetGroups.push(shipFleet);
        },
        () => {},
        () => {}
      )
    );

    this.subscriptions$.push(
      this.mapInteractionService.removedShipFromFleet$.subscribe(
        (shipFleet) => {
          this.groups
            .find((x) => x.id === shipFleet.groupId)
            .shipFleetGroups.splice(
              this.groups
                .find((x) => x.id === shipFleet.groupId)
                .shipFleetGroups.findIndex((x) => x.id === shipFleet.id),
              1
            );
        },
        () => {},
        () => {}
      )
    );

    this.subscriptions$.push(
      this.mapInteractionService.addShipToNoGroup$.subscribe(
        (shipFleet) => {
          this.shipsWithoutGroup.push(shipFleet);
        },
        () => {},
        () => {}
      )
    );

    this.subscriptions$.push(
      this.mapInteractionService.removeShipFromNoGroup$.subscribe(
        (shipFleet) => {
          this.shipsWithoutGroup.splice(
            this.shipsWithoutGroup.findIndex((x) => x.id === shipFleet.id),
            1
          );
        },
        () => {},
        () => {}
      )
    );
  }

  ngOnInit() {
    this.getGroups();
    this.getShipsWithoutGroup();
  }

  ngOnDestroy() {
    this.subscriptions$.forEach((s) => s.unsubscribe());
  }

  openVessel(ship: ShipModel) {
    this.onSelectVessel.emit(ship);
  }

  getGroups() {
    this.subscriptions$.push(
      this.groupService.getGroups().subscribe(
        (response) => {
          this.groups = response.result;

          this.groups.forEach((g) => {
            if (g.shipFleetGroups) {
              g.shipFleetGroups.forEach((sfg) => {
                if (sfg.ship && sfg.ship.id) {
                  sfg.ship.id = sfg.ship.id.split('_').pop();
                }
              });
            }
          });
        },
        () => {},
        () => {}
      )
    );
  }

  getShipsWithoutGroup() {
    this.subscriptions$.push(
      this.groupService.getAllShipsWithoutGroup().subscribe(
        (response) => {
          this.shipsWithoutGroup = response.result;
          this.shipsWithoutGroup.map((s) => {
            if (s.ship && s.ship.id) {
              s.ship.id = s.ship.id.split('_').pop();
            }
            return s;
          });
        },
        () => {},
        () => {}
      )
    );
  }

  openAddGroupModal() {
    const modalRef = this.modalService.open(GroupModalComponent, {
      backdrop: 'static',
    });

    modalRef.result
      .then(
        (data) => {
          data.shipFleetGroups = [];
          this.groups.push(data);
        },
        () => {}
      )
      .catch(() => {})
      .finally(() => {});
  }

  toggleActiveGroup(group: FleetGroup) {
    group.isActive = !group.isActive;
  }

  editGroup($event, group: FleetGroup) {
    $event.stopPropagation();

    const modalRef = this.modalService.open(GroupModalComponent, {
      backdrop: 'static',
    });
    modalRef.componentInstance.group = group;

    modalRef.result
      .then(
        (data) => {
          this.groups[this.groups.findIndex((x) => x.id === group.id)].name =
            data.name;
        },
        () => {}
      )
      .catch(() => {})
      .finally(() => {});
  }

  deleteGroup($event, group: FleetGroup) {
    $event.stopPropagation();

    this.subscriptions$.push(
      this.groupService.deleteGroup(group).subscribe(
        (response) => {
          this.notificationService.showSuccess(
            'Fleet group ' + group.name + ' deleted' + '.',
            'Successfully'
          );

          this.groups.splice(
            this.groups.findIndex((x) => x.id === group.id),
            1
          );
        },
        () => {},
        () => {}
      )
    );
  }

  deleteVessel(id: number, group: FleetGroup) {
    this.subscriptions$.push(
      this.groupService.removeShipFromGroup(id).subscribe(
        (response) => {
          group.shipFleetGroups.splice(
            group.shipFleetGroups.findIndex((x) => x.id === id),
            1
          );

          this.notificationService.showSuccess(
            'Ship deleted from group ' + group.name + '.',
            'Successfully'
          );
        },
        () => {},
        () => {}
      )
    );
  }

  deleteVesselWithoutGroup(id: number) {
    this.subscriptions$.push(
      this.groupService.removeShipFromGroup(id).subscribe(
        (response) => {
          this.shipsWithoutGroup.splice(
            this.shipsWithoutGroup.findIndex((x) => x.id === id),
            1
          );

          this.notificationService.showSuccess('Ship deleted.', 'Successfully');
        },
        () => {},
        () => {}
      )
    );
  }

  addShipToFleetGroup(fleetGroupId: number) {
    const ngbModalOptions: NgbModalOptions = { backdrop: 'static' };
    const modalRef = this._modal.open(
      NauticalShipPickerDialogComponent,
      ngbModalOptions
    );

    modalRef.result
      .then((resolve: { nauticalShip: NauticalShipModel }) => {
        const shipId = resolve.nauticalShip
          ? resolve.nauticalShip.baseId
          : null;
        if (shipId) {
          this.subscriptions$.push(
            this.groupService.addShipToGroup(fleetGroupId, shipId).subscribe(
              (result) => {
                if (result.hasResult) {
                  this.groups[
                    this.groups.findIndex((x) => x.id === fleetGroupId)
                  ].shipFleetGroups.push(result.result);
                  this.notificationService.showSuccess(
                    this._translate.instant('myFleet.shipAddedToFleet'),
                    this._translate.instant('shared.terms.success')
                  );
                } else {
                  this.notificationService.displayErrorNotification(
                    result.messages
                  );
                }
              },
              () => this.notificationService.displayErrorNotification(),
              () => {}
            )
          );
        }
      })
      .catch(() => {})
      .finally(() => {});
  }
}
