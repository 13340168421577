import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { NauticalShipModel } from '@seahorse/domain';
import { NauticalShipDataService } from '@seahorse/domain';

import * as _ from 'underscore';
import * as moment from 'moment';

import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ProxyServices } from '../../../core/services/proxy.service';
import {
  CustomDataContentService,
  DataObjectKeyModel,
  SearchOperators,
} from '@seahorse/domain';
import { AISMarkerModel } from '@seahorse/domain';
import { MyFleetModalComponent } from '../my-fleet-modal/my-fleet-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NauticalVisitOverviewModel } from '@seahorse/domain';
import { NauticalVisitDataService } from '@seahorse/domain';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ca-map-popup-large',
  templateUrl: 'map-popup-large.component.html',
})
export class MapPopupLargeComponent implements OnDestroy, OnInit {
  private localAISInfo?: AISMarkerModel;
  private localShip?: NauticalShipModel;
  private subscriptions$ = Array<Subscription>();

  @Input() get aisInfo(): AISMarkerModel {
    return this.localAISInfo;
  }
  set aisInfo(input: AISMarkerModel) {
    this.resetState();
    if (input && (input.imo || input.mmsi)) {
      this.localAISInfo = input;
      this.setShipDetails();
      this.getShip(input.imo, input.mmsi);
    } else {
      this.localAISInfo = undefined;
    }
  }

  @Output() close = new EventEmitter<any>();

  isLoading = false;

  get ship(): NauticalShipModel {
    return this.localShip;
  }
  set ship(input: NauticalShipModel) {
    this.localShip = input;

    if (this.localShip) {
      this.setShipDetails();
      this.detectObjectConnections(this.localShip.id);
    } else {
      this.close.emit(input);
    }
  }
  shipDetailGroups: {
    data?: any[];
    display?: { key: string; value: string }[];
    items?: any[];
    label: string;
  }[] = [];
  shipDataGroups: {
    data?: any[];
    display?: { key: string; value: string }[];
    items?: any[];
    label: string;
  }[] = [];
  showPhoto = true;
  visitActive?: NauticalVisitOverviewModel;
  visitsUpcoming: NauticalVisitOverviewModel[] = [];

  constructor(
    private customContent: CustomDataContentService,
    private modal: NgbModal,
    private nauticalShipDataService: NauticalShipDataService,
    private nauticalVisitDataService: NauticalVisitDataService,
    private proxy: ProxyServices,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {}

  ngOnDestroy() {
    this.subscriptions$.forEach((s) => s.unsubscribe());
  }

  addToMyFleet() {
    const modalRef = this.modal.open(MyFleetModalComponent, {
      backdrop: 'static',
    });
    modalRef.componentInstance.shipId = this.ship.id;
  }

  clear() {
    this.aisInfo = undefined;
    this.ship = undefined;
    this.shipDetailGroups = [];
  }

  private detectObjectConnections(shipId: string) {
    this.shipDataGroups = [];

    if (!this.proxy.objectDefinitions) {
      return;
    }

    const linkedObjectDefinitions =
      this.proxy.getObjectDefinitionsForLinkedObject('$nautical_ship_id');
    if (linkedObjectDefinitions && linkedObjectDefinitions.length > 0) {
      _.each(linkedObjectDefinitions, (linkedObjectDefinition) => {
        let fieldDefinitions = linkedObjectDefinition.objectFieldDefinitions;
        if (
          linkedObjectDefinition.baseObjectDefinition &&
          linkedObjectDefinition.baseObjectDefinition.objectFieldDefinitions
        ) {
          fieldDefinitions = fieldDefinitions.concat(
            linkedObjectDefinition.baseObjectDefinition.objectFieldDefinitions
          );
        }
        const itemField = _.find(
          fieldDefinitions,
          (fieldFind) =>
            fieldFind.fieldCode === '_linkedobject' &&
            fieldFind.additionalDataModel &&
            fieldFind.additionalDataModel.mappingKey === '$nautical_ship_id'
        );

        const uiModule = this.proxy.getUIModuleForObjectDefinition(
          linkedObjectDefinition.id
        );

        // Only load data now if we know the field exists AND there is an existing UI module definition
        // For later: If there is no UI module definition we could navigate to a more generic view of the object data
        if (itemField && uiModule && uiModule.uiModuleDetailsConfiguration) {
          this.customContent
            .searchDataByObjectKeyAndFields(
              DataObjectKeyModel.fromKey(
                'ct-' + linkedObjectDefinition.systemCode
              ),
              [
                {
                  fieldName: itemField.systemCode,
                  searchColumn: 'id',
                  searchOperator: SearchOperators.Equals,
                  searchValue: this.localShip.baseId,
                },
              ],
              undefined,
              undefined,
              undefined,
              0,
              10
            )
            .subscribe({
              next: (customContentResult) => {
                if (customContentResult.hasResult) {
                  if (
                    customContentResult.result &&
                    customContentResult.result.length > 0
                  ) {
                    this.shipDataGroups.push({
                      label: linkedObjectDefinition.name,
                      data: _.map(
                        customContentResult.result,
                        (customContentMap) => {
                          return {
                            display: customContentMap.__DisplayName,
                            url:
                              '/module/' +
                              uiModule.systemCode +
                              '/details/' +
                              customContentMap.__Id,
                          };
                        }
                      ),
                    });
                  }
                }
              },
            });
        }
      });
    }
  }

  getShip(imo?: string, mmsi?: string) {
    this.isLoading = true;

    if (imo) {
      this.subscriptions$.push(
        this.nauticalShipDataService.getByImo(imo).subscribe({
          next: (response) => {
            if (response.hasResult && response.result) {
              this.getShipDetails(response.result.id, response.result);
            } else {
              this.subscriptions$.push(
                this.nauticalShipDataService.getByMmsi(mmsi).subscribe({
                  next: (getByMmsiResponse) => {
                    if (
                      getByMmsiResponse.hasResult &&
                      getByMmsiResponse.result &&
                      getByMmsiResponse.result.id
                    ) {
                      this.getShipDetails(
                        getByMmsiResponse.result.id,
                        getByMmsiResponse.result
                      );
                    } else {
                      this.isLoading = false;
                    }
                  },
                  error: (e) => {
                    this.isLoading = false;
                  },
                })
              );
            }
          },
          error: (e) => {
            this.isLoading = false;
          },
        })
      );
    } else if (mmsi) {
      this.subscriptions$.push(
        this.nauticalShipDataService.getByMmsi(mmsi).subscribe({
          next: (getByMmsiResponse) => {
            if (getByMmsiResponse.hasResult && getByMmsiResponse.result) {
              this.getShipDetails(
                getByMmsiResponse.result.id,
                getByMmsiResponse.result
              );
            }
          },
          error: (e) => {
            this.isLoading = false;
          },
        })
      );
    }
  }

  private getShipDetails(shipId: string, originalObject: NauticalShipModel) {
    this.nauticalShipDataService.getById(shipId).subscribe({
      next: (shipResult) => {
        if (shipResult) {
          this.ship = shipResult;
        } else {
          this.ship = originalObject;
        }
        this.isLoading = false;
      },
      error: (err) => {
        this.localShip = originalObject;
      },
    });
  }

  private getVisitDetails(shipId: string) {
    this.subscriptions$.push(
      this.nauticalVisitDataService
        .getListOverviewForShip(shipId, 'expectedinport')
        .subscribe(
          (res) => {
            if (res.result && res.result.length > 0) {
              this.visitActive = _.first(
                _.map(
                  _.filter(
                    res.result,
                    (visitFilter) => visitFilter.status === 2
                  ),
                  (visitMap) => {
                    const attributes: any[] = [];
                    if (visitMap.portName) {
                      attributes.push({
                        fieldName: 'Port',
                        fieldValue: visitMap.portName,
                      });
                    }
                    if (visitMap.referenceNumber) {
                      attributes.push({
                        fieldName: 'nautical.visit.model.referenceNumber',
                        fieldValue: visitMap.referenceNumber,
                      });
                    }
                    if (visitMap.portAgentName) {
                      attributes.push({
                        fieldName: 'nautical.terms.portAgent',
                        fieldValue: visitMap.portAgentName,
                      });
                    }
                    if (visitMap.ata) {
                      attributes.push({
                        fieldName: 'nautical.terms.ata',
                        fieldValue: moment(visitMap.ata).format(
                          'DD-MM-YYYY HH:mm'
                        ),
                      });
                    } else if (visitMap.eta) {
                      attributes.push({
                        fieldName: 'nautical.terms.eta',
                        fieldValue: moment(visitMap.eta).format(
                          'DD-MM-YYYY HH:mm'
                        ),
                      });
                    }
                    if (visitMap.atd) {
                      attributes.push({
                        fieldName: 'nautical.terms.atd',
                        fieldValue: moment(visitMap.atd).format(
                          'DD-MM-YYYY HH:mm'
                        ),
                      });
                    } else if (visitMap.etd) {
                      attributes.push({
                        fieldName: 'nautical.terms.etd',
                        fieldValue: moment(visitMap.etd).format(
                          'DD-MM-YYYY HH:mm'
                        ),
                      });
                    }
                    if (
                      visitMap.currentMovement &&
                      visitMap.currentMovement.portWayPointTo &&
                      (visitMap.currentMovement.ata ||
                        visitMap.currentMovement.atd)
                    ) {
                      if (visitMap.currentMovement.ata) {
                        attributes.push({
                          fieldName: 'nautical.terms.currentBerth',
                          fieldValue:
                            visitMap.currentMovement.portWayPointTo.name +
                            (visitMap.currentMovement.portWayPointTo.code
                              ? ' (' +
                                visitMap.currentMovement.portWayPointTo.code +
                                ')'
                              : ''),
                        });
                      } else if (visitMap.currentMovement.atd) {
                        attributes.push({
                          fieldName: 'nautical.terms.expectedBerth',
                          fieldValue:
                            visitMap.currentMovement.portWayPointTo.name +
                            (visitMap.currentMovement.portWayPointTo.code
                              ? ' (' +
                                visitMap.currentMovement.portWayPointTo.code +
                                ')'
                              : ''),
                        });
                      }
                    } else if (
                      visitMap.currentMovement &&
                      visitMap.currentMovement.portWayPointFrom
                    ) {
                      attributes.push({
                        fieldName: 'nautical.terms.currentBerth',
                        fieldValue:
                          visitMap.currentMovement.portWayPointFrom.name +
                          (visitMap.currentMovement.portWayPointFrom.code
                            ? ' (' +
                              visitMap.currentMovement.portWayPointFrom.code +
                              ')'
                            : ''),
                      });
                    }
                    visitMap['attributes'] = attributes;
                    return visitMap;
                  }
                )
              );
              this.visitsUpcoming = _.filter(
                res.result,
                (visitFilter) => visitFilter.status === 1
              );
              this.setVisitDetails();
            }
          },
          (e) => {},
          () => {}
        )
    );
  }

  navigate(route: string) {
    this.router.navigate([route]);
  }

  private resetState() {
    _.each(this.subscriptions$, (sub) => sub.unsubscribe());

    this.visitActive = undefined;
    this.visitsUpcoming = [];
    this.shipDataGroups = [];
    this.shipDetailGroups = [];
    this.ship = null;
    this.showPhoto = true;
  }

  private setShipDetails() {
    this.shipDetailGroups = [];
    this.setShipFromAIS();
    if (this.localShip.id) {
      this.getVisitDetails(this.localShip.id);
    }

    // Identification
    const identification = [];
    if (this.localShip.imo) {
      identification.push({
        key: 'nautical.ship.model.imo',
        value: this.localShip.imo,
      });
    }
    if (this.localShip.callSign) {
      identification.push({
        key: 'nautical.ship.model.callSign',
        value: this.localShip.callSign,
      });
    }
    if (this.localShip.flagCode) {
      identification.push({
        key: 'nautical.ship.model.flagCode',
        value: this.localShip.flagCode,
      });
    }
    if (this.localShip.eni) {
      identification.push({
        key: 'nautical.ship.model.eni',
        value: this.localShip.eni,
      });
    }
    if (this.localShip.fisheryNumber) {
      identification.push({
        key: 'nautical.ship.model.fisheryNumber',
        value: this.localShip.fisheryNumber,
      });
    }
    if (this.localShip.shipType && this.localShip.shipType.name) {
      identification.push({
        key: 'nautical.ship.shipType.shipType',
        value: this.localShip.shipType.name,
      });
    }
    if (this.localShip.mmsi) {
      identification.push({
        key: 'nautical.ship.model.mmsi',
        value: this.localShip.mmsi,
      });
    }
    if (identification.length > 0) {
      this.shipDetailGroups.push({
        display: _.first(_.chunk(identification, 3)),
        label: 'nautical.ship.identification',
        items: identification,
      });
    }

    // Dimensions
    const dimensions = [];
    if (this.localShip.loa && this.localShip.width) {
      dimensions.push({
        key: 'nautical.ship.model.size',
        value: this.localShip.loa + 'm x ' + this.localShip.width + 'm',
      });
    } else if (this.localShip.loa) {
      dimensions.push({
        key: 'nautical.ship.model.loa',
        value: this.localShip.loa + 'm',
      });
    } else if (this.localShip.width) {
      dimensions.push({
        key: 'nautical.ship.model.width',
        value: this.localShip.width + 'm',
      });
    }
    if (this.localShip.gt) {
      dimensions.push({
        key: 'nautical.ship.model.gt',
        value: this.localShip.gt + 't',
      });
    }
    if (this.localShip.dwt) {
      dimensions.push({
        key: 'nautical.ship.model.dwt',
        value: this.localShip.dwt + 't',
      });
    }
    if (this.localShip.maxDraft) {
      dimensions.push({
        key: 'nautical.ship.model.maxDraft',
        value: this.localShip.maxDraft + 'dm',
      });
    }
    if (dimensions.length > 0) {
      this.shipDetailGroups.push({
        display: _.first(_.chunk(dimensions, 3)),
        label: 'nautical.ship.dimensions',
        items: dimensions,
      });
    }

    // Position
    const position = [];
    if (this.aisInfo) {
      position.push({
        key: 'nautical.ship.aisPosition.speed',
        value: (this.aisInfo.speed ? this.aisInfo.speed : '0') + 'kts',
      });
      position.push({
        key: 'nautical.ship.aisPosition.destination',
        value: this.aisInfo.destination ? this.aisInfo.destination : '-',
      });
      position.push({
        key: 'nautical.ship.aisPosition.heading',
        value: (this.aisInfo.heading ? this.aisInfo.heading : '0') + '°',
      });
      position.push({
        key: 'nautical.ship.aisPosition.course',
        value:
          (this.aisInfo.courseOverGround
            ? this.aisInfo.courseOverGround
            : '0') + '°',
      });
      position.push({
        key: 'nautical.ship.aisPosition.lastUpdate',
        value: moment(this.aisInfo.positionDate).format('DD-MM-YYYY HH:mm'),
      });

      if (position.length > 0) {
        this.shipDetailGroups.push({
          display: _.first(_.chunk(position, 2)),
          label: 'nautical.ship.aisPosition.title',
          items: position,
        });
      }
    }
  }

  private setShipFromAIS() {
    if (this.localAISInfo && !this.localShip) {
      this.localShip = {
        imo: this.localAISInfo.imo,
        mmsi: this.localAISInfo.mmsi,
        name: this.localAISInfo.name,
        loa: this.localAISInfo.bow + this.localAISInfo.stern,
        shipTypeCategoryId: -1,
        width: this.localAISInfo.starboard + this.localAISInfo.port,
      };
      this.shipDataGroups = [];
      this.shipDetailGroups = [];
    }
  }

  private setVisitDetails() {
    if (this.visitsUpcoming && this.visitsUpcoming.length > 0) {
      this.shipDataGroups.push({
        label:
          this.translate.instant('nautical.visit.portVisits') +
          ' (' +
          this.translate.instant('nautical.visit.filter.expected') +
          ')',
        data: _.map(this.visitsUpcoming, (visitMap) => {
          const args = [];
          if (visitMap.portName) {
            args.push(visitMap.portName);
          }
          const thisYear = moment().year;
          if (visitMap.ata) {
            const dAta = moment(visitMap.ata);
            args.push(
              'ATA ' +
                moment(visitMap.ata).format(
                  dAta.year === thisYear ? 'DD-MM HH:mm' : 'DD-MM-YYYY HH:mm'
                )
            );
          } else if (visitMap.eta) {
            const dEta = moment(visitMap.eta);
            args.push(
              'ETA ' +
                moment(visitMap.eta).format(
                  dEta.year === thisYear ? 'DD-MM HH:mm' : 'DD-MM-YYYY HH:mm'
                )
            );
          }
          if (visitMap.atd) {
            const dAtd = moment(visitMap.atd);
            args.push(
              'ATD ' +
                moment(visitMap.atd).format(
                  dAtd.year === thisYear ? 'DD-MM HH:mm' : 'DD-MM-YYYY HH:mm'
                )
            );
          } else if (visitMap.etd) {
            const dEtd = moment(visitMap.etd);
            args.push(
              'ETD ' +
                moment(visitMap.etd).format(
                  dEtd.year === thisYear ? 'DD-MM HH:mm' : 'DD-MM-YYYY HH:mm'
                )
            );
          }

          return {
            display: args.join(', '),
            url: '/nautical/visit/' + visitMap.id,
          };
        }),
      });
    }
  }
}
