import { Component, Input, Output, EventEmitter } from '@angular/core';
import * as _ from 'underscore';
import { EventModel } from '../../../shared/models/event.model';
import { ObjectDefinitionModel } from '@seahorse/domain';

@Component({
  selector: 'ca-order-registration-factory-header',
  templateUrl: 'order-registration-factory-header.component.html',
})
export class OrderRegistrationFactoryHeaderComponent {
  @Input() loading: boolean;
  @Input() orderObjectDefinition: ObjectDefinitionModel;

  @Output() onEvent: EventEmitter<EventModel>;

  constructor() {
    this.loading = false;
    this.onEvent = new EventEmitter<EventModel>();
  }
}
