import * as moment from 'moment';
import * as _ from 'underscore';

import { ValidationResult } from '@seahorse/common';
import { NauticalShipModel } from '@seahorse/domain';
import { CustomDataBaseModel } from '@seahorse/domain';

export class CVVActivityModel extends CustomDataBaseModel {
  activityState: string;
  activityType: number;
  berth1: string;
  berth2: string;
  endsOn: Date;
  getOff: string;
  getOn: string;
  // public heavyMaterial:                       boolean;
  // public motorboat:                           number;
  movementType: number;
  numberOfRunners: number;
  orderNumber?: string;
  people: string[];
  // public personsAft:                          number;
  // public personsFront:                        number;
  // public personsMiddle:                       number;
  pierInbound: Date;
  pierOutbound: Date;
  pilot: boolean;
  receipts: number;
  remarks: string;
  startsOn: Date;
  tenderType: string;
  // public winchAft:                            number;
  // public winchFront:                          number;
  // public winchMiddle:                         number;
  $nautical_portmovement_id: number;
  $nautical_portvisit_referencenumber: string;
  $nautical_ship_id: number;
  $companies_company_id: number;
  groupId: string;

  // for display
  $nautical_ship: NauticalShipModel;

  constructor() {
    super();

    this.activityState = 'open';
    this.activityType = null;
    this.berth1 = null;
    this.berth2 = null;
    this.endsOn = null;
    this.getOff = null;
    this.getOn = null;
    // this.heavyMaterial                        = false;
    // this.motorboat                            = 0;
    this.movementType = null;
    this.numberOfRunners = 0;
    this.orderNumber = null;
    this.people = [];
    // this.personsAft                           = 0;
    // this.personsFront                         = 0;
    // this.personsMiddle                        = 0;
    this.pierInbound = null;
    this.pierOutbound = null;
    this.pilot = null;
    this.receipts = 0;
    this.remarks = null;
    this.startsOn = null;
    this.tenderType = null;
    // this.winchAft                             = 0;
    // this.winchFront                           = 0;
    // this.winchMiddle                          = 0;
    this.$nautical_portmovement_id = null;
    this.$nautical_portvisit_referencenumber = null;
    this.$nautical_ship_id = null;
    this.$companies_company_id = null;
    this.groupId = null;
  }

  static fromApiModel(model: any): CVVActivityModel {
    const result = _.clone(model);

    if (result.people && result.people.length > 0) {
      result.people = result.people.split(',');
    }

    return result;
  }

  static toApiModel(model: any): CVVActivityModel {
    const result = _.clone(model);

    if (model.people) {
      result.people = model.people.join();
    }

    return result;
  }

  get endsOnDisplay(): string {
    return this.endsOn === null
      ? ''
      : moment(this.endsOn).format('DD-MM-YYYY HH:mm');
  }

  set endsOnDisplay(date: string) {
    const momDate = moment(date, ['DD-MM-YYYY HH:mm', 'DD-MM-YYYY']);

    if (momDate.isValid()) {
      this.endsOn = momDate.toDate();
    }
  }

  get startsOnDisplay(): string {
    return this.startsOn === null
      ? ''
      : moment(this.startsOn).format('DD-MM-YYYY HH:mm');
  }

  set startsOnDisplay(date: string) {
    const momDate = moment(date, ['DD-MM-YYYY HH:mm', 'DD-MM-YYYY']);

    if (momDate.isValid()) {
      this.startsOn = momDate.toDate();
    }
  }

  get pierInboundDisplay(): string {
    return this.pierInbound === null
      ? ''
      : moment(this.pierInbound).format('DD-MM-YYYY HH:mm');
  }

  set pierInboundDisplay(date: string) {
    const momDate = moment(date, ['DD-MM-YYYY HH:mm', 'DD-MM-YYYY']);

    if (momDate.isValid()) {
      this.pierInbound = momDate.toDate();
    }
  }

  get pierOutboundDisplay(): string {
    return this.pierOutbound === null
      ? ''
      : moment(this.pierOutbound).format('DD-MM-YYYY HH:mm');
  }

  set pierOutboundDisplay(date: string) {
    const momDate = moment(date, ['DD-MM-YYYY HH:mm', 'DD-MM-YYYY']);

    if (momDate.isValid()) {
      this.pierOutbound = momDate.toDate();
    }
  }

  static validate(
    activity: CVVActivityModel,
    errorPrefix?: string
  ): ValidationResult {
    const result: ValidationResult = new ValidationResult();
    if (!errorPrefix) {
      errorPrefix = '';
    }

    if (!activity.activityState) {
      activity.activityState = 'open';
    }

    // valid activitytype; is required
    if (!activity.activityType || activity.activityType < 0) {
      result.errors.push(errorPrefix + 'Het veld type activiteit is leeg');
    }

    // valid movementtyoe when is set
    if (
      !activity.movementType ||
      isNaN(activity.movementType) ||
      activity.movementType < 0
    ) {
      result.errors.push(errorPrefix + 'Het veld type reisdeel is leeg');
    }

    // valid the time; startson is required
    let isStartValid = null;
    if (!activity.startsOn) {
      result.errors.push(errorPrefix + 'Het veld starttijd is leeg');
    } else {
      isStartValid = moment(activity.startsOn).isValid();
    }

    let isEndsValid = null;

    if (activity.endsOn) {
      isEndsValid = moment(activity.endsOn).isValid();
    }

    if (isStartValid === false) {
      result.errors.push(errorPrefix + 'Starttijd formaat is fout');
    }

    if (isEndsValid === false) {
      result.errors.push(errorPrefix + 'Endtijd formaat is fout');
    }

    if (
      isStartValid &&
      isEndsValid &&
      moment(activity.endsOn).isBefore(activity.startsOn)
    ) {
      result.errors.push(
        errorPrefix + 'Het veld eindtijd kan niet eerder dan starttijd'
      );
    }

    // // valid motorboat when is set
    // if(activity.motorboat !== undefined && activity.motorboat !== null) {
    //   if(isNaN(activity.motorboat) || activity.motorboat < 0)
    //     result.errors.push(errorPrefix + 'Het veld motorboten is ongeldig');
    // }

    // // valid winch when is set
    // if(activity.winchMiddle !== undefined && activity.winchMiddle !== null) {
    //   if(isNaN(activity.winchMiddle) || activity.winchMiddle < 0)
    //     result.errors.push(errorPrefix + 'Het veld winchwagens normaal is ongeldig');
    // }

    // if(activity.winchFront !== undefined && activity.winchFront !== null) {
    //   if(isNaN(activity.winchFront) || activity.winchFront < 0)
    //     result.errors.push(errorPrefix + 'Het veld winchwagens voor is ongeldig');
    // }

    // if(activity.winchAft !== undefined && activity.winchAft !== null) {
    //   if(isNaN(activity.winchAft) || activity.winchAft < 0)
    //     result.errors.push(errorPrefix + 'Het veld winchwagens achter is ongeldig');
    // }

    // // valid people when is set
    // if(activity.personsMiddle !== undefined && activity.personsMiddle !== null) {
    //   if(isNaN(activity.personsMiddle) || activity.personsMiddle < 0)
    //     result.errors.push(errorPrefix + 'Het veld personen normaal is ongeldig');
    // }

    // if(activity.personsFront !== undefined && activity.personsFront !== null) {
    //   if(isNaN(activity.personsFront) || activity.personsFront < 0)
    //     result.errors.push(errorPrefix + 'Het veld personen voor is ongeldig');
    // }

    // if(activity.personsAft !== undefined && activity.personsAft !== null) {
    //   if(isNaN(activity.personsAft) || activity.personsAft < 0)
    //     result.errors.push(errorPrefix + 'Het veld personen achter is ongeldig');
    // }

    // valid receipts when is set
    if (activity.receipts !== undefined && activity.receipts !== null) {
      if (isNaN(activity.receipts) || activity.receipts < 0) {
        result.errors.push(errorPrefix + 'Het veld bonnen is ongeldig');
      }
    }

    // valid numberOfRunners when is set
    if (
      activity.numberOfRunners !== undefined &&
      activity.numberOfRunners !== null
    ) {
      if (isNaN(activity.numberOfRunners) || activity.numberOfRunners < 0) {
        result.errors.push(errorPrefix + 'Het aantal personen is ongeldig');
      }
    }

    // valid the time pf the piee
    let isPierOutboundValid = null;

    if (activity.pierOutbound) {
      isPierOutboundValid = moment(activity.pierOutbound).isValid();
    }

    let isPierInboundValid = null;

    if (activity.pierInbound) {
      isPierInboundValid = moment(activity.pierInbound).isValid();
    }

    if (isPierOutboundValid === false) {
      result.errors.push(errorPrefix + 'Pier buiten formaat is fout');
    }

    if (isPierInboundValid === false) {
      result.errors.push(errorPrefix + 'Pier binnen formaat is fout');
    }

    if (
      isPierOutboundValid &&
      isPierInboundValid &&
      moment(activity.pierInbound).isBefore(activity.pierOutbound)
    ) {
      result.errors.push(
        errorPrefix + 'Het veld binnen pieren kan niet eerder dan buiten pieren'
      );
    }

    result.isValid = result.errors.length === 0;

    return result;
  }
}

export enum CVVActivityStatus {
  Open = 'open',
  Completed = 'completed',
  Invoiced = 'invoiced',
}
