import { Injectable } from '@angular/core';
import { ResultWrapper } from '@seahorse/common';
import { DataContextService } from '@seahorse/common';
import { Observable } from 'rxjs';

import { AreaActivityModel } from '../models/area-activity.model';
import { DataService } from '@seahorse/domain';

@Injectable()
export class AreaActivityDataService implements DataService<AreaActivityModel> {
  constructor(private dataContext: DataContextService) {}

  search(
    search?: string,
    pageIndex?: number,
    pageSize?: number
  ): Observable<ResultWrapper<AreaActivityModel[]>> {
    const queryParams = [];

    if (search !== undefined && search !== null) {
      queryParams.push(`search=${encodeURIComponent(search)}`);
    }

    if (pageIndex !== undefined && pageIndex !== null) {
      queryParams.push(`pindex=${pageIndex}`);
    }

    if (pageSize !== undefined && pageSize !== null) {
      queryParams.push(`psize=${pageSize}`);
    }

    const queryString = queryParams.join('&');
    const url = `su/nautical/areaactivities/search${
      queryString ? '?' + queryString : ''
    }`;

    return this.dataContext.get<AreaActivityModel[]>(url);
  }

  getById(
    areaActivityId: number
  ): Observable<ResultWrapper<AreaActivityModel>> {
    return this.dataContext.get<AreaActivityModel>(
      `su/nautical/areaactivities/${areaActivityId}`
    );
  }

  add(
    areaActivity: AreaActivityModel
  ): Observable<ResultWrapper<AreaActivityModel>> {
    return this.dataContext.post<AreaActivityModel>(
      `su/nautical/areaactivities`,
      areaActivity
    );
  }

  update(
    areaActivity: AreaActivityModel
  ): Observable<ResultWrapper<AreaActivityModel>> {
    return this.dataContext.put<AreaActivityModel>(
      `su/nautical/areaactivities/${areaActivity.id}`,
      areaActivity
    );
  }

  delete(areaActivityId: number): Observable<ResultWrapper<AreaActivityModel>> {
    return this.dataContext.delete<AreaActivityModel>(
      `su/nautical/areaactivities/${areaActivityId}`
    );
  }

  getAll(): Observable<ResultWrapper<AreaActivityModel[]>> {
    return this.dataContext.get<AreaActivityModel[]>(
      `su/nautical/areaactivities`
    );
  }
}
