<div class="wrapper">
  <div class="info">
    <ol>
      <li>{{ 'user.mfa.setup.step1' | translate }}</li>
      <li>{{ 'user.mfa.setup.step2' | translate }}</li>
    </ol>
  </div>

  <img *ngIf="safeSrc" [src]="safeSrc" width="250px" />

  <div class="setup-code">
    <button
      type="button"
      class="btn btn-link"
      *ngIf="!showManualEntryCode"
      (click)="showManualEntryCode = true"
    >
      {{ 'user.mfa.setup.showCode' | translate }}
    </button>
    <div *ngIf="showManualEntryCode">
      {{ setupCode.manualSetupCode }}
      <temp-copy-content-button
        [content]="setupCode.manualSetupCode"
      ></temp-copy-content-button>
    </div>
  </div>

  <div class="verification-code">
    <sh-text-input
      [formControl]="codeControl"
      [placeholder]="'user.mfa.verificationCode' | translate"
    ></sh-text-input>
  </div>
</div>
