import { BusinessLogicTimePeriods } from './business-logic-calculation.model';
import { BusinessLogicObject } from './business-logic-object.model';
import {
  ArrayOperators,
  BooleanOperators,
  ConditionScheduleTypes,
  DateOperators,
  NumericOperators,
  ScheduleOperators,
  StringOperators,
  TimespanOperators,
} from './operators.enum';

export type ConditionSetGroup = {
  evaluationSet: string;
  conditions: BusinessLogicObject[];
};

export class BusinessLogicCondition extends BusinessLogicObject {
  evaluationSet?: string;

  static groupByConditionSet(
    conditions: BusinessLogicCondition[]
  ): ConditionSetGroup[] {
    const conditionSets = [] as ConditionSetGroup[];
    // we need to unify null and undefined evaluationSets
    const uniqueSets = [
      ...new Set(conditions.map((x) => x.evaluationSet ?? null)),
    ];

    // group by evaluationSet
    uniqueSets.forEach((set) => {
      conditionSets.push({
        evaluationSet: set ?? 'shared.terms.default',
        conditions: conditions.filter((x) => (x.evaluationSet ?? null) === set),
      });
    });

    return conditionSets;
  }
}

export class ConditionArrayModel extends BusinessLogicCondition {
  sourceField: string;
  operator: ArrayOperators;
  values: string[];
}

export class ConditionBooleanModel extends BusinessLogicCondition {
  sourceField: string;
  operator: BooleanOperators;
}

export class ConditionDateModel extends BusinessLogicCondition {
  sourceField: string;
  operator: DateOperators;
  value: Date;
  secondValue: Date;
}

export class ConditionNumericModel extends BusinessLogicCondition {
  sourceField: string;
  operator: NumericOperators;
  value: number;
  secondValue: number;
}

export class ConditionScheduleModel extends BusinessLogicCondition {
  sourceFieldMin: string;
  sourceFieldMax: string;
  operator: ScheduleOperators;
  value: string;
  items: ConditionScheduleItem[];
}

export class ConditionStringModel extends BusinessLogicCondition {
  sourceField: string;
  operator: StringOperators;
  value: string;
}

export class ConditionTimespanModel extends BusinessLogicCondition {
  sourceFieldMin: string;
  sourceFieldMax: string;
  operator: TimespanOperators;
  operatorPeriod: BusinessLogicTimePeriods;
  value: number;
}

export class ConditionScheduleItem {
  from: ConditionScheduleObject;
  to: ConditionScheduleObject;
}

export class ConditionScheduleObject {
  scheduleType?: ConditionScheduleTypes;
  value?: string;
}
