import { Component, Input, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CVVActivityLogicService } from '../../services/cvv-activity-logic.service';

@Component({
  templateUrl: './movement-type-picker-modal.component.html',
})
// Retained legacy name, but this wasn't used anywhere else
export class CustomUIListPickerModal {
  @Input()
  label: string;

  @Input()
  title: string;

  selectedId: number;
  @Input()
  set selectedData(value: unknown) {
    this.selectedId = value['id'];
  }

  @Input()
  sourceData: CVVActivityLogicService['movementTypes'];

  private _ngbActiveModal = inject(NgbActiveModal);

  dismiss() {
    this._ngbActiveModal.close(null);
  }

  save() {
    this._ngbActiveModal.close(
      this.sourceData.find((x) => x['id'] === this.selectedId)
    );
  }
}
