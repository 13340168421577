import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ca-register-account-thank-you',
  templateUrl: './register-account-thank-you.component.html'
})
export class RegisterAccountThankYouComponent implements OnInit {

  ngOnInit(): void {
  }
}
