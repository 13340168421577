<div class="modal-header">
  <h4 class="modal-title-bold" *ngIf="modalHeader">{{ modalHeader }}</h4>
  <h4 class="modal-title-bold" *ngIf="!modalHeader">
    {{ 'shared.general.messageTitle' | translate }}
  </h4>
  <button type="button" class="close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p class="text-center">{{ modalBody | translate }}</p>
  <div *ngIf="!hideOkButton" class="row">
    <div class="col">
      <button type="button" class="btn btn-block btn-primary" (click)="ok()">
        {{ 'shared.terms.ok' | translate }}
      </button>
    </div>
  </div>
</div>
