import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ResultWrapper } from '@seahorse/common';
import { NotificationService } from '@seahorse/common';
import { TaskType } from '../../../../../tasks/modules/task/models/task.model';
import { UserModel } from '@seahorse/domain';
import { UserDataService } from '../../../../../user/services/user-data.service';
import * as _ from 'underscore';

import { TaskDefinitionModel } from '../../models/task-definition.model';

@Component({
  selector: 'task-definition-form',
  templateUrl: './task-definition-form.component.html',
})
export class TaskDefinitionFormComponent {
  @Input() taskDefinition: TaskDefinitionModel;

  taskTypes: number[];
  loadingUsers: boolean;

  constructor(
    private userData: UserDataService,
    private translate: TranslateService,
    private notify: NotificationService
  ) {
    this.taskDefinition = null;
    this.taskTypes = _.filter(_.toArray(TaskType), (t) =>
      _.isNumber(t)
    ) as number[];
    this.loadingUsers = false;
  }

  users(): Object[] {
    this.loadingUsers = true;

    let users: Object[];

    this.userData.getByOrganisation(false).subscribe(
      (r: ResultWrapper<UserModel[]>) => {
        if (r.hasResult) {
          users = r.result.map((u: UserModel) => {
            return { title: `${u.givenName} ${u.surname}`, value: u.id };
          });
        } else {
          this.notify.showError(
            _.pluck(r.messages, 'message').join('\n'),
            this.translate.instant('shared.terms.failed')
          );
        }
      },
      (e) => {
        this.notify.showError(
          _.pluck(e.error.messages, 'message').join('\n'),
          this.translate.instant('shared.terms.failed')
        );

        this.loadingUsers = false;
      },
      () => {
        this.loadingUsers = false;
      }
    );

    return users;
  }
}
