import { Injectable } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'underscore';

import { TranslateService } from '@ngx-translate/core';
import { NauticalWaypointModel } from '@seahorse/domain';
import { NauticalMovementModel } from '@seahorse/domain';
import { NauticalVisitFlowMarkerModel } from '../models/nautical-visit-flow-marker.model';
import { NauticalVisitFlowModel } from '../models/nautical-visit-flow.model';
import { NauticalVisitOverviewModel } from '@seahorse/domain';
import {
  NauticalVisitDetailsModel
} from '@seahorse/domain';
@Injectable()
// This service is used for utility functions that are used for the Nautical Visit Flow
export class NauticalVisitFlowUtilityService {
  constructor(private translate: TranslateService) {}

  // Convert a NauticalVisitDetailsModel into a NauticalVisitFlowModel
  convertVisitDetailsToFlow(
    visit: NauticalVisitDetailsModel,
    calculatePositions = true
  ): NauticalVisitFlowModel {
    if (!visit) {
      return;
    }

    // Create the output object
    const output = new NauticalVisitFlowModel();
    if (visit.nextPort) {
      output.endDisplayTextLong = visit.nextPort.name;
      output.endDisplayTextShort = visit.nextPort.unloCode;
      output.endDisplayTooltip =
        visit.nextPort.unloCode + ' - ' + visit.nextPort.name;
    } else if (visit.nextPortId) {
      output.endDisplayTextLong = visit.nextPortId.toString();
      output.endDisplayTextShort = visit.nextPortId.toString();
    }
    // Instantiate the ship marker
    output.shipMarker = new NauticalVisitFlowMarkerModel();
    output.shipMarker.displayTextLong =
      visit.referenceNumber + (visit.ship ? ' - ' + visit.ship.name : '');
    output.shipMarker.displayTextShort =
      visit.referenceNumber + (visit.ship ? ' - ' + visit.ship.name : '');

    if (visit.previousPort) {
      output.startDisplayTextLong = visit.previousPort.name;
      output.startDisplayTextShort = visit.previousPort.unloCode;
      output.startDisplayTooltip =
        visit.previousPort.unloCode + ' - ' + visit.previousPort.name;
    } else if (visit.previousPortId) {
      output.startDisplayTextLong = visit.previousPortId.toString();
      output.startDisplayTextShort = visit.previousPortId.toString();
    }

    // Process the movements
    if (visit.portMovements && visit.portMovements.length > 0) {
      const lastDate: moment.Moment = null;

      for (
        let movementIndex = 0;
        movementIndex < visit.portMovements.length;
        movementIndex++
      ) {
        const portMovement = visit.portMovements[movementIndex];

        this.convertMovementToMarker(portMovement, lastDate, output.markers);
      }

      // Sort the markers by date
      if (output.markers) {
        output.markers = _.sortBy(
          output.markers,
          (marker) => marker.actualDate
        );
      }
      // Combine the markers if they are times from the same waypoint
      /*
            let indicesToRemove = [];
            for (let markerIndex = 1; markerIndex < output.markers.length; markerIndex++) {
                const marker = output.markers[markerIndex];
                const previousMarker = output.markers[markerIndex - 1];

                if (previousMarker.displayType === 'ATA' && marker.displayType === 'ATD'
                    && previousMarker.displayText === marker.displayText) {
                    indicesToRemove.push(markerIndex - 1);
                    marker.displayType = 'ATA_ATD';
                }
            }

            if (indicesToRemove.length > 0)
                indicesToRemove.reverse().forEach(index => {
                    output.markers.splice(index, 1);
                });
            */

      // Filter out all markers with a date for position calculation purposes.
      const markersWithDate = _.filter(
        output.markers,
        (marker) =>
          marker.actualDate !== undefined && marker.actualDate !== null
      );

      // Find the earliest start date. This can be the ATA/ETA or first marker date. This date is used for the position calculation.
      let visitStart = visit.ata ? visit.ata : visit.eta;
      // If there are markers with a date go ahead and check them
      if (markersWithDate.length > 0) {
        try {
          // Get the date of the first marker
          const firstMarkerDate = markersWithDate[0].actualDate;
          // Compare it to the current visitStart and select the earliest one
          visitStart = !visitStart
            ? firstMarkerDate
            : [visitStart, firstMarkerDate].sort()[0];
        } catch {}
      }

      // Find the latest end date. This can be the ATD/ETD or the last marker date. This date is used for the position calculation.
      let visitEnd = visit.atd ? visit.atd : visit.etd;
      // If there are markers with a date go ahead and check them
      if (markersWithDate.length > 0) {
        try {
          // Get the date of the last marker
          const lastMarkerDate =
            markersWithDate[markersWithDate.length - 1].actualDate;
          // Compare it to the current visitEnd and select the latest one
          visitEnd = !visitEnd
            ? lastMarkerDate
            : [visitEnd, lastMarkerDate].sort()[1];
        } catch {}
      }

      // Map the markers to include the calculated position
      if (calculatePositions) {
        output.markers = _.map(output.markers, (marker) =>
          this.calculateMarkerPosition(marker, visitStart, visitEnd)
        );
        output.shipMarker = this.calculateMarkerPosition(
          output.shipMarker,
          visitStart,
          visitEnd
        );
      }

      // Return the output object
      return output;
    }
  }

  convertMovementToMarker(
    portMovement: NauticalMovementModel,
    lastDate: moment.Moment,
    markers: NauticalVisitFlowMarkerModel[]
  ) {
    if (!portMovement) {
      return;
    }

    if (markers === null || markers === undefined) {
      markers = [];
    }

    // Status 4 is 'Cancelled'
    if (portMovement.status === 4) {
      return;
    }

    // Process the ATD
    if (portMovement.atd) {
      // Convert the ATA to a moment object
      const atdMoment = moment(portMovement.atd);

      // Instantiate the output marker object
      const marker = new NauticalVisitFlowMarkerModel();
      marker.waypoint = portMovement.portWayPointFrom;
      // Set the actualDate to the ATD (keep this a Date object, not moment)
      marker.actualDate = portMovement.atd;
      // Set the activity type
      marker.activityType = 'ATD';
      // Set the displayDate based on the getDisplayDate function
      marker.displayDate = this.getDisplayDate(lastDate, atdMoment);
      // Check if it's a new date day or still the same day
      marker.isNewDate = !atdMoment.isSame(lastDate, 'day');
      // Set the displayType as ATD
      marker.displayType = 'minor';
      // The movement is finished, so the marker is too
      marker.isOpen = false;
      // Process the waypoint information
      if (portMovement.portWayPointFrom) {
        // Set the text for the display from the 'To' Waypoint
        marker.displayTextShort = this.getWaypointText(
          portMovement.portWayPointFrom
        );
        // Set the long description to include detailed information
        if (this.sameLocationAsLast(markers, portMovement.portWayPointFrom)) {
          marker.displayTextLong = this.translate.instant(
            'nautical.flow.departureFromBerth'
          );
        } else {
          marker.displayTextLong = portMovement.portWayPointFrom.name;
        }
      }
      // Push the created marker into the output
      markers.push(marker);
      // Set the last date to this one
      lastDate = atdMoment;
    } else if (portMovement.etd && !portMovement.ata) {
      // Convert the ETD to a moment object
      const etdMoment = moment(portMovement.etd);

      // Instantiate the output marker object
      const marker = new NauticalVisitFlowMarkerModel();
      marker.waypoint = portMovement.portWayPointFrom;
      // Set the actualDate to the ETD (keep this a Date object, not moment)
      marker.actualDate = portMovement.etd;
      // Set the activity type
      marker.activityType = 'ETD';
      // Set the displayDate based on the getDisplayDate function
      marker.displayDate = this.getDisplayDate(lastDate, etdMoment);
      // Check if it's a new date day or still the same day
      marker.isNewDate = !etdMoment.isSame(lastDate, 'day');
      // Set the displayType as ETD
      marker.displayType = 'minor';
      // The movement is not finished, so the marker isn't either
      marker.isOpen = true;
      // Process the waypoint information
      if (portMovement.portWayPointFrom) {
        // Set the text for the display from the 'From' Waypoint
        marker.displayTextShort = this.getWaypointText(
          portMovement.portWayPointFrom
        );
        // Set the long description to include detailed information
        if (this.sameLocationAsLast(markers, portMovement.portWayPointFrom)) {
          marker.displayTextLong = this.translate.instant(
            'nautical.flow.enteredAtBreakwaters'
          );
        } else {
          marker.displayTextLong = portMovement.portWayPointFrom.name;
        }
      } else {
        marker.displayTextShort = 'ETD';
        marker.displayTextLong = `${this.translate.instant(
          'nautical.flow.enteredAtBreakwatersFromUnknownLocation'
        )}`;
      }
      // Push the created marker into the output
      markers.push(marker);
      // Set the last date to this one
      lastDate = etdMoment;
    } else if (!portMovement.etd && !portMovement.atd) {
      // Instantiate the output marker object
      const marker = new NauticalVisitFlowMarkerModel();
      marker.waypoint = portMovement.portWayPointFrom;
      // Set the activity type
      marker.activityType = 'UNKNOWN';
      // Set the displayType as minor
      marker.displayType = 'minor';
      // The movement is not finished, so the marker isn't either
      marker.isOpen = true;
      // Process the waypoint information
      marker.displayTextShort = this.translate.instant('shared.terms.unknown');
      // Set the long description to include detailed information
      marker.displayTextLong = this.translate.instant(
        'nautical.flow.noDepartureInformation'
      );
      // Push the created marker into the output
      markers.push(marker);
    }

    // Process the ATA
    if (portMovement.ata) {
      // We won't show anything if we don't know what the waypoint is
      if (!portMovement.portWayPointTo) {
        return;
      }

      // Convert the ATA to a moment object
      const ataMoment = moment(portMovement.ata);

      // Instantiate the output marker object
      const marker = new NauticalVisitFlowMarkerModel();
      marker.waypoint = portMovement.portWayPointTo;
      // Set the actualDate to the ATA (keep this a Date object, not moment)
      marker.actualDate = portMovement.ata;
      // Set the activity type
      marker.activityType = 'ATA';
      // Set the displayDate based on the getDisplayDate function
      marker.displayDate = this.getDisplayDate(lastDate, ataMoment);
      // Check if it's a new date day or still the same day
      marker.isNewDate = !ataMoment.isSame(lastDate, 'day');
      // Set the displayType as ATA
      marker.displayType = 'major';
      // The movement is finished, so the marker is too
      marker.isOpen = false;
      // Process the waypoint information
      if (portMovement.portWayPointTo) {
        // Set the text for the display from the 'To' Waypoint
        marker.displayTextShort = this.getWaypointText(
          portMovement.portWayPointTo
        );
        // Set the tooltip to include detailed information
        marker.displayTextLong = portMovement.portWayPointTo.name;
      } else {
        marker.displayTextShort = this.translate.instant('nautical.terms.ata');
      }
      // Push the created marker into the output
      markers.push(marker);
      // Set the last date to this one
      lastDate = ataMoment;
    } else if (portMovement.eta) {
      // Convert the ETA to a moment object
      const etaMoment = moment(portMovement.eta);

      // Instantiate the output marker object
      const marker = new NauticalVisitFlowMarkerModel();
      marker.waypoint = portMovement.portWayPointTo;
      // Set the actualDate to the ETA (keep this a Date object, not moment)
      marker.actualDate = portMovement.eta;
      // Set the activity type
      marker.activityType = 'ETA';
      // Set the displayDate based on the getDisplayDate function
      marker.displayDate = this.getDisplayDate(lastDate, etaMoment);
      // Check if it's a new date day or still the same day
      marker.isNewDate = !etaMoment.isSame(lastDate, 'day');
      // Set the displayType as ETA
      marker.displayType = 'major';
      // The movement is not finished, so the marker isn't either
      marker.isOpen = true;
      // Process the waypoint information
      if (portMovement.portWayPointTo) {
        // Set the text for the display from the 'To' Waypoint
        marker.displayTextShort = this.getWaypointText(
          portMovement.portWayPointTo
        );
        // Set the tooltip to include detailed information
        marker.displayTextLong = portMovement.portWayPointTo.name;
      } else {
        marker.displayTextShort = this.translate.instant('nautical.terms.eta');
      }
      // Push the created marker into the output
      markers.push(marker);
      // Set the last date to this one
      lastDate = etaMoment;
    }

    if (
      portMovement.portVisitEvents &&
      portMovement.portVisitEvents.length > 0
    ) {
      // Production loop
      _.each(portMovement.portVisitEvents, (visitEvent) => {
        switch (visitEvent.activityType) {
          case 'ATA':
            break;
          case 'ATD':
            break;
          case 'BOATMEN_ON_ARRIVAL':
            break;
          case 'BOATMEN_ON_DEPARTURE':
            break;
          case 'ETA':
            break;
          case 'ETD':
            break;
          case 'LOCK_PASSAGE':
            {
              if (visitEvent.parameters === 'ATA') {
                if (visitEvent.startsOn) {
                  const marker = new NauticalVisitFlowMarkerModel();
                  marker.activityType = 'event';
                  marker.actualDate = visitEvent.startsOn;
                  marker.displayType = 'minor';
                  marker.displayDate = moment(visitEvent.startsOn).format(
                    'HH:mm'
                  );
                  marker.displayTextLong = this.translate.instant(
                    'nautical.visit.visitEvents.lock_in_long'
                  );
                  marker.displayTextShort = this.translate.instant(
                    'nautical.visit.visitEvents.lock_in'
                  );
                  markers.push(marker);
                }
                if (visitEvent.endsOn) {
                  const marker = new NauticalVisitFlowMarkerModel();
                  marker.activityType = 'event';
                  marker.actualDate = visitEvent.endsOn;
                  marker.displayType = 'minor';
                  marker.displayDate = moment(visitEvent.startsOn).format(
                    'HH:mm'
                  );
                  marker.displayTextLong = this.translate.instant(
                    'nautical.visit.visitEvents.lock_out_long'
                  );
                  marker.displayTextShort = this.translate.instant(
                    'nautical.visit.visitEvents.lock_out'
                  );
                  markers.push(marker);
                }
              }
            }
            break;
          case 'PILOTAGE_ON_ARRIVAL':
            if (visitEvent.startsOn) {
              const marker = new NauticalVisitFlowMarkerModel();
              marker.activityType = 'event';
              marker.actualDate = visitEvent.startsOn;
              marker.displayType = 'minor';
              marker.displayDate = moment(visitEvent.startsOn).format('HH:mm');
              marker.displayTextLong = this.translate.instant(
                'nautical.visit.visitEvents.pilotage_on_arrival'
              );
              marker.displayTextShort = marker.displayTextLong;
              markers.push(marker);
            }

            break;
          case 'PILOTAGE_ON_DEPARTURE':
            if (visitEvent.startsOn) {
              const marker = new NauticalVisitFlowMarkerModel();
              marker.activityType = 'event';
              marker.actualDate = visitEvent.startsOn;
              marker.displayType = 'minor';
              marker.displayDate = moment(visitEvent.startsOn).format('HH:mm');
              marker.displayTextLong = this.translate.instant(
                'nautical.visit.visitEvents.pilotage_on_departure'
              );
              marker.displayTextShort = marker.displayTextLong;
              markers.push(marker);
            }
            break;
          case 'TUGJOB_ON_ARRIVAL':
            // if (visitEvent.parameters) {
            //     _.each(visitEvent.parameters.split(';'), (param) => {
            //         var splitValues = param.split(':');
            //         if (splitValues[0] === 'tugs' && splitValues.length > 1 && parseInt(splitValues[1]) > 0) {
            //             if (visitEvent.startsOn) {
            //                 const marker = new NauticalVisitFlowMarkerModel();
            //                 marker.activityType = 'event';
            //                 marker.actualDate = lastDate.toDate();
            //                 marker.displayType = 'minor';
            //                 marker.displayDate = moment(lastDate).format('HH:mm');
            //                 marker.displayTextLong = splitValues[1] + ' '
            // + this.translate.instant('nautical.visit.visitEvents.tugs_on_arrival');
            //                 marker.displayTextShort = marker.displayTextLong;
            //                 markers.push(marker);
            //             }
            //         }
            //     });
            // }
            break;
          case 'TUGJOB_ON_DEPARTURE':
            // if (visitEvent.parameters) {
            //     _.each(visitEvent.parameters.split(';'), (param) => {
            //         var splitValues = param.split(':');
            //         if (splitValues[0] === 'tugs' && splitValues.length > 1 && parseInt(splitValues[1]) > 0) {
            //             if (visitEvent.startsOn) {
            //                 const marker = new NauticalVisitFlowMarkerModel();
            //                 marker.activityType = 'event';
            //                 marker.actualDate = .toDate();
            //                 marker.displayType = 'minor';
            //                 marker.displayDate = moment(lastDate).format('HH:mm');
            //                 marker.displayTextLong = splitValues[1]
            // + ' ' + this.translate.instant('nautical.visit.visitEvents.tugs_on_arrival');
            //                 marker.displayTextShort = marker.displayTextLong;
            //                 markers.push(marker);
            //             }
            //         }
            //     });
            // }
            break;
        }
      });

      /*
            // For debug: print them ALL!!!!
            _.each(portMovement.portVisitEvents, (visitEvent) => {
                const marker = new NauticalVisitFlowMarkerModel();
                marker.activityType = 'event';
                marker.actualDate = visitEvent.createdOn;
                marker.displayDate = moment(visitEvent.createdOn).format('HH:mm');
                marker.displayTextLong = visitEvent.activityType
                + ' (' + visitEvent.parameters + ') ' + moment(visitEvent.startsOn).format('HH:mm');
                marker.displayType = 'minor';
                markers.push(marker);
            });
            */
    }
  }

  convertVisitOverviewToFlow(
    visit: NauticalVisitOverviewModel
  ): NauticalVisitFlowModel {
    if (!visit) {
      return;
    }
    // Create the output object
    const output = new NauticalVisitFlowModel();
    if (visit.nextPort) {
      output.endDisplayTextLong = visit.nextPort.name;
      output.endDisplayTextShort = visit.nextPort.unloCode;
      output.endDisplayTooltip =
        visit.nextPort.unloCode + ' - ' + visit.nextPort.name;
    } else {
      output.endDisplayTextLong = this.translate.instant(
        'nautical.flow.unknownDestination'
      );
      output.endDisplayTextShort = 'XXXXX';
    }
    // Set the marker(s)
    this.convertMovementToMarker(
      visit.currentMovement,
      visit.ata ? moment(visit.ata) : moment(visit.eta),
      output.markers
    );

    // Instantiate the ship marker
    output.shipMarker = new NauticalVisitFlowMarkerModel();
    if (visit.previousPort) {
      output.startDisplayTextLong = visit.previousPort.name;
      output.startDisplayTextShort = visit.previousPort.unloCode;
      output.startDisplayTooltip =
        visit.previousPort.unloCode + ' - ' + visit.previousPort.name;
    } else {
      output.startDisplayTextLong = this.translate.instant(
        'nautical.flow.unknownOrigin'
      );
      output.startDisplayTextShort = 'XXXXX';
    }

    // Filter out all markers with a date for position calculation purposes.
    const markersWithDate = _.filter(
      output.markers,
      (marker) => marker.actualDate !== undefined && marker.actualDate !== null
    );

    // Find the earliest start date. This can be the ATA/ETA or first marker date. This date is used for the position calculation.
    let visitStart = visit.ata ? visit.ata : visit.eta;
    // If there are markers with a date go ahead and check them
    if (markersWithDate.length > 0) {
      try {
        // Get the date of the first marker
        const firstMarkerDate = markersWithDate[0].actualDate;
        // Compare it to the current visitStart and select the earliest one
        visitStart = !visitStart
          ? firstMarkerDate
          : [visitStart, firstMarkerDate].sort()[0];
      } catch {}
    }

    // Find the latest end date. This can be the ATD/ETD or the last marker date. This date is used for the position calculation.
    let visitEnd = visit.atd ? visit.atd : visit.etd;
    // If there are markers with a date go ahead and check them
    if (markersWithDate.length > 0) {
      try {
        // Get the date of the last marker
        const lastMarkerDate =
          markersWithDate[markersWithDate.length - 1].actualDate;
        // Compare it to the current visitEnd and select the latest one
        visitEnd = !visitEnd
          ? lastMarkerDate
          : [visitEnd, lastMarkerDate].sort()[1];
      } catch {}
    }

    output.markers = _.map(output.markers, (marker) =>
      this.calculateMarkerPosition(marker, visitStart, visitEnd)
    );
    output.shipMarker = this.calculateMarkerPosition(
      output.shipMarker,
      visitStart,
      visitEnd
    );

    return output;
  }

  // Calculate the relative display position for a marker, on the timeline
  private calculateMarkerPosition(
    marker: NauticalVisitFlowMarkerModel,
    startDate?: Date,
    endDate?: Date
  ) {
    const defaultSpace = 0.1;

    if (!marker) {
      return marker;
    }
    // If we don't have any dates to reference, return -1 to indicate this
    if (!startDate || !endDate) {
      marker.position = -1;
    } else {
      // Calculate the total amount of difference for the total timeline
      const totalDiff = moment(startDate).diff(moment(endDate));
      // The position is the difference from the start of the timeline relative to the total difference (a percentage of the whole)
      if (totalDiff !== 0) {
        marker.position =
          moment(startDate).diff(moment(marker.actualDate)) / totalDiff;
      } else {
        marker.position = defaultSpace;
      }
      // If the position is over 100%, something is wrong
      if (marker.position > 100) {
        marker.position = -1;
      } else if (marker.position < 0) {
        marker.position = -1;
      }
    }
    // Give the markers a default position if they are 0 or below
    if (marker.position <= 0) {
      marker.position = defaultSpace;
    }
    // Return the marker
    return marker;
  }

  // Form a display date based on the new marker date in reference to the last marker date
  private getDisplayDate(
    lastDate: moment.Moment,
    newDate: moment.Moment
  ): string {
    if (!lastDate && !newDate) {
      return null;
    }

    if (!lastDate) {
      if (newDate.isSame(lastDate, 'day')) {
        return newDate.format('HH:mm');
      }
      return newDate.format('DD-MM HH:mm');
    }

    if (newDate.isSame(lastDate, 'day')) {
      return newDate.format('HH:mm');
    }
    return newDate.format('DD-MM HH:mm');
  }

  // Get the text for the waypoint
  private getWaypointText(waypoint: NauticalWaypointModel): string {
    if (!waypoint) {
      return null;
    }

    if (waypoint.code) {
      return waypoint.code;
    }
    if (waypoint.number) {
      return waypoint.number;
    }
    if (waypoint.name) {
      return waypoint.name.length > 5
        ? waypoint.name.substring(0, 5) + '...'
        : waypoint.name;
    }
  }

  private sameLocationAsLast(
    currentMarkers: NauticalVisitFlowMarkerModel[],
    waypoint: NauticalWaypointModel
  ) {
    if (!currentMarkers || currentMarkers.length === 0) {
      return false;
    }
    if (!waypoint) {
      return false;
    }
    if (currentMarkers[currentMarkers.length - 1].waypoint) {
      return (
        currentMarkers[currentMarkers.length - 1].waypoint.id === waypoint.id
      );
    }
    return false;
  }
}
