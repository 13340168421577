export enum TariffTypes {
  Regular = 1,
  Surcharge = 2,
  Weekend = 3,
}

export enum TariffAmountTypes {
  Number = 1,
  Percentage = 2,
}
