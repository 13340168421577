import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { CustomKPComponentsModule } from './components/kp-components.module';
import { CustomKPPagesModule } from './pages/kp-pages.module';
import { CustomKPServicesModule } from './services/kp-services.module';
import { CustomKPPipesModule } from './pipes/kp-pipes.module';
import { KPActivitiesPage } from './pages/kp-activities/kp-activities.page';

const routes: Routes = [
  {
    path: 'activities',
    component: KPActivitiesPage,
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    // Child Modules
    CustomKPComponentsModule,
    CustomKPPagesModule,
    CustomKPPipesModule,
    CustomKPServicesModule,
  ],
})
export class CustomKPModule {}
