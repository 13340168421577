<div class="tile-container">
  <div
    *ngFor="let task of tasks; first as isFirst"
    [ngClass]="{
      selected: selected && selected.id == task.id,
      'mt-2': isFirst
    }"
    (click)="onEvent.emit({ action: 'select', data: task })"
    class="card tile tile-clickable"
  >
    <div class="card-body">
      <div class="row seperated-columns">
        <div class="col-12 col-sm-5">
          <b>{{ task.subject }}</b>
          <br />
          {{ task.description | shTruncate: 30 }}
        </div>
        <div class="col-12 col-sm-2">
          <div>
            <div>
              <dt></dt>
              <dd>
                <ng-container [ngSwitch]="task.taskType">
                  <i
                    *ngSwitchCase="TaskType.Call"
                    class="fa-regular fa-phone-alt fa-fw"
                  ></i>
                  <i
                    *ngSwitchCase="TaskType.SendEmail"
                    class="fa-regular fa-envelope fa-fw"
                  ></i>
                  <i
                    *ngSwitchCase="TaskType.Visit"
                    class="fa-regular fa-exchange-alt fa-fw"
                  ></i>
                  <i
                    *ngSwitchCase="TaskType.Process"
                    class="fa-regular fa-microchip fa-fw"
                  ></i>
                  <i
                    *ngSwitchCase="TaskType.Website"
                    class="fa-light fa-globe-pointer fa-fw"
                  ></i>
                </ng-container>
              </dd>
            </div>
            <div>
              <div>
                <dt></dt>
                <dd>
                  <ng-container [ngSwitch]="task.status">
                    <i
                      *ngSwitchCase="TaskStatus.Todo"
                      class="fa-solid fa-list-ul fa-fw text-primary"
                    ></i>
                    <i
                      *ngSwitchCase="TaskStatus.Done"
                      class="fa-regular fa-check fa-fw text-success"
                    ></i>
                    <i
                      *ngSwitchCase="TaskStatus.Cancelled"
                      class="fa-solid fa-times fa-fw text-danger"
                    ></i>
                    <i
                      *ngSwitchCase="TaskStatus.InProgress"
                      class="fa-regular fa-spinner fa-fw text-info"
                    ></i>
                  </ng-container>
                </dd>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-5">
          <div class="attribute-group attribute-group-col-2">
            <div class="attribute-group-item">
              <dt>{{ 'tasks.task.model.assignedTo' | translate }}</dt>
              <td>{{ task.assignedTo | nameFromUserId | async }}</td>
            </div>
          </div>
          <div class="attribute-group attribute-group-col-2">
            <ng-container
              *ngIf="task.completedOn; then completed; else notCompleted"
            ></ng-container>
            <ng-template #completed>
              <div class="attribute-group-item">
                <dt>{{ 'tasks.task.model.completedOn' | translate }}</dt>
                <dd>{{ task.completedOn | date: 'dd-MM-yyyy' }}</dd>
              </div>
            </ng-template>
            <ng-template #notCompleted>
              <div class="attribute-group-item">
                <dt>{{ 'tasks.task.model.dueDate' | translate }}</dt>
                <dd>{{ task.dueDate | date: 'dd-MM-yyyy' }}</dd>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
