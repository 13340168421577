import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { EventModel } from '../../../../shared/models/event.model';
import { ZeehavenIJmuidenVisitModel } from '../../models/zeehaven-ijmuiden-visit.model';

@Component({
  templateUrl: 'zeehaven-ijmuiden-visit-list-picker-modal.component.html',
})
export class ZeehavenIJmuidenVisitListPickerModalComponent {
  @Input() visits: ZeehavenIJmuidenVisitModel[];

  constructor(public modal: NgbActiveModal) {}

  dismiss(result: any) {
    this.modal.close(result);
  }

  onSelect(event: EventModel) {
    this.modal.close(event.data);
  }
}
