import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbActiveModal, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { KeyValuePair } from '@seahorse/common';
import { FieldType } from '@seahorse/domain';
import { merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import * as _ from 'underscore';
import { DateTimePickerComponent } from '../../controls/date-time-picker/date-time-picker.component';
import { FlagCodePickerDialogFormComponent } from '../../pickers/flag-code-picker-dialog-form/flag-code-picker-dialog-form.component';
import { FieldAttributeModel } from '../field-attribute.model';

@Component({
  selector: 'temp-edit-attribute-modal',
  templateUrl: 'edit-attribute-modal.component.html',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    DateTimePickerComponent,
    FlagCodePickerDialogFormComponent,
    TranslateModule,
    NgbTypeaheadModule,
  ],
})
export class EditAttributeModal {
  fieldTypes = null;
  focusItem = new Subject<string>();
  isValid = true;
  currentValue: any = null;
  isEditable: any = false;
  private _modalHeader: string;
  private _attribute: FieldAttributeModel = null;

  @Input() set attribute(attribute: FieldAttributeModel) {
    this._attribute = attribute;
    if (attribute.fieldType === undefined || attribute.fieldType === null) {
      this._attribute.fieldType = FieldType.MultiLineText;
    }

    if (
      this._attribute.fieldType === FieldType.List &&
      attribute !== undefined &&
      attribute !== null &&
      attribute.options !== undefined &&
      attribute.options !== null
    ) {
      this.isEditable = attribute.options.mustMatch !== true;
    }

    if (
      this._attribute.fieldType === FieldType.List &&
      Array.isArray(attribute.fieldValue) &&
      attribute.options &&
      attribute.options.max === 1
    ) {
      const foundItem = _.find(
        attribute.options.items,
        (item) =>
          item !== undefined &&
          item !== null &&
          item.key.toLowerCase() === attribute.fieldValue[0].toLowerCase()
      );
      this.currentValue = foundItem
        ? foundItem
        : new KeyValuePair(attribute.fieldValue[0], attribute.fieldValue[0]);
    } else {
      this.currentValue = _.clone(attribute.fieldValue);
    }
  }
  get attribute(): FieldAttributeModel {
    return this._attribute;
  }

  @Input() set modalHeader(modalHeader: string) {
    this._modalHeader = modalHeader;
  }
  get modalHeader(): string {
    return this._modalHeader;
  }

  @Input() modelName: string;

  constructor(public modal: NgbActiveModal) {
    this.fieldTypes = FieldType;
  }

  dismiss(result: any) {
    this.modal.close(result);
  }

  itemFormatter = (item: KeyValuePair<any, any>) => item.value;

  onItemSelected($event) {
    if ($event && $event.item) {
      this.currentValue = $event.item.key;
    } else {
      this.currentValue = null;
    }
  }

  searchItem = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const inputFocus$ = this.focusItem;

    // in case of fieldType = FieldType.List
    // options: {items: KeyValuePair<any, any>[], mustMatch: boolean}
    if (
      this.attribute.options === undefined ||
      this.attribute.options === null
    ) {
      this.attribute.options = { items: [], mustMatch: false };
    }

    if (
      this.attribute.options.items === undefined ||
      this.attribute.options.items === null
    ) {
      this.attribute.options.items = [];
    }

    return merge(debouncedText$, inputFocus$).pipe(
      map((term: string) => {
        return (
          !term || term === ''
            ? this.attribute.options.items.slice(0, 15)
            : this.attribute.options.items.filter(
                (v) => v.value.toLowerCase().indexOf(term.toLowerCase()) > -1
              )
        ).slice(0, 15);
      })
    );
  };

  save() {
    this.isValid = true;
    if (
      (this.attribute.fieldType === FieldType.Integer ||
        this.attribute.fieldType === FieldType.Decimal) &&
      this.currentValue !== undefined &&
      this.currentValue !== null
    ) {
      this.isValid = !isNaN(this.currentValue);
    }

    if (this.isValid) {
      if (
        this.attribute.fieldType === FieldType.List &&
        this.attribute.fieldCode != 'flagCode'
      ) {
        if (
          this.currentValue !== undefined &&
          this.currentValue !== null &&
          this.currentValue.key !== undefined
        ) {
          this.attribute.fieldValue = [this.currentValue.key]; // an itme is selected
        } else {
          this.attribute.fieldValue =
            this.currentValue !== undefined &&
            this.currentValue !== null &&
            this.currentValue.trim() !== ''
              ? [this.currentValue]
              : null;
        }
      } else {
        this.attribute.fieldValue = this.currentValue;
      }
      this.modal.close(this.attribute);
    }
  }
}
