import {
  Component, EventEmitter,
  Inject, Input, OnDestroy, OnInit, Output
} from '@angular/core';

import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import { CompanyContactModel, CompanyDataService } from '@seahorse/domain';
import { InteractionService } from '../../../../shared/services/interaction.service';
import { ContactModalComponent } from '../../contact/contact-modal/contact.modal.component';

import { CompanyModel } from '@seahorse/domain';
import { EventModel } from '../../../../shared/models/event.model';

@Component({
  selector: 'company-contacts-card',
  templateUrl: 'company-contacts-card.component.html',
})
export class CompanyContactsCardComponent implements OnInit, OnDestroy {
  @Input() companyId: CompanyModel['id'];

  @Output() onEvent: EventEmitter<EventModel>;

  canLoadMore: boolean;
  pageIndex = 0;
  pageSize = 5;
  contacts: CompanyContactModel[];

  private subscription: Subscription;

  constructor(
    @Inject('ContactInteractionService')
    private contactInteractionService: InteractionService,
    private companyDataService: CompanyDataService,
    private modalService: NgbModal
  ) {
    this.companyId = null;
    this.onEvent = new EventEmitter<EventModel>();

    this.subscription = this.contactInteractionService.modifyCalled$.subscribe(
      () => {
        this.reloadContacts();
      }
    );

    this.contactInteractionService.deleteCalled$.subscribe(() => {
      this.reloadContacts();
    });
  }

  ngOnInit() {
    this.pageIndex = 0;
    this.loadContacts();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  // action(action: any) {
  //     if (action.action) {
  //         switch (action.action) {
  //             case 'delete':
  //                 this.delete(action.contact);
  //                 break;
  //             case 'edit':
  //             case 'select':
  //                 this.selectContact(action.contact);
  //                 break;
  //         }
  //     }
  // }

  loadMore() {
    this.pageIndex += 1;
    this.loadContacts();
  }

  loadContacts() {
    this.companyDataService
      .getContacts(this.companyId, '', this.pageIndex, this.pageSize)
      .subscribe(
        (result) => {
          if (this.pageIndex > 0) {
            this.contacts = this.contacts.concat(result.result);
          } else {
            this.contacts = result.result;
          }
          this.canLoadMore = this.contacts.length < result.count;
        },
        () => {}
      );
  }

  selectContact(contact: CompanyContactModel) {
    const ngbModalOptions: NgbModalOptions = { backdrop: 'static' };
    const modalRef = this.modalService.open(
      ContactModalComponent,
      ngbModalOptions
    );

    if (contact != null) {
      modalRef.componentInstance.contactId = contact.id;
    }
  }

  delete(contact: CompanyContactModel) {
    // this.contactInteractionService.delete(contact);
  }

  reloadContacts() {
    this.pageIndex = 0;
    this.loadContacts();
  }
}
