import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CollectionDisplay } from '../../../layout/models/collection-display.model';
import { SelectedSearchModel } from '../../../layout/models/selectedSearch.model';
import { LayoutDisplayService } from '../../../layout/services/layout-display.service';
import { SearchService } from '../../../layout/services/search-service';
import { SystemMessageModalComponent } from '../../../preference/components/system-message/system-message-modal/system-message-modal.component';
import { SystemMessageModel } from '../../../preference/models/system-message.model';
import { SystemMessageService } from '../../../preference/services/system-message.service';
import { InteractionService } from '../../../shared/services/interaction.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'underscore';

@Component({
  selector: 'ca-system-messages-overview',
  templateUrl: './system-messages-overview.page.html',
})
export class SystemMessagesOverviewPage implements OnInit, OnDestroy {
  canLoadMore = false;
  collectionDisplay = CollectionDisplay;
  isInitial = true;
  isLoading = false;
  pageIndex = 0;
  pageSize = 20;
  search = '';
  systemMessages: SystemMessageModel[] = [];
  selectedSystemMessage: SystemMessageModel = null;
  selectedView = CollectionDisplay.List;

  private destroy$ = new Subject();

  constructor(
    @Inject('SystemMessageInteractionService')
    private systemMessageInteractionService: InteractionService,
    private layoutDisplay: LayoutDisplayService,
    private searchService: SearchService,
    private systemMessageService: SystemMessageService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.layoutDisplay.currentModuleName = 'System messages';

    this.searchService.searchChanged$
      .pipe(takeUntil(this.destroy$))
      .subscribe((search: SelectedSearchModel) => {
        this.search = search.searchQuery;
        this.findSystemMessages();
      });

    this.searchService.selectedViewCalled$
      .pipe(takeUntil(this.destroy$))
      .subscribe((view: CollectionDisplay) => {
        this.selectedView = view;
      });

    this.systemMessageInteractionService.modifyCalled$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.reloadPage();
      });

    this.systemMessageInteractionService.deleteCalled$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.reloadPage();
      });

    this.searchService.openComponent$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.addSystemMessage();
      });

    this.loadSystemMessages();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadSystemMessages() {
    this.isLoading = true;
    this.systemMessageService
      .get(this.search, this.pageSize)
      .subscribe((result) => {
        this.searchService.searchResultCountChanged(result.count);

        if (this.pageIndex > 0) {
          this.systemMessages = this.systemMessages.concat(result.result);
        } else {
          this.systemMessages = result.result;
        }
        this.canLoadMore =
          this.systemMessages && this.systemMessages.length < result.count;
      })
      .add(() => {
        this.isLoading = false;
        this.isInitial = false;
      });
  }

  selectSystemMessage(message: SystemMessageModel) {
    this.selectedSystemMessage = message;
    if (message != null) {
      this.router.navigate(
        ['../systemmessages/', { outlets: { summary: [message.id] } }],
        { relativeTo: this.route }
      );
    } else {
      this.router.navigate(
        ['../systemmessages/', { outlets: { summary: null } }],
        { relativeTo: this.route }
      );
    }
  }

  addSystemMessage() {
    const ngbModalOptions: NgbModalOptions = { backdrop: 'static' };
    const modalRef = this.modalService.open(
      SystemMessageModalComponent,
      ngbModalOptions
    );
    const systemMessage = new SystemMessageModel();
    systemMessage.createdOn = new Date();
    modalRef.componentInstance.systemMessage = systemMessage;

    modalRef.componentInstance.addedSystemMessage.subscribe((response) => {
      this.systemMessages.push(response);
      this.systemMessages = _.sortBy(this.systemMessages, 'CreatedOn');
    });
  }

  findSystemMessages() {
    this.pageIndex = 0;
    this.selectedSystemMessage = null;
    this.loadSystemMessages();
  }

  loadMore() {
    this.pageIndex += 1;
    this.loadSystemMessages();
  }

  reloadPage() {
    this.findSystemMessages();
  }
}
