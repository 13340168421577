<main class="page-with-header">
  <zeehaven-ijmuiden-details-header
    [loading]="loading"
    [status]="zMovement ? zMovement.status : ''"
    (onEvent)="action($event)"
  ></zeehaven-ijmuiden-details-header>
  <section class="container-fluid py-3">
    <ng-container
      *ngIf="loading && !zMovement; then loadVisit; else showVisit"
    ></ng-container>
    <ng-template #showVisit>
      <div class="row my-n2">
        <div class="col-12 col-xl-8 col-lg-7 col-md-6 py-2">
          <div class="card">
            <div class="card-header">
              {{ 'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.details.info'
              | translate }}
            </div>
            <div class="card-body">
              <div class="card-body-group card-body-group-no-border">
                <div class="row no-gutters attribute-group py-0">
                  <div
                    (click)="openSuggestionPicker()"
                    class="col-12 attribute-group-item attribute-group-item-editable py-0"
                  >
                    <div class="row no-gutters attribute-group w-100 py-0">
                      <ng-container
                        *ngIf="zMovement.$nautical_ship; then showSuggestion; else noSuggestion"
                      >
                      </ng-container>
                      <ng-template #showSuggestion>
                        <div class="col-12 attribute-group-item">
                          <dt class="mb-0">
                            {{ 'nautical.ship.model.name' | translate }}
                          </dt>
                          <dd *ngIf="zMovement.$nautical_ship.name">
                            {{ zMovement.$nautical_ship.name | uppercase }}
                            <i
                              class="fa-regular fa-pencil-alt fa-fw btn-editable text-muted float-right"
                            ></i>
                          </dd>
                        </div>
                        <div class="col-12 col-md-6 attribute-group-item">
                          <dt class="mb-0">
                            {{ 'nautical.ship.model.callSign' | translate }}
                          </dt>
                          <dd *ngIf="zMovement.$nautical_ship.callSign">
                            {{ zMovement.$nautical_ship.callSign }}
                          </dd>
                        </div>
                        <div class="col-12 col-md-6 attribute-group-item">
                          <dt class="mb-0">
                            {{ 'nautical.ship.model.fisheryNumber' | translate
                            }}
                          </dt>
                          <dd *ngIf="zMovement.$nautical_ship.fisheryNumber">
                            {{ zMovement.$nautical_ship.fisheryNumber }}
                          </dd>
                        </div>
                        <div class="col-12 col-md-6 attribute-group-item">
                          <dt class="mb-0">
                            {{ 'nautical.ship.model.imo' | translate }}
                          </dt>
                          <dd *ngIf="zMovement.$nautical_ship.imo">
                            {{ zMovement.$nautical_ship.imo }}
                          </dd>
                        </div>
                        <div class="col-12 col-md-6 attribute-group-item">
                          <dt class="mb-0">
                            {{ 'nautical.ship.model.eni' | translate }}
                          </dt>
                          <dd *ngIf="zMovement.$nautical_ship.eni">
                            {{ zMovement.$nautical_ship.eni }}
                          </dd>
                        </div>
                        <div class="col-12 col-md-6 attribute-group-item">
                          <dt class="mb-0">
                            {{ 'nautical.ship.model.loa' | translate }}
                          </dt>
                          <dd *ngIf="zMovement.$nautical_ship.loa">
                            {{ zMovement.$nautical_ship.loa }} m
                          </dd>
                        </div>
                        <ng-container *ngIf="zMovement.$nautical_portvisit">
                          <div class="col-12 attribute-group-item">
                            <dt class="mb-0">
                              {{ 'nautical.visit.model.referenceNumber' |
                              translate }}
                            </dt>
                            <dd
                              *ngIf="zMovement.$nautical_portvisit.referenceNumber"
                            >
                              {{ zMovement.$nautical_portvisit.referenceNumber
                              }}
                            </dd>
                          </div>
                          <div class="col-12 attribute-group-item">
                            <dt class="mb-0">
                              {{ 'nautical.terms.port' | translate }}
                            </dt>
                            <dd
                              *ngIf="zMovement.$nautical_portvisit.port && zMovement.$nautical_portvisit.port.name"
                            >
                              {{ zMovement.$nautical_portvisit.port.name }}
                            </dd>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="zMovement.$nautical_movement">
                          <div class="col-12 col-md-6 attribute-group-item">
                            <dt class="mb-0">
                              {{ 'nautical.terms.eta' | translate }}
                            </dt>
                            <dd *ngIf="zMovement.$nautical_movement.eta">
                              {{ zMovement.$nautical_movement.eta | date:
                              'dd-MM-yyyy HH:mm' }}
                            </dd>
                          </div>
                          <div class="col-12 col-md-6 attribute-group-item">
                            <dt class="mb-0">
                              {{ 'nautical.terms.etd' | translate }}
                            </dt>
                            <dd *ngIf="zMovement.$nautical_movement.etd">
                              {{ zMovement.$nautical_movement.etd | date:
                              'dd-MM-yyyy HH:mm' }}
                            </dd>
                          </div>
                          <div class="col-12 col-md-6 attribute-group-item">
                            <dt class="mb-0">
                              {{ 'nautical.terms.ata' | translate }}
                            </dt>
                            <dd *ngIf="zMovement.$nautical_movement.ata">
                              {{ zMovement.$nautical_movement.ata | date:
                              'dd-MM-yyyy HH:mm' }}
                            </dd>
                          </div>
                          <div class="col-12 col-md-6 attribute-group-item">
                            <dt class="mb-0">
                              {{ 'nautical.terms.atd' | translate }}
                            </dt>
                            <dd *ngIf="zMovement.$nautical_movement.atd">
                              {{ zMovement.$nautical_movement.atd | date:
                              'dd-MM-yyyy HH:mm' }}
                            </dd>
                          </div>
                        </ng-container>
                      </ng-template>
                      <ng-template #noSuggestion>
                        <div class="col-12 attribute-group-item">
                          <dt class="mb-0">
                            {{
                            'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.details.suggestionNotFound'
                            | translate }}
                          </dt>
                          <dd>
                            <i
                              class="fa-regular fa-pencil-alt fa-fw btn-editable text-muted float-right"
                            ></i>
                          </dd>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-xl-4 col-lg-5 col-md-6 py-2">
          <div class="card h-100" style="min-height: 250px">
            <div class="card-header bg-white">
              {{
              'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.details.passageData'
              | translate }}
            </div>
            <div class="card-body">
              <ng-container
                *ngIf="!loading && zMovement.$nautical_portvisit; then showVisitFlow; else loadVisitFlow"
              >
              </ng-container>
              <ng-template #showVisitFlow>
                <div
                  class="position-absolute"
                  style="
                    overflow-y: scroll;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                  "
                >
                  <ca-nautical-visit-flow
                    [nautical-visit]="zMovement.$nautical_portvisit"
                  ></ca-nautical-visit-flow>
                </div>
              </ng-template>
              <ng-template #loadVisitFlow>
                <span class="text-muted"
                  >{{
                  'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.details.noVisitSelected'
                  | translate }}</span
                >
              </ng-template>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4 py-2">
          <div class="row my-n2">
            <div class="col-12 py-2">
              <div class="card">
                <div class="card-header">
                  {{
                  'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.details.basicData'
                  | translate }}
                </div>
                <div class="card-body">
                  <div class="attribute-group">
                    <div
                      (click)="openAttributePicker('shipName', 'text')"
                      class="attribute-group-item attribute-group-item-editable"
                    >
                      <dt class="mb-0">
                        {{
                        'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.model.shipName'
                        | translate }}
                      </dt>
                      <dd>
                        <ng-container *ngIf="zMovement.shipName"
                          >{{ zMovement.shipName }}</ng-container
                        >
                        <i
                          class="fa-regular fa-pencil-alt fa-fw btn-editable text-muted float-right"
                        ></i>
                      </dd>
                    </div>
                  </div>
                  <div class="attribute-group">
                    <div
                      (click)="openAttributePicker('movementType', 'movementType', true)"
                      class="attribute-group-item attribute-group-item-editable"
                    >
                      <dt class="mb-0">
                        {{
                        'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.model.movementType'
                        | translate }}
                      </dt>
                      <dd>
                        {{ zMovement.movementType |
                        zeehavenIJmuidenVisitMovementType }}
                        <i
                          class="fa-regular fa-pencil-alt fa-fw btn-editable text-muted float-right"
                        ></i>
                      </dd>
                    </div>
                  </div>
                  <div class="attribute-group">
                    <div
                      (click)="openAttributePicker('etaOrEtd', 'dateTime')"
                      class="attribute-group-item attribute-group-item-editable"
                    >
                      <dt class="mb-0">
                        {{
                        'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.model.etaOrEtd'
                        | translate }}
                      </dt>
                      <dd>
                        <ng-container *ngIf="zMovement.etaOrEtd"
                          >{{ zMovement.etaOrEtd | date: 'dd-MM-yyyy HH:mm' }}
                        </ng-container>
                        <i
                          class="fa-regular fa-pencil-alt fa-fw btn-editable text-muted float-right"
                        ></i>
                      </dd>
                    </div>
                  </div>
                  <div class="attribute-group">
                    <div
                      (click)="openDosFormPicker()"
                      class="attribute-group-item attribute-group-item-editable"
                    >
                      <dt class="mb-0">
                        {{
                        'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.model.dosForm'
                        | translate }}
                      </dt>
                      <dd>
                        <span *ngIf="zMovement.dosForm">
                          <i class="fa-regular fa-check fa-fw"></i>
                        </span>
                        <span *ngIf="!zMovement.dosForm">
                          <i class="fa-regular fa-minus fa-fw"></i>
                        </span>
                        <span *ngIf="zMovement.dosFormDate" class="ml-2"
                          >{{ zMovement.dosFormDate | date: 'dd-MM-yyyy'
                          }}</span
                        >
                        <i
                          class="fa-regular fa-pencil-alt fa-fw btn-editable text-muted float-right"
                        ></i>
                      </dd>
                    </div>
                  </div>
                  <div class="attribute-group">
                    <div
                      (click)="openAttributePicker('captainsEta', 'boolean')"
                      class="attribute-group-item attribute-group-item-editable"
                    >
                      <dt class="mb-0">
                        {{
                        'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.model.captainsEta'
                        | translate }}
                      </dt>
                      <dd>
                        <span *ngIf="zMovement.captainsEta">
                          <i class="fa-regular fa-check fa-fw"></i>
                        </span>
                        <span *ngIf="!zMovement.captainsEta">
                          <i class="fa-regular fa-minus fa-fw"></i>
                        </span>
                        <i
                          class="fa-regular fa-pencil-alt fa-fw btn-editable text-muted float-right"
                        ></i>
                      </dd>
                    </div>
                  </div>
                  <div class="attribute-group">
                    <div
                      (click)="openAnchoredPicker()"
                      class="attribute-group-item attribute-group-item-editable"
                    >
                      <dt class="mb-0">
                        {{
                        'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.model.anchored'
                        | translate }}
                      </dt>
                      <dd>
                        <span *ngIf="zMovement.anchored">
                          <i class="fa-regular fa-check fa-fw"></i>
                        </span>
                        <span *ngIf="!zMovement.anchored">
                          <i class="fa-regular fa-minus fa-fw"></i>
                        </span>
                        <span *ngIf="zMovement.anchoredMessage" class="ml-2"
                          >{{ zMovement.anchoredMessage }}</span
                        >
                        <i
                          class="fa-regular fa-pencil-alt fa-fw btn-editable text-muted float-right"
                        ></i>
                      </dd>
                    </div>
                  </div>
                  <div class="attribute-group">
                    <div
                      (click)="openAttributePicker('plannedLock', 'text')"
                      class="attribute-group-item attribute-group-item-editable"
                    >
                      <dt class="mb-0">
                        {{
                        'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.model.plannedLock'
                        | translate }}
                      </dt>
                      <dd>
                        {{ zMovement.plannedLock }}
                        <i
                          class="fa-regular fa-pencil-alt fa-fw btn-editable text-muted float-right"
                        ></i>
                      </dd>
                    </div>
                  </div>
                  <div class="attribute-group">
                    <div
                      (click)="openAttributePicker('ataOrAtd', 'dateTime')"
                      class="attribute-group-item attribute-group-item-editable"
                    >
                      <dt class="mb-0">
                        {{
                        'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.model.ataOrAtd'
                        | translate }}
                      </dt>
                      <dd>
                        <ng-container *ngIf="zMovement.ataOrAtd"
                          >{{ zMovement.ataOrAtd | date: 'dd-MM-yyyy HH:mm' }}
                        </ng-container>
                        <i
                          class="fa-regular fa-pencil-alt fa-fw btn-editable text-muted float-right"
                        ></i>
                      </dd>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 py-2">
              <div class="card">
                <div class="card-header">
                  {{
                  'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.model.origin'
                  | translate }}
                </div>
                <div class="card-body">
                  <div class="attribute-group">
                    <div
                      (click)="zMovement.movementType === 1 ? openGeographyPicker('origin', 'port') : openGeographyPicker('origin', 'waypoint')"
                      class="attribute-group-item attribute-group-item-editable"
                    >
                      <dd>
                        <ng-container
                          *ngIf="zMovement.origin; then showOrigin; else makeOrigin"
                        ></ng-container>
                        <ng-template #showOrigin
                          >{{ zMovement.origin }}</ng-template
                        >
                        <ng-template #makeOrigin>
                          <span class="text-muted"
                            >{{
                            'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.details.makeOrigin'
                            | translate }}</span
                          >
                        </ng-template>
                        <i
                          class="fa-regular fa-pencil-alt fa-fw btn-editable text-muted float-right"
                        ></i>
                      </dd>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 py-2">
              <div class="card">
                <div class="card-header">
                  {{
                  'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.model.destination'
                  | translate }}
                </div>
                <div class="card-body">
                  <div class="attribute-group">
                    <div
                      (click)="zMovement.movementType === 3 ? openGeographyPicker('destination', 'port') : openGeographyPicker('destination', 'waypoint')"
                      class="attribute-group-item attribute-group-item-editable"
                    >
                      <dd>
                        <ng-container
                          *ngIf="zMovement.destination; then showDestination; else makeDestination"
                        >
                        </ng-container>
                        <ng-template #showDestination
                          >{{ zMovement.destination }}</ng-template
                        >
                        <ng-template #makeDestination>
                          <span class="text-muted"
                            >{{
                            'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.details.makeDestination'
                            | translate }}</span
                          >
                        </ng-template>
                        <i
                          class="fa-regular fa-pencil-alt fa-fw btn-editable text-muted float-right"
                        ></i>
                      </dd>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4 py-2">
          <div class="row my-n2">
            <div class="col-12 py-2">
              <div class="card">
                <div class="card-header">
                  {{
                  'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.details.shipData'
                  | translate }}
                </div>
                <div class="card-body">
                  <div class="attribute-group">
                    <div
                      (click)="openAttributePicker('agent', 'text')"
                      class="attribute-group-item attribute-group-item-editable"
                    >
                      <dt class="mb-0">
                        {{
                        'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.model.agent'
                        | translate }}
                      </dt>
                      <dd>
                        <ng-container *ngIf="zMovement.agent"
                          >{{ zMovement.agent }}</ng-container
                        >
                        <i
                          class="fa-regular fa-pencil-alt fa-fw btn-editable text-muted float-right"
                        ></i>
                      </dd>
                    </div>
                    <div
                      (click)="openAttributePicker('owner', 'text')"
                      class="attribute-group-item attribute-group-item-editable"
                    >
                      <dt class="mb-0">
                        {{
                        'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.model.owner'
                        | translate }}
                      </dt>
                      <dd>
                        <ng-container *ngIf="zMovement.owner"
                          >{{ zMovement.owner }}</ng-container
                        >
                        <i
                          class="fa-regular fa-pencil-alt fa-fw btn-editable text-muted float-right"
                        ></i>
                      </dd>
                    </div>
                    <div
                      (click)="openAttributePicker('operator', 'text')"
                      class="attribute-group-item attribute-group-item-editable"
                    >
                      <dt class="mb-0">
                        {{
                        'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.model.operator'
                        | translate }}
                      </dt>
                      <dd>
                        <ng-container *ngIf="zMovement.operator"
                          >{{ zMovement.operator }}</ng-container
                        >
                        <i
                          class="fa-regular fa-pencil-alt fa-fw btn-editable text-muted float-right"
                        ></i>
                      </dd>
                    </div>
                    <div
                      (click)="openAttributePicker('technicalManager', 'text')"
                      class="attribute-group-item attribute-group-item-editable"
                    >
                      <dt class="mb-0">
                        {{
                        'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.model.technicalManager'
                        | translate }}
                      </dt>
                      <dd>
                        <ng-container *ngIf="zMovement.technicalManager"
                          >{{ zMovement.technicalManager }}</ng-container
                        >
                        <i
                          class="fa-regular fa-pencil-alt fa-fw btn-editable text-muted float-right"
                        ></i>
                      </dd>
                    </div>
                    <div
                      (click)="openAttributePicker('shipManager', 'text')"
                      class="attribute-group-item attribute-group-item-editable"
                    >
                      <dt class="mb-0">
                        {{
                        'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.model.shipManager'
                        | translate }}
                      </dt>
                      <dd>
                        <ng-container *ngIf="zMovement.shipManager"
                          >{{ zMovement.shipManager }}</ng-container
                        >
                        <i
                          class="fa-regular fa-pencil-alt fa-fw btn-editable text-muted float-right"
                        ></i>
                      </dd>
                    </div>
                    <div
                      (click)="openAttributePicker('contents', 'text')"
                      class="attribute-group-item attribute-group-item-editable"
                    >
                      <dt class="mb-0">
                        {{
                        'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.model.contents'
                        | translate }}
                      </dt>
                      <dd>
                        <ng-container *ngIf="zMovement.contents"
                          >{{ zMovement.contents }}</ng-container
                        >
                        <i
                          class="fa-regular fa-pencil-alt fa-fw btn-editable text-muted float-right"
                        ></i>
                      </dd>
                    </div>
                    <div
                      (click)="openAttributePicker('originalContents', 'text')"
                      class="attribute-group-item attribute-group-item-editable"
                    >
                      <dt class="mb-0">
                        {{
                        'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.model.originalContents'
                        | translate }}
                      </dt>
                      <dd>
                        <ng-container *ngIf="zMovement.originalContents"
                          >{{ zMovement.originalContents }}</ng-container
                        >
                        <i
                          class="fa-regular fa-pencil-alt fa-fw btn-editable text-muted float-right"
                        ></i>
                      </dd>
                    </div>
                    <div
                      (click)="openAttributePicker('maxDraft', 'text')"
                      class="attribute-group-item attribute-group-item-editable"
                    >
                      <dt class="mb-0">
                        {{
                        'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.model.maxDraft'
                        | translate }}
                      </dt>
                      <dd>
                        <ng-container *ngIf="zMovement.maxDraft"
                          >{{ zMovement.maxDraft }} dm</ng-container
                        >
                        <i
                          class="fa-regular fa-pencil-alt fa-fw btn-editable text-muted float-right"
                        ></i>
                      </dd>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 py-2">
              <div class="card">
                <div class="card-header">
                  {{
                  'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.model.comments'
                  | translate }}
                </div>
                <div class="card-body">
                  <div class="attribute-group">
                    <div
                      (click)="openAttributePicker('comments', 'textarea')"
                      class="attribute-group-item attribute-group-item-editable"
                    >
                      <dd>
                        <ng-container
                          *ngIf="zMovement.comments; then showComments; else makeComments"
                        ></ng-container>
                        <ng-template #showComments
                          >{{ zMovement.comments }}</ng-template
                        >
                        <ng-template #makeComments>
                          <span class="text-muted"
                            >{{
                            'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.details.makeComments'
                            | translate }}</span
                          >
                        </ng-template>
                        <i
                          class="fa-regular fa-pencil-alt fa-fw btn-editable text-muted float-right"
                        ></i>
                      </dd>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4 py-2">
          <div class="card mb-3">
            <div class="card-header">
              {{
              'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.details.zeehavenIJmuidenSpecific'
              | translate }}
            </div>
            <div class="card-body">
              <div class="card-body-group card-body-group-no-border">
                <div class="row no-gutters attribute-group py-0">
                  <div
                    (click)="openZeehavenIJmuidenSpecificPicker()"
                    class="col-12 attribute-group-item attribute-group-item-editable py-0"
                  >
                    <div class="attribute-group w-100 py-0">
                      <div class="attribute-group-item">
                        <dt class="mb-0">
                          {{
                          'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.model.fender'
                          | translate }}
                        </dt>
                        <dd>
                          <span *ngIf="zMovement.fender">
                            <i class="fa-regular fa-check fa-fw"></i>
                          </span>
                          <span *ngIf="!zMovement.fender">
                            <i class="fa-regular fa-minus fa-fw"></i>
                          </span>
                          <span *ngIf="zMovement.fenderMessage" class="ml-2"
                            >{{ zMovement.fender }}</span
                          >
                        </dd>
                      </div>
                      <div class="attribute-group-item">
                        <dt class="mb-0">
                          {{
                          'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.model.oilScreen'
                          | translate }}
                        </dt>
                        <dd>
                          <span *ngIf="zMovement.oilScreen">
                            <i class="fa-regular fa-check fa-fw"></i>
                          </span>
                          <span *ngIf="!zMovement.oilScreen">
                            <i class="fa-regular fa-minus fa-fw"></i>
                          </span>
                          <span *ngIf="zMovement.oilScreenMessage" class="ml-2"
                            >{{ zMovement.oilScreenMessage }}</span
                          >
                        </dd>
                      </div>
                      <div class="attribute-group-item">
                        <dt class="mb-0">
                          {{
                          'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.model.power'
                          | translate }}
                        </dt>
                        <dd>
                          <span *ngIf="zMovement.power">
                            <i class="fa-regular fa-check fa-fw"></i>
                          </span>
                          <span *ngIf="!zMovement.power">
                            <i class="fa-regular fa-minus fa-fw"></i>
                          </span>
                          <span *ngIf="zMovement.powerMessage" class="ml-2"
                            >{{ zMovement.powerMessage }}</span
                          >
                        </dd>
                      </div>
                      <div class="attribute-group-item">
                        <dt class="mb-0">
                          {{
                          'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.model.tug'
                          | translate }}
                        </dt>
                        <dd>
                          <span *ngIf="zMovement.tug">
                            <i class="fa-regular fa-check fa-fw"></i>
                          </span>
                          <span *ngIf="!zMovement.tug">
                            <i class="fa-regular fa-minus fa-fw"></i>
                          </span>
                          <span *ngIf="zMovement.tugMessage" class="ml-2"
                            >{{ zMovement.tugMessage }}</span
                          >
                        </dd>
                      </div>
                      <div class="attribute-group-item">
                        <dt class="mb-0">
                          {{
                          'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.model.garbage'
                          | translate }}
                        </dt>
                        <dd>
                          <span *ngIf="zMovement.garbage">
                            <i class="fa-regular fa-check fa-fw"></i>
                          </span>
                          <span *ngIf="!zMovement.garbage">
                            <i class="fa-regular fa-minus fa-fw"></i>
                          </span>
                          <span *ngIf="zMovement.garbageMessage" class="ml-2"
                            >{{ zMovement.garbageMessage }}</span
                          >
                        </dd>
                      </div>
                      <div class="attribute-group-item">
                        <dt class="mb-0">
                          {{
                          'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.model.water'
                          | translate }}
                        </dt>
                        <dd>
                          <span *ngIf="zMovement.water">
                            <i class="fa-regular fa-check fa-fw"></i>
                          </span>
                          <span *ngIf="!zMovement.water">
                            <i class="fa-regular fa-minus fa-fw"></i>
                          </span>
                          <span *ngIf="zMovement.waterMessage" class="ml-2"
                            >{{ zMovement.waterMessage }}</span
                          >
                        </dd>
                      </div>
                      <div class="attribute-group-item">
                        <dt class="mb-0">
                          {{
                          'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.model.workPlatform'
                          | translate }}
                        </dt>
                        <dd>
                          <span *ngIf="zMovement.workPlatform">
                            <i class="fa-regular fa-check fa-fw"></i>
                          </span>
                          <span *ngIf="!zMovement.workPlatform">
                            <i class="fa-regular fa-minus fa-fw"></i>
                          </span>
                          <span
                            *ngIf="zMovement.workPlatformMessage"
                            class="ml-2"
                            >{{ zMovement.workPlatformMessage }}</span
                          >
                        </dd>
                      </div>
                    </div>
                    <i
                      class="fa-regular fa-pencil-alt fa-fw btn-editable text-muted float-right"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Visit reasons box -->
          <div class="card">
            <div class="card-header">
              {{
              'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.details.visitReasons'
              | translate }}
            </div>
            <div class="card-body">
              <div class="card-body-group card-body-group-no-border">
                <div class="row no-gutters attribute-group py-0">
                  <div
                    (click)="openVisitReasonsPicker()"
                    class="col-12 attribute-group-item attribute-group-item-editable py-0"
                  >
                    <div class="attribute-group w-100 py-0">
                      <ng-container
                        *ngFor="let field of movementDefinition.objectFieldDefinitions"
                      >
                        <div
                          class="attribute-group-item"
                          *ngIf="field.groupName === 'Visit reasons'"
                        >
                          <dt>{{field.name}}</dt>
                          <dd>
                            <span *ngIf="zMovement[field.systemCode]">
                              <i class="fa-regular fa-check fa-fw"></i>
                            </span>
                            <span *ngIf="!zMovement[field.systemCode]">
                              <i class="fa-regular fa-minus fa-fw"></i>
                            </span>
                            <span
                              class="ml-3"
                              *ngIf="field.systemCode === 'containers' &&
                              zMovement[field.systemCode] === true"
                            >
                              {{zMovement['containers_loaded'] ?
                              zMovement['containers_loaded'] : '0'}} geladen,
                              {{zMovement['containers_unloaded'] ?
                              zMovement['containers_unloaded'] : '0'}} gelost
                            </span>
                          </dd>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #loadVisit>
      <sh-loading-indicator
        class="d-block text-center"
      ></sh-loading-indicator>
    </ng-template>
  </section>
</main>
