<ul>
  <li *ngFor="let item of data | keyvalue">
    <span *ngIf="isArray(item.value)">
      {{ item.key }}
      <ul>
        <li *ngFor="let subitem of item.value; let idx = index">
          {{ item.key }} ({{ idx }})
          <ca-data-display [data]="subitem"></ca-data-display>
        </li>
      </ul>
    </span>
    <span *ngIf="isObject(item.value)">
      {{ item.key }}
      <ca-data-display [data]="item.value"></ca-data-display>
    </span>
    <span *ngIf="isProperty(item.value)">
      {{ item.key }}: {{ item.value }}
    </span>
  </li>
</ul>
