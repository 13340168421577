// 3rd party modules
import {
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService, ResultWrapper } from '@seahorse/common';
import {
  NauticalShipModel,
  NauticalVisitDataService,
  NauticalVisitModel,
  NauticalVisitOverviewModel,
} from '@seahorse/domain';
import * as moment from 'moment';
import * as _ from 'underscore';
import { BasePage } from '../../../../layout/pages/base/base.page';
import { LayoutDisplayService } from '../../../../layout/services/layout-display.service';
import { MapDataService } from '@seahorse/domain';

import { FileService } from '@seahorse/domain';
import { KPActivitiesListComponent } from '../../components/activities-list/activities-list.component';
import { KPAddActivityModal } from '../../components/add-activity-modal/add-activity-modal.component';
import { ActivityPageStateModel } from '../../models/activity-page-state.model';
import { KPActivityTypes } from '../../models/kp-activity-types.enum';
import { KPActivityModel } from '../../models/kp-activity.model';
import { KPEventModel } from '../../models/kp-event.model';
import { KPPortVisitModel } from '../../models/kp-port-visit.model';
import { KPShipRequirementsModel } from '../../models/kp-ship-requirements.model';
import { KPStatus } from '../../models/kp-status.model';
import { KPActivityLogicService } from '../../services/kp-activity-logic.service';
import { KPDataService } from '../../services/kp-data.service';

// ShipM8 modules
// Custom UI modules
@Component({
  selector: 'ca-kp-activities-page',
  templateUrl: 'kp-activities.page.html',
})
export class KPActivitiesPage
  extends BasePage<ActivityPageStateModel>
  implements OnInit, OnDestroy
{
  isLoading = false;
  isReloading = false;
  isActivitiesLoading = false;
  isSuggestionsLoading = false;
  activeActivities = [];
  files = {};
  historyActivities = [];
  groups = {};
  portVisitInfos = {}; // Dicctionary with key = portVisitId, value = portvisitInfo object
  showOrderedOnly = true;
  shipRequirements = {}; // Dicctionary with key = shipId, value = shipRequirements object
  // store the repsonse from the request into this property, after completed the loading process,
  // replace the activeActivities list by this property
  private tempActivities = [];

  // use for the object state
  activityTypeGroups: string[] = [];
  filter = {
    list: 'active', // 'active' or 'history'
    timeFuture: 12, // default 12 hours in the future for make suggestions
    timePast: 6, // default 6 hours in the past
    options: [
      { value: 6 },
      { value: 12 },
      { value: 24 },
      { value: 48 },
      { value: 96 },
    ],
  };

  @ViewChildren(KPActivitiesListComponent)
  private activities: QueryList<KPActivitiesListComponent>;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    private fileService: FileService,
    private kpActivityLogic: KPActivityLogicService,
    private kpService: KPDataService,
    private layoutDisplay: LayoutDisplayService,
    private mapData: MapDataService,
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private visitData: NauticalVisitDataService
  ) {
    super(route, router);
    this.refreshInterval = 900000; // refresh each 15 min
    // this.refreshInterval = 30000; // 30 sec
    // this.refreshInterval = 0; // turned off

    this.layoutDisplay.currentModuleName = 'De Koperen Ploeg - Dagstaat';
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  /**
   * Create a new state object
   * @returns Returns a new state object.
   */
  createDefaultState(): ActivityPageStateModel {
    const stateObject = new ActivityPageStateModel();
    return stateObject;
  }

  /**
   * Triggered when the state object is changed.
   */
  onStateChanged(newState: ActivityPageStateModel, changes: unknown[]): void {
    let loadData = false;
    const changedKeys = _.pluck(changes, 'key');
    if (_.contains(changedKeys, 'types')) {
      loadData = this.activityTypeGroups.length === 0;
      this.activityTypeGroups = [];
      if (newState.types) {
        const types = newState.types.split(',');
        _.each(types, (t) => {
          const foundType = _.find(KPActivityTypes, (at) => at.group === t);
          if (foundType) {
            this.activityTypeGroups.push(foundType.group);
          }
        });
      }
    }

    if (_.contains(changedKeys, 'list') || _.contains(changedKeys, 'hours')) {
      this.filter.list = !newState.list ? 'active' : newState.list;
      switch (this.filter.list) {
        case 'history':
          this.filter.timePast = !newState.hours ? 24 : newState.hours;
          break;

        case 'active':
        default:
          this.filter.timeFuture = !newState.hours ? 12 : newState.hours;
          break;
      }
      loadData = true;
    }

    if (loadData) {
      this.loadData();
    }
  }

  addClick() {
    const newActivity = new KPActivityModel();
    this.openActivityModal(newActivity);
  }

  handleEvent(event: KPEventModel) {
    if (!event.data || !event.data['activity']) {
      return;
    }

    const activity: KPActivityModel = event.data['activity'];

    switch (event.objectType) {
      case 'ship':
        if (activity.$nautical_ship_id && activity.$nautical_ship_id > 0) {
          let shipRequirements =
            this.shipRequirements[activity.$nautical_ship_id];
          if (!shipRequirements) {
            shipRequirements = new KPShipRequirementsModel();
            shipRequirements.$nautical_ship_id = activity.$nautical_ship_id;
          }
          shipRequirements.remarks = event.data['remarks'];
          this.notificationService.showInfo(
            this.translateService.instant('customers.kp.remarksSaving')
          );

          this.saveShipRequirements(shipRequirements);
        }
        break;

      case 'portVisit':
        if (
          activity.$nautical_portvisit_id &&
          activity.$nautical_portvisit_id > 0
        ) {
          let portVisitInfo =
            this.portVisitInfos[activity.$nautical_portvisit_id];
          if (!portVisitInfo) {
            portVisitInfo = new KPPortVisitModel();
            portVisitInfo.$nautical_portvisit_id =
              activity.$nautical_portvisit_id;
          }
          portVisitInfo.remarks = event.data['remarks'];
          this.notificationService.showInfo(
            this.translateService.instant('customers.kp.remarksSaving')
          );

          this.savePortVisitInfo(portVisitInfo);
        }
        break;

      case 'activity':
      default:
        switch (event.action) {
          case 'add':
          case 'copy':
            activity.__Id = null; // ensure the id is null
            this.saveActivityModal(
              activity,
              event.data['originalActivityType']
            );
            break;

          case 'complete':
            this.completeActivity(activity);
            break;

          case 'delete':
            if (!activity.__Id) {
              this.ignoreActivity(activity, event.data['remarks']);
            } else {
              this.deleteActivity(activity, event.data['remarks']);
            }
            break;

          case 'edit':
            this.saveActivityModal(
              activity,
              event.data['originalActivityType']
            );
            break;
        }
        break;
    }
  }

  onRefresh() {
    if (this.isLoading === true || this.isReloading === true) {
      return;
    }

    this.loadData();
  }

  /**
   * Triggered when the list is changed
   */
  switchList(): void {
    this.setState('list', this.filter.list);
    this.setState(
      'hours',
      this.filter.list === 'history'
        ? this.filter.timePast
        : this.filter.timeFuture
    );
    this.loadData();
  }

  /**
   * Triggered when the hours is changed
   * @param hours The new hours value
   */
  switchTime(hours): void {
    switch (this.getState('list')) {
      case 'history':
        this.filter.timePast = hours;
        break;

      case 'active':
      default:
        this.filter.timeFuture = hours;
        break;
    }
    this.setState('hours', hours);
    this.loadData();
  }

  /**
   * Triggered when the types changed
   * @param types The new types
   */
  switchTypes(types: string): void {
    this.activityTypeGroups =
      !types || types.length === 0 ? null : types.split(',');
    this.setState('types', !types || types.length === 0 ? null : types);
  }

  /**
   * Triggered when the types changed
   * @param types The new types
   */
  setData(types: string[]): void {
    this.activityTypeGroups = types;
    this.setState('types', types.join(','));
    this.setState('list', 'active');
    this.loadData();
  }

  loadData() {
    // no types selected, no need to load data
    if (!this.activityTypeGroups || this.activityTypeGroups.length === 0) {
      return;
    }

    if (this.getState('list') === 'history') {
      this.loadHistoryData();
    } else {
      this.loadActiveData();
    }
  }

  loadActiveData() {
    if (!this.activeActivities || this.activeActivities.length === 0) {
      this.isLoading = true;
    } else {
      this.isReloading = true;
    }

    this.tempActivities = [];
    this.isActivitiesLoading = true;
    this.kpService
      .getActiveActivities
      // ["$nautical_portvisit_latestevents"]
      ()
      .subscribe(
        (response) => {
          if (response && response.hasResult && response.result.length > 0) {
            this.tempActivities = this.tempActivities.concat(response.result);

            if (response.result.length > 0) {
              const portVisitIds = [];
              const shipIds = [];
              const mmsis = [];
              // 1. first handle properties of the ativities
              _.each(this.tempActivities, (item: KPActivityModel) => {
                // add ship id to load requiments
                if (
                  item.$nautical_ship_id &&
                  item.$nautical_ship_id > 0 &&
                  !this.shipRequirements[item.$nautical_ship_id]
                ) {
                  shipIds.push(item.$nautical_ship_id);
                }

                // add port visit id to load the info
                if (
                  item.$nautical_portvisit_id &&
                  !this.portVisitInfos[item.$nautical_portvisit_id]
                ) {
                  portVisitIds.push(item.$nautical_portvisit_id);
                }

                if (
                  item.$nautical_portvisit &&
                  item.$nautical_portvisit.referencenumber
                ) {
                  item.$nautical_portvisit.referenceNumber =
                    item.$nautical_portvisit.referencenumber;
                }

                // handle ship data
                if (item.$nautical_ship) {
                  if (item.$nautical_ship.mmsi) {
                    mmsis.push(item.$nautical_ship.mmsi);
                  }
                }

                // handle company data
                if (!item.$companies_company || !item.$companies_company.name) {
                  const company = _.find(
                    this.kpActivityLogic.companies,
                    (c) => c.id === item.$companies_company_id
                  );
                  if (company) {
                    item.$companies_company = {
                      id: company.id,
                      name: company.name,
                    };
                  }
                } else {
                  item.$companies_company.id = item.$companies_company_id;
                }

                // handle berth name
                if (item.berth1) {
                  const berth1 = _.find(
                    this.kpActivityLogic.berths,
                    (b) => b.code === item.berth1
                  );
                  if (berth1) {
                    item.berth1Name = berth1.name;
                  }
                }

                if (item.berth2) {
                  const berth2 = _.find(
                    this.kpActivityLogic.berths,
                    (b) => b.code === item.berth2
                  );
                  if (berth2) {
                    item.berth2Name = berth2.name;
                  }
                }

                return item;
              });

              this.loadAttachments(
                _.map(response.result, (mapItem) => mapItem.__Id)
              );
              this.loadPositions(mmsis);
              this.loadShipRequirements(shipIds);
              this.loadPortVisitInfos(portVisitIds);
            }
          }

          this.isActivitiesLoading = false;
          this.completeLoading();
        },
        () => {
          this.isActivitiesLoading = false;
          this.completeLoading();
        }
      );

    this.loadVisits();
  }

  loadAttachments(activityIds: string[]) {
    this.fileService
      .getFilesForObjects(activityIds, '$customcontent_ct-kp-activity_id')
      .subscribe((fileResult) => {
        _.each(activityIds, (eachActivity) => {
          this.files[eachActivity] = _.filter(fileResult.result, (filterFile) =>
            _.any(
              filterFile.objectFiles,
              (anyObject) =>
                anyObject.objectId.toLowerCase() === eachActivity.toLowerCase()
            )
          );
        });
      });
  }

  loadHistoryData() {
    if (!this.historyActivities || this.historyActivities.length === 0) {
      this.isLoading = true;
    } else {
      this.isReloading = true;
    }

    this.kpService
      .getActivitiesByPeriod(
        moment().add(0 - this.filter.timePast, 'hours'),
        moment()
      )
      .subscribe(
        (response) => {
          if (response && response.hasResult) {
            this.historyActivities = response.result;

            if (response.result.length > 0) {
              const portVisitIds = [];
              const shipIds = [];
              const mmsis = [];
              // 1. first handle properties of the ativities
              _.each(this.historyActivities, (item: KPActivityModel) => {
                // add ship id to load requiments
                if (
                  item.$nautical_ship_id &&
                  item.$nautical_ship_id > 0 &&
                  !this.shipRequirements[item.$nautical_ship_id]
                ) {
                  shipIds.push(item.$nautical_ship_id);
                }

                // add port visit id to load the info
                if (
                  item.$nautical_portvisit_id &&
                  !this.portVisitInfos[item.$nautical_portvisit_id]
                ) {
                  portVisitIds.push(item.$nautical_portvisit_id);
                }

                // handle ship data
                if (item.$nautical_ship) {
                  if (item.$nautical_ship.mmsi) {
                    mmsis.push(item.$nautical_ship.mmsi);
                  }
                }

                // handle company data
                if (!item.$companies_company || !item.$companies_company.name) {
                  const company = _.find(
                    this.kpActivityLogic.companies,
                    (c) => c.id === item.$companies_company_id
                  );
                  if (company) {
                    item.$companies_company = {
                      id: company.id,
                      name: company.name,
                    };
                  }
                } else {
                  item.$companies_company.id = item.$companies_company_id;
                }

                // handle berth name
                if (item.berth1) {
                  const berth1 = _.find(
                    this.kpActivityLogic.berths,
                    (b) => b.code === item.berth1
                  );
                  if (berth1) {
                    item.berth1Name = berth1.name;
                  }
                }

                if (item.berth2) {
                  const berth2 = _.find(
                    this.kpActivityLogic.berths,
                    (b) => b.code === item.berth2
                  );
                  if (berth2) {
                    item.berth2Name = berth2.name;
                  }
                }

                return item;
              });

              this.loadAttachments(
                _.map(response.result, (mapItem) => mapItem.__Id)
              );
              this.loadPositions(mmsis);
              this.loadShipRequirements(shipIds);
              this.loadPortVisitInfos(portVisitIds);
            }
          }
          this.isLoading = false;
          this.isReloading = false;
          this.groupAndReorder();
        },
        () => {
          this.isLoading = false;
          this.isReloading = false;
          this.groupAndReorder();
        }
      );
  }

  private loadVisits() {
    this.isSuggestionsLoading = true;
    this.visitData
      .getActiveVisitForPort('nlams', this.filter.timeFuture, true, 0, 500)
      .subscribe(
        (visitResult) => {
          if (
            visitResult.hasResult &&
            visitResult.result &&
            visitResult.result.length > 0
          ) {
            this.loadActivitiesByVisits(visitResult.result);
          } else {
            this.isSuggestionsLoading = false;
            this.completeLoading();
          }
        },
        () => {
          this.isSuggestionsLoading = false;
          this.completeLoading();
        }
      );
  }

  private loadActivitiesByVisits(sources) {
    const portVisitIds = [];
    const shipIds = [];
    const movementIds: number[] = [];
    const visits = [];
    const mmsis = [];

    // filter the visits, only visit with currentMovement will be handled
    _.each(sources, (visit: NauticalVisitOverviewModel) => {
      if (visit.currentMovement) {
        visits.push(visit);
        movementIds.push(visit.currentMovement.id);
      }

      if (!this.portVisitInfos[visit.id]) {
        portVisitIds.push(visit.id);
      }

      if (!this.shipRequirements[visit.shipId]) {
        shipIds.push(visit.shipId);
      }

      if (visit.ship && visit.ship.mmsi) {
        mmsis.push(visit.ship.mmsi);
      }
    });

    this.loadShipRequirements(shipIds);
    this.loadPortVisitInfos(portVisitIds);
    this.loadPositions(mmsis);

    let foundActivities = {};
    if (movementIds.length > 0) {
      this.kpService.getActivitiesByMovementIds(movementIds).subscribe(
        (activitiesResult) => {
          if (activitiesResult.hasResult && activitiesResult.result) {
            // group the activities by portmovement id
            foundActivities = _.groupBy(
              activitiesResult.result,
              '$nautical_portmovement_id'
            );
          }
        },
        () => {
          this.isSuggestionsLoading = false;
          this.convertVisits(visits, foundActivities);
        },
        () => {
          this.isSuggestionsLoading = false;
          this.convertVisits(visits, foundActivities);
        }
      );
    } else {
      this.isSuggestionsLoading = false;
      this.completeLoading();
    }
  }

  //#region Private methods

  private completeLoading() {
    // loading is still busy, skip sorting
    if (
      this.isActivitiesLoading === true ||
      this.isSuggestionsLoading === true
    ) {
      return;
    }

    // ensure the loading flags are false and replace the list
    this.isLoading = false;
    this.isReloading = false;
    this.activeActivities = this.tempActivities;

    this.groupAndReorder();
  }

  private convertVisits(visits: NauticalVisitOverviewModel[], activityGroups) {
    _.each(visits, (visit: NauticalVisitOverviewModel) => {
      const activities = activityGroups[visit.currentMovement.id];
      if (visit.currentMovement.etd && !visit.currentMovement.atd) {
        // shift
        if (
          visit.currentMovement.portWayPointFrom &&
          visit.currentMovement.portWayPointTo &&
          _.find(
            this.kpActivityLogic.berths,
            (b) => b.code === visit.currentMovement.portWayPointFrom.code
          ) !== undefined &&
          _.find(
            this.kpActivityLogic.berths,
            (b) => b.code === visit.currentMovement.portWayPointTo.code
          ) !== undefined
        ) {
          // make suggestion only when no activity with this movement id or the activityType is not 'Verhalen'
          if (
            !activities ||
            _.every(
              activities,
              (a: KPActivityModel) =>
                a.activityType !== KPActivityTypes.Verhalen.code
            )
          ) {
            this.tempActivities.push(
              this.visitToActivity(visit, KPActivityTypes.Verhalen.code)
            );
          }
        }
        // ontmeren
        if (
          visit.currentMovement.portWayPointFrom &&
          _.find(
            this.kpActivityLogic.berths,
            (b) => b.code === visit.currentMovement.portWayPointFrom.code
          ) !== undefined &&
          (visit.currentMovement.movementType === 3 ||
            visit.currentMovement.portWayPointTo === null ||
            _.find(
              this.kpActivityLogic.berths,
              (b) => b.code === visit.currentMovement.portWayPointTo.code
            ) === undefined)
        ) {
          // make suggestion only when no activity with this movement id or the activityType is not 'Ontmeren'
          if (
            !activities ||
            _.every(
              activities,
              (a: KPActivityModel) =>
                a.activityType !== KPActivityTypes.Ontmeren.code
            )
          ) {
            this.tempActivities.push(
              this.visitToActivity(visit, KPActivityTypes.Ontmeren.code)
            );
          }
        }
      } else if (visit.currentMovement.eta) {
        // meren
        if (
          visit.currentMovement.portWayPointTo &&
          _.find(
            this.kpActivityLogic.berths,
            (b) => b.code === visit.currentMovement.portWayPointTo.code
          ) !== undefined
        ) {
          // make suggestion only when no activity with this movement id or the activityType is not 'Meren'
          if (
            !activities ||
            _.every(
              activities,
              (a: KPActivityModel) =>
                a.activityType !== KPActivityTypes.Meren.code
            )
          ) {
            this.tempActivities.push(
              this.visitToActivity(visit, KPActivityTypes.Meren.code)
            );
          }
        }
      }
    });
    this.completeLoading();
  }

  isForKP(visit: NauticalVisitOverviewModel): boolean {
    const result = true;

    if (!visit.currentMovement) {
      return false;
    }

    if (
      !visit.currentMovement.portVisitEvents ||
      visit.currentMovement.portVisitEvents.length === 0
    ) {
      return false;
    }

    const filteredEvents = _.filter(
      visit.currentMovement.portVisitEvents,
      (pve) => pve.activityType !== null && pve.parameters !== null
    );
    const boatmenOrdered = _.find(
      filteredEvents,
      (pve) =>
        pve.activityType.indexOf('BOATMEN') > -1 &&
        pve.parameters.indexOf('KOP') > -1
    );
    const lockPlanned = _.find(
      filteredEvents,
      (pve) =>
        pve.activityType === 'LOCK_PASSAGE' &&
        (pve.parameters === 'ATA' || pve.parameters === 'ETA') &&
        pve.startsOn !== null
    );

    if (!boatmenOrdered || !lockPlanned) {
      return false;
    }

    return result;
  }

  private groupAndReorder() {
    if (this.isLoading === true) {
      return;
    }

    // merge active & history list and filter the duplicates
    const list = _.uniq(
      this.filter.list === 'history'
        ? this.historyActivities.concat(this.activeActivities)
        : this.activeActivities.concat(this.historyActivities),
      false,
      (o: KPActivityModel) => (!o.__Id ? o : o.__Id)
    );

    // group the list by activity type
    const groups = _.groupBy(list, (activity) => {
      const foundType = _.find(
        KPActivityTypes,
        (at) => at.code === activity.activityType
      );
      if (foundType) {
        return foundType.group;
      }

      return 'unknown';
    });

    _.each(groups, (values, key) => {
      if (values) {
        groups[key] = _.sortBy(groups[key], (a: KPActivityModel) => {
          return [
            key === 'ontm'
              ? a.orderTime
              : a.startsOn ?? Number.POSITIVE_INFINITY,
            a.$nautical_ship?.name || a.subject || null,
          ];
        });
      }
    });

    // replace this property after reorder, optimize performance render in the ui.
    this.groups = groups;
    this.finishAction();
  }

  private visitToActivity(
    visit: NauticalVisitOverviewModel,
    activityType: string
  ) {
    const output = {};
    output['activityType'] = activityType;
    output['orderedForKP'] = this.isForKP(visit);

    output['$companies_company'] = { id: null, name: 'UNKNOWN' };
    if (visit.portAgentId) {
      const companyIds = _.map(
        _.filter(
          this.kpActivityLogic.companyFinancialKeys,
          (key) => key.sourceValue === visit.portAgentId.toString()
        ),
        'companyId'
      );
      const company = _.find(
        this.kpActivityLogic.companies,
        (c) => c.financialCount > 0 && companyIds.indexOf(c.id) > -1
      );
      if (company) {
        output['$companies_company_id'] = company.id;
        output['$companies_company'] = {
          id: company.id,
          name: visit.portAgentName,
        };
      }
    }

    output['$nautical_ship_id'] = visit.shipId;
    output['$nautical_ship'] = {
      name: visit.ship.name,
      mmsi: visit.ship.mmsi,
      loa: visit.ship.loa,
    };
    output['$nautical_portvisit'] = visit;
    output['$nautical_portvisit_id'] = visit.id;

    if (visit.currentMovement) {
      output['$nautical_portmovement_id'] = visit.currentMovement.id;

      if (activityType === KPActivityTypes.Meren.code) {
        output['berth1'] = visit.currentMovement.portWayPointTo.code;
        output['startsOn'] = visit.currentMovement.eta;
      } else if (activityType === KPActivityTypes.Ontmeren.code) {
        output['berth1'] = visit.currentMovement.portWayPointFrom.code;
        output['orderTime'] = visit.currentMovement.etd;
      } else if (activityType === KPActivityTypes.Verhalen.code) {
        output['berth1'] = visit.currentMovement.portWayPointFrom.code;
        output['berth2'] = visit.currentMovement.portWayPointTo.code;
        output['orderTime'] = visit.currentMovement.etd;
      }

      // handle berth name
      if (output['berth1']) {
        const berth1 = _.find(
          this.kpActivityLogic.berths,
          (b) => b.code === output['berth1']
        );
        if (berth1) {
          output['berth1Name'] = berth1.name;
        }
      }
    }

    output['status'] = KPStatus.Suggestion;

    return output;
  }

  private afterAddedOrUpdated(
    activityResult: ResultWrapper<KPActivityModel>,
    shipModel: NauticalShipModel,
    portVisitModel: NauticalVisitModel,
    isNew: boolean,
    isFromSuggestion: boolean,
    originalActivityType?: string
  ) {
    if (activityResult && activityResult.hasResult) {
      const savedActivity: KPActivityModel = activityResult.result;
      savedActivity.$nautical_ship = shipModel; // set ship after save
      savedActivity.$nautical_portvisit = !portVisitModel ? {} : portVisitModel;

      this.handleDataAfterSave(savedActivity);

      const msg =
        !isNew || isFromSuggestion
          ? 'Activiteit is bijgewerkt!'
          : 'Nieuw activiteit is toegevoegd!';

      // update the list
      if (savedActivity.status !== 'Active') {
        const index = isNew
          ? -1
          : _.findIndex(
              this.historyActivities,
              (a: KPActivityModel) => a.__Id === savedActivity.__Id
            );
        if (index > -1) {
          this.historyActivities[index] = savedActivity;
        } else {
          this.historyActivities.push(savedActivity);
        }
      } else {
        const index = isNew
          ? -1
          : _.findIndex(
              this.activeActivities,
              (a: KPActivityModel) => a.__Id === savedActivity.__Id
            );
        if (index > -1) {
          this.activeActivities[index] = savedActivity;
        } else {
          this.activeActivities.push(savedActivity);
        }

        if (originalActivityType) {
          const oriActivityType = _.find(
            KPActivityTypes,
            (at) => at.code === originalActivityType
          );
          if (oriActivityType) {
            if (isFromSuggestion === true) {
              if (originalActivityType === savedActivity.activityType) {
                this.removeSuggestion(savedActivity, originalActivityType);
              }
            }
          }
        }
      }

      if (
        savedActivity.$nautical_ship_id &&
        savedActivity.$nautical_ship_id > 0
      ) {
        this.loadShipRequirementsByShipId(savedActivity.$nautical_ship_id);
      }

      if (savedActivity.$nautical_portvisit_id) {
        const needUpdatePortVisitInfo =
          (originalActivityType &&
            originalActivityType === KPActivityTypes.Boord.code) ||
          savedActivity.activityType === KPActivityTypes.Boord.code;
        this.loadPortVisitInfosByPortVisitId(
          savedActivity.$nautical_portvisit_id,
          needUpdatePortVisitInfo
        );
      }

      this.groupAndReorder();
      this.notificationService.showSuccess(msg, 'Successfully');
    } else {
      const errors = _.map(activityResult.messages, (msg) => msg.message).join(
        '\n'
      );
      this.notificationService.showError(
        errors,
        'Fout tijdens opslaan van een activiteit'
      );
      this.finishAction(errors);
    }
  }

  private afterCompletedOrDeleted(
    activityResult: ResultWrapper<KPActivityModel>,
    needRemoveSuggestion: boolean
  ) {
    if (activityResult && activityResult.hasResult) {
      const activity: KPActivityModel = activityResult.result;
      if (activity.status !== KPStatus.Completed) {
        activity.status = KPStatus.Ignored;
      }

      const index = _.findIndex(
        this.historyActivities,
        (a: KPActivityModel) => a.__Id === activity.__Id
      );
      if (index > -1) {
        this.historyActivities[index] = activity;
      } else {
        this.historyActivities.push(activity);
      }

      // remove the suggestion from the group
      if (needRemoveSuggestion) {
        this.removeSuggestion(activity, activity.activityType);
      } else {
        this.activeActivities = _.reject(
          this.activeActivities,
          (a: KPActivityModel) => a.__Id === activity.__Id
        );
      }

      this.groupAndReorder();
      this.notificationService.showSuccess(
        'Activiteit is opgeslagen!',
        'Successfully'
      );
    } else {
      const errors = _.map(activityResult.messages, (msg) => msg.message).join(
        '\n'
      );
      this.notificationService.showError(
        errors,
        'Fout tijdens opslaan van een activiteit'
      );
      this.finishAction(errors);
    }
  }

  private completeActivity(activity) {
    if (!activity) {
      return;
    }

    this.handleDataBeforeSave(activity);

    if (activity.status === KPStatus.Suggestion) {
      activity.status = KPStatus.Completed;
      this.kpService
        .addActivity(activity)
        .subscribe((activityResult: ResultWrapper<KPActivityModel>) => {
          this.afterCompletedOrDeleted(activityResult, true);
        });
    } else {
      activity.status = KPStatus.Completed;
      this.kpService
        .updateActivity(this.kpActivityLogic.removeFields(activity))
        .subscribe((activityResult: ResultWrapper<KPActivityModel>) => {
          this.afterCompletedOrDeleted(activityResult, false);
        });
    }
  }

  private deleteActivity(activity, remarks: string) {
    if (!activity || !remarks) {
      return;
    }

    this.kpService
      .deleteActivity(activity, remarks)
      .subscribe((activityResult: ResultWrapper<KPActivityModel>) => {
        this.afterCompletedOrDeleted(activityResult, false);
      });
  }

  private handleDataAfterSave(activity) {
    if (!activity) {
      return;
    }

    const company = _.find(
      this.kpActivityLogic.companies,
      (c) => c.id === activity.$companies_company_id
    );
    if (company) {
      activity.$companies_company = { name: company.name, id: company.id };
    } // set company name
  }

  private handleDataBeforeSave(activity) {
    if (!activity) {
      return;
    }

    // reset some properties
  }

  private ignoreActivity(activity: KPActivityModel, remarks: string) {
    if (!activity || !remarks) {
      return;
    }

    activity.__Id = null;
    activity.status = KPStatus.Ignored;
    activity.remarks = remarks;
    this.kpService
      .addActivity(activity)
      .subscribe((activityResult: ResultWrapper<KPActivityModel>) => {
        if (activityResult.hasResult && activityResult.result) {
          // add the saved activity to the list
          const savedActivity = activityResult.result;
          const activityType = _.find(
            KPActivityTypes,
            (at) => at.code === savedActivity.activityType
          );
          if (activityType) {
            this.activeActivities.push(savedActivity);
          }

          // mark the activity as deleted
          this.kpService
            .deleteActivity(savedActivity, remarks)
            .subscribe((result: ResultWrapper<KPActivityModel>) => {
              this.afterCompletedOrDeleted(result, true);
            });
        }
      });
  }

  private loadPositions(input: string[]) {
    if (!input || input.length === 0) {
      return;
    }

    const mmsis = _.uniq(_.without(input, null));
    if (mmsis && mmsis.length > 0) {
      this.mapData.getMarkerForMMSIs(mmsis).subscribe((markers) => {
        if (markers && markers.length > 0) {
          const positions = _.indexBy(markers, 'mmsi');
          const list =
            this.isLoading === false && this.isReloading === false
              ? this.activeActivities
              : this.tempActivities;

          _.each(
            list.concat(this.historyActivities),
            (activity: KPActivityModel) => {
              if (activity.$nautical_ship) {
                activity.$nautical_ship.position = positions[
                  activity.$nautical_ship.mmsi
                ]
                  ? positions[activity.$nautical_ship.mmsi]
                  : null;
              }
            }
          );
        }
      });
    }
  }

  private loadPortVisitInfos(ids: number[]) {
    if (!ids || ids.length === 0) {
      return;
    }

    this.kpService.getPortVisitInfosByVisitIds(ids).subscribe((response) => {
      if (response.hasResult && response.result) {
        _.each(response.result, (o: KPPortVisitModel) => {
          this.portVisitInfos[o.$nautical_portvisit_id] = o;
        });
      }
    });
  }

  private loadPortVisitInfosByPortVisitId(
    id: number,
    needUpdateQuantitiesField: boolean
  ) {
    if (!this.portVisitInfos[id]) {
      this.kpService.getPortVisitInfosByVisitIds([id]).subscribe((response) => {
        if (response.hasResult && response.result) {
          this.portVisitInfos[id] = response.result[0];
        }

        if (needUpdateQuantitiesField === true) {
          this.updatePortVisitInfoQuantitiesField(id);
        }
      });
    } else if (needUpdateQuantitiesField === true) {
      this.updatePortVisitInfoQuantitiesField(id);
    }
  }

  private loadShipRequirements(ids: number[]) {
    if (!ids || ids.length === 0) {
      return;
    }

    this.kpService.getShipRequirementsByShipIds(ids).subscribe((response) => {
      if (response.hasResult && response.result) {
        _.each(response.result, (o: KPShipRequirementsModel) => {
          this.shipRequirements[o.$nautical_ship_id] = o;
        });
      }
    });
  }

  private loadShipRequirementsByShipId(id: number) {
    if (!this.shipRequirements[id]) {
      this.kpService
        .getShipRequirementsByShipIds([id])
        .subscribe((response) => {
          if (response.hasResult && response.result) {
            this.shipRequirements[id] = response.result[0];
          }
        });
    }
  }

  private openActivityModal(activity: KPActivityModel) {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
    };

    const modalRef = this.modalService.open(
      KPAddActivityModal,
      ngbModalOptions
    );
    modalRef.componentInstance.activity = activity;
    modalRef.componentInstance.includedFiles.push(this.files);
    modalRef.result
      .then((modalResult) => {
        if (modalResult) {
          // if result is null, means user cancels the action
          const model = new KPEventModel();
          model.objectType = 'activity';
          model.action = 'add';
          model.data = { activity: modalResult };
          this.handleEvent(model);
        }
      })
      .catch();
  }

  private removeSuggestion(activity: KPActivityModel, activityType: string) {
    this.activeActivities = _.reject(
      this.activeActivities,
      (a: KPActivityModel) =>
        a.$nautical_ship_id === activity.$nautical_ship_id &&
        a.$nautical_portvisit_id === activity.$nautical_portvisit_id &&
        a.$nautical_portmovement_id === activity.$nautical_portmovement_id &&
        a.status === KPStatus.Suggestion &&
        a.activityType === activityType
    );
  }

  private saveActivityModal(activity, originalActivityType?: string) {
    if (!activity) {
      return;
    }

    this.handleDataBeforeSave(activity);

    let isNew = false;
    let isFromSuggestion = false;
    if (activity.status === KPStatus.Suggestion) {
      isNew = true;
      isFromSuggestion = true;
      activity.__Id = null;
      activity.status = KPStatus.Active;
    } else {
      isNew =
        !activity.__Id || activity.__Id === 0 || activity.__Id.length === 0;
    }

    const shipModel = _.clone(activity.$nautical_ship);
    if (shipModel && !shipModel.position) {
      this.setShipAndLoadPosition(shipModel);
    }

    let portVisitModel = null;
    if (activity.$nautical_portvisit) {
      portVisitModel = _.clone(activity.$nautical_portvisit);
    }

    if (activity.status === KPStatus.Completed) {
      this.kpService
        .updateActivity(this.kpActivityLogic.removeFields(activity))
        .subscribe((activityResult: ResultWrapper<KPActivityModel>) => {
          this.afterAddedOrUpdated(
            activityResult,
            shipModel,
            portVisitModel,
            isNew,
            isFromSuggestion,
            originalActivityType
          );
        });
    } else {
      if (isNew) {
        this.kpService
          .addActivity(activity)
          .subscribe((activityResult: ResultWrapper<KPActivityModel>) => {
            this.afterAddedOrUpdated(
              activityResult,
              shipModel,
              portVisitModel,
              isNew,
              isFromSuggestion,
              originalActivityType
            );
          });
      } else {
        this.kpService
          .updateActivity(activity)
          .subscribe((activityResult: ResultWrapper<KPActivityModel>) => {
            this.afterAddedOrUpdated(
              activityResult,
              shipModel,
              portVisitModel,
              isNew,
              isFromSuggestion,
              originalActivityType
            );
          });
      }
    }
  }

  private savePortVisitInfo(model: KPPortVisitModel) {
    if (!model) {
      return;
    }

    if (!model.__Id) {
      this.kpService
        .addPortVisitInfo(model)
        .subscribe((response: ResultWrapper<KPPortVisitModel>) => {
          if (response.hasResult && response.result) {
            this.portVisitInfos[response.result.$nautical_portvisit_id] =
              response.result;
          }

          this.finishAction();
        });
    } else {
      this.kpService
        .updatePortVisitInfo(model)
        .subscribe((response: ResultWrapper<KPPortVisitModel>) => {
          if (response.hasResult && response.result) {
            this.portVisitInfos[response.result.$nautical_portvisit_id] =
              response.result;
          }

          this.finishAction();
        });
    }
  }

  private saveShipRequirements(model: KPShipRequirementsModel) {
    if (!model) {
      return;
    }

    if (!model.__Id) {
      this.kpService
        .addShipRequirements(model)
        .subscribe((response: ResultWrapper<KPShipRequirementsModel>) => {
          if (response.hasResult && response.result) {
            this.shipRequirements[response.result.$nautical_ship_id] =
              response.result;
          }

          this.finishAction();
        });
    } else {
      this.kpService
        .updateShipRequirements(model)
        .subscribe((response: ResultWrapper<KPShipRequirementsModel>) => {
          if (response.hasResult && response.result) {
            this.shipRequirements[response.result.$nautical_ship_id] =
              response.result;
          }
          this.finishAction();
        });
    }
  }

  private setShipAndLoadPosition(shipModel) {
    if (!shipModel) {
      return;
    }

    if (shipModel.mmsi) {
      this.mapData.getMarkerForMMSIs([shipModel.mmsi]).subscribe((markers) => {
        if (markers && markers.length > 0) {
          shipModel.position = markers[0];
        }
      });
    }
  }

  private updatePortVisitInfoQuantitiesField(visitId: number) {
    const activities: KPActivityModel[] = this.activeActivities.concat(
      this.historyActivities
    );

    let portVisitInfo = this.portVisitInfos[visitId];
    if (!portVisitInfo) {
      portVisitInfo = new KPPortVisitModel();
      portVisitInfo.$nautical_portvisit_id = visitId;
    }

    const fenderActivities = _.filter(
      activities,
      (activity) =>
        activity.$nautical_portvisit_id === visitId &&
        activity.subType &&
        ['fenders', 'fendersEnSlangen'].indexOf(activity.subType) > -1
    );
    const hoseActivities = _.filter(
      activities,
      (activity) =>
        activity.$nautical_portvisit_id === visitId &&
        activity.subType &&
        ['slangen', 'fendersEnSlangen'].indexOf(activity.subType) > -1
    );
    const reducerActivities = _.filter(
      activities,
      (activity) =>
        activity.$nautical_portvisit_id === visitId &&
        activity.subType &&
        ['reducers', 'reducersvopak'].indexOf(activity.subType) > -1
    );

    portVisitInfo.fendersOnBoard = !fenderActivities
      ? 0
      : _.reduce(
          fenderActivities,
          (memorizer, activity) => {
            return memorizer + (!activity.quantity ? 0 : activity.quantity);
          },
          0
        );
    portVisitInfo.hosesOnBoard = !hoseActivities
      ? 0
      : _.reduce(
          hoseActivities,
          (memorizer, activity) => {
            return memorizer + (!activity.quantity ? 0 : activity.quantity);
          },
          0
        );
    portVisitInfo.reducersOnBoard = !reducerActivities
      ? 0
      : _.reduce(
          reducerActivities,
          (memorizer, activity) => {
            return memorizer + (!activity.quantity ? 0 : activity.quantity);
          },
          0
        );

    this.savePortVisitInfo(portVisitInfo);
  }

  //#endregion

  finishAction(errors = null) {
    this.activities.forEach((activity) => {
      if (errors && activity.container.currentActivity) {
        activity.setErrors(activity.container.currentActivity.__Id, errors);
      } else if (activity.container.currentActivity) {
        activity.removeErrors(activity.container.currentActivity.__Id);
      }

      activity.container.currentActivity = null;
    });
  }
}
