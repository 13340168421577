import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ResultWrapper } from '@seahorse/common';
import {
  CompanyAddressModel,
  CompanyContactModel, CompanyContactStatuses
} from '@seahorse/domain';
import { ContactDataService } from '../../../../company/services/contact-data.service';
import { EventModel } from '../../../../shared/models/event.model';

import { AddressModalComponent } from '../../address/address-modal/address-modal.component';

@Component({
  selector: 'contact-modal',
  templateUrl: './contact-modal.component.html',
  styleUrls: ['./contact-model.component.scss'],
})
export class ContactModalComponent implements OnInit {
  @Input() contact: CompanyContactModel;
  @Input() companyId: number;

  @Output() onSelectContact: EventEmitter<CompanyContactModel>;

  isSubmitted: boolean;
  isNew: boolean;
  contactId: number;

  activeTab = ContactTab.ContactInformation;
  ContactTabs = ContactTab;

  constructor(
    private contactDataService: ContactDataService,
    private modalService: NgbModal,
    private cdr: ChangeDetectorRef
  ) {
    this.onSelectContact = new EventEmitter<CompanyContactModel>();
    this.isSubmitted = false;
    this.isNew = false;
    this.contact = new CompanyContactModel();
    this.contact.companyAddresses = [] as CompanyAddressModel[];
  }

  ngOnInit() {
    this.isNew = this.contact.id == null;

    if (!this.isNew) {
      this.getContactById();
    } else {
      this.contact.status = CompanyContactStatuses.Active;
    }
  }

  setActiveTab(activeTab: ContactTab) {
    this.activeTab = activeTab;
  }

  getContactById() {
    this.contactDataService
      .getById(this.contact.id)
      .subscribe(
        (contactGetByIdResponse: ResultWrapper<CompanyContactModel>) => {
          this.contact = contactGetByIdResponse.result;

          this.contact.departmentContacts =
            contactGetByIdResponse.result.departmentContacts.map((x) => {
              return {
                id: x.departmentId,
                name: x.departmentName,
              };
            });

          if (!this.contact.companyAddresses) {
            this.contact.companyAddresses = [];
          }

          this.cdr.detectChanges();
        }
      );
  }

  onAction(event: EventModel) {
    switch (event.action) {
      case 'editAddress':
        this.selectAddress(event.data);
        break;
      case 'deleteAddress':
        this.deleteAddress(event.data);
        break;
      default:
        break;
    }
  }

  selectAddress(address: CompanyAddressModel) {
    const ngbModalOptions: NgbModalOptions = { backdrop: 'static' };
    const modalRef = this.modalService.open(
      AddressModalComponent,
      ngbModalOptions
    );
    modalRef.componentInstance.showContactField = false;

    if (address != null) {
      modalRef.componentInstance.address = address;
    }

    modalRef.componentInstance.onAddCompanyAddress.subscribe((addedAddress) => {
      this.contact.companyAddresses.push(addedAddress);
    });

    modalRef.componentInstance.onUpdateCompanyAddress.subscribe(
      (updatedAddress) => {
        this.contact.companyAddresses.splice(
          this.contact.companyAddresses.findIndex(
            (x) =>
              x.id === updatedAddress.id || x.value === updatedAddress.value
          ),
          updatedAddress
        );
      }
    );
  }

  deleteAddress(address: CompanyAddressModel) {
    let index = this.contact.companyAddresses.indexOf(
      this.contact.companyAddresses.find((x) => x.id === address.id)
    );
    if (index > -1) {
      this.contact.companyAddresses.splice(index, 1);
    } else {
      index = this.contact.companyAddresses.indexOf(
        this.contact.companyAddresses.find((x) => x.value === address.value)
      );
      this.contact.companyAddresses.splice(index, 1);
    }
  }

  save() {
    if (!CompanyContactModel.validate(this.contact)) {
      this.isSubmitted = true;
      return;
    }

    this.onSelectContact.emit(this.contact);
  }
}

export enum ContactTab {
  ContactInformation,
  Addresses,
}
