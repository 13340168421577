import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'underscore';

import { ResultWrapper, KeyValuePair } from '@seahorse/common';
import { NotificationService } from '@seahorse/common';
import { EventModel } from '../../../../../shared/models/event.model';
import { QueryModel } from '../../../../../shared/models/query.model';
import { LayoutDisplayService } from '../../../../../layout/services/layout-display.service';
import { StatisticModel } from '../../models/statistic.model';
import { ExecutionModel } from '../../models/execution.model';
import { FlowDataService } from '../../services/flow-data.service';
import { ExecutionDataService } from '../../services/execution-data.service';
import { FlowModel } from '@seahorse/domain';

@Component({ templateUrl: 'flow-details.page.html' })
export class FlowDetailsPage implements OnInit {
  flow: FlowModel;
  loadingFlow: boolean;
  statistics: StatisticModel[];
  loadingStatistics: boolean;
  executionsQuery: QueryModel;
  executionsCount: number;
  executions: ExecutionModel[];
  loadingExecutions: boolean;
  indexedExecutions: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private notification: NotificationService,
    private layoutDisplay: LayoutDisplayService,
    private flowData: FlowDataService,
    private executionData: ExecutionDataService,
    private modal: NgbModal
  ) {
    this.flow = null;
    this.loadingFlow = false;
    this.statistics = [];
    this.loadingStatistics = false;
    this.executionsQuery = new QueryModel();
    this.executionsCount = 0;
    this.executions = [];
    this.loadingExecutions = false;
    this.indexedExecutions = false;
  }

  ngOnInit() {
    this.translate
      .get('invoicingFlows.flowDetails.title')
      .subscribe(
        (translateGet) => (this.layoutDisplay.currentModuleName = translateGet)
      );

    this.route.paramMap.subscribe((routeParamMap) =>
      this.loadFlow(parseInt(routeParamMap.get('id')))
    );
  }

  loadFlow(flowId: FlowModel['id']) {
    this.loadingFlow = true;

    this.flowData.getById(flowId).subscribe(
      (flowGetById: ResultWrapper<FlowModel>) => {
        if (flowGetById.hasResult) {
          this.flow = flowGetById.result;
        } else {
          this.notification.showError(
            _.pluck(flowGetById.messages, 'message').join('\n'),
            this.translate.instant('shared.terms.failed')
          );
        }

        this.loadingFlow = false;
      },
      (flowGetByIdError) => {
        this.notification.showError(
          _.pluck(flowGetByIdError.error.messages, 'message').join('\n'),
          this.translate.instant('shared.terms.failed')
        );

        this.loadingFlow = false;
      }
    );

    this.loadingStatistics = true;

    this.executionData.getStatisticsByFlowId(flowId).subscribe(
      (
        executionGetStatisticsByFlowId: ResultWrapper<
          KeyValuePair<string, number>[]
        >
      ) => {
        if (executionGetStatisticsByFlowId.hasResult) {
          this.statistics = _.map(
            executionGetStatisticsByFlowId.result,
            (keyValuePair: KeyValuePair<string, number>) => {
              return {
                fieldName: keyValuePair.key,
                fieldValue: keyValuePair.value,
              };
            }
          );

          this.searchExecutions(flowId.toString());
        } else {
          this.notification.showError(
            _.pluck(executionGetStatisticsByFlowId.messages, 'message').join(
              '\n'
            ),
            this.translate.instant('shared.terms.failed')
          );
        }

        this.loadingStatistics = false;
      },
      (executionGetStatisticsByFlowIdError) => {
        this.notification.showError(
          _.pluck(
            executionGetStatisticsByFlowIdError.error.messages,
            'message'
          ).join('\n'),
          this.translate.instant('shared.terms.failed')
        );

        this.loadingStatistics = false;
      }
    );
  }

  searchExecutions(search?: QueryModel['search']) {
    if (search) {
      this.executionsQuery.search = search;
    } else {
      this.executionsQuery.search = this.flow.id.toString();
    }

    this.executionsQuery.pageSize = 10;
    this.loadingExecutions = true;

    this.executionData.search(this.executionsQuery).subscribe(
      (executionSearch: ResultWrapper<ExecutionModel[]>) => {
        if (executionSearch.hasResult) {
          this.executionsCount = executionSearch.count;

          if (this.executionsQuery.pageIndex > 0) {
            this.executions = this.executions.concat(executionSearch.result);
          } else {
            this.executions = executionSearch.result;
          }

          this.indexedExecutions =
            this.executions.length < this.executionsCount;
        } else {
          this.notification.showError(
            _.pluck(executionSearch.messages, 'message').join('\n'),
            this.translate.instant('shared.terms.failed')
          );
        }

        this.loadingExecutions = false;
      },
      (executionSearchError) => {
        this.notification.showError(
          _.pluck(executionSearchError.error.messages, 'message').join('\n'),
          this.translate.instant('shared.terms.failed')
        );

        this.loadingExecutions = false;
      }
    );
  }

  action(event: EventModel) {
    switch (event.action) {
      case 'saveFlow': {
        this.loadingFlow = true;

        this.flowData.update(event.data).subscribe(
          (flowUpdate: ResultWrapper<FlowModel>) => {
            if (flowUpdate.hasResult) {
              this.notification.showSuccess(
                this.translate.instant(
                  'invoicingFlows.flowDetails.flowUpdated'
                ),
                this.translate.instant('shared.terms.success')
              );
            } else {
              this.notification.showError(
                _.pluck(flowUpdate.messages, 'message').join('\n'),
                this.translate.instant('shared.terms.failed')
              );
            }

            this.loadingFlow = false;
          },
          (flowUpdateError) => {
            this.notification.showError(
              _.pluck(flowUpdateError.error.messages, 'message').join('\n'),
              this.translate.instant('shared.terms.failed')
            );

            this.loadingFlow = false;
          }
        );

        this.modal.dismissAll();

        break;
      }

      case 'addStage': {
        this.router.navigate(
          [`invoicing-and-tariffs/invoicing-flows/stage/create`],
          { queryParams: { flow: this.flow.id } }
        );

        break;
      }

      case 'viewStage': {
        this.router.navigate([
          `invoicing-and-tariffs/invoicing-flows/stage/${event.data}`,
        ]);

        break;
      }

      case 'approveExecution': {
        this.loadingExecutions = true;

        this.executionData.approve(event.data).subscribe(
          (executionApprove: ResultWrapper<ExecutionModel>) => {
            if (executionApprove.hasResult) {
              this.searchExecutions();

              this.notification.showSuccess(
                this.translate.instant(
                  'invoicingFlows.flowDetails.executionApproved'
                ),
                this.translate.instant('shared.terms.success')
              );
            } else {
              this.notification.showError(
                _.pluck(executionApprove.messages, 'message').join('\n'),
                this.translate.instant('shared.terms.failed')
              );
            }

            this.loadingExecutions = false;
          },
          (executionApproveError) => {
            this.notification.showError(
              _.pluck(executionApproveError.error.messages, 'message').join(
                '\n'
              ),
              this.translate.instant('shared.terms.failed')
            );

            this.loadingExecutions = false;
          }
        );

        break;
      }

      case 'moreExecutions': {
        this.executionsQuery.pageIndex += 1;

        this.searchExecutions();

        break;
      }
    }
  }
}
