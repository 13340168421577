<div class="checkbox">
  <label class="cursor-pointer d-flex">
    <input
      type="checkbox"
      name="no-group"
      [(ngModel)]="isCheckedNoGroup"
      (change)="changeMyFleet()"
    />
    <i class="custom-checkbox"></i>
    (no group)
  </label>
</div>

<div class="checkbox" *ngFor="let group of groups">
  <label class="cursor-pointer d-flex">
    <input
      type="checkbox"
      name="group{{ group.id }}"
      [(ngModel)]="group.isChecked"
      (change)="changeMyFleetGroup(group)"
    />
    <i class="custom-checkbox"></i>
    {{ group.name }}
  </label>
</div>
