import { Injectable } from '@angular/core';
import * as _ from 'underscore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ErrorMessage, ResultWrapper } from '@seahorse/common';
import {
  CustomDataContentService,
  CustomDataSearchFieldModel,
  OrderRegistrationModel,
  SearchOperators,
} from '@seahorse/domain';
import { OrderRegistrationStatusLogModel } from '../models/order-registration-status-log.model';

@Injectable({
  providedIn: 'root',
})
export class OrderRegistrationStatusLogDataService {
  constructor(private customData: CustomDataContentService) {}

  getByOrderRegistrationId(
    id: OrderRegistrationModel['__Id']
  ): Observable<ResultWrapper<OrderRegistrationStatusLogModel[]>> {
    const field = new CustomDataSearchFieldModel();
    {
      (field.fieldName = 'orderRegistrationId'),
        (field.searchOperator = SearchOperators.Equals),
        (field.searchValue = id);
    }

    return this.customData.searchActiveCustomerDataByFields(
      'orderRegistrationStatusLog',
      [field],
      null,
      null,
      0,
      500
    );
  }

  getCurrentByOrderRegistrationId(
    id: OrderRegistrationModel['__Id']
  ): Observable<ResultWrapper<OrderRegistrationStatusLogModel>> {
    const field = new CustomDataSearchFieldModel();
    {
      (field.fieldName = 'orderRegistrationId'),
        (field.searchOperator = SearchOperators.Equals),
        (field.searchValue = id);
    }

    return this.customData
      .searchActiveCustomerDataByFields(
        'orderRegistrationStatusLog',
        [field],
        null,
        null,
        0,
        500
      )
      .pipe(
        map((r: ResultWrapper<OrderRegistrationStatusLogModel[]>) => {
          const w: ResultWrapper<OrderRegistrationStatusLogModel> = _.extend(
            _.omit(r, ['result', 'hasResult']),
            { result: null, hasResult: false }
          );

          if (r.hasResult && r.result && r.result.length == 1) {
            w.hasResult = true;
            w.result = _.last(_.sortBy(r.result, '__CreatedOn'));
          } else {
            w.hasResult = false;

            if (r.result) {
              const errorMessage: ErrorMessage = new ErrorMessage();

              if (r.result.length) {
                errorMessage.code = 'multipleResults';
                errorMessage.message = 'Multiple results have been found';
              } else {
                errorMessage.code = 'noResults';
                errorMessage.message = 'No results have been found';
              }

              w.messages = w.messages.concat(errorMessage);
            }
          }

          return w;
        })
      );
  }

  add(
    orderRegistrationStatusLog: OrderRegistrationStatusLogModel
  ): Observable<ResultWrapper<OrderRegistrationStatusLogModel>> {
    return this.customData.addCustomerData(
      'orderRegistrationStatusLog',
      orderRegistrationStatusLog
    );
  }
}
