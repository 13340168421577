<ng-container *ngIf="formModel">
  <temp-form [model]="formModel"></temp-form>
</ng-container>
<ng-container class="mt-2" *ngFor="let formModel of formModels | keyvalue">
  <h6>
    {{
      'contentDistribution.exportTemplate.configuration.requiredData'
        | translate
    }}
    - {{ formModel.value.name }}
  </h6>
  <hr />
  <temp-form [model]="formModel.value.formModel"></temp-form>
</ng-container>
