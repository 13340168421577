import { Component, Input, Output, EventEmitter } from '@angular/core';

import { EventModel } from '../../../../../shared/models/event.model';

@Component({
  selector: 'flow-creator-header',
  templateUrl: 'flow-creator-header.component.html',
})
export class FlowCreatorHeaderComponent {
  @Input() loadingFlow: boolean;

  @Output() onEvent: EventEmitter<EventModel>;

  constructor() {
    this.loadingFlow = false;
    this.onEvent = new EventEmitter<EventModel>();
  }
}
