/* eslint-disable @typescript-eslint/no-explicit-any */
import { FactoryProvider, Type } from '@angular/core';

type Environment = {
  [key: string]: any;
};

const envServiceFactory = (env: Environment) => () => {
  // Read environment variables from browser window
  const browserWindow: { [key: string]: any } = window || {};
  const browserWindowEnv: Environment = browserWindow['__env'] || {};

  // Assign environment variables from browser window to env
  // In the current implementation, properties from env.js overwrite defaults from the EnvService.
  // If needed, a deep merge can be performed here to merge properties instead of overwriting them.
  for (const key in browserWindowEnv) {
    // eslint-disable-next-line no-prototype-builtins
    if (browserWindowEnv.hasOwnProperty(key)) {
      env[key] = browserWindowEnv[key];
    }
  }

  return env;
};

export const envServiceProvider: <T extends Type<Environment>>(
  envService: T
) => FactoryProvider = <T extends Type<Environment>>(envService: T) => ({
  provide: envService,
  useFactory: envServiceFactory(new envService()),
});
