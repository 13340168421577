<div class="modal-header">
  <div class="modal-title">Add ship tag</div>
  <button type="button" class="close" aria-label="Close" closeModal>
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form #addShipTagForm="ngForm">
    <div class="row">
      <div class="col-8">
        <div class="form-group">
          <input
            name="name"
            type="text"
            class="form-control"
            placeholder="Type to search"
            [class.is-invalid]="searchFailed"
            [(ngModel)]="model"
            [ngbTypeahead]="searchShipTags"
            [inputFormatter]="resultShipTagsFormatter"
            [resultFormatter]="resultShipTagsFormatter"
          />
          <sh-loading-indicator
            class="text-center d-block"
            *ngIf="searching"
          ></sh-loading-indicator>
        </div>
      </div>
      <div class="col-4">
        <button *ngIf="!editMode" class="btn btn-primary" (click)="addTag()">
          <i class="fa-regular fa-plus"></i>
          {{ 'shared.terms.add' | translate }}
        </button>
        <button
          *ngIf="editMode"
          class="btn btn-outline-secondary"
          (click)="editTag()"
        >
          <i class="fa-regular fa-pencil-alt"></i>
        </button>
      </div>
    </div>
  </form>

  <div *ngIf="selectedTags.length > 0">
    <i>Selected tags:</i>
    <div class="tag-list">
      <span class="tag" *ngFor="let tag of selectedTags"
        >{{ tag.name }}
        &nbsp; &nbsp;
        <span (click)="removeTag(tag)" *ngIf="!editMode" class="tag-close"
          ><i class="fa-solid fa-times fa-fw"></i></span
      ></span>
    </div>

    <div class="d-flex" *ngIf="!editMode">
      <div class="flex-grow-1">
        <button type="button" class="btn btn-block btn-link" closeModal>
          {{ 'shared.terms.cancel' | translate }}
        </button>
      </div>
      <div class="flex-grow-1">
        <button
          type="button"
          class="btn btn-block btn-primary"
          (click)="save()"
        >
          {{ 'shared.terms.save' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
