import {
  Component,
  ComponentFactoryResolver,
  ViewChild,
  ViewContainerRef,
  Input,
  Type,
  ComponentRef,
  OnInit,
} from '@angular/core';

import { InteractionService } from '../../../shared/services/interaction.service';
import { BaseModel } from '@seahorse/common';
import { ResolverService } from '../../../layout/services/resolver.service';

@Component({
  selector: 'modal',
  templateUrl: './modal.component.html',
})
export class ModalComponent implements OnInit {
  isSubmitted = false;
  errors: string[] = [];
  model: BaseModel;

  @ViewChild('form', { read: ViewContainerRef, static: true })
  form: ViewContainerRef;

  @Input() object: any;
  @Input() interactionService: InteractionService;
  @Input() dataService: any;

  component: ComponentRef<any>;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private resolverService: ResolverService
  ) {}

  ngOnInit() {
    this.resolverService.resolveModel(this.object.name).subscribe(
      (result) => {
        const instance = Object.create(this.object.prototype);
        instance.constructor.apply(instance);
        this.model = instance;

        Object.assign(this.model, result);
      },
      (error) => {},
      () => {
        const factories = Array.from(
          this.componentFactoryResolver['_factories'].keys()
        );
        const factoryClass = <Type<any>>(
          factories.find(
            (x: any) =>
              x.name === this.object.name.replace('Model', '') + 'FormComponent'
          )
        );
        const componentFactory =
          this.componentFactoryResolver.resolveComponentFactory(factoryClass);
        this.component = this.form.createComponent(componentFactory);
        this.component.instance.model = this.model;
      }
    );
  }

  save() {
    const validationResult = this.model.validate();

    if (!validationResult.isValid) {
      this.errors = validationResult.errors;
      return;
    }

    this.interactionService.modify(this.model);
  }
}
