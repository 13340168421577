import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { MomentDateFormatter } from '../../shared/extenders/dateparser.extender';
import { ZeehavenIJmuidenOverviewPage } from './pages/zeehaven-ijmuiden-overview/zeehaven-ijmuiden-overview.page';
import { ZeehavenIJmuidenDetailsPage } from './pages/zeehaven-ijmuiden-details/zeehaven-ijmuiden-details.page';
import { ZeehavenIJmuidenPagesModule } from './pages/zeehaven-ijmuiden-pages.module';
import { ZeehavenIJmuidenComponentsModule } from './components/zeehaven-ijmuiden-components.module';
import { ZeehavenIJmuidenServicesModule } from './services/zeehaven-ijmuiden-services.module';

const routes: Routes = [
  { path: 'zeehaven-overview', component: ZeehavenIJmuidenOverviewPage },
  { path: 'zeehaven/:id', component: ZeehavenIJmuidenDetailsPage },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),

    // Child modules
    ZeehavenIJmuidenPagesModule,
    ZeehavenIJmuidenComponentsModule,
    ZeehavenIJmuidenServicesModule,
  ],
  providers: [
    { provide: NgbDateParserFormatter, useClass: MomentDateFormatter },
  ],
})
export class ZeehavenIJmuidenModule {}
