import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationService } from '@seahorse/common';
import { UserModel, UsersDataService } from '@seahorse/domain';
import { Observable, of, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
} from 'rxjs/operators';
import { BaseControl } from '../base-control';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'temp-user-list',
  templateUrl: './user-list.control.html',
  styleUrls: ['./user-list.control.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserListControl),
      multi: true,
    },
    {
      provide: BaseControl,
      useExisting: forwardRef(() => UserListControl),
    },
  ],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    NgbModule
  ]
})
export class UserListControl
  extends BaseControl<string[] | null>
  implements OnInit, OnDestroy {
  @ViewChild('input', { static: true }) inputRef: ElementRef<HTMLInputElement>;

  @Input() required = false;
  @Input() value: string[] | null = null;

  @Input() placeholder: string;
  @Input() name: string;

  selectedItems: UserModel[] = [];
  isLoading = false;
  hasLoadingFailed = false;
  private _users: UserModel[] = [];
  private _subscriptions = new Subscription();

  constructor(
    private _userData: UsersDataService,
    private _notificationService: NotificationService
  ) {
    super();
  }

  ngOnInit() {
    this.loadUsers();
  }

  private loadUsers() {
    this.isLoading = true;

    this._subscriptions.add(
      this._userData.getByOrganisation(false).subscribe((usersResponse) => {
        if (usersResponse.hasResult) {
          this._users = usersResponse.result;
        } else {
          this._notificationService.showError('user.loadUsersFailed');
        }

        this.isLoading = false;

        if (this.value != null) {
          this.getObjects(this.value);
        }
      })
    );
  }

  private getObjects(ids: string[]) {
    this.selectedItems = this._users.filter((x) => ids.includes(x.id));
  }

  dataSearch = (input$: Observable<string>): Observable<any> =>
    input$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      map((query) => query.toLocaleLowerCase()),
      switchMap((query) => {
        const users = this._users.filter(
          (user) =>
            user.givenName.toLocaleLowerCase().startsWith(query) ||
            user.surname.toLocaleLowerCase().startsWith(query)
        );

        return of(users);
      })
    )

  selectItem(event: NgbTypeaheadSelectItemEvent) {
    event.preventDefault();
    const selectedItem = event.item as UserModel;

    const exists =
      this.selectedItems.map((item) => item.id).indexOf(selectedItem.id) !== -1;
    if (exists) {
      return;
    }

    this.selectedItems.push(selectedItem);
    this.inputRef.nativeElement.value = '';

    const values = this.selectedItems.map((item) => item.id);

    this.emitOnChange(values);
  }

  removeItem(item: UserModel) {
    this.selectedItems.splice(this.selectedItems.indexOf(item), 1);
    const values = this.selectedItems.map((selectedItem) => selectedItem.id);

    this.emitOnChange(values.length ? values : null);
  }

  dataFormatter = (user: UserModel | UserModel[]): string =>
    user instanceof Array ? '' : `${user.givenName} ${user.surname}`

  ngOnDestroy() {
    if (this._subscriptions) {
      this._subscriptions.unsubscribe();
    }
  }
}
