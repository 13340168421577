import { Injectable, OnDestroy } from '@angular/core';
import { TaskGroupModel } from '../models/task-group.model';
import {
  TaskModel,
  TaskStatus,
} from '../../tasks/modules/task/models/task.model';
import { TaskGroupDataService } from './task-group-data.service';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { TaskGroupServiceModel } from '../models/task-group-service.model';
import { map, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class TaskGroupModelService implements OnDestroy {
  private subscriptions$ = new Subscription();

  constructor(private taskGroupDataService: TaskGroupDataService) {}

  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }

  createFromTask(
    task: TaskModel,
    taskGroupName: string
  ): TaskGroupServiceModel {
    const serviceModel = new TaskGroupServiceModel(null, task);
    serviceModel.outputTaskGroup = new TaskGroupModel();
    serviceModel.outputTaskGroup.tasks.push(task);
    serviceModel.outputTaskGroup.name = taskGroupName;
    serviceModel.outputTaskGroup.objectType = task.objectType
      ? task.objectType
      : null;
    serviceModel.outputTaskGroup.objectId = task.objectId
      ? task.objectId
      : null;
    serviceModel.outputTaskGroup.status = task.status;
    serviceModel.outputTaskGroup.dueDate = null;
    serviceModel.outputTaskGroup.completedOn = task.completedOn
      ? task.completedOn
      : null;
    return serviceModel;
  }

  addTask(
    task: TaskModel,
    taskGroupId: number,
    taskGroupIncludingTasks: TaskGroupModel | null
  ): Observable<TaskGroupServiceModel> {
    return of(taskGroupIncludingTasks).pipe(
      switchMap((taskGroup) => {
        if (taskGroup) {
          const serviceModel = new TaskGroupServiceModel(taskGroup, task);
          return of(this.addTaskFromModel(serviceModel));
        } else {
          return this.findModelIncludingTasks(taskGroupId).pipe(
            map((response) => new TaskGroupServiceModel(response, task)),
            map((serviceModel) => this.addTaskFromModel(serviceModel))
          );
        }
      })
    );
  }

  updateTask(
    task: TaskModel,
    taskGroupId: number,
    taskGroupIncludingTasks: TaskGroupModel | null
  ): Observable<TaskGroupServiceModel> {
    return of(taskGroupIncludingTasks).pipe(
      switchMap((taskGroup) => {
        if (taskGroup) {
          const serviceModel = new TaskGroupServiceModel(taskGroup, task);
          return of(this.updateTaskFromModel(serviceModel));
        } else {
          return this.findModelIncludingTasks(taskGroupId).pipe(
            map((response) => new TaskGroupServiceModel(response, task)),
            map((serviceModel) => this.updateTaskFromModel(serviceModel))
          );
        }
      })
    );
  }

  removeTask(
    task: TaskModel,
    taskGroupId: number,
    taskGroupIncludingTasks: TaskGroupModel | null
  ): Observable<TaskGroupServiceModel> {
    return of(taskGroupIncludingTasks).pipe(
      switchMap((taskGroup) => {
        if (taskGroup) {
          const serviceModel = new TaskGroupServiceModel(taskGroup, task);
          return of(this.removeTaskFromModel(serviceModel));
        } else {
          return this.findModelIncludingTasks(taskGroupId).pipe(
            map((response) => new TaskGroupServiceModel(response, task)),
            map((serviceModel) => this.removeTaskFromModel(serviceModel))
          );
        }
      })
    );
  }

  private findModelIncludingTasks(
    taskGroupId: number
  ): Observable<TaskGroupModel | null> {
    const taskGroupSubject = new Subject<TaskGroupModel>();
    this.subscriptions$.add(taskGroupSubject);
    if (taskGroupId > 0) {
      this.subscriptions$.add(
        this.taskGroupDataService.getById(taskGroupId).subscribe({
          next: (response) => {
            if (response.hasResult) taskGroupSubject.next(response.result);
            else taskGroupSubject.next(null);
          },
          error: (e) => taskGroupSubject.error(e),
          complete: () => taskGroupSubject.complete(),
        })
      );
    } else {
      taskGroupSubject.next(null);
      taskGroupSubject.complete();
    }
    return taskGroupSubject.asObservable();
  }

  private addTaskFromModel(
    serviceModel: TaskGroupServiceModel
  ): TaskGroupServiceModel {
    if (serviceModel.outputTask) {
      serviceModel.outputTask.objectType =
        serviceModel.inputTaskGroup.objectType;
      serviceModel.outputTask.objectId = serviceModel.inputTaskGroup.objectId;
      serviceModel.outputTask.taskGroupId = serviceModel.inputTaskGroup.id;
      if (serviceModel.outputTaskGroup) {
        serviceModel.outputTaskGroup.tasks.push(serviceModel.outputTask);
        serviceModel.outputTaskGroup.status = this.findStatus(
          serviceModel.outputTaskGroup.tasks
        );
        serviceModel.outputTaskGroup.completedOn = this.findCompletedOn(
          serviceModel.outputTaskGroup.tasks
        );
      }
    }
    return serviceModel;
  }

  private updateTaskFromModel(
    serviceModel: TaskGroupServiceModel
  ): TaskGroupServiceModel {
    if (serviceModel.outputTaskGroup) {
      const index = serviceModel.outputTaskGroup.tasks.findIndex(
        (x) => x.id === serviceModel.outputTask.id
      );
      if (index === -1) return serviceModel;
      serviceModel.outputTaskGroup.tasks[index] = serviceModel.outputTask;
      serviceModel.outputTaskGroup.status = this.findStatus(
        serviceModel.outputTaskGroup.tasks
      );
      serviceModel.outputTaskGroup.completedOn = this.findCompletedOn(
        serviceModel.outputTaskGroup.tasks
      );
    }
    return serviceModel;
  }

  private removeTaskFromModel(
    serviceModel: TaskGroupServiceModel
  ): TaskGroupServiceModel {
    if (serviceModel.outputTask) {
      serviceModel.outputTask.taskGroupId = null;
      const index = serviceModel.outputTaskGroup.tasks.findIndex(
        (x) => x.id === serviceModel.outputTask.id
      );
      if (index === -1) return serviceModel;
      if (serviceModel.outputTaskGroup) {
        serviceModel.outputTaskGroup.tasks.splice(index, 1);
        serviceModel.outputTaskGroup.status = this.findStatus(
          serviceModel.outputTaskGroup.tasks
        );
        serviceModel.outputTaskGroup.completedOn = this.findCompletedOn(
          serviceModel.outputTaskGroup.tasks
        );
      }
    }
    return serviceModel;
  }

  private findStatus(tasks: Array<TaskModel>): TaskStatus {
    let taskGroupStatus;
    if (tasks.length > 0) {
      taskGroupStatus = TaskStatus.InProgress;
      if (tasks.every((x) => x.status === TaskStatus.Cancelled))
        taskGroupStatus = TaskStatus.Cancelled;
      else if (
        tasks.every(
          (x) =>
            x.status === TaskStatus.Todo || x.status === TaskStatus.Cancelled
        )
      )
        taskGroupStatus = TaskStatus.Todo;
      else if (
        tasks.every(
          (x) =>
            x.status === TaskStatus.Done || x.status === TaskStatus.Cancelled
        )
      )
        taskGroupStatus = TaskStatus.Done;
    } else taskGroupStatus = TaskStatus.Todo;
    return taskGroupStatus;
  }

  private findCompletedOn(tasks: Array<TaskModel>): string | null {
    if (
      !tasks.every(
        (x) => x.status === TaskStatus.Cancelled || x.status === TaskStatus.Done
      )
    )
      return null;
    const findCompletedMs = tasks
      .filter((x) => x.status === TaskStatus.Done && x.completedOn)
      .map((x) => new Date(x.completedOn).getTime());
    return findCompletedMs.length
      ? new Date(Math.max(...findCompletedMs)).toISOString()
      : null;
  }
}
