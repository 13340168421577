<form #departmentForm="ngForm">
  <div class="row">
    <div class="col-12">
      <div class="form-group">
        <label class="control-label required"
          ><b>{{ 'shared.terms.name' | translate }}</b></label
        >
        <input
          required
          #name="ngModel"
          type="text"
          name="name"
          [(ngModel)]="model.name"
          class="form-control"
        />
        <small
          *ngIf="(name.value == null || name.value === '') && submitted"
          class="text-danger"
          >{{ 'companies.department.errors.nameEmpty' | translate }}</small
        >
      </div>
    </div>
  </div>
  <form *ngIf="!isLoadingContacts" [formGroup]="form">
    <div class="form-group">
      <label>
        <b> {{ 'companies.department.model.selectContacts' | translate }}: </b>
      </label>
      <sh-multi-select
        formControlName="contacts"
        [options]="contactList"
      ></sh-multi-select>
    </div>
  </form>
</form>
