import { Component, Input, Output, EventEmitter } from '@angular/core';

import { ViewModel } from '../../../../../../shared/models/view.model';
import { EventModel } from '../../../../../../shared/models/event.model';
import { QueryModel } from '../../../../../../shared/models/query.model';

@Component({
  selector: 'invoicing-flows-header',
  templateUrl: 'invoicing-flows-header.component.html',
})
export class InvoicingFlowsHeaderComponent {
  @Input() resultCount: number;
  @Input() viewOptions: ViewModel[];
  @Input() selectedView: ViewModel['value'];

  @Output() onEvent: EventEmitter<EventModel>;

  @Input() search: QueryModel['search'];

  constructor() {
    this.resultCount = null;
    this.viewOptions = [];
    this.selectedView = null;
    this.onEvent = new EventEmitter<EventModel>();
    this.search = '';
  }
}
