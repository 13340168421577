<div class="modal-header">
  <div class="modal-title">{{ 'shared.terms.linkedObjects' | translate }}</div>
</div>
<div class="modal-body" style="padding: 0px">
  <div *ngFor="let item of linkedObjects">
    <div class="row p-3">
      <h5 class="col">
        {{ objectFieldDefinitions[item.key].name }}
      </h5>
      <div class="col-auto d-flex align-items-center">
        <button
          *ngIf="item.type === fieldType"
          class="btn btn-outline-secondary btn-sm ml-2 mr-2 text-nowrap"
          type="button"
          (click)="
            openBatchUpdateDialog(item.key, linkedObjectDefinitions[item.key])
          "
        >
          <i class="fa-regular fa-rectangle-list fa-fw text-nowrap"></i>
          {{ 'uiModules.batchUpdate' | translate }}
        </button>
        <div class="input-group" *ngIf="item.type === fieldType">
          <div class="input-group-prepend">
            <select
              class="form-control form-control-sm"
              [(ngModel)]="selectedColumns[item.key]"
            >
              <option *ngFor="let column of item.columns" [value]="column">
                {{ column | titleCase }}
              </option>
            </select>
          </div>
          <input
            type="text"
            class="form-control form-control-sm"
            placeholder="{{ 'shared.terms.search' | translate }}"
            [(ngModel)]="searchTerms[item.key]"
          />
          <div class="input-group-append">
            <button
              class="btn btn-primary btn-sm"
              (click)="
                search(item, selectedColumns[item.key], searchTerms[item.key])
              "
            >
              <i class="fa-solid fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <table class="table table-clickable">
      <thead>
        <tr>
          <th style="width: 1%" class="pl-3 pr-0">
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input"
                [id]="'customCheckAll_' + item.key"
                (change)="checkAll($event, item)"
              />
              <label
                class="custom-control-label"
                [for]="'customCheckAll_' + item.key"
              ></label>
            </div>
          </th>
          <th *ngFor="let column of item.columns">
            {{ column | titleCase }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let row of item.value | slice: 0:itemsToShow[item.key]"
          [class.no-checked]="!row['isChecked']"
        >
          <td class="pl-3 pr-0" style="width: 1%" (click)="toggleChecked(row)">
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input"
                [(ngModel)]="row['isChecked']"
              />
              <label class="custom-control-label"></label>
            </div>
          </td>
          <td *ngFor="let column of item.columns">
            {{ row[column] }}
          </td>
        </tr>
      </tbody>
    </table>
    <div>
      <button
        *ngIf="item.value.length > itemsToShow[item.key]"
        class="btn btn-primary btn-block btn-sm my-3 w-100"
        (click)="loadMore(item.key)"
      >
        {{ 'shared.terms.loadMore' | translate }}
      </button>
    </div>
  </div>
</div>
