import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { LayoutComponentsModule } from '../../layout/components/layout-components.module';
import { ReleaseNotesOverviewPage } from './release-notes-overview/release-notes-overview.page';
import { PreferenceComponentsModule } from '../components/preference-components.module';
import { RouterModule } from '@angular/router';
import { SystemMessagesOverviewPage } from './system-messages-overview/system-messages-overview.page';

@NgModule({
  declarations: [ReleaseNotesOverviewPage, SystemMessagesOverviewPage],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    TranslateModule,
    LayoutComponentsModule,
    PreferenceComponentsModule,
  ],
  exports: [SystemMessagesOverviewPage],
})
export class PreferencePagesModule {}
