import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { LayoutComponentsModule } from '../../layout/components/layout-components.module';
import { SharedModule } from '../../shared/shared.module';

import { UserPipesModule } from '../pipes/user-pipes.module';
import { LatestUpdatesComponent } from './latest-updates/latest-updates.component';
import { UserFormComponent } from './user-form/user-form.component';
import { UserHeaderComponent } from './user-header/user-header.component';
import { UserInviteComponent } from './user-invite/user-invite.component';
import { UserModalComponent } from './user-modal/user-modal.component';
import { UserPickerModalComponent } from './user-picker-modal/user-picker-modal.component';
import { UserPreferenceComponent } from './user-preference/user-preference.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { UserServiceConnectionsComponent } from './user-service-connections/user-service-connections.component';

@NgModule({
  declarations: [
    UserPreferenceComponent,
    UserProfileComponent,
    UserFormComponent,
    UserHeaderComponent,
    UserModalComponent,
    UserPickerModalComponent,
    LatestUpdatesComponent,
    UserServiceConnectionsComponent,
    UserInviteComponent,
  ],
  exports: [
    UserPreferenceComponent,
    UserProfileComponent,
    UserFormComponent,
    UserHeaderComponent,
    UserModalComponent,
    UserPickerModalComponent,
    LatestUpdatesComponent,
    UserServiceConnectionsComponent,
    UserInviteComponent,
  ],
  imports: [
    NgbModule,
    CommonModule,
    SharedModule,
    LayoutComponentsModule,
    RouterModule,
    FormsModule,
    NgSelectModule,
    UserPipesModule,
  ],
})
export class UserComponentsModule {}
