import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'underscore';
import { Observable, Subject, merge } from 'rxjs';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'ca-single-select-modal',
  templateUrl: 'single-select-modal.component.html',
})
export class SingleSelectModal {
  dropdownSettings = {
    allowSearchIdField: true,
    idField: 'id',
    textField: 'name',
    itemsShowLimit: 15,
    showIdFieldInDisplay: true,
    showIdFieldInSearch: true,
    toUpperCase: true,
  };
  data = [];
  errors: string[] = [];
  selected = [];
  focusSubject = new Subject<string>();

  @Input() set settings(value: {}) {
    this.dropdownSettings = _.extend(this.dropdownSettings, value);
  }

  @Input('selected-data') set selectedData(value) {
    this.selected = _.clone(value);
  }

  @Input('source-data') set sourceData(value: any[]) {
    this.data = _.clone(value);
  }

  @Input() label: string;
  @Input() title: string;
  @Input() placeholder: string;

  constructor(public modal: NgbActiveModal) {}

  dismiss(result) {
    this.modal.dismiss(result);
  }

  formatterDisplay = (item) => {
    if (!item) {
      return;
    }

    if (!item[this.dropdownSettings.textField]) {
      if (item[this.dropdownSettings.idField]) {
        item = _.find(
          this.data,
          (o) =>
            o[this.dropdownSettings.idField] ===
            item[this.dropdownSettings.idField]
        );
        if (!item) {
          return;
        }
      } else {
        return;
      }
    }

    let output = item[this.dropdownSettings.textField].toUpperCase();

    if (
      this.dropdownSettings.showIdFieldInDisplay === true &&
      this.dropdownSettings.idField !== this.dropdownSettings.textField
    ) {
      output += ' (' + item[this.dropdownSettings.idField] + ')';
    }

    return this.dropdownSettings.toUpperCase ? output.toUpperCase() : output;
  };

  formatterSearch = (item) => {
    let output = item[this.dropdownSettings.textField];

    if (
      this.dropdownSettings.showIdFieldInSearch === true &&
      this.dropdownSettings.idField !== this.dropdownSettings.textField
    ) {
      output += ' (' + item[this.dropdownSettings.idField] + ')';
    }

    return this.dropdownSettings.toUpperCase ? output.toUpperCase() : output;
  };

  onFocus(event) {
    this.focusSubject.next(event.target.value);
  }

  save() {
    this.modal.close(this.selected);
  }

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const inputFocus$ = this.focusSubject;

    return merge(debouncedText$, inputFocus$).pipe(
      map((term: string) =>
        (!term || term === ''
          ? this.data.slice(0, this.dropdownSettings.itemsShowLimit)
          : this.data.filter(
              (v) =>
                (v[this.dropdownSettings.textField] &&
                  v[this.dropdownSettings.textField]
                    .toLowerCase()
                    .indexOf(term.toLowerCase()) > -1) ||
                (this.dropdownSettings.allowSearchIdField === true &&
                  v[this.dropdownSettings.idField] &&
                  v[this.dropdownSettings.idField]
                    .toLowerCase()
                    .indexOf(term.toLowerCase()) > -1)
            )
        ).slice(0, this.dropdownSettings.itemsShowLimit)
      )
    );
  };
}
