import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@seahorse/common';
import { AISMarkerModel } from '@seahorse/domain';
import { GeoJson } from '../../../map/models/geojson.model';
import { MapDataService } from '@seahorse/domain';
import { ShipModel } from '../../../superuser-nautical/models/ship.model';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { MapBoxComponent } from '../map-box/map-box.component';
import { MapPopupLargeComponent } from '../map-popup-large/map-popup-large.component';
import { NauticalMapHeaderComponent } from '../nautical-map-header/nautical-map-header.component';
import { PreferenceDataService } from '../../../preference/services/preference-data.service';
import { MapMarkersModel } from '../../../map/models/map-markers.model';
import * as _ from 'underscore';
import * as moment from 'moment';

@Component({
  selector: 'ca-nautical-map',
  templateUrl: './nautical-map.component.html',
  styleUrls: ['./nautical-map.component.scss'],
})
export class NauticalMapComponent implements OnDestroy, OnInit {
  @ViewChild(NauticalMapHeaderComponent, { static: true }) header;
  @ViewChild(MapBoxComponent, { static: true }) mapBox;
  @ViewChild(MapPopupLargeComponent, { static: true }) mapPopupLarge;

  private _subscriptions = new Subscription();
  private _destroy$ = new Subject();

  currentPopupMarker: any;
  showTrackOnSelection = false;
  useLargePopup = false;

  constructor(
    private mapDataService: MapDataService,
    private notificationService: NotificationService,
    private preferenceService: PreferenceDataService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.preferenceService
      .getByName('mapMarkers', 'map')
      .subscribe((prefResult) => {
        if (prefResult.hasResult) {
          const preferences: MapMarkersModel = JSON.parse(
            prefResult.result.fieldValue
          );
          this.showTrackOnSelection = preferences.showTrackOnSelection;
          this.useLargePopup = preferences.useLargePopup;
        }
      });
  }

  toggleExpandedMenu() {
    if (this.header.isExpanded) {
      this.header.toggleExpanded();
    }
  }

  selectVessel($event) {
    this.loadPosition($event);
  }

  selectedMarker(aisInfo: AISMarkerModel) {
    if (aisInfo && aisInfo.mmsi && this.useLargePopup) {
      this.mapPopupLarge.aisInfo = aisInfo;
      if (this.showTrackOnSelection) {
        this.loadTrack(aisInfo);
      }
    } else if ((!aisInfo || !aisInfo.mmsi) && this.showTrackOnSelection) {
      this.mapBox.drawHistoricTrack([]);
    }
  }

  loadPosition(ship: ShipModel) {
    if (!ship.mmsi) {
      this.notificationService.showWarning(
        'Ship ' + ship.name + ' does not have mmsi information.',
        this.translate.instant('shared.terms.warning')
      );
      return;
    }

    this._subscriptions.add(
      this.mapDataService
        .getMarkerForMMSI(ship.mmsi)
        .pipe(takeUntil(this._destroy$))
        .subscribe(
          (result) => {
            if (!result || (!result.latitude && !result.longitude)) {
              this.notificationService.showWarning(
                'We can not find the ship.',
                this.translate.instant('shared.terms.warning')
              );
              return;
            }

            const ais = result;
            if (this.showTrackOnSelection) {
              this.loadTrack(ais);
            }

            if (this.mapBox.popupVisible && this.currentPopupMarker) {
              this.mapBox.showPopup(this.currentPopupMarker, false);
            }

            this.currentPopupMarker = this.mapBox.mapboxMarkers.find(
              (x) => x.options.id === ais.mmsi
            );

            if (!this.currentPopupMarker) {
              this.currentPopupMarker = this.mapBox.createMarker(
                new GeoJson([ais.longitude, ais.latitude]),
                ais
              );
            }

            if (!this.useLargePopup) {
              this.mapBox.showPopup(this.currentPopupMarker, true);
            } else {
              this.mapBox.flyTo(new GeoJson([ais.longitude, ais.latitude]));

              this.mapPopupLarge.aisInfo = ais;
            }
          },
          () => {},
          () => {}
        )
    );
  }

  loadTrack(aisInfo: AISMarkerModel) {
    this._subscriptions.add(
      this.mapDataService
        .getTrackForMMSI(
          aisInfo.mmsi,
          moment().add(-24, 'hours').toISOString(),
          moment().toISOString()
        )
        .subscribe((trackResult) => {
          if (trackResult) {
            aisInfo.positionDate = aisInfo.lastUpdate;
            trackResult.push(aisInfo);
            this.mapBox.drawHistoricTrack(trackResult);
          } else {
            this.mapBox.drawHistoricTrack([]);
          }
        })
    );
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();

    this._subscriptions.unsubscribe();
  }
}
