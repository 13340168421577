import { NgModule } from '@angular/core';

import { FileSizePipe, GeneralPipeResolverPipe } from '@seahorse/common';
import { UserDisplayPipe } from '@seahorse/domain';
import { CustomListDisplayPipe, FieldValuePipe, LinkedObjectsPipe } from '@seahorse/temp';
import { CurrencySymbolPipe } from './currency-symbol.pipe';
import { FieldOperatorPipe } from './field-operator.pipe';
import { PeriodIntervalDisplayPipe } from './period-interval-display.pipe';
import { TitleCasePipe } from './title-case.pipe';

@NgModule({
  imports: [
    GeneralPipeResolverPipe,
    CustomListDisplayPipe,
    LinkedObjectsPipe,
    UserDisplayPipe,
    FileSizePipe,
    FieldValuePipe
  ],
  declarations: [
    CurrencySymbolPipe,
    FieldOperatorPipe,
    PeriodIntervalDisplayPipe,
    TitleCasePipe,
  ],
  exports: [
    CurrencySymbolPipe,
    FieldOperatorPipe,
    FileSizePipe,
    PeriodIntervalDisplayPipe,
    TitleCasePipe,
  ],
  providers: [
    CurrencySymbolPipe,
    FieldOperatorPipe,
    FieldValuePipe,
    FileSizePipe,
    PeriodIntervalDisplayPipe,
    LinkedObjectsPipe,
    GeneralPipeResolverPipe,
    CustomListDisplayPipe,
    UserDisplayPipe
  ],
})
export class LayoutPipesModule {}
