import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'sh-error-message-panel',
  template: `
    <ng-container *ngIf="errors && errors.length > 0">
      <div class="alert alert-danger">
        <ng-container *ngIf="errors.length === 1">
          {{ errors[0] | translate }}
        </ng-container>
        <ng-container *ngIf="errors.length > 1">
          <ul>
            <li *ngFor="let error of errors">{{ error | translate }}</li>
          </ul>
        </ng-container>
      </div>
    </ng-container>
  `,
})
export class ErrorMessagePanelComponent {
  @Input() errors: string[] | undefined;
  @Input() set httpError(errorEvent: HttpErrorResponse) {
    if (errorEvent) {
      if (
        errorEvent.error &&
        (typeof errorEvent.error === 'string' ||
          errorEvent.error instanceof String)
      ) {
        const errorObj = JSON.parse(errorEvent.error.toString());
        if (errorObj) {
          if (errorObj.exceptionMessage) {
            this.errors = [errorObj.exceptionMessage];
            return;
          } else if (errorObj.message) {
            this.errors = [errorObj.exceptionMessage];
            return;
          }
        }
      }
      this.errors = ['shared.errorMessages.anErrorHasOccurred'];
    } else {
      this.errors = [];
    }
  }
}
