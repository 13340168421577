import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@seahorse/common';
import { ModalAction, ModalService } from '@seahorse/temp';
import { EMPTY, Subscription } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import * as _ from 'underscore';
import { LanguageModel, Languages } from '../../../core/models/language.model';
import { IdentityService } from '../../../core/services/identity.service';
import { UserLanguageModalComponent } from '../../../preference/components/user-language/user-language-modal/user-language-modal.component';
import { PreferenceModel } from '../../../preference/models/preference.model';
import { PreferenceDataService } from '../../../preference/services/preference-data.service';
import { MfaService } from '@seahorse/temp';
import { MfaSetupComponent } from '@seahorse/temp';
import { IdentityChallengeComponent } from '../identity-challenge/identity-challenge.component';

@Component({
  selector: 'ca-user-preference',
  templateUrl: 'user-preference.component.html',
  styleUrls: ['./user-preference.component.scss'],
})
export class UserPreferenceComponent implements OnInit, OnDestroy {
  language: LanguageModel = null;
  private preference: PreferenceModel = null;
  @Input() preferences: PreferenceModel[];
  @Input() isMfaEnabled = this.identityService.isMfaEnabled;

  private _subscriptions = new Subscription();
  constructor(
    private ngbModalService: NgbModal,
    private identityService: IdentityService,
    private notificationService: NotificationService,
    private preferenceDataService: PreferenceDataService,
    private translateService: TranslateService,
    private _modalService: ModalService,
    private _mfaService: MfaService
  ) {
    this.language = _.find(Languages, (lang) => {
      return lang.isDefault;
    });
  }

  ngOnInit() {
    this.preference = _.find(this.preferences, (preference) => {
      return preference.name.toLowerCase() === 'language';
    });

    this.setLanguage();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  selectLanguage() {
    const ngbModalOptions: NgbModalOptions = { backdrop: 'static' };
    const modalRef = this.ngbModalService.open(
      UserLanguageModalComponent,
      ngbModalOptions
    );

    if (this.preference) {
      modalRef.componentInstance.language = this.preference;
    }

    modalRef.result
      .then((result) => {
        if (result) {
          this.preferenceDataService.save(result).subscribe((response) => {
            if (response && response.hasResult) {
              this.identityService.setPreferences([response.result]);
              this.translateService.use(response.result.fieldValue);
              this.notificationService.showSuccess(
                this.translateService.instant('shared.terms.updateSuccess'),
                this.translateService.instant('shared.terms.success')
              );
              this.preference = response.result;
              this.setLanguage();
            }
          });
        }
      })
      .catch(() => {
        // do nothing
      });
  }

  setLanguage() {
    if (this.preference) {
      const lang = _.find(Languages, (l) => {
        return l.value === this.preference.fieldValue;
      });

      if (lang) {
        this.language = lang;
      }
    }
  }

  toggleMfa($event: Event) {
    $event.preventDefault();

    this._modalService
      .openComponentModal<IdentityChallengeComponent>(
        {
          component: IdentityChallengeComponent,
        },
        'account.challenge.title'
      )
      .pipe(
        mergeMap((result) => {
          const componentRef =
            result.componentRef as IdentityChallengeComponent;

          if (result.action === ModalAction.Close) {
            return EMPTY;
          }

          return componentRef
            .submitChallenge()
            .pipe(filter((isSuccess) => isSuccess === true));
        })
      )
      .subscribe(() => {
        this._modalService.closeModals();

        if (this.isMfaEnabled) {
          this.disableMfa();
        } else {
          this.enableMfa();
        }
      });
  }

  private disableMfa() {
    this._mfaService.removeMfa().subscribe((response) => {
      if (response.result) {
        this.isMfaEnabled = false;
        this.identityService.isMfaEnabled = false;
        this.notificationService.showSuccess(
          'user.mfa.disabled',
          'shared.terms.success'
        );
        this.verifyMfaRequirement();
      } else {
        this.notificationService.showError(
          'user.mfa.disableFailed',
          'shared.terms.error'
        );
      }
    });
  }

  private enableMfa() {
    this._mfaService
      .initMfa()
      .pipe(
        mergeMap((response) => {
          if (response.result) {
            return this._modalService.openComponentModal<MfaSetupComponent>(
              {
                component: MfaSetupComponent,
                props: {
                  setupCode: response.result,
                },
              },
              'user.mfa.setup.title'
            );
          } else {
            this.notificationService.showError('shared.terms.error');
            return EMPTY;
          }
        }),
        mergeMap((result) => {
          if (result.action === ModalAction.Close) {
            result.modalRef.close();
            return EMPTY;
          }

          const componentRef: MfaSetupComponent = result.componentRef;
          const code = componentRef.codeControl.value;

          if (!code) {
            this.notificationService.showError(
              'user.mfa.verificationCodeRequired'
            );
            return EMPTY;
          }

          return this._mfaService.completeMfa(code).pipe(
            map((response) => ({
              success: response.result,
              modalRef: result.modalRef,
            }))
          );
        })
      )
      .subscribe(({ success, modalRef }) => {
        if (success) {
          this.isMfaEnabled = true;
          this.identityService.isMfaEnabled = true;
          this.notificationService.showSuccess(
            'user.mfa.enabled',
            'shared.terms.success'
          );
          modalRef.close();
        } else {
          this.notificationService.showError(
            'user.mfa.invalidVerificationCode',
            'shared.terms.error'
          );
        }
      });
  }

  private verifyMfaRequirement() {
    this._subscriptions.add(
      this.preferenceDataService
        .getByName('mfa', null, true)
        .subscribe((res) => {
          if (res.hasResult && res?.result.fieldValue) {
            this.identityService.clearIdentity();
          }
        })
    );
  }
}
