import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, interval, Observable, Subject, zip } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IdentityService } from './identity.service';
import { Languages } from '../../core/models/language.model';
import { ProxyServices } from '../../core/services/proxy.service';
import { GenericMessageModalComponent } from '../../layout/components/generic-message-modal/generic-message-modal.component';
import { PreferenceModel } from '../../preference/models/preference.model';
import { PreferenceDataService } from '../../preference/services/preference-data.service';
import { ServiceConnectionsDataService } from '../../service-connections/services/service-connections.service';
import { UserDataService } from '../../user/services/user-data.service';
import { HttpErrorResponse } from '@angular/common/http';
import { InboxDataService } from '@seahorse/domain';

@Injectable({
  providedIn: 'root',
})
export class PreloaderService {
  private _identityService = inject(IdentityService);
  private _inboxDataService = inject(InboxDataService);
  private _ngbModal = inject(NgbModal);
  private _preferenceDataService = inject(PreferenceDataService);
  private _proxyServices = inject(ProxyServices);
  private _router = inject(Router);
  private _serviceConnectionsService = inject(ServiceConnectionsDataService);
  private _translateService = inject(TranslateService);
  private _userDataService = inject(UserDataService);
  private _destroy = new Subject();

  loadIdentity(): Observable<boolean> {
    return new Observable<boolean>((subscriber) => {
      // Try to load the identity from the API
      if (this._identityService.token) {
        // Preload all users
        this._userDataService.getByOrganisation(true).subscribe();

        const preferences = this.getPreferences();
        const proxy = this._proxyServices.getData();
        const subscriptions =
          this._serviceConnectionsService.getSubscriptions();
        // const latestReleaseNote = this._systemMessageService.getLatest('releasenote');

        forkJoin([
          preferences,
          proxy,
          subscriptions,
          /*, latestReleaseNote*/
        ]).subscribe(
          (results) => {
            const preferences = results[0];
            const subscriptionsResult = results[2];
            // const releaseNotesResult = results[3];

            // if (releaseNotesResult.hasResult) {
            //   this.identityService.latestReleaseNote = releaseNotesResult.result[0];
            // }

            if (subscriptionsResult.hasResult) {
              this._serviceConnectionsService.subscriptions =
                subscriptionsResult.result;
            }

            if (this._identityService.identity) {
              const customDataPermissionsPreference = preferences.find(
                (x) =>
                  x.category?.toLowerCase() === 'permissions' &&
                  x.name.toLowerCase() === 'custom_data'
              )?.fieldValue;

              if (customDataPermissionsPreference) {
                const customDataPermissions = JSON.parse(
                  customDataPermissionsPreference
                );
                if (customDataPermissions.hasStep) {
                  const invoicesPermissionsPreference = preferences.find(
                    (x) =>
                      x.category?.toLowerCase() === 'permissions' &&
                      x.name.toLowerCase() === 'invoices'
                  )?.fieldValue;
                  const invoicesPermissions = invoicesPermissionsPreference
                    ? JSON.parse(invoicesPermissionsPreference)
                    : undefined;
                  if (
                    customDataPermissions.finished &&
                    invoicesPermissions?.hasStep &&
                    !invoicesPermissions?.finished
                  ) {
                    this._router.navigate(['/onboarding/invoicing']);
                  } else {
                    this._router.navigate(['/onboarding/customdata']);
                  }
                }
              }

              // load incoming data count
              if (
                this._identityService.hasPermission('custom_data.inbox_admin')
              ) {
                interval(30000)
                  .pipe(takeUntil(this._destroy))
                  .subscribe(() => {
                    this._inboxDataService.getCount().subscribe((dataCount) => {
                      if (dataCount.hasResult) {
                        this._identityService.numberOfInboxData =
                          dataCount.result;
                      }
                    });
                  });
              }
            } else {
              this._identityService.clearIdentity();
            }

            interval(30000)
              .pipe(takeUntil(this._destroy))
              .subscribe(() => {
                this._userDataService.getProfile().subscribe({
                  next: (userResult) => {
                    if (!userResult.hasResult) {
                      this.onUserUnauthorized();
                    }
                  },
                  error: (error: HttpErrorResponse) => {
                    if (error.status === 401) {
                      this.onUserUnauthorized();
                    }
                  },
                });
              });

            subscriber.next(true);
          },
          () => {
            this._identityService.clearIdentity();
          }
        );
      } else {
        subscriber.next(true);
      }
    });
  }

  private onUserUnauthorized() {
    const modalRef = this._ngbModal.open(GenericMessageModalComponent, {
      backdrop: 'static',
    });
    modalRef.componentInstance.modalHeader = 'You are not logged in anymore';
    modalRef.componentInstance.modalBody = 'Logging out...';
    modalRef.componentInstance.hideOkButton = true;

    interval(5000)
      .pipe(takeUntil(this._destroy))
      .subscribe(() => {
        this._identityService.clearIdentity();
      });
  }

  preload(): Observable<number> {
    return new Observable<number>((subscriber) => {
      const identityLoader = this.loadIdentity();

      zip(identityLoader).subscribe((zResult) => {
        subscriber.next(zResult.length);
      });
    });
  }

  private getPreferences(): Observable<PreferenceModel[]> {
    return new Observable<PreferenceModel[]>((subscription) => {
      let result: PreferenceModel[] = [];
      this._preferenceDataService.get().subscribe({
        next: (response) => {
          if (response.hasResult) {
            result = response.result;
          }
        },
        error: () => {
          subscription.next(result);
          subscription.complete();
        },
        complete: () => {
          if (result) {
            this._identityService.setPreferences(result);
            const language = Languages.find(
              (x) =>
                x.value.toLowerCase() ===
                result
                  .find((y) => y.name.toLowerCase() === 'language')
                  ?.fieldValue?.toLowerCase()
            )?.value;
            if (language) {
              this._translateService.use(language).subscribe({
                complete: () => {
                  subscription.next(result);
                  subscription.complete();
                },
              });
            } else {
              subscription.next(result);
              subscription.complete();
            }
          }
        },
      });
    });
  }
}
