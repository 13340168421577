import {
  Component,
  ComponentRef,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';

import * as mapboxgl from 'mapbox-gl';
import { MapInteractionService } from '../../services/map-interaction.service';

import * as _ from 'underscore';
import { AISMarkerModel } from '@seahorse/domain';
import { GeoJson } from '../../../map/models/geojson.model';

@Component({
  selector: 'ca-map-box-kiosk-display',
  templateUrl: './map-box-kiosk-display.component.html',
  styleUrls: ['./map-box-kiosk-display.component.scss'],
})
export class MapBoxKioskDisplayComponent {
  private _markers: mapboxgl.Marker[] = [];
  private mapMarkerComponent: ComponentRef<any>;

  @Input() animationTimeout = 15000;
  @Input() set aisMarkers(aisMarkers: AISMarkerModel[]) {
    if (!aisMarkers) {
      return;
    }

    if (!this.map) {
      this.buildMap();
    } else {
      // this.flyTo(aisMarker);
    }

    _.each(
      _.filter(
        aisMarkers,
        (marker) => marker.latitude !== null && marker.longitude !== null
      ),
      (markerEach) => {
        const existingMarker = _.find(
          this._markers,
          (m) => m.mmsi === markerEach.mmsi
        );
        if (existingMarker) {
          existingMarker.setLngLat([markerEach.longitude, markerEach.latitude]);
        } else {
          const newMarker = new mapboxgl.Marker()
            .setLngLat([markerEach.longitude, markerEach.latitude])
            .addTo(this.map);
          newMarker.mmsi = markerEach.mmsi;
          this._markers.push(newMarker);
        }
      }
    );
    this.startRotation();
  }

  @Output() markerSelected = new EventEmitter<AISMarkerModel>();

  private currentIndex = -1;
  private isRunning = false;

  /// default settings
  map: mapboxgl.Map;
  style = 'mapbox://styles/mapbox/outdoors-v9';

  // data
  source: any;
  markers: any = [];

  // Subscriptions to service calls

  constructor(
    private mapInteractionService: MapInteractionService,
    private router: Router
  ) {}

  mapLoaded() {}

  buildMap() {
    this.map = new mapboxgl.Map({
      attributionControl: false,
      container: 'nautical-map-kiosk-display',
      style: this.style,
      bearing: 0,
      // center: [],
      zoom: 6,
      pitch: 45,
    });

    /// Add map controls
    this.map.addControl(new mapboxgl.NavigationControl());
  }

  flyTo(aisMarker: mapboxgl.Marker) {
    if (!aisMarker || !aisMarker._lngLat) {
      return;
    }
    this.map.flyTo({
      center: aisMarker._lngLat,
    });
  }

  nextIndex() {
    let newIndex = this.currentIndex + 1;
    if (newIndex >= this._markers.length) {
      newIndex = 0;
    }

    if (this.currentIndex === newIndex) {
      return null;
    } else {
      this.currentIndex = newIndex;
    }
    return newIndex;
  }

  nextItem() {
    if (
      this._markers &&
      this._markers.length > 0 &&
      this.nextIndex() !== null
    ) {
      if (this.currentIndex >= 0) {
        this.flyTo(this._markers[this.currentIndex]);
        this.markerSelected.emit(this._markers[this.currentIndex]);
      }
    }

    setTimeout(() => {
      this.nextItem();
    }, this.animationTimeout);
  }

  startRotation() {
    if (this.isRunning) {
      return;
    }

    this.isRunning = true;
    this.nextItem();
  }
}
