import { TaskModel } from '../../tasks/modules/task/models/task.model';
import { TaskGroupModel } from './task-group.model';

export class TaskGroupServiceModel {
  inputTaskGroup: TaskGroupModel | null;
  inputTask: TaskModel | null;
  outputTaskGroup: TaskGroupModel | null;
  outputTask: TaskModel | null;

  constructor(
    inputTaskGroup: TaskGroupModel | null,
    inputTask: TaskModel | null
  ) {
    this.inputTaskGroup = inputTaskGroup;
    this.inputTask = inputTask;
    this.outputTaskGroup = inputTaskGroup
      ? (JSON.parse(JSON.stringify(inputTaskGroup)) as TaskGroupModel)
      : null;
    this.outputTask = inputTask
      ? (JSON.parse(JSON.stringify(inputTask)) as TaskModel)
      : null;
  }

  taskGroupHasChanged(): boolean {
    return TaskGroupHasChanged(
      this.inputTaskGroup,
      this.outputTaskGroup,
      false
    );
  }

  taskHasChanged(): boolean {
    const inputTaskGroup = new TaskGroupModel();
    inputTaskGroup.tasks.push(this.inputTask);
    const outputTaskGroup = new TaskGroupModel();
    outputTaskGroup.tasks.push(this.outputTask);
    return TaskGroupHasChanged(inputTaskGroup, outputTaskGroup, true);
  }
}

export function TaskGroupHasChanged(
  taskGroup1: TaskGroupModel | null,
  taskGroup2: TaskGroupModel | null,
  compareTasks: boolean
): boolean {
  const clonedTaskGroup1 = taskGroup1 ? { ...taskGroup1 } : null;
  if (clonedTaskGroup1) clonedTaskGroup1.tasks = undefined;
  const clonedTaskGroup2 = taskGroup2 ? { ...taskGroup2 } : null;
  if (clonedTaskGroup2) clonedTaskGroup2.tasks = undefined;

  if (compareTasks) {
    const clonedTasks1 = taskGroup1?.tasks.map((x) => {
      const n = { ...x };
      n.followUpTasks = undefined;
      return n;
    });
    const clonedTasks2 = taskGroup2?.tasks.map((x) => {
      const n = { ...x };
      n.followUpTasks = undefined;
      return n;
    });

    if (clonedTasks1 && clonedTasks2) {
      if (clonedTasks1.length !== clonedTasks2.length) return true;

      for (const clonedTask1 of clonedTasks1) {
        const clonedTask2 = clonedTasks2.find((clonedTask2) => {
          const taskKeys1 = Object.keys(clonedTask1);
          const taskKeys2 = Object.keys(clonedTask2);

          if ((taskKeys1 && !taskKeys2) || (!taskKeys1 && taskKeys2))
            return false;
          else if (!taskKeys1 && !taskKeys2) return true;
          else if (taskKeys1.length !== taskKeys2.length) return false;
          return taskKeys1.every(
            (key) => clonedTask1[key] === clonedTask2[key]
          );
        });
        if (!clonedTask2) return true;
      }
    } else if (!(!clonedTasks1 && !clonedTasks2)) return true;
  }

  let taskGroupKeys1: string[] | null = null;
  if (clonedTaskGroup1) taskGroupKeys1 = Object.keys(clonedTaskGroup1);

  let taskGroupKeys2: string[] | null = null;
  if (clonedTaskGroup2) taskGroupKeys2 = Object.keys(clonedTaskGroup2);

  if (
    (taskGroupKeys1 && !taskGroupKeys2) ||
    (!taskGroupKeys1 && taskGroupKeys2)
  )
    return true;
  else if (!taskGroupKeys1 && !taskGroupKeys2) return false;
  else if (taskGroupKeys1.length !== taskGroupKeys2.length) return true;
  return !taskGroupKeys1.every(
    (key) => clonedTaskGroup1[key] === clonedTaskGroup2[key]
  );
}
