<div class="card">
  <div class="card-header">
    <button
      (click)="onEvent.emit({ action: 'selectInvoicingFlow', data: null })"
      type="button"
      class="btn btn-clear float-right"
    >
      <i class="fa-solid fa-times fa-fw"></i>
    </button>
    <span class="text-uppercase">
      {{ selectedInvoicingFlow.name }}
    </span>
  </div>
  <div class="card-body">
    <div class="card-body-group">
      <dl class="row">
        <dt class="col-6 col-xl-3">
          {{ 'invoicingFlows.flow.invoiceNumberTemplate' | translate }}
        </dt>
        <dd class="col-6 col-xl-9">
          {{ selectedInvoicingFlow.invoiceNumberTemplate }}
        </dd>
        <dt class="col-6 col-xl-3">
          {{ 'invoicingFlows.flow.onlineAvailable' | translate }}
        </dt>
        <dd class="col-6 col-xl-9">
          <i
            [ngClass]="
              selectedInvoicingFlow.onlineAvailable
                ? 'fa-solid fa-check fa-fw text-success'
                : 'fa-solid fa-times fa-fw text-danger'
            "
          ></i>
        </dd>
        <dt class="col-6 col-xl-3">
          {{ 'invoicingFlows.flow.trackPayments' | translate }}
        </dt>
        <dd class="col-6 col-xl-9">
          <i
            [ngClass]="
              selectedInvoicingFlow.trackPayments
                ? 'fa-solid fa-check fa-fw text-success'
                : 'fa-solid fa-times fa-fw text-danger'
            "
          ></i>
        </dd>
      </dl>
    </div>
    <div
      *ngIf="selectedInvoicingFlow && selectedInvoicingFlow.stages.length > 0"
      class="card-body-group bg-light p-3"
    >
      <h4 class="text-muted">{{ 'invoicingFlows.flow.stages' | translate }}</h4>
      {{ selectedInvoicingFlow.stages }}
    </div>
  </div>
  <ul class="summary-buttons">
    <li>
      <button
        (click)="onEvent.emit({ action: 'viewInvoicingFlow' })"
        type="button"
        class="summary-button w-100"
      >
        <i class="fa-regular fa-funnel-dollar"></i>
        <span class="summary-button-text">{{
          'invoicingFlows.overview.viewFlow' | translate
        }}</span>
      </button>
    </li>
  </ul>
</div>
