import { CompanyModel } from '../../companies/models/company.model';
import { CustomDataBaseModel } from '../../custom-data/models/custom-data-base.model';
import { NauticalShipModel } from '../../nautical-ships/models/nautical-ship.model';
import { NauticalVisitModel } from '../../nautical-visits/models/nautical-visit.model';

export class OrderRegistrationModel extends CustomDataBaseModel {
  tariffType: OrderRegistrationTariffType;
  currency?: OrderRegistrationCurrency;
  contact?: string;
  status: OrderRegistrationStatus;
  // public actualCosts:             number;
  amount: number;
  quantity: number;
  po: string;
  orderDate?: string | Date;
  deliveryDate?: string | Date;
  // public quoteRequired:           boolean;
  // public isBudget:                boolean;
  comments?: string;
  customerReference?: string;
  // public handlingFee?:            number;
  // public handlingFeePercentage:   boolean;
  commissionFee?: number;
  commissionFeePercentage: boolean;
  shipName?: string;
  shipImo?: string;
  urgent: boolean;
  isChanged: boolean;

  connectedTariff?: any;
  customer?: CompanyModel['id'];
  portCallFile?: CustomDataBaseModel['__Id'];
  portVisit?: NauticalVisitModel['id'];
  ship?: NauticalShipModel['id'];
  supplier?: CompanyModel['id'];

  isChecked: boolean;

  constructor() {
    super();

    this.tariffType = OrderRegistrationTariffType.Regular;
    // this.currency = OrderRegistrationCurrency.EUR;
    this.contact = null;
    this.status = OrderRegistrationStatus.Pending;
    // this.actualCosts              = 0;
    // this.amount = 0;
    this.quantity = 1;
    this.po = null;
    this.orderDate = null;
    this.deliveryDate = null;
    // this.quoteRequired            = false;
    // this.isBudget                 = false;
    this.comments = null;
    this.customerReference = null;
    // this.handlingFee              = 0;
    // this.handlingFeePercentage    = false;
    // this.commissionFee = 0;
    this.commissionFeePercentage = true;
    this.shipName = null;
    this.shipImo = null;
    this.urgent = false;
    this.isChanged = false;

    this.connectedTariff = null;
    this.customer = null;
    this.portCallFile = null;
    this.portVisit = null;
    this.ship = null;
    this.supplier = null;
  }

  static fromOrderRegistrationModel = (
    orderRegistration: OrderRegistrationModel
  ) => {
    if (orderRegistration) {
      if (!orderRegistration.__AdditionalData) {
        orderRegistration.__AdditionalData = {};
      }

      if (orderRegistration.portCallFile) {
        if (!orderRegistration.__AdditionalData.portCallFile) {
          orderRegistration.__AdditionalData.portCallFile = {};
        }

        orderRegistration.__AdditionalData.portCallFile.__Id =
          orderRegistration.portCallFile;
      }

      if (orderRegistration.connectedTariff) {
        if (!orderRegistration.__AdditionalData.connectedTariff) {
          orderRegistration.__AdditionalData.connectedTariff = {};
        }

        orderRegistration.__AdditionalData.connectedTariff.id =
          orderRegistration.connectedTariff;
      }

      if (orderRegistration.customer) {
        if (!orderRegistration.__AdditionalData.customer) {
          orderRegistration.__AdditionalData.customer = {};
        }

        orderRegistration.__AdditionalData.customer.id =
          orderRegistration.customer;
      }

      if (orderRegistration.ship) {
        if (!orderRegistration.__AdditionalData.ship) {
          orderRegistration.__AdditionalData.ship = {};
        }

        orderRegistration.__AdditionalData.ship.id = orderRegistration.ship;
        orderRegistration.__AdditionalData.ship.name =
          orderRegistration.shipName ||
          orderRegistration.__AdditionalData.ship.name;
        orderRegistration.__AdditionalData.ship.imo =
          orderRegistration.shipImo ||
          orderRegistration.__AdditionalData.ship.imo;
      }

      if (orderRegistration.portVisit) {
        if (!orderRegistration.__AdditionalData.portVisit) {
          orderRegistration.__AdditionalData.portVisit = {};
        }

        orderRegistration.__AdditionalData.portVisit.id =
          orderRegistration.portVisit;
      }

      if (orderRegistration.supplier) {
        if (!orderRegistration.__AdditionalData.supplier) {
          orderRegistration.__AdditionalData.supplier = {};
        }

        orderRegistration.__AdditionalData.supplier.id =
          orderRegistration.supplier;
      }
    }
    return orderRegistration;
  };
}

export enum OrderRegistrationTariffType {
  Regular,
  Weekend,
}

export enum OrderRegistrationCurrency {
  EUR = 'EUR',
  GBP = 'GBP',
  USD = 'USD',
  JPY = 'JPY',
  DKK = 'DKK',
  NOK = 'NOK',
}

export enum OrderRegistrationStatus {
  Ordered,
  Pending,
  AwaitingShipment,
  Shipping,
  Completed,
  Cancelled,
}
