import { ComponentRef, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DynamicsService, NotificationService } from '@seahorse/common';
import {
  CustomDataContentService,
  CustomDataSearchFieldModel,
  ExpenseEventType,
  ExpenseModel,
  ExpenseFilterModel,
  ExpensesDataService,
  FieldType,
  ObjectDefinitionHelpers,
  ObjectDefinitionModel,
  ProxyService,
  SearchOperators,
} from '@seahorse/domain';
import { Observable, of } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import * as _ from 'underscore';
import { DataCollectionConfig } from '../data/data-collection.model';
import { DataListComponent } from '../data/data-list/data-list.component';
import { DataField } from '../data/data.model';
import { CustomDataFormService } from '../form/custom-data-form.service';
import { FormField } from '../form/form.model';
import { ModalAction } from '../modal/modal.model';
import { ModalService } from '../modal/modal.service';
import { ExpenseUpdateModalHeader } from './expense-header.model';
import { ExpenseSearchHeaderComponent } from './expense-header/expense-search-header/expense-search-header.component';

export const expensesUpdateModalProcedure = (preload: boolean) => {
  const modalService = inject(ModalService);
  const customDataFormService = inject(CustomDataFormService);
  const objectDefinition = inject(ProxyService).objectDefinitions.find(
    (x) => x.mappingKey === '$customcontent_st-expense'
  );
  const customDataService = inject(CustomDataContentService);
  const expensesDataService = inject(ExpensesDataService);
  const notificationService = inject(NotificationService);
  const translate = inject(TranslateService);
  const dynamicsService = inject(DynamicsService);
  let dataListComponentInstance = null;
  let searchboxComponentRef: ComponentRef<ExpenseUpdateModalHeader> = null;
  let allExpenses: ExpenseModel[] = [];
  const hasSearchQuery = (): boolean => {
    return (
      searchboxComponentRef?.instance?.data !== undefined &&
      searchboxComponentRef?.instance?.data !== null &&
      searchboxComponentRef?.instance?.data !== ''
    );
  };

  return (
    expenses?: ExpenseModel[] | undefined,
    checkedIds?: string[],
    singleSelect = false,
    parent?: any,
    parentDefinition?: ObjectDefinitionModel,
    searchFields?: CustomDataSearchFieldModel[],
    headerComponentRef?: ComponentRef<ExpenseUpdateModalHeader>,
    showSearchBox = false
  ) => {
    const searchDefault = (searchFields: CustomDataSearchFieldModel[]) => {
      return customDataService
        .searchActiveCustomerDataByFields(
          'expense',
          searchFields,
          null,
          null,
          -1,
          -1,
          null,
          null,
          null,
          null,
          null,
          null,
          ObjectDefinitionHelpers.getConnectedDataConfig(objectDefinition)
        )
        .pipe(map((response) => response.result));
    };

    const data$ = preload ? searchDefault(searchFields) : of(expenses);

    const fields = ObjectDefinitionModel.getAllFieldDefinitions(
      objectDefinition,
      true
    )
      .filter((x) =>
        ['description', 'amountActual', 'remarks', 'tariff'].includes(
          x.systemCode
        )
      )
      .map((x) => DataField.fromFieldDefinition(x));

    const poField: DataField = {
      key: 'po',
      name: 'PO',
      type: FieldType.SingleLineText,
    };

    fields.unshift(poField);

    const formModel = customDataFormService.getFormModel(
      objectDefinition,
      fields.map((x) => x.key as string),
      null,
      false
    );

    const poFormField: FormField<FieldType.SingleLineText> = {
      key: 'po' as keyof FieldType.SingleLineText & string,
      name: 'PO',
      groupName: null,
      type: FieldType.SingleLineText,
      isRequired: false,
      isDisabled: true,
      validation: null,
      options: null,
    };

    formModel.fields.unshift(poFormField);

    const mapAndSetExpenses = (expenses: ExpenseModel[]) => {
      allExpenses = expenses.map((expense) => ({
        ...expense,
        po: expense.__AdditionalData?.orderRegistration?.po || null,
        isChecked: checkedIds?.includes(expense.__Id),
      }));
      return allExpenses;
    };

    const filterExpenses = (
      expenses: ExpenseModel[],
      filter: ExpenseUpdateModalHeader
    ) => {
      if (!filter || !filter.data || filter.type !== ExpenseEventType.Filter) {
        return expenses;
      }

      const eventData = filter.data as ExpenseFilterModel;
      const properties = eventData.propertyPaths.map((path) => {
        return path !== undefined && path !== null ? path.split('.') : [];
      });

      const tempList = allExpenses.filter((expense) => {
        let allMatch = true;
        properties.forEach((property) => {
          let current = expense;
          let isMatch = false;
          property.forEach((path) => {
            if (!path || current === undefined || current === null) return;

            current = current[path];
            if (current === undefined || current === null) {
              isMatch =
                isMatch ||
                eventData.filterValue === undefined ||
                eventData.filterValue === null;
            } else if (Array.isArray(current)) {
              if (
                eventData.filterValue === undefined ||
                eventData.filterValue === null
              ) {
                isMatch = isMatch || current.length === 0;
              } else {
                isMatch = isMatch || current.includes(eventData.filterValue);
              }
            } else if (typeof current === 'string') {
              isMatch = isMatch || current === eventData.filterValue;
            }
          });
          allMatch = allMatch && isMatch;
        });
        return allMatch;
      });

      return tempList;
    };

    const updateDataList = (
      expenses: ExpenseModel[],
      dataListComponentInstance: DataListComponent,
      resetCheckboxes = false
    ) => {
      if (dataListComponentInstance) {
        const seedData = ObjectDefinitionHelpers.getSeedData(
          parentDefinition,
          objectDefinition.addConfig
        );

        let isAllChecked = expenses && expenses.length > 0;
        if (resetCheckboxes === true) {
          expenses?.forEach((expense) => {
            expense['isChecked'] = false;
          });
          isAllChecked = false;
        } else {
          expenses?.forEach((expense) => {
            const existing = dataListComponentInstance.items.find(
              (x) => x.__Id === expense.__Id
            );
            expense['isChecked'] = existing ? existing['isChecked'] : false;
            isAllChecked = isAllChecked && expense['isChecked'];
          });
        }

        dataListComponentInstance.isAllChecked = isAllChecked;
        dataListComponentInstance.items = expenses;
        dataListComponentInstance.suggestedRows = expenses?.reduce(
          (acc, cur, index) => {
            acc[index] = ObjectDefinitionHelpers.applySeedData(
              parent,
              {},
              seedData
            );
            return acc;
          },
          {}
        );

        dataListComponentInstance.detectChanges();
      }
    };

    const handleData = (
      header: ExpenseUpdateModalHeader
    ): Observable<ExpenseModel[]> => {
      let search$: Observable<ExpenseModel[]>;
      const headerType = header?.type;
      let resetCheckboxes = false;
      switch (headerType) {
        case ExpenseEventType.Search:
          {
            resetCheckboxes = true;
            header.isLoading = true;
            const fields = hasSearchQuery()
              ? objectDefinition.simpleSearchConfig?.fields.map(
                  (field) =>
                    ({
                      fieldName: field,
                      searchOperator: SearchOperators.ContainsAny,
                      searchValue: header.data,
                    } as CustomDataSearchFieldModel)
                )
              : searchFields;

            search$ = searchDefault(fields);
          }
          break;
        case ExpenseEventType.Filter:
          search$ = of(allExpenses);
          break;
        default:
          search$ = of(allExpenses);
          break;
      }

      return search$.pipe(
        map((expenses) => {
          mapAndSetExpenses(expenses);
          return filterExpenses(allExpenses, headerComponentRef?.instance);
        }),
        tap((expenses) => {
          if (header) {
            header.isLoading = false;
          }
          updateDataList(expenses, dataListComponentInstance, resetCheckboxes);
        })
      );
    };

    if (headerComponentRef) {
      headerComponentRef.changeDetectorRef.detectChanges();
      headerComponentRef.instance.filterChange.subscribe((headerResponse) =>
        handleData(headerResponse).subscribe()
      );
    }

    if (showSearchBox) {
      searchboxComponentRef = dynamicsService.createComponent({
        component: ExpenseSearchHeaderComponent,
      });
      searchboxComponentRef.changeDetectorRef.detectChanges();
      searchboxComponentRef.instance.filterChange.subscribe((headerResponse) =>
        handleData(headerResponse).subscribe()
      );
    }

    return data$.pipe(
      mergeMap((data) => {
        mapAndSetExpenses(data);
        const items = filterExpenses(allExpenses, headerComponentRef?.instance); // check if header component is defined and filter the data,
        const seedData = ObjectDefinitionHelpers.getSeedData(
          parentDefinition,
          objectDefinition.addConfig
        );

        const modalRef = modalService
          .buildModal()
          .withTitle('customModules.expenses.updateDialog')
          .withHTMLHeader(searchboxComponentRef?.location.nativeElement)
          .withHTMLHeader(headerComponentRef?.location.nativeElement)
          .withComponentBody<DataListComponent>({
            component: DataListComponent,
            props: {
              selectable: true,
              singleSelect: singleSelect,
              items,
              suggestedRows: items.reduce((acc, cur, index) => {
                acc[index] = ObjectDefinitionHelpers.applySeedData(
                  parent,
                  {},
                  seedData
                );
                return acc;
              }, {}),
              config: <DataCollectionConfig>{
                objectDefinition: objectDefinition,
                displayFields: fields,
              },
              formModel,
            },
            hostAttributes: ['flex'],
          });

        dataListComponentInstance = modalRef.components[0] as DataListComponent;
        dataListComponentInstance.checkChange.subscribe();
        dataListComponentInstance.detectChanges();

        return modalRef.open({ size: 'xl', isTable: true }).pipe(
          mergeMap((res) => {
            if (res.action === ModalAction.Close) {
              return of(undefined);
            }

            const dataListRef = res.componentRef as DataListComponent;
            const updatedExpenses = dataListRef.forms.map((x) => x.value);

            res.modalRef.close();

            return expensesDataService.update(updatedExpenses).pipe(
              tap(
                (rExpenses) => {
                  if (rExpenses.hasResult) {
                    notificationService.showSuccess(
                      'customModules.expenses.expenseUpdated',
                      'shared.terms.success'
                    );
                  } else {
                    notificationService.showError(
                      _.pluck(rExpenses.messages, 'message').join('\n'),
                      translate.instant('shared.terms.failed')
                    );
                  }
                },
                (eExpenses) => {
                  notificationService.showError(
                    _.pluck(eExpenses.error.messages, 'message').join('\n'),
                    translate.instant('shared.terms.failed')
                  );
                }
              ),
              map((response) => response.result)
            );
          })
        ) as Observable<ExpenseModel[] | undefined>;
      })
    );
  };
};
