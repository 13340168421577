import { Injectable } from '@angular/core';
import { ResultWrapper } from '@seahorse/common';
import { Observable } from 'rxjs';
import { DataContextV2Service } from '../../shared/services/data-context-v2.service';
import { CompanyReferenceModel } from '@seahorse/domain';
import { ContentDistributionRecipientModel } from '../../content-distribution/models/content-distribution-recipient.model';
@Injectable({
  providedIn: 'root',
})
export class CompanyReferenceDataService {
  constructor(private dataContext: DataContextV2Service) {}

  getById(id: number): Observable<ResultWrapper<CompanyReferenceModel>> {
    return this.dataContext.get<CompanyReferenceModel>(
      `companyreference/${id}`
    );
  }

  getByCompanyId(
    companyId: number,
    pageIndex: number = 0,
    pageSize: number = 5,
    orderBy: string = 'Name'
  ): Observable<ResultWrapper<CompanyReferenceModel[]>> {
    return this.dataContext.get<CompanyReferenceModel[]>(
      `companyreference/company/${companyId}?pindex=${pageIndex}&psize=${pageSize}&orderby=${encodeURIComponent(
        orderBy
      )}`
    );
  }

  getByCompanyIdForOrganisation(
    companyId: number
  ): Observable<ResultWrapper<CompanyReferenceModel>> {
    return this.dataContext.get<CompanyReferenceModel>(
      `companyreference/company/${companyId}/organisation`
    );
  }

  add(
    companyReference: CompanyReferenceModel
  ): Observable<ResultWrapper<CompanyReferenceModel>> {
    return this.dataContext.post<CompanyReferenceModel>(
      `companyreference`,
      companyReference
    );
  }

  update(
    companyReference: CompanyReferenceModel
  ): Observable<ResultWrapper<CompanyReferenceModel>> {
    return this.dataContext.put<CompanyReferenceModel>(
      `companyreference/`,
      companyReference
    );
  }

  delete(id: number): Observable<ResultWrapper<CompanyReferenceModel>> {
    return this.dataContext.delete<CompanyReferenceModel>(
      `companyreference/${id}`
    );
  }

  setInvitedStatus(
    companyId: number,
    recipients: ContentDistributionRecipientModel[]
  ): Observable<ResultWrapper<CompanyReferenceModel>> {
    return this.dataContext.put<CompanyReferenceModel>(
      `companyreference/${companyId}/set-invited-status`,
      recipients ?? []
    );
  }
}
