import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService, PipeModel } from '@seahorse/common';
import { FieldType, OrderRegistrationModel } from '@seahorse/domain';
import { TableColumnModel } from '../../../layout/models/table.model';
import { EventModel } from '../../../shared/models/event.model';
import * as _ from 'underscore';

import { OrderRegistrationsDataService } from '../../services/order-registrations-data.service';

@Component({
  selector: 'ca-order-registrations-overview-list',
  templateUrl: 'order-registrations-overview-list.component.html',
})
export class OrderRegistrationsOverviewListComponent implements OnInit {
  @Input() orderRegistrations = [];

  @Input() getLatestByDeliveryDate: boolean;
  @Input() loading: boolean;
  @Input() columns: TableColumnModel[];
  @Input() selected: OrderRegistrationModel;
  @Input() showCheckbox = true;
  @Input() title?: string;

  @Output() event: EventEmitter<EventModel>;

  constructor(
    private notification: NotificationService,
    private translate: TranslateService,
    private orderRegistrationsData: OrderRegistrationsDataService
  ) {
    this.getLatestByDeliveryDate = false;
    this.loading = false;

    this.columns = [
      new TableColumnModel('checkbox', null),
      new TableColumnModel(
        'po',
        FieldType.AutoNumbering,
        this.translate.instant('customModules.orderRegistrations.model.po')
      ),
      new TableColumnModel(
        '__DataObjectKey',
        FieldType.SingleLineText,
        this.translate.instant('customModules.orderRegistrations.model.type'),
        [new PipeModel('shCustomDataDefinitionType')],
        false
      ),
      new TableColumnModel(
        'supplier',
        FieldType.LinkedObject,
        this.translate.instant(
          'customModules.orderRegistrations.model.supplier'
        ),
        [new PipeModel('keyValue', { onlyValue: true })],
        false
      ),
      new TableColumnModel(
        'deliveryDate',
        FieldType.DateTime,
        this.translate.instant(
          'customModules.orderRegistrations.model.deliveryDate'
        )
      ),
      new TableColumnModel(
        'comments',
        FieldType.MultiLineText,
        this.translate.instant(
          'customModules.orderRegistrations.model.comments'
        )
      ),
      // new TableColumnModel(
      //   'amount',
      //   FieldType.Decimal,
      //   this.translate.instant('customModules.orderRegistrations.model.amount'),
      //   null,
      //   false
      // ),
      new TableColumnModel(
        'status',
        FieldType.SingleLineText,
        this.translate.instant('customModules.orderRegistrations.model.status'),
        [new PipeModel('shOrderRegistrationStatus')],
        false
      ),
    ];

    this.selected = null;
    this.event = new EventEmitter<EventModel>();
    this.title = null;
  }

  ngOnInit() {
    if (!this.orderRegistrations || !this.orderRegistrations.length) {
      this.loadLatest();
    }
  }

  loadLatest() {
    this.loading = true;

    if (this.getLatestByDeliveryDate === true) {
      this.loadData(this.orderRegistrationsData.getUpcoming(5));
    } else {
      this.loadData(this.orderRegistrationsData.getLatest(5));
    }
  }

  loadData(query) {
    if (!query) {
      return;
    }
    query.subscribe(
      (r) => {
        if (r.hasResult) {
          this.orderRegistrations = r.result;
        }
      },

      (e) => {
        this.notification.showError(
          _.pluck(e.error.messages, 'message').join('\n'),
          this.translate.instant('shared.terms.failed')
        );
      },

      () => (this.loading = false)
    );
  }

  checkAllChanged(event) {
    for (const orderRegistration of this.orderRegistrations) {
      orderRegistration['isChecked'] = event.target.checked;
    }
    this.event.emit({ action: 'checkAll', data: this.orderRegistrations });
  }

  checkOrder(orderRegistration: OrderRegistrationModel) {
    orderRegistration.isChecked = !orderRegistration.isChecked;
    this.event.emit({ action: 'check', data: orderRegistration });
  }
}
