<div class="modal-header align-items-center">
  <h1 class="modal-title">{{ title }}</h1>
  <button closeModal type="button" class="btn btn-link text-dark">
    <i class="fa-solid fa-times fa-sm fa-fw"></i>
  </button>
</div>
<div class="modal-body">
  <div
    *ngIf="hotelStays && hotelStays.length"
    class="tablist tablist-accordion mb-3"
    id="hotelStayPicker"
    role="tablist"
    aria-multiselectable="false"
  >
    <div
      *ngFor="let hotelStay of hotelStays; let i = index"
      [id]="'sectionHeaderId_' + i"
      class="tablist-item"
    >
      <a
        [id]="'sectionLinkId_' + i"
        [href]="'#sectionContentId_' + i"
        [attr.aria-controls]="'sectionContentId_' + i"
        class="tablist-header"
        data-toggle="collapse"
        aria-expanded="false"
      >
        <div class="dropdown float-right">
          <button
            [id]="'rowMenuButton_' + i"
            type="button"
            class="btn btn-light dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis-v fa-fw"></i>
          </button>
          <div
            [attr.aria-labelledby]="'rowMenuButton_' + i"
            class="btn dropdown-menu"
          >
            <button (click)="removeHotelStay(hotelStay)" class="dropdown-item">
              <i class="fa-solid fa-trash fa-fw"></i>
              {{ 'shared.terms.delete' | translate }}
            </button>
          </div>
        </div>
        {{
          hotelStay.roomOrName
            ? hotelStay.roomOrName
            : ('shared.terms.unknown' | translate)
        }}
      </a>
      <div
        [id]="'sectionContentId_' + i"
        [attr.aria-labelledby]="'sectionHeaderId_' + i"
        class="collapse"
        data-parent="#hotelStayPicker"
      >
        <div class="form-row p-3">
          <div class="form-group col-12">
            <label
              class="d-flex justify-content-between"
              [for]="'roomOrName_' + i"
            >
              <b class="required">{{
                'customModules.orderRegistrations.hotelStay.model.roomOrName'
                  | translate
              }}</b>
            </label>
            <div class="input-group">
              <input
                [(ngModel)]="hotelStay.roomOrName"
                [id]="'roomOrName_' + i"
                [name]="'roomOrName_' + i"
                [disabled]="hotelStaysLoading"
                [placeholder]="
                  'customModules.orderRegistrations.hotelStay.placeholder.roomOrName'
                    | translate
                "
                required
                type="text"
                class="form-control"
              />
            </div>
          </div>
          <div class="form-group col-12 col-sm-6">
            <label
              class="d-flex justify-content-between"
              [for]="'checkInDate_' + i"
            >
              <b>{{
                'customModules.orderRegistrations.hotelStay.model.checkInDate'
                  | translate
              }}</b>
            </label>
            <div class="input-group">
              <temp-date-time-picker
                [dateTime]="hotelStay.checkInDate"
                [hideTime]="true"
                [isContained]="true"
                (dateTimeChange)="checkOutDateChanged($event, i)"
              ></temp-date-time-picker>
            </div>
          </div>
          <div class="form-group col-12 col-sm-6 d-flex align-items-end">
            <div class="input-group">
              <div class="custom-control custom-switch">
                <input
                  [(ngModel)]="hotelStay.earlyCheckIn"
                  [disabled]="hotelStaysLoading"
                  [id]="'earlyCheckIn_' + i"
                  [name]="'earlyCheckIn_' + i"
                  type="checkbox"
                  class="custom-control-input"
                />
                <label [for]="'earlyCheckIn_' + i" class="custom-control-label">
                  <b>{{
                    'customModules.orderRegistrations.hotelStay.placeholder.earlyCheckIn'
                      | translate
                  }}</b>
                </label>
              </div>
            </div>
          </div>
          <div class="form-group col-12 col-sm-6">
            <label
              class="d-flex justify-content-between"
              [for]="'checkOutDate_' + i"
            >
              <b>{{
                'customModules.orderRegistrations.hotelStay.model.checkOutDate'
                  | translate
              }}</b>
            </label>
            <div class="input-group">
              <temp-date-time-picker
                [dateTime]="hotelStay.checkOutDate"
                [isContained]="true"
                (dateTimeChange)="checkOutDateChanged($event, i)"
              ></temp-date-time-picker>
            </div>
          </div>
          <div class="form-group col-12 col-sm-6 d-flex align-items-end">
            <div class="input-group">
              <div class="custom-control custom-switch">
                <input
                  [(ngModel)]="hotelStay.lateCheckOut"
                  [disabled]="hotelStaysLoading"
                  [id]="'lateCheckOut_' + i"
                  [name]="'lateCheckOut_' + i"
                  type="checkbox"
                  class="custom-control-input"
                />
                <label [for]="'lateCheckOut_' + i" class="custom-control-label">
                  <b>{{
                    'customModules.orderRegistrations.hotelStay.placeholder.lateCheckOut'
                      | translate
                  }}</b>
                </label>
              </div>
            </div>
          </div>
          <div class="form-group col-12">
            <div class="input-group">
              <div class="custom-control custom-switch">
                <input
                  [(ngModel)]="hotelStay.dayroom"
                  [disabled]="hotelStaysLoading"
                  [id]="'dayroom_' + i"
                  [name]="'dayroom_' + i"
                  type="checkbox"
                  class="custom-control-input"
                />
                <label [for]="'dayroom_' + i" class="custom-control-label">
                  <b>{{
                    'customModules.orderRegistrations.hotelStay.placeholder.dayroom'
                      | translate
                  }}</b>
                </label>
              </div>
            </div>
          </div>
          <div class="form-group col-12 col-sm-4">
            <div class="input-group">
              <div class="custom-control custom-switch">
                <input
                  [(ngModel)]="hotelStay.breakfast"
                  [disabled]="hotelStaysLoading"
                  [id]="'breakfast_' + i"
                  [name]="'breakfast_' + i"
                  type="checkbox"
                  class="custom-control-input"
                />
                <label [for]="'breakfast_' + i" class="custom-control-label">
                  <b>{{
                    'customModules.orderRegistrations.hotelStay.model.breakfast'
                      | translate
                  }}</b>
                </label>
              </div>
            </div>
          </div>
          <div class="form-group col-12 col-sm-4">
            <div class="input-group">
              <div class="custom-control custom-switch">
                <input
                  [(ngModel)]="hotelStay.lunch"
                  [disabled]="hotelStaysLoading"
                  [id]="'lunch_' + i"
                  [name]="'lunch_' + i"
                  type="checkbox"
                  class="custom-control-input"
                />
                <label [for]="'lunch_' + i" class="custom-control-label">
                  <b>{{
                    'customModules.orderRegistrations.hotelStay.model.lunch'
                      | translate
                  }}</b>
                </label>
              </div>
            </div>
          </div>
          <div class="form-group col-12 col-sm-4">
            <div class="input-group">
              <div class="custom-control custom-switch">
                <input
                  [(ngModel)]="hotelStay.dinner"
                  [disabled]="hotelStaysLoading"
                  [id]="'dinner_' + i"
                  [name]="'dinner_' + i"
                  type="checkbox"
                  class="custom-control-input"
                />
                <label [for]="'dinner_' + i" class="custom-control-label">
                  <b>{{
                    'customModules.orderRegistrations.hotelStay.model.dinner'
                      | translate
                  }}</b>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mb-3 text-right">
    <button
      (click)="addHotelStay()"
      type="button"
      class="btn btn-outline-secondary"
    >
      <i class="fa-regular fa-plus fa-fw"></i>
      {{
        'customModules.orderRegistrations.hotelStay.addHotelStay' | translate
      }}
    </button>
  </div>
  <div class="mb-3 text-danger" *ngIf="errorList && errorList.length">
    <ul style="list-style-type: square">
      <li *ngFor="let error of errorList">{{ error }}</li>
    </ul>
  </div>
</div>
<div class="modal-footer">
  <button closeModal type="button" class="btn btn-link flex-fill">
    {{ 'shared.terms.cancel' | translate }}
  </button>
  <button
    [disabled]="hotelStaysLoading"
    (click)="confirm()"
    type="button"
    class="btn btn-success flex-fill"
  >
    <span *ngIf="hotelStaysLoading">
      <i class="fa-regular fa-spinner fa-spin fa-fw"></i>
    </span>
    <ng-container *ngIf="!hotelStaysLoading">
      {{ 'shared.terms.save' | translate }}
    </ng-container>
  </button>
</div>
