<section class="page-header d-flex px-3 py-1">
  <div class="input-group align-items-center">
    <div class="custom-control custom-switch">
      <input
        [(ngModel)]="history"
        (change)="onEvent.emit({ action: 'toggleHistory', data: history })"
        type="checkbox"
        class="custom-control-input"
        id="history"
      />
      <label class="custom-control-label" for="history">{{
        'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.overview.toggleHistory'
          | translate
      }}</label>
    </div>
  </div>
  <div class="d-flex align-items-center">
    <div class="input-group mr-3">
      <div class="input-group-prepend">
        <span class="input-group-text">Weergave</span>
      </div>
      <select
        class="form-control"
        [(ngModel)]="currentView"
        (change)="switchView()"
      >
        <option [value]="''">Alle overzichten</option>
        <option [value]="'inPort'">In de haven</option>
        <option [value]="'incoming'">Inkomend</option>
        <option [value]="'shifting'">Verhalend</option>
        <option [value]="'outgoing'">Uitgaand</option>
      </select>
    </div>
    <button type="button" class="btn btn-outline-secondary" (click)="print()">
      {{ 'shared.terms.print' | translate }}
    </button>
  </div>
</section>
