import { FactoryProvider, inject, InjectionToken } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomDataSearchFieldModel, SearchOperators } from '@seahorse/domain';
import { map } from 'rxjs/operators';
import { SearchQueryParams } from './search.model';

export const SEARCH_PARAMS = new InjectionToken<SearchQueryParams | null>(
  'SearchParamsProvider.'
);

export function searchParamsProviderFactory(route: ActivatedRoute) {
  const fillSearchModel = (search: string) => {
    const searchParams = search.split(',');
    return searchParams.map((s) => {
      const regexPattern = /^([^_]+)_(.+)_(\d+)$/;
      const match = s.match(regexPattern);

      if (match) {
        const [_, fieldName, searchValue, searchOperator] = match;
        return {
          fieldName: fieldName,
          searchValue: searchValue,
          searchOperator: SearchOperators[searchOperator],
        } as CustomDataSearchFieldModel;
      }

      const parts = s.split('_');
      return {
        fieldName: parts[0],
        searchValue: parts[1],
        searchOperator: SearchOperators[parts[2]],
      } as CustomDataSearchFieldModel;
    });
  };

  return route.queryParamMap.pipe(
    map((params) => {
      const pSize = params.get('pSize');
      const pView = params.get('pView');
      const simpleSearch = params.get('sSearch');
      const advancedSearch = params.get('aSearch');

      const queryParams: SearchQueryParams = {
        pSize: pSize != null ? +pSize : null,
        pView: pView != null ? +pView : null,
      };

      if (simpleSearch) {
        queryParams.sSearch = fillSearchModel(simpleSearch)[0];
      }

      if (advancedSearch) {
        queryParams.aSearch = fillSearchModel(advancedSearch);
      }

      const hasValue = Object.values(queryParams).some((x) => x !== null);
      return hasValue ? queryParams : null;
    })
  );
}

export const searchParamsProvider: FactoryProvider = {
  provide: SEARCH_PARAMS,
  useFactory: () => searchParamsProviderFactory(inject(ActivatedRoute)),
};
