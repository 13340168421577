import { inject } from '@angular/core';
import { ProxyServices } from '../../core/services/proxy.service';

export const navigateToPfcProcedure = () => {
  const proxy = inject(ProxyServices);

  return (id: string) => {
    if (!id) {
      return;
    }

    const definition = proxy.getObjectDefinitionByMappingKey(
      '$customcontent_ct-portcallfile'
    );

    if (!definition || definition.baseObjectDefinitionId) {
      window.open(`port-call-file/${id}`, '_blank');
    } else {
      window.open(`/module/portcallfile/details/${id}`, '_blank');
    }
  };
};
