<form #addressForm="ngForm">
  <div class="form-group">
    <label class="control-label required"
      ><b>{{ 'companies.address.model.type' | translate }}</b></label
    >
    <ng-select
      required
      #type
      name="type"
      [items]="addressTypes"
      (change)="changeAddressType($event)"
      placeholder="{{ 'companies.address.selectType' | translate }}"
      bindValue="value"
      [clearable]="false"
      [searchable]="false"
      [(ngModel)]="model.type"
    >
    </ng-select>
    <small *ngIf="submitted && model.type == undefined" class="text-danger">
      {{ 'companies.address.errors.typeEmpty' | translate }}</small
    >
  </div>

  <div *ngIf="model.type">
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label class="control-label required"
            ><b>{{ 'companies.address.model.name' | translate }}</b></label
          >
          <input
            required
            #name="ngModel"
            type="text"
            name="name"
            [(ngModel)]="model.name"
            class="form-control"
          />
          <small
            *ngIf="submitted && (name.value == null || name.value === '')"
            class="text-danger"
          >
            {{ 'companies.address.errors.nameEmpty' | translate }}</small
          >
        </div>
      </div>
      
      <div class="col-6 primary-button">
        <div class="custom-control custom-switch" *ngIf="model.type === AddressTypes.Postal">
          <input [(ngModel)]="model.isPrimary" type="checkbox" class="custom-control-input" id="isPrimary" name="isPrimary" />
          <label for="isPrimary" class="custom-control-label cursor-pointer">
            {{ 'companies.address.model.isPrimary' | translate }}
          </label>
        </div>
      </div>

      <div class="col-6" *ngIf="model.type !== AddressTypes.Postal">
        <div class="form-group">
          <label class="control-label required"
            ><b>{{ 'companies.address.model.value' | translate }}</b></label
          >
          <input
            required
            #value="ngModel"
            type="text"
            name="value"
            [(ngModel)]="model.value"
            class="form-control"
          />
          <small
            *ngIf="submitted && (value.value == null || value.value === '')"
            class="text-danger"
          >
            {{ 'companies.address.errors.valueEmpty' | translate }}</small
          >
        </div>
      </div>
    </div>

    <div class="row" *ngIf="model.type === AddressTypes.Postal">
      <div class="col-6">
        <div class="form-group">
          <label class="control-label required"
            ><b>{{ 'companies.address.addressLine' | translate }} 1</b></label
          >
          <input
            required
            #address1="ngModel"
            name="addressLine1"
            [(ngModel)]="model.postalModel.addressLine1"
            class="form-control"
          />
          <small
            *ngIf="
              submitted && (address1.value == null || address1.value == '')
            "
            class="text-danger"
          >
            {{ 'companies.address.errors.addressLine' | translate }}</small
          >
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label class="control-label"
            ><b>{{ 'companies.address.addressLine' | translate }} 2</b></label
          >
          <input
            name="addressLine2"
            [(ngModel)]="model.postalModel.addressLine2"
            class="form-control"
          />
        </div>
      </div>
    </div>

    <div class="row" *ngIf="model.type === AddressTypes.Postal">
      <div class="col-6">
        <div class="form-group">
          <label class="control-label"
            ><b>{{ 'companies.address.zip' | translate }}</b></label
          >
          <input
            name="zip"
            [(ngModel)]="model.postalModel.zip"
            class="form-control"
          />
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label class="control-label"
            ><b>{{ 'companies.address.city' | translate }}</b></label
          >
          <input
            name="city"
            [(ngModel)]="model.postalModel.city"
            class="form-control"
          />
        </div>
      </div>
    </div>

    <div class="row" *ngIf="model.type === AddressTypes.Postal">
      <div class="col-6">
        <div class="form-group">
          <label class="control-label"
            ><b>{{ 'companies.address.state' | translate }}</b></label
          >
          <input
            name="state"
            [(ngModel)]="model.postalModel.state"
            class="form-control"
          />
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label class="control-label"
            ><b>{{ 'shared.addressForm.model.country' | translate }}</b></label
          >
          <ng-container *ngIf="!isCountryLoading">
            <ng-select
              name="country"
              [items]="countries"
              placeholder="Select country"
              bindLabel="name"
              bindValue="isO2"
              [searchable]="true"
              [(ngModel)]="model.postalModel.country"
            ></ng-select>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="form-group" [hidden]="!showContactField">
      <label
        ><b>{{ 'companies.contact.contact' | translate }}</b></label
      >:
      <ng-select
        name="contact"
        [items]="contacts"
        [(ngModel)]="model.contactId"
        bindValue="id"
        bindLabel="nameInfo"
        placeholder="{{ 'companies.address.selectContact' | translate }}"
      >
      </ng-select>
    </div>
  </div>
</form>
