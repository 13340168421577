import { NgModule } from '@angular/core';

import { NauticalPortWaypointDataService } from './nautical-port-waypoint.data.service';
import { NauticalVisitFlowUtilityService } from './nautical-visit-flow-utility.service';
import { NauticalVisitInteractionService } from './nautical-visit-interaction.service';

@NgModule({
  providers: [
    NauticalPortWaypointDataService,
    NauticalVisitFlowUtilityService,
    NauticalVisitInteractionService,
  ],
})
export class NauticalVisitServicesModule {}
