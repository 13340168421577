import { Component, Input, Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'ca-icon-picker-grid',
  templateUrl: './icon-picker-grid.component.html',
  styleUrls: ['./icon-picker-grid.component.scss'],
})
export class IconPickerGridComponent {
  @Input() icons = [
    'fa-star',
    'fa-cube',
    'fa-link',
    'fa-calendar',
    'fa-file-alt',
    'fa-suitcase',
    'fa-briefcase',
    'fa-address-card',
    'fa-shopping-cart',
    'fa-barcode',
    'fa-calculator',
    'fa-anchor',
    'fa-bus',
    'fa-car',
    'fa-bicycle',
    'fa-taxi',
    'fa-ambulance',
    'fa-plane',
    'fa-subway',
    'fa-industry',
    'fa-university',
    'fa-building',
    'fa-binoculars',
    'fa-beer',
  ];

  @Input() selectedIcon: string;
  @Output() selectedIconChange = new EventEmitter<string>();
}
