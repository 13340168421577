import * as mapboxgl from 'mapbox-gl';

export class MapAreaModel {
  bounds: mapboxgl.LngLat[];
  name: string;
}

export class FitBoundsModel {
  bounds: mapboxgl.LngLat[];
  options?: any;
}
