<div class="card" *ngIf="user">
  <sh-loading-indicator
    *ngIf="isLoading"
    class="loading-indicator-overlay"
  ></sh-loading-indicator>
  <div class="card-header">
    <span *ngIf="currentForm === 0">
      {{ 'user.userProfile' | translate }}
    </span>
    <span *ngIf="currentForm === 1">
      {{ 'user.changePassword' | translate }}
    </span>
  </div>

  <div class="card-body">
    <!-- User profile -->
    <div *ngIf="currentForm === 0">
      <user-form [model]="user" [submitted]="isSubmitted"></user-form>

      <div class="form-group alert alert-danger" *ngIf="usernameExists">
        {{ 'user.errors.usernameExists' | translate }}
      </div>

      <div class="row">
        <div class="col">
          <button class="btn btn-primary" (click)="updateProfile()">
            {{ 'user.updateProfile' | translate }}
          </button>
        </div>
        <div class="col text-right">
          <a
            [routerLink]="'/dashboard/settings'"
            class="btn btn-outline-secondary ml-1"
            >{{ 'dashboard.settings' | translate }}</a
          >
          <button class="btn btn-outline-secondary ml-1">
            <a (click)="currentForm = 1"
              >{{ 'user.changePassword' | translate }}?</a
            >
          </button>
        </div>
      </div>
    </div>

    <!-- Change password -->
    <form
      *ngIf="currentForm === 1"
      #changePasswordForm="ngForm"
      [mustMatch]="['newPassword', 'confirmPassword']"
      (ngSubmit)="
        changePasswordForm.submitted &&
          changePasswordForm.valid &&
          changePassword()
      "
    >
      <div class="form-group">
        <label class="control-label required">{{
          'user.model.oldPassword' | translate
        }}</label>
        <input
          required
          class="form-control"
          name="oldPassword"
          type="password"
          #oldPassword="ngModel"
          [(ngModel)]="changePasswordModel.oldPassword"
        />
      </div>

      <div class="form-group">
        <label class="control-label required">{{
          'user.model.newPassword' | translate
        }}</label>
        <input
          required
          pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}"
          class="form-control"
          name="newPassword"
          type="password"
          #newPassword="ngModel"
          [(ngModel)]="changePasswordModel.newPassword"
        />
        <span
          *ngIf="changePasswordForm.submitted && newPassword.errors?.required"
          class="text-danger"
        >
          {{ 'user.errors.passwordEmpty' | translate }}</span
        >
        <span *ngIf="newPassword.errors?.pattern" class="text-danger">
          {{ 'user.errors.passwordPattern' | translate }}</span
        >
      </div>

      <div class="form-group">
        <label class="control-label required" for="confirmPassword">{{
          'user.confirmPassword' | translate
        }}</label>
        <input
          required
          class="form-control"
          name="confirmPassword"
          type="password"
          #confirmPassword="ngModel"
          [(ngModel)]="changePasswordModel.confirmPassword"
        />
        <span
          *ngIf="
            changePasswordForm.submitted && confirmPassword.errors?.required
          "
          class="text-danger"
        >
          {{ 'user.errors.passwordEmpty' | translate }}</span
        >
        <span *ngIf="confirmPassword.errors?.mustMatch" class="text-danger">{{
          'user.errors.passwordsMustMatch' | translate
        }}</span>
      </div>

      <div class="form-group alert alert-danger" *ngIf="!passwordChanged">
        {{ 'user.errors.passwordWrong' | translate }}
      </div>

      <button
        type="submit"
        class="btn btn-primary"
        [disabled]="!changePasswordForm.valid"
      >
        {{ 'user.changePassword' | translate }}
      </button>
      <div class="float-right">
        <a (click)="currentForm = 0">{{ 'user.backToProfile' | translate }}</a>
      </div>
    </form>
  </div>
</div>
