<div class="modal-header align-items-center">
  <h1 class="modal-title">{{ 'user.user' | translate | uppercase }}</h1>
  <button
    aria-label="Close"
    (click)="onButtonClick('dismiss')"
    type="button"
    class="close"
  >
    <span aria-hidden="true">×</span>
  </button>
</div>

<div class="modal-body">
  <!-- Member picker -->
  <div class="d-flex mb-3">
    <div
      class="btn-group mr-2"
      title="{{ 'shared.terms.all' | translate }}"
      *ngIf="allowMultiple === true"
    >
      <div class="input-group-prepend">
        <div class="input-group-text">
          <input
            id="checkboxAllUsers"
            [checked]="isAllUserChecked"
            (change)="selectAll(!isAllUserChecked)"
            type="checkbox"
          />
        </div>
      </div>
    </div>
    <div class="input-group ml-1">
      <div class="input-group-prepend">
        <span class="input-group-text">
          <i class="fa-regular fa-search fa-fw fa-sm"></i>
        </span>
      </div>
      <input
        [(ngModel)]="searchField"
        [placeholder]="'shared.terms.search' | translate"
        type="text"
        name="searchBox"
        class="form-control"
      />
    </div>
  </div>
  <div style="max-height: 240px; overflow-y: auto" class="border rounded">
    <ng-container
      *ngIf="!loadUsers; then userPicker; else loading"
    ></ng-container>

    <ng-template #userPicker>
      <table class="table table-borderless table-hover mb-0">
        <tr
          *ngFor="
            let user of allUsers | searchUsers: searchField;
            let i = index
          "
        >
          <td scope="row">
            <div class="custom-control custom-checkbox">
              <input
                [id]="'checkbox-userPicker-' + i"
                [checked]="selectedUserIds.indexOf(user.id) > -1"
                (change)="selectUser(user.id)"
                type="checkbox"
                class="custom-control-input"
              />
              <label
                [for]="'checkbox-userPicker-' + i"
                class="custom-control-label w-100"
              >
                {{ user.givenName }}
                {{ user.surname }}
              </label>
            </div>
          </td>
        </tr>
      </table>
    </ng-template>

    <ng-template #loading>
      <sh-loading-indicator
        class="d-flex justify-content-center align-items-center h-100"
      ></sh-loading-indicator>
    </ng-template>
  </div>
</div>

<div class="modal-footer">
  <button
    (click)="onButtonClick('dismiss')"
    type="button"
    class="btn btn-link w-50"
  >
    {{ 'shared.terms.cancel' | translate }}
  </button>
  <button
    (click)="onButtonClick('ok')"
    type="button"
    class="btn btn-success w-50"
  >
    {{ 'shared.terms.ok' | translate }}
  </button>
</div>
