<div class="modal-header align-items-center">
  <h1 class="modal-title">{{ title }}</h1>
  <button closeModal type="button" class="btn btn-link text-dark">
    <i class="fa-solid fa-times fa-sm fa-fw"></i>
  </button>
</div>
<div class="modal-body">
  <div
    class="tablist tablist-accordion mb-3"
    id="memberPicker"
    role="tablist"
    aria-multiselectable="false"
  >
    <div
      class="tablist-item"
      [attr.id]="'sectionHeaderId_' + i"
      *ngFor="let formModel of formModels; let i = index"
    >
      <a
        class="tablist-header"
        data-toggle="collapse"
        [attr.href]="'#sectionContentId_' + i"
        [attr.aria-expanded]="i === 0 ? 'true' : 'false'"
        [attr.aria-controls]="'sectionContentId_' + i"
      >
        <div class="dropdown float-right">
          <button
            type="button"
            class="btn btn-light dropdown-toggle"
            id="rowMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis-v fa-fw"></i>
          </button>
          <div class="btn dropdown-menu" aria-labelledby="rowMenuButton">
            <button class="dropdown-item" (click)="deleteCrewMember(formModel)">
              <i class="fa-solid fa-trash fa-fw"></i>
              {{ 'shared.terms.delete' | translate }}
            </button>
          </div>
        </div>
        <div>
          {{
            formModel.value.name
              ? formModel.value.name
              : ('customModules.orderRegistrations.crewMember.newCrewMember'
                | translate)
          }}
        </div>
      </a>
      <div
        [attr.id]="'sectionContentId_' + i"
        class="p-3 collapse in"
        data-parent="#memberPicker"
        [attr.aria-labelledby]="'sectionHeaderId_' + i"
        [ngClass]="{ show: formModel.value.expanded }"
      >
        <temp-form [model]="formModel"></temp-form>
      </div>
    </div>
  </div>

  <!-- 
  <table class="table table-clickable border mb-3" *ngIf="crewMembers && crewMembers.length > 0">
    <tbody>
      <ng-container *ngFor="let crewMember of crewMembers; let i = index;">
        <tr (click)="selectedMember = i">
          <td>{{ crewMember.name }}</td>
          <td class="btn-list">
            <div class="dropdown p-0">
              <button type="button" class="btn btn-light dropdown-toggle" id="rowMenuButton" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                <i class="fa-solid fa-ellipsis-v fa-fw"></i>
              </button>
              <div class="btn dropdown-menu" aria-labelledby="rowMenuButton">
                <button class="dropdown-item" (click)="deleteCrewMember(crewMember)">
                  <i class="fa-solid fa-trash fa-fw"></i>
                  {{ 'shared.terms.delete' | translate }}
                </button>
              </div>
            </div>
          </td>
        </tr>
        <tr *ngIf="selectedMember === i">
          <td colspan="2">
            Selected!!!!
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table> -->

  <div class="mb-3 text-right">
    <button
      type="button"
      class="btn btn-outline-secondary"
      (click)="addCrewMember()"
    >
      <i class="fa-regular fa-plus fa-fw"></i>
      {{
        'customModules.orderRegistrations.crewMember.addCrewMember' | translate
      }}
    </button>
  </div>

  <div class="mb-3 text-danger" *ngIf="errors && errors.length > 0">
    <ul style="list-style-type: square">
      <li *ngFor="let error of errors">{{ error | translate }}</li>
    </ul>
  </div>
</div>
<div class="modal-footer">
  <button closeModal type="button" class="btn btn-link flex-fill">
    {{ 'shared.terms.cancel' | translate }}
  </button>
  <button
    [disabled]="crewMembersLoading"
    (click)="confirm()"
    type="button"
    class="btn btn-success flex-fill"
  >
    <span *ngIf="crewMembersLoading">
      <i class="fa-regular fa-spinner fa-spin fa-fw"></i>
    </span>
    <ng-container *ngIf="!crewMembersLoading">
      {{ 'shared.terms.save' | translate }}
    </ng-container>
  </button>
</div>
