<ng-container
  *ngIf="parentCompanies.length; then selectCompany; else noCompanies"
></ng-container>

<ng-template #selectCompany>
  <div class="input-group mb-3">
    <select
      [(ngModel)]="parentCompanyId"
      required
      id="parentCompany"
      name="parentCompany"
      class="custom-select"
    >
      <option
        *ngFor="let parentCompany of parentCompanies"
        [ngValue]="parentCompany.id"
      >
        {{ parentCompany.name }}
      </option>
    </select>
    <div class="input-group-append">
      <button
        (click)="parentCompanyId = null"
        type="button"
        class="btn btn-outline-danger"
      >
        <i class="fa-solid fa-trash fa-sm fa-fw"></i>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #noCompanies>
  {{ 'companies.company.noCompaniesToAdd' | translate }}
</ng-template>
