import { Pipe, PipeTransform } from '@angular/core';

import { Guid } from 'guid-typescript';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'underscore';

import { TranslateService } from '@ngx-translate/core';
import { ResultWrapper } from '@seahorse/common';
import { UserModel } from '@seahorse/domain';
import { UserDataService } from '../../user/services/user-data.service';

@Pipe({ name: 'nameFromUserId' })
export class NameFromUserIdPipe implements PipeTransform {
  constructor(
    private translate: TranslateService,
    private userDataService: UserDataService
  ) {}

  transform(
    input: any,
    display?: string,
    separator?: string
  ): Observable<string> {
    if (Array.isArray(input)) {
      const users: Observable<string>[] = [];
      for (const id of input) {
        const observable = this.getUserName(id, display);
        if (observable !== null) {
          users.push(observable);
        }
      }
      return new Observable<string>((subscriber) => {
        forkJoin(users).subscribe((result) => {
          subscriber.next(result.join(separator ? separator : ', '));
          subscriber.complete();
        });
      });
    } else {
      return this.getUserName(input, display);
    }
  }

  getUserName(userId: UserModel['id'], display?: string) {
    if (userId && Guid.isGuid(userId)) {
      return this.userDataService.get(userId).pipe(
        map((userGetResponse: ResultWrapper<any>) => {
          if (userGetResponse && userGetResponse.hasResult) {
            if (userGetResponse.result) {
              switch (display) {
                case 'initials':
                  return (
                    _.map(
                      userGetResponse.result.givenName.split(' '),
                      (u) => u[0]
                    )
                      .join('.')
                      .toUpperCase() + '.'
                  );
                case 'abbr_full':
                  return _.map(
                    (
                      userGetResponse.result.givenName +
                      ' ' +
                      userGetResponse.result.surname
                    ).split(' '),
                    (u) => u[0]
                  ).join('');
                case 'abbr_firstname':
                  return (
                    _.map(
                      userGetResponse.result.givenName.split(' '),
                      (u) => u[0]
                    )
                      .join('.')
                      .toUpperCase() +
                    '. ' +
                    userGetResponse.result.surname
                  );
                case 'abbr_lastname':
                  return (
                    (userGetResponse.result.givenName
                      ? userGetResponse.result.givenName + ' '
                      : '') +
                    _.map(
                      userGetResponse.result.surname.split(' '),
                      (u) => u[0]
                    ).join('')
                  );
                default:
                  return (
                    (userGetResponse.result.givenName
                      ? userGetResponse.result.givenName + ' '
                      : '') + userGetResponse.result.surname
                  );
              }
            } else {
              // id is correct and response is ok. it means user is deleted
              return '(' + this.translate.instant('user.deleted') + ')';
            }
          }

          return null;
        })
      );
    }

    return null;
  }
}
