import {
  Component,
  inject,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  CustomDataSearchFieldModel,
  FieldType,
  ObjectDefinitionModel,
  SearchOperators,
} from '@seahorse/domain';
import { ExportTemplateModel } from '../../models/export-template.model';
import {
  CustomDataFormService,
  FormComponent,
  FormField,
  FormModel,
  SeahorseTempModule,
} from '@seahorse/temp';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ProxyServices } from '../../../core/services/proxy.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'ca-export-filter-options-modal',
  templateUrl: 'export-filter-options-modal.component.html',
  standalone: true,
  imports: [SeahorseTempModule, FormComponent, TranslateModule, CommonModule],
})
export class ExportFilterOptionsModalComponent implements OnInit {
  @ViewChild(FormComponent, { static: true }) formRef: FormComponent;
  @ViewChildren(FormComponent) formRefs: QueryList<FormComponent>;

  @Input() exportTemplate: ExportTemplateModel;
  searchFields: CustomDataSearchFieldModel[] = [];
  requiredDataSearchFields: {
    [key: string]: CustomDataSearchFieldModel[];
  } = {};
  formModels: Record<string, { formModel: FormModel; name: string }> = {};

  private _objectDefinition: ObjectDefinitionModel;
  @Input() set objectDefinition(val: ObjectDefinitionModel) {
    if (val !== undefined && val !== null) {
      this._objectDefinition = JSON.parse(JSON.stringify(val));
    } else {
      this._objectDefinition = null;
    }
  }

  get objectDefinition() {
    return this._objectDefinition;
  }

  private get hasFilters(): boolean {
    return this.exportTemplate.templateConfiguration.filtering?.length > 0;
  }

  private get shouldSkipFirstForm(): boolean {
    return (
      this.hasFilters &&
      this.exportTemplate.templateConfiguration.filtering.some(
        (filter) => filter.userInput === true
      )
    );
  }

  formModel: FormModel;

  private _customDataFormService = inject(CustomDataFormService);
  private _translateService = inject(TranslateService);
  private _proxyService = inject(ProxyServices);

  ngOnInit(): void {
    if (!this.searchFields) {
      this.searchFields = [];
    }
    this.setUpFilter();
    this.setUpRequiredDataFilter();
  }

  private setUpFilter() {
    const searchFields = this.createSearchFields(
      this.exportTemplate.templateConfiguration.filtering,
      this.objectDefinition
    );

    if (searchFields?.length) {
      this.formModel = this.objectDefinition
        ? this._customDataFormService.getFormModel(
            this.objectDefinition,
            searchFields
          )
        : this.createSimpleFormModel(searchFields);

      this.formModel.options = { noValidate: true };
    }
  }

  private setUpRequiredDataFilter() {
    if (this.exportTemplate.templateConfiguration.requiredData) {
      this.exportTemplate.templateConfiguration.requiredData
        .filter(
          (requiredData) =>
            requiredData.filtering && requiredData.filtering.length > 0
        )
        .forEach((requiredData) => {
          const temp = this._proxyService.getObjectDefinitionByMappingKey(
            this.adaptMappingKey(requiredData.mappingKey)
          );
          const objectDefinition = temp
            ? JSON.parse(JSON.stringify(temp))
            : null;

          const requiredDataFields = this.createSearchFields(
            requiredData.filtering,
            objectDefinition
          );

          if (objectDefinition) {
            this.formModels[requiredData.mappingKey] = {
              formModel: this._customDataFormService.getFormModel(
                objectDefinition,
                requiredDataFields
              ),
              name: objectDefinition.name,
            };
          } else {
            this.formModels[requiredData.mappingKey] = {
              formModel: this.createSimpleFormModel(requiredDataFields),
              name: requiredData.mappingKey,
            };
          }

          this.formModels[requiredData.mappingKey].formModel.options = {
            noValidate: true,
          };
        });
    }
  }

  private createSearchFields(filters: any[], objectDefinition: any) {
    const fieldCodes: string[] = [];

    if (filters) {
      filters
        .filter((filter) => filter.userInput)
        .forEach((filter) => {
          if (filter.searchOperator === SearchOperators.Between) {
            const sourceField = objectDefinition?.objectFieldDefinitions.find(
              (x) => x.systemCode === filter.fieldName
            );

            if (sourceField) {
              const fromCode = `${
                filter.fieldName
              }${this._translateService.instant('shared.terms.from')}`;
              const toCode = `${
                filter.fieldName
              }${this._translateService.instant('shared.terms.to')}`;

              const codes = [fromCode, toCode];

              codes.forEach((code, index) => {
                const fieldDefinition = {
                  ...sourceField,
                  systemCode: code,
                  name: `${sourceField.name} ${this._translateService.instant(
                    index === 0 ? 'shared.terms.from' : 'shared.terms.to'
                  )}`,
                };
                objectDefinition.objectFieldDefinitions.push(fieldDefinition);
                fieldCodes.push(code);
              });
            } else {
              fieldCodes.push(filter.fieldName);
            }
          } else {
            fieldCodes.push(filter.fieldName);
          }
        });
    }

    return fieldCodes;
  }

  confirm() {
    const formRefsArray = this.formRefs.toArray();

    // Process filters
    if (this.hasFilters) {
      this.exportTemplate.templateConfiguration.filtering.forEach((filter) => {
        if (!filter.userInput) {
          this.searchFields.push(filter);
          return;
        }

        const value = formRefsArray[0].submit();
        this.processFilter(filter, value, this.searchFields);
      });
    }

    // Process required data filters
    this.formRefs.forEach((formRef, index) => {
      if (this.shouldSkipFirstForm && index === 0) {
        return;
      }

      const formValue = formRef.submit();
      const mappingKey = Object.keys(this.formModels)[
        this.shouldSkipFirstForm ? index - 1 : index
      ];
      const requiredDataSearchFields = [];

      if (this.exportTemplate.templateConfiguration.requiredData) {
        this.exportTemplate.templateConfiguration.requiredData.forEach(
          (requiredData) => {
            if (
              requiredData.mappingKey === mappingKey &&
              requiredData.filtering
            ) {
              requiredData.filtering.forEach((filter) => {
                if (!filter.userInput) {
                  requiredDataSearchFields.push(filter);
                  return;
                }

                const objectDefinition =
                  this._proxyService.getObjectDefinitionByMappingKey(
                    this.adaptMappingKey(mappingKey)
                  );
                const temp = objectDefinition
                  ? JSON.parse(JSON.stringify(objectDefinition))
                  : null;

                this.processFilter(
                  filter,
                  formValue,
                  requiredDataSearchFields,
                  temp
                );
              });
            }
          }
        );
      }

      this.requiredDataSearchFields[mappingKey] = requiredDataSearchFields;
    });

    return {
      searchFields: this.searchFields,
      requiredDataSearchFields: this.requiredDataSearchFields,
    };
  }

  private processFilter(
    filter: CustomDataSearchFieldModel,
    formValue: object,
    searchFields: CustomDataSearchFieldModel[],
    objectDefinition?: ObjectDefinitionModel
  ) {
    if (filter.searchOperator === SearchOperators.Between) {
      const fieldCodes = [
        `${filter.fieldName}${this._translateService.instant(
          'shared.terms.from'
        )}`,
        `${filter.fieldName}${this._translateService.instant(
          'shared.terms.to'
        )}`,
      ];

      const [valueFrom, valueTo] = objectDefinition
        ? [formValue[fieldCodes[0]], formValue[fieldCodes[1]]]
        : [formValue[filter.fieldName], null];

      const baseSearchField = {
        fieldName: filter.fieldName,
        searchColumn: filter.searchColumn,
        searchInCustomContent: filter.searchInCustomContent,
      } as CustomDataSearchFieldModel;

      if (valueFrom != null && valueTo != null) {
        searchFields.push({
          ...baseSearchField,
          searchOperator: SearchOperators.Between,
          searchValue: `${valueFrom},${valueTo}`,
        });
      } else if (valueFrom != null) {
        searchFields.push({
          ...baseSearchField,
          searchOperator: SearchOperators.GreaterThan,
          searchValue: valueFrom,
        });
      } else if (valueTo != null) {
        searchFields.push({
          ...baseSearchField,
          searchOperator: SearchOperators.LesserThan,
          searchValue: valueTo,
        });
      }
    } else {
      searchFields.push({
        fieldName: filter.fieldName,
        searchColumn: filter.searchColumn,
        searchInCustomContent: filter.searchInCustomContent,
        searchOperator: filter.searchOperator,
        searchValue: formValue[filter.fieldName],
      });
    }
  }

  private createSimpleFormModel(fields: string[]): FormModel {
    const formFields: FormField[] = fields.map((field) => ({
      key: field,
      name: field,
      type: FieldType.SingleLineText,
      isRequired: false,
      isDisabled: false,
      groupName: null,
    }));

    return {
      fields: formFields,
      value: {},
    };
  }

  adaptMappingKey(mappingKey: string): string {
    const CUSTOMCONTENT_PREFIX = '$customcontent';
    const CT_SUFFIX = '_ct';

    if (mappingKey.startsWith(CUSTOMCONTENT_PREFIX)) {
      const parts = mappingKey.split('_');
      parts.pop();

      let baseKey = parts.join('_');
      if (!baseKey.includes(CT_SUFFIX)) {
        baseKey = baseKey.replace(
          CUSTOMCONTENT_PREFIX,
          `${CUSTOMCONTENT_PREFIX}${CT_SUFFIX}`
        );
      }

      baseKey = baseKey.replace(
        `${CUSTOMCONTENT_PREFIX}${CT_SUFFIX}_`,
        `${CUSTOMCONTENT_PREFIX}${CT_SUFFIX}-`
      );

      return baseKey;
    }

    return mappingKey;
  }
}
