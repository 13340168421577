import { BaseModel } from '@seahorse/common';
import { ValidationResult } from '@seahorse/common';

import { CountryModel } from './country.model';
import {
  CoordinatePointModel,
  CoordinatePolygonModel,
} from '../../nautical-geography/models/coordinate.model';

export class PortModel extends BaseModel {
  countryId: number;
  country: CountryModel;
  centerPoint: CoordinatePointModel;
  boundary: CoordinatePolygonModel;
  name: string;
  systemCode: string;
  unloCode: string;

  hasCenterpoint: boolean;
  hasPolygon: boolean;

  static validate(port: PortModel) {
    if (!port.name || !port.systemCode || !port.countryId) {
      return false;
    }

    port.name = port.name.trim();
    if (port.name === '') {
      port.name = null;
      return false;
    }

    port.systemCode = port.systemCode.trim();
    if (port.systemCode === '') {
      port.systemCode = null;
      return false;
    }

    return true;
  }

  validate(errorPrefix?: string): ValidationResult {
    const result: ValidationResult = new ValidationResult();

    if (!errorPrefix) {
      errorPrefix = '';
    }

    if (this.name == null) {
      result.errors.push(errorPrefix + 'Name is required!');
    }

    this.name = this.name.trim();
    if (this.name === '') {
      this.name = null;
      result.errors.push(errorPrefix + 'Name is required!');
    }

    if (this.systemCode == null) {
      result.errors.push(errorPrefix + 'System code is required!');
    }

    this.systemCode = this.systemCode.trim();
    if (this.systemCode === '') {
      this.systemCode = null;
      result.errors.push(errorPrefix + 'System code is required!');
    }

    if (this.countryId === undefined) {
      result.errors.push(errorPrefix + 'Country is required!');
    }

    result.isValid = result.errors.length === 0;
    return result;
  }
}
