import { ModuleWithProviders, NgModule } from '@angular/core';
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateModule,
} from '@ngx-translate/core';
import { translateLoaderProvider } from './translate-loader.provider';
import { TRANSLATION_PATH } from './translation.tokens';

@NgModule()
export class SeahorseTranslateModule {
  static forRoot(): ModuleWithProviders<TranslateModule> {
    const module = TranslateModule.forRoot({
      loader: translateLoaderProvider,
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: SeahorseMissingTranslationHandler,
      },
    });

    return {
      ...module,
      providers: [
        ...(module.providers ?? []),
        {
          provide: TRANSLATION_PATH,
          useValue: 'assets/i18n',
          multi: true,
        },
        {
          provide: TRANSLATION_PATH,
          useValue: 'assets/i18n/shared',
          multi: true,
        }
      ],
    };
  }

  static forChild(path: string): ModuleWithProviders<TranslateModule> {
    const module = TranslateModule.forRoot({
      loader: translateLoaderProvider,
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: SeahorseMissingTranslationHandler,
      },
      isolate: true, // forbid access to parent module translations
    });

    return {
      ...module,
      providers: [
        ...(module.providers ?? []),
        {
          provide: TRANSLATION_PATH,
          useValue: path,
          multi: true,
        },
      ],
    };
  }
}

export class SeahorseMissingTranslationHandler
  implements MissingTranslationHandler
{
  handle(params: MissingTranslationHandlerParams) {
    // If the original is an actual translation key, we'll just return the last part
    if (
      params.key &&
      params.key.indexOf('.') > -1 &&
      params.key.indexOf(' ') === -1
    ) {
      const values = params.key.split('.');
      return values[values.length - 1];
    }
    return params.key;
  }
}
