import { Injectable } from '@angular/core';
import { DataContextService, ResultWrapper } from '@seahorse/common';
import { Observable } from 'rxjs';
import { ExpenseConfigurationModel } from '../models/expense-configuration.model';
import { DataService } from '@seahorse/domain';
import { Guid } from 'guid-typescript';

@Injectable({
  providedIn: 'root',
})
export class ExpenseConfigurationDataService
  implements DataService<ExpenseConfigurationModel>
{
  constructor(private dataContext: DataContextService) {}
  getById(id: number): Observable<ResultWrapper<ExpenseConfigurationModel>> {
    return this.dataContext.get<ExpenseConfigurationModel>(
      `expense-configuration/${id}`
    );
  }
  add(
    model: ExpenseConfigurationModel
  ): Observable<ResultWrapper<ExpenseConfigurationModel>> {
    return this.dataContext.post<ExpenseConfigurationModel>(
      `expense-configuration/`,
      model
    );
  }
  update(
    model: ExpenseConfigurationModel
  ): Observable<ResultWrapper<ExpenseConfigurationModel>> {
    return this.dataContext.put<ExpenseConfigurationModel>(
      `expense-configuration/`,
      model
    );
  }
  delete(
    id: number | Guid
  ): Observable<ResultWrapper<ExpenseConfigurationModel>> {
    return this.dataContext.delete<ExpenseConfigurationModel>(
      `expense-configuration/${id}`
    );
  }

  search(
    searchQuery?: string,
    pageIndex?: number,
    pageSize?: number
  ): Observable<ResultWrapper<ExpenseConfigurationModel[]>> {
    const queryParams = [];

    if (searchQuery !== undefined && searchQuery !== null) {
      queryParams.push(`searchTerm=${encodeURIComponent(searchQuery)}`);
    }

    if (pageIndex !== undefined && pageIndex !== null) {
      queryParams.push(`pindex=${pageIndex}`);
    }

    if (pageSize !== undefined && pageSize !== null) {
      queryParams.push(`psize=${pageSize}`);
    }

    const queryString = queryParams.join('&');
    const url = `expense-configuration/search${
      queryString ? '?' + queryString : ''
    }`;

    return this.dataContext.get<ExpenseConfigurationModel[]>(url);
  }
}
