import { OnInit, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'underscore';

import { PreferenceModel } from '../../../../preference/models/preference.model';

import { IdentityService } from '../../../../core/services/identity.service';

@Component({
  selector: 'user-language-modal',
  templateUrl: './user-language-modal.component.html',
})
export class UserLanguageModalComponent implements OnInit {
  private preferenceName = 'language';
  isSubmitted = false;

  @Input() language: PreferenceModel;

  constructor(
    private modalService: NgbActiveModal,
    private identityService: IdentityService
  ) {}

  ngOnInit() {
    if (!this.language) {
      this.language = _.find(
        this.identityService.getPreferences(),
        (preference) => {
          return (
            this.preferenceName.toLowerCase() === preference.name.toLowerCase()
          );
        }
      );

      if (!this.language) {
        this.language = new PreferenceModel();
        this.language.id = 0;
        this.language.name = this.preferenceName;
        this.language.fieldType = 1;
      }
    }
  }

  save() {
    if (!PreferenceModel.validate(this.language)) {
      this.isSubmitted = true;
      return;
    }

    this.modalService.close(this.language);
  }
}
