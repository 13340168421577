import { NgModule } from '@angular/core';

import { NauticalShipInteractionService } from './nautical-ship-interaction.service';
import { NauticalShipLogicService } from './nautical-ship-logic.service';
import { NauticalShipTagDataService } from './nautical-ship-tag-data.service';

@NgModule({
  providers: [
    NauticalShipInteractionService,
    NauticalShipTagDataService,
    NauticalShipLogicService,
  ],
})
export class NauticalShipServicesModule {}
