import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SystemMessageChannelModel } from '../../../../preference/models/system-message-channel.model';
import { SystemMessageReleaseModel } from '../../../../preference/models/system-message-release.model';
import { EventModel } from '../../../../shared/models/event.model';

@Component({
  selector: 'ca-system-message-release-form',
  templateUrl: './system-message-release-form.component.html',
})
export class SystemMessageReleaseFormComponent {
  @Input() model: SystemMessageReleaseModel;
  @Input() systemMessageChannels: SystemMessageChannelModel[];
  @Input() submitted = false;
  @Input() isNew = false;

  @Output() onEvent: EventEmitter<EventModel>;

  constructor() {
    this.onEvent = new EventEmitter<EventModel>();
  }
}
