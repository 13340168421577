import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProxyServices } from '../../../core/services/proxy.service';
import { CustomDataReportDefinitionModel } from '../../../custom-content/models/custom-data-report-definition.model';
import { CustomDataSearchFieldModel } from '@seahorse/domain';

import { FieldDefinitionModel, ObjectDefinitionModel } from '@seahorse/domain';

import * as _ from 'underscore';

@Component({
  selector: 'ca-custom-content-edit-report-modal',
  templateUrl: 'custom-content-edit-report-modal.component.html',
})
export class CustomContentEditReportModalComponent {
  activeFields: any[] = [];
  definitions: ObjectDefinitionModel[] = [];
  errors: string[] = [];
  filterFields: CustomDataSearchFieldModel[] = [];
  groupByFields: string = null;
  inactiveFields: any[] = [];
  inactiveFilterFields: CustomDataSearchFieldModel[] = [];
  showInactive = false;
  showInactiveFilter = false;
  numberOfExportRows: number = null;

  private _definitionFields: FieldDefinitionModel[] = [];
  private _report: CustomDataReportDefinitionModel = null;
  private _reportFields: any[] = [];

  @Input() set report(r: CustomDataReportDefinitionModel) {
    this._report = _.clone(r);
    if (r.reportConfiguration) {
      this._reportFields = r.reportConfiguration.fieldDisplay;
      if (r.reportConfiguration.groupBy) {
        this.groupByFields = r.reportConfiguration.groupBy.join(', ');
      }
      this.numberOfExportRows = r.reportConfiguration.numberOfExportRows;
    }

    this.definitions = this.proxyService.objectDefinitions;
    const definition = _.find(
      this.definitions,
      (def) => def.systemCode === this._report.customDataObjectSystemCode
    );
    if (definition) {
      this._definitionFields =
        ObjectDefinitionModel.getAllFieldDefinitions(definition);
    }

    this.formatFields();
  }
  get report(): CustomDataReportDefinitionModel {
    return this._report;
  }

  constructor(
    private activeModal: NgbActiveModal,
    private proxyService: ProxyServices
  ) {}

  activateField(field: FieldDefinitionModel) {
    this.inactiveFields.splice(
      _.findIndex(
        this.inactiveFields,
        (f) => f.systemCode === field.systemCode
      ),
      1
    );
    this.activeFields.push(field);
  }

  activateFilterField(field: CustomDataSearchFieldModel) {
    this.inactiveFilterFields.splice(
      _.findIndex(
        this.inactiveFilterFields,
        (f) => f.fieldName === field.fieldName
      ),
      1
    );
    this.filterFields.push(field);
  }

  deactivateField(field: FieldDefinitionModel) {
    this.activeFields.splice(
      _.findIndex(this.activeFields, (f) => f.systemCode === field.systemCode),
      1
    );
    this.inactiveFields.push(field);
    this.inactiveFields = _.sortBy(this.inactiveFields, 'name');
  }

  deactivateFilterField(field: CustomDataSearchFieldModel) {
    this.filterFields.splice(
      _.findIndex(this.filterFields, (f) => f.fieldName === field.fieldName),
      1
    );
    this.inactiveFilterFields.push(field);
    this.inactiveFilterFields = _.sortBy(this.inactiveFilterFields, 'name');
  }

  dismiss() {
    this.activeModal.dismiss(false);
  }

  private formatFields() {
    if (!this._definitionFields || this._definitionFields.length === 0) {
      return;
    }
    // if (!this._reportFields || this._reportFields.length === 0) {
    //     return;
    // }

    this.activeFields = [];
    this.filterFields = [];
    this.inactiveFields = [];
    this.inactiveFilterFields = [];

    _.each(_.sortBy(this._definitionFields, 'sortOrder'), (definitionField) => {
      const reportField = _.find(
        this._reportFields,
        (rf) => rf.fieldName === definitionField.systemCode
      );
      definitionField.sortOrder = definitionField.sortOrder
        ? definitionField.sortOrder
        : 0;

      if (reportField) {
        definitionField['isSelected'] = true;
        definitionField.name = reportField.displayName;
        definitionField.sortOrder = reportField.sortOrder
          ? reportField.sortOrder
          : 0;
        this.activeFields.push(definitionField);
      } else {
        this.inactiveFields.push(definitionField);
      }

      if ([8, 9].indexOf(definitionField.fieldType) === -1) {
        const reportFilterField = _.find(
          this._report.reportConfiguration.fields,
          (ff) => ff.fieldName === definitionField.systemCode
        );
        if (!reportFilterField) {
          this.inactiveFilterFields.push(
            this.mapFilterField(reportFilterField, definitionField)
          );
        } else {
          this.filterFields.push(
            this.mapFilterField(reportFilterField, definitionField)
          );
        }
      }
    });

    this.inactiveFields = _.sortBy(this.inactiveFields, 'name');
    this.inactiveFilterFields = _.sortBy(
      this.inactiveFilterFields,
      'displayName'
    );
  }

  mapFilterField(
    reportFilterField: CustomDataSearchFieldModel,
    definitionField: FieldDefinitionModel
  ): CustomDataSearchFieldModel {
    const result = reportFilterField
      ? reportFilterField
      : {
          fieldName: definitionField.systemCode,
          searchOperator: reportFilterField
            ? reportFilterField.searchOperator
            : 0,
          searchValue: reportFilterField ? reportFilterField.searchValue : null,
        };
    result['displayName'] = definitionField.name;
    result['fieldType'] = definitionField.fieldType;
    return result;
  }

  objectDefinitionChanged($event) {
    this._reportFields = [];
    this.activeFields = [];
    this._definitionFields = ObjectDefinitionModel.getAllFieldDefinitions(
      _.find(
        this.definitions,
        (def) => def.systemCode === this._report.customDataObjectSystemCode
      )
    );
    this.inactiveFields = _.sortBy(this._definitionFields, 'name');
    this.inactiveFilterFields = _.map(
      _.sortBy(
        _.filter(
          this._definitionFields,
          (filter) => [8, 9].indexOf(filter.fieldType) === -1
        ),
        'name'
      ),
      (f) => this.mapFilterField(null, f)
    );
  }

  save() {
    const r = _.clone(this._report);

    if (!r.reportConfiguration) {
      r.reportConfiguration = {
        additionalFields: [],
        fieldDisplay: [],
        fields: [],
        groupBy: [],
      };
    }

    r.reportConfiguration.fields = this.filterFields;
    r.reportConfiguration.fieldDisplay = _.map(this.activeFields, (f) => {
      return {
        displayName: f.name,
        fieldName: f.systemCode,
        sortOrder: f.sortOrder,
      };
    });
    if (this.groupByFields) {
      r.reportConfiguration.groupBy = _.map(
        this.groupByFields.split(','),
        (item) => item.trim()
      );
    } else {
      r.reportConfiguration.groupBy = [];
    }
    if (this.numberOfExportRows) {
      r.reportConfiguration.numberOfExportRows = this.numberOfExportRows;
    }

    const validationResult = CustomDataReportDefinitionModel.validate(r);
    if (!validationResult.isValid) {
      this.errors = validationResult.errors;
      return;
    }

    this.activeModal.close(r);
  }
}
