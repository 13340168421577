export class ReportsChart {
  coordinates: ReportsChartCoordinates[];
  xLabels: string[];
}

export class ReportsChartCoordinates {
  x: number;
  y: number;
}

export const PERIODS = [
  { id: 0, name: 'Day' },
  { id: 1, name: 'Week' },
  { id: 2, name: 'Month' },
  { id: 3, name: 'Quarter' },
  { id: 4, name: 'Year' },
];

export enum Period {
  Day,
  Week,
  Month,
  Quarter,
  Year,
}
