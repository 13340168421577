import {
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { EnvService } from '../configuration/env.service';
import { ResultWrapper } from './result-wrapper.model';

@Injectable({
  providedIn: 'root',
})
export class DataContextService {
  showProgress = new Subject();

  constructor(
    protected environment: EnvService,
    private httpClient: HttpClient
  ) {}

  delete<T>(url: string): Observable<ResultWrapper<T>> {
    url = this.processUrl(url);
    return this.httpClient.delete<ResultWrapper<T>>(url);
  }

  download(
    url: string,
    filename: string | null = null,
    postData: any = null
  ): Observable<boolean> {
    return new Observable((subscriber) => {
      url = this.processUrl(url);

      let req = null;
      if (postData !== undefined && postData !== null) {
        req = this.httpClient.post(url, postData, {
          observe: 'response',
          responseType: 'blob' as 'json',
        });
      } else {
        req = this.httpClient.get(url, {
          observe: 'response',
          responseType: 'blob' as 'json',
        });
      }
      req.subscribe(
        (response: any) => {
          const dataType = response.body.type;
          const binaryData = [];
          binaryData.push(response.body);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(
            new Blob(binaryData, { type: dataType })
          );
          if (filename) {
            downloadLink.setAttribute('download', filename);
          } else {
            downloadLink.setAttribute(
              'download',
              response.headers.get('x-shipm8-filename')
            );
          }
          document.body.appendChild(downloadLink);
          downloadLink.click();

          subscriber.next(true);
          subscriber.complete();
        },
        (error) => {
          subscriber.error(error);
          subscriber.complete();
        }
      );
    });
  }

  getBlob(url: string, postData: any = null, type: string): Observable<Blob> {
    return new Observable((subscriber) => {
      url = this.processUrl(url);

      let req;
      if (postData) {
        req = this.httpClient.post(url, postData, {
          observe: 'response',
          responseType: 'blob',
        });
      } else {
        req = this.httpClient.get(url, {
          observe: 'response',
          responseType: 'blob',
        });
      }

      req.subscribe(
        (response: HttpResponse<Blob>) => {
          subscriber.next(new Blob([response.body], { type: type }));
          subscriber.complete();
        },
        (error) => {
          subscriber.error(error);
          subscriber.complete();
        }
      );
    });
  }

  get<T>(url: string): Observable<ResultWrapper<T>> {
    url = this.processUrl(url);
    return this.httpClient.get<ResultWrapper<T>>(url);
  }

  getRaw(url: string): Observable<string> {
    url = this.processUrl(url);
    return this.httpClient.get(url, { responseType: 'text' });
  }

  getWithProgress<T>(url: string): Promise<any> {
    url = this.processUrl(url);
    const req = new HttpRequest('GET', url, {
      reportProgress: true,
    });
    return new Promise<any>((resolve, reject) => {
      this.httpClient.request(req).subscribe(
        (response: HttpEvent<any>) => {
          if (
            response.type === HttpEventType.DownloadProgress &&
            response.total
          ) {
            const total = response.total;
            const toLoad = response.loaded;
            this.showProgress.next(Math.round((100 * toLoad) / total));
          }
          if (response.type === HttpEventType.Response) {
            resolve(response.body);
          }
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  post<T>(url: string, parameters: any): Observable<ResultWrapper<T>> {
    url = this.processUrl(url);
    return this.httpClient.post<ResultWrapper<T>>(url, parameters);
  }

  postRaw(url: string, parameters: any): Observable<string> {
    url = this.processUrl(url);
    return this.httpClient.post(url, parameters, { responseType: 'text' });
  }

  put<T>(url: string, parameters: any): Observable<ResultWrapper<T>> {
    url = this.processUrl(url);
    return this.httpClient.put<ResultWrapper<T>>(url, parameters);
  }

  patch<T>(url: string, parameters: any): Observable<ResultWrapper<T>> {
    url = this.processUrl(url);
    return this.httpClient.patch<ResultWrapper<T>>(url, parameters);
  }

  formEncode(obj: any) {
    let encodedString = '';
    for (const key of Object.keys(obj)) {
      if (encodedString.length !== 0) {
        encodedString += '&';
      }

      encodedString += key + '=' + encodeURIComponent(obj[key]);
    }

    return encodedString.replace(/%20/g, '+');
  }

  protected processUrl(url: string) {
    if (!url || url === '') {
      return url;
    }

    if (
      url.toLowerCase().indexOf('http://') !== 0 &&
      url.toLowerCase().indexOf('https://') !== 0
    ) {
      url = this.environment.apiHost + '/' + url;
    }

    return url;
  }
}
