import { Component, Input, Output, EventEmitter } from '@angular/core';

import { EventModel } from '../../../../../../shared/models/event.model';
import { FlowModel } from '@seahorse/domain';

@Component({
  selector: 'flow-details-modal',
  templateUrl: 'flow-details-modal.component.html',
})
export class FlowDetailsModalComponent {
  @Input() flow: FlowModel;
  @Input() loadingFlow: boolean;

  @Output() onEvent: EventEmitter<EventModel>;

  constructor() {
    this.flow = null;
    this.loadingFlow = false;
    this.onEvent = new EventEmitter<EventModel>();
  }
}
