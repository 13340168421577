import { CustomDataBaseModel } from "@seahorse/domain";

export class KPPortVisitModel extends CustomDataBaseModel {
  $nautical_portvisit_id: number;
  reducersOnBoard: number;
  remarks: string;

  constructor() {
    super();

    this.$nautical_portvisit_id = null;
    this.reducersOnBoard = null;
    this.remarks = null;
  }
}
