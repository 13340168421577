<table class="table table-truncated">
  <thead>
    <tr>
      <th>Naam</th>
      <th>Email</th>
      <th>Kvk Nummer</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let relationship of relationships" class="card-body-group">
      <td>{{ relationship.naam }}</td>
      <td>{{ relationship.email }}</td>
      <td>{{ relationship.kvkNummer }}</td>
    </tr>
  </tbody>
</table>
