import { Guid } from 'guid-typescript';

export class LogModel {
  id: number;
  productId: Guid;
  userId: Guid;
  area: string;
  action: string;
  value: string;
  createdOn: Date;
}

export class AddLogModel {
  organisationId: string;
  userId: Guid;

  constructor(organisationId: string, userId: Guid) {
    this.organisationId = organisationId;
    this.userId = userId;
  }
}
