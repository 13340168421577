import { Pipe, PipeTransform } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'logicType' })
export class LogicTypePipe implements PipeTransform {
  logicTypeQuery: string;
  logicType: string;

  constructor(private translateService: TranslateService) {
    this.logicTypeQuery = null;
    this.logicType = null;
  }

  transform(logicType: number): string {
    switch (logicType) {
      case 0: {
        this.logicTypeQuery = 'calculationBracket';

        break;
      }

      case 1: {
        this.logicTypeQuery = 'calculationPeriod';

        break;
      }

      case 2: {
        this.logicTypeQuery = 'calculationPricePerUnit';

        break;
      }

      case 3: {
        this.logicTypeQuery = 'conditionBoolean';

        break;
      }

      case 4: {
        this.logicTypeQuery = 'conditionDate';

        break;
      }

      case 5: {
        this.logicTypeQuery = 'conditionNumeric';

        break;
      }

      case 6: {
        this.logicTypeQuery = 'conditionSchedule';

        break;
      }

      case 7: {
        this.logicTypeQuery = 'conditionString';

        break;
      }

      case 8: {
        this.logicTypeQuery = 'conditionTimespan';

        break;
      }

      case 9: {
        this.logicTypeQuery = 'tariffDependency';

        break;
      }

      case 10: {
        this.logicTypeQuery = 'tariffGroupDependency';

        break;
      }

      case 11: {
        this.logicTypeQuery = 'addressDependency';

        break;
      }

      case 12: {
        this.logicTypeQuery = 'conditionArray';

        break;
      }

      default: {
        this.logicTypeQuery = 'unknown';

        break;
      }
    }

    this.translateService
      .get(`invoicingFlows.logicTypes.${this.logicTypeQuery}`)
      .subscribe((translateGetResponse: any) => {
        this.logicType = translateGetResponse;
      });

    return this.logicType;
  }
}
