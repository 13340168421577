import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaskDefinitionComponentsModule } from './components/task-definition-components.module';
import { TaskDefinitionServicesModule } from './services/task-definition-services.module';

@NgModule({
  imports: [
    CommonModule,

    // Child modules
    TaskDefinitionComponentsModule,
    TaskDefinitionServicesModule,
  ],
})
export class TaskDefinitionModule {}
