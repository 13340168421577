import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { CollectionDisplay } from '../../layout/models/collection-display.model';
import { ShipSearchModel } from '@seahorse/domain';
import { SelectedSearchModel } from '../../layout/models/selectedSearch.model';
import { NauticalShipTagModel } from '@seahorse/domain';

@Injectable()
export class NauticalShipInteractionService {
  private searchResultCountSource = new Subject<number>();
  private advancedSearchSource = new Subject<ShipSearchModel>();
  private searchSource = new Subject<SelectedSearchModel>();
  private selectedViewSource = new Subject<CollectionDisplay>();
  private modifyShipTags = new Subject<NauticalShipTagModel[]>();

  advancedSearchChanged$ = this.advancedSearchSource.asObservable();
  searchChanged$ = this.searchSource.asObservable();
  searchResultCountChanged$ = this.searchResultCountSource.asObservable();
  selectedViewCalled$ = this.selectedViewSource.asObservable();
  modifyShipTags$ = this.modifyShipTags.asObservable();

  advancedSearchChanged(search: ShipSearchModel) {
    this.advancedSearchSource.next(search);
  }

  searchChanged(search: SelectedSearchModel) {
    this.searchSource.next(search);
  }

  searchResultCountChanged(count: number) {
    this.searchResultCountSource.next(count);
  }

  setSelectedView(view: CollectionDisplay) {
    this.selectedViewSource.next(view);
  }

  modifyTags(tags: NauticalShipTagModel[]) {
    this.modifyShipTags.next(tags);
  }
}
