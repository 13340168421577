import { Directive, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KeyValuePair } from '@seahorse/common';
import { interval, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'underscore';

@Directive()
export abstract class BasePage<TState> implements OnDestroy, OnInit {
  private stateObject: TState = null; // must via the functions setter and getter to update the object

  protected destroy$ = new Subject();

  refreshInterval = null; // refresh timer in ms

  constructor(protected route: ActivatedRoute, protected router: Router) {
    this.refreshInterval = 0; // default no refresh
  }

  /**
   * Create a default state object
   */
  abstract createDefaultState(): TState;

  /**
   * This method will be called when the staate object is changed.
   * @param state The new state object
   * @param changes The changes; an array with key/ value pair objects
   */
  abstract onStateChanged(state: TState, changes: any[]): void;

  ngOnInit() {
    this.resetState(); // set default state properties

    this.route.params.subscribe((params) => {
      const newState = this.convertParamsToState(params);
      const changes = this.updateState(newState);
      if (changes.length > 0) {
        this.onStateChanged(newState, changes);
      }
    });

    if (this.refreshInterval > 0) {
      interval(this.refreshInterval)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.onRefresh();
        });
    }
  }

  ngOnDestroy() {
    this.destroy$.complete();
  }

  convertParamsToState(params: any): TState {
    const newState = _.mapObject(params, (val) => {
      try {
        return JSON.parse(val); // try to convert the string value to the correct type; e.g. Boolean, Number etc...
      } catch (error) {
        return val;
      }
    });

    return newState as TState;
  }

  convertStateToParams() {
    const defaultState = this.createDefaultState();
    const keys = _.keys(defaultState);
    return _.pick(this.stateObject, (value, key) => {
      // filter all undefined values and return only the keys in the state model
      return (
        value !== undefined &&
        value !== null &&
        keys.indexOf(key.toString()) > -1
      );
    });
  }

  /**
   * @returns Returns a clone of the state object.
   */
  getAllStates() {
    // use JSON stringify/parse; ensure all references are removed from the original state object.
    // use the function "setState" to update the stateObject
    const text = JSON.stringify(this.stateObject);
    return JSON.parse(text);
  }

  getState(key: string, defaultValue: any = null) {
    return this.stateObject[key] === undefined
      ? defaultValue
      : this.stateObject[key];
  }

  getUrl(route: ActivatedRoute): string {
    const paths = [];
    if (route) {
      if (
        route.routeConfig &&
        route.routeConfig.path &&
        route.routeConfig.path !== ''
      ) {
        const tempPaths = route.routeConfig.path.split('/');
        const params = route.snapshot.params;
        const newPaths = _.map(tempPaths, (path) => {
          if (path.startsWith(':')) {
            const tempPath = path.substring(1);
            if (params[tempPath]) {
              return params[tempPath];
            }
          }
          return path;
        });
        paths.push(newPaths.join('/'));
      }

      if (route.children && route.children.length > 0) {
        const childPath: string = this.getUrl(route.children[0]);
        if (childPath && childPath !== '') {
          paths.push(childPath);
        }
      }
    }

    return paths.join('/');
  }

  onRefresh() {}

  resetState(): void {
    this.stateObject = this.createDefaultState();
  }

  setState(key: string, value: any) {
    if (value === undefined || value === null) {
      delete this.stateObject[key];
    } else {
      this.stateObject[key] = value;
    }

    this.updateUrl();
  }

  setStates(pairs: KeyValuePair<string, any>[]) {
    if (pairs) {
      _.each(pairs, (pair) => {
        if (pair.value === undefined || pair.value === null) {
          delete this.stateObject[pair.key];
        } else {
          this.stateObject[pair.key] = pair.value;
        }
      });
    }

    this.updateUrl();
  }

  /**
   * Update the current state object with the given state oject.
   * @param newState The new state object.
   * @returns Returns an array of objects with contains the key and the old values of the state object.
   */
  updateState(newState: TState): any[] {
    // create a new object, ensure all properties are present
    const newObject = this.createDefaultState();
    _.extend(newObject, newState);

    // get all keys
    const keys = _.uniq(_.keys(this.stateObject).concat(_.keys(newState)));

    // first compare the values
    const changes = [];
    _.each(keys, (key) => {
      if (this.stateObject[key] !== newObject[key]) {
        changes.push({ key: key, value: this.stateObject[key] });
      }
    });

    // after compare set new values
    this.stateObject = newObject;
    return changes;
  }

  updateUrl() {
    const path = this.getUrl(this.route.root);
    const commands: any[] = [`../${path}/`];

    const params = this.convertStateToParams();
    if (!_.isEmpty(params)) {
      commands.push(params);
    }

    this.router.navigate(commands);
  }
}
