import {
  Component,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'underscore';

import { CompanyModel } from '@seahorse/domain';
import { CompanyDataService } from '@seahorse/domain';
import { AddressTypePipe } from '../../../company/pipes/address-type.pipe';
import { FinancialDataService } from '@seahorse/domain';

@Component({
  selector: 'ca-company-summary',
  templateUrl: './company-summary.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanySummaryComponent {
  attributes: any[] = [];
  company: CompanyModel;
  isLoading = false;

  @Input('company-id') set companyid(value: number) {
    this.isLoading = true;

    this.companyDataService.getById(value).subscribe(
      (result) => {
        this.company = result.result;
        this.attributes = [];
        this.loadAddresses(value);
        this.loadFinancials(value);
      },
      () => {},
      () => {
        this.isLoading = false;
      }
    );
  }

  constructor(
    private companyDataService: CompanyDataService,
    private companyFinancialDataService: FinancialDataService,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
    private addressPipe: AddressTypePipe
  ) {}

  loadAddresses(companyId: number) {
    this.companyDataService.getAddresses(companyId, 0, 5).subscribe(
      (result) => {
        this.company.companyAddresses = result.result;
        this.cdr.markForCheck();

        this.setAttributes();
      },
      () => {}
    );
  }

  loadFinancials(companyId: number) {
    this.companyFinancialDataService.getFinancials(companyId, 0, 99).subscribe(
      (result) => {
        this.company.companyFinancials = result.result;
        this.cdr.markForCheck();

        this.setAttributes();
      },
      () => {}
    );
  }

  setAttributes() {
    if (!this.company) {
      return;
    }
    this.attributes = [];

    if (this.company.parentCompany) {
      this.attributes.push({
        fieldName: this.translateService.instant(
          'companies.company.model.parentCompany'
        ),
        fieldValue: this.company.parentCompany.name,
      });
    }

    if (this.company.companyAddresses) {
      _.each(this.company.companyAddresses, (address) => {
        this.attributes.push({
          fieldName: address.name,
          fieldValue: address.value,
        });
      });
    }
    if (this.company.companyFinancials) {
      _.each(this.company.companyFinancials, (fin) => {
        if (fin.emailAddress && fin.emailAddress.length > 1) {
          this.attributes.push({
            fieldName: 'companies.financial.model.emailAddress',
            fieldValue: fin.emailAddress,
          });
        }
      });
    }
  }
}
