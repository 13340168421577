import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  NgbTypeahead,
  NgbTypeaheadSelectItemEvent,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService, KeyValuePair } from '@seahorse/common';
import {
  CompanyDataService,
  CompanyModel,
  OrderRegistrationModel,
  OrderRegistrationStatus,
} from '@seahorse/domain';
import { merge, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import * as _ from 'underscore';
import { TableColumnModel } from '../../../layout/models/table.model';
import { OrderRegistrationsConstant } from '../../../order-registrations/constants/order-registrations.constant';
import { EventModel } from '../../../shared/models/event.model';
import { QueryModel } from '../../../shared/models/query.model';
import { ViewModel } from '../../../shared/models/view.model';

import { LayoutService } from '@seahorse/layout';

@Component({
  selector: 'ca-order-registrations-overview-header',
  templateUrl: 'order-registrations-overview-header.component.html',
})
export class OrderRegistrationsOverviewHeaderComponent {
  @Input() views: ViewModel[];
  @Input() view: ViewModel['value'];
  @Input() searchField: string;
  @Input() selectedFilter = '';
  @Input() selectedStatus: OrderRegistrationModel['status'];
  @Input() selectedType: string;
  @Input() types: KeyValuePair<string, string>[];
  @Input() objectIds: any[];

  @Input() set search(search: QueryModel['search']) {
    this._search = search?.trim();

    if (search) {
      if (!this.selectedCompany || search !== this.selectedCompany['name']) {
        this.selectedCompany = new CompanyModel();
        this.selectedCompany.name = search;
      }
    }
  }

  get search(): QueryModel['search'] {
    return this._search;
  }

  @Input() set searchFields(searchFields: TableColumnModel[]) {
    if (searchFields && searchFields.length > 0) {
      this._searchFields = _.filter(searchFields, (field) => {
        return field.isSearchable === true;
      });
    } else {
      this._searchFields = [];
    }

    if (!this._searchFields || this._searchFields.length === 0) {
      this.searchField = null;
    } else if (!this.searchField) {
      this.searchField = this._searchFields[0].fieldName;
    }
  }
  get searchFields() {
    return this._searchFields;
  }
  @Input() count: number;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onEvent: EventEmitter<EventModel>;

  private _searchFields: TableColumnModel[];
  statuses: OrderRegistrationStatus[];
  advanced: boolean;
  filters = [
    { value: '', title: 'shared.terms.none' },
    {
      value: 'upcoming',
      title: 'customModules.orderRegistrations.byDeliveryDate',
    },
  ];

  sidebarOpen = false;

  private destroy$ = new Subject();
  private _search: QueryModel['search'];

  selectedCompany: string | CompanyModel;

  @ViewChild('companyTypeahead', { static: true })
  companyTypeahead: NgbTypeahead;

  companiesLoading: boolean;
  companiesLoadingFailed: boolean;
  focus$: Subject<string>;
  mappingKey = OrderRegistrationsConstant.OBJECTTYPEID;

  constructor(
    private layoutService: LayoutService,
    private translate: TranslateService,
    private companyData: CompanyDataService,
    private notification: NotificationService
  ) {
    this.views = [];
    this.view = null;
    this.selectedStatus = null;
    this.search = null;
    this.count = 0;
    this.onEvent = new EventEmitter<EventModel>();
    this.searchField = null;
    this.statuses = _.filter(_.toArray(OrderRegistrationStatus), (r) =>
      _.isNumber(r)
    ) as OrderRegistrationStatus[];
    this.advanced = false;

    this.layoutService.sidebarToggle$
      .pipe(takeUntil(this.destroy$))
      .subscribe((sidebarOpen: boolean) => {
        this.sidebarOpen = sidebarOpen;
      });

    this.selectedCompany = null;
    this.companyTypeahead = null;
    this.companiesLoading = false;
    this.companiesLoadingFailed = false;
    this.focus$ = new Subject<string>();
  }

  searchOrderRegistrations() {
    this.advanced = false;

    let operator: string = null;

    if (this.searchField === 'supplier') {
      if (this.selectedCompany) {
        if (this.selectedCompany['id']) {
          operator = 'queryEquals';
        }

        if (this.selectedCompany['name']) {
          this.search = this.selectedCompany['name'];
        } else {
          this.search = this.selectedCompany.toString();
        }
      } else {
        this.search = null;
      }
    }

    this.onEvent.emit({
      action: 'search',
      data: {
        field: this.searchField,
        query: this.search,
        status: this.selectedStatus,
        type: this.selectedType,
        operator: operator,
      },
    });
  }

  itemSelected(event: NgbTypeaheadSelectItemEvent) {
    if (event.item) {
      this.selectedCompany = event.item;
    }
  }

  companyFormatter = (company: CompanyModel): string => {
    if (
      company !== undefined &&
      company !== null &&
      company.name !== undefined &&
      company.name !== null
    ) {
      return company.name.toUpperCase();
    } else {
      return '';
    }
  };

  searchCompany = (input$: Observable<string>): any => {
    const debouncedText$ = input$.pipe(
      debounceTime(300),
      distinctUntilChanged()
    );
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$).pipe(
      tap(() => (this.companiesLoading = true)),
      switchMap((s) =>
        this.companyData.search(s, 0, 100).pipe(
          tap(() => (this.companiesLoadingFailed = false)),
          map((r) => _.extend(r.result)),
          catchError((e) => {
            this.companiesLoading = false;
            this.companiesLoadingFailed = true;

            this.notification.showError(
              e,
              this.translate.instant('shared.terms.failed')
            );
            return of([]);
          })
        )
      ),
      tap(() => (this.companiesLoading = false))
    );
  };

  setNull() {
    this.selectedCompany = null;
  }

  resetQuery() {
    this.search = null;

    this.onEvent.emit({ action: 'reset' });
  }
}
