<input
  [(ngModel)]="nauticalWaypoint"
  [class.is-invalid]="nauticalWaypointsLoadingFailed"
  [ngbTypeahead]="searchnauticalWaypoint"
  [inputFormatter]="nauticalWaypointFormatter"
  [resultFormatter]="nauticalWaypointsFormatter"
  [editable]="false"
  [required]="isRequired"
  *ngIf="allowTypeAhead"
  id="typeahead-nauticalWaypoint"
  name="nauticalWaypoint"
  type="text"
  class="form-control"
  (selectItem)="itemSelected($event)"
/>
<select
  class="form-control"
  *ngIf="!allowTypeAhead"
  (ngModelChange)="codeSelected($event)"
  [ngModel]="nauticalWaypoint ? nauticalWaypoint.code : null"
>
  <option [value]="item.code" *ngFor="let item of waypoints">
    {{ nauticalWaypointsFormatter(item) }}
  </option>
</select>
