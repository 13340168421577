<div class="page-header">
  <div class="row">
    <div class="col text-right">
      <div class="btn-list">
        <div class="dropdown">
          <button
            type="button"
            class="btn btn-outline-secondary"
            id="headerHamburgerButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fa-regular fa-ellipsis-v fa-fw"></i>
          </button>
          <div class="dropdown-menu" aria-labelledby="headerHamburgerButton">
            <button
              class="btn dropdown-item"
              [disabled]="loadingStage"
              (click)="onEvent.emit({ action: 'deleteStage' })"
            >
              <i class="fa-solid fa-trash fa-fw"></i>
              {{ 'invoicingFlows.stageDetails.deleteStage' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
