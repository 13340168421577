import { Directive, ElementRef, inject, Input, OnInit } from '@angular/core';
import { BadgeModel } from './badge.model';

@Directive({
  selector: '[shBadge]',
  standalone: true,
})
export class BadgeDirective implements OnInit {
  private _model: BadgeModel | undefined;
  @Input('shBadge') set model(val: BadgeModel | undefined) {
    this._model = val;
    this.applyStyles();
  }

  get isPlain(): boolean {
    return this._model?.mode === 'plain';
  }

  get isBadge(): boolean {
    return this._model?.mode === 'badge';
  }

  get color(): string | undefined {
    if (!this._model) {
      return undefined;
    }

    const { color } = this._model;

    if (!color || color.toLowerCase() === '#ffffff') {
      return undefined;
    }

    return color;
  }

  hostEl = inject(ElementRef);
  element = this.hostEl.nativeElement as HTMLElement;

  ngOnInit() {
    this.applyStyles();
  }

  private applyStyles() {
    if (this.isBadge && this.color) {
      this.element.classList.add('sh-badge');
      this.hostEl.nativeElement.style.color = 'initial';
      this.hostEl.nativeElement.style.backgroundColor = this.color;
    } else {
      this.element.classList.remove('sh-badge');
      this.hostEl.nativeElement.style.backgroundColor = 'initial';
      if (this.isPlain && this.color)
        this.hostEl.nativeElement.style.color = this.color;
    }
  }
}
