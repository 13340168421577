import { CommonModule } from '@angular/common';
import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationService } from '@seahorse/common';
import {
  CustomDataBaseModel,
  FieldDefinitionModel,
  ObjectDefinitionModel,
  ProxyService,
  ScopeType,
} from '@seahorse/domain';
import { Observable, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { addNewObjectProcedure } from '../../pages/procedures/add-new-object.procedure';
import { BaseControl } from '../base-control';
import { newActionExcludedObjects } from '../linked-object.model';
import { LinkedObjectService } from '../linked-object.service';

@Component({
  selector: 'temp-linked-object',
  templateUrl: './linked-object.control.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LinkedObjectControl),
      multi: true,
    },
    {
      provide: BaseControl,
      useExisting: forwardRef(() => LinkedObjectControl),
    },
  ],
  standalone: true,
  imports: [CommonModule, FormsModule, TranslateModule, NgbTypeaheadModule],
})
export class LinkedObjectControl
  extends BaseControl<number | string | null>
  implements OnInit
{
  @Input() required = false;
  @Input() value: number | string | null = null;

  @Input() placeholder: string;
  @Input() name: string;

  private _fieldDefinition = null;
  @Input() set fieldDefinition(val: FieldDefinitionModel) {
    this._fieldDefinition = val;
    this._fieldAdditionalData = FieldDefinitionModel.getAdditionalData(val);
    this._displayFields = this._fieldAdditionalData
      ? this._fieldAdditionalData.displayFields
      : [];
    this.tagFilter = this._fieldAdditionalData?.tag;

    this._objectDefinition = this._proxyServices.objectDefinitions.find(
      (x) => x.systemCode === this._linkedObjectSystemCode
    );

    // try find the base object definition
    if (this._objectDefinition === undefined) {
      this._objectDefinition = this._proxyServices.objectDefinitions.find(
        (x) =>
          x.baseObjectDefinition !== undefined &&
          x.baseObjectDefinition !== null &&
          x.baseObjectDefinition.systemCode === this._linkedObjectSystemCode
      )?.baseObjectDefinition;
    }

    this.hideAddButton = newActionExcludedObjects.some(
      (key) =>
        this._objectDefinition?.mappingKey &&
        this._objectDefinition.mappingKey.startsWith(key)
    );
  }
  get fieldDefinition(): FieldDefinitionModel {
    return this._fieldDefinition;
  }

  dataValue: CustomDataBaseModel | null = null;
  isLoading = false;
  hasLoadingFailed = false;
  tagFilter = null;

  private _displayFields = [];
  private _fieldAdditionalData = null;

  private _addNewObjectProcedure = addNewObjectProcedure();

  private get _linkedObjectSystemCode() {
    return FieldDefinitionModel.getMappingKeySystemCode(this.fieldDefinition);
  }

  hideAddButton = false;
  private _objectDefinition: ObjectDefinitionModel;
  ScopeType = ScopeType;

  constructor(
    private _linkedObjectData: LinkedObjectService,
    private _notificationService: NotificationService,
    private _proxyServices: ProxyService
  ) {
    super();
  }

  ngOnInit() {
    if (this.value != null) {
      this.getObject(this.value);
    }
  }

  private getObject(key: any) {
    const mappingKey = this._objectDefinition?.mappingKey;

    const foreignKey = FieldDefinitionModel.getMappingKeyForeignKey(
      this.fieldDefinition
    );

    this._linkedObjectData
      .getByKey(this._linkedObjectSystemCode, mappingKey, foreignKey, key)
      .subscribe((res) =>
        res.hasResult ? (this.dataValue = res.result) : null
      );
  }

  dataSearch = (input$: Observable<string>): Observable<any> =>
    input$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => {
        this.isLoading = true;
      }),
      switchMap((query) => {
        return this._linkedObjectData
          .search(
            this._linkedObjectSystemCode,
            this._fieldAdditionalData.displayFields,
            this._objectDefinition?.mappingKey,
            query,
            0,
            50,
            this.tagFilter
          )
          .pipe(
            map((r) => r.result),
            tap(() => (this.isLoading = false)),
            catchError((error) => {
              this.hasLoadingFailed = true;
              this.isLoading = false;

              this._notificationService.showError(error, 'shared.terms.failed');
              return of([]);
            })
          );
      })
    );

  selectItem(value: CustomDataBaseModel) {
    if (!value) {
      this.dataValue = null;
      this.emitOnChange(null);
      return;
    }

    const foreignKey = FieldDefinitionModel.getMappingKeyForeignKey(
      this.fieldDefinition
    );

    this.emitOnChange(value[foreignKey]);
  }

  createNewObject() {
    this.isLoading = true;

    this._addNewObjectProcedure(this._objectDefinition).subscribe({
      next: (result) => {
        this.isLoading = false;
        this.dataValue = result;
        this.selectItem(result);
      },
    });
  }

  dataFormatter = (object: CustomDataBaseModel): string =>
    object.__DisplayName || object[this._displayFields[0]];

  clearTagFilter() {
    this.tagFilter = '';
  }
}
