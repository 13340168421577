import { ResultWrapper } from "@seahorse/common";
import { Observable } from "rxjs";
import { UserInfoModel, UserModel } from "../models/users.model";

export abstract class UsersDataService {
  abstract getByOrganisation(
    loadInactiveUsers: boolean
  ): Observable<ResultWrapper<UserModel[]>>;

  abstract getProfile(): Observable<ResultWrapper<UserInfoModel>>;
  abstract setUserIdentity(): Observable<string[]>;

  abstract add(user: UserModel): Observable<ResultWrapper<UserModel>>;
  abstract update(user: UserModel): Observable<ResultWrapper<UserModel>>;
}
