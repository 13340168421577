<div class="card">
  <table class="table table-clickable">
    <thead>
      <tr>
        <th scope="col">{{ 'shared.terms.name' | translate }}</th>
        <th scope="col">{{ 'shared.terms.description' | translate }}</th>
        <th scope="col">{{ 'invoicingFlows.flow.startsOn' | translate }}</th>
        <th scope="col">{{ 'invoicingFlows.flow.endsOn' | translate }}</th>
        <th scope="col">
          {{ 'invoicingFlows.flow.invoiceConfiguration' | translate }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let invoicingFlow of invoicingFlows"
        [ngClass]="{
          selected:
            selectedInvoicingFlow &&
            selectedInvoicingFlow.id === invoicingFlow.id
        }"
        (click)="
          onEvent.emit({ action: 'selectInvoicingFlow', data: invoicingFlow })
        "
      >
        <th scope="row">{{ invoicingFlow.name }}</th>
        <td>{{ invoicingFlow.description }}</td>
        <td>{{ invoicingFlow.startsOn | date: 'dd-MM-yyyy' }}</td>
        <td>{{ invoicingFlow.endsOn | date: 'dd-MM-yyyy' }}</td>
        <td>
          <ng-container *ngIf="invoicingFlow.invoiceConfiguration">
            {{ invoicingFlow.invoiceConfiguration.invoiceDescriptionTemplate }}
          </ng-container>
        </td>
      </tr>
    </tbody>
  </table>
</div>
