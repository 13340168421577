import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { LayoutComponentsModule } from '../../../../layout/components/layout-components.module';
import { InvoicingFlowsComponentsModule } from '../../../../invoicing-and-tariffs/modules/invoicing-flows/components/invoicing-flows-components.module';

import { InvoicingFlowsOverviewPage } from './invoicing-flows-overview/invoicing-flows-overview.page';
import { FlowCreatorPage } from './flow-creator/flow-creator.page';
import { FlowDetailsPage } from './flow-details/flow-details.page';
import { StageCreatorPage } from './stage-creator/stage-creator.page';
import { StageDetailsPage } from './stage-details/stage-details.page';

@NgModule({
  declarations: [
    InvoicingFlowsOverviewPage,
    FlowCreatorPage,
    FlowDetailsPage,
    StageCreatorPage,
    StageDetailsPage,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    LayoutComponentsModule,
    InvoicingFlowsComponentsModule,
  ],
})
export class InvoicingFlowsPagesModule {}
