<div class="page-with-header">
  <div
    class="page-header row no-gutters"
    *ngIf="activityTypeGroups && activityTypeGroups.length > 0"
  >
    <div class="col-sm-12 text-right">
      <div class="form-inline btn-group btn-list">
        <div class="form-check mr-3">
          <input
            class="form-check-input"
            type="checkbox"
            value=""
            id="defaultCheck1"
            [(ngModel)]="showOrderedOnly"
          />
          <label class="form-check-label" for="defaultCheck1">
            Toon alleen besteld
          </label>
        </div>
        <div class="btn-group" *ngIf="isReloading">
          <sh-loading-indicator
            class="loader-sm mr-2 mt-2"
          ></sh-loading-indicator>
        </div>
        <div class="input-group mr-3" *ngIf="filter.list === 'history'">
          <div class="input-group-prepend">
            <span class="input-group-text"
              >{{filter.list === 'active' ? ('customers.kp.page.future' |
              translate) : ('customers.kp.page.last' | translate)}}</span
            >
          </div>
          <select
            class="form-control"
            [value]="getState('hours')"
            name="ddl_activities_filter_time"
            (change)="switchTime($event.target.value)"
            title="{{filter.list === 'active' ? ('customers.kp.page.forward' | translate) : ('customers.kp.page.back' | translate)}}"
          >
            <option
              *ngFor="let option of filter.options"
              [value]="option.value"
            >
              {{option.value}} {{'customers.kp.page.hour' | translate}}
            </option>
          </select>
        </div>
        <div class="input-group mr-3">
          <div class="input-group-prepend">
            <span class="input-group-text"
              >{{'customers.kp.page.page' | translate}}</span
            >
          </div>
          <select
            class="form-control"
            [(ngModel)]="filter.list"
            name="ddl_activities_list"
            (change)="switchList()"
          >
            <option [value]="'active'">
              {{'customers.kp.page.active' | translate}}
            </option>
            <option [value]="'history'">
              {{'customers.kp.page.history' | translate}}
            </option>
          </select>
        </div>
        <div class="input-group mr-3">
          <div class="input-group-prepend">
            <span class="input-group-text"
              >{{'customers.kp.page.list' | translate}}</span
            >
          </div>
          <select
            class="form-control"
            [value]="getState('types')"
            name="ddl_activities_list"
            (change)="switchTypes($event.target.value)"
          >
            <option [value]="['meren','ontm']">Meren/Ontmeren</option>
            <option [value]="['comm','mat']">Comm/Mat</option>
          </select>
        </div>
        <div class="input-group mr-3">
          <button
            type="button"
            class="btn btn-primary float-right"
            (click)="addClick()"
          >
            <i class="fa-regular fa-plus fa-fw"></i>
            {{'customers.kp.page.addActivity' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid py-3">
    <div
      class="row"
      *ngIf="!activityTypeGroups || activityTypeGroups.length === 0"
    >
      <ul class="summary-buttons col-4">
        <li>
          <a class="summary-button" (click)="setData(['meren','ontm'])">
            <i class="fa-regular fa-anchor fa-fw" aria-hidden="true"></i>
            <span class="summary-button-text"> Meren & ontmeren </span>
          </a>
        </li>
      </ul>
      <ul class="summary-buttons col-4">
        <li>
          <a class="summary-button" (click)="setData(['comm','mat'])">
            <i class="fa-regular fa-exchange-alt fa-fw" aria-hidden="true"></i>
            <span class="summary-button-text">
              Communicatievaren & materiaalvervoer/ overig
            </span>
          </a>
        </li>
      </ul>
    </div>

    <div *ngIf="activityTypeGroups && activityTypeGroups.length > 0">
      <sh-loading-indicator
        class="text-center"
        *ngIf="isLoading"
      ></sh-loading-indicator>
      <div class="row" *ngIf="isLoading === false">
        <div
          class="col-sm-{{12/activityTypeGroups.length}}"
          *ngFor="let activityTypeGroup of activityTypeGroups"
        >
          <div>
            <ca-custom-kp-activities-list
              #activitiesList
              [activityTypeGroup]="activityTypeGroup"
              [activities]="groups[activityTypeGroup]"
              [files]="files"
              [shipRequirements]="shipRequirements"
              [portVisitInfos]="portVisitInfos"
              [viewList]="filter.list"
              [isLoading]="(isLoading || isReloading)"
              [showOrderedOnly]="showOrderedOnly"
              (actionClicked)="handleEvent($event)"
            >
            </ca-custom-kp-activities-list>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
