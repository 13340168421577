import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { FlowDataService } from '../../../services/flow-data.service';
import { NotificationService } from '@seahorse/common';
import { FlowModel } from '@seahorse/domain';

@Component({
  selector: 'ca-execution-start-generic-modal',
  templateUrl: 'execution-start-generic-modal.component.html',
})
export class ExecutionStartGenericModalComponent {
  @Input() allowFlowSelection = false;
  @Input() companyId?: number;
  @Input() companyFinancialId?: number;
  @Input() flow?: FlowModel;
  @Input() searchTerm?: string;

  customData: any[] = [];
  flows: any[] = [];
  isLoading = false;

  constructor(
    private activeModal: NgbActiveModal,
    private invoiceFlowData: FlowDataService,
    private notificationService: NotificationService
  ) {
    if (this.allowFlowSelection) {
      this.loadFlows();
    }
  }
  dismiss() {
    this.activeModal.dismiss(false);
  }

  loadFlows() {
    this.invoiceFlowData.getActive().subscribe((flowResult) => {
      if (flowResult.hasResult) {
        this.flows = flowResult.result;
        if (this.flows && this.flows.length > 0) {
          this.flow = this.flows[0];
        }
      }
    });
  }

  ok() {
    if (!this.flow) {
      this.notificationService.showWarning(
        'companies.financial.errors.debtorNumberEmpty'
      );
    }

    this.activeModal.close({
      companyId: this.companyId,
      companyFinancialId: this.companyFinancialId,
      invoiceFlow: this.flow,
    });
  }
}

export class ExecutionStartModalResult {
  companyId?: number;
  companyFinancialId?: number;
  invoiceFlow: FlowModel;
  selectedObjects?: any[];
}
