<table class="table table-clickable">
  <thead>
    <tr>
      <th>{{ 'shared.terms.name' | translate }}</th>
      <th>{{ 'companies.contact.model.function' | translate }}</th>
      <th>{{ 'companies.contact.model.status' | translate }}</th>
      <th>{{ 'companies.contact.model.isPrimary' | translate }}</th>
    </tr>
  </thead>
  <tbody>
    <tr
      *ngFor="let contact of contacts"
      (click)="selectContact(contact)"
      [ngClass]="{
        selected: selectedContact && selectedContact.id === contact.id
      }"
    >
      <td>{{ contact.nameInfo }}</td>
      <td>{{ contact.function }}</td>
      <td>
        <i
          *ngIf="contact.status === 1"
          class="fa-regular fa-check text-success mr-2"
        ></i>
        <i
          *ngIf="contact.status === 2"
          class="fa-solid fa-times text-danger mr-2"
        ></i>
        <i
          *ngIf="contact.status === 3"
          class="fa-regular fa-ambulance text-warning mr-2"
        ></i>
        {{ contact.status | contactStatus }}
      </td>
      <td>{{ contact.isPrimaryContact }}</td>
    </tr>
  </tbody>
</table>
