import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MenuEventArgs } from '@syncfusion/ej2-angular-splitbuttons';
import { SeahorseDropDownItem } from './drop-down.model';

@Component({
  selector: 'sh-drop-down',
  templateUrl: './drop-down.component.html',
  styleUrls: ['./drop-down.component.scss'],
})
export class DropDownComponent {
  @Input() options: SeahorseDropDownItem[] = [];
  @Input() showCarret = true;
  @Output() selectItem = new EventEmitter<SeahorseDropDownItem>();

  private _router = inject(Router);

  onSelect(args: MenuEventArgs) {
    const item = args.item as SeahorseDropDownItem;

    if (item.routerLink) {
      this._router.navigate([item.routerLink]);
    } else {
      this.selectItem.emit(item);
    }
  }
}
