<div class="page-with-master-detail">
  <div class="container-fluid py-3">
    <div class="row">
      <div
        class="page-master-wrapper"
        [ngClass]="{ 'page-master-detail-visible': ucrn && ucrn !== '' }"
      >
        <div class="page-master-wrapper-inner">
          <div
            class="text-muted text-center p-4"
            *ngIf="!isLoading && visits.length === 0"
          >
            <i class="fa-duotone fa-exchange-alt fa-10x mb-4"></i>
            <div class="text-lg">
              <p>{{'shared.terms.pageEmpty' | translate}}</p>
            </div>
          </div>
          <div class="card" *ngIf="visits.length > 0">
            <div class="card-header">Recent vertrokken schepen</div>
            <ca-nautical-visit-list
              [visits]="visits"
              [displayFields]="['displayTime','referenceNumber','nautical.ship.name','nautical.ship.identification','agent','portStay']"
              [visitSelected]="selectedVisit"
              (visitSelected)="visitSelected($event)"
            ></ca-nautical-visit-list>
          </div>
          <sh-loading-indicator
            class="text-center d-block"
            *ngIf="isLoading"
          ></sh-loading-indicator>
        </div>
      </div>
      <div
        class="page-detail-wrapper"
        *ngIf="selectedVisit && selectedVisit.referenceNumber && selectedVisit.referenceNumber !== ''"
      >
        <div class="card">
          <div class="card-header">
            <div class="float-right">
              <button
                type="button"
                class="btn btn-clear"
                (click)="setUCRN(null)"
              >
                <i class="fa-solid fa-times fa-fw"></i>
              </button>
            </div>
            <span class="text-uppercase">
              {{selectedVisit.referenceNumber}} - {{selectedVisit.ship.name}}
            </span>
          </div>
          <div class="card-body">
            <ng-container *ngIf="selectedVisit && selectedVisit.ship">
              <h4>Scheepsgegevens</h4>
              <sh-loading-indicator
                *ngIf="!selectedVisit.ship.shipType.name"
              ></sh-loading-indicator>
              <ng-container *ngIf="selectedVisit.ship.shipType.name">
                <div class="attribute-group">
                  <div class="attribute-group-item">
                    <dt>Scheepstype</dt>
                    <dd>{{selectedVisit.ship.shipType.name}}</dd>
                  </div>
                  <div class="attribute-group-item">
                    <dt>DWT:</dt>
                    <dd>{{getShipAttribute('dimensions', 'dwt')}}</dd>
                  </div>
                  <div class="attribute-group-item">
                    <dt>GT:</dt>
                    <dd>{{getShipAttribute('dimensions', 'gt')}}</dd>
                  </div>
                  <div class="attribute-group-item">
                    <dt>LOA:</dt>
                    <dd>{{getShipAttribute('dimensions', 'loa')}}</dd>
                  </div>
                </div>
              </ng-container>
            </ng-container>
            <h4 class="mt-3">Gegevens havenbezoek</h4>
            <div class="attribute-group">
              <div class="attribute-group-item">
                <dt>{{'nautical.terms.ata' | translate}}</dt>
                <dd>{{selectedVisit.ata | date: 'dd-MM-yyyy HH:mm'}}</dd>
              </div>
              <div class="attribute-group-item">
                <dt>{{'nautical.terms.atd' | translate}}</dt>
                <dd>{{selectedVisit.atd | date: 'dd-MM-yyyy HH:mm'}}</dd>
              </div>
              <div class="attribute-group-item">
                <dt>Ligplaats</dt>
                <dd>
                  {{selectedVisit.currentMovement.portWayPointFrom.code}}
                  <span
                    *ngIf="selectedVisit.currentMovement.portWayPointFrom.name"
                    class="text-muted"
                    >({{selectedVisit.currentMovement.portWayPointFrom.name}})</span
                  >
                </dd>
              </div>
              <div class="attribute-group-item">
                <dt>Activiteit</dt>
                <dd>
                  <select
                    class="form-control"
                    id="selectActivity"
                    name="selectActivity"
                    [(ngModel)]="declaration.activity"
                  >
                    <option value="loading">Laden / lossen</option>
                    <option value="lightering" disabled>Lichteren</option>
                    <option value="repairs">Reparaties</option>
                    <option value="waiting">Wachten</option>
                  </select>
                </dd>
              </div>
              <div
                class="attribute-group-item"
                *ngIf="declaration.activity === 'loading' || declaration.activity === 'lightering'"
              >
                <dt>Tonnage</dt>
                <dd>
                  <input
                    type="number"
                    class="form-control"
                    [(ngModel)]="declaration.tonnage"
                  />
                </dd>
              </div>
              <div
                class="attribute-group-item"
                *ngIf="declaration.activity === 'loading' || declaration.activity === 'lightering'"
              >
                <dt>Type lading</dt>
                <dd>
                  <select
                    class="form-control"
                    id="selectCargo"
                    name="selectCargo"
                    [(ngModel)]="declaration.cargo"
                  >
                    <option value="agribulk">
                      Bereidingen gebruik voor het voederen van dieren, droog
                      massagoed
                    </option>
                    <option value="container">Containers</option>
                    <option value="oil">Olieproducten</option>
                    <option value="coal">
                      Steenkolen of cokes (niet-zijnde petroleumcokes)
                    </option>
                  </select>
                </dd>
              </div>
              <div class="mt-3">
                <div
                  class="row"
                  *ngIf="declaration && (!declaration.status || declaration.status !== 'sent')"
                >
                  <div class="col">
                    <button
                      type="button"
                      class="btn btn-primary btn-block"
                      (click)="save(false)"
                    >
                      Alleen opslaan
                    </button>
                  </div>
                  <div class="col">
                    <button
                      type="button"
                      class="btn btn-success btn-block"
                      (click)="save(true)"
                    >
                      Opslaan en versturen
                    </button>
                  </div>
                </div>
                <div *ngIf="declaration && declaration.status !== 'sent'"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
