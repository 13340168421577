import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { CoreModule } from '../../../../core/core.module';
import { LayoutComponentsModule } from '../../../../layout/components/layout-components.module';
import { SharedModule } from '../../../../shared/shared.module';
import { TaskDefinitionSelectFormComponent } from '../../../../tasks/modules/task-definition/components/task-definition-select-form/task-definition-select-form.component';

import { TaskPipesModule } from '../pipes/task-pipes.module';
import { FollowUpTaskFormComponent } from './follow-up-task-form/follow-up-task-form.component';
import { FollowUpTaskModalComponent } from './follow-up-task-modal/follow-up-task-modal.component';
import { FollowUpTasksTableComponent } from './follow-up-tasks-table/follow-up-tasks-table.component';
import { TaskFormComponent } from './task-form/task-form.component';
import { TaskListComponent } from './task-list/task-list.component';
import { TaskModalComponent } from './task-modal/task-modal.component';
import { TaskOverviewHeaderComponent } from './task-overview-header/task-overview-header.component';
import { TaskSummaryComponent } from './task-summary/task-summary.component';
import { TaskTilesComponent } from './task-tiles/task-tiles.component';
import { TasksAdvancedSearchComponent } from './tasks-advanced-search/tasks-advanced-search.component';
import { TasksCardComponent } from './tasks-card/tasks-card.component';
import { SeahorseUiModule } from '@seahorse/ui';
import { SeahorseFormsModule } from '@seahorse/forms';
import { DateTimePickerComponent, FileListCardComponent } from '@seahorse/temp';
import { TruncatePipe } from '@seahorse/common';

@NgModule({
  declarations: [
    TaskTilesComponent,
    TaskListComponent,
    TaskSummaryComponent,
    TasksCardComponent,
    TaskModalComponent,
    TaskFormComponent,
    FollowUpTaskModalComponent,
    FollowUpTaskFormComponent,
    FollowUpTasksTableComponent,
    TaskOverviewHeaderComponent,
    TaskDefinitionSelectFormComponent,
    TasksAdvancedSearchComponent,
  ],
  imports: [
    FormsModule,
    LayoutComponentsModule,
    SharedModule,
    CommonModule,
    RouterModule,
    NgSelectModule,
    NgbModule,
    TaskPipesModule,
    CoreModule,
    ReactiveFormsModule,
    SeahorseFormsModule,
    SeahorseUiModule,
    DateTimePickerComponent,
    FileListCardComponent,
    TruncatePipe,
  ],
  exports: [
    TaskTilesComponent,
    TaskListComponent,
    TaskSummaryComponent,
    TasksCardComponent,
    FollowUpTasksTableComponent,
    TaskOverviewHeaderComponent,
    TasksAdvancedSearchComponent,
  ],
})
export class TaskComponentsModule {}
