export enum MessageType {
  INFO = 'INFO',
  ERROR = 'ERROR',
  PROGRESS = 'PROGRESS',
}

export enum MessageCode {
  COMPLETED_STEP = 'completed_step',
  FAILED_TO_COPY_FILES = 'failed_to_copy_files',
  FAILED_TO_EXECUTE_PS = 'failed_to_execute_ps',
  FAILED_TO_EXECUTE_SQL = 'failed_to_execute_sql',
  SCRIPT_EXECUTION_COMPLETED = 'script_execution_completed',
  SCRIPT_EXECUTION_EXITED = 'script_execution_exited',
  SCRIPT_EXECUTION_STARTED = 'script_execution_started',
  SCRIPT_EXECUTION_SUCESSFUL = 'script_execution_successful',
  SUCCESSFULLY_COPIED_FILES = 'succesfully_copied_files',
  SUCCESSFULLY_EXECUTED_SQL = 'succesfully_executed_sql',
  SYSTEM_ERROR = 'system_error',
  SYSTEM_INFO = 'system_info',
}

export interface CreateEnvironmentMessage {
  timestamp: Date;
  messageType: MessageType;
  messageCode: MessageCode;
  data?: string;
}

export interface CreateEnvironmentProgressMessage {
  timestamp: Date;
  messageCode: MessageCode;
  currentStep: number;
  totalSteps: number;
}

export function createEnvironmentMessageFromString(
  message: string
): CreateEnvironmentMessage | CreateEnvironmentProgressMessage {
  if (!message) {
    return;
  }

  const [timestampStr, messageTypeStr, messageCodeStr, dataStr] =
    message.split('|');

  if (!timestampStr || !messageTypeStr || !messageCodeStr) {
    return;
  }

  const timestamp = new Date(timestampStr);
  const messageType = messageTypeStr as MessageType;
  const messageCode = messageCodeStr as MessageCode;

  if (messageType === MessageType.PROGRESS) {
    const [currentStep, totalSteps] = dataStr.split(',').map((x) => Number(x));

    return {
      timestamp,
      messageCode,
      currentStep,
      totalSteps,
    } as CreateEnvironmentProgressMessage;
  } else {
    return {
      timestamp,
      messageType,
      messageCode,
      data: dataStr || null,
    } as CreateEnvironmentMessage;
  }
}
