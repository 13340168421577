<div class="card">
  <div class="card-header">
    {{ 'invoicingFlows.businessLogic.calculations' | translate }}
    <div class="btn-list">
      <button
        [disabled]="loadingCalculations"
        (click)="addCalculation()"
        type="button"
        class="btn btn-outline-secondary btn-sm"
      >
        <i class="fa-regular fa-plus fa-fw"></i>
        {{ 'shared.terms.add' | translate }}
      </button>
    </div>
  </div>
  <ng-container
    *ngIf="calculations.length > 0; then showCalculations; else noCalculations"
  ></ng-container>

  <ng-template #showCalculations>
    <div class="card-body card-body-table table-responsive">
      <table class="table">
        <tbody>
          <tr *ngFor="let calculation of calculations">
            <td>{{ calculation }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-template>

  <ng-template #noCalculations>
    <div *ngIf="!loadingCalculations" class="card-body">
      <span class="text-muted">{{
        'invoicingFlows.noCalculations' | translate
      }}</span>
    </div>
  </ng-template>
</div>
