import { Component, Input, Output, EventEmitter } from '@angular/core';
import { EventModel } from '../../../../shared/models/event.model';
import { SystemMessageReleaseModel } from '../../../../preference/models/system-message-release.model';

@Component({
  selector: 'ca-system-message-release-list',
  templateUrl: 'system-message-release-list.component.html',
})
export class SystemMessageReleaseListComponent {
  @Input() systemMessageReleases: SystemMessageReleaseModel[];

  @Output() onEvent: EventEmitter<EventModel>;

  constructor() {
    this.onEvent = new EventEmitter<EventModel>();
  }
}
