<ng-container *ngIf="selectSearchField || createShipButton; else plainShipPicker">
  <div class="input-group">
    <div *ngIf="selectSearchField" class="input-group-prepend">
      <select [(ngModel)]="searchField" class="form-control">
        <option *ngFor="let searchFieldOption of searchFieldOptions" [ngValue]="searchFieldOption">
          {{ 'nautical.ship.model.' + searchFieldOption | translate }}
        </option>
      </select>
    </div>
    <input
      [(ngModel)]="nauticalShip"
      [class.is-invalid]="nauticalShipsLoadingFailed"
      [ngbTypeahead]="searchNauticalShip"
      [inputFormatter]="nauticalShipFormatter"
      [resultFormatter]="nauticalShipsFormatter"
      [editable]="editable"
      [placeholder]="
        ('nautical.ship.pickerDialog.searchNauticalShip' | translate) + '...'
      "
      [required]="nauticalShipRequired"
      id="typeahead-nauticalShip"
      name="nauticalShip"
      type="text"
      class="form-control"
      (selectItem)="itemSelected($event)"
    />
    <div class="input-group-append">
      <button class="btn btn-outline-secondary btn-xs clear-input" (click)="setNull()" type="button">
        <i class="fa-solid fa-times fa-xs fa-fw"></i>
      </button>
      <button *ngIf="createShipButton" class="btn btn-outline-secondary" (click)="createShip()" type="button">
        <i class="fa-regular fa-plus"></i>
      </button>
    </div>
  </div>
</ng-container>
<ng-template #plainShipPicker>
  <input
    [(ngModel)]="nauticalShip"
    [class.is-invalid]="nauticalShipsLoadingFailed"
    [ngbTypeahead]="searchNauticalShip"
    [inputFormatter]="nauticalShipFormatter"
    [resultFormatter]="nauticalShipsFormatter"
    [editable]="editable"
    [placeholder]="
      ('nautical.ship.pickerDialog.searchNauticalShip' | translate) + '...'
    "
    [required]="nauticalShipRequired"
    id="typeahead-nauticalShip"
    name="nauticalShip"
    type="text"
    class="form-control"
    (selectItem)="itemSelected($event)"
  />
</ng-template>
