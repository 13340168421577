import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NotificationService } from '@seahorse/common';
import { FileModel, FileService } from '@seahorse/domain';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ca-nautical-ship-files-list',
  templateUrl: './nautical-ship-files-list.component.html',
  styleUrls: ['./nautical-ship-files-list.component.scss'],
})
export class NauticalShipFilesListComponent implements OnInit, OnDestroy {
  @Input() shipId: number;
  @Input() files: FileModel[];

  private subscriptions$ = Array<Subscription>();

  constructor(
    private filesService: FileService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.files.map(function (file) {
      file.icon =
        '../../../../../assets/images/file-types-icons/' +
        file.fileType.replace('.', '') +
        '.png';
      return file;
    });
  }

  ngOnDestroy() {
    this.subscriptions$.forEach((s) => s.unsubscribe());
  }

  delete(file: FileModel) {
    this.subscriptions$.push(
      this.filesService.deleteFile(file.id).subscribe(
        (response) => {
          this.notificationService.showSuccess('File deleted');
          this.files.splice(
            this.files.findIndex((x) => x.id === file.id),
            1
          );
        },
        () => {},
        () => {}
      )
    );
  }

  download(file: FileModel) {
    this.subscriptions$.push(
      this.filesService.getFileById(file.id).subscribe(
        (response) => {
          if (response.hasResult && response.result) {
            const blob = new Blob(
              [this.filesService.base64ToArrayBuffer(response.result.content)],
              { type: response.result.mimeType }
            );
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download =
              response.result.name + '' + response.result.fileType;
            link.click();
          }
        },
        () => {},
        () => {}
      )
    );
  }
}
