import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { FieldMode } from '@seahorse/domain';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[tempFieldMode]',
  standalone: true,
})
export class FieldModeDirective implements OnInit {
  @Input('tempFieldMode') mode: FieldMode;
  @Input('tempFieldModeValue') value: any;

  private get _elementRef() {
    return this.templateRef.elementRef;
  }

  private get _hostElement() {
    return (this._elementRef.nativeElement as HTMLElement).parentElement;
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}

  ngOnInit() {
    if (this.mode && this.mode.display) {
        this.displayInMode(this.mode.display);
      } else {
        this.show();
      }
  }

  private displayInMode(mode: string) {
    switch (mode) {
      // boolean
      case 'yes/no':
        this.show();
        break;
      case 'icon':
        this.renderIconMode();
        this.show();
        break;
      // singleLineText
      case 'plain':
        this.show();
        break;
      case 'password':
        this.renderPasswordMode();
        this.show();
        break;
      // status TODO status rendering in details page
      // case 'bar':
      //   this.show();
      //   break;
      // case 'battery':
      //   this.show();
      //   break;
      // case 'percentage':
      //   this.show();
      //   break;
      default:
        this.show();
        break;
    }
  }

  private show() {
    this.viewContainer.clear();
    this.viewContainer.createEmbeddedView(this.templateRef);
  }

  private renderIconMode() {
    const icon = document.createElement('i');
    const iconClass = this.value ? 'fa-check' : 'fa-times';
    icon.classList.add('fa', iconClass);
    this._hostElement.replaceChild(icon, this._elementRef.nativeElement);
  }

  private renderPasswordMode() {
      const mask = this.value.replace(/./g, '•');
      const span = document.createElement('span');
      span.innerText = mask;
      this._hostElement.replaceChild(span, this._elementRef.nativeElement);
  }
}
