import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceConnectionSubscriptionModel } from '../../../service-connections/models/service-connection-subscription.model';
import { ServiceConnectionsDataService } from '../../../service-connections/services/service-connections.service';

@Component({
  selector: 'user-service-connections',
  templateUrl: './user-service-connections.component.html',
})
export class UserServiceConnectionsComponent {
  @Input() subscriptions: ServiceConnectionSubscriptionModel[];

  constructor(
    private router: Router,
    private serviceConnectionsDataService: ServiceConnectionsDataService
  ) {}

  openConnection(subscription: ServiceConnectionSubscriptionModel) {
    this.router.navigate(['service-connections/tokens/overview']);
    this.serviceConnectionsDataService.setServiceConnection(subscription.id);
  }
}
