<div class="card mb-3" *ngFor="let systemMessage of systemMessages">
  <div class="card-header">
    <span class="text-uppercase">
      {{ systemMessage.subject }}
    </span>
  </div>

  <div class="card-body">
    <ul class="list-inline list-inline-seperated">
      <li class="list-inline-item">
        <b>{{ 'systemMessage.version' | translate }}: </b
        >{{ systemMessage.version }}
      </li>
      <li class="list-inline-item">
        <b>{{ 'systemMessage.createdOn' | translate }}: </b
        >{{ systemMessage.createdOn | utcToLocalDatePipe }}
      </li>
    </ul>
    <div class="pt-3" style="white-space: pre-wrap">
      {{ systemMessage.notes }}
    </div>
  </div>
</div>
