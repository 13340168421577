import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseControl } from '../base-control';
import { TranslateModule } from '@ngx-translate/core';
import { IncrementValuesListItem } from '../../form/form.model';

@Component({
  selector: 'temp-numeric-input',
  templateUrl: 'numeric-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumericInputComponent),
      multi: true,
    },
    {
      provide: BaseControl,
      useExisting: forwardRef(() => NumericInputComponent),
    },
  ],
  exportAs: 'numericInput',
  standalone: true,
  imports: [CommonModule, FormsModule, TranslateModule],
})
export class NumericInputComponent
  extends BaseControl<number>
  implements AfterViewInit
{
  private decimalSeperator = ','; // Should be more dynamic
  private hasLoaded = false;

  private numberValue: number = null;
  inputValue: string = null;

  @Input() disabled = false;
  @Input() appendText: string;

  @Input() appendList: any[] = null;
  @Input() numDecimals = 0;
  @Input() get number(): number {
    return this.numberValue;
  }
  set number(value: number) {
    if (value == null) {
      this.numberValue = null;
      this.inputValue = null;
      return;
    }
    this.numberValue = value;
    let formatted = value.toString();
    if (this.numDecimals > 0) {
      formatted = (Math.round(value * 100) / 100).toFixed(this.numDecimals);
    }
    this.inputValue = formatted.replace('.', this.decimalSeperator);
  }
  @Input() placeholder = '';
  @Input() prependText: string = null;
  @Input() required: boolean;
  @Input() submitted = false;
  @Input() incrementValuesList?: IncrementValuesListItem[];

  appendItem: any;
  @Output() appendListItemSelected = new EventEmitter<any>();
  @Output() numberChange = new EventEmitter<number>();

  constructor() {
    super();

    this.appendText = null;
  }

  ngAfterViewInit() {
    this.hasLoaded = true;
  }

  inputChanged() {
    if (!this.hasLoaded) {
      return;
    }

    let formattedInput = null;

    if (this.inputValue != null) {
      formattedInput = this.inputValue.replace(this.decimalSeperator, '.');
    }

    const parsedInput = parseFloat(formattedInput);

    if (!isNaN(parsedInput)) {
      this.numberValue = parsedInput;
    } else {
      this.numberValue = null;
    }
    this.numberChange.emit(this.numberValue);
    this.emitOnChange(this.numberValue);
  }

  selectAppendItem(item: any) {
    this.appendItem = item;
    this.appendListItemSelected.emit(item);
  }

  incrementValue(ticks: number) {
    if (!this.disabled) {
      this.number = this.number ? this.number + ticks : ticks;
      this.inputChanged();
    }
  }
}
