<div
  class="page-content-overlay page-content-overlay-left"
  [ngClass]="{
    'page-content-overlay-visible':
      (aisInfo && aisInfo.mmsi) || (ship && ship.id)
  }"
>
  <div class="card" style="background-color: transparent">
    <div class="card-header" *ngIf="ship">
      {{
        (ship ? ship.name : aisInfo.name ? aisInfo.name : 'unknown') | uppercase
      }}
      <div class="btn-list" *ngIf="ship">
        <button
          type="button"
          class="btn btn-light"
          (click)="addToMyFleet()"
          *ngIf="ship.id"
        >
          <i class="fa-regular fa-star fa-fw"></i>
        </button>
        <button
          type="button"
          class="btn btn-light"
          (click)="navigate('/nautical/vessels/' + this.ship.id)"
          *ngIf="ship.id"
          title="{{ 'shared.terms.details' | translate }}"
        >
          <i class="fad fa-arrow-up-right-from-square fa-fw"></i>
        </button>
        <button
          type="button"
          class="btn btn-light"
          (click)="clear()"
          title="{{ 'shared.terms.close' | translate }}"
        >
          <i class="fad fa-times fa-fw"></i>
        </button>
      </div>
    </div>
    <ng-container *ngIf="ship">
      <div
        class="card-image"
        *ngIf="ship.imo && ship.imo !== '0' && showPhoto"
        [ngStyle]="{
          'background-image':
            'url(https://dir.ship2report.com/' + ship.imo + '.jpg)'
        }"
      >
        <img
          src="{{ 'https://dir.ship2report.com/' + ship.imo + '.jpg' }}"
          style="display: none"
          (error)="showPhoto = false"
        />
      </div>
      <div
        class="card-body card-body-table"
        *ngIf="!isLoading && shipDetailGroups && shipDetailGroups.length > 0"
      >
        <div
          class="tablist tablist-accordion"
          id="shipDetailsList"
          role="tablist"
          aria-multiselectable="false"
        >
          <ng-container
            *ngFor="let shipDetailGroup of shipDetailGroups; let i = index"
          >
            <div
              class="tablist-item"
              role="tab"
              [attr.id]="'sectionHeaderId_' + i"
              *ngIf="
                (shipDetailGroup.data && shipDetailGroup.data.length > 0) ||
                (shipDetailGroup.items && shipDetailGroup.items.length > 0)
              "
            >
              <a
                class="tablist-header"
                data-toggle="collapse"
                data-parent="#shipDetailsList"
                [attr.href]="'#sectionContentId_' + i"
                aria-expanded="false"
                [attr.aria-controls]="'sectionContentId_' + i"
                [ngClass]="{
                  'tablist-header-with-data': shipDetailGroup.display
                }"
              >
                <ng-container *ngIf="shipDetailGroup.display">
                  <label class="mb-0">{{
                    shipDetailGroup.label | translate
                  }}</label>
                  <span
                    *ngFor="
                      let displayItem of shipDetailGroup.display;
                      let isLast = last
                    "
                  >
                    <span class="text-muted"
                      >{{ displayItem.key | translate }}
                    </span>
                    <span class="font-weight-bold"
                      >{{ displayItem.value }}{{ !isLast ? ', ' : '' }}</span
                    >
                  </span>
                </ng-container>
                <ng-container *ngIf="!shipDetailGroup.display">
                  {{ shipDetailGroup.label | translate }}
                </ng-container>
                <span class="tablist-item-chevron">
                  <i class="fa-regular fa-chevron-down fa-fw"></i>
                </span>
              </a>
              <ul
                [attr.id]="'sectionContentId_' + i"
                class="tablist-list collapse in"
                role="tabpanel"
                [attr.aria-labelledby]="'sectionHeaderId_' + i"
              >
                <li *ngFor="let subItem of shipDetailGroup.items">
                  <label>{{ subItem.key | translate }}</label>
                  {{ subItem.value }}
                  <temp-copy-content-button
                    [content]="subItem.value"
                    class="float-right"
                  ></temp-copy-content-button>
                </li>
                <li
                  *ngFor="let dataItem of shipDetailGroup.data"
                  [ngClass]="{ clickable: dataItem.url }"
                  (click)="navigate(dataItem.url)"
                >
                  {{ dataItem.display }}
                  <i
                    class="fa-regular fa-chevron-right float-right mt-1"
                    *ngIf="dataItem.url"
                  ></i>
                </li>
              </ul>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="px-3 mt-3" *ngIf="visitActive">
        <div class="card">
          <div class="card-header">
            {{ 'nautical.visit.portVisit' | translate }} ({{
              'nautical.visit.filter.inport' | translate
            }})
            <div class="btn-list">
              <button
                type="button"
                class="btn btn-light"
                (click)="navigate('/nautical/visit/' + visitActive.id)"
                *ngIf="visitActive.id"
                title="{{ 'shared.terms.details' | translate }}"
              >
                <i class="fad fa-arrow-up-right-from-square fa-fw"></i>
              </button>
            </div>
          </div>
          <div class="card-body">
            <temp-attribute-list
              [attributes]="visitActive.attributes"
            ></temp-attribute-list>
          </div>
        </div>
      </div>
      <div
        class="px-3 mt-3"
        *ngIf="shipDataGroups && shipDataGroups.length > 0"
      >
        <div
          class="tablist tablist-accordion"
          id="shipDataList"
          role="tablist"
          aria-multiselectable="false"
        >
          <ng-container
            *ngFor="let shipDataGroup of shipDataGroups; let i = index"
          >
            <div
              class="tablist-item"
              role="tab"
              [attr.id]="'sectionDataHeaderId_' + i"
              *ngIf="
                (shipDataGroup.data && shipDataGroup.data.length > 0) ||
                (shipDataGroup.items && shipDataGroup.items.length > 0)
              "
            >
              <a
                class="tablist-header"
                data-toggle="collapse"
                data-parent="#shipDataList"
                [attr.href]="'#sectionDataContentId_' + i"
                aria-expanded="false"
                [attr.aria-controls]="'sectionDataContentId_' + i"
                [ngClass]="{
                  'tablist-header-with-data': shipDataGroup.display
                }"
              >
                <ng-container *ngIf="shipDataGroup.display">
                  <label class="mb-0">{{
                    shipDataGroup.label | translate
                  }}</label>
                  <span
                    *ngFor="
                      let displayItem of shipDataGroup.display;
                      let isLast = last
                    "
                  >
                    <span class="text-muted"
                      >{{ displayItem.key | translate }}
                    </span>
                    <span class="font-weight-bold"
                      >{{ displayItem.value }}{{ !isLast ? ', ' : '' }}</span
                    >
                  </span>
                </ng-container>
                <ng-container *ngIf="!shipDataGroup.display">
                  {{ shipDataGroup.label | translate }}
                </ng-container>
                <span class="tablist-item-chevron">
                  <i class="fa-regular fa-chevron-down fa-fw"></i>
                </span>
              </a>
              <ul
                [attr.id]="'sectionDataContentId_' + i"
                class="tablist-list collapse in"
                role="tabpanel"
                [attr.aria-labelledby]="'sectionDataHeaderId_' + i"
              >
                <li
                  class="tablist-list-item"
                  *ngFor="let subItem of shipDataGroup.items"
                >
                  <label>{{ subItem.key | translate }}</label>
                  {{ subItem.value }}
                  <temp-copy-content-button
                    [content]="subItem.value"
                    class="float-right"
                  ></temp-copy-content-button>
                </li>
                <li
                  class="tablist-list-item"
                  *ngFor="let dataItem of shipDataGroup.data"
                  [ngClass]="{ clickable: dataItem.url }"
                  (click)="navigate(dataItem.url)"
                >
                  {{ dataItem.display }}
                  <i
                    class="fa-regular fa-chevron-right float-right mt-1"
                    *ngIf="dataItem.url"
                  ></i>
                </li>
              </ul>
            </div>
          </ng-container>
        </div>
      </div>
      <div>
        <ul class="summary-buttons">
          <!-- <li>
            <a class="summary-button" href="#">
              <i class="summary-button-icon fa-regular fa-plus fa-fw fa-2x"></i>
              <span class="summary-button-text">BUTTON!</span></a
            >
          </li> -->
        </ul>
      </div>
    </ng-container>
    <ng-container *ngIf="!ship || isLoading">
      <div class="card-body card-body-table">
        <div class="text-center" *ngIf="isLoading">
          <sh-loading-indicator></sh-loading-indicator>
        </div>
        <div class="p-3 text-muted" *ngIf="!isLoading && !ship">
          The specified ship could not be found
        </div>
      </div>
    </ng-container>
  </div>
</div>
