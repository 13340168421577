import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from '../../../core/core.module';
import { LayoutComponentsModule } from '../../../layout/components/layout-components.module';
import { NauticalShipServicesModule } from '../../../nautical-ship/services/nautical-ship-services.module';
import { NauticalVisitComponentsModule } from '../../../nautical-visit/components/nautical-visit-components.module';
import { NauticalVisitServicesModule } from '../../../nautical-visit/services/nautical-visit-services.module';
import { SharedModule } from '../../../shared/shared.module';

import { CustomKPPipesModule } from '../pipes/kp-pipes.module';
import { KPActivitiesContainerComponent } from './activities-container/activities-container.component';
import { KPActivitiesListComponent } from './activities-list/activities-list.component';
import { KPAddActivityModal } from './add-activity-modal/add-activity-modal.component';
import { KPCompleteActivityModal } from './complete-activity-modal/complete-activity-modal.component';
import { KPRemarksModal } from './remarks-modal/remarks-modal.component';
import { FileDragDropDirective } from '@seahorse/temp';
import { ContentDistributionComponentSharedModule } from '../../../content-distribution/components/content-distribution-component-shared.module';
import { TruncatePipe } from '@seahorse/common';

@NgModule({
  declarations: [
    KPActivitiesContainerComponent,
    KPActivitiesListComponent,
    KPAddActivityModal,
    KPCompleteActivityModal,
    KPRemarksModal,
  ],
  exports: [
    KPActivitiesContainerComponent,
    KPActivitiesListComponent,
    KPAddActivityModal,
    KPCompleteActivityModal,
    KPRemarksModal,
  ],
  imports: [
    CommonModule,
    CoreModule,
    CustomKPPipesModule,
    FormsModule,
    SharedModule,
    TranslateModule,
    LayoutComponentsModule,
    NauticalShipServicesModule,
    NauticalVisitComponentsModule,
    NauticalVisitServicesModule,
    TruncatePipe,

    NgbModule,
    FileDragDropDirective,
    ContentDistributionComponentSharedModule,
  ],
})
export class CustomKPComponentsModule {}
