<div class="card">
  <div class="card-header">
    <button
      (click)="onEvent.emit({ action: 'select', data: null })"
      type="button"
      class="btn btn-clear float-right"
    >
      <i class="fa-solid fa-times fa-fw"></i>
    </button>
    <span class="text-uppercase">{{
      'tasks.definitionlist.summary' | translate
    }}</span>
  </div>
  <div class="card-body">
    <task-definition-list-info
      [taskDefinitionList]="taskDefinitionList"
    ></task-definition-list-info>
    <task-definition-info
      [taskDefinitions]="taskDefinitions"
      [loading]="loadingTaskDefinitions"
    ></task-definition-info>
  </div>
  <ul class="summary-buttons">
    <li>
      <button
        routerLink="/tasks/definition/{{ taskDefinitionList.__Id }}"
        type="button"
        class="summary-button w-100"
      >
        <i class="fa-regular fa-folder-open"></i>
        <span class="summary-button-text">{{
          'tasks.definitionlist.viewDetails' | translate
        }}</span>
      </button>
    </li>
  </ul>
</div>
