import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LayoutComponentsModule } from '../../layout/components/layout-components.module';
import { LayoutPipesModule } from '../../layout/pipes/layout-pipes.module';
import { SharedModule } from '../../shared/shared.module';

import { OrderRegistrationsPipesModule } from '../pipes/order-registrations-pipes.module';
import { AttributePickerModalComponent } from './attribute-picker-modal/attribute-picker-modal.component';
import { OrderRegistrationDetailsHeaderComponent } from './order-registration-details-header/order-registration-details-header.component';
import { OrderRegistrationFactoryHeaderComponent } from './order-registration-factory-header/order-registration-factory-header.component';
import { OrderRegistrationFeePickerModalComponent } from './order-registration-fee-picker-modal/order-registration-fee-picker-modal.component';
import { OrderRegistrationPickerDialogFormComponent } from './order-registration-picker-dialog-form/order-registration-picker-dialog-form.component';
import { OrderRegistrationSummaryComponent } from './order-registration-summary/order-registration-summary.component';
import { OrderRegistrationsOverviewHeaderComponent } from './order-registrations-overview-header/order-registrations-overview-header.component';
import { OrderRegistrationsOverviewListComponent } from './order-registrations-overview-list/order-registrations-overview-list.component';
import { OrderRegistrationsOverviewTilesComponent } from './order-registrations-overview-tiles/order-registrations-overview-tiles.component';
import { TariffPickerModalComponent } from './tariff-picker-modal/tariff-picker-modal.component';
import { CrewFactoryDialogComponent } from './crew-factory-dialog-component/crew-factory-dialog.component';
import { HotelStayDialogComponent } from './hotel-stay-dialog-component/hotel-stay-dialog.component';
import { PartDialogComponent } from './part-dialog-component/part-dialog.component';
import { CustomDataPipesModule } from '../../custom-data/pipes/custom-data-pipes.module';
import { ContentDistributionComponentSharedModule } from '../../content-distribution/components/content-distribution-component-shared.module';
import {
  DateTimePickerComponent,
  FieldValuePipe,
  FormComponent,
} from '@seahorse/temp';

@NgModule({
  declarations: [
    OrderRegistrationsOverviewHeaderComponent,
    OrderRegistrationsOverviewListComponent,
    OrderRegistrationsOverviewTilesComponent,
    OrderRegistrationDetailsHeaderComponent,
    OrderRegistrationFactoryHeaderComponent,
    OrderRegistrationSummaryComponent,
    OrderRegistrationFeePickerModalComponent,
    OrderRegistrationPickerDialogFormComponent,
    AttributePickerModalComponent,
    TariffPickerModalComponent,
    CrewFactoryDialogComponent,
    HotelStayDialogComponent,
    PartDialogComponent,
  ],
  imports: [
    CommonModule,
    ContentDistributionComponentSharedModule,
    RouterModule,
    FormsModule,
    NgbModule,
    SharedModule,
    CustomDataPipesModule,
    LayoutComponentsModule,
    LayoutPipesModule,
    OrderRegistrationsPipesModule,
    DateTimePickerComponent,
    FieldValuePipe,
    FormComponent,
  ],
  exports: [
    OrderRegistrationsOverviewHeaderComponent,
    OrderRegistrationsOverviewListComponent,
    OrderRegistrationsOverviewTilesComponent,
    OrderRegistrationDetailsHeaderComponent,
    OrderRegistrationFactoryHeaderComponent,
    OrderRegistrationSummaryComponent,
    OrderRegistrationPickerDialogFormComponent,
  ],
})
export class OrderRegistrationsComponentsModule {}
