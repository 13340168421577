import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { TaskStatus } from '../models/task.model';

@Pipe({ name: 'taskStatus' })
export class TaskStatusPipe implements PipeTransform {
  Statuses: Object;

  constructor(private translate: TranslateService) {
    this.Statuses = {
      [TaskStatus.Todo]: 'tasks.task.model.taskStatuses.toDo',
      [TaskStatus.Done]: 'tasks.task.model.taskStatuses.done',
      [TaskStatus.Cancelled]: 'tasks.task.model.taskStatuses.cancelled',
      [TaskStatus.InProgress]: 'tasks.task.model.taskStatuses.inProgress',
    };
  }

  transform(taskStatus: TaskStatus): string {
    let pipe: string;

    this.translate.get(this.Statuses[taskStatus]).subscribe((r) => (pipe = r));

    return pipe;
  }
}
