import { Component, ViewChild } from '@angular/core';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';

@Component({
  selector: 'sh-modal',
  template: `<ejs-dialog
    #dialog
    [visible]="false"
    isModal="true"
    width="500px"
    showCloseIcon="true"
  >
    <ng-template #header>
      <ng-content select="[header]"></ng-content>
    </ng-template>
    <ng-template #content>
      <ng-content select="[body]"></ng-content>
    </ng-template>
    <ng-template #footerTemplate>
      <ng-content select="[footer]"></ng-content>
    </ng-template>
  </ejs-dialog> `,
})
export class ModalComponent {
  @ViewChild('dialog') instance!: DialogComponent;

  show() {
    this.instance.show();
  }

  hide() {
    this.instance.hide();
  }
}
