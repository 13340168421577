import { Directive, TemplateRef, ViewContainerRef, Input } from '@angular/core';
import { IdentityService } from '../../core/services/identity.service';

@Directive({
  selector: '[hasPermission]',
})
export class HasPermissionDirective {
  private hasView = false;

  @Input() set hasPermission(permission: string) {
    if (this.identity.isAuthenticated) {
      if (
        !permission ||
        (this.identity.hasPermission(permission) && !this.hasView)
      ) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.hasView = true;
      } else if (!this.identity.hasPermission(permission) && this.hasView) {
        this.viewContainer.clear();
        this.hasView = false;
      }
    } else if (this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }

  constructor(
    private identity: IdentityService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}
}
