import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { WidgetModel, WidgetTypes } from '../../models/widget.model';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { InvoiceWidgetDesignerComponent } from '../invoice-widget-designer/invoice-widget-designer.component';
import { WidgetDataService } from '../../services/widget-data.service';
import { Subscription } from 'rxjs';
import { TaskWidgetDesignerComponent } from '../task-widget-designer/task-widget-designer.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalAction, ModalResponse } from '@seahorse/temp';
import { IdentityService } from '../../../core/services/identity.service';

@Component({
  selector: 'ca-widget-designer',
  templateUrl: './widget-designer.component.html',
  styleUrls: ['./widget-designer.component.scss'],
})
export class WidgetDesignerComponent implements OnInit, OnDestroy {
  types = [
    { id: WidgetTypes.Invoice, name: 'Invoice' },
    { id: WidgetTypes.Task, name: 'Task' },
  ];

  widgetTypes = WidgetTypes;
  selectedType: WidgetTypes = null;
  name: string;

  @ViewChild(InvoiceWidgetDesignerComponent)
  invoiceDesigner: InvoiceWidgetDesignerComponent;

  @ViewChild(TaskWidgetDesignerComponent)
  taskWidgetDesigner: TaskWidgetDesignerComponent;

  widget: WidgetModel;
  configuration: any;
  isEdit = false;

  private subscriptions = new Subscription();

  constructor(
    private _widgetDataService: WidgetDataService,
    private _translateService: TranslateService,
    private _toastService: ToastrService,
    private _activeModal: NgbActiveModal,
    private _identityService: IdentityService
  ) {}

  ngOnInit(): void {
    const widgetId = this.widget?.id?.toString();

    if (widgetId) {
      this.isEdit = true;
      this.subscriptions.add(
        this._widgetDataService.getById(widgetId).subscribe((result) => {
          this.widget = result.result;
          this.selectedType = result.result.type;
          this.name = result.result.name;
          this.configuration = JSON.parse(result.result.configuration);
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  save() {
    if (this.selectedType === null) {
      this._toastService.info('You must select a widget type');
      return;
    }

    let widgetData;

    switch (this.selectedType) {
      case WidgetTypes.Invoice:
        widgetData = this.invoiceDesigner.getWidgetData();
        break;
      case WidgetTypes.Task:
        widgetData = this.taskWidgetDesigner.getWidgetData();
        break;
      default:
        console.error('Unsupported widget type:', this.selectedType);
        return;
    }

    const widget: WidgetModel = {
      name: this.name ?? 'New widget',
      type: this.selectedType,
      organisationId: this._identityService.identity.organisationId,
      configuration: JSON.stringify(widgetData),
    };

    if (this.isEdit) {
      widget.id = this.widget.id;
      this.subscriptions.add(
        this._widgetDataService.update(widget).subscribe(() => {
          this._toastService.success(
            this._translateService.instant('shared.terms.updateSuccess')
          );
          const response: ModalResponse = { action: ModalAction.Confirm };
          this._activeModal.close(response);
        })
      );
    } else {
      this.subscriptions.add(
        this._widgetDataService.add(widget).subscribe((response) => {
          this._toastService.success(
            this._translateService.instant('shared.terms.addSuccess')
          );
          if (response.hasResult) {
            const modal: ModalResponse = {
              action: ModalAction.Confirm,
              componentRef: {
                ...response.result,
                ...{ configuration: JSON.parse(response.result.configuration) },
              },
            };
            this._activeModal.close(modal);
          }
        })
      );
    }
  }

  cancel() {
    const response: ModalResponse = { action: ModalAction.Close };
    this._activeModal.close(response);
  }
}
