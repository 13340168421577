export class UiModuleCalendarConfiguration {
  static default(): UiModuleCalendarConfiguration {
    return {
      id: null,
      summaryConfig: {
        fields: null,
      },
      headerConfig: {
        showExportButton: true,
        showAddButton: true,
        showSimpleSearch: true,
        showAdvancedSearch: true,
      },
      overviewConfig: {
        startField: null,
        endField: null,
        dayStart: '06:00',
        dayEnd: '24:00',
        viewOptions: ['agenda'],
        viewDefault: 'agenda',
        viewPeriod: 'current',
      },
    };
  }
}

export interface UiModuleCalendarConfiguration {
  id: number;
  headerConfig: UiModuleCalendarHeaderConfigModel;
  summaryConfig: UiModuleCalendarSummaryConfig;
  overviewConfig: UiModuleCalendarOverviewConfigModel;
}

export interface UiModuleCalendarHeaderConfigModel {
  showExportButton: boolean;
  showAddButton: boolean;
  showSimpleSearch: boolean;
  showAdvancedSearch: boolean;
}

export interface UiModuleCalendarSummaryConfig {
  fields: string[];
}

export interface UiModuleCalendarOverviewConfigModel {
  startField: string;
  endField: string;
  dayStart: string;
  dayEnd: string;
  viewOptions: CalendarViewOption[];
  viewDefault: CalendarViewOption;
  viewPeriod: CalendarViewPeriod;
}

export type CalendarViewOption =
  | 'agenda'
  | 'day'
  | 'workWeek'
  | 'week'
  | 'month'
  | 'timelineDay'
  | 'timelineWeek'
  | 'timelineWorkWeek'
  | 'timelineMonth';

export type CalendarViewPeriod = 'current' | 'previous';
