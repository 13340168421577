<div class="card">
  <div class="card-header">
    {{ 'companies.department.departments' | translate }}
    <div class="btn-list">
      <button
        (click)="onEvent.emit({ action: 'editDepartment' })"
        type="button"
        class="btn btn-outline-secondary btn-sm"
      >
        <i class="fa-regular fa-plus fa-fw"></i>
        {{ 'shared.terms.add' | translate }}
      </button>
    </div>
  </div>

  <div
    class="card-body card-body-table"
    *ngIf="departments && departments.length > 0"
  >
    <ca-company-departments-list
      [departments]="departments"
      (onEvent)="onEvent.emit($event)"
      [showHeader]="true"
    >
    </ca-company-departments-list>
  </div>
  <div class="card-body" *ngIf="!departments || departments.length === 0">
    <div class="text-center text-muted">
      {{ 'companies.department.noDepartments' | translate }}
    </div>
  </div>
  <div
    class="card-footer card-footer-clickable"
    (click)="loadMore()"
    *ngIf="canLoadMore"
  >
    <span> {{ 'shared.terms.loadMore' | translate }} </span>
  </div>
</div>
