import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CompanyDepartmentModel } from '@seahorse/domain';

@Component({
  selector: 'ca-company-departments-list',
  templateUrl: 'company-departments-list.component.html',
})
export class CompanyDepartmentsListComponent {
  @Input() departments: CompanyDepartmentModel[];
  @Input() showHeader: boolean;

  @Output() onEvent: EventEmitter<CompanyDepartmentModel>;

  constructor() {
    this.departments = [];
    this.showHeader = false;
    this.onEvent = new EventEmitter<CompanyDepartmentModel>();
  }
}
