import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from '@seahorse/common';
import { FleetGroup } from '../../../map/models/fleet-group.model';
import { GroupService } from '../../../map/services/group.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ca-group-modal',
  templateUrl: './group-modal.component.html',
  styleUrls: ['./group-modal.component.scss'],
})
export class GroupModalComponent implements OnInit, OnDestroy {
  private subscriptions$ = Array<Subscription>();
  group: FleetGroup;
  isSubmitted: boolean;
  saveStarted: boolean;
  isNew: boolean;

  constructor(
    private groupService: GroupService,
    private notificationService: NotificationService,
    private modal: NgbActiveModal
  ) {}

  ngOnInit(): void {
    if (!this.group) {
      this.isNew = true;

      this.group = new FleetGroup();
    } else {
      this.isNew = false;

      this.group = JSON.parse(JSON.stringify(this.group));
    }
  }

  ngOnDestroy() {
    this.subscriptions$.forEach((s) => s.unsubscribe());
  }

  save() {
    if (!FleetGroup.validate(this.group)) {
      this.isSubmitted = true;
      return;
    }

    this.saveStarted = true;

    if (this.isNew) {
      this.addGroup();
    } else {
      this.editGroup();
    }
  }

  addGroup() {
    this.subscriptions$.push(
      this.groupService.createGroup(this.group).subscribe(
        (response) => {
          if (response.hasResult) {
            this.notificationService.showSuccess(
              'New fleet group added!',
              'Successfully'
            );
          }

          this.saveStarted = false;

          this.modal.close(response.result);
        },
        () => {},
        () => {}
      )
    );
  }

  editGroup() {
    this.subscriptions$.push(
      this.groupService.updateGroup(this.group).subscribe(
        (response) => {
          if (response.hasResult) {
            this.notificationService.showSuccess(
              'Updated fleet group!',
              'Successfully'
            );
          }

          this.saveStarted = false;

          this.modal.close(response.result);
        },
        () => {},
        () => {}
      )
    );
  }
}
