<div class="row">
  <div class="col-9">
    <div class="d-flex justify-content-center mt-5 mb-5">
      <div class="preview-section">
        <h3 class="text-center">
          {{ 'widgets.designer.preview' | translate }}
        </h3>
        <ca-invoice-widget [widget]="widget"></ca-invoice-widget>
      </div>
    </div>
  </div>
  <div class="col-3">
    <div class="card mb-3">
      <div class="card-header">
        {{ 'widgets.designer.configuration' | translate }}
      </div>
      <form [formGroup]="form" class="card-body">
        <div class="mb-3">
          <label for="name">
            <b>{{ 'widgets.invoice.title' | translate }}</b>
          </label>
          <input
            type="text"
            class="form-control"
            id="name"
            [(ngModel)]="widget.title"
            [ngModelOptions]="{ standalone: true }"
          />
        </div>
        <div class="mb-3">
          <label>
            <b>{{ 'widgets.invoice.bodyType' | translate }}</b>
          </label>
          <ng-select
            class="mb-3"
            [items]="bodyTypes"
            bindLabel="name"
            bindValue="id"
            [clearable]="false"
            [(ngModel)]="widget.bodyType"
            [ngModelOptions]="{ standalone: true }"
            (change)="changeBodyType($event)"
          >
          </ng-select>
        </div>
        <div class="mb-3">
          <label for="statuses">
            <b>{{ 'widgets.invoice.statuses' | translate }}</b>
          </label>
          <sh-multi-select
            formControlName="statuses"
            [options]="statusOptions"
          ></sh-multi-select>
        </div>
        <div class="mb-3">
          <label for="intervals">
            <b>{{ 'widgets.invoice.intervals' | translate }}</b>
          </label>
          <sh-multi-select
            formControlName="intervals"
            [options]="intervalOptions"
          ></sh-multi-select>
        </div>
        <div *ngIf="selectedIntervals.length > 0" class="mb-3">
          <label>
            <b>{{ 'widgets.invoice.intervalType' | translate }}</b>
          </label>
          <ng-select
            [items]="selectedIntervals"
            bindLabel="text"
            bindValue="id"
            [clearable]="false"
            [(ngModel)]="widget.intervalType"
            [ngModelOptions]="{ standalone: true }"
            (change)="changeIntervalType($event)"
          >
          </ng-select>
        </div>
        <div class="mb-3">
          <label
            ><b>{{ 'widgets.invoice.minDate' | translate }}</b></label
          >
          <div class="input-group">
            <temp-date-time-picker
              [(dateTime)]="widget.minDate"
              [hideTime]="true"
              [isContained]="true"
              [invalidAfter]="widget.maxDate"
              (dateTimeChange)="changeMinDate($event)"
            ></temp-date-time-picker>
          </div>
        </div>
        <div class="mb-3">
          <label
            ><b>{{ 'widgets.invoice.maxDate' | translate }}</b></label
          >
          <div class="input-group">
            <temp-date-time-picker
              [(dateTime)]="widget.maxDate"
              [hideTime]="true"
              [isContained]="true"
              [invalidBefore]="widget.minDate"
              (dateTimeChange)="changeMaxDate($event)"
            ></temp-date-time-picker>
          </div>
        </div>
        <div class="mb-3">
          <label>
            <b>{{ 'widgets.invoice.allowReload' | translate }}</b>
          </label>
          <input
            type="checkbox"
            class="form-check-input ml-2"
            [(ngModel)]="widget.reload"
            [ngModelOptions]="{ standalone: true }"
            (ngModelChange)="changeReload($event)"
          />
        </div>
        <div *ngIf="widget.bodyType === invoiceWidgetBodyTypes.Details">
          <div class="mb-3">
            <label>
              <b>{{ 'widgets.invoice.primaryDisplay' | translate }}</b>
            </label>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="widget.detailsConfiguration.primaryDisplay"
              [ngModelOptions]="{ standalone: true }"
              (change)="changePrimaryDisplay($event)"
            />
          </div>

          <div class="mb-3">
            <label>
              <b>{{ 'widgets.invoice.secondaryDisplay' | translate }}</b>
            </label>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="widget.detailsConfiguration.secondaryDisplay"
              [ngModelOptions]="{ standalone: true }"
              (change)="changeSecondaryDisplay($event)"
            />
          </div>
        </div>
        <div
          *ngIf="
            widget.bodyType === invoiceWidgetBodyTypes.Chart ||
            widget.bodyType === invoiceWidgetBodyTypes.MetaData
          "
        >
          <div class="mb-3">
            <label>
              <b>{{ 'widgets.invoice.chartType' | translate }}</b>
            </label>
            <ng-select
              class="mb-3"
              [items]="chartTypes"
              [clearable]="false"
              [(ngModel)]="widget.chartConfiguration.type"
              [ngModelOptions]="{ standalone: true }"
              (change)="changeChartType($event)"
            >
            </ng-select>
          </div>
          <div class="mb-3">
            <label>
              <b>{{ 'widgets.invoice.showLegend' | translate }}</b>
            </label>
            <input
              type="checkbox"
              class="form-check-input ml-2"
              [(ngModel)]="widget.chartConfiguration.showLegend"
              [ngModelOptions]="{ standalone: true }"
              (ngModelChange)="changeChartConfigProperty('showLegend', $event)"
            />
          </div>
          <div class="mb-3">
            <label>
              <b>{{ 'widgets.invoice.dataBreakdown' | translate }}</b>
            </label>
            <input
              type="text"
              class="form-control"
              id="dataBreakdown"
              [(ngModel)]="widget.chartConfiguration.dataBreakdown"
              [ngModelOptions]="{ standalone: true }"
              (input)="changeDataBreakdown($event)"
            />
          </div>

          <div
            class="mb-3"
            *ngIf="widget.bodyType === invoiceWidgetBodyTypes.MetaData"
          >
            <label>
              <b>{{ 'widgets.invoice.groupingField' | translate }}</b>
            </label>
            <input
              type="text"
              class="form-control"
              id="groupingField"
              [(ngModel)]="widget.metaDataConfiguration.groupingField"
              [ngModelOptions]="{ standalone: true }"
              (input)="changeGroupingField($event)"
            />
          </div>
          <div
            *ngIf="
              widget.bodyType === invoiceWidgetBodyTypes.MetaData &&
              widget.chartConfiguration.type === 'bar'
            "
            class="mb-3"
          >
            <label>
              <b>{{ 'widgets.invoice.columnInterval' | translate }}</b>
            </label>
            <ng-select
              [items]="intervals"
              bindLabel="text"
              bindValue="id"
              [clearable]="false"
              [(ngModel)]="widget.metaDataConfiguration.columnInterval"
              [ngModelOptions]="{ standalone: true }"
              (change)="changeColumnInterval($event)"
            >
            </ng-select>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
