export class FileHelpers {
  static openDocumentBlob(content: any, mimeType: string): void {
    if (!content || !mimeType) {
      return;
    }

    const blob: Blob = new Blob([content], {
      type: mimeType,
    });

    this.openDocument(blob);
  }

  static openDocument(blob: Blob): void {
    const fileURL = window.URL.createObjectURL(blob);
    window.open(fileURL, '_blank');
  }

  static downloadDocumentBlob(
    content: any,
    mimeType: string,
    fileName: string
  ) {
    if (!content || !mimeType) {
      return;
    }

    const blob: Blob = new Blob([content], {
      type: mimeType,
    });

    const link: HTMLAnchorElement = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName + mimeType;

    link.click();
  }
}
