import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as MapboxDraw from '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw';
import { MapDataService } from '@seahorse/domain';
import * as mapboxgl from 'mapbox-gl';
import * as _ from 'underscore';

import { MapInteractionService } from '../../services/map-interaction.service';
import { CoordinatePolygonModel, CoordinatePointModel } from '@seahorse/domain';

@Component({
  selector: 'ca-map-box-display-polygon',
  templateUrl: './map-box-display-polygon.component.html',
  styleUrls: ['./map-box-display-polygon.component.scss'],
})
export class MapBoxDisplayPolygonComponent implements OnInit {
  map: mapboxgl.Map;
  style = 'mapbox://styles/mapbox/outdoors-v9';

  coords;

  @Input() zoomLevel = 0;
  @Input() polygon: CoordinatePolygonModel;

  @Output() onPolygonUpdated = new EventEmitter<any>();
  @Output() onPolygonDeleted = new EventEmitter<any>();

  constructor(
    private mapInteractionService: MapInteractionService,
    private mapDataService: MapDataService
  ) {}

  ngOnInit() {
    this.buildMap();
  }

  buildMap() {
    this.mapInteractionService.map = new mapboxgl.Map({
      attributionControl: false,
      container: 'nautical-map-polygon-display',
      style: this.style,
      zoom: this.zoomLevel,
    });

    this.map = this.mapInteractionService.map;
    this.map.addControl(new mapboxgl.NavigationControl());

    const draw = new MapboxDraw({
      userProperties: true,
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: true,
      },
      styles: [
        {
          id: 'style-color',
          type: 'fill',
          paint: {
            'fill-color': '#7700ff',
            'fill-outline-color': '#72167a',
            'fill-opacity': 0.5,
          },
        },
      ],
    });

    this.map.addControl(draw);

    this.map.on('load', (event) => {
      if (this.polygon) {
        this.coords = _.map(this.polygon.points, (c: CoordinatePointModel) => [
          c.longitude,
          c.latitude,
        ]);

        // const center = this.mapDataService.calculatePolygonCenter(this.polygon.points);

        draw.add({
          id: 'nautical-map-polygon-display',
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'Polygon',
            coordinates: [this.coords],
          },
          layout: {},
        });

        const latitudes = _.map(this.polygon.points, (p) => p.latitude);
        const longitudes = _.map(this.polygon.points, (p) => p.longitude);

        const bounds = [
          [_.max(longitudes), _.min(latitudes)],
          [_.min(longitudes), _.max(latitudes)],
        ];

        this.map.fitBounds(bounds, { padding: 20 });

        // this.map.flyTo({
        //   center: center
        // });
      }
    });

    this.map.on('draw.create', (event) => {
      this.coords = event.features[0].geometry.coordinates[0];

      this.onPolygonUpdated.emit(this.coords);
    });

    this.map.on('draw.update', (event) => {
      this.coords = event.features[0].geometry.coordinates[0];

      this.onPolygonUpdated.emit(this.coords);
    });

    this.map.on('draw.delete', (event) => {
      this.onPolygonDeleted.emit();

      this.coords = null;
    });
  }

  updateLngLat(index: number) {
    const numberOfPoints = this.coords.length;

    if (index === 0) {
      this.coords[numberOfPoints - 1] = this.coords[0];
    }

    if (index === numberOfPoints - 1) {
      this.coords[0] = this.coords[numberOfPoints - 1];
    }

    this.onPolygonUpdated.emit(this.coords);
  }

  getCenterpoint() {
    let minX, maxX, minY, maxY;
    for (let i = 0; i < this.coords.length; i++) {
      minX =
        this.coords[i][0] < minX || minX == null ? this.coords[i][0] : minX;
      maxX =
        this.coords[i][0] > maxX || maxX == null ? this.coords[i][0] : maxX;
      minY =
        this.coords[i][1] < minY || minY == null ? this.coords[i][1] : minY;
      maxY =
        this.coords[i][1] > maxY || maxY == null ? this.coords[i][1] : maxY;
    }
    const centerpoint = [(minX + maxX) / 2, (minY + maxY) / 2];
  }
}
