<ng-container *ngIf="prependText || appendList || appendText">
  <div class="input-group">
    <div class="input-group-prepend" *ngIf="prependText">
      <span class="input-group-text">{{ prependText | translate }}</span>
    </div>
    <input
      [disabled]="disabled"
      type="text"
      class="form-control"
      [(ngModel)]="inputValue"
      [placeholder]="placeholder | translate"
      [required]="required"
      (change)="inputChanged()"
    />
    <div *ngIf="appendText" class="input-group-append">
      <span class="input-group-text">
        {{ appendText }}
      </span>
    </div>
    <div *ngIf="incrementValuesList" class="input-group-append">
      <button
        *ngFor="let incrementValuesListItem of incrementValuesList"
        class="btn btn-outline-secondary"
        type="button"
        (click)="incrementValue(incrementValuesListItem.value)"
        [title]="incrementValuesListItem.display"
        [disabled]="disabled"
      >
        {{ incrementValuesListItem.display }}
      </button>
    </div>
    <div *ngIf="appendList && appendList.length > 0" class="input-group-append">
      <button
        type="button"
        class="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <span class="sr-only">{{ 'shared.terms.selectType' | translate }}</span>
      </button>
      <div class="dropdown-menu">
        <a
          *ngFor="let appendItem of appendList"
          class="dropdown-item"
          (click)="selectAppendItem(appendItem)"
        >
          {{ appendItem }}
        </a>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!prependText && !appendList && !appendText">
  <input
    [disabled]="disabled"
    type="text"
    class="form-control"
    [(ngModel)]="inputValue"
    [placeholder]="placeholder"
    (change)="inputChanged()"
    [required]="required"
    name="input"
    #input="ngModel"
  />
  <small *ngIf="input.invalid && submitted && required" class="text-danger">
    {{ 'shared.terms.requiredFieldText' | translate }}
  </small>
</ng-container>
