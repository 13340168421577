import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  DataContextService,
  NotificationService,
  ResultWrapper,
} from '@seahorse/common';
import { Guid } from 'guid-typescript';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { catchError, map, mergeAll } from 'rxjs/operators';
import * as _ from 'underscore';
import { OrganisationModel } from '../../content-distribution/models/organisation.model';
import { ProfilePermission } from '../../core/models/permission.model';
import { IdentityService } from '../../core/services/identity.service';

import {
  ChangePasswordModel,
  UserInfoModel,
  UserModel,
  UsersDataService,
} from '@seahorse/domain';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class UserDataService extends UsersDataService {
  private nextUpdate: moment.Moment = null;
  private forceReloadTime = 120;
  private totalCount = 0;
  private users: UserModel[] = [];

  constructor(
    private router: Router,
    private dataContext: DataContextService,
    private identityService: IdentityService,
    private notificationService: NotificationService
  ) {
    super();
  }

  private appendUsers(users: UserModel[]) {
    _.each(users, (u) => {
      const curIndex = _.findIndex(this.users, (f) => f.id === u.id);
      if (curIndex === -1) {
        this.users.push(u);
      } else {
        this.users[curIndex] = u;
      }
      this.setNextUpdate();
    });
  }

  private localUserResult(id: string): ResultWrapper<UserModel> {
    id = id.toLowerCase();

    return {
      count: this.totalCount,
      hasResult: true,
      messages: [],
      result: _.find(this.users, (u) => u.id.toLowerCase() === id),
      status: 1,
    };
  }

  private localUsersResult(): ResultWrapper<UserModel[]> {
    return {
      count: this.totalCount,
      hasResult: true,
      messages: [],
      result: this.users,
      status: 1,
    };
  }

  private setNextUpdate() {
    this.nextUpdate = moment().add(this.forceReloadTime, 'm');
  }

  private shouldReload(): boolean {
    return (
      this.users === null ||
      this.users.length === 0 ||
      this.nextUpdate === null ||
      moment().isAfter(this.nextUpdate)
    );
  }

  getByOrganisation(
    loadInactiveUsers: boolean
  ): Observable<ResultWrapper<UserModel[]>> {
    return new Observable<ResultWrapper<UserModel[]>>((subscriber) => {
      if (this.shouldReload()) {
        this.dataContext
          .get<UserModel[]>(`users?loadInactiveUsers=${loadInactiveUsers}`)
          .subscribe((userResult) => {
            if (userResult.hasResult) {
              this.totalCount = userResult.count;
              this.appendUsers(userResult.result);
            }
            subscriber.next(this.localUsersResult());
            subscriber.complete();
          });
      } else {
        subscriber.next(this.localUsersResult());
        subscriber.complete();
      }
    });
  }

  getUsersByOrganisationId(
    organisationId: string
  ): Observable<ResultWrapper<UserModel[]>> {
    return this.dataContext.get<UserModel[]>(
      `users/organisation/${organisationId}`
    );
  }

  get(id: string): Observable<ResultWrapper<UserModel>> {
    id = id.toLowerCase();

    return new Observable<ResultWrapper<UserModel>>((subscriber) => {
      if (
        this.shouldReload() ||
        (this.users.length === 0 &&
          _.find(this.users, (u) => u.id.toLowerCase() === id) === undefined)
      ) {
        this.dataContext
          .get<UserModel>(`users/${id}`)
          .subscribe((userResult) => {
            if (userResult.hasResult) {
              this.appendUsers([userResult.result]);
            } else {
              const dummyUser = new UserModel();
              dummyUser.id = id;
              dummyUser.surname = id;
              this.appendUsers([dummyUser]);
            }
            subscriber.next(userResult);
            subscriber.complete();
          });
      } else {
        subscriber.next(this.localUserResult(id));
        subscriber.complete();
      }
    });
  }

  getProfile(): Observable<ResultWrapper<UserInfoModel>> {
    return this.dataContext.get<UserInfoModel>('users/me');
  }

  setUserIdentity(): Observable<string[]> {
    return this.getProfile().pipe(
      map((userResult) => {
        if (userResult.hasResult) {
          this.identityService.identity = userResult.result.user;
          this.identityService.setPermissions(userResult.result.permissions);

          if (this.identityService.hasPermission('general.onboarding_user')) {
            this.router.navigate(['onboarding/permissions']);
          }

          return userResult.result.permissions;
        } else {
          localStorage.clear();
          this.router.navigate(['/account/login']);

          if (userResult.messages.length > 0) {
            this.notificationService.showError(
              userResult.messages.map((x) => x.message).join('\n')
            );
          }
        }
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          localStorage.clear();
          this.router.navigate(['/account/login']);
        }

        throw error;
      })
    );
  }

  changePassword(model: ChangePasswordModel) {
    return this.dataContext.post<boolean>('account/change-password', model);
  }

  add(user: UserModel): Observable<ResultWrapper<UserModel>> {
    return new Observable<ResultWrapper<UserModel>>((subscriber) => {
      this.dataContext.post<UserModel>('users', user).subscribe((addResult) => {
        if (addResult.hasResult) {
          this.appendUsers([addResult.result]);
        }
        subscriber.next(addResult);
        subscriber.complete();
      });
    });
  }

  update(user: UserModel): Observable<ResultWrapper<UserModel>> {
    return new Observable<ResultWrapper<UserModel>>((subscriber) => {
      this.dataContext
        .put<UserModel>(`users/${user.id}`, user)
        .subscribe((updateResult) => {
          if (updateResult.hasResult) {
            this.appendUsers([updateResult.result]);
          }
          subscriber.next(updateResult);
          subscriber.complete();
        });
    });
  }

  updateProfile(user: UserModel): Observable<ResultWrapper<UserModel>> {
    user.enabled = true;
    user.allowLogon = true;
    user.logonName = user.email;
    return this.dataContext.put<UserModel>('users/me', user);
  }

  resetPassword(id: string): Observable<ResultWrapper<boolean>> {
    return this.dataContext.get<boolean>(`users/reset/${id}`);
  }

  setPermissions(
    permissions: string[]
  ): Observable<ResultWrapper<UserInfoModel>> {
    const setApiPermissions = this.dataContext.post<ProfilePermission[]>(
      `account/setpermissions`,
      permissions
    );

    return setApiPermissions.pipe(
      map(() => this.getProfile()),
      mergeAll()
    );
  }

  getOrganisations(ids: Guid[]): Observable<ResultWrapper<OrganisationModel>> {
    return this.dataContext.post<OrganisationModel>(`organisations/byids`, {
      ids,
    });
  }
}
