import { NgModule } from '@angular/core';

import { ContentMappingDataService } from './content-mapping-data.service';
import { ContentMappingExternalDataService } from './content-mapping-external-data.service';
import { ContentMappingLogicService } from './content-mapping-logic.service';

@NgModule({
  providers: [
    ContentMappingDataService,
    ContentMappingExternalDataService,
    ContentMappingLogicService,
  ],
})
export class ContentMappingServicesModule {}
