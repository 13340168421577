<form #userForm="ngForm" *ngIf="model">
  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label class="control-label required"
          ><b>{{ 'user.model.firstName' | translate }}</b></label
        >
        <input
          required
          class="form-control"
          name="givenName"
          #givenName="ngModel"
          [(ngModel)]="model.givenName"
        />
        <span
          *ngIf="
            submitted && (givenName.value == null || givenName.value == '')
          "
          class="text-danger"
        >
          {{ 'user.errors.firstNameEmpty' | translate }}</span
        >
      </div>
    </div>
    <div class="col-6">
      <div class="form-group">
        <label class="control-label required"
          ><b>{{ 'user.model.lastName' | translate }}</b></label
        >
        <input
          required
          class="form-control"
          name="surname"
          #surname="ngModel"
          [(ngModel)]="model.surname"
        />
        <span
          *ngIf="submitted && (surname.value == null || surname.value === '')"
          class="text-danger"
        >
          {{ 'user.errors.lastNameEmpty' | translate }}</span
        >
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label class="control-label required"
          ><b>{{ 'user.model.email' | translate }}</b></label
        >
        <input
          required
          pattern="\S+@\S+\.\S+$"
          class="form-control"
          name="email"
          #email="ngModel"
          [(ngModel)]="model.email"
        />
        <span
          *ngIf="submitted && (email.value == null || email.value === '')"
          class="text-danger"
        >
          {{ 'user.errors.emailEmpty' | translate }}</span
        >
        <span *ngIf="submitted && email.errors?.pattern" class="text-danger">
          {{ 'user.errors.emailPattern' | translate }}</span
        >
      </div>
    </div>

    <div class="col-6">
      <div class="form-group">
        <label
          ><b>{{ 'user.model.phone' | translate }}</b></label
        >
        <input
          class="form-control"
          name="phone"
          #phone="ngModel"
          [(ngModel)]="model.phone"
        />
      </div>
    </div>
  </div>
</form>
