<sh-card *shEmptyPlaceholder="isInitializing; items: 'attributes:1'" no-padding>
  <span header class="text-uppercase">
    {{ 'taskGroupDisplay.expiredTaskGroups' | translate }}
  </span>
  <table class="table table-clickable">
    <tbody (click)="goToTasksOverview()">
      <tr *ngFor="let taskGroupDisplayItem of taskGroupDisplay">
        <td>
          {{ taskGroupDisplayItem.title }}
        </td>
        <td>
          {{ taskGroupDisplayItem.description }}
        </td>
        <td [class.warning-cell]="taskGroupDisplayItem.dueDate">
          <ng-container *ngIf="taskGroupDisplayItem.dueDate">
            {{ taskGroupDisplayItem.dueDate | date: 'dd-MM-yyyy' }}
          </ng-container>
        </td>
        <td class="warning-cell">
          {{ taskGroupDisplayItem.tasks.length }}
          <ng-container
            *ngIf="taskGroupDisplayItem.tasks.length !== 1; else oneTaskExpired"
          >
            {{ 'taskGroupDisplay.tasksExpired' | translate | lowercase }}
          </ng-container>
          <ng-template #oneTaskExpired>
            {{ 'taskGroupDisplay.taskExpired' | translate | lowercase }}
          </ng-template>
        </td>
      </tr>
    </tbody>
  </table>
</sh-card>
