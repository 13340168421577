import { Injectable } from '@angular/core';
import {
  DataContextService,
  ResultWrapper
} from '@seahorse/common';
import { Observable } from 'rxjs';
import { NauticalPortModel } from '../models/nautical-port.model';

@Injectable({
  providedIn: 'root',
})
export class NauticalPortDataService {
  constructor(private dataContext: DataContextService) {}

  getById(portId: number, annotate = false): Observable<ResultWrapper<NauticalPortModel>> {
    return this.dataContext.get<NauticalPortModel>(
        `nautical/geography/port/${portId}?annotate=${annotate}`
    );
  }

  getByName(
    query: string,
    pageIndex?: number,
    pageSize?: number,
    onlyWithGeography: boolean = false,
    annotate = false
  ): Observable<ResultWrapper<NauticalPortModel[]>> {
    const queryParams = [
      `name=${encodeURIComponent(query)}`,
      `annotate=${annotate}`,
    ];

    if (pageIndex !== undefined && pageIndex !== null && pageIndex >= 0) {
      queryParams.push(`pindex=${pageIndex}`);
    }

    if (pageSize !== undefined && pageSize !== null && pageSize >= 0) {
      queryParams.push(`psize=${pageSize}`);
    }

    queryParams.push(
      `onlyWithGeography=${onlyWithGeography ? 'true' : 'false'}`
    );

    const queryString = queryParams.join('&');
    const url = `nautical/geography/port?${queryString}`;

    return this.dataContext.get<NauticalPortModel[]>(url);
  }

  getByUnlocode(
    query: string,
    onlyWithGeography: boolean = false
  ): Observable<ResultWrapper<NauticalPortModel[]>> {
    return this.dataContext
      .get<NauticalPortModel[]>(
        'nautical/geography/port?unlo=' +
          query +
          '&onlyWithGeography=' +
          (onlyWithGeography ? 'true' : 'false')
      );
  }

  search(
    query?: string,
    onlyWithGeography: boolean = false
  ): Observable<ResultWrapper<NauticalPortModel[]>> {
    const queryParams = [];

    if (query !== undefined && query !== null) {
      queryParams.push(`query=${encodeURIComponent(query)}`);
    }

    queryParams.push(
      `onlyWithGeography=${onlyWithGeography ? 'true' : 'false'}`
    );

    const queryString = queryParams.join('&');
    const url = `nautical/geography/port?${queryString}`;

    return this.dataContext
      .get<NauticalPortModel[]>(url);
  }
}
