/* eslint-disable @angular-eslint/no-output-native */
/* eslint-disable @angular-eslint/no-output-rename */
/* eslint-disable @angular-eslint/no-output-on-prefix */
import {
  ChangeDetectorRef,
  ComponentRef,
  Directive,
  EventEmitter,
  HostListener,
  Injector,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { DynamicsService } from '@seahorse/common';
import {
  CustomDataBaseModel,
  CustomDataSearchFieldModel,
  FieldDefinitionModel,
  FieldType,
  ObjectDefinitionModel,
  SearchOperators,
} from '@seahorse/domain';
import { Subscription, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { expensesUpdateModalProcedure } from '../expenses/expenses-update-modal.procedure';
import { ModalAction } from '../modal/modal.model';
import { FormInteractionService } from './form-interaction.service';
import { FormComponent } from './form.component';
import {
  FieldFormType,
  fieldFormTypeDictionary,
  FormModel,
} from './form.model';
import { FormService } from './form.service';

import { InlineFormActionsTemplate } from './inline-form-actions.template';
import { PurchaseInvoiceFilterHeaderComponent } from '../expenses/expense-header/purchase-invoice-filter-header/purchase-invoice-filter-header.component';
import { ExpenseUpdateModalHeader } from '../expenses/expense-header.model';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[tempFieldForm]',
  exportAs: 'fieldForm',
  providers: [FormInteractionService],
  standalone: true,
})
export class FieldFormDirective implements OnDestroy {
  @Input() model: FormModel;
  @Input() checkPendingChanges = false;
  @Input() objectValue: CustomDataBaseModel;
  @Input() definition: ObjectDefinitionModel;

  @Output('submit') onSubmit = new EventEmitter<{ value: any; id: any }>();

  private _subscriptions = new Subscription();
  private _formRef: ComponentRef<FormComponent>;
  private _openExpensesUpdateModal = expensesUpdateModalProcedure(true);

  private get _id() {
    return this.objectValue?.__Id;
  }

  constructor(
    private _injector: Injector,
    private _templateRef: TemplateRef<any>,
    private _vcRef: ViewContainerRef,
    private _cdRef: ChangeDetectorRef,
    private _formService: FormService,
    private _dynamicsService: DynamicsService,
    private _formInteractionService: FormInteractionService
  ) {
    this.createDefaultView();
  }

  // click outside
  @HostListener('document:  click', ['$event'])
  clickOutside(event: MouseEvent) {
    if (!this._formService.isInlineEditActive) {
      return;
    }

    if (!this._formRef || !this.model) {
      return;
    }

    const target = event.target as HTMLElement;
    const sourceField = target.id?.split('#')[1];

    if (sourceField === this.model.fields[0].key) {
      return;
    }

    const form = this._formRef.location.nativeElement as HTMLElement;
    const isClickInside = form.contains(target);

    if (isClickInside) {
      return;
    }

    this._submit.call(this);
  }

  show() {
    if (this._formService.isFieldEditActive) {
      return;
    }

    this.registerEventListeners();

    this._formService.isFieldEditActive = true;
    // prevent host from rerendering the elements, which will cause directive to be reinstantiated
    this._cdRef.detach();

    const fieldCode = this.model.fields[0].options['fieldCode'];
    if (fieldCode === 'user') {
      this.showModal();
      return;
    }

    if (this.model.fields[0].options['incrementValuesList']) {
      this.showModal();
      this._formService.isModalEditActive = true;
      return;
    }

    const fieldType = this.model.fields[0].type;

    switch (fieldFormTypeDictionary[fieldType]) {
      case FieldFormType.Inline:
        this.showInline();
        this._formService.isInlineEditActive = true;
        break;
      case FieldFormType.Modal:
        this.showModal();
        this._formService.isModalEditActive = true;
        break;
      default:
        break;
    }
  }

  private showInline() {
    this._vcRef.clear();

    this.createFormComponent();
    this.createFormActions();
  }

  private showModal() {
    const field = this.model.fields[0];
    const fieldDefinition = field.options?.['fieldDefinition'] as
      | FieldDefinitionModel
      | undefined;

    const isExpenseField =
      fieldDefinition?.additionalDataModel?.mappingKey ===
      '$customcontent_expense___id';

    if (isExpenseField) {
      this.showExpensesModal(fieldDefinition.fieldType);
      return;
    }

    this._subscriptions.add(
      this._formService.openFieldModal(this.model, this._injector).subscribe(
        (value: any) => {
          this.onSubmit.emit({ value, id: this._id });
          this._close();
        },
        () => {
          //
        },
        () => this._close()
      )
    );
  }

  private showExpensesModal(fieldType: FieldType) {
    const fieldCode = this.model.fields[0].key;
    const value = this.model.value[fieldCode];
    const isSingleSelect = fieldType === FieldType.LinkedObject;

    let headerComponentRef: ComponentRef<ExpenseUpdateModalHeader> = null;
    let searchFields = null;
    if (this.objectValue) {
      if (this.objectValue['portCallFile']) {
        const searchField = new CustomDataSearchFieldModel();
        searchField.fieldName = 'portCallFile';
        searchField.searchValue = this.objectValue['portCallFile'];
        searchField.searchOperator = SearchOperators.Equals;
        searchFields = [searchField];
      }

      if (this.definition) {
        const key = `${this.definition.mappingKey}_${this.model.fields[0].key}`;
        const componentRef = this._dynamicsService.createComponent({
          component: PurchaseInvoiceFilterHeaderComponent,
          props: {
            connectedDataKey: [
              key,
              key.replace('$customcontent_', '$customcontentinbox_'),
            ],
          },
        });
        headerComponentRef = componentRef;
      }
    }

    this._openExpensesUpdateModal(
      undefined,
      value,
      isSingleSelect,
      this.objectValue,
      this.definition,
      searchFields,
      headerComponentRef,
      true
    )
      .pipe(
        catchError((error) => {
          this._close();
          return throwError(error);
        })
      )
      .subscribe((expenses) => {
        if (!expenses) {
          this._close();
          return;
        }

        let value = expenses.map((expense) => expense.__Id) as any[];

        if (value && isSingleSelect) {
          value = value[0];
        }

        this.onSubmit.emit({ value, id: this._id });
        this._close();
      });
  }

  private createDefaultView() {
    this._vcRef.createEmbeddedView(this._templateRef);
  }

  private createFormComponent() {
    const formRef = this._dynamicsService.createComponent(
      {
        component: FormComponent,
        props: {
          model: this.model,
        },
        injector: this._injector,
        hostAttributes: ['inline'],
      },
      this._vcRef
    );

    this._formRef = formRef;
  }

  private createFormActions() {
    this._dynamicsService.createComponent(
      {
        component: InlineFormActionsTemplate,
        events: {
          submit: this._submit,
          cancel: this._close,
        },
      },
      this._vcRef
    );
  }

  private _submit = () => {
    if (!this._formRef.instance.form.dirty) {
      this._close();
    }

    const value = this._formRef.instance.submit();

    if (value) {
      this.onSubmit.emit({ value: Object.values(value)[0], id: this._id });
      this._close();
    }
  };

  private _close = () => {
    this.createDefaultView();
    this._cdRef.reattach();
    this._cdRef.detectChanges();
    this._formService.isFieldEditActive = false;
    this._formService.isInlineEditActive = false;
    this._formService.isModalEditActive = false;
    this.removeEventListeners();
  };

  private registerEventListeners() {
    document.addEventListener('keydown', this._keydownEventListener);
  }

  private removeEventListeners() {
    document.removeEventListener('keydown', this._keydownEventListener);
  }

  private _keydownEventListener = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      this.checkForPendingChanges();
    }
  };

  private checkForPendingChanges() {
    const formValue = this.model.value;

    if (
      this.checkPendingChanges &&
      this._formService.isPendingChangesDialogOpen === false &&
      this._formInteractionService.areTherePendingChanges(formValue)
    ) {
      this._subscriptions.add(
        this._formService.openPendingChangesDialog().subscribe((response) => {
          if (response.action === ModalAction.Confirm) {
            this._close();
          }
        })
      );
    } else {
      this._close();
    }
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
    this.removeEventListeners();
  }
}
