<form #financialForm="ngForm">
  <div class="form-group">
    <label
      ><b>{{ 'companies.financial.model.debtorNumber' | translate }}</b></label
    >:
    <input
      required
      #debtorNumber="ngModel"
      type="text"
      name="debtorNumber"
      [(ngModel)]="model.debtorNumber"
      class="form-control"
      [disabled]="onlyReadPermission"
    />
    <small
      *ngIf="
        submitted && (debtorNumber.value == null || debtorNumber.value == '')
      "
      class="text-danger"
    >
      {{ 'companies.financial.errors.debtorNumberEmpty' | translate }}</small
    >
  </div>

  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label
          ><b>{{ 'companies.financial.model.cocNumber' | translate }}</b></label
        >:
        <input
          required
          #cocNumber="ngModel"
          type="text"
          name="cocNumber"
          [(ngModel)]="model.cocNumber"
          class="form-control"
          [disabled]="onlyReadPermission"
        />
        <small
          *ngIf="
            submitted && (cocNumber.value == null || cocNumber.value == '')
          "
          class="text-danger"
        >
          {{ 'companies.financial.errors.cocNumberEmpty' | translate }}
        </small>
      </div>
    </div>

    <div class="col-6">
      <div class="form-group">
        <label
          ><b>{{ 'companies.financial.model.vatNumber' | translate }}</b></label
        >:
        <input
          required
          #vatNumber="ngModel"
          type="text"
          name="vatNumber"
          [(ngModel)]="model.vatNumber"
          class="form-control"
          [disabled]="onlyReadPermission"
        />
        <small
          *ngIf="
            submitted && (vatNumber.value == null || vatNumber.value == '')
          "
          class="text-danger"
        >
          {{ 'companies.financial.errors.vatNumberEmpty' | translate }}</small
        >
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label
          ><b>{{ 'companies.financial.model.startsOn' | translate }}</b></label
        >:
        <temp-date-time-picker
          [(dateTime)]="model.startsOn"
          [hideTime]="true"
          [isReadOnly]="onlyReadPermission"
          [isContained]="true"
        ></temp-date-time-picker>
      </div>
    </div>

    <div class="col-6">
      <div class="form-group">
        <label
          ><b>{{ 'companies.financial.model.endsOn' | translate }}</b></label
        >:
        <temp-date-time-picker
          [(dateTime)]="model.endsOn"
          [hideTime]="true"
          [isReadOnly]="onlyReadPermission"
          [isContained]="true"
        ></temp-date-time-picker>
      </div>
    </div>
  </div>

  <div class="form-group">
    <label
      ><b>{{ 'companies.financial.model.emailAddress' | translate }}</b></label
    >:
    <input
      required
      #emailAddress="ngModel"
      type="text"
      name="emailAddress"
      [(ngModel)]="model.emailAddress"
      class="form-control"
      [disabled]="onlyReadPermission"
    />
    <small
      *ngIf="
        submitted && (emailAddress.value == null || emailAddress.value == '')
      "
      class="text-danger"
    >
      {{ 'companies.financial.errors.emailEmpty' | translate }}
    </small>
  </div>

  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label
          ><b>{{ 'companies.financial.model.iban' | translate }}</b></label
        >:
        <input
          required
          #iban="ngModel"
          type="text"
          name="iban"
          [(ngModel)]="model.iban"
          class="form-control"
          [disabled]="onlyReadPermission"
        />
        <small
          *ngIf="submitted && (iban.value == null || iban.value === '')"
          class="text-danger"
        >
          {{ 'companies.financial.errors.ibanEmpty' | translate }}</small
        >
      </div>
    </div>

    <div class="col-6">
      <div class="form-group">
        <label
          ><b>{{ 'companies.financial.model.bic' | translate }}</b></label
        >:
        <input
          required
          #bic="ngModel"
          type="text"
          name="bic"
          [(ngModel)]="model.bic"
          class="form-control"
          [disabled]="onlyReadPermission"
        />
        <small
          *ngIf="submitted && (bic.value == null || bic.value === '')"
          class="text-danger"
        >
          {{ 'companies.financial.errors.bicEmpty' | translate }}
        </small>
      </div>
    </div>
  </div>

  <div class="form-group">
    <label
      ><b>{{ 'companies.financial.model.allowSepa' | translate }}</b></label
    >:
    <input
      required
      #allowSepa="ngModel"
      type="checkbox"
      class="small"
      name="allowSepa "
      [(ngModel)]="model.allowSepa"
      [disabled]="onlyReadPermission"
    />
    <small
      *ngIf="submitted && (allowSepa.value == null || allowSepa.value === '')"
      class="text-danger"
    >
      {{ 'companies.financial.errors.allowSepaEmpty' | translate }}</small
    >
  </div>

  <div class="form-group">
    <label
      ><b>{{ 'companies.financial.model.invoiceMethod' | translate }}</b></label
    >:
    <input
      required
      #invoiceMethod="ngModel"
      type="number"
      name="invoiceMethod"
      [(ngModel)]="model.invoiceMethod"
      class="form-control"
      [disabled]="onlyReadPermission"
    />
    <small
      *ngIf="
        submitted && (invoiceMethod.value == null || invoiceMethod.value == '')
      "
      class="text-danger"
    >
      {{ 'companies.financial.errors.invoiceMethodEmpty' | translate }}</small
    >
  </div>

  <div class="form-group">
    <label
      ><b>{{ 'companies.financial.model.addressing' | translate }}</b></label
    >:
    <input
      #addressing="ngModel"
      type="text"
      name="addressing"
      [(ngModel)]="model.addressing"
      class="form-control"
      [disabled]="onlyReadPermission"
    />
    <small
      *ngIf="submitted && (addressing.value == null || addressing.value === '')"
      class="text-danger"
    >
      {{ 'companies.financial.errors.addressingEmpty' | translate }}
    </small>
  </div>

  <div class="form-group">
    <label
      ><b>{{
        'companies.financial.model.customerReference' | translate
      }}</b></label
    >:
    <input
      #customerReference="ngModel"
      type="text"
      name="customerReference"
      [(ngModel)]="model.customerReference"
      class="form-control"
      [disabled]="onlyReadPermission"
    />
  </div>

  <div class="form-group" *ngIf="templates && templates.length > 0">
    <label
      ><b>{{
        'companies.financial.model.alternateTemplate' | translate
      }}</b></label
    >
    <select
      class="form-control"
      name="alternateTemplateId"
      [(ngModel)]="model.alternateTemplateId"
    >
      <option *ngFor="let template of templates" [ngValue]="template.id">
        {{ template.name }}
      </option>
    </select>
  </div>
</form>
