import { BusinessLogicObject } from './business-logic-object.model';

export class BusinessLogicCalculation extends BusinessLogicObject {
  calculationOrder: number;
}

export class CalculationBracketModel extends BusinessLogicCalculation {
  maxValue: number;
  minValue: number;
  sourceField: string;
  amount: number;
}

export class CalculationPeriodModel extends BusinessLogicCalculation {
  intervalQuantity: number;
  maxQuantity: number;
  minQuantity: number;
  period: BusinessLogicTimePeriods;
  periodQuantity: number;
  sourceFieldMin: string;
  sourceFieldMax: string;
}

export class CalculationPricePerUnitModel extends BusinessLogicCalculation {
  sourceField: string;
}

export enum BusinessLogicTimePeriods {
  Second,
  Minute,
  Hour,
  Day,
  Week,
  Month,
  Year,
}

export const TIMEPERIODS = [
  { id: BusinessLogicTimePeriods.Second, name: 'Second' },
  { id: BusinessLogicTimePeriods.Minute, name: 'Minute' },
  { id: BusinessLogicTimePeriods.Hour, name: 'Hour' },
  { id: BusinessLogicTimePeriods.Day, name: 'Day' },
  { id: BusinessLogicTimePeriods.Week, name: 'Week' },
  { id: BusinessLogicTimePeriods.Month, name: 'Month' },
  { id: BusinessLogicTimePeriods.Year, name: 'Year' },
];
