import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import * as _ from 'underscore';
import { LoadingIndicatorComponent } from '../loading-indicator/loading-indicator.component';

@Component({
  selector: 'sh-empty-placeholder',
  templateUrl: 'empty-placeholder.component.html',
  standalone: true,
  imports: [
    CommonModule,
    LoadingIndicatorComponent
  ]
})
export class EmptyPlaceholderComponent {
  itemCollection: any[] = [];

  @Input() set items(input: string) {
    if (!input || input === '') {
      return;
    }

    const values = input.split(',');
    this.itemCollection = _.map(values, (v) => {
      if (v.indexOf(':') > -1) {
        const vSplit = v.split(':');
        return {
          key: vSplit[0],
          repeat: new Array(parseInt(vSplit[1])),
        };
      } else {
        return { key: v };
      }
    });
  }
}
