import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalAction, ModalResponse } from '@seahorse/temp';
import { Router } from '@angular/router';

@Component({
  templateUrl: './beverwijk-action-modal.component.html',
})
export class BeverwijkActionModalComponent {
  @Input() shipId: string;
  
  constructor(
    private _activeModal: NgbActiveModal,
    private _router: Router
  ) {}
  
  goTo(value: string) {
    const response: ModalResponse = { action: ModalAction.Confirm };
    this._activeModal.close(response);
    this._router.navigateByUrl(`/custom-ui/beverwijk/${value}?shipId=${this.shipId}`);
  }
  
  cancel() {
    const response: ModalResponse = { action: ModalAction.Close };
    this._activeModal.close(response);
  }
}
