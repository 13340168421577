import { Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { NotificationService } from '@seahorse/common';
import { NauticalShipDataService } from '@seahorse/domain';
import { NauticalShipModel } from '@seahorse/domain';
import { ShipSearchModel } from '@seahorse/domain';
import { ModalService } from '@seahorse/temp';
import { NauticalShipModalComponent } from '../nautical-ship-modal/nautical-ship-modal.component';

@Component({
  selector: 'ca-nautical-ship-picker',
  templateUrl: 'nautical-ship-picker.component.html',
})
export class NauticalShipPickerComponent implements OnDestroy {
  @Input() nauticalShip: NauticalShipModel;
  @Input() nauticalShipRequired: boolean;
  @Input() editable: boolean;
  @Input() selectSearchField = false;
  @Input() createShipButton = false;
  @Input() searchField: keyof NauticalShipModel = 'name';
  @Input() searchFieldOptions: Array<keyof NauticalShipModel> = ['name', 'imo', 'eni', 'mmsi', 'callSign'];
  @Output() nauticalShipChange = new EventEmitter<NauticalShipModel>();

  nauticalShipsLoading: boolean;
  nauticalShipsLoadingFailed: boolean;

  private subscriptions$ = new Subscription();

  constructor(
    private translate: TranslateService,
    private notification: NotificationService,
    private nauticalShipData: NauticalShipDataService,
    private _modalService: ModalService
  ) {
    this.nauticalShip = null;
    this.nauticalShipsLoading = false;
    this.nauticalShipsLoadingFailed = false;
    this.nauticalShipRequired = false;
    this.editable = false;
  }

  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }

  itemSelected(evt: NgbTypeaheadSelectItemEvent) {
    if (evt && evt.item) {
      this.nauticalShipChange.emit(evt.item);
      this.searchField = 'name';
    }
  }

  nauticalShipFormatter = (nauticalShip: NauticalShipModel): string =>
    nauticalShip.name ? nauticalShip.name.toUpperCase() : '';

  nauticalShipsFormatter = (nauticalShip: NauticalShipModel): string => {
    let f = nauticalShip.name.toUpperCase();
    const a = [];

    if (nauticalShip.shipType && nauticalShip.shipType.name) {
      a.push(nauticalShip.shipType.name);
    }

    if (nauticalShip.callSign) {
      a.push(
        `${this.translate.instant('nautical.ship.model.callSign')}: ${
          nauticalShip.callSign
        }`
      );
    }

    if (nauticalShip.fisheryNumber) {
      a.push(nauticalShip.fisheryNumber);
    }

    if (nauticalShip.imo) {
      a.push(
        `${this.translate.instant('nautical.ship.model.imo')}: ${
          nauticalShip.imo
        }`
      );
    } else if (nauticalShip.eni) {
      a.push(
        `${this.translate.instant('nautical.ship.model.eni')}: ${
          nauticalShip.eni
        }`
      );
    }

    if (a.length) {
      f += ` (${a.join(', ')})`;
    }

    return f;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  searchNauticalShip = (input$: Observable<string>): any =>
    input$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.nauticalShipsLoading = true)),
      switchMap((s) =>
        this.nauticalShipData
          .find(Object.assign(new ShipSearchModel(null), { [this.searchField]: s } ), 0, 100, null, true)
          .pipe(
            tap(() => (this.nauticalShipsLoadingFailed = false)),
            map((r) => r.result),
            catchError((e) => {
              this.nauticalShipsLoadingFailed = true;

              this.notification.showError(
                e,
                this.translate.instant('shared.terms.failed')
              );
              return of([]);
            })
          )
      ),

      tap(() => (this.nauticalShipsLoading = false))
    );

  setNull = () => (this.nauticalShip = null);

  createShip() {
    this.subscriptions$.add(
      this._modalService.openModal(NauticalShipModalComponent, { isNew: true }).subscribe({
        next: (event) => {
          if (event) {
            this.subscriptions$.add(
              this.nauticalShipData.add(event['data']).subscribe({
                next: (response) => {
                  if (response.hasResult) {
                    this.nauticalShip = response.result;
                    this.nauticalShipChange.emit(response.result);
                    this.notification.showSuccess(this.translate.instant('shared.terms.addSuccess'), this.translate.instant('shared.terms.success'));
                  } else
                    this.notification.displayErrorNotification(response.messages);
                },
                error: (e) => this.notification.displayErrorNotification(e)
              })
            );
          }
        }
      })
    );
  }
}
