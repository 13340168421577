import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ResultStatus } from '@seahorse/common';
import { NotificationService } from '@seahorse/common';
import { ProxyServices } from '../../../core/services/proxy.service';
import {
  UiModule,
  UiModuleDefinition,
} from '@seahorse/domain';
import { UiModulesDataService } from '../../../custom-modules/services/ui-modules-data.service';
import { UiModuleDefinitionsDataService } from '../../../custom-modules/services/ui-modules-definition.service';
import { LayoutDisplayService } from '../../../layout/services/layout-display.service';
import { transformToCamelCase } from '../../../layout/util/string-utils';
import * as _ from 'underscore';

@Component({
  selector: 'ca-add-custom-ui-modal',
  templateUrl: 'add-custom-ui-modal.component.html',
})
export class AddCustomUIModalComponent implements OnInit {
  constructor(
    public modal: NgbActiveModal,
    private _proxyService: ProxyServices,
    private _uiModulesDataService: UiModulesDataService,
    private _uiModuleDefinitionsDataService: UiModuleDefinitionsDataService,
    private _notificationService: NotificationService,
    private _translateService: TranslateService,
    private _layoutDisplayService: LayoutDisplayService
  ) {}
  definitions: UiModuleDefinition[];
  selectedDefinition: string;
  uiModule: UiModule = {} as UiModule;
  isLoading = false;

  ngOnInit() {
    this.isLoading = true;

    this._uiModuleDefinitionsDataService
      .getAllDefinitions()
      .subscribe((response) => {
        this.definitions = response.hasResult ? response.result : [];
        this.isLoading = false;
      });
  }

  close() {
    this.modal.close();
  }

  cancel() {
    this.modal.dismiss(false);
  }

  save() {
    this.uiModule.systemCode = transformToCamelCase(this.uiModule.displayName);

    this._uiModulesDataService
      .add(this.uiModule, this.selectedDefinition)
      .subscribe((result) => {
        if (result.hasResult && result.status === ResultStatus.OK) {
          this._proxyService.updateUiModules(result.result);
          this._proxyService.updateObjectDefinitionsById(
            result.result.customObjectDefinitionId
          );
          this._layoutDisplayService.reloadNavigation();

          this._notificationService.showSuccess(
            this._translateService.instant('uiModules.customUiModuleAdded'),
            this._translateService.instant('shared.terms.success')
          );
          this.close();
        } else {
          this._notificationService.showError(
            _.pluck(result.messages, 'message').join('\n'),
            this._translateService.instant('shared.terms.failed')
          );
        }
      });
  }
}
