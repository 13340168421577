import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ResultWrapper } from '@seahorse/common';
import { DataContextService } from '@seahorse/common';

import { ContentMappingModel } from '../models/content-mapping.model';

@Injectable()
export class ContentMappingDataService {
  constructor(private dataContext: DataContextService) {}

  getAll(
    objectKey: string,
    objectValue: string
  ): Observable<ResultWrapper<ContentMappingModel[]>> {
    return this.dataContext.get<ContentMappingModel[]>(
      `contentmapping/${objectKey}/${objectValue}`
    );
  }

  getAllByRelationship(
    objectKey: string,
    objectValue: string,
    relationship: string
  ): Observable<ResultWrapper<ContentMappingModel[]>> {
    return this.dataContext.get<ContentMappingModel[]>(
      `contentmapping/${objectKey}/${objectValue}/${relationship}`
    );
  }

  addOrUpdate(
    contentMapping: ContentMappingModel
  ): Observable<ResultWrapper<ContentMappingModel>> {
    return this.dataContext.post<ContentMappingModel>(
      'contentmapping',
      contentMapping
    );
  }

  delete(
    id: ContentMappingModel['id']
  ): Observable<ResultWrapper<ContentMappingModel>> {
    return this.dataContext.delete<ContentMappingModel>(`contentmapping/${id}`);
  }
}
