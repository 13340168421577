import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ResultWrapper } from '@seahorse/common';
import { NotificationService } from '@seahorse/common';
import { EventModel } from '../../../../shared/models/event.model';
import * as _ from 'underscore';

import { ZeehavenIJmuidenMovementModel } from '../../models/zeehaven-ijmuiden-movement.model';
import { ZeehavenIJmuidenStatus } from '../../models/zeehaven-ijmuiden-status.model';
import { ZeehavenIJmuidenVisitModel } from '../../models/zeehaven-ijmuiden-visit.model';
import { ZeehavenIJmuidenVisitDataService } from '../../services/zeehaven-ijmuiden-visit-data.service';

@Component({
  templateUrl: 'zeehaven-ijmuiden-action-picker-modal.component.html',
})
export class ZeehavenIJmuidenActionPickerModalComponent implements OnInit {
  private isInitialized = false;
  isLoading = false;
  showCreateNewButtons = false;
  zhijMovements: ZeehavenIJmuidenMovementModel[] = null;
  zhijVisits: ZeehavenIJmuidenVisitModel[] = null;
  selectedZhijVisit = null;

  private _nauticalShipId: string;
  @Input() set nauticalShipId(nauticalShipId: string) {
    this._nauticalShipId = nauticalShipId;

    if (this.isInitialized === true) {
      this.loadZHIJVisitsByNauticalShipId();
    }
  }
  get nauticalShipId(): string {
    return this._nauticalShipId;
  }

  private _nauticalVisitId: number;
  @Input() set nauticalVisitId(nauticalVisitId: number) {
    this._nauticalVisitId = nauticalVisitId;

    if (this.isInitialized === true) {
      this.loadZHIJVisitsByNauticalVisitId();
    }
  }
  get nauticalVisitId(): number {
    return this._nauticalVisitId;
  }

  constructor(
    private notificationService: NotificationService,
    private translate: TranslateService,
    private zeehavenData: ZeehavenIJmuidenVisitDataService,
    public modal: NgbActiveModal
  ) {}

  ngOnInit() {
    this.isInitialized = true;
    if (
      this.nauticalVisitId !== undefined &&
      this.nauticalVisitId !== null &&
      this.nauticalVisitId > 0
    ) {
      this.loadZHIJVisitsByNauticalVisitId();
    } else if (
      this.nauticalShipId !== undefined &&
      this.nauticalShipId !== null
    ) {
      this.loadZHIJVisitsByNauticalShipId();
    }
  }

  afterLoadedVisit(response: ResultWrapper<ZeehavenIJmuidenVisitModel[]>) {
    this.showCreateNewButtons = false;
    this.zhijMovements = [];
    this.zhijVisits = [];
    this.selectedZhijVisit = null;

    if (response.hasResult && response.result && response.result.length > 0) {
      if (response.result.length === 1) {
        this.setSelectedZHIJVisit(response.result[0]);
      } else {
        this.zhijVisits = response.result;
        this.isLoading = false;
      }
    } else {
      const model = new EventModel();
      model.action = 'openSuggestionPicker';
      this.modal.close(model);
    }
  }

  afterLoadedFailed(messages) {
    this.notificationService.showError(
      _.pluck(messages, 'message').join('\n'),
      this.translate.instant('shared.terms.failed')
    );
  }

  dismiss(result: any) {
    this.modal.close(result);
  }

  loadZHIJVisitsByNauticalShipId() {
    if (this.isLoading !== true) {
      this.isLoading = true;
      this.zeehavenData
        .getOpenVisitsByNauticalShipId(this.nauticalShipId)
        .subscribe(
          (response) => this.afterLoadedVisit(response),
          (e) => {
            this.afterLoadedFailed(e.error ? e.error.messages : []);
            this.isLoading = false;
          }
        );
    }
  }

  loadZHIJVisitsByNauticalVisitId() {
    if (this.isLoading !== true) {
      this.isLoading = true;
      this.zeehavenData
        .getOpenVisitsByNauticalVisitId(this.nauticalVisitId)
        .subscribe(
          (response) => this.afterLoadedVisit(response),
          (e) => {
            this.afterLoadedFailed(e.error ? e.error.messages : []);
            this.isLoading = false;
          }
        );
    }
  }

  onSelect(event: EventModel) {
    if (event && event.action) {
      switch (event.action) {
        case 'selectMovement':
          if (event.data) {
            this.onSelectRedirect(event.data.__Id);
          }
          break;

        case 'selectVisit':
          if (event.data) {
            this.setSelectedZHIJVisit(event.data);
          }
          break;
        default:
          break;
      }
    }
  }

  onSelectRedirect(id: string) {
    const model = new EventModel();
    model.action = 'redirect';
    model.data = id;
    this.modal.close(model);
  }

  onSelectMovementType(movementType: string) {
    const model = new EventModel();
    model.action = 'newMovement';
    model.data = { visit: this.selectedZhijVisit, movementType: movementType };
    this.modal.close(model);
  }

  private setSelectedZHIJVisit(zhijVisit: ZeehavenIJmuidenVisitModel) {
    this.zhijVisits = [];
    this.selectedZhijVisit = zhijVisit;

    if (this.selectedZhijVisit.status === ZeehavenIJmuidenStatus.Suggestion) {
      // when ZHIJ visit status is Suggestion, movement type incoming is active. redirect to Incoming movement
      if (this.selectedZhijVisit.zhijMovementId) {
        this.onSelectRedirect(this.selectedZhijVisit.zhijMovementId);
      } else {
        this.onSelectMovementType('1');
      }
    } else {
      this.isLoading = true;
      this.zeehavenData
        .getOpenMovementsByZBIJVisitId(this.selectedZhijVisit.__Id)
        .subscribe(
          (movResponse: ResultWrapper<ZeehavenIJmuidenMovementModel[]>) => {
            if (
              movResponse.hasResult &&
              movResponse.result &&
              movResponse.result.length > 0
            ) {
              if (movResponse.result.length === 1) {
                // only 1 active ZHIJ movement is found, redirect to the active movement
                this.onSelectRedirect(movResponse.result[0].__Id);
              } else {
                this.zhijMovements = movResponse.result; // let user to choose which movement to show
                this.isLoading = false;
              }
            } else {
              this.showCreateNewButtons = true; // let user to choose which type movement should be create
              this.isLoading = false;
            }
          },
          (e) => {
            this.afterLoadedFailed(e.error ? e.error.messages : []);
            this.isLoading = false;
          }
        );
    }
  }
}
