<div class="card">
  <div class="card-header">
    {{ stage.name }}
    <small class="text-muted">{{ 'shared.terms.details' | translate }}</small>
    <div class="btn-list">
      <button
        [disabled]="loadingStage"
        (click)="editStage()"
        type="button"
        class="btn btn-outline-secondary btn-sm"
      >
        <i class="fa-regular fa-pencil-alt fa-fw"></i>
        {{ 'shared.terms.edit' | translate }}
      </button>
    </div>
  </div>
  <div class="card-body">
    <ng-container
      *ngIf="!loadingStage; then showStage; else isLoadingStage"
    ></ng-container>

    <ng-template #showStage>
      <div class="card-body-group card-body-group-no-border">
        <div class="row no-gutters attribute-group">
          <div class="col-12 col-md-6 attribute-group-item">
            <dt class="mb-0">
              {{ 'invoicingFlows.stage.stageType' | translate }}
            </dt>
            <dd>{{ stage.stageType | stageType }}</dd>
          </div>
          <div class="col-12 col-md-6 attribute-group-item">
            <dt class="mb-0">
              {{ 'invoicingFlows.stage.manualApproval' | translate }}
            </dt>
            <dd>
              <i
                [ngClass]="
                  stage.manualApproval
                    ? 'fa-solid fa-check fa-fw text-success'
                    : 'fa-solid fa-times fa-fw text-danger'
                "
              ></i>
            </dd>
          </div>
          <ng-container *ngIf="stage.previousStageId">
            <div class="col-12 col-md-6 attribute-group-item">
              <dt class="mb-0">
                {{ 'invoicingFlows.stage.previousStageId' | translate }}
              </dt>
              <dd>
                <ng-container
                  *ngIf="
                    !loadingPreviousStage;
                    then showPreviousStage;
                    else isLoadingPreviousStage
                  "
                ></ng-container>

                <ng-template #showPreviousStage>
                  <button
                    [disabled]="loadingFlow"
                    (click)="onEvent.emit({ action: 'viewPreviousStage' })"
                    type="button"
                    class="btn btn-link p-0 border-0 m-0"
                  >
                    <i class="fa-regular fa-search-dollar fa-fw"></i>
                    {{ previousStage.name }}
                  </button>
                </ng-template>

                <ng-template #isLoadingPreviousStage>
                  <div class="spinner-border spinner-border-sm" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </ng-template>
              </dd>
            </div>
            <div class="col-12 col-md-6 attribute-group-item">
              <dt class="mb-0">
                {{ 'invoicingFlows.stage.allPreviousMustSucceed' | translate }}
              </dt>
              <dd>
                <i
                  [ngClass]="
                    stage.allPreviousMustSucceed
                      ? 'fa-solid fa-check fa-fw text-success'
                      : 'fa-solid fa-times fa-fw text-danger'
                  "
                ></i>
              </dd>
            </div>
          </ng-container>
          <div class="col-12 attribute-group-item">
            <dt class="mb-0">
              {{ 'invoicingFlows.stage.invoiceFlowId' | translate }}
            </dt>
            <dd>
              <button
                [disabled]="loadingFlow"
                (click)="onEvent.emit({ action: 'viewFlow' })"
                type="button"
                class="btn btn-link p-0 border-0 m-0"
              >
                <i class="fa-regular fa-funnel-dollar fa-fw"></i>
                {{ 'invoicingFlows.stageDetails.viewFlow' | translate }}
              </button>
            </dd>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template #isLoadingStage>
      <sh-loading-indicator
        class="d-block text-center"
      ></sh-loading-indicator>
    </ng-template>
  </div>
</div>
