import { Component, Input, Output, EventEmitter } from '@angular/core';
import * as _ from 'underscore';

import { EventModel } from '../../../../shared/models/event.model';
import { InvoiceModel } from '@seahorse/domain';

@Component({
  selector: 'company-invoices-list',
  templateUrl: 'company-invoices-list.component.html',
})
export class CompanyInvoicesListComponent {
  @Input() set invoices(invoices: InvoiceModel[]) {
    if (invoices && invoices.length) {
      this._invoices = _.sortBy(invoices, 'invoiceDate').reverse();
    }
  }
  get invoices(): InvoiceModel[] {
    return this._invoices;
  }

  @Output() onEvent: EventEmitter<EventModel>;

  private _invoices: InvoiceModel[];

  constructor() {
    this.invoices = [];
    this.onEvent = new EventEmitter<EventModel>();
  }
}
