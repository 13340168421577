<div
  class="card tile tile-clickable"
  *ngFor="let ship of ships"
  (click)="selectShip(ship)"
  [ngClass]="{ selected: selectedShip && selectedShip.id === ship.id }"
>
  <div class="card-body">
    <div class="row seperated-columns">
      <div class="col-5">
        <strong>
          {{ ship.name | uppercase }}
        </strong>
        <ul class="list-inline list-inline-seperated">
          <li
            class="list-inline-item"
            *ngFor="let identification of shipIdentification(ship)"
          >
            <span class="text-muted">{{ identification.label }}</span>
            {{ identification.value }}
          </li>
        </ul>
      </div>
      <div class="col-7">
        <div class="attribute-group attribute-group-col-2">
          <div class="attribute-group-item">
            <dt>{{ 'shared.terms.name' | translate }}</dt>
            <dd>{{ ship.shipType.name }}</dd>
          </div>
          <div class="attribute-group-item">
            <dt>{{ 'nautical.ship.model.callSign' | translate }}</dt>
            <dd>{{ ship.callSign }}</dd>
          </div>
          <div class="attribute-group-item">
            <dt>{{ 'nautical.ship.model.flagCode' | translate }}</dt>
            <dd>{{ ship.flagCode | uppercase }}</dd>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
