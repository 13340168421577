import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { LayoutService } from '@seahorse/layout';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'underscore';

@Component({
  host: {
    '(document:click)': 'onDocumentClick($event)',
  },
  selector: 'ca-filter-list',
  templateUrl: 'filter-list.component.html',
})
export class FilterListComponent {
  private _buttonDisplayText = 'Button';
  private _listData: any[] = [];
  private _listDisplayMember: string = null;
  private _listTitle = 'Filters';
  private _selectedItem = null;

  isLoading = false;

  sidebarOpen = false;

  private destroy$ = new Subject();

  @Input() topMargin = false;

  @Input('button-displayText')
  set buttonDisplayText(displayText: string) {
    this._buttonDisplayText = displayText;
  }
  get buttonDisplayText(): string {
    return this._buttonDisplayText;
  }

  @Input('list-data')
  set listData(listData: any[]) {
    this._listData = listData;
    if (listData && listData.length > 0) {
      this.isLoading = false;
      const defaultData = _.find(listData, (d) => d.default === true);
      if (defaultData) {
        this._selectedItem = defaultData;
      }
    }
  }
  get listData(): any[] {
    return this._listData;
  }

  @Input('list-displayMember')
  set listDisplayMember(displayMember: string) {
    this._listDisplayMember = displayMember;
  }
  get listDisplayMember(): string {
    return this._listDisplayMember;
  }

  @Input('list-title')
  set listTitle(title: string) {
    this._listTitle = title ? title : 'Filters';
  }
  get listTitle(): string {
    return this._listTitle;
  }

  @Output() selected = new EventEmitter<any>();
  @Output() closeAdvancedSearch = new EventEmitter<any>();

  isExpanded = false;

  constructor(
    private _elementRef: ElementRef,
    private _layoutService: LayoutService
  ) {
    this.isLoading = true;

    this._layoutService.sidebarToggle$
      .pipe(takeUntil(this.destroy$))
      .subscribe((sidebarOpen: boolean) => {
        this.sidebarOpen = sidebarOpen;
      });
  }

  getButtonText() {
    if (this._selectedItem) {
      const displayText = this.getDisplayText(this._selectedItem);
      if (displayText) {
        return displayText;
      }
    }
    return this._buttonDisplayText ? this._buttonDisplayText : 'Button';
  }

  getDisplayText(item: any) {
    if (item[this._listDisplayMember]) {
      return item[this._listDisplayMember];
    } else if (this._listDisplayMember) {
      return this._listDisplayMember;
    } else {
      return item.toString();
    }
  }

  setSelectedItem(item: any, fromInvoicePage = false) {
    this._selectedItem = item;
    if (!fromInvoicePage) {
      this.selected.emit(item);
      this.toggleExpanded();
    }
  }

  onDocumentClick(event) {
    if (!this._elementRef.nativeElement.contains(event.target)) {
      if (this.isExpanded) {
        this.isExpanded = false;
      }
    }
  }

  toggleExpanded() {
    this.isExpanded = !this.isExpanded;
    if (this.isExpanded) {
      this.closeAdvancedSearch.emit();
    }
  }
}
