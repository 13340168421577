import { Component, Input, Output, EventEmitter } from '@angular/core';

import { EventModel } from '../../../../../../shared/models/event.model';

@Component({
  selector: 'calculations-modal',
  templateUrl: 'calculations-modal.component.html',
})
export class CalculationsModalComponent {
  @Input() calculation: any;

  @Output() onEvent: EventEmitter<EventModel>;

  constructor() {
    this.calculation = null;
    this.onEvent = new EventEmitter<EventModel>();
  }
}
