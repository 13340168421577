import {
  Component, Input,
  OnDestroy
} from '@angular/core';
import { Router } from '@angular/router';
import {
  NgbModal
} from '@ng-bootstrap/ng-bootstrap';
import { AISMarkerModel, NauticalShipModel, NauticalVisitOverviewModel } from '@seahorse/domain';
import { Subscription } from 'rxjs';
import { MapCustomActionsService } from '../../../map/services/map-custom-actions.service';
import { MapInteractionService } from '../../../map/services/map-interaction.service';

import { MyFleetModalComponent } from '../my-fleet-modal/my-fleet-modal.component';

@Component({
  selector: 'ca-map-popup-actions',
  templateUrl: './map-popup-actions.component.html',
  styleUrls: ['./map-popup-actions.component.scss'],
})
export class MapPopupActionsComponent implements OnDestroy {
  private subscriptions$ = Array<Subscription>();

  @Input() ship: NauticalShipModel;
  @Input() marker: AISMarkerModel;
  @Input() aisInfo: any;
  @Input() activeVisit: NauticalVisitOverviewModel;

  constructor(
    public customActionServices: MapCustomActionsService,
    private modalService: NgbModal,
    private router: Router,
    private mapInteractionService: MapInteractionService
  ) {}

  ngOnDestroy() {
    this.subscriptions$.forEach((s) => s.unsubscribe());
  }

  etaCalculation() {
    this.mapInteractionService.startEtaCalculation();
  }

  addToMyFleet() {
    const modalRef = this.modalService.open(MyFleetModalComponent, {
      backdrop: 'static',
    });
    modalRef.componentInstance.shipId = this.ship.id;
  }

  portVisitDetails() {
    this.router
      .navigate([`/nautical/visit/${this.activeVisit.id}`])
      .then(
        () => {},
        () => {}
      )
      .catch(() => {})
      .finally(() => {});
  }
}
