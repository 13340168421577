import { Injectable } from '@angular/core';
import { InvoicingTariffModel } from '../../../../core/models/invoicing-tariff.model';
import { ResultWrapper } from '@seahorse/common';
import { DataContextService } from '@seahorse/common';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TariffModel } from '../models/tariff.model';
import { TariffsDuplicateModel } from '../models/tariffs-duplicate.model';
import { DataService } from '@seahorse/domain';

@Injectable({ providedIn: 'root' })
export class TariffDataService implements DataService<TariffModel> {
  constructor(private dataContext: DataContextService) {}

  getById(id: number): Observable<ResultWrapper<TariffModel>> {
    return this.dataContext.get<TariffModel>(
      `invoice/tariff-group/tariff/${id}`
    );
  }

  getByIds(ids: number[]): Observable<ResultWrapper<TariffModel[]>> {
    return this.dataContext
      .post<TariffModel[]>(`invoice/tariff-group/tariff/ids`, ids)
      .pipe(map((response) => response));
  }

  add(model: TariffModel): Observable<ResultWrapper<TariffModel>> {
    return this.dataContext.post<TariffModel>(
      `invoice/tariff-group/tariff`,
      model
    );
  }

  addForInvoicing(
    model: InvoicingTariffModel
  ): Observable<ResultWrapper<TariffModel>> {
    return this.dataContext.post<TariffModel>(
      `invoice/tariff/invoicing`,
      model
    );
  }

  update(model: TariffModel): Observable<ResultWrapper<TariffModel>> {
    return this.dataContext.put<TariffModel>(
      `invoice/tariff-group/tariff`,
      model
    );
  }

  delete(id: number): Observable<ResultWrapper<TariffModel>> {
    return this.dataContext.delete<TariffModel>(
      `invoice/tariff-group/tariff/${id}`
    );
  }

  duplicateTariffs(
    model: TariffsDuplicateModel
  ): Observable<ResultWrapper<TariffModel[]>> {
    return this.dataContext
      .post<TariffModel[]>(`invoice/tariff-group/tariff/duplicate`, model)
      .pipe(map((response) => response));
  }

  getTariffsByGroupId(
    groupId: number,
    pageIndex: number,
    pageSize: number
  ): Observable<ResultWrapper<TariffModel[]>> {
    return this.dataContext
      .get<TariffModel[]>(
        `invoice/tariff-group/${groupId}/tariff?pindex=${pageIndex}&psize=${pageSize}`
      )
      .pipe(map((response) => response));
  }

  getTariffsByGroupIdAndDate(
    groupId: number,
    date: string,
    pageIndex: number,
    pageSize: number
  ): Observable<ResultWrapper<TariffModel[]>> {
    const dateString = moment(date).format('YYYY-MM-DD');
    return this.dataContext
      .get<TariffModel[]>(
        `invoice/tariff-group/${groupId}/tariff?date=${dateString}&pindex=${pageIndex}&psize=${pageSize}`
      )
      .pipe(map((response) => response));
  }
}
