import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SystemMessageModel } from '../../../../preference/models/system-message.model';
import { SystemMessageService } from '../../../../preference/services/system-message.service';
import { EventModel } from '../../../../shared/models/event.model';
import { InteractionService } from '../../../../shared/services/interaction.service';

@Component({
  selector: 'ca-system-message-summary',
  templateUrl: './system-message-summary.component.html',
})
export class SystemMessageSummaryComponent implements OnInit {
  isLoading = false;
  systemMessage: SystemMessageModel;
  isSubmitted = false;

  @Output() onEvent: EventEmitter<EventModel>;

  constructor(
    @Inject('SystemMessageInteractionService')
    private systemMessageInteractionService: InteractionService,
    private systemMessageService: SystemMessageService,
    private route: ActivatedRoute
  ) {
    this.onEvent = new EventEmitter<EventModel>();
  }

  ngOnInit() {
    this.route.params.subscribe((routeParams) => {
      const systemMessageId = +routeParams['id'];

      this.getSystemMessageById(systemMessageId);
    });
  }

  getSystemMessageById(systemMessageId: number) {
    this.isLoading = true;

    this.systemMessageService.getById(systemMessageId).subscribe((response) => {
      this.systemMessage = response.result;

      this.isLoading = false;
    });
  }

  save() {
    if (!SystemMessageModel.validate(this.systemMessage)) {
      this.isSubmitted = true;
      return;
    }

    this.systemMessageInteractionService
      .modify(this.systemMessage)
      .subscribe(() => {});
  }

  delete() {
    this.systemMessageInteractionService.delete(this.systemMessage);
  }
}
