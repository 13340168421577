export class InvoiceSearchModel {
  number: string;
  invoiceDate: Date;
  invoiceFlowId?: number;
  invoiceType: number;
  description: string;
  companyFinancialId: number;
}

export class InvoiceServerSearchModel {
  statuses?: number[];
  companyId?: number;
  dateFrom?: any;
  dateUntil?: any;
  appendFields?: string[];
  invoiceTypes?: number[];
  searchModel: InvoiceSearchModel = null;
  includeLines = false;
  annotate = false;

  constructor(
    public search: string,
    public pageIndex: number,
    public pageSize: number
  ) {}
}
