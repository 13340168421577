import {
  CompanyFinancialModel,
  CompanyModel,
  FlowModel,
} from '@seahorse/domain';

export class ExecutionModel {
  id: number;
  invoiceFlowId: FlowModel['id'];
  invoiceType?: number;
  status: number;
  startedOn?: Date;
  endedOn?: Date;
  referenceNumber: string;
  startedBySource: string;
  startedByReference: string;
  companyId?: CompanyModel['id'];
  companyFinancialId?: CompanyFinancialModel['id'];
  isManualInvoice?: boolean;
  calculationDate?: Date | string | null;
  invoiceFlowExecutionData: InvoiceFlowExecutionDataModel;

  constructor() {
    this.id = null;
    this.invoiceFlowId = null;
    this.invoiceType = null;
    this.status = null;
    this.startedOn = null;
    this.endedOn = null;
    this.referenceNumber = null;
    this.startedBySource = null;
    this.startedByReference = null;
    this.companyId = null;
    this.companyFinancialId = null;
    this.invoiceFlowExecutionData = null;
    this.isManualInvoice = null;
    this.calculationDate = null;
  }
}

export class InvoiceFlowExecutionDataModel {
  invoiceFlowExecutionId: number;
  sourceData: string;
  outputData: InvoiceFlowExecutionOutputDataModel;

  constructor() {
    this.invoiceFlowExecutionId = null;
    this.sourceData = null;
    this.outputData = null;
  }
}

export class InvoiceFlowExecutionOutputDataModel {
  stages: any[];

  constructor() {
    this.stages = null;
  }
}
