import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { IdentityServiceBase } from '../services/identity.service';

export const loginGuard: CanActivateFn = (): boolean => {
  const router = inject(Router);
  const identityService = inject(IdentityServiceBase);

  if (identityService.isAuthenticated) {
    router.navigate(['/']);
    return false;
  }

  return true;
};
