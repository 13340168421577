import { CommonModule, UpperCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { AttributeListComponent } from '@seahorse/temp';
import { LayoutComponentsModule } from '../../layout/components/layout-components.module';
import { SharedModule } from '../../shared/shared.module';

import { NauticalVisitPipesModule } from '../pipes/nautical-visit-pipes.module';
import { NauticalMovementPickerDialogComponent } from './nautical-movement-picker-dialog/nautical-movement-picker-dialog.component';
import { NauticalVisitCardComponent } from './nautical-visit-card/nautical-visit-card.component';
import { NauticalVisitDetailsComponent } from './nautical-visit-details/nautical-visit-details.component';
import { NauticalVisitFlowComponent } from './nautical-visit-flow.component/nautical-visit-flow.component';
import { NauticalVisitHeaderComponent } from './nautical-visit-header/nautical-visit-header.component';
import { NauticalVisitListComponent } from './nautical-visit-list/nautical-visit-list.component';
import { NauticalVisitPickerDialogComponent } from './nautical-visit-picker-dialog/nautical-visit-picker-dialog.component';
import { NauticalVisitSummaryComponent } from './nautical-visit-summary/nautical-visit-summary.component';
import { NauticalVisitTilesComponent } from './nautical-visit-tiles/nautical-visit-tiles.component';
import { VisitAgentComponent } from './visit-agent/visit-agent.component';
import { VisitAttributesComponent } from './visit-attributes/visit-attributes.component';
import { VisitEventsComponent } from './visit-events/visit-events.component';
import { VisitHeaderComponent } from './visit-header/visit-header.component';
import { VisitInfoComponent } from './visit-info/visit-info.component';
import { VisitMovementsComponent } from './visit-movements/visit-movements.component';
import { VisitShipComponent } from './visit-ship/visit-ship.component';

@NgModule({
  declarations: [
    NauticalVisitCardComponent,
    NauticalVisitDetailsComponent,
    NauticalVisitFlowComponent,
    NauticalVisitHeaderComponent,
    NauticalVisitListComponent,
    NauticalVisitSummaryComponent,
    NauticalVisitTilesComponent,
    VisitHeaderComponent,
    VisitInfoComponent,
    VisitAgentComponent,
    VisitShipComponent,
    VisitAttributesComponent,
    VisitEventsComponent,
    VisitMovementsComponent,
    NauticalVisitPickerDialogComponent,
    NauticalMovementPickerDialogComponent,
  ],
  exports: [
    NauticalVisitCardComponent,
    NauticalVisitDetailsComponent,
    NauticalVisitFlowComponent,
    NauticalVisitHeaderComponent,
    NauticalVisitListComponent,
    NauticalVisitSummaryComponent,
    NauticalVisitTilesComponent,
    VisitHeaderComponent,
    VisitInfoComponent,
    VisitAgentComponent,
    VisitShipComponent,
    VisitAttributesComponent,
    VisitEventsComponent,
    VisitMovementsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    LayoutComponentsModule,
    NauticalVisitPipesModule,
    SharedModule,
    TranslateModule,
    RouterModule,
    NgbModule,
    AttributeListComponent
  ],
  providers: [UpperCasePipe],
})
export class NauticalVisitComponentsModule {}
