import {
  Component,
  Input,
  Output,
  OnDestroy,
  OnInit,
  EventEmitter,
  AfterViewInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IdentityService } from '../../../core/services/identity.service';
import { ProxyServices } from '../../../core/services/proxy.service';
import { MenuItem } from '../../../layout/models/menu-item.model';
import { LayoutDisplayService } from '../../../layout/services/layout-display.service';
import { CaEnvService } from '../../../shared/services/env.service';
import { Subscription } from 'rxjs';

import { UiModule } from '@seahorse/domain';
import { UiModulesService } from '../../../custom-modules/services/ui-modules.service';
import { AddCustomUIModalComponent } from '../add-custom-ui-modal/add-custom-ui-modal.component';
import { navigation } from './sidebar.config';
import { LayoutService, SIDEBAR_LOCAL_STORAGE } from '@seahorse/layout';
import { PreferenceModel } from '../../../preference/models/preference.model';
import { PreferenceDataService } from '../../../preference/services/preference-data.service';
import { NotificationService } from '@seahorse/common';
import { FormattingService } from '../../../core/services/formatting.service';

@Component({
  selector: 'ca-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input()
  set sidebarOpen(value: boolean) {
    value = value === true;
    localStorage.setItem(
      SIDEBAR_LOCAL_STORAGE.isOpen,
      value.toString().toLowerCase()
    );
    this._layoutService.toggleSidebar(value);

    const setPreference = {
      name: 'sidebarOpen',
      fieldType: 3,
      fieldValue: value,
      category: 'layout',
    } as PreferenceModel;

    this.subscriptions$.add(
      this._preferenceDataService.save(setPreference).subscribe({
        next: (response) => {
          if (response.hasResult)
            this._identityService.setPreferences([response.result]);
          else
            this._notificationService.displayErrorNotification(
              response.messages
            );
        },
        error: (e) => this._notificationService.displayErrorNotification(e),
      })
    );
  }

  get sidebarOpen(): boolean {
    return localStorage.getItem(SIDEBAR_LOCAL_STORAGE.isOpen) === 'true';
  }

  navigationPrimary: MenuItem[] = [] as MenuItem[];
  navigationSecondary: MenuItem[] = [] as MenuItem[];
  uiModules = [] as UiModule[];
  subscriptions$ = new Subscription();
  @Output() toggleSidebarEvent = new EventEmitter<boolean>();

  constructor(
    private _customModuleService: UiModulesService,
    private _environment: CaEnvService,
    private _formatting: FormattingService,
    private _identityService: IdentityService,
    private _layoutDisplayService: LayoutDisplayService,
    private _layoutService: LayoutService,
    private _ngbModal: NgbModal,
    private _notificationService: NotificationService,
    private _preferenceDataService: PreferenceDataService,
    private _proxyService: ProxyServices,
    private _router: Router
  ) {}

  ngOnInit() {
    this.sidebarOpen = false;

    this.loadNavigation();
    this.subscriptions$.add(
      this._layoutDisplayService.navigationReload$.subscribe(() => {
        this.loadNavigation();
      })
    );
  }

  ngAfterViewInit() {
    const sidebarPreference = this._identityService
      .getPreferences()
      .find((x) => x.name === 'sidebarOpen' && x.category === 'layout');
    setTimeout(() => {
      this.toggleSidebarEvent.emit(
        !sidebarPreference ||
          sidebarPreference.fieldValue.toString().toLowerCase() === 'true'
      );
    });
  }

  navigate(str: string) {
    const findPath = location.pathname.indexOf(str);
    if (findPath !== -1) {
      this._router
        .navigateByUrl('/not-found', { skipLocationChange: true })
        .then(() => this._router.navigate([str]));
    }
  }

  navigateToModule(module: UiModule) {
    this._customModuleService.currentUiModule$.next(module);
    this._router.navigate(['module', module.systemCode, module.entryRoute]);
  }

  loadNavigation() {
    this.uiModules = this._formatting.sortBy(
      this._proxyService.uiModules,
      'menuOrder'
    );
    this.navigationPrimary = navigation.filter(
      (x) =>
        (x.isSecondary === undefined || x.isSecondary === false) &&
        (!x.permissions || this._identityService.hasPermission(x.permissions))
    );

    this.navigationPrimary.sort((item1, item2) => {
      if (item1.order > item2.order) {
        return 1;
      } else if (item1.order < item2.order) {
        return -1;
      } else {
        return 0;
      }
    });
    this.navigationSecondary = navigation.filter(
      (x) =>
        x.isSecondary !== undefined &&
        x.isSecondary === true &&
        (!x.permissions || this._identityService.hasPermission(x.permissions))
    );

    this.navigationSecondary.sort((item1, item2) => {
      if (item1.order > item2.order) {
        return 1;
      } else if (item1.order < item2.order) {
        return -1;
      } else {
        return 0;
      }
    });
  }

  openNewCustomUiModal() {
    this._ngbModal.open(AddCustomUIModalComponent, {
      backdrop: 'static',
    });
  }

  ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }

  toggleSidebar() {
    this.toggleSidebarEvent.emit(!this.sidebarOpen);
  }
}
