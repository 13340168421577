import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ColumnModel, GridComponent } from '@syncfusion/ej2-angular-grids';

@Component({
  selector: 'sh-grid',
  template: `
    <ejs-grid
      #grid
      [dataSource]="data"
      [columns]="columns"
      (rowSelected)="onRowSelected($event)"
    ></ejs-grid>
    <button
      *ngIf="loadMore"
      ejs-button
      class="load-more"
      (click)="triggerLoadMore()"
    >
      Load more
    </button>
  `,
  styles: [
    `
      .e-grid .e-rowcell,
      .e-grid .e-row {
        cursor: pointer;
      }
      .load-more {
        margin-top: 10px;
        margin-bottom: 10px;
        height: 35px;
        width: 100%;
      }
    `,
  ],
})
export class DataGridComponent {
  @Input() data: any;
  @Input() columns?: ColumnModel[];
  @Input() loadMore = false;
  @Output() rowSelected = new EventEmitter<any>();
  @Output() loadMoreClicked = new EventEmitter<any>();

  @ViewChild('grid') instance!: GridComponent;

  onRowSelected(args: any) {
    this.rowSelected.emit(args.data);
  }

  refresh() {
    this.instance.refresh();
  }

  triggerLoadMore() {
    this.loadMoreClicked.emit();
  }
}
