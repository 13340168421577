<div class="page-header">
  <div class="row">
    <div class="col-sm-6">
      <div class="btn-list px-4"></div>
    </div>
    <div class="col-sm-6 text-right">
      <div class="btn-list">
        <a
          *ngIf="
            masterShipId !== undefined &&
            masterShipId !== null &&
            masterShipId > 0
          "
          class="btn btn-outline-secondary"
          href="/nautical/vessels/base_{{ masterShipId }}"
        >
          <i class="fa-regular fa-fw fa-share"></i>
          {{ 'shared.terms.original' | translate }}
        </a>
        <button
          [hidden]="!allowEdit"
          class="btn btn-outline-secondary mr-0"
          (click)="action('addAttribute')"
        >
          <i class="fa-regular fa-plus fa-fw"></i>
          {{ 'shared.terms.addAttribute' | translate }}
        </button>
        <div *ngIf="allowDelete" class="dropdown">
          <button
            id="headerHamburgerButton"
            type="button"
            data-toggle="dropdown"
            class="btn btn-outline-secondary mx-1"
          >
            <i class="fa-regular fa-ellipsis-v fa-fw"></i>
          </button>
          <div class="dropdown-menu" aria-labelledby="headerHamburgerButton">
            <button
              (click)="action('deleteCustomerShip')"
              type="button"
              class="btn dropdown-item"
            >
              <i class="fa-solid fa-trash fa-fw"></i>
              {{ 'shared.terms.delete' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
