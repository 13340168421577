import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TaskDefinitionModel } from '../../models/task-definition.model';

@Component({ templateUrl: './task-definition-modal.component.html' })
export class TaskDefinitionModalComponent {
  @Input() taskDefinition: TaskDefinitionModel;

  @Output() onAddOrEditTaskDefinition: EventEmitter<TaskDefinitionModel>;

  constructor() {
    this.onAddOrEditTaskDefinition = new EventEmitter<TaskDefinitionModel>();
    this.taskDefinition = new TaskDefinitionModel();
  }
}
