<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title-bold">{{ 'user.addUser' | translate }}</h4>
    <button type="button" class="close" closeModal>
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <user-form [model]="user" [submitted]="isSubmitted"> </user-form>

    <div class="d-flex">
      <div class="flex-grow-1">
        <button type="button" class="btn btn-block btn-link" closeModal>
          {{ 'shared.terms.cancel' | translate }}
        </button>
      </div>
      <div class="flex-grow-1">
        <button
          type="button"
          class="btn btn-block btn-success"
          (click)="save()"
        >
          {{ 'shared.terms.save' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
