<div class="card" *hasPermission="'companies.company_financials'">
  <div class="card-header">
    {{ 'companies.financial.financial' | translate }}
    <div class="btn-list">
      <button
        class="btn btn-outline-secondary btn-sm"
        *hasPermission="'companies.company_financials_add'"
        type="button"
        (click)="onEvent.emit({ action: 'editFinancial' })"
      >
        <i class="fa-regular fa-plus"></i>
      </button>
    </div>
  </div>
  <div class="card-body">
    <div *ngFor="let financial of financials" class="card-body-group">
      <div class="row">
        <div class="col-sm-6">
          <ul class="list-inline list-inline-seperated">
            <li class="list-inline-item">
              <b>{{ 'companies.financial.model.debtorNumber' | translate }}:</b>
              {{ financial.debtorNumber }}
            </li>
            <li class="list-inline-item">
              <b>{{ 'companies.financial.model.cocNumber' | translate }}:</b>
              {{ financial.cocNumber }}
            </li>
            <li class="list-inline-item">
              <b>{{ 'companies.financial.model.vatNumber' | translate }}:</b>
              {{ financial.vatNumber }}
            </li>
          </ul>
        </div>
        <div class="col-sm-6 text-right">
          <button
            class="btn btn-hover-success"
            *hasPermission="'companies.company_financials_update'"
            (click)="onEvent.emit({ action: 'editFinancial', data: financial })"
          >
            <i class="fa-regular fa-pencil-alt"></i>
          </button>

          <button
            class="btn btn-hover-danger"
            *hasPermission="'companies.company_financials_delete'"
            (click)="
              onEvent.emit({ action: 'deleteFinancial', data: financial })
            "
          >
            <i class="fa-solid fa-times fa-fw"></i>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <ul class="list-inline list-inline-seperated">
            <li class="list-inline-item">
              <b>{{ 'companies.financial.model.startsOn' | translate }}:</b>
              {{ financial.startsOn }}
            </li>
            <li class="list-inline-item">
              <b>{{ 'companies.financial.model.endsOn' | translate }}:</b>
              {{ financial.endsOn }}
            </li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <ul class="list-inline list-inline-seperated">
            <li class="list-inline-item">
              <b>{{ 'companies.financial.model.emailAddress' | translate }}:</b>
              {{ financial.emailAddress }}
            </li>
            <li class="list-inline-item">
              <b>{{ 'companies.financial.model.iban' | translate }}:</b>
              {{ financial.iban }}
            </li>
            <li class="list-inline-item">
              <b>{{ 'companies.financial.model.bic' | translate }}:</b>
              {{ financial.bic }}
            </li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <ul class="list-inline list-inline-seperated">
            <li class="list-inline-item">
              <b>{{ 'companies.financial.model.allowSepa' | translate }}:</b>
              {{ financial.allowSepa }}
            </li>
            <li class="list-inline-item">
              <b
                >{{ 'companies.financial.model.invoiceMethod' | translate }}:</b
              >
              {{ financial.invoiceMethod }}
            </li>
            <li class="list-inline-item">
              <b>{{ 'companies.financial.model.addressing' | translate }}:</b>
              {{ financial.addressing }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div
    class="card-footer card-footer-clickable"
    (click)="loadMore()"
    *ngIf="canLoadMore"
  >
    <span> {{ 'shared.terms.loadMore' | translate }} </span>
  </div>
</div>
