<table class="table" *ngIf="!loadEvents">
  <tbody>
    <tr *ngFor="let event of events">
      <td style="width: 1%; white-space: nowrap" class="text-muted">
        {{ event.startsOn | date: 'dd-MM-yyyy HH:mm' }}
      </td>
      <!-- <td style="width: 1%; white-space: nowrap;">{{ event.endsOn | date: 'dd-MM-yyyy HH:mm' }}</td> -->
      <td style="width: 1%; white-space: nowrap">
        <span title="{{ event.eventType | nauticalVisitEventType }}">
          <i class="fa-regular fa-clock fa-fw" *ngIf="event.eventType === 1"></i>
          <i class="fa-regular fa-check fa-fw" *ngIf="event.eventType === 2"></i>
          <i
            class="fa-regular fa-question fa-fw"
            *ngIf="event.eventType !== 1 && event.eventType !== 2"
          ></i>
        </span>
        {{
          'nautical.visit.visitEvents.' + event.activityType.toLowerCase()
            | translate
        }}
      </td>
      <td>
        {{
          event.parameters
            ? event.parameters.replace(':', ': ').replace(';', ', ')
            : ''
        }}
      </td>
      <td>{{ event.remarks }}</td>
    </tr>
  </tbody>
</table>
