import { Pipe, PipeTransform } from '@angular/core';
import { UserModel } from '@seahorse/domain';

@Pipe({ name: 'searchUsers' })
export class SearchUsersPipe implements PipeTransform {
  transform(users: UserModel[], searchField: string): any[] {
    if (!users) {
      return [];
    }

    if (!searchField) {
      return users;
    }

    searchField = searchField.toLocaleLowerCase();

    return users.filter((user) => {
      return (
        user.givenName.toLocaleLowerCase().includes(searchField) ||
        user.surname.toLocaleLowerCase().includes(searchField)
      );
    });
  }
}
