<div class="card">
  <div class="card-header">
    {{ 'invoicingFlows.businessLogic.dependencies' | translate }}
    <div class="btn-list">
      <button
        [disabled]="loadingDependencies"
        (click)="addDependency()"
        type="button"
        class="btn btn-outline-secondary btn-sm"
      >
        <i class="fa-regular fa-plus fa-fw"></i>
        {{ 'shared.terms.add' | translate }}
      </button>
    </div>
  </div>
  <ng-container
    *ngIf="dependencies.length > 0; then showDependencies; else noDependencies"
  ></ng-container>

  <ng-template #showDependencies>
    <div class="card-body card-body-table table-responsive">
      <table class="table">
        <tbody>
          <tr *ngFor="let dependency of dependencies">
            <td>{{ dependency.businessLogicType | logicType }}</td>
            <td>
              <pre>{{
                dependency.tariffGroupSystemCode.replaceAll(',', '\n')
              }}</pre>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-template>

  <ng-template #noDependencies>
    <div *ngIf="!loadingDependencies" class="card-body">
      <span class="text-muted">{{
        'invoicingFlows.noDependencies' | translate
      }}</span>
    </div>
  </ng-template>
</div>
