import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  NgbTypeahead,
  NgbTypeaheadSelectItemEvent,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@seahorse/common';
import { CompanyFinancialModel, FinancialDataService } from '@seahorse/domain';
import { Observable, Subject, merge, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';

@Component({
  selector: 'ca-company-financial-picker',
  templateUrl: './company-financial-picker.component.html',
})
export class CompanyFinancialPickerComponent {
  @Input() showInline = false;
  @Input() title: string;
  @Input() selectedCompanyFinancial: CompanyFinancialModel;
  @Input() selectedCompanyFinancialLoading: boolean;
  @Input() isRequired: boolean;
  @Input() allowTextOnly: boolean;
  @Input() submitted = false;
  @Input() isDebtorPicker: boolean;
  @Input() isReadOnly: boolean;

  @Output()
  selectedCompanyFinancialChange!: EventEmitter<CompanyFinancialModel>;

  @ViewChild('companyFinancialTypeahead', { static: true })
  companyFinancialTypeahead: NgbTypeahead;

  companyFinancialsLoading: boolean;
  companyFinancialsLoadingFailed: boolean;
  focus$: Subject<string>;
  click$: Subject<string>;

  constructor(
    private translate: TranslateService,
    private companyFinancialData: FinancialDataService,
    private notification: NotificationService
  ) {
    this.title = this.translate.instant(
      'companyFinancials.pickerDialog.selectCompanyFinancial'
    );
    this.selectedCompanyFinancial = null;
    this.selectedCompanyFinancialLoading = false;
    this.isRequired = false;
    this.allowTextOnly = false;
    this.selectedCompanyFinancialChange =
      new EventEmitter<CompanyFinancialModel>();
    this.companyFinancialsLoading = false;
    this.companyFinancialsLoadingFailed = false;
    this.focus$ = new Subject<string>();
    this.click$ = new Subject<string>();
    this.isDebtorPicker = false;
    this.isReadOnly = false;
  }

  itemSelected(event: NgbTypeaheadSelectItemEvent) {
    if (this.isDebtorPicker && !event.item && !event.item.financialId) {
      event.preventDefault();
      return;
    }

    if (event.item) {
      this.selectedCompanyFinancialChange.emit(event.item);
    }
  }

  companyFinancialFormatter = (
    companyFinancial: CompanyFinancialModel
  ): string => {
    if (
      companyFinancial !== undefined &&
      companyFinancial !== null &&
      companyFinancial.debtorNumber !== undefined &&
      companyFinancial.debtorNumber !== null
    ) {
      return companyFinancial.debtorNumber.toUpperCase();
    } else {
      return '';
    }
  };

  searchCompanyFinancial = (input$: Observable<string>): any => {
    const debouncedText$ = input$.pipe(
      debounceTime(300),
      distinctUntilChanged()
    );

    const clicksWithClosedPopup$ = this.click$.pipe(
      filter(() => !this.companyFinancialTypeahead.isPopupOpen())
    );
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      tap(() => (this.companyFinancialsLoading = true)),
      switchMap((s) =>
        this.companyFinancialData.search(s, 0, 100, undefined).pipe(
          tap(() => (this.companyFinancialsLoadingFailed = false)),
          map((r) => r.result),
          catchError((e) => {
            this.companyFinancialsLoadingFailed = true;

            this.notification.showError(
              e,
              this.translate.instant('shared.terms.failed')
            );
            return of([]);
          })
        )
      ),
      tap(() => (this.companyFinancialsLoading = false))
    );
  };

  setNull() {
    this.selectedCompanyFinancialChange.emit(null);
  }
}
