<form #duplicateObject="ngForm">
  <div class="modal-header">
    <div class="modal-title">
      {{ 'files.wizard.linkToObject' | translate }}
    </div>
  </div>
  <div class="modal-body">
    <div class="row" *ngFor="let linkedObject of linkedObjects; let i = index">
      <div class="col-12">
        <div class="form-group input-group">
          <div class="input-group-prepend">
            <div class="input-group-text">
              <input
                [disabled]="editFile && linkedObject.initiallyChecked"
                type="checkbox"
                class="cursor-pointer"
                id="showlinkedobjectincheckbox{{ i }}"
                name="showlinkedobjectincheckbox{{ i }}"
                [(ngModel)]="linkedObject.checked"
              />
            </div>
          </div>
          <label
            class="form-control cursor-pointer"
            for="showlinkedobjectincheckbox{{ i }}"
            title="{{ linkedObject.key }}"
            >{{ objectFieldDefinitions[linkedObject.key].name }}</label
          >
        </div>
      </div>
    </div>
  </div>
</form>
