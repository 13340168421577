import { Injectable } from '@angular/core';
import { DataContextService } from '@seahorse/common';
import { map } from 'rxjs/operators';

import { UiModule } from '@seahorse/domain';

@Injectable({
  providedIn: 'root',
})
export class UiModulesDataService {
  private readonly _baseUrl = 'customdata/uimodule';

  constructor(private _dataContextService: DataContextService) {}

  getAll() {
    return this._dataContextService
      .get<UiModule[]>(`${this._baseUrl}`)
      .pipe(map((x) => (x.hasResult ? x.result : [])));
  }

  getById(id: number) {
    return this._dataContextService.get<UiModule>(`${this._baseUrl}/${id}`);
  }

  delete(id: number) {
    return this._dataContextService.delete<UiModule>(`${this._baseUrl}/${id}`);
  }

  add(uiModule: UiModule, definition: string = null) {
    return this._dataContextService.post<UiModule>(
      `${this._baseUrl}?definition=${definition}`,
      uiModule
    );
  }

  searchUiModules(
    searchQuery?: string,
    pageIndex?: number,
    pageSize?: number,
    sortfield = 'DisplayName',
    orderAscending = false
  ) {
    const queryParams = [
      `searchTerm=${encodeURIComponent(searchQuery)}`,
      `pindex=${pageIndex}`,
      `psize=${pageSize}`,
      `sortfield=${sortfield}`,
      `orderascending=${orderAscending}`,
    ];

    const queryString = queryParams.join('&');
    const url = `${this._baseUrl}/search?${queryString}`;

    return this._dataContextService.get<UiModule[]>(url);
  }

  update(uiModule: UiModule) {
    return this._dataContextService.put<UiModule>(
      `${this._baseUrl}/${uiModule.id}`,
      uiModule
    );
  }
}
