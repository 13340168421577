import { Injectable } from '@angular/core';
import { AddLogModel, LogModel } from '../../../core/models/log.model';
import { ResultWrapper } from '@seahorse/common';
import { DataContextService } from '@seahorse/common';
import { Observable } from 'rxjs';

@Injectable()
export class UserLoggingDataService {
  constructor(private dataContext: DataContextService) {}

  addLogs(
    addLogModel: AddLogModel
  ): Observable<ResultWrapper<LogModel>> {
    return this.dataContext.post<LogModel>(`user/logging`, addLogModel);
  }
}
