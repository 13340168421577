<div class="modal-header">
  <div class="modal-title">{{ 'systemMessage.translation' | translate }}</div>
</div>
<div class="modal-body">
  <div class="form">
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label>{{ 'systemMessage.subject' | translate }}</label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="translation.subject"
          />
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label>{{ 'shared.terms.language' | translate }}</label>
          <select class="form-control" [(ngModel)]="translation.language">
            <option value="en">English (EN)</option>
            <option value="nl">Dutch (NL)</option>
            <option value="sk">Serbian (SK)</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label>{{ 'systemMessage.notes' | translate }}</label>
          <textarea
            class="form-control"
            [(ngModel)]="translation.notes"
            rows="10"
          ></textarea>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-link" (click)="dismiss()">
    {{ 'shared.terms.cancel' | translate }}
  </button>
  <button type="button" class="btn btn-success" (click)="ok()">
    {{ 'shared.terms.ok' | translate }}
  </button>
</div>
