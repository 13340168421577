import { Guid } from 'guid-typescript';

export class ProfilePermission {
  profileId: number;
  permissionId: Guid;
}

export const PERMISSIONGROUPS = [
  { name: 'map', label: 'account.register.permissions.aisMap', checked: true },
  { name: 'ships', label: 'account.register.permissions.ships', checked: true },
  {
    name: 'port_visits',
    label: 'account.register.permissions.ports',
    checked: true,
  },
];
