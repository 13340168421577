<!-- Content -->
<div class="container-fluid py-3">
  <ng-container
    *ngIf="flow; then showFlow; else initializingFlow"
  ></ng-container>

  <ng-template #showFlow>
    <div class="row my-n2">
      <div class="col-12 col-lg-6 col-xl-8 py-2">
        <div class="row my-n2">
          <div class="col-12 py-2">
            <flow-details-info
              [flow]="flow"
              [loadingFlow]="loadingFlow"
              (onEvent)="action($event)"
            ></flow-details-info>
          </div>
          <div class="col-12 py-2">
            <flow-details-stages
              [stages]="flow.stages"
              [loadingFlow]="loadingFlow"
              (onEvent)="action($event)"
            >
            </flow-details-stages>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 col-xl-4 py-2">
        <div class="row my-n2">
          <div class="col-12 py-2">
            <statistics
              [statistics]="statistics"
              [loadingStatistics]="loadingStatistics"
              (onEvent)="action($event)"
            >
            </statistics>
          </div>
          <div class="col-12 py-2">
            <executions-card
              [executionsCount]="executionsCount"
              [executions]="executions"
              [flow]="flow"
              [flowId]="flow.id"
              [loadingExecutions]="loadingExecutions"
              [indexedExecutions]="indexedExecutions"
              (onEvent)="action($event)"
            ></executions-card>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #initializingFlow>
    <sh-loading-indicator
      *ngIf="loadingFlow"
      class="d-block text-center"
    ></sh-loading-indicator>
  </ng-template>
</div>
