import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { EventModel } from '../../../../../shared/models/event.model';

import { TaskModel, TaskStatus, TaskType } from '../../models/task.model';

@Component({
  selector: 'ca-tasks-card',
  templateUrl: './tasks-card.component.html',
})
export class TasksCardComponent {
  @Input() allowAdd = false;
  @Input() allowDelete = false;
  @Input() allowEdit = false;
  @Input() allowNavigateToTaskPage = false;
  @Input() loading = false;
  @Input() tasks: TaskModel[] = null;

  @Output() event: EventEmitter<EventModel>;

  TaskStatus = TaskStatus;
  TaskType = TaskType;

  constructor(private router: Router) {
    this.event = new EventEmitter<EventModel>();
  }

  onClick(action: string, task: TaskModel) {
    switch (action) {
      case 'addTask':
        if (this.allowAdd !== true) {
          return;
        }
        break;
      case 'deleteTask':
        if (this.allowDelete !== true) {
          return;
        }
        break;
      case 'editTask':
        // when edit is not allow, but it is allow to redirect to the task page, redirect to the selected task
        if (this.allowEdit !== true && this.allowNavigateToTaskPage === true) {
          this.router.navigateByUrl(`/tasks/overview;id=${task.id}`);
          return;
        }
        break;
    }
    this.event.emit(new EventModel({ action: action, data: task }));
  }
}
