import { Component, EventEmitter, HostListener, Output } from '@angular/core';

@Component({
  selector: 'temp-inline-form-actions',
  template: `
    <div class="actions">
      <span id="cancel" (click)="cancel.emit()"
        ><i class="fa-solid fa-times fa-fw"></i
      ></span>
    </div>
  `,
  styleUrls: [`./inline-form-actions.template.scss`],
  standalone: true,
})
export class InlineFormActionsTemplate {
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() submit = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();

  @HostListener('document:keyup.enter', ['$event'])
  onEnter() {
    this.submit.emit();
  }
}
