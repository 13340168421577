import { Component, Input, OnInit } from '@angular/core';
import { AISMarkerModel } from '@seahorse/domain';
import * as _ from 'underscore';
import * as mapboxgl from 'mapbox-gl';
import * as moment from 'moment';

import { MapInteractionService } from '../../services/map-interaction.service';
import { CoordinatePointModel } from '@seahorse/domain';

@Component({
  selector: 'ca-map-box-display-track',
  templateUrl: './map-box-display-track.component.html',
  styleUrls: ['./map-box-display-track.component.scss'],
})
export class MapBoxDisplayTrackComponent implements OnInit {
  map: mapboxgl.Map;
  style = 'mapbox://styles/mapbox/outdoors-v9';

  private _track: AISMarkerModel[] = [];

  @Input() get track(): AISMarkerModel[] {
    return this._track;
  }
  set track(input: AISMarkerModel[]) {
    this._track = input;
    this.drawRoute();
  }

  constructor(private mapInteractionService: MapInteractionService) {}

  ngOnInit() {
    this.buildMap();
  }

  buildMap() {
    this.mapInteractionService.map = new mapboxgl.Map({
      attributionControl: false,
      container: 'nautical-map-track-display',
      style: this.style,
    });

    this.map = this.mapInteractionService.map;
    this.map.addControl(new mapboxgl.NavigationControl());

    this.map.on('load', (event) => {
      if (this.track) {
        this.drawRoute();

        const latitudes = _.map(this.track, (p) => p.latitude);
        const longitudes = _.map(this.track, (p) => p.longitude);

        const bounds = [
          [_.max(longitudes), _.min(latitudes)],
          [_.min(longitudes), _.max(latitudes)],
        ];

        this.map.fitBounds(bounds, { padding: 20 });
      }
    });

    // Create a popup, but don't add it to the map yet.
    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
    });

    this.map.on('mouseenter', 'points', (e) => {
      // Change the cursor style as a UI indicator.
      this.map.getCanvas().style.cursor = 'pointer';

      // Copy coordinates array.
      const coordinates = e.features[0].geometry.coordinates.slice();
      const description = e.features[0].properties.description;

      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      // Populate the popup and set its coordinates
      // based on the feature found.
      popup.setLngLat(coordinates).setHTML(description).addTo(this.map);
    });

    this.map.on('mouseleave', 'points', () => {
      this.map.getCanvas().style.cursor = '';
      popup.remove();
    });
  }

  clearRoute() {
    if (this.map) {
      if (this.map.getLayer('route')) {
        this.map.removeLayer('route');
      }
      if (this.map.getLayer('points')) {
        this.map.removeLayer('points');
      }
      if (this.map.getSource('route')) {
        this.map.removeSource('route');
      }
      if (this.map.getSource('points')) {
        this.map.removeSource('points');
      }
    }
  }

  drawRoute() {
    if (!this.map) {
      return;
    }
    if (!this._track || this._track.length === 0) {
      return;
    }

    this.clearRoute();

    const coords = _.map(this._track, (c: CoordinatePointModel) => [
      c.longitude,
      c.latitude,
    ]);

    this.map.addSource('route', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates: coords,
            },
          },
        ],
      },
    });

    this.map.addSource('points', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: _.map(this.track, (trackItem) => {
          return {
            type: 'Feature',
            properties: {
              description: moment
                .utc(trackItem.positionDate)
                .local()
                .format('DD-MM-YYYY HH:mm'),
            },
            geometry: {
              type: 'Point',
              coordinates: [trackItem.longitude, trackItem.latitude],
            },
          };
        }),
      },
    });

    this.map.addLayer({
      id: 'route',
      type: 'line',
      source: 'route',
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': '#F7941D',
        'line-width': 4,
      },
    });

    this.map.addLayer({
      id: 'points',
      type: 'circle',
      source: 'points',
      paint: {
        'circle-color': '#081c39',
        'circle-radius': 3,
        'circle-stroke-width': 1,
        'circle-stroke-color': '#ffffff',
      },
    });
  }
}
