import {
  UiModuleCustomComponent,
  UiModuleCustomComponents,
} from '@seahorse/temp';
import { CompanyFormComponent } from '../../company/components/company/company.component';
import { ExpenseDialogComponent } from '../../expenses/components/expense-dialog/expense-dialog.component';
import { ExpensesListComponent } from '../../expenses/components/expenses-list/expenses-list.component';
import { NauticalShipBoxComponent } from '@seahorse/temp';
import { PurchaseInvoiceListComponent } from '../../invoicing-and-tariffs/modules/purchase-invoices/components/purchase-invoice-list/purchase-invoice-list.component';
import { ExpenseModel } from '@seahorse/domain';

const expenseConfiguration = <UiModuleCustomComponent<ExpensesListComponent>>{
  type: 'externalObjectCard',
  component: ExpensesListComponent,
  data: (title, id, parent, parentMappingKey, externalConfigurationId) => ({
    parent,
    parentMappingKey,
    ...(parentMappingKey === '$customcontent_st-orderregistration_id' && {
      expenseModel: Object.assign(new ExpenseModel(), {
        orderRegistration: parent.__Id,
        portCallFile: parent.portCallFile,
      }),
    }),
    hideOrderRegistrationPicker:
      parentMappingKey === '$customcontent_st-orderregistration_id',
    externalConfigurationId,
  }),
};

export const uiModuleCustomComponents: UiModuleCustomComponents = [
  <UiModuleCustomComponent<CompanyFormComponent>>{
    key: '$companies_company',
    type: 'form',
    component: CompanyFormComponent,
    data: (id) => ({
      navigateOnSave: false,
      companyId: id,
    }),
  },
  <UiModuleCustomComponent<ExpenseDialogComponent>>{
    key: '$customcontent_st-expense',
    type: 'form',
    component: ExpenseDialogComponent,
    data: (id) => ({
      hideOrderRegistrationPicker: true,
      expenseId: id,
    }),
  },
  { ...expenseConfiguration, key: '$customcontent_st-expense' },
  { ...expenseConfiguration, key: '$invoicing_expense' },
  <UiModuleCustomComponent<PurchaseInvoiceListComponent>>{
    key: '$customcontent_ct-purchaseentry',
    type: 'externalObjectCard',
    component: PurchaseInvoiceListComponent,
    data: (title, id, parent, parentMappingKey) => ({
      fieldCode: 'portCallFile',
      parentId: parent.__Id,
    }),
  },
  <UiModuleCustomComponent<NauticalShipBoxComponent>>{
    key: '$nautical_ship',
    type: 'linkedObjectCard',
    component: NauticalShipBoxComponent,
    data: (title, id, displayFields) => ({
      title,
      shipId: id,
      displayFields,
    }),
  },
];
