<ng-container *ngIf="position">
  <div
    class="d-block text-center"
    *ngIf="position.heading !== null || position.courseOverGround !== null"
    title="{{ 'map.popup.speed' | translate }}: {{ position.speed }} kts"
    (click)="goToMap(position)"
  >
    <i
      class="fa-regular fa-location-arrow"
      [ngClass]="{
        'text-muted': !position.speed || position.speed < 1,
        'text-success': position.speed && position.speed >= 1
      }"
      [ngStyle]="arrowStyle"
    ></i>
  </div>
  <div *ngIf="showSpeed && position.speed !== null">
    {{ position.speed }} kts
  </div>
</ng-container>
