import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  CompanyContactModel,
  CompanyDepartmentModel,
} from '@seahorse/domain';
import { DepartmentDataService } from '../../../../company/services/department-data.service';
import { ResultWrapper } from '@seahorse/common';

@Component({
  selector: 'department-modal',
  templateUrl: './department-modal.component.html',
})
export class DepartmentModalComponent implements OnInit {
  @Input() department: CompanyDepartmentModel;
  @Input() companyId: number;

  @Output() onSelectDepartment: EventEmitter<CompanyDepartmentModel>;

  isSubmitted: boolean;
  isNew: boolean;
  contacts: CompanyContactModel[];

  constructor(
    private departmentDataService: DepartmentDataService,
    private cdr: ChangeDetectorRef
  ) {
    this.onSelectDepartment = new EventEmitter<CompanyDepartmentModel>();
    this.isSubmitted = false;
    this.isNew = false;
    this.department = new CompanyDepartmentModel();
  }

  ngOnInit() {
    this.isNew = this.department.id == null;

    if (!this.isNew) {
      this.departmentDataService
        .getById(this.department.id)
        .subscribe(
          (
            departmentGetByIdResponse: ResultWrapper<CompanyDepartmentModel>
          ) => {
            this.department = departmentGetByIdResponse.result;

            this.department.departmentContacts =
              departmentGetByIdResponse.result.departmentContacts.map((x) => {
                return {
                  id: x.contactId,
                  name: x.contactName,
                };
              });

            this.cdr.detectChanges();
          }
        );
    }
  }
}
