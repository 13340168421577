<div class="card">
  <div class="card-header">
    <button
      (click)="onEvent.emit({ action: 'select', data: null })"
      type="button"
      class="btn btn-clear float-right"
    >
      <i class="fa-solid fa-times fa-fw"></i>
    </button>
    <span class="text-uppercase">{{ orderRegistration.po }}</span>
  </div>
  <div class="card-body">
    <ng-container
      *ngIf="!loading; then showSummary; else loadSummary"
    ></ng-container>
    <ng-template #showSummary>
      <div class="card-body-group card-body-group-no-border">
        <div class="row no-gutters attribute-group">
          <div class="col-12 attribute-group-item">
            <dt class="mb-0">
              {{ 'customModules.orderRegistrations.model.po' | translate }}
            </dt>
            <dd>
              {{ orderRegistration.po }}
            </dd>
          </div>
          <div class="col-12 attribute-group-item">
            <dt class="mb-0">
              {{ 'customModules.orderRegistrations.model.type' | translate }}
            </dt>
            <dd>
              {{
                orderRegistration.__DataObjectKey | shCustomDataDefinitionType
              }}
            </dd>
          </div>
          <div class="col-12 attribute-group-item">
            <dt class="mb-0">
              {{ 'customModules.orderRegistrations.model.status' | translate }}
            </dt>
            <dd>
              {{ orderRegistration.status | shOrderRegistrationStatus }}
            </dd>
          </div>
          <!-- <div class="col-12 attribute-group-item">
            <dt class="mb-0">
              {{
                'customModules.orderRegistrations.model.quantity' | translate
              }}
            </dt>
            <dd>{{ orderRegistration.quantity }}</dd>
          </div> -->
          <!-- <div class="col-12 attribute-group-item">
            <dt class="mb-0">
              {{ 'customModules.orderRegistrations.model.amount' | translate }}
            </dt>
            <dd>
              {{
                orderRegistration.amount
                  | currency: orderRegistration.currency:'symbol-narrow':'1.2-2'
              }}
            </dd>
          </div> -->
          <div class="col-12 attribute-group-item">
            <dt class="mb-0">
              {{
                'customModules.orderRegistrations.model.orderDate' | translate
              }}
            </dt>
            <dd>
              <ng-container *ngIf="orderRegistration.orderDate">{{
                orderRegistration.orderDate | date: 'dd-MM-yyyy HH:mm'
              }}</ng-container>
            </dd>
          </div>
          <div class="col-12 attribute-group-item">
            <dt class="mb-0">
              {{
                'customModules.orderRegistrations.model.deliveryDate'
                  | translate
              }}
            </dt>
            <dd>
              <ng-container *ngIf="orderRegistration.deliveryDate">{{
                orderRegistration.deliveryDate | date: 'dd-MM-yyyy HH:mm'
              }}</ng-container>
            </dd>
          </div>
          <div class="col-12 attribute-group-item">
            <dt class="mb-0">
              {{ 'customData.model.__CreatedBy' | translate }}
            </dt>
            <dd>
              <ng-container *ngIf="orderRegistration.__CreatedOn">{{
                orderRegistration.__CreatedBy | nameFromUserId: '' | async
              }}</ng-container>
            </dd>
          </div>
          <div class="col-12 attribute-group-item">
            <dt class="mb-0">
              {{ 'customData.model.__CreatedOn' | translate }}
            </dt>
            <dd>
              <ng-container *ngIf="orderRegistration.__CreatedOn">{{
                orderRegistration.__CreatedOn | date: 'dd-MM-yyyy HH:mm'
              }}</ng-container>
            </dd>
          </div>
          <div class="col-12 attribute-group-item">
            <dt class="mb-0">
              {{
                'customModules.orderRegistrations.model.comments' | translate
              }}
            </dt>
            <dd>
              {{ orderRegistration.comments }}
            </dd>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #loadSummary>
      <sh-loading-indicator
        class="d-flex justify-content-center w-100"
      ></sh-loading-indicator>
    </ng-template>
  </div>
  <ul class="summary-buttons">
    <li>
      <a
        routerLink="/order-registrations/{{ orderRegistration.__Id }}"
        class="summary-button w-100"
      >
        <i class="fa-regular fa-shopping-cart"></i>
        <span class="summary-button-text">{{
          'customModules.orderRegistrations.overview.viewOrderRegistration'
            | translate
        }}</span>
      </a>
    </li>
    <li
      *ngIf="
        orderRegistration.portCallFile && orderRegistration.portCallFile !== ''
      "
    >
      <a (click)="openPortCallFile()" class="summary-button w-100">
        <i class="fa-regular fa-file-alt"></i>
        <span class="summary-button-text">{{
          'customModules.orders.details.viewPortCallFile' | translate
        }}</span>
      </a>
    </li>
  </ul>
</div>
