<span
  class="badge badge-pill"
  (click)="clickBadge($event, badge)"
  [ngClass]="{
    'bg-light': badge.disabled,
    'bg-success': !badge.disabled,
    'text-muted': badge.disabled,
    'text-white': !badge.disabled,
    clickable: badge.clickable
  }"
>
  <span class="position-relative">
    <span *ngIf="badge.badgeText && badge.badgeText !== ''" class="mr-1">{{
      badge?.badgeText
    }}</span>
    <i
      *ngIf="badge.icon"
      class="fa-regular fa-{{ badge.icon }} fa-fw"
      title="{{ badge.description ?? '' | translate }}"
    >
    </i>
  </span>
</span>
