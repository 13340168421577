import { InjectionToken } from '@angular/core';

export interface SeahorseMenuItem {
  label: string;
  icon?: string;
  routerLink?: string;
  items?: SeahorseMenuItem[];
  placement?: 'top' | 'bottom';
  permissions?: string[];
}

export const SIDEBAR_MENU = new InjectionToken<SeahorseMenuItem[]>(
  'Sidebar configuration'
);

export const SIDEBAR_LOCAL_STORAGE = {
  isOpen: 'sidebar:isOpen',
};
