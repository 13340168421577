<div class="card">
  <div class="card-header">
    {{ flow.name }}
    <small class="text-muted">{{ 'shared.terms.details' | translate }}</small>
    <div class="btn-list">
      <button
        [disabled]="loadingFlow"
        (click)="editFlow()"
        type="button"
        class="btn btn-outline-secondary btn-sm"
      >
        <i class="fa-regular fa-pencil-alt fa-fw"></i>
        {{ 'shared.terms.edit' | translate }}
      </button>
    </div>
  </div>
  <div class="card-body">
    <ng-container
      *ngIf="!loadingFlow; then showFlow; else isLoadingFlow"
    ></ng-container>

    <ng-template #showFlow>
      <div class="card-body-group card-body-group-no-border">
        <div class="row no-gutters attribute-group">
          <div class="col-12 col-xl-8 attribute-group-item">
            <dt class="mb-0">{{ 'shared.terms.description' | translate }}</dt>
            <dd>{{ flow.description }}</dd>
          </div>
          <div class="col-12 col-xl-4 attribute-group-item">
            <dt class="mb-0">
              {{ 'invoicingFlows.flow.copyEmailAddress' | translate }}
            </dt>
            <dd>{{ flow.copyEmailAddress }}</dd>
          </div>
          <ng-template *ngIf="flow.invoiceConfiguration">
            <div class="col-12 col-xl-8 attribute-group-item">
              <dt class="mb-0">
                {{
                  'invoicingFlows.invoiceConfiguration.invoiceDescriptionTemplate'
                    | translate
                }}
              </dt>
              <dd>
                {{ flow.invoiceConfiguration.invoiceDescriptionTemplate }}
              </dd>
            </div>
            <div class="col-12 col-xl-4 attribute-group-item">
              <dt class="mb-0">
                {{
                  'invoicingFlows.invoiceConfiguration.sortInvoiceLinesBy'
                    | translate
                }}
              </dt>
              <dd>{{ flow.invoiceConfiguration.sortInvoiceLinesBy }}</dd>
            </div>
          </ng-template>
          <div class="col-12 col-xl-4 attribute-group-item">
            <dt class="mb-0">
              {{ 'invoicingFlows.flow.enabled' | translate }}
            </dt>
            <dd>
              <i
                [ngClass]="
                  flow.enabled
                    ? 'fa-solid fa-check fa-fw text-success'
                    : 'fa-solid fa-times fa-fw text-danger'
                "
              ></i>
            </dd>
          </div>
          <div class="col-12 col-xl-4 attribute-group-item">
            <dt class="mb-0">
              {{ 'invoicingFlows.flow.trackPayments' | translate }}
            </dt>
            <dd>
              <i
                [ngClass]="
                  flow.trackPayments
                    ? 'fa-solid fa-check fa-fw text-success'
                    : 'fa-solid fa-times fa-fw text-danger'
                "
              ></i>
            </dd>
          </div>
          <div class="col-12 col-xl-4 attribute-group-item">
            <dt class="mb-0">
              {{ 'invoicingFlows.flow.onlineAvailable' | translate }}
            </dt>
            <dd>
              <i
                [ngClass]="
                  flow.onlineAvailable
                    ? 'fa-solid fa-check fa-fw text-success'
                    : 'fa-solid fa-times fa-fw text-danger'
                "
              ></i>
            </dd>
          </div>
          <div class="col-12 col-xl-4 attribute-group-item">
            <dt class="mb-0">
              {{ 'invoicingFlows.flow.startsOn' | translate }}
            </dt>
            <dd>{{ flow.startsOn }}</dd>
          </div>
          <div class="col-12 col-xl-4 attribute-group-item">
            <dt class="mb-0">{{ 'invoicingFlows.flow.endsOn' | translate }}</dt>
            <dd *ngIf="flow.endsOn">{{ flow.endsOn }}</dd>
          </div>
          <div class="col-12 col-xl-4 attribute-group-item">
            <dt class="mb-0">
              {{ 'invoicingFlows.flow.invoiceNumberTemplate' | translate }}
            </dt>
            <dd>{{ flow.invoiceNumberTemplate }}</dd>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template #isLoadingFlow>
      <sh-loading-indicator
        class="d-block text-center"
      ></sh-loading-indicator>
    </ng-template>
  </div>
</div>
