import { Component, Input } from '@angular/core';
import { FlowModel } from '@seahorse/domain';

@Component({
  selector: 'ca-flow-form',
  templateUrl: 'flow-form.component.html',
})
export class FlowFormComponent {
  @Input() flow: FlowModel;
  @Input() loadingFlow: boolean;

  constructor() {
    this.flow = null;
    this.loadingFlow = false;
  }
}
