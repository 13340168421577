/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import { Injectable } from '@angular/core';
import {
  AbstractControlOptions,
  FormBuilder,
  FormGroup,
  ɵElement,
} from '@angular/forms';
import { SeahorseFormGroup } from './form-group';

// prettier-ignore
@Injectable({
    providedIn: 'root'
})
export class SeahorseFormBuilder extends FormBuilder {
    override group<T extends {}>(controls: T, options?: AbstractControlOptions | null | undefined): SeahorseFormGroup<{ [K in keyof T]: ɵElement<T[K], null>; }>;
    override group(controls: { [key: string]: any; }, options: { [key: string]: any; }): FormGroup<any>;
    override group<T extends {}>(controls: T, options?: AbstractControlOptions | null | undefined): SeahorseFormGroup<any> | SeahorseFormGroup<{ [K in keyof T]: ɵElement<T[K], null>; }> {
        const group = super.group(controls, options);
        return new SeahorseFormGroup(group.controls, group.validator, group.asyncValidator);
    }
}
