import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FileModel, FileService } from '@seahorse/domain';
import { Subscription } from 'rxjs';
import { FileImportDialogComponent } from '@seahorse/temp';
import { NauticalShipConstants } from '../../../nautical-ship/models/nautical-ship.constnts';

@Component({
  selector: 'ca-nautical-ship-files-card',
  templateUrl: './nautical-ship-files-card.component.html',
  styleUrls: ['./nautical-ship-files-card.component.scss'],
})
export class NauticalShipFilesCardComponent implements OnInit, OnDestroy {
  @Input() shipId: number;
  private subscriptions$ = Array<Subscription>();
  canLoadMore = false;
  pageIndex = 0;
  pageSize = 5;
  files: FileModel[] = [];
  isLoading = false;

  constructor(
    private filesService: FileService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.pageIndex = 0;
    this.loadFiles();
  }

  ngOnDestroy() {
    this.subscriptions$.forEach((s) => s.unsubscribe());
  }

  loadFiles() {
    this.isLoading = true;
    this.subscriptions$.push(
      this.filesService
        .getFiles(
          this.shipId.toString(),
          NauticalShipConstants.OBJECTTYPEID,
          this.pageIndex,
          this.pageSize
        )
        .subscribe(
          (result) => {
            if (this.pageIndex > 0) {
              this.files = this.files.concat(result.result);
            } else {
              this.files = result.result;
            }
            this.canLoadMore = this.files && this.files.length < result.count;
            this.isLoading = false;
          },
          () => {
            this.isLoading = false;
          },
          () => {
            this.isLoading = false;
          }
        )
    );
  }

  loadMore() {
    this.pageIndex += 1;
    this.loadFiles();
  }

  add() {
    const modal: NgbModalRef = this.modalService.open(
      FileImportDialogComponent,
      { backdrop: 'static' }
    );
    modal.componentInstance.objectId = this.shipId.toString();
    modal.componentInstance.objectMappingKey =
      NauticalShipConstants.OBJECTTYPEID;

    this.subscriptions$.push(
      modal.componentInstance.confirm.subscribe({
        next: (o: { files: FileModel[] }) => this.loadFiles(),
      })
    );
  }
}
