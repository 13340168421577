<div class="modal-header">
  <h4 class="modal-title-bold">{{ modalHeader }}</h4>
  <button (click)="close()" type="button" class="btn btn-link text-dark">
    <i class="fa-solid fa-times fa-fw fa-sm"></i>
  </button>
</div>
<div class="modal-body">
  <p class="text-center">
    {{ description | translate }}
  </p>
  <div *ngIf="remarksRequired" class="form-group">
    <label>
      <b class="required">{{
        'shared.general.areYouSure.remarks' | translate
      }}</b>
    </label>
    <textarea
      [(ngModel)]="remarks"
      id="remarks"
      name="remarks"
      class="form-control"
      type="text"
      rows="3"
    ></textarea>
  </div>
</div>
<div class="modal-footer row no-gutters">
  <div class="col">
    <button (click)="no()" type="button" class="btn btn-block btn-link">
      {{ 'shared.terms.no' | translate }}
    </button>
  </div>
  <div class="col">
    <button
      (click)="yes()"
      [disabled]="remarksRequired && !remarks"
      type="button"
      class="btn btn-block btn-primary"
    >
      {{ 'shared.terms.yes' | translate }}
    </button>
  </div>
</div>
