import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreModule } from '../../../../core/core.module';
import { LayoutComponentsModule } from '../../../../layout/components/layout-components.module';
import { SharedModule } from '../../../../shared/shared.module';
import { TaskPipesModule } from '../../../../tasks/modules/task/pipes/task-pipes.module';

import { TaskDefinitionEditorComponent } from './task-definition-editor/task-definition-editor.component';
import { TaskDefinitionFormComponent } from './task-definition-form/task-definition-form.component';
import { TaskDefinitionInfoComponent } from './task-definition-info/task-definition-info.component';
import { TaskDefinitionListDetailsHeaderComponent } from './task-definition-list-details-header/task-definition-list-details-header.component';
import { TaskDefinitionListFormComponent } from './task-definition-list-form/task-definition-list-form.component';
import { TaskDefinitionListInfoComponent } from './task-definition-list-info/task-definition-list-info.component';
import { TaskDefinitionListListComponent } from './task-definition-list-list/task-definition-list-list.component';
import { TaskDefinitionListModalComponent } from './task-definition-list-modal/task-definition-list-modal.component';
import { TaskDefinitionListOverviewHeaderComponent } from './task-definition-list-overview-header/task-definition-list-overview-header.component';
import { TaskDefinitionListSummaryComponent } from './task-definition-list-summary/task-definition-list-summary.component';
import { TaskDefinitionListTilesComponent } from './task-definition-list-tiles/task-definition-list-tiles.component';
import { TaskDefinitionModalComponent } from './task-definition-modal/task-definition-modal.component';

@NgModule({
  declarations: [
    TaskDefinitionListOverviewHeaderComponent,
    TaskDefinitionListDetailsHeaderComponent,
    TaskDefinitionListListComponent,
    TaskDefinitionListTilesComponent,
    TaskDefinitionListSummaryComponent,
    TaskDefinitionListInfoComponent,
    TaskDefinitionListFormComponent,
    TaskDefinitionListModalComponent,
    TaskDefinitionInfoComponent,
    TaskDefinitionEditorComponent,
    TaskDefinitionFormComponent,
    TaskDefinitionModalComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgbModule,
    SharedModule,
    LayoutComponentsModule,
    TaskPipesModule,
    CoreModule,
  ],
  exports: [
    TaskDefinitionListOverviewHeaderComponent,
    TaskDefinitionListDetailsHeaderComponent,
    TaskDefinitionListListComponent,
    TaskDefinitionListTilesComponent,
    TaskDefinitionListSummaryComponent,
    TaskDefinitionListInfoComponent,
    TaskDefinitionListFormComponent,
    TaskDefinitionInfoComponent,
    TaskDefinitionEditorComponent,
    TaskDefinitionFormComponent,
  ],
})
export class TaskDefinitionComponentsModule {}
