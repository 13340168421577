import { NauticalShipModel } from '../../nautical-ships/models/nautical-ship.model';
import { NauticalMovementModel } from './nautical-movement.model';

export class NauticalVisitBaseModel {
  id: number;
  referenceNumber: string;
  shipId: number;
  portId: number;
  portAuthorityId?: number;
  portAgentId?: number;
  portAgentName?: string;
  portName?: string;
  eta?: Date;
  eta_SourceId?: number;
  etd?: Date;
  etd_SourceId?: number;
  ata?: Date;
  ata_SourceId?: number;
  atd?: Date;
  atd_SourceId?: number;
  status: number;
  currentMovement?: NauticalMovementModel;

  ship: NauticalShipModel = null;
}
