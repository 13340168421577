import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomDatadefinitionModel } from '../../../core/models/custom-service-data.model';
import { RegisterAccountModel } from '../../../core/models/register-account.model';

import { DataContextService, ResultStatus } from '@seahorse/common';
import {
  LoginModel,
  ResetPasswordModel,
  UpdatePasswordModel,
} from '../../models/login.model';
import { IdentityService } from '../../services/identity.service';
import {
  ChallengeResponse,
  MfaChallengeModel,
  PasswordChallengeModel,
} from '../../models/challenge.model';
import { CreateEnvironmentModel } from '../../models/create-environment.model';
import { OrganisationModel } from '../../../content-distribution/models/organisation.model';
import { DataContextV2Service } from '../../../shared/services/data-context-v2.service';
import { AccessToken, LoginResponse } from '@seahorse/auth';

@Injectable({
  providedIn: 'root',
})
export class UserAccountService {
  private accountActivatedSource = new Subject<boolean>();
  accountActivatedCalled$ = this.accountActivatedSource.asObservable();

  private customDataObjectSource =
    new BehaviorSubject<CustomDatadefinitionModel>(null);
  customDataObjectCreated$ = this.customDataObjectSource.asObservable();

  private _dataContextV2Service = inject(DataContextV2Service);

  constructor(
    private dataContext: DataContextService,
    private identityService: IdentityService,
    private router: Router,
    private authService: MsalService
  ) {}

  loginUser(model: LoginModel) {
    return this.dataContext.post<AccessToken>('account/login', model).pipe(
      map((response) => {
        const loginResponse: LoginResponse = {};

        if (response.status !== ResultStatus.OK) {
          return loginResponse;
        }

        const token = response.result;
        this.identityService.setIdentity(token.access_token);

        const isMfaEnabled = response.result?.has_mfa;
        const isMfaRequired = response.result?.required_mfa;

        this.identityService.isMfaEnabled = isMfaEnabled;
        loginResponse.isMfaEnabled = isMfaEnabled;
        loginResponse.isMfaRequired = isMfaRequired;
        loginResponse.isSuccess = true;

        return loginResponse;
      })
    );
  }

  verifyMfa(code: string) {
    return this.dataContext.get<boolean>(`account/mfa/verify/${code}`).pipe(
      map((response) => {
        const loginResponse: LoginResponse = {};
        loginResponse.isSuccess = response.result;
        return loginResponse;
      })
    );
  }

  passwordChallenge(challenge: PasswordChallengeModel) {
    return this.dataContext.post<ChallengeResponse>(
      'account/challenge/password',
      challenge
    );
  }

  mfaChallenge(challenge: MfaChallengeModel) {
    return this.dataContext.post<ChallengeResponse>(
      'account/challenge/mfa',
      challenge
    );
  }

  loginWithOffice365() {
    this.authService.loginPopup().then((loginResponse) => {
      if (loginResponse.idToken) {
        const token = loginResponse.idToken.rawIdToken;
        this.identityService.setIdentity(token);

        this.router.navigate(['/']);
      }
    });
  }

  resetPassword(model: ResetPasswordModel) {
    return this.dataContext.post<any>('account/reset-password', model).pipe(
      map((response) => {
        if (response.status !== ResultStatus.OK) {
          return false;
        }

        return true;
      })
    );
  }

  updatePassword(model: UpdatePasswordModel): Observable<boolean> {
    return this.dataContext
      .put<boolean>('account/update-password', model)
      .pipe(map((response) => response.status === ResultStatus.OK));
  }

  registerAccount(model: RegisterAccountModel, languageCode?: string) {
    let url = 'account/register';
    if (languageCode) {
      url = url + `?languageCode=${languageCode}`;
    }

    return this.dataContext.post<any>(url, model);
  }

  registerWithAzure(
    model: RegisterAccountModel,
    externalUserId = '',
    issuer = ''
  ) {
    return this.dataContext
      .post<any>(
        `account/register/aad?externalUserId=${externalUserId}&issuer=${issuer}`,
        model
      )
      .pipe(
        map((response) => {
          if (response.status === ResultStatus.OK) {
            const token = response.result;
            this.identityService.setIdentity(token.access_token);
          }
          return response;
        })
      );
  }

  activateAccount(token: string) {
    return this.dataContext.get<any>(`account/activate?token=${token}`).pipe(
      map((response) => {
        if (!response.hasResult || response.status !== ResultStatus.OK) {
          return false;
        }

        return true;
      })
    );
  }

  inviteUsers(emails: string[]) {
    return this.dataContext.post<any>(`account/inviteusers`, emails).pipe(
      map((response) => {
        if (!response.hasResult || response.status !== ResultStatus.OK) {
          return false;
        }

        return true;
      })
    );
  }

  resendActivationToken(token: string) {
    const url = `account/resendtoken?token=${token}`;

    return this.dataContext.get<any>(url);
  }

  activateAccountMessage() {
    this.accountActivatedSource.next(true);
  }

  sendCustomDataObject(model: CustomDatadefinitionModel) {
    this.customDataObjectSource.next(model);
  }

  getDemoOrganisations() {
    return this.dataContext.get<OrganisationModel[]>('organisations/seeds');
  }

  createEnvironment(model: CreateEnvironmentModel) {
    return this._dataContextV2Service.post<string>(
      'Registration/create-environment',
      model
    );
  }
}
