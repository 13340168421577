import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CompanyFinancialModel } from '@seahorse/domain';

@Component({
  selector: 'ca-company-financials-list',
  templateUrl: 'company-financials-list.component.html',
})
export class CompanyFinancialsListComponent {
  @Input() financials: CompanyFinancialModel[];
  @Input() showHeader: boolean;

  @Output() onEvent: EventEmitter<CompanyFinancialModel>;

  constructor() {
    this.financials = [];
    this.showHeader = false;
    this.onEvent = new EventEmitter<CompanyFinancialModel>();
  }
}
