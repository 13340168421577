import { Component, Input, Output, EventEmitter } from '@angular/core';
import * as _ from 'underscore';
import { AttributeModel } from '../../../../shared/models/attribute.model';
import { ZeehavenIJmuidenVisitMovementType } from '../../models/zeehaven-ijmuiden-movement.model';

@Component({ templateUrl: 'attribute-picker-modal.component.html' })
export class AttributePickerModalComponent {
  @Input() set attribute(a: AttributeModel) {
    if (a) {
      this._attribute = a;

      switch (a.type) {
        case 'movementType': {
          this.zeehavenIJmuidenVisitMovementTypes = _.filter(
            _.toArray(ZeehavenIJmuidenVisitMovementType),
            (r) => _.isNumber(r)
          );
          break;
        }
      }
    }
  }
  get attribute() {
    return this._attribute;
  }

  @Output() onPickAttribute: EventEmitter<AttributeModel>;

  private _attribute: AttributeModel;

  zeehavenIJmuidenVisitMovementTypes: (
    | string
    | ZeehavenIJmuidenVisitMovementType
  )[];

  constructor() {
    this.attribute = null;
    this.onPickAttribute = new EventEmitter<AttributeModel>();
    this._attribute = null;
    this.zeehavenIJmuidenVisitMovementTypes = [];
  }

  useSuggestion() {
    this.attribute.value = this.attribute.suggestion;
  }
}
