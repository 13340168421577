import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from '../../core/core.module';
import { SharedModule } from '../../shared/shared.module';

import { NauticalGeographyServicesModule } from '../services/nautical-geography-services.module';
import { NauticalGeographyPickerDialogComponent } from './nautical-geography-picker-dialog/nautical-geography-picker-dialog.component';
import { NauticalPortPickerDialogComponent } from './nautical-port-picker-dialog/nautical-port-picker-dialog.component';
import { NauticalPortPickerComponent } from './nautical-port-picker/nautical-port-picker.component';
import { NauticalWaypointPickerDialogComponent } from './nautical-waypoint-picker-dialog/nautical-waypoint-picker-dialog.component';
import { NauticalWaypointPickerComponent } from './nautical-waypoint-picker/nautical-waypoint-picker.component';

@NgModule({
  declarations: [
    NauticalGeographyPickerDialogComponent,
    NauticalPortPickerComponent,
    NauticalPortPickerDialogComponent,
    NauticalWaypointPickerComponent,
    NauticalWaypointPickerDialogComponent,
  ],
  exports: [
    NauticalGeographyPickerDialogComponent,
    NauticalPortPickerComponent,
    NauticalPortPickerDialogComponent,
    NauticalWaypointPickerComponent,
    NauticalWaypointPickerDialogComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    NgSelectModule,
    TranslateModule,
    NgbModule,
    NauticalGeographyServicesModule,
    CoreModule,
  ],
})
export class NauticalGeographyComponentsModule {}
