import { Injectable } from '@angular/core';
import { ResultWrapper } from '@seahorse/common';
import { DataContextService } from '@seahorse/common';
import { Observable } from 'rxjs';
import { FlowStageInvoicingModel } from '../models/FlowStageInvoicingModel';
import { DataService, StageModel } from '@seahorse/domain';

@Injectable()
export class StageDataService implements DataService<StageModel> {
  constructor(private dataContext: DataContextService) {}

  // Get
  getById(id: StageModel['id']): Observable<ResultWrapper<StageModel>> {
    return this.dataContext.get<StageModel>(`invoice/flow/stage/${id}`);
  }

  // Post
  add(stage: StageModel): Observable<ResultWrapper<StageModel>> {
    return this.dataContext.post<StageModel>(`invoice/flow/stage`, stage);
  }

  // Post
  addForInvoicing(
    email: string
  ): Observable<ResultWrapper<FlowStageInvoicingModel>> {
    return this.dataContext.post<FlowStageInvoicingModel>(
      `invoice/flow/stage/invoicing`,
      {
        email,
      }
    );
  }

  // Put
  update(stage: StageModel): Observable<ResultWrapper<StageModel>> {
    return this.dataContext.put<StageModel>(
      `invoice/flow/stage/${stage.id}`,
      stage
    );
  }

  // Delete
  delete(id: StageModel['id']): Observable<ResultWrapper<StageModel>> {
    return this.dataContext.delete<StageModel>(`invoice/flow/stage/${id}`);
  }
}
