import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DataContextService, ResultWrapper } from '@seahorse/common';
import * as _ from 'underscore';
import { CoordinatePointModel } from '../../nautical-geography/models/coordinate.model';
import { AISMarkerModel } from '../models/ais-marker.model';
import { ClusterModel } from '../models/cluster.model';
import { RouteCalculatorRequestModel, RouteCalculatorResponseModel } from '../models/route-calculator.model';

@Injectable({
  providedIn: 'root',
})
export class MapDataService {
  constructor(private dataContext: DataContextService) {}

  calculateRoute(
    request: RouteCalculatorRequestModel
  ): Observable<RouteCalculatorResponseModel[]> {
    return this.dataContext
      .post<RouteCalculatorResponseModel[]>(
        'nautical/distance/calculate',
        request
      )
      .pipe(map((response) => response.result));
  }

  getMarkerForMMSI(mmsi: string): Observable<AISMarkerModel> {
    return this.dataContext
      .get<AISMarkerModel>('ais/mmsi/' + mmsi)
      .pipe(map((response) => response.result));
  }

  getMarkerForMMSIs(mmsis: string[]): Observable<AISMarkerModel[]> {
    return this.dataContext
      .post<AISMarkerModel[]>('ais/mmsis/', mmsis)
      .pipe(map((response) => response.result));
  }

  getMarkers(listName: string): Observable<AISMarkerModel[]> {
    return this.dataContext
      .get<AISMarkerModel[]>('ais/list/' + listName)
      .pipe(map((response) => response.result));
  }

  getPositionsForArea(
    top: number,
    right: number,
    bottom: number,
    left: number
  ): Observable<AISMarkerModel[]> {
    return this.dataContext
      .get<AISMarkerModel[]>(
        'ais/position?top=' +
          top +
          '&right=' +
          right +
          '&bottom=' +
          bottom +
          '&left=' +
          left
      )
      .pipe(map((response) => response.result));
  }

  getClustersForArea(
    top: number,
    right: number,
    bottom: number,
    left: number,
    zoom: number
  ): Observable<ResultWrapper<ClusterModel[]>> {
    return this.dataContext.get<ClusterModel[]>(
      `ais/cluster?top=${top}&right=${right}&bottom=${bottom}&left=${left}&zoom=${zoom}`
    );
  }

  getTrackForMMSI(
    mmsi: string,
    dateFrom?: string,
    dateUntil?: string
  ): Observable<AISMarkerModel[]> {
    let url = 'ais/mmsi/' + mmsi + '/track';
    const urlArgs = [];
    if (dateFrom) {
      urlArgs.push('datefrom=' + dateFrom);
    }
    if (dateUntil) {
      urlArgs.push('dateuntil=' + dateUntil);
    }
    if (urlArgs.length > 0) {
      url += '?' + urlArgs.join('&');
    }

    return this.dataContext
      .get<AISMarkerModel[]>(url)
      .pipe(map((response) => response.result));
  }

  calculatePolygonCenter(points: CoordinatePointModel[]): number[] {
    const latitudes = _.map(points, (c: CoordinatePointModel) => c.latitude);
    const sumLatitudes = latitudes.reduce((a, b) => a + b, 0);
    const avgLatitudes = sumLatitudes / latitudes.length || 0;

    const longitudes = _.map(points, (c: CoordinatePointModel) => c.longitude);
    const sumLongitudes = longitudes.reduce((a, b) => a + b, 0);
    const avgLongitudes = sumLongitudes / longitudes.length || 0;

    return [avgLongitudes, avgLatitudes];
  }
}
