export class LogicModel {
  calculations: any[];
  conditions: any[];
  dependencies: any[];

  constructor() {
    this.calculations = [];
    this.conditions = [];
    this.dependencies = [];
  }
}
