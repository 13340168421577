import { NgModule } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@seahorse/common';
import { InteractionService } from '../../shared/services/interaction.service';

import { PreferenceDataService } from './preference-data.service';
import { ServiceMessageDataService } from './service-message-data.service';
import { SystemMessageReleaseService } from './system-message-release.service';
import { SystemMessageService } from './system-message.service';

@NgModule({
  providers: [
    PreferenceDataService,
    ServiceMessageDataService,
    SystemMessageService,
    SystemMessageReleaseService,
    {
      provide: 'SystemMessageInteractionService',
      useFactory: (
        dataService: SystemMessageService,
        modalService: NgbModal,
        notificationService: NotificationService,
        translateService: TranslateService
      ) =>
        new InteractionService(
          dataService,
          modalService,
          notificationService,
          translateService
        ),
      deps: [
        SystemMessageService,
        NgbModal,
        NotificationService,
        TranslateService,
      ],
    },
  ],
})
export class PreferenceServicesModule {}
