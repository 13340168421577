import { NgModule } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@seahorse/common';
import { InteractionService } from '../../shared/services/interaction.service';
import { CountryDataService } from '../../superuser-nautical/services/country-data.service';
import { TaskDataService } from '../../tasks/modules/task/services/task-data.service';

import { AddressTypePipe } from '../pipes/address-type.pipe';
import { ContactStatusPipe } from '../pipes/contact-status.pipe';
import { AddressDataService } from './address-data.service';
import { CompanyExternalKeyDataService } from './company-external-key-data.service';
import { CompanyInvoicesDataService } from './company-invoices-data.service';
import { ContactDataService } from './contact-data.service';
import { DepartmentDataService } from './department-data.service';
import { FinancialDataService } from '@seahorse/domain';
import { PreferencesService } from '../../customer-portal/services/preferences.service';
import { CompanyReferenceDataService } from './company-reference-data.service';

@NgModule({
  providers: [
    {
      provide: 'TaskInteractionService',
      useFactory: (
        dataService: TaskDataService,
        modalService: NgbModal,
        notificationService: NotificationService,
        translateService: TranslateService
      ) =>
        new InteractionService(
          dataService,
          modalService,
          notificationService,
          translateService
        ),
      deps: [TaskDataService, NgbModal, NotificationService, TranslateService],
    },
    {
      provide: 'AddressInteractionService',
      useFactory: (
        dataService: AddressDataService,
        modalService: NgbModal,
        notificationService: NotificationService,
        translateService: TranslateService
      ) =>
        new InteractionService(
          dataService,
          modalService,
          notificationService,
          translateService
        ),
      deps: [
        AddressDataService,
        NgbModal,
        NotificationService,
        TranslateService,
      ],
    },
    {
      provide: 'ContactInteractionService',
      useFactory: (
        dataService: ContactDataService,
        modalService: NgbModal,
        notificationService: NotificationService,
        translateService: TranslateService
      ) =>
        new InteractionService(
          dataService,
          modalService,
          notificationService,
          translateService
        ),
      deps: [
        ContactDataService,
        NgbModal,
        NotificationService,
        TranslateService,
      ],
    },
    {
      provide: 'DepartmentInteractionService',
      useFactory: (
        dataService: DepartmentDataService,
        modalService: NgbModal,
        notificationService: NotificationService,
        translateService: TranslateService
      ) =>
        new InteractionService(
          dataService,
          modalService,
          notificationService,
          translateService
        ),
      deps: [
        DepartmentDataService,
        NgbModal,
        NotificationService,
        TranslateService,
      ],
    },
    {
      provide: 'FinancialInteractionService',
      useFactory: (
        dataService: FinancialDataService,
        modalService: NgbModal,
        notificationService: NotificationService,
        translateService: TranslateService
      ) =>
        new InteractionService(
          dataService,
          modalService,
          notificationService,
          translateService
        ),
      deps: [
        FinancialDataService,
        NgbModal,
        NotificationService,
        TranslateService,
      ],
    },
    ContactDataService,
    DepartmentDataService,
    AddressDataService,
    CompanyExternalKeyDataService,
    CompanyInvoicesDataService,
    AddressTypePipe,
    ContactStatusPipe,
    TaskDataService,
    CountryDataService,
    PreferencesService,
    CompanyReferenceDataService,
  ],
})
export class CompanyServicesModule {}
