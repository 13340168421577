import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import {
  FleetGroup,
  ShipFleetGroupModel,
} from '../../../map/models/fleet-group.model';
import { GroupService } from '../../../map/services/group.service';
import { MapInteractionService } from '../../../map/services/map-interaction.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ca-my-fleet-form',
  templateUrl: './my-fleet-form.component.html',
  styleUrls: ['./my-fleet-form.component.scss'],
})
export class MyFleetFormComponent implements OnInit, OnDestroy {
  @Input() submitted: boolean;
  @Input() groups: FleetGroup[];
  @Input() shipsWithoutGroup: ShipFleetGroupModel[];
  @Input() shipId: number;
  private subscriptions$ = Array<Subscription>();
  isCheckedNoGroup: boolean;

  constructor(
    private groupService: GroupService,
    private mapInteractionService: MapInteractionService
  ) {}

  ngOnInit(): void {
    this.isCheckedNoGroup = this.shipsWithoutGroup
      ? this.shipsWithoutGroup.some((x) => x.shipId === this.shipId)
      : false;
  }

  ngOnDestroy() {
    this.subscriptions$.forEach((s) => s.unsubscribe());
  }

  changeMyFleetGroup(group: FleetGroup) {
    if (group.isChecked) {
      this.subscriptions$.push(
        this.groupService.addShipToGroup(group.id, this.shipId).subscribe(
          (response) => {
            if (response.hasResult) {
              this.mapInteractionService.addShipToFleet(response.result);
              if (this.groups === null) {
                this.groups = [];
              }

              const foundGroup = this.groups.find(
                (x) => x.id === response.result.groupId
              );
              if (foundGroup) {
                if (!foundGroup.shipFleetGroups) {
                  foundGroup.shipFleetGroups = [];
                }
                foundGroup.shipFleetGroups.push(response.result);
              }
            }
          },
          () => {},
          () => {}
        )
      );
    } else {
      this.subscriptions$.push(
        this.groupService
          .removeShipFromGroup(
            group.shipFleetGroups.find((x) => x.shipId === this.shipId).id
          )
          .subscribe(
            (response) => {
              this.mapInteractionService.removeShipFromFleet(
                group.shipFleetGroups.find((x) => x.shipId === this.shipId)
              );

              this.groups
                .find((x) => x.id === group.id)
                .shipFleetGroups.splice(
                  this.groups
                    .find((x) => x.id === group.id)
                    .shipFleetGroups.findIndex(
                      (x) => x.id === response.result.id
                    ),
                  1
                );
            },
            () => {},
            () => {}
          )
      );
    }
  }

  changeMyFleet() {
    if (this.isCheckedNoGroup) {
      this.subscriptions$.push(
        this.groupService.addShipToGroup(null, this.shipId).subscribe(
          (response) => {
            if (response.hasResult) {
              this.mapInteractionService.addShipToNoGroup(response.result);

              this.shipsWithoutGroup.push(response.result);
            }
          },
          () => {},
          () => {}
        )
      );
    } else {
      this.subscriptions$.push(
        this.groupService
          .removeShipFromGroup(
            this.shipsWithoutGroup.find((x) => x.shipId === this.shipId).id
          )
          .subscribe(
            (response) => {
              this.mapInteractionService.removeShipFromNoGroup(
                this.shipsWithoutGroup.find((x) => x.shipId === this.shipId)
              );

              this.shipsWithoutGroup.splice(
                this.shipsWithoutGroup.findIndex(
                  (x) => x.id === response.result.id
                ),
                1
              );
            },
            () => {},
            () => {}
          )
      );
    }
  }
}
