import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DateTimePickerComponent } from '@seahorse/temp';
import { LayoutComponentsModule } from '../../../layout/components/layout-components.module';
import { SharedModule } from '../../../shared/shared.module';

import { ZeehavenIJmuidenPipesModule } from '../pipes/zeehaven-ijmuiden-pipes.module';
import { AnchoredPickerModalComponent } from './anchored-picker-modal/anchored-picker-modal.component';
import { AttributePickerModalComponent } from './attribute-picker-modal/attribute-picker-modal.component';
import { DosFormPickerModalComponent } from './dos-form-picker-modal/dos-form-picker-modal.component';
import { SuggestionPickerModalComponent } from './suggestion-picker-modal/suggestion-picker-modal.component';
import { VisitReasonsPickerModalComponent } from './visit-reasons-picker-modal/visit-reasons-picker-modal.component';
import { ZeehavenIJmuidenActionPickerModalComponent } from './zeehaven-ijmuiden-action-picker-modal/zeehaven-ijmuiden-action-picker-modal.component';
import { ZeehavenIJmuidenDetailsHeaderComponent } from './zeehaven-ijmuiden-details-header/zeehaven-ijmuiden-details-header.component';
import { ZeehavenIJmuidenOverviewHeaderComponent } from './zeehaven-ijmuiden-overview-header/zeehaven-ijmuiden-overview-header.component';
import { ZeehavenIJmuidenSpecificPickerModalComponent } from './zeehaven-ijmuiden-specific-picker-modal/zeehaven-ijmuiden-specific-picker-modal.component';
import { ZeehavenIJmuidenVisitListPickerModalComponent } from './zeehaven-ijmuiden-visit-list-picker-modal/zeehaven-ijmuiden-visit-list-picker-modal.component';
import { ZeehavenIJmuidenVisitListComponent } from './zeehaven-ijmuiden-visit-list/zeehaven-ijmuiden-visit-list.component';

@NgModule({
  declarations: [
    AttributePickerModalComponent,
    DosFormPickerModalComponent,
    AnchoredPickerModalComponent,
    ZeehavenIJmuidenOverviewHeaderComponent,
    ZeehavenIJmuidenSpecificPickerModalComponent,
    VisitReasonsPickerModalComponent,
    ZeehavenIJmuidenActionPickerModalComponent,
    ZeehavenIJmuidenDetailsHeaderComponent,
    ZeehavenIJmuidenVisitListComponent,
    ZeehavenIJmuidenVisitListPickerModalComponent,
    SuggestionPickerModalComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgbModule,
    SharedModule,
    LayoutComponentsModule,
    ZeehavenIJmuidenPipesModule,
    DateTimePickerComponent
  ],
  exports: [
    ZeehavenIJmuidenOverviewHeaderComponent,
    ZeehavenIJmuidenDetailsHeaderComponent,
    ZeehavenIJmuidenVisitListComponent,
  ],
})
export class ZeehavenIJmuidenComponentsModule {}
