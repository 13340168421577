import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OrderRegistrationStatusLog } from '../models/order-registration-status-log.model';

@Pipe({ name: 'orderRegistrationStatusLog' })
export class OrderRegistrationStatusLogPipe implements PipeTransform {
  orderRegistrationStatusLogs: Object;

  constructor(private translate: TranslateService) {
    this.orderRegistrationStatusLogs = {
      [OrderRegistrationStatusLog.OrderRegistrationDeleted]:
        'customModules.orderRegistrations.statusLog.deleted',
      [OrderRegistrationStatusLog.OrderRegistrationStatusCreated]:
        'customModules.orderRegistrations.statusLog.statusCreated',
      [OrderRegistrationStatusLog.OrderRegistrationStatusUpdated]:
        'customModules.orderRegistrations.statusLog.statusUpdated',
    };
  }

  transform = (
    orderRegistrationStatusLog: OrderRegistrationStatusLog
  ): string =>
    this.translate.instant(
      this.orderRegistrationStatusLogs[orderRegistrationStatusLog]
    );
}
