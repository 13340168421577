import { Injectable, inject } from '@angular/core';
import { DataContextService } from '@seahorse/common';
import { CaEnvService } from './env.service';

@Injectable({
  providedIn: 'root',
})
export class DataContextV2Service extends DataContextService {
  override environment = inject(CaEnvService);

  protected override processUrl(url: string): string {
    if (!url || url === '') {
      return url;
    }

    if (
      url.toLowerCase().indexOf('http://') !== 0 &&
      url.toLowerCase().indexOf('https://') !== 0
    ) {
      url = this.environment.apiHostV2 + '/' + url;
    }

    return url;
  }
}
