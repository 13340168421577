<section class="page-header d-flex flex-fill px-1">
  <div class="flex-grow-1 d-flex justify-content-start align-items-center">
    <button
      (click)="onEvent.emit({ action: 'overview' })"
      *ngIf="!orderObjectDefinition"
      type="button"
      class="btn btn-link mx-1"
    >
      <i class="fa-solid fa-long-arrow-alt-left fa-fw mr-1"></i>
      <span class="d-none d-sm-inline-flex">{{
        'customModules.orderRegistrations.overview.title' | translate
      }}</span>
    </button>
    <button
      (click)="onEvent.emit({ action: 'reset' })"
      *ngIf="orderObjectDefinition"
      type="button"
      class="btn btn-link mx-1"
    >
      <i class="fa-solid fa-long-arrow-alt-left fa-fw mr-1"></i>
      <span class="d-none d-sm-inline-flex">{{
        'shared.terms.back' | translate
      }}</span>
    </button>
  </div>
  <div class="d-flex justify-content-end align-items-center">
    <div class="input-group mx-1">
      <button
        [disabled]="loading"
        (click)="onEvent.emit({ action: 'addAndView' })"
        type="button"
        class="btn btn-success"
      >
        <i class="fa-regular fa-save fa-fw"></i>
        <span class="d-none d-sm-inline-flex">{{
          'customModules.orderRegistrations.factory.createAndView' | translate
        }}</span>
      </button>
      <div class="input-group-append">
        <button
          type="button"
          class="btn btn-success dropdown-toggle dropdown-toggle-split"
          data-toggle="dropdown"
        ></button>
        <div class="dropdown-menu">
          <button
            [disabled]="loading"
            (click)="onEvent.emit({ action: 'add' })"
            type="button"
            class="btn dropdown-item"
          >
            <i class="fa-regular fa-redo-alt fa-fw"></i>
            {{
              'customModules.orderRegistrations.factory.createAndMakeNew'
                | translate
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
