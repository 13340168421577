import { inject } from '@angular/core';
import { FormModel, SeahorseFormBuilder } from '@seahorse/forms';
import { NauticalShipStatus } from '@seahorse/domain';

export type NauticalShipModelForm = FormModel<
  typeof CreateNauticalShipModelForm
>;

export function CreateNauticalShipModelForm() {
  const fb = inject(SeahorseFormBuilder);

  return fb.group({
    // name: fb.control<string>(null),
    status: fb.control<NauticalShipStatus>(null),
    // callSign: fb.control<string>(null),
    // flagCode: fb.control<string>(null),
    // mmsi: fb.control<string>(null),
    // imo: fb.control<string>(null),
    // eni: fb.control<string>(null),
    // fisheryNumber: fb.control<string>(null),
    // maxDraft: fb.control<string>(null),
    // loa: fb.control<string>(null),
    // width: fb.control<string>(null),
    // gt: fb.control<string>(null),
    // dwt: fb.control<string>(null),
    // shipType: fb.control<NauticalShipTypeModel>(null),
    // shipTypeId: fb.control<number>(null),
    // shipTypeCategoryId: fb.control<number>(null),
  });
}
