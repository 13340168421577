<div
  class="card tile cell-borders"
  *ngIf="!isClassicView || !isActive || (activities && activities.length > 0)"
  [ngClass]="{ 'text-muted': !isActive, 'classic-view': isClassicView }"
>
  <div
    class="d-flex" 
    [ngClass]="{
      'time-warning-left' : !isClassicView && hasInactiveTimeWarning,
      'time-warning-top'  : isClassicView && hasInactiveTimeWarning
    }"
  >
    <div class="flex-shrink-1 pt-3 pl-2" *ngIf="!isClassicView">
      <button
        class="btn btn-primary btn-sm border-square"
        style="font-size: 1.2em; width: 43px"
        disabled
        *ngIf="!isActive && movement && movement.movementType"
        [ngClass]="{
          'btn-primary': isActive && movement.movementType > 2,
          'btn-secondary': !isActive,
          'btn-success': isActive && movement.movementType === 1,
          'btn-warning': isActive && movement.movementType === 2
        }"
      >
        <span *ngIf="movement.movementType === 1" title="Inkomend">IN</span>
        <span *ngIf="movement.movementType === 2" title="Verhaling">VH</span>
        <span *ngIf="movement.movementType === 3" title="Uitgaand">UIT</span>
        <span *ngIf="movement.movementType === 4" title="Turn-around">RZ</span>
        <span *ngIf="movement.movementType === 5" title="Doorvaarder">DV</span>
        <span *ngIf="movement.movementType === 255" title="Onbekend">..</span>
      </button>
      <button
        class="btn btn-secondary btn-sm border-square"
        style="font-size: 1.2em; width: 43px"
        disabled
        *ngIf="!isActive && (!movement || !movement.movementType)"
      >
        <span>..</span>
      </button>
    </div>
    <div style="min-width: 250px" class="p-2 cell" *ngIf="!isClassicView">
      <ca-map-direction-indicator
        class="float-right"
        [position]="position"
      ></ca-map-direction-indicator>
      <strong>{{
        visit.ship
          ? formatShip(visit.ship)
          : ('nautical.ship.unknown' | translate)
      }}</strong>
      <div
        class="text-muted text-truncate"
        title="{{ visit.referenceNumber | uppercase }}"
      >
        {{ visit.referenceNumber | uppercase }}
      </div>
      <div
        class="text-muted text-truncate"
        title="{{
          (visit.portAgentName
            ? visit.portAgentName
            : visit.portAgent && visit.portAgent.name
            ? visit.portAgent.name
            : 'unknown agent'
          ) | uppercase
        }}"
      >
        {{
          (visit.portAgentName
            ? visit.portAgentName
            : visit.portAgent && visit.portAgent.name
            ? visit.portAgent.name
            : 'unknown agent'
          ) | uppercase
        }}
      </div>
    </div>
    <div
      class="cells"
      *ngIf="isClassicView"
      [ngClass]="{ inactive: !isActive }"
    >
      <div class="flex-shrink-1 pt-3 pl-2">
        <button
          class="btn btn-primary btn-sm border-square"
          style="font-size: 1.2em; width: 43px"
          disabled
          *ngIf="!isActive && movement && movement.movementType"
          [ngClass]="{
            'btn-primary': isActive && movement.movementType > 2,
            'btn-secondary': !isActive,
            'btn-success': isActive && movement.movementType === 1,
            'btn-warning': isActive && movement.movementType === 2
          }"
        >
          <span *ngIf="movement.movementType === 1" title="Inkomend">IN</span>
          <span *ngIf="movement.movementType === 2" title="Verhaling">VH</span>
          <span *ngIf="movement.movementType === 3" title="Uitgaand">UIT</span>
          <span *ngIf="movement.movementType === 4" title="Turn-around"
            >RZ</span
          >
          <span *ngIf="movement.movementType === 5" title="Doorvaarder"
            >DV</span
          >
          <span *ngIf="movement.movementType === 255" title="Onbekend">..</span>
        </button>
        <button
          class="btn btn-secondary btn-sm border-square"
          style="font-size: 1.2em; width: 43px"
          disabled
          *ngIf="!isActive && (!movement || !movement.movementType)"
        >
          <span>..</span>
        </button>
      </div>
      <div class="p-2 flex-grow-1 text-truncate">
        <!-- <ca-map-direction-indicator class="float-right btn" [position]="position"></ca-map-direction-indicator> -->
        <strong
          title="{{
            visit.ship
              ? formatShip(visit.ship)
              : ('nautical.ship.unknown' | translate)
          }}"
        >
          {{
            visit.ship
              ? formatShip(visit.ship)
              : ('nautical.ship.unknown' | translate)
          }}
        </strong>
        <div
          class="text-muted text-truncate"
          title="{{ visit.referenceNumber | uppercase }}"
        >
          {{ visit.referenceNumber | uppercase }}
        </div>
        <div
          class="text-muted text-truncate"
          title="{{
            (visit.portAgentName
              ? visit.portAgentName
              : visit.portAgent && visit.portAgent.name
              ? visit.portAgent.name
              : 'unknown agent'
            ) | uppercase
          }}"
        >
          {{
            (visit.portAgentName
              ? visit.portAgentName
              : visit.portAgent && visit.portAgent.name
              ? visit.portAgent.name
              : 'unknown agent'
            ) | uppercase
          }}
        </div>
      </div>
      <div class="flex-shrink-1 flex-column">
        <button
          *ngIf="isActive"
          class="d-flex btn btn-hover-primary"
          style="padding: 0.75em 0.95em"
          (click)="
            activityItemClicked.emit({ action: 'add', type: 'activity' })
          "
          title="activiteit toevoegen"
        >
          <i class="fa-regular fa-plus"></i>
        </button>
        <ca-map-direction-indicator
          class="btn"
          [position]="position"
        ></ca-map-direction-indicator>
      </div>
    </div>
    <!-- ship requirements -->
    <div style="min-width: 300px" class="cell">
      <button
        type="button"
        class="btn btn-hover-light btn-inline btn-block text-left"
        [disabled]="!isActive"
        (click)="
          visitItemClicked.emit({
            type: 'visit',
            action: 'openEditDialog',
            data: null
          })
        "
      >
        <div class="row">
          <div class="col" title="Normaal/Voor/Achter">
            <span class="text-muted d-inline-block" style="width: 45px"
              >Pers:</span
            >
            <span>{{
              requirements.persons ? requirements.persons : '0/0/0'
            }}</span>
          </div>
          <div class="col" title="Normaal/Voor/Achter">
            <span class="text-muted d-inline-block" style="width: 45px"
              >WW:</span
            >
            <span>{{
              requirements.winches ? requirements.winches : '0/0/0'
            }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col" title="Motorboten">
            <span class="text-muted d-inline-block" style="width: 45px"
              >MB:</span
            >
            <span>{{
              requirements.motorboat ? requirements.motorboat : 0
            }}</span>
          </div>
          <div class="col" title="Zwaar materieel">
            <span class="text-muted d-inline-block" style="width: 45px"
              >ZM:</span
            >
            <span>{{
              requirements.heavyEquipment === true ? 'Ja' : 'Nee'
            }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <span class="text-truncate text-muted">
              <span *ngIf="!requirements.remarks">Geen opmerkingen...</span>
              <span
                *ngIf="requirements.remarks"
                title="{{ requirements.remarks }}"
                >{{ requirements.remarks }}</span
              >
            </span>
          </div>
        </div>
      </button>
    </div>
    <!-- movement info -->
    <div class="w-100 p-2 cell" *ngIf="!currentView || currentView === 'text'">
      <div class="row">
        <div class="col text-truncate">
          <span class="text-muted d-inline-block" style="width: 45px">
            Van:
          </span>
          <span
            *ngIf="
              movement &&
              !movement.portWayPointFrom &&
              visit.previousPort &&
              visit.previousPort.id
            "
          >
            <strong *ngIf="visit.previousPort.unloCode">{{
              visit.previousPort.unloCode
            }}</strong>
            ({{ visit.previousPort.name }})
          </span>
          <span
            *ngIf="movement && movement.portWayPointFrom"
            [title]="
              movement.portWayPointFrom.code +
              ' ' +
              movement.portWayPointFrom.name
            "
          >
            <strong>{{ movement.portWayPointFrom.code }}</strong>
            ({{ movement.portWayPointFrom.name }})
          </span>
        </div>
        <div class="col text-truncate">
          <span class="text-muted d-inline-block" style="width: 45px">
            Naar:
          </span>
          <span
            *ngIf="
              movement &&
              !movement.portWayPointTo &&
              visit.nextPort &&
              visit.nextPort.id
            "
          >
            <strong *ngIf="visit.nextPort.unloCode">{{
              visit.nextPort.unloCode
            }}</strong>
            ({{ visit.nextPort.name }})
          </span>
          <span
            *ngIf="visit && movement && movement.portWayPointTo"
            [title]="
              movement.portWayPointTo.code + ' ' + movement.portWayPointTo.name
            "
          >
            <strong>{{ movement.portWayPointTo.code }}</strong>
            ({{ movement.portWayPointTo.name }})
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <span *ngIf="movement && movement.atd" style="width: 45px">
            <span class="text-muted d-inline-block" style="width: 45px"
              >ATD:</span
            >
            <span>{{ movement.atd | date: 'dd-MM-yyyy HH:mm' }}</span>
          </span>
          <span *ngIf="movement && movement.etd && !movement.atd">
            <span class="text-muted d-inline-block" style="width: 45px"
              >ETD:</span
            >
            <span>{{ movement.etd | date: 'dd-MM-yyyy HH:mm' }}</span>
          </span>
          <span
            *ngIf="!movement && activities && activities.length > 0"
            style="width: 45px"
          >
            <span class="text-muted d-inline-block" style="width: 45px"
              >Start:</span
            >
            <span>{{
              activities[activities.length - 1].startsOn
                | date: 'dd-MM-yyyy HH:mm'
            }}</span>
          </span>
        </div>
        <div class="col">
          <span *ngIf="movement && movement.ata" style="width: 45px">
            <span class="text-muted d-inline-block" style="width: 45px"
              >ATA:</span
            >
            <span>{{ movement.ata | date: 'dd-MM-yyyy HH:mm' }}</span>
          </span>
          <span *ngIf="movement && movement.eta && !movement.ata">
            <span class="text-muted d-inline-block" style="width: 45px"
              >ETA:</span
            >
            <span>{{ movement.eta | date: 'dd-MM-yyyy HH:mm' }}</span>
          </span>
          <span *ngIf="!movement && activities && activities.length > 0">
            <span class="text-muted d-inline-block" style="width: 45px"
              >Eind:</span
            >
            <span>{{ activities[0].endsOn | date: 'dd-MM-yyyy HH:mm' }}</span>
          </span>
        </div>
      </div>
    </div>
    <div class="w-100 p-2 cell" *ngIf="currentView && currentView === 'flow'">
      <ca-nautical-visit-flow
        [show-only-current]="true"
        visit-flow-orientation="horizontal"
        [nautical-visit-overview]="visit"
      ></ca-nautical-visit-flow>
    </div>
    <!-- action buttons -->
    <div class="flex-shrink-1 p2 cell text-right py-4 px-3" *ngIf="!isActive">
      <button
        type="button"
        class="btn btn-success mr-2"
        (click)="visitItemClicked.emit({ action: 'active', type: 'visit' })"
      >
        <i class="fa-regular fa-check"></i>
        activeren
      </button>
      <button
        type="button"
        class="btn btn-link text-danger mr-2"
        (click)="visitItemClicked.emit({ action: 'delete', type: 'visit' })"
      >
        verwijderen
      </button>
    </div>

    <div
      class="cell flex-shrink-1"
      *ngIf="isActive && !isClassicView && selectedVisitList === 'active'"
    >
      <ul class="tile-buttons actions">
        <!-- <li>
										  <button class="btn" (click)="itemClicked.emit('share')" title="informatie delen">
												  <i class="fa-regular fa-share-alt"></i>
										  </button>
								  </li> -->
        <!-- <li>
                      <button class="btn" (click)="itemClicked.emit('map')" title="toon schip op de kaart">
                          <i class="fa-regular fa-map"></i>
                      </button>
                  </li> -->
        <li>
          <button
            class="btn btn-hover-primary"
            title="{{
              cvvVisit && cvvVisit.showHistory
                ? 'Geschiedenis verbergen'
                : 'Toon geschiedenis'
            }}"
            (click)="
              visitItemClicked.emit({ action: 'history', type: 'visit' })
            "
          >
            <i class="fa-regular fa-history"></i>
          </button>
        </li>
      </ul>
    </div>

    <div class="cell flex-shrink-1" *ngIf="isActive && !isClassicView">
      <ul class="tile-buttons actions">
        <li>
          <button
            *ngIf="cvvObject && cvvObject.status !== 'Completed'"
            class="btn btn-hover-success"
            (click)="
              visitItemClicked.emit({ action: 'complete', type: 'visit' })
            "
            title="reis voltooien"
          >
            <i class="fa-regular fa-check"></i>
          </button>
        </li>
        <li>
          <button
            class="btn btn-hover-primary"
            (click)="
              activityItemClicked.emit({ action: 'add', type: 'activity' })
            "
            title="activiteit toevoegen"
          >
            <i class="fa-regular fa-plus"></i>
          </button>
        </li>
      </ul>
    </div>
  </div>
  <ng-container *ngIf="activities && activities.length > 0">
    <div *ngFor="let activity of activities" class="d-flex">
      <div class="cell" *ngIf="!isClassicView">
        <button
          class="btn btn-primary btn-sm border-square"
          style="font-size: 1.2em; width: 43px; height: 100%"
          [ngClass]="{
            'btn-primary': activity.movementType > 2,
            'btn-success': activity.movementType === 1,
            'btn-warning': activity.movementType === 2
          }"
          (click)="pickList(activity, 'movementType')"
        >
          <span *ngIf="activity.movementType === 1" title="Inkomend">IN</span>
          <span *ngIf="activity.movementType === 2" title="Verhaling">VH</span>
          <span *ngIf="activity.movementType === 3" title="Uitgaand">UIT</span>
          <span *ngIf="activity.movementType === 4" title="Turn-around"
            >RZ</span
          >
          <span *ngIf="activity.movementType === 5" title="Doorvaarder"
            >DV</span
          >
          <span *ngIf="activity.movementType === 255" title="Onbekend">..</span>
        </button>
      </div>
      <div
        class="cell dark-background p-2"
        style="min-width: 250px"
        *ngIf="!isClassicView"
      >
        {{ activity.activityType | cvvActivityTypeDisplay }}&nbsp;
        <svg
          *ngIf="activity.pilot === true"
          class="float-right ml-2"
          style="height: 20px"
          title="loods aan boord"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 425.88 553.77"
        >
          <defs>
            <style>
              .cls-1 {
                fill: #fff;
              }
            </style>
          </defs>
          <title>Loods aan boord</title>
          <path
            class="cls-1"
            d="M266.06,0q0,130.5-.16,261c0,4.66,2,4.95,5.68,4.94,49.17-.08,151.76,0,154.28,0v105c-1.52,0-107,.07-158.18,0-25.53,0-47.14-15.32-52.92-37.67-1.66-6.4-1.74-13.18-3-19.7-5.66-30-20.74-44.1-51.77-48.87-.11-1.33,0-176.2,0-262.2,0-.83-.05-1.66,0-2.49Z"
          />
          <path
            class="cls-1"
            d="M0,346.39c18,.13,36,0,53.93,0,33.81.06,67.63-.42,101.44,0a79.9,79.9,0,0,1,23.41,4.17c19.28,6.3,29.4,20.76,32.89,40.24.93,5.21,1,10.58,2,15.78a52.86,52.86,0,0,0,46.08,42.8,168.74,168.74,0,0,0,18.39,1.29c47.3.08,147.79,0,147.79,0s-.13,101.06,0,103.05c-54.35,0-165.2.13-166.43,0C239.1,551.6,225,540.26,218.26,521c-3-8.67-4.25-18-5.81-27.12-3.45-20.24-13.76-35.09-33.87-40.94a121.85,121.85,0,0,0-32.26-5c-46.95-.52-144.5-.12-146.32,0Z"
          />
        </svg>
        <span class="float-right">
          <span *ngIf="activity.berth1">{{ activity.berth1 }}</span>
          <span *ngIf="activity.berth1 && activity.berth2">-</span>
          <span *ngIf="activity.berth2">{{ activity.berth2 }}</span>
        </span>
      </div>
      <div class="cells" *ngIf="isClassicView">
        <div class="cell" style="min-width: 12% !important">
          <button
            class="btn btn-primary btn-sm border-square"
            style="font-size: 1.2em; width: 43px; height: 100%"
            [ngClass]="{
              'btn-primary': activity.movementType > 2,
              'btn-success': activity.movementType === 1,
              'btn-warning': activity.movementType === 2
            }"
            (click)="pickList(activity, 'movementType')"
          >
            <span *ngIf="activity.movementType === 1" title="Inkomend">IN</span>
            <span *ngIf="activity.movementType === 2" title="Verhaling"
              >VH</span
            >
            <span *ngIf="activity.movementType === 3" title="Uitgaand"
              >UIT</span
            >
            <span *ngIf="activity.movementType === 4" title="Turn-around"
              >RZ</span
            >
            <span *ngIf="activity.movementType === 5" title="Doorvaarder"
              >DV</span
            >
            <span *ngIf="activity.movementType === 255" title="Onbekend"
              >..</span
            >
          </button>
        </div>
        <div class="cell dark-background p-2" style="min-width: 88% !important">
          {{ activity.activityType | cvvActivityTypeDisplay }}&nbsp;
          <svg
            *ngIf="activity.pilot === true"
            class="float-right ml-2"
            style="height: 20px"
            title="loods aan boord"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 425.88 553.77"
          >
            <defs>
              <style>
                .cls-1 {
                  fill: #fff;
                }
              </style>
            </defs>
            <title>Loods aan boord</title>
            <path
              class="cls-1"
              d="M266.06,0q0,130.5-.16,261c0,4.66,2,4.95,5.68,4.94,49.17-.08,151.76,0,154.28,0v105c-1.52,0-107,.07-158.18,0-25.53,0-47.14-15.32-52.92-37.67-1.66-6.4-1.74-13.18-3-19.7-5.66-30-20.74-44.1-51.77-48.87-.11-1.33,0-176.2,0-262.2,0-.83-.05-1.66,0-2.49Z"
            />
            <path
              class="cls-1"
              d="M0,346.39c18,.13,36,0,53.93,0,33.81.06,67.63-.42,101.44,0a79.9,79.9,0,0,1,23.41,4.17c19.28,6.3,29.4,20.76,32.89,40.24.93,5.21,1,10.58,2,15.78a52.86,52.86,0,0,0,46.08,42.8,168.74,168.74,0,0,0,18.39,1.29c47.3.08,147.79,0,147.79,0s-.13,101.06,0,103.05c-54.35,0-165.2.13-166.43,0C239.1,551.6,225,540.26,218.26,521c-3-8.67-4.25-18-5.81-27.12-3.45-20.24-13.76-35.09-33.87-40.94a121.85,121.85,0,0,0-32.26-5c-46.95-.52-144.5-.12-146.32,0Z"
            />
          </svg>
          <span class="float-right">
            <span *ngIf="activity.berth1">{{ activity.berth1 }}</span>
            <span *ngIf="activity.berth1 && activity.berth2">-</span>
            <span *ngIf="activity.berth2">{{ activity.berth2 }}</span>
          </span>
        </div>
      </div>
      <div class="cell pr-2" style="min-width: 263px">
        <div class="row">
          <div class="col">
            <span class="text-muted d-inline-block p-2">Start:</span>
            <button
              type="button"
              class="btn btn-hover-light btn-inline"
              *ngIf="activity.startsOn"
              (click)="pickDatetime(activity, 'startsOn')"
            >
              <strong
                *ngIf="activity.startsOn"
                [ngClass]="{ 'text-danger' : hasActivityTimeWarning(activity.startsOn) }"
                title="{{ activity.startsOn | date: 'dd-MM-yyyy HH:mm' }}"
                >{{ activity.startsOn | date: 'HH:mm' }}</strong
              >
            </button>
            <button
              type="button"
              class="btn btn-inline btn-sm mt-1 mr-1 btn-success"
              *ngIf="!activity.startsOn"
              title="'Nu' registreren"
              (click)="setField(activity, 'startsOn', '#now')"
            >
              <i class="fa-regular fa-check"></i>
            </button>
            <button
              type="button"
              class="btn btn-inline btn-sm mt-1 btn-secondary"
              *ngIf="!activity.startsOn"
              (click)="pickDatetime(activity, 'startsOn')"
            >
              <i class="fa-regular fa-clock"></i>
            </button>
          </div>
          <div class="col">
            <span class="text-muted d-inline-block p-2">Eind:</span>
            <button
              type="button"
              class="btn btn-hover-light btn-inline"
              *ngIf="activity.endsOn"
              (click)="pickDatetime(activity, 'endsOn')"
            >
              <strong
                title="{{ activity.endsOn | date: 'dd-MM-yyyy HH:mm' }}"
                >{{ activity.endsOn | date: 'HH:mm' }}</strong
              >
            </button>
            <button
              type="button"
              class="btn btn-inline btn-sm mt-1 mr-1 btn-success"
              *ngIf="activity.startsOn && !activity.endsOn"
              title="'Nu' registreren"
              (click)="setField(activity, 'endsOn', '#now')"
            >
              <i class="fa-regular fa-check"></i>
            </button>
            <button
              type="button"
              class="btn btn-inline btn-sm mt-1 btn-secondary"
              *ngIf="activity.startsOn && !activity.endsOn"
              (click)="pickDatetime(activity, 'endsOn')"
            >
              <i class="fa-regular fa-clock"></i>
            </button>
          </div>
        </div>
      </div>

      <!-- Members -->
      <div class="cell d-flex align-items-center px-1">
        <span class="text-muted"
          >{{ 'customUI.members' | translate }}:&nbsp;</span
        >

        <ng-container
          *ngIf="!loadMembersAnimation; then picker; else loading"
        ></ng-container>

        <ng-template #picker>
          <button
            (click)="
              activityItemClicked.emit({
                type: 'activity',
                action: 'openMemberPicker',
                data: { activity: activity }
              })
            "
            type="button"
            class="btn btn-hover-light"
          >
            <ng-container
              *ngIf="
                !activity.people || activity.people.length === 0;
                then noMembers;
                else members
              "
            >
            </ng-container>

            <ng-template #members>
              <div class="mx-n2 mt-n1 mb-n2">
                <div
                  *ngFor="let member of activity.people"
                  class="d-inline-flex dark-background text-white rounded p-1 mx-1 mb-1"
                >
                  <span *ngIf="member" class="text-nowrap">
                    {{ member | nameFromUserId: 'abbr_lastname' | async }}
                  </span>
                </div>
              </div>
            </ng-template>

            <ng-template #noMembers>
              <span class="text-muted">{{
                'customUI.noMembers' | translate
              }}</span>
            </ng-template>
          </button>
        </ng-template>

        <ng-template #loading>
          <i class="fa-regular fa-spinner fa-2x fa-fw fa-spin"></i>
        </ng-template>
      </div>

      <div class="cell" *ngIf="isActivityType(activity, [1, 2, 3, 4])">
        <button
          type="button"
          class="btn btn-hover-light btn-inline"
          (click)="pickField(activity, 'receipts')"
        >
          <span class="text-muted" *ngIf="activity.receipts === 0"
            >0 bonnen</span
          >
          <strong *ngIf="activity.receipts > 0"
            >{{ activity.receipts }} bonnen</strong
          >
        </button>
      </div>
      <div class="cell flex-grow-1">
        <button
          type="button"
          class="btn btn-hover-light btn-inline btn-block text-left"
          (click)="pickField(activity, 'remarks')"
        >
          <span class="text-muted" *ngIf="!activity.remarks"
            >Geen opmerkingen...</span
          >
          <span *ngIf="activity.remarks">{{ activity.remarks }}</span>
        </button>
      </div>
      <div class="cell flex-shrink-1" *ngIf="!isClassicView">
        <ul class="tile-buttons">
          <li>
            <button
              class="btn btn-hover-light"
              title="activiteit bewerken"
              (click)="emitActivityAction(activity, 'openEditDialog')"
            >
              <i class="fa-regular fa-pencil-alt"></i>
            </button>
          </li>
        </ul>
      </div>
      <div class="cell flex-shrink-1" *ngIf="!isClassicView">
        <ul class="tile-buttons">
          <li>
            <button
              class="btn btn-hover-light"
              title="activiteit verwijderen"
              (click)="deleteActivity(activity)"
            >
              <i class="fa-solid fa-trash-alt"></i>
            </button>
          </li>
        </ul>
      </div>
      <div class="cell flex-shrink-1" *ngIf="isClassicView">
        <ul class="tile-buttons">
          <li>
            <button
              *ngIf="
                cvvObject &&
                cvvObject.status !== 'Completed' &&
                activity.activityState === 'open'
              "
              class="btn btn-hover-success"
              (click)="emitActivityAction(activity, 'complete')"
              title="reis voltooien"
            >
              <i class="fa-regular fa-check"></i>
            </button>
          </li>
          <li>
            <button
              class="btn btn-hover-light"
              title="activiteit bewerken"
              (click)="emitActivityAction(activity, 'openEditDialog')"
            >
              <i class="fa-regular fa-pencil-alt"></i>
            </button>
          </li>
          <li>
            <button
              class="btn btn-hover-light"
              title="activiteit verwijderen"
              (click)="deleteActivity(activity)"
            >
              <i class="fa-solid fa-trash-alt"></i>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </ng-container>

  <!-- HISTORY ACTIVITIES -->
  <ng-container
    *ngIf="
      cvvVisit &&
      cvvVisit.showHistory &&
      cvvVisit.historyActivities.length > 0 &&
      !isClassicView
    "
  >
    <div
      *ngFor="let activity of cvvVisit.historyActivities"
      class="d-flex history-activities"
    >
      <div class="cell">
        <button
          class="btn btn-primary btn-sm border-square"
          style="font-size: 1.2em; width: 43px; height: 100%"
          [ngClass]="{
            'btn-primary': activity.movementType > 2,
            'btn-success': activity.movementType === 1,
            'btn-warning': activity.movementType === 2
          }"
          (click)="pickList(activity, 'movementType')"
          disabled
        >
          <span *ngIf="activity.movementType === 1" title="Inkomend">IN</span>
          <span *ngIf="activity.movementType === 2" title="Verhaling">VH</span>
          <span *ngIf="activity.movementType === 3" title="Uitgaand">UIT</span>
          <span *ngIf="activity.movementType === 4" title="Turn-around"
            >RZ</span
          >
          <span *ngIf="activity.movementType === 5" title="Doorvaarder"
            >DV</span
          >
          <span *ngIf="activity.movementType === 255" title="Onbekend">..</span>
        </button>
      </div>
      <div class="cell p-2 text-muted" style="min-width: 250px">
        {{ activity.activityType | cvvActivityTypeDisplay }}&nbsp;
        <svg
          *ngIf="activity.pilot === true"
          class="float-right ml-2"
          style="height: 20px"
          title="loods aan boord"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 425.88 553.77"
        >
          <defs>
            <style>
              .cls-1 {
                fill: #fff;
              }
            </style>
          </defs>
          <title>Loods aan boord</title>
          <path
            class="cls-1"
            d="M266.06,0q0,130.5-.16,261c0,4.66,2,4.95,5.68,4.94,49.17-.08,151.76,0,154.28,0v105c-1.52,0-107,.07-158.18,0-25.53,0-47.14-15.32-52.92-37.67-1.66-6.4-1.74-13.18-3-19.7-5.66-30-20.74-44.1-51.77-48.87-.11-1.33,0-176.2,0-262.2,0-.83-.05-1.66,0-2.49Z"
          />
          <path
            class="cls-1"
            d="M0,346.39c18,.13,36,0,53.93,0,33.81.06,67.63-.42,101.44,0a79.9,79.9,0,0,1,23.41,4.17c19.28,6.3,29.4,20.76,32.89,40.24.93,5.21,1,10.58,2,15.78a52.86,52.86,0,0,0,46.08,42.8,168.74,168.74,0,0,0,18.39,1.29c47.3.08,147.79,0,147.79,0s-.13,101.06,0,103.05c-54.35,0-165.2.13-166.43,0C239.1,551.6,225,540.26,218.26,521c-3-8.67-4.25-18-5.81-27.12-3.45-20.24-13.76-35.09-33.87-40.94a121.85,121.85,0,0,0-32.26-5c-46.95-.52-144.5-.12-146.32,0Z"
          />
        </svg>
        <span class="float-right text-muted">
          <span *ngIf="activity.berth1">{{ activity.berth1 }}</span>
          <span *ngIf="activity.berth1 && activity.berth2">-</span>
          <span *ngIf="activity.berth2">{{ activity.berth2 }}</span>
        </span>
      </div>
      <div class="cell pr-2" style="min-width: 263px">
        <div class="row">
          <div class="col">
            <span class="text-muted d-inline-block p-2">Start:</span>
            <button
              disabled
              type="button"
              class="btn btn-hover-light btn-inline"
              *ngIf="activity.startsOn"
              (click)="pickDatetime(activity, 'startsOn')"
            >
              <strong
                *ngIf="activity.startsOn"
                title="{{ activity.startsOn | date: 'dd-MM-yyyy HH:mm' }}"
                >{{ activity.startsOn | date: 'HH:mm' }}</strong
              >
            </button>
            <button
              disabled
              type="button"
              class="btn btn-inline btn-sm mt-1 mr-1 btn-success"
              *ngIf="!activity.startsOn"
              title="'Nu' registreren"
              (click)="setField(activity, 'startsOn', '#now')"
            >
              <i class="fa-regular fa-check"></i>
            </button>
            <button
              disabled
              type="button"
              class="btn btn-inline btn-sm mt-1 btn-secondary"
              *ngIf="!activity.startsOn"
              (click)="pickDatetime(activity, 'startsOn')"
            >
              <i class="fa-regular fa-clock"></i>
            </button>
          </div>
          <div class="col">
            <span class="text-muted d-inline-block p-2">Eind:</span>
            <button
              disabled
              type="button"
              class="btn btn-hover-light btn-inline"
              *ngIf="activity.endsOn"
              (click)="pickDatetime(activity, 'endsOn')"
            >
              <strong
                title="{{ activity.endsOn | date: 'dd-MM-yyyy HH:mm' }}"
                >{{ activity.endsOn | date: 'HH:mm' }}</strong
              >
            </button>
            <button
              disabled
              type="button"
              class="btn btn-inline btn-sm mt-1 mr-1 btn-success"
              *ngIf="activity.startsOn && !activity.endsOn"
              title="'Nu' registreren"
              (click)="setField(activity, 'endsOn', '#now')"
            >
              <i class="fa-regular fa-check"></i>
            </button>
            <button
              disabled
              type="button"
              class="btn btn-inline btn-sm mt-1 btn-secondary"
              *ngIf="activity.startsOn && !activity.endsOn"
              (click)="pickDatetime(activity, 'endsOn')"
            >
              <i class="fa-regular fa-clock"></i>
            </button>
          </div>
        </div>
      </div>

      <!-- Members -->
      <div class="cell d-flex align-items-center px-1">
        <span class="text-muted"
          >{{ 'customUI.members' | translate }}:&nbsp;</span
        >

        <ng-container
          *ngIf="!loadMembersAnimation; then picker; else loading"
        ></ng-container>

        <ng-template #picker>
          <button
            disabled
            (click)="
              activityItemClicked.emit({
                type: 'activity',
                action: 'openMemberPicker',
                data: { activity: activity }
              })
            "
            type="button"
            class="btn btn-hover-light"
          >
            <ng-container
              *ngIf="
                !activity.people || activity.people.length === 0;
                then noMembers;
                else members
              "
            >
            </ng-container>

            <ng-template #members>
              <div class="mx-n2 mt-n1 mb-n2">
                <div
                  *ngFor="let member of activity.people"
                  class="d-inline-flex rounded p-1 mx-1 mb-1"
                >
                  <span *ngIf="member" class="text-nowrap">
                    {{ member | nameFromUserId: 'abbr_lastname' | async }}
                  </span>
                </div>
              </div>
            </ng-template>

            <ng-template #noMembers>
              <span class="text-muted">{{
                'customUI.noMembers' | translate
              }}</span>
            </ng-template>
          </button>
        </ng-template>

        <ng-template #loading>
          <i class="fa-regular fa-spinner fa-2x fa-fw fa-spin"></i>
        </ng-template>
      </div>

      <div class="cell" *ngIf="isActivityType(activity, [1, 2, 3, 4])">
        <button
          disabled
          type="button"
          class="btn btn-hover-light btn-inline"
          (click)="pickField(activity, 'receipts')"
        >
          <span class="text-muted" *ngIf="activity.receipts === 0"
            >0 bonnen</span
          >
          <strong class="text-muted" *ngIf="activity.receipts > 0"
            >{{ activity.receipts }} bonnen</strong
          >
        </button>
      </div>
      <div class="cell flex-grow-1">
        <button
          disabled
          type="button"
          class="btn btn-hover-light btn-inline btn-block text-left"
          (click)="pickField(activity, 'remarks')"
        >
          <span class="text-muted" *ngIf="!activity.remarks"
            >Geen opmerkingen...</span
          >
          <span *ngIf="activity.remarks">{{ activity.remarks }}</span>
        </button>
      </div>
    </div>
  </ng-container>

  <ng-container
    *ngIf="
      cvvVisit &&
      cvvVisit.showHistory &&
      cvvVisit.historyActivities.length === 0 &&
      !isClassicView
    "
  >
    <span class="text-muted p-2 cell history-activities text-center"
      >Geen geschiedenis beschikbaar voor dit bezoek
    </span>
  </ng-container>

  <ng-container *ngIf="cvvVisit && cvvVisit.isLoadingHistory && !isClassicView">
    <span class="p-2 cell history-activities text-center">
      <i class="fa-regular fa-spinner fa-2x fa-fw fa-spin"></i>
    </span>
  </ng-container>
</div>
