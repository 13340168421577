import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OrderRegistrationCurrency } from '@seahorse/domain';

@Pipe({ name: 'orderRegistrationCurrencyTranslate' })
export class OrderRegistrationCurrencyTranslatePipe implements PipeTransform {
  orderRegistrationCurrencies: object;

  constructor(private translate: TranslateService) {
    this.orderRegistrationCurrencies = {
      [OrderRegistrationCurrency.EUR]:
        'customModules.orderRegistrations.currency.eur',
      [OrderRegistrationCurrency.GBP]:
        'customModules.orderRegistrations.currency.gbp',
      [OrderRegistrationCurrency.USD]:
        'customModules.orderRegistrations.currency.usd',
      [OrderRegistrationCurrency.JPY]:
        'customModules.orderRegistrations.currency.jpy',
      [OrderRegistrationCurrency.DKK]:
        'customModules.orderRegistrations.currency.dkk',
      [OrderRegistrationCurrency.NOK]:
        'customModules.orderRegistrations.currency.nok',
    };
  }

  transform = (orderRegistrationCurrency: OrderRegistrationCurrency): string =>
    this.translate.instant(
      this.orderRegistrationCurrencies[orderRegistrationCurrency]
    );
}
