<div class="flex-shrink-1">
  <ul class="d-flex tile-buttons">
    <li>
      <a
        class="btn btn-hover-light"
        (click)="etaCalculation()"
        title="{{ 'map.popup.etaCalculation' | translate }}"
      >
        <i class="fa-regular fa-ruler"></i>
      </a>
    </li>
    <li>
      <a
        class="btn btn-hover-light"
        *ngIf="ship"
        routerLink="/nautical/vessels/{{ ship.id }}"
        title="{{ 'map.vessel.vesselInfo' | translate }}"
      >
        <i class="fa-regular fa-ship"></i>
      </a>
    </li>
    <li *hasPermission="'custom_ui.cvv'">
      <a
        class="btn btn-hover-light"
        *ngIf="ship"
        (click)="
          customActionServices.addCVVActivity(ship, activeVisit, aisInfo)
        "
        title="{{ '(CVV)' }} {{ 'map.popup.newActivity' | translate }}"
      >
        <i class="fa-regular fa-plus"></i>
      </a>
    </li>
    <li *hasPermission="'custom_ui.koperenploeg'">
      <a
        class="btn btn-hover-light"
        *ngIf="ship"
        (click)="customActionServices.addKPActivity(ship, activeVisit)"
        title="{{ '(KP)' }} {{ 'map.popup.newActivity' | translate }}"
      >
        <i class="fa-regular fa-plus"></i>
      </a>
    </li>
    <ng-container *hasPermission="'custom_ui.zeehaven_ijmuiden'">
      <li>
        <a
          *ngIf="ship"
          title="{{ '(ZH)' }} {{ 'shared.terms.actions' | translate }}"
          (click)="customActionServices.openZHActionDialog(ship, null)"
          class="btn btn-hover-light"
        >
          <i class="fa-regular fa-plus"></i>
        </a>
      </li>
    </ng-container>
    <ng-container *hasPermission="'custom_ui.haven_beverwijk'">
      <li>
        <a
          *ngIf="ship"
          title="{{ '(BV)' }} {{ 'shared.terms.actions' | translate }}"
          (click)="customActionServices.openBVActionDialog(ship.id)"
          class="btn btn-hover-light"
        >
          <i class="fa-regular fa-plus"></i>
        </a>
      </li>
    </ng-container>
    <li *hasPermission="'ships.fleetlist_admin'">
      <a
        class="btn btn-hover-light"
        *ngIf="ship"
        title="{{ 'Add to fleet' }}"
        (click)="addToMyFleet()"
      >
        <i class="fa-regular fa-star"></i>
      </a>
    </li>
    <li *hasPermission="'port_visits'">
      <a
        *ngIf="ship && activeVisit"
        [title]="'nautical.visit.details.details' | translate"
        (click)="portVisitDetails()"
        class="btn btn-hover-light"
      >
        <i
          class="fa-regular fa-exchange-alt fa-fw"
          style="vertical-align: -0.25em"
        ></i>
      </a>
    </li>
  </ul>
</div>
