import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { AddressTypes } from '@seahorse/domain';

@Pipe({
  name: 'addressType',
  pure: true,
})
export class AddressTypePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  types = {
    [AddressTypes.Email]: this.translateService.instant(
      'address.addressType.email'
    ),
    [AddressTypes.Fax]: this.translateService.instant(
      'address.addressType.fax'
    ),
    [AddressTypes.Phone]: this.translateService.instant(
      'address.addressType.phone'
    ),
    [AddressTypes.Postal]: this.translateService.instant(
      'address.addressType.postal'
    ),
    [AddressTypes.Visiting]: this.translateService.instant(
      'address.addressType.visiting'
    ),
  };

  transform(addressType: AddressTypes): string {
    return this.types[addressType];
  }
}
