<section class="page-header d-flex flex-fill px-1">
  <div class="flex-grow-1 d-flex justify-content-start align-items-center">
    <form (ngSubmit)="searchOrderRegistrations()" class="form-inline m-0 mx-1">
      <div class="input-group">
        <select
          class="form-control"
          style="max-width: 150px"
          [(ngModel)]="searchField"
          name="ddl_header_data_searchField"
        >
          <option
            [value]="field.fieldName"
            (click)="resetQuery()"
            *ngFor="let field of searchFields"
          >
            {{ field.displayName }}
          </option>
        </select>
        <div class="input-group">
          <input
            *ngIf="searchField !== 'supplier'"
            [(ngModel)]="search"
            [placeholder]="'shared.terms.search' | translate"
            type="text"
            name="search"
            class="form-control"
          />
          <input
            [(ngModel)]="selectedCompany"
            [ngClass]="{ 'd-none': searchField !== 'supplier' }"
            [class.is-invalid]="companiesLoadingFailed"
            [ngbTypeahead]="searchCompany"
            [inputFormatter]="companyFormatter"
            [resultFormatter]="companyFormatter"
            [editable]="true"
            [placeholder]="
              ('companies.pickerDialog.searchCompany' | translate) + '...'
            "
            [required]="false"
            (focus)="focus$.next($any($event).target.value)"
            (click)="click$.next($any($event).target.value)"
            (selectItem)="itemSelected($event)"
            #companyTypeahead="ngbTypeahead"
            id="typeahead-company"
            name="company"
            type="text"
            class="form-control"
          />
          <div class="input-group-append">
            <button
              [ngClass]="{ 'd-none': searchField !== 'supplier' }"
              (click)="setNull()"
              type="button"
              class="btn bg-transparent br-0"
              style="z-index: 100; margin-left: -40px"
            >
              <i class="fa-solid fa-times fa-xs fa-fw ml-1"></i>
            </button>
            <button
              [ngClass]="{ 'bg-secondary text-white': advanced }"
              (click)="advanced = !advanced"
              type="button"
              class="btn btn-outline-secondary"
            >
              <i class="fa-regular fa-chevron-down fa-fw"></i>
            </button>
            <button type="submit" class="btn btn-primary">
              <i class="fa-solid fa-search fa-fw"></i>
            </button>
          </div>
        </div>
      </div>
      <em *ngIf="count != null" class="ml-2 d-none d-md-flex">
        {{ count }}
        <ng-container
          *ngIf="count == 1; then itemFound; else itemsFound"
        ></ng-container>
        <ng-template #itemFound>{{
          'shared.terms.itemFound' | translate
        }}</ng-template>
        <ng-template #itemsFound>{{
          'shared.terms.itemsFound' | translate
        }}</ng-template>
      </em>
      <div
        [ngClass]="{
          'page-header-filter-list-visible': advanced,
          'sidebar-open': sidebarOpen
        }"
        class="page-header-filter-list px-3"
      >
        <div
          class="dropdown-menu d-block position-relative border-0 rounded-0 fixed-top"
        >
          <span class="h6 dropdown-header px-0">{{
            'shared.terms.advancedSearch' | translate
          }}</span>

          <div class="input-group mt-3">
            <div class="input-group-prepend">
              <span class="input-group-text">{{
                'customModules.orders.model.orderStatus' | translate
              }}</span>
            </div>
            <select
              [(ngModel)]="selectedStatus"
              required
              id="orderRegistrationStatus"
              name="orderRegistrationStatus"
              class="form-control"
            >
              <option selected [ngValue]="null">
                {{ 'shared.terms.all' | translate }}
              </option>
              <option *ngIf="statuses.length" disabled>
                &nbsp;&nbsp;&nbsp;
              </option>
              <option *ngFor="let status of statuses" [ngValue]="status">
                {{ status | shOrderRegistrationStatus }}
              </option>
            </select>
          </div>

          <div class="input-group mt-3">
            <div class="input-group-prepend">
              <span class="input-group-text">{{
                'customModules.orders.model.orderType' | translate
              }}</span>
            </div>
            <select
              [(ngModel)]="selectedType"
              required
              id="orderRegistrationType"
              name="orderRegistrationType"
              class="form-control"
            >
              <option selected [ngValue]="null">
                {{ 'shared.terms.all' | translate }}
              </option>
              <option *ngIf="statuses.length" disabled>
                &nbsp;&nbsp;&nbsp;
              </option>
              <option *ngFor="let type of types" [ngValue]="type.key">
                {{ type.value }}
              </option>
            </select>
          </div>

          <button type="submit" class="btn btn-primary float-right mt-3 mr-0">
            <i class="fa-solid fa-search fa-fw"></i>
          </button>
        </div>
      </div>
    </form>
  </div>
  <div class="d-flex justify-content-end align-items-center">
    <div class="input-group align-items-center w-auto mx-1 d-none d-md-flex">
      <div class="input-group-prepend">
        <span class="input-group-text">{{
          'shared.terms.filter' | translate
        }}</span>
      </div>
      <select
        [(ngModel)]="selectedFilter"
        (change)="onEvent.emit({ action: 'setFilter', data: selectedFilter })"
        name="ddl_selectedFilter"
        class="form-control"
      >
        <option *ngFor="let filter of filters" [ngValue]="filter.value">
          {{ filter.title | translate }}
        </option>
      </select>
    </div>

    <ca-content-distribution-export-button
      [contentModule]="'customcontent'"
      [objectType]="mappingKey"
      [contentType]="'st-orderRegistration'"
      [multiple]="true"
      [objectIds]="objectIds"
    >
    </ca-content-distribution-export-button>
    <button
      (click)="onEvent.emit({ action: 'create' })"
      type="button"
      class="btn btn-primary mx-1"
    >
      <i class="fa-regular fa-plus fa-fw"></i>
      <span class="d-none d-sm-inline-flex">{{
        'shared.terms.create' | translate
      }}</span>
    </button>
    <button
      type="button"
      class="btn btn-outline-secondary"
      (click)="onEvent.emit({ action: 'print' })"
    >
      {{ 'shared.terms.print' | translate }}
    </button>
  </div>
</section>
