import {
  Directive,
  Input,
  HostBinding,
  HostListener,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

@Directive({
  selector: '[caDisplayNon]',
})
export class DisplayNonDirective implements OnInit {
  @Input() maxWidth = 770; // default value
  @Input() hasId = false; // default value

  @HostBinding('class.hideForMobile') isHidden = false;
  @HostListener('window:resize', ['$event']) toggleHidden(event: any) {
    this.displayNone(event.target.innerWidth, this.maxWidth, this.hasId);
  }
  @HostListener('click') toggleDisplay() {
    this.displayNone(window.innerWidth, this.maxWidth, this.hasId);
  }
  ngOnInit() {
    this.displayNone(window.innerWidth, this.maxWidth, this.hasId);
  }
  displayNone(source: any, width: number, id: boolean) {
    if (!id) {
      this.checkId();
    }
    if (source < width && id) {
      this.isHidden = true;
    } else if (source < width && !id) {
      this.isHidden = false;
    } else {
      this.isHidden = false;
    }
  }
  checkId() {
    this.router.params.subscribe((params: Params) => {
      if (+params['id']) {
        return true;
      } else {
        return false;
      }
    });
  }
  constructor(private router: ActivatedRoute) {}
}
