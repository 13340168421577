import { Pipe, PipeTransform } from '@angular/core';
import { CustomDataBaseModel, FieldType } from '@seahorse/domain';

@Pipe({
  name: 'tempLinkedObjects',
  standalone: true,
})
export class LinkedObjectsPipe implements PipeTransform {
  transform(
    type: FieldType,
    obj: CustomDataBaseModel,
    systemCode: string,
    displayFields?: string[]
  ): any {
    let displayValue;

    if (type === FieldType.LinkedObjects) {
      const linkedObjects: CustomDataBaseModel[] =
        obj.__AdditionalData[systemCode] || [];
      displayValue = linkedObjects
        .map((object) => {
          if (object == null) {
            return ' ';
          } else if (object.__DisplayName != null) {
            return object.__DisplayName;
          } else {
            const objectEntries = Object.entries(object).filter(([key]) =>
              displayFields
                ? displayFields.includes(key)
                : !key.startsWith('__')
            );

            const filteredObject = objectEntries.reduce(
              (acc, [key, value]) => ({ ...acc, [key]: value }),
              {}
            );

            const keys = Object.keys(filteredObject);
            if (keys.length === 0) {
              return ' ';
            } else if (keys.length === 1) {
              return Object.values(filteredObject)[0] || ' ';
            } else {
              return keys.reduce((acc, cur, index) => {
                const keyValueString = `${
                  cur[0].toUpperCase() + cur.substring(1)
                }: ${filteredObject[cur] || ' '}`;
                return acc + (index !== 0 ? ',\n' : '') + keyValueString;
              }, '');
            }
          }
        })
        .join(',\n');
    } else if (type === FieldType.LinkedObject) {
      const linkedObject = obj.__AdditionalData[systemCode];
      displayValue = linkedObject != null ? linkedObject.__DisplayName : '';

      if (displayValue == null && linkedObject) {
        const objectEntries = Object.entries(linkedObject).filter(([key]) =>
          displayFields ? displayFields.includes(key) : !key.startsWith('__')
        );

        if (objectEntries.length === 1) {
          displayValue = objectEntries[0][1] != null ? objectEntries[0][1] : '';
        } else {
          displayValue = objectEntries.reduce((acc, [key, value], index) => {
            const formattedKey = key[0].toUpperCase() + key.substring(1);
            const valueString = value != null ? value : '';
            return (
              acc +
              (index !== 0 ? '\n' : '') +
              `${formattedKey}: ${valueString}`
            );
          }, '');
        }
      }
    }

    return displayValue;
  }
}
