import { Directive, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[caWindowScroll]',
})
export class WindowScrollDirective {
  @HostBinding('class.keep_fixed') isHidden = false;
  @HostListener('window:scroll', ['$event']) togglePossition(event: any) {
    if (document.documentElement.scrollTop > 200) {
      this.isHidden = true;
    } else {
      this.isHidden = false;
    }
  }
}
