import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { NauticalPortModel } from '@seahorse/domain';
import { CollectionDisplay } from '../../layout/models/collection-display.model';

@Injectable()
export class NauticalVisitInteractionService {
  private selectedFilterSource = new Subject<string>();
  private selectedNauticalFilterSource = new Subject<number>();
  private selectedPortSource = new Subject<NauticalPortModel>();
  private selectedViewSource = new Subject<CollectionDisplay>();

  selectedFilterCalled$ = this.selectedFilterSource.asObservable();
  selectedNauticalFilterCalled$ =
    this.selectedNauticalFilterSource.asObservable();
  selectedPortCalled$ = this.selectedPortSource.asObservable();
  selectedViewCalled$ = this.selectedViewSource.asObservable();

  ports: NauticalPortModel[] = [];

  setSelectedFilter(filter: string) {
    this.selectedFilterSource.next(filter);
  }

  setSelectedNauticalFilter(filter: number) {
    this.selectedNauticalFilterSource.next(filter);
  }

  setSelectedPort(port: NauticalPortModel) {
    this.selectedPortSource.next(port);
  }

  setSelectedView(view: CollectionDisplay) {
    this.selectedViewSource.next(view);
  }
}
