import { ZeehavenIJmuidenDisplayModel } from './zeehaven-ijmuiden-display.model';

export class ZeehavenIJmuidenOverviewModel {
  inPort: ZeehavenIJmuidenDisplayModel[];
  incoming: ZeehavenIJmuidenDisplayModel[];
  outgoing: ZeehavenIJmuidenDisplayModel[];
  shifting: ZeehavenIJmuidenDisplayModel[];

  constructor() {
    this.inPort = [];
    this.incoming = [];
    this.outgoing = [];
    this.shifting = [];
  }
}
