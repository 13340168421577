<div class="modal-header align-items-center">
  <h1 class="modal-title">
    {{ 'invoicingFlows.stageDetails.editStage' | translate }}
  </h1>
  <button closeModal type="button" class="btn btn-link text-dark">
    <i class="fa-solid fa-times fa-fw fa-sm"></i>
  </button>
</div>
<div class="modal-body">
  <stage-details-form
    [stage]="stage"
    [loadingStage]="loadingStage"
    [flow]="flow"
    [loadingFlow]="loadingFlow"
    (onEvent)="action($event)"
  ></stage-details-form>
</div>
<div class="modal-footer d-flex">
  <div class="flex-grow-1">
    <button closeModal type="button" class="btn btn-block btn-link">
      {{ 'shared.terms.cancel' | translate }}
    </button>
  </div>
  <div class="flex-grow-1">
    <button
      [disabled]="loadingStage"
      (click)="onEvent.emit({ action: 'saveStage', data: stage })"
      type="button"
      class="btn btn-block btn-success"
    >
      {{ 'shared.terms.save' | translate }}
    </button>
  </div>
</div>
