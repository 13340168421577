import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@seahorse/common';
import { NauticalPortDataService, NauticalPortModel } from '@seahorse/domain';
import { Observable, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  switchMap,
  catchError,
  tap,
  map,
} from 'rxjs/operators';

@Component({
  selector: 'ca-nautical-port-picker',
  templateUrl: 'nautical-port-picker.component.html',
})
export class NauticalPortPickerComponent {
  @Input() isRequired = false;
  @Input() nauticalPort: NauticalPortModel;
  @Input() onlyWithGeography = false;

  @Output() nauticalPortChange = new EventEmitter<NauticalPortModel>();

  nauticalPortsLoading = false;
  nauticalPortsLoadingFailed = false;

  constructor(
    private notification: NotificationService,
    private nauticalPortData: NauticalPortDataService,
    private translate: TranslateService
  ) {}

  itemSelected(itemEvent) {
    if (itemEvent && itemEvent.item) {
      this.nauticalPortChange.emit(itemEvent.item);
    } else {
      this.nauticalPortChange.emit(null);
    }
  }

  nauticalPortFormatter = (nauticalPort: NauticalPortModel): string =>
    nauticalPort && nauticalPort.name ? nauticalPort.name.toUpperCase() : '';

  nauticalPortsFormatter = (nauticalPort: NauticalPortModel): string => {
    if (nauticalPort.unloCode && nauticalPort.name) {
      return (
        nauticalPort.name.toUpperCase() + ' (' + nauticalPort.unloCode + ')'
      );
    } else {
      return nauticalPort.name.toUpperCase();
    }
  };

  searchnauticalPort = (input$: Observable<string>): any =>
    input$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.nauticalPortsLoading = true)),
      switchMap((s) =>
        this.nauticalPortData
          .getByName(s, null, null, this.onlyWithGeography)
          .pipe(
            tap(() => (this.nauticalPortsLoadingFailed = false)),
            map((r) => r.result),
            catchError((e) => {
              this.nauticalPortsLoadingFailed = true;

              this.notification.showError(
                e,
                this.translate.instant('shared.terms.failed')
              );
              return of([]);
            })
          )
      ),

      tap(() => (this.nauticalPortsLoading = false))
    );

  setNull = () => (this.nauticalPort = null);
}
