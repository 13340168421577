<div class="card">
  <div *ngIf="title" class="card-header">{{ title }}</div>
  <ng-container
    *ngIf="!loading; then showOrderRegistrations; else showLoading"
  ></ng-container>
  <ng-template #showOrderRegistrations>
    <ng-container
      *ngIf="
        orderRegistrations && orderRegistrations.length;
        then showList;
        else showEmptyMessage
      "
    ></ng-container>
    <ng-template #showList>
      <div class="card-body p-0">
        <table class="table table-responsive table-clickable table-truncated">
          <thead>
            <tr>
              <th
                scope="col"
                *ngFor="let column of columns; first as isFirst"
                [ngStyle]="{ width: column.width }"
              >
                <div
                  *ngIf="isFirst && showCheckbox"
                  class="custom-control custom-checkbox"
                >
                  <input
                    style="width: 1%"
                    type="checkbox"
                    class="custom-control-input"
                    id="{{ title + '_orderRegistrations_customCheckAll' }}"
                    (change)="checkAllChanged($event)"
                  />
                  <label
                    class="custom-control-label"
                    for="{{ title + '_orderRegistrations_customCheckAll' }}"
                  ></label>
                </div>
                <div *ngIf="!isFirst">{{ column.displayName }}</div>
              </th>
              <th scope="col">
                {{ 'customData.model.__CreatedOn' | translate }}
              </th>
              <th scope="col">
                {{ 'customData.model.__CreatedBy' | translate }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let orderRegistration of orderRegistrations"
              [ngClass]="{
                selected: selected && selected.__Id === orderRegistration.__Id
              }"
            >
              <td
                [ngClass]="{ 'text-truncate': !isFirst }"
                [ngStyle]="isFirst && { width: '1%' }"
                *ngFor="let column of columns; first as isFirst"
                (click)="
                  (!isFirst &&
                    event.emit({
                      action: 'select',
                      data: orderRegistration
                    })) ||
                    (isFirst && checkOrder(orderRegistration))
                "
                title="{{
                  orderRegistration.__AdditionalData &&
                  orderRegistration.__AdditionalData[column.fieldName]
                    ? (orderRegistration.__AdditionalData[column.fieldName]
                      | fieldValue: column.fieldType:column.pipes)
                    : (orderRegistration[column.fieldName]
                      | fieldValue: column.fieldType:column.pipes)
                }}"
              >
                <ng-container
                  *ngIf="
                    orderRegistration.__AdditionalData &&
                      orderRegistration.__AdditionalData[column.fieldName] &&
                      column.fieldName !== 'ship';
                    then additionalDataCell;
                    else plainCell
                  "
                ></ng-container>
                <ng-template #additionalDataCell>
                  {{
                    orderRegistration.__AdditionalData[column.fieldName]
                      | fieldValue: column.fieldType:column.pipes
                  }}
                </ng-template>
                <ng-template #plainCell>
                  <ng-container
                    *ngIf="
                      isFirst && showCheckbox;
                      then checkbox;
                      else orderData
                    "
                  ></ng-container>
                </ng-template>
                <ng-template #orderData>
                  <ng-container [ngSwitch]="column.fieldName">
                    <ng-container *ngSwitchCase="'amount'">
                      {{
                        orderRegistration.amount
                          | currency
                            : orderRegistration.currency
                            : 'symbol-narrow'
                            : '1.2-2'
                      }}
                    </ng-container>
                    <ng-container *ngSwitchCase="'ship'">
                      <ng-container
                        *ngIf="
                          orderRegistration.shipName;
                          then showShipName;
                          else showShip
                        "
                      >
                      </ng-container>
                      <ng-template #showShipName>
                        {{ orderRegistration.shipName }}
                      </ng-template>
                      <ng-template #showShip>
                        {{
                          orderRegistration &&
                          orderRegistration.__AdditionalData &&
                          orderRegistration.__AdditionalData.ship &&
                          orderRegistration.__AdditionalData.ship.name
                            ? orderRegistration.__AdditionalData.ship.name
                            : null
                        }}
                      </ng-template>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                      {{
                        orderRegistration[column.fieldName]
                          | fieldValue: column.fieldType:column.pipes
                      }}
                    </ng-container>
                  </ng-container>
                </ng-template>
                <ng-template #checkbox>
                  <div class="custom-control custom-checkbox" style="width: 1%">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      [(ngModel)]="orderRegistration.isChecked"
                      (click)="checkOrder(orderRegistration)"
                      [ngModelOptions]="{ standalone: true }"
                    />
                    <label class="custom-control-label"></label>
                  </div>
                </ng-template>
              </td>
              <td
                (click)="
                  event.emit({ action: 'select', data: orderRegistration })
                "
              >
                {{ orderRegistration.__CreatedOn | date: 'dd-MM-yyyy HH:mm' }}
              </td>
              <td
                (click)="
                  event.emit({ action: 'select', data: orderRegistration })
                "
              >
                {{ orderRegistration.__CreatedBy | nameFromUserId: '' | async }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-template>
    <ng-template #showEmptyMessage>
      <div class="card-body">
        {{ 'shared.terms.noResult' | translate }}
      </div>
    </ng-template>
  </ng-template>
  <ng-template #showLoading>
    <sh-loading-indicator class="d-block text-center"></sh-loading-indicator>
  </ng-template>
</div>
