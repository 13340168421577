<div class="modal-header">
  <div class="modal-title">{{ modalHeader }}</div>
  <button type="button" class="close" (click)="dismiss(null)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form class="modal-body" (ngSubmit)="save()">
  <ng-container [ngSwitch]="attribute.fieldType">
    <div *ngSwitchCase="fieldTypes.MultiLineText" class="form-group">
      <label class="bold" [ngClass]="{ required: attribute.isRequired }">{{
        attribute.fieldName
      }}</label>
      <textarea
        [ngClass]="{ required: attribute.isRequired }"
        name="editAttributeValueText"
        class="form-control"
        [(ngModel)]="currentValue"
        rows="5"
      >
      </textarea>
      <small
        *ngIf="
          attribute.isRequired &&
          (currentValue === undefined ||
            currentValue === null ||
            (currentValue && currentValue === ''))
        "
        class="text-danger"
        >{{ 'shared.terms.requiredFieldText' | translate }}</small
      >
    </div>

    <div *ngSwitchCase="fieldTypes.Boolean" class="form-group input-group">
      <div class="input-group-prepend">
        <div class="input-group-text">
          <input
            type="checkbox"
            id="editAttributeValueCheckbox"
            aria-label="Checkbox for following text input"
            name="editAttributeValueCheckbox"
            [(ngModel)]="currentValue"
          />
        </div>
      </div>
      <label
        class="form-control"
        for="editAttributeValueCheckbox"
        title="{{ attribute.fieldName }}"
      >
        {{ attribute.fieldName }}
      </label>
    </div>

    <div *ngSwitchCase="fieldTypes.Date" class="form-group input-group">
      <temp-date-time-picker
        [(dateTime)]="currentValue"
        [hideTime]="true"
        [isContained]="true"
      ></temp-date-time-picker>
    </div>

    <div *ngSwitchCase="fieldTypes.DateTime" class="form-group input-group">
      <temp-date-time-picker
        [(dateTime)]="currentValue"
        [isContained]="true"
      ></temp-date-time-picker>
    </div>

    <div *ngSwitchCase="fieldTypes.Time" class="form-group input-group">
      <temp-date-time-picker
        [(dateTime)]="currentValue"
        [dateTimeFormat]="attribute.fieldRule?.format ?? 'HH:mm'"
        [invalidBefore]="attribute.fieldRule?.minTime"
        [invalidAfter]="attribute.fieldRule?.maxTime"
        [hideDate]="true"
        [isContained]="true"
      ></temp-date-time-picker>
    </div>

    <ng-container *ngSwitchCase="fieldTypes.List">
      <ng-container
        *ngIf="
          attribute.fieldCode === 'flagCode';
          then showFlagCodeForm;
          else showDefaultListForm
        "
      ></ng-container>
      <ng-template #showFlagCodeForm>
        <temp-flag-code-picker-dialog-form
          [(selectedFlagCode)]="currentValue"
          [title]="attribute.fieldName"
          [isRequired]="attribute.isRequired"
        ></temp-flag-code-picker-dialog-form>
      </ng-template>
      <ng-template #showDefaultListForm>
        <div class="form-group">
          <label class="bold" [ngClass]="{ required: attribute.isRequired }">{{
            attribute.fieldName
          }}</label>
          <input
            id="typeahead-list"
            name="editAttributeValueList"
            type="text"
            class="form-control"
            [(ngModel)]="currentValue"
            [ngbTypeahead]="searchItem"
            [inputFormatter]="itemFormatter"
            [resultFormatter]="itemFormatter"
            (selectItem)="onItemSelected($event)"
            placeholder="{{ 'shared.terms.select' | translate }}"
            [editable]="isEditable"
            (click)="this.focusItem.next($event.target.value)"
          />
          <small
            *ngIf="
              attribute.isRequired &&
              (currentValue === undefined ||
                currentValue === null ||
                (currentValue && currentValue === ''))
            "
            class="text-danger"
            >{{ 'shared.terms.requiredFieldText' | translate }}</small
          >
        </div>
      </ng-template>
    </ng-container>

    <div *ngSwitchDefault class="form-group">
      <label class="bold" [ngClass]="{ required: attribute.isRequired }">{{
        attribute.fieldName
      }}</label>
      <input
        [ngClass]="{ required: attribute.isRequired }"
        name="editAttributeValueString"
        type="text"
        class="form-control"
        [(ngModel)]="currentValue"
      />
      <small
        *ngIf="
          attribute.isRequired &&
          (currentValue === undefined ||
            currentValue === null ||
            (currentValue && currentValue === ''))
        "
        class="text-danger"
        >{{ 'shared.terms.requiredFieldText' | translate }}</small
      >
    </div>
  </ng-container>

  <div class="form-group" *ngIf="!isValid">
    <div class="alert alert-danger">
      <span>{{ 'shared.terms.fieldInvalidText' | translate }}</span>
    </div>
  </div>
  <div class="d-flex">
    <div class="flex-grow-1">
      <button
        type="button"
        class="btn btn-block btn-link"
        (click)="dismiss(null)"
      >
        {{ 'shared.terms.cancel' | translate }}
      </button>
    </div>
    <div class="flex-grow-1">
      <button
        [attr.disabled]="
          attribute.isRequired &&
          (currentValue === undefined ||
            currentValue === null ||
            (currentValue &&
              attribute.fieldType !== fieldTypes.Boolean &&
              currentValue === ''))
            ? 'disabled'
            : null
        "
        type="submit"
        class="btn btn-block btn-success"
      >
        {{ 'shared.terms.save' | translate }}
      </button>
    </div>
  </div>
</form>
