import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { TranslateService } from '@ngx-translate/core';
import * as _ from 'underscore';

import { NotificationService } from '@seahorse/common';
import { ResultWrapper } from '@seahorse/common';
import {
  CustomDataBaseModel,
  CustomDataContentService,
  ObjectKeyMappingModel,
} from '@seahorse/domain';

@Component({ templateUrl: './custom-content-picker-modal.component.html' })
export class CustomContentPickerModalComponent implements OnInit {
  @Input() mappingModel: ObjectKeyMappingModel;
  @Input() allowMultiple: boolean;
  @Input() set objects(objects: CustomDataBaseModel[]) {
    if (objects) {
      if (this.mappingModel.displayFields[0]) {
        this.allObjects = _.sortBy(
          _.map(objects, (mapItem) => {
            if (!mapItem.__DisplayName || mapItem.__DisplayName.length === 0) {
              mapItem.__DisplayName = _.map(
                this.mappingModel.displayFields,
                (mapDisplay) => mapItem[mapDisplay]
              ).join(', ');
            }
            return mapItem;
          }),
          '__DisplayName'
        );
      } else {
        this.allObjects = objects;
      }
    } else {
      this.allObjects = null;
    }
  }
  get objects(): CustomDataBaseModel[] {
    if (this.searchField && this.searchField.length > 0) {
      return _.filter(this.allObjects, (filterItem) => {
        return (
          filterItem.__DisplayName
            .toLowerCase()
            .indexOf(this.searchField.toLowerCase()) > -1
        );
      });
    } else {
      return this.allObjects;
    }
  }

  @Input() set selectedObjectIds(selectedObjectIds: string[]) {
    if (selectedObjectIds) {
      this._selectedObjectIds = _.map(selectedObjectIds, (id) => {
        return id.toLowerCase();
      });
    } else {
      this._selectedObjectIds = [];
    }
  }
  get selectedObjectIds() {
    return this._selectedObjectIds;
  }

  @Input() set selectedObjects(selectedObjects: CustomDataBaseModel[]) {
    if (!this._selectedObjectIds) {
      this._selectedObjectIds = [];
    }

    _.each(selectedObjects, (u) => {
      if (this._selectedObjectIds.indexOf(u.__Id.toLowerCase()) === -1) {
        this._selectedObjectIds.push(u.__Id.toLowerCase());
      }
    });
  }

  private _selectedObjectIds: string[] = null; // lower case

  allObjects: CustomDataBaseModel[] = null;
  loadObjects: boolean;
  searchField: string;
  isAllObjectChecked = false;

  constructor(
    public modal: NgbActiveModal,
    private translateService: TranslateService,
    private dataService: CustomDataContentService,
    private notificationService: NotificationService
  ) {
    this.loadObjects = false;
  }

  ngOnInit() {
    if (!this._selectedObjectIds) {
      this._selectedObjectIds = [];
    }

    if (this.allObjects === null) {
      // Get data
      this.loadAllObjects();
    } else {
      this.isAllSelect();
    }
  }

  loadAllObjects() {
    this.loadObjects = true;
    this.dataService
      .searchCustomerData(
        this.mappingModel.objectKey,
        null,
        null,
        null,
        '__LastEditedOn',
        'desc',
        0,
        100
      )
      .subscribe((response: ResultWrapper<CustomDataBaseModel[]>) => {
        this.objects = [];
        if (response.hasResult) {
          this.objects = response.result;
          this.isAllSelect();
        } else {
          this.objects = [];
          this.notificationService.showError(
            this.translateService.instant('shared.terms.failed')
          );
        }

        this.loadObjects = false;
      });
  }

  selectAll(value) {
    this.isAllObjectChecked = value;
    if (this.isAllObjectChecked === true) {
      this._selectedObjectIds = _.map(this.allObjects, (u) => {
        return u.__Id.toLowerCase();
      });
    } else {
      this._selectedObjectIds = [];
    }
  }

  selectObject(value: string) {
    if (this.allowMultiple === true) {
      const foundIndex = this.selectedObjectIds.lastIndexOf(
        value.toLowerCase()
      );
      if (foundIndex > -1) {
        this.selectedObjectIds.splice(foundIndex, 1);
      } else {
        this.selectedObjectIds.push(value);
      }
      this.isAllSelect();
    } else {
      if (
        this.selectedObjectIds &&
        this.selectedObjectIds.length === 1 &&
        this.selectedObjectIds[0] === value
      ) {
        this.selectedObjectIds = [];
      } else {
        this.selectedObjectIds = [value];
      }
    }
  }

  isAllSelect() {
    this.isAllObjectChecked =
      this.allObjects &&
      this.selectedObjectIds &&
      this.allObjects.length === this.selectedObjectIds.length &&
      _.every(this.allObjects, (u) => {
        return this.selectedObjectIds.lastIndexOf(u.__Id) > -1;
      });
  }

  requestFailed() {
    this.notificationService.showError(
      this.translateService.instant('shared.terms.failed')
    );
  }

  onButtonClick(buttonName: string) {
    switch (buttonName) {
      case 'ok': {
        const users = _.filter(this.allObjects, (u) => {
          return this._selectedObjectIds.indexOf(u.__Id.toLowerCase()) > -1;
        });
        this.modal.close(users);
        break;
      }
      default: {
        this.modal.dismiss(null);
        break;
      }
    }
  }
}
