import {
  ChangeDetectionStrategy,
  Component,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';

import { CompanyContactModel } from '@seahorse/domain';

@Component({
  selector: 'ca-contact-list',
  templateUrl: './contact-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactListComponent {
  selectedContact: CompanyContactModel = null;

  @Input() contacts: CompanyContactModel[] = [];
  @Output() onContactSelected = new EventEmitter<CompanyContactModel>();

  selectContact(contact: CompanyContactModel) {
    this.selectedContact = contact;
    this.onContactSelected.emit(contact);
  }
}
