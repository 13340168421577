import { CommonModule } from '@angular/common';
import {
  Component, EventEmitter, Input, OnInit, Output, ViewChild
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  NgbTypeahead,
  NgbTypeaheadModule,
  NgbTypeaheadSelectItemEvent
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@seahorse/common';
import { NauticalShipDataService, NauticalShipFlagModel } from '@seahorse/domain';
import { merge, Observable, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged, filter, map
} from 'rxjs/operators';
import * as _ from 'underscore';

@Component({
  selector: 'temp-flag-code-picker-dialog-form',
  templateUrl: './flag-code-picker-dialog-form.component.html',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NgbTypeaheadModule,
    TranslateModule
  ]
})
export class FlagCodePickerDialogFormComponent implements OnInit {
  @Input() title: string;
  @Input() selectedFlagCode: NauticalShipFlagModel['flagCode'];
  @Input() errors: string[];
  @Input() isRequired: boolean;

  @Output() selectedFlagCodeChange!: EventEmitter<
    NauticalShipFlagModel['flagCode']
  >;

  @ViewChild('typeaheadFlags', { static: true }) typeaheadFlags: NgbTypeahead;

  selectedFlag: NauticalShipFlagModel;
  flags: NauticalShipFlagModel[];
  flagsLoading: boolean;
  flagsLoadingFailed: boolean;
  focus$: Subject<string>;
  click$: Subject<string>;

  constructor(
    private translate: TranslateService,
    private notification: NotificationService,
    private shipData: NauticalShipDataService
  ) {
    this.title = this.translate.instant('nautical.ship.model.flagCode');
    this.selectedFlagCode = null;
    this.errors = [];
    this.isRequired = false;
    this.selectedFlagCodeChange = new EventEmitter<
      NauticalShipFlagModel['flagCode']
    >();
    this.flags = [];
    this.flagsLoading = false;
    this.flagsLoadingFailed = false;
    this.focus$ = new Subject<string>();
    this.click$ = new Subject<string>();
  }

  ngOnInit() {
    this.flagsLoading = true;

    this.shipData.getShipFlagCodes().subscribe(
      (r) => {
        if (r.hasResult) {
          this.flags = _.sortBy(r.result, 'flagName');

          if (this.selectedFlagCode) {
            this.selectedFlag = _.findWhere(this.flags, {
              flagCode: this.selectedFlagCode,
            });
          }
        } else {
          this.notification.showError(
            _.pluck(r.messages, 'message').join('\n'),
            this.translate.instant('shared.terms.failed')
          );
        }
      },

      (e) => {
        this.notification.showError(
          _.pluck(e.error.messages, 'message').join('\n'),
          this.translate.instant('shared.terms.failed')
        );
      },

      () => (this.flagsLoading = false)
    );
  }

  itemSelected(event: NgbTypeaheadSelectItemEvent) {
    if (event.item) {
      this.selectedFlagCodeChange.emit(event.item.flagCode);
    }
  }

  flagFormatter = (flag: NauticalShipFlagModel): string => flag.flagCode;
  flagsFormatter = (flag: NauticalShipFlagModel): string =>
    `${flag.flagCode} (${flag.flagName})`;

  searchFlags = (
    input$: Observable<string>
  ): Observable<NauticalShipFlagModel[]> => {
    const debouncedText$ = input$.pipe(
      debounceTime(100),
      distinctUntilChanged()
    );
    const inputFocus$ = this.focus$;
    const clicksWithClosedPopup$ = this.click$.pipe(
      filter(() => !this.typeaheadFlags.isPopupOpen())
    );

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((search?: string) => {
        return this.flags
          .filter((x) => {
            let hasResult = false;

            if (x.flagCode.toLowerCase().indexOf(search.toLowerCase()) > -1) {
              hasResult = true;
            } else {
              hasResult =
                x.flagName.toLowerCase().indexOf(search.toLowerCase()) > -1;
            }

            return hasResult;
          })
          .slice(0, 100);
      })
    );
  };

  setNull() {
    this.selectedFlag = null;
    this.selectedFlagCodeChange.emit(null);
  }
}
