// 3rd party modules
import { Component } from '@angular/core';
import {
  NgbModal,
  NgbModalOptions,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@seahorse/common';
import {
  CustomDataSearchFieldModel,
  FieldType,
  MapDataService,
  NauticalMovementModel,
  NauticalVisitBaseModel,
  NauticalVisitDataService,
  NauticalVisitOverviewModel,
  NauticalVisitSortModel,
  SearchOperators,
} from '@seahorse/domain';
import { EditAttributeModal, FieldAttributeModel } from '@seahorse/temp';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';
import * as _ from 'underscore';
import { MemberPickerModalComponent } from '../../../../custom-ui/general/components/member-picker-modal/member-picker-modal.component';
import { ConfirmComponent } from '../../../../layout/components/dialogs/confirm.component';
import { ErrorComponent } from '../../../../layout/components/dialogs/error.component';
import { LayoutDisplayService } from '../../../../layout/services/layout-display.service';
import { UserDataService } from '../../../../user/services/user-data.service';

import { CVVAddActivityModal } from '../../components/add-activity-modal/add-activity-modal.component';
import { CVVShipRequirementsModal } from '../../components/cvv-ship-requirements-modal/cvv-ship-requirements-modal.component';
import * as CVVActiveVisitModel from '../../models/cvv-active-visit.model';
import {
  CVVActivityModel,
  CVVActivityStatus,
} from '../../models/cvv-activity.model';
import { CVVAddActivityResultModel } from '../../models/cvv-add-activity-result.model';
import { CVVEventModel } from '../../models/cvv-event.model';
import { CVVShipRequirementsModel } from '../../models/cvv-ship-requirements.model';
import { CVVActivityLogicService } from '../../services/cvv-activity-logic.service';
import { CVVDataService } from '../../services/cvv-data.service';

// ShipM8 modules
// CVV modules
@Component({
  selector: 'ca-cvv-activities-page',
  templateUrl: 'cvv-activities.page.html',
  styleUrls: ['./cvv-activities.page.scss'],
})
export class CVVActivitiesPage {
  private loadedVisits;
  // List of CVVVisitModels; contains db cvv visit, ucrn, activity[], mmsi. Index by cvv (visit) object id
  private cvvVisits: CVVVisitModel[];

  activeCvvVisits: CVVVisitModel[] = [];

  // public activeNrOfInward: number = 0;
  // public activeNrOfOutward: number = 0;
  // public activeNrOfShift: number = 0;
  // public activeNrOfOther: number = 0;

  expectedVisits: NauticalVisitOverviewModel[] = [];

  // public expectedNrOfInward: number = 0;
  // public expectedNrOfOutward: number = 0;
  // public expectedNrOfShift: number = 0;
  // public expectedNrOfOther: number = 0;

  filter = {
    timeFuture: 12, // default 12 hours in the future
    timePast: 24, // default 24 hours in the past
    options: [
      { value: 12, text: '12 uur' },
      { value: 24, text: '24 uur' },
      { value: 48, text: '48 uur' },
      { value: 96, text: '96 uur' },
    ],
  };
  isLoading = false;
  positions = {};
  shipRequirements = {};
  search = {
    resultCount: null,
    searchQuery: null,
  };
  selectedList = 'active'; // 'active' or 'history'
  selectedView = 'text'; // 'text' or 'flow'
  selectedFilter = 12;
  selectedPageView = 'modern';
  sortField = 'datetime'; // base on sort.model properties
  visitStatus = CVVActiveVisitModel.CVVActiveVisitStatus.Active;

  loadMembersAnimation: boolean;

  loas: object[];

  date = new Date();
  isActive = true;

  incomingTimeWarnings: { index: number; warning: string }[] = [];
  outgoingTimeWarnings: { index: number; warning: string }[] = [];

  get incomingTimeWarningDisplay(): string {
    return this.incomingTimeWarnings.map((x) => x.warning).join(', ');
  }

  get outgoingTimeWarningDisplay(): string {
    return this.outgoingTimeWarnings.map((x) => x.warning).join(', ');
  }

  constructor(
    private userDataService: UserDataService,
    private cvvData: CVVDataService,
    private cvvActivityLogic: CVVActivityLogicService,
    private layoutDisplay: LayoutDisplayService,
    private mapData: MapDataService,
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private visitData: NauticalVisitDataService,
    private translateService: TranslateService
  ) {
    this.layoutDisplay.currentModuleName = 'activiteiten';

    this.loadMembersAnimation = false;

    this.loas = [];

    this.date.setDate(this.date.getDate() - 1);

    // Load all users
    this.userDataService.getByOrganisation(false).subscribe();

    this.reload();
  }

  clear() {
    this.cvvVisits = [];
    this.positions = {};
    this.loadedVisits = {};
    this.expectedVisits = [];
  }

  reload() {
    this.clear();
    this.isLoading = true;

    switch (this.selectedList) {
      case 'active':
        this.loadActivePage();
        break;
      case 'history':
        this.loadHistoryPage();
        break;
    }
  }

  getActivities(visit: CVVVisitModel, movementType: string) {
    switch (movementType) {
      case 'inward': {
        const inwards =
          !visit || !visit.activities || visit.activities.length === 0
            ? []
            : _.filter(
                visit.activities,
                // tslint:disable-next-line: triple-equals
                (a) =>
                  a.activityState === CVVActivityStatus.Open &&
                  a.movementType == 1
              );
        return inwards;
      }
      case 'shift': {
        const shifts =
          !visit || !visit.activities || visit.activities.length === 0
            ? []
            : _.filter(
                visit.activities,
                // tslint:disable-next-line: triple-equals
                (a) =>
                  a.activityState === CVVActivityStatus.Open &&
                  a.movementType == 2
              );
        return shifts;
      }
      case 'outward': {
        const outwarts =
          !visit || !visit.activities || visit.activities.length === 0
            ? []
            : _.filter(
                visit.activities,
                // tslint:disable-next-line: triple-equals
                (a) =>
                  a.activityState === CVVActivityStatus.Open &&
                  a.movementType == 3
              );
        return outwarts;
      }
      case 'other':
      default: {
        const others =
          !visit || !visit.activities || visit.activities.length === 0
            ? []
            : _.filter(
                visit.activities,
                (a) =>
                  a.activityState === CVVActivityStatus.Open &&
                  // tslint:disable-next-line: triple-equals
                  a.movementType != 1 &&
                  // tslint:disable-next-line: triple-equals
                  a.movementType != 2 &&
                  // tslint:disable-next-line: triple-equals
                  a.movementType != 3
              );
        return others;
      }
    }
  }

  filterExpectedVisits(movementType: string) {
    switch (movementType) {
      case 'inward': {
        const inwards =
          !this.expectedVisits || this.expectedVisits.length === 0
            ? []
            : this.expectedVisits.filter(
                (x) => x.currentMovement && x.currentMovement.movementType === 1
              );
        return inwards;
      }
      case 'shift': {
        const shifts =
          !this.expectedVisits || this.expectedVisits.length === 0
            ? []
            : this.expectedVisits.filter(
                (x) => x.currentMovement && x.currentMovement.movementType === 2
              );
        return shifts;
      }
      case 'outward': {
        const outwarts =
          !this.expectedVisits || this.expectedVisits.length === 0
            ? []
            : this.expectedVisits.filter(
                (x) => x.currentMovement && x.currentMovement.movementType === 3
              );
        return outwarts;
      }
      case 'other':
      default: {
        const others =
          !this.expectedVisits || this.expectedVisits.length === 0
            ? []
            : this.expectedVisits.filter(
                (x) =>
                  x.currentMovement &&
                  x.currentMovement.movementType !== 1 &&
                  x.currentMovement.movementType !== 2 &&
                  x.currentMovement.movementType !== 3
              );
        return others;
      }
    }
  }

  //#region actions

  activityItemClicked(
    event: CVVEventModel,
    visit: NauticalVisitOverviewModel,
    cvvVisit?: CVVActiveVisitModel.CVVActiveVisitModel
  ) {
    switch (event.action) {
      case 'add':
        {
          const ngbModalOptions: NgbModalOptions = {
            backdrop: 'static',
          };
          const modalRef = this.modalService.open(
            CVVAddActivityModal,
            ngbModalOptions
          );
          const activity = new CVVActivityModel();

          modalRef.componentInstance.onItemClicked.subscribe(
            (onItemClicked: CVVEventModel) => {
              this.activityItemClicked(onItemClicked, null);
            }
          );

          const hasVisit = visit ? true : false;

          if (hasVisit) {
            activity.$nautical_portvisit_referencenumber =
              visit.referenceNumber;
            activity.$nautical_ship_id = visit.shipId;
            activity.movementType = visit.currentMovement
              ? visit.currentMovement.movementType
              : 255;
            if (visit.currentMovement) {
              activity.$nautical_portmovement_id = visit.currentMovement.id;
              if (visit.currentMovement.atd && visit.currentMovement.ata) {
                activity.startsOn = visit.currentMovement.ata;
                if (
                  visit.currentMovement.portWayPointTo &&
                  visit.currentMovement.portWayPointTo.code
                ) {
                  activity.berth1 = visit.currentMovement.portWayPointTo.code;
                }
              } else if (
                visit.currentMovement.atd &&
                !visit.currentMovement.ata
              ) {
                activity.startsOn = visit.currentMovement.eta;
                if (
                  visit.currentMovement.portWayPointTo &&
                  visit.currentMovement.portWayPointTo.code
                ) {
                  activity.berth1 = visit.currentMovement.portWayPointTo.code;
                }
              } else if (visit.currentMovement.etd) {
                activity.startsOn = visit.currentMovement.etd;
                if (
                  visit.currentMovement.portWayPointFrom &&
                  visit.currentMovement.portWayPointFrom.code
                ) {
                  activity.berth1 = visit.currentMovement.portWayPointFrom.code;
                }
              }

              if (activity.orderNumber === null) {
                this.setOrderNumber(visit.currentMovement, activity);
              }
            } else {
              if (visit.atd && visit.ata) {
                activity.startsOn = visit.ata;
              } else if (visit.atd && !visit.ata) {
                activity.startsOn = visit.eta;
              } else if (visit.etd) {
                activity.startsOn = visit.etd;
              }
            }
            activity.$companies_company_id =
              this.cvvActivityLogic.getCompanyByAgentId(
                visit.currentMovement
                  ? visit.currentMovement.portAgentId
                  : visit.portAgentId
              );
          } else {
            activity.movementType = 255;
          }
          modalRef.componentInstance.activity = activity;

          modalRef.result.then((modalResult: CVVAddActivityResultModel) => {
            if (hasVisit) {
              const groupIds = _.keys(this.cvvVisits);
              if (groupIds && groupIds.length > 0) {
                const additionalSearch = [];

                const searchFieldActivityType =
                  new CustomDataSearchFieldModel();
                searchFieldActivityType.fieldName = 'activityType';
                searchFieldActivityType.searchOperator = SearchOperators.Equals;
                searchFieldActivityType.searchValue =
                  modalResult.activity.activityType;

                additionalSearch.push(searchFieldActivityType);

                const currentDateTime = new Date();
                currentDateTime.setHours(currentDateTime.getHours() - 1);

                const searchFieldCreatedOn = new CustomDataSearchFieldModel();
                searchFieldCreatedOn.fieldName = '__CreatedOn';
                searchFieldCreatedOn.searchOperator =
                  SearchOperators.GreaterThan;
                searchFieldCreatedOn.searchValue = currentDateTime;

                additionalSearch.push(searchFieldCreatedOn);

                const searchFieldShipId = new CustomDataSearchFieldModel();
                searchFieldShipId.fieldName =
                  '$nautical_portvisit_referencenumber';
                searchFieldShipId.searchOperator = SearchOperators.Equals;
                searchFieldShipId.searchValue = modalResult.visit
                  ? modalResult.visit.referenceNumber
                  : visit.referenceNumber;

                additionalSearch.push(searchFieldShipId);

                this.cvvData
                  .getActivitiesByGroupIds(groupIds, additionalSearch)
                  .subscribe((response) => {
                    if (response.result && response.result.length > 0) {
                      ConfirmComponent.additionalText =
                        'You have already added the same activity in the past hour for the same visit. Do you want to continue?';

                      const modalConfirmRef = this.modalService.open(
                        ConfirmComponent,
                        { backdrop: 'static' }
                      );

                      modalConfirmRef.result.then((confirmResult) => {
                        if (confirmResult) {
                          this.callBackAddActivityModal(
                            modalResult.activity,
                            modalResult.visit ? modalResult.visit : visit,
                            cvvVisit
                          );
                        }
                      });
                    } else {
                      this.callBackAddActivityModal(
                        modalResult.activity,
                        modalResult.visit ? modalResult.visit : visit,
                        cvvVisit
                      );
                    }
                  });
              }
            } else {
              this.callBackAddActivityModal(
                modalResult.activity,
                modalResult.visit ? modalResult.visit : visit,
                cvvVisit
              );
            }
          });
        }
        break;

      case 'complete':
        {
          const activity = event.data['activity'];
          const validationResult =
            this.cvvActivityLogic.validActivitiesForVisit([activity]);
          if (validationResult.isValid === false) {
            ErrorComponent.title = 'Sommige velden zijn ongeldig:';
            ErrorComponent.body = validationResult.errors;
            const ngbModalOptions: NgbModalOptions = {
              backdrop: 'static',
            };
            this.modalService.open(ErrorComponent, ngbModalOptions);
          } else {
            this.cvvData.getActivitieById(activity.__Id).subscribe(
              (activityResult) => {
                if (activityResult && activityResult.result) {
                  this.completeActivity(activityResult.result, cvvVisit);
                } else {
                  this.completeActivity(activity, cvvVisit);
                }
              },
              (e) => {
                this.completeActivity(activity, cvvVisit);
              }
            );
          }
        }
        break;

      case 'delete':
        {
          this.cvvData
            .deleteActivity(event.data['activity'], event.data['remarks'])
            .subscribe((dbResult) => {
              if (dbResult.hasResult && this.cvvVisits[cvvVisit.__Id]) {
                const index = _.findIndex(
                  this.cvvVisits[cvvVisit.__Id].activities,
                  // tslint:disable-next-line: triple-equals
                  (a) => a['__Id'] == dbResult.result.__Id
                );
                if (index > -1) {
                  this.cvvVisits[cvvVisit.__Id].activities.splice(index, 1);
                }
              }
            });
        }
        break;

      case 'openEditDialog':
        {
          const ngbModalOptions: NgbModalOptions = {
            backdrop: 'static',
          };

          const modalRef = this.modalService.open(
            CVVAddActivityModal,
            ngbModalOptions
          );
          const activity = _.clone(event.data['activity']);
          modalRef.componentInstance.activity = activity;

          modalRef.componentInstance.onItemClicked.subscribe(
            (onItemClicked: CVVEventModel) => {
              switch (onItemClicked.action) {
                case 'openMemberPicker':
                  this.openMemberPickerModal(activity, visit, cvvVisit, false);
                  break;
              }
            }
          );

          modalRef.result
            .then((modalResult) => {
              if (
                modalResult &&
                modalResult.activity &&
                modalResult.activity.__Id
              ) {
                this.updateActivity(
                  modalResult.activity,
                  cvvVisit,
                  modalResult.visit
                );
              }
            })
            .catch();
        }
        break;

      case 'openMemberPicker':
        this.openMemberPickerModal(
          event.data['activity'],
          visit,
          cvvVisit,
          true
        );
        break;

      case 'update':
        this.updateActivity(event.data['activity'], cvvVisit);
        break;
    }
  }

  completeActivity(
    activity: CVVActivityModel,
    cvvVisit: CVVActiveVisitModel.CVVActiveVisitModel
  ) {
    let needCompleteVisit = false;
    const tempVisit = this.cvvVisits[cvvVisit.__Id];
    if (tempVisit && tempVisit.activities) {
      const openActivities = _.filter(
        tempVisit.activities,
        (a) =>
          a['__Id'] !== activity.__Id &&
          a['activityState'] === CVVActivityStatus.Open
      );
      needCompleteVisit = openActivities.length === 0;
    }

    if (activity.activityState !== CVVActivityStatus.Invoiced) {
      activity.activityState = CVVActivityStatus.Completed;
      this.updateActivity(activity, cvvVisit);
    }
    if (needCompleteVisit === true) {
      this.visitOnFinish(cvvVisit);
    }
  }

  setOrderNumber(
    currentMovement: NauticalMovementModel,
    activity: CVVActivityModel
  ) {
    if (
      currentMovement.portVisitEvents &&
      currentMovement.portVisitEvents.length > 0
    ) {
      let boatmenEvents = _.filter(
        currentMovement.portVisitEvents,
        (pve) =>
          pve !== null &&
          pve.activityType.indexOf('BOATMEN') === 0 &&
          pve.remarks != null
      );
      boatmenEvents = _.sortBy(
        _.sortBy(boatmenEvents, 'createdOn'),
        'parameters'
      ).reverse();
      if (boatmenEvents !== null && boatmenEvents.length > 0) {
        activity.orderNumber = boatmenEvents[0].remarks;
      }
    }
  }

  visitItemClicked(
    event: CVVEventModel,
    visit: NauticalVisitOverviewModel,
    cvvVisit?: CVVVisitModel
  ) {
    switch (event.type) {
      case 'shipRequirements':
      case 'openEditDialog':
        {
          const ngbModalOptions: NgbModalOptions = {
            backdrop: 'static',
          };

          const modalRef = this.modalService.open(
            CVVShipRequirementsModal,
            ngbModalOptions
          );
          if (this.shipRequirements[visit.shipId]) {
            modalRef.componentInstance.shipRequirements = _.clone(
              this.shipRequirements[visit.shipId]
            );
          } else {
            const model = new CVVShipRequirementsModel();
            model.$nautical_ship_id = visit.shipId;
            modalRef.componentInstance.shipRequirements = model;
          }
          modalRef.componentInstance.title = this.translateService.instant(
            'customers.cvv.shipRequirements.title'
          );
          modalRef.result
            .then((modalResult) => {
              if (modalResult) {
                this.saveShipRequirements(modalResult.model);
              }
            })
            .catch();
        }
        break;

      case 'visit':
        switch (event.action) {
          case 'active':
            this.visitOnActive(visit, null, cvvVisit ? cvvVisit.visit : null);
            break;
          case 'complete':
            this.visitOnComplete(cvvVisit.visit);
            break;
          case 'delete':
            this.visitOnDelete(visit);
            break;
          case 'history':
            this.visitOnHistory(cvvVisit);
            break;
          case 'openEditDialog':
            {
              const ngbModalOptions: NgbModalOptions = {
                backdrop: 'static',
              };

              const modalRef = this.modalService.open(
                CVVShipRequirementsModal,
                ngbModalOptions
              );

              modalRef.componentInstance.shipRequirements = {
                ...this.shipRequirements[visit.shipId],
              };
              modalRef.componentInstance.title = this.translateService.instant(
                'customers.cvv.currentRequirements.title'
              );
              modalRef.result
                .then((modalResult) => {
                  if (modalResult) {
                    this.updateRequirements(
                      visit,
                      modalResult.model,
                      cvvVisit.visit,
                      modalResult.saveDataToShip
                    );
                  }
                })
                .catch();
            }
            break;
        }
        break;
    }
  }

  callBackAddActivityModal(
    activity: CVVActivityModel,
    visit: NauticalVisitOverviewModel,
    cvvVisit?: CVVActiveVisitModel.CVVActiveVisitModel
  ) {
    if (!activity) {
      return;
    }

    let existingCvvVisitModel: CVVVisitModel = null;
    if (cvvVisit) {
      existingCvvVisitModel = this.cvvVisits[cvvVisit.__Id];
    }

    if (!existingCvvVisitModel) {
      // check the port visit is present in the list; if not add to the list
      if (visit && visit.id) {
        let portVisit: LoadedPortVisit =
          // tslint:disable-next-line: no-use-before-declare
          this.loadedVisits[LoadedPortVisit.getKey(visit)];
        if (!portVisit) {
          this.addLoadedVisits([visit], false);
          // tslint:disable-next-line: no-use-before-declare
          portVisit = this.loadedVisits[LoadedPortVisit.getKey(visit)];
        }
        this.visitOnActive(portVisit ? portVisit.model : null, [activity]);
      } else {
        this.visitOnActive(null, [activity]);
      }
    } else {
      // add new activity to the existing cvv visit
      this.itemAdded(activity, existingCvvVisitModel);
      if (!existingCvvVisitModel.movementId) {
        this.updateCvvVisitByNauticalVisit(cvvVisit.__Id, visit);
      }
    }
  }

  ensureCvvVisit(
    portVisit: NauticalVisitOverviewModel,
    cvvVisit: CVVActiveVisitModel.CVVActiveVisitModel,
    activities: CVVActivityModel[]
  ) {
    if (!cvvVisit) {
      let newVisit = null;
      if (portVisit && portVisit.currentMovement) {
        // create new cvv visit base on port visit
        newVisit = new CVVActiveVisitModel.CVVActiveVisitModel();
        newVisit.__Id = null;
        newVisit.$nautical_ship_id = portVisit.shipId;
        newVisit.$nautical_portvisit_id = portVisit.id;
        newVisit.$nautical_portmovement_id = portVisit.currentMovement.id;
        newVisit.status = this.visitStatus;
        newVisit.remarks = null;

        _.each(activities, (activity) => {
          if (activity.orderNumber === null) {
            this.setOrderNumber(portVisit.currentMovement, activity);
          }
        });
      } else if (
        activities &&
        activities.length > 0 &&
        activities[0].$nautical_ship_id
      ) {
        // create new cvv visit base on activities
        newVisit = new CVVActiveVisitModel.CVVActiveVisitModel();
        newVisit.__Id = null;
        newVisit.$nautical_ship_id = activities[0].$nautical_ship_id;
        newVisit.status = this.visitStatus;
        newVisit.remarks = null;
      }

      if (newVisit !== null) {
        this.cvvData.addActiveVisit(newVisit).subscribe((activeVisitResult) => {
          if (activeVisitResult.hasResult) {
            this.updateLocalListAndAddActivities(
              portVisit,
              activeVisitResult.result,
              activities
            );
          } else {
            const errors = _.map(
              activeVisitResult.messages,
              (msg) => msg.message
            );
            this.notificationService.showError(
              errors.join('\n'),
              'Fout tijdens activeren van een reis'
            );
          }
        });
      }
    } else {
      // update the status of the cvv visit when it needs
      if (cvvVisit.status !== this.visitStatus) {
        cvvVisit.status = this.visitStatus;
        this.cvvData.updateVisit(cvvVisit);
      }
      this.updateLocalListAndAddActivities(portVisit, cvvVisit, activities);
    }
  }

  /**
   * Apply the filter
   */
  find() {
    this.resetListAndSort();
  }

  itemAdded(item: CVVActivityModel, visitModel: CVVVisitModel) {
    if (!item || !item.activityType) {
      return;
    }

    // when visitModel is not given, assume this activity is not link to a portvisit/movement. just link to a ship
    const found: CVVVisitModel = visitModel
      ? visitModel
      : _.find(
          this.cvvVisits,
          (v: CVVVisitModel) =>
            // tslint:disable-next-line: no-use-before-declare
            v.key === CVVVisitModel.getKeyByShipId(item.$nautical_ship_id)
        );
    if (found) {
      item.groupId = found.visit.__Id;
      this.cvvData.addActivity(item).subscribe((activityResult: any) => {
        if (activityResult.result) {
          if (this.cvvVisits[found.visit.__Id]) {
            this.cvvVisits[found.visit.__Id].activities.push(
              activityResult.result
            );

            if (
              activityResult.result['activityState'] ===
                CVVActivityStatus.Open &&
              this.cvvVisits[found.visit.__Id].visit.status !==
                CVVActiveVisitModel.CVVActiveVisitStatus.Active
            ) {
              const modelToSave = {
                ...this.cvvVisits[found.visit.__Id].visit,
                status: CVVActiveVisitModel.CVVActiveVisitStatus.Active,
              };
              this.cvvData.updateVisit(modelToSave).subscribe({
                next: (saveVisitResponse) => {
                  if (saveVisitResponse.hasResult && saveVisitResponse.result) {
                    this.cvvVisits[found.visit.__Id].visit =
                      saveVisitResponse.result;
                  } else {
                    this.notificationService.displayErrorNotification(
                      saveVisitResponse.messages
                    );
                  }
                },
                error: (e) => {
                  this.notificationService.displayErrorNotification(e);
                },
                complete: () => {
                  this.selectedList = 'active';
                  this.reload();
                },
              });
            }
          }

          this.notificationService.showSuccess(
            this.translateService.instant('customUI.activity.saved'),
            this.translateService.instant('shared.terms.success')
          );
        } else {
          this.notificationService.showError(
            this.translateService.instant('shared.terms.failed')
          );
        }
      });
    }
  }

  openMemberPickerModal(
    activityModel: CVVActivityModel,
    visit: NauticalVisitOverviewModel,
    cvvVisit: CVVActiveVisitModel.CVVActiveVisitModel,
    needToSave: boolean
  ) {
    // Open member picker
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      size: 'sm',
    };
    const memberPickerModal: NgbModalRef = this.modalService.open(
      MemberPickerModalComponent,
      ngbModalOptions
    );

    // Input
    memberPickerModal.componentInstance.users = this.cvvActivityLogic.people;
    memberPickerModal.componentInstance.teams = this.cvvActivityLogic.teams;
    memberPickerModal.componentInstance.members = _.clone(activityModel.people);

    // Output
    memberPickerModal.componentInstance.onSelectMembersAction.subscribe(
      (onSelectMembersAction: {
        members: CVVActivityModel['people'];
        action: string;
      }) => {
        this.loadMembersAnimation = true;

        switch (onSelectMembersAction.action) {
          case 'save':
            activityModel.people = onSelectMembersAction.members;

            if (needToSave === true && activityModel.__Id) {
              this.updateActivity(activityModel, cvvVisit);
            }
            break;

          case 'dismiss':
            break;
        }

        memberPickerModal.dismiss();
        this.loadMembersAnimation = false;
      }
    );
  }

  updateActivity(
    activity: CVVActivityModel,
    cvvVisit: CVVActiveVisitModel.CVVActiveVisitModel,
    selectedVisit?: NauticalVisitOverviewModel
  ) {
    if (!activity || !activity.__Id || !activity.activityType) {
      return;
    }

    if (
      selectedVisit &&
      selectedVisit.currentMovement &&
      activity.orderNumber === null
    ) {
      this.setOrderNumber(selectedVisit.currentMovement, activity);
    }

    this.cvvData.updateActivity(activity).subscribe((dbResult) => {
      if (dbResult.hasResult) {
        if (dbResult.result && dbResult.result.__Id) {
          if (!cvvVisit.$nautical_portvisit_id && selectedVisit) {
            this.updateCvvVisitByNauticalVisit(
              cvvVisit.__Id,
              selectedVisit,
              dbResult.result.__Id
            );
          }

          if (this.cvvVisits[cvvVisit.__Id]) {
            const index = _.findIndex(
              this.cvvVisits[cvvVisit.__Id].activities,
              // tslint:disable-next-line: triple-equals
              (a: CVVActivityModel) => a.__Id == dbResult.result.__Id
            );
            if (index > -1) {
              this.cvvVisits[cvvVisit.__Id].activities[index] = dbResult.result;
            }
          }

          this.notificationService.showSuccess(
            this.translateService.instant('customUI.activity.updated'),
            this.translateService.instant('shared.terms.success')
          );
        }
      } else {
        this.notificationService.showError(
          this.translateService.instant('shared.terms.failed')
        );
      }
    });
  }

  updateLocalListAndAddActivities(
    portVisit: NauticalVisitOverviewModel,
    cvvVisit: CVVActiveVisitModel.CVVActiveVisitModel,
    activities: CVVActivityModel[]
  ) {
    const newModel = this.convertVisitToCvvVisit(cvvVisit, portVisit);

    // ship name is already load, set the name when the portVisit is not set
    if (!newModel.nauticalVisit.ship && activities && activities.length > 0) {
      newModel.nauticalVisit.ship = activities[0].$nautical_ship;
    }

    this.loadPositionByMmsi(newModel.mmsi);
    this.loadRequirementsByShipId(newModel.nauticalVisit.id);
    const newItems = !activities
      ? this.cvvActivityLogic.generateActivitiesForVisit(portVisit)
      : activities;
    if (newItems) {
      newItems.forEach((item) => {
        this.itemAdded(item, newModel);
      });
    }

    this.finishLoadedData();
  }

  updateCvvVisitByNauticalVisit(
    cvvVisitId: string,
    nauticalVisit: NauticalVisitOverviewModel,
    skipActivityId?: string
  ) {
    const cvvVisit: CVVVisitModel = this.cvvVisits[cvvVisitId];
    if (cvvVisit) {
      // tslint:disable-next-line: no-use-before-declare
      cvvVisit.key = CVVVisitModel.getKeyByPortVisit(nauticalVisit);
      cvvVisit.nauticalVisit = nauticalVisit;
      cvvVisit.visit.$nautical_portvisit_id = nauticalVisit.id;
      if (nauticalVisit.currentMovement) {
        cvvVisit.movementId = nauticalVisit.currentMovement.id;
        cvvVisit.visit.$nautical_portmovement_id =
          nauticalVisit.currentMovement.id;
      }

      this.cvvData.updateVisit(cvvVisit.visit).subscribe((response) => {
        if (response.hasResult && response.result) {
          cvvVisit.visit = response.result;
        }
      });

      _.each(this.cvvVisits[cvvVisitId].activities, (a: CVVActivityModel) => {
        if (
          a.__Id !== skipActivityId &&
          !a.$nautical_portvisit_referencenumber
        ) {
          a.$nautical_portvisit_referencenumber = nauticalVisit.referenceNumber;
          if (nauticalVisit.currentMovement) {
            a.$nautical_portmovement_id = nauticalVisit.currentMovement.id;
            this.cvvData.updateActivity(a).subscribe((res) => {
              if (res.hasResult && res.result) {
                const idx = _.findIndex(
                  this.cvvVisits[cvvVisitId].activities,
                  (activity: CVVActivityModel) =>
                    activity.__Id === res.result.__Id
                );
                if (idx !== -1) {
                  this.cvvVisits[cvvVisitId].activities[idx] = res.result;
                }
              }
            });
          }
        }
      });
    }
  }

  visitOnActive(
    source: NauticalVisitBaseModel,
    activities: CVVActivityModel[],
    cvvVisit?: CVVActiveVisitModel.CVVActiveVisitModel
  ) {
    this.incomingTimeWarnings = this.incomingTimeWarnings.filter(
      (x) => x.index !== source.id
    );
    this.outgoingTimeWarnings = this.outgoingTimeWarnings.filter(
      (x) => x.index !== source.id
    );

    const portVisit: NauticalVisitOverviewModel = <NauticalVisitOverviewModel>(
      source
    );
    if (cvvVisit) {
      const model: CVVVisitModel = this.cvvVisits[cvvVisit.__Id];
      if (!model) {
        this.ensureCvvVisit(portVisit, cvvVisit, activities);
      } else {
        if (activities && activities.length > 0) {
          activities.forEach((item) => {
            this.itemAdded(item, model);
          });
        }
      }
    } else {
      if (!portVisit) {
        if (activities && activities.length > 0) {
          const otherCVVVisit = _.find(
            this.cvvVisits,
            (v: CVVVisitModel) =>
              v.key ===
              // tslint:disable-next-line: no-use-before-declare
              CVVVisitModel.getKeyByShipId(activities[0].$nautical_ship_id)
          );
          if (otherCVVVisit) {
            activities.forEach((item) => {
              this.itemAdded(item, otherCVVVisit);
            });
          } else {
            let found = null;
            this.cvvData
              .getVisitsByShipIds([activities[0].$nautical_ship_id])
              .subscribe(
                (result) => {
                  if (result.hasResult && result.result.length > 0) {
                    found = _.find(
                      result.result,
                      (v: CVVActiveVisitModel.CVVActiveVisitModel) =>
                        !v.$nautical_portvisit_id
                    );
                  }
                },
                (error) => {
                  this.ensureCvvVisit(portVisit, found, activities);
                },
                () => {
                  this.ensureCvvVisit(portVisit, found, activities);
                }
              );
          }
        }
      } else if (portVisit.currentMovement) {
        const otherCVVVisit = _.find(
          this.cvvVisits,
          (v: CVVVisitModel) =>
            // tslint:disable-next-line: no-use-before-declare
            v.key === CVVVisitModel.getKeyByPortVisit(portVisit)
        );
        if (otherCVVVisit) {
          if (activities && activities.length > 0) {
            activities.forEach((item) => {
              if (item.orderNumber === null) {
                this.setOrderNumber(portVisit.currentMovement, item);
              }
              this.itemAdded(item, otherCVVVisit);
            });
          }
        } else {
          let found = null;
          this.cvvData.getVisitByPortVisitId(portVisit.id).subscribe(
            (result) => {
              if (result.hasResult && result.result.length > 0) {
                found = _.find(
                  result.result,
                  (o: CVVActiveVisitModel.CVVActiveVisitModel) => {
                    return (
                      o.$nautical_portmovement_id ===
                      portVisit.currentMovement.id
                    );
                  }
                );
              }
            },
            (error) => {
              this.ensureCvvVisit(portVisit, found, activities);
            },
            () => {
              this.ensureCvvVisit(portVisit, found, activities);
            }
          );
        }
      } else {
        if (activities && activities.length > 0) {
          this.ensureCvvVisit(null, null, activities);
        }
      }
    }
  }

  visitOnComplete(cvvVisit: CVVActiveVisitModel.CVVActiveVisitModel) {
    if (!cvvVisit) {
      return;
    }

    const currentActivities = this.cvvVisits[cvvVisit.__Id].activities;
    if (
      currentActivities === undefined ||
      currentActivities === null ||
      currentActivities.length === 0
    ) {
      ConfirmComponent.additionalText =
        'Er zijn nog geen activiteiten voor deze reis opgevoerd. Weet je zeker dat je deze reis wil afsluiten?';
      const ngbModalOptions: NgbModalOptions = {
        backdrop: 'static',
      };
      const modalRef = this.modalService.open(
        ConfirmComponent,
        ngbModalOptions
      );

      modalRef.result.then((modalResult) => {
        if (modalResult === true) {
          this.visitOnFinish(cvvVisit);
        }
      });
    } else {
      const validationResult =
        this.cvvActivityLogic.validActivitiesForVisit(currentActivities);
      if (validationResult.isValid === false) {
        ErrorComponent.title = 'Sommige velden zijn ongeldig:';
        ErrorComponent.body = validationResult.errors;
        const ngbModalOptions: NgbModalOptions = {
          backdrop: 'static',
        };
        this.modalService.open(ErrorComponent, ngbModalOptions);
      } else {
        this.visitOnFinish(cvvVisit);
      }
    }
  }

  visitOnHistory(cvvVisit: CVVVisitModel) {
    if (!cvvVisit) {
      return;
    }

    if (cvvVisit.showHistory) {
      cvvVisit.showHistory = false;

      return;
    }
    if (!cvvVisit.nauticalVisit || !cvvVisit.nauticalVisit.referenceNumber) {
      cvvVisit.historyActivities = [];
      cvvVisit.showHistory = true;
      cvvVisit.isLoadingHistory = false;
      return;
    }

    cvvVisit.isLoadingHistory = true;

    const additionalSearch = [];

    const searchFieldReferenceNo = new CustomDataSearchFieldModel();
    searchFieldReferenceNo.fieldName = '$nautical_portvisit_referencenumber';
    searchFieldReferenceNo.searchOperator = SearchOperators.Equals;
    searchFieldReferenceNo.searchValue = cvvVisit.nauticalVisit.referenceNumber;

    additionalSearch.push(searchFieldReferenceNo);

    const searchValues = [
      CVVActivityStatus.Completed,
      CVVActivityStatus.Invoiced,
    ];

    const searchFieldActivityState = new CustomDataSearchFieldModel();
    searchFieldActivityState.fieldName = 'activityState';
    searchFieldActivityState.searchOperator = SearchOperators.InArray;
    searchFieldActivityState.searchValue = searchValues.join();

    additionalSearch.push(searchFieldActivityState);

    this.cvvData.getActivities(additionalSearch).subscribe((response) => {
      cvvVisit.historyActivities = response.result;
      cvvVisit.showHistory = true;
      cvvVisit.isLoadingHistory = false;
    });
  }

  visitOnDelete(visit: NauticalVisitOverviewModel) {
    if (!visit) {
      return;
    }

    const model: CVVVisitModel = _.find(
      this.cvvVisits,
      // tslint:disable-next-line: no-use-before-declare
      (v: CVVVisitModel) => v.key === CVVVisitModel.getKeyByPortVisit(visit)
    );
    if (!model) {
      const ngbModalOptions: NgbModalOptions = {
        backdrop: 'static',
        size: 'sm',
      };
      const modalRef = this.modalService.open(
        EditAttributeModal,
        ngbModalOptions
      );
      modalRef.componentInstance.attribute = new FieldAttributeModel(
        'Opmerkingen',
        FieldType.MultiLineText,
        null,
        'remarks',
        true
      );
      modalRef.componentInstance.modalHeader = 'Reis verwijderen';

      modalRef.result.then((modalResult) => {
        if (modalResult && modalResult.fieldValue !== undefined) {
          const newVisit = new CVVActiveVisitModel.CVVActiveVisitModel();
          newVisit.__Id = null;
          newVisit.$nautical_ship_id = visit.shipId;
          newVisit.$nautical_portvisit_id = visit.id;
          newVisit.$nautical_portmovement_id = visit.currentMovement.id;
          newVisit.status = CVVActiveVisitModel.CVVActiveVisitStatus.Ignored;
          newVisit.remarks = modalResult.fieldValue;

          this.cvvData
            .addActiveVisit(newVisit)
            .subscribe((activeVisitResult) => {
              if (activeVisitResult.hasResult) {
                this.loadedVisits[
                  // tslint:disable-next-line: no-use-before-declare
                  LoadedPortVisit.getKey(visit)
                ].isExpectedVisit = false;
                this.resetIsExpectedVisit();
              } else {
                const errors = _.map(
                  activeVisitResult.messages,
                  (msg) => msg.message
                );
                this.notificationService.showError(
                  errors.join('\n'),
                  'Fout tijdens verwijderen van een reis'
                );
              }
            });
        }
      });
    } else {
      const currenttActiveVisit = model.visit;
      currenttActiveVisit.status =
        CVVActiveVisitModel.CVVActiveVisitStatus.Ignored;
      this.cvvData
        .updateVisit(currenttActiveVisit)
        .subscribe((activeVisitResult) => {
          if (activeVisitResult.hasResult) {
            this.cvvVisits = _.omit(this.cvvVisits, currenttActiveVisit.__Id);
            this.resetListAndSort();
          } else {
            const errors = _.map(
              activeVisitResult.messages,
              (msg) => msg.message
            );
            this.notificationService.showError(
              errors.join('\n'),
              'Fout tijdens verwijderen van een reis'
            );
          }
        });
    }
  }

  visitOnFinish(cvvVisit: CVVActiveVisitModel.CVVActiveVisitModel) {
    const model: CVVVisitModel = this.cvvVisits[cvvVisit.__Id];
    if (model && model.visit) {
      model.visit.status = CVVActiveVisitModel.CVVActiveVisitStatus.Completed;

      const updateVisit = this.cvvData.updateVisit(model.visit);
      const activeActivities = this.cvvData.getActivitiesByGroupIds([
        model.visit.__Id,
      ]);
      forkJoin([updateVisit, activeActivities]).subscribe(
        (results: any[]) => {
          if (results[1] && results[1].result) {
            this.cvvVisits[cvvVisit.__Id].activities = results[1].result;
          }

          if (results[0] && results[0].result) {
            model.visit = results[0].result;
            const currentActivities = this.cvvVisits[cvvVisit.__Id].activities;
            _.each(currentActivities, (activity) => {
              if (
                activity.orderNumber === null &&
                model.nauticalVisit &&
                model.nauticalVisit.currentMovement
              ) {
                this.setOrderNumber(
                  model.nauticalVisit.currentMovement,
                  activity
                );
              }
              if (activity.activityState === CVVActivityStatus.Open) {
                activity.activityState = CVVActivityStatus.Completed;
                this.cvvData.updateActivity(activity).subscribe();
              }
            });

            this.resetActiveCvvVisits();
            this.sortVisits();
          }
        },
        (error) => {
          this.isLoading = false;
        }
      );
    }
  }

  //#endregion

  //#region filtering

  filterByTime(updateValue: boolean) {
    let filterKey;
    switch (this.selectedList) {
      case 'active':
        filterKey = 'timeFuture';
        this.visitStatus = CVVActiveVisitModel.CVVActiveVisitStatus.Active;
        break;
      case 'history':
        filterKey = 'timePast';
        this.visitStatus = CVVActiveVisitModel.CVVActiveVisitStatus.Completed;
        break;
    }

    if (updateValue) {
      this.filter[filterKey] = this.selectedFilter;
    } else {
      this.selectedFilter = this.filter[filterKey];
    }

    // this.selectedFilter = _.find(this.filter.options, (o) => { return o.value === this.filter[filterKey]; }).text;
    this.reload();
  }

  switchList(value: string) {
    // when the list is switch via the ui, no input value
    if (value) {
      this.selectedList = value;
    }
    this.filterByTime(false);
  }

  switchPageView() {
    // in case of classic view and the list is history, switch back to active list
    if (
      this.selectedPageView === 'classic' &&
      this.selectedList === 'history'
    ) {
      this.switchList('active');
    }
  }

  //#endregion

  //#region Private methods

  private addLoadedVisits(
    list: NauticalVisitBaseModel[],
    isExpectedVisit: boolean
  ) {
    _.each(list, (v) => {
      // tslint:disable-next-line: no-use-before-declare
      const portVisit = new LoadedPortVisit();
      portVisit.isExpectedVisit = isExpectedVisit;
      portVisit.model = v;
      // tslint:disable-next-line: no-use-before-declare
      this.loadedVisits[LoadedPortVisit.getKey(v)] = portVisit;
    });
  }

  private loadActivePage() {
    const expectedVisits = this.visitData.getActiveVisitForPort(
      this.cvvActivityLogic.portCode,
      this.filter.timeFuture,
      true,
      0,
      500
    );
    const activeVisits = this.cvvData.getActiveVisits(['$nautical_ship_name']);

    // 1. get the expected visits (NauticalVisitOverviewModel) and active cvv visits (Custom data)
    forkJoin([expectedVisits, activeVisits]).subscribe(
      (results) => {
        this.loadMissingDataForActivePage(results[0], results[1]);
        this.loas = this.loa(results[1].result);
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }

  loa(v): Object[] {
    const loas = [];

    _.forEach(v, (i) => {
      if (
        i['$nautical_ship'] &&
        i['$nautical_ship']['attribute_dimensions_loa']
      ) {
        loas.push({
          id: i['$nautical_ship_id'],
          loa: i['$nautical_ship']['attribute_dimensions_loa'],
        });
      }
    });

    return loas;
  }

  private loadHistoryPage() {
    // History page => 1. find the modified activity in the last x hours
    const date = moment().add(0 - this.filter.timePast, 'h');

    let groupIds = [];
    this.cvvData.getHistoryActivitiesByDate(date).subscribe(
      (response) => {
        if (response.hasResult && response.result) {
          groupIds = _.pluck(response.result, 'groupId');
        }
      },
      (error) => {
        this.loadHistoryGroupObjects(groupIds);
      },
      () => {
        this.loadHistoryGroupObjects(groupIds);
      }
    );
  }

  private loadHistoryGroupObjects(idsToLoad: string[]) {
    // History page => 2. find the modified visit/group object in the last x hours + the visit/group objects of the preloaded activities
    const date = moment().add(0 - this.filter.timePast, 'h');

    const historyVisits = this.cvvData.getHistoryVisitsByDate(date, [
      '$nautical_ship_name',
    ]);
    if (idsToLoad && idsToLoad.length > 0) {
      const historyActivities = this.cvvData.getVisitsByIds(idsToLoad, [
        '$nautical_ship_name',
      ]);
      forkJoin([historyVisits, historyActivities]).subscribe((results) => {
        this.loadHistoryActivities(results[0], results[1]);
      });
    } else {
      forkJoin([historyVisits]).subscribe((results) => {
        this.loadHistoryActivities(results[0], null);
      });
    }
  }

  private loadHistoryActivities(loadedByTime, loadedByActivities) {
    const loaded = {};

    // History page => 3. find all activities of the loaded visit/group objects
    if (loadedByTime && loadedByTime.hasResult) {
      _.each(
        loadedByTime.result,
        (v: CVVActiveVisitModel.CVVActiveVisitModel) => {
          // show only not active visits
          if (v.status !== CVVActiveVisitModel.CVVActiveVisitStatus.Active) {
            loaded[v.__Id] = v;
          }
        }
      );
    }

    if (loadedByActivities && loadedByActivities.hasResult) {
      _.each(
        loadedByActivities.result,
        (v: CVVActiveVisitModel.CVVActiveVisitModel) => {
          // show only not active visits
          if (v.status !== CVVActiveVisitModel.CVVActiveVisitStatus.Active) {
            if (!loaded[v.__Id]) {
              // this visit is not yet in the list
              loaded[v.__Id] = v;
            }
          }
        }
      );
    }

    this.loadNauticalVisitsForHistoryPage(_.values(loaded), null);
  }

  private loadNauticalVisitsForHistoryPage(
    preloadedCvvVisits: CVVActiveVisitModel.CVVActiveVisitModel[],
    preloadedActivities: CVVActivityModel[]
  ) {
    let portIds = [];
    if (preloadedCvvVisits && preloadedCvvVisits.length > 0) {
      portIds = _.pluck(
        _.filter(
          preloadedCvvVisits,
          (v) =>
            v.$nautical_portvisit_id !== undefined &&
            v.$nautical_portvisit_id !== null
        ),
        '$nautical_portvisit_id'
      );
    }

    if (portIds && portIds.length > 0) {
      this.visitData.getByIds(portIds, 0, 500).subscribe(
        (response) => {
          if (response.hasResult) {
            this.addLoadedVisits(response.result, false);
          }
        },
        (error) => {
          this.loadRelevantData(preloadedCvvVisits, preloadedActivities);
        },
        () => {
          this.loadRelevantData(preloadedCvvVisits, preloadedActivities);
        }
      );
    } else {
      this.loadRelevantData(preloadedCvvVisits, preloadedActivities);
    }
  }

  private loadMissingDataForActivePage(
    expectedVisitsResult,
    activeVisitsResult
  ) {
    let expectedVisitIds = [];
    if (expectedVisitsResult.hasResult) {
      this.addLoadedVisits(expectedVisitsResult.result, true);
      expectedVisitIds = _.pluck(expectedVisitsResult.result, 'id');
    }

    let loadedCvvVisits = [];
    const activeVisitIds = [];
    if (activeVisitsResult.hasResult) {
      loadedCvvVisits = activeVisitsResult.result;
      _.each(loadedCvvVisits, (v: CVVActiveVisitModel.CVVActiveVisitModel) => {
        if (v.$nautical_portvisit_id) {
          activeVisitIds.push(v.$nautical_portvisit_id);
        }
      });
    }

    const requests = [];
    const missingExpectedIds = _.difference(activeVisitIds, expectedVisitIds);
    if (missingExpectedIds && missingExpectedIds.length > 0) {
      // 2a. load missing portvisits where the visit id existis in the active visit but not in the expected visit
      // because there is no link between active visit and activity, so we need to load the visit first then use
      // the ucrn of the visit to load the activities
      requests.push(
        this.visitData.getListOverviewForPortByIds(missingExpectedIds, 0, 100)
      );
    }

    const missingCvvVisitIds = _.difference(expectedVisitIds, activeVisitIds);
    if (missingCvvVisitIds && missingCvvVisitIds.length > 0) {
      // 2b. load missing cvv visit where the visit id existis in the expected visit but not in the active cvv visit
      // possible the visit is already handled
      requests.push(
        this.cvvData.getNotActiveVisitsByVisitIds(missingCvvVisitIds)
      );
    }

    if (requests.length > 0) {
      forkJoin(requests).subscribe((results) => {
        let index = 0;
        if (missingExpectedIds && missingExpectedIds.length > 0) {
          index++;
          const visitsResult = results[0];

          if (visitsResult['hasResult']) {
            this.addLoadedVisits(visitsResult['result'], false);
          }
        }

        if (requests.length > index) {
          const cvvVisitsResult = results[index];
          if (cvvVisitsResult['hasResult']) {
            _.each(
              cvvVisitsResult['result'],
              (v: CVVActiveVisitModel.CVVActiveVisitModel) => {
                if (
                  v.status === CVVActiveVisitModel.CVVActiveVisitStatus.Active
                ) {
                  return;
                } // the status of cvv visit is active, so do nothing

                const loadedVisit: LoadedPortVisit =
                  // tslint:disable-next-line: no-use-before-declare
                  this.loadedVisits[LoadedPortVisit.getKeyByCvvVisit(v)];
                if (
                  loadedVisit &&
                  // tslint:disable-next-line: no-use-before-declare
                  CVVVisitModel.getKeyByPortVisit(
                    <NauticalVisitOverviewModel>loadedVisit.model
                    // tslint:disable-next-line: no-use-before-declare
                  ) === CVVVisitModel.getKey(v)
                ) {
                  // this expected visit is already handled, so it is not expected visit anymore
                  this.loadedVisits[
                    // tslint:disable-next-line: no-use-before-declare
                    LoadedPortVisit.getKeyByCvvVisit(v)
                  ].isExpectedVisit = false;
                }
              }
            );
          }
        }

        this.loadRelevantData(loadedCvvVisits, null); // load the activities and the positions
      });
    } else {
      this.loadRelevantData(loadedCvvVisits, null); // load the activities and the positions
    }
  }

  private loadRelevantData(
    preloadedCvvVisits: CVVActiveVisitModel.CVVActiveVisitModel[],
    preloadedActivities: CVVActivityModel[]
  ) {
    this.convertVisitsToCvvVisits(preloadedCvvVisits);
    this.loadPositions(_.map(this.cvvVisits, (v: CVVVisitModel) => v.mmsi));
    this.loadRequirements(
      _.map(this.loadedVisits, (o: LoadedPortVisit) => o.model.shipId)
    );

    if (preloadedActivities === null) {
      this.loadActivities();
    } else {
      this.groupActivities(preloadedActivities);
    }
  }

  private convertVisitsToCvvVisits(
    preloadedCvvVisits: CVVActiveVisitModel.CVVActiveVisitModel[]
  ) {
    _.each(preloadedCvvVisits, (cvvVisit) => {
      this.convertVisitToCvvVisit(cvvVisit, null);
    });
  }

  private convertVisitToCvvVisit(
    cvvVisit: CVVActiveVisitModel.CVVActiveVisitModel,
    portVisit: NauticalVisitOverviewModel
  ): CVVVisitModel {
    // tslint:disable-next-line: no-use-before-declare
    const key = CVVVisitModel.getKey(cvvVisit);

    // tslint:disable-next-line: no-use-before-declare
    const model = new CVVVisitModel();
    model.visitId = cvvVisit.__Id;
    model.key = key;
    model.activities = [];
    model.visit = cvvVisit;
    model.movementId = cvvVisit.$nautical_portmovement_id;

    const nauticalVisit: LoadedPortVisit =
      this.loadedVisits[
        portVisit
          ? // tslint:disable-next-line: no-use-before-declare
            LoadedPortVisit.getKey(portVisit)
          : // tslint:disable-next-line: no-use-before-declare
            LoadedPortVisit.getKeyByCvvVisit(cvvVisit)
      ];
    if (nauticalVisit) {
      model.mmsi =
        nauticalVisit.model.ship && nauticalVisit.model.ship.mmsi !== null
          ? nauticalVisit.model.ship.mmsi
          : null;
      model.nauticalVisit = JSON.parse(JSON.stringify(nauticalVisit.model));

      if (!model.movementId) {
        if (nauticalVisit.model['currentMovement']) {
          model.movementId = nauticalVisit.model['currentMovement'].id;
          model.movement = nauticalVisit.model['currentMovement'];
        } else {
          model.movementId = null;
        }
      } else if (nauticalVisit.model['portMovements']) {
        const movement = _.find(
          nauticalVisit.model['portMovements'],
          (m: NauticalMovementModel) => m.id === model.movementId
        );
        if (movement) {
          model.movement = movement;
        }
      } else if (
        nauticalVisit.model['currentMovement'] &&
        model.movementId === nauticalVisit.model['currentMovement'].id
      ) {
        model.movement = nauticalVisit.model['currentMovement'];
      }

      // current movement needs to hidden
      if (
        cvvVisit.$nautical_portmovement_id &&
        nauticalVisit.model['currentMovement'] &&
        cvvVisit.$nautical_portmovement_id ===
          nauticalVisit.model['currentMovement'].id
      ) {
        nauticalVisit.isExpectedVisit = false;
      }
    } else {
      const nv = new NauticalVisitOverviewModel();
      nv.shipId = cvvVisit.$nautical_ship_id;
      model.nauticalVisit = nv;
      model.nauticalVisit.ship = cvvVisit.$nautical_ship;
    }

    this.cvvVisits[cvvVisit.__Id] = model;
    return model;
  }

  private loadPositions(input: string[]) {
    if (!input || input.length === 0) {
      return;
    }

    const mmsis = _.uniq(_.without(input, null));
    if (mmsis && mmsis.length > 0) {
      this.mapData.getMarkerForMMSIs(mmsis).subscribe((markers) => {
        this.positions = _.indexBy(markers, 'mmsi');
      });
    }
  }

  private loadPositionByMmsi(mmsi: string) {
    if (mmsi && this.positions[mmsi] === undefined) {
      this.mapData.getMarkerForMMSI(mmsi).subscribe((marker) => {
        if (marker) {
          this.positions[marker.mmsi] = marker;
        }
      });
    }
  }

  private loadRequirements(ids: number[]) {
    if (!ids || ids.length === 0) {
      return;
    }

    _.each(ids, (id) => {
      this.shipRequirements[id] = new CVVShipRequirementsModel();
    });

    this.cvvData.getShipRequirementsByShipIds(ids).subscribe((response) => {
      if (response.hasResult && response.result) {
        _.each(response.result, (o: CVVShipRequirementsModel) => {
          this.shipRequirements[o.$nautical_ship_id] = o;
        });
      }
    });
  }

  private loadRequirementsByShipId(id: number) {
    if (!this.shipRequirements[id]) {
      this.cvvData.getShipRequirementsByShipIds([id]).subscribe((response) => {
        if (response.hasResult && response.result) {
          this.shipRequirements[id] = response.result[0];
        }
      });
    }
  }

  private loadActivities() {
    const groupIds = _.keys(this.cvvVisits);
    if (groupIds && groupIds.length > 0) {
      this.cvvData.getActivitiesByGroupIds(groupIds).subscribe(
        (response) => {
          if (response.hasResult && response.result) {
            _.each(
              _.groupBy(response.result, (a: CVVActivityModel) => a.groupId),
              (list, gId) => {
                if (this.cvvVisits[gId]) {
                  this.cvvVisits[gId].activities = _.sortBy(
                    list,
                    (a) => a.startsOn
                  ).reverse();
                }
              }
            );
          }
        },
        (error) => {
          this.finishLoadedData();
        },
        () => {
          this.finishLoadedData();
        }
      );
    } else {
      this.finishLoadedData();
    }
  }

  private groupActivities(preloadedActivities: CVVActivityModel[]) {
    const groups = _.groupBy(
      preloadedActivities,
      (a: CVVActivityModel) => a.groupId
    );
    _.each(groups, (list, gId) => {
      if (this.cvvVisits[gId]) {
        this.cvvVisits[gId].activities = _.sortBy(
          list,
          (a) => a.startsOn
        ).reverse();
      }
    });
    this.finishLoadedData();
  }

  private finishLoadedData() {
    this.resetListAndSort();
    this.isLoading = false;
  }

  private resetListAndSort() {
    this.resetActiveCvvVisits();
    this.resetIsExpectedVisit();
    this.sortVisits();
  }

  private resetActiveCvvVisits() {
    const searchTerm =
      !this.search.searchQuery || this.search.searchQuery.length <= 1
        ? null
        : this.search.searchQuery.toLowerCase().trim();
    const visits: CVVVisitModel[] = _.filter(
      _.values(this.cvvVisits),
      (v: CVVVisitModel) => {
        if (this.selectedList === 'active') {
          return (
            v.visit.status === CVVActiveVisitModel.CVVActiveVisitStatus.Active
          );
        } else {
          return (
            v.visit.status !== CVVActiveVisitModel.CVVActiveVisitStatus.Active
          );
        }
      }
    );

    // 2021-07-01 JV: hotfix, this is wrong
    //    visits = visits.filter(x => !x.movement || (x.movement && new Date(x.movement.eta) >= this.date));

    if (searchTerm === null) {
      this.activeCvvVisits = visits;
    } else {
      this.activeCvvVisits = [];
      _.each(visits, (v: CVVVisitModel) => {
        if (
          (v.nauticalVisit.ship &&
            v.nauticalVisit.ship.name &&
            v.nauticalVisit.ship.name.toLowerCase().indexOf(searchTerm) > -1) ||
          (v.nauticalVisit.portAgentName &&
            v.nauticalVisit.portAgentName.toLowerCase().indexOf(searchTerm) >
              -1) ||
          (v.nauticalVisit.referenceNumber &&
            v.nauticalVisit.referenceNumber.toLowerCase().indexOf(searchTerm) >
              -1)
        ) {
          this.activeCvvVisits.push(v);
        }
      });
    }
  }

  private resetIsExpectedVisit() {
    this.expectedVisits = [];
    const searchTerm =
      !this.search.searchQuery || this.search.searchQuery.length <= 1
        ? null
        : this.search.searchQuery.toLowerCase().trim();

    _.each(_.values(this.loadedVisits), (v: LoadedPortVisit) => {
      if (v.isExpectedVisit) {
        if (
          searchTerm === null &&
          v.model &&
          v.model.currentMovement &&
          ((v.model.currentMovement.eta &&
            new Date(v.model.currentMovement.eta) >= this.date) ||
            (v.model.currentMovement.etd &&
              new Date(v.model.currentMovement.etd) >= this.date))
        ) {
          this.expectedVisits.push(<NauticalVisitOverviewModel>v.model);
        } else if (
          (v.model.ship &&
            v.model.ship.name &&
            v.model.ship.name.toLowerCase().indexOf(searchTerm) > -1) ||
          (v.model.portAgentName &&
            v.model.portAgentName.toLowerCase().indexOf(searchTerm) > -1) ||
          (v.model.referenceNumber &&
            v.model.referenceNumber.toLowerCase().indexOf(searchTerm) > -1)
        ) {
          this.expectedVisits.push(<NauticalVisitOverviewModel>v.model);
        }
      }
    });
  }

  private sortVisits() {
    // sort expected visits
    this.expectedVisits = _.map(this.expectedVisits, (v) => {
      v.sort = new NauticalVisitSortModel();
      v.sort.datetime = this.sortQuery(v, null);
      v.sort.name = v.ship.name;
      return v;
    });

    if (this.selectedList === 'history') {
      this.expectedVisits = _.sortBy(
        this.expectedVisits,
        (v) => v.sort[this.sortField]
      ).reverse();
    } else {
      this.expectedVisits = _.sortBy(
        this.expectedVisits,
        (v) => v.sort[this.sortField]
      );
    }

    // sort active visits
    this.activeCvvVisits = _.map(this.activeCvvVisits, (v: CVVVisitModel) => {
      v.sort = new NauticalVisitSortModel();
      v.sort.datetime = this.sortQuery(null, v.visitId);
      v.sort.name = v.nauticalVisit.ship
        ? v.nauticalVisit.ship.name
        : v.visit.$nautical_ship
        ? v.visit.$nautical_ship.name
        : null;
      return v;
    });

    if (this.selectedList === 'history') {
      this.activeCvvVisits = _.sortBy(
        this.activeCvvVisits,
        (v) => v.sort[this.sortField]
      ).reverse();
    } else {
      this.activeCvvVisits = _.sortBy(
        this.activeCvvVisits,
        (v) => v.sort[this.sortField]
      );
    }
  }

  private sortQuery(visit: NauticalVisitOverviewModel, key: string) {
    if (this.selectedList === 'history') {
      let date = null;
      const cvvVisit: CVVVisitModel = this.cvvVisits[key];
      if (cvvVisit) {
        // first sort by ATA/ETA of the movement
        if (cvvVisit.movement) {
          date = cvvVisit.movement.ata
            ? cvvVisit.movement.ata
            : cvvVisit.movement.eta;
        }

        // if not found, get the start time of the activity
        if (
          date === null &&
          cvvVisit.activities &&
          cvvVisit.activities.length > 0
        ) {
          date = _.last(
            _.sortBy(cvvVisit.activities, (a) => a.startsOn)
          ).startsOn;
        }
      }

      return date;
    } else if (visit) {
      return visit.currentMovement
        ? visit.currentMovement.etd
          ? visit.currentMovement.etd
          : visit.currentMovement.eta
        : visit.eta;
    } else {
      return null;
    }
  }

  private saveShipRequirements(model: CVVShipRequirementsModel) {
    if (model.__Id) {
      this.cvvData.updateShipRequirements(model).subscribe((response) => {
        if (response.hasResult && response.result) {
          this.shipRequirements[model.$nautical_ship_id] = response.result;

          this.notificationService.showSuccess(
            this.translateService.instant(
              'customers.cvv.shipRequirements.saved'
            ),
            this.translateService.instant('shared.terms.success')
          );
        }
      });
    } else {
      this.cvvData.addShipRequirements(model).subscribe((response) => {
        if (response.hasResult && response.result) {
          this.shipRequirements[model.$nautical_ship_id] = response.result;

          this.notificationService.showSuccess(
            this.translateService.instant(
              'customers.cvv.shipRequirements.saved'
            ),
            this.translateService.instant('shared.terms.success')
          );
        }
      });
    }
  }

  private updateRequirements(
    nauticalVisit: NauticalVisitOverviewModel,
    modelToSave: CVVActiveVisitModel.CVVActiveVisitModel,
    source: CVVActiveVisitModel.CVVActiveVisitModel,
    saveDataToShip: boolean
  ) {
    this.cvvData.updateVisit(modelToSave).subscribe((saveVisitResponse) => {
      if (saveVisitResponse.hasResult && saveVisitResponse.result) {
        this.cvvVisits[saveVisitResponse.result.__Id].visit =
          saveVisitResponse.result;
        source = saveVisitResponse.result;

        this.notificationService.showSuccess(
          this.translateService.instant(
            'customers.cvv.currentRequirements.saved'
          ),
          this.translateService.instant('shared.terms.success')
        );
      }
    });

    if (saveDataToShip === true) {
      let shipReq: CVVShipRequirementsModel;
      if (this.shipRequirements[nauticalVisit.shipId]) {
        shipReq = _.clone(this.shipRequirements[nauticalVisit.shipId]);
      } else {
        shipReq = new CVVShipRequirementsModel();
      }

      if (!shipReq.$nautical_ship_id && nauticalVisit.shipId) {
        shipReq.$nautical_ship_id = nauticalVisit.shipId;
      }

      shipReq.heavyEquipment = modelToSave.heavyEquipment;
      shipReq.motorboat = modelToSave.motorboat;
      shipReq.persons = modelToSave.persons;
      shipReq.remarks = modelToSave.remarks;
      shipReq.winches = modelToSave.winches;

      this.saveShipRequirements(shipReq);
    }
  }

  addIncomingTimeWarning(incoming: { index: number; warning: string }) {
    if (
      this.incomingTimeWarnings.findIndex((x) => x.index === incoming.index) ===
      -1
    )
      this.incomingTimeWarnings.push(incoming);
  }

  addOutgoingTimeWarning(outgoing: { index: number; warning: string }) {
    if (
      this.outgoingTimeWarnings.findIndex((x) => x.index === outgoing.index) ===
      -1
    )
      this.outgoingTimeWarnings.push(outgoing);
  }

  //#endregion
}

export class CVVVisitModel {
  key: string; // shipId_visitId_movementId
  visitId: string;
  movementId: number;
  activities: CVVActivityModel[];
  mmsi: string;
  visit: CVVActiveVisitModel.CVVActiveVisitModel;
  nauticalVisit: NauticalVisitBaseModel; // NauticalVisitOverviewModel (active page)/ NauticalVisitDetailsModel (history page)
  sort: NauticalVisitSortModel;
  movement: NauticalMovementModel;

  showHistory: boolean;
  isLoadingHistory: boolean;
  historyActivities: CVVActivityModel[];

  static getKey(cvvVisit: CVVActiveVisitModel.CVVActiveVisitModel) {
    return `${cvvVisit.$nautical_ship_id}_${
      cvvVisit.$nautical_portvisit_id ? cvvVisit.$nautical_portvisit_id : 0
    }_${
      cvvVisit.$nautical_portmovement_id
        ? cvvVisit.$nautical_portmovement_id
        : 0
    }`;
  }

  static getKeyByPortVisit(visit: NauticalVisitOverviewModel) {
    return `${visit.shipId}_${visit.id}_${
      visit.currentMovement ? visit.currentMovement.id : 0
    }`;
  }

  static getKeyByShipId(shipId: number) {
    return `${shipId}_${0}_${0}`;
  }
}

export class LoadedPortVisit {
  isExpectedVisit: boolean;
  model: NauticalVisitBaseModel;

  static getKey(visit: NauticalVisitBaseModel) {
    return `${visit.shipId}_${visit.id}`; // shipId_visitId
  }

  static getKeyByCvvVisit(cvvVisit: CVVActiveVisitModel.CVVActiveVisitModel) {
    return `${cvvVisit.$nautical_ship_id}_${
      cvvVisit.$nautical_portvisit_id ? cvvVisit.$nautical_portvisit_id : 0
    }`;
  }
}
