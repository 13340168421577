import { NauticalVisitFlowMarkerModel } from './nautical-visit-flow-marker.model';
import * as moment from 'moment';
import * as _ from 'underscore';

export class NauticalVisitFlowModel {
  // The short text that can be used to indicate the end of the flow
  endDisplayTextShort: string;
  // The long text that can be used to indicate the end of the flow
  endDisplayTextLong: string;
  // The text that can be used as a tooltip for the end of the flow
  endDisplayTooltip: string;
  // The individual markers for the flow
  markers: NauticalVisitFlowMarkerModel[] = [];
  // The marker of the ship (different styling and positioning, that's why it's seperate)
  shipMarker: NauticalVisitFlowMarkerModel;
  // The short text that can be used to indicate the start of the flow
  startDisplayTextShort: string;
  // The long text that can be used to indicate the start of the flow
  startDisplayTextLong: string;
  // The text that can be used as a tooltip for the start of the flow
  startDisplayTooltip: string;

  groupedByDate() {
    if (!this.markers || this.markers.length === 0) {
      return [];
    }

    const groupedObject = _.groupBy(this.markers, (marker) => {
      return moment(marker.actualDate).format('YYYY-MM-DD');
    });

    const result = [];

    for (const prop in groupedObject) {
      result.push({
        dateValue: prop,
        markers: groupedObject[prop],
      });
    }

    return result;
  }
}
