import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  NgbTypeahead,
  NgbTypeaheadSelectItemEvent,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@seahorse/common';
import { merge, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import * as _ from 'underscore';

import { CompanyModel } from '@seahorse/domain';
import { CompanyDataService } from '@seahorse/domain';

@Component({
  selector: 'ca-company-picker',
  templateUrl: './company-picker.component.html',
  styleUrls: ['./company-picker.component.scss'],
})
export class CompanyPickerComponent {
  @Input() showInline = false;
  @Input() title: string;
  @Input() selectedCompany: CompanyModel;
  @Input() selectedCompanyLoading: boolean;
  @Input() isRequired: boolean;
  @Input() allowTextOnly: boolean;
  @Input() submitted = false;
  @Input() tagFilter?: string;
  @Input() hideMissingDebtor: boolean;
  @Input() isDebtorPicker: boolean;
  @Input() isReadOnly: boolean;

  @Output() selectedCompanyChange!: EventEmitter<CompanyModel>;

  @ViewChild('companyTypeahead', { static: true })
  companyTypeahead: NgbTypeahead;

  companiesLoading: boolean;
  companiesLoadingFailed: boolean;
  focus$: Subject<string>;
  click$: Subject<string>;

  constructor(
    private translate: TranslateService,
    private companyData: CompanyDataService,
    private notification: NotificationService
  ) {
    this.title = this.translate.instant('companies.pickerDialog.selectCompany');
    this.selectedCompany = null;
    this.selectedCompanyLoading = false;
    this.isRequired = false;
    this.allowTextOnly = false;
    this.selectedCompanyChange = new EventEmitter<CompanyModel>();
    this.companiesLoading = false;
    this.companiesLoadingFailed = false;
    this.focus$ = new Subject<string>();
    this.click$ = new Subject<string>();
    this.hideMissingDebtor = false;
    this.isDebtorPicker = false;
    this.isReadOnly = false;
  }

  itemSelected(event: NgbTypeaheadSelectItemEvent) {
    if (this.isDebtorPicker && !event.item.financialId) {
      event.preventDefault();
      return;
    }

    if (event.item) {
      this.selectedCompanyChange.emit(event.item);
    }
  }

  clearTagFilter() {
    this.tagFilter = undefined;
  }

  companyFormatter = (company: CompanyModel): string => {
    if (
      company !== undefined &&
      company !== null &&
      company.name !== undefined &&
      company.name !== null
    ) {
      return company.name.toUpperCase();
    } else {
      return '';
    }
  };

  searchCompany = (input$: Observable<string>): any => {
    const debouncedText$ = input$.pipe(
      debounceTime(300),
      distinctUntilChanged()
    );

    const clicksWithClosedPopup$ = this.click$.pipe(
      filter(() => !this.companyTypeahead.isPopupOpen())
    );
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      tap(() => (this.companiesLoading = true)),
      switchMap((s) =>
        this.companyData.search(s, 0, 100, undefined, this.tagFilter).pipe(
          tap(() => (this.companiesLoadingFailed = false)),
          map((r) => {
            if (r.result) {
              if (this.isDebtorPicker) {
                const companies = [];
                r.result.forEach((c) => {
                  // loop through each company financial and add a new company model into the array
                  if (c.companyFinancials && c.companyFinancials.length > 0) {
                    c.companyFinancials.forEach((cf) => {
                      const cloneCompany = Object.assign({}, c);
                      cloneCompany.financialId = cf.id;
                      cloneCompany.name = `${c.name} (${cf.debtorNumber})`;
                      companies.push(cloneCompany);
                    });
                  } else if (!this.hideMissingDebtor) {
                    c.financialId = null;
                    companies.push(c);
                  }
                });
                return companies;
              } else if (this.hideMissingDebtor)
                return r.result.filter(
                  (c) => c.companyFinancials && c.companyFinancials.length > 0
                );
            }

            return r.result;
          }),
          catchError((e) => {
            this.companiesLoadingFailed = true;

            this.notification.showError(
              e,
              this.translate.instant('shared.terms.failed')
            );
            return of([]);
          })
        )
      ),
      tap(() => (this.companiesLoading = false))
    );
  };

  setNull() {
    this.selectedCompanyChange.emit(null);
  }
}
