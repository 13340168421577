import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'underscore';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { ResultWrapper } from '@seahorse/common';
import { NotificationService } from '@seahorse/common';
import { NauticalShipDataService } from '@seahorse/domain';
import { NauticalShipModel } from '@seahorse/domain';
import { ShipSearchModel } from '@seahorse/domain';
import { NauticalVisitDataService } from '@seahorse/domain';
import { NauticalMovementModel } from '@seahorse/domain';
import { NauticalVisitModel } from '@seahorse/domain';
import { NauticalVisitOverviewModel } from '@seahorse/domain';

@Component({ templateUrl: 'nautical-movement-picker-dialog.component.html' })
export class NauticalMovementPickerDialogComponent implements OnInit {
  @Input() nauticalMovement: NauticalMovementModel;
  @Input() nauticalMovementRequired?: boolean;

  @Input() set nauticalVisit(v: NauticalVisitModel) {
    if (v) {
      this._nauticalVisit = _.clone(v);
      this.nauticalVisitId = v.id;

      const nauticalShipId: string = v.ship
        ? v.ship.id
        : v.shipId
        ? v.shipId.toString()
        : null;

      if (!this.nauticalShip) {
        if (nauticalShipId) {
          this.nauticalVisitsLoading = true;

          this.nauticalVisitData
            .getListOverviewForShip(nauticalShipId, null, null, false, 0, 10)
            .subscribe(
              (r: ResultWrapper<NauticalVisitOverviewModel[]>) => {
                if (r.hasResult) {
                  this.nauticalVisits = r.result;
                  this.nauticalVisitsCount = r.count;
                } else {
                  this.notification.showError(
                    _.pluck(r.messages, 'message').join('\n'),
                    this.translate.instant('shared.terms.failed')
                  );
                }
              },
              (e) => {
                this.notification.showError(
                  _.pluck(e.error.messages, 'message').join('\n'),
                  this.translate.instant('shared.terms.failed')
                );
              },
              () => (this.nauticalVisitsLoading = false)
            );

          if (v.ship) {
            this.nauticalShip = v.ship;
          } else {
            this.nauticalShipLoading = true;

            this.nauticalShipData.getById(v.shipId.toString()).subscribe(
              (r: NauticalShipModel) => {
                if (r) {
                  this.nauticalShip = r;
                } else {
                  this.nauticalShipLoadingFailed = true;
                }
              },
              (e) => {
                this.notification.showError(
                  _.pluck(e.error.messages, 'message').join('\n'),
                  this.translate.instant('shared.terms.failed')
                );
              },
              () => (this.nauticalShipLoading = false)
            );
          }
        }
      }
    } else {
      this._nauticalVisit = null;
    }
  }
  get nauticalVisit() {
    return this._nauticalVisit;
  }

  @Input() nauticalVisitRequired?: boolean;
  @Input() nauticalVisitPickable?: boolean;
  @Input() nauticalVisitRetrieval?: boolean;

  @Input() set nauticalShip(s: NauticalShipModel) {
    if (s) {
      this._nauticalShip = _.clone(s);
      this.nauticalVisitId = this.matchNauticalVisitId()
        ? this.nauticalVisit.id
        : null;
      this.nauticalVisitsLoading = true;
      this.nauticalVisits = [];
      this.nauticalVisitsCount = null;

      this.nauticalVisitData
        .getListOverviewForShip(s.id, null, null, false, 0, 10)
        .subscribe(
          (r: ResultWrapper<NauticalVisitOverviewModel[]>) => {
            if (r.hasResult) {
              this.nauticalVisits = r.result;
              this.nauticalVisitsCount = r.count;

              if (r.count == 1 && !this.nauticalVisitId) {
                this.nauticalVisitId = _.first(r.result).id;
              }
            } else {
              this.notification.showError(
                _.pluck(r.messages, 'message').join('\n'),
                this.translate.instant('shared.terms.failed')
              );
            }
          },
          (e) => {
            this.notification.showError(
              _.pluck(e.error.messages, 'message').join('\n'),
              this.translate.instant('shared.terms.failed')
            );
          },
          () => (this.nauticalVisitsLoading = false)
        );
    } else {
      this._nauticalShip = null;
    }
  }
  get nauticalShip() {
    return this._nauticalShip;
  }

  @Input() nauticalShipRequired?: boolean;
  @Input() nauticalShipPickable?: boolean;
  @Input() nauticalShipRetrieval?: boolean;
  @Input() title?: string;

  @Output() onConfirm: EventEmitter<{
    nauticalMovement: NauticalMovementModel;
    nauticalVisit?: NauticalVisitModel;
    nauticalShip?: NauticalShipModel;
  }>;

  private _nauticalVisit: NauticalVisitModel;
  private _nauticalShip: NauticalShipModel;

  nauticalVisitId: NauticalVisitModel['id'];
  nauticalVisitLoading: boolean;
  nauticalVisitLoadingFailed: boolean;
  nauticalVisits: NauticalVisitOverviewModel[];
  nauticalVisitsCount: number;
  nauticalVisitsLoading: boolean;
  nauticalShipLoading: boolean;
  nauticalShipLoadingFailed: boolean;
  nauticalShipsLoading: boolean;
  nauticalShipsLoadingFailed: boolean;

  constructor(
    private activeModal: NgbActiveModal,
    private translate: TranslateService,
    private notification: NotificationService,
    private nauticalVisitData: NauticalVisitDataService,
    private nauticalShipData: NauticalShipDataService
  ) {
    this.nauticalMovement = null;
    this.nauticalMovementRequired = true;
    this.nauticalVisit = null;
    this.nauticalVisitRequired = false;
    this.nauticalVisitPickable = false;
    this.nauticalVisitRetrieval = false;
    this.nauticalShip = null;
    this.nauticalShipRequired = false;
    this.nauticalShipPickable = false;
    this.nauticalShipRetrieval = false;
    this.title = this.translate.instant('nautical.movement.pickerDialog.title');
    this.onConfirm = new EventEmitter<{
      nauticalMovement: NauticalMovementModel;
    }>();
    this._nauticalVisit = null;
    this._nauticalShip = null;
    this.nauticalVisitId = null;
    this.nauticalVisitLoading = false;
    this.nauticalVisitLoadingFailed = false;
    this.nauticalVisits = [];
    this.nauticalVisitsCount = null;
    this.nauticalVisitsLoading = false;
    this.nauticalShipLoading = false;
    this.nauticalShipLoadingFailed = false;
    this.nauticalShipsLoading = false;
    this.nauticalShipsLoadingFailed = false;
  }

  ngOnInit() {
    if (this.nauticalShipPickable && !this.nauticalVisitPickable) {
      this.nauticalVisitPickable = true;
    }
  }

  nauticalMovementFormatter = (
    nauticalMovement: NauticalMovementModel
  ): string => {
    let f =
      nauticalMovement.portWayPointFrom &&
      nauticalMovement.portWayPointFrom.name
        ? nauticalMovement.portWayPointFrom.name
        : this.translate.instant('shared.terms.unknown');
    f +=
      nauticalMovement.portWayPointTo && nauticalMovement.portWayPointTo.name
        ? ` > ${nauticalMovement.portWayPointTo.name}`
        : ` > ${this.translate.instant('shared.terms.unknown')}`;

    const a = [];

    if (nauticalMovement.ata) {
      a.push(
        `${this.translate.instant('nautical.terms.ata')}: ${moment(
          nauticalMovement.ata
        ).format('DD-MM-YYYY HH:mm')}`
      );
    } else if (nauticalMovement.eta) {
      a.push(
        `${this.translate.instant('nautical.terms.eta')}: ${moment(
          nauticalMovement.eta
        ).format('DD-MM-YYYY HH:mm')}`
      );
    }
    if (nauticalMovement.atd) {
      a.push(
        `${this.translate.instant('nautical.terms.atd')}: ${moment(
          nauticalMovement.atd
        ).format('DD-MM-YYYY HH:mm')}`
      );
    } else if (nauticalMovement.etd) {
      a.push(
        `${this.translate.instant('nautical.terms.etd')}: ${moment(
          nauticalMovement.etd
        ).format('DD-MM-YYYY HH:mm')}`
      );
    }
    if (a.length) {
      f += ` (${a.join(', ')})`;
    }

    return f;
  };

  nauticalVisitFormatter = (
    nauticalVisit: NauticalVisitModel | NauticalVisitOverviewModel
  ): string => {
    let f = nauticalVisit.referenceNumber;
    const a = [];

    if (nauticalVisit.ata) {
      a.push(
        `${this.translate.instant('nautical.terms.ata')}: ${moment(
          nauticalVisit.ata
        ).format('DD-MM-YYYY HH:mm')}`
      );
    } else if (nauticalVisit.eta) {
      a.push(
        `${this.translate.instant('nautical.terms.eta')}: ${moment(
          nauticalVisit.eta
        ).format('DD-MM-YYYY HH:mm')}`
      );
    }
    if (nauticalVisit.atd) {
      a.push(
        `${this.translate.instant('nautical.terms.atd')}: ${moment(
          nauticalVisit.atd
        ).format('DD-MM-YYYY HH:mm')}`
      );
    } else if (nauticalVisit.etd) {
      a.push(
        `${this.translate.instant('nautical.terms.etd')}: ${moment(
          nauticalVisit.etd
        ).format('DD-MM-YYYY HH:mm')}`
      );
    }
    if (a.length) {
      f += ` (${a.join(', ')})`;
    }

    return f;
  };

  nauticalShipFormatter = (nauticalShip: NauticalShipModel): string =>
    nauticalShip.name ? nauticalShip.name.toUpperCase() : '';

  nauticalShipsFormatter = (nauticalShip: NauticalShipModel): string => {
    let f = nauticalShip.name.toUpperCase();
    const a = [];

    if (nauticalShip.shipType && nauticalShip.shipType.name) {
      a.push(nauticalShip.shipType.name);
    }
    if (nauticalShip.callSign) {
      a.push(
        `${this.translate.instant('nautical.ship.model.callSign')}: ${
          nauticalShip.callSign
        }`
      );
    }
    if (nauticalShip.fisheryNumber) {
      a.push(nauticalShip.fisheryNumber);
    }
    if (nauticalShip.imo) {
      a.push(
        `${this.translate.instant('nautical.ship.model.imo')}: ${
          nauticalShip.imo
        }`
      );
    } else if (nauticalShip.eni) {
      a.push(
        `${this.translate.instant('nautical.ship.model.eni')}: ${
          nauticalShip.eni
        }`
      );
    }
    if (a.length) {
      f += ` (${a.join(', ')})`;
    }

    return f;
  };

  searchNauticalShip = (input$: Observable<string>): any =>
    input$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.nauticalShipsLoading = true)),
      switchMap((s) =>
        this.nauticalShipData.find(new ShipSearchModel(s), 0, 100).pipe(
          tap(() => (this.nauticalShipsLoadingFailed = false)),
          map((r) => r.result),
          catchError((e) => {
            this.nauticalShipsLoadingFailed = true;

            this.notification.showError(
              e,
              this.translate.instant('shared.terms.failed')
            );
            return of([]);
          })
        )
      ),
      tap(() => (this.nauticalShipsLoading = false))
    );

  matchNauticalVisitId = (): boolean =>
    this.nauticalVisit &&
    this.nauticalShip &&
    ((this.nauticalVisit.ship &&
      this.nauticalVisit.ship.id == this.nauticalShip.id) ||
      (this.nauticalVisit.shipId &&
        this.nauticalVisit.shipId.toString() == this.nauticalShip.id));

  setNull(attribute: string) {
    switch (attribute) {
      case 'nauticalMovement': {
        this.nauticalMovement = null;
        break;
      }

      case 'nauticalVisit': {
        if (this.nauticalVisitPickable) {
          this.nauticalMovement = null;
          this.nauticalVisit = null;
          this.nauticalVisitId = null;
        }
        break;
      }

      case 'nauticalShip': {
        if (this.nauticalShipPickable) {
          this.nauticalMovement = null;
          this.nauticalVisit = null;
          this.nauticalVisitId = null;
          this.nauticalVisits = [];
          this.nauticalVisitsCount = null;
          this.nauticalShip = null;
        }
        break;
      }
    }
  }

  setNauticalMovementToCurrent = (nauticalMovement: NauticalMovementModel) =>
    (this.nauticalMovement = nauticalMovement ? nauticalMovement : null);

  pickedNauticalMovement() {
    if (this.nauticalMovement) {
      if (this.nauticalVisitRetrieval) {
        if (this.nauticalVisit) {
          if (this.nauticalShipRetrieval) {
            if (this.nauticalShip) {
              this.activeModal.close(
                this.onConfirm.emit({
                  nauticalMovement: this.nauticalMovement,
                  nauticalVisit: this.nauticalVisit,
                  nauticalShip: this.nauticalShip,
                })
              );
            } else if (!this.nauticalShipRequired) {
              this.activeModal.close(
                this.onConfirm.emit({
                  nauticalMovement: this.nauticalMovement,
                  nauticalVisit: this.nauticalVisit,
                  nauticalShip: null,
                })
              );
            }
          } else {
            this.activeModal.close(
              this.onConfirm.emit({
                nauticalMovement: this.nauticalMovement,
                nauticalVisit: this.nauticalVisit,
              })
            );
          }
        } else if (!this.nauticalVisitRequired) {
          this.activeModal.close(
            this.onConfirm.emit({
              nauticalMovement: this.nauticalMovement,
              nauticalVisit: this.nauticalVisit,
              nauticalShip: null,
            })
          );
        }
      } else if (this.nauticalShipRetrieval) {
        if (this.nauticalShip) {
          this.activeModal.close(
            this.onConfirm.emit({
              nauticalMovement: this.nauticalMovement,
              nauticalShip: this.nauticalShip,
            })
          );
        } else if (!this.nauticalShipRequired) {
          this.activeModal.close(
            this.onConfirm.emit({
              nauticalMovement: this.nauticalMovement,
              nauticalShip: null,
            })
          );
        }
      }
    }
  }

  pickedNauticalVisit() {
    if (this.nauticalVisitPickable && this.nauticalVisitId) {
      this.nauticalVisitLoading = true;

      this.nauticalVisitData.getById(this.nauticalVisitId).subscribe(
        (r: ResultWrapper<NauticalVisitModel>) => {
          if (r.hasResult) {
            this.nauticalVisit = r.result;
          } else {
            this.nauticalVisitLoadingFailed = true;

            this.notification.showError(
              _.pluck(r.messages, 'message').join('\n'),
              this.translate.instant('shared.terms.failed')
            );
          }
        },
        (e) => {
          this.nauticalVisitLoadingFailed = true;

          this.notification.showError(
            _.pluck(e.error.messages, 'message').join('\n'),
            this.translate.instant('shared.terms.failed')
          );
        },
        () => (this.nauticalVisitLoading = false)
      );
    }
  }

  pickedNauticalShip() {
    if (this.nauticalShipPickable && this.nauticalShip) {
      this.nauticalVisitId = this.matchNauticalVisitId()
        ? this.nauticalVisit.id
        : null;
      this.nauticalVisitsLoading = true;
      this.nauticalVisits = [];
      this.nauticalVisitsCount = null;

      this.nauticalVisitData
        .getListOverviewForShip(this.nauticalShip.id, null, null, false, 0, 10)
        .subscribe(
          (r: ResultWrapper<NauticalVisitOverviewModel[]>) => {
            if (r.hasResult) {
              this.nauticalVisits = r.result;
              this.nauticalVisitsCount = r.count;

              if (r.count == 1 && !this.nauticalVisitId) {
                this.nauticalVisitId = _.first(r.result).id;
              }
            } else {
              this.notification.showError(
                _.pluck(r.messages, 'message').join('\n'),
                this.translate.instant('shared.terms.failed')
              );
            }
          },
          (e) => {
            this.notification.showError(
              _.pluck(e.error.messages, 'message').join('\n'),
              this.translate.instant('shared.terms.failed')
            );
          },
          () => (this.nauticalVisitsLoading = false)
        );
    }
  }
}
