import { Injectable } from '@angular/core';
import { ResultWrapper } from '@seahorse/common';
import { DataContextService } from '@seahorse/common';
import { Observable } from 'rxjs';

import { DistributionServiceModel } from '../models/distribution-service.model';
import { DataService } from '@seahorse/domain';

@Injectable({
  providedIn: 'root',
})
export class DistributionServiceDataService
  implements DataService<DistributionServiceModel>
{
  constructor(private dataContext: DataContextService) {}

  getById(id: number): Observable<ResultWrapper<DistributionServiceModel>> {
    return this.dataContext.get<DistributionServiceModel>(
      `contentdistribution/distributionservice/${id}`
    );
  }

  add(
    model: DistributionServiceModel
  ): Observable<ResultWrapper<DistributionServiceModel>> {
    return this.dataContext.post<DistributionServiceModel>(
      `contentdistribution/distributionservice`,
      model
    );
  }

  update(
    model: DistributionServiceModel
  ): Observable<ResultWrapper<DistributionServiceModel>> {
    return this.dataContext.put<DistributionServiceModel>(
      `contentdistribution/distributionservice`,
      model
    );
  }

  delete(id: number): Observable<ResultWrapper<DistributionServiceModel>> {
    return this.dataContext.delete<DistributionServiceModel>(
      `contentdistribution/distributionservice/${id}`
    );
  }

  getAll(): Observable<ResultWrapper<DistributionServiceModel[]>> {
    return this.dataContext.get<DistributionServiceModel[]>(
      `contentdistribution/distributionservice`
    );
  }
}
