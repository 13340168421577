export class AttributeModel {
  // TODO: remove this class; use FieldAttributeModel in layout module
  name: string;
  value: any;
  required: boolean;
  type?: string;
  suggestion?: any;

  constructor(override?: Partial<AttributeModel>) {
    this.name = null;
    this.value = null;
    this.required = false;
    this.type = null;
    this.suggestion = null;

    if (override) {
      Object.assign(this, override);
    }
  }
}
