import { inject, Injectable, Injector, Type } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ComponentDescriptor } from '@seahorse/common';
import { from, Observable } from 'rxjs';

import { ModalBuilder } from './modal.builder';
import { ModalOptions, ModalResponse } from './modal.model';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private _injector = inject(Injector);
  private _ngbModal = inject(NgbModal);

  buildModal() {
    return new ModalBuilder(this._injector);
  }

  openModal<TComponent>(
    component: Type<TComponent>,
    props?: { [key in keyof TComponent]?: any },
    modalOptions?: NgbModalOptions
  ): Observable<ModalResponse> {
    const modal = this._ngbModal.open(
      component,
      modalOptions ?? { backdrop: 'static' }
    );

    Object.assign(modal.componentInstance, props);

    return from(modal.result);
  }

  openComponentModal<TComponent>(
    descriptor: ComponentDescriptor<TComponent>,
    title: string,
    options?: ModalOptions
  ) {
    return this.buildModal()
      .withTitle(title)
      .withComponentBody(descriptor)
      .open(options);
  }

  closeModals() {
    this._ngbModal?.dismissAll();
  }
}
