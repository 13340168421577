import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ProxyServices } from '../../core/services/proxy.service';

@Pipe({ name: 'fieldOperator' })
export class FieldOperatorPipe implements PipeTransform {
  constructor(
    private proxy: ProxyServices,
    private translateService: TranslateService
  ) {}

  transform(input: any): string {
    const result: string = null;
    if (input !== null && input !== undefined) {
      switch (input.toString()) {
        case '0':
          return this.translateService.instant('shared.operators.equals');
        case '1':
          return this.translateService.instant('shared.operators.contains');
        case '2':
          return this.translateService.instant('shared.operators.startsWith');
        case '3':
          return this.translateService.instant('shared.operators.endsWith');
        case '4':
          return this.translateService.instant('shared.operators.greaterThan');
        case '5':
          return this.translateService.instant('shared.operators.lesserThan');
        case '6':
          return this.translateService.instant('shared.operators.inArray');
        case '7':
          return this.translateService.instant('shared.operators.between');
        case '8':
          return this.translateService.instant('shared.operators.notEquals');
      }
    }

    return result;
  }
}
