import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  NgbActiveModal,
  NgbModal, NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';

import { CVVActivityModel } from '../../models/cvv-activity.model';

import { NauticalShipDataService, NauticalShipModel, NauticalVisitDataService, NauticalVisitOverviewModel, ShipSearchModel } from '@seahorse/domain';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import {
  catchError, debounceTime,
  distinctUntilChanged, map, switchMap, tap
} from 'rxjs/operators';
import * as _ from 'underscore';
import { ConfirmComponent } from '../../../../layout/components/dialogs/confirm.component';
import { CVVAddActivityResultModel } from '../../models/cvv-add-activity-result.model';
import { CVVEventModel } from '../../models/cvv-event.model';
import { CVVActivityLogicService } from '../../services/cvv-activity-logic.service';

@Component({
  selector: 'ca-custom-cvv-add-activity-modal',
  templateUrl: 'add-activity-modal.component.html',
})
export class CVVAddActivityModal {
  private _activity: CVVActivityModel = null;
  private _preseletedShipId: string = null;
  dropdownSettings = {};

  errors: string[] = [];
  loadingVisits = false;
  searching = false;
  searchFailed = false;
  tempShip: any = null;
  tempVisit: any = null;
  shipVisits: NauticalVisitOverviewModel[] = [];

  @Input() set activity(value: CVVActivityModel) {
    this._activity = value;
    this.setActivityDefaults();
  }
  get activity(): CVVActivityModel {
    return this._activity;
  }

  @Input() set selectedShipId(value: string) {
    this._preseletedShipId = value;
    this.loadShipById();
  }

  @Output() onItemClicked: EventEmitter<CVVEventModel>;

  constructor(
    public modal: NgbActiveModal,
    public cvvActivityLogic: CVVActivityLogicService,
    private nauticalShipData: NauticalShipDataService,
    private nauticalVisitData: NauticalVisitDataService,
    private ngbModal: NgbModal
  ) {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'name',
      textField: 'name',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };

    this.onItemClicked = new EventEmitter<CVVEventModel>();
  }

  addMinutes(num: number, property: string) {
    if (this.activity[property]) {
      this.activity[property] = moment(
        this.activity[property],
        'DD-MM-YYYY HH:mm'
      )
        .add(num, 'm')
        .format('DD-MM-YYYY HH:mm');
    }
  }

  dismiss(result) {
    this.modal.dismiss(result);
  }

  displayShipFormatter = (item: NauticalShipModel) => item.name.toUpperCase();

  isActivityType(types: number[]) {
    if (
      this.activity.activityType === undefined ||
      this.activity.activityType === null
    ) {
      return false;
    }

    return types.indexOf(+this.activity.activityType) > -1;
  }

  getActivityTypes() {
    const types = _.values(this.cvvActivityLogic.cvvActivityTypes);
    return _.sortBy(types, 'sort');
  }

  loadShipById() {
    if (this._preseletedShipId) {
      this.searching = true;
      this.nauticalShipData.getById(this._preseletedShipId).subscribe(
        (result) => {
          if (result) {
            this.tempShip = result;
            this.shipVisits = [];
            this.loadVisitsForShip(this.tempShip.id);
          }
        },
        (e) => {
          this.searching = false;
        },
        () => {
          this.searching = false;
        }
      );
    }
  }

  loadVisitsForShip(vesselId) {
    this.loadingVisits = true;
    this.nauticalVisitData
      .getMostRelevantPortVisitOverviewForShip(vesselId, 10)
      .subscribe((result) => {
        this.shipVisits = result.result;
        this.loadingVisits = false;
      });
  }

  save() {
    if (this.activity.startsOnDisplay) {
      this.activity.startsOn = moment
        .utc(this.activity.startsOnDisplay, 'DD-MM-YYYY HH:mm')
        .toDate();
    }

    if (this.activity.endsOnDisplay) {
      this.activity.endsOn = moment
        .utc(this.activity.endsOnDisplay, 'DD-MM-YYYY HH:mm')
        .toDate();
    }

    if (this.activity.pierOutboundDisplay) {
      this.activity.pierOutbound = moment
        .utc(this.activity.pierOutboundDisplay, 'DD-MM-YYYY HH:mm')
        .toDate();
    }

    if (this.activity.pierInboundDisplay) {
      this.activity.pierInbound = moment
        .utc(this.activity.pierInboundDisplay, 'DD-MM-YYYY HH:mm')
        .toDate();
    }

    const validationResult = CVVActivityModel.validate(this.activity);

    if (!validationResult.isValid) {
      this.errors = validationResult.errors;
    } else {
      if (
        !this.activity.$nautical_portvisit_referencenumber &&
        this.tempVisit &&
        this.tempVisit.referenceNumber
      ) {
        this.activity.$nautical_portvisit_referencenumber =
          this.tempVisit.referenceNumber;
        this.activity.$companies_company_id =
          this.cvvActivityLogic.getCompanyByAgentId(
            this.tempVisit.currentMovement
              ? this.tempVisit.currentMovement.portAgentId
              : this.tempVisit.portAgentId
          );

        if (this.tempVisit.currentMovement) {
          this.activity.$nautical_portmovement_id =
            this.tempVisit.currentMovement.id;
        }
      }

      // show a warning when no visit is set for the type "Meren", "Ontmeren" en "Verhalen"
      if (
        !this.activity.$nautical_portvisit_referencenumber &&
        (this.activity.activityType ==
          this.cvvActivityLogic.cvvActivityTypes.Meren.id ||
          this.activity.activityType ==
            this.cvvActivityLogic.cvvActivityTypes.Ontmeren.id ||
          this.activity.activityType ==
            this.cvvActivityLogic.cvvActivityTypes.Verhalen.id)
      ) {
        ConfirmComponent.additionalText =
          'Er is nog geen reis voor deze activiteit opgevoerd. Weet je zeker dat je deze activiteit wil opslaan?';
        const ngbModalOptions: NgbModalOptions = {
          backdrop: 'static',
        };

        this.ngbModal
          .open(ConfirmComponent, ngbModalOptions)
          .result.then((modalResult) => {
            if (modalResult === true) {
              this.saveActivity();
            }
          });
      } else {
        this.saveActivity();
      }
    }
  }

  saveActivity() {
    const result = new CVVAddActivityResultModel();
    result.activity = this.activity;
    result.visit = this.tempVisit;
    this.modal.close(result);
  }

  searchShip = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.searching = true)),
      switchMap((term) =>
        this.nauticalShipData.find(new ShipSearchModel(term), 0, 100).pipe(
          tap(() => (this.searchFailed = false)),
          map((result) => result.result),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })
        )
      ),
      tap(() => (this.searching = false))
    );

  searchShipFormatter = (result: NauticalShipModel) => {
    let output = result.name.toUpperCase();

    const attributes = [];
    if (result.shipType && result.shipType.name) {
      attributes.push(result.shipType.name);
    }
    if (result.callSign) {
      attributes.push('Callsign: ' + result.callSign);
    }
    if (result.fisheryNumber) {
      attributes.push(result.fisheryNumber);
    }
    if (result.imo) {
      attributes.push('IMO: ' + result.imo);
    } else if (result.eni) {
      attributes.push('ENI: ' + result.eni);
    }

    if (attributes.length > 0) {
      output += ' (' + attributes.join(', ') + ')';
    }

    return output;
  };

  setActivityDefaults() {
    if (!this._activity) {
      return;
    }

    if (
      !this.activity.$nautical_portvisit_referencenumber &&
      this.activity.$nautical_ship_id
    ) {
      this.loadVisitsForShip(this.activity.$nautical_ship_id);
    }

    if (this.activity.startsOn) {
      this.activity.startsOnDisplay = moment(this.activity.startsOn).format(
        'DD-MM-YYYY HH:mm'
      );
    }

    if (this.activity.endsOn) {
      this.activity.endsOnDisplay = moment(this.activity.endsOn).format(
        'DD-MM-YYYY HH:mm'
      );
    }

    if (this.activity.pierOutbound) {
      this.activity.pierOutboundDisplay = moment(
        this.activity.pierOutbound
      ).format('DD-MM-YYYY HH:mm');
    }

    if (this.activity.pierInbound) {
      this.activity.pierInboundDisplay = moment(
        this.activity.pierInbound
      ).format('DD-MM-YYYY HH:mm');
    }
  }

  setNow(target) {
    if (target === 'startsOn') {
      this.activity.startsOn = new Date();
      this.activity.startsOnDisplay = moment(this.activity.startsOn).format(
        'DD-MM-YYYY HH:mm'
      );
    } else if (target === 'endsOn') {
      this.activity.endsOn = new Date();
      this.activity.endsOnDisplay = moment(this.activity.endsOn).format(
        'DD-MM-YYYY HH:mm'
      );
    } else if (target === 'pierOutbound') {
      this.activity.pierOutbound = new Date();
      this.activity.pierOutboundDisplay = moment(
        this.activity.pierOutbound
      ).format('DD-MM-YYYY HH:mm');
    } else if (target === 'pierInbound') {
      this.activity.pierInbound = new Date();
      this.activity.pierInboundDisplay = moment(
        this.activity.pierInbound
      ).format('DD-MM-YYYY HH:mm');
    }
  }

  setShip(tempShip: any) {
    this.shipVisits = [];

    if (tempShip && tempShip.item && tempShip.item.id) {
      this.loadVisitsForShip(tempShip.item.id);
    }
  }

  setShipAndVisit() {
    if (this.tempVisit && this.tempVisit.referenceNumber) {
      this.activity.$nautical_portvisit_referencenumber =
        this.tempVisit.referenceNumber;
      this.activity.$companies_company_id =
        this.cvvActivityLogic.getCompanyByAgentId(
          this.tempVisit.currentMovement
            ? this.tempVisit.currentMovement.portAgentId
            : this.tempVisit.portAgentId
        );
    }
    this.activity.$nautical_ship_id = this.tempShip.baseId
      ? parseInt(this.tempShip.baseId)
      : parseInt(
          this.tempShip.id.replace('customer_', '').replace('base_', '')
        );
    this.activity.$nautical_ship = this.tempShip;
  }

  visitDisplay(visit: NauticalVisitOverviewModel) {
    let output = visit.referenceNumber + ' ';

    if (visit.eta || visit.ata) {
      if (visit.ata) {
        output += 'ATA ' + moment(visit.ata).format('DD-MM-YYYY HH:mm');
      } else if (visit.eta) {
        output += 'ETA ' + moment(visit.eta).format('DD-MM-YYYY HH:mm');
      }
    }
    if (visit.eta || visit.ata || visit.etd || visit.atd) {
      output += ' - ';
    }
    if (visit.etd || visit.atd) {
      if (visit.atd) {
        output += 'ATD ' + moment(visit.atd).format('DD-MM-YYYY HH:mm');
      } else if (visit.etd) {
        output += 'ETD ' + moment(visit.etd).format('DD-MM-YYYY HH:mm');
      }
    }

    return output;
  }
}
