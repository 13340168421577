import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { CustomCVVPipesModule } from '../../../custom-ui/cvv/pipes/cvv-pipes.module';
import { LayoutComponentsModule } from '../../../layout/components/layout-components.module';
import { NauticalShipServicesModule } from '../../../nautical-ship/services/nautical-ship-services.module';
import { NauticalVisitComponentsModule } from '../../../nautical-visit/components/nautical-visit-components.module';
import { NauticalVisitServicesModule } from '../../../nautical-visit/services/nautical-visit-services.module';
import { SharedModule } from '../../../shared/shared.module';

import { CVVActivitiesContainerComponent } from './activities-container/activities-container.component';
import { CVVAddActivityModal } from './add-activity-modal/add-activity-modal.component';
import { CVVShipRequirementsModal } from './cvv-ship-requirements-modal/cvv-ship-requirements-modal.component';

@NgModule({
  declarations: [
    CVVActivitiesContainerComponent,
    CVVAddActivityModal,
    CVVShipRequirementsModal,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    SharedModule,
    LayoutComponentsModule,
    NauticalShipServicesModule,
    NauticalVisitComponentsModule,
    NauticalVisitServicesModule,
    CustomCVVPipesModule,

    NgbModule,
  ],
  exports: [
    CVVActivitiesContainerComponent,
    CVVAddActivityModal,
    CVVShipRequirementsModal,
  ],
})
export class CustomCVVComponentsModule {}
