<form #form="ngForm" class="form-row">
  <div
    class="form-group col-12 text-danger"
    *ngIf="invoice.isFinalized === true"
  >
    {{ 'invoice.finalizedMessage' | translate }}
  </div>
  <div class="form-group col-12 col-md-6" *ngIf="flows && flows.length > 1">
    <label
      ><b>{{ 'invoicingFlows.flow.flow' | translate }}</b></label
    >
    <select
      class="form-control"
      [(ngModel)]="invoice.invoiceFlowId"
      [disabled]="invoice.isFinalized === true"
      name="invoiceFlowId"
    >
      <option *ngFor="let flow of flows" [ngValue]="flow.id">
        {{ flow.name }}
      </option>
    </select>
  </div>
  <div class="form-group col-12 col-md-6" *ngIf="!flows || flows.length === 1">
    <label for="number">
      <b>{{ 'invoicing.invoiceModel.number' | translate }}</b>
    </label>
    <input
      [(ngModel)]="invoice.number"
      [readonly]="invoice.isFinalized === true"
      type="text"
      placeholder="{{ 'invoicing.invoiceModel.number' | translate }}"
      id="number"
      name="number"
      class="form-control"
      disabled
    />
  </div>
  <!-- Invoice type should never be changed by a user directly -->
  <!-- <div class="form-group col-12 col-sm-6 col-md-3">
    <label for="invoiceType">
      <b>{{ 'invoicing.invoiceModel.invoiceType' | translate }}</b>
      <i class="fa-regular fa-asterisk fa-xs fa-fw text-danger mb-1"></i>
    </label>
    <select [(ngModel)]="invoice.invoiceType" required id="invoiceType" name="invoiceType" class="custom-select">
      <option *ngFor="let invoiceType of [1,2,3]" [value]="invoiceType">
        {{ invoiceType | invoiceType }}
      </option>
    </select>
  </div> -->
  <div class="form-group col-12 col-sm-6">
    <label for="invoiceDate">
      <b>{{ 'invoicing.invoiceModel.invoiceDate' | translate }}</b>
      <i class="fa-regular fa-asterisk fa-xs fa-fw text-danger mb-1"></i>
    </label>
    <temp-date-time-picker
      [(dateTime)]="invoice.invoiceDate"
      [dateTimeFormat]="'YYYY-MM-DD[T00:00:00.000Z]'"
      [hideTime]="true"
      [isReadOnly]="invoice.isFinalized === true"
      [isContained]="true"
      [required]="true"
    ></temp-date-time-picker>
    <small *ngIf="isSubmitted && !invoice.invoiceDate" class="text-danger">
      {{ 'shared.terms.requiredFieldText' | translate }}
    </small>
  </div>
  <div class="form-group col-12">
    <label for="description">
      <b>{{ 'shared.terms.description' | translate }}</b>
    </label>
    <textarea
      [(ngModel)]="invoice.description"
      type="text"
      rows="5"
      placeholder="{{ 'shared.terms.description' | translate }}"
      id="description"
      name="description"
      class="form-control"
    ></textarea>
  </div>
  <div class="form-group col-12">
    <label for="company">
      <div class="row ml-0">
        <b>{{ 'invoicing.invoiceModel.company' | translate }}</b>
        <i class="fa-regular fa-asterisk fa-xs fa-fw text-danger mb-1"></i>
        <div
          class="custom-control custom-checkbox ml-2 text-overflow"
          style="max-width: 290px"
          title="{{
            'companies.company.hideMissingFinancialCompanies' | translate
          }}"
        >
          <input
            [(ngModel)]="hideInvalidCompany"
            type="checkbox"
            class="custom-control-input"
            id="hideInvalidCompany"
            name="hideInvalidCompany"
          />
          <label class="custom-control-label" for="hideInvalidCompany">{{
            'companies.company.hideMissingFinancialCompanies' | translate
          }}</label>
        </div>
      </div>
    </label>

    <ca-company-picker
      [(selectedCompany)]="company"
      [isRequired]="true"
      [isReadOnly]="invoice.isFinalized === true"
      [showInline]="true"
      [isDebtorPicker]="true"
      [submitted]="isSubmitted"
      [hideMissingDebtor]="hideInvalidCompany"
      (selectedCompanyChange)="companyChanged($event)"
    ></ca-company-picker>
  </div>
  <div class="form-group col-12 col-md-6">
    <label for="type">
      <b>{{ 'invoicing.invoiceModel.currency' | translate }}</b>
      <i class="fa-regular fa-asterisk fa-xs fa-fw text-danger mb-1"></i>
    </label>
    <select
      [(ngModel)]="invoice.currency"
      [disabled]="invoice.isFinalized === true"
      required
      id="currency"
      name="currency"
      class="custom-select"
    >
      <option
        *ngFor="let currency of ['EUR', 'USD', 'GBP', 'DKK', 'NKK']"
        [value]="currency"
      >
        {{ currency | invoiceCurrencySymbol }}
        {{ currency }}
      </option>
    </select>
  </div>
  <div class="form-group col-12 col-md-6">
    <label for="payByDate">
      <b>{{ 'invoicing.invoiceModel.payByDate' | translate }}</b>
    </label>
    <temp-date-time-picker
      [(dateTime)]="invoice.payByDate"
      [dateTimeFormat]="'YYYY-MM-DD[T00:00:00.000Z]'"
      [hideTime]="true"
      [isReadOnly]="invoice.isFinalized === true"
      [isContained]="true"
    ></temp-date-time-picker>
  </div>
  <div class="form-group col-12 col-sm-6">
    <label for="customerReference">
      <b>{{ 'invoicing.invoiceModel.customerReference' | translate }}</b>
    </label>
    <input
      [(ngModel)]="invoice.customerReference"
      type="text"
      placeholder="{{ 'invoicing.invoiceModel.customerReference' | translate }}"
      id="customerReference"
      name="customerReference"
      class="form-control"
    />
  </div>
  <div class="form-group col-12 col-sm-6">
    <label for="internalReference">
      <b>{{ 'invoicing.invoiceModel.internalRemarks' | translate }}</b>
    </label>
    <input
      [(ngModel)]="invoice.internalRemarks"
      type="text"
      placeholder="{{ 'invoicing.invoiceModel.internalRemarks' | translate }}"
      id="internalRemarks"
      name="internalRemarks"
      class="form-control"
    />
  </div>
  <!-- Status should never be directly modified by a user -->
  <!--   <div class="form-group col-12 col-md-4">
    <label for="type">
      <b>{{ 'invoicing.invoiceModel.status' | translate }}</b>
      <i class="fa-regular fa-asterisk fa-xs fa-fw text-danger mb-1"></i>
    </label>
    <select [(ngModel)]="invoice.status" required id="status" name="status" class="custom-select">
      <option *ngFor="let status of [1,2,3,4,5,6]" [value]="status">
        {{ status | invoiceStatus }}
      </option>
    </select>
  </div> -->
</form>
