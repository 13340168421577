import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';

import { CustomContentComponentsModule } from '../../custom-content/components/custom-content-components.module';
import { LayoutComponentsModule } from '../../layout/components/layout-components.module';
import { LayoutPipesModule } from '../../layout/pipes/layout-pipes.module';
import { SharedModule } from '../../shared/shared.module';

import {
  AdvancedSearchComponent,
  AttributeListComponent,
  FormComponent,
} from '@seahorse/temp';
import { CardComponent, SeahorseUiModule } from '@seahorse/ui';
import { ContentDistributionComponentSharedModule } from '../../content-distribution/components/content-distribution-component-shared.module';
import { GenericDuplicateComponent } from './generic-duplicate/generic-duplicate.component';

@NgModule({
  declarations: [
    // Components
    GenericDuplicateComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    LayoutComponentsModule,
    LayoutPipesModule,
    NgbModule,

    NgSelectModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    TranslateModule,
    CustomContentComponentsModule,
    ContentDistributionComponentSharedModule,
    SeahorseUiModule,
    FormComponent,
    AdvancedSearchComponent,
    CardComponent,
    AttributeListComponent,
  ],
  exports: [
    // Components
    GenericDuplicateComponent,
  ],
})
export class GenericUIComponentsModule {}
