import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'invoiceType',
})
export class InvoiceTypePipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: number): string {
    switch (value) {
      case 1:
        return this.translate.instant(
          'invoicing.invoiceModel.invoiceTypes.proforma'
        );
      case 2:
        return this.translate.instant(
          'invoicing.invoiceModel.invoiceTypes.regular'
        );
      case 3:
        return this.translate.instant(
          'invoicing.invoiceModel.invoiceTypes.credit'
        );
      default:
        return this.translate.instant(
          'invoicing.invoiceModel.invoiceTypes.unknown'
        );
    }
  }
}
