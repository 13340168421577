<div class="modal-header align-items-center">
  <h1 class="modal-title">
    {{ selectedMember.$usermanager_user_givenName }}
    {{ selectedMember.$usermanager_user_surname }}
  </h1>
  <button
    aria-label="Close"
    (click)="onSelectMemberAction.emit({ member: null, action: 'dismiss' })"
    type="button"
    class="close"
  >
    <span aria-hidden="true">×</span>
  </button>
</div>

<div class="modal-body">
  <table
    class="table table-borderless table-hover table-sm"
    *ngIf="teams.length > 0"
  >
    <tr *ngFor="let team of teams; let i = index">
      <td scope="row">
        <div class="custom-control custom-checkbox">
          <input
            [id]="'row-memberManagerTeam' + i"
            [checked]="isChecked(team.__Id)"
            (change)="selectTeam(team.__Id)"
            type="checkbox"
            class="custom-control-input"
          />
          <label
            [for]="'row-memberManagerTeam' + i"
            class="custom-control-label w-100"
          >
            {{ team.name }}
            {{
              team.active === true
                ? '(' + ('customUI.memberTeamPicker.active' | translate) + ')'
                : ''
            }}
          </label>
        </div>
      </td>
    </tr>
  </table>

  <div class="form-group">
    <label for="remarks">
      {{ 'customUI.memberModel.remarks' | translate }}
    </label>
    <textarea
      [(ngModel)]="selectedMember.remarks"
      id="remarks"
      name="remarks"
      class="form-control"
    ></textarea>
  </div>

  <div class="custom-control custom-switch">
    <input
      [(ngModel)]="selectedMember.isActive"
      type="checkbox"
      class="custom-control-input"
      id="isActive"
      name="isActive"
    />
    <label for="isActive" class="custom-control-label">
      {{ 'customUI.memberModel.isActive' | translate }}
    </label>
  </div>

  <div class="modal-footer">
    <button
      (click)="onSelectMemberAction.emit({ member: null, action: 'dismiss' })"
      type="button"
      class="btn btn-link w-50"
    >
      {{ 'shared.terms.cancel' | translate }}
    </button>
    <button
      (click)="
        onSelectMemberAction.emit({ member: selectedMember, action: 'save' })
      "
      type="button"
      class="btn btn-success w-50"
    >
      {{ 'shared.terms.save' | translate }}
    </button>
  </div>
</div>
