import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { LayoutComponentsModule } from '../../../layout/components/layout-components.module';
import { NauticalVisitComponentsModule } from '../../../nautical-visit/components/nautical-visit-components.module';
import { NauticalVisitPipesModule } from '../../../nautical-visit/pipes/nautical-visit-pipes.module';
import { ZeehavenIJmuidenComponentsModule } from '../components/zeehaven-ijmuiden-components.module';
import { ZeehavenIJmuidenPipesModule } from '../pipes/zeehaven-ijmuiden-pipes.module';
import { ZeehavenIJmuidenOverviewPage } from './zeehaven-ijmuiden-overview/zeehaven-ijmuiden-overview.page';
import { ZeehavenIJmuidenDetailsPage } from './zeehaven-ijmuiden-details/zeehaven-ijmuiden-details.page';
import { NauticalGeographyComponentsModule } from '../../../nautical-geography/components/nautical-geography-components.module';

@NgModule({
  declarations: [ZeehavenIJmuidenOverviewPage, ZeehavenIJmuidenDetailsPage],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    LayoutComponentsModule,
    NauticalGeographyComponentsModule,
    NauticalVisitComponentsModule,
    NauticalVisitPipesModule,
    ZeehavenIJmuidenComponentsModule,
    ZeehavenIJmuidenPipesModule,
  ],
})
export class ZeehavenIJmuidenPagesModule {}
