import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { CompanyContactModel } from '@seahorse/domain';
import { DepartmentDataService } from '../../../../company/services/department-data.service';
import { MultiSelectOption } from '@seahorse/forms';
import { CreateContactForm } from './contact.form';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ca-contact-form',
  templateUrl: './contact-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactFormComponent implements OnInit, OnDestroy {
  contactStatuses: Array<any> = [
    { value: 1, label: 'Active', icon: '<i class="fa-regular fa-check mr-2"></i>' },
    {
      value: 2,
      label: 'Left company',
      icon: '<i class="fa-solid fa-times mr-2"></i>',
    },
    { value: 3, label: 'Diseased', icon: '<i class="fa-regular fa-cross mr-2"></i>' },
  ];

  private hasInitializedDepartments = false;
  private _model: CompanyContactModel;
  @Input()
  set model(value: CompanyContactModel) {
    this._model = value;
    if (!this.hasInitializedDepartments && value?.departmentContacts?.length > 0) {
      const newDepartments = value.departmentContacts?.map((x) => ({ label: x.name, value: x.id })) || [];
      if (this.departmentList.length === 0) {
        this.departmentList = newDepartments;
      } else {
        newDepartments.forEach((x) => {
          if (this.departmentList.findIndex((y) => y.value === x.value) === -1) {
            this.departmentList.push(x);
          }
        });
      }
      this.form.setValue({ departments: value.departmentContacts?.map((x) => x.id) ?? [] });
      this.hasInitializedDepartments = true;
    }
    
  }
  get model(): CompanyContactModel {
    return this._model;
  }

  @Input() submitted = false;
  @Input() companyId: number;

  form = CreateContactForm();
  isLoadingDepartments = false;
  departmentList: MultiSelectOption[] = [];

  constructor(private departmentDataService: DepartmentDataService) {}

  private _subscriptions = new Subscription();
  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
  
  ngOnInit() {
    this.isLoadingDepartments = true;
    this._subscriptions.add(this.departmentDataService.getAll(this.companyId).subscribe((result) => {
      const newDepartments = result.result?.map((x) => ({ label: x.name, value: x.id })) || [];
      if (this.departmentList.length === 0) {
        this.departmentList = newDepartments;
      } else {
        newDepartments.forEach((x) => {
          if (this.departmentList.findIndex((y) => y.value === x.value) === -1) {
            this.departmentList.push(x);
          }
        });
      }
      this.isLoadingDepartments = false;
    }));

    this._subscriptions.add(this.form.get('departments').valueChanges.subscribe((value) => {
      this.model.departmentContacts = this.departmentList
        ?.filter((x) => value.includes(+x.value))
        ?.map((x) => { return { id: x.value, name: x.label } })
        ?? [];
    }));
  }
}
