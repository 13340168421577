import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as _ from 'underscore';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { ResultWrapper } from '@seahorse/common';
import { NotificationService } from '@seahorse/common';
import { TariffGroupDataService } from '../../../invoicing-and-tariffs/modules/tariffs/services/tariff-group.data.service';
import { TariffDataService } from '../../../invoicing-and-tariffs/modules/tariffs/services/tariff-data.service';
import { TariffModel } from '../../../invoicing-and-tariffs/modules/tariffs/models/tariff.model';
import { TariffGroupModel } from '../../../invoicing-and-tariffs/modules/tariffs/models/tariff-group.model';

@Component({ templateUrl: 'tariff-picker-modal.component.html' })
export class TariffPickerModalComponent implements OnInit {
  @Input() tariff: TariffModel;
  @Input() tariffRequired?: boolean;

  @Output() onConfirm: EventEmitter<TariffModel>;

  tariffs: TariffModel[];
  tariffsLoading: boolean;
  tariffGroup: TariffGroupModel;
  tariffGroupLoading: boolean;
  tariffGroups: TariffGroupModel[];
  tariffGroupsLoading: boolean;

  constructor(
    private translate: TranslateService,
    private notification: NotificationService,
    private tariffGroupData: TariffGroupDataService,
    private tariffData: TariffDataService
  ) {
    this.tariff = null;
    this.tariffRequired = true;
    this.onConfirm = new EventEmitter<TariffModel>();
    this.tariffs = [];
    this.tariffsLoading = false;
    this.tariffGroup = null;
    this.tariffGroups = [];
    this.tariffGroupsLoading = false;
  }

  ngOnInit() {
    this.tariffGroupsLoading = true;

    this.tariffGroupData.getTariffGroups(0, 9999).subscribe(
      (r: ResultWrapper<TariffGroupModel[]>) => {
        if (r.hasResult) {
          this.tariffGroups = r.result;

          if (r.result.length && this.tariff && this.tariff.tariffGroupId) {
            this.tariffGroup = _.find(
              r.result,
              (t) => t.id == this.tariff.tariffGroupId
            );

            this.tariffsLoad();
          } else if (r.result.length == 1) {
            this.tariffGroup = _.first(r.result);

            this.tariffsLoad();
          }
        } else {
          this.notification.showError(
            _.pluck(r.messages, 'message').join('\n'),
            this.translate.instant('shared.terms.failed')
          );
        }
      },
      (e) => {
        this.notification.showError(
          _.pluck(e.error.messages, 'message').join('\n'),
          this.translate.instant('shared.terms.failed')
        );
      },
      () => (this.tariffGroupsLoading = false)
    );
  }

  tariffsLoad() {
    if (this.tariffGroup && this.tariffGroup.id) {
      this.tariffsLoading = true;

      this.tariffData
        .getTariffsByGroupIdAndDate(
          this.tariffGroup.id,
          moment.utc().format('DD-MM-YYYY HH:mm'),
          0,
          9999
        )
        .subscribe(
          (r: ResultWrapper<TariffModel[]>) => {
            if (r.hasResult) {
              this.tariffs = r.result;

              if (!this.tariff && r.result.length == 1) {
                this.tariff = _.first(r.result);
              }
            } else {
              this.notification.showError(
                _.pluck(r.messages, 'message').join('\n'),
                this.translate.instant('shared.terms.failed')
              );
            }
          },
          (e) => {
            this.notification.showError(
              _.pluck(e.error.messages, 'message').join('\n'),
              this.translate.instant('shared.terms.failed')
            );
          },
          () => (this.tariffsLoading = false)
        );
    } else {
      this.tariffs = [];
    }
  }

  setNull(type: string) {
    switch (type) {
      case 'tariff': {
        this.tariff = null;
        break;
      }

      case 'tariffGroup': {
        this.tariffGroup = null;
        this.tariffs = [];
        this.tariff = null;
        break;
      }
    }
  }
}
