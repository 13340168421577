import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { GroupOption, NotificationService } from '@seahorse/common';
import { ConvertObjectMappingModel } from '../../../../../layout/models/convert-object-mapping.model';
import { TableColumnModel } from '../../../../../layout/models/table.model';
import { ObjectMappingService } from '../../../../../layout/services/object-mapping.service';
import * as _ from 'underscore';
import {
  CustomDataBaseModel,
  FileService,
  InvoiceLineModel,
  InvoiceMetaDataModel,
  InvoiceModel,
  ObjectDefinitionModel,
} from '@seahorse/domain';
import { TariffDataService } from '../../../tariffs/services/tariff-data.service';
import { TariffModel } from '../../../tariffs/models/tariff.model';
import { TariffMetaDataModel } from '../../../tariffs/models/tariff-meta-data.model';
import { InvoiceCreatorDebtorFormComponent } from '../invoice-creator/invoice-creator-debtor-form/invoice-creator-debtor-form.component';
import { CustomDataFormService, DataField, FormModel } from '@seahorse/temp';

@Component({
  selector: 'ca-invoice-creator-custom-data-modal',
  templateUrl: 'invoice-creator-by-custom-data-modal.component.html',
  styleUrls: ['./invoice-creator-by-custom-data-modal.component.scss'],
})
export class InvoiceCreatorByCustomDataModalComponent implements OnInit {
  @Input() amountField: string;
  @Input() approvedField: string;
  @Input() customData: CustomDataBaseModel[];
  @Input() customDataColumns: TableColumnModel[];
  @Input() invoice: InvoiceModel;
  @Input() invoiceLineMappings: ConvertObjectMappingModel[];
  @Input() companyIdField: string;
  @Input() metaDataMappingkey: string;
  @Input() objectDefinition: ObjectDefinitionModel;
  @Input() selectGroupOptions: GroupOption<string, any, string[]>[];

  @Input() includeZeroAmount = false;
  @Input() includeCharged = false;

  @ViewChild(InvoiceCreatorDebtorFormComponent)
  creatorDebtorForm: InvoiceCreatorDebtorFormComponent;

  isAllObjectChecked = false;
  step = 0;
  selectedObjects = [];
  selectedIds: string[];
  tariffs: TariffModel[] = [];
  formModels?: FormModel[];
  selectedGroupOption: GroupOption<string, any, string[]> = null;
  private _includeFiltering = false;
  set includeFiltering(value: boolean) {
    this._includeFiltering = value;
    this.groupBy();
  }

  get includeFiltering(): boolean {
    return this._includeFiltering;
  }

  groupedCustomData: {
    [key: string]: {
      data: CustomDataBaseModel[];
      model: CustomDataBaseModel;
      selected: boolean;
    };
  };

  get amountActualIndex(): number {
    return this.customDataColumns.findIndex(
      (column) => column.fieldName === this.amountField
    );
  }

  get hasGroupOptions(): boolean {
    return this.selectGroupOptions && this.selectGroupOptions.length > 0;
  }

  get showTable(): boolean {
    return this.customData && this.customData.length > 0;
  }

  constructor(
    public modal: NgbActiveModal,
    private mappingService: ObjectMappingService,
    private notification: NotificationService,
    private translate: TranslateService,
    private tariffData: TariffDataService,
    private _customDataFormService: CustomDataFormService,
    private _filesService: FileService
  ) {
    this.approvedField = null;
    this.customData = [];
    this.customDataColumns = [];
    this.invoice = new InvoiceModel();
    this.invoiceLineMappings = [];
    this.metaDataMappingkey = null;
  }

  ngOnInit(): void {
    if (this.hasGroupOptions && this.selectedGroupOption === null)
      this.selectedGroupOption = this.selectGroupOptions[0];

    this.groupBy();
    this.setFormModels();

    this.customData.forEach((expense) => {
      if (expense['isChecked'] === true) {
        this.selectedObjects.push(expense);
      }
    });
  }

  private setFormModels() {
    const columns = this.customDataColumns.map((c) => c.fieldName);
    const fields = ObjectDefinitionModel.getAllFieldDefinitions(
      this.objectDefinition,
      true
    )
      .filter((x) => columns.includes(x.systemCode))
      .map((x) => DataField.fromFieldDefinition(x));

    const formModel = this._customDataFormService.getFormModel(
      this.objectDefinition,
      fields.map((x) => x.key as string),
      null,
      false
    );

    if (formModel.fields.find((f) => f.key === this.amountField)) {
      formModel.fields[
        formModel.fields.findIndex((f) => f.key === this.amountField)
      ].isDisabled = true;
    }

    this.formModels = formModel.fields.map((f, i) => {
      const clone = Object.assign({}, formModel);
      clone.fields = [formModel.fields[i]];
      return clone;
    });
  }

  convertToInvoiceLines() {
    this.invoice.invoiceLines = [];
    this.invoice.metaData = [];
    this.selectedIds = [];
    const tariffsToLoad = [];
    let hasCompanyValue = undefined;
    let currentCompanyValue = null;

    let selectedObjects = [];
    if (
      this.selectedGroupOption?.value === null ||
      this.selectedGroupOption?.value === undefined
    ) {
      selectedObjects = this.selectedObjects;
      this.selectedIds = this.selectedObjects.map((id) => id.__Id);
    } else {
      // Note: selected IDs are different from selectedObjects. SelectedObjects are what the invoice will be
      // created from (Invoice lines), while the selectedIds indicate what the original source of the invoice
      // lines was (eg: expenses)

      // if the group header is selected, parse the group header
      selectedObjects = Object.values(this.groupedCustomData)
        .filter((grp) => grp.selected === true)
        .map((grp) => grp.model);
      this.selectedIds = _.flatten(
        Object.values(this.groupedCustomData)
          .filter((grp) => grp.selected === true)
          .map((grp) => grp.data)
      ).map((id) => id.__Id);

      // if the group header is not selected, parse the selected children
      Object.values(this.groupedCustomData)
        .filter((grp) => grp.selected !== true)
        .forEach((grp) => {
          selectedObjects = selectedObjects.concat(
            grp.data.filter((d) => this.selectedObjects.indexOf(d) > -1)
          );
        });
      Object.values(this.groupedCustomData)
        .filter((grp) => grp.selected !== true)
        .forEach((grp) => {
          this.selectedIds = this.selectedIds.concat(
            grp.data
              .filter((d) => this.selectedObjects.indexOf(d) > -1)
              .map((id) => id.__Id)
          );
        });
    }

    selectedObjects.forEach((data) => {
      if (
        this.metaDataMappingkey !== undefined &&
        this.metaDataMappingkey !== null
      ) {
        const metaData = new InvoiceMetaDataModel();
        metaData.invoiceLineId = this.invoice.invoiceLines.length; // the index of the invoice line
        metaData.metaTarget = 'seahorse_contentmapping';
        metaData.metaKey = this.metaDataMappingkey;
        metaData.metaValue = data.__Id;
        this.invoice.metaData.push(metaData);
      }

      if (
        this.selectedGroupOption?.value === null ||
        this.selectedGroupOption?.value === undefined
      ) {
        this.addFileMetaData(
          this.invoice,
          data.__Id,
          this.invoice.invoiceLines.length
        );
      } else {
        this.selectedObjects.forEach((selectedObject) => {
          this.addFileMetaData(
            this.invoice,
            selectedObject.__Id,
            this.invoice.invoiceLines.length
          );
        });
      }

      const newLine = this.mappingService.convertObjectByType(
        InvoiceLineModel,
        data,
        this.invoiceLineMappings
      );
      if (newLine.quantity === null) newLine.quantity = 1;

      this.invoice.invoiceLines.push(newLine);

      if (newLine.tariffId && !this.tariffs[newLine.tariffId]) {
        tariffsToLoad.push(newLine.tariffId);
      }

      // check if all customdata has the same companyFinancialField
      if (this.companyIdField) {
        if (hasCompanyValue === undefined) {
          currentCompanyValue = data[this.companyIdField];
          hasCompanyValue = true;
        } else if (
          hasCompanyValue === true &&
          currentCompanyValue !== data[this.companyIdField]
        ) {
          hasCompanyValue = false;
        }
      } else {
        hasCompanyValue = false;
      }
    });

    if (
      hasCompanyValue === true &&
      currentCompanyValue &&
      !isNaN(parseInt(currentCompanyValue))
    ) {
      this.invoice.companyId = parseInt(currentCompanyValue);
      this.creatorDebtorForm.companyIdChanged();
    } else {
      this.invoice.companyId = null;
      this.invoice.companyFinancialId = null;
      this.creatorDebtorForm.company = null;
    }

    if (tariffsToLoad.length > 0) {
      this.tariffData.getByIds(tariffsToLoad).subscribe(
        (r) => {
          if (r.result) {
            r.result.forEach((tariff) => {
              this.tariffs[tariff.id] = tariff;
            });
          } else {
            this.notification.showError(
              _.pluck(r.messages, 'message').join('\n'),
              this.translate.instant('shared.terms.failed')
            );
          }
          this.addTariffMetaData();
        },
        (e) => {
          this.notification.showError(
            _.pluck(e.error.messages, 'message').join('\n'),
            this.translate.instant('shared.terms.failed')
          );
          this.addTariffMetaData();
        }
      );
    } else {
      this.addTariffMetaData();
    }
  }

  addTariffMetaData() {
    this.invoice.invoiceLines.forEach((invoiceLine, invLineIdx) => {
      // loop through the invoicelines, check if we need add metadata to this invoiceline by the linked tariff id
      if (invoiceLine.tariffId && this.tariffs[invoiceLine.tariffId]) {
        const tariff = this.tariffs[invoiceLine.tariffId];

        // copy the vat code id of the tariff
        invoiceLine.vatCodeId = tariff.vatCodeId;

        // handle meta data
        if (
          tariff.tariffMetaDatasModel &&
          tariff.tariffMetaDatasModel.length > 0
        ) {
          // group the meta data by metaTarget + metaKey
          const grpMetaData = tariff.tariffMetaDatasModel.reduce(
            (r, v, i, a, k = v.metaTarget + v.metaKey) => (
              (r[k] || (r[k] = [])).push(v), r
            ),
            {}
          );
          Object.entries(grpMetaData).forEach(([, value]) => {
            if (
              typeof value === 'object' &&
              value !== null &&
              Array.isArray(value) &&
              value.length > 0
            ) {
              let mdata = value[0];
              if (value.length > 1) {
                // meta data with tariff id has priority
                const metaDataWithTariff = value.find(
                  (md: TariffMetaDataModel) =>
                    md.tariffId !== undefined && md.tariffId !== null
                );
                if (metaDataWithTariff) {
                  mdata = metaDataWithTariff;
                }
              }

              const metaData = new InvoiceMetaDataModel();
              metaData.invoiceLineId = invLineIdx; // the index of the invoice line
              metaData.metaTarget = mdata.metaTarget;
              metaData.metaKey = mdata.metaKey;
              metaData.metaValue = mdata.metaValue;
              metaData.metaDisplay = mdata.metaDisplay;
              this.invoice.metaData.push(metaData);
            }
          });
        }
      }
    });
  }

  getFieldValue(item: CustomDataBaseModel, fieldName: string) {
    let temp = Object.assign({}, item);
    const deeps = fieldName?.split('.');
    if (deeps && deeps.length > 0) {
      deeps.forEach((deep) => {
        temp = this.getFieldValueByProperty(temp, deep);
        return temp;
      });
    }

    return temp;
  }

  getFieldValueByProperty(item: CustomDataBaseModel, fieldName: string) {
    return item[fieldName];
  }

  groupBy() {
    const dataSet = this.customData.filter((x) => {
      let shouldInclude = true;

      if (this.includeZeroAmount === false && x['amountActual'] === 0) {
        shouldInclude = false;
      }

      if (this.includeCharged === false && x['charged'] === true) {
        shouldInclude = false;
      }

      if (shouldInclude) {
        const index = this.selectedObjects.indexOf(x);
        if (index > -1) {
          this.selectedObjects.splice(index, 1);
        }
      }

      return shouldInclude;
    });

    this.groupedCustomData = dataSet.reduce((groups, item: any) => {
      const groupKey = !this.selectedGroupOption?.groupKeys
        ? '__Id'
        : this.selectedGroupOption.groupKeys
            .map((f) => this.getFieldValue(item, f))
            .join(' - ');

      if (!groups[groupKey]) {
        const newModel = Object.assign({}, item); // copy all fields

        // these fields are need to (re)set; description, amount and quantity
        newModel[this.amountField] = 0;
        const descriptionField = this.invoiceLineMappings.find(
          (m) => m.targetField === 'description'
        );
        if (
          descriptionField &&
          descriptionField.sourceFields &&
          descriptionField.sourceFields.length > 0
        ) {
          newModel[descriptionField.sourceFields[0]] = groupKey;
        }

        const quantityField = this.invoiceLineMappings.find(
          (m) => m.targetField === 'quantity'
        );
        if (
          quantityField &&
          quantityField.sourceFields &&
          quantityField.sourceFields.length > 0
        ) {
          newModel[quantityField.sourceFields[0]] = 1;
        }

        groups[groupKey] = {
          data: [],
          model: newModel,
          selected: false,
        };
      }

      groups[groupKey].data.push(item);
      groups[groupKey].model[this.amountField] += item[this.amountField];

      return groups;
    }, {});

    if (dataSet.length !== this.customData.length) {
      Object.entries(this.groupedCustomData).forEach(([gKey, gValue]) => {
        this.updateGroupAmount({ key: gKey, value: gValue });
      });
    }
  }

  nextButton() {
    if (this.step === 1) {
      if (!this.creatorDebtorForm?.validate()) {
        return;
      }
    }

    this.step += 1;

    if (this.step === 1) {
      this.convertToInvoiceLines();
    }
  }

  save() {
    if (!this.invoice.invoiceLines || this.invoice.invoiceLines.length === 0) {
      return;
    }

    this.modal.close({ invoice: this.invoice, selectedIds: this.selectedIds });
  }

  selectAll(value: boolean) {
    this.isAllObjectChecked = value;
    if (this.isAllObjectChecked === true) {
      this.selectedObjects = [];
      Object.entries(this.groupedCustomData).forEach(([gKey, gValue]) => {
        gValue.selected = true;
        gValue.data.forEach((object) => {
          this.selectedObjects.push(object);
        });
        this.updateGroupAmount({ key: gKey, value: gValue });
      });
    } else {
      this.selectedObjects = [];
      Object.values(this.groupedCustomData).forEach((group) => {
        group.selected = false;
      });
    }
  }

  selectObject(object, group) {
    const index = this.selectedObjects.indexOf(object);
    if (index > -1) {
      this.selectedObjects.splice(index, 1);
    } else {
      this.selectedObjects.push(object);
    }

    this.updateGroupAmount(group);
  }

  selectGroup(group) {
    group.value.selected = !group.value.selected;

    if (group.value.selected) {
      group.value.data.forEach((object) => {
        if (this.selectedObjects.indexOf(object) === -1) {
          this.selectedObjects.push(object);
        }
      });

      this.updateGroupAmount(group);
    } else {
      group.value.model.amountActual = 0;

      group.value.data.forEach((object) => {
        group.value.model.amountActual += object.amountActual;

        const index = this.selectedObjects.indexOf(object);
        if (index > -1) {
          this.selectedObjects.splice(index, 1);
        }
      });
    }
  }

  updateGroupAmount(group) {
    let totalAmount = 0;
    group.value.data.forEach((object) => {
      if (this.selectedObjects.indexOf(object) !== -1) {
        totalAmount += object.amountActual;
      }
    });
    group.value.model.amountActual = totalAmount;
  }

  private addFileMetaData(
    invoice: InvoiceModel,
    customDataId: string,
    invoiceLineId: number
  ) {
    if (!invoice || !customDataId || !this.metaDataMappingkey) {
      return;
    }

    this._filesService
      .getFiles(customDataId, this.metaDataMappingkey, 0, 100)
      .subscribe((res) => {
        if (res.hasResult) {
          res.result.forEach((file) => {
            const fileMetaData = new InvoiceMetaDataModel();
            fileMetaData.invoiceLineId = invoiceLineId;
            fileMetaData.metaTarget = 'seahorse_contentmapping';
            fileMetaData.metaKey = '$files_file_id';
            fileMetaData.metaValue = file.id.toString();

            invoice.metaData.push(fileMetaData);
          });
        }
      });
  }
}
