export class InvoiceMetaDataModel {
  id: number;
  invoiceId: number;
  invoiceLineId: number;
  metaKey: string;
  metaTarget: string;
  metaValue: string;
  metaDisplay: string;

  constructor() {
    this.id = -1;
    this.invoiceId = null;
    this.invoiceLineId = null;
    this.metaKey = null;
    this.metaTarget = null;
    this.metaValue = null;
  }

  static validate(metaData: InvoiceMetaDataModel) {
    if (
      !metaData.metaKey ||
      metaData.metaKey.trim().length === 0 ||
      !metaData.metaTarget ||
      metaData.metaTarget.trim().length === 0 ||
      !metaData.metaValue ||
      metaData.metaValue.trim().length === 0
    ) {
      return false; // some data is missing
    }

    return true;
  }
}

export class InvoiceMetaDataDisplayModel extends InvoiceMetaDataModel {
  isDelete: boolean;

  constructor() {
    super();
    this.isDelete = false;
  }
}
