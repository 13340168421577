<div class="card">
  <div class="card-header">
    <span class="text-uppercase">{{ columnName }}</span>
  </div>
  <div class="card-body card-body-table">
    <div
      *ngIf="!filteredActivities || filteredActivities.length === 0"
      class="text-muted p-3 text-center"
    >
      Er zijn geen activiteiten voor deze groep
    </div>
    <ca-custom-kp-activities-container
      #container
      [activities]="filteredActivities"
      [files]="files"
      [shipRequirements]="shipRequirements"
      [portVisitInfos]="portVisitInfos"
      [activityTypeGroup]="groupCode"
      [viewList]="viewList"
      [isLoading]="isLoading"
    ></ca-custom-kp-activities-container>
  </div>
</div>
