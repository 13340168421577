import { Component, Input, Output, EventEmitter } from '@angular/core';

import { EventModel } from '../../../../../shared/models/event.model';
import { StatisticModel } from '../../models/statistic.model';

@Component({
  selector: 'statistics',
  templateUrl: 'statistics.component.html',
})
export class StatisticsComponent {
  @Input() statistics: StatisticModel[];
  @Input() loadingStatistics: boolean;

  @Output() onEvent: EventEmitter<EventModel>;

  constructor() {
    this.statistics = [];
    this.loadingStatistics = false;
    this.onEvent = new EventEmitter<EventModel>();
  }
}
