import { Injectable, OnDestroy } from '@angular/core';
import { CaEnvService } from '../../shared/services/env.service';
import * as mapboxgl from 'mapbox-gl';
import { Subject, Subscription } from 'rxjs';

import { AISMarkerModel } from '@seahorse/domain';
import { ClusterModel } from '@seahorse/domain';
import { ShipFleetGroupModel } from '../models/fleet-group.model';
import { FitBoundsModel } from '../models/map-interaction.model';
import { RouteCalculatorResponseModel } from '@seahorse/domain';
import { MapDataService } from '@seahorse/domain';
import { CoordinatePointModel } from '@seahorse/domain';

@Injectable()
export class MapInteractionService implements OnDestroy {
  private _subscriptions = new Subscription();
  map: mapboxgl.Map;
  isAreaActivityOpen = false;

  // Observable sources, for two-way communication
  private fitBoundsSource = new Subject<FitBoundsModel>();
  private markerSource = new Subject<AISMarkerModel[]>();
  private clusterSource = new Subject<ClusterModel[]>();
  private shipFleetAddSource = new Subject<ShipFleetGroupModel>();
  private shipFleetRemoveSource = new Subject<ShipFleetGroupModel>();
  private addShipToNoGroupSource = new Subject<ShipFleetGroupModel>();
  private removeShipFromNoGroupSource = new Subject<ShipFleetGroupModel>();
  private showPolygonSource = new Subject<CoordinatePointModel[]>();
  private areaActivityMarkers = new Subject<AISMarkerModel[]>();
  private startEtaCalculationSource = new Subject<[]>();
  private selectPopupSource = new Subject<AISMarkerModel>();
  private routeSource = new Subject<RouteCalculatorResponseModel>();

  // Observable streams
  fitBoundsCalled$ = this.fitBoundsSource.asObservable();
  markersLoaded$ = this.markerSource.asObservable();
  clustersLoaded$ = this.clusterSource.asObservable();

  shipToFleetAdded$ = this.shipFleetAddSource.asObservable();
  removedShipFromFleet$ = this.shipFleetRemoveSource.asObservable();

  addShipToNoGroup$ = this.addShipToNoGroupSource.asObservable();
  removeShipFromNoGroup$ = this.removeShipFromNoGroupSource.asObservable();

  showPolygonCalled$ = this.showPolygonSource.asObservable();
  areaActivityMarkers$ = this.areaActivityMarkers.asObservable();

  etaCalculationStarted$ = this.startEtaCalculationSource.asObservable();
  popupSelected$ = this.selectPopupSource.asObservable();

  routeLoaded$ = this.routeSource.asObservable();

  constructor(
    private environment: CaEnvService,
    private mapDataService: MapDataService
  ) {
    mapboxgl.accessToken = this.environment.mapBoxAccessToken;
  }

  drawRoute(route: RouteCalculatorResponseModel) {
    this.routeSource.next(route);
  }

  showPolygon(points: CoordinatePointModel[]) {
    this.showPolygonSource.next(points);
  }

  fitBounds(bounds: mapboxgl.LngLat[], options: any) {
    if (!bounds || bounds.length !== 2 || !bounds[0] || !bounds[1]) {
      return;
    }

    const args = new FitBoundsModel();
    args.bounds = bounds;
    args.options = options;

    this.fitBoundsSource.next(args);
  }

  loadMarkers(listName: string) {
    // this.mapDataService.getMarkers(listName).subscribe(markers => {
    //   this.markerSource.next(markers);
    // });
  }

  getPositionsForArea(
    top: number,
    right: number,
    bottom: number,
    left: number
  ) {
    if (!this.isAreaActivityOpen) {
      this._subscriptions.add(
        this.mapDataService
          .getPositionsForArea(top, right, bottom, left)
          .subscribe(
            (markers) => {
              this.markerSource.next(markers);
            },
            () => {},
            () => {}
          )
      );
    }
  }

  getPositionsForAreaActivity(
    top: number,
    right: number,
    bottom: number,
    left: number
  ) {
    if (this.isAreaActivityOpen) {
      this._subscriptions.add(
        this.mapDataService
          .getPositionsForArea(top, right, bottom, left)
          .subscribe(
            (markers) => {
              this.markerSource.next(markers);
            },
            () => {},
            () => {}
          )
      );
    }
  }

  getMarkersForAreaActivity(markers: AISMarkerModel[]) {
    this.areaActivityMarkers.next(markers);
  }

  getClustersForArea(
    top: number,
    right: number,
    bottom: number,
    left: number,
    zoom: number
  ) {
    this._subscriptions.add(
      this.mapDataService
        .getClustersForArea(top, right, bottom, left, zoom)
        .subscribe(
          (response) => {
            this.clusterSource.next(response.result);
          },
          () => {},
          () => {}
        )
    );
  }

  addShipToFleet(shipFleetGroupModel: ShipFleetGroupModel) {
    this.shipFleetAddSource.next(shipFleetGroupModel);
  }

  removeShipFromFleet(shipFleetGroupModel: ShipFleetGroupModel) {
    this.shipFleetRemoveSource.next(shipFleetGroupModel);
  }

  addShipToNoGroup(shipFleetGroupModel: ShipFleetGroupModel) {
    this.addShipToNoGroupSource.next(shipFleetGroupModel);
  }

  removeShipFromNoGroup(shipFleetGroupModel: ShipFleetGroupModel) {
    this.removeShipFromNoGroupSource.next(shipFleetGroupModel);
  }

  startEtaCalculation() {
    this.startEtaCalculationSource.next();
  }

  selectPopup(marker: AISMarkerModel) {
    this.selectPopupSource.next(marker);
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}
