import {
  Directive,
  TemplateRef,
  ViewContainerRef,
  Input,
  inject,
} from '@angular/core';
import { IdentityServiceBase } from '@seahorse/auth';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[shHasFeature]',
  standalone: true,
})
export class HasFeatureDirective {
  private _hasView = false;

  private _identity = inject(IdentityServiceBase);
  private _templateRef = inject(TemplateRef);
  private _viewContainer = inject(ViewContainerRef);

  @Input('shHasFeature') set hasFeature(feature: string) {
    if (!feature) {
      this.showContent();
      return;
    }

    if (this._identity.hasFeature(feature) && !this._hasView) {
      this.showContent();
      return;
    }

    if (!this._identity.hasFeature(feature) && this._hasView) {
      this.hideContent();
      return;
    }
  }

  private showContent() {
    this._viewContainer.createEmbeddedView(this._templateRef);
    this._hasView = true;
  }

  private hideContent() {
    this._viewContainer.clear();
    this._hasView = false;
  }
}
