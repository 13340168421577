import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  InvoiceWidgetBodyTypes,
  InvoiceWidgetModel,
} from '../../models/invoice-widget.model';
import * as moment from 'moment';
import * as Chart from 'chart.js';
import { Observable } from 'rxjs';
import { ResultWrapper } from '@seahorse/common';
import {
  IntervalModel,
  OptionFieldModel,
} from '../../../invoicing-and-tariffs/modules/invoicing/models/invoice-display-config.model';
import { PERIODS } from '../../../reports/models/reports-chart-coordinates.model';
import { StatisticsDataService } from '../../../reports/services/statistics-data.service';
import { DataSetRequestModel } from '../../../reports/models/data-set-request.model';
import { TranslateService } from '@ngx-translate/core';
import { FieldType, InvoiceDataService } from '@seahorse/domain';
import { ChartDataService } from '../../../dashboard/services/chart-data-service';

@Component({
  selector: 'ca-invoice-widget',
  templateUrl: './invoice-widget.component.html',
})
export class InvoiceWidgetComponent implements OnChanges, AfterViewInit {
  @Input() widget: InvoiceWidgetModel;
  @Input() noBorder: false;
  invoiceWidgetBodyTypes = InvoiceWidgetBodyTypes;

  //DETAILS
  primaryValue = null;
  secondaryItems = [];

  //CHART
  @ViewChild('invoiceChart', { static: false })
  invoiceChart;
  chart: {
    canvas: any;
    ctx: any;
    chart: Chart;
  };

  //METADATA
  private columnGrouping: OptionFieldModel = null;
  private periods = PERIODS;
  isLoading = false;

  //METADATA BAR
  labels: string[] = [];

  //COMMON
  intervalOptions = [];
  items: any[] = [];

  viewInitialized = false;

  constructor(
    private invoiceData: InvoiceDataService,
    private chartDataService: ChartDataService,
    private statisticsDataService: StatisticsDataService,
    private translate: TranslateService
  ) {
    this.chart = {
      canvas: null,
      ctx: null,
      chart: null,
    };

    this.columnGrouping = new OptionFieldModel();
    this.columnGrouping.category = 'Invoice';
    this.columnGrouping.fieldName = 'InvoiceDate';
    this.columnGrouping.fieldType = FieldType.Date;
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.viewInitialized = true;
      this.primaryValue = null;
      this.secondaryItems = [];
      this.items = [];

      if (this.widget.intervals.length > 0) {
        this.setStartPeriod();
      }

      if (this.widget.bodyType === InvoiceWidgetBodyTypes.Chart) {
        this.initChart();
      }

      if (this.widget.bodyType === InvoiceWidgetBodyTypes.MetaData) {
        if (this.widget.chartConfiguration.type === 'bar') {
          this.initChartMetaDataBar();
        } else {
          this.initChart();
        }
      }

      this.loadTotals();
    }, 0);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.widget && this.viewInitialized) {
      this.primaryValue = null;
      this.secondaryItems = [];
      this.items = [];

      if (this.widget.intervals.length > 0) {
        this.setStartPeriod();
      }

      if (this.widget.bodyType === InvoiceWidgetBodyTypes.Chart) {
        this.initChart();
      }

      if (this.widget.bodyType === InvoiceWidgetBodyTypes.MetaData) {
        if (this.widget.chartConfiguration.type === 'bar') {
          this.initChartMetaDataBar();
        } else {
          this.initChart();
        }
      }

      this.loadTotals();
    }
  }

  intervalChanged() {
    this.loadTotals();
  }

  initChart() {
    if (this.chart && this.chart.chart) {
      this.chart.chart.destroy();
    }

    this.chart.canvas = this.invoiceChart.nativeElement;
    this.chart.ctx = this.chart.canvas.getContext('2d');
    this.chart.chart = new Chart(this.chart.ctx, {
      type: this.widget.chartConfiguration.type,
      data: {
        datasets: [],
      },
      options: {
        legend: {
          display: this.widget.chartConfiguration.showLegend,
        },
        tooltips: {
          callbacks: {
            label: function (context, data) {
              return (
                '€ ' +
                data.datasets[context.datasetIndex].data[
                  context.index
                ].toLocaleString('nl-NL') +
                ' - ' +
                data.labels[context.index].toString()
              );
            },
          },
        },
      },
    });
  }

  initChartMetaDataBar() {
    if (this.chart && this.chart.chart) {
      this.chart.chart.destroy();
    }

    this.chart.canvas = this.invoiceChart.nativeElement;
    this.chart.ctx = this.chart.canvas.getContext('2d');
    this.chart.chart = new Chart(this.chart.ctx, {
      type: 'bar',
      data: {
        datasets: [],
      },
      options: {
        legend: {
          display: this.widget.chartConfiguration.showLegend,
        },
        tooltips: {
          callbacks: {
            label: function (context, data) {
              return (
                '€ ' +
                data.datasets[context.datasetIndex].data[
                  context.index
                ].toLocaleString('nl-NL') +
                ' - ' +
                data.datasets[context.datasetIndex].label
              );
            },
          },
        },
        responsive: true,
        scales: {
          xAxes: [
            {
              stacked: true,
            },
          ],
          yAxes: [
            {
              stacked: true,
            },
          ],
        },
      },
    });
  }

  loadTotals() {
    let dateFrom,
      dateUntil = null;

    if (
      this.widget.bodyType === InvoiceWidgetBodyTypes.Details ||
      this.widget.bodyType === InvoiceWidgetBodyTypes.Chart
    ) {
      if (this.widget.intervals.length === 0) {
        dateFrom = '2000-01-01';
        dateUntil = moment().add(1, 'days').format('YYYY-MM-DD');
      } else {
        switch (this.widget.intervalType) {
          case 'year': {
            const dt = moment(this.widget.intervalValue + '-01-01');
            dateFrom = dt.format('YYYY-MM-DD');
            dateUntil = dt.add(1, 'year').format('YYYY-MM-DD');
            break;
          }
          case 'month': {
            const dt = moment(this.widget.intervalValue + '-01');
            dateFrom = dt.format('YYYY-MM-DD');
            dateUntil = dt.add(1, 'month').format('YYYY-MM-DD');
            break;
          }
        }
      }
    }

    if (this.widget.bodyType === InvoiceWidgetBodyTypes.Chart) {
      let dataCall: Observable<ResultWrapper<any>> = null;

      switch (this.widget.chartConfiguration.dataBreakdown) {
        case 'customer':
          dataCall = this.invoiceData.getTotals(
            this.widget.statuses,
            dateFrom,
            dateUntil,
            this.widget.chartConfiguration.dataBreakdown
          );
          break;
      }

      dataCall.subscribe((result) => {
        this.items = [];
        if (result && result.result && result.result.length > 0) {
          this.items = result.result;
          this.setChart();
        }
      });
    }

    if (this.widget.bodyType === InvoiceWidgetBodyTypes.Details) {
      this.invoiceData
        .getTotals(this.widget.statuses, dateFrom, dateUntil)
        .subscribe((result) => {
          this.primaryValue = null;
          this.secondaryItems = [];

          if (result && result.result && result.result.length > 0) {
            if (
              !this.widget.detailsConfiguration.primaryDisplay ||
              this.widget.detailsConfiguration.primaryDisplay === ''
            ) {
              this.primaryValue = result.result[0];
              this.secondaryItems = result.result.splice(1);
            } else {
              result.result.forEach((item) => {
                if (
                  item.key === this.widget.detailsConfiguration.primaryDisplay
                ) {
                  this.primaryValue = item;
                } else {
                  if (
                    this.widget.detailsConfiguration.secondaryDisplay &&
                    this.widget.detailsConfiguration.secondaryDisplay.length > 0
                  ) {
                    if (
                      this.widget.detailsConfiguration.secondaryDisplay.indexOf(
                        item.key
                      ) > -1
                    ) {
                      this.secondaryItems.push(item);
                    }
                  } else {
                    this.secondaryItems.push(item);
                  }
                }
              });
            }
          }
        });
    }

    if (this.widget.bodyType === InvoiceWidgetBodyTypes.MetaData) {
      if (this.widget.intervals.length === 0) {
        dateFrom = moment('2000-01-01', 'YYYY-MM-DD');
        dateUntil = moment().add(1, 'days');
      } else {
        switch (this.widget.intervalType) {
          case 'year':
            dateFrom = moment(this.widget.intervalValue + '-01-01');
            dateUntil = moment(this.widget.intervalValue + '-01-01').add(
              1,
              'year'
            );
            break;
          case 'month':
            dateFrom = moment(this.widget.intervalValue + '-01');
            dateUntil = moment(this.widget.intervalValue + '-01').add(
              1,
              'month'
            );
            break;
        }
      }

      const rowGrouping = new OptionFieldModel();
      rowGrouping.category = 'MetaData';
      rowGrouping.fieldName = this.widget.metaDataConfiguration.groupingField;
      rowGrouping.fieldType = FieldType.SingleLineText;

      if (this.widget.chartConfiguration.type !== 'bar') {
        const period = this.periods.find(
          (p) => p.name.toLowerCase() === this.widget.intervalType.toLowerCase()
        );
        this.columnGrouping.intervals = [
          new IntervalModel(
            dateFrom.format('YYYY-MM-DD[T]HH:mm:ss'),
            !period ? '4' : period.id.toString()
          ),
          new IntervalModel(dateUntil.format('YYYY-MM-DD[T]HH:mm:ss'), null),
        ];
        const dataSetRequest: DataSetRequestModel = new DataSetRequestModel(
          dateFrom.format('YYYY-MM-DD[T]HH:mm:ss'),
          dateUntil.format('YYYY-MM-DD[T]HH:mm:ss'),
          rowGrouping,
          this.columnGrouping,
          'InvoiceDate',
          null
        );

        this.isLoading = true;
        this.statisticsDataService
          .generateRevenueReport(dataSetRequest)
          .subscribe(
            (result) => {
              this.isLoading = false;
              this.items = [];
              if (result && result.result && result.result.length > 0) {
                this.items = result.result[0].statistics.sort(
                  (a, b) => b.amount - a.amount
                );
                this.setChartMetaData();
              }
            },
            (e) => {
              this.isLoading = false;
            }
          );
      }

      if (this.widget.chartConfiguration.type === 'bar') {
        const columnInterval =
          this.widget.metaDataConfiguration.columnInterval === undefined ||
          this.widget.metaDataConfiguration.columnInterval === null
            ? this.widget.intervalValue
            : this.widget.metaDataConfiguration.columnInterval;
        const period = this.periods.find(
          (p) => p.name.toLowerCase() === columnInterval.toLowerCase()
        );
        this.columnGrouping.intervals = [
          new IntervalModel(
            dateFrom.format('YYYY-MM-DD[T]HH:mm:ss'),
            !period ? '2' : period.id.toString()
          ),
          new IntervalModel(dateUntil.format('YYYY-MM-DD[T]HH:mm:ss'), null),
        ];
        const dataSetRequest: DataSetRequestModel = new DataSetRequestModel(
          dateFrom.format('YYYY-MM-DD[T]HH:mm:ss'),
          dateUntil.format('YYYY-MM-DD[T]HH:mm:ss'),
          rowGrouping,
          this.columnGrouping,
          'InvoiceDate',
          null
        );

        this.isLoading = true;
        this.statisticsDataService
          .generateRevenueReport(dataSetRequest)
          .subscribe(
            (result) => {
              this.isLoading = false;
              this.items = [];
              this.labels = [];
              if (result && result.result) {
                result.result.forEach((g) => {
                  this.labels.push(g.interval);
                  this.items.push(g.statistics);
                });
                this.setChartMetaDataBar();
              }
            },
            (e) => {
              this.isLoading = false;
            }
          );
      }
    }
  }

  setStartPeriod() {
    let _curDate =
      this.widget.minDate === null || this.widget.minDate === ''
        ? moment().add(-1, 'year')
        : moment(this.widget.minDate);
    const _maxDate =
      this.widget.maxDate === null || this.widget.maxDate === ''
        ? moment().add(10, 'minute')
        : moment(this.widget.maxDate);

    this.intervalOptions = [];

    switch (this.widget.intervalType) {
      case 'year':
        {
          while (_curDate.isBefore(_maxDate)) {
            const val = _curDate.format('YYYY');
            this.intervalOptions.push({
              name: val,
              value: val,
            });
            if (val === moment().format('YYYY')) {
              this.widget.intervalValue = val;
            }

            _curDate = _curDate.add(1, 'year');
          }
        }
        break;
      case 'month':
        {
          while (_curDate.isBefore(_maxDate)) {
            const val = _curDate.format('YYYY-MM');
            this.intervalOptions.push({
              name: _curDate.format('MMM YYYY'),
              value: val,
            });
            if (val === moment().format('YYYY-MM')) {
              this.widget.intervalValue = val;
            }

            _curDate = _curDate.add(1, 'month');
          }
        }
        break;
    }
  }

  setChart() {
    this.chart.chart.data.datasets = [];

    if (!this.items || this.items.length === 0) {
      return;
    }

    const coordinates = this.items.map((item) => {
      return item.amount;
    });
    const labels = this.items.map((item) => {
      return item.description;
    });
    const newDataSet = {
      backgroundColor: [
        '#414143',
        '#d42323',
        '#75cac2',
        '#e97272',
        '#e6622b',
        '#686868',
        '#ffe654',
        '#7e91da',
        '#fab14a',
        '#415dc9',
      ],
      label: 'test',
      data: coordinates,
    };

    this.chart.chart.data.datasets.push(newDataSet);
    this.chart.chart.data.labels = labels;

    this.chart.chart.update();
  }

  setChartMetaData() {
    this.chart.chart.data.datasets = [];

    if (!this.items || this.items.length === 0) {
      return;
    }

    let total = 0;
    const percentages = [];
    const coordinates = this.items.map((item) => {
      total += item.amount;
      return item.amount;
    });

    const labels = this.items.map((item) => {
      const perc = Math.round((item.amount / total) * 10000) / 100;
      percentages.push(perc);
      return item.key === null || item.key === undefined
        ? this.translate.instant('shared.terms.none')
        : item.key;
    });

    const bgColors = this.chartDataService.getBackgroundColors(
      this.items.length
    );

    const newDataSet = {
      backgroundColor: bgColors,
      label: 'test',
      data: coordinates,
    };

    this.chart.chart.data.datasets.push(newDataSet);
    this.chart.chart.data.labels = labels;
    this.chart.chart.data['percentages'] = percentages;

    this.chart.chart.update();
  }

  setChartMetaDataBar() {
    this.chart.chart.data.datasets = [];

    if (!this.items || this.items.length === 0) {
      return;
    }

    let nrOfSet = 0;
    const groups: {
      [key: string]: {
        data: number[];
        label: string;
        total: number;
        backgroundColor?: string;
      };
    } = {};

    this.items.forEach((statistics, index) => {
      statistics.forEach((statistic) => {
        let obj = groups[statistic.key];
        if (obj === undefined) {
          const key =
            statistic.key === null || statistic.key === undefined
              ? this.translate.instant('shared.terms.none')
              : statistic.key;
          obj = { data: [], label: key, total: 0 };
          groups[key] = obj;
          nrOfSet++;
        }
        obj.data[index] = statistic.amount;
        obj.total = obj.total + statistic.amount;
      });
    });

    let datasets = Object.values(groups).sort((a, b) => b.total - a.total);
    const bgColors = this.chartDataService.getBackgroundColors(datasets.length);

    datasets = datasets.map((dataset, index) => {
      dataset['backgroundColor'] = bgColors[index];
      return dataset;
    });
    this.chart.chart.data.datasets = datasets;
    this.chart.chart.data.labels = this.labels;

    this.chart.chart.update();
  }
}
