import { Component, Input } from '@angular/core';
import { TaskModel } from '../../models/task.model';

@Component({
  selector: 'ca-follow-up-tasks-table',
  templateUrl: './follow-up-tasks-table.component.html',
  styleUrls: ['./follow-up-tasks-table.component.scss'],
})
export class FollowUpTasksTableComponent {
  @Input() followUpTasks: TaskModel[];
}
