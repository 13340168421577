import { InvoiceStatuses } from '@seahorse/domain';

export class InvoiceWidgetModel {
  static default(): InvoiceWidgetModel {
    return {
      title: 'Invoice',
      bodyType: null,
      intervalType: null,
      intervalValue: null,
      maxDate: null,
      minDate: null,
      statuses: [],
      intervals: [],
      reload: false,
      detailsConfiguration: {
        primaryDisplay: 'amount',
        secondaryDisplay: [],
      },
      chartConfiguration: {
        dataBreakdown: 'customer',
        type: 'doughnut',
        showLegend: true,
      },
      metaDataConfiguration: {
        groupingField: 'KostenPlaats',
        columnInterval: null,
      },
    };
  }
}

export interface InvoiceWidgetModel {
  title: string;
  bodyType: InvoiceWidgetBodyTypes;
  intervalType: string;
  intervalValue: string;
  maxDate: string;
  minDate: string;
  statuses: InvoiceStatuses[];
  intervals: string[];
  reload: boolean;
  detailsConfiguration?: {
    primaryDisplay: string;
    secondaryDisplay: string[];
  };
  chartConfiguration?: {
    dataBreakdown: string;
    type: 'pie' | 'bar' | 'line' | 'doughnut';
    showLegend: boolean;
  };
  metaDataConfiguration: {
    groupingField: string;
    columnInterval: string;
  };
}

export enum InvoiceWidgetBodyTypes {
  Chart,
  Details,
  MetaData,
}

export enum InvoiceWidgetIntervalTypes {
  Chart,
  Details,
}
