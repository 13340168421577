import { Component, Input, Output, EventEmitter } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'underscore';

import { EventModel } from '../../../../../../shared/models/event.model';
import { FlowDetailsModalComponent } from '../flow-details-modal/flow-details-modal.component';
import { FlowModel } from '@seahorse/domain';

@Component({
  selector: 'flow-details-info',
  templateUrl: 'flow-details-info.component.html',
})
export class FlowDetailsInfoComponent {
  @Input() flow: FlowModel;
  @Input() loadingFlow: boolean;

  @Output() onEvent: EventEmitter<EventModel>;

  constructor(private modal: NgbModal) {
    this.flow = null;
    this.loadingFlow = false;
    this.onEvent = new EventEmitter<EventModel>();
  }

  editFlow() {
    const flowDetailsModal = this.modal.open(FlowDetailsModalComponent);

    // @Input()
    flowDetailsModal.componentInstance.flow = _.clone(this.flow);
    flowDetailsModal.componentInstance.loadingFlow = this.loadingFlow;

    // @Output()
    flowDetailsModal.componentInstance.onEvent.subscribe(
      (flowDetailsEvent: EventModel) => this.onEvent.emit(flowDetailsEvent)
    );
  }
}
