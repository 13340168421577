<div class="modal-header">
  <div class="modal-title">{{ title }}</div>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="modal.dismiss()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form>
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label for="dropdown">{{ label }}:</label>
          <input
            id="typeahead-input"
            name="dropdown"
            type="text"
            class="form-control"
            [(ngModel)]="selected"
            [ngbTypeahead]="search"
            [inputFormatter]="formatterDisplay"
            [resultFormatter]="formatterSearch"
            placeholder="{{ placeholder }}"
            [editable]="false"
            (click)="onFocus($event)"
          />
        </div>
      </div>
    </div>
    <div class="d-flex">
      <div class="flex-grow-1">
        <button
          type="button"
          class="btn btn-block btn-link"
          (click)="dismiss(null)"
        >
          Annuleren
        </button>
      </div>
      <div class="flex-grow-1">
        <button
          type="button"
          class="btn btn-block btn-success"
          (click)="save()"
        >
          Opslaan
        </button>
      </div>
    </div>
  </form>
</div>
