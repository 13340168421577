<div class="card">
  <div class="card-header">
    {{ title | translate }}
    <div class="btn-list">
      <button
        *ngIf="archive"
        [disabled]="loading || !files || (files && !files.length)"
        (click)="filesZip()"
        type="button"
        class="btn btn-outline-secondary btn-sm"
      >
        <i class="fa-regular fa-file-archive fa-fw"></i>
        {{ 'shared.terms.downloadAll' | translate }}
      </button>
      <button
        *ngIf="!readonly || inbox"
        [disabled]="loading"
        (click)="componentService.filesAdd(this)"
        type="button"
        class="btn btn-outline-secondary btn-sm"
      >
        <i class="fa-regular fa-plus fa-fw"></i>
        {{ 'shared.terms.add' | translate }}
      </button>
    </div>
  </div>
  <div
    class="card-body card-body-table"
    tempFileDragDrop
    [objectId]="objectId"
    [objectType]="objectType"
    [object]="object"
    [isFileDroppedFromPage]="isFileDroppedFromPage"
    (fileUploaded)="fileUploaded($event)"
  >
    <ng-container
      *ngIf="!loading; then filesHasLoaded; else filesIsLoading"
    ></ng-container>
    <ng-template #filesHasLoaded>
      <ng-container
        *ngIf="files && files.length; then filesShow; else filesHide"
      ></ng-container>
      <ng-template #filesShow>
        <table
          *ngIf="fileTypes().length"
          class="table table-clickable table-truncated"
        >
          <thead *ngIf="fileTypes('images').length">
            <tr>
              <th>{{ 'shared.terms.files' | translate }}</th>
              <th class="w-25"></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let file of fileTypes()"
              class="card-body-group"
              [ngClass]="{ 'bg-inbox': file.inbox }"
            >
              <td
                class="text-truncate"
                (click)="filePreview(file)"
                title="{{ 'shared.terms.preview' | translate }}"
              >
                <i
                  [ngClass]="fileIcon(file.fileType)"
                  class="fa-regular fa-fw"
                ></i>
                {{ file.description }}
              </td>
              <td style="text-align: right; width: 1%">
                <i
                  *ngIf="file.hasLinkedObjects && !readonly"
                  class="fa-solid fa-object-subtract mr-4 cursor-pointer"
                  (click)="
                    componentService.openImportLinkedObjectsModal(file, this)
                  "
                ></i>
                <i *ngIf="!file.isPrivate" class="fa-regular fa-lock-open"></i>
                <i *ngIf="file.isPrivate" class="fa-regular fa-lock"></i>
              </td>
              <td class="btn-list">
                <div class="dropdown p-0">
                  <button
                    type="button"
                    class="btn btn-light dropdown-toggle"
                    id="rowMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fa-solid fa-ellipsis-v fa-fw"></i>
                  </button>
                  <div
                    class="btn dropdown-menu"
                    aria-labelledby="rowMenuButton"
                  >
                    <button
                      *ngIf="!readonly"
                      class="dropdown-item"
                      (click)="updatePrivacy(file)"
                    >
                      <i class="fa-regular fa-unlock"></i>
                      {{ 'files.model.changePrivacy' | translate }}
                    </button>
                    <button class="dropdown-item" (click)="fileDownload(file)">
                      <i class="fa-regular fa-download fa-fw"></i>
                      {{ 'shared.terms.download' | translate }}
                      ({{ file.size | fileSize }})
                    </button>
                    <button
                      *ngIf="!readonly"
                      class="dropdown-item"
                      (click)="onDeleteClick(file)"
                    >
                      <i class="fa-solid fa-trash fa-fw"></i>
                      {{ 'shared.terms.delete' | translate }}
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <table *ngIf="fileTypes('images').length" class="table table-truncated">
          <thead *ngIf="fileTypes().length">
            <tr>
              <th>{{ 'shared.terms.images' | translate }}</th>
              <th class="w-25"></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let file of fileTypes('images')"
              class="card-body-group"
            >
              <td class="text-truncate">
                <i
                  [ngClass]="fileIcon(file.fileType)"
                  class="fa-regular fa-fw"
                ></i>
                {{ file.description }}
              </td>
              <td style="text-align: right">
                <i *ngIf="!file.isPrivate" class="fa-regular fa-lock-open"></i>
                <i *ngIf="file.isPrivate" class="fa-regular fa-lock"></i>
              </td>
              <td class="btn-list">
                <div class="dropdown p-0">
                  <button
                    type="button"
                    class="btn btn-light dropdown-toggle"
                    id="rowMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fa-solid fa-ellipsis-v fa-fw"></i>
                  </button>
                  <div
                    class="btn dropdown-menu"
                    aria-labelledby="rowMenuButton"
                  >
                    <button
                      *ngIf="!readonly"
                      class="dropdown-item"
                      (click)="updatePrivacy(file)"
                    >
                      <i class="fa-regular fa-unlock"></i>
                      {{ 'files.model.changePrivacy' | translate }}
                    </button>
                    <button class="dropdown-item" (click)="fileDownload(file)">
                      <i class="fa-regular fa-download fa-fw"></i>
                      {{ 'shared.terms.download' | translate }}
                      ({{ file.size | fileSize }})
                    </button>
                    <button
                      *ngIf="!readonly"
                      class="dropdown-item"
                      (click)="fileDelete(file)"
                    >
                      <i class="fa-solid fa-trash fa-fw"></i>
                      {{ 'shared.terms.delete' | translate }}
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </ng-template>
      <ng-template #filesHide>
        <div class="text-center text-muted py-3">
          {{ 'files.fileListCard.notFound' | translate }}
        </div>
      </ng-template>
    </ng-template>
    <ng-template #filesIsLoading>
      <sh-loading-indicator class="d-block text-center"></sh-loading-indicator>
    </ng-template>
  </div>
</div>
