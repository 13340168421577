import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TariffAmountTypes } from '@seahorse/domain';
import * as _ from 'underscore';

@Pipe({ name: 'tariffAmountType' })
export class TariffAmountTypesPipe implements PipeTransform {
  tariffAmountTypes: object;

  constructor(private translate: TranslateService) {
    this.tariffAmountTypes = {
      [TariffAmountTypes.Number]:
        _.keys(TariffAmountTypes)[
          _.indexOf(_.values(TariffAmountTypes), TariffAmountTypes.Number)
        ],
      [TariffAmountTypes.Percentage]:
        _.keys(TariffAmountTypes)[
          _.indexOf(_.values(TariffAmountTypes), TariffAmountTypes.Percentage)
        ],
    };
  }

  transform(tariffAmountType: TariffAmountTypes): string {
    let pipe: string;

    this.translate
      .get(this.tariffAmountTypes[tariffAmountType])
      .subscribe((t) => (pipe = t));

    return pipe;
  }
}
