<div class="modal-header">
  <h1 class="modal-title">
    {{ title }}
  </h1>
  <button closeModal type="button" class="btn btn-clear">
    <i class="fa-solid fa-times fa-fw"></i>
  </button>
</div>
<div class="modal-body">
  <ca-custom-default-data-checklist
    [(defaultDataObjects)]="defaultDataObjects"
    [dataObjectKey]="dataObjectKey"
    [defaultDataGroup]="defaultDataGroup"
    [customDefaultDataSet]="customDefaultDataSet"
    [loadingCustomDefaultDataSet]="loadingCustomDefaultDataSet"
    [hideDefaultDataGroupPicker]="hideDefaultDataGroupPicker"
  ></ca-custom-default-data-checklist>
</div>
<div class="modal-footer row no-gutters">
  <div class="col">
    <button (click)="cancel()" type="button" class="btn btn-block btn-link">
      {{ 'shared.terms.cancel' | translate }}
    </button>
  </div>
  <div class="col">
    <button (click)="confirm()" type="button" class="btn btn-block btn-primary">
      {{ 'shared.terms.confirm' | translate }}
    </button>
  </div>
</div>
