<div class="sidebar">
  <div *ngFor="let widget of widgets">
    <div
      class="mb-4 widget"
      *ngIf="widget.type === widgetTypes.Invoice"
      (click)="onWidgetClicked(widget)"
    >
      <ca-invoice-widget [widget]="widget.configuration"></ca-invoice-widget>
    </div>
    <div
      class="mb-4 widget"
      *ngIf="widget.type === widgetTypes.Task"
      (click)="onWidgetClicked(widget)"
    >
      <ca-task-widget [widget]="widget.configuration"></ca-task-widget>
    </div>
  </div>
  <div *ngIf="!widgets?.length" class="text-center mt-5">
    <p>{{ 'There are no more widgets in the library' | translate }}</p>
  </div>
</div>
