import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseControl } from '../base.control';

@Component({
  selector: 'sh-password',
  templateUrl: './password.control.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PasswordControl),
      multi: true,
    },
    {
      provide: BaseControl,
      useExisting: forwardRef(() => PasswordControl),
    },
  ],
})
export class PasswordControl extends BaseControl<string | null> {}
