import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'temp-empty-collection',
  template: `
    <div class="text-muted text-center {{ size === 'lg' ? 'p-4' : 'p-3' }}">
      <i
        class="{{ icon }} {{ size === 'lg' ? 'fa-10x mb-4' : 'fa-3x mb-3' }}"
        *ngIf="icon"
      ></i>
      <div class="{{ size === 'lg' ? 'text-lg' : '' }}">
        <p>{{ message | translate }}</p>
        <ng-content select="[emptyActions]"></ng-content>
      </div>
    </div>
  `,
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule
  ]
})
export class EmptyCollectionTemplate {
  @Input() icon = 'fa-regular fa-boxes';
  @Input() message = 'shared.terms.noResult';
  @Input() size = 'lg';
}
