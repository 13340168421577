import { BaseModel } from '@seahorse/common';
import { ValidationResult } from '@seahorse/common';
import {
  CompanyFinancialModel,
  CompanyModel,
} from '../../../companies/models/company.model';

import { FlowModel } from '../../invoicing-flows/models/flow.model';
import { InvoiceEventModel } from './invoice-event.model';
import { InvoiceLineModel } from './invoice-line.model';
import { InvoiceMetaDataModel } from './invoice-metadata.model';

export class InvoiceModel extends BaseModel {
  additionalData: unknown;
  guid: string;
  number: string;
  organizationId: string;
  companyId?: number;
  companyFinancialId?: number;
  customerReference: string;
  invoiceDate: Date;
  payByDate?: Date;
  status: number;
  invoiceType: number;
  isFinalized: boolean;
  relatedInvoiceId?: number;
  relatedInvoiceType?: number;
  description: string;
  totalDiscount?: number;
  totalNett?: number;
  totalVat?: number;
  totalAmount?: number;
  totalSurcharge?: number;
  currency: string;
  internalRemarks: string;
  invoiceFlow?: FlowModel;
  invoiceFlowId?: number;
  invoiceFlowExecutionId?: number;
  companyName: string;
  companyNumber: string;
  companyAddresses: string;
  company?: CompanyModel;
  companyFinancial?: CompanyFinancialModel;
  invoiceEvents?: InvoiceEventModel[];
  invoiceLines?: InvoiceLineModel[];
  metaData?: InvoiceMetaDataModel[];
  hasSuggestions: boolean;
  isChecked: boolean;
  invoiceCompanies?: CompanyFinancialModel[];

  debtorNumber: string;
  vatNumber: string;
  emailAddress: string;
  IBAN: string;
  BIC: string;
  allowSepa: boolean;
  isCredited = false;

  validate(): ValidationResult {
    return new ValidationResult();
  }

  constructor() {
    super();

    this.id = null;
    this.guid = null;
    this.number = null;
    this.organizationId = null;
    this.companyId = null;
    this.companyFinancialId = null;
    this.customerReference = null;
    this.invoiceDate = null;
    this.payByDate = null;
    this.status = null;
    this.invoiceType = null;
    this.relatedInvoiceId = null;
    this.description = null;
    this.totalNett = null;
    this.totalVat = null;
    this.totalAmount = null;
    this.totalSurcharge = null;
    this.currency = null;
    this.internalRemarks = null;
    this.invoiceFlowExecutionId = null;
    this.invoiceFlowId = null;
    this.company = null;
    this.companyName = null;
    this.companyNumber = null;
    this.companyFinancial = null;
    this.invoiceEvents = null;
    this.invoiceLines = null;
    this.metaData = null;
  }
}

export enum InvoiceTypes {
  Proforma = 1,
  Regular = 2,
  Credit = 3,
}

export enum InvoiceStatuses {
  InProgress = 1,
  PendingApproval = 2,
  Open = 3,
  FirstReminder = 4,
  Paid = 5,
  Cancelled = 6,
  Exported = 7,
  PendingVerification = 8,
}
