import { Component, Input, Output, EventEmitter } from '@angular/core';

import { EventModel } from '../../../../../../shared/models/event.model';

@Component({
  selector: 'conditions-modal',
  templateUrl: 'conditions-modal.component.html',
})
export class ConditionsModalComponent {
  @Input() condition: any;

  @Output() onEvent: EventEmitter<EventModel>;

  constructor() {
    this.condition = null;
    this.onEvent = new EventEmitter<EventModel>();
  }
}
