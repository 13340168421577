export class CoordinatePointModel {
  latitude: number;
  longitude: number;
}

export class CoordinatePolygonModel {
  points: CoordinatePointModel[];
}

export class CoordinateBoundingBoxModel {
  topLeft: CoordinatePointModel;
  bottomRight: CoordinatePointModel;
}

export class FeatureCollectionModel {
  type: string;
  features: FeatureModel[];
}

export class FeatureModel {
  type: string;
  geometry: FeatureGeometryModel;
  properties: FeatureProperties;
}

export class FeatureGeometryModel {
  type: string;
}

export class FeatureProperties {
  values: { [key: string]: any };
}
