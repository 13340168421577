import {
  Attribute,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  ExportOptionItemModel,
  OptionType,
} from '../../../content-distribution/models/content-distribtion-export-option.model';
import { ContentDistributionRecipientModel } from '../../../content-distribution/models/content-distribution-recipient.model';
import { GenericDataExportModel } from '../../../content-distribution/models/generic-data-export.model';
import { ContentDistributionCacheService } from '../../../content-distribution/services/content-distribution-cache.service';
import { ContentDistributionDataService } from '../../../content-distribution/services/content-distribution-data.service';
import { IdentityService } from '../../../core/services/identity.service';
import {
  CustomDataSearchFieldModel,
  FileService,
  ObjectDefinitionModel,
} from '@seahorse/domain';
import { GenericMessageModalComponent } from '../../../layout/components/generic-message-modal/generic-message-modal.component';
import { ServiceConnectionsDataService } from '../../../service-connections/services/service-connections.service';
import { of, Subject, Subscription } from 'rxjs';
import { debounceTime, map, mergeMap } from 'rxjs/operators';
import * as _ from 'underscore';

import { ExportSelectRecipientModalComponent } from '../export-select-recipient-modal/export-select-recipient-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { ResultStatus } from '@seahorse/common';
import { ExportTemplateDataService } from '../../services/export-template.service';
import { ModalAction, ModalService } from '@seahorse/temp';
import { ExportFilterOptionsModalComponent } from '../export-filter-options/export-filter-options-modal.component';

@Component({
  selector: 'ca-content-distribution-export-button',
  templateUrl: 'export-button.component.html',
})
export class ContentDistributionExportButtonComponent
  implements OnInit, OnChanges, OnDestroy
{
  exportOptions: ExportOptionItemModel[];
  sendOptions: ExportOptionItemModel[];
  storeOptions: ExportOptionItemModel[];
  isExporting = false;
  isSending = false;
  isStoring = false;

  private _getOptionsSub = new Subject<void>();
  getOptions$ = this._getOptionsSub.asObservable();

  @Input() contentModule: string;

  _contentType: string;
  @Input() set contentType(value: string) {
    this._contentType = value;
    this._getOptionsSub.next();
  }

  get contentType(): string {
    return this._contentType;
  }

  _systemCode: string;
  @Input() set systemCode(value: string) {
    this._systemCode = value;
    this._getOptionsSub.next();
  }

  get systemCode(): string {
    return this._systemCode;
  }

  @Input() exportData: GenericDataExportModel;
  @Input() objectDefinition: ObjectDefinitionModel;
  @Input() objectIds: any[];
  @Input() data: any;
  @Input() recipients: ContentDistributionRecipientModel[];
  @Input() multiple = false;
  @Input() fallback = false;
  @Input() objectType: string = null;
  @Input() renderAs = 'button';
  @Input() searchFields: CustomDataSearchFieldModel[] = null;

  /**
   * When this boolean is true, no export/distribute action will executed at
   * this component. But only an event will be raised, so the place where this
   * component is used will handle the export/distribute event/action
   */
  @Input() skipAction = false;

  @Output() buttonClicked = new EventEmitter<ExportOptionItemModel>();
  @Output() itemExported = new EventEmitter<any>();
  @Output() itemStored = new EventEmitter<any>();
  buttonClass: string | null = null;

  private get _fallbackModule() {
    return 'generic';
  }

  private get _fallbackType() {
    return this.multiple ? 'generic-table' : 'generic-table';
  }

  private _subscription = new Subscription();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private contentDistributionCache: ContentDistributionCacheService,
    private fileService: FileService,
    private contentDistributionService: ContentDistributionDataService,
    private serviceConnectionsService: ServiceConnectionsDataService,
    private modal: NgbModal,
    private identityService: IdentityService,
    private translate: TranslateService,
    private _exportTemplateDataService: ExportTemplateDataService,
    private _modalService: ModalService,
    @Attribute('size') _size: string | null
  ) {
    this.buttonClass = _size ? `btn-${_size}` : null;

    this.getOptions$
      .pipe(debounceTime(300))
      .subscribe(() => this.setUpOptions());
  }

  ngOnInit() {
    this._getOptionsSub.next();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['exportData'] && !changes['exportData'].isFirstChange()) {
      this.setUpOptions();
    }
  }

  private setUpOptions() {
    if (!this.contentModule || !this.contentType) {
      return;
    }

    this.getOptions(this.contentModule, this.contentType, this.systemCode)
      .pipe(
        mergeMap(([exportOpt, sendOpt]) => {
          const response = [exportOpt, sendOpt];

          if (exportOpt.length && sendOpt.length) {
            return of(response);
          }

          if (!this.fallback) {
            return of(response);
          }

          return this.getOptions(
            this._fallbackModule,
            this._fallbackType,
            this.systemCode
          ).pipe(
            map(([fallbackExportOpt, fallbackSendOpt]) => {
              response[0] = response[0].length
                ? response[0]
                : fallbackExportOpt;
              response[1] = response[1].length ? response[1] : fallbackSendOpt;

              return response;
            })
          );
        })
      )
      .subscribe(([exportOpt, sendOpt]) => {
        exportOpt = exportOpt.filter((opt) => {
          if (!opt.visibilityCondition || !this.exportData?.data[0]) {
            return opt;
          }

          return this.checkVisibilityCondition(opt);
        });

        sendOpt = sendOpt.filter((opt) => {
          if (!opt.visibilityCondition || !this.exportData?.data[0]) {
            return opt;
          }

          return this.checkVisibilityCondition(opt);
        });

        this.exportOptions = _.sortBy(exportOpt, 'templateName');
        this.sendOptions = _.sortBy(sendOpt, 'templateName');

        this.storeOptions = [...this.exportOptions, ...this.sendOptions]
          .filter((x) => x.enableStore)
          .map((x) => ({ ...x, optionType: OptionType.Store }));

        this.changeDetectorRef?.markForCheck();
      });
  }

  private checkVisibilityCondition(option: any): boolean {
    if (
      option?.visibilityCondition?.fieldValue === undefined ||
      option?.visibilityCondition?.fieldValue === null ||
      option.visibilityCondition.fieldValue.length === 0
    ) {
      return false;
    }

    const fieldCodeValue =
      this.exportData.data[0][option.visibilityCondition.fieldCode];

    for (const x of option.visibilityCondition.fieldValue) {
      if (fieldCodeValue == x) {
        return true;
      }
    }

    return false;
  }

  private getOptions(
    contentModule: string,
    contentType: string,
    systemCode?: string
  ) {
    return this.contentDistributionCache
      .getByMappingKey(contentModule, contentType, systemCode)
      .pipe(
        map((options) => {
          const exportOpt = this.filterExportOptions(options);
          const sendOpt = this.filterSendOptions(options);

          return [exportOpt, sendOpt];
        })
      );
  }

  private filterExportOptions(options: ExportOptionItemModel[]) {
    let exportOpt = _.filter(
      options,
      (ri) => ri.documentType.distributionService === null
    );
    exportOpt = _.filter(
      exportOpt,
      (r2) =>
        (this.multiple === false && r2.supportsMultiple === false) ||
        (this.multiple === false && r2.combine === true) ||
        this.multiple === true
    );

    exportOpt.map((x) => (x.optionType = OptionType.Export));
    return exportOpt;
  }

  private filterSendOptions(options: ExportOptionItemModel[]) {
    let sendOpt = _.filter(
      options,
      (ri) =>
        ri.documentType.distributionService !== null &&
        (ri.documentType.distributionService === 'shipm8_notification' ||
          this.serviceConnectionsService.subscriptions
            .map((x) => x.serviceConnection.systemCode)
            .includes(ri.documentType.distributionService))
    );
    sendOpt = _.filter(
      sendOpt,
      (r2) =>
        (this.multiple === false && r2.supportsMultiple === false) ||
        (this.multiple === false && r2.combine === true) ||
        this.multiple === true
    );

    sendOpt.map((x) => (x.optionType = OptionType.Send));
    return sendOpt;
  }

  // Sends a document
  distributeDocument(exportOption: ExportOptionItemModel) {
    if (this.skipAction === true) {
      return;
    }

    if ((!this.objectIds || this.objectIds.length === 0) && !this.data) {
      this.showWarningDialog();
    } else {
      this.isSending = true;
      this.contentDistributionService
        .distributeTemplate(
          exportOption.templateId,
          exportOption.documentType.id,
          this.objectIds,
          exportOption.recipients,
          this.objectType,
          this.searchFields,
          exportOption.body,
          exportOption.subject,
          JSON.stringify(this.data),
          exportOption.fileIds,
          exportOption.fromAddress
        )
        .subscribe(
          (distributionResult) => {
            this.itemExported.emit({ exportOption, distributionResult });
            this.isSending = false;
          },
          () => {
            this.isSending = false;
          }
        );
    }
  }

  // Exports a document to the browser
  private exportDocument(
    exportOption: ExportOptionItemModel,
    searchFields: CustomDataSearchFieldModel[] = [],
    requiredDataSearchFields: Record<string, CustomDataSearchFieldModel[]> = {}
  ) {
    const combinedSearchFields = [
      ...(this.searchFields ?? []),
      ...searchFields,
    ];

    if (this.skipAction === true) {
      return;
    }

    if (exportOption.isGeneric === true) {
      if (this.exportData?.data?.length > 0) {
        this.isExporting = true;

        this.contentDistributionService
          .generateExportForData(this.exportData, exportOption)
          .subscribe(
            (exportResult) => {
              this.isExporting = false;
              this.itemExported.emit({ exportOption, exportResult });
            },
            () => {
              this.isExporting = false;
            }
          );
      } else {
        return;
      }
    } else if (this.objectIds?.length > 0 || this.data) {
      this.isExporting = true;
      this.contentDistributionService
        .generateExport(
          exportOption,
          this.objectIds,
          this.objectType,
          combinedSearchFields,
          JSON.stringify(this.data),
          requiredDataSearchFields
        )
        .subscribe(
          (exportResult) => {
            this.isExporting = false;
            this.itemExported.emit({ exportOption, exportResult });
          },
          () => {
            this.isExporting = false;
          }
        );
    } else if (this.multiple === true) {
      this.showWarningDialog();
    }
  }

  // stores as file
  private storeDocument(exportOption: ExportOptionItemModel) {
    if (exportOption.isGeneric === true) {
      if (this.exportData?.data?.length > 0) {
        this.isStoring = true;

        this.contentDistributionService
          .storeForData(
            this.exportData,
            exportOption,
            this.objectIds,
            this.objectType
          )
          .subscribe(
            () => {
              this.isStoring = false;
            },
            () => {
              this.isStoring = false;
            }
          );
      } else {
        return;
      }
    } else if (this.objectIds?.length > 0) {
      this.isStoring = true;
      this.contentDistributionService
        .store(exportOption, this.objectIds, this.objectType, this.searchFields)
        .subscribe(
          (storeResult) => {
            this.isStoring = false;
            if (storeResult.status === ResultStatus.OK)
              this.itemStored.emit(storeResult.result);
          },
          () => {
            this.isStoring = false;
          }
        );
    } else if (this.multiple === true) {
      this.showWarningDialog();
    }
  }

  getButtonIcon(exportOption: ExportOptionItemModel) {
    if (exportOption.optionType === OptionType.Export) {
      return 'fa-file-export';
    }

    if (exportOption.optionType === OptionType.Send) {
      return 'fa-paper-plane';
    }

    if (exportOption.optionType === OptionType.Store) {
      return 'fa-upload';
    }

    return this.fileService.getFileTypeIcon(
      exportOption.documentType.systemCode
    );
  }

  optionSelected(exportOption: ExportOptionItemModel) {
    if (exportOption.optionType === OptionType.Send) {
      const retVal = _.clone(exportOption);
      const possibleRecipients = _.filter(
        this.recipients,
        (r) =>
          (!r.documentType ||
            r.documentType === exportOption.documentType.systemCode) &&
          (!r.distributionService ||
            r.distributionService ===
              exportOption.documentType.distributionService)
      );
      retVal.recipients = possibleRecipients;

      if (
        exportOption.documentType.requiresRecipients &&
        (this.objectIds?.length > 0 || this.data)
      ) {
        // There are multiple recipients, so we'll give the user an option to choose one or more
        const modalRef = this.modal.open(ExportSelectRecipientModalComponent, {
          backdrop: 'static',
          size: 'lg',
        });
        modalRef.componentInstance.exportOption = exportOption;
        modalRef.componentInstance.objectIds = this.objectIds;
        modalRef.componentInstance.data = this.data;
        modalRef.componentInstance.objectType = this.objectType;
        modalRef.componentInstance.recipients = retVal.recipients;
        modalRef.componentInstance.searchFields = this.searchFields;

        modalRef.result.then((modalResult) => {
          if (modalResult.recipients && modalResult.recipients.length > 0) {
            exportOption.recipients = modalResult.recipients;
            retVal.recipients = modalResult.recipients;
            retVal.subject = modalResult.subject;
            retVal.body = modalResult.body;
            retVal.fileIds = modalResult.fileIds;
            retVal.fromAddress = modalResult.fromAddress;

            this.distributeDocument(retVal);
            this.buttonClicked.emit(exportOption);
          } else {
            const modalRefGeneric = this.modal.open(
              GenericMessageModalComponent,
              {
                backdrop: 'static',
              }
            );
            modalRefGeneric.componentInstance.modalBody =
              'contentDistribution.errors.recipientRequired';
          }
        });
      } else {
        this.distributeDocument(retVal);
        this.buttonClicked.emit(retVal);
      }
    }

    if (exportOption.optionType === OptionType.Export) {
      const preference = _.find(this.identityService.getPreferences(), (p) => {
        return 'language' === p.name.toLowerCase();
      });

      exportOption.language =
        preference && preference.fieldValue ? preference.fieldValue : null;

      this._subscription.add(
        this._exportTemplateDataService
          .getById(exportOption.templateId)
          .pipe(
            mergeMap((res) => {
              if (res.result) {
                const filterWithUserInput =
                  res.result?.templateConfiguration?.filtering?.find(
                    (f) => f.userInput === true
                  );

                const hasRequiredDataWithFiltering =
                  res.result?.templateConfiguration?.requiredData?.some(
                    (data) =>
                      data.filtering &&
                      data.filtering.some((f) => f.userInput === true)
                  );

                if (filterWithUserInput || hasRequiredDataWithFiltering) {
                  return this._modalService
                    .openComponentModal(
                      {
                        component: ExportFilterOptionsModalComponent,
                        props: {
                          exportTemplate: res.result,
                          objectDefinition: this.objectDefinition,
                        },
                      },
                      'contentDistribution.exportFilterOptions'
                    )
                    .pipe(
                      mergeMap((modalRes) => {
                        if (modalRes.action === ModalAction.Confirm) {
                          const { searchFields, requiredDataSearchFields } =
                            modalRes.componentRef.confirm();
                          this.exportDocument(
                            exportOption,
                            searchFields,
                            requiredDataSearchFields
                          );
                          this.buttonClicked.emit(exportOption);
                        }
                        this._modalService.closeModals();
                        return of(null);
                      })
                    );
                } else {
                  this.exportDocument(exportOption);
                  this.buttonClicked.emit(exportOption);
                  return of(null);
                }
              }
              return of(null);
            })
          )
          .subscribe()
      );
    }

    if (exportOption.optionType === OptionType.Store) {
      this.storeDocument(exportOption);
    }
  }

  showWarningDialog() {
    const modalRef = this.modal.open(GenericMessageModalComponent, {
      backdrop: 'static',
    });
    modalRef.componentInstance.modalBody = this.translate.instant(
      'shared.general.atLeastOne'
    );
    modalRef.componentInstance.modalHeader = this.translate.instant(
      'shared.terms.export'
    );
  }

  // To be used by parent components if necessary
  useOption(exportOption: ExportOptionItemModel) {
    this.optionSelected(exportOption);
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}
