import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'underscore';

import { ResultWrapper } from '@seahorse/common';
import { NotificationService } from '@seahorse/common';
import { EventModel } from '../../../../../shared/models/event.model';
import { LayoutDisplayService } from '../../../../../layout/services/layout-display.service';
import { FlowDataService } from '../../services/flow-data.service';
import { FlowModel } from '@seahorse/domain';

@Component({ templateUrl: 'flow-creator.page.html' })
export class FlowCreatorPage implements OnInit {
  flow: FlowModel;
  loadingFlow: boolean;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private notification: NotificationService,
    private layoutDisplay: LayoutDisplayService,
    private flowData: FlowDataService
  ) {
    this.flow = new FlowModel();
    this.loadingFlow = false;
  }

  ngOnInit() {
    this.translate
      .get('invoicingFlow.flowCreator.title')
      .subscribe((translateGet: any) => {
        this.layoutDisplay.currentModuleName = translateGet;
      });
  }

  action(event: EventModel) {
    switch (event.action) {
      case 'saveFlowThenNewFlow': {
        this.loadingFlow = true;

        this.flowData.add(this.flow).subscribe(
          (flowAdd: ResultWrapper<FlowModel>) => {
            if (flowAdd.hasResult) {
              this.notification.showSuccess(
                this.translate.instant('invoicingFlow.flowCreator.added'),
                this.translate.instant('shared.terms.success')
              );
            } else {
              this.notification.showError(
                _.pluck(flowAdd.messages, 'message').join('\n'),
                this.translate.instant('shared.terms.failed')
              );
            }

            this.loadingFlow = false;
          },
          (flowAddError: any) => {
            this.notification.showError(
              _.pluck(flowAddError.error.messages, 'message').join('\n'),
              this.translate.instant('shared.terms.failed')
            );

            this.loadingFlow = false;
          }
        );

        break;
      }

      case 'saveFlowThenNewStage': {
        this.loadingFlow = true;

        this.flowData.add(this.flow).subscribe(
          (flowAdd: ResultWrapper<FlowModel>) => {
            if (flowAdd.hasResult) {
              this.notification.showSuccess(
                this.translate.instant('invoicingFlow.flowCreator.added'),
                this.translate.instant('shared.terms.success')
              );

              this.router.navigate(
                [`invoicing-and-tariffs/invoicing-flows/stage/create`],
                { queryParams: { flow: flowAdd.result.id } }
              );
            } else {
              this.notification.showError(
                _.pluck(flowAdd.messages, 'message').join('\n'),
                this.translate.instant('shared.terms.failed')
              );
            }

            this.loadingFlow = false;
          },
          (flowAddError: any) => {
            this.notification.showError(
              _.pluck(flowAddError.error.messages, 'message').join('\n'),
              this.translate.instant('shared.terms.failed')
            );

            this.loadingFlow = false;
          }
        );

        break;
      }
    }
  }
}
