import { Injectable } from '@angular/core';
import { ResultWrapper } from '@seahorse/common';
import { DataContextService } from '@seahorse/common';
import { Observable } from 'rxjs';
import { DocumentTypeModel } from '../models/document-type.model';
import { DataService } from '@seahorse/domain';

@Injectable()
export class DocumentTypeDataService implements DataService<DocumentTypeModel> {
  constructor(private dataContext: DataContextService) {}

  search(
    searchQuery?: string,
    pageIndex?: number,
    pageSize?: number
  ): Observable<ResultWrapper<DocumentTypeModel[]>> {
    const queryParams = [];

    if (searchQuery !== undefined && searchQuery !== null) {
      queryParams.push(`searchTerm=${encodeURIComponent(searchQuery)}`);
    }

    if (pageIndex !== undefined && pageIndex !== null) {
      queryParams.push(`pindex=${pageIndex}`);
    }

    if (pageSize !== undefined && pageSize !== null) {
      queryParams.push(`psize=${pageSize}`);
    }

    const queryString = queryParams.join('&');
    const url = `contentdistribution/documenttype/search${
      queryString ? '?' + queryString : ''
    }`;

    return this.dataContext.get<DocumentTypeModel[]>(url);
  }

  getById(id: number): Observable<ResultWrapper<DocumentTypeModel>> {
    return this.dataContext.get<DocumentTypeModel>(
      `contentdistribution/documenttype/${id}`
    );
  }

  add(model: DocumentTypeModel): Observable<ResultWrapper<DocumentTypeModel>> {
    return this.dataContext.post<DocumentTypeModel>(
      `contentdistribution/documenttype`,
      model
    );
  }

  update(
    model: DocumentTypeModel
  ): Observable<ResultWrapper<DocumentTypeModel>> {
    return this.dataContext.put<DocumentTypeModel>(
      `contentdistribution/documenttype`,
      model
    );
  }

  delete(id: number): Observable<ResultWrapper<DocumentTypeModel>> {
    return this.dataContext.delete<DocumentTypeModel>(
      `contentdistribution/documenttype/${id}`
    );
  }

  getAll(): Observable<ResultWrapper<DocumentTypeModel[]>> {
    return this.dataContext.get<DocumentTypeModel[]>(
      `contentdistribution/documenttype`
    );
  }
}
