import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import * as _ from 'underscore';

import { CVVShipRequirementsModel } from '../../models/cvv-ship-requirements.model';

@Component({
  selector: 'ca-ship-requirements-cvv-container-modal',
  templateUrl: 'cvv-ship-requirements-modal.component.html',
})
export class CVVShipRequirementsModal {
  private _shipRequirements: CVVShipRequirementsModel = null;
  errors: string[] = [];
  persons = {
    after: 0,
    front: 0,
    middle: 0,
  };
  winch = {
    after: 0,
    front: 0,
    middle: 0,
  };
  saveDataToShip = false;

  @Input() title: string = null;
  @Input() set shipRequirements(value: CVVShipRequirementsModel) {
    this._shipRequirements = value;
    this.setDefaults();
  }
  get shipRequirements(): CVVShipRequirementsModel {
    return this._shipRequirements;
  }

  constructor(public modal: NgbActiveModal) {}

  dismiss(result) {
    this.modal.dismiss(result);
  }

  save() {
    // valid motorboat when is set
    if (
      this.shipRequirements.motorboat !== undefined &&
      this.shipRequirements.motorboat !== null
    ) {
      if (this.shipRequirements.motorboat.toString().trim() === '') {
        this.shipRequirements.motorboat = null;
      } else if (
        isNaN(this.shipRequirements.motorboat) ||
        this.shipRequirements.motorboat < 0
      ) {
        this.errors.push('Het veld motorboten is ongeldig');
      }
    }

    // valid people when is set
    if (this.persons.middle !== undefined && this.persons.middle !== null) {
      if (this.persons.middle.toString().trim() === '') {
        this.persons.middle = null;
      } else if (
        isNaN(this.persons.middle) === true ||
        this.persons.middle < 0
      ) {
        this.errors.push('Het veld personen normaal is ongeldig');
      }
    }

    if (this.persons.front !== undefined && this.persons.front !== null) {
      if (this.persons.front.toString().trim() === '') {
        this.persons.front = null;
      } else if (isNaN(this.persons.front) || this.persons.front < 0) {
        this.errors.push('Het veld personen voor is ongeldig');
      }
    }

    if (this.persons.after !== undefined && this.persons.after !== null) {
      if (this.persons.after.toString().trim() === '') {
        this.persons.after = null;
      } else if (isNaN(this.persons.after) || this.persons.after < 0) {
        this.errors.push('Het veld personen achter is ongeldig');
      }
    }

    // valid winch when is set
    if (this.winch.middle !== undefined && this.winch.middle !== null) {
      if (this.winch.middle.toString().trim() === '') {
        this.winch.middle = null;
      } else if (isNaN(this.winch.middle) || this.winch.middle < 0) {
        this.errors.push('Het veld winchwagens normaal is ongeldig');
      }
    }

    if (this.winch.front !== undefined && this.winch.front !== null) {
      if (this.winch.front.toString().trim() === '') {
        this.winch.front = null;
      } else if (isNaN(this.winch.front) || this.winch.front < 0) {
        this.errors.push('Het veld winchwagens voor is ongeldig');
      }
    }

    if (this.winch.after !== undefined && this.winch.after !== null) {
      if (this.winch.after.toString().trim() === '') {
        this.winch.after = null;
      } else if (isNaN(this.winch.after) || this.winch.after < 0) {
        this.errors.push('Het veld winchwagens achter is ongeldig');
      }
    }

    if (this.errors.length === 0) {
      if (!this.persons.middle && !this.persons.front && !this.persons.after) {
        this.shipRequirements.persons = null;
      } else {
        this.shipRequirements.persons = `${
          this.persons.middle ? this.persons.middle : 0
        }/${this.persons.front ? this.persons.front : 0}/${
          this.persons.after ? this.persons.after : 0
        }`;
      }

      if (!this.winch.middle && !this.winch.front && !this.winch.after) {
        this.shipRequirements.winches = null;
      } else {
        this.shipRequirements.winches = `${
          this.winch.middle ? this.winch.middle : 0
        }/${this.winch.front ? this.winch.front : 0}/${
          this.winch.after ? this.winch.after : 0
        }`;
      }

      this.modal.close({
        model: this.shipRequirements,
        saveDataToShip: this.saveDataToShip,
      });
    }
  }

  private setDefaults() {
    this.persons.after = null;
    this.persons.front = null;
    this.persons.middle = null;

    if (this.shipRequirements.persons && this.shipRequirements.persons !== '') {
      const p = this.shipRequirements.persons.split('/');
      if (isNaN(parseInt(p[0])) === false) {
        this.persons.middle = parseInt(p[0]);
      }

      if (isNaN(parseInt(p[1])) === false) {
        this.persons.front = parseInt(p[1]);
      }

      if (isNaN(parseInt(p[2])) === false) {
        this.persons.after = parseInt(p[2]);
      }
    }

    this.winch.after = null;
    this.winch.front = null;
    this.winch.middle = null;

    if (this.shipRequirements.winches && this.shipRequirements.winches !== '') {
      const w = this.shipRequirements.winches.split('/');
      if (isNaN(parseInt(w[0])) === false) {
        this.winch.middle = parseInt(w[0]);
      }

      if (isNaN(parseInt(w[1])) === false) {
        this.winch.front = parseInt(w[1]);
      }

      if (isNaN(parseInt(w[2])) === false) {
        this.winch.after = parseInt(w[2]);
      }
    }
  }
}
