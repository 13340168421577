<div class="modal-header">
  <div class="modal-title">{{ modalHeader }}</div>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="modal.dismiss()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form>
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label for="addActivityDate">{{ attribute.fieldDisplayName }}:</label>
          <div class="input-group">
            <input
              class="form-control"
              placeholder="dd-mm-jjjj uu:mm"
              id="addActivityDate"
              name="addActivityDate"
              [(ngModel)]="attribute.fieldValue"
            />
            <div class="input-group-append">
              <button
                class="btn btn-outline-secondary"
                (click)="addMinutes()"
                type="button"
                title="+ {{ defaultMinutes }} min"
                *ngIf="showAddButton"
              >
                <span>+{{ defaultMinutes }}</span>
              </button>
              <button
                class="btn btn-outline-secondary"
                (click)="setNow()"
                type="button"
                title="Nu"
              >
                <i class="fa-regular fa-stopwatch"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="error">
      <div class="col-12">
        <div class="alert alert-danger">
          <span>{{ error }}</span>
        </div>
      </div>
    </div>
    <div class="d-flex">
      <div class="flex-grow-1">
        <button
          type="button"
          class="btn btn-block btn-link"
          (click)="dismiss(null)"
        >
          Annuleren
        </button>
      </div>
      <div class="flex-grow-1">
        <button
          type="button"
          class="btn btn-block btn-success"
          (click)="save()"
        >
          Opslaan
        </button>
      </div>
    </div>
  </form>
</div>
