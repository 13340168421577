import { CustomDataBaseModel, OrderRegistrationModel } from '@seahorse/domain';

export class OrderRegistrationStatusLogModel extends CustomDataBaseModel {
  $customcontent_orderregistration_id: OrderRegistrationModel['__Id'];
  $customcontent_orderregistration_status: OrderRegistrationModel['status'];
  orderRegistrationStatusLog: OrderRegistrationStatusLog;

  constructor() {
    super();

    this.$customcontent_orderregistration_id = null;
    this.$customcontent_orderregistration_status = null;
    this.orderRegistrationStatusLog = null;
  }
}

export enum OrderRegistrationStatusLog {
  OrderRegistrationStatusCreated,
  OrderRegistrationStatusUpdated,
  OrderRegistrationDeleted,
}
