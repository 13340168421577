import { Component, Input, Output, EventEmitter } from '@angular/core';
import { EventModel } from '../../../../../shared/models/event.model';
import { QueryModel } from '../../../../../shared/models/query.model';
import { ViewModel } from '../../../../../shared/models/view.model';

@Component({
  selector: 'task-definition-list-overview-header',
  templateUrl: './task-definition-list-overview-header.component.html',
})
export class TaskDefinitionListOverviewHeaderComponent {
  @Input() loading: boolean;
  @Input() views: ViewModel[];
  @Input() view: ViewModel['value'];
  @Input() search: QueryModel['search'];
  @Input() count: number;

  @Output() onEvent: EventEmitter<EventModel>;

  constructor() {
    this.loading = false;
    this.views = [];
    this.view = null;
    this.search = null;
    this.count = 0;
    this.onEvent = new EventEmitter<EventModel>();
  }
}
