import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { DataContextService } from '@seahorse/common';

@Injectable({
  providedIn: 'root',
})
export class ResolverService {
  constructor(private dataContext: DataContextService) {}

  resolveModel(model: string): Observable<any> {
    return this.dataContext.get<any>(`resolver/resolve-model?model=${model}`);
  }
}
