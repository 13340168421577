<div class="card">
  <div class="card-header">
    {{ 'systemMessage.latestUpdates' | translate }}
  </div>
  <div class="list-group">
    <div class="list-group-item" *ngFor="let systemMessage of systemMessages">
      <div class="d-flex justify-content-between align-items-center">
        <div>{{ systemMessage.subject }}</div>
      </div>
    </div>
  </div>
  <div class="card-footer card-footer-clickable">
    <a routerLink="/release-notes/overview"
      >{{ 'shared.terms.more' | translate }}
      {{ 'systemMessage.updates' | translate }} &raquo;</a
    >
  </div>
</div>
