import {
  Directive,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@seahorse/common';
import { CustomDataBaseModel, FileModel, FileService } from '@seahorse/domain';
import * as _ from 'underscore';
import { FileDropModel } from './file-drop.model';

@Directive({
  selector: '[tempFileDragDrop]',
  standalone: true,
})
export class FileDragDropDirective {
  @Input()
  set objectId(value: string) {
    this._objectId = value;
    if (this._objectId && this.pendingFiles) {
      this.uploadFiles(this.pendingFiles);
      this.pendingFiles = null;
    }
  }
  get objectId(): string {
    return this._objectId;
  }
  private _objectId: string = null;
  @Input() objectType: string = null;
  @Input() objectKey: string = null;
  @Input()
  object: CustomDataBaseModel = null;
  @Input() excludeSelector?: string;
  @Input() isFileDroppedFromPage = false;
  @Input() isDropAllowed = true;
  @Input() filesOnly = false;

  @Output() fileUploaded = new EventEmitter<FileModel>();
  @Output() requestObjectCreation = new EventEmitter<CustomDataBaseModel>();

  private pendingFiles: FileList | null = null;

  constructor(
    private fileDataService: FileService,
    private notificationService: NotificationService,
    private translate: TranslateService
  ) {}

  @HostBinding('class.fileover') fileOver: boolean;

  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = true;
  }

  @HostListener('dragleave', ['$event']) onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
  }

  @HostListener('drop', ['$event'])
  onDrop(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();

    this.fileOver = false;

    if (!this.isDropAllowed) {
      return false;
    }

    if (
      evt.dataTransfer &&
      evt.dataTransfer.files &&
      evt.dataTransfer.files.length > 0
    ) {
      if (this.isFileDroppedFromPage === true) {
        if (this.excludeSelector) {
          const excludeDropArea = document.querySelectorAll(
            this.excludeSelector
          );

          for (let i = 0; i < excludeDropArea.length; i++) {
            const element = excludeDropArea[i] as HTMLElement;
            const excludedArea = element.contains(evt.target as Node);

            if (excludedArea) {
              return true;
            }
          }
        }

        if (this.filesOnly) {
          this.fileDataService.readFiles(evt.dataTransfer.files);
        } else {
          const droppedFilesData = new FileDropModel();
          droppedFilesData.objectId = this.objectId;
          droppedFilesData.objectType = this.objectType;
          droppedFilesData.objectKey = this.objectKey;
          droppedFilesData.object = this.object;
          droppedFilesData.files = evt.dataTransfer.files;

          this.fileDataService.readFilesData(droppedFilesData);
        }
      } else {
        if (!this.objectId) {
          this.pendingFiles = evt.dataTransfer.files;

          this.requestObjectCreation.emit(this.object);
        } else {
          this.uploadFiles(evt.dataTransfer.files);
        }
      }
    } else if (
      evt.dataTransfer &&
      evt.dataTransfer.items &&
      evt.dataTransfer.items.length > 0
    ) {
      const itemContent = evt.dataTransfer.items[0].getAsString((cb) => {
        // Here we can implement drag/drops from other sources
        if (console) {
          // console.log(cb);
        }
      });
    }

    return false;
  }

  private uploadFiles(files: FileList) {
    _.each(files, (file) => {
      const t = FileModel.fromHtmlInput(file);
      if (!t.objectFile || t.objectFile.length === 0) {
        t.objectFile = [
          { objectId: this.objectId, objectType: this.objectType },
        ];
      }

      this.fileDataService.addFile(t).subscribe(
        (uploadResult) => {
          if (uploadResult.hasResult) {
            this.notificationService.showSuccess(
              this.translate.instant('files.fileAdded')
            );
            this.fileUploaded.emit(uploadResult.result);
          } else {
            this.notificationService.showError(
              _.pluck(uploadResult.messages, 'message').join('\n'),
              this.translate.instant('shared.terms.failed')
            );
          }
        },
        (e) => {
          this.notificationService.showError(
            _.pluck(e.error.messages, 'message').join('\n'),
            this.translate.instant('shared.terms.failed')
          );
        }
      );
    });
  }
}
