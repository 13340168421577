<section class="page-header d-flex justify-content-end px-3 py-1">
  <div class="dropdown">
    <button
      type="button"
      class="btn btn-outline-secondary"
      id="headerHamburgerButton"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <i class="fa-regular fa-ellipsis-v fa-fw"></i>
    </button>
    <div class="dropdown-menu" aria-labelledby="headerHamburgerButton">
      <button
        (click)="onEvent.emit({ action: 'delete' })"
        class="btn dropdown-item"
      >
        <i class="fa-solid fa-trash fa-fw"></i>
        {{ 'shared.terms.delete' | translate }}
      </button>
    </div>
  </div>
</section>
