<div class="card">
  <h1 class="card-header">
    {{ 'nautical.visit.details.portAgent' | translate }}
  </h1>
  <div *ngIf="!loadAgent" class="card-body">
    {{ agent.id }}<br />
    {{ agent.name }}<br />
    {{ agent.parentCompanyId }}<br />
    {{ agent.parentCompany }}<br />
    {{ agent.parentCompanyType }}
  </div>
</div>
