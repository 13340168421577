import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import {
  ErrorMessage,
  NotificationService,
  ResultWrapper,
} from '@seahorse/common';
import {
  CustomDataBaseModel,
  FieldDefinitionModel,
  FieldType,
  FileModel,
  FileService,
  ObjectDefinitionModel,
} from '@seahorse/domain';
import { BatchUpdateModalComponent, LinkedObjectService } from '@seahorse/temp';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ProxyServices } from '../../../core/services/proxy.service';

@Component({
  selector: 'ca-file-import-linked-objects-modal',
  templateUrl: './file-import-linked-objects-modal.component.html',
  styleUrls: ['./file-import-linked-objects-modal.component.scss'],
})
export class FileImportLinkedObjectsModalComponent
  implements OnInit, OnDestroy
{
  @Input() object: CustomDataBaseModel;
  @Input() objectMappingKey?: string;
  @Input() selected: string[];
  @Input() objectFieldDefinitions: FieldDefinitionModel[];
  @Input() files: FileModel[];

  @Input() editFile = false;
  @Input() file: FileModel;

  @Input() matchingLinkedObjects?: any;

  @Output() confirm: EventEmitter<{ files: FileModel[] }> = new EventEmitter<{
    files: [];
  }>();

  linkedObjectDefinitions = {} as Record<
    string,
    ObjectDefinitionModel & {
      order?: number | null;
      contentModule?: string;
      contentType?: string;
    }
  >;

  linkedObjects: any[] = [];
  loading = false;
  fieldType = FieldType.LinkedObjects;

  selectedColumns: { [key: string]: string } = {};
  searchTerms: { [key: string]: string } = {};
  originalValuesMap: Map<string, any[]> = new Map();

  itemsToShow: { [key: string]: number } = {};
  itemsIncrement = 5;

  private subscriptions = new Subscription();

  constructor(
    private _linkedObjectData: LinkedObjectService,
    private _translateService: TranslateService,
    private _notificationService: NotificationService,
    private _filesDataService: FileService,
    private _proxyService: ProxyServices,
    private _toastService: ToastrService,
    private _modalService: NgbModal,
    private _activeModal: NgbActiveModal
  ) {}

  ngOnInit(): void {
    this.populateLinkedObjectDefinitions();
    this.populateLinkedObjects();
  }

  private populateLinkedObjectDefinitions(): void {
    const linkedFieldTypes = [FieldType.LinkedObject, FieldType.LinkedObjects];

    Object.values(this.objectFieldDefinitions).forEach((field) => {
      if (linkedFieldTypes.includes(field.fieldType)) {
        const objectSystemCode =
          FieldDefinitionModel.getMappingKeySystemCode(field);
        const objectDefinition = this._proxyService.objectDefinitions.find(
          (def) => def.systemCode === objectSystemCode
        );

        this.linkedObjectDefinitions[field.systemCode] = objectDefinition;
        const mappingKeyParts = objectDefinition.mappingKey.split('_');
        this.linkedObjectDefinitions[field.systemCode].contentModule =
          mappingKeyParts[0].split('$')[1];
        this.linkedObjectDefinitions[field.systemCode].contentType =
          mappingKeyParts[1];
      }
    });
  }

  private populateLinkedObjects(): void {
    Object.keys(this.object).forEach((property) => {
      if (property.startsWith('__') || !this.selected.includes(property)) {
        return;
      }

      if (
        this.objectFieldDefinitions[property].fieldType ===
        FieldType.LinkedObject
      ) {
        const mappingKey = FieldDefinitionModel.getMappingKey(
          this.objectFieldDefinitions[property]
        );
        const foreignKey = FieldDefinitionModel.getMappingKeyForeignKey(
          this.objectFieldDefinitions[property]
        );

        this.subscriptions.add(
          this._linkedObjectData
            .getByKey(
              FieldDefinitionModel.getMappingKeySystemCode(
                this.objectFieldDefinitions[property]
              ),
              mappingKey,
              foreignKey,
              this.object[property]
            )
            .subscribe((res) => {
              this.handleLinkedObject(property, res.result);
            })
        );
      }

      if (
        this.objectFieldDefinitions[property].fieldType ===
        FieldType.LinkedObjects
      ) {
        const mappingKey = FieldDefinitionModel.getMappingKey(
          this.objectFieldDefinitions[property]
        );
        const foreignKey = FieldDefinitionModel.getMappingKeyForeignKey(
          this.objectFieldDefinitions[property]
        );

        this.subscriptions.add(
          this._linkedObjectData
            .getByKeys(
              FieldDefinitionModel.getMappingKeySystemCode(
                this.objectFieldDefinitions[property]
              ),
              mappingKey,
              foreignKey,
              this.object[property]
            )
            .subscribe((res) => {
              this.handleLinkedObjects(property, res.result);
            })
        );
      }
    });

    this.linkedObjects.forEach((linkedObject) => {
      this.itemsToShow[linkedObject.key] = this.itemsIncrement;
    });
  }

  private handleLinkedObject(property: string, result: any): void {
    const columns = Object.keys(result).filter((key) =>
      this.objectFieldDefinitions[
        property
      ].additionalDataModel.displayFields.includes(key)
    );

    const isChecked = this.isObjectChecked(property, result.__Id);

    result.isChecked = isChecked;

    this.linkedObjects.push({
      key: property,
      value: [result],
      columns: columns,
      type: FieldType.LinkedObject,
      objectMappingKey: `${this.linkedObjectDefinitions[property].mappingKey}_id`,
    });
  }

  private handleLinkedObjects(property: string, result: any[]): void {
    const firstObject = result[0];
    const columns = firstObject ? this.getColumns(firstObject) : [];

    result.forEach((object) => {
      object.isChecked = this.isObjectChecked(property, object.__Id);
    });

    this.linkedObjects.push({
      key: property,
      value: result,
      columns: columns,
      type: FieldType.LinkedObjects,
      objectMappingKey: `${this.linkedObjectDefinitions[property].mappingKey}_id`,
    });
  }

  private getColumns(object: any): string[] {
    return Object.keys(object).filter(
      (x) => !x.startsWith('__') && x !== 'isChecked'
    );
  }

  private isObjectChecked(property: string, objectId: string): boolean {
    if (!this.editFile) {
      return false;
    }

    const matchingObject = this.matchingLinkedObjects.find(
      (item) => item.key === property && item.fileId === this.file.id
    );

    return matchingObject?.ids.includes(objectId) ?? false;
  }

  checkAll(event: any, linkedObject: any) {
    const isChecked = event.target.checked;

    for (const row of linkedObject.value) {
      row['isChecked'] = isChecked;
    }
  }

  toggleChecked(item: any) {
    item['isChecked'] = !item['isChecked'];
  }

  uploadFilesToLinkedObjects() {
    if (this.editFile) {
      this.updateFile();
    } else {
      if (
        (this.object?.__Id && this.objectMappingKey) ||
        (this.linkedObjects?.length && this.files?.length)
      ) {
        this.loading = true;
        const successful: ResultWrapper<FileModel>[] = [];
        let unsuccessful = 0;
        let messages: ErrorMessage[] = [];

        this.files.forEach((x) => {
          x.objectFile = [];
          if (this.object.__Id) {
            x.objectFile.push({
              objectId: this.object.__Id,
              objectType: this.objectMappingKey,
            });
          }
          if (this.linkedObjects) {
            this.linkedObjects.forEach((linkedObject) => {
              linkedObject.value.forEach((valueObject) => {
                if (valueObject.isChecked) {
                  x.objectFile.push({
                    objectId: valueObject.__Id,
                    objectType: linkedObject.objectMappingKey,
                  });
                }
              });
            });
          }
          this.subscriptions.add(
            this._filesDataService.addFile(x).subscribe(
              (r: ResultWrapper<FileModel>) => {
                if (r.hasResult) {
                  r.result.hasLinkedObjects = true;
                  successful.push(r);
                } else {
                  messages = messages.concat(...r.messages);
                  unsuccessful++;
                }
              },
              (e) => {
                messages = messages.concat(...e.error.messages);
                unsuccessful++;
              },
              () => {
                if (successful.length + unsuccessful === this.files.length) {
                  this.loading = false;
                  this.confirm.emit({ files: successful.map((s) => s.result) });
                  this._activeModal.close();
                  if (this.files.length > 1) {
                    if (successful.length) {
                      const successfulMessage = `${successful.length}/${
                        this.files.length
                      } ${this._translateService.instant('files.filesAdded')}`;
                      if (unsuccessful) {
                        this._notificationService.showWarning(
                          successfulMessage,
                          this._translateService.instant('shared.terms.success')
                        );
                      } else {
                        this._notificationService.showSuccess(
                          successfulMessage,
                          this._translateService.instant('shared.terms.success')
                        );
                      }
                    }
                  } else {
                    this._notificationService.showSuccess(
                      this._translateService.instant('files.fileAdded'),
                      this._translateService.instant('shared.terms.success')
                    );
                  }
                  if (unsuccessful) {
                    this._notificationService.showError(
                      messages.map((m) => m.message).join('\n'),
                      this._translateService.instant('shared.terms.failed')
                    );
                  }
                }
              }
            )
          );
        });
      } else {
        this.confirm.emit({ files: this.files });
        this._activeModal.close();
      }
    }
  }

  updateFile() {
    this.linkedObjects.forEach((linkedObject) => {
      const objectType = linkedObject.objectMappingKey;

      linkedObject.value.forEach((valueObject) => {
        if (valueObject.isChecked) {
          const objectId = valueObject.__Id;
          const objectToAdd = { objectId, objectType };

          const index = this.file.objectFiles.findIndex(
            (of) => of.objectId === objectId && of.objectType === objectType
          );
          if (index === -1) {
            this.file.objectFiles.push(objectToAdd);
          }
        } else {
          const index = this.file.objectFiles.findIndex(
            (of) =>
              of.objectId === valueObject.__Id && of.objectType === objectType
          );
          if (index !== -1) {
            this.file.objectFiles.splice(index, 1);
          }
        }
      });
    });

    this.subscriptions.add(
      this._filesDataService.updateObjectFile(this.file).subscribe((res) => {
        if (res.hasResult) {
          this._notificationService.showSuccess(
            this._translateService.instant('shared.terms.success')
          );
          this.file = res.result;
          if (this.file.objectFiles.length === 1) {
            this.files.forEach((file) => {
              if (file.id === this.file.id) {
                file.hasLinkedObjects = false;
              }
            });
          }
          this._activeModal.close();
        } else {
          this._notificationService.showError(
            this._translateService.instant('shared.terms.failed')
          );
        }
      })
    );
  }

  openBatchUpdateDialog(key: string, value: any) {
    let items;
    const fieldDefinition = this.objectFieldDefinitions[key];

    this.linkedObjects.forEach((linkedObject) => {
      if (linkedObject.key === key) {
        items = linkedObject.value;
      }
    });

    let data;

    if (fieldDefinition.fieldType === FieldType.LinkedObjects) {
      data = items.filter((item) => item['isChecked']);
    }

    if (data.length >= 1) {
      const modalRef = this._modalService.open(BatchUpdateModalComponent);

      modalRef.componentInstance.objectDefinition = value;
      modalRef.componentInstance.items = data;

      modalRef.result.then((result) => {
        if (result) {
          this.object.__AdditionalData[key] = this.object.__AdditionalData[
            key
          ].map((item) => {
            const updatedItem = result.find(
              (updated) => updated.__Id === item.__Id
            );

            if (updatedItem) {
              Object.keys(updatedItem).forEach((key) => {
                if (Array.isArray(item[key])) {
                  updatedItem[key] = Array.isArray(updatedItem[key])
                    ? updatedItem[key]
                    : [updatedItem[key]];
                }
              });
            }

            return updatedItem ? updatedItem : item;
          });

          this.linkedObjects = [];
          this.populateLinkedObjects();
        }
      });
    } else {
      this._toastService.info(
        this._translateService.instant('uiModules.batchUpdate.info')
      );
    }
  }

  search(item: any, selectedColumn: string, searchTerm: string) {
    if (item && item.value && Array.isArray(item.value) && selectedColumn) {
      if (!this.originalValuesMap.has(item.key)) {
        this.originalValuesMap.set(
          item.key,
          JSON.parse(JSON.stringify(item.value))
        );
      }

      const originalValues = this.originalValuesMap.get(item.key);

      let filteredValues = [];

      if (searchTerm.trim() === '') {
        filteredValues = originalValues;
      } else {
        filteredValues = originalValues.filter((obj) =>
          String(obj[selectedColumn])
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        );
      }

      const index = this.linkedObjects.findIndex(
        (linkedItem) => linkedItem.key === item.key
      );
      if (index !== -1) {
        this.linkedObjects[index].value = filteredValues;
      }
    }
  }

  loadMore(key: string): void {
    this.itemsToShow[key] += this.itemsIncrement;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
