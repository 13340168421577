import { PipeModel } from '@seahorse/common';
import { FieldType } from '@seahorse/domain';

export class RenderOptionModel {
  fieldType: FieldType;
  pipes: PipeModel[];
  width: string;

  constructor(fieldType: FieldType, pipes?: PipeModel[]) {
    this.fieldType = fieldType;
    this.pipes = !pipes ? [] : pipes; // default empty array
    this.width = null;
  }
}

export class TableColumnModel extends RenderOptionModel {
  displayName: string;
  fieldName: string;
  isSearchable: boolean;
  isSortable: boolean;
  sortOrder: number;
  allowOverflow = false;

  constructor(
    fieldName: string,
    fieldType: FieldType,
    displayName?: string,
    pipes?: PipeModel[],
    isSearchable?: boolean,
    isSortable?: boolean,
    sortOrder?: number,
    allowOverflow: boolean = false,
    width?: string
  ) {
    super(fieldType, pipes);

    this.allowOverflow = allowOverflow;
    this.displayName = displayName;
    this.fieldName = fieldName;
    this.isSearchable = isSearchable === false ? false : true; // default is true
    this.isSortable = isSortable === false ? false : true; // default is true
    this.sortOrder = !sortOrder ? -1 : sortOrder;
    this.width = width ? width : 'auto';
  }
}
