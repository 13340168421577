import { Component, Input, Output, EventEmitter } from '@angular/core';
import { OrderRegistrationModel } from '@seahorse/domain';
import { EventModel } from '../../../shared/models/event.model';

@Component({
  selector: 'ca-order-registrations-overview-tiles',
  templateUrl: 'order-registrations-overview-tiles.component.html',
})
export class OrderRegistrationsOverviewTilesComponent {
  @Input() orderRegistrations: OrderRegistrationModel[];
  @Input() selected: OrderRegistrationModel;

  @Output() onEvent: EventEmitter<EventModel>;

  constructor() {
    this.orderRegistrations = [];
    this.selected = null;
    this.onEvent = new EventEmitter<EventModel>();
  }
}
