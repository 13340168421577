<table
  class="table table-truncated"
  [ngClass]="{ 'table-clickable': clickable }"
>
  <thead>
    <tr>
      <th scope="col" *ngIf="showType === true">
        {{
          'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.model.movementType'
            | translate
        }}
      </th>
      <th scope="col">
        {{
          'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.model.nauticalVisit.ship.name'
            | translate
        }}
      </th>
      <th
        scope="col"
        style="min-width: 90px; max-width: 90px"
        *ngIf="simpleView !== true"
      >
        {{
          'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.model.nauticalVisit.ship.fisheryNumber'
            | translate
        }}
      </th>
      <th
        scope="col"
        style="min-width: 90px; max-width: 90px"
        *ngIf="simpleView !== true && visitDisplay == 'inPort'"
      >
        {{
          'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.model.nauticalVisit.ship.callSign'
            | translate
        }}
      </th>
      <th scope="col">
        {{
          'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.model.nauticalVisit.portAgent.name'
            | translate
        }}
      </th>
      <th scope="col" *ngIf="visitDisplay == 'inPort'">
        {{
          'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.model.nauticalVisit.berth'
            | translate
        }}
      </th>
      <th
        scope="col"
        *ngIf="
          visitDisplay == 'incoming' ||
          visitDisplay == 'shifting' ||
          visitDisplay == 'outgoing'
        "
      >
        {{
          'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.model.nauticalVisit.previousPort.name'
            | translate
        }}
      </th>
      <th scope="col" *ngIf="visitDisplay == 'incoming'">
        {{
          'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.model.nauticalVisit.berth'
            | translate
        }}
      </th>
      <th
        scope="col"
        *ngIf="
          simpleView !== true &&
          (visitDisplay == 'shifting' || visitDisplay == 'outgoing')
        "
      >
        {{
          'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.model.nauticalVisit.nextPort.name'
            | translate
        }}
      </th>
      <th scope="col" *ngIf="simpleView !== true && visitDisplay == 'incoming'">
        {{
          'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.model.nauticalVisit.eta'
            | translate
        }}
      </th>
      <th scope="col" *ngIf="simpleView !== true && visitDisplay == 'outgoing'">
        {{
          'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.model.nauticalVisit.etd'
            | translate
        }}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr
      [ngClass]="{
        disapproved:
          (model.__AdditionalData &&
            model.__AdditionalData.$nautical_portvisit_id &&
            model.__AdditionalData.$nautical_portvisit_id.status == '4') ||
          (model.__AdditionalData.$nautical_portmovement_id &&
            model.__AdditionalData.$nautical_portmovement_id.status == '4')
      }"
      *ngFor="let model of models"
      (click)="
        onSelectVisit.emit({
          action: visitDisplay == 'inPort' ? 'selectVisit' : 'selectMovement',
          data: model
        })
      "
    >
      <td *ngIf="showType === true" class="text-nowrap text-truncate">
        <ng-container *ngIf="model.movementType"
          >{{ model.movementType | zeehavenIJmuidenVisitMovementType }}
        </ng-container>
      </td>
      <td
        class="text-nowrap text-truncate"
        title="{{ model.shipName | uppercase }}"
      >
        <ng-container *ngIf="model.shipName">{{
          model.shipName | uppercase
        }}</ng-container>
      </td>
      <td
        class="text-nowrap"
        style="width: 0.5%"
        title="{{
          model.__AdditionalData && model.__AdditionalData.nauticalShip
            ? model.__AdditionalData.nauticalShip.fisherynumber
            : ('' | uppercase)
        }}"
        *ngIf="simpleView !== true"
      >
        <ng-container
          *ngIf="
            model.__AdditionalData &&
            model.__AdditionalData.nauticalShip &&
            model.__AdditionalData.nauticalShip.fisherynumber
          "
        >
          {{
            model.__AdditionalData.nauticalShip.fisherynumber | uppercase
          }}</ng-container
        >
      </td>
      <td
        class="text-nowrap"
        style="min-width: 90px; max-width: 90px"
        title="{{
          model.__AdditionalData && model.__AdditionalData.nauticalShip
            ? model.__AdditionalData.nauticalShip.callsign
            : ('' | uppercase)
        }}"
        *ngIf="simpleView !== true && visitDisplay == 'inPort'"
      >
        <ng-container
          *ngIf="
            model.__AdditionalData &&
            model.__AdditionalData.nauticalShip &&
            model.__AdditionalData.nauticalShip.callsign
          "
        >
          {{
            model.__AdditionalData.nauticalShip.callsign | uppercase
          }}</ng-container
        >
      </td>
      <td
        class="text-nowrap text-truncate"
        title="{{ model.agent | lowercase }}"
      >
        <ng-container *ngIf="model.agent">{{
          model.agent | lowercase
        }}</ng-container>
      </td>
      <td
        class="text-nowrap text-truncate"
        title="{{ model.currentBerth | uppercase }}"
        *ngIf="visitDisplay == 'inPort'"
      >
        <ng-container>
          {{ model.currentBerth | uppercase }}
        </ng-container>
      </td>
      <td
        class="text-nowrap text-truncate"
        title="{{ model.origin | uppercase }}"
        *ngIf="visitDisplay != 'inPort'"
      >
        <ng-container *ngIf="model.origin">{{
          model.origin | uppercase
        }}</ng-container>
      </td>
      <td
        class="text-nowrap text-truncate"
        title="{{ model.destination | uppercase }}"
        *ngIf="simpleView !== true && visitDisplay != 'inPort'"
      >
        <ng-container *ngIf="model.destination">{{
          model.destination | uppercase
        }}</ng-container>
      </td>
      <td
        class="text-nowrap"
        title="{{
          model.etaOrEtd ? (model.etaOrEtd | date: 'dd-MM-yyyy HH:mm') : ''
        }}"
        *ngIf="
          simpleView !== true &&
          (visitDisplay == 'incoming' || visitDisplay == 'outgoing')
        "
      >
        <ng-container *ngIf="model.etaOrEtd">
          {{ model.etaOrEtd | date: 'dd-MM-yyyy HH:mm' }}
        </ng-container>
      </td>
    </tr>
  </tbody>
</table>
