<ng-container
  *ngIf="isContained; then pickerRef; else formGroupContainer"
></ng-container>

<ng-template #formGroupContainer>
  <div class="form-group" style="width: max-content">
    <ng-container *ngTemplateOutlet="pickerRef"></ng-container>
  </div>
</ng-template>

<ng-template #pickerRef>
  <ng-container *ngIf="sfPickerEnabled; else dateTimeForm">
    <ng-container *ngTemplateOutlet="syncfusionForm"></ng-container>
  </ng-container>
</ng-template>

<ng-template #dateTimeForm>
  <div class="input-group">
    <ng-container
      [ngIf]="!hideDate"
      *ngTemplateOutlet="datePart"
    ></ng-container>

    <ng-container
      [ngIf]="!hideTime"
      *ngTemplateOutlet="timePart"
    ></ng-container>

    <ng-container *ngTemplateOutlet="addOns"></ng-container>
  </div>
</ng-template>

<ng-template #syncfusionForm>
  <div class="input-group">
    <ng-container *ngIf="hideTime && !hideDate">
      <ejs-datepicker
        class="w-100"
        [showClearButton]="!hideClear"
        [showTodayButton]="!hideNow"
        [enableMask]="true"
        [maskPlaceholder]="sfConfig.datePickerMask"
        [firstDayOfWeek]="sfConfig.firstDayOfWeek"
        [locale]="locale"
        [readonly]="isReadOnly"
        format="dd-MM-yyyy"
        [value]="sfDate"
        (change)="onSfDateChange($event, false, true)"
      ></ejs-datepicker>
      <ng-container
        *ngTemplateOutlet="incrementAddOn; context: { $implicit: 'sf' }"
      ></ng-container>
    </ng-container>

    <ng-container *ngIf="!hideTime && !hideDate">
      <ng-container *ngIf="allowPartialValue === true">
        <ejs-datepicker
          class="w-100"
          [showClearButton]="!hideClear"
          [showTodayButton]="!hideNow"
          [enableMask]="true"
          [maskPlaceholder]="sfConfig.datePickerMask"
          [firstDayOfWeek]="sfConfig.firstDayOfWeek"
          [locale]="locale"
          [readonly]="isReadOnly"
          format="dd-MM-yyyy"
          [value]="partialValue.date.sfDate"
          (change)="onSfDateChange($event, false, true)"
        ></ejs-datepicker>
        <ng-container
          *ngTemplateOutlet="incrementAddOn; context: { $implicit: 'sf' }"
        ></ng-container>
        <ejs-timepicker
          class="w-100"
          format="HH:mm"
          [locale]="locale"
          [readonly]="isReadOnly"
          [enableMask]="true"
          [maskPlaceholder]="sfConfig.timePickerMask"
          [value]="partialValue.time.sfDate"
          (change)="onSfDateChange($event, true)"
        ></ejs-timepicker>
      </ng-container>
      <ng-container *ngIf="allowPartialValue !== true">
        <ejs-datetimepicker
          class="w-100"
          [showClearButton]="!hideClear"
          [showTodayButton]="!hideNow"
          [enableMask]="true"
          [maskPlaceholder]="sfConfig.dateTimePickerMask"
          [firstDayOfWeek]="sfConfig.firstDayOfWeek"
          [locale]="locale"
          [readonly]="isReadOnly"
          format="dd-MM-yyyy HH:mm"
          [value]="sfDate"
          (change)="onSfDateChange($event)"
        ></ejs-datetimepicker>
        <div class="input-group-append input-group-spacer btn-group">
          <ng-container
            *ngTemplateOutlet="incrementAddOn; context: { $implicit: 'sf' }"
          ></ng-container>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="!hideTime && hideDate">
      <ejs-timepicker
        class="w-100"
        format="HH:mm"
        [locale]="locale"
        [enableMask]="true"
        [maskPlaceholder]="sfConfig.timePickerMask"
        [value]="sfDate"
        (change)="onSfDateChange($event, true)"
      ></ejs-timepicker>
    </ng-container>
  </div>
</ng-template>

<ng-template #addOns>
  <div
    *ngIf="
      isContained ||
      !hideClear ||
      !hideTime ||
      !hideNow ||
      incrementValuesListItem
    "
    class="input-group-append input-group-spacer btn-group"
  >
    <div
      *ngIf="isContained"
      [ngClass]="{ 'is-invalid': isInvalid, 'is-disabled': isReadOnly }"
      (click)="focusLastInput()"
      class="input-spacer"
    ></div>
    <button
      *ngIf="!hideClear"
      [title]="'shared.dateTimePicker.clear' | translate"
      [disabled]="isReadOnly"
      (click)="setNull()"
      type="button"
      class="btn btn-outline-secondary btn-xs clear-input"
    >
      <i class="fa-solid fa-times fa-xs fa-fw"></i>
    </button>
    <ng-container *ngTemplateOutlet="incrementAddOn"></ng-container>
    <button
      *ngIf="!hideNow"
      [title]="'shared.dateTimePicker.now' | translate"
      [disabled]="isReadOnly"
      (click)="setNow()"
      type="button"
      class="btn btn-outline-secondary"
    >
      <i class="fa-regular fa-clock fa-fw"></i>
    </button>
  </div>
</ng-template>

<ng-template #timePart>
  <input
    [(ngModel)]="hour.display"
    [id]="hour.id"
    [name]="hour.id"
    [disabled]="isReadOnly"
    [size]="hour.maxLength"
    [maxlength]="hour.maxLength"
    [ngClass]="{ 'is-invalid': isInvalid || hour.isInvalid }"
    (keydown)="onKeyDown($event, 'hour')"
    (keyup)="onKeyUp($event, 'hour')"
    (focusout)="onFocusOut('hour')"
    [required]="required"
    type="text"
    [placeholder]="'HH'"
    class="form-control date-time-input border-right-0"
  />
  <div
    [ngClass]="{ 'is-invalid': isInvalid, 'is-disabled': isReadOnly }"
    class="input-group-concat"
  >
    <span class="input-group-text">:</span>
  </div>
  <input
    [(ngModel)]="minute.display"
    [id]="minute.id"
    [name]="minute.id"
    [disabled]="isReadOnly"
    [size]="minute.maxLength"
    [maxlength]="minute.maxLength"
    [ngClass]="{ 'is-invalid': isInvalid || minute.isInvalid }"
    (keydown)="onKeyDown($event, 'minute')"
    (keyup)="onKeyUp($event, 'minute')"
    (focusout)="onFocusOut('minute')"
    [required]="required"
    type="text"
    [placeholder]="'mm'"
    class="form-control date-time-input border-left-0"
  />
</ng-template>

<ng-template #datePart>
  <div *ngIf="!hideCalendar" class="input-group-prepend">
    <button
      [title]="'shared.dateTimePicker.pickDate' | translate"
      [disabled]="isReadOnly"
      (click)="ngbDatepicker.toggle()"
      type="button"
      class="btn btn-outline-secondary"
    >
      <i class="fa-solid fa-calendar-alt fa-fw"></i>
    </button>
    <input
      #ngbDatepicker="ngbDatepicker"
      [(ngModel)]="ngbDate"
      [minDate]="invalidBefore"
      [maxDate]="invalidAfter"
      [required]="required"
      (dateSelect)="onDateSelect($event)"
      ngbDatepicker
      type="text"
      class="position-absolute invisible"
    />
  </div>
  <input
    [(ngModel)]="day.display"
    [id]="day.id"
    [name]="day.id"
    [disabled]="isReadOnly"
    [size]="day.maxLength"
    [maxlength]="day.maxLength"
    [ngClass]="{ 'is-invalid': isInvalid || day.isInvalid }"
    (keydown)="onKeyDown($event, 'day')"
    (keyup)="onKeyUp($event, 'day')"
    (focusout)="onFocusOut('day')"
    [required]="required"
    type="text"
    [placeholder]="'DD'"
    class="form-control date-time-input border-right-0"
  />
  <div
    [ngClass]="{ 'is-invalid': isInvalid, 'is-disabled': isReadOnly }"
    class="input-group-concat"
  >
    <span class="input-group-text">-</span>
  </div>
  <input
    [(ngModel)]="month.display"
    [id]="month.id"
    [name]="month.id"
    [disabled]="isReadOnly"
    [size]="month.maxLength"
    [maxlength]="month.maxLength"
    [ngClass]="{ 'is-invalid': isInvalid || month.isInvalid }"
    (keydown)="onKeyDown($event, 'month')"
    (keyup)="onKeyUp($event, 'month')"
    (focusout)="onFocusOut('month')"
    [required]="required"
    type="text"
    [placeholder]="'MM'"
    class="form-control date-time-input border-left-0 border-right-0"
  />
  <div
    [ngClass]="{ 'is-invalid': isInvalid, 'is-disabled': isReadOnly }"
    class="input-group-concat"
  >
    <span class="input-group-text">-</span>
  </div>
  <input
    [(ngModel)]="year.display"
    [id]="year.id"
    [name]="year.id"
    [disabled]="isReadOnly"
    [size]="year.maxLength"
    [maxlength]="year.maxLength"
    [ngClass]="{ 'is-invalid': isInvalid || year.isInvalid }"
    (keydown)="onKeyDown($event, 'year')"
    (keyup)="onKeyUp($event, 'year')"
    (focusout)="onFocusOut('year')"
    [required]="required"
    type="text"
    [placeholder]="'YYYY'"
    class="form-control date-time-input date-time-input-year border-left-0"
  />
</ng-template>

<ng-template #incrementAddOn let-source>
  <ng-container *ngIf="incrementValuesList">
    <button
      *ngFor="let incrementValuesListItem of incrementValuesList"
      class="btn btn-outline-secondary"
      [class.sf-button]="source === 'sf'"
      type="button"
      (click)="incrementValue(incrementValuesListItem.value, source)"
      [title]="incrementValuesListItem.display"
      [disabled]="isReadOnly || isInvalid"
    >
      {{ incrementValuesListItem.display }}
    </button>
  </ng-container>
</ng-template>
