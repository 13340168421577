import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'underscore';

import {
  CustomDataAppendDataModel,
  CustomDataContentService,
  CustomDataSearchFieldModel,
  SearchOperators,
} from '@seahorse/domain';
import { ResultWrapper } from '@seahorse/common';
import { KPActivityModel } from '../models/kp-activity.model';
import { KPPortVisitModel } from '../models/kp-port-visit.model';
import { KPShipRequirementsModel } from '../models/kp-ship-requirements.model';
import { KPStatus } from '../models/kp-status.model';

@Injectable()
export class KPDataService {
  constructor(private customDataContent: CustomDataContentService) {}

  addActivity(
    activity: KPActivityModel
  ): Observable<ResultWrapper<KPActivityModel>> {
    return this.customDataContent
      .addCustomerData(
        'kp-activity',
        KPActivityModel.toApiModel({ ...activity })
      )
      .pipe(
        map((response) => {
          if (response && response.result) {
            response.result = KPActivityModel.fromApiModel(response.result);
          }

          return response;
        })
      );
  }

  addPortVisitInfo(
    model: KPPortVisitModel
  ): Observable<ResultWrapper<KPPortVisitModel>> {
    return this.customDataContent.addCustomerData('kpPortVisitInfo', model);
  }

  addShipRequirements(
    model: KPShipRequirementsModel
  ): Observable<ResultWrapper<KPShipRequirementsModel>> {
    return this.customDataContent.addCustomerData('kpShipRequirements', model);
  }

  getActiveActivities(
    preloadedFields?: string[]
  ): Observable<ResultWrapper<KPActivityModel[]>> {
    const searchField: CustomDataSearchFieldModel = {
      fieldName: 'status',
      searchOperator: SearchOperators.Equals,
      searchValue: KPStatus.Active,
    };

    const appendDataModel = new CustomDataAppendDataModel();
    if (preloadedFields && preloadedFields.length > 0) {
      appendDataModel.appendData = true;
      appendDataModel.appendFields = preloadedFields;
    }

    return this.customDataContent
      .searchCustomerData(
        'kp-activity',
        'Active',
        'status',
        [KPStatus.Active],
        null,
        null,
        0,
        200,
        appendDataModel,
        null,
        null,
        true,
        null
      )
      .pipe(
        map((response) => {
          if (response && response.result && response.result.length > 0) {
            response.result = _.map(response.result, (item: any) => {
              return KPActivityModel.fromApiModel(item);
            });
          }

          return response;
        })
      );
  }

  getActivitiesByMovementIds(
    movementIds: number[],
    preloadedFields?: string[]
  ): Observable<ResultWrapper<KPActivityModel[]>> {
    const searchField: CustomDataSearchFieldModel = {
      fieldName: '$nautical_portmovement_id',
      searchOperator: SearchOperators.InArray,
      searchValue: movementIds.map(String).join(),
    };

    const appendDataModel = new CustomDataAppendDataModel();
    if (preloadedFields && preloadedFields.length > 0) {
      appendDataModel.appendData = true;
      appendDataModel.appendFields = preloadedFields;
    }

    return this.customDataContent
      .searchCustomerDataByFields(
        'kp-activity',
        [searchField],
        null,
        null,
        null,
        0,
        500,
        appendDataModel
      )
      .pipe(
        map((response) => {
          if (response && response.result && response.result.length > 0) {
            response.result = _.map(response.result, (item: any) => {
              return KPActivityModel.fromApiModel(item);
            });
          }

          return response;
        })
      );
  }

  getActivitiesByPeriod(dateFrom, dateUntil, preloadedFields?: string[]) {
    let appendDataModel = null;
    if (preloadedFields && preloadedFields.length > 0) {
      appendDataModel = new CustomDataAppendDataModel();
      appendDataModel.appendData = true;
      appendDataModel.appendFields = preloadedFields;
    }

    return this.customDataContent
      .searchCustomerData(
        'kp-activity',
        null,
        null,
        null,
        null,
        null,
        0,
        9999,
        appendDataModel,
        dateFrom,
        dateUntil,
        true,
        '__LastEditedOn'
      )
      .pipe(
        map((response) => {
          if (response && response.result && response.result.length > 0) {
            response.result = _.map(response.result, (item: any) => {
              return KPActivityModel.fromApiModel(item);
            });
          }

          return response;
        })
      );
  }

  deleteActivity(
    activity: KPActivityModel,
    remarks: string
  ): Observable<ResultWrapper<KPActivityModel>> {
    return this.customDataContent
      .markAsDelete('kp-activity', activity.__Id, remarks)
      .pipe(
        map((response) => {
          if (response && response.result) {
            response.result = KPActivityModel.fromApiModel(response.result);
          }

          return response;
        })
      );
  }

  getPortVisitInfosByVisitIds(
    ids: number[],
    preloadedFields?: string[]
  ): Observable<ResultWrapper<any>> {
    const searchField: CustomDataSearchFieldModel = {
      fieldName: '$nautical_portvisit_id',
      searchOperator: SearchOperators.InArray,
      searchValue: ids.map(String).join(),
    };

    const appendDataModel = new CustomDataAppendDataModel();
    if (preloadedFields && preloadedFields.length > 0) {
      appendDataModel.appendData = true;
      appendDataModel.appendFields = preloadedFields;
    }

    return this.customDataContent.searchActiveCustomerDataByFields(
      'kpPortVisitInfo',
      [searchField],
      null,
      null,
      0,
      500,
      appendDataModel
    );
  }

  getShipRequirementsByShipIds(
    ids: number[],
    preloadedFields?: string[]
  ): Observable<ResultWrapper<any>> {
    const searchField: CustomDataSearchFieldModel = {
      fieldName: '$nautical_ship_id',
      searchOperator: SearchOperators.InArray,
      searchValue: ids.map(String).join(),
    };

    const appendDataModel = new CustomDataAppendDataModel();
    if (preloadedFields && preloadedFields.length > 0) {
      appendDataModel.appendData = true;
      appendDataModel.appendFields = preloadedFields;
    }

    return this.customDataContent.searchActiveCustomerDataByFields(
      'kpShipRequirements',
      [searchField],
      null,
      null,
      0,
      500,
      appendDataModel
    );
  }

  updateActivity(
    activity: KPActivityModel
  ): Observable<ResultWrapper<KPActivityModel>> {
    return this.customDataContent
      .updateCustomerData(
        'kp-activity',
        activity.__Id,
        KPActivityModel.toApiModel({ ...activity })
      )
      .pipe(
        map((response) => {
          if (response && response.result) {
            response.result = KPActivityModel.fromApiModel(response.result);
          }

          return response;
        })
      );
  }

  updatePortVisitInfo(
    model: KPPortVisitModel
  ): Observable<ResultWrapper<KPPortVisitModel>> {
    return this.customDataContent.updateCustomerData(
      'kpPortVisitInfo',
      model.__Id,
      model
    );
  }

  updateShipRequirements(
    model: KPShipRequirementsModel
  ): Observable<ResultWrapper<KPShipRequirementsModel>> {
    return this.customDataContent.updateCustomerData(
      'kpShipRequirements',
      model.__Id,
      model
    );
  }
}
