<div class="row">
  <div class="col-9">
    <div class="d-flex justify-content-center mt-5 mb-5">
      <div class="preview-section">
        <h3 class="text-center">
          {{ 'widgets.designer.preview' | translate }}
        </h3>
        <ca-task-widget [widget]="widget"></ca-task-widget>
      </div>
    </div>
  </div>
  <div class="col-3">
    <div class="card mb-3">
      <div class="card-header">
        {{ 'widgets.designer.configuration' | translate }}
      </div>
      <div class="card-body">
        <div class="mb-3">
          <label>
            <b>{{ 'widgets.invoice.bodyType' | translate }}</b>
          </label>
          <ng-select
            class="mb-3"
            [items]="bodyTypes"
            bindLabel="name"
            bindValue="id"
            [clearable]="false"
            [(ngModel)]="widget.bodyType"
            (change)="onChangeBodyType($event)"
          ></ng-select>
        </div>
      </div>
    </div>
  </div>
</div>
