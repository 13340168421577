import { Injectable } from '@angular/core';
import { ResultWrapper } from '@seahorse/common';
import { CustomDataAppendDataModel, CustomDataContentService, CustomDataSearchFieldModel, SearchOperators } from '@seahorse/domain';
import { NauticalShipModel } from '@seahorse/domain';
import { NauticalShipDataService } from '@seahorse/domain';
import { NauticalVisitDetailsModel } from '@seahorse/domain';
import { NauticalVisitDataService } from '@seahorse/domain';
import { Observable } from 'rxjs';
import * as _ from 'underscore';

import { ZeehavenIJmuidenMovementDetailsModel } from '../models/zeehaven-ijmuiden-movement-details.model';
import { ZeehavenIJmuidenMovementModel } from '../models/zeehaven-ijmuiden-movement.model';
import { ZeehavenIJmuidenStatus } from '../models/zeehaven-ijmuiden-status.model';
import { ZeehavenIJmuidenVisitModel } from '../models/zeehaven-ijmuiden-visit.model';

@Injectable()
export class ZeehavenIJmuidenVisitDataService {
  constructor(
    private customData: CustomDataContentService,
    private visitData: NauticalVisitDataService,
    private shipData: NauticalShipDataService
  ) {}

  getAllActiveVisits(): Observable<
    ResultWrapper<ZeehavenIJmuidenVisitModel[]>
  > {
    const searchField: CustomDataSearchFieldModel = {
      fieldName: 'status',
      searchOperator: SearchOperators.Equals,
      searchValue: ZeehavenIJmuidenStatus.Active,
    };
    const appendData = new CustomDataAppendDataModel(true);
    appendData.appendFields = [
      'nauticalShip_fisheryNumber',
      'nauticalShip_callSign',
    ];

    return this.customData.searchActiveCustomerDataByFields(
      'zeehavenIJmuidenVisit',
      [searchField],
      null,
      null,
      0,
      500,
      appendData,
      null,
      null,
      null,
      null,
      false
    );
  }

  getAllOpenMovements(): Observable<
    ResultWrapper<ZeehavenIJmuidenMovementModel[]>
  > {
    const searchField: CustomDataSearchFieldModel = {
      fieldName: 'status',
      searchOperator: SearchOperators.InArray,
      searchValue: `${ZeehavenIJmuidenStatus.Active},${ZeehavenIJmuidenStatus.Suggestion}`,
    };
    const appendData = new CustomDataAppendDataModel(true);
    appendData.appendFields = ['nauticalShip_fisheryNumber'];

    return this.customData.searchActiveCustomerDataByFields(
      'zeehavenIJmuidenMovement',
      [searchField],
      null,
      null,
      0,
      1000,
      appendData,
      null,
      null,
      null,
      null,
      false
    );
  }

  getCompletedMovements(): Observable<
    ResultWrapper<ZeehavenIJmuidenMovementModel[]>
  > {
    return this.customData.searchActiveCustomerData(
      'zeehavenIJmuidenMovement',
      ZeehavenIJmuidenStatus.Completed,
      'status',
      '__LastEditedOn',
      'desc',
      0,
      100,
      null,
      null,
      null,
      null,
      null,
      true
    );
  }

  getOpenMovementsByZBIJVisitId(
    visitId: string
  ): Observable<ResultWrapper<ZeehavenIJmuidenMovementModel[]>> {
    const statusField: CustomDataSearchFieldModel = {
      fieldName: 'status',
      searchOperator: SearchOperators.InArray,
      searchValue: `${ZeehavenIJmuidenStatus.Active},${ZeehavenIJmuidenStatus.Suggestion}`,
    };
    const visitField: CustomDataSearchFieldModel = {
      fieldName: 'zhijVisitId',
      searchOperator: SearchOperators.Equals,
      searchValue: visitId,
    };
    return this.customData.searchActiveCustomerDataByFields(
      'zeehavenIJmuidenMovement',
      [statusField, visitField],
      null,
      null,
      0,
      500,
      null,
      null,
      null,
      null,
      null,
      true
    );
  }

  getOpenVisitsByNauticalShipId(
    nauticalShipId: string
  ): Observable<ResultWrapper<ZeehavenIJmuidenVisitModel[]>> {
    const statusField: CustomDataSearchFieldModel = {
      fieldName: 'status',
      searchOperator: SearchOperators.InArray,
      // 2023-10-31 JV: Removed the suggestion, don't know what the overall effect is
      // searchValue: `${ZeehavenIJmuidenStatus.Active},${ZeehavenIJmuidenStatus.Suggestion}`,
      searchValue: `${ZeehavenIJmuidenStatus.Active}`,
    };
    const shipField: CustomDataSearchFieldModel = {
      fieldName: 'nauticalShip',
      searchOperator: SearchOperators.Equals,
      searchValue: nauticalShipId,
    };
    return this.customData.searchActiveCustomerDataByFields(
      'zeehavenIJmuidenVisit',
      [statusField, shipField],
      null,
      null,
      0,
      500,
      null,
      null,
      null,
      null,
      null,
      true
    );
  }

  getOpenVisitsByNauticalVisitId(
    nauticalVisitId: number
  ): Observable<ResultWrapper<ZeehavenIJmuidenVisitModel[]>> {
    const statusField: CustomDataSearchFieldModel = {
      fieldName: 'status',
      searchOperator: SearchOperators.InArray,
      searchValue: `${ZeehavenIJmuidenStatus.Active},${ZeehavenIJmuidenStatus.Suggestion}`,
    };
    const visitField: CustomDataSearchFieldModel = {
      fieldName: '$nautical_portvisit_id',
      searchOperator: SearchOperators.Equals,
      searchValue: nauticalVisitId,
    };
    return this.customData.searchActiveCustomerDataByFields(
      'zeehavenIJmuidenVisit',
      [statusField, visitField],
      null,
      null,
      0,
      500,
      null,
      null,
      null,
      null,
      null,
      true
    );
  }

  getVisitsByIds(
    zeehavenVisitIds: ZeehavenIJmuidenVisitModel['__Id'][]
  ): Observable<ResultWrapper<ZeehavenIJmuidenVisitModel[]>> {
    return this.customData.getCustomerDataByIds(
      'zeehavenIJmuidenVisit',
      zeehavenVisitIds
    );
  }

  getVisitsByMovementId(
    zeehavenMovementId: ZeehavenIJmuidenMovementModel['__Id']
  ): Observable<ResultWrapper<ZeehavenIJmuidenVisitModel[]>> {
    const query: CustomDataSearchFieldModel[] = [];
    query.push({
      fieldName: 'zhijMovementId',
      searchOperator: SearchOperators.Equals,
      searchValue: zeehavenMovementId,
    });

    return this.customData.searchActiveCustomerDataByFields(
      'zeehavenIJmuidenVisit',
      query,
      null,
      null,
      0,
      500
    );
  }

  getMovementById(
    zeehavenMovementId: ZeehavenIJmuidenMovementModel['__Id']
  ): Observable<ResultWrapper<ZeehavenIJmuidenMovementModel>> {
    return new Observable<ResultWrapper<ZeehavenIJmuidenMovementModel>>((s) => {
      let loading: boolean;
      const appendData = new CustomDataAppendDataModel(true);

      this.customData
        .getCustomerDataByIds(
          'zeehavenIJmuidenMovement',
          [zeehavenMovementId],
          appendData
        )
        .subscribe(
          (rCustom: ResultWrapper<ZeehavenIJmuidenMovementModel[]>) => {
            const w: ResultWrapper<ZeehavenIJmuidenMovementDetailsModel> =
              _.extend(_.omit(rCustom, 'result'), { result: null });

            if (rCustom.hasResult && rCustom.result.length) {
              w.result = rCustom.result[0];
              loading = true;

              if (w.result.$nautical_portvisit_id) {
                this.visitData
                  .getDetails(w.result.$nautical_portvisit_id)
                  .subscribe(
                    (rVisit: NauticalVisitDetailsModel) => {
                      w.result.$nautical_portvisit = rVisit;
                      w.result.$nautical_ship = rVisit.ship;

                      if (w.result.$nautical_portmovement_id) {
                        _.forEach(rVisit.portMovements, (m) => {
                          // tslint:disable-next-line: triple-equals
                          if (m.id == w.result.$nautical_portmovement_id) {
                            w.result.$nautical_movement = m;

                            s.next(w);
                          }
                        });
                      } else {
                        s.next(w);
                      }
                    },

                    (eVisit) => s.error(eVisit),

                    () => {
                      loading = false;

                      s.complete();
                    }
                  );
              } else if (w.result.nauticalShip) {
                this.shipData.getById(w.result.nauticalShip).subscribe(
                  (rShip: NauticalShipModel) => {
                    w.result.$nautical_ship = rShip;

                    s.next(w);
                  },

                  (eShip) => s.error(eShip),

                  () => {
                    loading = false;

                    s.complete();
                  }
                );
              } else {
                loading = false;

                s.next(w);
              }
            }
          },

          (eCustom) => s.error(eCustom),

          () => {
            if (!loading) {
              s.complete();
            }
          }
        );
    });
  }

  add(
    zeehavenMovement: ZeehavenIJmuidenMovementModel
  ): Observable<ResultWrapper<ZeehavenIJmuidenMovementModel>> {
    return new Observable<ResultWrapper<ZeehavenIJmuidenMovementModel>>((s) => {
      if (zeehavenMovement.$nautical_portmovement_id) {
        let loading = false;

        const query: CustomDataSearchFieldModel[] = [];
        query.push({
          fieldName: '$nautical_movement_id',
          searchOperator: SearchOperators.Equals,
          searchValue: zeehavenMovement.$nautical_portmovement_id,
        });
        query.push({
          fieldName: 'movementType',
          searchOperator: SearchOperators.Equals,
          searchValue: zeehavenMovement.movementType,
        });

        this.customData
          .searchActiveCustomerDataByFields(
            'zeehavenIJmuidenMovement',
            query,
            null,
            null,
            0,
            500,
            null,
            null,
            null,
            null,
            null,
            true
          )
          .subscribe(
            (rSearch: ResultWrapper<ZeehavenIJmuidenMovementModel[]>) => {
              if (rSearch.hasResult && rSearch.result.length) {
                const m: ResultWrapper<ZeehavenIJmuidenMovementModel> =
                  new ResultWrapper<ZeehavenIJmuidenMovementModel>();
                m.count = rSearch.count;
                m.hasResult = rSearch.hasResult;
                m.messages = rSearch.messages;
                m.result = rSearch.result[0];
                m.status = rSearch.status;

                s.next(m);
              } else {
                loading = true;

                this.customData
                  .addCustomerData('zeehavenIJmuidenMovement', zeehavenMovement)
                  .subscribe(
                    (rAdd: ResultWrapper<ZeehavenIJmuidenMovementModel>) =>
                      s.next(rAdd),
                    (eAdd) => s.error(eAdd),

                    () => {
                      loading = false;

                      s.complete();
                    }
                  );
              }
            },

            (eSearch) => s.error(eSearch),

            () => {
              if (!loading) {
                s.complete();
              }
            }
          );
      } else {
        this.customData
          .addCustomerData('zeehavenIJmuidenMovement', zeehavenMovement)
          .subscribe(
            (rAdd: ResultWrapper<ZeehavenIJmuidenMovementModel>) =>
              s.next(rAdd),
            (eAdd) => s.error(eAdd),
            () => s.complete()
          );
      }
    });
  }

  update(
    zeehavenMovement: ZeehavenIJmuidenMovementModel
  ): Observable<ResultWrapper<ZeehavenIJmuidenMovementModel>> {
    return this.customData.updateCustomerData(
      'zeehavenIJmuidenMovement',
      zeehavenMovement.__Id,
      zeehavenMovement
    );
  }

  remove(
    zeehavenMovementId: ZeehavenIJmuidenMovementModel['__Id']
  ): Observable<ResultWrapper<ZeehavenIJmuidenMovementModel>> {
    return this.customData.deleteCustomerData(
      'zeehavenIJmuidenMovement',
      zeehavenMovementId
    );
  }

  addVisit(
    zeehavenVisit: ZeehavenIJmuidenVisitModel
  ): Observable<ResultWrapper<ZeehavenIJmuidenVisitModel>> {
    return this.customData.addCustomerData(
      'zeehavenIJmuidenVisit',
      zeehavenVisit
    );
  }

  updateVisit(
    zeehavenVisit: ZeehavenIJmuidenVisitModel
  ): Observable<ResultWrapper<ZeehavenIJmuidenVisitModel>> {
    return this.customData.updateCustomerData(
      'zeehavenIJmuidenVisit',
      zeehavenVisit.__Id,
      zeehavenVisit
    );
  }
}
