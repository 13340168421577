import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { KPEventModel } from '../../models/kp-event.model';
import { KPActivityModel } from '../../models/kp-activity.model';

@Component({
  selector: 'ca-custom-kp-remarks-modal',
  templateUrl: 'remarks-modal.component.html',
})
export class KPRemarksModal implements OnInit {
  @Input() activity: KPActivityModel = null;
  @Input() objectType: string;
  @Input() remarks: string;

  constructor(public modal: NgbActiveModal) {}

  ngOnInit() {
    if (!this.objectType) {
      this.objectType = 'activity';
    }
  }

  dismiss(result: any) {
    this.modal.close(result);
  }

  save() {
    const result = new KPEventModel();
    result.objectType = this.objectType;
    result.data = this.remarks;
    this.modal.close(result);
  }
}
