import { Component, Input, Output, EventEmitter } from '@angular/core';
import { EventModel } from '../../../../../shared/models/event.model';
import { TaskDefinitionListModel } from '../../models/task-definition-list.model';

@Component({
  selector: 'task-definition-list-list',
  templateUrl: './task-definition-list-list.component.html',
})
export class TaskDefinitionListListComponent {
  @Input() taskDefinitionLists: TaskDefinitionListModel[];
  @Input() selected: TaskDefinitionListModel;

  @Output() onEvent: EventEmitter<EventModel>;

  constructor() {
    this.taskDefinitionLists = [];
    this.selected = null;
    this.onEvent = new EventEmitter<EventModel>();
  }
}
