import { Component, Input } from '@angular/core';

import { Languages } from '../../../../core/models/language.model';
import { PreferenceModel } from '../../../../preference/models/preference.model';

@Component({
  selector: 'user-language-form',
  templateUrl: './user-language-form.component.html',
})
export class UserLanguageFormComponent {
  languages: Array<any> = Languages;

  @Input() model: PreferenceModel;
  @Input() submitted = false;
}
