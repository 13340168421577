import { Injectable, Injector } from '@angular/core';
import { EMPTY, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { ModalAction, ModalResponse } from '../modal/modal.model';
import { ModalService } from '../modal/modal.service';
import { FormComponent } from './form.component';
import { FormModel } from './form.model';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  isPendingChangesDialogOpen = false;
  isFieldEditActive = false;
  isInlineEditActive = false;
  isModalEditActive = false;

  constructor(private _modalService: ModalService) {}

  openFormModal(model: FormModel, title: string, injector?: Injector) {
    return this._modalService
      .buildModal()
      .withTitle(title)
      .withComponentBody({
        component: FormComponent,
        props: {
          model,
        },
        injector,
      })
      .open()
      .pipe(
        mergeMap(this.submitFormModal),
        catchError(() => EMPTY)
      );
  }

  openFieldModal(model: FormModel, injector: Injector) {
    return this.openFormModal(model, null, injector).pipe(
      map((value) => Object.values(value)[0])
    );
  }

  openPendingChangesDialog() {
    this.isPendingChangesDialogOpen = true;

    return this._modalService
      .buildModal()
      .withTitle('forms.validation.pendingChangesDialog.title')
      .withTextBody('forms.validation.pendingChangesDialog.body', {
        textAlign: 'center',
      })
      .open({ closeWithEscape: false })
      .pipe(
        tap((response) => {
          this.isPendingChangesDialogOpen = false;
          response.modalRef.close();
        })
      );
  }

  closeModals() {
    this._modalService.closeModals();
  }

  submitFormModal = (response: ModalResponse<FormComponent>) => {
    if (response.action === ModalAction.Close) {
      return throwError(null);
    }

    const formValue = response.componentRef.submit();

    if (!formValue) {
      return EMPTY;
    }

    response.modalRef.close();
    return of(formValue);
  };
}
