import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ExpenseUpdateModalHeader } from '../../expense-header.model';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { ExpenseEventType } from '@seahorse/domain';
import { CommonModule } from '@angular/common';
import { LoadingIndicatorComponent } from '@seahorse/ui';

@Component({
  selector: 'temp-expense-search-header',
  template: `<form class="form-inline mx-3" (ngSubmit)="onSearch()">
    <div class="input-group">
      <input
        type="text"
        class="form-control"
        [placeholder]="'shared.terms.search' | translate"
        [(ngModel)]="searchQuery"
        name="searchBox"
        [disabled]="isLoading"
      />
      <div class="input-group-append">
        <button
          class="btn btn-outline-secondary"
          type="submit"
          [disabled]="isLoading"
        >
          <i class="fa-solid fa-search"></i>
        </button>
      </div>
      <sh-loading-indicator
        *ngIf="isLoading"
        class="d-flex justify-content-center loader-sm mr-2 mt-2"
      >
      </sh-loading-indicator>
    </div>
  </form>`,
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    LoadingIndicatorComponent,
  ],
})
export class ExpenseSearchHeaderComponent implements ExpenseUpdateModalHeader {
  searchQuery: string = null;

  // interface implementation
  @Input() isLoading = false;
  @Output() filterChange: EventEmitter<ExpenseUpdateModalHeader> =
    new EventEmitter<ExpenseUpdateModalHeader>();
  type: ExpenseEventType;
  data: string;

  constructor() {
    this.type = ExpenseEventType.Search;
  }

  onSearch() {
    if (
      this.searchQuery === undefined ||
      this.searchQuery === null ||
      this.searchQuery === ''
    ) {
      this.data = null;
    } else {
      this.data = this.searchQuery;
    }

    this.filterChange.emit(this);
  }
}
