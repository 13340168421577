<div class="page-header d-flex justify-content-between px-3 py-1">
  <div class="d-flex mx-n1">
    <ca-filter-list
      [button-displayText]="selectedPortText"
      [list-data]="ports"
      list-displayMember="displayText"
      [list-title]="'shared.terms.availablePorts' | translate"
      (selected)="setSelectedPort($event)"
    ></ca-filter-list>
    <div class="input-group align-items-center w-auto mx-1">
      <div class="input-group-prepend">
        <span class="input-group-text">{{
          'shared.terms.view' | translate
        }}</span>
      </div>
      <select
        required
        name="view"
        class="form-control mr-3"
        [(ngModel)]="selectedView"
        (change)="setSelectedView()"
      >
        <option [value]="view.List">{{ 'shared.terms.list' | translate }}</option>
        <option [value]="view.Tiles">
          {{ 'shared.terms.tiles' | translate }}
        </option>
      </select>
    </div>
    <div
      class="input-group align-items-center w-auto mx-1"
      *ngIf="nauticalFilterItems && nauticalFilterItems.length > 0"
    >
      <div class="input-group-prepend">
        <span class="input-group-text">{{
          'shared.terms.filter' | translate
        }}</span>
      </div>
      <select
        class="form-control mr-3"
        [(ngModel)]="selectedNauticalFilter"
        (change)="setSelectedNauticalFilter()"
      >
        <option
          [ngValue]="nauticalFilterItem.id"
          *ngFor="let nauticalFilterItem of nauticalFilterItems"
        >
          {{ nauticalFilterItem.name }}
        </option>
      </select>
    </div>
  </div>
  <div class="dropdown">
    <button
      class="btn btn-outline-secondary dropdown-toggle m-1"
      id="ddl_header_visit_filter"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      data-offset="0,1"
    >
      <i class="fa-regular fa-filter fa-fw mr-1"></i>
      <span>{{ selectedFilter | translate }}</span>
    </button>
    <button
      type="button"
      class="btn btn-outline-secondary"
      (click)="printEvent.emit(true)"
    >
      {{ 'shared.terms.print' | translate }}
    </button>
    <div
      class="dropdown-menu dropdown-menu-right"
      aria-labelledby="ddl_header_visit_filter"
    >
      <button class="dropdown-item" (click)="setSelectedFilter('')">
        {{ 'nautical.visit.filter.noFilter' | translate }}
      </button>
      <button class="dropdown-item" (click)="setSelectedFilter('departing')">
        {{ 'nautical.visit.filter.departing' | translate }}
      </button>
      <button class="dropdown-item" (click)="setSelectedFilter('expected')">
        {{ 'nautical.visit.filter.expected' | translate }}
      </button>
      <button class="dropdown-item" (click)="setSelectedFilter('inport')">
        {{ 'nautical.visit.filter.inport' | translate }}
      </button>
      <button class="dropdown-item" (click)="setSelectedFilter('sailed')">
        {{ 'nautical.visit.filter.sailed' | translate }}
      </button>
    </div>
  </div>
</div>
