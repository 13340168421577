<sh-card no-padding *ngIf="render">
  <ng-container header>
    {{ 'purchaseInvoices.title' | translate }}
    <div class="btn-list">
      <div class="small input-group">
        <ng-content></ng-content>
      </div>
    </div>
  </ng-container>

  <temp-data-list
    [items]="items"
    [config]="collectionConfig"
    (select)="onClick($event)"
    [emptyPlaceholderConfig]="{
      size: 'md',
      message: 'shared.terms.noResult',
      icon: undefined
    }"
  >
  </temp-data-list>
</sh-card>
