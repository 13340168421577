import { Pipe, PipeTransform } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { StageModel, StageTypes } from '@seahorse/domain';

@Pipe({ name: 'stageType' })
export class StageTypePipe implements PipeTransform {
  stageTypeQuery: string;
  stageType: string;

  constructor(private translateService: TranslateService) {
    this.stageTypeQuery = null;
    this.stageType = null;
  }

  transform(stageType: StageModel['stageType']): string {
    const type = StageTypes[stageType];
    if (type === undefined) {
      this.stageTypeQuery = 'unknown';
    } else {
      this.stageTypeQuery =
        type.substring(0, 1).toLowerCase() + type.substring(1);
    }

    this.translateService
      .get(`invoicingFlows.stageTypes.${this.stageTypeQuery}`)
      .subscribe((translateGetResponse: any) => {
        this.stageType = translateGetResponse;
      });

    return this.stageType;
  }
}
