import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ResultWrapper } from '@seahorse/common';
import { UserDataService } from '../../../user/services/user-data.service';

import {
  CustomDataContentService,
  CustomDataSearchFieldModel,
  SearchOperators,
} from '@seahorse/domain';

import { CustomMemberModel } from '../models/custom-member.model';

@Injectable()
export class CustomMemberDataService {
  constructor(
    private customDataContentService: CustomDataContentService,
    private userDataService: UserDataService
  ) {}

  get(): Observable<ResultWrapper<any>> {
    return this.customDataContentService.getAllCustomerData('members').pipe(
      map((response) => {
        if (response.hasResult && response.result && response.result.length) {
          for (let i = 0; i < response.result.length; i++) {
            response.result[i] = CustomMemberModel.fromApiModel(
              response.result[i],
              this.userDataService
            );
          }
        }

        return response;
      })
    );
  }

  getByUserId(
    userId: CustomMemberModel['$usermanager_user_id']
  ): Observable<ResultWrapper<any>> {
    const field: CustomDataSearchFieldModel = {
      fieldName: '$usermanager_user_id',
      searchOperator: SearchOperators.Equals,
      searchValue: userId,
    };

    return this.customDataContentService
      .searchActiveCustomerDataByFields('members', [field], null, null, 0, 500)
      .pipe(
        map((response) => {
          if (
            response.hasResult &&
            response.result &&
            response.result.length > 0
          ) {
            for (let i = 0; i < response.result.length; i++) {
              response.result[i] = CustomMemberModel.fromApiModel(
                response.result[i],
                this.userDataService
              );
            }
          }

          return response;
        })
      );
  }

  getByMembergroupId(
    membergroupId: CustomMemberModel['$customdata_membergroup_ids']
  ): Observable<ResultWrapper<any>> {
    const field: CustomDataSearchFieldModel = {
      fieldName: '$customdata_membergroup_ids',
      searchOperator: SearchOperators.Contains,
      searchValue: membergroupId.join(),
    };

    return this.customDataContentService
      .searchActiveCustomerDataByFields('members', [field], null, null, 0, 500)
      .pipe(
        map((response) => {
          if (
            response.hasResult &&
            response.result &&
            response.result.length > 0
          ) {
            for (let i = 0; i < response.result.length; i++) {
              response.result[i] = CustomMemberModel.fromApiModel(
                response.result[i],
                this.userDataService
              );
            }
          }

          return response;
        })
      );
  }

  add(member: CustomMemberModel): Observable<ResultWrapper<any>> {
    return this.customDataContentService
      .addCustomerData('members', CustomMemberModel.toApiModel(member))
      .pipe(
        map((response) => {
          if (response.hasResult && response.result) {
            response.result = CustomMemberModel.fromApiModel(
              response.result,
              this.userDataService
            );
          }

          return response;
        })
      );
  }

  update(member: CustomMemberModel): Observable<ResultWrapper<any>> {
    return this.customDataContentService
      .updateCustomerData(
        'members',
        member.__Id,
        CustomMemberModel.toApiModel(member)
      )
      .pipe(
        map((response) => {
          if (response.hasResult && response.result) {
            response.result = CustomMemberModel.fromApiModel(
              response.result,
              this.userDataService
            );
          }

          return response;
        })
      );
  }

  delete(id: CustomMemberModel['__Id']): Observable<ResultWrapper<any>> {
    return this.customDataContentService.deleteCustomerData('members', id);
  }
}
