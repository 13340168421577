import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Router } from '@angular/router';
import { ResultWrapper } from '@seahorse/common';
import { CompanyInvoicesDataService } from '../../../../company/services/company-invoices-data.service';
import { CompanyModel, InvoiceModel } from '@seahorse/domain';
import { EventModel } from '../../../../shared/models/event.model';

@Component({
  selector: 'company-invoices-card',
  templateUrl: 'company-invoices-card.component.html',
})
export class CompanyInvoicesCardComponent implements OnInit {
  @Input() company: CompanyModel;

  @Output() onEvent: EventEmitter<EventModel>;

  isLoading: boolean;
  canLoadMore: boolean;
  pageIndex: number;
  pageSize: number;
  invoices: InvoiceModel[];

  constructor(
    private companyInvoicesDataService: CompanyInvoicesDataService,
    private router: Router
  ) {
    this.company = new CompanyModel();
    this.onEvent = new EventEmitter<EventModel>();
    this.isLoading = false;
    this.canLoadMore = false;
    this.pageIndex = 0;
    this.pageSize = 5;
    this.invoices = [];
  }

  ngOnInit() {
    this.loadInvoices();
  }

  loadInvoices() {
    this.isLoading = true;

    this.companyInvoicesDataService
      .getByCompanyId(this.company.id, this.pageIndex, this.pageSize)
      .subscribe(
        (companyInvoicesGetByCompanyIdResponse: ResultWrapper<any>) => {
          if (companyInvoicesGetByCompanyIdResponse.hasResult) {
            if (this.pageIndex > 0) {
              this.invoices = this.invoices.concat(
                companyInvoicesGetByCompanyIdResponse.result
              );
            } else {
              this.invoices = companyInvoicesGetByCompanyIdResponse.result;
            }

            this.canLoadMore =
              this.invoices.length <
              companyInvoicesGetByCompanyIdResponse.count;
            this.isLoading = false;
          }
        }
      );
  }

  loadMore() {
    if (this.company) {
      this.router.navigateByUrl(
        `/invoicing-and-tariffs/invoicing;companyId=${this.company.id}`
      );
    } else {
      this.pageIndex += 1;
      this.loadInvoices();
    }
  }
}
