import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SystemMessageModel } from '../../../../preference/models/system-message.model';
import { SystemMessageTranslationModalComponent } from '../system-message-translation-modal/system-message-translation-modal.component';

@Component({
  selector: 'ca-system-message-form',
  templateUrl: './system-message-form.component.html',
})
export class SystemMessageFormComponent {
  systemMessageTypes: Array<any> = [
    { value: 1, label: 'ReleaseNote' },
    { value: 2, label: 'Maintenance' },
    { value: 3, label: 'Outage' },
  ];

  @Input() model: SystemMessageModel;
  @Input() submitted = false;

  constructor(private modal: NgbModal) {}

  addTranslation() {
    const modalRef = this.modal.open(SystemMessageTranslationModalComponent);
    modalRef.componentInstance.translation = {};

    modalRef.result
      .then((modalResult) => {
        if (this.model.systemMessageTranslations === null) {
          this.model.systemMessageTranslations = [];
        }
        this.model.systemMessageTranslations.push(modalResult);
      })
      .catch((error) => {});
  }

  updateTranslation(translation) {
    const modalRef = this.modal.open(SystemMessageTranslationModalComponent);
    modalRef.componentInstance.translation = translation;

    modalRef.result
      .then((modalResult) => {
        translation = modalResult;
      })
      .catch((error) => {});
  }
}
