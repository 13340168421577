<div class="card">
  <div class="card-header">
    {{ company.name }}
    <div class="btn-list">
      <button
        *hasPermission="'companies.companies_update'"
        (click)="onEvent.emit({ action: 'editCompany' })"
        type="button"
        class="btn btn-sm btn-outline-secondary"
      >
        <i class="fa-regular fa-pencil-alt fa-fw"></i>
        {{ 'shared.terms.edit' | translate }}
      </button>
    </div>
  </div>
  <div class="card-body" *ngIf="company">
    <ul
      class="list-inline list-inline-seperated"
      *ngIf="company.companyTypes && company.companyTypes.length > 0"
    >
      <li class="list-inline-item">
        <i class="fa-regular fa-tags text-muted"></i>
      </li>
      <li class="list-inline-item" *ngFor="let tag of company.companyTypes">
        {{ tag.name }}
      </li>
    </ul>
    <div class="card-body-group card-body-group-no-border">
      <div class="attribute-group attribute-group-col-2">
        <div class="attribute-group-item">
          <dt>{{ 'companies.company.model.number' | translate }}</dt>
          <dd>{{ company.number }}</dd>
        </div>
        <div class="attribute-group-item">
          <dt>{{ 'companies.company.model.parentCompany' | translate }}</dt>
          <dd>
            <button
              (click)="onEvent.emit({ action: 'addParent' })"
              type="button"
              class="btn btn-link py-0"
            >
              <span *ngIf="hasParent">{{ company.parentCompany?.name }}</span>
              <span *ngIf="!hasParent"
                >{{ 'shared.terms.select' | translate }}...</span
              >
            </button>
          </dd>
        </div>
      </div>
    </div>
    <div *ngIf="isCustomerPortalEnabled" class="attribute-group">
      <div
        class="attribute-group-item"
        *hasPermission="'companies.companies_customerportal_admin'"
      >
        <dt>
          {{ 'companies.company.optInStatus' | translate }}
        </dt>
        <dd>
          <button
            (click)="onEvent.emit({ action: 'editOptInStatus' })"
            type="button"
            class="btn btn-link py-0"
          >
            <span>{{ companyReference?.status | optInStatus }}</span>
          </button>
        </dd>
      </div>
    </div>
  </div>
</div>
