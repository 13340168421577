<form>
  <div class="row">
    <div class="col-12">
      <div class="form-group">
        <label class="control-label required"
          ><b>{{ 'systemMessage.subject' | translate }}</b></label
        >
        <input
          #subject="ngModel"
          type="text"
          name="subject"
          [(ngModel)]="model.subject"
          class="form-control"
        />
        <small *ngIf="submitted && !model.subject" class="text-danger">{{
          'systemMessage.errors.subjectEmpty' | translate
        }}</small>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="form-group">
        <label class="control-label required"
          ><b>{{ 'systemMessage.systemMessageType' | translate }}</b></label
        >
        <ng-select
          required
          #type
          name="type"
          [items]="systemMessageTypes"
          placeholder="{{ 'companies.address.selectType' | translate }}"
          bindValue="value"
          [clearable]="false"
          [searchable]="false"
          [(ngModel)]="model.systemMessageType"
        >
        </ng-select>
        <small
          *ngIf="submitted && model.systemMessageType == null"
          class="text-danger"
        >
          {{ 'systemMessage.errors.typeEmpty' | translate }}</small
        >
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label class="control-label required"
          ><b>{{ 'systemMessage.version' | translate }}</b></label
        >
        <input
          #version="ngModel"
          type="text"
          name="version"
          numbersOnly
          [(ngModel)]="model.version"
          class="form-control"
        />
        <small *ngIf="submitted && !model.version" class="text-danger">{{
          'systemMessage.errors.versionEmpty' | translate
        }}</small>
      </div>
    </div>

    <div class="col-6">
      <div class="form-group">
        <label class="control-label required"
          ><b>{{ 'systemMessage.createdOn' | translate }}</b></label
        >
        <div class="input-group">
          <temp-date-time-picker
            [(dateTime)]="model.createdOn"
            [dateTimeFormat]="'YYYY-MM-DD[T00:00:00.000Z]'"
            [isReadOnly]="true"
            [isContained]="true"
            [hideTime]="true"
            [hideClear]="true"
            [hideNow]="true"
          ></temp-date-time-picker>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="form-group">
        <label class="control-label required"
          ><b>{{ 'systemMessage.notes' | translate }}</b></label
        >
        <div>
          <textarea
            rows="4"
            class="form-control textarea-field"
            #notes
            type="text"
            [(ngModel)]="model.notes"
            name="notes"
          ></textarea>
          <small *ngIf="submitted && !model.notes" class="text-danger">{{
            'systemMessage.errors.notesEmpty' | translate
          }}</small>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="card mb-3">
        <div class="card-header">
          {{ 'systemMessage.translations' | translate }}
          <div class="btn-list">
            <button
              type="button"
              class="btn btn-sm btn-outline-secondary"
              (click)="addTranslation()"
            >
              <i class="fa-regular fa-plus fa-fw"></i>
              {{ 'shared.terms.add' | translate }}
            </button>
          </div>
        </div>
        <div class="card-body card-body-table">
          <table class="table table-clickable">
            <thead>
              <tr>
                <th>{{ 'systemMessage.subject' | translate }}</th>
                <th>{{ 'shared.terms.language' | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let translation of model.systemMessageTranslations"
                (click)="updateTranslation(translation)"
              >
                <td>{{ translation.subject }}</td>
                <td>{{ translation.language }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="model.id > 0">
    <div class="col-12">
      <system-message-release-card
        [systemMessageId]="model.id"
        [systemMessageReleases]="model.systemMessageReleases"
      >
      </system-message-release-card>
    </div>
  </div>
</form>
