/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { CollectionDisplay } from '../../../layout/models/collection-display.model';
import { LayoutService } from '@seahorse/layout';
import { Subscription } from 'rxjs';
import { CreateAdvancedSearchForm } from './advanced-search.form';
import { CompanySearchModel } from '@seahorse/domain';
import { DropDownListOption } from '@seahorse/forms';
import { CompanyDataService, CompanyModel } from '@seahorse/domain';

@Component({
  selector: 'ca-company-header',
  templateUrl: './company-header.component.html',
})
export class CompanyHeaderComponent implements OnDestroy {
  view = CollectionDisplay;

  @Input() searchQuery: string = null;
  @Input() searchResultCount: number = null;
  @Input() selectedView: CollectionDisplay = CollectionDisplay.Tiles;

  @Output() onNewButtonClicked = new EventEmitter();
  @Output() onSearchChanged = new EventEmitter<string>();
  @Output() onViewChanged = new EventEmitter<CollectionDisplay>();

  form = CreateAdvancedSearchForm();
  sidebarOpen = false;
  showAdvancedSearch = false;
  companyTypeDropDownList = new Array<DropDownListOption>();

  set selectedParentCompany(value:CompanyModel|null) {
    this._selectedParentCompany = value;

    if (value)
      this.form.patchValue({parentCompanyId: value.id});
    else
      this.form.patchValue({parentCompanyId: null});
  }

  get selectedParentCompany(): CompanyModel|null {
    return this._selectedParentCompany;
  }

  @Output() advancedSearchEvent = new EventEmitter<CompanySearchModel|null>();

  private subscriptions$ = new Subscription();
  private _selectedParentCompany: CompanyModel|null = null;

  constructor(
    private _layoutService: LayoutService,
    private _companyDataService: CompanyDataService) {
    this.subscriptions$.add(
      this._layoutService.sidebarToggle$.subscribe((sidebarOpen: boolean) => {
        this.sidebarOpen = sidebarOpen;
      })
    );
    this.subscriptions$.add(
      this._companyDataService.getTypesAssigned().subscribe({
        next: (response) => {
          if (response.hasResult) {
            this.companyTypeDropDownList = response.result.map(x => {
              return { label: x.name, value: x.id }
            });
          }
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }

  newButtonClicked() {
    this.onNewButtonClicked.emit();
  }

  searchCompanies() {
    this.onSearchChanged.emit(this.searchQuery);
  }

  setSelectedView() {
    this.onViewChanged.emit(Number(this.selectedView));
  }

  clearAdvancedSearch() {
    this.selectedParentCompany = null;
    this.form.reset();
  }

  advancedSearch() {
    const companySearchModel = this.form.getRawValue() as CompanySearchModel;
    if (
      (companySearchModel.name && companySearchModel.name !== '')
      || (companySearchModel.debtorNumber && companySearchModel.debtorNumber !== '')
      || (companySearchModel.contactName && companySearchModel.contactName !== '')
      || companySearchModel.parentCompanyId
      || (companySearchModel.typeIds && companySearchModel.typeIds.length)
    ) {
      this.advancedSearchEvent.emit(companySearchModel);
    } else
      this.advancedSearchEvent.emit(null);
  }
}
