<div class="btn-list text-right pt-2 pr-2" [class.move-left]="showLibrary">
  <button
    *ngIf="enableEditing"
    class="btn btn-primary btn-sm mr-1"
    (click)="save()"
  >
    {{ 'shared.terms.save' | translate }}
  </button>
  <button
    *ngIf="enableEditing"
    class="btn btn-outline-secondary btn-sm mr-1"
    (click)="cancel()"
  >
    {{ 'shared.terms.cancel' | translate }}
  </button>
  <button
    *ngIf="enableEditing"
    class="btn btn-outline-secondary btn-sm mr-1"
    (click)="showLibrary = !showLibrary"
  >
    {{ 'widgets.text' | translate }}
  </button>
  <div *ngIf="!enableEditing" class="dropdown">
    <button
      type="button"
      class="btn btn-outline-secondary btn-sm"
      id="headerHamburgerButton"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <i class="fa-regular fa-ellipsis-v fa-fw"></i>
    </button>
    <div class="dropdown-menu" aria-labelledby="headerHamburgerButton">
      <button
        *ngIf="!enableEditing"
        class="btn dropdown-item btn-sm"
        (click)="edit()"
      >
        {{ 'shared.terms.edit' | translate }}
      </button>
      <button class="btn dropdown-item btn-sm" (click)="resetToCompany()">
        {{ 'widgets.dashboard.reset' | translate }}
      </button>
    </div>
  </div>
</div>
<div class="container-fluid py-3">
  <div class="pr-2">
    <ejs-dashboardlayout
      id="dashboard"
      columns="4"
      #dashboard
      [cellSpacing]="cellSpacing"
      [allowResizing]="enableEditing"
      [allowDragging]="enableEditing"
      [allowFloating]="enableEditing"
      [allowPushing]="enableEditing"
      [hidden]="!hasWidgets"
    >
    </ejs-dashboardlayout>
    <div *ngIf="!hasWidgets" class="text-center mt-5">
      <p class="font-weight-bold">
        {{ 'widgets.dashboard.empty' | translate }}
      </p>
      <p>{{ 'widgets.dashboard.choose' | translate }}</p>
    </div>
    <ca-widgets-library
      *ngIf="showLibrary"
      [widgetsOnDashboardIds]="widgetsOnDashboardIds"
      (selectedWidget)="addWidget($event)"
    ></ca-widgets-library>
  </div>
</div>
