import {
  ApplicationRef,
  createComponent,
  EmbeddedViewRef,
  EventEmitter,
  Injectable,
  Injector,
  TemplateRef,
  Type,
  ViewContainerRef,
} from '@angular/core';
import { TemplateHost } from '../templating/template-host';
import { ComponentDescriptor } from './dynamics.model';

/**
 * Service providing dynamic component creation features.
 */
@Injectable({
  providedIn: 'root',
})
export class DynamicsService {
  constructor(private _injector: Injector, private _appRef: ApplicationRef) {}

  createComponent<TComponent>(
    descriptor: ComponentDescriptor<TComponent>,
    hostView?: ViewContainerRef
  ) {
    const componentRef = createComponent(descriptor.component, {
      environmentInjector: this._appRef.injector,
      elementInjector: descriptor.injector || this._injector,
      projectableNodes: descriptor.projectableNodes,
    });

    const content = componentRef.location.nativeElement as HTMLElement;

    if (descriptor.hostAttributes) {
      Object.keys(descriptor.hostAttributes).forEach((key) =>
        content.setAttribute(descriptor.hostAttributes[key], '')
      );
    }

    Object.assign(componentRef.instance, descriptor.props);

    if (descriptor.events) {
      Object.keys(descriptor.events).forEach((key) =>
        (<EventEmitter<any>>componentRef.instance[key]).subscribe(
          descriptor.events[key]
        )
      );
    }

    componentRef.changeDetectorRef.detectChanges();

    if (hostView) {
      hostView.insert(componentRef.hostView);
    } else {
      this._appRef.attachView(componentRef.hostView);
      componentRef.onDestroy(() => {
        this._appRef.detachView(componentRef.hostView);
      });
    }

    return componentRef;
  }

  renderTemplate(
    templateRef: TemplateRef<any>,
    hostView: ViewContainerRef,
    context?: any
  ): EmbeddedViewRef<any> {
    return hostView.createEmbeddedView(templateRef, context);
  }

  renderStandaloneTemplate<THost extends TemplateHost>(
    templateHost: Type<THost>,
    hostView: ViewContainerRef,
    context?: any
  ): EmbeddedViewRef<any> {
    const hostRef = this.createComponent({
      component: templateHost,
    });

    hostRef.changeDetectorRef.detectChanges();
    const templateRef = hostRef.instance.templateRef;

    if (!templateRef) {
      throw new Error('No templateRef found in the provided TemplateHost');
    }

    return hostView.createEmbeddedView(templateRef, context);
  }
}
