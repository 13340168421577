import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ResultWrapper } from '@seahorse/common';
import { NotificationService } from '@seahorse/common';
import { LayoutDisplayService } from '../../../../layout/services/layout-display.service';
import { EventModel } from '../../../../shared/models/event.model';
import { forkJoin } from 'rxjs';
import * as _ from 'underscore';

import { SuggestionPickerModalComponent } from '../../components/suggestion-picker-modal/suggestion-picker-modal.component';
import { ZeehavenIJmuidenVisitListPickerModalComponent } from '../../components/zeehaven-ijmuiden-visit-list-picker-modal/zeehaven-ijmuiden-visit-list-picker-modal.component';
import { ZeehavenIJmuidenMovementDetailsModel } from '../../models/zeehaven-ijmuiden-movement-details.model';
import {
  ZeehavenIJmuidenMovementModel,
  ZeehavenIJmuidenVisitMovementType,
} from '../../models/zeehaven-ijmuiden-movement.model';
import { ZeehavenIJmuidenOverviewModel } from '../../models/zeehaven-ijmuiden-overview.model';
import { ZeehavenIJmuidenStatus } from '../../models/zeehaven-ijmuiden-status.model';
import { ZeehavenIJmuidenVisitModel } from '../../models/zeehaven-ijmuiden-visit.model';
import { ZeehavenIJmuidenLogicService } from '../../services/zeehaven-ijmuiden-logic.service';
import { ZeehavenIJmuidenVisitDataService } from '../../services/zeehaven-ijmuiden-visit-data.service';

@Component({
  styleUrls: ['./zeehaven-ijmuiden-overview.page.scss'],
  templateUrl: './zeehaven-ijmuiden-overview.page.html',
})
export class ZeehavenIJmuidenOverviewPage implements OnInit {
  currentView = '';
  loadingMovements: boolean;
  loadingVisits: boolean;
  visits: ZeehavenIJmuidenOverviewModel;
  cachedVisits: ZeehavenIJmuidenOverviewModel;
  cachedHistory: ZeehavenIJmuidenOverviewModel;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private modal: NgbModal,
    private notify: NotificationService,
    private layout: LayoutDisplayService,
    private logicService: ZeehavenIJmuidenLogicService,
    private zeehavenData: ZeehavenIJmuidenVisitDataService
  ) {
    this.loadingMovements = false;
    this.loadingVisits = false;
    this.visits = null;
    this.cachedVisits = null;
    this.cachedHistory = null;
  }

  ngOnInit() {
    this.layout.currentModuleName = this.translate.instant(
      'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.overview.title'
    );

    this.loadOverview(false);
  }

  loadOverview(history?: boolean) {
    this.loadingMovements = true;
    this.visits = new ZeehavenIJmuidenOverviewModel();

    if (history === true) {
      this.zeehavenData.getCompletedMovements().subscribe(
        (response) => this.afterLoadedMovements(response),
        (e) => {
          this.afterLoadedFailed(e.error ? e.error.messages : []);
          this.loadingMovements = false;
        },
        () => (this.loadingMovements = false)
      );
    } else {
      this.loadingVisits = true;
      const movements = this.zeehavenData.getAllOpenMovements();
      const visits = this.zeehavenData.getAllActiveVisits();

      forkJoin([movements, visits]).subscribe(
        (results) => {
          const movementResult = results[0];
          const visitResults = results[1];

          this.afterLoadedMovements(movementResult, true);
          this.afterLoadedVisits(
            visitResults,
            movementResult && movementResult.hasResult
              ? movementResult.result
              : null
          );
          this.sortOverviewModel();
        },
        (e) => {
          this.afterLoadedFailed(e.error ? e.error.messages : []);
          this.loadingMovements = false;
          this.loadingVisits = false;
        }
      );
    }
  }

  afterLoadedMovements(
    response: ResultWrapper<ZeehavenIJmuidenMovementModel[]>,
    skipSort?: boolean
  ) {
    if (response.hasResult) {
      this.logicService.addZHVMovementsToZHVOverviewModel(
        response.result,
        this.visits
      );

      if (skipSort !== true) {
        this.sortOverviewModel();
      }
    } else {
      this.afterLoadedFailed(response.messages);
    }
    this.loadingMovements = false;
  }

  afterLoadedVisits(
    response: ResultWrapper<ZeehavenIJmuidenVisitModel[]>,
    zhijMovements: ZeehavenIJmuidenMovementModel[]
  ) {
    if (zhijMovements && zhijMovements.length > 0) {
      // const movementsWithAta: ZeehavenIJmuidenMovementModel[] = _.filter(zhijMovements, x => x.ataOrAtd != null);
      // _.forEach(movementsWithAta, movementWithAta => this.visits.inPort.push(movementWithAta));

      if (response.hasResult) {
        _.forEach(response.result, (model) => {
          // when the status of the ZHIJ visit is active, try update the last movement id
          if (model.status === ZeehavenIJmuidenStatus.Active) {
            const movements = _.filter(zhijMovements, (movement) => {
              return movement.zhijVisitId === model.__Id;
            });

            if (movements && movements.length > 0) {
              const last = _.sortBy(movements, (mov) => {
                return !mov.etaOrEtd ? '' : mov.etaOrEtd;
              }).reverse()[0];

              model.$nautical_portvisit_id = last.$nautical_portvisit_id;
              if (
                last.movementType === ZeehavenIJmuidenVisitMovementType.Outgoing
              ) {
                model.destination = last.destination;
              }
            }
          }

          this.visits.inPort.push(model);
        });
      }

      this.sortOverviewModel();
    } else if (!response.hasResult) {
      this.afterLoadedFailed(response.messages);
    }
    this.loadingVisits = false;
  }

  afterLoadedFailed(messages) {
    this.notify.showError(
      _.pluck(messages, 'message').join('\n'),
      this.translate.instant('shared.terms.failed')
    );
  }

  openInPortLisPicker(movementType: ZeehavenIJmuidenVisitMovementType) {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      size: 'lg',
    };

    const modalRef = this.modal.open(
      ZeehavenIJmuidenVisitListPickerModalComponent,
      ngbModalOptions
    );

    modalRef.componentInstance.visits = this.visits.inPort;

    modalRef.result
      .then((selectedVisit: ZeehavenIJmuidenVisitModel) => {
        if (selectedVisit) {
          this.logicService.createNewMovementByZHIJVisit(
            selectedVisit,
            movementType
          );
          this.loadingMovements = this.logicService.isLoading;
        }
      })
      .catch((error) => {});
  }

  openShipPickerModal() {
    const z: ZeehavenIJmuidenMovementDetailsModel =
      new ZeehavenIJmuidenMovementModel();
    z.movementType = ZeehavenIJmuidenVisitMovementType.Incoming;

    const m = this.modal.open(SuggestionPickerModalComponent, {
      backdrop: 'static',
    });
    m.componentInstance.zVisit = z;

    m.componentInstance.onPickSuggestion.subscribe((v) => {
      if (v) {
        this.logicService.createNewMovementAndRedirect(v);
        this.loadingMovements = this.logicService.isLoading;
      }
    });
  }

  action(event: EventModel) {
    switch (event.action) {
      case 'selectMovement': {
        if (!event.data.__Id) {
          this.zeehavenData.add(event.data).subscribe(
            (r: ResultWrapper<ZeehavenIJmuidenMovementModel>) => {
              if (r.hasResult) {
                this.router.navigateByUrl(
                  `custom-ui/zeehaven/${r.result.__Id}`
                );
              } else {
                this.notify.showError(
                  _.pluck(r.messages, 'message').join('\n'),
                  this.translate.instant('shared.terms.failed')
                );
              }
            },

            (e) => {
              this.notify.showError(
                _.pluck(e.error.messages, 'message').join('\n'),
                this.translate.instant('shared.terms.failed')
              );
            },

            () => {}
          );
        } else {
          this.router.navigateByUrl(`custom-ui/zeehaven/${event.data.__Id}`);
        }
        break;
      }

      case 'toggleHistory': {
        if (event.data) {
          if (!this.loadingVisits && !this.loadingMovements && this.visits) {
            this.cachedVisits = this.visits;
          }

          if (this.cachedHistory) {
            this.visits = this.cachedHistory;
          } else {
            this.loadOverview(true);
          }
        } else {
          if (!this.loadingVisits && this.visits) {
            this.cachedHistory = this.visits;
          }

          if (this.cachedVisits) {
            this.visits = this.cachedVisits;
          } else {
            this.loadOverview(false);
          }
        }
        break;
      }
      case 'viewChanged': {
        if (event.data !== null) {
          this.currentView = event.data;
        }
      }
    }
  }

  private sortOverviewModel() {
    if (this.visits) {
      // in port
      if (this.visits.inPort) {
        this.visits.inPort = _.sortBy(this.visits.inPort, (visit) =>
          visit.shipName ? visit.shipName.toLowerCase() : ''
        );
      }

      // incoming
      if (this.visits.incoming) {
        this.visits.incoming = _.chain(this.visits.incoming)
          .sortBy((i) => {
            return i.shipName;
          })
          .sortBy((i) => {
            return i['etaOrEtd'] === undefined || i['etaOrEtd'] === null
              ? ''
              : i['etaOrEtd'];
          })
          .value();
      }

      // shifting
      if (this.visits.shifting) {
        this.visits.shifting = _.sortBy(this.visits.shifting, 'shipName');
      }

      // outgoing
      if (this.visits.outgoing) {
        this.visits.outgoing = _.chain(this.visits.outgoing)
          .sortBy((o) => {
            return o.shipName;
          })
          .sortBy((o) => {
            return o['etaOrEtd'] === undefined || o['etaOrEtd'] === null
              ? ''
              : o['etaOrEtd'];
          })
          .value();
      }
    }
  }
}
