<div class="card">
  <div class="card-header">
    {{ 'map.marker.marker' | translate }}
  </div>
  <div class="card-body">
    <div class="form-group">
      <label for="passengerColor">
        <b>{{ 'map.marker.passengerColor' | translate }}</b>
      </label>
      <select [(ngModel)]="mapMarkers.passengerColor" class="form-control">
        <option [ngValue]="null">
          {{ 'shared.terms.default' | translate }}
        </option>
        <option
          *ngFor="let mapMarkerColor of mapMarkerColorList"
          [ngValue]="mapMarkerColor"
        >
          {{ mapMarkerColor | mapMarkerColorTranslate }}
        </option>
      </select>
    </div>
    <div class="form-group">
      <label for="cargoColor">
        <b>{{ 'map.marker.cargoColor' | translate }}</b>
      </label>
      <select [(ngModel)]="mapMarkers.cargoColor" class="form-control">
        <optgroup>
          <option [ngValue]="null">
            {{ 'shared.terms.default' | translate }}
          </option>
        </optgroup>
        <optgroup *ngIf="mapMarkerColorList && mapMarkerColorList.length">
          <option
            *ngFor="let mapMarkerColor of mapMarkerColorList"
            [ngValue]="mapMarkerColor"
          >
            {{ mapMarkerColor | mapMarkerColorTranslate }}
          </option>
        </optgroup>
      </select>
    </div>
    <div class="form-group">
      <label for="tankerColor">
        <b>{{ 'map.marker.tankerColor' | translate }}</b>
      </label>
      <select [(ngModel)]="mapMarkers.tankerColor" class="form-control">
        <optgroup>
          <option [ngValue]="null">
            {{ 'shared.terms.default' | translate }}
          </option>
        </optgroup>
        <optgroup *ngIf="mapMarkerColorList && mapMarkerColorList.length">
          <option
            *ngFor="let mapMarkerColor of mapMarkerColorList"
            [ngValue]="mapMarkerColor"
          >
            {{ mapMarkerColor | mapMarkerColorTranslate }}
          </option>
        </optgroup>
      </select>
    </div>
    <div class="form-group">
      <label for="pilotColor">
        <b>{{ 'map.marker.pilotColor' | translate }}</b>
      </label>
      <select [(ngModel)]="mapMarkers.pilotColor" class="form-control">
        <optgroup>
          <option [ngValue]="null">
            {{ 'shared.terms.default' | translate }}
          </option>
        </optgroup>
        <optgroup *ngIf="mapMarkerColorList && mapMarkerColorList.length">
          <option
            *ngFor="let mapMarkerColor of mapMarkerColorList"
            [ngValue]="mapMarkerColor"
          >
            {{ mapMarkerColor | mapMarkerColorTranslate }}
          </option>
        </optgroup>
      </select>
    </div>
    <div class="form-group">
      <label for="pleasureColor">
        <b>{{ 'map.marker.pleasureColor' | translate }}</b>
      </label>
      <select [(ngModel)]="mapMarkers.pleasureColor" class="form-control">
        <optgroup>
          <option [ngValue]="null">
            {{ 'shared.terms.default' | translate }}
          </option>
        </optgroup>
        <optgroup *ngIf="mapMarkerColorList && mapMarkerColorList.length">
          <option
            *ngFor="let mapMarkerColor of mapMarkerColorList"
            [ngValue]="mapMarkerColor"
          >
            {{ mapMarkerColor | mapMarkerColorTranslate }}
          </option>
        </optgroup>
      </select>
    </div>
    <div class="form-group">
      <label for="fishingColor">
        <b>{{ 'map.marker.fishingColor' | translate }}</b>
      </label>
      <select [(ngModel)]="mapMarkers.fishingColor" class="form-control">
        <optgroup>
          <option [ngValue]="null">
            {{ 'shared.terms.default' | translate }}
          </option>
        </optgroup>
        <optgroup *ngIf="mapMarkerColorList && mapMarkerColorList.length">
          <option
            *ngFor="let mapMarkerColor of mapMarkerColorList"
            [ngValue]="mapMarkerColor"
          >
            {{ mapMarkerColor | mapMarkerColorTranslate }}
          </option>
        </optgroup>
      </select>
    </div>
    <div class="form-group">
      <label for="defaultColor">
        <b>{{ 'map.marker.defaultColor' | translate }}</b>
      </label>
      <select [(ngModel)]="mapMarkers.defaultColor" class="form-control">
        <optgroup>
          <option [ngValue]="null">
            {{ 'shared.terms.default' | translate }}
          </option>
        </optgroup>
        <optgroup *ngIf="mapMarkerColorList && mapMarkerColorList.length">
          <option
            *ngFor="let mapMarkerColor of mapMarkerColorList"
            [ngValue]="mapMarkerColor"
          >
            {{ mapMarkerColor | mapMarkerColorTranslate }}
          </option>
        </optgroup>
      </select>
    </div>
    <div class="form-group">
      <div class="custom-control custom-switch">
        <input
          [(ngModel)]="mapMarkers.shapeAlwaysOn"
          type="checkbox"
          class="custom-control-input"
          id="shapeAlwaysOn"
        />
        <label class="custom-control-label" for="shapeAlwaysOn">{{
          'map.marker.shapeAlwaysOn' | translate
        }}</label>
      </div>
    </div>
    <div class="form-group">
      <div class="custom-control custom-switch">
        <input
          [(ngModel)]="mapMarkers.nameAlwaysOn"
          type="checkbox"
          class="custom-control-input"
          id="nameAlwaysOn"
        />
        <label class="custom-control-label" for="nameAlwaysOn">{{
          'map.marker.nameAlwaysOn' | translate
        }}</label>
      </div>
    </div>
    <div class="form-group">
      <div class="custom-control custom-switch">
        <input
          [(ngModel)]="mapMarkers.colorOnSailing"
          type="checkbox"
          class="custom-control-input"
          id="colorOnSailing"
        />
        <label class="custom-control-label" for="colorOnSailing">{{
          'map.marker.colorOnSailing' | translate
        }}</label>
      </div>
    </div>
    <div class="form-group">
      <div class="custom-control custom-switch">
        <input
          [(ngModel)]="mapMarkers.useLargePopup"
          type="checkbox"
          class="custom-control-input"
          id="useLargePopup"
        />
        <label class="custom-control-label" for="useLargePopup">{{
          'map.popup.useLargeInfo' | translate
        }}</label>
      </div>
    </div>
    <div class="form-group">
      <div class="custom-control custom-switch">
        <input
          [(ngModel)]="mapMarkers.showTrackOnSelection"
          type="checkbox"
          class="custom-control-input"
          id="showTrackOnSelection"
        />
        <label class="custom-control-label" for="showTrackOnSelection">{{
          'map.popup.showTrackOnSelection' | translate
        }}</label>
      </div>
    </div>
    <div class="form-group">
      <button class="btn btn-block btn-primary" (click)="saveMapMarkers()">
        {{ 'shared.terms.save' | translate }}
      </button>
    </div>
  </div>
</div>
