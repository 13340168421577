import { Component, Input } from '@angular/core';

import { NauticalVisitDetailsModel } from '@seahorse/domain';

@Component({
  selector: 'visit-events',
  templateUrl: 'visit-events.component.html',
})
export class VisitEventsComponent {
  @Input() events: NauticalVisitDetailsModel['portVisitEvents'];
  @Input() loadEvents: boolean;

  constructor() {
    this.events = null;
    this.loadEvents = false;
  }
}
