import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { UserExternalResponse } from '../../../../core/models/user-external.model';
import { IdentityService } from '../../../../core/services/identity.service';
import { CaEnvService } from '../../../../shared/services/env.service';
import { AuthenticationParameters } from 'msal/lib-commonjs/AuthenticationParameters';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ca-login-identity-providers',
  templateUrl: './login-identity-providers.component.html',
})
export class LoginIdentityProvidersComponent implements OnInit, OnDestroy {
  accountName = '';
  isLoggedIn = false;
  subscriptions: Subscription[] = [];

  constructor(
    public environment: CaEnvService,
    public identityService: IdentityService,
    private authService: MsalService,
    private broadcastService: BroadcastService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const loginSuccessSubscription = this.broadcastService.subscribe(
      'msal:loginSuccess',
      () => {
        this.checkAccount();
      }
    );

    const loginFailureSubscription = this.broadcastService.subscribe(
      'msal:loginFailure',
      (error) => {
        console.log('login failed');
        console.log(error);

        this.checkAccount();
      }
    );

    this.subscriptions.push(loginSuccessSubscription);
    this.subscriptions.push(loginFailureSubscription);
    this.checkAccount();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  onLogout() {
    this.authService.logout();
    window.sessionStorage.clear();
  }

  onLogin() {
    if (this.isLoggedIn) {
      this.authService.logout();
    } else {
      this.authService
        .loginPopup()
        .then((result: UserExternalResponse) => {
          const isNewUser = result.account.idToken.newUser;

          console.log('Login success', result);
          console.log('is new user', isNewUser);
          console.log('Account', result.account);

          if (isNewUser) {
            this.router.navigate(['/account/register'], {
              queryParams: { aadIdentifier: result.account.accountIdentifier },
            });
          } else {
            const accessTokenRequest: AuthenticationParameters = {
              scopes: ['https://shipm8.onmicrosoft.com/api/read_data'],
            };

            this.authService
              .acquireTokenPopup(accessTokenRequest)
              .then(function (accessTokenResponse) {
                const accessToken = accessTokenResponse.idToken.rawIdToken;

                this.identityService.setIdentity(accessToken);

                this.router.navigate(['/']);
              });
          }
        })
        .catch((err) => {
          console.log('Login failed : ', err);
        });
    }
  }

  checkAccount() {
    this.isLoggedIn = !!this.authService.getAccount();
    if (this.isLoggedIn) {
      this.accountName = this.authService.getAccount().name;
    } else {
      this.accountName = '';
    }
  }
}
