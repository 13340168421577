import {
  AfterViewInit,
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import * as _ from 'underscore';
import { OnInit } from '@angular/core';

import { KPStatus } from '../../models/kp-status.model';
import { KPActivitiesContainerComponent } from '../activities-container/activities-container.component';
import { KPActivityModel } from '../../models/kp-activity.model';
import { KPEventModel } from '../../models/kp-event.model';
import { KPActivityTypes } from '../../models/kp-activity-types.enum';
import { ObjectState } from '@seahorse/domain';

@Component({
  selector: 'ca-custom-kp-activities-list',
  templateUrl: 'activities-list.component.html',
})
export class KPActivitiesListComponent implements OnInit, AfterViewInit {
  private isInitialized = false;
  private _activities: KPActivityModel[] = [];
  private _showOrderedOnly = false;

  columnName: string;
  groupCode: string;
  filteredActivities: KPActivityModel[] = [];
  activitiesWithErrors: KPActivityModel[] = [];

  @ViewChild('container', { static: true })
  container: KPActivitiesContainerComponent;

  @Input() files: any = {};
  @Input() viewList = 'active';
  @Input() shipRequirements: any[] = [];
  @Input() portVisitInfos: any[] = [];
  @Input() set showOrderedOnly(value: boolean) {
    this._showOrderedOnly = value;
    if (this.isInitialized === true) {
      this.filterList();
    }
  }
  @Input() set activities(values: KPActivityModel[]) {
    this._activities = values;
    if (this.isInitialized === true) {
      this.filterList();
    }
  }

  @Input() set activityTypeGroup(value: string) {
    this.groupCode = value;

    const types = _.sortBy(
      _.filter(KPActivityTypes, (at) => at.group === value),
      (t) => t.id
    );
    this.columnName =
      types[0].name +
      (types.length > 1 ? `/ ${types[types.length - 1].name}` : '');
  }
  get activity(): string {
    return this.groupCode;
  }

  @Input() isLoading: boolean;

  @Output() actionClicked = new EventEmitter<KPEventModel>();

  constructor() {
    this.isLoading = false;
  }

  ngAfterViewInit(): void {
    this.container.eventEmitter = this.actionClicked;
  }

  ngOnInit() {
    this.isInitialized = true;
    this.filterList();
  }

  filterList() {
    this.filteredActivities = !this._activities
      ? []
      : _.filter(this._activities, (activity: KPActivityModel) => {
          const a = this.activitiesWithErrors.find(
            (x) => x.__Id === activity.__Id
          );
          if (a) {
            activity.errors = a.errors;
          }

          return (
            ((!this._showOrderedOnly ||
              activity.status !== KPStatus.Suggestion ||
              activity.activityType === 'verhalen' ||
              activity['orderedForKP'] === true) &&
              this.viewList === 'active' &&
              activity.__ObjectState !== ObjectState.Deleted &&
              (activity.status === KPStatus.Active ||
                activity.status === KPStatus.Suggestion)) ||
            (this.viewList !== 'active' &&
              activity.status !== KPStatus.Active &&
              activity.status !== KPStatus.Suggestion)
          );
        });
  }

  setErrors(activityId: string, errors: string) {
    const activity = this.filteredActivities.find((x) => x.__Id === activityId);
    activity.errors = errors;

    this.activitiesWithErrors.push(activity);
  }

  removeErrors(activityId: string) {
    const activity = this.activitiesWithErrors.find(
      (x) => x.__Id === activityId
    );

    if (activity) {
      this.activitiesWithErrors.splice(
        this.activitiesWithErrors.map((x) => x.__Id).indexOf(activityId),
        1
      );
    }
  }
}
