import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import * as _ from 'underscore';

import { ResultWrapper } from '@seahorse/common';
import { NotificationService } from '@seahorse/common';
import { EventModel } from '../../../../../../shared/models/event.model';
import { FlowDataService } from '../../../services/flow-data.service';
import { StageModel, FlowModel } from '@seahorse/domain';

@Component({
  selector: 'stage-details-modal',
  templateUrl: 'stage-details-modal.component.html',
})
export class StageDetailsModalComponent implements OnInit {
  @Input() stage: StageModel;
  @Input() loadingStage: boolean;

  @Output() onEvent: EventEmitter<EventModel>;

  flow: FlowModel;
  loadingFlow: boolean;

  constructor(
    private translate: TranslateService,
    private notification: NotificationService,
    private flowData: FlowDataService
  ) {
    this.stage = null;
    this.loadingStage = false;
    this.onEvent = new EventEmitter<EventModel>();
    this.flow = null;
    this.loadingFlow = false;
  }

  ngOnInit() {
    this.getFlow(this.stage.invoiceFlowId);
  }

  getFlow(id: FlowModel['id']) {
    this.loadingFlow = true;

    this.flowData.getById(id).subscribe(
      (flowGetById: ResultWrapper<FlowModel>) => {
        if (flowGetById.hasResult) {
          this.flow = flowGetById.result;
        } else {
          this.notification.showError(
            _.pluck(flowGetById.messages, 'message').join('\n'),
            this.translate.instant('shared.terms.failed')
          );
        }
      },
      (flowGetByIdError) => {
        this.notification.showError(
          _.pluck(flowGetByIdError.error.messages, 'message').join('\n'),
          this.translate.instant('shared.terms.failed')
        );

        this.loadingFlow = false;
      }
    );
  }

  action(event: EventModel) {
    switch (event.action) {
      case 'searchPreviousStages': {
        this.stage.previousStageId = null;

        this.getFlow(this.stage.invoiceFlowId);

        break;
      }
    }
  }
}
