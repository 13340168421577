import { NauticalShipModel } from '@seahorse/domain';
import { ShipModel } from '../../superuser-nautical/models/ship.model';

export class FleetGroup {
  id: number;
  name: string;
  shipFleetGroups: ShipFleetGroupModel[];

  isActive: boolean;
  isChecked: boolean;

  static validate(group: FleetGroup) {
    if (!group.name) {
      return false;
    }

    group.name = group.name.trim();

    if (!group.name) {
      return false;
    }

    return true;
  }
}

export class ShipFleetGroupModel {
  id: number;
  shipId: number;
  groupId: number;
  ship: NauticalShipModel;
}
