import { Injectable } from '@angular/core';
import { ResultWrapper } from '@seahorse/common';
import { DataContextService } from '@seahorse/common';
import { QueryModel } from '../../../../shared/models/query.model';
import { Observable } from 'rxjs';
import { DataService, FlowModel } from '@seahorse/domain';

@Injectable()
export class FlowDataService implements DataService<FlowModel> {
  constructor(private dataContextService: DataContextService) {}

  add(flow: FlowModel): Observable<ResultWrapper<FlowModel>> {
    flow.organizationId = '{3a2a4e84-4c21-4981-ae10-3fda0d9b0f83}'; // Fake Id

    return this.dataContextService.post<FlowModel>(`invoice/flow`, flow);
  }

  get(): Observable<ResultWrapper<FlowModel[]>> {
    return this.dataContextService.get<FlowModel[]>('invoice/flow');
  }

  getActive(): Observable<ResultWrapper<FlowModel[]>> {
    return this.dataContextService.get<FlowModel[]>('invoice/flow/active');
  }

  getById(id: FlowModel['id']): Observable<ResultWrapper<FlowModel>> {
    return this.dataContextService.get<FlowModel>(`invoice/flow/${id}`);
  }

  getBySystemCode(systemCode: string): Observable<ResultWrapper<FlowModel>> {
    return this.dataContextService.get<FlowModel>(
      `invoice/flow/system-code/${systemCode}`
    );
  }

  search(query: QueryModel): Observable<ResultWrapper<FlowModel[]>> {
    return this.dataContextService.get<FlowModel[]>(
      `invoice/flow?search=${query.search}&pindex=${query.pageIndex}&psize=${query.pageSize}`
    );
  }

  // Put
  update(flow: FlowModel): Observable<ResultWrapper<FlowModel>> {
    return this.dataContextService.put<FlowModel>(
      `invoice/flow/${flow.id}`,
      flow
    );
  }

  // Delete
  delete(id: FlowModel['id']): Observable<ResultWrapper<FlowModel>> {
    return this.dataContextService.delete<FlowModel>(`invoice/flow/${id}`);
  }
}
