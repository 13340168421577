import { ControlValueAccessor } from '@angular/forms';

/**
 * Temporary thin wrapper around existing controls,
 * until those are refactored to fully use ControlValueAccessor interface
 */
export abstract class BaseControl<TInput, TOutput = TInput>
  implements ControlValueAccessor {
  onTouchedCb?: () => void;
  onChangeCb?: (value: unknown) => TOutput;

  emitOnChange(value: TOutput) {
    if (this.onChangeCb) {
      this.onChangeCb(value);
    }

    if (this.onTouchedCb) {
      this.onTouchedCb();
    }
  }

  writeValue(value: TInput) {
    // TODO: implement
  }

  registerOnTouched(fn: () => void): void {
    this.onTouchedCb = fn;
  }

  registerOnChange(fn: (value: unknown) => TOutput): void {
    this.onChangeCb = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // TODO: implement
  }
}
