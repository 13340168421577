import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'invoiceStatusCode',
})
export class InvoiceStatusCodePipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: string): string {
    const translated = this.translate.instant('invoicing.statuscodes.' + value);

    if (
      translated &&
      translated !== '' &&
      translated !== 'invoicing.statuscodes.' + value
    ) {
      return translated;
    }
    return value;
  }
}
