import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  NgbTypeaheadModule,
  NgbTypeaheadSelectItemEvent,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationService } from '@seahorse/common';
import {
  CustomDataBaseModel,
  FieldDefinitionModel,
  ObjectDefinitionModel,
  ProxyService,
  ScopeType,
} from '@seahorse/domain';
import { Observable, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { addNewObjectProcedure } from '../../pages/procedures/add-new-object.procedure';
import { BaseControl } from '../base-control';
import { newActionExcludedObjects } from '../linked-object.model';
import { LinkedObjectService } from '../linked-object.service';

type LinkedObjectsControlValue = (number | string)[] | null;

@Component({
  selector: 'temp-linked-objects',
  templateUrl: './linked-objects.control.html',
  styleUrls: ['./linked-objects.control.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LinkedObjectsControl),
      multi: true,
    },
    {
      provide: BaseControl,
      useExisting: forwardRef(() => LinkedObjectsControl),
    },
  ],
  standalone: true,
  imports: [CommonModule, FormsModule, TranslateModule, NgbTypeaheadModule],
})
export class LinkedObjectsControl
  extends BaseControl<LinkedObjectsControlValue>
  implements OnInit
{
  @ViewChild('input', { static: true }) inputRef: ElementRef<HTMLInputElement>;

  @Input() required = false;
  @Input() value: LinkedObjectsControlValue = null;

  @Input() placeholder: string;
  @Input() name: string;

  private _fieldDefinition = null;
  @Input() set fieldDefinition(val: FieldDefinitionModel) {
    this._fieldDefinition = val;
    this._fieldAdditionalData = FieldDefinitionModel.getAdditionalData(val);
    this._linkedObjectSystemCode =
      FieldDefinitionModel.getMappingKeySystemCode(val);
    this._linkedObjectMappingKey = FieldDefinitionModel.getMappingKeyForeignKey(
      this.fieldDefinition
    );
    this._displayFields = this._fieldAdditionalData
      ? this._fieldAdditionalData.displayFields
      : [];

    this._objectDefinition = this._proxyServices.objectDefinitions.find(
      (x) => x.systemCode === this._linkedObjectSystemCode
    );

    // try find the base object definition
    if (this._objectDefinition === undefined) {
      this._objectDefinition = this._proxyServices.objectDefinitions.find(
        (x) =>
          x.baseObjectDefinition !== undefined &&
          x.baseObjectDefinition !== null &&
          x.baseObjectDefinition.systemCode === this._linkedObjectSystemCode
      )?.baseObjectDefinition;
    }

    this.hideAddButton = newActionExcludedObjects.some(
      (key) =>
        this._objectDefinition?.mappingKey &&
        this._objectDefinition.mappingKey.startsWith(key)
    );
  }
  get fieldDefinition(): FieldDefinitionModel {
    return this._fieldDefinition;
  }

  private _externalObjectDefinition: ObjectDefinitionModel;

  @Input()
  set externalObjectDefinition(value: ObjectDefinitionModel) {
    this._externalObjectDefinition = value;
  }

  get externalObjectDefinition(): ObjectDefinitionModel {
    return this._externalObjectDefinition;
  }

  selectedItems: CustomDataBaseModel[] = [];
  isLoading = false;
  hasLoadingFailed = false;
  tagFilter = null;
  private _displayFields = [];
  private _fieldAdditionalData = null;
  private _linkedObjectSystemCode: string;
  private _linkedObjectMappingKey: string;

  hideAddButton = false;
  private _objectDefinition: ObjectDefinitionModel;
  ScopeType = ScopeType;
  private _addNewObjectProcedure = addNewObjectProcedure();

  constructor(
    private _linkedObjectData: LinkedObjectService,
    private _notificationService: NotificationService,
    private _proxyServices: ProxyService
  ) {
    super();
  }

  ngOnInit() {
    if (this.value != null) {
      this.getObjects(this.value);
    }

    this.tagFilter = this._fieldAdditionalData?.tag;
  }

  private getObjects(value: LinkedObjectsControlValue) {
    this.isLoading = true;

    const systemCode = this.externalObjectDefinition
      ? this.externalObjectDefinition.systemCode
      : this._linkedObjectSystemCode;

    const mappingKey = this.externalObjectDefinition
      ? this.externalObjectDefinition.mappingKey
      : this._objectDefinition !== undefined
      ? this._objectDefinition.mappingKey
      : null;

    let foreignKey;
    if (this.fieldDefinition) {
      foreignKey = FieldDefinitionModel.getMappingKeyForeignKey(
        this.fieldDefinition
      );
    }

    this._linkedObjectData
      .getByKeys(systemCode, mappingKey, foreignKey, value)
      .subscribe((res) => {
        this.isLoading = false;
        return res.hasResult ? (this.selectedItems = res.result) : [];
      });
  }

  dataSearch = (input$: Observable<string>): Observable<any> =>
    input$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => {
        this.isLoading = true;
      }),
      switchMap((query) => {
        const systemCode = this.externalObjectDefinition
          ? this.externalObjectDefinition.systemCode
          : this._linkedObjectSystemCode;

        const mappingKey = this.externalObjectDefinition
          ? this.externalObjectDefinition.mappingKey
          : this._objectDefinition !== undefined
          ? this._objectDefinition.mappingKey
          : null;

        return this._linkedObjectData
          .search(
            systemCode,
            this._fieldAdditionalData?.displayFields,
            mappingKey,
            query,
            0,
            50,
            this.tagFilter
          )
          .pipe(
            map((r) => r.result),
            tap((r) =>
              r.sort((a, b) => (a.__DisplayName > b.__DisplayName ? 1 : -1))
            ),
            tap(() => (this.isLoading = false)),
            catchError((error) => {
              this.hasLoadingFailed = true;
              this.isLoading = false;

              this._notificationService.showError(error, 'shared.terms.failed');
              return of([]);
            })
          );
      })
    );

  selectItem(event: NgbTypeaheadSelectItemEvent) {
    event.preventDefault();
    const selectedItem = event.item as CustomDataBaseModel;

    const displayField = this._displayFields[0];
    selectedItem.__DisplayName =
      selectedItem.__DisplayName ?? selectedItem[displayField];

    this.selectCustomDataBaseModel(selectedItem);
  }

  selectCustomDataBaseModel(selectedItem: CustomDataBaseModel) {
    const getId = (data: any) => data['__Id'] ?? data['id'];

    const exists =
      this.selectedItems
        .map((item) => getId(item))
        .indexOf(getId(selectedItem)) !== -1;

    if (exists) {
      return;
    }

    this.selectedItems.push(selectedItem);
    this.inputRef.nativeElement.value = '';

    const values = this.selectedItems.map(
      (item) => item[this._linkedObjectMappingKey]
    );

    this.emitOnChange(values);
  }

  createNewObject() {
    this.isLoading = true;

    this._addNewObjectProcedure(this._objectDefinition).subscribe({
      next: (result) => {
        this.isLoading = false;
        result.__DisplayName = this.dataFormatter(result);
        this.selectCustomDataBaseModel(result);
      },
    });
  }

  removeItem(item: CustomDataBaseModel) {
    this.selectedItems.splice(this.selectedItems.indexOf(item), 1);
    const values = this.selectedItems.map(
      (selectedItem) => selectedItem[this._linkedObjectMappingKey]
    );

    this.emitOnChange(values.length ? values : null);
  }

  dataFormatter = (object: CustomDataBaseModel): string =>
    object.__DisplayName || object[this._displayFields[0]];

  clearTagFilter() {
    this.tagFilter = '';
  }
}
