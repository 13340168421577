import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ZeehavenIJmuidenVisitMovementType } from '../models/zeehaven-ijmuiden-movement.model';

@Pipe({ name: 'zeehavenIJmuidenVisitMovementType' })
export class ZeehavenIJmuidenVisitMovementTypePipe implements PipeTransform {
  zeehavenIJmuidenVisitMovementTypes: Object;

  constructor(private translate: TranslateService) {
    this.zeehavenIJmuidenVisitMovementTypes = {
      [ZeehavenIJmuidenVisitMovementType.Incoming]:
        'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.movementType.incoming',
      [ZeehavenIJmuidenVisitMovementType.Shifting]:
        'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.movementType.shifting',
      [ZeehavenIJmuidenVisitMovementType.Outgoing]:
        'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.movementType.outgoing',
    };
  }

  transform = (
    zeehavenIJmuidenVisitMovementType: ZeehavenIJmuidenVisitMovementType
  ): string =>
    this.translate.instant(
      this.zeehavenIJmuidenVisitMovementTypes[zeehavenIJmuidenVisitMovementType]
    );
}
