import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import {
  FieldType,
  NauticalMovementModel,
  NauticalVisitDetailsModel,
} from '@seahorse/domain';
import { EditAttributeModal, FieldAttributeModel } from '@seahorse/temp';
import * as moment from 'moment';
import * as _ from 'underscore';

import { CustomUIDatetimePickerModal } from '../../../general/components/datetime-picker-modal.component/datetime-picker-modal.component';
import { CustomUIListPickerModal } from '../movement-type-picker-modal/movement-type-picker-modal.component';
import * as CVVActiveVisitModel from '../../models/cvv-active-visit.model';
import { CVVActivityModel } from '../../models/cvv-activity.model';
import { CVVEventModel } from '../../models/cvv-event.model';
import { CVVShipRequirementsModel } from '../../models/cvv-ship-requirements.model';
import { CVVVisitModel } from '../../pages/cvv-activities/cvv-activities.page';
import { CVVActivityLogicService } from '../../services/cvv-activity-logic.service';

@Component({
  selector: 'ca-custom-cvv-activities-container',
  templateUrl: 'activities-container.component.html',
  styleUrls: ['./activities-container.component.scss'],
})
export class CVVActivitiesContainerComponent implements OnInit {
  private _cvvObject: CVVActiveVisitModel.CVVActiveVisitModel = null;
  private _shipRequirements: CVVShipRequirementsModel = null;

  @Input() set cvvObject(cvvObject: CVVActiveVisitModel.CVVActiveVisitModel) {
    this._cvvObject = cvvObject;
    this.mergeRequirements();
  }
  get cvvObject(): CVVActiveVisitModel.CVVActiveVisitModel {
    return this._cvvObject;
  }

  @Input() set shipRequirements(shipRequirements: CVVShipRequirementsModel) {
    this._shipRequirements = shipRequirements;
    this.mergeRequirements();
  }
  get shipRequirements(): CVVShipRequirementsModel {
    return this._shipRequirements;
  }

  @Input() cvvVisit: CVVVisitModel = null;
  @Input() activities: CVVActivityModel[] = [];
  @Input() currentView: string = null;
  @Input() isActive = false;
  @Input() movement: NauticalMovementModel = null;
  @Input() position: any = null;
  @Input() visit: NauticalVisitDetailsModel = null;
  @Input() loadMembersAnimation: boolean;

  @Input() loas: object[];

  @Input() isClassicView = false;
  @Input() selectedVisitList = 'active';

  @Output() visitItemClicked = new EventEmitter<CVVEventModel>();
  @Output() activityItemClicked = new EventEmitter<CVVEventModel>();

  requirements: CVVShipRequirementsModel = null;
  canHaveTimeWarnings = false;

  hasInactiveTimeWarning = false;

  @Output() incomingTimeWarningDisplay = new EventEmitter<{
    index: number;
    warning: string;
  }>();
  @Output() outgoingTimeWarningDisplay = new EventEmitter<{
    index: number;
    warning: string;
  }>();

  constructor(
    public cvvActivityLogic: CVVActivityLogicService,
    private modalService: NgbModal,
    private translate: TranslateService
  ) {
    this.loadMembersAnimation = false;

    this.loas = [];
  }

  ngOnInit() {
    this.mergeRequirements();

    this.canHaveTimeWarnings =
      this.selectedVisitList?.toLowerCase() !== 'history' &&
      ((this.movement?.movementType === 1 && !!this.movement?.eta) ||
        (this.movement?.movementType === 3 && !!this.movement?.etd));

    if (!this.isActive && this.canHaveTimeWarnings) {
      if (
        this.movement?.movementType === 1 &&
        this.movement.eta &&
        this.incomingTimeWarning(this.movement.eta)
      ) {
        this.hasInactiveTimeWarning = true;
        this.incomingTimeWarningDisplay.emit({
          index: this.visit.id,
          warning: `${new Date(this.movement.eta).toLocaleTimeString([], {
            hour12: false,
            hour: '2-digit',
            minute: '2-digit',
          })} ${
            this.visit?.ship?.name
              ? this.visit.ship.name.toUpperCase()
              : this.translate.instant('nautical.ship.unknown')
          }`,
        });
      }

      if (
        this.movement?.movementType === 3 &&
        this.movement.etd &&
        this.outgoingTimeWarning(this.movement.etd)
      ) {
        this.hasInactiveTimeWarning = true;
        this.outgoingTimeWarningDisplay.emit({
          index: this.visit.id,
          warning: `${new Date(this.movement.etd).toLocaleTimeString([], {
            hour12: false,
            hour: '2-digit',
            minute: '2-digit',
          })} ${
            this.visit?.ship?.name
              ? this.visit.ship.name.toUpperCase()
              : this.translate.instant('nautical.ship.unknown')
          }`,
        });
      }
    }
  }

  hasActivityTimeWarning(startsOn: string | number | Date): boolean {
    if (this.isActive && this.canHaveTimeWarnings) {
      if (this.movement?.movementType === 1)
        return this.incomingTimeWarning(startsOn);

      if (this.movement?.movementType === 3)
        return this.outgoingTimeWarning(startsOn);
    }
    return false;
  }

  // Incoming movement: Warn when ETA or Start passed 15 minutes or more ago
  incomingTimeWarning = (date: string | number | Date): boolean =>
    new Date(date).getTime() + 900000 < new Date().getTime();

  // Outgoing movement: Warn when ETD or Start is less than 30 minutes from now
  outgoingTimeWarning = (date: string | number | Date): boolean =>
    new Date(date).getTime() < new Date().getTime() + 1800000;

  deleteActivity(activity) {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      size: 'sm',
    };

    const modalRef = this.modalService.open(
      EditAttributeModal,
      ngbModalOptions
    );
    modalRef.componentInstance.attribute = new FieldAttributeModel(
      'Reden',
      FieldType.MultiLineText,
      null,
      'remarks',
      true
    );
    modalRef.componentInstance.modalHeader = 'Voer een reden in';
    modalRef.result
      .then((modalResult) => {
        if (modalResult && modalResult.fieldValue) {
          const model = new CVVEventModel();
          model.action = 'delete';
          model.type = 'activity';
          model.data = { activity: activity, remarks: modalResult.fieldValue };
          this.activityItemClicked.emit(model);
        }
      })
      .catch();
  }

  emitActivityAction(activity, actionType: string) {
    // raise an event, the page will open the edit dialog and handle the save call
    const model = new CVVEventModel();
    model.action = actionType;
    model.type = 'activity';
    model.data = { activity: activity };
    this.activityItemClicked.emit(model);
  }

  isActivityType(activity, allowedTypes) {
    return allowedTypes.indexOf(activity.activityType) > -1;
  }

  mergeRequirements() {
    if (!this.shipRequirements) {
      this.requirements = new CVVShipRequirementsModel();
    } else {
      this.requirements = _.clone(this.shipRequirements);
    }

    if (this.cvvObject) {
      if (
        this.cvvObject.heavyEquipment !== undefined &&
        this.cvvObject.heavyEquipment !== null
      ) {
        this.requirements.heavyEquipment = this.cvvObject.heavyEquipment;
      }

      if (this.cvvObject.motorboat) {
        this.requirements.motorboat = this.cvvObject.motorboat;
      }

      if (this.cvvObject.persons) {
        this.requirements.persons = this.cvvObject.persons;
      }

      if (this.cvvObject.winches) {
        this.requirements.winches = this.cvvObject.winches;
      }

      if (this.cvvObject.remarks) {
        this.requirements.remarks = this.cvvObject.remarks;
      }
    }
  }

  pickDatetime(activity: any, fieldName: string) {
    let fieldDisplayName = fieldName;
    let value = activity[fieldName];
    let showAddButton = false;

    switch (fieldDisplayName) {
      case 'startsOn':
        fieldDisplayName = 'Start';
        break;
      case 'endsOn':
        fieldDisplayName = 'Eind';
        showAddButton = true;
        if (!value && activity.startsOn) {
          value = activity.startsOn;
        }
        break;
    }

    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      size: 'sm',
    };

    const modalRef = this.modalService.open(
      CustomUIDatetimePickerModal,
      ngbModalOptions
    );
    modalRef.componentInstance.attribute = {
      fieldName: fieldName,
      fieldDisplayName: fieldDisplayName,
      fieldValue: value ? moment(value).format('DD-MM-YYYY HH:mm') : null,
    };

    modalRef.componentInstance.modalHeader = 'Veld bewerken';
    modalRef.componentInstance.showAddButton = showAddButton;

    modalRef.result
      .then((modalResult) => {
        if (modalResult && modalResult.fieldValue !== undefined) {
          const result = moment(modalResult.fieldValue, 'DD-MM-YYYY HH:mm');
          this.setField(
            activity,
            fieldName,
            result.isValid() ? result.format('YYYY-MM-DDTHH:mm:ss') : null
          );
        }
      })
      .catch();
  }

  pickField(activity: any, fieldName: string) {
    let fieldDisplayName = fieldName;
    let fieldType = null;

    switch (fieldDisplayName) {
      case 'receipts':
        fieldDisplayName = 'Aantal bonnen';
        fieldType = FieldType.Integer;
        break;
      case 'remarks':
        fieldDisplayName = 'Opmerkingen';
        fieldType = FieldType.MultiLineText;
        break;
    }

    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      size: 'sm',
    };

    const modalRef = this.modalService.open(
      EditAttributeModal,
      ngbModalOptions
    );
    modalRef.componentInstance.attribute = new FieldAttributeModel(
      fieldDisplayName,
      fieldType,
      activity[fieldName],
      fieldName,
      false
    );
    modalRef.componentInstance.modalHeader = 'Veld bewerken';

    modalRef.result.then((modalResult) => {
      if (modalResult && modalResult.fieldValue !== undefined) {
        this.setField(activity, fieldName, modalResult.fieldValue);
      }
    });
  }

  pickList(activity: any, fieldName: string) {
    let value = null;
    let title = '';
    let label = '';
    let data = [];

    switch (fieldName) {
      case 'movementType':
        title = 'Reisdeel selecteren';
        label = 'Type reisdeel';
        data = this.cvvActivityLogic.movementTypes;
        value = _.find(
          this.cvvActivityLogic.movementTypes,
          (t) => t.id == activity[fieldName]
        );
        break;
    }

    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      size: 'sm',
    };

    const modalRef = this.modalService.open(
      CustomUIListPickerModal,
      ngbModalOptions
    );
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.label = label;
    modalRef.componentInstance.sourceData = data;
    modalRef.componentInstance.selectedData = value;

    modalRef.result
      .then((modalResult) => {
        if (modalResult && modalResult.id) {
          this.setField(activity, fieldName, modalResult.id);
        }
      })
      .catch();
  }

  saveActivity(activity) {
    // raise an event, the page will handle the save call
    const model = new CVVEventModel();
    model.action = 'update';
    model.type = 'activity';
    model.data = { activity: activity };
    this.activityItemClicked.emit(model);
  }

  setField(activity, fieldName, fieldValue) {
    const activityToSave = _.clone(activity);

    if (fieldValue === '#now') {
      activity[fieldName] = moment().format('YYYY-MM-DDTHH:mm:ss');
      activityToSave[fieldName] = moment().format('YYYY-MM-DDTHH:mm:ss');
    } else {
      activity[fieldName] = fieldValue;
      activityToSave[fieldName] = fieldValue;
    }

    this.saveActivity(activityToSave);
  }

  formatShip(ship): string {
    let r: string;

    if (ship['name']) {
      r = ship['name'].toUpperCase();
    } else {
      r = this.translate.instant('nautical.ship.unknown');
    }

    if (ship['loa']) {
      r += ` (${ship['loa']}m)`;
    } else if (this.loas.length > 0) {
      _.forEach(this.loas, (l) => {
        if (l['id'] === ship['baseId']) {
          r += ` (${l['loa']}m)`;
        }
      });
    }

    return r;
  }
}
