import { Injectable } from '@angular/core';
import { ResultWrapper } from '@seahorse/common';
import { DataContextService } from '@seahorse/common';
import { Observable } from 'rxjs';
import { NauticalObjectFilterGroupModel } from '../models/nautical-object-filter-group.model';

@Injectable()
export class NauticalObjectFilterDataService {
  constructor(private dataContext: DataContextService) {}

  get(): Observable<ResultWrapper<NauticalObjectFilterGroupModel[]>> {
    return this.dataContext.get<NauticalObjectFilterGroupModel[]>(
      'nautical/customer/filter'
    );
  }
}
