import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService, ResultWrapper } from '@seahorse/common';
import * as _ from 'underscore';
import { TaskDefinitionListModalComponent } from '../../components/task-definition-list-modal/task-definition-list-modal.component';
import { TaskDefinitionListModel } from '../../../../../task-definition-list/models/task-definition-list.model';
import { TaskDefinitionListDataService } from '../../../../../task-definition-list/services/task-definition-list-data.service';
import { of } from 'rxjs';

@Component({ templateUrl: './task-definition-select-form.component.html' })
export class TaskDefinitionSelectFormComponent implements OnInit {
  taskDefinitionLists: TaskDefinitionListModel[] = [];
  selectedTaskDefinitionList: TaskDefinitionListModel = null;
  loading = false;
  objectType?: string;
  mappingKey?: string;
  objectId?: string;

  showAddTaskDefinitionList = true;

  private notifications = inject(NotificationService);
  private translate = inject(TranslateService);

  constructor(
    private taskDefinitionListData: TaskDefinitionListDataService,
    private router: Router,
    private modal: NgbModal
  ) {}

  ngOnInit() {
    this.loading = true;

    this.taskDefinitionListData.getByMappingKey(this.mappingKey).subscribe(
      (r: ResultWrapper<TaskDefinitionListModel[]>) => {
        if (r.hasResult) {
          this.taskDefinitionLists = r.result;
        } else {
          this.notifications.showError(
            _.pluck(r.messages, 'message').join('\n'),
            this.translate.instant('shared.terms.failed')
          );
        }
      },
      (e) => {
        this.notifications.showError(
          _.pluck(e.error.messages, 'message').join('\n'),
          this.translate.instant('shared.terms.failed')
        );

        this.loading = false;
      },
      () => {
        this.loading = false;
      }
    );
  }

  addTaskDefinitionList() {
    const m = this.modal.open(TaskDefinitionListModalComponent, {
      backdrop: 'static',
    });
    m.componentInstance.taskDefinitionList = new TaskDefinitionListModel();

    m.componentInstance.onAddOrEditTaskDefinitionList.subscribe(
      (l: TaskDefinitionListModel) => {
        l.objectType = this.mappingKey;
        this.taskDefinitionListData.add(l).subscribe(
          (r: ResultWrapper<TaskDefinitionListModel>) => {
            if (r.hasResult) {
              this.notifications.showSuccess(
                this.translate.instant('tasks.definition.added'),
                this.translate.instant('shared.terms.success')
              );

              this.router.navigateByUrl(`/task-definition-list/overview`);
            } else {
              this.notifications.showError(
                _.pluck(r.messages, 'message').join('\n'),
                this.translate.instant('shared.terms.failed')
              );
            }
          },
          (e) => {
            this.notifications.showError(
              _.pluck(e.error.messages, 'message').join('\n'),
              this.translate.instant('shared.terms.failed')
            );
          }
        );
      }
    );
  }

  save() {
    if (!this.selectedTaskDefinitionList) return of(null);

    return this.taskDefinitionListData.create(
      [this.selectedTaskDefinitionList.id],
      this.objectType,
      this.objectId
    );
  }
}
