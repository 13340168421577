import { Component, Input } from '@angular/core';
import { BaseControl } from './base.control';

@Component({
  selector: 'sh-control',
  template: `
    <div class="sh-control-wrapper">
      <div [class.sh-control-group]="isControlGroup">
        <div class="sh-control">
          <ng-content></ng-content>
        </div>
        <div class="input-group-append" *ngIf="appendText">
          <span class="input-group-text">{{ appendText }}</span>
        </div>

        <span
          class="sh-control-tooltip fa fa-info-circle"
          *ngIf="hint"
          [title]="hint"
        ></span>
      </div>

      <label
        *ngIf="showError?.() && getError"
        class="e-error"
        [for]="formControlName"
        >{{ getError() | translate: { field: placeholder } }}</label
      >
    </div>
  `,
  styleUrls: ['./control.template.scss'],
})
export class ControlTemplate {
  @Input() control?: BaseControl;

  get required() {
    return this.control?.required;
  }

  get placeholder() {
    return this.control?.placeholder;
  }

  get formControlName() {
    return this.control?.formControlName;
  }

  get appendText() {
    return this.control?.appendText;
  }

  get isControlGroup() {
    return this.appendText || this.hint;
  }

  get hint() {
    return this.control?.hint;
  }

  get showError() {
    return this.control?.showError.bind(this.control);
  }

  get getError() {
    return this.control?.getError.bind(this.control);
  }
}
