import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shAppendText',
  standalone: true,
})
export class AppendTextPipe implements PipeTransform {
  transform(value: any, text: string | undefined, atBeginning = false): string {
    if (text == null) {
      return value;
    }

    if (this.isEmptyValue(value)) {
      return value;
    }

    if (atBeginning) {
      return `${text} ${value}`;
    }

    return `${value} ${text}`;
  }

  private isEmptyValue(value: any): boolean {
    return (
      value == null ||
      (Array.isArray(value) && value.length === 0) ||
      (typeof value === 'object' && Object.keys(value).length === 0) ||
      (typeof value === 'string' && value.trim().length === 0)
    );
  }
}
