<div class="modal-header align-items-center">
  <h1 class="modal-title">
    {{ 'invoicingFlows.businessLogic.conditions' | translate }}
  </h1>
  <button closeModal type="button" class="btn btn-link text-dark">
    <i class="fa-solid fa-times fa-fw fa-sm"></i>
  </button>
</div>
<div class="modal-body">
  <p>
    condition types: array, boolean, date, numeric, schedule, string, timespan
  </p>
</div>
<div class="modal-footer d-flex">
  <div class="flex-grow-1">
    <button closeModal type="button" class="btn btn-block btn-link">
      {{ 'shared.terms.cancel' | translate }}
    </button>
  </div>
  <div class="flex-grow-1">
    <button
      (click)="onEvent.emit({ action: 'saveCondition', data: condition })"
      type="button"
      class="btn btn-block btn-success"
    >
      {{ 'shared.terms.save' | translate }}
    </button>
  </div>
</div>
