import { Injectable } from '@angular/core';
import { ResultWrapper } from '@seahorse/common';
import { Observable } from 'rxjs';
import * as _ from 'underscore';
import { CustomDataAppendDataModel } from '../../custom-data/models/custom-data-append-data.model';
import { DataObjectKeyModel } from '../../custom-data/models/custom-data-base.model';
import {
  CustomDataSearchFieldModel,
  SearchOperators,
} from '../../custom-data/models/custom-data-search-field.model';
import { CustomDataContentService } from '../../custom-data/services/custom-data-content.service';
import { ExpenseConstant } from '../models/expense.constant';
import { ExpenseModel } from '../models/expense.model';
import { ProxyService } from '../../proxy/proxy.service';
import { ObjectDefinitionHelpers } from '../../custom-data/helpers/object-definition.helpers';

@Injectable({
  providedIn: 'root',
})
export class ExpensesDataService {
  private keyModel: DataObjectKeyModel;
  private expenseObjectDefinition =
    this.proxy.findObjectDefinitionBySystemCode('expense');

  constructor(
    private customData: CustomDataContentService,
    private proxy: ProxyService
  ) {
    this.keyModel = new DataObjectKeyModel();
    this.keyModel.parseKey(ExpenseConstant.OBJECTTYPEKEY);
  }

  getByOrderRegistration(
    orderRegistration: ExpenseModel['orderRegistration'],
    additionalSearchFields?: CustomDataSearchFieldModel[]
  ): Observable<ResultWrapper<ExpenseModel[]>> {
    let searchFields: CustomDataSearchFieldModel[] = [
      {
        fieldName: 'orderRegistration',
        searchOperator: SearchOperators.Equals,
        searchValue: orderRegistration,
      },
    ];

    if (additionalSearchFields && additionalSearchFields.length) {
      searchFields = searchFields.concat(additionalSearchFields);
    }

    const appendData = new CustomDataAppendDataModel(true);
    return this.customData.searchDataByObjectKeyAndFields(
      this.keyModel,
      searchFields,
      null,
      '__CreatedOn',
      'desc',
      0,
      500,
      appendData,
      null,
      null,
      null,
      null,
      null,
      null,
      ObjectDefinitionHelpers.getConnectedDataConfig(
        this.expenseObjectDefinition
      )
    );
  }

  getByPortCallFile(
    portCallFile: ExpenseModel['portCallFile'],
    additionalSearchFields?: CustomDataSearchFieldModel[]
  ): Observable<ResultWrapper<ExpenseModel[]>> {
    let searchFields: CustomDataSearchFieldModel[] = [
      {
        fieldName: 'portCallFile',
        searchOperator: SearchOperators.Equals,
        searchValue: portCallFile,
      },
    ];

    if (additionalSearchFields && additionalSearchFields.length) {
      searchFields = searchFields.concat(additionalSearchFields);
    }

    const appendData = new CustomDataAppendDataModel(true);
    return this.customData.searchDataByObjectKeyAndFields(
      this.keyModel,
      searchFields,
      null,
      '__CreatedOn',
      null,
      0,
      500,
      appendData,
      null,
      null,
      null,
      null,
      null,
      null,
      ObjectDefinitionHelpers.getConnectedDataConfig(
        this.expenseObjectDefinition
      )
    );
  }

  getByCustomer(
    customer: ExpenseModel['customer'],
    additionalSearchFields?: CustomDataSearchFieldModel[]
  ): Observable<ResultWrapper<ExpenseModel[]>> {
    let searchFields: CustomDataSearchFieldModel[] = [
      {
        fieldName: 'customer',
        searchOperator: SearchOperators.Equals,
        searchValue: customer,
      },
    ];

    if (additionalSearchFields && additionalSearchFields.length) {
      searchFields = searchFields.concat(additionalSearchFields);
    }

    return this.customData.searchDataByObjectKeyAndFields(
      this.keyModel,
      searchFields,
      null,
      '__CreatedOn',
      null,
      0,
      500,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      ObjectDefinitionHelpers.getConnectedDataConfig(
        this.expenseObjectDefinition
      )
    );
  }

  getBySupplier(
    supplier: ExpenseModel['supplier'],
    additionalSearchFields?: CustomDataSearchFieldModel[]
  ): Observable<ResultWrapper<ExpenseModel[]>> {
    let searchFields: CustomDataSearchFieldModel[] = [
      {
        fieldName: 'supplier',
        searchOperator: SearchOperators.Equals,
        searchValue: supplier,
      },
    ];

    if (additionalSearchFields && additionalSearchFields.length) {
      searchFields = searchFields.concat(additionalSearchFields);
    }

    return this.customData.searchDataByObjectKeyAndFields(
      this.keyModel,
      searchFields,
      null,
      '__CreatedOn',
      null,
      0,
      500,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      ObjectDefinitionHelpers.getConnectedDataConfig(
        this.expenseObjectDefinition
      )
    );
  }

  getByTariff(
    tariff: ExpenseModel['tariff'],
    additionalSearchFields?: CustomDataSearchFieldModel[]
  ): Observable<ResultWrapper<ExpenseModel[]>> {
    let searchFields: CustomDataSearchFieldModel[] = [
      {
        fieldName: 'tariff',
        searchOperator: SearchOperators.Equals,
        searchValue: tariff,
      },
    ];

    if (additionalSearchFields && additionalSearchFields.length) {
      searchFields = searchFields.concat(additionalSearchFields);
    }

    return this.customData.searchDataByObjectKeyAndFields(
      this.keyModel,
      searchFields,
      null,
      '__CreatedOn',
      null,
      0,
      500,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      ObjectDefinitionHelpers.getConnectedDataConfig(
        this.expenseObjectDefinition
      )
    );
  }

  getById(id: string) {
    return this.customData.getDataByIds(this.keyModel, [id]);
  }

  add(expenses: ExpenseModel[]): Observable<ResultWrapper<ExpenseModel[]>> {
    return new Observable<ResultWrapper<ExpenseModel[]>>((s) => {
      this.customData
        .addCustomerArrayOfData(this.keyModel.SystemCode, expenses)
        .subscribe((r) => {
          if (r.hasResult) {
            s.next(r);
            s.complete();
          }
        });
    });
  }

  addDefaultDataForParent(
    parentType: string,
    parentId: string,
    defaultDataGroups?: number[],
    defaultDataObjects?: string[]
  ): Observable<ResultWrapper<ExpenseModel[]>> {
    return new Observable<ResultWrapper<ExpenseModel[]>>((s) => {
      this.customData
        .addDefaultDataForData(
          parentType,
          parentId,
          defaultDataGroups,
          defaultDataObjects
        )
        .subscribe((r) => {
          if (r.hasResult) {
            s.next(r);
            s.complete();
          }
        });
    });
  }

  update(expenses: ExpenseModel[]): Observable<ResultWrapper<ExpenseModel[]>> {
    return new Observable<ResultWrapper<ExpenseModel[]>>((s) => {
      const succesful: ExpenseModel[] = [];
      let messages: ResultWrapper<any>['messages'] = [];
      let unsuccesful = 0;

      if (expenses.length) {
        _.forEach(expenses, (expense) => {
          this.customData
            .updateCustomerData(this.keyModel.SystemCode, expense.__Id, expense)
            .subscribe(
              (r) => {
                if (r.hasResult) {
                  succesful.push(r.result);
                } else {
                  messages = messages.concat(r.messages);
                  unsuccesful++;
                }
              },

              (e) => {
                messages = messages.concat(e.error.messages);
                unsuccesful++;
              },

              () => {
                if (succesful.length + unsuccesful === expenses.length) {
                  const w: ResultWrapper<ExpenseModel[]> = new ResultWrapper<
                    ExpenseModel[]
                  >();
                  {
                    w.count = expenses.length;
                    w.hasResult =
                      !expenses.length || succesful.length ? true : false;
                    w.messages = messages;
                    w.result = succesful;
                  }

                  s.next(w);
                  s.complete();
                }
              }
            );
        });
      } else {
        s.complete();
      }
    });
  }

  delete(
    ids: ExpenseModel['__Id'][]
  ): Observable<ResultWrapper<ExpenseModel[]>> {
    return new Observable<ResultWrapper<ExpenseModel[]>>((s) => {
      const succesful: ExpenseModel[] = [];
      let messages: ResultWrapper<any>['messages'] = [];
      let unsuccesful = 0;

      _.forEach(ids, (id) => {
        this.customData
          .deleteCustomerData(this.keyModel.SystemCode, id)
          .subscribe(
            (r) => {
              if (r.hasResult) {
                succesful.push(r.result);
              } else {
                messages = messages.concat(r.messages);
                unsuccesful++;
              }
            },

            (e) => {
              messages = messages.concat(e.error.messages);
              unsuccesful++;
            },

            () => {
              if (succesful.length + unsuccesful === ids.length) {
                const w: ResultWrapper<ExpenseModel[]> = new ResultWrapper<
                  ExpenseModel[]
                >();
                {
                  w.count = ids.length;
                  w.hasResult = !ids.length || succesful.length ? true : false;
                  w.messages = messages;
                  w.result = succesful;
                }

                s.next(w);
                s.complete();
              }
            }
          );
      });
    });
  }
}
