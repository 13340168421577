import { inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DataContextService } from '@seahorse/common';
import { ResultWrapper } from '@seahorse/common';
import { NotificationConfigurationModel } from '../models/notification.configuration.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NotificationConfigurationService {
  private _senderAddresses: NotificationConfigurationModel;

  private _dataContext = inject(DataContextService);

  setSenderAddresses(addresses: NotificationConfigurationModel) {
    this._senderAddresses = addresses;
  }

  getSenderAddresses(): NotificationConfigurationModel {
    return this._senderAddresses;
  }

  loadSenderAddresses(
    addressType: number
  ): Observable<NotificationConfigurationModel> {
    if (this._senderAddresses) {
      return of(this._senderAddresses);
    } else {
      return this.getNotificationConfiguration(addressType).pipe(
        map((res) => {
          this._senderAddresses = res.result;
          return this._senderAddresses;
        })
      );
    }
  }

  getNotificationConfiguration(
    addressType: number
  ): Observable<ResultWrapper<NotificationConfigurationModel>> {
    const url = `notification-configuration/${addressType}`;
    return this._dataContext.get<NotificationConfigurationModel>(url);
  }
}
