import { Component, Input } from '@angular/core';
import { SystemMessageType } from '../../../layout/models/system-message-type.enum';

@Component({
  selector: 'ca-system-message',
  templateUrl: './system-message.component.html',
  styleUrls: ['./system-message.component.scss'],
})
export class SystemMessageComponent {
  @Input() message: SystemMessageModel;
  MessageTypes = SystemMessageType;
}

export class SystemMessageModel {
  id: string;
  messageType: SystemMessageType;
  message: string;
}
