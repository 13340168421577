import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PeriodInterval } from '../models/period-dates.model';

@Pipe({ name: 'periodIntervalDisplay' })
export class PeriodIntervalDisplayPipe implements PipeTransform {
  periodIntervals: Object;

  constructor(private translate: TranslateService) {
    this.periodIntervals = {
      [PeriodInterval.WEEK]: 'shared.datepicker.week',
      [PeriodInterval.MONTH]: 'shared.datepicker.month',
      [PeriodInterval.QUARTER]: 'shared.datepicker.quarter',
      [PeriodInterval.YEAR]: 'shared.datepicker.year',
      [PeriodInterval.HALFDECADE]: 'shared.datepicker.halfDecade',
    };
  }

  transform(periodInterval: PeriodInterval): string {
    let pipe: string;

    this.translate
      .get(this.periodIntervals[periodInterval])
      .subscribe((t) => (pipe = t));

    return pipe;
  }
}
