import { Injectable } from '@angular/core';
import { NauticalVisitOverviewModel } from '@seahorse/domain';
import {
  CompanyModel,
  CompanyExternalKeyModel,
} from '@seahorse/domain';
import { CVVActivityModel } from '../models/cvv-activity.model';
import { ValidationResult } from '@seahorse/common';

import * as moment from 'moment';
import * as _ from 'underscore';

import { CompanyDataService } from '@seahorse/domain';
import { CompanyExternalKeyDataService } from '../../../company/services/company-external-key-data.service';
import { UserModel } from '@seahorse/domain';
import { UserDataService } from '../../../user/services/user-data.service';
import { CustomTeamModel } from '../../general/models/custom-team.model';
import { CustomTeamDataService } from '../../general/services/custom-team-data.service';
import { IdentityService } from '../../../core/services/identity.service';

@Injectable()
export class CVVActivityLogicService {
  berths = [
    { code: 'ANC8N', name: 'ANC8N (Ankerplaats 8)' },
    { code: 'ANCHD', name: 'ANCHD (Ankerplaats 6 (DWA))' },
    { code: 'ANCHO', name: 'ANCHO (Ankerplaats 7 (zuid))' },
    { code: 'AVALG', name: 'AVALG (Averijhaven IJ palen Algemeen)' },
    { code: 'AVPLN', name: 'AVPLN (Averijhaven IJ palen)' },
    { code: 'BNSPK', name: 'BNSPK (Binnenspuikanaal Algemeen)' },
    { code: 'BNSPN', name: 'BNSPN (Binnenspuikanaal Noord)' },
    { code: 'BNSPZ', name: 'BNSPZ (Binnenspuikanaal Zuid)' },
    { code: 'BSKAD', name: 'BSKAD (Buitenhaven Buitenspuikanaal kade)' },
    { code: 'BSPLN', name: 'BSPLN (Buitenhaven Buitenspuikanaal Palen)' },
    { code: 'BUALG', name: 'BUALG (Buitenhaven Algemeen)' },
    { code: 'BUAMS', name: 'BUAMS (Buitenhaven Amoniak Steiger)' },
    { code: 'BUK2O', name: 'BUK2O (Buitenhaven Buitenkade 2 Oost)' },
    { code: 'BUK2W', name: 'BUK2W (Buitenhaven Buitenkade 2 West)' },
    { code: 'BUKA0', name: 'BUKA0 (Buitenhaven Buitenkade 0)' },
    { code: 'BUKA1', name: 'BUKA1 (Buitenhaven Buitenkade 1)' },
    { code: 'BUKA3', name: 'BUKA3 (Buitenhaven Buitenkade 3)' },
    { code: 'BULLK', name: 'BULLK (Buitenhaven Laad en los kade)' },
    { code: 'BUPHK', name: 'BUPHK (Buitenhaven Pelt en Hooykaas)' },
    { code: 'BUSTE', name: 'BUSTE (Buitenhaven Steenstortkade)' },
    { code: 'BUTEE', name: 'BUTEE (Buitenhaven Teersteiger)' },
    { code: 'BVABE', name: 'BVABE (Beverwijk Abeko Kade)' },
    { code: 'BVALG', name: 'BVALG (Beverwijk Algemeen)' },
    { code: 'BVBEB', name: 'BVBEB (Beverwijk Beaverbase)' },
    { code: 'BVCON', name: 'BVCON (Beverwijk Containerterminal)' },
    { code: 'BVKO1', name: 'BVKO1 (Beverwijk Riwald kade 1)' },
    { code: 'BVKO2', name: 'BVKO2 (Beverwijk Riwald kade 2)' },
    { code: 'BVMEB', name: 'BVMEB (Beverwijk Mebin)' },
    { code: 'BVNIE', name: 'BVNIE (Beverwijk Nieuwekade 22-30)' },
    { code: 'BVNOR', name: 'BVNOR (Beverwijk Noorderkade)' },
    { code: 'BVTR1', name: 'BVTR1 (Beverwijk Transko)' },
    { code: 'BVZUI', name: 'BVZUI (Beverwijk Zuiderkade)' },
    { code: 'FPALG', name: 'FPALG (Fortput Put)' },
    { code: 'FPPLN', name: 'FPPLN (Fortput Steiger Eiland)' },
    { code: 'HAALG', name: 'HAALG (Haringhaven Algemeen)' },
    { code: 'HABUK', name: 'HABUK (Haringhaven Bunkerkade)' },
    { code: 'HABUP', name: 'HABUP (Haringhaven Bunkerpalen)' },
    { code: 'HAHEL', name: 'HAHEL (Haringhaven Scheepshelling)' },
    { code: 'HAKOT', name: 'HAKOT (Haringhaven Kotterkade)' },
    { code: 'HALEO', name: 'HALEO (Haringhaven Leonarduskade West)' },
    { code: 'HALOG', name: 'HALOG (Haringhaven Loggerkade)' },
    { code: 'HALZU', name: 'HALZU (Haringhaven Leonarduskade Zuid)' },
    { code: 'HASTW', name: 'HASTW (Haringhaven Leonarduskade Zuidwest)' },
    { code: 'LWKAD', name: 'LWKAD (Loswal RWS)' },
    { code: 'PS KRP 1', name: 'PS KRP 1 (Pilotstation KRP 1)' },
    { code: 'PS KRP 10', name: 'PS KRP 10 (Pilotstation KRP 10)' },
    { code: 'PS KRP 11', name: 'PS KRP 11 (Pilotstation KRP 11)' },
    { code: 'PS KRP 12', name: 'PS KRP 12 (Pilotstation KRP 12)' },
    { code: 'PS KRP 13', name: 'PS KRP 13 (Pilotstation KRP 13)' },
    { code: 'PS KRP 2', name: 'PS KRP 2 (Pilotstation KRP 2)' },
    { code: 'PS KRP 3', name: 'PS KRP 3 (Pilotstation KRP 3)' },
    { code: 'PS KRP 4', name: 'PS KRP 4 (Pilotstation KRP 4)' },
    { code: 'PS KRP 5', name: 'PS KRP 5 (Pilotstation KRP 5)' },
    { code: 'PS KRP 6', name: 'PS KRP 6 (Pilotstation KRP 6)' },
    { code: 'PS KRP 7', name: 'PS KRP 7 (Pilotstation KRP 7)' },
    { code: 'PS KRP 8', name: 'PS KRP 8 (Pilotstation KRP 8)' },
    { code: 'PS KRP 9', name: 'PS KRP 9 (Pilotstation KRP 9)' },
    { code: 'PS RV 1', name: 'PS RV 1 (Pilotstation Rendez-Vous 1)' },
    { code: 'PS RV 2', name: 'PS RV 2 (Pilotstation Rendez-Vous 2)' },
    { code: 'R1ALG', name: 'R1ALG (Rijksbinnenhaven 1 Algemeen)' },
    { code: 'R1CEM', name: 'R1CEM (Rijksbinnenhaven 1 CEMIJ Steiger)' },
    { code: 'R1PLZ', name: 'R1PLZ (Rijksbinnenhaven 1 Palen Zuidzijde)' },
    { code: 'R2ALG', name: 'R2ALG (Rijksbinnenhaven 2 Algemeen)' },
    { code: 'R2KAD', name: 'R2KAD (Rijksbinnenhaven 2 Kade)' },
    { code: 'R2KOP', name: 'R2KOP (Rijksbinnenhaven 2 Kop)' },
    { code: 'R2PLN', name: 'R2PLN (Rijksbinnenhaven 2 Palen West)' },
    { code: 'R2UNA', name: 'R2UNA (Rijksbinnenhaven 2 UNA)' },
    { code: 'R3ALG', name: 'R3ALG (Rijksbinnenhaven 3 Algemeen)' },
    { code: 'R3AWT', name: 'R3AWT (Rijksbinnenhaven 3 All Weatherterminal)' },
    { code: 'R3KRT', name: 'R3KRT (Rijksbinnenhaven 3 Korte kade)' },
    { code: 'R3NRD', name: 'R3NRD (Rijksbinnenhaven 3 Kade Noord)' },
    { code: 'R3WAG', name: 'R3WAG (Rijksbinnenhaven 3 Langszij AWT)' },
    { code: 'R3WAK', name: 'R3WAK (Rijksbinnenhaven 3 Wachtkade)' },
    { code: 'SPSPM', name: 'SPSPM (Seaport Marina)' },
    { code: 'STKAD', name: 'STKAD (Staalhaven Kade)' },
    { code: 'STPLN', name: 'STPLN (Staalhaven Palen)' },
    { code: 'VEALG', name: 'VEALG (Velsen Algemeen)' },
    { code: 'VECMF', name: 'VECMF (Velsen CMF)' },
    { code: 'VEHOU', name: 'VEHOU (Velsen Grote Hout)' },
    { code: 'VEOCT', name: 'VEOCT (Velsen Octopusbase)' },
    { code: 'VETER', name: 'VETER (Velsenterminal)' },
    { code: 'VHALG', name: 'VHALG (Vissershaven Algemeen)' },
    { code: 'VHHAL', name: 'VHHAL (Vissershaven Halkade)' },
    { code: 'VHNRM', name: 'VHNRM (Vissershaven K.N.R.M.)' },
    { code: 'VHSTG', name: 'VHSTG (Vissershaven Steigers)' },
    { code: 'VHTER', name: 'VHTER (Vissershaven Felison Terminal)' },
    { code: 'VHZZM', name: 'VHZZM (Vissershaven Trawlerkade ZZ Midden)' },
    { code: 'VHZZO', name: 'VHZZO (Vissershaven Trawlerkade ZZ Oost)' },
    { code: 'VHZZW', name: 'VHZZW (Vissershaven Trawlerkade ZZ West)' },
    { code: 'WPSLW', name: 'WPSLW (Wachtpalen Sluis west)' },
    { code: 'YMALG', name: 'YMALG (IJmondhaven Algemeen)' },
    { code: 'YMMON', name: 'YMMON (IJmondhaven Monnickendamkade Oost)' },
    { code: 'YMVOL', name: 'YMVOL (IJmondhaven Volendamkade West)' },
    { code: 'ZTPAS', name: 'ZTPAS (Zuidertoeleiding Cruiseterminal)' },
    { code: 'ZTPLO', name: 'ZTPLO (Zuiderpalen Oost)' },
    { code: 'ZTPLW', name: 'ZTPLW (Zuiderpalen West)' },
  ];

  private berthsEastOfLock: string[] = [
    'VEALG',
    'VECMF',
    'VEHOU',
    'VEOCT',
    'VETER',
    'STKAD',
    'STPLN',
    'LWKAD',
    'BVABE',
    'BVALG',
    'BVBEB',
    'BVCON',
    'BVKO1',
    'BVKO2',
    'BVMEB',
    'BVNIE',
    'BVNOR',
    'BVTR1',
    'BVZUI',
    'BNSPK',
    'BNSPN',
    'BNSPZ',
    'R1ALG',
    'R1CEM',
    'R1PLZ',
    'R2ALG',
    'R2KAD',
    'R2KOP',
    'R2PLN',
    'R2UNA',
    'R3ALG',
    'R3AWT',
    'R3KRT',
    'R3NRD',
    'R3WAG',
    'R3WAK',
  ];

  cvvActivityTypes = {
    Verhalen: { id: 1, name: 'Verhalen', sort: 4 },
    Ontmeren: { id: 2, name: 'Ontmeren', sort: 2 },
    MerenOntmerenSluis: { id: 3, name: 'Meren/ontmeren sluis', sort: 3 },
    Meren: { id: 4, name: 'Meren', sort: 1 },
    Runners: { id: 5, name: 'Runners', sort: 10 },
    Communicatievaren: { id: 6, name: 'Communicatie/tender', sort: 5 },
    Slepen: { id: 7, name: 'Slepen', sort: 11 },
    Materiaal: { id: 8, name: 'Materiaal', sort: 8 },
    Oliescherm: { id: 9, name: 'Oliescherm', sort: 9 },
    Diepgang: { id: 10, name: 'Diepgang', sort: 7 },
    CommunicatievarenOverig: {
      id: 11,
      name: 'Communicatievaren (overig)',
      sort: 6,
    },
    Wachttijd: { id: 100, name: 'Wachttijd', sort: 13 },
    Heli: { id: 101, name: 'Stand-by heli', sort: 12 },
    Overig: { id: 999, name: 'Overig', sort: 14 },
  };

  private ignoredBerthsForActivities: string[] = [
    'PS KRP 1',
    'PS KRP 10',
    'PS KRP 11',
    'PS KRP 12',
    'PS KRP 13',
    'PS KRP 2',
    'PS KRP 3',
    'PS KRP 4',
    'PS KRP 5',
    'PS KRP 6',
    'PS KRP 7',
    'PS KRP 8',
    'PS KRP 9',
    'PS RV 1',
    'PS RV 2',
  ];

  movementTypes = [
    { id: 1, name: 'Inward' },
    { id: 3, name: 'Outward' },
    { id: 2, name: 'Shift' },
    { id: 255, name: 'Other' },
  ];

  tenderTypes = [
    { id: 'motorboat', name: 'motorboot' },
    { id: 'CTV', name: 'CTV' },
  ];

  portCode = 'nlams';

  companies: CompanyModel[] = [];
  companyFinancialKeys: CompanyExternalKeyModel[] = [];
  people: UserModel[] = [];
  teams: CustomTeamModel[] = [];

  constructor(
    private companyDataService: CompanyDataService,
    private companyExternalKeyDataService: CompanyExternalKeyDataService,
    private identittyService: IdentityService,
    private teamDataService: CustomTeamDataService,
    private userDataService: UserDataService
  ) {
    this.loadFinancialKeys();
    this.loadCompanies();
    this.loadUsersAndMemberTeams();

    this.setBerths();
  }

  loadCompanies() {
    this.companyDataService.getAll(0, 9999).subscribe((result) => {
      if (result.hasResult) {
        this.companies = _.sortBy(result.result, (c) => c.name.toLowerCase());
      }
    });
  }

  loadFinancialKeys() {
    this.companyExternalKeyDataService
      .getFinancialKeys('nautical_company_id')
      .subscribe((result) => {
        if (result.hasResult) {
          this.companyFinancialKeys = result.result;
        }
      });
  }

  loadUsersAndMemberTeams() {
    // Get all users
    this.userDataService.getByOrganisation(false).subscribe((result) => {
      if (result.hasResult) {
        this.people = result.result;
      }
    });

    // get teams
    this.teamDataService.get().subscribe((teamGetResponse) => {
      if (teamGetResponse.hasResult) {
        this.teams = teamGetResponse.result;
      }
    });
  }

  generateActivitiesForVisit(
    visit: NauticalVisitOverviewModel
  ): CVVActivityModel[] {
    const result: CVVActivityModel[] = [];
    if (!visit || !visit.currentMovement) {
      return result;
    }

    let isFromCVV = false;
    let fromLockPosition = null;
    let isToCVV = false;
    let toLockPosition = null;
    const companyId = this.getCompanyByAgentId(
      visit.currentMovement.portAgentId
    );

    if (!visit.currentMovement.portWayPointFrom) {
      fromLockPosition = 'w';
    } else if (
      visit.currentMovement.portWayPointFrom &&
      visit.currentMovement.portWayPointFrom.code
    ) {
      isFromCVV =
        _.findIndex(
          this.berths,
          (b) =>
            b.code === visit.currentMovement.portWayPointFrom.code.toUpperCase()
        ) > -1;
      fromLockPosition =
        _.contains(
          this.berthsEastOfLock,
          visit.currentMovement.portWayPointFrom.code.toUpperCase()
        ) || !isFromCVV
          ? 'o'
          : 'w';
    }

    if (!visit.currentMovement.portWayPointTo) {
      toLockPosition = 'w';
    } else if (
      visit.currentMovement.portWayPointTo &&
      visit.currentMovement.portWayPointTo.code
    ) {
      isToCVV =
        _.findIndex(
          this.berths,
          (b) =>
            b.code === visit.currentMovement.portWayPointTo.code.toUpperCase()
        ) > -1;
      toLockPosition =
        _.contains(
          this.berthsEastOfLock,
          visit.currentMovement.portWayPointTo.code.toUpperCase()
        ) || !isToCVV
          ? 'o'
          : 'w';
    }

    if (
      visit.currentMovement &&
      visit.currentMovement.movementType &&
      visit.currentMovement.movementType == 2
    ) {
      const shift = new CVVActivityModel();
      shift.$nautical_portmovement_id = visit.currentMovement.id;
      shift.$nautical_portvisit_referencenumber = visit.referenceNumber;
      shift.$nautical_ship_id = visit.shipId;
      shift.$companies_company_id = companyId;
      shift.activityType = this.cvvActivityTypes.Verhalen.id;
      shift.movementType = visit.currentMovement.movementType;
      shift.startsOn = visit.currentMovement.etd
        ? visit.currentMovement.etd
        : new Date(moment().format('YYYY-MM-DDTHH:mm:ss') + 'Z');

      if (
        visit.currentMovement.portWayPointFrom &&
        _.findIndex(
          this.berths,
          (b) =>
            b.code === visit.currentMovement.portWayPointFrom.code.toUpperCase()
        ) > -1
      ) {
        shift.berth1 = visit.currentMovement.portWayPointFrom.code;
      }

      if (
        visit.currentMovement.portWayPointTo &&
        _.findIndex(
          this.berths,
          (b) =>
            b.code === visit.currentMovement.portWayPointTo.code.toUpperCase()
        ) > -1
      ) {
        shift.berth2 = visit.currentMovement.portWayPointTo.code;
      }

      result.push(shift);
    } else {
      if (
        isFromCVV &&
        this.ignoredBerthsForActivities.indexOf(
          visit.currentMovement.portWayPointFrom.code.toUpperCase()
        ) === -1
      ) {
        const unmooring = new CVVActivityModel();
        unmooring.$nautical_portmovement_id = visit.currentMovement.id;
        unmooring.$nautical_portvisit_referencenumber = visit.referenceNumber;
        unmooring.$nautical_ship_id = visit.shipId;
        unmooring.$companies_company_id = companyId;
        unmooring.activityType = this.cvvActivityTypes.Ontmeren.id;
        unmooring.berth1 = visit.currentMovement.portWayPointFrom.code;
        unmooring.movementType = visit.currentMovement.movementType;
        unmooring.startsOn = visit.currentMovement.etd
          ? visit.currentMovement.etd
          : new Date(moment().format('YYYY-MM-DDTHH:mm:ss') + 'Z');
        result.push(unmooring);
      }

      if (fromLockPosition !== toLockPosition) {
        const locks = new CVVActivityModel();
        locks.$nautical_portmovement_id = visit.currentMovement.id;
        locks.$nautical_portvisit_referencenumber = visit.referenceNumber;
        locks.$nautical_ship_id = visit.shipId;
        locks.$companies_company_id = companyId;
        locks.activityType = this.cvvActivityTypes.MerenOntmerenSluis.id;
        locks.movementType = visit.currentMovement.movementType;
        locks.startsOn = visit.currentMovement.eta
          ? visit.currentMovement.eta
          : new Date(moment().format('YYYY-MM-DDTHH:mm:ss') + 'Z');
        result.push(locks);
      }

      if (
        isToCVV &&
        this.ignoredBerthsForActivities.indexOf(
          visit.currentMovement.portWayPointTo.code.toUpperCase()
        ) === -1
      ) {
        const mooring = new CVVActivityModel();
        mooring.$nautical_portmovement_id = visit.currentMovement.id;
        mooring.$nautical_portvisit_referencenumber = visit.referenceNumber;
        mooring.$nautical_ship_id = visit.shipId;
        mooring.$companies_company_id = companyId;
        mooring.activityType = this.cvvActivityTypes.Meren.id;
        mooring.berth1 = visit.currentMovement.portWayPointTo.code;
        mooring.movementType = visit.currentMovement.movementType;
        mooring.startsOn = visit.currentMovement.eta
          ? visit.currentMovement.eta
          : new Date(moment().format('YYYY-MM-DDTHH:mm:ss') + 'Z');
        result.push(mooring);
      }
    }

    return result;
  }

  getCompanyByAgentId(agentId: number) {
    if (agentId) {
      const companyIds = _.map(
        _.filter(
          this.companyFinancialKeys,
          (key) => key.sourceValue === agentId.toString()
        ),
        'companyId'
      );
      const company = _.find(
        this.companies,
        (c) => c.financialCount > 0 && companyIds.indexOf(c.id) > -1
      );
      return company ? company.id : null;
    }

    return null;
  }

  setBerths() {
    if (
      this.identittyService.identity &&
      this.identittyService.identity.organisationId
    ) {
      switch (this.identittyService.identity.organisationId.toLowerCase()) {
        case '39b94510-32fd-47b9-b858-4a8a347ab1d0':
          {
            this.berths = [
              { code: 'DZEEM', name: 'Delfzijl - Eemskanaal' },
              { code: 'DZHHA', name: 'Delfzijl - Handelshaven' },
              { code: 'DZOOS', name: 'Delfzijl - Oosterhornhaven' },
              { code: 'DZFRM', name: 'Delfzijl - Farmsumerhaven' },
              { code: 'DZWLD', name: 'Delfzijl - Kade Woldbrug' },
              { code: 'DZZHK', name: 'Delfzijl - Zeehavenkanaal' },
              { code: 'EMEMM', name: 'Eemshaven - Emmahaven' },
              { code: 'EMBEA', name: 'Eemshaven - Beatrixhaven' },
              { code: 'EMDOE', name: 'Eemshaven - Doekegatkanaal' },
              { code: 'EMJUL', name: 'Eemshaven - Julianahaven' },
              { code: 'EMWIL', name: 'Eemshaven - Wilhelminahaven' },
            ];
            delete this.cvvActivityTypes.MerenOntmerenSluis;
            this.portCode = 'nldzl';
          }
          break;
      }
    }
  }

  validActivitiesForVisit(activities: CVVActivityModel[]): ValidationResult {
    const result: ValidationResult = new ValidationResult();

    activities.forEach((activity, index) => {
      // define the prefix of the error message
      let prefix: string;
      let activityType = null;
      if (activity.activityType) {
        activityType = _.find(
          _.values(this.cvvActivityTypes),
          (a) => a.id === activity.activityType
        );
      }

      if (!activity) {
        prefix = 'Activiteit ' + index + ': ';
      } else {
        prefix = activityType.name + ': ';
      }

      result.errors = result.errors.concat(
        CVVActivityModel.validate(activity, prefix).errors
      );

      if (!activity.$companies_company_id) {
        result.errors.push(
          prefix + 'Het veld klanten is verplicht bij reis afsluiten.'
        );
      }

      if (!activity.endsOn) {
        result.errors.push(
          prefix + 'Het veld eindtijd is verplicht bij reis afsluiten.'
        );
      }

      if ([2, 3, 4].indexOf(activity.activityType) > -1) {
        if (!activity.berth1) {
          result.errors.push(
            prefix + 'Het veld ligplaats is verplicht bij reis afsluiten.'
          );
        }
      } else if (activity.activityType === 1) {
        if (!activity.berth1 || !activity.berth2) {
          result.errors.push(
            prefix + 'De ligplaatsen zijn verplicht bij reis afsluiten.'
          );
        }
      }
    });

    result.isValid = result.errors.length === 0;
    return result;
  }
}
