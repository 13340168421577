import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { CompanyContactStatuses } from '@seahorse/domain';

@Pipe({
  name: 'contactStatus',
  pure: true,
})
export class ContactStatusPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  statuses = {
    [CompanyContactStatuses.Active]: this.translateService.instant(
      'contact.status.active'
    ),
    [CompanyContactStatuses.Diseased]: this.translateService.instant(
      'contact.status.diseased'
    ),
    [CompanyContactStatuses.LeftCompany]: this.translateService.instant(
      'contact.status.leftCompany'
    ),
  };

  transform(status: CompanyContactStatuses): string {
    return this.statuses[status];
  }
}
