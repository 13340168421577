import { Injectable } from '@angular/core';

import { FormComponent } from './form.component';

@Injectable()
export class FormInteractionService {
  formRef: FormComponent;

  private get _form() {
    return this.formRef.form;
  }

  private get _formValue() {
    return this.formRef.value;
  }

  areTherePendingChanges(initValue: any) {
    return (
      this._form.dirty &&
      JSON.stringify(initValue) !== JSON.stringify(this._formValue)
    );
  }
}
