<div *shEmptyPlaceholder="isLoading">
  <div *ngIf="ship" class="p-relative">
    <div
      class="card-image"
      *ngIf="ship.imo && showPhoto"
      [ngStyle]="{
        'background-image':
          'url(https://dir.ship2report.com/' + ship.imo + '.jpg)'
      }"
    ></div>
    <div class="card-body">
      <div
        class="card-body-group card-body-group-no-border"
        *ngIf="attributes && attributes.length > 0"
      >
        <h4>{{ 'nautical.ship.dimensions' | translate }}</h4>
        <temp-attribute-list
          [attributes]="attributes"
          columns="2"
        ></temp-attribute-list>
      </div>
      <div *ngIf="ship">
        <ca-nautical-ship-details
          [ship]="ship"
          [allowEdit]="false"
          [showTitle]="false"
        >
        </ca-nautical-ship-details>
      </div>
    </div>
    <div *ngIf="showNavigation">
      <ul class="summary-buttons">
        <li>
          <a
            routerLink="/nautical/vessels/{{ ship.id }}"
            class="summary-button"
          >
            <i class="fa-regular fa-ship fa-fw" aria-hidden="true"></i>
            <span class="summary-button-text">
              {{ 'nautical.ship.shipDetails' | translate }}
            </span>
          </a>
        </li>
        <li *ngIf="ship.mmsi && shipPosition">
          <a
            routerLink="/map"
            [queryParams]="{
              lat: shipPosition.latitude,
              lng: shipPosition.longitude,
              mmsi: ship.mmsi
            }"
            class="summary-button"
          >
            <i class="fa-regular fa-map-marked-alt fa-fw"></i>
            <span class="summary-button-text">{{
              'nautical.ship.findOnMap' | translate
            }}</span>
          </a>
        </li>
        <li *hasPermission="'port_visits'">
          <a
            routerLink="/nautical/visit/ship/{{ ship.id }}/overview"
            class="summary-button has-summary-count"
          >
            <i class="fa-regular fa-route fa-fw" aria-hidden="true"></i>
            <span class="summary-button-text">
              {{ portVisitCount }} {{ 'nautical.visit.portVisits' | translate }}
            </span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
