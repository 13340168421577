import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { LayoutService } from '@seahorse/layout';
import { Subscription } from 'rxjs';
import { EventModel } from '../../../../../shared/models/event.model';
import { QueryModel } from '../../../../../shared/models/query.model';
import { DropDownListOption } from '@seahorse/forms';
import { UserDataService } from '../../../../../user/services/user-data.service';
import { NotificationService } from '@seahorse/common';
import { createTasksSearchForm } from './tasks-advanced-search.form';
import { IdentityService } from '../../../../../core/services/identity.service';

@Component({
  selector: 'ca-tasks-advanced-search',
  templateUrl: 'tasks-advanced-search.component.html',
  styleUrls: ['./tasks-advanced-search.component.scss'],
})
export class TasksAdvancedSearchComponent implements OnInit, OnDestroy {
  @Input() loading: boolean;
  @Input() search: QueryModel['search'];
  @Input() active: boolean;
  @Input() expired: boolean;
  @Input() assignedTo: string;
  @Input() showAdvancedSearch = false;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onEvent: EventEmitter<EventModel> = new EventEmitter<EventModel>();

  expanded: boolean;
  sidebarOpen = false;

  assignedToDropDownList: DropDownListOption[] = [];
  form = createTasksSearchForm();

  private _subscriptions = new Subscription();

  private _layoutService = inject(LayoutService);
  private _userDataService = inject(UserDataService);
  private _notificationService = inject(NotificationService);
  private _identityService = inject(IdentityService);

  ngOnInit(): void {
    this.loadUsers();

    this.form.patchValue({
      active: this.active,
      expired: this.expired,
      assignedTo: this.assignedTo,
    });

    this._subscriptions.add(
      this._layoutService.sidebarToggle$.subscribe((sidebarOpen: boolean) => {
        this.sidebarOpen = sidebarOpen;
      })
    );

    this._subscriptions.add(
      this.form.get('assignedTo').valueChanges.subscribe((value) => {
        this.onEvent.emit({ action: 'setAssignedTo', data: value });
      })
    );
  }

  loadUsers() {
    const me = this._identityService.identity.id;

    this._subscriptions.add(
      this._userDataService.getByOrganisation(true).subscribe({
        next: (response) => {
          if (response.hasResult) {
            this.assignedToDropDownList = response.result.map((x) => {
              const label = x.id === me ? 'Me' : x.givenName + ' ' + x.surname;
              return { label: label, value: x.id };
            });

            this.assignedToDropDownList.sort((a, b) => {
              if (a.label === 'Me') return -1;
              if (b.label === 'Me') return 1;
              return 0;
            });
          } else {
            this._notificationService.displayErrorNotification(
              response.messages
            );
          }
        },
        error: (e) => this._notificationService.displayErrorNotification(e),
      })
    );
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }
}
