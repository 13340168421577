<form #followUpTaskForm="ngForm">
  <div class="row">
    <div class="col-12">
      <div class="form-group">
        <label class="control-label required"
          ><b>{{ 'tasks.task.model.subject' | translate }}</b></label
        >
        <input
          #name="ngModel"
          type="text"
          name="subject"
          [(ngModel)]="task.subject"
          class="form-control"
        />
        <small *ngIf="submitted && !task.subject" class="text-danger">{{
          'tasks.task.model.subjectRequired' | translate
        }}</small>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="form-group">
        <label class="control-labe"
          ><b>{{ 'shared.terms.description' | translate }}</b></label
        >
        <input
          #description="ngModel"
          type="text"
          name="description"
          [(ngModel)]="task.description"
          class="form-control"
        />
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="form-group">
        <label
          ><b>{{ 'tasks.task.model.assignedTo' | translate }}</b></label
        >
        <ng-select
          #user
          name="user"
          [items]="users"
          placeholder="{{ 'user.select' | translate }}"
          bindValue="id"
          bindLabel="fullname"
          [(ngModel)]="task.assignedTo"
        >
        </ng-select>
      </div>
    </div>
  </div>
</form>
