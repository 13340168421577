import { Injectable } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'underscore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ResultWrapper } from '@seahorse/common';

import {
  CustomDataAppendDataModel,
  CustomDataContentService,
  CustomDataSearchFieldModel,
  SearchOperators,
} from '@seahorse/domain';

import { CVVActivityModel } from '../../../custom-ui/cvv/models/cvv-activity.model';
import * as CVVActiveVisitModelClass from '../../../custom-ui/cvv/models/cvv-active-visit.model';
import { CVVShipRequirementsModel } from '../../../custom-ui/cvv/models/cvv-ship-requirements.model';

@Injectable()
export class CVVDataService {
  constructor(private customDataContent: CustomDataContentService) {}

  addActiveVisit(
    activeVisit: CVVActiveVisitModelClass.CVVActiveVisitModel
  ): Observable<ResultWrapper<any>> {
    return this.customDataContent.addCustomerData('activevisit', activeVisit);
  }

  addActivity(activity: CVVActivityModel): Observable<ResultWrapper<any>> {
    return this.customDataContent
      .addCustomerData('activity', CVVActivityModel.toApiModel(activity))
      .pipe(
        map((response) => {
          if (response && response.result) {
            response.result = CVVActivityModel.fromApiModel(response.result);
          }

          return response;
        })
      );
  }

  addShipRequirements(
    model: CVVShipRequirementsModel
  ): Observable<ResultWrapper<any>> {
    return this.customDataContent.addCustomerData('shipRequirements', model);
  }

  deleteActivity(
    activity: CVVActivityModel,
    remarks: string
  ): Observable<ResultWrapper<any>> {
    return this.customDataContent.markAsDelete(
      'activity',
      activity.__Id,
      remarks
    );
  }

  getVisitsByShipIds(
    shipIds: number[],
    onlyActive?: boolean,
    preloadedFields?: string[]
  ): Observable<ResultWrapper<any>> {
    const fields = [];
    fields.push({
      fieldName: '$nautical_ship_id',
      searchOperator: SearchOperators.InArray,
      searchValue: shipIds.join(),
    });

    if (onlyActive === true) {
      fields.push({
        fieldName: 'status',
        searchOperator: SearchOperators.Equals,
        searchValue: CVVActiveVisitModelClass.CVVActiveVisitStatus.Active,
      });
    }

    const appendDataModel = new CustomDataAppendDataModel();
    if (preloadedFields && preloadedFields.length > 0) {
      appendDataModel.appendData = true;
      appendDataModel.appendFields = preloadedFields;
    }

    return this.customDataContent.searchActiveCustomerDataByFields(
      'activevisit',
      fields,
      null,
      null,
      0,
      500,
      appendDataModel
    );
  }

  getActiveVisits(preloadedFields?: string[]): Observable<ResultWrapper<any>> {
    const searchField: CustomDataSearchFieldModel = {
      fieldName: 'status',
      searchOperator: SearchOperators.Equals,
      searchValue: CVVActiveVisitModelClass.CVVActiveVisitStatus.Active,
    };

    const appendDataModel = new CustomDataAppendDataModel();
    if (preloadedFields && preloadedFields.length > 0) {
      appendDataModel.appendData = true;
      appendDataModel.appendFields = preloadedFields;
    }

    return this.customDataContent.searchActiveCustomerDataByFields(
      'activevisit',
      [searchField],
      null,
      null,
      0,
      500,
      appendDataModel
    );
  }

  getVisitByPortVisitId(id: number): Observable<ResultWrapper<any>> {
    const searchField: CustomDataSearchFieldModel = {
      fieldName: '$nautical_portvisit_id',
      searchOperator: SearchOperators.Equals,
      searchValue: id,
    };

    return this.customDataContent.searchActiveCustomerDataByFields(
      'activevisit',
      [searchField],
      null,
      null,
      0,
      100
    );
  }

  getActivitieById(id: string): Observable<ResultWrapper<any>> {
    return this.customDataContent.getCustomerDataById('activevisit', id, null);
  }

  getActivitiesByShipIdsAndEmptyUCRN(
    shipIds: number[]
  ): Observable<ResultWrapper<any>> {
    const shipField: CustomDataSearchFieldModel = {
      fieldName: '$nautical_ship_id',
      searchOperator: SearchOperators.InArray,
      searchValue: shipIds.map(String).join(),
    };

    const ucrnField: CustomDataSearchFieldModel = {
      fieldName: '$nautical_portvisit_referencenumber',
      searchOperator: SearchOperators.Equals,
      searchValue: null,
    };

    return this.customDataContent
      .searchActiveCustomerDataByFields(
        'activity',
        [shipField, ucrnField],
        null,
        null,
        0,
        200
      )
      .pipe(
        map((response) => {
          if (response && response.result && response.result.length > 0) {
            for (let i = 0; i < response.result.length; i++) {
              response.result[i] = CVVActivityModel.fromApiModel(
                response.result[i]
              );
            }
          }

          return response;
        })
      );
  }

  getActivitiesByMovementIds(ids: number[]): Observable<ResultWrapper<any>> {
    const searchField: CustomDataSearchFieldModel = {
      fieldName: '$nautical_portmovement_id',
      searchOperator: SearchOperators.InArray,
      searchValue: ids.map(String).join(),
    };

    return this.customDataContent
      .searchActiveCustomerDataByFields(
        'activity',
        [searchField],
        null,
        null,
        0,
        500
      )
      .pipe(
        map((response) => {
          if (response && response.result && response.result.length > 0) {
            for (let i = 0; i < response.result.length; i++) {
              response.result[i] = CVVActivityModel.fromApiModel(
                response.result[i]
              );
            }
          }

          return response;
        })
      );
  }

  getActivitiesByGroupIds(
    groupIds: string[],
    additionalSearch: CustomDataSearchFieldModel[] = null
  ): Observable<ResultWrapper<any>> {
    let searchFiels = [];

    const searchFieldGroupId: CustomDataSearchFieldModel = {
      fieldName: 'groupId',
      searchOperator: SearchOperators.InArray,
      searchValue: groupIds.join(),
    };
    searchFiels.push(searchFieldGroupId);

    if (additionalSearch) {
      searchFiels = searchFiels.concat(additionalSearch);
    }

    return this.customDataContent
      .searchActiveCustomerDataByFields(
        'activity',
        searchFiels,
        null,
        null,
        0,
        500
      )
      .pipe(
        map((response) => {
          if (response && response.result && response.result.length > 0) {
            for (let i = 0; i < response.result.length; i++) {
              response.result[i] = CVVActivityModel.fromApiModel(
                response.result[i]
              );
            }
          }

          return response;
        })
      );
  }

  getActivities(
    additionalSearch: CustomDataSearchFieldModel[] = null
  ): Observable<ResultWrapper<any>> {
    return this.customDataContent
      .searchActiveCustomerDataByFields(
        'activity',
        additionalSearch,
        null,
        null,
        0,
        500
      )
      .pipe(
        map((response) => {
          if (response && response.result && response.result.length > 0) {
            for (let i = 0; i < response.result.length; i++) {
              response.result[i] = CVVActivityModel.fromApiModel(
                response.result[i]
              );
            }
          }

          return response;
        })
      );
  }

  getHistoryVisitsByDate(
    date: moment.Moment,
    preloadedFields?: string[]
  ): Observable<ResultWrapper<any>> {
    const statusField: CustomDataSearchFieldModel = {
      fieldName: 'status',
      searchOperator: SearchOperators.NotEquals,
      searchValue: CVVActiveVisitModelClass.CVVActiveVisitStatus.Active,
    };

    const timeField: CustomDataSearchFieldModel = {
      fieldName: '__LastEditedOn',
      searchOperator: SearchOperators.GreaterThan,
      searchValue: date.format('YYYY-MM-DDTHH:mm:ss'),
    };

    const appendDataModel = new CustomDataAppendDataModel();
    if (preloadedFields && preloadedFields.length > 0) {
      appendDataModel.appendData = true;
      appendDataModel.appendFields = preloadedFields;
    }

    return this.customDataContent.searchActiveCustomerDataByFields(
      'activevisit',
      [statusField, timeField],
      null,
      null,
      0,
      500,
      appendDataModel
    );
  }

  getHistoryActivitiesByDate(
    date: moment.Moment,
    additionalSearch: CustomDataSearchFieldModel[] = null
  ): Observable<ResultWrapper<any>> {
    let searchFiels = [];

    const timeField: CustomDataSearchFieldModel = {
      fieldName: '__LastEditedOn',
      searchOperator: SearchOperators.GreaterThan,
      searchValue: date.format('YYYY-MM-DDTHH:mm:ss'),
    };

    searchFiels.push(timeField);

    if (additionalSearch) {
      searchFiels = searchFiels.concat(additionalSearch);
    }

    return this.customDataContent
      .searchActiveCustomerDataByFields(
        'activity',
        searchFiels,
        null,
        null,
        0,
        5000
      )
      .pipe(
        map((response) => {
          if (response && response.result && response.result.length > 0) {
            for (let i = 0; i < response.result.length; i++) {
              response.result[i] = CVVActivityModel.fromApiModel(
                response.result[i]
              );
            }
          }

          return response;
        })
      );
  }

  getVisitsByIds(
    ids: string[],
    preloadedFields?: string[]
  ): Observable<ResultWrapper<any>> {
    const appendDataModel = new CustomDataAppendDataModel();
    if (preloadedFields && preloadedFields.length > 0) {
      appendDataModel.appendData = true;
      appendDataModel.appendFields = preloadedFields;
    }

    return this.customDataContent.getCustomerDataByIds(
      'activevisit',
      ids,
      appendDataModel
    );
  }

  getNotActiveVisitsByVisitIds(ids: number[]): Observable<ResultWrapper<any>> {
    const statusField: CustomDataSearchFieldModel = {
      fieldName: 'status',
      searchOperator: SearchOperators.NotEquals,
      searchValue: CVVActiveVisitModelClass.CVVActiveVisitStatus.Active,
    };

    const searchField: CustomDataSearchFieldModel = {
      fieldName: '$nautical_portvisit_id',
      searchOperator: SearchOperators.InArray,
      searchValue: ids.map(String).join(),
    };

    return this.customDataContent.searchActiveCustomerDataByFields(
      'activevisit',
      [statusField, searchField],
      null,
      null,
      0,
      500
    );
  }

  getShipRequirementsByShipIds(
    ids: number[],
    preloadedFields?: string[]
  ): Observable<ResultWrapper<any>> {
    const searchField: CustomDataSearchFieldModel = {
      fieldName: '$nautical_ship_id',
      searchOperator: SearchOperators.InArray,
      searchValue: ids.map(String).join(),
    };

    const appendDataModel = new CustomDataAppendDataModel();
    if (preloadedFields && preloadedFields.length > 0) {
      appendDataModel.appendData = true;
      appendDataModel.appendFields = preloadedFields;
    }

    return this.customDataContent.searchActiveCustomerDataByFields(
      'shipRequirements',
      [searchField],
      null,
      null,
      0,
      500,
      appendDataModel
    );
  }

  getVisitsByMovementIds(
    ids: number[],
    preloadedFields?: string[]
  ): Observable<ResultWrapper<any>> {
    const searchField: CustomDataSearchFieldModel = {
      fieldName: '$nautical_portmovement_id',
      searchOperator: SearchOperators.InArray,
      searchValue: ids.map(String).join(),
    };

    const appendDataModel = new CustomDataAppendDataModel();
    if (preloadedFields && preloadedFields.length > 0) {
      appendDataModel.appendData = true;
      appendDataModel.appendFields = preloadedFields;
    }

    return this.customDataContent.searchActiveCustomerDataByFields(
      'activevisit',
      [searchField],
      null,
      null,
      0,
      500,
      appendDataModel
    );
  }

  updateShipRequirements(
    model: CVVShipRequirementsModel
  ): Observable<ResultWrapper<any>> {
    return this.customDataContent.updateCustomerData(
      'shipRequirements',
      model.__Id,
      model
    );
  }

  updateVisit(
    activeVisit: CVVActiveVisitModelClass.CVVActiveVisitModel
  ): Observable<ResultWrapper<any>> {
    return this.customDataContent.updateCustomerData(
      'activevisit',
      activeVisit.__Id,
      activeVisit
    );
  }

  updateActivity(activity: CVVActivityModel): Observable<ResultWrapper<any>> {
    return this.customDataContent
      .updateCustomerData(
        'activity',
        activity.__Id,
        CVVActivityModel.toApiModel(activity)
      )
      .pipe(
        map((response) => {
          if (response && response.result) {
            response.result = CVVActivityModel.fromApiModel(response.result);
          }

          return response;
        })
      );
  }
}
