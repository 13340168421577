import { NgModule } from '@angular/core';

import { KPActivityTypeDisplayPipe } from './kp-activity-display.pipe';
import { KPSubtypeTypeDisplayPipe } from './kp-subttype-display.pipe';

@NgModule({
  declarations: [KPActivityTypeDisplayPipe, KPSubtypeTypeDisplayPipe],
  exports: [KPActivityTypeDisplayPipe, KPSubtypeTypeDisplayPipe],
})
export class CustomKPPipesModule {}
