import { Component, Input, OnDestroy } from '@angular/core';
import { AISMarkerModel } from '@seahorse/domain';
import { MapDataService } from '@seahorse/domain';
import * as moment from 'moment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ca-nautical-ship-track-display',
  templateUrl: 'nautical-ship-track-display.component.html',
})
export class NauticalShipTrackDisplayComponent implements OnDestroy {
  private subscriptions$ = Array<Subscription>();
  private _mmsi: string;
  private _selectedTimespan = 24;

  get selectedTimespan(): number {
    return this._selectedTimespan;
  }
  set selectedTimespan(value: number) {
    if (this._selectedTimespan !== value) {
      this._selectedTimespan = value;
      this.loadTrack();
    }
  }
  shipTrack: AISMarkerModel[] = [];

  @Input() get mmsi(): string {
    return this._mmsi;
  }
  set mmsi(input: string) {
    if (this._mmsi !== input) {
      this._mmsi = input;
      this.loadTrack();
    }
  }

  constructor(private mapDataService: MapDataService) {}

  ngOnDestroy() {
    this.subscriptions$.forEach((s) => s.unsubscribe());
  }

  loadTrack() {
    if (
      !this._mmsi ||
      this._mmsi.length !== 9 ||
      !this._selectedTimespan ||
      this._selectedTimespan <= 0
    ) {
      return;
    }

    const dateUntil = moment().format('YYYY-MM-DDTHH:mm:ss');
    const dateFrom = moment()
      .add(this._selectedTimespan * -1, 'hour')
      .format('YYYY-MM-DDTHH:mm:ss');

    this.subscriptions$.push(
      this.mapDataService
        .getTrackForMMSI(this._mmsi, dateFrom, dateUntil)
        .subscribe(
          (result) => {
            if (!result || result.length === 0) {
              return;
            }
            this.shipTrack = result;
          },
          (error) => {},
          () => {}
        )
    );
  }
}
