/* eslint-disable @angular-eslint/no-output-on-prefix */
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import { NauticalShipDataService, NauticalShipTypeCategory, ShipSearchModel } from '@seahorse/domain';
import { LayoutService } from '@seahorse/layout';
import { Subscription } from 'rxjs';
import { CollectionDisplay } from '../../../layout/models/collection-display.model';

@Component({
  selector: 'ca-nautical-ship-header',
  templateUrl: './nautical-ship-header.component.html',
})
export class NauticalShipHeaderComponent implements OnDestroy {
  private subscriptions$ = Array<Subscription>();
  previousSearchField: string = null;
  currentSearchField: string = null;
  simpleSearchFields: string[];
  shipTypeCategories: NauticalShipTypeCategory[];
  showAdvancedSearch = false;
  model = new ShipSearchModel(null);
  view = CollectionDisplay;
  sidebarOpen = false;
  CollectionDisplay = CollectionDisplay;

  @Input() searchResultCount: number = null;
  @Input() selectedView: CollectionDisplay = CollectionDisplay.Tiles;

  @Output() onNewButtonClicked = new EventEmitter();
  @Output() onSearchChanged = new EventEmitter<ShipSearchModel>();
  @Output() onViewChanged = new EventEmitter<CollectionDisplay>();
  @Output() onClear = new EventEmitter<void>();

  constructor(
    private nauticalShipDataService: NauticalShipDataService,
    private layoutService: LayoutService
  ) {
    this.getShipTypes();
    this.simpleSearchFields = ['name', 'fisheryNumber', 'imo', 'mmsi'];
    this.currentSearchField = this.simpleSearchFields[0];
    this.previousSearchField = this.simpleSearchFields[0];

    this.subscriptions$.push(
      this.layoutService.sidebarToggle$.subscribe(
        (sidebarOpen: boolean) => {
          this.sidebarOpen = sidebarOpen;
        },
      )
    );
  }

  ngOnDestroy() {
    this.subscriptions$.forEach((s) => s.unsubscribe());
  }

  searchShips() {
    this.onSearchChanged.emit(this.model);
    this.showAdvancedSearch = false;
  }

  simpleSearchChanged() {
    this.model[this.currentSearchField] = this.model[this.previousSearchField];
    this.model[this.previousSearchField] = null;
    this.previousSearchField = this.currentSearchField;
  }

  getShipTypes() {
    this.subscriptions$.push(
      this.nauticalShipDataService.getShipTypeCategories().subscribe(
        (response) => {
          this.shipTypeCategories = response.result;
        },
      )
    );
  }

  setSelectedView() {
    this.onViewChanged.emit(Number(this.selectedView));
  }

  clearForm() {
    this.model = new ShipSearchModel(null);
    this.showAdvancedSearch = true;
    this.onClear.emit();
  }

  newButtonClicked() {
    this.onNewButtonClicked.emit();
  }
}
