export const mimeTypes: { [extension: string]: string } = {
  '3g2': 'video/3gpp2',
  '3gp': 'video/3gpp',
  aac: 'audio/aac',
  aif: 'audio/x-aiff',
  aifc: 'audio/aiff',
  aiff: 'audio/aiff',
  appcache: 'text/cache-manifest',
  atom: 'application/atom+xml',
  au: 'audio/basic',
  avi: 'video/x-msvideo',
  bmp: 'image/bmp',
  css: 'text/css',
  csv: 'application/octet-stream',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  eml: 'message/rfc822',
  gif: 'image/gif',
  htm: 'text/html',
  html: 'text/html',
  jfif: 'image/jpeg',
  ico: 'image/x-icon',
  jpe: 'image/jpeg',
  jpeg: 'image/jpeg',
  jpg: 'image/jpeg',
  js: 'application/javascript',
  json: 'application/json',
  m3u: 'audio/x-mpegurl',
  m4a: 'audio/mp4',
  m4v: 'video/mp4',
  mid: 'audio/mid',
  midi: 'audio/mid',
  mov: 'video/quicktime',
  mp3: 'audio/mpeg',
  mp4: 'video/mp4',
  mpa: 'video/mpeg',
  mpe: 'video/mpeg',
  mpeg: 'video/mpeg',
  mpg: 'video/mpeg',
  mpv2: 'video/mpeg',
  msg: 'application/vnd.ms-outlook',
  ogg: 'video/ogg',
  ogv: 'video/ogg',
  p7s: 'application/pkcs7-signature',
  pdf: 'application/pdf',
  png: 'image/png',
  pps: 'application/vnd.ms-powerpoint',
  ppt: 'application/vnd.ms-powerpoint',
  pptm: 'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  ps: 'application/postscript',
  qt: 'video/quicktime',
  ra: 'audio/x-pn-realaudio',
  ram: 'audio/x-pn-realaudio',
  rtf: 'application/rtf',
  sheet: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  svg: 'image/svg+xml',
  svgz: 'image/svg+xml',
  swf: 'application/x-shockwave-flash',
  tif: 'image/tiff',
  tiff: 'image/tiff',
  txt: 'text/plain',
  wav: 'audio/wav',
  webm: 'video/webm',
  webp: 'image/webp',
  xhtml: 'application/xhtml+xml',
  xml: 'text/xml',
  xsl: 'text/xml',
  xslt: 'text/xml',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  zip: 'application/zip',
};
