<sh-control *ngIf="control" [control]="this">
  <ejs-multiselect
    [addTagOnBlur]="true"
    [allowCustomValue]="allowCustomValue"
    [attr.name]="formControlName"
    [dataSource]="options"
    [fields]="fieldMapping"
    [formControl]="control"
    [id]="formControlName"
    [placeholder]="placeholder"
    [maximumSelectionLength]="1"
    (valueChange)="emitOnChange($event)"
    mode="Box"
  ></ejs-multiselect>
</sh-control>
