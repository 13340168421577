<div class="modal-header">
  <h4 class="modal-title">
    {{
      (isNew ? 'companies.contact.addContact' : 'companies.contact.editContact')
        | translate
    }}
  </h4>
  <button closeModal type="button" class="btn btn-link text-dark">
    <i class="fa-solid fa-times fa-fw fa-sm"></i>
  </button>
</div>

<ul class="nav nav-tabs">
  <li class="nav-item">
    <button
      class="nav-link card-header"
      [class.font-weight-bold]="activeTab === ContactTabs.ContactInformation"
      (click)="setActiveTab(ContactTabs.ContactInformation)"
    >
      Contact information
    </button>
  </li>
  <li class="nav-item">
    <button
      class="nav-link card-header"
      [class.font-weight-bold]="activeTab === ContactTabs.Addresses"
      (click)="setActiveTab(ContactTabs.Addresses)"
    >
      {{ 'companies.address.addresses' | translate }}
    </button>
  </li>
</ul>

<div class="modal-body">
  <ca-contact-form
    [hidden]="activeTab === ContactTabs.Addresses"
    [model]="contact"
    [companyId]="companyId"
    [submitted]="isSubmitted"
  ></ca-contact-form>

  <div *ngIf="contact.companyAddresses && contact.companyAddresses.length > 0">
    <ca-company-addresses-list
      (onEvent)="onAction($event)"
      [hidden]="activeTab === ContactTabs.ContactInformation"
      [showHeader]="true"
      [addresses]="contact.companyAddresses"
    >
    </ca-company-addresses-list>
  </div>
  <div
    [hidden]="activeTab === ContactTabs.ContactInformation"
    *ngIf="!contact.companyAddresses || contact.companyAddresses.length === 0"
    class="text-muted text-center"
  >
    {{ 'companies.address.noAddresses' | translate }}
  </div>

  <div
    [hidden]="activeTab === ContactTabs.ContactInformation"
    class="text-right"
  >
    <button
      class="btn btn-outline-secondary btn-sm"
      *hasPermission="'companies.company_addresses_add'"
      type="button"
      (click)="selectAddress()"
    >
      <i class="fa-regular fa-plus"></i>
      {{ 'shared.terms.add' | translate }}
    </button>
  </div>
</div>

<div [hidden]="activeTab === ContactTabs.Addresses" class="modal-footer d-flex">
  <div class="flex-grow-1">
    <button closeModal type="button" class="btn btn-block btn-link">
      {{ 'shared.terms.cancel' | translate }}
    </button>
  </div>
  <div class="flex-grow-1">
    <button (click)="save()" type="button" class="btn btn-block btn-success">
      {{ 'shared.terms.save' | translate }}
    </button>
  </div>
</div>
