import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'confirm',
  template: `<div class="modal-header">
      <h4 class="modal-title">{{ 'shared.terms.confirm' | translate }}</h4>
      <button type="button" class="close" (click)="activeModal.close(false)">
        &times;
      </button>
    </div>
    <div class="modal-body">
      <p *ngIf="!additionalText">{{ 'shared.terms.areYouSure' | translate }}</p>
      <div *ngIf="additionalText">{{ additionalText }}</div>
    </div>
    <div class="modal-footer">
      <button
        type="submit"
        class="btn m-btn--pill btn-primary"
        (click)="activeModal.close(true)"
      >
        {{ 'shared.terms.ok' | translate }}
      </button>
      <button
        type="button"
        class="btn m-btn--pill btn-default"
        (click)="activeModal.close(false)"
      >
        {{ 'shared.terms.cancel' | translate }}
      </button>
    </div> `,
})
export class ConfirmComponent {
  static additionalText: string;
  constructor(public activeModal: NgbActiveModal) {}

  get additionalText() {
    return ConfirmComponent.additionalText;
  }
}
