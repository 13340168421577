import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TaskDefinitionListModel } from '../../../../../task-definition-list/models/task-definition-list.model';

@Component({ templateUrl: './task-definition-list-modal.component.html' })
export class TaskDefinitionListModalComponent {
  @Input() taskDefinitionList: TaskDefinitionListModel;

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  onAddOrEditTaskDefinitionList: EventEmitter<TaskDefinitionListModel>;

  constructor() {
    this.onAddOrEditTaskDefinitionList =
      new EventEmitter<TaskDefinitionListModel>();
    this.taskDefinitionList = new TaskDefinitionListModel();
  }
}
