import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import * as _ from 'underscore';

import { ProxyServices } from '../../core/services/proxy.service';
import { NameFromUserIdPipe } from '../../shared/pipes/nameFromUserId.pipe';
import { FieldDefinitionModel } from '@seahorse/domain';
import {
  FieldTypeDefinitionModel,
  FieldType
} from '@seahorse/domain';
import { RenderOptionModel } from '../../layout/models/table.model';
import { PipeModel, KeyValuePair } from '@seahorse/common';
import { FieldValuePipe } from '@seahorse/temp';

@Pipe({ name: 'customValueDisplay' })
export class CustomValueDisplayPipe implements PipeTransform {
  fieldTypeDefinitions: FieldTypeDefinitionModel[] = [];

  constructor(
    public proxyServices: ProxyServices,
    public fieldValuePipe: FieldValuePipe,
    public nameFromUserIdPipe: NameFromUserIdPipe
  ) {
    this.fieldTypeDefinitions = this.proxyServices.fieldTypeDefinitions;
  }

  transform(
    input: any,
    field: FieldDefinitionModel,
    renderOption: RenderOptionModel
  ): Observable<any> {
    if (field) {
      // TODO use the system code instead of use the id of the field definition
      const fieldType = this.fieldTypeDefinitions.find(
        (x) => x.id === field.fieldTypeDefinitionId
      );

      // user field
      if (
        (fieldType && fieldType.systemCode === 'user') ||
        (renderOption &&
          renderOption.pipes &&
          _.some(renderOption.pipes, (pipe) => pipe.name === 'nameFromUserId'))
      ) {
        return this.nameFromUserIdPipe.transform(
          typeof input === 'string' ? input.split(',') : input,
          ''
        );
      } else {
        return this.handleFieldType(
          input,
          !field.fieldType ? fieldType.fieldType : field.fieldType,
          field,
          renderOption ? renderOption.pipes : null
        );
      }
    } else if (renderOption) {
      return this.handleFieldType(
        input,
        renderOption.fieldType,
        null,
        renderOption.pipes
      );
    }

    return input;
  }

  handleFieldType(
    input: any,
    fieldType: FieldType,
    field: FieldDefinitionModel,
    pipes: PipeModel[]
  ) {
    return new Observable<any>((subscriber) => {
      let result = input;
      if (input !== undefined && input !== null) {
        if (fieldType !== undefined && fieldType !== null) {
          switch (fieldType) {
            // datetime field
            // boolean field
            case FieldType.Boolean:
            case FieldType.Date:
            case FieldType.DateTime:
              result = this.fieldValuePipe.transform(input, fieldType, pipes);
              break;

            case FieldType.List:
              let items = [];
              if (!pipes) {
                pipes = [];
              }
              pipes.push(
                new PipeModel('keyValue', {
                  onlyValue: true,
                  displayFields: ['value'],
                })
              );

              const itemsTypeName = input.constructor.name;
              switch (itemsTypeName) {
                case 'Array':
                  if (field && field.fieldRule && field.fieldRule.items) {
                    items = _.map(input, (s) => {
                      let item = _.find(
                        field.fieldRule.items,
                        (item) => item.key.toLowerCase() === s.toLowerCase()
                      );
                      if (item === undefined || item === null) {
                        item = new KeyValuePair(input, input);
                      }
                      return item;
                    });
                  } else {
                    items = _.map(input, (s) => {
                      return new KeyValuePair(input, input);
                    });
                  }

                  const output = _.map(items, (o) => {
                    return this.fieldValuePipe.transform(o, fieldType, pipes);
                  });
                  result = output.join(', ');
                  break;

                case 'Object':
                  result = this.fieldValuePipe.transform(
                    input,
                    fieldType,
                    pipes
                  );
                  break;

                default:
                  let item = null;
                  if (field.fieldRule && field.fieldRule.items) {
                    item = _.find(
                      field.fieldRule.items,
                      (item) =>
                        item.key.toLowerCase() ===
                        input.toString().toLowerCase()
                    );
                  }

                  if (item === undefined || item === null) {
                    item = new KeyValuePair(input, input);
                  }
                  result = this.fieldValuePipe.transform(
                    item,
                    fieldType,
                    pipes
                  );
                  break;
              }
              break;

            case FieldType.LinkedObject:
            case FieldType.LinkedObjects:
              const typeName = input.constructor.name;
              switch (typeName) {
                case 'Object':
                  result = this.handleLinkedObject(input, field, pipes);

                  break;
                case 'Array':
                  const output = _.map(input, (o) => {
                    return this.handleLinkedObject(o, field, pipes);
                  });
                  result = output.join(', ');
                  break;
                default:
                  result = this.fieldValuePipe.transform(
                    input,
                    fieldType,
                    pipes
                  );
                  break;
              }
              break;

            default:
              result = this.fieldValuePipe.transform(input, fieldType, pipes);
              break;
          }
        }
      }

      subscriber.next(result);
      subscriber.complete();
    });
  }

  handleLinkedObject(input, field: FieldDefinitionModel, pipes: PipeModel[]) {
    const typeName = input.constructor.name;
    switch (typeName) {
      case 'Object':
        if (!pipes || pipes.length === 0) {
          pipes = [];
          let mapping = null;
          let additionalDataModel = null;

          if (field) {
            if (field.additionalData !== undefined) {
              additionalDataModel = JSON.parse(field.additionalData);
            }

            if (additionalDataModel !== null) {
              mapping =
                this.proxyServices.objectKeyMapping[
                  additionalDataModel.mappingKey
                ];
            }

            if (!mapping) {
              mapping = this.proxyServices.objectKeyMapping[field.systemCode];
            }
          }

          pipes.push(
            new PipeModel(
              'keyValue',
              mapping ? { displayFields: mapping.displayFields } : null
            )
          );
        }

        return this.fieldValuePipe.transform(input, null, pipes);
    }
    return input;
  }
}
