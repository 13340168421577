import { Injectable } from '@angular/core';
import { ServiceConnectionSubscriptionModel } from '../service-connections/models/service-connection-subscription.model';
import { ServiceConnectionsDataService } from '../service-connections/services/service-connections.service';
import { Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';

import { SnelstartArticle } from './models/snelstart-article';
import { SnelstartBtwtarief } from './models/snelstart-btwtarief';
import { SnelstartGrootboek } from './models/snelstart-grootboek';
import { SnelstartInvoice } from './models/snelstart-invoice';
import { SnelstartKostenPlaats } from './models/snelstart-kostenplaats';
import { SnelstartOrder } from './models/snelstart-order';
import { SnelstartRelationship } from './models/snelstart-relationship';
import { ResultWrapper } from '@seahorse/common';
import { DataContextService } from '@seahorse/common';

@Injectable({
  providedIn: 'root',
})
export class SnelstartService {
  snelstartSubscription: ServiceConnectionSubscriptionModel;

  constructor(
    private dataContext: DataContextService,
    private serviceConnectionDataService: ServiceConnectionsDataService
  ) {
    this.snelstartSubscription =
      this.serviceConnectionDataService.subscriptions.find(
        (x) => x.serviceConnection.systemCode === 'snelstart'
      );
  }

  getArticles(
    search: string = null
  ): Observable<ResultWrapper<SnelstartArticle[]>> {
    return this.serviceConnectionDataService
      .getTokenForSubscription(
        this.snelstartSubscription.serviceConnectionId,
        this.snelstartSubscription.id
      )
      .pipe(
        concatMap((token) => {
          if (search) {
            const query =
              '(substringof(' +
              "'" +
              search +
              "', Omschrijving) eq true) or (substringof(" +
              "'" +
              search +
              "', Artikelcode) eq true)";

            return this.dataContext.post<SnelstartArticle[]>(
              `snelstart/artikelen?$filter=${query}`,
              token.result
            );
          }

          return this.dataContext.post<SnelstartArticle[]>(
            `snelstart/artikelen`,
            token.result
          );
        })
      );
  }

  getArticle(id: string): Observable<ResultWrapper<SnelstartArticle>> {
    return this.serviceConnectionDataService
      .getTokenForSubscription(
        this.snelstartSubscription.serviceConnectionId,
        this.snelstartSubscription.id
      )
      .pipe(
        concatMap((token) => {
          return this.dataContext.post<SnelstartArticle>(
            `snelstart/artikelen/${id}`,
            token.result
          );
        })
      );
  }

  getGrootboeken(
    search: string = null
  ): Observable<ResultWrapper<SnelstartGrootboek[]>> {
    return this.serviceConnectionDataService
      .getTokenForSubscription(
        this.snelstartSubscription.serviceConnectionId,
        this.snelstartSubscription.id
      )
      .pipe(
        concatMap((token) => {
          if (search) {
            const query = isNaN(+search)
              ? `(substringof('${search}',Omschrijving) eq true)`
              : `(substringof('${search}',Omschrijving) eq true) or Nummer eq ${search}`;

            return this.dataContext.post<SnelstartGrootboek[]>(
              `snelstart/grootboeken?$filter=${query}`,
              token.result
            );
          }

          return this.dataContext.post<SnelstartGrootboek[]>(
            `snelstart/grootboeken`,
            token.result
          );
        })
      );
  }

  getGrootboekById(id: string): Observable<ResultWrapper<SnelstartGrootboek>> {
    return this.serviceConnectionDataService
      .getTokenForSubscription(
        this.snelstartSubscription.serviceConnectionId,
        this.snelstartSubscription.id
      )
      .pipe(
        concatMap((token) => {
          return this.dataContext.post<SnelstartGrootboek>(
            `snelstart/grootboeken/${id}`,
            token.result
          );
        })
      );
  }

  getBtwtarieven(): Observable<ResultWrapper<SnelstartBtwtarief[]>> {
    return this.serviceConnectionDataService
      .getTokenForSubscription(
        this.snelstartSubscription.serviceConnectionId,
        this.snelstartSubscription.id
      )
      .pipe(
        concatMap((token) => {
          return this.dataContext.post<SnelstartBtwtarief[]>(
            `snelstart/btwtarieven`,
            token.result
          );
        })
      );
  }

  getKostenPlaatsen(): Observable<ResultWrapper<SnelstartKostenPlaats[]>> {
    return this.serviceConnectionDataService
      .getTokenForSubscription(
        this.snelstartSubscription.serviceConnectionId,
        this.snelstartSubscription.id
      )
      .pipe(
        concatMap((token) => {
          return this.dataContext.post<SnelstartKostenPlaats[]>(
            `snelstart/kostenplaatsen`,
            token.result
          );
        })
      );
  }

  getKostenPlaatsById(
    id: string
  ): Observable<ResultWrapper<SnelstartKostenPlaats>> {
    return this.serviceConnectionDataService
      .getTokenForSubscription(
        this.snelstartSubscription.serviceConnectionId,
        this.snelstartSubscription.id
      )
      .pipe(
        concatMap((token) => {
          return this.dataContext.post<SnelstartKostenPlaats>(
            `snelstart/kostenplaatsen/${id}`,
            token.result
          );
        })
      );
  }

  getRelationships(
    debtorNumbers: string[]
  ): Observable<ResultWrapper<SnelstartRelationship[]>> {
    return this.serviceConnectionDataService
      .getTokenForSubscription(
        this.snelstartSubscription.serviceConnectionId,
        this.snelstartSubscription.id
      )
      .pipe(
        concatMap((token) => {
          const values = new Array<string>();

          for (const num in debtorNumbers) {
            values.push(`Relatiecode eq ${+debtorNumbers[num]}`);
          }
          const query = '$filter=' + values.join(' or ');

          return this.dataContext.post<SnelstartRelationship[]>(
            `snelstart/relaties?${query}`,
            token.result
          );
        })
      );
  }

  getOrderById(id: string): Observable<ResultWrapper<SnelstartOrder>> {
    return this.serviceConnectionDataService
      .getTokenForSubscription(
        this.snelstartSubscription.serviceConnectionId,
        this.snelstartSubscription.id
      )
      .pipe(
        concatMap((token) => {
          return this.dataContext.post<SnelstartOrder>(
            `snelstart/verkooporders/${id}`,
            token.result
          );
        })
      );
  }

  getInvoiceById(id: string): Observable<ResultWrapper<SnelstartInvoice>> {
    return this.serviceConnectionDataService
      .getTokenForSubscription(
        this.snelstartSubscription.serviceConnectionId,
        this.snelstartSubscription.id
      )
      .pipe(
        concatMap((token) => {
          return this.dataContext.post<SnelstartInvoice>(
            `snelstart/verkoopfacturen/${id}`,
            token.result
          );
        })
      );
  }
}
