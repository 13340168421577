import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  forwardRef,
  Input,
  ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { SeahorseTemplateDirective } from '@seahorse/common';
import { BaseControl } from '../base.control';
import { NewItemConfig, ComboBoxOption } from './combo-box.model';
import { ComboBoxComponent } from '@syncfusion/ej2-angular-dropdowns';

@Component({
  selector: 'sh-combo-box',
  templateUrl: './combo-box.control.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ComboBoxControl),
      multi: true,
    },
    {
      provide: BaseControl,
      useExisting: forwardRef(() => ComboBoxControl),
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComboBoxControl extends BaseControl<string | number | null> {
  @ContentChild(SeahorseTemplateDirective) template?: SeahorseTemplateDirective;
  @Input() options: ComboBoxOption[] = [];
  @Input() newItemConfig: NewItemConfig | undefined;
  @Input() isLoading = false;

  fieldMapping = { text: 'label', value: 'value' };

  get templateRef() {
    return this.template?.templateRef;
  }

  @ViewChild('object') object: ComboBoxComponent | undefined;
  setInputAsValue() {
    if (this.object) {
      const newOptionLabel =
        this.object.element.getElementsByTagName('input')[0].value;
      this.object.value = newOptionLabel;
      this.object.hidePopup();
    }
  }
}
