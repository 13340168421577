<table class="table table-clickable">
  <thead>
    <tr>
      <th>{{ 'systemMessage.release.releasedOn' | translate }}</th>
      <th>{{ 'systemMessage.release.endsOn' | translate }}</th>
      <th>{{ 'systemMessage.release.channel' | translate }}</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let release of systemMessageReleases" class="card-body-group">
      <td (click)="onEvent.emit({ action: 'editRelease', data: release })">
        {{ release.releasedOn | utcToLocalDatePipe }}
      </td>
      <td
        (click)="onEvent.emit({ action: 'editRelease', data: release })"
        class="text-truncate"
      >
        {{ release.endsOn | utcToLocalDatePipe }}
      </td>
      <td
        (click)="onEvent.emit({ action: 'editRelease', data: release })"
        class="text-truncate"
      >
        {{ release.systemMessageChannel.description }}
      </td>
      <td class="btn-list">
        <div class="dropdown">
          <button
            type="button"
            class="btn btn-light dropdown-toggle"
            id="rowMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis-v fa-fw"></i>
          </button>
          <div class="dropdown-menu" aria-labelledby="rowMenuButton">
            <a
              class="dropdown-item"
              (click)="onEvent.emit({ action: 'deleteRelease', data: release })"
            >
              <i class="fa-solid fa-trash fa-fw"></i>
              {{ 'shared.terms.delete' | translate }}
            </a>
          </div>
        </div>
      </td>
    </tr>
  </tbody>
</table>
