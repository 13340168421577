<div class="card">
  <div class="card-header">
    {{ 'map.myFleet' | translate }}
    <div class="btn-list">
      <button
        *hasPermission="'ships.fleetlist_admin'"
        (click)="openAddGroupModal()"
        class="btn btn-outline-secondary btn-sm"
        type="button"
      >
        <i class="fa-regular fa-plus fa-fw"></i>
      </button>
    </div>
  </div>

  <div class="text-muted p-3" *ngIf="!groups || groups.length === 0">
    {{ 'map.fleetNotFound' | translate }}
  </div>

  <div *ngIf="groups">
    <div class="card mb-2 ml-2 mr-2 mt-2" *ngFor="let group of groups">
      <div
        class="card-header cursor-pointer d-flex justify-content-between align-items-center p-2"
        [class.active-header]="group.isActive"
        role="tab"
        (click)="toggleActiveGroup(group)"
      >
        <h6 class="m-1">
          <span
            >{{ group.name }} ({{
              group.shipFleetGroups ? group.shipFleetGroups.length : 0
            }})</span
          >
        </h6>

        <!-- <i class="fa-regular fa-chevron-down" *ngIf="!group.isActive"></i>
        <i class="fa-regular fa-chevron-up" *ngIf="group.isActive"></i> -->

        <div class="dropdown p-0" *hasPermission="'ships.fleetlist_admin'">
          <button
            type="button"
            class="btn btn-light"
            id="rowMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis-v fa-fw"></i>
          </button>
          <div class="btn dropdown-menu" aria-labelledby="rowMenuButton">
            <button
              class="dropdown-item"
              (click)="addShipToFleetGroup(group.id)"
            >
              <i class="fa-regular fa-plus fa-fw"></i>
              {{ 'shared.terms.add' | translate }}
            </button>
            <button class="dropdown-item" (click)="editGroup($event, group)">
              <i class="fa-regular fa-pencil-alt fa-fw"></i>
              Edit
            </button>
            <button class="dropdown-item" (click)="deleteGroup($event, group)">
              <i class="fa-solid fa-trash fa-fw"></i>
              {{ 'shared.terms.delete' | translate }}
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="group.isActive">
        <ul class="list-group">
          <li
            *ngFor="let ship of group.shipFleetGroups"
            class="list-group-item d-flex justify-content-between align-items-center p-2"
          >
            <span
              (click)="openVessel(ship.ship)"
              class="ml-2 mb-2 mt-2 cursor-pointer w-100 text-uppercase"
              >{{ ship.ship.name }}</span
            >

            <div
              class="dropdown p-0 delete-vessel"
              *hasPermission="'ships.fleetlist_admin'"
            >
              <button
                type="button"
                class="btn btn-light"
                id="rowMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="fa-solid fa-ellipsis-v fa-fw"></i>
              </button>
              <div class="btn dropdown-menu" aria-labelledby="rowMenuButton">
                <button
                  class="dropdown-item"
                  (click)="deleteVessel(ship.id, group)"
                >
                  <i class="fa-solid fa-trash fa-fw"></i>
                  {{ 'shared.terms.delete' | translate }}
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div
    class="card mb-2 mt-2"
    *ngIf="shipsWithoutGroup && shipsWithoutGroup.length > 0"
  >
    <div
      class="card-header cursor-pointer d-flex justify-content-between align-items-center"
    >
      <h6 class="mt-1 mb-1">
        <span
          >No group ({{
            shipsWithoutGroup ? shipsWithoutGroup.length : 0
          }})</span
        >
      </h6>
    </div>
    <div>
      <ul class="list-group">
        <ng-container *ngFor="let ship of shipsWithoutGroup">
          <li
            *ngIf="ship && ship.ship"
            class="list-group-item d-flex justify-content-between align-items-center"
          >
            <span
              (click)="openVessel(ship.ship)"
              class="ml-2 mb-2 mt-2 cursor-pointer w-100 text-uppercase"
              >{{ ship.ship.name }}</span
            >

            <div
              class="dropdown p-0 delete-vessel"
              *hasPermission="'ships.fleetlist_admin'"
            >
              <button
                type="button"
                class="btn btn-light dropdown-toggle"
                id="rowMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="fa-solid fa-ellipsis-v fa-fw"></i>
              </button>
              <div class="btn dropdown-menu" aria-labelledby="rowMenuButton">
                <button
                  class="dropdown-item"
                  (click)="deleteVesselWithoutGroup(ship.id)"
                >
                  <i class="fa-solid fa-trash fa-fw"></i>
                  {{ 'shared.terms.delete' | translate }}
                </button>
              </div>
            </div>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
</div>
