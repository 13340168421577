<div class="card">
  <div class="card-header">{{ 'map.route.calculator' | translate }}</div>
  <div class="card-body">
    <form class="m-0" (ngSubmit)="calculate()">
      <div class="form-group">
        <label
          ><b>{{ 'map.route.from' | translate }}</b></label
        >
        <ca-nautical-port-picker
          [(nauticalPort)]="fromPort"
          [onlyWithGeography]="true"
        >
        </ca-nautical-port-picker>
      </div>
      <div class="form-group">
        <label
          ><b>{{ 'map.route.to' | translate }}</b></label
        >
        <ca-nautical-port-picker
          [(nauticalPort)]="toPort"
          [onlyWithGeography]="true"
        >
        </ca-nautical-port-picker>
      </div>
      <div class="form-group">
        <label
          ><b>{{ 'map.route.speed' | translate }}</b></label
        >
        <div class="input-group">
          <input
            type="number"
            class="form-control"
            name="speed"
            [(ngModel)]="speed"
          />
          <span class="input-group-append">
            <span class="input-group-text">kts</span>
          </span>
        </div>
      </div>
      <button type="submit" class="btn btn-primary btn-block">
        {{ 'map.route.calculate' | translate }}
      </button>
    </form>

    <ng-container *ngIf="routes !== null">
      <h3 class="mt-3">{{ 'map.route.routes' | translate }}</h3>
      <div class="text-muted text-center" *ngIf="routes.length === 0">
        {{ 'map.route.notFound' | translate }}
      </div>
      <div class="tiles">
        <div class="tile" *ngFor="let route of routes">
          <div>
            <b>{{ 'map.route.distance' | translate }}:</b>
            {{ route.distance | number: '1.0-0' }}nm
          </div>
          <div>
            <b>{{ 'nautical.terms.eta' | translate }}:</b>
            {{ route.eta | date: 'dd-MM-yyyy HH:mm' }}
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
