import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ZeehavenIJmuidenMovementModel } from '../../models/zeehaven-ijmuiden-movement.model';

@Component({ templateUrl: 'anchored-picker-modal.component.html' })
export class AnchoredPickerModalComponent {
  @Input() anchored: ZeehavenIJmuidenMovementModel['anchored'];
  @Input() anchoredMessage: ZeehavenIJmuidenMovementModel['anchoredMessage'];

  @Output() onPickAnchored: EventEmitter<{
    anchored: ZeehavenIJmuidenMovementModel['anchored'];
    anchoredMessage: ZeehavenIJmuidenMovementModel['anchoredMessage'];
  }>;

  constructor() {
    this.anchored = null;
    this.anchoredMessage = null;
    this.onPickAnchored = new EventEmitter<{
      anchored: ZeehavenIJmuidenMovementModel['anchored'];
      anchoredMessage: ZeehavenIJmuidenMovementModel['anchoredMessage'];
    }>();
  }

  setNull() {
    this.anchoredMessage = null;
  }
}
