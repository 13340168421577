<div class="vesselPopup">
  <header class="popup-header">
    <h1 id="popup-vessel-name" style="font-size: 18px">
      {{ marker.options.aisInfo.name }}
    </h1>
  </header>
  <div class="d-flex popup-content">
    <div
      class="d-flex flex-column flex-grow-1"
      *ngIf="attributes && attributes.length > 0"
    >
      <div class="d-flex" *ngFor="let attribute of attributes">
        <label class="text-muted" style="min-width: 100px">{{
          attribute.fieldName
        }}</label>
        <span [innerHtml]="attribute.fieldValue"></span>
      </div>
      <h5 class="d-flex mb-2">
        {{ 'nautical.visit.portVisit' | translate | uppercase }}
      </h5>
      <sh-loading-indicator
        *ngIf="isLoading"
        class="text-left d-block"
      ></sh-loading-indicator>
      <div class="d-flex mb-2" *ngIf="!isLoading && visitData.length === 0">
        <span>{{ 'nautical.visit.notFound' | translate }}</span>
      </div>
      <div class="d-flex" *ngFor="let data of visitData">
        <label class="text-muted" style="min-width: 100px">{{
          data.fieldName
        }}</label>
        <span [innerHtml]="data.fieldValue"></span>
      </div>

      <div class="d-flex" *ngFor="let eta of etas">
        <label class="text-muted" style="min-width: 100px"
          >ETA {{ eta.name }}</label
        >
        <span>{{ eta.eta }}</span>
      </div>
    </div>

    <ca-map-popup-actions
      [ship]="ship"
      [aisInfo]="marker.options.aisInfo"
      [activeVisit]="activeVisit"
    >
    </ca-map-popup-actions>
  </div>
</div>
