import { Component, Input, OnDestroy } from '@angular/core';
import {
  NauticalShipModel,
  NauticalShipAttributeModel,
  CoordinatePointModel,
} from '@seahorse/domain';
import { NauticalShipDataService } from '@seahorse/domain';

import * as _ from 'underscore';
import { NauticalVisitDataService } from '@seahorse/domain';
import { MapDataService } from '@seahorse/domain';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ca-nautical-ship-summary',
  templateUrl: './nautical-ship-summary.component.html',
})
export class NauticalShipSummaryComponent implements OnDestroy {
  private subscriptions$ = Array<Subscription>();
  shipPosition: CoordinatePointModel = null;
  attributes: NauticalShipAttributeModel[] = [];
  isLoading = false;
  portVisitCount: any = '';
  private _ship: NauticalShipModel;

  @Input() showNavigation = true;
  @Input() showPhoto = true;
  @Input() set ship(value: NauticalShipModel) {
    if (!value || !value.id) {
      this.clearData(); // id is not set, clear all data
    } else {
      const keys = _.keys(value);
      if (keys.length === 1) {
        // when the object has only id property, need to load ship data
        if (!this._ship || value.id !== this._ship.id) {
          this.loadShipData(value.id);
        }
      } else {
        // otherwise the ship data is already loaded, set ship data
        this.setShipData(value);
      }
    }
  }
  get ship(): NauticalShipModel {
    return this._ship;
  }

  @Input() set shipId(value: string) {
    if (!value) {
      this.clearData();
    } else {
      this.loadShipData(value);
    }
  }

  constructor(
    private shipData: NauticalShipDataService,
    private visitData: NauticalVisitDataService,
    private mapDataService: MapDataService
  ) {}

  ngOnDestroy() {
    this.subscriptions$.forEach((s) => s.unsubscribe());
  }

  clearData() {
    this._ship = null;
    this.shipPosition = null;
    this.attributes = [];
    this.portVisitCount = '';
  }

  loadShipData(id: string) {
    this.isLoading = true;
    this.subscriptions$.push(
      this.shipData.getById(id).subscribe(
        (result) => {
          if (!result) {
            this.clearData();
          } else {
            this.setShipData(result);
          }
        },
        (error) => {
          this.ship = null;
        },
        () => {
          this.isLoading = false;
        }
      )
    );
  }

  setAttributes(categories: string[]) {
    if (!this.ship || !this.ship.shipAttributes) {
      return;
    }
    this.attributes = _.filter(
      this.ship.shipAttributes,
      (attr) => categories.indexOf(attr.category) > -1
    );
  }

  setShipData(value: NauticalShipModel) {
    this._ship = value; // assume the value is not null
    this.attributes = [];
    this.setAttributes(['dimensions']);

    this.subscriptions$.push(
      this.visitData.getPortVisitCountForShip(value.id).subscribe(
        (result) => {
          this.portVisitCount = result;
        },
        (error) => {},
        () => {}
      )
    );

    this.subscriptions$.push(
      this.mapDataService.getMarkerForMMSI(value.mmsi).subscribe(
        (response) => {
          if (response && response.latitude && response.longitude) {
            const point = new CoordinatePointModel();
            point.latitude = response.latitude;
            point.longitude = response.longitude;
            this.shipPosition = point;
          }
        },
        (error) => {},
        () => {}
      )
    );
  }
}
