<div class="card">
  <div class="card-header">
    {{ 'invoicingFlows.flow.stages' | translate }}
    <div class="btn-list">
      <button
        [disabled]="loadingFlow"
        (click)="onEvent.emit({ action: 'addStage' })"
        type="button"
        class="btn btn-outline-secondary btn-sm"
      >
        <i class="fa-regular fa-plus fa-fw"></i>
        {{ 'shared.terms.add' | translate }}
      </button>
    </div>
  </div>
  <ng-container
    *ngIf="stages.length > 0; then showStages; else noStages"
  ></ng-container>

  <ng-template #showStages>
    <div class="card-body p-2">
      <div class="card-deck m-n1">
        <div
          *ngFor="let stage of stages"
          class="card m-1"
          style="min-width: 200px"
        >
          <div class="card-header">
            <span class="text-uppercase">{{ stage.name }}</span>
          </div>
          <div class="card-body">
            <div class="card-body-group">
              <dl class="row">
                <dt class="col-12 col-md-6">
                  {{ 'invoicingFlows.stage.stageType' | translate }}
                </dt>
                <dd class="col-12 col-md-6">
                  {{ stage.stageType | stageType }}
                </dd>
                <dt class="col-12 col-md-6">
                  {{ 'invoicingFlows.stage.manualApproval' | translate }}
                </dt>
                <dd class="col-12 col-md-6">
                  <i
                    [ngClass]="
                      stage.manualApproval
                        ? 'fa-solid fa-check fa-fw text-success'
                        : 'fa-solid fa-times fa-fw text-danger'
                    "
                  ></i>
                </dd>
                <ng-container *ngIf="stage.previousStageId">
                  <dt class="col-12 col-md-6">
                    {{
                      'invoicingFlows.stage.allPreviousMustSucceed' | translate
                    }}
                  </dt>
                  <ng-container *ngFor="let previousStage of stages">
                    <dd
                      *ngIf="previousStage.id == stage.previousStageId"
                      class="col-12 col-md-6"
                    >
                      <button
                        [disabled]="loadingFlow"
                        (click)="
                          onEvent.emit({
                            action: 'viewStage',
                            data: previousStage.id
                          })
                        "
                        type="button"
                        class="btn btn-link p-0 border-0 m-0"
                      >
                        <i class="fa-regular fa-search-dollar fa-fw"></i>
                        {{ previousStage.name }}
                      </button>
                    </dd>
                  </ng-container>
                  <dt class="col-12 col-md-6">
                    {{ 'invoicingFlows.stage.previousStageId' | translate }}
                  </dt>
                  <dd class="col-12 col-md-6">
                    <i
                      [ngClass]="
                        stage.allPreviousMustSucceed
                          ? 'fa-solid fa-check fa-fw text-success'
                          : 'fa-solid fa-times fa-fw text-danger'
                      "
                    ></i>
                  </dd>
                </ng-container>
              </dl>
            </div>
          </div>
          <ul class="summary-buttons">
            <li>
              <button
                [disabled]="loadingFlow"
                (click)="onEvent.emit({ action: 'viewStage', data: stage.id })"
                type="button"
                class="summary-button w-100"
              >
                <i class="fa-regular fa-search-dollar"></i>
                <span class="summary-button-text">{{
                  'invoicingFlows.flowDetails.viewStage' | translate
                }}</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #noStages>
    <div *ngIf="!loadingFlow" class="card-body">
      <span class="text-muted">{{
        'invoicingFlows.flowDetails.noStages' | translate
      }}</span>
    </div>
  </ng-template>

  <sh-loading-indicator
    *ngIf="loadingFlow"
    class="d-block text-center"
  ></sh-loading-indicator>
</div>
