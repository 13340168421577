import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataContextService } from '@seahorse/common';
import { ResultWrapper } from '@seahorse/common';
import { SystemMessageReleaseModel } from '../models/system-message-release.model';
import { SystemMessageChannelModel } from '../models/system-message-channel.model';
import { DataService } from '@seahorse/domain';

@Injectable()
export class SystemMessageReleaseService
  implements DataService<SystemMessageReleaseModel>
{
  constructor(private dataContext: DataContextService) {}

  search(
    searchQuery: string = null
  ): Observable<ResultWrapper<SystemMessageReleaseModel[]>> {
    return this.dataContext.get<SystemMessageReleaseModel[]>(
      `su/systemmessage/release/search?searchTerm=${searchQuery}`
    );
  }

  getAllBySystemMessageId(
    messageId: number
  ): Observable<ResultWrapper<SystemMessageReleaseModel[]>> {
    return this.dataContext.get<SystemMessageReleaseModel[]>(
      `su/systemmessage/release/bymessageid/${messageId}`
    );
  }

  getAllForServiceWindows(): Observable<
    ResultWrapper<SystemMessageReleaseModel[]>
  > {
    return this.dataContext.get<SystemMessageReleaseModel[]>(
      `su/systemmessage/release/servicewindow`
    );
  }

  getById(id: number): Observable<ResultWrapper<SystemMessageReleaseModel>> {
    return this.dataContext.get<SystemMessageReleaseModel>(
      `su/systemmessage/release/${id}`
    );
  }

  add(
    model: SystemMessageReleaseModel
  ): Observable<ResultWrapper<SystemMessageReleaseModel>> {
    return this.dataContext.post<SystemMessageReleaseModel>(
      `su/systemmessage/release`,
      model
    );
  }

  update(
    model: SystemMessageReleaseModel
  ): Observable<ResultWrapper<SystemMessageReleaseModel>> {
    return this.dataContext.put<SystemMessageReleaseModel>(
      `su/systemmessage/release/${model.id}`,
      model
    );
  }

  delete(id: number): Observable<ResultWrapper<SystemMessageReleaseModel>> {
    return this.dataContext.delete<SystemMessageReleaseModel>(
      `su/systemmessage/release/${id}`
    );
  }

  getAllSystemMessageChannels(): Observable<
    ResultWrapper<SystemMessageChannelModel[]>
  > {
    return this.dataContext.get<SystemMessageChannelModel[]>(
      `su/systemmessage/channel`
    );
  }
}
