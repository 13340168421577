<div class="col-10">
  <div
    class="page-header-filter-list"
    [ngClass]="{
      'page-header-filter-list-visible': showAdvancedSearch,
      'sidebar-open': sidebarOpen
    }"
  >
    <form
      [formGroup]="form"
      (ngSubmit)="onEvent.emit({ action: 'setSearch', data: search })"
      class="dropdown-header mt-4 mb-4"
    >
      <div class="custom-control custom-switch mb-2">
        <input
          formControlName="active"
          (change)="
            onEvent.emit({
              action: 'setActive',
              data: form.get('active').value
            })
          "
          checked
          type="checkbox"
          class="custom-control-input"
          id="active"
        />
        <label
          class="custom-control-label justify-content-start"
          for="active"
          >{{ 'tasks.task.active' | translate }}</label
        >
      </div>

      <div class="custom-control custom-switch mb-2">
        <input
          formControlName="expired"
          (change)="
            onEvent.emit({
              action: 'setExpired',
              data: form.get('expired').value
            })
          "
          type="checkbox"
          class="custom-control-input"
          id="expired"
        />
        <label
          class="custom-control-label justify-content-start"
          for="expired"
          >{{ 'tasks.task.expired' | translate }}</label
        >
      </div>

      <div class="form-group">
        <label
          ><b>{{ 'tasks.task.model.assignedTo' | translate }}</b></label
        >
        <div class="input-group mb-3">
          <select class="form-control" formControlName="assignedTo">
            <option
              *ngFor="let option of assignedToDropDownList"
              [value]="option.value"
            >
              {{ option.label }}
            </option>
          </select>
          <div class="input-group-append">
            <button
              class="btn btn-outline-secondary"
              type="button"
              (click)="form.get('assignedTo').reset()"
            >
              {{ 'shared.terms.clear' | translate }}
            </button>
          </div>
        </div>
      </div>

      <div class="form-group text-right mt-4 mb-4">
        <button class="btn btn-primary mr-0" type="submit">
          {{ 'shared.terms.search' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
