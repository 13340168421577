import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'underscore';
import { TranslateService } from '@ngx-translate/core';

import {
  NauticalShipModel,
  NauticalShipTagModel,
} from '@seahorse/domain';

@Component({
  selector: 'ca-nautical-ship-base-information',
  templateUrl: 'nautical-ship-base-information.component.html',
})
export class NauticalShipBaseInformationComponent implements OnInit {
  attributes: any[] = [];

  @Input() ship: NauticalShipModel;

  constructor(private translateService: TranslateService) {}

  ngOnInit() {
    this.createAttributes(this.ship);
  }

  createAttributes(ship: NauticalShipModel) {
    this.attributes = [];

    if (!ship) {
      return;
    }

    if (ship.shipType && ship.shipType.name) {
      this.attributes.push({
        fieldName: this.translateService.instant(
          'nautical.ship.shipType.shipType'
        ),
        fieldValue: ship.shipType.name,
      });
    }
    if (ship.flagCode) {
      this.attributes.push({
        fieldName: this.translateService.instant(
          'nautical.ship.model.flagCode'
        ),
        fieldValue: ship.flagCode,
      });
    }
    if (ship.imo) {
      this.attributes.push({
        fieldName: this.translateService.instant('nautical.ship.model.imo'),
        fieldValue: ship.imo,
      });
    }
    if (ship.eni) {
      this.attributes.push({
        fieldName: this.translateService.instant('nautical.ship.model.eni'),
        fieldValue: ship.eni,
      });
    }
    if (ship.fisheryNumber) {
      this.attributes.push({
        fieldName: this.translateService.instant(
          'nautical.ship.model.fisheryNumber'
        ),
        fieldValue: ship.fisheryNumber,
      });
    }
    if (ship.callSign) {
      this.attributes.push({
        fieldName: this.translateService.instant(
          'nautical.ship.model.callSign'
        ),
        fieldValue: ship.callSign,
      });
    }
    if (ship.mmsi) {
      this.attributes.push({
        fieldName: this.translateService.instant('nautical.ship.model.mmsi'),
        fieldValue: ship.mmsi,
      });
    }
  }
}
