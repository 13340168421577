<form #file="ngForm">
  <div class="row">
    <div class="col-md-12 text-center">
      <button type="button" class="btn btn-light btn-lg" (click)="showPicker()">
        <i class="fa-regular fa-upload fa-fw"></i>
        {{ 'files.file.filePicker.selectFiles' | translate }}
      </button>
      <input
        #fileInput
        multiple
        id="filePickerInput"
        type="file"
        (change)="uploadFile($event)"
        style="display: none"
      />
    </div>
  </div>

  <div class="row" *ngIf="files.length > 0">
    <div class="col-md-12">
      <table class="table table-condensed mt-3">
        <thead>
          <tr>
            <th>
              {{ 'file.model.description' | translate }}
              <span class="text-danger">*</span>
            </th>
            <th>
              {{ 'file.model.purpose' | translate }}
              <span class="text-danger">*</span>
            </th>
            <th>{{ 'file.model.size' | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let file of files">
            <td>
              <input
                required
                class="form-control"
                name="description{{ file.name }}"
                #description="ngModel"
                [(ngModel)]="file.description"
              />
            </td>
            <td>
              <input
                required
                class="form-control"
                name="purpose{{ file.name }}"
                #purpose="ngModel"
                [(ngModel)]="file.filePurpose"
                placeholder="..."
              />
            </td>
            <td>{{ file?.file?.size | fileSize }}</td>
          </tr>
        </tbody>
      </table>
      <span *ngIf="submitted && showError" class="text-danger"> * </span>
    </div>
  </div>
</form>
