<div class="modal-header align-items-center">
  <h1 class="modal-title">{{ title }}</h1>
  <button closeModal type="button" class="btn btn-link text-dark">
    <i class="fa-solid fa-times fa-sm fa-fw"></i>
  </button>
</div>
<div class="modal-body">
  <div class="form-row">
    <div *ngIf="nauticalShipPickable" class="form-group col-12">
      <label
        class="d-flex justify-content-between"
        for="typeahead-nauticalShip"
      >
        <b [ngClass]="{ required: nauticalShipRequired }">{{
          'nautical.movement.pickerDialog.selectNauticalShip' | translate
        }}</b>
        <span *ngIf="nauticalShipLoading || nauticalShipsLoading">
          <i class="fa-regular fa-spinner fa-spin fa-fw"></i>
        </span>
      </label>
      <div class="input-group">
        <input
          [(ngModel)]="nauticalShip"
          [class.is-invalid]="
            nauticalShipLoadingFailed || nauticalShipsLoadingFailed
          "
          [ngbTypeahead]="searchNauticalShip"
          [inputFormatter]="nauticalShipFormatter"
          [resultFormatter]="nauticalShipsFormatter"
          [editable]="false"
          [placeholder]="
            ('nautical.movement.pickerDialog.searchNauticalShip' | translate) +
            '...'
          "
          [required]="nauticalShipRequired"
          (selectItem)="pickedNauticalShip()"
          id="typeahead-nauticalShip"
          name="nauticalShip"
          type="text"
          class="form-control"
        />
        <div class="input-group-append">
          <button
            class="btn btn-outline-secondary"
            (click)="setNull('nauticalShip')"
            type="button"
          >
            <i class="fa-regular fa-backspace fa-fw"></i>
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="nauticalVisitPickable" class="form-group col-12">
      <label class="d-flex justify-content-between" for="nauticalVisits">
        <b [ngClass]="{ required: nauticalVisitRequired }">{{
          'nautical.movement.pickerDialog.selectNauticalVisit' | translate
        }}</b>
        <div>
          <em *ngIf="nauticalShip">
            {{ nauticalVisitsCount }}
            <ng-container
              *ngIf="
                nauticalVisitsCount == 1;
                then nauticalVisitsSingularFound;
                else nauticalVisitsMultipleFound
              "
            ></ng-container>
            <ng-template #nauticalVisitsSingularFound>{{
              'shared.terms.itemFound' | translate
            }}</ng-template>
            <ng-template #nauticalVisitsMultipleFound>{{
              'shared.terms.itemsFound' | translate
            }}</ng-template>
          </em>
          <span *ngIf="nauticalVisitsLoading">
            <i class="fa-regular fa-spinner fa-spin fa-fw"></i>
          </span>
        </div>
      </label>
      <div class="input-group">
        <select
          [(ngModel)]="nauticalVisitId"
          [required]="nauticalVisitRequired"
          [disabled]="
            nauticalVisitsLoading || (!nauticalVisitsCount && !nauticalVisitId)
          "
          (change)="pickedNauticalVisit()"
          id="visits"
          name="visits"
          class="custom-select"
        >
          <option selected [ngValue]="null">
            <ng-container
              *ngIf="
                nauticalVisitsLoading;
                then loadingMessage;
                else enablePicker
              "
            ></ng-container>
            <ng-template #loadingMessage
              >{{ 'shared.terms.loading' | translate }}...</ng-template
            >
            <ng-template #enablePicker>
              <ng-container
                *ngIf="
                  nauticalVisitsCount || nauticalVisitId;
                  then chooseMessage;
                  else noneAvailable
                "
              ></ng-container>
              <ng-template #chooseMessage
                >{{ 'shared.terms.choose' | translate }}...</ng-template
              >
              <ng-template #noneAvailable>{{
                'nautical.movement.pickerDialog.noNauticalVisitsAvailable'
                  | translate
              }}</ng-template>
            </ng-template>
          </option>
          <optgroup *ngIf="matchNauticalVisitId()">
            <option [ngValue]="nauticalVisit.id">
              {{ nauticalVisitFormatter(nauticalVisit) }}
            </option>
          </optgroup>
          <optgroup *ngIf="nauticalVisits.length">
            <option
              *ngFor="let nauticalVisitOption of nauticalVisits"
              [ngValue]="nauticalVisitOption.id"
              (click)="
                setNauticalMovementToCurrent(
                  nauticalVisitOption.currentMovement
                )
              "
            >
              {{ nauticalVisitFormatter(nauticalVisitOption) }}
            </option>
          </optgroup>
        </select>
        <div class="input-group-append">
          <button
            [disabled]="nauticalVisitLoading"
            (click)="setNull('nauticalVisit')"
            type="button"
            class="btn btn-outline-secondary"
          >
            <i class="fa-regular fa-backspace fa-fw"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="form-group col-12">
      <label class="d-flex justify-content-between" for="nauticalMovements">
        <b [ngClass]="{ required: nauticalMovementRequired }">{{
          'nautical.movement.pickerDialog.selectNauticalMovement' | translate
        }}</b>
        <div>
          <em *ngIf="nauticalVisit && nauticalVisit.portMovements">
            {{ nauticalVisit.portMovements.length }}
            <ng-container
              *ngIf="
                nauticalVisit.portMovements.length == 1;
                then nauticalMovementsSingularFound;
                else nauticalMovementsMultipleFound
              "
            ></ng-container>
            <ng-template #nauticalMovementsSingularFound>{{
              'shared.terms.itemFound' | translate
            }}</ng-template>
            <ng-template #nauticalMovementsMultipleFound>{{
              'shared.terms.itemsFound' | translate
            }}</ng-template>
          </em>
          <span *ngIf="nauticalVisitLoading">
            <i class="fa-regular fa-spinner fa-spin fa-fw"></i>
          </span>
        </div>
      </label>
      <div class="input-group">
        <select
          [(ngModel)]="nauticalMovement"
          [required]="nauticalMovementRequired"
          [disabled]="
            nauticalVisitLoading ||
            !nauticalVisit ||
            !nauticalVisit.portMovements ||
            !nauticalVisit.portMovements.length
          "
          id="movements"
          name="movements"
          class="custom-select"
        >
          <option selected [ngValue]="null">
            <ng-container
              *ngIf="
                nauticalVisitLoading;
                then loadingMessage;
                else enablePicker
              "
            ></ng-container>
            <ng-template #loadingMessage
              >{{ 'shared.terms.loading' | translate }}...</ng-template
            >
            <ng-template #enablePicker>
              <ng-container
                *ngIf="
                  nauticalVisit &&
                    nauticalVisit.portMovements &&
                    nauticalVisit.portMovements.length;
                  then chooseMessage;
                  else noneAvailable
                "
              ></ng-container>
              <ng-template #chooseMessage
                >{{ 'shared.terms.choose' | translate }}...</ng-template
              >
              <ng-template #noneAvailable>{{
                'nautical.movement.pickerDialog.noNauticalMovementsAvailable'
                  | translate
              }}</ng-template>
            </ng-template>
          </option>
          <optgroup *ngIf="nauticalMovement">
            <option [ngValue]="nauticalMovement">
              {{ nauticalMovementFormatter(nauticalMovement) }}
            </option>
          </optgroup>
          <optgroup
            *ngIf="
              nauticalVisit &&
              nauticalVisit.portMovements &&
              nauticalVisit.portMovements.length
            "
          >
            <option
              *ngFor="let nauticalMovementOption of nauticalVisit.portMovements"
              [ngValue]="nauticalMovementOption"
            >
              {{ nauticalMovementFormatter(nauticalMovementOption) }}
            </option>
          </optgroup>
        </select>
        <div class="input-group-append">
          <button
            [disabled]="nauticalVisitLoading"
            (click)="setNull('nauticalMovement')"
            type="button"
            class="btn btn-outline-secondary"
          >
            <i class="fa-regular fa-backspace fa-fw"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button closeModal type="button" class="btn btn-link flex-fill">
    {{ 'shared.terms.cancel' | translate }}
  </button>
  <button
    [disabled]="
      (nauticalMovementRequired && !nauticalMovement) ||
      (nauticalVisitRequired && !nauticalVisitId) ||
      (nauticalShipRequired && !nauticalShip)
    "
    [ngClass]="
      nauticalVisitLoadingFailed ? 'btn-outline-danger' : 'btn-success'
    "
    (click)="pickedNauticalMovement()"
    type="button"
    class="btn flex-fill"
  >
    {{ 'shared.terms.confirm' | translate }}
  </button>
</div>
