import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { DataContextService } from '@seahorse/common';
import { ResultWrapper } from '@seahorse/common';
import { NauticalPortWaypointModel } from '../models/nautical-port-waypoint.model';

@Injectable()
export class NauticalPortWaypointDataService {
  constructor(private dataContext: DataContextService) {}

  getByPortId(
    portId: number,
    pageIndex: number = 0,
    pageSize: number = 50,
    filter: string = null
  ): Observable<ResultWrapper<NauticalPortWaypointModel[]>> {
    return this.dataContext.get<NauticalPortWaypointModel[]>(
      `nautical/portwaypoints/port/${portId}?pindex=${pageIndex}&psize=${pageSize}&filter=${filter}`
    );
  }
}
