import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BasePage } from '../../../../layout/pages/base/base.page';
import { NauticalVisitOverviewModel } from '@seahorse/domain';
import { NauticalVisitDataService } from '@seahorse/domain';
import { ZHGPageStateModel } from './zhg-page-state.model';

import { NotificationService } from '@seahorse/common';
import { CustomDataContentService, SearchOperators } from '@seahorse/domain';
import * as _ from 'underscore';
import { NauticalShipDataService } from '@seahorse/domain';
import { PortOfAmsterdamDeclarationModel } from '../../models/declaration.model';

@Component({
  selector: 'ca-portofamsterdam-zhg-page',
  templateUrl: 'zhg.page.html',
})
export class PortOfAmsterdamZHGPage extends BasePage<ZHGPageStateModel> {
  declaration: PortOfAmsterdamDeclarationModel =
    new PortOfAmsterdamDeclarationModel();
  isLoading = false;
  selectedVisit: NauticalVisitOverviewModel = null;
  ucrn: string = null;
  visits: NauticalVisitOverviewModel[] = [];

  moduleName = 'Opgave zeehavengeld - Port of Amsterdam';

  constructor(
    private customContent: CustomDataContentService,
    private notificationService: NotificationService,
    protected route: ActivatedRoute,
    protected router: Router,
    private nauticalShipData: NauticalShipDataService,
    private nauticalVisitData: NauticalVisitDataService
  ) {
    super(route, router);

    this.loadVisits();
  }

  createDefaultState(): ZHGPageStateModel {
    return new ZHGPageStateModel();
  }

  getShipAttribute(category: string, fieldName: string) {
    const field = _.find(
      this.selectedVisit.ship.shipAttributes,
      (attr) => attr.category === category && attr.fieldName === fieldName
    );
    if (field) {
      return field.fieldValue;
    }
    return null;
  }

  loadVisit(ucrn: string) {
    if (_.any(this.visits, (v) => v.referenceNumber === ucrn)) {
      this.selectedVisit = _.find(
        this.visits,
        (v) => v.referenceNumber === ucrn
      );
      this.processVisit();
    } else {
      this.nauticalVisitData
        .getListOverviewForPortByReferences([ucrn], 0, 1)
        .subscribe((visitResult) => {
          if (
            visitResult &&
            visitResult.hasResult &&
            visitResult.result.length === 1
          ) {
            this.selectedVisit = visitResult.result[0];
            this.processVisit();
          } else {
            this.selectedVisit = null;
          }
        });
    }
  }

  loadVisits() {
    this.isLoading = true;

    this.nauticalVisitData
      .getListOverviewForPort('nlams', 'sailed', false, 0, 150, 'portagent')
      .subscribe(
        (visitResult) => {
          if (visitResult && visitResult.hasResult) {
            this.visits = visitResult.result;
          }
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
        }
      );
  }

  ngOnInit() {
    super.ngOnInit();
    this.ucrn = this.getState('ucrn');
    if (this.ucrn) {
      this.loadVisit(this.ucrn);
    }
  }

  onStateChanged(state: ZHGPageStateModel, changes: any[]): void {
    this.ucrn = state.ucrn;

    this.loadVisit(this.ucrn);
  }

  processVisit() {
    this.nauticalShipData
      .getById(this.selectedVisit.ship.id)
      .subscribe((shipResult) => {
        if (shipResult) {
          this.selectedVisit.ship = shipResult;
        }
      });

    this.declaration = new PortOfAmsterdamDeclarationModel();
    this.customContent
      .searchActiveCustomerDataByFields(
        'POAZHG',
        [
          {
            fieldName: '$nautical_portvisit_ucrn',
            searchOperator: SearchOperators.Equals,
            searchValue: this.selectedVisit.referenceNumber,
          },
        ],
        null,
        null,
        0,
        500
      )
      .subscribe((visitResult) => {
        if (visitResult.hasResult && visitResult.result.length > 0) {
          this.declaration = visitResult.result[0];
        }
      });
  }

  save(finalize: boolean) {
    this.declaration.$nautical_portvisit_ucrn =
      this.selectedVisit.referenceNumber;
    this.declaration.activityState = null;

    if (finalize === true) {
      this.declaration.status = 'sent';
    }

    if (this.declaration.__Id && this.declaration.__Id !== '') {
      this.customContent
        .updateCustomerData('POAZHG', this.declaration.__Id, this.declaration)
        .subscribe((result) => {
          this.declaration = result.result;
          this.notificationService.showSuccess(
            'De aangifte is opgeslagen' + (finalize ? ' en verstuurd' : '')
          );
        });
    } else {
      this.customContent
        .addCustomerData('POAZHG', this.declaration)
        .subscribe((result) => {
          this.declaration = result.result;
          this.notificationService.showSuccess(
            'De aangifte is opgeslagen' + (finalize ? ' en verstuurd' : '')
          );
        });
    }
  }

  setUCRN(ucrn: string) {
    this.ucrn = ucrn;
    this.setState('ucrn', ucrn);

    if (!ucrn) {
      this.selectedVisit = null;
    }
  }

  visitSelected(visit: NauticalVisitOverviewModel) {
    if (visit && visit.referenceNumber) {
      this.setUCRN(visit.referenceNumber);
      this.loadVisit(visit.referenceNumber);
    } else {
      this.setUCRN(null);
    }
  }
}
