import * as moment from 'moment';
import * as _ from 'underscore';

import { CustomDataBaseModel, NauticalShipModel, NauticalWaypointModel } from '@seahorse/domain';
import { NauticalMovementModel } from '@seahorse/domain';
import { NauticalVisitOverviewModel } from '@seahorse/domain';
import { NauticalVisitModel } from '@seahorse/domain';
import { ZeehavenIJmuidenDisplayModel } from './zeehaven-ijmuiden-display.model';

export class ZeehavenIJmuidenMovementModel
  extends CustomDataBaseModel
  implements ZeehavenIJmuidenDisplayModel
{
  // #region meta
  nauticalShip: NauticalShipModel['id'];
  $nautical_portvisit_id?: NauticalVisitModel['id'];
  $nautical_portmovement_id?: NauticalMovementModel['id'];
  zhijVisitId: string;
  status: string;
  //#endregion
  // #region basic data
  shipName?: string;
  movementType: ZeehavenIJmuidenVisitMovementType;
  etaOrEtd?: string;
  dosForm: boolean;
  dosFormDate: string;
  captainsEta: boolean;
  anchored: boolean;
  anchoredMessage?: string;
  plannedLock?: string;
  ataOrAtd?: string;
  // #endregion
  // #region origin
  origin?: string;
  originWaypoint?: NauticalWaypointModel['id'];
  // #endregion
  // #region destination
  destination?: string;
  destinationWaypoint?: NauticalWaypointModel['id'];
  // #endregion
  // #region comments
  comments?: string;
  // #endregion
  // #region ship data
  agent?: string;
  owner?: string;
  operator?: string;
  technicalManager?: string;
  shipManager?: string;
  contents?: string;
  originalContents?: string;
  maxDraft?: string;
  // #endregion
  // #region Zeehaven IJmuiden specific
  oilScreen?: boolean;
  oilScreenMessage?: string;
  power: boolean;
  powerMessage?: string;
  tug: boolean;
  tugMessage?: string;
  fender: boolean;
  fenderMessage?: string;
  garbage: boolean;
  garbageMessage?: string;
  water: boolean;
  waterMessage?: string;
  workPlatform: boolean;
  workPlatformMessage?: string;
  // #endregion

  constructor() {
    super();
    // #region meta
    this.nauticalShip = null;
    this.$nautical_portvisit_id = null;
    this.$nautical_portmovement_id = null;
    this.status = null;
    // #endregion
    // #region basic data
    this.shipName = null;
    this.movementType = null;
    this.etaOrEtd = null;
    this.dosForm = null;
    this.dosFormDate = null;
    this.captainsEta = false;
    this.anchored = false;
    this.anchoredMessage = null;
    this.plannedLock = null;
    this.ataOrAtd = null;
    // #endregion
    // #region origin
    this.origin = null;
    // #endregion
    // #region destination
    this.destination = null;
    // #endregion
    // #region comments
    this.comments = null;
    // #endregion
    // #region ship data
    this.agent = null;
    this.owner = null;
    this.operator = null;
    this.technicalManager = null;
    this.shipManager = null;
    this.contents = null;
    this.originalContents = null;
    this.maxDraft = null;
    // #endregion
    // #region Zeehaven IJmuiden specific
    this.oilScreen = false;
    this.oilScreenMessage = null;
    this.power = false;
    this.powerMessage = null;
    this.tug = false;
    this.tugMessage = null;
    this.fender = false;
    this.fenderMessage = null;
    this.garbage = false;
    this.garbageMessage = null;
    this.water = false;
    this.waterMessage = null;
    this.workPlatform = false;
    this.workPlatformMessage = null;
    // #endregion
  }

  static fromVisitOverviewModel(
    visitOverview: NauticalVisitOverviewModel,
    movement: NauticalMovementModel
  ): ZeehavenIJmuidenMovementModel {
    let f: ZeehavenIJmuidenMovementModel = null;

    const zeehavenIJmuidenWaypoints: NauticalWaypointModel['code'][] = [
      'blstg',
      'ha',
      'habuk',
      'habup',
      'hafer',
      'hahel',
      'hakot',
      'haleo',
      'halog',
      'halwe',
      'halzu',
      'halzw',
      'hanew',
      'hasto',
      'hastw',
      'ijm',
      'vehou',
      'vekbk',
      'velbk',
      'vezvk',
      'vh',
      'vhbin',
      'vhdok',
      'vhft2',
      'vhhal',
      'vhleo',
      'vhnrm',
      'vhstg',
      'vhzzm',
      'vhzzo',
      'vhzzw',
      'vhzzy',
      'ym',
      'ymfct',
      'ymft3',
      'ymmon',
      'ymvol',
      'ztft1',
    ];

    if (
      (movement &&
        movement.portWayPointFrom &&
        _.contains(
          zeehavenIJmuidenWaypoints,
          movement.portWayPointFrom.code.toLowerCase()
        )) ||
      (movement.portWayPointTo &&
        _.contains(
          zeehavenIJmuidenWaypoints,
          movement.portWayPointTo.code.toLowerCase()
        ))
    ) {
      const z: ZeehavenIJmuidenMovementModel =
        new ZeehavenIJmuidenMovementModel();
      z.$nautical_portvisit_id = visitOverview.id;
      z.$nautical_portmovement_id = movement.id;

      if (visitOverview.portAgentName) {
        z.agent = visitOverview.portAgentName;
      }

      if (visitOverview.ship) {
        z.nauticalShip = visitOverview.ship.id;
        z.shipName = visitOverview.ship.name.toUpperCase();
        z.owner = visitOverview.ship.owner;

        if (visitOverview.ship.shipAttributes) {
          _.forEach(visitOverview.ship.shipAttributes, (a) => {
            if (a.category == 'ownership') {
              switch (a.fieldName) {
                case 'groupowner': {
                  z.owner = a.fieldValue;
                  break;
                }

                case 'operator': {
                  z.operator = a.fieldValue;
                  break;
                }

                case 'shipmanager': {
                  z.shipManager = a.fieldValue;
                  break;
                }
              }
            }
          });
        }

        if (visitOverview.ship.maxDraft) {
          z.maxDraft = visitOverview.ship.maxDraft.toString();
        }
      }

      if (
        movement.portWayPointFrom &&
        _.contains(
          zeehavenIJmuidenWaypoints,
          movement.portWayPointFrom.code.toLowerCase()
        )
      ) {
        z.origin = movement.portWayPointFrom.name;

        if (
          movement.portWayPointTo &&
          _.contains(
            zeehavenIJmuidenWaypoints,
            movement.portWayPointTo.code.toLowerCase()
          )
        ) {
          z.movementType = ZeehavenIJmuidenVisitMovementType.Shifting;
          z.etaOrEtd = moment(movement.eta).format('YYYY-MM-DD[T]HH:mm');
          z.destination = movement.portWayPointTo.name;
        } else {
          z.movementType = ZeehavenIJmuidenVisitMovementType.Outgoing;
          z.etaOrEtd = moment(movement.etd).format('YYYY-MM-DD[T]HH:mm');

          if (visitOverview.nextPort) {
            z.destination = visitOverview.nextPort.name;
          }
        }
      } else if (
        movement.portWayPointTo &&
        _.contains(
          zeehavenIJmuidenWaypoints,
          movement.portWayPointTo.code.toLowerCase()
        )
      ) {
        z.movementType = ZeehavenIJmuidenVisitMovementType.Incoming;
        z.destination = movement.portWayPointTo.name;
        z.etaOrEtd = moment(movement.eta).format('YYYY-MM-DD[T]HH:mm');

        if (visitOverview.previousPort) {
          z.origin = visitOverview.previousPort.name;
        }
      }

      f = z;
    }

    return f;
  }
}

export enum ZeehavenIJmuidenVisitMovementType {
  Incoming = 1,
  Shifting = 2,
  Outgoing = 3,
}
