import { Injectable } from '@angular/core';
import { DataContextService } from '@seahorse/common';
import { AreaActivityModel } from '../../superuser-nautical/models/area-activity.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { NauticalCityModel } from '../models/nautical-city.model';
import {
  CoordinatePointModel,
  CoordinatePolygonModel,
  CountryModel,
  FeatureCollectionModel,
  HarbourModel,
  NauticalPortModel,
  PortModel,
  PortWaypointModel,
} from '@seahorse/domain';

@Injectable()
export class NauticalCoordinatesDataService {
  constructor(private dataContext: DataContextService) {}

  convertKml(fileToUpload: File): Observable<FeatureCollectionModel[]> {
    const formData: FormData = new FormData();
    formData.append('fileKey', fileToUpload, fileToUpload.name);

    return this.dataContext
      .post<FeatureCollectionModel[]>(
        'nautical/coordinate/convert/kml',
        formData
      )
      .pipe(map((response) => response.result));
  }

  setCityBoundary(
    city: NauticalCityModel,
    polygon: CoordinatePolygonModel,
    mapCenterPoint: boolean
  ): Observable<NauticalCityModel> {
    let url = 'su/nautical/coordinate/city/' + city.id + '/bounds';
    if (mapCenterPoint) {
      url += '?updateCenter=' + (mapCenterPoint ? 'true' : 'false');
    }

    return this.dataContext
      .put<NauticalCityModel>(url, polygon)
      .pipe(map((response) => response.result));
  }

  setPortBoundary(
    port: NauticalPortModel,
    polygon: CoordinatePolygonModel,
    mapCenterPoint: boolean
  ): Observable<NauticalPortModel> {
    let url = 'su/nautical/coordinate/port/' + port.id + '/bounds';
    if (mapCenterPoint) {
      url += '?updateCenter=' + (mapCenterPoint ? 'true' : 'false');
    }

    return this.dataContext
      .put<NauticalPortModel>(url, polygon)
      .pipe(map((response) => response.result));
  }

  setPortWaypointBoundary(
    portWaypoint: PortWaypointModel,
    polygon: CoordinatePolygonModel,
    mapCenterPoint: boolean
  ): Observable<PortWaypointModel> {
    let url =
      'su/nautical/coordinate/portwaypoint/' + portWaypoint.id + '/bounds';
    if (mapCenterPoint) {
      url += '?updateCenter=' + (mapCenterPoint ? 'true' : 'false');
    }

    return this.dataContext
      .put<PortWaypointModel>(url, polygon)
      .pipe(map((response) => response.result));
  }

  setHarbourBoundary(
    harbour: HarbourModel,
    polygon: CoordinatePolygonModel
  ): Observable<HarbourModel> {
    const url = 'su/nautical/coordinate/harbour/' + harbour.id + '/bounds';

    return this.dataContext
      .put<HarbourModel>(url, polygon)
      .pipe(map((response) => response.result));
  }

  setCountryBoundary(
    counrty: CountryModel,
    polygon: CoordinatePolygonModel,
    mapCenterPoint: boolean
  ): Observable<CountryModel> {
    let url = 'su/nautical/coordinate/country/' + counrty.id + '/bounds';
    if (mapCenterPoint) {
      url += '?updateCenter=' + (mapCenterPoint ? 'true' : 'false');
    }

    return this.dataContext
      .put<CountryModel>(url, polygon)
      .pipe(map((response) => response.result));
  }

  setCountryCenterpoint(
    country: CountryModel,
    centerpoint: CoordinatePointModel
  ): Observable<CountryModel> {
    const url = 'su/nautical/coordinate/country/' + country.id + '/centerpoint';

    return this.dataContext
      .put<CountryModel>(url, centerpoint)
      .pipe(map((response) => response.result));
  }

  setPortCenterpoint(
    port: PortModel,
    centerpoint: CoordinatePointModel
  ): Observable<PortModel> {
    const url = 'su/nautical/coordinate/port/' + port.id + '/centerpoint';

    return this.dataContext
      .put<PortModel>(url, centerpoint)
      .pipe(map((response) => response.result));
  }

  setPortWaypointCenterpoint(
    portWaypoint: PortWaypointModel,
    centerpoint: CoordinatePointModel
  ): Observable<PortWaypointModel> {
    const url =
      'su/nautical/coordinate/portwaypoint/' + portWaypoint.id + '/centerpoint';

    return this.dataContext
      .put<PortWaypointModel>(url, centerpoint)
      .pipe(map((response) => response.result));
  }

  getPolygon(type: string, typeId): Observable<CoordinatePolygonModel> {
    const url = 'su/nautical/coordinate/' + type + '/' + typeId + '/bounds';

    return this.dataContext
      .get<CoordinatePolygonModel>(url)
      .pipe(map((response) => response.result));
  }

  getCenterpoint(type: string, typeId): Observable<CoordinatePointModel> {
    const url =
      'su/nautical/coordinate/' + type + '/' + typeId + '/centerpoint';

    return this.dataContext
      .get<CoordinatePointModel>(url)
      .pipe(map((response) => response.result));
  }

  setAreaActivityBoundary(
    areaActivity: AreaActivityModel,
    polygon: CoordinatePolygonModel
  ): Observable<AreaActivityModel> {
    const url =
      'su/nautical/coordinate/areaactivity/' + areaActivity.id + '/bounds';

    return this.dataContext
      .put<AreaActivityModel>(url, polygon)
      .pipe(map((response) => response.result));
  }
}
