import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { FieldDefinitionModel } from '@seahorse/domain';
import {
  ObjectDefinitionConfigurationModel,
  ObjectDefinitionModel,
} from '@seahorse/domain';

@Pipe({ name: 'templateCode' })
export class TemplateCodePipe implements PipeTransform {
  customFields: FieldDefinitionModel[] = null;

  constructor(private translateService: TranslateService) {}

  transform(input: string, objectDefinition: ObjectDefinitionModel): string {
    if (!input) {
      return;
    }

    if (objectDefinition.objectFieldDefinitions) {
      this.customFields = objectDefinition.objectFieldDefinitions;
    }

    if (
      objectDefinition.baseObjectDefinition &&
      objectDefinition.baseObjectDefinition.objectFieldDefinitions
    ) {
      this.customFields = this.customFields.concat(
        objectDefinition.baseObjectDefinition.objectFieldDefinitions
      );
    }

    const configuration = JSON.parse(
      input
    ) as ObjectDefinitionConfigurationModel;

    configuration.link.mapping.keys = this.transformFields(
      configuration.link.mapping.keys
    );
    configuration.link.mapping.displayFields = this.transformFields(
      configuration.link.mapping.displayFields
    );

    const result =
      configuration.link.mapping.keys.join(', ') +
      ' <i>' +
      this.translateService.instant(
        'customData.objectDefinition.fieldsToDisplay'
      ) +
      '</i>' +
      ': ' +
      configuration.link.mapping.displayFields.join(', ');

    return result;
  }

  transformFields(fields: string[]): string[] {
    const transformedFields = [];

    fields.forEach((element) => {
      switch (element) {
        case '__LastEditedOn':
          transformedFields.push(
            this.translateService.instant('customData.model.__LastEditedOn')
          );
          break;

        case '__LastEditedBy':
          transformedFields.push(
            this.translateService.instant('customData.model.__LastEditedBy')
          );
          break;

        case '__CreatedOn':
          transformedFields.push(
            this.translateService.instant('customData.model.__CreatedOn')
          );
          break;

        case '__CreatedBy':
          transformedFields.push(
            this.translateService.instant('customData.model.__CreatedBy')
          );
          break;

        case '__Id':
          transformedFields.push(
            this.translateService.instant('customData.model.__Id')
          );
          break;

        default: {
          const customField = this.customFields.find(
            (x) => x.systemCode === element
          );

          if (customField) {
            transformedFields.push(customField.name);
          } else {
            transformedFields.push(element);
          }
          break;
        }
      }
    });
    return transformedFields;
  }
}
