<div class="page-header row no-gutters">
  <div class="col-10">
    <div
      class="page-header-filter-list"
      [ngClass]="{
        'page-header-filter-list-visible': showAdvancedSearch,
        'sidebar-open': sidebarOpen
      }"
    >
      <form
        #advancedSearchForm="ngForm"
        class="dropdown-header"
        (ngSubmit)="searchShips()"
      >
        <h6><b>find vessels</b></h6>
        <div class="form-group">
          <label>{{ 'nautical.ship.model.name' | translate }}:</label>
          <input
            #name="ngModel"
            type="text"
            name="name"
            [(ngModel)]="model.name"
            class="form-control"
          />
        </div>

        <div class="form-group">
          <label>{{ 'nautical.ship.model.callSign' | translate }}:</label>
          <input
            #callSign="ngModel"
            type="text"
            name="callSign"
            [(ngModel)]="model.callSign"
            class="form-control"
          />
        </div>

        <div class="form-group">
          <label>{{ 'nautical.ship.model.fisheryNumber' | translate }}:</label>
          <input
            #fisheryNumber="ngModel"
            type="text"
            name="fisheryNumber"
            [(ngModel)]="model.fisheryNumber"
            class="form-control"
          />
        </div>

        <div class="form-group">
          <label>{{ 'nautical.ship.model.imo' | translate }}:</label>
          <input
            #imo="ngModel"
            type="text"
            name="imo"
            [(ngModel)]="model.imo"
            class="form-control"
          />
        </div>

        <div class="form-group">
          <label>{{ 'nautical.ship.model.mmsi' | translate }}:</label>
          <input
            #mmsi="ngModel"
            type="text"
            name="mmsi"
            [(ngModel)]="model.mmsi"
            class="form-control"
          />
        </div>

        <div class="form-group">
          <label>{{ 'nautical.ship.model.tags' | translate }}:</label>
          <input
            #tags="ngModel"
            type="text"
            name="tags"
            [(ngModel)]="model.shipTags"
            class="form-control"
          />
        </div>

        <div class="form-group">
          <label>{{ 'shipType.shipTypeCategory' | translate }}:</label>
          <ng-select
            #shipTypeCategory
            name="shipTypeCategory"
            [items]="shipTypeCategories"
            [(ngModel)]="model.shipTypeCategories"
            [multiple]="true"
            [closeOnSelect]="false"
            [searchable]="false"
            bindLabel="name"
            placeholder="{{
              'nautical.ship.shipType.shipTypeCategory.selectCategories'
                | translate
            }}"
          >
          </ng-select>
        </div>

        <div class="row">
          <div class="form-group col-6">
            <label>LOA from:</label>
            <input
              #fromLOA="ngModel"
              name="fromLOA"
              [(ngModel)]="model.fromLOA"
              min="0"
              type="number"
              class="form-control"
              nullValue
            />
          </div>

          <div class="form-group col-6">
            <label>LOA {{ 'shared.terms.to' | translate }}:</label>
            <input
              #toLOA="ngModel"
              name="toLOA"
              [(ngModel)]="model.toLOA"
              min="0"
              type="number"
              class="form-control"
              nullValue
            />
          </div>
        </div>

        <div class="row">
          <div class="form-group col-6">
            <label>GT from:</label>
            <input
              #fromGT="ngModel"
              name="fromGT"
              [(ngModel)]="model.fromGT"
              min="0"
              type="number"
              class="form-control"
            />
          </div>

          <div class="form-group col-6">
            <label>GT {{ 'shared.terms.to' | translate }}:</label>
            <input
              #toGT="ngModel"
              name="toGT"
              [(ngModel)]="model.toGT"
              min="0"
              type="number"
              class="form-control"
            />
          </div>
        </div>

        <div class="row">
          <div class="form-group col-6">
            <label>DWT from:</label>
            <input
              #fromDWT="ngModel"
              name="fromDWT"
              [(ngModel)]="model.fromDWT"
              min="0"
              type="number"
              class="form-control"
            />
          </div>

          <div class="form-group col-6">
            <label>DWT {{ 'shared.terms.to' | translate }}:</label>
            <input
              #toDWT="ngModel"
              name="toDWT"
              [(ngModel)]="model.toDWT"
              min="0"
              type="number"
              class="form-control"
            />
          </div>
        </div>

        <div class="form-group text-right">
          <button class="btn btn-link" type="button" (click)="clearForm()">
            {{ 'shared.terms.clear' | translate }}
          </button>
          <button class="btn btn-primary" type="submit">
            {{ 'shared.terms.search' | translate }}
          </button>
        </div>
      </form>
    </div>

    <form class="form-inline mx-3" (ngSubmit)="searchShips()">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">{{
            'shared.terms.view' | translate
          }}</span>
        </div>
        <select
          class="form-control mr-3"
          [(ngModel)]="selectedView"
          name="ddl_header_data_display"
          (change)="setSelectedView()"
        >
          <option [value]="CollectionDisplay.List">
            {{ 'shared.terms.list' | translate }}
          </option>
          <option [value]="CollectionDisplay.Tiles">
            {{ 'shared.terms.tiles' | translate }}
          </option>
        </select>
      </div>
      <div class="input-group">
        <div class="input-group-prepend">
          <select
            class="form-control"
            style="max-width: 150px"
            [(ngModel)]="currentSearchField"
            name="ddl_header_data_searchField"
            (change)="simpleSearchChanged()"
          >
            <option
              [value]="simpleField"
              *ngFor="let simpleField of simpleSearchFields"
            >
              {{ 'nautical.ship.model.' + simpleField | translate }}
            </option>
          </select>
        </div>
        <input
          type="text"
          class="form-control"
          placeholder="{{ 'shared.terms.search' | translate }}"
          aria-label="Find ships"
          [(ngModel)]="model[currentSearchField]"
          name="nauticalShipSearchBox"
        />
        <div class="input-group-append">
          <button
            type="button"
            class="btn btn-outline-secondary"
            (click)="showAdvancedSearch = !showAdvancedSearch"
          >
            <i class="fa-regular fa-chevron-down"></i>
          </button>
          <button class="btn btn-primary mr-3" type="submit">
            <i class="fa-solid fa-search"></i>
          </button>
        </div>
      </div>
      <em class="mr-3" *ngIf="searchResultCount !== null">
        <span *ngIf="searchResultCount === 1"
          >{{ searchResultCount }}
          {{ 'shared.terms.itemFound' | translate }}</span
        >
        <span *ngIf="searchResultCount !== 1"
          >{{ searchResultCount }}
          {{ 'shared.terms.itemsFound' | translate }}</span
        >
      </em>
    </form>
  </div>

  <div class="col text-right">
    <div class="btn-group pt-1 pr-3">
      <button
        class="btn btn-primary"
        *hasPermission="'ships.ships_create'"
        type="button"
        (click)="newButtonClicked()"
      >
        <i class="fa-regular fa-plus fa-fw"></i>
        {{ 'shared.terms.new' | translate }}
      </button>
    </div>
  </div>
</div>
