import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'underscore';
import { CVVActivityLogicService } from '../services/cvv-activity-logic.service';

@Pipe({
  name: 'cvvActivityTypeDisplay',
})
export class CVVActivityTypeDisplayPipe implements PipeTransform {
  constructor(private cvvActivityLogic: CVVActivityLogicService) {}

  transform(value: number): string {
    const type = _.find(
      _.values(this.cvvActivityLogic.cvvActivityTypes),
      (t) => t.id === value
    );
    return type ? type.name : 'unknown';
  }
}
