import { Component, Output, EventEmitter } from '@angular/core';

import { UserModel } from '@seahorse/domain';

@Component({
  selector: 'user-modal',
  templateUrl: './user-modal.component.html',
})
export class UserModalComponent {
  isSubmitted = false;
  user: UserModel = null;

  @Output() saveUser = new EventEmitter<UserModel>();

  constructor() {
    this.user = new UserModel();
  }

  save() {
    if (!UserModel.validate(this.user)) {
      this.isSubmitted = true;
      return;
    }

    this.saveUser.emit(this.user);
  }
}
