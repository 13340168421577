import { Injectable } from '@angular/core';
import {
  OrganisationModel,
  OrganisationOwnerModel,
  ProductModel,
  ProfileModel,
} from '../../content-distribution/models/organisation.model';
import { ResultWrapper } from '@seahorse/common';
import { DataContextService } from '@seahorse/common';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { DataService } from '@seahorse/domain';

@Injectable()
export class AccountDataService implements DataService<OrganisationModel> {
  constructor(private dataContext: DataContextService) {}

  searchOrganisations(
    searchQuery?: string,
    pageIndex?: number,
    pageSize?: number,
    showInactive: boolean = false
  ): Observable<ResultWrapper<OrganisationModel[]>> {
    const queryParams = [`showInactive=${showInactive}`, 'sortfield=CreatedOn'];

    if (searchQuery !== undefined && searchQuery !== null) {
      queryParams.push(`searchTerm=${encodeURIComponent(searchQuery)}`);
    }

    if (pageIndex !== undefined && pageIndex !== null) {
      queryParams.push(`pindex=${pageIndex}`);
    }

    if (pageSize !== undefined && pageSize !== null) {
      queryParams.push(`psize=${pageSize}`);
    }

    const queryString = queryParams.join('&');
    const url = `organisations/search?${queryString}`;

    return this.dataContext.get<OrganisationModel[]>(url);
  }

  getOrganisation(id: string): Observable<ResultWrapper<OrganisationModel>> {
    return this.dataContext.get<OrganisationModel>(`organisations/${id}`);
  }

  getOrganisations(): Observable<ResultWrapper<OrganisationModel[]>> {
    return this.dataContext.get<OrganisationModel[]>(`organisations`);
  }

  getProfiles(
    pageIndex: number,
    pageSize: number,
    organisationId: Guid
  ): Observable<ResultWrapper<ProfileModel[]>> {
    return this.dataContext.get<ProfileModel[]>(
      `profiles?organisationId=${organisationId}&pindex=${pageIndex}&psize=${pageSize}`
    );
  }

  getById(id: number): Observable<ResultWrapper<OrganisationModel>> {
    throw new Error('Method not implemented.');
  }

  add(model: OrganisationModel): Observable<ResultWrapper<OrganisationModel>> {
    return this.dataContext.post<OrganisationModel>(`organisations`, model);
  }

  update(
    model: OrganisationModel
  ): Observable<ResultWrapper<OrganisationModel>> {
    return this.dataContext.put<OrganisationModel>(
      `organisations/${model.id}`,
      model
    );
  }

  addOrganisationOwner(
    model: OrganisationOwnerModel
  ): Observable<ResultWrapper<OrganisationOwnerModel>> {
    return this.dataContext.post<OrganisationOwnerModel>(
      `organisations/owner`,
      model
    );
  }

  updateOrganisationOwner(
    model: OrganisationOwnerModel
  ): Observable<ResultWrapper<OrganisationOwnerModel>> {
    return this.dataContext.put<OrganisationOwnerModel>(
      `organisations/owner/${model.organisationId}`,
      model
    );
  }

  delete(
    accountId: OrganisationModel['id']
  ): Observable<ResultWrapper<OrganisationModel>> {
    return this.dataContext.delete<OrganisationModel>(
      `organisations/${accountId}`
    );
  }

  addProfile(model: ProfileModel): Observable<ResultWrapper<ProfileModel>> {
    return this.dataContext.post<ProfileModel>(`profiles`, model);
  }

  updateProfile(model: ProfileModel): Observable<ResultWrapper<ProfileModel>> {
    return this.dataContext.put<ProfileModel>(`profiles/${model.id}`, model);
  }

  getProducts(): Observable<ResultWrapper<ProductModel[]>> {
    return this.dataContext.get<ProductModel[]>(`profiles/products`);
  }
}
