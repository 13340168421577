import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from '@angular/core';

import { CollectionDisplay } from '../../../layout/models/collection-display.model';
import { SelectedSearchModel } from '../../../layout/models/selectedSearch.model';
import { SearchService } from '../../../layout/services/search-service';

@Component({
  selector: 'ca-user-header',
  templateUrl: './user-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserHeaderComponent {
  currentModuleName = '';
  search = new SelectedSearchModel(null, null);
  searchResultCount: number = null;
  selectedView = CollectionDisplay.Tiles;
  view = CollectionDisplay;
  includeInactiveUsers = false;

  @Output() includeInactiveUsersChanged: EventEmitter<boolean> =
    new EventEmitter();

  constructor(private searchService: SearchService) {
    this.currentModuleName = this.searchService.currentModuleName;
    const includeInactiveUsers = localStorage.getItem('includeInactiveUsers');
    if (includeInactiveUsers) {
      this.includeInactiveUsers = JSON.parse(includeInactiveUsers);
    }
  }

  open() {
    this.searchService.open();
  }

  setSelectedView(view: CollectionDisplay) {
    switch (view) {
      case CollectionDisplay.List:
        this.selectedView = CollectionDisplay.List;
        break;
      case CollectionDisplay.Tiles:
        this.selectedView = CollectionDisplay.Tiles;
        break;
    }

    this.searchService.setSelectedView(view);
  }

  includeInactiveUsersClick(event: { target: { checked: boolean } }) {
    this.includeInactiveUsers = event.target.checked;
    localStorage.setItem('includeInactiveUsers', String(event.target.checked));
    this.includeInactiveUsersChanged.emit(this.includeInactiveUsers);
  }
}
