import { Component, Inject, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ResultStatus } from '@seahorse/common';
import { IdentityService } from '../../../../../core/services/identity.service';
import { NotificationService } from '@seahorse/common';
import { InteractionService } from '../../../../../shared/services/interaction.service';
import * as moment from 'moment';

import { TaskModel } from '../../models/task.model';
import { TaskDataService } from '../../services/task-data.service';
import { FollowUpTaskModalComponent } from '../follow-up-task-modal/follow-up-task-modal.component';
import { ComboBoxOption, NewItemConfig } from '@seahorse/forms';
import { CreateTaskGroupModalForm } from './task-group-modal.form';
import { TaskGroupDataService } from '../../../../../task-group/services/task-group-data.service';
import { ResultWrapper } from '@seahorse/common';
import { TaskGroupModel } from '../../../../../task-group/models/task-group.model';
import { TaskGroupModelService } from '../../../../../task-group/services/task-group-model.service';

@Component({
  selector: 'ca-task-modal',
  templateUrl: './task-modal.component.html',
})
export class TaskModalComponent implements OnInit {
  isNew = true;
  saveStarted: boolean;
  users: any[];
  task: TaskModel;
  submitted: boolean;
  dueDate: NgbDate;
  tasks: TaskModel[];
  currentUserId: string;
  form = CreateTaskGroupModalForm();
  newTaskGroupConfig: NewItemConfig;
  taskGroupOptions = new Array<ComboBoxOption>();
  @Input() loadTaskGroupsExternally = false;
  @Input() isLoadingTaskGroupOptions = false;
  @Input() taskGroups = new Array<TaskGroupModel>();

  constructor(
    public modal: NgbActiveModal,
    public identityService: IdentityService,
    private notificationService: NotificationService,
    private modalService: NgbModal,
    private taskDataService: TaskDataService,
    private translateService: TranslateService,
    @Inject('TaskInteractionService')
    private tasksInteractionService: InteractionService,
    private taskGroupDataService: TaskGroupDataService,
    private taskGroupModelService: TaskGroupModelService
  ) {
    this.currentUserId = this.identityService.identity.id;

    this.translateService.get('taskGroup.addNewTaskGroup').subscribe({
      next: (result) => {
        this.newTaskGroupConfig = { label: result } as NewItemConfig;
      },
    });
  }

  ngOnInit() {
    if (!this.isNew) {
      if (this.task.dueDate) {
        const dueDate = moment.utc(this.task.dueDate).local();
        this.dueDate = new NgbDate(
          dueDate.year(),
          dueDate.month() + 1,
          dueDate.date()
        );
      }
    }
    this.isLoadingTaskGroupOptions = true;
    if (this.loadTaskGroupsExternally) {
      this.taskGroupOptions = this.taskGroups
        .filter(
          (x) =>
            (x.objectType == null || x.objectType == this.task.objectType) &&
            (x.objectId == null || x.objectId == this.task.objectId)
        )
        .map((taskGroup) => {
          return {
            label: taskGroup.name,
            value: taskGroup.id,
          } as ComboBoxOption;
        });
      this.isLoadingTaskGroupOptions = false;
    } else {
      this.taskGroupDataService
        .getByObject(this.task.objectType, this.task.objectId)
        .subscribe({
          next: (response: ResultWrapper<Array<TaskGroupModel>>) => {
            if (response.hasResult) {
              this.taskGroupOptions = response.result.map((taskGroup) => {
                return {
                  label: taskGroup.name,
                  value: taskGroup.id,
                } as ComboBoxOption;
              });
              this.taskGroups = response.result;
            } else {
              this.notificationService.showError(
                response.messages.map((x) => x.message).join('\n'),
                this.translateService.instant('shared.terms.failed')
              );
            }
          },
          error: (e) => {
            this.notificationService.showError(
              e.error.messages.map((x) => x.message).join('\n'),
              this.translateService.instant('shared.terms.failed')
            );
            this.isLoadingTaskGroupOptions = false;
          },
          complete: () => (this.isLoadingTaskGroupOptions = false),
        });
    }

    if (this.task.taskGroupId)
      this.form.setValue({ selectedFormGroupId: this.task.taskGroupId });
  }

  save() {
    if (!TaskModel.validate(this.task)) {
      this.submitted = true;
      return;
    }

    this.saveStarted = true;

    if (this.task.assignedTo && this.task.assignedToChanged) {
      this.taskDataService
        .sendAssignedToNotification(this.task)
        .subscribe((result) => {
          if (result && result.status === ResultStatus.OK) {
            this.notificationService.showSuccess(
              this.translateService.instant('user.emailSent'),
              this.translateService.instant('shared.terms.success')
            );
          }
        });
    }

    const formValue = this.form.getRawValue();
    if (formValue.selectedFormGroupId) {
      if (typeof formValue.selectedFormGroupId === 'string') {
        const taskGroup = this.taskGroupModelService.createFromTask(
          this.task,
          formValue.selectedFormGroupId
        ).outputTaskGroup;
        this.taskGroupDataService.add(taskGroup).subscribe({
          next: (response) => {
            if (response.hasResult) this.task = response.result.tasks[0];
            else
              this.notificationService.displayErrorNotification(
                response.messages
              );
          },
          error: (e) => {
            this.notificationService.displayErrorNotification(e.error.messages);
            this.saveStarted = false;
          },
          complete: () => this.modal.close(this.task),
        });
        // } else {
        // Theoretical, currently we go to the details page when updating a task
        // TODO: Implement updating the task groups
      }
      // } else if (this.task.taskGroupId) {
      // Theoretical, currently we go to the details page when updating a task
      // TODO: Implement updating the task groups
    } else {
      this.tasksInteractionService
        .modify(this.task)
        .subscribe((r) => this.modal.close(r.result));
    }
  }

  followUp() {
    const modalRef = this.modalService.open(FollowUpTaskModalComponent, {
      backdrop: 'static',
    });
    modalRef.componentInstance.task = new TaskModel();
    modalRef.componentInstance.users = this.users;

    modalRef.result
      .then((result) => {
        if (result) {
          result.dueDate = this.task.dueDate;
          result.createdFromTaskId = this.task.id;
          result.taskType = this.task.taskType;
          result.status = this.task.status;
          result.remarks = this.task.remarks;
          result.objectId = this.task.objectId;
          result.objectType = this.task.objectType;
          result.taskGroupId = this.task.taskGroupId;

          this.taskDataService.add(result).subscribe((response) => {
            if (response.result) {
              this.notificationService.showSuccess(
                this.translateService.instant('tasks.task.followUpAdded')
              );

              this.task.followUpTasks.push(response.result);
            }

            modalRef.close();
          });
        }
      })
      .catch();
  }

  onDateChanged(d) {
    this.task.dueDate = d.dateString;
  }
}
