<div class="date-period-picker d-flex">
  <div class="date-period-picker-display" style="width: max-content">
    <button
      (click)="selectionConfirmed = false"
      type="button"
      class="btn btn-link btn-picker-text"
      title="{{ 'shared.datepicker.selectedPeriod' | translate }}"
      [autoClose]="'outside'"
      [ngbPopover]="datePeriodPickerPopoverHTML"
      placement="{{ placement }}"
      #p="ngbPopover"
    >
      {{ dateDisplay() }}
      <span class="fa-regular fa-chevron-down"></span>
    </button>
  </div>

  <div class="date-period-picker-navigation" style="width: max-content">
    <button
      type="button"
      class="btn btn-link"
      title="{{ 'shared.datepicker.previousPeriod' | translate }}"
      (click)="previous()"
    >
      <span class="fa-regular fa-chevron-left"></span>
    </button>
    <button
      type="button"
      class="btn btn-link"
      title="{{ 'shared.datepicker.nextPeriod' | translate }}"
      (click)="next()"
    >
      <span class="fa-regular fa-chevron-right"></span>
    </button>
  </div>

  <ng-template #datePeriodPickerPopoverHTML>
    <div class="date-period-picker-popover">
      <select
        *ngIf="usePeriodInterval"
        [(ngModel)]="periodInterval"
        (change)="onChangePeriodInterval()"
        id="periodInterval"
        class="form-control mb-2"
      >
        <option selected [ngValue]="null">
          {{ 'shared.datepicker.selectedPeriod' | translate }}
        </option>
        <option
          *ngFor="let periodIntervalListOption of periodIntervalList"
          [ngValue]="periodIntervalListOption"
        >
          {{ periodIntervalListOption | periodIntervalDisplay }}
        </option>
      </select>
      <div class="calendars-container">
        <div class="calendar-container">
          <ngb-datepicker #dp1 [(ngModel)]="dateCalendarFrom"></ngb-datepicker>
        </div>
        <div class="calendar-container">
          <ngb-datepicker #dp2 [(ngModel)]="dateCalendarTo"></ngb-datepicker>
        </div>
      </div>
      <div class="text-right">
        <button class="btn btn-link" (click)="popover.close()">
          {{ 'shared.terms.cancel' | translate }}
        </button>
        <button class="btn btn-success" (click)="setFromCalendar()">
          {{ 'shared.terms.ok' | translate }}
        </button>
      </div>
      <div class="date-period-picker-popover-footer">
        <div class="d-flex align-items-stretch">
          <button
            type="button"
            class="btn btn-secondary mr-2"
            (click)="previous()"
          >
            {{ 'shared.terms.previous' | translate }}
            <ng-container
              *ngIf="
                periodInterval;
                then periodIntervalDisplay;
                else selectedPeriodDisplay
              "
            >
            </ng-container>
          </button>
          <button type="button" class="btn btn-secondary" (click)="next()">
            {{ 'shared.terms.next' | translate }}
            <ng-container
              *ngIf="
                periodInterval;
                then periodIntervalDisplay;
                else selectedPeriodDisplay
              "
            >
            </ng-container>
          </button>
        </div>
      </div>
    </div>
  </ng-template>
</div>

<ng-template #periodIntervalDisplay>{{
  periodInterval | periodIntervalDisplay | lowercase
}}</ng-template>
<ng-template #selectedPeriodDisplay>{{ intervalInDays() }}</ng-template>
