import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ContentDistributionRecipientModel } from '../../../../content-distribution/models/content-distribution-recipient.model';

@Component({
  selector: 'ca-company-details-header',
  templateUrl: './company-details-header.component.html',
})
export class CompanyDetailsHeaderComponent implements OnDestroy {
  @Input() companyId: number;
  @Input() saveStarted = false;
  @Input() showInviteButton = false;

  @Output() addAddress = new EventEmitter<void>();
  @Output() addContact = new EventEmitter<void>();
  @Output() addFinancials = new EventEmitter<void>();
  @Output() addInvoice = new EventEmitter<void>();
  @Output() deactivate = new EventEmitter<void>();
  @Output() inviteSent = new EventEmitter<
    ContentDistributionRecipientModel[]
  >();

  isExporting = false;

  private _subscriptions = new Subscription();

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  exportButtonClicked() {
    this.isExporting = true;
  }

  inviteCompany() {
    this.inviteSent.emit();
  }
}
