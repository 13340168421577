import { Component, Input } from '@angular/core';

import { NauticalVisitDetailsModel } from '@seahorse/domain';

@Component({
  selector: 'visit-ship',
  templateUrl: './visit-ship.component.html',
})
export class VisitShipComponent {
  @Input() ship: NauticalVisitDetailsModel['ship'];
  @Input() loadShip: boolean;

  constructor() {
    this.ship = null;
    this.loadShip = false;
  }
}
