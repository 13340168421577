<div class="page-header row no-gutters">
  <div class="col-sm-6">
    <span class="include-inactive-users bold"
      >Include inactive users
      <input
        type="checkbox"
        [(ngModel)]="includeInactiveUsers"
        (click)="includeInactiveUsersClick($event)"
    /></span>
  </div>
  <div class="col-sm-6 text-right">
    <div class="btn-group">
      <button class="btn btn-hover-success" type="button" (click)="open()">
        <i class="fa-regular fa-plus"></i>
        <span
          >{{ 'shared.terms.new' | translate }} {{ currentModuleName }}
        </span>
      </button>
      <button
        class="btn btn-link dropdown-toggle"
        id="ddl_header_map_display"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        data-offset="0,1"
      >
        <span class="text-muted">{{ 'shared.terms.view' | translate }}: </span>
        <span>{{ selectedView }}</span>
      </button>
      <div
        class="dropdown-menu dropdown-menu-right"
        aria-labelledby="ddl_header_map_display"
      >
        <button class="dropdown-item" (click)="setSelectedView(view.List)">
          {{ view.List }}
        </button>
        <button class="dropdown-item" (click)="setSelectedView(view.Tiles)">
          {{ view.Tiles }}
        </button>
      </div>
    </div>
  </div>
</div>
