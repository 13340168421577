<div class="modal-header align-items-center">
  <h1 class="modal-title">{{ title }}</h1>
  <button type="button" class="btn btn-link text-dark" (click)="dismiss()">
    <i class="fa-solid fa-times fa-sm fa-fw"></i>
  </button>
</div>
<div class="modal-body">
  <div class="form-row">
    <div class="form-group col-12">
      <label
        class="d-flex justify-content-between"
        for="typeahead-nauticalShip"
      >
        <b [ngClass]="{ required: nauticalShipRequired }">{{
          'nautical.ship.pickerDialog.selectNauticalShip' | translate
        }}</b>
        <span *ngIf="nauticalShipsLoading">
          <i class="fa-regular fa-spinner fa-spin fa-fw"></i>
        </span>
      </label>
      <div class="input-group">
        <input
          [(ngModel)]="nauticalShip"
          [class.is-invalid]="nauticalShipsLoadingFailed"
          [ngbTypeahead]="searchNauticalShip"
          [inputFormatter]="nauticalShipFormatter"
          [resultFormatter]="nauticalShipsFormatter"
          [editable]="editable"
          [placeholder]="
            ('nautical.ship.pickerDialog.searchNauticalShip' | translate) +
            '...'
          "
          [required]="nauticalShipRequired"
          id="typeahead-nauticalShip"
          name="nauticalShip"
          type="text"
          class="form-control"
        />
        <div class="input-group-append">
          <button
            (click)="setNull()"
            type="button"
            class="btn bg-transparent br-0"
            style="margin-left: -37px; z-index: 100"
          >
            <i class="fa-solid fa-times fa-xs fa-fw"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-link flex-fill" (click)="dismiss()">
    {{ 'shared.terms.cancel' | translate }}
  </button>
  <button
    [disabled]="
      nauticalShipRequired &&
      (!nauticalShip || (nauticalShip && nauticalShipsLoading))
    "
    (click)="ok()"
    type="button"
    class="btn btn-success flex-fill"
  >
    <span *ngIf="!nauticalShipRequired && !nauticalShip">
      {{ 'shared.terms.skip' | translate }}
      <i class="fa-solid fa-long-arrow-alt-right"></i>
    </span>
    <span
      *ngIf="nauticalShipRequired || (!nauticalShipRequired && nauticalShip)"
    >
      {{ 'shared.terms.confirm' | translate }}
    </span>
  </button>
</div>
