import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Optional,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { SeahorseTemplateDirective } from '@seahorse/common';

import { ModalAction, ModalResponse } from './modal.model';

@Component({
  selector: 'temp-modal',
  templateUrl: './modal.template.html',
  standalone: true,
  imports: [CommonModule, SeahorseTemplateDirective, TranslateModule],
})
export class ModalTemplate {
  @Input() title: string;
  @Input() disabled = false;
  @Input() isTable = false;
  @Input() hideActions = false;
  @Input() deleteAction = false;

  @Output() action = new EventEmitter<ModalResponse>();

  @ViewChild(SeahorseTemplateDirective, { static: true })
  templateRefDirective: SeahorseTemplateDirective;

  get templateRef() {
    return this.templateRefDirective.templateRef;
  }

  constructor(
    // injected only when instantiated through template
    @Optional()
    private _activeModal?: NgbActiveModal
  ) {}

  delete() {
    this.action.emit({ action: ModalAction.Delete });
  }

  cancel() {
    const action = { action: ModalAction.Close };
    this.action.emit(action);

    this._activeModal?.close(action);
  }

  confirm() {
    this.action.emit({ action: ModalAction.Confirm });
  }
}
