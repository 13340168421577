import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { CompanyFinancialModel } from '@seahorse/domain';
import { ContentDistributionDataService } from '../../../../content-distribution/services/content-distribution-data.service';
import { IdentityService } from '../../../../core/services/identity.service';
import * as _ from 'underscore';

@Component({
  selector: 'ca-financial-form',
  templateUrl: './financial-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinancialFormComponent implements OnInit {
  @Input() model: CompanyFinancialModel;
  @Input() submitted = false;

  onlyReadPermission: boolean;
  templates: any[] = [];

  constructor(
    private chRef: ChangeDetectorRef,
    private contentDistributionService: ContentDistributionDataService,
    private identity: IdentityService
  ) {
    this.onlyReadPermission = false;

    this.loadTemplates();
  }

  ngOnInit() {
    const writePermission: boolean = this.identity.hasPermission(
      'companies.company_financials_update'
    );
    const readPermission: boolean = this.identity.hasPermission(
      'companies.company_financials'
    );

    if (!writePermission && readPermission) {
      this.onlyReadPermission = true;
    }
  }

  loadTemplates() {
    const preference = _.find(this.identity.getPreferences(), (p) => {
      return 'language' === p.name.toLowerCase();
    });

    const language =
      preference && preference.fieldValue ? preference.fieldValue : null;

    this.contentDistributionService
      .getExportOptions(language)
      .subscribe((exportOptionResult) => {
        if (exportOptionResult.hasResult) {
          this.templates = [];

          const exportOption = _.find(
            exportOptionResult.result,
            (f) => f.mappingKey === '$invoicing_invoice'
          );
          if (exportOption && exportOption.options) {
            this.templates = this.templates.concat(
              _.map(
                _.filter(
                  exportOption.options,
                  (f) => f.documentType.distributionService !== null
                ),
                (opt) => ({ id: opt.templateId, name: opt.templateName })
              )
            );
          }

          this.chRef.detectChanges();
        }
      });
  }

  onEndDateChanged(d) {
    this.model.endsOn = d.dateString;
  }
}
