<div class="p-4 m-4 d-flex justify-content-center">

  <h3>Testing AAD EI</h3>
</div>


<div class="p-4 m-4 d-flex justify-content-center">
  <span *ngIf="accountName">{{accountName}}</span>
</div>

<div class="p-4 m-4 d-flex justify-content-center">
  <button type="button" *ngIf="!isLoggedIn" class="btn btn-primary" (click)="onLogin()">Login with Azure</button>
  <button type="button" *ngIf="isLoggedIn" class="btn btn-primary" (click)="onLogout()">Logout</button>
</div>
