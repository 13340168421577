<table class="table table-clickable">
  <thead *ngIf="showHeader">
    <tr>
      <th scope="col"></th>
      <th scope="col">{{ 'shared.terms.name' | translate }}</th>
      <th scope="col">{{ 'companies.contact.model.function' | translate }}</th>
      <th scope="col"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let contact of contacts">
      <td
        (click)="onEvent.emit({ action: 'editContact', data: contact })"
        title="{{ contact.status | contactStatus }}"
        style="width: 1%"
        class="pr-0"
      >
        <i *ngIf="contact.status === 1" class="fa-regular fa-check mr-2"></i>
        <i *ngIf="contact.status === 2" class="fa-solid fa-times mr-2"></i>
        <i *ngIf="contact.status === 3" class="fa-regular fa-cross mr-2"></i>
      </td>
      <td
        [ngClass]="{ 'text-muted': contact.status !== 1 }"
        (click)="onEvent.emit({ action: 'editContact', data: contact })"
      >
        {{ contact.nameInfo }}
      </td>
      <td
        [ngClass]="{ 'text-muted': contact.status !== 1 }"
        (click)="onEvent.emit({ action: 'editContact', data: contact })"
      >
        {{ contact.function }}
      </td>
      <td class="btn-list">
        <div class="dropdown p-0">
          <button
            *hasPermission="'companies.company_contacts_delete'"
            type="button"
            class="btn btn-light dropdown-toggle"
            id="rowMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis-v fa-fw"></i>
          </button>
          <div class="btn dropdown-menu" aria-labelledby="rowMenuButton">
            <button
              class="dropdown-item"
              *hasPermission="'companies.company_contacts_delete'"
              (click)="onEvent.emit({ action: 'deleteContact', data: contact })"
            >
              <i class="fa-solid fa-trash fa-fw"></i>
              {{ 'shared.terms.delete' | translate }}
            </button>
          </div>
        </div>
      </td>
    </tr>
  </tbody>
</table>
