<div class="not-found" *ngIf="!areaActivities || areaActivities.length === 0">
  {{ 'map.areaActivity.areaActivitiesNotFound' | translate }}
</div>

<div *ngIf="areaActivities">
  <div class="card mb-2 mt-2" *ngFor="let areaActivity of areaActivities">
    <div
      class="cursor-pointer d-flex justify-content-between align-items-center p-2"
      [class.active-header]="areaActivity.isActive"
      role="tab"
      (click)="toggleActiveArea(areaActivity)"
    >
      <h6 class="m-1">
        <span>{{ areaActivity.name }}</span>
      </h6>
    </div>
    <div *ngIf="areaActivity.isActive">
      <ca-map-area-ship-list
        [area]="areaActivity"
        (onSelectVessel)="openVessel($event)"
      >
      </ca-map-area-ship-list>
    </div>
  </div>
</div>
