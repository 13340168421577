import { NauticalWaypointModel } from '@seahorse/domain';

export class NauticalVisitFlowMarkerModel {
  // The date object used for the marker
  actualDate: Date;
  // The type of activity
  activityType: string;
  // The date that can be used for display purposes
  displayDate: string;
  // Indicates wether the date is a new one in the series
  isNewDate: boolean;
  // The icon that can be used for display purposes
  displayIcon: string;
  // The text that can be used for display purposes (long-form)
  displayTextLong: string;
  // The text that can be used for display purposes (short-form)
  displayTextShort: string;
  // The type of marker that is used for display purposes
  displayType: string;
  // The number between 0 and 1 where the marker fits on the timeline
  position: number;
  // Indicates if the marker is finished (false) or in the future (true)
  isOpen: boolean;
  // Stores the original waypoint
  waypoint: NauticalWaypointModel;
}
