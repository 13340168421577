import { InvoiceWidgetModel } from './invoice-widget.model';
import { TaskWidgetModel } from './task-widget.model';

export enum WidgetTypes {
  Invoice,
  Task = 1,
}

export interface WidgetModel {
  id?: number;
  name: string;
  type: WidgetTypes;
  organisationId: string;
  configuration: string;
}

export interface WidgetDesigner {
  getWidgetData(): InvoiceWidgetModel | TaskWidgetModel;
}
