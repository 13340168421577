import { Time } from '@angular/common';
import { FieldType } from './field-type-definition.model';

export type BooleanFieldRule = BaseFieldRule;

export interface DateFieldRule extends BaseFieldRule {
  minDate: Date | string | null;
  maxDate: Date | string | null;
  format: string;
}

export interface TimeFieldRule extends BaseFieldRule {
  minTime: Time | string | null;
  maxTime: Time | string | null;
  format: string;
}

export type DateTimeFieldRule = DateFieldRule;

export interface FileFieldRule extends BaseFieldRule {
  min: number;
  max: number;
  allowFileTypes: string[];
}

export interface IntegerFieldRule extends BaseFieldRule {
  cultureName: string;
  min: number | null;
  max: number | null;
  symbol: string;
  valueDescriptor: string | null;
}

export interface DecimalFieldRule extends BaseFieldRule {
  digits: number | null;
  min: number | null;
  max: number | null;
  valueDescriptor: string | null;
}

export type LinkedObjectFieldRule = BaseFieldRule;

export type LinkedObjectsFieldRule = BaseFieldRule;

export interface ListFieldRule extends BaseFieldRule {
  max: number | null;
  mustMatch: boolean;
  items: ListFieldItem[];
  valueDescriptor: string | null;
}

export interface ListFieldItem {
  value: string;
  key: string;
  color?: string;
}

export interface SingleLineTextFieldRule extends BaseFieldRule {
  minLength: number | null;
  maxLength: number | null;
  regexPattern: string;
  valueDescriptor: string | null;
}

export type MultiLineTextFieldRule = SingleLineTextFieldRule;

export const CurrencyCodes = ['EUR', 'GBP', 'USD', 'JPY', 'DKK', 'KKR'];

export interface CurrencyFieldRule extends DecimalFieldRule {
  currencyCodes: typeof CurrencyCodes;
}

export interface BaseFieldRule {
  [rule: string]: any;
  fieldType?: FieldType;
}

export class BaseFieldRule {
  // type helpers
  static boolean(rule: BaseFieldRule) {
    return rule as BooleanFieldRule;
  }
  static dateTime(rule: BaseFieldRule) {
    return rule as DateTimeFieldRule;
  }
  static date(rule: BaseFieldRule) {
    return rule as DateFieldRule;
  }
  static time(rule: BaseFieldRule) {
    return rule as TimeFieldRule;
  }
  static currency(rule: BaseFieldRule) {
    return rule as CurrencyFieldRule;
  }
  static file(rule: BaseFieldRule) {
    return rule as FileFieldRule;
  }
  static integer(rule: BaseFieldRule) {
    return rule as IntegerFieldRule;
  }
  static decimal(rule: BaseFieldRule) {
    return rule as DecimalFieldRule;
  }
  static linkedObject(rule: BaseFieldRule) {
    return rule as LinkedObjectFieldRule;
  }
  static linkedObjects(rule: BaseFieldRule) {
    return rule as LinkedObjectsFieldRule;
  }
  static list(rule: BaseFieldRule) {
    return rule as ListFieldRule;
  }
  static singleLineText(rule: BaseFieldRule) {
    return rule as SingleLineTextFieldRule;
  }
  static multiLineText(rule: BaseFieldRule) {
    return rule as MultiLineTextFieldRule;
  }
}
