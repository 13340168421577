import { CustomDataBaseModel } from '@seahorse/domain';

export class KPShipRequirementsModel extends CustomDataBaseModel {
  $nautical_ship_id: number;
  remarks: string;

  constructor() {
    super();

    this.$nautical_ship_id = null;
    this.remarks = null;
  }
}
