export class ExpenseConstant {
  static OBJECTTYPEKEY = 'st-expense';
  static OBJECTTYPEID = '$customcontent_st-expense_id';
}

export enum ExpenseGroupOptions {
  Reference = 0,
  Supplier,
  SupplierAndReference,
}

export enum ExpenseEventType {
  Search = 0, // Search the list based on the provided search criteria (API)
  Filter, // Filter the list based on the provided property (UI)
}
