import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { ServiceConnectionsDataService } from '../../service-connections/services/service-connections.service';

@Directive({
  selector: '[hasSubscription]',
})
export class HasSubscriptionDirective {
  @Input() set hasSubscription(subscription: string) {
    if (
      this.serviceConnectionsService.subscriptions.find(
        (x) => x.serviceConnection.systemCode === subscription
      )
    ) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private serviceConnectionsService: ServiceConnectionsDataService
  ) {}
}
