import { ResultWrapper } from '@seahorse/common';
import { UserModel } from '@seahorse/domain';
import { UserDataService } from '../../../user/services/user-data.service';
import * as _ from 'underscore';

import { CustomTeamModel } from './custom-team.model';
import { CustomDataBaseModel } from '@seahorse/domain';

export class CustomMemberModel extends CustomDataBaseModel {
  remarks: string;
  isActive: boolean;
  $usermanager_user_id: UserModel['id'];
  $usermanager_user_givenName: UserModel['givenName'];
  $usermanager_user_surname: UserModel['surname'];
  $customdata_membergroup_ids: CustomTeamModel['__Id'][];
  isChecked: boolean;

  constructor() {
    super();

    this.remarks = null;
    this.isActive = false;
    this.$usermanager_user_id = null;
    this.$usermanager_user_givenName = null;
    this.$usermanager_user_surname = null;
    this.$customdata_membergroup_ids = [];
    this.isChecked = false;
  }

  static fromApiModel(
    model: any,
    userDataService: UserDataService
  ): CustomMemberModel {
    const result = _.clone(model);

    if (
      result.$customdata_membergroup_ids &&
      result.$customdata_membergroup_ids.length > 0
    ) {
      result.$customdata_membergroup_ids =
        result.$customdata_membergroup_ids.split(',');
    }

    userDataService
      .get(result.$usermanager_user_id)
      .subscribe((userGetResponse: ResultWrapper<any>) => {
        if (userGetResponse.hasResult && userGetResponse.result) {
          result.$usermanager_user_givenName = userGetResponse.result.givenName;
          result.$usermanager_user_surname = userGetResponse.result.surname;
        }
      });

    return result;
  }

  static toApiModel(model: any): CustomMemberModel {
    const result = _.clone(model);

    if (model.$customdata_membergroup_ids) {
      result.$customdata_membergroup_ids =
        model.$customdata_membergroup_ids.join();
    }

    return result;
  }
}
