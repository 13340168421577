<div class="modal-header align-items-center">
  <h1 class="modal-title">{{ 'shared.terms.editAttribute' | translate }}</h1>
  <button closeModal type="button" class="btn btn-link text-dark">
    <i class="fa-solid fa-times fa-sm fa-fw"></i>
  </button>
</div>
<div class="modal-body">
  <label for="anchored">
    <b>{{
      'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.model.anchored'
        | translate
    }}</b>
  </label>
  <div class="row no-gutters my-3">
    <div class="col-12 col-sm-4">
      <div class="custom-control custom-checkbox mr-1">
        <input
          [(ngModel)]="anchored"
          type="checkbox"
          class="custom-control-input"
          id="anchored"
        />
        <label
          class="custom-control-label"
          for="anchored"
          style="overflow: initial"
          >{{
            'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.placeholder.anchored'
              | translate
          }}</label
        >
      </div>
    </div>
    <div class="col-12 col-sm-8">
      <div class="input-group mt-n2">
        <input
          [(ngModel)]="anchoredMessage"
          [placeholder]="
            'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.placeholder.anchoredMessage'
              | translate
          "
          type="text"
          class="form-control"
          id="anchoredMessage"
        />
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button closeModal type="button" class="btn btn-link flex-fill">
    {{ 'shared.terms.cancel' | translate }}
  </button>
  <button
    (click)="
      onPickAnchored.emit({
        anchored: anchored,
        anchoredMessage: anchoredMessage
      })
    "
    closeModal
    type="button"
    class="btn btn-success flex-fill"
  >
    {{ 'shared.terms.confirm' | translate }}
  </button>
</div>
