export class ObjectDefinitionExtensionsModel {
  icon?: string | undefined;
}

export const ObjectDefinitionExtensionsDictionary: Record<
  string,
  ObjectDefinitionExtensionsModel
> = {
  $companies_company: {
    icon: 'fa-regular fa-building',
  },
  // The customcontent definition for purchase and expenses will be
  // migrated to their own system object definition later
  '$customcontent_ct-expense': {
    icon: 'fa-regular fa-euro-sign',
  },
  '$customcontent_st-expense': {
    icon: 'fa-regular fa-euro-sign',
  },
  '$customcontent_ct-orderregistration': {
    icon: 'fa-regular fa-shopping-cart',
  },
  '$customcontent_st-orderregistration': {
    icon: 'fa-regular fa-shopping-cart',
  },
  '$customcontent_ct-purchaseentry': {
    icon: 'fa-regular fa-file-invoice-dollar',
  },
  '$customcontent_ct-purchaseinvoice': {
    icon: 'fa-regular fa-file-invoice-dollar',
  },
  $invoicing_invoice: {
    icon: 'fa-regular fa-euro-sign',
  },
  $files_file: {
    icon: 'fa-regular fa-paperclip',
  },
  $nautical_portvisit: {
    icon: 'fa-regular fa-exchange-alt',
  },
  $nautical_ship: {
    icon: 'fa-regular fa-ship',
  },
  $tasks_task: {
    icon: 'fa-regular fa-tasks',
  },
};
