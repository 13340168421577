<div class="card">
  <div class="card-header" *ngIf="definition && hideTitle !== true">
    {{ title === undefined || title === null ? definition.name : title }}
    <small class="text-muted" *ngIf="titleDescription"
      >&nbsp;{{ titleDescription }}</small
    >
    <div *ngIf="buttonFieldList.length" class="btn-list">
      <ng-container *ngFor="let buttonField of buttonFieldList">
        <!--
                    ! temporary fix
                    ! renderOptions don't work yet and the enumerable isn't displayed correctly
                    
                    <ca-custom-content-edit-value
                        [entry]="entry"
                        [title]="buttonField.name"
                        [fieldDefinition]="buttonField"
                        [isReadOnly]="isEditMode !== true || buttonField.isReadOnly"
                        [inlineEdit]="false"
                        [renderOption]="renderOptions[buttonField.systemCode]"
                        (onFieldChanged)="onFieldChanged.emit($event)"
                    ></ca-custom-content-edit-value>
                 -->
        <button
          (click)="onButtonClicked.emit(buttonField)"
          class="btn btn-outline-secondary btn-sm text-hover-white"
          type="button"
        >
          <div class="d-flex mb-n2">
            <b>{{ buttonField.name }}:&nbsp;</b>
            <ca-custom-content-edit-value
              [entry]="entry"
              [fieldDefinition]="buttonField"
              [isReadOnly]="true"
              [renderOption]="renderOptions[buttonField.systemCode]"
            >
            </ca-custom-content-edit-value>
          </div>
        </button>
      </ng-container>
    </div>
  </div>
  <div class="card-body" *ngIf="!definition || !entry">
    <div class="text-muted text-center">
      {{ 'customData.notFound' | translate }}
    </div>
  </div>
  <div class="card-body" *ngIf="definition && entry">
    <div
      class="card-body-group card-body-group-no-border"
      *ngIf="showBasicInfo === true"
    >
      <div class="attribute-group attribute-group-col-2">
        <div
          class="attribute-group-item"
          title="{{ 'customData.model.__CreatedOn' | translate }}"
        >
          <dt>{{ 'customData.model.__CreatedOn' | translate }}</dt>
          <dd>
            {{ entry.__CreatedOn | date: 'dd-MM-yyyy HH:mm' }}
          </dd>
        </div>
        <div
          class="attribute-group-item"
          title="{{ 'customData.model.__CreatedBy' | translate }}"
        >
          <dt>{{ 'customData.model.__CreatedBy' | translate }}</dt>
          <dd>
            {{ entry.__CreatedBy | nameFromUserId: '' | async }}
          </dd>
        </div>
      </div>
      <div class="attribute-group attribute-group-col-2">
        <div
          class="attribute-group-item"
          title="{{ 'customData.model.__LastEditedOn' | translate }}"
        >
          <dt>{{ 'customData.model.__LastEditedOn' | translate }}</dt>
          <dd>
            {{ entry.__LastEditedOn | date: 'dd-MM-yyyy HH:mm' }}
          </dd>
        </div>
        <div
          class="attribute-group-item"
          title="{{ 'customData.model.__LastEditedBy' | translate }}"
        >
          <dt>{{ 'customData.model.__LastEditedBy' | translate }}</dt>
          <dd>
            {{ entry.__LastEditedBy | nameFromUserId: '' | async }}
          </dd>
        </div>
      </div>
    </div>

    <div
      class="card-body-group card-body-group-no-border"
      *ngFor="let group of fieldGroups | keyvalue"
    >
      <ng-container *ngIf="fieldGroups.length > 1">{{
        group.key === '-1' ? '' : group.key
      }}</ng-container>
      <div
        class="attribute-group"
        *ngFor="let field of group.value"
        title="{{ field.description || field.name }}"
      >
        <ca-custom-content-edit-value
          [entry]="entry"
          [title]="field.name"
          [fieldDefinition]="field"
          [isReadOnly]="isEditMode !== true || field.isReadOnly"
          [inlineEdit]="inlineEdit"
          [submitted]="submitted"
          [orderRegistrationRequiredFields]="true"
          [renderOption]="renderOptions[field.systemCode]"
          (onFieldChanged)="onFieldChanged.emit($event)"
        >
        </ca-custom-content-edit-value>
      </div>
    </div>
  </div>
</div>
