import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NauticalPortModel } from '@seahorse/domain';
import { NauticalWaypointModel } from '@seahorse/domain';

@Component({
  selector: 'ca-nautical-geography-picker-dialog',
  templateUrl: 'nautical-geography-picker-dialog.component.html',
})
export class NauticalGeographyPickerDialogComponent {
  private _filter: string = null;

  @Input() get filter(): string {
    return this._filter;
  }
  set filter(value: string) {
    this._filter = value;
    if (this._filter && this._filter !== '') {
      this.useFilter = true;
    }
  }
  @Input() nauticalPort: NauticalPortModel = null;
  @Input() nauticalWaypoint: NauticalWaypointModel = null;
  @Input() portId: number = null;
  @Input() selectedType = 'port';

  useFilter = false;

  constructor(private activeModal: NgbActiveModal) {}

  confirm() {
    this.activeModal.close({
      selectedType: this.selectedType,
      selectedObject:
        this.selectedType === 'port'
          ? this.nauticalPort
          : this.nauticalWaypoint,
    });
  }

  dismiss() {
    this.activeModal.dismiss();
  }
}
