import { Injectable, inject } from '@angular/core';
import { Router, Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { PreferenceDataService } from '../preference/services/preference-data.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DashboardResolver implements Resolve<boolean> {
  private _preferenceDataService = inject(PreferenceDataService);
  private _router = inject(Router);

  resolve(): Observable<boolean> {
    return this._preferenceDataService.getDashboardPreference(false).pipe(
      map((res) => {
        if (res.hasResult) {
          this._router.navigate(['/custom-dashboard']);
          return true;
        } else {
          return false;
        }
      })
    );
  }
}
