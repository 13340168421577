import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { LayoutComponentsModule } from '../layout/components/layout-components.module';
import { DefaultThemeComponent } from './components/default-theme/default-theme.component';

@NgModule({
  declarations: [DefaultThemeComponent],
  imports: [CommonModule, LayoutComponentsModule, RouterModule],
})
export class ThemesModule {}
