<div class="card">
  <div class="card-header">Snelstart debtor information</div>

  <div class="card-body card-body-table">
    <sh-loading-indicator
      *ngIf="isLoading"
      class="d-flex justify-content-center w-100"
    ></sh-loading-indicator>

    <ca-company-snelstart-debtor-information-list
      *ngIf="!isLoading && relationships.length > 0"
      [relationships]="relationships"
    ></ca-company-snelstart-debtor-information-list>
  </div>

  <div class="card-body" *ngIf="!isLoading && relationships.length === 0">
    <div class="text-muted text-center">There are no relationships</div>
  </div>
</div>
