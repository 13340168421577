import { Component, Input } from '@angular/core';

import { NauticalVisitDetailsModel } from '@seahorse/domain';

@Component({
  selector: 'visit-agent',
  templateUrl: 'visit-agent.component.html',
})
export class VisitAgentComponent {
  @Input() agent: NauticalVisitDetailsModel['portAgent'];
  @Input() loadAgent: boolean;

  constructor() {
    this.agent = null;
    this.loadAgent = false;
  }
}
