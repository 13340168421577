<div *ngIf="!loading" class="card-body-group card-body-group-no-border">
  <div class="row no-gutters attribute-group">
    <div class="col-12 attribute-group-item">
      <dt class="mb-0">{{ 'shared.terms.name' | translate }}</dt>
      <dd>{{ taskDefinitionList.name }}</dd>
    </div>
    <div class="col-12 attribute-group-item">
      <dt class="mb-0">{{ 'shared.terms.description' | translate }}</dt>
      <dd *ngIf="taskDefinitionList.description">
        {{ taskDefinitionList.description }}
      </dd>
    </div>
  </div>
</div>
<sh-loading-indicator
  *ngIf="loading"
  class="d-block text-center"
></sh-loading-indicator>
