import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'invoiceCurrencySymbol',
})
export class InvoiceCurrencySymbolPipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case 'EUR':
        return '€';
      case 'USD':
        return '$';
      case 'GBP':
        return '£';
      case 'DKK':
        return 'kr';
      case 'NKK':
        return 'kr';
      default:
        return '';
    }
  }
}
