<div
  *ngIf="
    expenseFieldOptions['orderRegistration'].isVisible &&
    !isLoadingOrderRegistration
  "
  class="row"
>
  <div class="col-12">
    <ca-order-registration-picker-dialog-form
      [(selectedOrderRegistration)]="selectedOrderRegistration"
      [isRequired]="expenseFieldOptions['orderRegistration'].isRequired"
      [orderRegistrations]="orderRegistrations"
      [loadingOrderRegistrations]="
        expenseFieldOptions['orderRegistration'].isReadOnly
          ? true
          : isLoadingOrderRegistrations
      "
      (selectedOrderRegistrationChange)="onSelectedOrderRegistrationChanged()"
    ></ca-order-registration-picker-dialog-form>
  </div>
</div>
<div
  *ngIf="expenseFieldOptions['tariff'].isVisible && !isLoadingTariff"
  class="row"
>
  <div class="col-12">
    <ca-tariff-picker-form
      [(selectedTariff)]="selectedTariff"
      [isRequired]="expenseFieldOptions['tariff'].isRequired"
      [loadingTariffGroups]="
        expenseFieldOptions['tariff'].isReadOnly ? true : undefined
      "
      (selectedTariffChange)="onSelectedTariffChanged()"
    ></ca-tariff-picker-form>
  </div>
</div>
<div
  *ngIf="
    expenseFieldOptions['customer'].isVisible ||
    expenseFieldOptions['supplier'].isVisible
  "
  class="row"
>
  <div
    class="col-12 col-sm-6"
    [ngClass]="{ invisible: !expenseFieldOptions['customer'].isVisible }"
  >
    <ca-company-picker
      [title]="expenseFieldOptions['customer'].label"
      [(selectedCompany)]="selectedCustomer"
      [selectedCompanyLoading]="isLoadingCustomer"
      [isRequired]="expenseFieldOptions['customer'].isRequired"
      [isReadOnly]="expenseFieldOptions['customer'].isReadOnly"
      [submitted]="submitted"
      (selectedCompanyChange)="onSelectedCustomerChanged()"
    ></ca-company-picker>
  </div>
  <div
    class="col-12 col-sm-6"
    [ngClass]="{ invisible: !expenseFieldOptions['supplier'].isVisible }"
  >
    <ca-company-picker
      [title]="expenseFieldOptions['supplier'].label"
      [(selectedCompany)]="selectedSupplier"
      [selectedCompanyLoading]="isLoadingSupplier"
      [isRequired]="expenseFieldOptions['supplier'].isRequired"
      [isReadOnly]="expenseFieldOptions['supplier'].isReadOnly"
      [submitted]="submitted"
      (selectedCompanyChange)="onSelectedSupplierChanged()"
    ></ca-company-picker>
  </div>
</div>
<div *ngIf="expenseFieldOptions['description'].isVisible" class="row">
  <div class="col-12">
    <div class="form-group">
      <label
        for="description"
        [ngClass]="{ required: expenseFieldOptions['description'].isRequired }"
      >
        <b>{{ expenseFieldOptions['description'].label }}</b>
      </label>
      <div class="input-group">
        <textarea
          [(ngModel)]="expense.description"
          [required]="expenseFieldOptions['description'].isRequired"
          [disabled]="expenseFieldOptions['description'].isReadOnly"
          id="description"
          name="description"
          type="text"
          class="form-control"
          rows="6"
        ></textarea>
      </div>
    </div>
  </div>
</div>
<div
  *ngIf="
    expenseFieldOptions['quantity'].isVisible ||
    expenseFieldOptions['payByDate'].isVisible
  "
  class="row"
>
  <div
    class="col-12 col-sm-6"
    [ngClass]="{ invisible: !expenseFieldOptions['quantity'].isVisible }"
  >
    <div class="form-group">
      <label
        for="quantity"
        [ngClass]="{ required: expenseFieldOptions['quantity'].isRequired }"
      >
        <b>{{ expenseFieldOptions['quantity'].label }}</b>
      </label>
      <div class="input-group">
        <input
          [(ngModel)]="expense.quantity"
          [required]="expenseFieldOptions['quantity'].isRequired"
          [disabled]="expenseFieldOptions['quantity'].isReadOnly"
          [placeholder]="
            'customModules.expenses.placeholder.quantity' | translate
          "
          id="quantity"
          name="quantity"
          type="number"
          step="1"
          min="0"
          class="form-control"
        />
      </div>
    </div>
  </div>
  <div
    class="col-12 col-sm-6"
    [ngClass]="{ invisible: !expenseFieldOptions['payByDate'].isVisible }"
  >
    <div class="form-group">
      <label
        for="payByDate"
        [ngClass]="{ required: expenseFieldOptions['payByDate'].isRequired }"
      >
        <b>{{ expenseFieldOptions['payByDate'].label }}</b>
      </label>
      <temp-date-time-picker
        [(dateTime)]="expense.payByDate"
        [required]="expenseFieldOptions['payByDate'].isRequired"
        [isReadOnly]="expenseFieldOptions['payByDate'].isReadOnly"
        [hideTime]="true"
        [isContained]="true"
      ></temp-date-time-picker>
    </div>
  </div>
</div>
<div
  *ngIf="
    expenseFieldOptions['amount'].isVisible ||
    expenseFieldOptions['amountActual'].isVisible
  "
  class="row"
>
  <div
    class="col-12 col-sm-6"
    [ngClass]="{ invisible: !expenseFieldOptions['amount'].isVisible }"
  >
    <div class="form-group">
      <label
        for="amount"
        [ngClass]="{ required: expenseFieldOptions['amount'].isRequired }"
      >
        <b>{{ expenseFieldOptions['amount'].label }}</b>
      </label>
      <div class="input-group">
        <temp-numeric-input
          [(number)]="expense.amount"
          [required]="expenseFieldOptions['amount'].isRequired"
          [disabled]="expenseFieldOptions['amount'].isReadOnly"
          [submitted]="submitted"
          [numDecimals]="2"
          [placeholder]="
            'customModules.expenses.placeholder.amount' | translate
          "
          [appendList]="currencyValues"
          [prependText]="expense.currency[0]"
          (appendListItemSelected)="onSelectedCurrencyChanged($event)"
          class="w-100"
        ></temp-numeric-input>
      </div>
    </div>
  </div>
  <div
    class="col-12 col-sm-6"
    [ngClass]="{ invisible: !expenseFieldOptions['amountActual'].isVisible }"
  >
    <div class="form-group">
      <label
        for="amountActual"
        [ngClass]="{ required: expenseFieldOptions['amountActual'].isRequired }"
      >
        <b>{{ expenseFieldOptions['amountActual'].label }}</b>
      </label>
      <div class="input-group">
        <temp-numeric-input
          [(number)]="expense.amountActual"
          [required]="expenseFieldOptions['amountActual'].isRequired"
          [disabled]="expenseFieldOptions['amountActual'].isReadOnly"
          [submitted]="submitted"
          [numDecimals]="2"
          [placeholder]="
            'customModules.expenses.placeholder.amountActual' | translate
          "
          [appendList]="currencyValues"
          [prependText]="expense.currency[0]"
          (appendListItemSelected)="onSelectedCurrencyChanged($event)"
          class="w-100"
        ></temp-numeric-input>
      </div>
    </div>
  </div>
</div>
<div
  *ngIf="
    expenseFieldOptions['commissionFee'].isVisible ||
    expenseFieldOptions['commissionFeeType'].isVisible
  "
  class="row"
>
  <div
    class="col-12 col-sm-6"
    [ngClass]="{ invisible: !expenseFieldOptions['commissionFee'].isVisible }"
  >
    <div class="form-group">
      <label
        for="commissionFee"
        [ngClass]="{
          required: expenseFieldOptions['commissionFee'].isRequired
        }"
      >
        <b>{{ expenseFieldOptions['commissionFee'].label }}</b>
      </label>
      <div class="input-group">
        <temp-numeric-input
          [(number)]="expense.commissionFee"
          [required]="expenseFieldOptions['commissionFee'].isRequired"
          [disabled]="expenseFieldOptions['commissionFee'].isReadOnly"
          [submitted]="submitted"
          [numDecimals]="2"
          [placeholder]="
            'customModules.expenses.placeholder.commissionFee' | translate
          "
          [prependText]="prependText"
          class="w-100"
        ></temp-numeric-input>
      </div>
    </div>
  </div>
  <div
    class="col-12 col-sm-6"
    [ngClass]="{
      invisible:
        commissionFeeTypes?.length > 0 &&
        !expenseFieldOptions['commissionFeeType'].isVisible
    }"
  >
    <div class="input-group">
      <label
        for="commissionFeeType"
        class="w-100"
        [ngClass]="{
          required: expenseFieldOptions['commissionFeeType'].isRequired
        }"
      >
        <b>{{ expenseFieldOptions['commissionFeeType'].label }}</b>
      </label>
      <select
        [(ngModel)]="expense.commissionFeeType"
        [required]="expenseFieldOptions['commissionFeeType'].isRequired"
        [disabled]="expenseFieldOptions['commissionFeeType'].isReadOnly"
        (change)="onCommissionFeeTypeChanged()"
        id="commissionFeeTypes"
        name="commissionFeeTypes"
        class="form-control"
      >
        <option [ngValue]="null">
          {{ 'shared.terms.select' | translate }}...
        </option>
        <option
          *ngFor="let commissionFeeType of commissionFeeTypes"
          [ngValue]="commissionFeeType"
        >
          {{ commissionFeeType | tariffAmountType }}
        </option>
      </select>
    </div>
  </div>
</div>
<div
  *ngIf="
    expenseFieldOptions['charged'].isVisible ||
    expenseFieldOptions['remarks'].isVisible
  "
  class="row"
>
  <div
    class="col-12 col-sm-6"
    [ngClass]="{ invisible: !expenseFieldOptions['charged'].isVisible }"
  >
    <div class="form-group">
      <label
        [ngClass]="{ required: expenseFieldOptions['charged'].isRequired }"
      >
        <b>{{ expenseFieldOptions['charged'].label }}</b>
      </label>
      <div class="input-group form-check">
        <input
          [(ngModel)]="expense.charged"
          [required]="expenseFieldOptions['charged'].isRequired"
          [disabled]="expenseFieldOptions['charged'].isReadOnly"
          id="charged"
          type="checkbox"
          class="form-check-input"
        />
        <label for="charged" class="form-check-label">
          {{ 'customModules.expenses.placeholder.charged' | translate }}
        </label>
      </div>
    </div>
  </div>
  <div
    class="col-12 col-sm-6"
    [ngClass]="{ invisible: !expenseFieldOptions['remarks'].isVisible }"
  >
    <div class="form-group">
      <label
        for="remarks"
        [ngClass]="{ required: expenseFieldOptions['remarks'].isRequired }"
      >
        <b>{{ expenseFieldOptions['remarks'].label }}</b>
      </label>
      <div class="input-group">
        <input
          [(ngModel)]="expense.remarks"
          [required]="expenseFieldOptions['remarks'].isRequired"
          [disabled]="expenseFieldOptions['remarks'].isReadOnly"
          [placeholder]="
            'customModules.expenses.placeholder.remarks' | translate
          "
          id="remarks"
          name="remarks"
          type="text"
          class="form-control"
          #remarks="ngModel"
        />
      </div>
      <small *ngIf="remarks.invalid && submitted" class="text-danger">
        {{ 'shared.terms.requiredFieldText' | translate }}
      </small>
    </div>
  </div>
</div>
<temp-form *ngIf="appendedForm" [model]="appendedForm"></temp-form>
