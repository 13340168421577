import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addressPostal',
})
export class AddressPostalPipe implements PipeTransform {
  transform(value: any, ...args: any[]): string {
    let displayText = '';

    if (!value) {
      return displayText;
    }

    const postalInformation = value.split(';').filter((x) => x) as string[];

    postalInformation.forEach((element, i) => {
      if (!element) {
        return;
      }

      displayText += element;

      if (i !== postalInformation.length - 1) {
        displayText += ', ';
      }
    });

    return displayText;
  }
}
