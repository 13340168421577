import {
  AfterViewChecked,
  Directive,
  ElementRef,
  HostListener,
  OnInit,
} from '@angular/core';

@Directive({
  selector: '[shEllipsis]',
  standalone: true,
})
export class EllipsisDirective implements OnInit, AfterViewChecked {
  private get hasOverflow(): boolean {
    const el: HTMLElement = this.el.nativeElement;
    return el.offsetWidth < el.scrollWidth;
  }

  constructor(private el: ElementRef) {}

  ngOnInit() {
    this.el.nativeElement.classList.add('overflow');
    this.el.nativeElement.classList.add('ellipsis');
  }

  ngAfterViewChecked() {
    this.toggleTitle();
  }

  toggleTitle() {
    if (this.hasOverflow) {
      this.el.nativeElement.setAttribute(
        'title',
        this.el.nativeElement.innerText
      );
    } else {
      this.el.nativeElement.setAttribute('title', '');
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.toggleTitle();
  }
}
