import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { WidgetDesigner } from '../../models/widget.model';
import {
  InvoiceWidgetBodyTypes,
  InvoiceWidgetModel,
} from '../../models/invoice-widget.model';
import { InvoiceStatuses } from '@seahorse/domain';
import { Subscription } from 'rxjs';
import { CreateInvoiceWidgetDesignerForm } from './invoice-widget-designer.form';

@Component({
  selector: 'ca-invoice-widget-designer',
  templateUrl: './invoice-widget-designer.component.html',
  styleUrls: ['./invoice-widget-designer.component.scss'],
})
export class InvoiceWidgetDesignerComponent
  implements OnDestroy, OnInit, WidgetDesigner
{
  bodyTypes = [
    { id: InvoiceWidgetBodyTypes.Details, name: 'Details' },
    { id: InvoiceWidgetBodyTypes.Chart, name: 'Chart' },
    { id: InvoiceWidgetBodyTypes.MetaData, name: 'Meta data' },
  ];

  chartTypes = ['pie', 'bar', 'line', 'doughnut'];

  intervals = [
    { id: 'year', text: 'Year' },
    { id: 'month', text: 'Month' },
  ];

  get intervalOptions(): unknown[] {
    return this.intervals.map((x) => {
      return { value: x.id, label: x.text };
    });
  }

  statuses = [
    { id: InvoiceStatuses.InProgress, text: 'In progress' },
    { id: InvoiceStatuses.PendingApproval, text: 'Pending approval' },
    { id: InvoiceStatuses.Open, text: 'Open' },
    { id: InvoiceStatuses.FirstReminder, text: 'First reminder' },
    { id: InvoiceStatuses.Paid, text: 'Paid' },
    { id: InvoiceStatuses.Cancelled, text: 'Cancelled' },
    { id: InvoiceStatuses.Exported, text: 'Exported' },
    { id: InvoiceStatuses.PendingVerification, text: 'PendingVerification' },
  ];

  get statusOptions(): unknown[] {
    return this.statuses.map((x) => {
      return { value: x.id, label: x.text };
    });
  }

  selectedIntervals: unknown[] = [];
  selectedStatuses: unknown[] = [];

  invoiceWidgetBodyTypes = InvoiceWidgetBodyTypes;

  @Input() widget: InvoiceWidgetModel;
  data: InvoiceWidgetModel = null;

  form = CreateInvoiceWidgetDesignerForm();

  private _subscriptions = new Subscription();
  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this._subscriptions.add(
      this.form.get('intervals').valueChanges.subscribe((value) => {
        this.selectedIntervals = this.intervals.filter((interval) =>
          value.includes(interval.id)
        );

        this.onDeSelectAll('intervals');
        this.selectedIntervals.forEach((x) => {
          this.onItemSelect(x, 'intervals');
        });
      })
    );

    this._subscriptions.add(
      this.form.get('statuses').valueChanges.subscribe((value) => {
        this.selectedStatuses = this.statuses.filter((status) =>
          value.includes(status.id)
        );

        this.onDeSelectAll('statuses');
        this.selectedStatuses.forEach((x) => {
          this.onItemSelect(x, 'statuses');
        });
      })
    );

    if (this.widget == null) {
      this.widget = InvoiceWidgetModel.default();
    } else {
      this.form
        .get('intervals')
        .setValue(
          this.intervals
            .filter((interval) => this.widget.intervals.includes(interval.id))
            .map((x) => x.id)
        );

      this.form
        .get('statuses')
        .setValue(
          this.statuses
            .filter((status) => this.widget.statuses.includes(status.id))
            .map((x) => x.id)
        );
    }
  }

  onItemSelect(item: any, key: string) {
    switch (key) {
      case 'intervals':
        this.widget = {
          ...this.widget,
          intervals: [...this.widget.intervals, item.id],
        };
        if (!this.widget.intervalType) {
          this.widget = { ...this.widget, intervalType: item.id };
        }
        break;

      case 'statuses':
        this.widget = {
          ...this.widget,
          statuses: [...this.widget.statuses, item.id],
        };
        break;
    }
  }

  onItemDeSelect(item: any, key: string) {
    switch (key) {
      case 'intervals': {
        const newIntervals = this.widget.intervals.filter((x) => x !== item.id);
        this.widget = { ...this.widget, intervals: newIntervals };

        if (this.widget.intervalType === item.id) {
          this.widget = {
            ...this.widget,
            intervalType: newIntervals?.length ? newIntervals[0] : null,
          };
        }
        break;
      }

      case 'statuses': {
        this.widget = {
          ...this.widget,
          statuses: this.widget.statuses.filter((x) => x !== item.id),
        };
        break;
      }
    }
  }

  onSelectAll(items: any, key: string) {
    switch (key) {
      case 'intervals': {
        const intervalIds = items.map((x) => x.id);
        this.widget = { ...this.widget, intervals: intervalIds };

        if (!this.widget.intervalType) {
          this.widget = { ...this.widget, intervalType: intervalIds[0] };
        }
        break;
      }

      case 'statuses': {
        this.widget = { ...this.widget, statuses: items.map((x) => x.id) };
        break;
      }
    }
  }

  onDeSelectAll(key: string) {
    switch (key) {
      case 'intervals':
        this.widget = { ...this.widget, intervals: [], intervalType: null };
        break;

      case 'statuses':
        this.widget = { ...this.widget, statuses: [] };
        break;
    }
  }

  changeBodyType(newType: any) {
    this.widget = { ...this.widget, bodyType: newType.id };

    if (newType.id === InvoiceWidgetBodyTypes.MetaData) {
      const intervalIds = this.intervals.map((interval) => interval.id);
      this.widget = {
        ...this.widget,
        intervals: intervalIds,
        intervalType: this.intervals[0]?.id || null,
      };
    }
  }

  changeChartType(newType: any) {
    this.widget = {
      ...this.widget,
      chartConfiguration: {
        ...this.widget.chartConfiguration,
        type: newType,
      },
    };
  }

  changeChartConfigProperty(property: string, value: any) {
    this.widget = {
      ...this.widget,
      chartConfiguration: {
        ...this.widget.chartConfiguration,
        [property]: value,
      },
    };
  }

  changeMinDate(newDate: any) {
    this.widget = { ...this.widget, minDate: newDate };
  }

  changeMaxDate(newDate: any) {
    this.widget = { ...this.widget, maxDate: newDate };
  }

  changeIntervalType(newType: any) {
    this.widget = { ...this.widget, intervalType: newType.id };
  }

  changeColumnInterval(newType: any) {
    this.widget = {
      ...this.widget,
      metaDataConfiguration: {
        ...this.widget.metaDataConfiguration,
        columnInterval: newType.id,
      },
    };
  }

  changeReload(value: boolean) {
    this.widget = { ...this.widget, reload: value };
  }

  changePrimaryDisplay(event: any) {
    this.widget = {
      ...this.widget,
      detailsConfiguration: {
        ...this.widget.detailsConfiguration,
        primaryDisplay: event.target.value,
      },
    };
  }

  changeSecondaryDisplay(event: any) {
    let displayArray = [];
    if (event.target.value) {
      displayArray = event.target.value.split(',').map((item) => item.trim());
    }
    this.widget = {
      ...this.widget,
      detailsConfiguration: {
        ...this.widget.detailsConfiguration,
        secondaryDisplay: displayArray,
      },
    };
  }

  changeDataBreakdown(event: any) {
    this.widget = {
      ...this.widget,
      chartConfiguration: {
        ...this.widget.chartConfiguration,
        dataBreakdown: event.target.value,
      },
    };
  }

  changeGroupingField(event: any) {
    this.widget = {
      ...this.widget,
      metaDataConfiguration: {
        ...this.widget.metaDataConfiguration,
        groupingField: event.target.value,
      },
    };
  }

  getWidgetData() {
    return this.widget;
  }
}
