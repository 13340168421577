import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NauticalShipModel } from '@seahorse/domain';

@Component({
  selector: 'ca-nautical-ship-tiles',
  templateUrl: './nautical-ship-tiles.component.html',
})
export class NauticalShipTilesComponent {
  @Input() selectedShip: NauticalShipModel = null;
  @Input() ships: NauticalShipModel[] = [];
  @Output() onShipSelected = new EventEmitter<NauticalShipModel>();

  selectShip(ship: NauticalShipModel) {
    this.selectedShip = ship;
    this.onShipSelected.emit(ship);
  }

  shipIdentification(ship: NauticalShipModel) {
    const result = [];

    if (ship.eni) {
      result.push({ label: 'ENI', value: ship.eni });
    }
    if (ship.fisheryNumber) {
      result.push({ label: 'fishery', value: ship.fisheryNumber });
    }
    if (ship.imo) {
      result.push({ label: 'IMO', value: ship.imo });
    }
    if (ship.mmsi) {
      result.push({ label: 'MMSI', value: ship.mmsi });
    }

    return result;
  }
}
