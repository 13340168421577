import { Component, Input } from '@angular/core';
import * as _ from 'underscore';

import { NauticalVisitDetailsModel } from '@seahorse/domain';
import { NauticalVisitDataService } from '@seahorse/domain';

@Component({
  selector: 'ca-nautical-visit-details',
  templateUrl: './nautical-visit-details.html',
})
export class NauticalVisitDetailsComponent {
  visit: NauticalVisitDetailsModel;

  @Input('visit-id') set visitid(value: number) {
    this.visitData.getDetails(value).subscribe((result) => {
      if (result.portMovements) {
        result.portMovements = _.sortBy(result.portMovements, 'movementType');
      }
      if (result.portVisitAttributes) {
        result.portVisitAttributes = _.sortBy(
          result.portVisitAttributes,
          'createdOn'
        );
      }
      if (result.portVisitEvents) {
        result.portVisitEvents = _.sortBy(result.portVisitEvents, 'createdOn');
      }

      this.visit = result;
    });
  }

  constructor(private visitData: NauticalVisitDataService) {}

  visitAttributesForMovement(movementId: number) {
    if (!this.visit || !this.visit.portVisitAttributes) {
      return [];
    }

    return _.filter(
      this.visit.portVisitAttributes,
      (item) => item.portMovementId === movementId
    );
  }

  visitAttributesForVisit() {
    if (!this.visit || !this.visit.portVisitAttributes) {
      return [];
    }

    return _.filter(
      this.visit.portVisitAttributes,
      (item) => !item.portMovementId
    );
  }

  visitEventsForMovement(movementId: number) {
    if (!this.visit || !this.visit.portVisitEvents) {
      return [];
    }

    return _.filter(
      this.visit.portVisitEvents,
      (item) => item.portMovementId === movementId
    );
  }

  visitEventsForVisit() {
    if (!this.visit || !this.visit.portVisitEvents) {
      return [];
    }

    return _.filter(this.visit.portVisitEvents, (item) => !item.portMovementId);
  }
}
