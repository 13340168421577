import { inject } from '@angular/core';
import { InvoiceStatuses } from '@seahorse/domain';
import { FormModel, SeahorseFormBuilder } from '@seahorse/forms';

export type CreateInvoiceWidgetDesignerForm = FormModel<
  typeof CreateInvoiceWidgetDesignerForm
>;

export function CreateInvoiceWidgetDesignerForm() {
  const fb = inject(SeahorseFormBuilder);

  return fb.group({
    intervals: fb.control<string[]>([]),
    statuses: fb.control<InvoiceStatuses[]>([]),
  });
}
