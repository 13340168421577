import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CompanyServicesModule } from '../../../company/services/company-services.module';
import { LayoutComponentsModule } from '../../../layout/components/layout-components.module';
import { MapServicesModule } from '../../../map/services/map-services.module';
import { NauticalVisitServicesModule } from '../../../nautical-visit/services/nautical-visit-services.module';

import { CustomCVVComponentsModule } from '../components/cvv-components.module';
import { CVVActivitiesPage } from './cvv-activities/cvv-activities.page';

@NgModule({
  declarations: [CVVActivitiesPage],
  exports: [],
  imports: [
    CommonModule,
    CompanyServicesModule,
    CustomCVVComponentsModule,
    FormsModule,
    LayoutComponentsModule,
    MapServicesModule,
    NauticalVisitServicesModule,
    TranslateModule,
  ],
})
export class CustomCVVPagesModule {}
