import { inject } from '@angular/core';
import { SeahorseFormBuilder } from '@seahorse/forms';

export function createTasksSearchForm() {
  const fb = inject(SeahorseFormBuilder);

  return fb.group({
    active: fb.control<boolean>(false),
    expired: fb.nonNullable.control<boolean>(false),
    assignedTo: fb.control<string | null>(null),
  });
}
