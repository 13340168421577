import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NauticalShipModel } from '@seahorse/domain';

@Component({
  selector: 'ca-nautical-ship-list',
  templateUrl: './nautical-ship-list.component.html',
})
export class NauticalShipListComponent {
  @Input() selectedShip: NauticalShipModel = null;
  @Input() ships: NauticalShipModel[] = [];

  @Output() onShipSelected = new EventEmitter<NauticalShipModel>();

  selectShip(ship: NauticalShipModel) {
    this.selectedShip = ship;
    this.onShipSelected.emit(ship);
  }
}
