<div class="modal-header align-items-center">
  <h1 class="modal-title">{{ 'tasks.definition.modal' | translate }}</h1>
  <button closeModal type="button" class="btn btn-link text-dark">
    <i class="fa-solid fa-times fa-sm fa-fw"></i>
  </button>
</div>
<div class="modal-body">
  <task-definition-form
    [taskDefinition]="taskDefinition"
  ></task-definition-form>
</div>
<div class="modal-footer">
  <button closeModal type="button" class="btn btn-link btn-block w-50">
    {{ 'shared.terms.cancel' | translate }}
  </button>
  <button
    [disabled]="
      !taskDefinition.$customdata_taskdefinitionlist_id ||
      !taskDefinition.subject ||
      !taskDefinition.taskType
    "
    (click)="onAddOrEditTaskDefinition.emit(taskDefinition)"
    closeModal
    type="button"
    class="btn btn-success btn-block w-50"
  >
    <ng-container
      *ngIf="
        taskDefinition.__Id;
        then editTaskDefinition;
        else addTaskDefinition
      "
    ></ng-container>
    <ng-template #addTaskDefinition>{{
      'shared.terms.add' | translate
    }}</ng-template>
    <ng-template #editTaskDefinition>{{
      'shared.terms.update' | translate
    }}</ng-template>
  </button>
</div>
