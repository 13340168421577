import { inject } from '@angular/core';
import { FormModel, SeahorseFormBuilder } from '@seahorse/forms';

export type AdvancedSearchForm = FormModel<
  typeof CreateAdvancedSearchForm
>;

export function CreateAdvancedSearchForm() {
  const fb = inject(SeahorseFormBuilder);

  return fb.group({
    name: fb.control<string|null>(null),
    debtorNumber: fb.control<string|null>(null),
    contactName: fb.control<string|null>(null),
    parentCompanyId: fb.control<number|null>(null),
    typeIds: fb.control<Array<number>|null>(null),
  });
}
