import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { TranslateService } from '@ngx-translate/core';
import * as _ from 'underscore';

import { NotificationService, ResultWrapper } from '@seahorse/common';
import { UserModel } from '@seahorse/domain';
import { UserDataService } from '../../../user/services/user-data.service';

@Component({ templateUrl: './user-picker-modal.component.html' })
export class UserPickerModalComponent implements OnInit {
  @Input() allowMultiple: boolean;
  @Input() set users(users: UserModel[]) {
    if (users) {
      this.allUsers = users;
    } else {
      this.allUsers = null;
    }
  }

  @Input() set selectedUserIds(selectedUserIds: string[]) {
    if (selectedUserIds) {
      this._selectedUserIds = _.map(selectedUserIds, (id) => {
        return id.toLowerCase();
      });
    } else {
      this._selectedUserIds = [];
    }
  }
  get selectedUserIds() {
    return this._selectedUserIds;
  }

  @Input() set selectedUsers(selectedUsers: UserModel[]) {
    if (!this._selectedUserIds) {
      this._selectedUserIds = [];
    }

    _.each(selectedUsers, (u) => {
      if (this._selectedUserIds.indexOf(u.id.toLowerCase()) === -1) {
        this._selectedUserIds.push(u.id.toLowerCase());
      }
    });
  }

  private _selectedUserIds: string[] = null; // lower case

  allUsers: UserModel[] = null;
  loadUsers: boolean;
  searchField: string;
  isAllUserChecked = false;

  constructor(
    public modal: NgbActiveModal,
    private translateService: TranslateService,
    private userDataService: UserDataService,
    private notificationService: NotificationService
  ) {
    this.loadUsers = false;
  }

  ngOnInit() {
    if (!this._selectedUserIds) {
      this._selectedUserIds = [];
    }

    if (this.allUsers === null) {
      // Get users
      this.loadAllUsers();
    } else {
      this.isAllSelect();
    }
  }

  loadAllUsers() {
    this.loadUsers = true;
    this.userDataService
      .getByOrganisation(false)
      .subscribe((usersResponse: ResultWrapper<any>) => {
        this.allUsers = [];
        if (usersResponse.hasResult) {
          this.allUsers = usersResponse.result;
          this.isAllSelect();
        } else {
          this.allUsers = [];
          this.notificationService.showError(
            this.translateService.instant('user.loadUsersFailed')
          );
        }

        this.loadUsers = false;
      });
  }

  selectAll(value) {
    this.isAllUserChecked = value;
    if (this.isAllUserChecked === true) {
      this._selectedUserIds = _.map(this.allUsers, (u) => {
        return u.id.toLowerCase();
      });
    } else {
      this._selectedUserIds = [];
    }
  }

  selectUser(id: string) {
    if (this.allowMultiple === true) {
      const foundIndex = this.selectedUserIds.lastIndexOf(id.toLowerCase());
      if (foundIndex > -1) {
        this.selectedUserIds.splice(foundIndex, 1);
      } else {
        this.selectedUserIds.push(id);
      }
      this.isAllSelect();
    } else {
      if (
        this.selectedUserIds &&
        this.selectedUserIds.length === 1 &&
        this.selectedUserIds[0] === id
      ) {
        this.selectedUserIds = [];
      } else {
        this.selectedUserIds = [id];
      }
    }
  }

  isAllSelect() {
    this.isAllUserChecked =
      this.allUsers &&
      this.selectedUserIds &&
      this.allUsers.length === this.selectedUserIds.length &&
      _.every(this.allUsers, (u) => {
        return this.selectedUserIds.lastIndexOf(u.id) > -1;
      });
  }

  requestFailed() {
    this.notificationService.showError(
      this.translateService.instant('shared.terms.failed')
    );
  }

  onButtonClick(buttonName: string) {
    switch (buttonName) {
      case 'ok':
        const users = _.filter(this.allUsers, (u) => {
          return this._selectedUserIds.indexOf(u.id.toLowerCase()) > -1;
        });
        this.modal.close(users);
        break;

      default:
        this.modal.dismiss(null);
        break;
    }
  }
}
