<div class="modal-header">
  <div>My fleet</div>
  <button type="button" class="close" closeModal>
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <ca-my-fleet-form
    *ngIf="!isLoading"
    [groups]="groups"
    [shipsWithoutGroup]="shipsWithoutGroup"
    [shipId]="shipId"
  >
  </ca-my-fleet-form>

  <sh-loading-indicator
    class="text-center d-block"
    *ngIf="isLoading"
  ></sh-loading-indicator>
</div>

<div class="modal-footer">
  <button closeModal type="button" class="btn btn-light btn-block">
    {{ 'shared.terms.close' | translate }}
  </button>
</div>
