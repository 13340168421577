<div class="page-with-header">
  <!-- Header -->
  <stage-creator-header
    [loadingStage]="loadingStage"
    (onEvent)="action($event)"
  ></stage-creator-header>
  <!-- Content -->
  <div class="container-fluid py-3">
    <div class="row my-n2">
      <div class="col-12 col-lg-6 col-xl-8 py-2">
        <div class="card">
          <div class="card-header">
            {{ 'invoicingFlows.stageCreator.newStage' | translate }}
          </div>
          <div class="card-body">
            <stage-creator-form
              [stage]="stage"
              [loadingStage]="loadingStage"
              [invoicingFlows]="invoicingFlows"
              [loadingInvoicingFlows]="loadingInvoicingFlows"
              [flow]="flow"
              [loadingFlow]="loadingFlow"
              (onEvent)="action($event)"
            ></stage-creator-form>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 col-xl-4 py-2">
        <div class="row my-n2">
          <div class="col-12 py-2">
            <calculations-info
              [calculations]="stage.businessLogic.calculations"
              [loadingCalculations]="loadingStage"
              (onEvent)="action($event)"
            ></calculations-info>
          </div>
          <div class="col-12 py-2">
            <conditions-info
              [conditions]="stage.businessLogic.conditions"
              [loadingConditions]="loadingStage"
              (onEvent)="action($event)"
            ></conditions-info>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
