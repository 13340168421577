import { Component } from '@angular/core';

// note: Not provided in SeahorseUiModule,
// because it would require adding imports to the module in too many places.
// Declare separately in each module that needs it.
@Component({
  selector: 'sh-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  standalone: true
})
export class LoadingIndicatorComponent {}
