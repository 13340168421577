import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {
  NgbModal,
  NgbModalOptions,
  NgbModalRef
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@seahorse/common';
import { NauticalShipModel, NauticalVisitOverviewModel } from '@seahorse/domain';
import { ModalService } from '@seahorse/temp';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import * as _ from 'underscore';
import { BeverwijkActionModalComponent } from '../../custom-ui/beverwijk/components/beverwijk-action-modal/beverwijk-action-modal.component';
import { CVVAddActivityModal } from '../../custom-ui/cvv/components/add-activity-modal/add-activity-modal.component';
import {
  CVVActiveVisitModel,
  CVVActiveVisitStatus
} from '../../custom-ui/cvv/models/cvv-active-visit.model';
import { CVVActivityModel } from '../../custom-ui/cvv/models/cvv-activity.model';
import { CVVAddActivityResultModel } from '../../custom-ui/cvv/models/cvv-add-activity-result.model';
import { CVVEventModel } from '../../custom-ui/cvv/models/cvv-event.model';
import { CVVActivityLogicService } from '../../custom-ui/cvv/services/cvv-activity-logic.service';
import { CVVDataService } from '../../custom-ui/cvv/services/cvv-data.service';
import { MemberPickerModalComponent } from '../../custom-ui/general/components/member-picker-modal/member-picker-modal.component';
import { KPAddActivityModal } from '../../custom-ui/kp/components/add-activity-modal/add-activity-modal.component';
import { KPActivityTypes } from '../../custom-ui/kp/models/kp-activity-types.enum';
import { KPActivityModel } from '../../custom-ui/kp/models/kp-activity.model';
import { KPActivityLogicService } from '../../custom-ui/kp/services/kp-activity-logic.service';
import { KPDataService } from '../../custom-ui/kp/services/kp-data.service';
import { SuggestionPickerModalComponent } from '../../custom-ui/zeehaven-ijmuiden/components/suggestion-picker-modal/suggestion-picker-modal.component';
import { ZeehavenIJmuidenActionPickerModalComponent } from '../../custom-ui/zeehaven-ijmuiden/components/zeehaven-ijmuiden-action-picker-modal/zeehaven-ijmuiden-action-picker-modal.component';
import { ZeehavenIJmuidenMovementDetailsModel } from '../../custom-ui/zeehaven-ijmuiden/models/zeehaven-ijmuiden-movement-details.model';
import {
  ZeehavenIJmuidenMovementModel, ZeehavenIJmuidenVisitMovementType
} from '../../custom-ui/zeehaven-ijmuiden/models/zeehaven-ijmuiden-movement.model';
import { ZeehavenIJmuidenLogicService } from '../../custom-ui/zeehaven-ijmuiden/services/zeehaven-ijmuiden-logic.service';
import { EventModel } from '../../shared/models/event.model';
import { AISMarkerModel } from '@seahorse/domain';

@Injectable()
export class MapCustomActionsService implements OnDestroy {
  private noordersluisLat = [52.467149, 52.467844];
  private noordersluisLong = [4.605871, 4.611678];
  private middensluisLat = [52.465744, 52.465986];
  private middensluisLong = [4.597106, 4.600707];
  private zuidersluisLat = [52.463926, 52.464268];
  private zuidersluisLong = [4.590539, 4.592669];
  private subscriptions$ = new Subscription();

  constructor(
    private cvvActivityLogic: CVVActivityLogicService,
    private cvvDataService: CVVDataService,
    private kpActivityLogic: KPActivityLogicService,
    private kpService: KPDataService,
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private router: Router,
    private translateService: TranslateService,
    private zeehavenLogicService: ZeehavenIJmuidenLogicService,
    private _modalService: ModalService
  ) {}

  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }

  // #region CVV

  addCVVActivity(
    ship: NauticalShipModel,
    activeVisit: NauticalVisitOverviewModel,
    aisInfo: AISMarkerModel
  ) {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
    };
    const modalRef = this.modalService.open(
      CVVAddActivityModal,
      ngbModalOptions
    );
    const activity = new CVVActivityModel();

    modalRef.componentInstance.onItemClicked.subscribe(
      (onItemClicked: CVVEventModel) => {
        switch (onItemClicked.action) {
          case 'openMemberPicker':
            this.openMemberPickerModal(activity);
            break;
        }
      },
      () => {},
      () => {}
    );

    const lockName = this.isInLock(aisInfo);
    if (activeVisit !== null) {
      activity.$nautical_portmovement_id = activeVisit.currentMovement
        ? activeVisit.currentMovement.id
        : null;
      activity.$nautical_ship_id = activeVisit.shipId;
      activity.$nautical_portvisit_referencenumber =
        activeVisit.referenceNumber;
      activity.$companies_company_id =
        this.cvvActivityLogic.getCompanyByAgentId(
          activeVisit.currentMovement
            ? activeVisit.currentMovement.portAgentId
            : activeVisit.portAgentId
        );
      activity.startsOn = moment.utc().toDate();

      if (activeVisit.etd !== null) {
        activity.activityType =
          this.cvvActivityLogic.cvvActivityTypes.Ontmeren.id;
      } else if (activeVisit.eta !== null || activeVisit.ata !== null) {
        activity.activityType = this.cvvActivityLogic.cvvActivityTypes.Meren.id;
      }

      if (lockName === null && activeVisit.currentMovement) {
        if (
          activeVisit.currentMovement.portWayPointTo &&
          activeVisit.currentMovement.portWayPointTo.code
        ) {
          activity.berth1 = activeVisit.currentMovement.portWayPointTo.code;
        } else if (
          activeVisit.currentMovement.portWayPointFrom &&
          activeVisit.currentMovement.portWayPointFrom.code
        ) {
          activity.berth1 = activeVisit.currentMovement.portWayPointFrom.code;
        }
      }
    } else if (ship) {
      modalRef.componentInstance.selectedShipId = ship.id;
    }

    // ship is in a lock, set the activitytype and lock
    if (lockName !== null) {
      activity.berth1 = lockName;
      activity.activityType =
        this.cvvActivityLogic.cvvActivityTypes.MerenOntmerenSluis.id;

      if (aisInfo) {
        const heading = aisInfo ? aisInfo.heading % 360 : 0;
        if (heading < 180) {
          activity.movementType = 1; // Inward
        } else {
          activity.movementType = 3; // Outward
        }
      }
    }
    modalRef.componentInstance.activity = activity;

    modalRef.result
      .then(
        (modalResult: CVVAddActivityResultModel) =>
          this.callBackAddCVVActivityModal(modalResult, activeVisit),
        (error) => {}
      )
      .catch(() => {})
      .finally(() => {});
  }

  private addCvvVisit(
    visit: NauticalVisitOverviewModel,
    activity: CVVActivityModel
  ) {
    if (!activity) {
      return;
    }

    const cvvVisit = new CVVActiveVisitModel();
    cvvVisit.$nautical_ship_id = activity.$nautical_ship_id;
    if (visit) {
      cvvVisit.$nautical_portvisit_id = visit.id;
      cvvVisit.$nautical_portmovement_id = visit.currentMovement
        ? visit.currentMovement.id
        : null;
    }
    cvvVisit.status = CVVActiveVisitStatus.Active;
    this.cvvDataService.addActiveVisit(cvvVisit).subscribe(
      (response) => {
        if (response && response.hasResult) {
          this.notificationService.showSuccess(
            this.translateService.instant('customers.cvv.visit.added'),
            this.translateService.instant('shared.terms.success')
          );
          activity.groupId = response.result.__Id;
          this.saveCvvActivity(activity);
        }
      },
      () => {},
      () => {}
    );
  }

  private callBackAddCVVActivityModal(
    result: CVVAddActivityResultModel,
    activeVisit: NauticalVisitOverviewModel
  ) {
    if (!result || !result.activity) {
      return;
    }

    // no visit is selected
    const visit = result.visit ? result.visit : activeVisit;
    if (!visit) {
      this.cvvDataService
        .getVisitsByShipIds([result.activity.$nautical_ship_id], true)
        .subscribe(
          (response) => {
            if (response && response.result && response.result.length > 0) {
              const visits = response.result;
              if (visits && visits.length > 0) {
                result.activity.groupId = visits[0].__Id;
                this.saveCvvActivity(result.activity);
                return;
              }
            }
            this.addCvvVisit(visit, result.activity);
          },
          () => {},
          () => {}
        );
      return;
    }

    // 1. check if the cvv visit is already exist, if not create one
    // 2. add activities
    this.cvvDataService.getVisitByPortVisitId(visit.id).subscribe(
      (visitResponse) => {
        let cvvVisit: CVVActiveVisitModel = null;
        if (
          visitResponse &&
          visitResponse.result &&
          visitResponse.result.length > 0
        ) {
          cvvVisit = _.find(
            visitResponse.result,
            (v: CVVActiveVisitModel) =>
              v.$nautical_portmovement_id ===
              (visit.currentMovement
                ? visit.currentMovement.id
                : visit.currentMovement)
          );
        }

        if (cvvVisit) {
          // visit with current movement is already exists
          if (cvvVisit.status !== CVVActiveVisitStatus.Active) {
            cvvVisit.status = CVVActiveVisitStatus.Active; // change the status to active
            this.cvvDataService.updateVisit(cvvVisit).subscribe(
              (updateResponse) => {
                if (updateResponse && updateResponse.hasResult) {
                  this.notificationService.showSuccess(
                    this.translateService.instant(
                      'customers.cvv.visit.changeStatusActive'
                    ),
                    this.translateService.instant('shared.terms.success')
                  );
                }
              },
              () => {},
              () => {}
            );
          }
          result.activity.groupId = cvvVisit.__Id;
          this.saveCvvActivity(result.activity);
        } else {
          // visit with current movement doens't exist, create new one
          this.addCvvVisit(visit, result.activity);
        }
      },
      () => {},
      () => {}
    );
  }

  private saveCvvActivity(activity: CVVActivityModel) {
    if (!activity) {
      return;
    }

    this.notificationService.showInfo(
      this.translateService.instant('customUI.activity.saving')
    );

    this.cvvDataService.addActivity(activity).subscribe(
      (response) => {
        if (response && response.hasResult) {
          this.notificationService.showSuccess(
            this.translateService.instant('customUI.activity.added'),
            this.translateService.instant('shared.terms.success')
          );
        } else {
          this.notificationService.showError(
            this.translateService.instant('customUI.activity.errorSave'),
            this.translateService.instant('shared.terms.failed')
          );
        }
      },
      (error) => {
        this.notificationService.showError(
          this.translateService.instant('customUI.activity.errorSave'),
          this.translateService.instant('shared.terms.failed')
        );
      },
      () => {}
    );
  }

  //#endregion

  // #region Koperen Ploeg

  addKPActivity(
    ship: NauticalShipModel,
    activeVisit: NauticalVisitOverviewModel
  ) {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
    };
    const modalRef = this.modalService.open(
      KPAddActivityModal,
      ngbModalOptions
    );
    const activity = new KPActivityModel();

    if (activeVisit !== null) {
      activity.$nautical_ship_id = ship.baseId;
      activity.$nautical_portvisit_id = activeVisit.id;
      activity.$nautical_portvisit = {
        referencenumber: activeVisit.referenceNumber,
        portAgentId: activeVisit.portAgentId,
      };
      activity.$companies_company_id = this.kpActivityLogic.getCompanyByAgentId(
        activeVisit.currentMovement
          ? activeVisit.currentMovement.portAgentId
          : activeVisit.portAgentId
      );
      activity.startsOn = moment.utc().toDate();

      if (activeVisit.etd !== null) {
        activity.activityType = KPActivityTypes.Ontmeren.code;
      } else if (activeVisit.eta !== null || activeVisit.ata !== null) {
        activity.activityType = KPActivityTypes.Meren.code;
      }

      // skip predefine the berth1
      // if (activeVisit.currentMovement) {
      //   if (activeVisit.currentMovement.portWayPointTo && activeVisit.currentMovement.portWayPointTo.code) {
      //     activity.berth1 = activeVisit.currentMovement.portWayPointTo.code;
      //   } else if (activeVisit.currentMovement.portWayPointFrom && activeVisit.currentMovement.portWayPointFrom.code) {
      //     activity.berth1 = activeVisit.currentMovement.portWayPointFrom.code;
      //   }
      // }
    } else if (ship) {
      modalRef.componentInstance.selectedShipId = ship.id;
    }

    modalRef.componentInstance.activity = activity;

    modalRef.result
      .then(
        (modalResult: KPActivityModel) =>
          this.callBackAddKPActivityModal(modalResult),
        (error) => {}
      )
      .catch(() => {})
      .finally(() => {});
  }

  private callBackAddKPActivityModal(activity: KPActivityModel) {
    if (!activity) {
      return;
    }

    this.notificationService.showInfo(
      this.translateService.instant('customUI.activity.saving')
    );
    this.kpService.addActivity(activity).subscribe(
      (response) => {
        if (response && response.hasResult) {
          this.notificationService.showSuccess(
            this.translateService.instant('customUI.activity.added'),
            this.translateService.instant('shared.terms.success')
          );
        } else {
          this.notificationService.showError(
            this.translateService.instant('customUI.activity.errorSave'),
            this.translateService.instant('shared.terms.failed')
          );
        }
      },
      (error) => {
        this.notificationService.showError(
          this.translateService.instant('customUI.activity.errorSave'),
          this.translateService.instant('shared.terms.failed')
        );
      },
      () => {}
    );
  }

  // #endregion

  // #region Zeehaven IJmuiden

  openZHActionDialog(
    ship: NauticalShipModel,
    activeVisit: NauticalVisitOverviewModel
  ) {
    let modal = null;
    if (activeVisit) {
      modal = this.modalService.open(
        ZeehavenIJmuidenActionPickerModalComponent,
        { backdrop: 'static', size: 'lg' }
      );
      modal.componentInstance.nauticalVisitId = activeVisit.id;
      modal.componentInstance.nauticalShipId = ship.id;
    } else if (ship) {
      modal = this.modalService.open(
        ZeehavenIJmuidenActionPickerModalComponent,
        { backdrop: 'static', size: 'lg' }
      );
      modal.componentInstance.nauticalShipId =
        ship.baseId ?? ship.id.toString().split('_')[0];
    } else {
      this.addZHActivity(
        ZeehavenIJmuidenVisitMovementType.Incoming,
        ship,
        activeVisit
      );
    }

    if (modal !== null) {
      modal.result
        .then(
          (modalResult: EventModel) => {
            if (modalResult && modalResult.action) {
              switch (modalResult.action) {
                case 'openSuggestionPicker':
                  this.addZHActivity(
                    ZeehavenIJmuidenVisitMovementType.Incoming,
                    ship,
                    activeVisit
                  );
                  break;

                case 'redirect':
                  this.router
                    .navigateByUrl(`custom-ui/zeehaven/${modalResult.data}`)
                    .then(
                      () => {},
                      () => {}
                    )
                    .catch(() => {})
                    .finally(() => {});
                  break;

                case 'newMovement':
                  this.zeehavenLogicService.createNewMovementByZHIJVisit(
                    modalResult.data.visit,
                    modalResult.data.movementType
                  );
                  break;

                default:
                  break;
              }
            }
          },
          () => {}
        )
        .catch((error) => {})
        .finally(() => {});
    }
  }

  private addZHActivity(
    movementType: ZeehavenIJmuidenMovementModel['movementType'],
    ship: NauticalShipModel,
    activeVisit: NauticalVisitOverviewModel
  ) {
    const z: ZeehavenIJmuidenMovementDetailsModel =
      new ZeehavenIJmuidenMovementModel();
    z.movementType = movementType;
    z.$nautical_ship = ship;

    if (activeVisit) {
      z.$nautical_portvisit_id = activeVisit.id;
    }

    const m = this.modalService.open(SuggestionPickerModalComponent, {
      backdrop: 'static',
    });
    m.componentInstance.zVisit = z;

    m.componentInstance.onPickSuggestion.subscribe(
      (v) => {
        this.zeehavenLogicService.createNewMovementAndRedirect(v);
      },
      () => {},
      () => {}
    );
  }

  // #endregion

  // #region Beverwijk
  openBVActionDialog(shipId: string) {
    if (shipId) {
      this.subscriptions$.add(
        this._modalService
          .openModal(BeverwijkActionModalComponent, { shipId: shipId })
          .subscribe()
      );
    }
  }
  // #endregion Beverwijk

  private isInLock(aisInfo: AISMarkerModel): string {
    const latitude = aisInfo ? aisInfo.latitude : 0;
    const longitude = aisInfo ? aisInfo.longitude : 0;

    // is in noordersluis
    if (
      latitude >= this.noordersluisLat[0] &&
      latitude <= this.noordersluisLat[1]
    ) {
      if (
        longitude >= this.noordersluisLong[0] &&
        longitude <= this.noordersluisLong[1]
      ) {
        return 'noordersluis';
      }
    }

    // is in middensluis
    if (
      latitude >= this.middensluisLat[0] &&
      latitude <= this.middensluisLat[1]
    ) {
      if (
        longitude >= this.middensluisLong[0] &&
        longitude <= this.middensluisLong[1]
      ) {
        return 'middensluis';
      }
    }

    // is in zuidersluis
    if (
      latitude >= this.zuidersluisLat[0] &&
      latitude <= this.zuidersluisLat[1]
    ) {
      if (
        longitude >= this.zuidersluisLong[0] &&
        longitude <= this.zuidersluisLong[1]
      ) {
        return 'kleinesluis';
      }
    }

    return null;
  }

  private openMemberPickerModal(activityModel: CVVActivityModel) {
    // Open member picker
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      size: 'sm',
    };
    const memberPickerModal: NgbModalRef = this.modalService.open(
      MemberPickerModalComponent,
      ngbModalOptions
    );

    // Input
    memberPickerModal.componentInstance.users = this.cvvActivityLogic.people;
    memberPickerModal.componentInstance.teams = this.cvvActivityLogic.teams;
    memberPickerModal.componentInstance.members = _.clone(activityModel.people);

    // Output
    memberPickerModal.componentInstance.onSelectMembersAction.subscribe(
      (onSelectMembersAction: {
        members: CVVActivityModel['people'];
        action: string;
      }) => {
        switch (onSelectMembersAction.action) {
          case 'save':
            activityModel.people = onSelectMembersAction.members;
            break;

          case 'dismiss':
            break;
        }

        memberPickerModal.dismiss();
      },
      () => {},
      () => {}
    );
  }
}
