<div class="form-row">
  <div class="form-group col-12">
    <label for="name" class="required">
      <b>{{ 'shared.terms.name' | translate }}</b>
    </label>
    <input
      [(ngModel)]="taskDefinitionList.title"
      [placeholder]="'tasks.definitionlist.placeholder.name' | translate"
      required
      type="text"
      id="name"
      name="name"
      class="form-control"
    />
  </div>
</div>
