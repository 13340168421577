export class CloneObjectRequest {
  sourceId: string;
  fields: LinkedObjectField[];
  objectKey: string;

  static empty(): CloneObjectRequest {
    return {
      sourceId: '',
      fields: [],
      objectKey: '',
    };
  }
}

export class CloneLinkedObjectsRequest {
  sourceId: string;
  targetId: string;
  mappingKey: string;
  cloneType: CloneType;

  static empty(): CloneLinkedObjectsRequest {
    return {
      sourceId: '',
      targetId: '',
      mappingKey: '',
      cloneType: CloneType.Duplicate,
    };
  }
}

export class LinkedObjectField {
  systemCode: string;
  cloneType: CloneType;
}

export enum CloneType {
  Duplicate = 1,
  Link = 2,
}
