<div class="modal-header align-items-center">
  <h1 class="modal-title">
    {{ 'customModules.orderRegistrations.details.editTariff' | translate }}
  </h1>
  <button closeModal type="button" class="btn btn-link text-dark">
    <i class="fa-solid fa-times fa-sm fa-fw"></i>
  </button>
</div>
<div class="modal-body">
  <div class="form-row">
    <div class="form-group col-12">
      <label for="tariffGroup">
        <b>{{ 'invoicing.tariffGroups.model.tariffGroup' | translate }}</b>
        <span *ngIf="tariffGroupsLoading">
          <i class="fa-regular fa-spinner fa-spin fa-fw"></i>
        </span>
      </label>
      <div class="input-group">
        <select
          [(ngModel)]="tariffGroup"
          [disabled]="tariffGroupsLoading"
          (change)="tariffsLoad()"
          id="tariffGroup"
          name="tariffGroup"
          class="custom-select"
        >
          <option selected [ngValue]="null">
            {{ 'shared.terms.choose' | translate }}...
          </option>
          <optgroup *ngIf="tariffGroup">
            <option [ngValue]="tariffGroup">{{ tariffGroup.name }}</option>
          </optgroup>
          <optgroup *ngIf="tariffGroups.length">
            <option
              *ngFor="let tariffGroupOption of tariffGroups"
              [ngValue]="tariffGroupOption"
            >
              {{ tariffGroupOption.name }}
            </option>
          </optgroup>
        </select>
        <div class="input-group-append">
          <button
            class="btn btn-outline-secondary"
            (click)="setNull('tariffGroup')"
            type="button"
          >
            <i class="fa-regular fa-eraser fa-fw"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="form-group col-12">
      <label for="tariff">
        <b>{{ 'invoicing.tariffGroups.model.tariff' | translate }}</b>
        <span *ngIf="tariffsLoading">
          <i class="fa-regular fa-spinner fa-spin fa-fw"></i>
        </span>
      </label>
      <div class="input-group">
        <select
          [(ngModel)]="tariff"
          [disabled]="tariffsLoading || !tariffGroup"
          id="tariff"
          name="tariff"
          class="custom-select"
        >
          <option selected [ngValue]="null">
            {{ 'shared.terms.choose' | translate }}...
          </option>
          <optgroup *ngIf="tariff">
            <option [ngValue]="tariff">{{ tariff.name }}</option>
          </optgroup>
          <optgroup *ngIf="tariffs.length">
            <option
              *ngFor="let tariffOption of tariffs"
              [ngValue]="tariffOption"
            >
              {{ tariffOption.name }}
            </option>
          </optgroup>
        </select>
        <div class="input-group-append">
          <button
            class="btn btn-outline-secondary"
            (click)="setNull('tariff')"
            type="button"
          >
            <i class="fa-regular fa-eraser fa-fw"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button closeModal type="button" class="btn btn-link flex-fill">
    {{ 'shared.terms.cancel' | translate }}
  </button>
  <button
    closeModal
    (click)="onConfirm.emit(tariff)"
    [disabled]="tariffRequired && !tariff"
    type="button"
    class="btn btn-success flex-fill"
  >
    {{ 'shared.terms.confirm' | translate }}
  </button>
</div>
