<div id="header-wrapper">
  <div class="d-flex flex-row">
    <div
      class="d-flex justify-content-center align-items-center p-2 pl-3 mr-auto"
    >
      <h1>
        <div class="pt-2">
          {{ layoutDisplayService.currentModuleName | translate }}
        </div>
      </h1>
    </div>
    <!-- <div class="d-flex">
            <div class="p-3"><i class="fa-regular fa-bell"></i></div>
            <div class="p-3"><i class="fa-regular fa-envelope"></i></div>
        </div> -->
    <div>
      <div class="d-flex" *ngIf="identityService.identity">
        <div class="d-flex user_info">
          <div
            class="d-flex py-1 px-2 align-items-center cursor-pointer"
            title="{{ 'shared.terms.incomingData' | translate }}"
            *hasPermission="'custom_data.inbox_admin'"
          >
            <button
              type="button"
              class="btn btn-light position-relative"
              (click)="openInboxdata()"
            >
              <i class="fa-lg fa-regular fa-folder-arrow-down"></i>
              <span
                *ngIf="
                  identityService.numberOfInboxData &&
                  identityService.numberOfInboxData > 0
                "
                class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger text-white"
              >
                {{ identityService.numberOfInboxData }}
              </span>
            </button>
          </div>
          <div
            class="d-flex py-1 px-2 align-items-center cursor-pointer"
            title="{{
              'notifications.outgoing.outgoingNotifications' | translate
            }}"
            *hasPermission="'messages.messages_read'"
          >
            <button
              type="button"
              class="btn btn-light position-relative"
              (click)="openMessages()"
            >
              <i class="fa-lg fa-regular fa-envelope"></i>
              <span
                *ngIf="numberOfMessages && numberOfMessages > 0"
                class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger text-white"
              >
                {{ numberOfMessages }}
              </span>
            </button>
          </div>

          <div class="p-2 user_info_initials">
            {{ getInitials() | uppercase }}
          </div>
          <div class="py-1 px-3 user_info_details d-none d-sm-block">
            <div>
              {{ identityService.identity.givenName }}
              {{ identityService.identity.surname }}
            </div>
            <div class="user_info_companyname">
              {{ identityService.identity.organisationName }}
            </div>
          </div>
        </div>

        <div class="d-flex flex-column service-buttons service-buttons-sm">
          <a routerLink="/users/profile">
            <i class="fa-solid fa-user"></i>
          </a>
          <a (click)="logOut()">
            <i class="fa-solid fa-power-off"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
