import { Injectable } from '@angular/core';
import { MessageModel } from '@seahorse/domain';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MessagesInteractionService {
  private messagesSubject = new BehaviorSubject<MessageModel[]>([]);
  messages$ = this.messagesSubject.asObservable();

  readMessages(messages: MessageModel[]) {
    this.messagesSubject.next(messages);
  }
}
