import { NgModule } from '@angular/core';
import { CustomDataDefinitionTypePipe } from '@seahorse/domain';

import { TemplateCodePipe } from './template-code.pipe';

@NgModule({
  declarations: [TemplateCodePipe],
  exports: [TemplateCodePipe, CustomDataDefinitionTypePipe],
  imports: [CustomDataDefinitionTypePipe],
  providers: [CustomDataDefinitionTypePipe],
})
export class CustomDataPipesModule {}
