import { Injectable } from '@angular/core';
import { ResultWrapper } from '@seahorse/common';
import { DataContextService } from '@seahorse/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'underscore';

import { PreferenceModel } from '../models/preference.model';

@Injectable()
export class PreferenceDataService {
  constructor(private dataContext: DataContextService) {}

  delete(preferenceId: number): Observable<ResultWrapper<PreferenceModel>> {
    return this.dataContext.delete<PreferenceModel>(
      `preferences/${preferenceId}`
    );
  }

  getByName(
    name: string,
    category?: string,
    isCompanyPreference = false,
    organisationId?: string
  ): Observable<ResultWrapper<PreferenceModel>> {
    let url = `preferences/${name}?category=${category}&isCompanyPreference=${isCompanyPreference}`;
    if (organisationId) {
      url += `&organisationId=${organisationId}`;
    }
    return this.dataContext
      .get<PreferenceModel>(url)
      .pipe(map(this.preferenceMapper));
  }

  getDashboardPreference(
    isCompanyPreference: boolean
  ): Observable<ResultWrapper<PreferenceModel>> {
    return this.dataContext
      .get<PreferenceModel>(`preferences/dashboard/${isCompanyPreference}`)
      .pipe(map(this.preferenceMapper));
  }

  deleteDashboardPreferences(): Observable<ResultWrapper<PreferenceModel[]>> {
    return this.dataContext
      .delete<PreferenceModel[]>(`preferences/dashboard`)
      .pipe(map(this.preferencesMapper));
  }

  get(): Observable<ResultWrapper<PreferenceModel[]>> {
    // this will returned all preferences of current logon user
    return this.dataContext
      .get<PreferenceModel[]>('preferences')
      .pipe(map(this.preferencesMapper));
  }

  save(
    preference: PreferenceModel,
    organisationId?: string
  ): Observable<ResultWrapper<PreferenceModel>> {
    // this function will add or update the preference
    let url = `preferences`;
    if (organisationId) {
      url += `?organisationId=${organisationId}`;
    }
    return this.dataContext.put<PreferenceModel>(url, preference);
  }

  preferenceMap(pref: PreferenceModel) {
    switch (pref.fieldType) {
      case 1:
        break;
      case 2:
        pref.fieldValue = parseFloat(pref.fieldValue);
        break;
      case 3:
        pref.fieldValue = pref.fieldValue.toString().toLowerCase() === 'true';
        break;
      case 4:
        break;
    }
    return pref;
  }

  preferenceMapper = (preferenceResult: ResultWrapper<PreferenceModel>) => {
    if (preferenceResult.hasResult) {
      preferenceResult.result = this.preferenceMap(preferenceResult.result);
    }

    return preferenceResult;
  };

  preferencesMapper = (preferenceResult: ResultWrapper<PreferenceModel[]>) => {
    if (preferenceResult.hasResult) {
      _.each(preferenceResult.result, (pref) => this.preferenceMap(pref));
    }

    return preferenceResult;
  };
}
