<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title-bold">
      {{ 'tasks.task.model.followUpTask' | translate }}
    </h4>
    <button type="button" class="close" closeModal>
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ca-follow-up-task-form
      [users]="users"
      [task]="task"
      [submitted]="submitted"
    >
    </ca-follow-up-task-form>

    <form [formGroup]="form">
      <div class="form-group">
        <label>
          <b>{{ 'taskGroup.display' | translate }}</b>
        </label>
        <sh-combo-box
          formControlName="selectedFormGroupId"
          [options]="taskGroupOptions"
          [newItemConfig]="newTaskGroupConfig"
          [isLoading]="isLoadingTaskGroupOptions"
        ></sh-combo-box>
      </div>
    </form>

    <sh-loading-indicator
      class="text-center d-block"
      *ngIf="saveStarted"
    ></sh-loading-indicator>
  </div>
  <div class="modal-footer">
    <button
      class="btn btn-info"
      [disabled]="saveStarted"
      data-offset="0,1"
      (click)="followUp()"
    >
      <i class="fab fa-twitter"></i> {{ 'tasks.task.followUp' | translate }}
    </button>
  </div>
</div>
