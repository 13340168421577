import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResultStatus } from '@seahorse/common';
import { NotificationService } from '@seahorse/common';
import { SystemMessageModel } from '../../../../preference/models/system-message.model';
import { SystemMessageService } from '../../../../preference/services/system-message.service';

@Component({
  selector: 'ca-system-message-modal',
  templateUrl: './system-message-modal.component.html',
})
export class SystemMessageModalComponent {
  saveStarted: boolean;
  isSubmitted = false;
  @Input() systemMessage: SystemMessageModel;
  @Output() addedSystemMessage: EventEmitter<SystemMessageModel> =
    new EventEmitter();

  constructor(
    private systemMessageService: SystemMessageService,
    private notificationService: NotificationService,
    private modalService: NgbModal
  ) {
    this.systemMessage = new SystemMessageModel();
  }

  save() {
    if (!SystemMessageModel.validate(this.systemMessage)) {
      this.isSubmitted = true;
      return;
    }

    this.saveStarted = true;

    this.add();
  }

  add() {
    this.systemMessageService.add(this.systemMessage).subscribe((response) => {
      if (response.status === ResultStatus.OK && response.result.id > 0) {
        this.notificationService.showSuccess(
          'New system message added!',
          'Successfully'
        );

        this.close();

        this.addedSystemMessage.emit(response.result);
      } else {
        this.notificationService.showError('Something went wrong!', 'Failed');
      }
      this.saveStarted = false;
    });
  }

  close() {
    this.modalService.dismissAll();
  }
}
