<div class="form-row">
  <div class="form-group col-12">
    <label for="taskDefinitionList">
      <b>{{ 'tasks.definitionlist.title' | translate }}</b>
    </label>
    <div class="input-group">
      <select
        [(ngModel)]="selectedTaskDefinitionList"
        [disabled]="loading"
        id="taskDefinitionList"
        name="taskDefinitionList"
        class="form-control"
      >
        <option [value]="null">
          {{ 'shared.terms.select' | translate }}...
        </option>
        <option
          *ngFor="let taskDefinitionList of taskDefinitionLists"
          [ngValue]="taskDefinitionList"
        >
          {{ taskDefinitionList.title }}
        </option>
      </select>
      <div *ngIf="showAddTaskDefinitionList" class="input-group-append">
        <button
          (click)="addTaskDefinitionList()"
          type="button"
          class="btn btn-outline-secondary"
        >
          <i class="fa-regular fa-plus fa-fw fa-sm"></i>
        </button>
      </div>
    </div>
  </div>
</div>
