<label for="company">
  <b>{{ 'invoicing.invoiceModel.company' | translate }}</b>
</label>

<ng-container *ngIf="isLoading; then loadingCompanies; else companyResult">
</ng-container>

<ng-template #loadingCompanies>
  <div>
    <sh-loading-indicator></sh-loading-indicator>
  </div>
</ng-template>
<ng-template #companyResult>
  <ng-container
    *ngIf="hasCompanies; then selectCompany; else noCompaniesFound"
  ></ng-container>

  <ng-template #selectCompany>
    <select
      [(ngModel)]="companyFinancialId"
      id="companyId"
      name="companyId"
      class="custom-select"
      (change)="companyChanged($event)"
    >
      <option
        *ngFor="let company of companies"
        [value]="company.id"
        [disabled]="company.id === -1"
      >
        {{ company.displayName }}
      </option>
    </select>
  </ng-template>

  <ng-template #noCompaniesFound>
    <p>{{ 'companies.company.noCompaniesToAdd' | translate }}</p>
  </ng-template>
</ng-template>
