export class ConvertObjectMappingModel {
  targetField: string;
  sourceFields?: string[];
  sourceFieldSeperator?: string;
  fixValue?: any;
  sourceValueType: SourceValueType;

  constructor(targetField: string, sourceValueType: SourceValueType) {
    this.targetField = targetField;
    this.sourceValueType = sourceValueType;
  }
}

export enum SourceValueType {
  DefaultValue,
  FieldValue
}
