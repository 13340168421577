import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'utcToLocalDateTimePipe',
})
export class UtcToLocalDateTimePipe implements PipeTransform {
  transform(utcDate: any): string {
    return moment.utc(utcDate).local().format('DD-MM-YYYY, HH:mm');
  }
}
