export class DateTimeObject {
  id!: string;
  display!: string;
  min!: number;
  max!: number;
  maxLength!: number;
  isInvalid!: boolean;

  constructor(override: DateTimeObject) {
    Object.assign(this, override);
  }
}
