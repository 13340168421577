<div class="modal-header align-items-center">
  <h1 class="modal-title">{{ 'tasks.definitionlist.modal' | translate }}</h1>
  <button closeModal type="button" class="btn btn-link text-dark">
    <i class="fa-solid fa-times fa-sm fa-fw"></i>
  </button>
</div>
<div class="modal-body">
  <task-definition-list-form
    [taskDefinitionList]="taskDefinitionList"
  ></task-definition-list-form>
</div>
<div class="modal-footer">
  <button closeModal type="button" class="btn btn-link btn-block w-50">
    {{ 'shared.terms.cancel' | translate }}
  </button>
  <button
    [disabled]="!taskDefinitionList.title"
    (click)="onAddOrEditTaskDefinitionList.emit(taskDefinitionList)"
    closeModal
    type="button"
    class="btn btn-success btn-block w-50"
  >
    <ng-container
      *ngIf="
        taskDefinitionList.id;
        then editTaskDefinitionList;
        else addTaskDefinitionList
      "
    ></ng-container>
    <ng-template #addTaskDefinitionList>{{
      'shared.terms.add' | translate
    }}</ng-template>
    <ng-template #editTaskDefinitionList>{{
      'shared.terms.update' | translate
    }}</ng-template>
  </button>
</div>
