import { Component, Input, OnInit } from '@angular/core';
import { NotificationService, ResultStatus } from '@seahorse/common';
import { CompanyDataService, CompanyModel } from '@seahorse/domain';
import { ModalAction, ModalService } from '@seahorse/temp';

import { ParentCompanyPickerComponent } from '../parent-company-picker/parent-company-picker.component';

@Component({
  selector: 'company-children-list',
  templateUrl: 'company-children-list.component.html',
})
export class CompanyChildrenListComponent implements OnInit {
  hasChild = false;
  childCompanies: CompanyModel[] = [];

  @Input() company: CompanyModel;

  constructor(
    private companyDataService: CompanyDataService,
    private notificationService: NotificationService,
    private _modalService: ModalService
  ) {}

  ngOnInit() {
    this.loadChildCompanies();
  }

  addChild() {
    this._modalService
      .buildModal()
      .withTitle('companies.company.companies')
      .withComponentBody({
        component: ParentCompanyPickerComponent,
        props: {
          company: this.company,
        },
      })
      .open()
      .subscribe((response) => {
        if (response.action === ModalAction.Confirm) {
          this.saveChild(response.componentRef.parentCompany);
          response.modalRef.close();
        }
      });
  }

  saveChild(selectedCompany: CompanyModel) {
    if (!selectedCompany) {
      return;
    }

    selectedCompany.parentCompanyId = this.company.id;
    this.companyDataService.update(selectedCompany).subscribe((response) => {
      if (response.status === ResultStatus.OK) {
        this.notificationService.showSuccess(
          'companies.company.childCompanyAdded',
          'shared.terms.error'
        );
        this.childCompanies.push(response.result);
        this.hasChild = true;
      }
    });
  }

  loadChildCompanies() {
    this.companyDataService
      .getChildCompanies(this.company.id)
      .subscribe((result) => {
        if (result.count > 0) {
          this.hasChild = true;
        }
        this.childCompanies = result.result;
      });
  }
}
