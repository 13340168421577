import { Injectable } from '@angular/core';
import { DataContextService, ResultWrapper } from '@seahorse/common';
import { Observable } from 'rxjs';
import { CompanyContactModel } from '../models/company.model';

@Injectable({
  providedIn: 'root',
})
export class CompanyContactDataService {
  constructor(private dataContext: DataContextService) {}

  getById(
    id: number,
    annotate: boolean = false
  ): Observable<ResultWrapper<CompanyContactModel>> {
    return this.dataContext.get<CompanyContactModel>(
      `contacts/${id}?annotate=${annotate}`
    );
  }
}
