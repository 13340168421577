import { Component, Input, Output, EventEmitter } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { EventModel } from '../../../../../../shared/models/event.model';
import { CalculationsModalComponent } from '../calculations-modal/calculations-modal.component';

@Component({
  selector: 'calculations-info',
  templateUrl: 'calculations-info.component.html',
})
export class CalculationsInfoComponent {
  @Input() calculations: any[];
  @Input() loadingCalculations: boolean;

  @Output() onEvent: EventEmitter<EventModel>;

  constructor(private modal: NgbModal) {
    this.calculations = [];
    this.loadingCalculations = false;
    this.onEvent = new EventEmitter<EventModel>();
  }

  addCalculation() {
    const calculationsModal = this.modal.open(CalculationsModalComponent);

    // @Input()
    calculationsModal.componentInstance.calculation = null;

    // @Output()
    calculationsModal.componentInstance.onEvent.subscribe(
      (calculationsEvent: EventModel) => this.onEvent.emit(calculationsEvent)
    );
  }
}
