import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SystemMessageChannelModel } from '../../../../preference/models/system-message-channel.model';
import { SystemMessageReleaseModel } from '../../../../preference/models/system-message-release.model';
import { SystemMessageReleaseService } from '../../../../preference/services/system-message-release.service';

@Component({
  selector: 'ca-system-message-release-modal',
  templateUrl: './system-message-release-modal.component.html',
})
export class SystemMessageReleaseModalComponent implements OnInit {
  saveStarted: boolean;
  isSubmitted = false;
  systemMessageRelease: SystemMessageReleaseModel;
  systemMessageChannels: SystemMessageChannelModel[];
  isNew = false;

  @Output() onSelectRelease: EventEmitter<SystemMessageReleaseModel>;

  constructor(
    private systemMessageReleaseService: SystemMessageReleaseService,
    private modalService: NgbModal
  ) {
    this.systemMessageRelease = new SystemMessageReleaseModel();
    this.onSelectRelease = new EventEmitter<SystemMessageReleaseModel>();
  }

  ngOnInit(): void {
    this.systemMessageReleaseService
      .getAllSystemMessageChannels()
      .subscribe((result) => {
        this.systemMessageChannels = result.result;
      });
  }

  save() {
    if (!SystemMessageReleaseModel.validate(this.systemMessageRelease)) {
      this.isSubmitted = true;
      return;
    }

    this.onSelectRelease.emit(this.systemMessageRelease);
  }

  close() {
    this.modalService.dismissAll();
  }
}
