import { Component, Input, Output, EventEmitter } from '@angular/core';
import { EventModel } from '../../../../shared/models/event.model';
import { SystemMessageReleaseModel } from '../../../../preference/models/system-message-release.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from '@seahorse/common';
import { TranslateService } from '@ngx-translate/core';
import { SystemMessageReleaseModalComponent } from '../system-message-release-modal/system-message-release-modal.component';
import { SystemMessageReleaseService } from '../../../../preference/services/system-message-release.service';
import { ResultWrapper } from '@seahorse/common';
import * as _ from 'underscore';

@Component({
  selector: 'system-message-release-card',
  templateUrl: 'system-message-release-card.component.html',
})
export class SystemMessageReleaseCardComponent {
  @Input() systemMessageReleases: SystemMessageReleaseModel[];
  @Input() systemMessageId: number;

  @Output() onEvent: EventEmitter<EventModel>;

  isLoading = false;

  constructor(
    private ngbModal: NgbModal,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private systemMessageReleaseService: SystemMessageReleaseService
  ) {
    this.onEvent = new EventEmitter<EventModel>();
  }

  loadSystemMessageReleases() {
    this.systemMessageReleaseService
      .getAllBySystemMessageId(this.systemMessageId)
      .subscribe((result) => {
        this.systemMessageReleases = result.result;
      });
  }

  action(event: EventModel) {
    switch (event.action) {
      case 'editRelease':
        const releaseModal = this.ngbModal.open(
          SystemMessageReleaseModalComponent,
          { backdrop: 'static' }
        );

        if (event.data) {
          releaseModal.componentInstance.systemMessageRelease = _.clone(
            event.data
          );

          releaseModal.componentInstance.onSelectRelease.subscribe(
            (release: SystemMessageReleaseModel) => {
              this.systemMessageReleaseService
                .update(release)
                .subscribe((response: ResultWrapper<any>) => {
                  if (response.hasResult) {
                    this.loadSystemMessageReleases();
                    this.notificationService.showSuccess(
                      this.translateService.instant(
                        'systemMessage.release.releaseUpdated'
                      ),
                      this.translateService.instant('shared.terms.success')
                    );
                  } else {
                    this.notificationService.showError(
                      this.translateService.instant('shared.terms.failed')
                    );
                  }
                });

              releaseModal.dismiss();
            }
          );
        } else {
          releaseModal.componentInstance.isNew = true;
          releaseModal.componentInstance.onSelectRelease.subscribe(
            (release: SystemMessageReleaseModel) => {
              release.systemMessageId = this.systemMessageId;
              this.systemMessageReleaseService
                .add(release)
                .subscribe((response: ResultWrapper<any>) => {
                  if (response.hasResult) {
                    this.loadSystemMessageReleases();

                    this.notificationService.showSuccess(
                      this.translateService.instant(
                        'systemMessage.release.releaseAdded'
                      ),
                      this.translateService.instant('shared.terms.success')
                    );
                  } else {
                    this.notificationService.showError(
                      this.translateService.instant('shared.terms.failed')
                    );
                  }
                });

              releaseModal.dismiss();
            }
          );
        }
        break;
      case 'deleteRelease':
        this.systemMessageReleaseService
          .delete(event.data.id)
          .subscribe((response: ResultWrapper<any>) => {
            if (response.hasResult) {
              this.loadSystemMessageReleases();

              this.notificationService.showSuccess(
                this.translateService.instant(
                  'systemMessage.release.releaseDeleted'
                ),
                this.translateService.instant('shared.terms.success')
              );
            } else {
              this.notificationService.showError(
                this.translateService.instant('shared.terms.failed')
              );
            }
          });
        break;
    }
  }
}
