<div class="page-with-header">
  <!-- Header -->
  <flow-creator-header
    [loadingFlow]="loadingFlow"
    (onEvent)="action($event)"
  ></flow-creator-header>
  <!-- Content -->
  <div class="container-fluid py-3">
    <div class="card">
      <div class="card-header">
        {{ 'invoicingFlow.flowCreator.newFlow' | translate }}
      </div>
      <div class="card-body">
        <ca-flow-form
          [flow]="flow"
          [loadingFlow]="loadingFlow"
        ></ca-flow-form>
      </div>
    </div>
  </div>
</div>
