import { Injectable } from '@angular/core';

@Injectable()
export class AppInitializerService {
  // NOTE: Please don't use any functionality here that has an inherent delay, like API calls
  Init() {
    return new Promise<any>((resolve, reject) => {
      resolve(true);
    });
  }
}
