import {
  Component,
  Injector,
  ViewChild,
  ViewContainerRef,
  InjectionToken,
  Type,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { InteractionService } from '../../../shared/services/interaction.service';
import { BaseModel } from '@seahorse/common';

@Component({
  selector: 'summary',
  templateUrl: './summary.component.html',
})
export class SummaryComponent implements OnInit {
  isLoading = false;
  model: BaseModel;
  errors: string[] = [];

  @ViewChild('form', { read: ViewContainerRef, static: true })
  form: ViewContainerRef;

  object: any;
  injectionToken: InjectionToken<string>;
  interactionService: InteractionService;
  dataService: any;
  formComponent: any;

  constructor(
    private injector: Injector,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.route.parent.data.subscribe((res) => {
      this.object = res['object'];
      this.dataService = res['dataService'];
      this.injectionToken = res['interactionService'];
      this.formComponent = res['form'];
    });
    this.dataService = this.injector.get<any>(this.dataService);

    this.interactionService = this.injector.get<any>(this.injectionToken);

    this.route.url.subscribe((url) => {
      const path = url[0].path;
    });

    this.route.params.subscribe((routeParams) => {
      let idString = routeParams['id'];
      if (idString.includes('base')) {
        idString = routeParams['id'].split('_')[1];
      }
      const id = +idString;
      this.load(id);
    });
  }

  load(id: number) {
    let component;
    this.isLoading = true;
    this.errors = [];

    this.dataService.getById(id).subscribe(
      (result) => {
        const instance = Object.create(this.object.prototype);
        this.model = instance;

        Object.assign(this.model, result.result);
      },
      (error) => {},
      () => {
        if (this.form != undefined) {
          this.form.clear();
        }

        component = this.form.createComponent(this.formComponent);
        component.instance.model = this.model;

        this.isLoading = false;
      }
    );
  }

  save() {
    const validationResult = this.model.validate();

    if (!validationResult.isValid) {
      this.errors = validationResult.errors;
      return;
    }

    this.interactionService.modify(this.model).subscribe();
  }

  delete() {
    this.interactionService.delete(this.model);
  }
}
