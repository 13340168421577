import { Routes } from '@angular/router';
import { NauticalMapComponent } from './components/nautical-map/nautical-map.component';

export const MAP_ROUTES: Routes = [
  {
    path: '',
    component: NauticalMapComponent,
    data: {
      ui: {
        headerTitle: 'map.title',
      },
    },
  },
];
