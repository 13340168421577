<ng-container>
  <div class="d-flex justify-content-between">
    <label for="typeahead-{{name}}">
      <b [ngClass]="{ required: required }">{{ placeholder }}</b>
      <span *ngIf="isLoading">
        <i class="fa-regular fa-spinner fa-spin fa-fw"></i>
      </span>
    </label>
    <div>
      <button
        type="button"
        class="btn btn-link btn-sm"
        (click)="clearTagFilter()"
        *ngIf="fieldDefinition.scope === ScopeType.Custom && tagFilter && tagFilter.length > 0"
      >
        <i class="fa-regular fa-close fa-fw"></i>
        {{ tagFilter }}
      </button>
      <button
        type="button"
        class="btn btn-link btn-sm"
        (click)="createNewObject()"
        *ngIf="!hideAddButton"
      >
        <i class="fa-regular fa-plus fa-fw"></i>
        {{ 'shared.terms.new' | translate }}
      </button>
    </div>
  </div>
  <div class="input-group">
    <input
      [ngModel]="dataValue"
      (ngModelChange)="selectItem($event)"
      [class.is-invalid]="hasLoadingFailed"
      [ngbTypeahead]="dataSearch"
      [inputFormatter]="dataFormatter"
      [resultFormatter]="dataFormatter"
      [placeholder]="('shared.terms.search' | translate) + ' ' + placeholder + '...'"
      [required]="required"
      id="typeahead-{{name}}"
      [name]="name"
      type="text"
      class="form-control"
    />
    <div class="input-group-append">
      <button
        (click)="selectItem(null)"
        type="button"
        class="btn bg-transparent br-0"
        style="margin-left: -37px; z-index: 100"
      >
        <i class="fa-solid fa-times fa-xs fa-fw"></i>
      </button>
    </div>
  </div>
</ng-container>
