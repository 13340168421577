<div class="modal-header align-items-center">
  <h1 class="modal-title">{{ 'shared.terms.editAttribute' | translate }}</h1>
  <button closeModal type="button" class="btn btn-link text-dark">
    <i class="fa-solid fa-times fa-sm fa-fw"></i>
  </button>
</div>
<div class="modal-body">
  <label for="zeehavenIJmuidenSpecific">
    <b>{{
      'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.details.zeehavenIJmuidenSpecific'
        | translate
    }}</b>
  </label>
  <div class="row no-gutters my-3">
    <div class="col-12 col-sm-4">
      <div class="custom-control custom-checkbox mr-1">
        <input
          [(ngModel)]="fender"
          type="checkbox"
          class="custom-control-input"
          id="fender"
        />
        <label
          class="custom-control-label"
          for="fender"
          style="overflow: initial"
          >{{
            'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.placeholder.fender'
              | translate
          }}</label
        >
      </div>
    </div>
    <div class="col-12 col-sm-8">
      <div class="input-group mt-n2">
        <input
          [(ngModel)]="fenderMessage"
          placeholder="{{
            'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.placeholder.fenderMessage'
              | translate
          }}"
          type="text"
          class="form-control"
          id="fenderMessage"
        />
      </div>
    </div>
  </div>
  <div class="row no-gutters my-3">
    <div class="col-12 col-sm-4">
      <div class="custom-control custom-checkbox mr-1">
        <input
          [(ngModel)]="oilScreen"
          type="checkbox"
          class="custom-control-input"
          id="oilScreen"
        />
        <label
          class="custom-control-label"
          for="oilScreen"
          style="overflow: initial"
          >{{
            'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.placeholder.oilScreen'
              | translate
          }}</label
        >
      </div>
    </div>
    <div class="col-12 col-sm-8">
      <div class="input-group mt-n2">
        <input
          [(ngModel)]="oilScreenMessage"
          placeholder="{{
            'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.placeholder.oilScreenMessage'
              | translate
          }}"
          type="text"
          class="form-control"
          id="oilScreenMessage"
        />
      </div>
    </div>
  </div>
  <div class="row no-gutters my-3">
    <div class="col-12 col-sm-4">
      <div class="custom-control custom-checkbox mr-1">
        <input
          [(ngModel)]="power"
          type="checkbox"
          class="custom-control-input"
          id="power"
        />
        <label
          class="custom-control-label"
          for="power"
          style="overflow: initial"
          >{{
            'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.placeholder.power'
              | translate
          }}</label
        >
      </div>
    </div>
    <div class="col-12 col-sm-8">
      <div class="input-group mt-n2">
        <input
          [(ngModel)]="powerMessage"
          placeholder="{{
            'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.placeholder.powerMessage'
              | translate
          }}"
          type="text"
          class="form-control"
          id="powerMessage"
        />
      </div>
    </div>
  </div>
  <div class="row no-gutters my-3">
    <div class="col-12 col-sm-4">
      <div class="custom-control custom-checkbox mr-1">
        <input
          [(ngModel)]="tug"
          type="checkbox"
          class="custom-control-input"
          id="tug"
        />
        <label
          class="custom-control-label"
          for="tug"
          style="overflow: initial"
          >{{
            'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.placeholder.tug'
              | translate
          }}</label
        >
      </div>
    </div>
    <div class="col-12 col-sm-8">
      <div class="input-group mt-n2">
        <input
          [(ngModel)]="tugMessage"
          placeholder="{{
            'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.placeholder.tugMessage'
              | translate
          }}"
          type="text"
          class="form-control"
          id="tugMessage"
        />
      </div>
    </div>
  </div>
  <div class="row no-gutters my-3">
    <div class="col-12 col-sm-4">
      <div class="custom-control custom-checkbox mr-1">
        <input
          [(ngModel)]="garbage"
          type="checkbox"
          class="custom-control-input"
          id="garbage"
        />
        <label
          class="custom-control-label"
          for="garbage"
          style="overflow: initial"
          >{{
            'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.placeholder.garbage'
              | translate
          }}</label
        >
      </div>
    </div>
    <div class="col-12 col-sm-8">
      <div class="input-group mt-n2">
        <input
          [(ngModel)]="garbageMessage"
          placeholder="{{
            'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.placeholder.garbageMessage'
              | translate
          }}"
          type="text"
          class="form-control"
          id="garbageMessage"
        />
      </div>
    </div>
  </div>
  <div class="row no-gutters my-3">
    <div class="col-12 col-sm-4">
      <div class="custom-control custom-checkbox mr-1">
        <input
          [(ngModel)]="water"
          type="checkbox"
          class="custom-control-input"
          id="water"
        />
        <label
          class="custom-control-label"
          for="water"
          style="overflow: initial"
          >{{
            'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.placeholder.water'
              | translate
          }}</label
        >
      </div>
    </div>
    <div class="col-12 col-sm-8">
      <div class="input-group mt-n2">
        <input
          [(ngModel)]="waterMessage"
          placeholder="{{
            'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.placeholder.waterMessage'
              | translate
          }}"
          type="text"
          class="form-control"
          id="waterMessage"
        />
      </div>
    </div>
  </div>
  <div class="row no-gutters my-3">
    <div class="col-12 col-sm-4">
      <div class="custom-control custom-checkbox mr-1">
        <input
          [(ngModel)]="workPlatform"
          type="checkbox"
          class="custom-control-input"
          id="workPlatform"
        />
        <label
          class="custom-control-label"
          for="workPlatform"
          style="overflow: initial"
          >{{
            'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.placeholder.workPlatform'
              | translate
          }}</label
        >
      </div>
    </div>
    <div class="col-12 col-sm-8">
      <div class="input-group mt-n2">
        <input
          [(ngModel)]="workPlatformMessage"
          placeholder="{{
            'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.placeholder.workPlatformMessage'
              | translate
          }}"
          type="text"
          class="form-control"
          id="workPlatformMessage"
        />
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button closeModal type="button" class="btn btn-link btn-block w-50">
    {{ 'shared.terms.cancel' | translate }}
  </button>
  <button
    (click)="
      onPickZeehavenIJmuidenSpecific.emit({
        oilScreen: oilScreen,
        oilScreenMessage: oilScreenMessage,
        power: power,
        powerMessage: powerMessage,
        tug: tug,
        tugMessage: tugMessage,
        fender: fender,
        fenderMessage: fenderMessage,
        garbage: garbage,
        garbageMessage: garbageMessage,
        water: water,
        waterMessage: waterMessage,
        workPlatform: workPlatform,
        workPlatformMessage: workPlatformMessage
      })
    "
    closeModal
    type="button"
    class="btn btn-success btn-block w-50"
  >
    {{ 'shared.terms.confirm' | translate }}
  </button>
</div>
