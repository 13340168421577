<div class="modal-header">
  <h4 class="modal-title-bold">
    {{ 'customModules.model.newModule' | translate }}
  </h4>
  <button (click)="close()" type="button" class="btn btn-link text-dark">
    <i class="fa-solid fa-times fa-fw fa-sm"></i>
  </button>
</div>
<div class="modal-body">
  <sh-loading-indicator
    *ngIf="isLoading"
    class="d-flex justify-content-center loader-sm mr-2 mt-2"
  >
  </sh-loading-indicator>

  <div *ngIf="!isLoading">
    <div class="form-group">
      <label class="control-label required"
        ><b>{{ 'customModules.model.moduleDefiniton' | translate }}</b></label
      >
      <ng-select
        name="tolocationrange"
        [(ngModel)]="selectedDefinition"
        [items]="definitions"
        placeholder="Select a definition"
        bindLabel="displayName"
        bindValue="systemCode"
        [clearable]="false"
        [searchable]="false"
        (change)="uiModule.displayIcon = $event.displayIcon"
      >
      </ng-select>
    </div>

    <div class="form-group">
      <label class="control-label required"
        ><b>{{ 'customModules.model.displayName' | translate }}</b></label
      >
      <input
        required
        class="form-control"
        name="name"
        #name="ngModel"
        [(ngModel)]="uiModule.displayName"
      />
    </div>
    <div class="form-group">
      <label for="displayIcon" class="control-label">
        <b>{{ 'uiModules.moduleDefinition.displayIcon' | translate }}</b>
      </label>
      <ca-icon-picker-grid
        [(selectedIcon)]="uiModule.displayIcon"
      ></ca-icon-picker-grid>
    </div>
  </div>
  <div class="modal-footer row no-gutters">
    <div class="col">
      <button (click)="cancel()" type="button" class="btn btn-block btn-link">
        {{ 'shared.terms.cancel' | translate }}
      </button>
    </div>
    <div class="col">
      <button
        (click)="save()"
        [disabled]="!uiModule.displayName || !selectedDefinition"
        type="button"
        class="btn btn-block btn-primary"
      >
        {{ 'shared.terms.save' | translate }}
      </button>
    </div>
  </div>
</div>
