<div class="p-relative">
  <sh-loading-indicator
    *ngIf="isLoading"
    class="loading-indicator-overlay"
  ></sh-loading-indicator>
  <div class="card-body">
    <ng-template #form></ng-template>

    <div class="row" *ngIf="errors && errors.length > 0">
      <div class="col-12">
        <div class="alert alert-danger">
          <p>Errors</p>
          <ul>
            <li *ngFor="let error of errors">{{ error }}</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="form-group text-right">
      <button class="btn btn-outline-success" (click)="save()">
        <i class="fa-regular fa-save"></i> {{ 'shared.terms.save' | translate }}
      </button>
      <button class="btn btn-outline-danger" type="button" (click)="delete()">
        <i class="fa-solid fa-fw"></i> {{ 'shared.terms.delete' | translate }}
      </button>
    </div>
  </div>
</div>
