/* eslint-disable @typescript-eslint/no-unused-vars */
import { inject, Injectable } from '@angular/core';
import { EnvService } from '@seahorse/common';
import { FileModel } from '@seahorse/domain';
import { Subject } from 'rxjs';
import { FileDropModel } from '../file-drop.model';
import { FileListCardComponent } from './file-list-card.component';

@Injectable({
  providedIn: 'root',
})
export class BaseFileListCardService {
  env = inject(EnvService);
  private _reloadSub = new Subject();
  reload$ = this._reloadSub.asObservable();
  
  reload() {
    this._reloadSub.next();
  }

  setMatchingLinkedObjects(files: FileModel[], linkedObjectsIds: any[]) {
    if (!this.env.useFileUploadWizard || !linkedObjectsIds) {
      return [];
    }

    const matchingLinkedObjects: any[] = [];

    files.forEach((file) => {
      if (file.objectFiles && Array.isArray(file.objectFiles)) {
        file.objectFiles.forEach((objectFile) => {
          Object.keys(linkedObjectsIds).forEach((key) => {
            const linkedObjectValue = linkedObjectsIds[key];

            let isMatch = false;
            if (Array.isArray(linkedObjectValue)) {
              isMatch = linkedObjectValue.includes(objectFile.objectId);
            } else if (typeof linkedObjectValue === 'string') {
              isMatch = linkedObjectValue === objectFile.objectId;
            }

            if (isMatch) {
              file.hasLinkedObjects = true;
              const existingObject = matchingLinkedObjects.find(
                (item) => item.key === key && item.fileId === file.id
              );
              if (existingObject) {
                existingObject.ids.push(objectFile.objectId);
              } else {
                matchingLinkedObjects.push({
                  key: key,
                  ids: [objectFile.objectId],
                  fileId: file.id,
                });
              }
            }
          });
        });
      }
    });

    return matchingLinkedObjects;
  }

  filesAdd(component: FileListCardComponent, fileDropped?: FileDropModel) {
    throw new Error('Not implemented');
  };
  
  openImportLinkedObjectsModal(file: FileModel, component: FileListCardComponent) {
    throw new Error('Not implemented');
  };
}
