<div
  class="card tile tile-clickable"
  *ngFor="let message of systemMessages"
  (click)="selectSystemMessage(message)"
  [ngClass]="{
    selected: selectedSystemMessage && selectedSystemMessage.id === message.id
  }"
>
  <div class="card-body">
    <div class="row seperated-columns">
      <div class="col-4">
        <strong>
          {{ message.subject | uppercase }}
        </strong>
        <ul class="list-inline list-inline-seperated">
          <li class="list-inline-item">
            <span class="text-muted"
              >{{ 'systemMessage.version' | translate }}:</span
            >
            {{ message.version }}
          </li>
        </ul>
      </div>
      <div class="col-4">
        <div class="attribute-group attribute-group-col-2">
          <div class="attribute-group-item">
            <dt>{{ 'systemMessage.notes' | translate }}:</dt>
            <dd>
              {{ message.notes | shTruncate: 20 }}
            </dd>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="attribute-group attribute-group-col-2">
          <div class="attribute-group-item">
            <dt>{{ 'systemMessage.createdOn' | translate }}:</dt>
            <dd>
              {{ message.createdOn | utcToLocalDatePipe }}
            </dd>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
