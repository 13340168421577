<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title-bold">
      {{ 'taskDefinitionList.modalTitle' | translate }}
    </h4>
    <button type="button" class="close" (click)="cancel()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="!isEdit" class="centered">
      <h5>
        {{ 'widgets.designer.widgetType' | translate }}
      </h5>
      <ng-select
        class="mb-2"
        [items]="types"
        bindLabel="name"
        bindValue="id"
        [(ngModel)]="selectedType"
        [clearable]="false"
      ></ng-select>
      <input
        type="text"
        class="form-control"
        placeholder="{{ 'widgets.name' | translate }}"
        [(ngModel)]="name"
      />
    </div>
    <ng-container
      *ngIf="selectedType !== undefined && selectedType !== null"
      [ngSwitch]="selectedType"
    >
      <ca-invoice-widget-designer
        *ngSwitchCase="widgetTypes.Invoice"
        [widget]="configuration"
      ></ca-invoice-widget-designer>
      <ca-task-widget-designer
        *ngSwitchCase="widgetTypes.Task"
        [widget]="configuration"
      ></ca-task-widget-designer>
    </ng-container>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-block btn-success" (click)="save()">
      {{ 'shared.terms.save' | translate }}
    </button>
  </div>
</div>
