import * as moment from 'moment';
import { isArray } from 'util';
import { ValidationResult } from '@seahorse/common';
import { KPStatus } from '../models/kp-status.model';
import * as _ from 'underscore';
import { CustomDataBaseModel } from '@seahorse/domain';
import { KPActivityLogicService } from '../services/kp-activity-logic.service';

export class KPActivityModel extends CustomDataBaseModel {
  activityType: string = null;
  berth1: string = null;
  berth2: string = null;
  cancelledReason: string = null;
  cancelledOrder = false;
  douane = false; // only for Materiaal
  endsOn: Date = null;
  hasWaitingTime = false;
  mail = false; // only for Communicatievaren
  motorboat1 = false;
  motorboat2 = false; // only for verhalen
  orderNumber: string = null;
  orderTime: Date = null;
  people: string = null;
  plastic = false; // only for Materiaal
  quantity: number = null;
  remarks: string = null;
  sailedTime: Date = null;
  startsOn: Date = null;
  status: string = KPStatus.Active;
  subject: string = null;
  subType: string = null;
  waitingTime = 0;
  waitingTimeReason: string = null;
  weekendTariffOff = false;
  boardType?: string = null; // only for 'Boord'

  // External references
  $nautical_portmovement_id: number = null;
  $nautical_portvisit_id: number = null;
  $nautical_ship_id: number = null;
  $companies_company_id: number = null;

  // for display/UI only
  $nautical_portvisit = null;
  $nautical_ship = null;
  $companies_company = null;
  berth1Name = null;
  berth2Name = null;

  errors: string;

  constructor() {
    super();

    this.$nautical_portmovement_id = null;
    this.$nautical_portvisit_id = null;
    this.$nautical_ship_id = null;
    this.$companies_company_id = null;
  }

  static hasBerth2(activityType: string) {
    return (
      [
        'verhalen',
        'communicatie',
        'slepen',
        'verhuur',
        'trossen',
        'overig',
      ].indexOf(activityType) > -1
    );
  }

  static isOrderNumberRequired(activityType: string) {
    return (
      [
        'communicatie',
        'materiaal',
        'boord',
        'standby',
        'slepen',
        'overig',
      ].indexOf(activityType) > -1
    );
  }

  static fromApiModel(model: any) {
    if (model.people && model.people.length > 0) {
      model.people = model.people.split(',');
    }

    if (model.materialTypes && model.materialTypes.length > 0) {
      model.materialTypes = model.materialTypes.split(',');
    }

    if (model.__AdditionalData) {
      _.each(model.__AdditionalData, (data, key) => {
        const parts = key.split('_');
        if (parts.length > 2) {
          // dirty fix, convert all properties to lowercase
          const props = _.keys(data);
          _.each(props, (prop) => {
            data[prop.toLowerCase()] = data[prop];
          });

          model[parts[0] + '_' + parts[1]] = data;
        }
      });
    }

    return model;
  }

  static toApiModel(model: any) {
    if (model.people && isArray(model.people)) {
      model.people = model.people.join();
    }

    if (model.materialTypes && isArray(model.materialTypes)) {
      model.materialTypes = model.materialTypes.join();
    }

    return model;
  }

  static validate(
    activity: KPActivityModel,
    isFinish: boolean,
    activityTypesWithSubtype: string[],
    errorPrefix?: string
  ): ValidationResult {
    const result: ValidationResult = new ValidationResult();
    if (!errorPrefix) {
      errorPrefix = '';
    }

    // valid ship; is required
    if (!activity.$nautical_ship_id || activity.$nautical_ship_id === 0) {
      result.errors.push(errorPrefix + 'Het veld schip is leeg');
    }

    // valid activitytype; is required
    if (!activity.activityType || activity.activityType === '') {
      result.errors.push(errorPrefix + 'Het veld type dienst is leeg');
    }

    // valid agent; is required
    if (!activity.$companies_company_id || activity.$companies_company_id < 0) {
      result.errors.push(errorPrefix + 'Het veld agent is leeg');
    }

    // valid the time
    let isStartValid = null;
    if (activity.startsOn) {
      isStartValid = moment(activity.startsOn).isValid();
    }

    let isEndsValid = null;
    if (activity.endsOn) {
      isEndsValid = moment(activity.endsOn).isValid();
    }

    if (isStartValid === false) {
      result.errors.push(errorPrefix + 'Starttijd formaat is fout');
    }

    if (isEndsValid === false) {
      result.errors.push(errorPrefix + 'Endtijd formaat is fout');
    }

    if (
      isStartValid &&
      isEndsValid &&
      moment(activity.endsOn).isBefore(activity.startsOn)
    ) {
      result.errors.push(
        errorPrefix + 'Het veld eindtijd kan niet eerder dan starttijd'
      );
    }

    if (!activity.orderTime) {
      // order time is required for "ontmeren" and "verhalen"
      if (
        activity.activityType === 'ontmeren' ||
        activity.activityType === 'verhalen'
      ) {
        result.errors.push(errorPrefix + 'De besteltijd is leeg');
      }
    } else if (moment(activity.orderTime).isValid() === false) {
      result.errors.push(errorPrefix + 'Besteltijd formaat is fout');
    }

    if (
      activity.sailedTime &&
      moment(activity.sailedTime).isValid() === false
    ) {
      result.errors.push(errorPrefix + 'Opvaren tijd formaat is fout');
    }

    if (activity.quantity && isNaN(activity.quantity)) {
      result.errors.push(errorPrefix + 'Het veld aantal formaat is fout');
    }

    if (activity.activityType === 'boord' && !activity.boardType) {
      result.errors.push(errorPrefix + 'Aan/van boord is leeg');
    }

    // extra checks for during finish the activity
    if (isFinish === true) {
      if (
        activityTypesWithSubtype &&
        activityTypesWithSubtype.length > 0 &&
        activity.activityType
      ) {
        if (
          activityTypesWithSubtype.indexOf(activity.activityType) > -1 &&
          !activity.subType
        ) {
          result.errors.push(
            errorPrefix + 'Het subtype is verplicht bij het afsluiten.'
          );
        }
      }

      // for "meren" is sailedTime required
      if (!activity.sailedTime && activity.activityType === 'meren') {
        result.errors.push(
          errorPrefix + 'Het veld opvaren is verplicht bij het afsluiten.'
        );
      }

      if (!activity.startsOn) {
        result.errors.push(
          errorPrefix + 'Het veld starttijd is verplicht bij het afsluiten.'
        );
      }

      if (!activity.endsOn) {
        result.errors.push(
          errorPrefix + 'Het veld eindtijd is verplicht bij het afsluiten.'
        );
      }

      if (!activity.berth1) {
        result.errors.push(
          errorPrefix +
            'Het veld ligplaats (van) is verplicht bij het afsluiten.'
        );
      }

      if (activity.activityType === 'verhalen' && !activity.berth2) {
        // only for the type verhalen is the berth2 required
        result.errors.push(
          errorPrefix +
            'Het veld ligplaats (naar) is verplicht bij het afsluiten.'
        );
      }

      if (!activity.people) {
        result.errors.push(
          errorPrefix + 'Het veld personen is verplicht bij het afsluiten.'
        );
      }

      if (
        KPActivityModel.isOrderNumberRequired(activity.activityType) &&
        (!activity.orderNumber || activity.orderNumber.trim().length === 0)
      ) {
        result.errors.push(
          errorPrefix +
            'Het PO nummer is verplicht bij het afsluiten van mat. en comm. varen. Controleer je e-mailbevestiging voor de referentie!'
        );
      }

      const quantitySubtypes = Object.values(KPActivityLogicService.subTypes)
        .reduce((acc, cur) => acc.concat(cur), [])
        .filter((x: { display?: string }) => x.display != null)
        .map((x) => x.code);

      if (quantitySubtypes.includes(activity.subType)) {
        if (
          activity.quantity === undefined ||
          activity.quantity === null ||
          isNaN(activity.quantity)
        ) {
          result.errors.push(
            'Het veld hoeveelheid is verplicht bij het afsluiten.'
          );
        }
      }
    }

    result.isValid = result.errors.length === 0;
    return result;
  }
}

export class KPActivityDisplayModel extends KPActivityModel {
  endsOnDisplay: string = null;
  orderTimeDisplay: string = null;
  sailedTimeDisplay: string = null;
  startsOnDisplay: string = null;
  orderNumberLocked = false;
}
