import { CustomDataBaseModel } from '@seahorse/domain';

export class OrderRegistrationHotelStayModel extends CustomDataBaseModel {
  roomOrName: string;
  checkInDate?: Date;
  checkOutDate?: Date;
  dayroom: boolean;
  earlyCheckIn: boolean;
  lateCheckOut: boolean;
  breakfast: boolean;
  lunch: boolean;
  dinner: boolean;
  drinksIncluded: boolean;

  constructor() {
    super();

    this.roomOrName = null;
    this.checkInDate = null;
    this.checkOutDate = null;
    this.dayroom = false;
    this.earlyCheckIn = false;
    this.lateCheckOut = false;
    this.breakfast = false;
    this.lunch = false;
    this.dinner = false;
    this.drinksIncluded = false;
  }
}
