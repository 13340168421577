import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AISMarkerModel } from '@seahorse/domain';
import { MapInteractionService } from '../../../map/services/map-interaction.service';
import { NauticalCoordinatesDataService } from '../../../nautical-geography/services/nautical-coordinates-data.service';
import { NauticalShipModel } from '@seahorse/domain';
import { NauticalShipDataService } from '@seahorse/domain';
import { NauticalVisitOverviewModel } from '@seahorse/domain';
import { NauticalVisitDataService } from '@seahorse/domain';
import { AreaActivityModel } from '../../../superuser-nautical/models/area-activity.model';
import * as moment from 'moment';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'ca-map-area-ship-list',
  templateUrl: './map-area-ship-list.component.html',
  styleUrls: ['./map-area-ship-list.component.scss'],
})
export class MapAreaShipListComponent implements OnInit, OnDestroy {
  @Input() area: AreaActivityModel;
  @Output() onSelectVessel: EventEmitter<any> = new EventEmitter<any>();

  private subscriptions$ = Array<Subscription>();
  ship: NauticalShipModel;
  activeVisit: NauticalVisitOverviewModel = null;
  visitData: any[] = [];
  isLoading: boolean;

  constructor(
    private nauticalShipDataService: NauticalShipDataService,
    private nauticalVisitDataService: NauticalVisitDataService,
    public mapInteractionService: MapInteractionService,
    private coordinatesDataService: NauticalCoordinatesDataService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.loadMarkers();
    this.getPolygon();

    const source = interval(this.area.refreshInterval * 60 * 1000);

    this.subscriptions$.push(
      source.subscribe(
        (val) => {
          this.showPolygon();
        },
        () => {},
        () => {}
      )
    );
  }

  ngOnDestroy() {
    this.subscriptions$.forEach((s) => s.unsubscribe());
  }

  loadMarkers() {
    this.isLoading = true;
    this.area.markers = [];

    this.subscriptions$.push(
      this.mapInteractionService.areaActivityMarkers$.subscribe(
        (markers) => {
          this.area.markers = markers;
          this.isLoading = false;
        },
        () => {},
        () => {}
      )
    );
  }

  getPolygon() {
    this.subscriptions$.push(
      this.coordinatesDataService
        .getPolygon('areaactivity', this.area.id)
        .subscribe(
          (response) => {
            if (response) {
              this.area.polygon = response;
              this.showPolygon();
            } else {
              this.isLoading = false;
            }
          },
          () => {},
          () => {}
        )
    );
  }

  showPolygon() {
    this.mapInteractionService.showPolygon(this.area.polygon.points);
  }

  getShip(marker: AISMarkerModel) {
    this.mapInteractionService.selectPopup(marker);

    if (marker.imo && +marker.imo !== 0) {
      this.subscriptions$.push(
        this.nauticalShipDataService.getByImo(marker.imo).subscribe(
          (response) => {
            if (response.result) {
              this.getVisitData(response.result);
            }
          },
          (e) => {
            this.ship = null;
          },
          () => {}
        )
      );
    } else if (marker.mmsi && +marker.mmsi !== 0) {
      this.subscriptions$.push(
        this.nauticalShipDataService.getByMmsi(marker.mmsi).subscribe(
          (response) => {
            if (response.result) {
              this.getVisitData(response.result);
            }
          },
          (e) => {
            this.ship = null;
          },
          () => {}
        )
      );
    }

    this.ship = null;
  }

  getVisitData(ship: NauticalShipModel) {
    this.ship = ship;

    this.subscriptions$.push(
      this.nauticalVisitDataService
        .getListOverviewForShip(ship.id, 'expectedinport')
        .subscribe(
          (res) => {
            if (res.result && res.result.length > 0) {
              this.activeVisit = res.result[0];

              if (this.activeVisit.ata) {
                this.visitData.push({
                  fieldName:
                    this.translateService.instant('nautical.terms.ata'),
                  fieldValue: moment(this.activeVisit.ata).format(
                    'DD-MM-YYYY HH:mm'
                  ),
                });
              } else if (this.activeVisit.eta) {
                this.visitData.push({
                  fieldName:
                    this.translateService.instant('nautical.terms.eta'),
                  fieldValue: moment(this.activeVisit.eta).format(
                    'DD-MM-YYYY HH:mm'
                  ),
                });
              }

              if (this.activeVisit.atd) {
                this.visitData.push({
                  fieldName:
                    this.translateService.instant('nautical.terms.atd'),
                  fieldValue: moment(this.activeVisit.atd).format(
                    'DD-MM-YYYY HH:mm'
                  ),
                });
              } else if (this.activeVisit.etd) {
                this.visitData.push({
                  fieldName:
                    this.translateService.instant('nautical.terms.etd'),
                  fieldValue: moment(this.activeVisit.etd).format(
                    'DD-MM-YYYY HH:mm'
                  ),
                });
              }

              if (this.activeVisit.referenceNumber) {
                this.visitData.push({
                  fieldName: this.translateService.instant(
                    'nautical.visit.model.crn'
                  ),
                  fieldValue: this.activeVisit.referenceNumber,
                });
              }

              if (this.activeVisit.currentMovement) {
                if (
                  this.activeVisit.currentMovement.portWayPointTo &&
                  this.activeVisit.currentMovement.portWayPointTo.code
                ) {
                  this.visitData.push({
                    fieldName: this.translateService.instant(
                      'nautical.terms.currentBerth'
                    ),
                    fieldValue:
                      this.activeVisit.currentMovement.portWayPointTo.code,
                  });
                } else if (
                  this.activeVisit.currentMovement.portWayPointFrom &&
                  this.activeVisit.currentMovement.portWayPointFrom.code
                ) {
                  this.visitData.push({
                    fieldName: this.translateService.instant(
                      'nautical.terms.currentBerth'
                    ),
                    fieldValue:
                      this.activeVisit.currentMovement.portWayPointFrom.code,
                  });
                }
              }
            }
          },
          (e) => {},
          () => {}
        )
    );
  }
}
