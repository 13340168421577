import { NgModule } from '@angular/core';
import { Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { CustomGeneralComponentsModule } from './components/custom-components.module';
import { CustomGeneralPipesModule } from './pipes/custom-pipes.module';
import { CustomGeneralServicesModule } from './services/custom-services.module';

const routes: Routes = [];

@NgModule({
  imports: [
    CommonModule,
    CustomGeneralComponentsModule,
    CustomGeneralPipesModule,
    CustomGeneralServicesModule,
  ],
})
export class CustomGeneralModule {}
