import { Pipe, PipeTransform } from '@angular/core';

import { WaypointTypes } from '../models/nautical-port-waypoint.model';

@Pipe({
  name: 'waypointType',
  pure: true,
})
export class WaypointTypePipe implements PipeTransform {
  types = {
    [WaypointTypes.Quay]: 'Quay',
    [WaypointTypes.Dock]: 'Dock',
    [WaypointTypes.Buoy]: 'Buoy',
    [WaypointTypes.Dolphin]: 'Dolphin',
    [WaypointTypes.EntryPoint]: 'EntryPoint',
    [WaypointTypes.Unknown]: 'Unknown',
  };

  transform(waypointType: WaypointTypes): string {
    return this.types[waypointType];
  }
}
