import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseControl } from '../base.control';

@Component({
  selector: 'sh-checkbox',
  templateUrl: './checkbox.control.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxControl),
      multi: true,
    },
    {
      provide: BaseControl,
      useExisting: forwardRef(() => CheckboxControl),
    },
  ],
})
export class CheckboxControl extends BaseControl<boolean> {}
