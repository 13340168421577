<div class="modal-header">
  <div class="modal-title">
    {{ 'customers.kp.remarksDialogTitle' | translate }}
  </div>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="modal.close(null)"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form>
    <ng-container
      *ngIf="
        activity && activity.$nautical_ship_id && activity.$nautical_ship_id > 0
      "
    >
      <div class="form-group">
        {{ 'customers.kp.remarksDialogText' | translate }}
      </div>
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <div class="custom-control custom-radio">
              <input
                id="activity"
                type="radio"
                class="custom-control-input"
                value="activity"
                name="objectType"
                [(ngModel)]="objectType"
              />
              <label for="activity" class="custom-control-label">{{
                'customers.kp.objectActivity' | translate
              }}</label>
            </div>
            <div
              class="custom-control custom-radio"
              *ngIf="
                activity.$nautical_portvisit_id &&
                activity.$nautical_portvisit_id > 0 &&
                (activity.activityType === 'meren' ||
                  activity.activityType === 'ontmeren' ||
                  activity.activityType === 'verhalen')
              "
            >
              <input
                id="portVisit"
                type="radio"
                class="custom-control-input"
                value="portVisit"
                name="objectType"
                [(ngModel)]="objectType"
              />
              <label for="portVisit" class="custom-control-label">{{
                'customers.kp.objectPortVisit' | translate
              }}</label>
            </div>
            <div class="custom-control custom-radio">
              <input
                id="ship"
                type="radio"
                class="custom-control-input"
                value="ship"
                name="objectType"
                [(ngModel)]="objectType"
              />
              <label for="ship" class="custom-control-label">{{
                'customers.kp.objectShip' | translate
              }}</label>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <textarea
            rows="5"
            class="form-control"
            name="kpRemarksField"
            placeholder="{{ 'customers.kp.fields.remarks' | translate }}..."
            [(ngModel)]="remarks"
          ></textarea>
        </div>
      </div>
    </div>
    <div class="d-flex">
      <div class="flex-grow-1">
        <button
          type="button"
          class="btn btn-block btn-link"
          (click)="modal.close(null)"
        >
          {{ 'shared.terms.cancel' | translate }}
        </button>
      </div>
      <div class="flex-grow-1">
        <button
          type="button"
          class="btn btn-block btn-success"
          (click)="save()"
        >
          {{ 'shared.terms.save' | translate }}
        </button>
      </div>
    </div>
  </form>
</div>
