// 3rd party modules
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import {
  FieldType,
  NauticalVisitDataService,
  ObjectState,
} from '@seahorse/domain';
import { EditAttributeModal, FieldAttributeModel } from '@seahorse/temp';
import * as moment from 'moment';
import * as _ from 'underscore';
import { ErrorComponent } from '../../../../layout/components/dialogs/error.component';
import { SingleSelectModal } from '../../../../layout/components/single-select-modal.component/single-select-modal.component';

import { NotificationService } from '@seahorse/common';
import { FileModel, FileService } from '@seahorse/domain';
import { AreYouSureModalComponent } from '@seahorse/ui';
import { CustomUIDatetimePickerModal } from '../../../general/components/datetime-picker-modal.component/datetime-picker-modal.component';
import { MemberPickerModalComponent } from '../../../general/components/member-picker-modal/member-picker-modal.component';
import { KPAddActivityModal } from '../../components/add-activity-modal/add-activity-modal.component';
import { KPCompleteActivityModal } from '../../components/complete-activity-modal/complete-activity-modal.component';
import { KPRemarksModal } from '../../components/remarks-modal/remarks-modal.component';
import { KPActivityTypes } from '../../models/kp-activity-types.enum';
import { KPActivityModel } from '../../models/kp-activity.model';
import { KPEventModel } from '../../models/kp-event.model';
import { KPStatus } from '../../models/kp-status.model';
import { KPActivityLogicService } from '../../services/kp-activity-logic.service';

// ShipM8 modules
// Custom UI modules
@Component({
  selector: 'ca-custom-kp-activities-container',
  templateUrl: 'activities-container.component.html',
  styles: [
    `
      .gray-box {
        background-color: #d9d9d9;
      }

      .loader {
        position: absolute;
        left: 50%;
        padding-top: 33px;
      }

      .border-error {
        border-left: 5px solid red !important;
      }

      .tooltip {
        left: 0px;
      }
    `,
  ],
})
export class KPActivitiesContainerComponent {
  @Input() activities: KPActivityModel[] = [];
  @Input() activityTypeGroup: string = null;
  @Input() files: any = {};
  @Input() isLoading: boolean;
  @Input() portVisitInfos: any[] = [];
  @Input() shipRequirements: any[] = [];
  @Input() viewList = 'active';

  @Output() eventEmitter = new EventEmitter<KPEventModel>();

  ngbModalOptions: NgbModalOptions = { backdrop: 'static' };
  ngbModalOptionsSm: NgbModalOptions = { backdrop: 'static', size: 'sm' };

  activityTypes = KPActivityTypes;
  objectState = ObjectState;

  currentActivity: KPActivityModel;

  constructor(
    private fileService: FileService,
    private modalService: NgbModal,
    private kpActivityLogic: KPActivityLogicService,
    private nauticalVisitData: NauticalVisitDataService,
    private notificationService: NotificationService
  ) {
    this.isLoading = false;
  }

  fileUploaded(file: FileModel, activityId: string) {
    if (this.files[activityId]) {
      this.files[activityId].push(file);
    } else {
      this.files[activityId] = [file];
    }
  }

  hasBerth2(activityType) {
    return KPActivityModel.hasBerth2(activityType);
  }

  // TODO: how to get the latestEvent from the sql
  // hasEvent(activity: any, eventType: string) {
  //     return activity.$nautical_portvisit &&
  //         activity.$nautical_portvisit.latestEvent &&
  //         activity.$nautical_portvisit.latestEvent[eventType] !== undefined &&
  //         activity.$nautical_portvisit.latestEvent[eventType] !== null;
  // }

  isOrderNumberRequired(activityType) {
    return KPActivityModel.isOrderNumberRequired(activityType);
  }

  itemClicked(action: string, activity: KPActivityModel) {
    switch (action) {
      case 'copy':
        const copyActivity = !activity
          ? new KPActivityModel()
          : _.clone(activity);
        const originalActivityType = copyActivity.activityType;
        copyActivity.__Id = null; // ensure the id is reset
        copyActivity.activityType = null;
        copyActivity.__ObjectState = ObjectState.Active;
        copyActivity.status = KPStatus.Active;

        if (
          activity &&
          activity.activityType === 'communicatie' &&
          activity.subType === 'aanboord'
        ) {
          copyActivity.activityType = 'communicatie';
          copyActivity.subType = 'vanboord';
          copyActivity.berth1 = activity.berth2;
          copyActivity.berth2 = activity.berth1;
          copyActivity.endsOn = null;
          copyActivity.people = null;
          copyActivity.startsOn = null;
        }

        if (activity.$companies_company_id) {
          if (!copyActivity.$nautical_portvisit) {
            copyActivity.$nautical_portvisit = {};
          }

          const financialKeys = _.filter(
            this.kpActivityLogic.companyFinancialKeys,
            (key) => key.companyId === activity.$companies_company_id
          );
          if (financialKeys.length > 0) {
            // tslint:disable-next-line: radix
            copyActivity.$nautical_portvisit.portAgentId = parseInt(
              financialKeys[0]['sourceValue']
            );
          }
        }

        this.openActivityModal(action, copyActivity, originalActivityType);
        break;

      case 'complete':
        if (activity) {
          const validationResult = KPActivityModel.validate(
            activity,
            true,
            _.keys(KPActivityLogicService.subTypes)
          );

          if (validationResult.isValid) {
            const activityToComplete = _.clone(activity);
            if (activityToComplete.orderTime) {
              const duration = moment.duration(
                moment(activityToComplete.startsOn).diff(
                  moment(activityToComplete.orderTime)
                )
              );
              activityToComplete.waitingTime = duration.asMinutes();
            }

            const modalRef = this.modalService.open(
              KPCompleteActivityModal,
              this.ngbModalOptions
            );
            modalRef.componentInstance.activity = activityToComplete;
            modalRef.componentInstance.activityTypeGroup =
              this.activityTypeGroup;
            modalRef.result.then((modalResult) => {
              if (modalResult && modalResult.activity) {
                // if result is null, means user cancels the action
                this.emitEvent(action, {
                  activity: _.clone(modalResult.activity),
                });

                // when pallets is set, clone an activity for subtype pallets van boord
                if (
                  modalResult.activity.subType === 'palletsaan' &&
                  modalResult.pallets &&
                  isNaN(modalResult.pallets) === false &&
                  modalResult.pallets > 0
                ) {
                  const newActivity: KPActivityModel = _.clone(
                    modalResult.activity
                  );
                  newActivity.subType = 'palletsvan';
                  newActivity.quantity = modalResult.pallets;
                  newActivity.status = KPStatus.Suggestion;
                  this.emitEvent(action, { activity: newActivity });
                }
              }
            });
          } else {
            ErrorComponent.title = 'Sommige velden zijn ongeldig:';
            ErrorComponent.body = validationResult.errors;
            this.modalService.open(ErrorComponent, this.ngbModalOptions);
          }
        }
        break;

      case 'delete':
        if (activity) {
          this.openRemarksModal(action, _.clone(activity));
        }
        break;

      case 'edit':
        if (activity) {
          // tslint:disable-next-line: no-shadowed-variable
          const originalActivityType = activity.activityType;
          this.openActivityModal(
            action,
            _.clone(activity),
            originalActivityType
          );
        }
        break;
    }
  }

  pickBerth(activity: any, fieldName: string) {
    if (activity.__ObjectState === ObjectState.Deleted) {
      return;
    }

    const modalRef = this.modalService.open(
      SingleSelectModal,
      this.ngbModalOptions
    );
    switch (fieldName) {
      case 'berth1':
        modalRef.componentInstance.label = 'Ligplaats (van)';
        break;
      case 'berth2':
        modalRef.componentInstance.label = 'Ligplaats naar';
        break;
    }

    modalRef.componentInstance.title = 'Ligplaats selecteren';
    modalRef.componentInstance.placeholder = 'Selecteer het ligplaats...';
    modalRef.componentInstance.selectedData = { code: activity[fieldName] };
    modalRef.componentInstance.sourceData = this.kpActivityLogic.berths;
    modalRef.componentInstance.settings = {
      idField: 'code',
      textField: 'name',
      itemsShowLimit: 15,
      showIdFieldInDisplay: false,
      showIdFieldInSearch: true,
      allowSearchIdField: true,
    };

    modalRef.result
      .then((modalResult) => {
        if (modalResult) {
          this.currentActivity = activity;

          activity.companyName = modalResult.name;
          this.setField(activity, fieldName, modalResult.code);
        }
      })
      .catch(() => {
        this.currentActivity = null;
      });
  }

  pickCompany(activity: any) {
    if (activity.__ObjectState === ObjectState.Deleted) {
      return;
    }

    const modalRef = this.modalService.open(
      SingleSelectModal,
      this.ngbModalOptions
    );
    modalRef.componentInstance.label = 'Agent';
    modalRef.componentInstance.title = 'Agent selecteren';
    modalRef.componentInstance.placeholder = 'Selecteer de agent...';
    modalRef.componentInstance.selectedData = activity.$companies_company;
    modalRef.componentInstance.sourceData = this.kpActivityLogic.companies;
    modalRef.componentInstance.settings = {
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 15,
      showIdFieldInDisplay: false,
      showIdFieldInSearch: false,
      allowSearchIdField: false,
    };

    modalRef.result
      .then((modalResult) => {
        if (modalResult) {
          this.currentActivity = activity;

          activity.$companies_company = {
            name: modalResult.name,
            id: modalResult.id,
          };
          this.setField(activity, '$companies_company_id', modalResult.id);
        }
      })
      .catch(() => {
        this.currentActivity = null;
      });
  }

  pickDatetime(activity: any, fieldName: string) {
    if (activity.__ObjectState === ObjectState.Deleted) {
      return;
    }

    let fieldDisplayName = fieldName;
    let value = activity[fieldName];
    let showAddButton = false;

    switch (fieldDisplayName) {
      case 'startsOn':
        fieldDisplayName = 'Start';
        break;
      case 'endsOn':
        fieldDisplayName = 'Eind';
        showAddButton = true;
        if (!value && activity.startsOn) {
          value = activity.startsOn;
        }
        break;
      case 'sailedTime':
        fieldDisplayName = 'Opvaren';
        break;
      case 'orderTime':
        fieldDisplayName = 'Besteltijd';
        break;
    }

    const modalRef = this.modalService.open(
      CustomUIDatetimePickerModal,
      this.ngbModalOptionsSm
    );
    modalRef.componentInstance.attribute = {
      addMinutes: 30,
      fieldName: fieldName,
      fieldDisplayName: fieldDisplayName,
      fieldValue: value ? moment(value).format('DD-MM-YYYY HH:mm') : null,
    };

    modalRef.componentInstance.modalHeader = 'Veld bewerken';
    modalRef.componentInstance.showAddButton = showAddButton;

    modalRef.result
      .then((modalResult) => {
        this.currentActivity = activity;

        if (modalResult && modalResult.fieldValue !== undefined) {
          const result = moment(modalResult.fieldValue, 'DD-MM-YYYY HH:mm');
          this.setField(
            activity,
            fieldName,
            result.isValid() ? result.format('YYYY-MM-DDTHH:mm:ss') : null
          );
        }
      })
      .catch(() => {
        this.currentActivity = null;
      });
  }

  pickField(activity: any, fieldName: string) {
    if (activity.__ObjectState === ObjectState.Deleted) {
      return;
    }

    let fieldDisplayName = fieldName;
    let isRequired = false;
    let fieldType = FieldType.SingleLineText;

    switch (fieldDisplayName) {
      case 'douane':
        fieldType = FieldType.Boolean;
        fieldDisplayName = 'Douane';
        break;
      case 'mail':
        fieldType = FieldType.Boolean;
        fieldDisplayName = 'Mail';
        break;
      case 'orderNumber':
        fieldDisplayName = 'Order/PO nummer';
        if (KPActivityModel.isOrderNumberRequired(activity.activityType)) {
          isRequired = true;
        }
        break;
    }

    const modalRef = this.modalService.open(
      EditAttributeModal,
      this.ngbModalOptionsSm
    );
    modalRef.componentInstance.attribute = new FieldAttributeModel(
      fieldDisplayName,
      fieldType,
      activity[fieldName],
      fieldName,
      isRequired
    );
    modalRef.componentInstance.modalHeader = 'Veld bewerken';

    modalRef.result.then((modalResult) => {
      if (modalResult) {
        this.currentActivity = activity;

        if (fieldType === FieldType.Boolean) {
          this.setField(activity, fieldName, Boolean(modalResult.fieldValue));
        } else if (modalResult.fieldValue !== undefined) {
          this.setField(activity, fieldName, modalResult.fieldValue);
        }
      } else {
        this.currentActivity = null;
      }
    });
  }

  pickPeople(activity: any) {
    if (activity.__ObjectState === ObjectState.Deleted) {
      return;
    }

    const modalRef = this.modalService.open(
      MemberPickerModalComponent,
      this.ngbModalOptionsSm
    );
    modalRef.componentInstance.users = this.kpActivityLogic.people;
    modalRef.componentInstance.teams = this.kpActivityLogic.teams;
    modalRef.componentInstance.members = _.clone(
      activity.people ? activity.people : []
    );
    modalRef.componentInstance.onSelectMembersAction.subscribe(
      (onSelectMembersAction: { members: any; action: string }) => {
        this.currentActivity = activity;

        switch (onSelectMembersAction.action) {
          case 'save':
            this.setField(activity, 'people', onSelectMembersAction.members);
            break;
        }

        modalRef.dismiss();
      },
      () => {
        this.currentActivity = null;
      }
    );
  }

  pickRemarks(remarks: string, objectType: string, activity: KPActivityModel) {
    if (activity.__ObjectState === ObjectState.Deleted) {
      return;
    }

    const modalRef = this.modalService.open(
      KPRemarksModal,
      this.ngbModalOptionsSm
    );
    modalRef.componentInstance.objectType = !objectType
      ? 'activity'
      : objectType;
    modalRef.componentInstance.activity = activity;
    modalRef.componentInstance.remarks = remarks;
    modalRef.result.then(
      (result: KPEventModel) => {
        if (result) {
          this.currentActivity = activity;

          switch (result.objectType) {
            case 'activity':
              this.currentActivity = activity;
              this.setField(activity, 'remarks', result.data);
              break;
            default:
              this.emitEvent(
                'saveRemarks',
                { activity: activity, remarks: result.data },
                result.objectType
              );
          }
        }
      },
      () => {
        this.currentActivity = null;
      }
    );
  }

  removeFile(fileId, activityId) {
    const modalRef = this.modalService.open(AreYouSureModalComponent);

    modalRef.result.then((modalResult) => {
      if (modalResult === true) {
        this.fileService.deleteFile(fileId).subscribe((deleteResult) => {
          this.notificationService.showSuccess('files.fileDeleted');
          this.files[activityId].splice(
            _.findIndex(
              this.files[activityId],
              (findActivity) => findActivity.id === fileId
            ),
            1
          );
        });
      }
    });
  }

  setField(activity, fieldName, fieldValue) {
    if (activity.__ObjectState === ObjectState.Deleted) {
      return;
    }

    const activityToSave = _.clone(activity);

    if (fieldValue === '#now') {
      activity[fieldName] = moment().format('YYYY-MM-DDTHH:mm:ss');
      activityToSave[fieldName] = moment().format('YYYY-MM-DDTHH:mm:ss');
    } else {
      activity[fieldName] = fieldValue;
      activityToSave[fieldName] = fieldValue;
    }

    if (
      activity.$nautical_portvisit_id &&
      activity.$nautical_ship_id &&
      (!activity.orderNumber || activity.orderNumber.length === 0)
    ) {
      this.loadPONumberFromVisit(activityToSave);
    } else {
      this.emitEvent('edit', {
        activity: activityToSave,
        originalActivityType: activityToSave.activityType,
      });
    }
  }

  //#region Private methods

  private emitEvent(action: string, data: any, objectType = 'activity') {
    const model = new KPEventModel();
    model.objectType = objectType;
    model.action = action;
    model.data = data;
    this.eventEmitter.emit(model);

    this.currentActivity = data.activity;
  }

  private loadPONumberFromVisit(activity) {
    this.nauticalVisitData
      .getDetails(activity.$nautical_portvisit_id)
      .subscribe(
        (portVisitResult) => {
          try {
            const orderNumber = this.kpActivityLogic.getPONumberByEvents(
              portVisitResult.portVisitEvents
            );
            if (orderNumber) {
              activity.orderNumber = orderNumber;
            }
          } catch {}
          this.emitEvent('edit', {
            activity: activity,
            originalActivityType: activity.activityType,
          });
        },
        () => {
          this.emitEvent('edit', {
            activity: activity,
            originalActivityType: activity.activityType,
          });
        }
      );
  }

  private openActivityModal(
    action: string,
    activity: KPActivityModel,
    originalActivityType: string
  ) {
    const modalRef = this.modalService.open(
      KPAddActivityModal,
      this.ngbModalOptions
    );
    modalRef.componentInstance.activity = activity;
    if (this.files && this.files[activity.__Id]) {
      this.files[activity.__Id].forEach((file) => {
        modalRef.componentInstance.includedFiles.push(file);
      });
    }
    modalRef.result
      .then((modalResult) => {
        if (modalResult) {
          // if result is null, means user cancels the action
          this.emitEvent(action, {
            activity: modalResult,
            originalActivityType: originalActivityType,
          });
        }
      })
      .catch(() => {});
  }

  private openRemarksModal(action: string, activity: KPActivityModel) {
    const modalRef = this.modalService.open(
      EditAttributeModal,
      this.ngbModalOptionsSm
    );
    modalRef.componentInstance.attribute = new FieldAttributeModel(
      'Reden',
      FieldType.MultiLineText,
      null,
      'remarks',
      true
    );
    modalRef.componentInstance.modalHeader = 'Voer een reden in';
    modalRef.result
      .then((modalResult) => {
        if (modalResult && modalResult.fieldValue !== undefined) {
          // if result is null, means user cancels the action
          this.emitEvent(action, {
            activity: activity,
            remarks: modalResult.fieldValue,
          });
        }
      })
      .catch(() => {});
  }

  //#endregion
}
