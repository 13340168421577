import { Component, Input, Output, EventEmitter } from '@angular/core';

import { EventModel } from '../../../../../../shared/models/event.model';
import { ExecutionModel } from '../../../../../../invoicing-and-tariffs/modules/invoicing-flows/models/execution.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExecutionStartModalComponent } from '../execution-start-modal/execution-start-modal.component';
import { FlowDataService } from '../../../services/flow-data.service';
import { FlowModel } from '@seahorse/domain';

@Component({
  selector: 'executions-card',
  templateUrl: 'executions-card.component.html',
})
export class ExecutionsCardComponent {
  @Input() executionsCount: number;
  @Input() executions: ExecutionModel[];
  @Input() flow: FlowModel;
  @Input() flowId: number;
  @Input() loadingExecutions: boolean;
  @Input() indexedExecutions: boolean;

  @Output() onEvent: EventEmitter<EventModel>;

  constructor(private flowData: FlowDataService, private modal: NgbModal) {
    this.executionsCount = 0;
    this.executions = [];
    this.loadingExecutions = false;
    this.indexedExecutions = false;
    this.onEvent = new EventEmitter<EventModel>();
  }

  newExecution() {
    const modalRef = this.modal.open(ExecutionStartModalComponent, {
      backdrop: 'static',
      size: 'lg',
    });

    if (this.flow === undefined || this.flow === null) {
      this.flowData.getById(this.flowId).subscribe((flowResult) => {
        modalRef.componentInstance.flow = flowResult.result;
      });
    } else {
      modalRef.componentInstance.flow = this.flow;
    }

    modalRef.result.then((modalResult) => {}).catch((error) => {});
  }
}
