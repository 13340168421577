<div
  class="card tile tile-clickable"
  *ngFor="let contact of contacts"
  (click)="selectContact(contact)"
  [ngClass]="{ selected: selectedContact && selectedContact.id === contact.id }"
>
  <div class="card-body">
    <div class="row seperated-columns">
      <div class="col-5">
        <strong>
          {{ contact.nameInfo | uppercase }}
        </strong>
        <ul class="list-inline list-inline-seperated">
          <li class="list-inline-item">
            <span class="text-muted">{{
              'companies.contact.model.function' | translate
            }}</span>
            {{ contact.function }}
          </li>
        </ul>
      </div>
      <div class="col-7">
        <div class="attribute-group attribute-group-col-2">
          <div class="attribute-group-item">
            <dt>{{ 'companies.contact.model.status' | translate }}</dt>
            <dd>
              <i
                *ngIf="contact.status === 1"
                class="fa-regular fa-check text-success mr-2"
              ></i>
              <i
                *ngIf="contact.status === 2"
                class="fa-solid fa-times text-danger mr-2"
              ></i>
              <i
                *ngIf="contact.status === 3"
                class="fa-regular fa-ambulance text-warning mr-2"
              ></i>
              {{ contact.status | contactStatus }}
            </dd>
          </div>
          <div class="attribute-group-item">
            <dt>{{ 'companies.contact.model.isPrimary' | translate }}</dt>
            <dd>{{ contact.isPrimaryContact }}</dd>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
