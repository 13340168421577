import { Injectable } from '@angular/core';
import { ResultWrapper } from '@seahorse/common';
import { DataContextService } from '@seahorse/common';
import { GroupingFieldModel } from '../../invoicing-and-tariffs/modules/invoicing/models/invoice-display-config.model';
import { Observable } from 'rxjs';

import { DataSetRequestModel } from '../models/data-set-request.model';
import { PeriodStatisticsModel } from '../models/period-statistics.model';
import { InvoiceModel } from '@seahorse/domain';

@Injectable({
  providedIn: 'root',
})
export class StatisticsDataService {
  constructor(private dataContext: DataContextService) {}

  /**
   * @param dataSetRequest The data set request.
   * @return The generated data set.
   **/
  generateRevenueReport(
    dataSetRequest: DataSetRequestModel,
    metaKey?: string
  ): Observable<ResultWrapper<PeriodStatisticsModel[]>> {
    if (metaKey === undefined || metaKey === null || metaKey.trim() === '') {
      return this.dataContext.post<PeriodStatisticsModel[]>(
        'invoice/generate-revenue-report',
        dataSetRequest
      );
    } else {
      return this.dataContext.post<PeriodStatisticsModel[]>(
        'invoice/generate-revenue-report/count-metadata',
        dataSetRequest
      );
    }
  }

  getRevenueReportDetails(
    dataSetRequest: DataSetRequestModel
  ): Observable<ResultWrapper<InvoiceModel[]>> {
    return this.dataContext.post<InvoiceModel[]>(
      `invoice/generate-details-revenue-report`,
      dataSetRequest
    );
  }
}
