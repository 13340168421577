import { ObjectDefinitionModel } from '../../custom-data/models/object-definition.model';
import { UiModuleCalendarConfiguration } from './ui-module-calendar-configuration.model';
import { UiModuleDetailsConfiguration } from './ui-module-details-configuration.model';
import { UiModuleOverviewConfiguration } from './ui-module-overview-configuration.model';
import { UiModulePermission } from './ui-module-permission.model';

export class UiModule {
  static default(): UiModule {
    return {
      id: null,
      displayName: null,
      displayIcon: 'fa-star',
      isActive: false,
      menuOrder: 0,
      overviewConfigurationId: null,
      detailsConfigurationId: null,
      systemObjectDefinitionId: null,
      customObjectDefinitionId: null,
      systemCode: null,
      entryRoute: 'overview',
      organisationId: null,
      definitionId: null,
      organisationName: null,
      uiModuleDefinition: null,
      uiModulePermissions: [],
      hasDetailsConfiguration: false,
      hasOverviewConfiguration: false,
      hasLinkedObjectsConfiguration: false,
    };
  }
}

export interface UiModuleDefinition {
  id: number;
  displayName: string;
  displayIcon: string;
  isActive: boolean;
  menuOrder: number;
  overviewConfigurationId: number | null;
  detailsConfigurationId: number | null;
  systemObjectDefinitionId: number | null;
  customObjectDefinitionId: number | null;
  systemCode: string;
  entryRoute: string;

  customDataObjectDefinition?: ObjectDefinitionModel;
  systemDataObjectDefinition?: ObjectDefinitionModel;
  uiModuleDetailsConfiguration?: UiModuleDetailsConfiguration;
  uiModuleOverviewConfiguration?: UiModuleOverviewConfiguration;
  uiModuleCalendarConfiguration?: UiModuleCalendarConfiguration;

  hasOverviewConfiguration?: boolean;
  hasDetailsConfiguration?: boolean;
  hasLinkedObjectsConfiguration?: boolean;
}

export interface UiModule extends UiModuleDefinition {
  organisationId: string;
  definitionId: number;

  organisationName: string;

  uiModuleDefinition: UiModuleDefinition;
  uiModulePermissions: UiModulePermission[];
}
