import { InvoicingBusinessLogic } from '../../invoicing-and-tariffs/modules/tariffs/models/business-logic/invoicing-business-logic.model';

export class InvoicingTariffModel {
  tariffGroupId: number;
  currencyCode: string;
  amount: number;
  tariffOption: TariffOption;
  businessLogic: InvoicingBusinessLogic = new InvoicingBusinessLogic();
  weekendCalculations: WeekendCalculation;
  basedOn: string;

  constructor() {
    this.businessLogic;
    this.businessLogic.calculations = [];
    this.weekendCalculations = new WeekendCalculation();
  }
}

export class WeekendCalculation {
  weekendStarts: Weekday;
  weekendEnds: Weekday;
  weekendStartsHour: WeekendHour;
  weekendEndsHour: WeekendHour;

  constructor() {
    this.weekendStarts = Weekday.Friday;
    this.weekendEnds = Weekday.Monday;
    this.weekendStartsHour = { hour: 17, minute: 30 };
    this.weekendEndsHour = { hour: 9, minute: 15 };
  }
}

export const CURRENCIES = [
  { id: 1, name: 'EUR' },
  { id: 2, name: 'USD' },
  { id: 3, name: 'GBP' },
  { id: 4, name: 'DKK' },
  { id: 5, name: 'NKK' },
];

export enum TariffOption {
  WeekendsAndHolidays = 1,
  Weekends = 2,
  None = 3,
}

export const TARIFFOPTIONS = [
  {
    id: TariffOption.WeekendsAndHolidays,
    name: 'Yes, in weekends and on holidays',
  },
  { id: TariffOption.Weekends, name: 'Yes, in weekends' },
  { id: TariffOption.None, name: 'No' },
];

export enum Weekday {
  Friday = 1,
  Saturday = 2,
  Sunday = 3,
  Monday = 4,
}

export const WEEKDAYS = [
  { id: Weekday.Friday, name: 'Friday', short: 'fri' },
  { id: Weekday.Saturday, name: 'Saturday', short: 'sat' },
  { id: Weekday.Sunday, name: 'Sunday', short: 'sun' },
  { id: Weekday.Monday, name: 'Monday', short: 'mon' },
];

export class WeekendHour {
  hour: number;
  minute: number;
}
