<div class="card">
  <div class="card-header">
    <span>
      {{ 'user.userPreference' | translate }}
    </span>
  </div>
  <div class="card-body">
    <div class="attribute-group">
      <div
        class="attribute-group-item attribute-group-item-editable"
        (click)="selectLanguage()"
      >
        <dt>{{ 'shared.terms.language' | translate }}</dt>
        <dd>
          <span *ngIf="language">{{ language.label }}</span>
          <i
            class="fa-regular fa-pencil-alt fa-fw btn-editable text-muted float-right"
          ></i>
        </dd>
      </div>
      <div
        class="mt-4 mb-2 custom-control custom-switch"
        (click)="toggleMfa($event)"
      >
        <input
          class="custom-control-input"
          type="checkbox"
          name="hasTabsConfiguration"
          id="hasTabsConfiguration"
          [ngModel]="isMfaEnabled"
        />
        <label
          class="custom-control-label cursor-pointer"
          for="hasTabsConfiguration"
          >{{ 'user.mfa.twofactor' | translate }}</label
        >
      </div>
    </div>
  </div>
</div>
