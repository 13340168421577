import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'underscore';
import {
  CustomDataObjectModel,
  CustomDefaultDataModel,
} from '@seahorse/domain';
import { ChecklistOptionModel } from '../../../shared/models/checklist-option.model';
import { CustomDefaultDataService } from '../../../custom-data/services/custom-default-data.service';
import { ErrorMessage } from '@seahorse/common';
import { NotificationService } from '@seahorse/common';

@Component({
  selector: 'ca-custom-default-data-checklist',
  templateUrl: './custom-default-data-checklist.component.html',
})
export class CustomDefaultDataChecklistComponent implements OnInit {
  @Input() dataObjectKey: string;
  @Input() defaultDataGroup: CustomDefaultDataModel;
  @Input() customDefaultDataSet: CustomDefaultDataModel[];
  @Input() loadingCustomDefaultDataSet: boolean;
  @Input() hideDefaultDataGroupPicker: boolean;
  @Input() defaultDataObjects: CustomDataObjectModel[];
  @Output() defaultDataObjectsChange!: EventEmitter<CustomDataObjectModel[]>;

  checklist: ChecklistOptionModel[];
  loadingChecklist: boolean;
  isAllChecked = false;

  constructor(
    private _translateService: TranslateService,
    private _notificationService: NotificationService,
    private _customDefaultDataService: CustomDefaultDataService
  ) {
    this.customDefaultDataSet = [];
    this.loadingCustomDefaultDataSet = false;
    this.hideDefaultDataGroupPicker = false;
    this.defaultDataObjects = [];
    this.defaultDataObjectsChange = new EventEmitter<CustomDataObjectModel[]>();
    this.checklist = [];
  }

  ngOnInit() {
    if (this.defaultDataGroup) {
      this.createChecklist();
    } else {
      if (this.customDefaultDataSet.length) {
        this.defaultDataGroup = this.customDefaultDataSet[0];
        this.createChecklist();
      } else if (this.dataObjectKey) {
        this.loadingCustomDefaultDataSet = true;
        this._customDefaultDataService
          .getDefaultDataGroups(this.dataObjectKey)
          .subscribe(
            (response) => {
              const errorMessages: (string | ErrorMessage)[] = response.messages
                ? response.messages
                : [];
              if (response.hasResult) {
                this.customDefaultDataSet = response.result;
                if (response.result && response.result.length === 1) {
                  this.defaultDataGroup = response.result[0];
                } else {
                  this.defaultDataGroup = null;
                }
                this.createChecklist();
              }

              if (errorMessages && errorMessages.length) {
                this._notificationService.displayErrorNotification(
                  errorMessages
                );
              }
            },

            () => this._notificationService.displayErrorNotification(),
            () => (this.loadingCustomDefaultDataSet = false)
          );
      }
    }
  }

  createChecklist() {
    const newChecklist: ChecklistOptionModel[] = [];
    if (this.defaultDataGroup) {
      this.defaultDataGroup.objects.forEach((object) => {
        const value =
          this.defaultDataGroup.configuration.objectDisplayField &&
          object.fields[this.defaultDataGroup.configuration.objectDisplayField]
            ? object.fields[
                this.defaultDataGroup.configuration.objectDisplayField
              ].toString()
            : object.id.toString();
        const checklistOption = new ChecklistOptionModel(
          object.id,
          value,
          true
        );
        newChecklist.push(checklistOption);
        this.isAllChecked = true;
      });
      this.defaultDataObjectsChange.emit(this.defaultDataGroup.objects);
    }

    this.checklist = _.sortBy(newChecklist, 'value');
  }

  toggleChecklistOption(checklistOption: ChecklistOptionModel) {
    const checklistIndex = this.checklist.findIndex(
      (x) => x.key === checklistOption.key
    );
    const defaultDataObjectsIndex = this.defaultDataObjects.findIndex(
      (x) => x.id === checklistOption.key
    );
    const defaultDataGroupObjectsIndex =
      this.defaultDataGroup.objects.findIndex(
        (x) => x.id === checklistOption.key
      );
    const defaultDataObjects = _.clone(this.defaultDataObjects);
    if (checklistIndex > -1) {
      this.checklist[checklistIndex].isChecked =
        !this.checklist[checklistIndex].isChecked;
      if (
        defaultDataObjectsIndex > -1 &&
        !this.checklist[checklistIndex].isChecked
      ) {
        defaultDataObjects.splice(defaultDataObjectsIndex, 1);
      }
      if (
        defaultDataObjectsIndex === -1 &&
        this.checklist[checklistIndex].isChecked
      ) {
        defaultDataObjects.push(
          this.defaultDataGroup.objects[defaultDataGroupObjectsIndex]
        );
      }
    }

    this.isAllChecked =
      defaultDataObjects &&
      defaultDataObjects.length !== 0 &&
      this.checklist.length === defaultDataObjects.length;

    this.defaultDataObjectsChange.emit(defaultDataObjects);
  }

  selectAll(isAllChecked: boolean) {
    this.isAllChecked = isAllChecked;
    this.checklist = this.checklist.map((x) => {
      x.isChecked = isAllChecked;
      return x;
    });
    this.defaultDataObjectsChange.emit(
      isAllChecked ? this.defaultDataGroup.objects : []
    );
  }
}
