<div class="empty-placeholder">
  <ng-container *ngFor="let item of itemCollection">
    <div class="empty-placeholder-photo" *ngIf="item.key === 'photo-loader'">
      <div class="d-block">
        <i class="fa-regular fa-camera-retro fa-6x"></i>
      </div>
      <sh-loading-indicator></sh-loading-indicator>
    </div>
    <div class="empty-placeholder-attributes" *ngIf="item.key === 'attributes'">
      <ng-container *ngFor="let r of item.repeat">
        <div></div>
        <div></div>
        <div></div>
      </ng-container>
    </div>
  </ng-container>
</div>
