<div class="modal-header align-items-center">
  <h1 class="modal-title">{{ title }}</h1>
  <button (click)="cancel()" type="button" class="btn btn-link text-dark">
    <i class="fa-solid fa-times fa-sm fa-fw"></i>
  </button>
</div>
<div class="modal-body">
  <ca-icon-picker-grid [(selectedIcon)]="selectedIcon"></ca-icon-picker-grid>
</div>
<div class="modal-footer">
  <button (click)="cancel()" type="button" class="btn btn-link flex-fill">
    {{ 'shared.terms.cancel' | translate }}
  </button>
  <button (click)="submit()" type="button" class="btn btn-success flex-fill">
    {{ 'shared.terms.save' | translate }}
  </button>
</div>
