import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

import { IdentityService } from './identity.service';

@Injectable()
export class OnboardingGuardService implements CanActivate {
  constructor(private identity: IdentityService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (
      (route.routeConfig.path === 'permissions' &&
        !this.identity.hasPermission('general.onboarding_user')) ||
      (route.routeConfig.path === 'customdata' &&
        !this.identity.hasPermission('custom_data.custom_data_admin') &&
        !this.identity.hasPermission('general.onboarding_user')) ||
      (route.routeConfig.path === 'invoicing' &&
        !this.identity.hasPermission('invoices') &&
        !this.identity.hasPermission('general.onboarding_user'))
    ) {
      this.router.navigate(['/']);
      return false;
    }

    return true;
  }
}
