export class ActivityPageStateModel {
  hours: number;
  list: string;
  types: string;

  constructor() {
    this.hours = null;
    this.list = null;
    this.types = null;
  }
}
