import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AISMarkerModel } from '@seahorse/domain';

@Component({
  selector: 'sh-map-direction-indicator',
  templateUrl: 'map-direction-indicator.component.html',
  standalone: true,
  imports: [CommonModule, TranslateModule],
})
export class MapDirectionIndicatorComponent {
  private _position?: AISMarkerModel;

  arrowStyle: Partial<CSSStyleDeclaration> = {
    transform: 'rotate(-45deg)',
  };

  @Input() set position(p: AISMarkerModel | undefined) {
    if (p == null) {
      return;
    }
    this._position = p;

    if (p && p.latitude && p.longitude) {
      this.arrowStyle['cursor'] = 'pointer';
    }

    if (p && p.heading) {
      this.arrowStyle.transform = 'rotate(' + (p.heading - 45) + 'deg)';
    } else if (p && p.courseOverGround) {
      this.arrowStyle.transform =
        'rotate(' + (p.courseOverGround - 45) + 'deg)';
    }

    if (p && p.speed >= 1) {
      this.arrowStyle['fontSize'] = '1.5em';
    }
    if (this.noResize === false) {
      this.arrowStyle['fontSize'] = '1em';
    }
  }
  get position() {
    return this._position;
  }

  @Input() noResize = false;
  @Input() showSpeed = true;

  constructor(private router: Router) {}

  goToMap(position: AISMarkerModel) {
    if (position.latitude && position.longitude) {
      const param: any = {
        lat: position.latitude,
        lng: position.longitude,
        zoom: 11,
      };

      if (position.mmsi) {
        param['mmsi'] = position.mmsi;
      }

      this.router.navigate(['/map'], { queryParams: param });
    }
  }
}
