import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import * as _ from 'underscore';

import { ResultWrapper } from '@seahorse/common';
import { NotificationService } from '@seahorse/common';
import { QueryModel } from '../../../../../shared/models/query.model';
import { EventModel } from '../../../../../shared/models/event.model';
import { LayoutDisplayService } from '../../../../../layout/services/layout-display.service';
import { FlowDataService } from '../../services/flow-data.service';
import { StageDataService } from '../../services/stage-data.service';
import { StageModel, FlowModel } from '@seahorse/domain';

@Component({ templateUrl: 'stage-creator.page.html' })
export class StageCreatorPage implements OnInit {
  stage: StageModel;
  loadingStage: boolean;
  invoicingFlows: FlowModel[];
  loadingInvoicingFlows: boolean;
  flow: FlowModel;
  loadingFlow: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private notification: NotificationService,
    private layoutDisplay: LayoutDisplayService,
    private flowData: FlowDataService,
    private stageData: StageDataService
  ) {
    this.stage = new StageModel();
    this.loadingStage = false;
    this.invoicingFlows = [];
    this.loadingInvoicingFlows = false;
    this.flow = null;
    this.loadingFlow = false;
  }

  ngOnInit() {
    this.translate
      .get('invoicingFlows.stageCreator.title')
      .subscribe(
        (translateGet) => (this.layoutDisplay.currentModuleName = translateGet)
      );

    this.route.queryParams.subscribe((routeQueryParams) => {
      this.stage.invoiceFlowId = routeQueryParams['flow'];

      this.getFlow(routeQueryParams['flow']);
    });

    this.loadingInvoicingFlows = true;

    this.flowData.search(new QueryModel()).subscribe(
      (flowSearch: ResultWrapper<FlowModel[]>) => {
        if (flowSearch.hasResult) {
          this.invoicingFlows = flowSearch.result;
        } else {
          this.notification.showError(
            _.pluck(flowSearch.messages, 'message').join('\n'),
            this.translate.instant('shared.terms.failed')
          );
        }

        this.loadingInvoicingFlows = false;
      },
      (flowSearchError) => {
        this.notification.showError(
          _.pluck(flowSearchError.error.messages, 'message').join('\n'),
          this.translate.instant('shared.terms.failed')
        );

        this.loadingInvoicingFlows = false;
      }
    );
  }

  getFlow(flowId: FlowModel['id']) {
    this.loadingFlow = true;

    this.flowData.getById(flowId).subscribe(
      (flowGetByIdResponse: ResultWrapper<FlowModel>) => {
        if (flowGetByIdResponse.hasResult) {
          this.flow = flowGetByIdResponse.result;
        } else {
          this.notification.showError(
            _.pluck(flowGetByIdResponse.messages, 'message').join('\n'),
            this.translate.instant('shared.terms.failed')
          );
        }

        this.loadingFlow = false;
      },
      (flowGetByIdError) => {
        this.notification.showError(
          _.pluck(flowGetByIdError.error.messages, 'message').join('\n'),
          this.translate.instant('shared.terms.failed')
        );

        this.loadingFlow = false;
      }
    );
  }

  action(event: EventModel) {
    switch (event.action) {
      case 'saveStage': {
        this.loadingStage = true;

        this.stageData.add(this.stage).subscribe(
          (stageAdd: ResultWrapper<StageModel>) => {
            if (stageAdd.hasResult) {
              this.notification.showSuccess(
                this.translate.instant(
                  'invoicingFlows.stageCreator.stageAdded'
                ),
                this.translate.instant('shared.terms.success')
              );

              this.router.navigate([
                `invoicing-and-tariffs/invoicing-flows/flow/${stageAdd.result.invoiceFlowId}`,
              ]);
            } else {
              this.notification.showError(
                _.pluck(stageAdd.messages, 'message').join('\n'),
                this.translate.instant('shared.terms.failed')
              );
            }

            this.loadingStage = false;
          },
          (stageAddError) => {
            this.notification.showError(
              _.pluck(stageAddError.error.messages, 'message').join('\n'),
              this.translate.instant('shared.terms.failed')
            );

            this.loadingStage = false;
          }
        );

        break;
      }

      case 'saveStageThenNewStage': {
        this.loadingStage = true;

        this.stageData.add(this.stage).subscribe(
          (stageAdd: ResultWrapper<StageModel>) => {
            if (stageAdd.hasResult) {
              this.notification.showSuccess(
                this.translate.instant(
                  'invoicingFlows.stageCreator.stageAdded'
                ),
                this.translate.instant('shared.terms.success')
              );

              this.stage = new StageModel();
              this.stage.invoiceFlowId = stageAdd.result.invoiceFlowId;
            } else {
              this.notification.showError(
                _.pluck(stageAdd.messages, 'message').join('\n'),
                this.translate.instant('shared.terms.failed')
              );
            }

            this.loadingStage = false;
          },
          (stageAddError) => {
            this.notification.showError(
              _.pluck(stageAddError.error.messages, 'message').join('\n'),
              this.translate.instant('shared.terms.failed')
            );

            this.loadingStage = false;
          }
        );

        break;
      }

      case 'searchPreviousStages': {
        this.stage.previousStageId = null;

        this.getFlow(this.stage.invoiceFlowId);

        break;
      }
    }
  }
}
