import { TaskGroupModel } from '../../task-group/models/task-group.model';
import {
  TaskModel,
  TaskStatus,
  TaskType,
} from '../../tasks/modules/task/models/task.model';

export class TaskGroupDisplayItem {
  id: number | null;
  title: string;
  description: string | null;
  startsExpanded: boolean;
  grouping: DisplayGrouping;
  dueDate: Date | null;
  completedOn: Date | null;
  tasks: Array<TaskDisplayItem>;
  model: TaskGroupModel;
  changedModel: TaskGroupModel | null;

  constructor(taskGroup?: TaskGroupModel, ungroupedDisplay?: string) {
    this.id = taskGroup?.id ?? null;
    this.title = taskGroup?.name ?? ungroupedDisplay ?? '';
    this.description = taskGroup?.remarks ?? null;
    this.startsExpanded = taskGroup?.tasks && taskGroup.tasks.length > 0;
    this.grouping = DisplayGrouping.Default;
    if (taskGroup) {
      if (
        taskGroup.dueDate &&
        !taskGroup.completedOn &&
        new Date().getTime() > new Date(taskGroup.dueDate).getTime()
      )
        this.grouping = DisplayGrouping.Warning;
      if (taskGroup.status !== null) {
        if (taskGroup.status === TaskStatus.Done) {
          this.startsExpanded = false;
          this.grouping = DisplayGrouping.Success;
        }
        if (taskGroup.status === TaskStatus.Cancelled) {
          this.startsExpanded = false;
          this.grouping = DisplayGrouping.Muted;
        }
      }
    }
    this.dueDate = taskGroup?.dueDate ? new Date(taskGroup.dueDate) : null;
    this.completedOn = taskGroup?.completedOn
      ? new Date(taskGroup.completedOn)
      : null;
    this.tasks =
      taskGroup?.tasks && taskGroup.tasks.length > 0
        ? taskGroup.tasks.map((x) => new TaskDisplayItem(x))
        : new Array<TaskDisplayItem>();
    this.model = taskGroup;
    this.changedModel = null;
  }
}

export class TaskDisplayItem {
  id: number | null;
  createdFromTaskId: number | null;
  taskType: TaskType;
  title: string;
  description: string;
  remarks: string;
  additionalInfo: string;
  grouping: DisplayGrouping;
  isChecked: boolean;
  startsChecked: boolean;
  dueDate: Date | null;
  assignedTo: string;

  constructor(task?: TaskModel) {
    this.id = task?.id ?? null;
    this.createdFromTaskId = task?.createdFromTaskId ?? null;
    this.taskType = task?.taskType ?? TaskType.Process;
    this.title = task?.subject ?? '';
    this.description = task?.description ?? '';
    this.grouping = DisplayGrouping.Default;
    this.isChecked = false;
    if (task) {
      // task.dueDate is a string not a Date
      if (
        task.dueDate &&
        !task.completedOn &&
        new Date().getTime() > new Date(task.dueDate).getTime()
      )
        this.grouping = DisplayGrouping.Warning;
      if (task.status) {
        if (task.status === TaskStatus.Done) {
          this.isChecked = true;
          this.grouping = DisplayGrouping.Success;
        }
        if (task.status === TaskStatus.Cancelled)
          this.grouping = DisplayGrouping.Muted;
      }
    }
    this.startsChecked = this.isChecked;
    // task.dueDate is a string not a Date
    this.dueDate = task?.dueDate ? new Date(task.dueDate) : null;
    this.assignedTo = task?.assignedTo ?? null;
    this.additionalInfo = task?.additionalInfo ?? null;
  }
}

export enum DisplayGrouping {
  Warning,
  Default,
  Success,
  Muted,
}

export function SortTaskGroupDisplay(
  taskGroups: Array<TaskGroupDisplayItem>
): Array<TaskGroupDisplayItem> {
  const displaySort = (
    a: TaskGroupDisplayItem | TaskDisplayItem,
    b: TaskGroupDisplayItem | TaskDisplayItem
  ) => {
    if (a.grouping !== b.grouping) return a.grouping - b.grouping;
    if (a.dueDate && b.dueDate)
      return a.dueDate.getTime() - b.dueDate.getTime();
    else if (a.dueDate) return -1;
    else if (b.dueDate) return 1;
    return a.id - b.id;
  };
  taskGroups.sort(displaySort);
  taskGroups.forEach((taskGroup) => {
    taskGroup.tasks.sort((a, b) => {
      if (a.createdFromTaskId !== null && a.createdFromTaskId === b.id)
        return 1;
      if (b.createdFromTaskId !== null && b.createdFromTaskId === a.id)
        return -1;
      return displaySort(a, b);
    });
    const nullIndex = taskGroup.tasks.findIndex((task) => task.id === null);
    if (nullIndex > -1) {
      const nullTask = taskGroup.tasks.splice(nullIndex, 1)[0];
      taskGroup.tasks.push(nullTask);
    }
  });
  const nullIndex = taskGroups.findIndex((taskGroup) => taskGroup.id === null);
  if (nullIndex > -1) {
    const nullTaskGroup = taskGroups.splice(nullIndex, 1)[0];
    taskGroups.push(nullTaskGroup);
  }
  return taskGroups;
}

export function FindDueDate(tasks: Array<TaskDisplayItem>): Date | null {
  if (tasks.every((x) => x.grouping === DisplayGrouping.Muted)) return null;
  const findDueDateMs = tasks
    .filter((x) => x.grouping !== DisplayGrouping.Muted && x.dueDate)
    .map((x) => new Date(x.dueDate.toString()).getTime());
  return findDueDateMs.length ? new Date(Math.min(...findDueDateMs)) : null;
}
