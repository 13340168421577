import * as moment from 'moment';
import { OptionFieldModel } from '../../invoicing-and-tariffs/modules/invoicing/models/invoice-display-config.model';

export class DataSetRequestModel {
  start: string;
  end: string;
  rowGrouping: OptionFieldModel;
  columnGrouping: OptionFieldModel;
  filtering: string;
  type: string;

  constructor(
    start: Date,
    end: Date,
    rowGrouping: OptionFieldModel,
    columnGrouping: OptionFieldModel,
    filtering: string,
    type?: string
  ) {
    this.start = moment(start).format('YYYY-MM-DD[T]HH:mm:ss');
    this.end = moment(end).format('YYYY-MM-DD[T]HH:mm:ss');
    this.rowGrouping = rowGrouping;
    this.columnGrouping = columnGrouping;
    this.filtering = filtering;
    this.type = type;
  }
}

export class DataDetailsRequestModel extends DataSetRequestModel {
  appendFields: string[];
  skipIntervalFiltering: boolean;
  intervalName: string;
  groupName: string;
}
