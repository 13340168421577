import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AddressModel } from '../../models/address.model';

@Component({
  selector: 'ca-address-form',
  templateUrl: 'address-form.component.html',
})
export class AddressFormComponent {
  @Input() identifier?: string; // For forms where the address field is iterated on the same page
  @Input() address?: AddressModel;
  @Input() hideCountry?: boolean;
  @Input() hideProvince?: boolean;

  @Output() addressChange!: EventEmitter<AddressModel>;

  constructor() {
    this.identifier = 'address';
    this.address = new AddressModel();
    this.hideCountry = false;
    this.hideProvince = false;
    this.addressChange = new EventEmitter<AddressModel>();
  }

  setNull(attribute: string) {
    if (attribute) {
      this.address[attribute].value = null;
    }
  }
}
