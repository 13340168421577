import { Observable } from 'rxjs';
import { FieldTypeDefinitionModel } from '../custom-data/models/field-type-definition.model';
import {
  ObjectDefinitionConfigurationModel,
  ObjectDefinitionModel,
} from '../custom-data/models/object-definition.model';
import { ObjectKeyMappingModel } from '../custom-data/models/object-key-mapping.model';
import { UiModule } from '../generic-ui/models/ui-module.model';
import * as _ from 'underscore';
import { inject } from '@angular/core';
import { NotificationService } from '@seahorse/common';

export abstract class ProxyService {
  fieldTypeDefinitions: FieldTypeDefinitionModel[] = [];
  objectDefinitions: ObjectDefinitionModel[] = [];
  uiModules: UiModule[] = [];
  enabledFeatures: string[] = [];
  objectKeyMapping;
  abstract getData(): Observable<boolean>;

  protected _notificationService = inject(NotificationService);

  getBaseObjectDefinitionByMappingKey(
    mappingKey: string
  ): ObjectDefinitionModel {
    return _.find(this.objectDefinitions, (filter) => {
      return (
        filter.baseObjectDefinition &&
        filter.baseObjectDefinition.mappingKey === mappingKey
      );
    })?.baseObjectDefinition;
  }

  getBaseObjectDefinitionBySystemCode(
    systemCode: string
  ): ObjectDefinitionModel {
    return _.find(this.objectDefinitions, (filter) => {
      return (
        filter.baseObjectDefinition &&
        filter.baseObjectDefinition.systemCode === systemCode
      );
    })?.baseObjectDefinition;
  }

  findObjectDefinitionByMappingKey(mappingKey: string): ObjectDefinitionModel {
    return this.objectDefinitions.find(
      (def) => def.mappingKey.toLowerCase() === mappingKey.toLowerCase()
    );
  }

  findObjectDefinitionBySystemCode(systemCode: string): ObjectDefinitionModel {
    return this.objectDefinitions.find(
      (def) => def.systemCode.toLowerCase() === systemCode.toLowerCase()
    );
  }

  getUIModuleForObjectDefinition(definitionId: number) {
    return _.find(
      this.uiModules,
      (moduleFind) => moduleFind.customObjectDefinitionId === definitionId
    );
  }

  protected resetObjectKeyMappings() {
    this.objectKeyMapping = {
      // NauticalCountryModel
      $nautical_country_id: new ObjectKeyMappingModel(
        'Nautical',
        'nautical',
        'Country',
        'country',
        ['id'],
        ['name', 'isO2']
      ),

      // NauticalPortModel
      $nautical_port_id: new ObjectKeyMappingModel(
        'Nautical',
        'nautical',
        'Port',
        'port',
        ['id'],
        ['name', 'unloCode']
      ),

      // NauticalShipModel
      $nautical_ship_id: new ObjectKeyMappingModel(
        'Nautical',
        'nautical',
        'Ship',
        'ship',
        ['id'],
        [
          'name',
          'imo',
          'loa',
          'mmsi',
          'gt',
          'eni',
          'flagCode',
          'callSign',
          'fisheryNumber',
          'shipType',
          'width',
          'dwt',
          'maxDraft',
        ]
      ),

      // NauticalVisitModel
      $nautical_portvisit_id: new ObjectKeyMappingModel(
        'Nautical',
        'nautical',
        'Port Visit',
        'portvisit',
        ['id', 'referenceNumber'],
        ['referenceNumber']
      ),
      $nautical_portvisit_referencenumber: new ObjectKeyMappingModel(
        'Nautical',
        'nautical',
        'Port Visit',
        'portvisit',
        ['id', 'referenceNumber'],
        ['referenceNumber']
      ),

      // NauticalMovementModel
      $nautical_portmovement_id: new ObjectKeyMappingModel(
        'Nautical',
        'nautical',
        'Port Movement',
        'portmovement',
        ['id'],
        ['ata', 'atd']
      ),

      // NauticalWaypointModel
      $nautical_portwaypoint_id: new ObjectKeyMappingModel(
        'Nautical',
        'nautical',
        'Port Waypoint',
        'portwaypoint',
        ['id'],
        ['code', 'name']
      ),

      // NauticalWaypointModel
      $nautical_harbour_id: new ObjectKeyMappingModel(
        'Nautical',
        'nautical',
        'Harbour',
        'harbour',
        ['id'],
        ['code', 'name']
      ),

      // CompanyModel
      $companies_company_id: new ObjectKeyMappingModel(
        'Companies',
        'companies',
        'Company',
        'company',
        ['id'],
        ['name']
      ),

      // FinancialModel
      $companies_financial_id: new ObjectKeyMappingModel(
        'Companies',
        'companies',
        'Financial',
        'financial',
        ['id'],
        ['debtorNumber', 'emailAddress']
      ),

      // UserModel
      $usermanager_user_id: new ObjectKeyMappingModel(
        'Users',
        'usermanager',
        'User',
        'user',
        ['id'],
        ['givenName', 'surname']
      ),

      // InvoiceModel
      $invoicing_invoice_id: new ObjectKeyMappingModel(
        'Invoicing',
        'invoicing',
        'Invoice',
        'invoice',
        ['id'],
        ['number', 'description', 'totalAmount']
      ),
      $invoicing_tariff_id: new ObjectKeyMappingModel(
        'Invoicing',
        'invoicing',
        'Tariff',
        'tariff',
        ['id', 'systemCode'],
        ['name']
      ),
    };

    if (this.objectDefinitions && this.objectDefinitions.length > 0) {
      _.each(this.objectDefinitions, (def: ObjectDefinitionModel) => {
        if (def.templateCode) {
          try {
            const config: ObjectDefinitionConfigurationModel = JSON.parse(
              def.templateCode
            );
            if (
              config &&
              config.link &&
              config.link.allowTolink === true &&
              config.link.mapping
            ) {
              _.each(config.link.mapping.keys, (key) => {
                this.objectKeyMapping[
                  `$${config.link.mapping.moduleKey}_${
                    config.link.mapping.objectKey
                  }_${key.toLowerCase()}`
                ] = config.link.mapping;
              });
            }
          } catch (error: any) {
            this._notificationService.displayErrorNotification(error);
          }
        }
      });
    }
  }
}
