import { Component, Input } from '@angular/core';
import { ResultWrapper } from '@seahorse/common';

import * as _ from 'underscore';

@Component({
  selector: 'ca-result-wrapper-error',
  templateUrl: 'result-wrapper-error.component.html',
})
export class ResultWrapperErrorComponent {
  private _result: ResultWrapper<any> = null;

  @Input() set resultWrapper(rw: ResultWrapper<any>) {
    if (rw.hasOwnProperty('Result')) {
      this._result = {
        count: rw['Count'],
        hasResult: rw['HasResult'],
        messages: [],
        result: rw['Result'],
        status: rw['Status'],
      };

      this._result.messages = _.map(rw['Messages'], (msg) => ({
        code: msg.Code,
        message: msg.Message,
      }));
    } else {
      this._result = rw;
    }
  }
  get resultWrapper(): ResultWrapper<any> {
    return this._result;
  }
}
