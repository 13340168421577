<div
  class="visit-flow visit-flow-horizontal"
  *ngIf="viewOrientation === 'horizontal' && details"
>
  <div class="visit-flow-start" [attr.title]="details.startDisplayTooltip">
    <span>
      {{ details.startDisplayTextShort }}
    </span>
  </div>
  <div class="visit-flow-items">
    <ng-container *ngFor="let marker of filteredMarkers()">
      <div
        class="visit-flow-item"
        [attr.title]="marker.displayTextLong"
        [style.left]="marker.position * 100 + '%'"
        [ngClass]="{
          'visit-flow-item-open': marker.isOpen,
          'visit-flow-item-major': marker.displayType === 'major'
        }"
      >
        <div class="visit-flow-item-date">{{ marker.displayDate }}</div>
        <div class="visit-flow-item-text">
          <span class="visit-flow-item-title">{{
            marker.displayTextShort
          }}</span>
        </div>
      </div>
    </ng-container>
    <div
      class="visit-flow-item visit-flow-vessel"
      [style.left]="details.shipMarker.position * 100 + '%'"
      *ngIf="details.shipMarker.position > -1"
    ></div>
  </div>
  <div class="visit-flow-end" [attr.title]="details.endDisplayTooltip">
    <span>
      {{ details.endDisplayTextShort }}
    </span>
  </div>
</div>

<div
  class="visit-flow visit-flow-vertical"
  *ngIf="viewOrientation === 'vertical' && details"
>
  <div class="visit-flow-start" [attr.title]="details.startDisplayTooltip">
    <span *ngIf="details.startDisplayTextLong">
      {{ details.startDisplayTextLong }}
      <span class="text-muted">({{ details.startDisplayTextShort }})</span>
    </span>
    <span class="text-muted" *ngIf="!details.startDisplayTextLong">
      {{ 'nautical.visit.unknownOrigin' | translate }}
    </span>
  </div>
  <div class="visit-flow-items">
    <div
      class="visit-flow-item-group"
      *ngFor="let markerGroup of details.groupedByDate()"
    >
      <div class="visit-flow-item-group-date">
        <div class="visit-flow-date-box">
          <div class="visit-flow-date-box-day">
            {{ formatDate(markerGroup.dateValue, 'D') }}
          </div>
          <div class="visit-flow-date-box-month">
            {{ formatDate(markerGroup.dateValue, 'MMM') }}
          </div>
        </div>
      </div>
      <div class="visit-flow-item-group-markers">
        <div
          class="visit-flow-item visit-flow-item-marker-{{
            marker.displayType
          }}"
          [attr.title]="marker.displayTextLong"
          *ngFor="let marker of markerGroup.markers"
          [ngClass]="{
            'visit-flow-item-open': marker.isOpen,
            'visit-flow-item-seperator': marker.isNewDate
          }"
        >
          <div class="visit-flow-item-time">
            <span *ngIf="marker.displayType === 'major'">
              {{ formatDate(marker.actualDate, 'HH:mm') }}
            </span>
          </div>
          <div class="visit-flow-item-text">
            <div>
              <span *ngIf="marker.displayType === 'minor'">
                {{ formatDate(marker.actualDate, 'HH:mm') }}
              </span>
              <span
                [ngClass]="{
                  'visit-flow-item-title': marker.displayType === 'major'
                }"
              >
                {{ marker.displayTextLong }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="visit-flow-end" [attr.title]="details.endDisplayTooltip">
    <span *ngIf="details.endDisplayTextLong">
      {{ details.endDisplayTextLong }}
      <span class="text-muted">({{ details.endDisplayTextShort }})</span>
    </span>
    <span *ngIf="!details.endDisplayTextLong" class="text-muted">
      {{ 'nautical.visit.unknownDestination' | translate }}
    </span>
  </div>
</div>
