import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'underscore';
import { CompanyModel } from '@seahorse/domain';
import { CompanyPickerDialogComponent } from '../company-picker-dialog/company-picker-dialog.component';

@Component({
  selector: 'ca-company-card',
  templateUrl: './company-card.component.html',
})
export class CompanyCardComponent {
  @Input() company: CompanyModel;
  @Input() title: string;

  @Output() onChanged: EventEmitter<CompanyModel>;

  constructor(private modal: NgbModal) {
    this.onChanged = new EventEmitter<CompanyModel>();
  }

  openCompanyPicker() {
    const m: NgbModalRef = this.modal.open(CompanyPickerDialogComponent, {
      backdrop: 'static',
    });
    m.componentInstance.companyRequired = false;
    if (this.company) {
      m.componentInstance.company = _.clone(this.company);
    }

    m.componentInstance.onConfirm.subscribe((o: { company: CompanyModel }) => {
      if (o.company && this.company && o.company.id === this.company.id) {
        return;
      }

      this.onChanged.emit(o.company);
    });
  }

  openCompany() {
    if (this.company && this.company.id) {
      window.open(`companies/${this.company.id}`, '_blank');
    }
  }

  unlinkCompany() {
    this.company = null;
    this.onChanged.emit(null);
  }
}
