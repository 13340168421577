import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'invoiceEventType',
})
export class InvoiceEventTypePipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: number): string {
    switch (value) {
      case 1:
        return this.translate.instant(
          'invoicing.invoiceModel.invoiceEventTypes.sent'
        );
      case 2:
        return this.translate.instant(
          'invoicing.invoiceModel.invoiceEventTypes.reminder'
        );
      case 3:
        return this.translate.instant(
          'invoicing.invoiceModel.invoiceEventTypes.received'
        );
      case 4:
        return this.translate.instant(
          'invoicing.invoiceModel.invoiceEventTypes.cancelledByCustomer'
        );
      case 5:
        return this.translate.instant(
          'invoicing.invoiceModel.invoiceEventTypes.cancelledByAdmin'
        );
      case 6:
        return this.translate.instant(
          'invoicing.invoiceModel.invoiceEventTypes.created'
        );
      case 7:
        return this.translate.instant(
          'invoicing.invoiceModel.invoiceEventTypes.approved'
        );
      case 8:
        return this.translate.instant(
          'invoicing.invoiceModel.invoiceEventTypes.exported'
        );
      case 9:
        return this.translate.instant(
          'invoicing.invoiceModel.invoiceEventTypes.changed'
        );
      case 255:
        return this.translate.instant(
          'invoicing.invoiceModel.invoiceEventTypes.other'
        );
      default:
        return this.translate.instant('shared.terms.unknown');
    }
  }
}
