import { Component, Input, Output, EventEmitter } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { EventModel } from '../../../../../../shared/models/event.model';
import { DependenciesModalComponent } from '../dependencies-modal/dependencies-modal.component';

@Component({
  selector: 'dependencies-info',
  templateUrl: 'dependencies-info.component.html',
})
export class DependenciesInfoComponent {
  @Input() dependencies: any[];
  @Input() loadingDependencies: boolean;

  @Output() onEvent: EventEmitter<EventModel>;

  constructor(private modal: NgbModal) {
    this.dependencies = [];
    this.loadingDependencies = false;
    this.onEvent = new EventEmitter<EventModel>();
  }

  addDependency() {
    const dependenciesModal = this.modal.open(DependenciesModalComponent);

    // @Input()
    dependenciesModal.componentInstance.condition = null;

    // @Output()
    dependenciesModal.componentInstance.onEvent.subscribe(
      (dependenciesEvent: EventModel) => this.onEvent.emit(dependenciesEvent)
    );
  }
}
