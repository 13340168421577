export class NauticalPortWaypointModel {
  name: string;
  waypointType: WaypointTypes;
  code: string;
  number: number;
  portId: number;
}

export enum WaypointTypes {
  Quay = 1,
  Dock = 2,
  Buoy = 3,
  Dolphin = 4,
  EntryPoint = 5,
  Unknown = 255,
}
