import { NgModule } from '@angular/core';

import { CoreModule } from '../../core/core.module';

import { ObjectDefinitionsDataService } from './object-definitions-data.service';
import { FieldDefinitionsDataService } from './field-definitions-data.service';
import { FieldTypeDefinitionsDataService } from './field-type-definitions-data.service';
import { CustomDefaultDataService } from './custom-default-data.service';

@NgModule({
  imports: [CoreModule],
  providers: [
    CustomDefaultDataService,
    ObjectDefinitionsDataService,
    FieldDefinitionsDataService,
    FieldTypeDefinitionsDataService,
  ],
})
export class CustomDataServicesModule {}
