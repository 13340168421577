<div class="page-header d-flex justify-content-between px-3 py-1">
  <!-- Left side -->
  <form
    (ngSubmit)="onEvent.emit({ action: 'searchInvoicingFlows', data: search })"
    class="form-inline mx-0 my-1"
  >
    <div class="input-group mr-2">
      <div class="input-group-prepend">
        <span class="input-group-text">
          {{ 'shared.terms.view' | translate }}
        </span>
      </div>
      <select
        [(ngModel)]="selectedView"
        (change)="onEvent.emit({ action: 'setView', data: selectedView })"
        required
        name="selectView"
        class="form-control"
      >
        <option
          *ngFor="let viewOption of viewOptions"
          [ngValue]="viewOption.value"
        >
          {{ viewOption.title | translate }}
        </option>
      </select>
    </div>
    <div class="input-group mr-2">
      <div class="input-group-prepend">
        <span class="input-group-text">
          <i class="fa-solid fa-search fa-fw"></i>
        </span>
      </div>
      <input
        [(ngModel)]="search"
        [placeholder]="'shared.terms.search' | translate"
        type="text"
        name="search"
        class="form-control"
      />
      <div class="input-group-append">
        <button type="submit" class="btn btn-outline-secondary mr-0">
          {{ 'shared.terms.search' | translate }}
        </button>
      </div>
    </div>
    <em *ngIf="resultCount != null">
      {{ resultCount }}
      <ng-container
        *ngIf="resultCount == 1; then itemFound; else itemsFound"
      ></ng-container>
      <ng-template #itemFound>{{
        'shared.terms.itemFound' | translate
      }}</ng-template>
      <ng-template #itemsFound>{{
        'shared.terms.itemsFound' | translate
      }}</ng-template>
    </em>
  </form>
  <!-- Right side -->
  <button
    (click)="onEvent.emit({ action: 'createFlow' })"
    type="button"
    class="btn btn-primary my-1"
  >
    <i class="fa-regular fa-plus fa-fw"></i>
    {{ 'invoicingFlows.overview.newFlow' | translate }}
  </button>
</div>
