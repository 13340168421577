export class AddressModel {
  country: AddressTextField;
  province: AddressTextField;
  postalCode: AddressTextField;
  town: AddressTextField;
  street: AddressTextField;
  number: AddressNumberField;
  addition: AddressTextField;

  constructor(override?: Partial<AddressModel>) {
    this.country = new AddressTextField();
    this.province = new AddressTextField();
    this.postalCode = new AddressTextField({ required: true });
    this.town = new AddressTextField({ required: true });
    this.street = new AddressTextField({ required: true });
    this.number = new AddressNumberField({ minimum: 1, required: true });
    this.addition = new AddressTextField();

    if (override) {
      Object.assign(this, override);
    }
  }
}

export class AddressProperties {
  required: boolean;

  constructor() {
    this.required = false;
  }
}

export class AddressTextField extends AddressProperties {
  value: string;

  constructor(override?: Partial<AddressTextField>) {
    super();

    this.value = null;

    if (override) {
      Object.assign(this, override);
    }
  }
}

export class AddressNumberField extends AddressProperties {
  value: number;
  minimum?: number;

  constructor(override?: Partial<AddressNumberField>) {
    super();

    this.value = null;
    this.minimum = null;

    if (override) {
      Object.assign(this, override);
    }
  }
}
