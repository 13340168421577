import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { AttributeListComponent, FlagCodePickerDialogFormComponent } from '@seahorse/temp';
import { CoreModule } from '../../core/core.module';
import { LayoutComponentsModule } from '../../layout/components/layout-components.module';
import { LayoutPipesModule } from '../../layout/pipes/layout-pipes.module';
import { MapComponentsModule } from '../../map/components/map-components.module';
import { MapServicesModule } from '../../map/services/map-services.module';
import { NauticalVisitServicesModule } from '../../nautical-visit/services/nautical-visit-services.module';
import { SharedModule } from '../../shared/shared.module';

import { NauticalShipAddAttributeComponent } from './nautical-ship-add-attribute/nautical-ship-add-attribute.component';
import { NauticalShipBaseInformationComponent } from './nautical-ship-base-information/nautical-ship-base-information.component';
import { NauticalShipCardComponent } from './nautical-ship-card/nautical-ship-card.component';
import { NauticalShipDetailsHeaderComponent } from './nautical-ship-details-header/nautical-ship-details-header.component';
import { NauticalShipDetailsComponent } from './nautical-ship-details/nautical-ship-details.component';
import { NauticalShipFilesCardComponent } from './nautical-ship-files-card/nautical-ship-files-card.component';
import { NauticalShipFilesListComponent } from './nautical-ship-files-list/nautical-ship-files-list.component';
import { NauticalShipHeaderComponent } from './nautical-ship-header/nautical-ship-header.component';
import { NauticalShipListComponent } from './nautical-ship-list/nautical-ship-list.component';
import { NauticalShipModalComponent } from './nautical-ship-modal/nautical-ship-modal.component';
import { NauticalShipPickerDialogComponent } from './nautical-ship-picker-dialog/nautical-ship-picker-dialog.component';
import { NauticalShipPickerComponent } from './nautical-ship-picker/nautical-ship-picker.component';
import { NauticalShipSummaryComponent } from './nautical-ship-summary/nautical-ship-summary.component';
import { NauticalShipTagComponent } from './nautical-ship-tag/nautical-ship-tag.component';
import { NauticalShipTilesComponent } from './nautical-ship-tiles/nautical-ship-tiles.component';
import { NauticalShipTrackDisplayComponent } from './nautical-ship-track-display/nautical-ship-track-display.component';
import { RecentVesselsCardComponent } from './recent-vessels-card/recent-vessels-card.component';
import { SeahorseFormsModule } from '@seahorse/forms';
import { SeahorseUiModule } from '@seahorse/ui';

@NgModule({
  declarations: [
    NauticalShipAddAttributeComponent,
    NauticalShipBaseInformationComponent,
    NauticalShipCardComponent,
    NauticalShipDetailsComponent,
    NauticalShipDetailsHeaderComponent,
    NauticalShipFilesCardComponent,
    NauticalShipFilesListComponent,
    NauticalShipHeaderComponent,
    NauticalShipListComponent,
    NauticalShipModalComponent,
    NauticalShipPickerComponent,
    NauticalShipPickerDialogComponent,
    NauticalShipSummaryComponent,
    NauticalShipTagComponent,
    NauticalShipTilesComponent,
    NauticalShipTrackDisplayComponent,
    RecentVesselsCardComponent,
  ],
  exports: [
    NauticalShipBaseInformationComponent,
    NauticalShipCardComponent,
    NauticalShipDetailsComponent,
    NauticalShipDetailsHeaderComponent,
    NauticalShipHeaderComponent,
    NauticalShipListComponent,
    NauticalShipModalComponent,
    NauticalShipSummaryComponent,
    NauticalShipTilesComponent,
    NauticalShipFilesListComponent,
    NauticalShipFilesCardComponent,
    NauticalShipPickerComponent,
    NauticalShipTrackDisplayComponent,
    RecentVesselsCardComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    LayoutComponentsModule,
    LayoutPipesModule,
    MapComponentsModule,
    MapServicesModule,
    RouterModule,
    NgSelectModule,
    TranslateModule,
    NgbModule,
    NauticalVisitServicesModule,
    CoreModule,
    FlagCodePickerDialogFormComponent,
    AttributeListComponent,
    ReactiveFormsModule,
    SeahorseFormsModule,
    SeahorseUiModule
  ],
})
export class NauticalShipComponentsModule {}
