import { Component, Input, Output, EventEmitter } from '@angular/core';
import { EventModel } from '../../../shared/models/event.model';

@Component({
  selector: 'ca-nautical-ship-details-header',
  templateUrl: 'nautical-ship-details-header.component.html',
})
export class NauticalShipDetailsHeaderComponent {
  @Input() allowEdit = true;
  @Input() allowDelete = false;
  @Input() masterShipId: number = null;
  @Output() actionSelected = new EventEmitter<EventModel>();

  action(actionName: string) {
    const model = new EventModel();
    model.action = actionName;
    this.actionSelected.emit(model);
  }
}
