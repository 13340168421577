import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'nauticalVisitStatus',
})
export class NauticalVisitStatusPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: number): string {
    switch (value) {
      case 1:
        return this.translate.instant('nautical.visit.filter.planned');
      case 2:
        return this.translate.instant('nautical.visit.filter.inport');
      case 3:
        return this.translate.instant('nautical.visit.filter.sailed');
      case 4:
        return this.translate.instant('nautical.visit.filter.cancelled');
      default:
        return this.translate.instant('nautical.visit.filter.unknown');
    }
  }
}
