import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'titleCase',
})
export class TitleCasePipe implements PipeTransform {
  transform(value: string): string {
    return this.toTitleCase(value);
  }

  toTitleCase(str: string) {
    const newStr = str.replace(/(\B[A-Z])/g, ' $1').toLowerCase();
    if (str.charAt(0) === str.charAt(0).toUpperCase()) {
      return str.charAt(0) + newStr.slice(1);
    } else {
      return newStr.charAt(0).toUpperCase() + newStr.slice(1);
    }
  }
}
