import { BusinessLogicCalculation } from './business-logic-calculation.model';
import { BusinessLogicCondition } from './business-logic-condition.model';
import { BusinessLogicDependency } from './business-logic-dependency.model';
import { VATApplication } from './vat-application.model';

export class InvoicingBusinessLogic {
  calculations: BusinessLogicCalculation[];
  conditions: BusinessLogicCondition[];
  dependencies: BusinessLogicDependency[];
  vatApplications: VATApplication[];
}
