<div class="page-header d-flex justify-content-between px-3 py-1">
  <form (ngSubmit)="searchVisits()" class="form-inline mx-0 my-1">
    <div class="input-group mr-2">
      <div class="input-group-prepend">
        <span class="input-group-text">{{
          'shared.terms.view' | translate
        }}</span>
      </div>
      <select
        [(ngModel)]="selectedView"
        (change)="setSelectedView()"
        required
        name="ddl_header_data_display"
        class="custom-select"
      >
      <option [value]="view.List">{{ 'shared.terms.list' | translate }}</option>
      <option [value]="view.Tiles">
        {{ 'shared.terms.tiles' | translate }}
      </option>
      </select>
    </div>
    <div class="input-group mr-2">
      <div class="input-group-prepend">
        <span class="input-group-text">
          <i class="fa-solid fa-search fa-fw"></i>
        </span>
      </div>
      <input
        [(ngModel)]="searchQuery"
        [placeholder]="'shared.terms.search' | translate"
        type="text"
        name="searchBox"
        class="form-control"
      />
      <div class="input-group-append">
        <button type="submit" class="btn btn-outline-secondary mr-0">
          {{ 'shared.terms.search' | translate }}
        </button>
      </div>
      <em *ngIf="searchResultCount != null">
        {{ searchResultCount }}
        <ng-container
          *ngIf="searchResultCount == 1; then itemFound; else itemsFound"
        ></ng-container>
        <ng-template #itemFound>{{
          'shared.terms.itemFound' | translate
        }}</ng-template>
        <ng-template #itemsFound>{{
          'shared.terms.itemsFound' | translate
        }}</ng-template>
      </em>
    </div>
  </form>
  <div class="input-group my-1 mr-2 w-auto">
    <div class="input-group-prepend">
      <span class="input-group-text">{{
        'nautical.visit.filter.filter' | translate
      }}</span>
    </div>
    <select
      [(ngModel)]="selectedFilter"
      (change)="setSelectedFilter(selectedFilter)"
      required
      name="ddl_header_visit_filter"
      class="custom-select"
    >
      <option [value]="'nofilter'">
        {{ 'nautical.visit.filter.noFilter' | translate }}
      </option>
      <option [value]="'cancelled'">
        {{ 'nautical.visit.filter.cancelled' | translate }}
      </option>
      <option [value]="'inport'">
        {{ 'nautical.visit.filter.inport' | translate }}
      </option>
      <option [value]="'planned'">
        {{ 'nautical.visit.filter.planned' | translate }}
      </option>
      <option [value]="'sailed'">
        {{ 'nautical.visit.filter.sailed' | translate }}
      </option>
    </select>
  </div>
</div>
