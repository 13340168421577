<section class="page-header d-flex justify-content-between px-3 py-1">
  <div class="d-flex mx-n1">
    <div *ngIf="showFilterList">
      <ca-filter-list
        button-displayText="shared.terms.selectFilter"
        [list-data]="filters"
        list-displayMember="displayText"
        list-title="shared.terms.availableFilters"
        (selected)="setSelectedFilter($event)"
      >
      </ca-filter-list>
    </div>
    <div
      class="input-group align-items-center w-auto mx-1"
      *ngIf="showViewSelector"
    >
      <div class="input-group-prepend">
        <span class="input-group-text">{{
          'shared.terms.view' | translate
        }}</span>
      </div>
      <select
        class="form-control"
        [(ngModel)]="selectedView"
        name="ddl_header_data_display"
        (change)="changeSelectedView()"
      >
        <option [value]="CollectionDisplay.List">
          {{ 'shared.terms.list' | translate }}
        </option>
        <option [value]="CollectionDisplay.Tiles">
          {{ 'shared.terms.tiles' | translate }}
        </option>
      </select>
    </div>
    <form class="form-inline mx-1 mt-0" (ngSubmit)="find()">
      <div class="input-group" *ngIf="showSearch">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <i class="fa-solid fa-search fa-fw"></i>
          </span>
        </div>
        <input
          type="text"
          class="form-control"
          [placeholder]="'shared.terms.search' | translate"
          [(ngModel)]="search.searchQuery"
          name="searchBox"
        />
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" type="submit">
            {{ 'shared.terms.search' | translate }}
          </button>
        </div>
      </div>
      <em class="ml-2" *ngIf="searchResultCount != null">
        {{ searchResultCount }}
        <ng-container
          *ngIf="searchResultCount == 1; then itemFound; else itemsFound"
        ></ng-container>
        <ng-template #itemFound>{{
          'shared.terms.itemFound' | translate
        }}</ng-template>
        <ng-template #itemsFound>{{
          'shared.terms.itemsFound' | translate
        }}</ng-template>
      </em>
      <div *ngIf="showDatePickerFilter">
        <div class="input-group">
          <label for="dateFilter" class="padding-right-5"
            >{{ 'shared.terms.date' | translate }}:</label
          >
          <div class="input-group">
            <temp-date-time-picker
              [(dateTime)]="date"
              [dateTimeFormat]="'ngbDate'"
              [hideTime]="true"
              [hideNow]="true"
            ></temp-date-time-picker>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="d-flex mx-n1">
    <div class="input-group align-items-center w-auto mx-1">
      <div class="custom-control custom-switch mr-3" *ngIf="showInactiveFilter">
        <input
          [(ngModel)]="showInactive"
          (change)="toggleInactiveAccounts()"
          type="checkbox"
          class="custom-control-input"
          id="showInactive"
        />
        <label class="custom-control-label" for="showInactive">{{
          'accounts.showInactive' | translate
        }}</label>
      </div>
      <ng-content select="[additionalOptions]"></ng-content>
      <div *ngIf="showNewButton">
        <button class="btn btn-primary" type="button" (click)="open()">
          <i class="fa-regular fa-plus fa-fw"></i>
          {{ 'shared.terms.new' | translate }} {{ currentModuleName }}
        </button>
      </div>
    </div>
  </div>
</section>
