import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BadgeComponent } from './badge.component';
import { BadgeConfigurationModel } from './badge-configuration.model';

@Component({
  selector: 'sh-badge-list',
  template: `<ng-container *ngFor="let badge of badges">
    <sh-badge
      [badge]="badge"
      class="ml-2"
      (badgeClicked)="badgeClicked.emit($event)"
    ></sh-badge>
  </ng-container>`,
  standalone: true,
  imports: [CommonModule, BadgeComponent],
})
export class BadgeListComponent {
  @Input() badges: BadgeConfigurationModel[] = [];
  @Output() badgeClicked = new EventEmitter<BadgeConfigurationModel>();
}
