import { Component, Input } from '@angular/core';
import { CompanyModel } from '@seahorse/domain';
import { CompanyDataService } from '@seahorse/domain';

@Component({
  selector: 'ca-parent-company-picker',
  templateUrl: 'parent-company-picker.component.html',
})
export class ParentCompanyPickerComponent {
  private _value: CompanyModel;
  @Input() set company(val: CompanyModel) {
    this._value = val;
    this.parentCompanyId = val.parentCompanyId;
    this.loadCompanies();
  }
  get company() {
    return this._value;
  }

  parentCompany: CompanyModel;
  parentCompanies = [{ id: null, name: '' }] as CompanyModel[];

  private _parentCompanyId: number | null = null;
  set parentCompanyId(val: number | null) {
    this._parentCompanyId = val;
    this.parentCompany = this.parentCompanies.find(x => x.id === val) || null;
  }
  get parentCompanyId() {
    return this._parentCompanyId;
  }

  constructor(private _companyDataService: CompanyDataService) {}

  loadCompanies() {
    this._companyDataService
      .getPossibleParentCompanies(this.company.id)
      .subscribe((response) => {
        this.parentCompanies = this.parentCompanies.concat(
          response.result
        );
      });
  }
}
