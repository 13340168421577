<div class="page-with-master-detail">
  <div class="container-fluid py-3">
    <div class="row">
      <div class="col-md-12 col-lg-6 mb-4 mb-4">
        <div class="card">
          <div class="card-header">
            <span>{{ 'account.register.customdata.title' | translate }}</span>
          </div>

          <div class="card-body">
            <form #stepOneForm="ngForm" *ngIf="currentForm == 0">
              <div class="column">
                <h5 class="mb-3">
                  {{
                    'account.register.customdata.wizard.first.title' | translate
                  }}
                </h5>

                <p class="mb-4 text-justify">
                  {{
                    'account.register.customdata.wizard.first.text' | translate
                  }}
                </p>
              </div>

              <div class="form-group">
                <input
                  required
                  class="form-control"
                  name="name"
                  #name="ngModel"
                  [(ngModel)]="serviceData.name"
                />
                <span
                  *ngIf="
                    isSubmitted && (name.value == null || name.value == '')
                  "
                  class="text-danger"
                  >{{
                    'account.register.customdata.error.nameRequired' | translate
                  }}</span
                >
              </div>
            </form>

            <form #stepTwoForm="ngForm" *ngIf="currentForm == 1">
              <div class="column">
                <h5 class="mb-3">
                  {{
                    'account.register.customdata.informationTitle' | translate
                  }}
                </h5>

                <p class="mb-4">
                  {{ 'account.register.customdata.keepTrackTitle' | translate }}
                  <b>{{ serviceData.name }}</b> ?
                </p>
              </div>

              <div
                class="row"
                *ngFor="
                  let data of serviceData.serviceInformationFields;
                  let i = index
                "
              >
                <div
                  class="col-md-8 col-xs-12 col-sm-12"
                  *ngIf="data.isCheckbox"
                >
                  <div class="form-group input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text">
                        <input
                          class="cursor-pointer"
                          type="checkbox"
                          id="serviceData{{ i }}"
                          name="serviceData{{ i }}"
                          [(ngModel)]="data.checked"
                        />
                      </div>
                    </div>
                    <label
                      class="form-control cursor-pointer"
                      for="serviceData{{ i }}"
                      >{{ data.label }}</label
                    >
                  </div>
                </div>
              </div>

              <p class="mb-3 mt-3">
                {{ 'account.register.customdata.moreNamely' | translate }}
              </p>

              <div class="form-row">
                <table class="table table-truncated">
                  <tbody>
                    <ng-container
                      *ngFor="
                        let data of serviceData.serviceInformationFields;
                        let isLast = last;
                        let i = index
                      "
                    >
                      <tr class="card-body-group" *ngIf="!data.isCheckbox">
                        <td style="width: 1%">
                          <button
                            class="btn btn-outline-secondary btn-sm"
                            *ngIf="
                              !isLast || newServiceInformationField !== null
                            "
                            type="button"
                            (click)="removeRow(data)"
                          >
                            <i class="fa-regular fa-minus"></i>
                          </button>
                          <button
                            class="btn btn-outline-secondary btn-sm"
                            *ngIf="
                              isLast && newServiceInformationField === null
                            "
                            type="button"
                            (click)="addRow()"
                          >
                            <i class="fa-regular fa-plus"></i>
                          </button>
                        </td>
                        <td class="text-truncate">
                          <input
                            [(ngModel)]="data.name"
                            name="name{{ i }}"
                            type="text"
                            class="form-control"
                          />
                        </td>
                        <td>
                          <ng-select
                            #fieldType="ngModel"
                            required
                            name="fieldType{{ i }}"
                            (change)="onChange()"
                            [(ngModel)]="data.fieldTypeDefinitionId"
                            [items]="fieldTypes"
                            [placeholder]="
                              'shared.terms.selectType' | translate
                            "
                            bindLabel="name"
                            bindValue="id"
                            [clearable]="false"
                            [searchable]="false"
                          >
                          </ng-select>
                        </td>
                        <td
                          *ngIf="
                            findFieldType(data.fieldTypeDefinitionId) ===
                            FieldType.List
                          "
                        >
                          <div class="form-group">
                            <div>
                              <span
                                class="list-item"
                                *ngFor="let listItem of data.listItems"
                              >
                                {{ listItem }}
                                <a
                                  class="list-item-remove"
                                  (click)="removeItemFromList(data, listItem)"
                                  >x</a
                                >
                              </span>
                            </div>
                            <div>
                              <div class="input-group">
                                <div class="input-group-prepend flex-grow-1">
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="newListItem"
                                    name="newListItem"
                                    [(ngModel)]="newListItem"
                                    [placeholder]="
                                      'shared.terms.newValue' | translate
                                    "
                                  />
                                </div>
                                <button
                                  class="btn btn-link ml-2"
                                  type="button"
                                  (click)="addItemToList(data)"
                                >
                                  {{ 'shared.terms.add' | translate }}
                                </button>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </ng-container>

                    <tr
                      class="card-body-group"
                      *ngIf="newServiceInformationField !== null"
                    >
                      <td style="width: 1%">
                        <button
                          class="btn btn-outline-secondary btn-sm"
                          type="button"
                          (click)="addRow()"
                        >
                          <i class="fa-regular fa-plus"></i>
                        </button>
                      </td>

                      <td style="width: 30%">
                        <input
                          required
                          #name="ngModel"
                          type="text"
                          name="name"
                          id="name"
                          [(ngModel)]="newServiceInformationField.name"
                          class="form-control"
                          (keyup)="onChange()"
                        />
                        <span *ngIf="sameFieldExists" class="text-danger">{{
                          'account.register.customdata.errors.nameUnique'
                            | translate
                        }}</span>
                      </td>

                      <td style="width: 30%">
                        <ng-select
                          #fieldType="ngModel"
                          required
                          name="fieldType"
                          (change)="onChange()"
                          [(ngModel)]="
                            newServiceInformationField.fieldTypeDefinitionId
                          "
                          [items]="fieldTypes"
                          [placeholder]="'shared.terms.selectType' | translate"
                          bindLabel="name"
                          bindValue="id"
                          [clearable]="false"
                          [searchable]="false"
                        >
                        </ng-select>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </form>

            <div class="d-flex justify-content-end align-items-center">
              <button
                *ngIf="currentForm == 0"
                type="button"
                class="btn btn-primary"
                (click)="changeStep()"
              >
                {{ 'shared.terms.nextStep' | translate }}
              </button>
              <button
                *ngIf="currentForm == 1"
                type="button"
                class="btn btn-primary"
                (click)="changeStep()"
              >
                {{ 'shared.terms.previousStep' | translate }}
              </button>
              <button
                *ngIf="currentForm == 1"
                [disabled]="sameFieldExists"
                type="button"
                class="btn btn-success"
                (click)="save()"
              >
                {{ 'shared.terms.save' | translate }}
              </button>
            </div>

            <div class="d-flex justify-content-end align-items-center mt-2">
              <sh-loading-indicator
                class="text-center loader-sm mr-4"
                *ngIf="isLoading"
              ></sh-loading-indicator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
