<ca-language-picker
  class="d-flex justify-content-end m-2"
></ca-language-picker>

<div class="login-wrapper">
  <div class="login-form">
    <section>
      <div class="login-logo">{{ 'shared.branding.shipm8' | translate }}</div>
      <div class="d-flex flex-column">
        <h5 class="mt-4 text-center">
          <strong>{{ 'account.register.thankyou.title' | translate }}</strong>
        </h5>
        <span class="mt-4 text-justify">
          {{ 'account.register.thankyou.text' | translate }}
        </span>
      </div>

      <div class="d-flex justify-content-end">
        <a class="btn-link" href="/account/login">{{
          'account.login.backToLogin' | translate
        }}</a>
      </div>
    </section>
  </div>
</div>
