import { CustomDataBaseModel } from '@seahorse/domain';

export class OrderRegistrationPartModel extends CustomDataBaseModel {
  quantity: number;
  name?: string;

  constructor(quantity: number, name?: string) {
    super();

    this.quantity = quantity;
    this.name = name;
  }
}
