import { Injectable } from '@angular/core';
import { ResultWrapper, KeyValuePair } from '@seahorse/common';
import { DataContextService } from '@seahorse/common';
import { ExecutionModel } from '../../../../invoicing-and-tariffs/modules/invoicing-flows/models/execution.model';
import { QueryModel } from '../../../../shared/models/query.model';
import { Observable } from 'rxjs';
import { FlowModel } from '@seahorse/domain';

@Injectable({
  providedIn: 'root',
})
export class ExecutionDataService {
  constructor(private dataContextService: DataContextService) {}

  // Get
  search(query: QueryModel): Observable<ResultWrapper<ExecutionModel[]>> {
    return this.dataContextService.get<ExecutionModel[]>(
      `invoice/flow/${query.search}/execution?&pindex=${query.pageIndex}&psize=${query.pageSize}`
    );
  }

  approve(id: ExecutionModel['id']): Observable<ResultWrapper<ExecutionModel>> {
    return this.dataContextService.get<ExecutionModel>(
      `invoice/flow/execution/${id}/approve`
    );
  }

  getStatisticsByFlowId(
    flowId: FlowModel['id']
  ): Observable<ResultWrapper<KeyValuePair<string, number>[]>> {
    return this.dataContextService.get<KeyValuePair<string, number>[]>(
      `invoice/flow/${flowId}/execution/statistics`
    );
  }

  startExecution(
    flowId: FlowModel['id'],
    execution: ExecutionModel
  ): Observable<ResultWrapper<ExecutionModel>> {
    return this.dataContextService.post<ExecutionModel>(
      `invoice/flow/${flowId}/execution`,
      execution
    );
  }
}
