export class EventModel {
  action: string;
  data?: any;

  constructor(override?: Partial<EventModel>) {
    this.action = override && override.action ? override.action : null;

    if (override && override.data) {
      this.data = override.data;
    }
  }
}
