import { Component, Input } from '@angular/core';

import { NauticalVisitFlowModel } from '../../models/nautical-visit-flow.model';
import { NauticalVisitDetailsModel } from '@seahorse/domain';
import { NauticalVisitFlowUtilityService } from '../../services/nautical-visit-flow-utility.service';
import * as moment from 'moment';
import * as _ from 'underscore';
import { NauticalVisitOverviewModel } from '@seahorse/domain';

@Component({
  selector: 'ca-nautical-visit-flow',
  templateUrl: './nautical-visit-flow.component.html',
  styleUrls: ['nautical-visit-flow.component.scss'],
})
export class NauticalVisitFlowComponent {
  details: NauticalVisitFlowModel = null;
  showOnlyCurrentMarkers = false;
  showOnlyMajorMarkers = false;
  viewOrientation = 'vertical';

  @Input('visit-flow-orientation') set visitFlowOrientation(value: string) {
    this.viewOrientation = value;
  }

  @Input('visit-flow-details') set visitFlowDetails(
    value: NauticalVisitFlowModel
  ) {
    // Just set the details outright
    this.details = value;
  }

  @Input('nautical-visit') set nauticalVisit(visit: NauticalVisitDetailsModel) {
    // Process a visit into visit flow details
    this.details =
      this.nauticalVisitFlowUtilities.convertVisitDetailsToFlow(visit);
  }

  @Input('nautical-visit-overview') set nauticalVisitOverview(
    visit: NauticalVisitOverviewModel
  ) {
    this.details =
      this.nauticalVisitFlowUtilities.convertVisitOverviewToFlow(visit);
  }

  @Input('show-only-current') set showOnlyCurrentMarkersFilter(
    onlyCurrent: boolean
  ) {
    this.showOnlyCurrentMarkers = onlyCurrent;
  }

  @Input('show-only-major') set showOnlyMajorMarkersFilter(onlyMajor: boolean) {
    this.showOnlyMajorMarkers = onlyMajor;
  }

  constructor(
    private nauticalVisitFlowUtilities: NauticalVisitFlowUtilityService
  ) {}

  formatDate(date, format) {
    if (!date) {
      return null;
    }
    return moment(date).format(format);
  }

  filteredMarkers() {
    let markers = _.clone(this.details.markers);

    if (this.showOnlyMajorMarkers === true) {
      markers = _.filter(markers, (m) => m.displayType === 'major');
    }

    if (this.showOnlyCurrentMarkers === true) {
      const lastPastItem = _.last(
        _.filter(markers, (m) => moment(m.actualDate).isBefore(moment()))
      );
      markers = _.filter(markers, (m) =>
        moment(m.actualDate).isAfter(moment())
      );
      if (lastPastItem) {
        markers.unshift(lastPastItem);
      }
    }

    // Distribute the positions evenly
    for (let i = 1; i < markers.length; i++) {
      if (markers[i].position <= 0) {
        continue;
      }

      // Compare this position to the previous and ensure at least .1 (10%) space
      if (markers[i].position - markers[i - 1].position < 0.1) {
        markers[i].position = markers[i - 1].position + 0.1;
      }
    }

    return markers;
  }
}
