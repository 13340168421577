import { ScopeType } from './custom-default-data.model';
import { BaseFieldRule } from './field-rule.model';
import { FieldType } from './field-type-definition.model';

export class FieldMode {
  display: string;
  edit: string;

  static empty() {
    return new FieldMode();
  }

  static isEmpty(mode: FieldMode) {
    return JSON.stringify(FieldMode.empty()) === JSON.stringify(mode);
  }
}

export class CustomDataAdditionalDataModel {
  dependencies: CustomDataDependencyModel[];
  displayFields: string[];
  mappingKey: string;
  overrideBase: boolean;
  templateBody: string;
  tag: string;
  mode: FieldMode;
  showCopyButton: boolean;
  implementation?: string;
  incrementValues?: { [key: string]: string };

  constructor() {
    this.dependencies = [];
    this.displayFields = [];
    this.mappingKey = null;
    this.templateBody = null;
    this.overrideBase = false;
    this.mode = FieldMode.empty();
    this.showCopyButton = false;
  }

  static empty() {
    return new CustomDataAdditionalDataModel();
  }

  static isEmpty(additionalData: CustomDataAdditionalDataModel) {
    return (
      JSON.stringify(additionalData) ===
      JSON.stringify(CustomDataAdditionalDataModel.empty())
    );
  }
}

export class FieldDefinitionModel {
  description: string;
  fieldRule: BaseFieldRule;
  defaultValue: string;
  additionalData: string;
  groupName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sortOrder: any;
  isReadOnly: boolean;
  visibility: FieldVisibility;
  allowEmpty: boolean;
  isRequired: boolean;
  name: string;
  systemCode: string;
  dataObjectId: number;
  fieldTypeDefinitionId: number;
  scope: ScopeType;
  fieldType: FieldType;
  fieldCode: string;
  dataObjectDefinitionModel: unknown;
  formatting?: object;
  additionalDataModel = CustomDataAdditionalDataModel.empty();

  static getAdditionalData(field: FieldDefinitionModel) {
    if (!field?.additionalData) {
      return null;
    }

    try {
      return JSON.parse(field.additionalData) as CustomDataAdditionalDataModel;
    } catch (error) {
      return null;
    }
  }

  static getMappingKey(field: FieldDefinitionModel) {
    const additionalData = FieldDefinitionModel.getAdditionalData(field);
    return additionalData ? additionalData.mappingKey : null;
  }

  static getMappingKeySystemCode(field: FieldDefinitionModel) {
    const mappingKey = FieldDefinitionModel.getMappingKey(field);
    return mappingKey.split('_')[1];
  }

  static getMappingKeyForeignKey(field: FieldDefinitionModel) {
    const mappingKey = FieldDefinitionModel.getMappingKey(field);
    const mappingKeyObjectName =
      FieldDefinitionModel.getMappingKeySystemCode(field);

    const foreignKey = mappingKey.split(`${mappingKeyObjectName}_`).pop();

    if (!foreignKey.startsWith('__')) {
      return foreignKey;
    }

    const originalCode = foreignKey.split('_').pop();
    // converts __fieldName to __FieldName
    return '__' + originalCode.charAt(0).toUpperCase() + originalCode.slice(1);
  }

  static isValid(field: FieldDefinitionModel) {
    if (!field.fieldTypeDefinitionId) {
      return false;
    }

    if (!field.systemCode || field.systemCode.trim().length === 0) {
      return false;
    }

    if (!field.name || field.name.trim().length === 0) {
      return false;
    }

    return true;
  }
}

export enum FieldVisibility {
  Public = 0,
  Private = 1,
  Hidden = 2,
}

export class CustomDataDependencyModel {
  dependencyCode: string;
  mappingKey: string;
  autoUpdateDependency: boolean;
  clearDependencyChanged: boolean;

  constructor() {
    this.dependencyCode = null;
    this.mappingKey = null;
    this.autoUpdateDependency = null;
    this.clearDependencyChanged = null;
  }
}
