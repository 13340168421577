import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'underscore';

@Pipe({
  name: 'shiptypesFilter',
})
export class ShiptypesFilterPipe implements PipeTransform {
  transform(value: any, shiptypes: any): any {
    const res = _.find(shiptypes, (st: any) => st.id === value);
    if (res) {
      return res.name;
    } else {
      return null;
    }
  }
}
