import { OnInit, Component, Input } from '@angular/core';
import { WidgetDesigner } from '../../models/widget.model';
import {
  TaskWidgetType,
  TaskWidgetModel,
} from '../../models/task-widget.model';

@Component({
  selector: 'ca-task-widget-designer',
  templateUrl: './task-widget-designer.component.html',
})
export class TaskWidgetDesignerComponent implements WidgetDesigner, OnInit {
  @Input() widget: TaskWidgetModel;
  bodyTypes = Object.keys(TaskWidgetType)
    .filter((key) => isNaN(Number(key)))
    .map((key) => {
      return { id: TaskWidgetType[key], name: key };
    });

  getWidgetData = () => this.widget;

  ngOnInit() {
    if (!this.widget) {
      this.widget = new TaskWidgetModel();
    }
  }

  onChangeBodyType(value: { id: number; label: string }) {
    this.widget = { ...this.widget, bodyType: value.id };
  }
}
