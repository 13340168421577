import { Component, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AreYouSureModalComponent } from '@seahorse/ui';
import { MapAreaModel } from '../../../map/models/map-interaction.model';
import { PreferenceDataService } from '../../../preference/services/preference-data.service';
import { Subscription } from 'rxjs';

import * as _ from 'underscore';

@Component({
  selector: 'ca-map-areas-edit-modal',
  templateUrl: 'map-areas-edit-modal.component.html',
})
export class MapAreasEditModalComponent implements OnDestroy {
  private subscriptions$ = Array<Subscription>();
  areas: MapAreaModel[] = [];
  editIndex = -1;
  editItem: MapAreaModel = null;
  isLoading = false;

  @Input() mapBounds = null;

  constructor(
    private activeModal: NgbActiveModal,
    private modal: NgbModal,
    private preferenceService: PreferenceDataService,
    private translate: TranslateService
  ) {
    this.isLoading = true;
    this.subscriptions$.push(
      this.preferenceService.getByName('map_areas', 'map').subscribe(
        (result) => {
          if (result.result) {
            try {
              this.areas = JSON.parse(result.result.fieldValue);
            } catch {}
          }
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        },
        () => {}
      )
    );
  }

  ngOnDestroy() {
    this.subscriptions$.forEach((s) => s.unsubscribe());
  }

  addCurrentArea() {
    const newItem = {
      name: this.translate.instant('map.areas.newArea'),
      bounds: this.mapBounds,
    };
    newItem['isNew'] = 1;
    this.areas.push(newItem);

    this.editIndex = this.areas.length - 1;
    this.editItem = newItem;
  }

  cancelEdit() {
    if (this.editItem['isNew'] === 1) {
      let emptyIndex = _.findIndex(this.areas, (area) => area['isNew'] === 1);
      while (emptyIndex > -1) {
        this.areas.splice(emptyIndex, 1);
        emptyIndex = _.findIndex(this.areas, (area) => area['isNew'] === 1);
      }
    }

    this.editIndex = -1;
    this.editItem = null;
  }

  delete(index) {
    this.modal
      .open(AreYouSureModalComponent, { backdrop: 'static' })
      .result.then(
        (deleteResult) => {
          if (deleteResult === true) {
            this.areas.splice(index, 1);
            this.subscriptions$.push(
              this.savePreference().subscribe(
                (saveResult) => {},
                () => {},
                () => {}
              )
            );
          }
        },
        () => {}
      )
      .catch(() => {})
      .finally(() => {});
  }

  dismiss() {
    this.activeModal.close(this.areas);
  }

  save() {
    if (!this.editItem) {
      return;
    }
    this.subscriptions$.push(
      this.savePreference().subscribe(
        (saveResult) => {},
        () => {},
        () => {}
      )
    );

    this.editIndex = -1;
    this.editItem = null;
  }

  savePreference() {
    return this.preferenceService.save({
      category: 'map',
      fieldType: 1,
      fieldValue: JSON.stringify(
        _.map(this.areas, (a) => ({ name: a.name, bounds: a.bounds }))
      ),
      id: -1,
      name: 'map_areas',
    });
  }

  setEdit(index, item) {
    this.editIndex = index;
    this.editItem = _.clone(item);
  }
}
