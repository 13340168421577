import { NgModule } from '@angular/core';

import { CoreModule } from '../../../core/core.module';
import { CustomDataServicesModule } from '../../../custom-data/services/custom-data-services.module';

import { CVVDataService } from './cvv-data.service';
import { CVVActivityLogicService } from './cvv-activity-logic.service';

@NgModule({
  imports: [CoreModule, CustomDataServicesModule],
  providers: [CVVDataService, CVVActivityLogicService],
})
export class CustomCVVServicesModule {}
