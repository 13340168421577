import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { CoreModule } from '../core/core.module';
import { MAP_ROUTES } from './map.routes';
import { MapComponentsModule } from './components/map-components.module';
import { MapServicesModule } from './services/map-services.module';
import { MapPipesModule } from './pipes/map-pipes.module';
import { MapCustomActionServicesModule } from './services/map-custom-action-services.module';

@NgModule({
  imports: [
    CoreModule,
    MapComponentsModule,
    MapCustomActionServicesModule,
    MapServicesModule,
    MapPipesModule,
    RouterModule.forChild(MAP_ROUTES),
  ],
})
export class MapModule {}
