import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@seahorse/common';
import { NauticalShipDataService, NauticalShipModel, ShipSearchModel } from '@seahorse/domain';
import { LayoutService } from '@seahorse/layout';
import { Subscription } from 'rxjs';
import { MapAreaModel } from '../../../map/models/map-interaction.model';
import { GroupService } from '../../../map/services/group.service';
import { PreferenceDataService } from '../../../preference/services/preference-data.service';

import { FilterListModel } from '../../../shared/models/filter-list.model';
import { MapInteractionService } from '../../services/map-interaction.service';
import { MapAreasEditModalComponent } from '../map-areas-edit-modal/map-areas-edit-modal.component';

@Component({
  selector: 'ca-nautical-map-header',
  templateUrl: './nautical-map-header.component.html',
  styleUrls: ['./nautical-map-header.component.scss'],
})
export class NauticalMapHeaderComponent implements OnDestroy {
  private subscriptions$ = Array<Subscription>();
  areas: MapAreaModel[] = [];
  showFilter = false;
  selectedArea = 'geen selectie';
  selectedList: FilterListModel = null;
  searchResults: NauticalShipModel[];
  searchText: string;
  sidebarOpen = false;

  expandedSidebar: string = null;
  isExpandedAreaActivity = false;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onSelectVessel: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public mapInteractionService: MapInteractionService,
    public groupService: GroupService,
    private modalService: NgbModal,
    private nauticalShipDataService: NauticalShipDataService,
    private preferenceService: PreferenceDataService,
    private _layoutService: LayoutService,
    private _translate: TranslateService,
    private _notification: NotificationService
  ) {
    this.selectedList = new FilterListModel();
    this.selectedList.id = 1;
    this.selectedList.name = 'Alle schepen';

    this.subscriptions$.push(
      this.preferenceService.getByName('map_areas', 'map').subscribe(
        (result) => {
          if (result.result) {
            try {
              this.areas = JSON.parse(result.result.fieldValue);
            } catch {
              //
            }
          }
        },
      )
    );

    this.expandedSidebar = null;

    this.subscriptions$.push(
      this._layoutService.sidebarToggle$.subscribe(
        (sidebarOpen: boolean) => {
          this.sidebarOpen = sidebarOpen;
        },
      )
    );
  }

  ngOnDestroy() {
    this.subscriptions$.forEach((s) => s.unsubscribe());
  }

  calculatedRoutes(route) {
    this.mapInteractionService.drawRoute(route[0]);
  }

  editList() {
    const modal = this.modalService.open(MapAreasEditModalComponent, {
      backdrop: 'static',
    });
    modal.componentInstance.mapBounds =
      this.mapInteractionService.map.getBounds();

    modal.result
      .then(
        (modalResult) => {
          if (modalResult) {
            this.areas = modalResult;
          }
        },
      )
      .catch((error) => {
        //
      });
  }

  setSelectedList(listName: string) {
    switch (listName) {
      case 'all-ships':
        this.selectedList.name = 'Alle schepen';
        break;
      case 'sail-schepen':
        this.selectedList.name = 'Schepen: Alle Sail 2020 schepen';
        break;
      case 'tall-ships-clippers':
        this.selectedList.name = 'Schepen: Tall ships & Clippers';
        break;
      case 'nederlands':
        this.selectedList.name = 'Schepen: Nederlandse schepen';
        break;
    }

    // this.mapInteractionService.loadMarkers(listName);
  }

  showArea(areaName: any) {
    if (areaName.name) {
      this.selectedArea = areaName.name;
      this.mapInteractionService.fitBounds(
        [
          [areaName.bounds._sw.lng, areaName.bounds._sw.lat],
          [areaName.bounds._ne.lng, areaName.bounds._ne.lat],
        ],
        { bearing: 0, pitch: 0 }
      );
    } else {
      switch (areaName) {
        case 'anzk':
          this.selectedArea = 'ANZK-gebied';
          this.mapInteractionService.fitBounds(
            [
              [4.500209, 52.405082],
              [5.080287, 52.425688],
            ],
            { bearing: 15, pitch: 0 }
          );
          break;
        case 'europa':
          this.selectedArea = 'Europa';
          this.mapInteractionService.fitBounds(
            [
              [-13.161044, 33.954551],
              [38.535501, 60.429188],
            ],
            { bearing: 0, pitch: 0 }
          );
          break;
        case 'nederland':
          this.selectedArea = 'Nederland';
          this.mapInteractionService.fitBounds(
            [
              [2.506026, 51.163872],
              [7.695967, 53.572638],
            ],
            { bearing: 0, pitch: 0 }
          );
          break;
        case 'presail':
          this.selectedArea = 'Pre-SAIL terrein';
          this.mapInteractionService.fitBounds(
            [
              [4.53152, 52.450887],
              [4.687526, 52.479459],
            ],
            { bearing: 0, pitch: 0 }
          );
          break;
        case 'sail':
          this.selectedArea = 'SAIL terrein';
          this.mapInteractionService.fitBounds(
            [
              [4.911036, 52.375318],
              [4.947119, 52.384308],
            ],
            { bearing: 15, pitch: 0 }
          );
          break;
        case 'noordersluis':
          this.selectedArea = 'Noordersluis';
          this.mapInteractionService.fitBounds(
            [
              [4.603734, 52.466764],
              [4.612785, 52.468464],
            ],
            { bearing: 0, pitch: 0 }
          );
          break;
        case 'middensluis':
          this.selectedArea = 'Middensluis';
          this.mapInteractionService.fitBounds(
            [
              [4.596267, 52.465275],
              [4.601329, 52.466177],
            ],
            { bearing: 0, pitch: 0 }
          );
          break;
        case 'zuidersluis':
          this.selectedArea = 'Zuidersluis';
          this.mapInteractionService.fitBounds(
            [
              [4.589982, 52.463631],
              [4.593253, 52.464369],
            ],
            { bearing: 0, pitch: 0 }
          );
          break;
        case 'piersluis':
          this.selectedArea = 'Pieren tot sluis';
          this.mapInteractionService.fitBounds(
            [
              [4.5278, 52.456386],
              [4.601922, 52.472764],
            ],
            { bearing: 0, pitch: 0 }
          );
          break;
        case 'sluiszijkanaal':
          this.selectedArea = 'Sluis tot Zijkanaal A';
          this.mapInteractionService.fitBounds(
            [
              [4.610021, 52.460616],
              [4.66407, 52.470526],
            ],
            { bearing: 0, pitch: 0 }
          );
          break;
        // Harbours
        case 'afrikahaven':
          this.selectedArea = 'Afrikahaven';
          this.mapInteractionService.fitBounds(
            [
              [4.73296165, 52.40729495],
              [4.75908851, 52.43449309],
            ],
            { bearing: 0, pitch: 0 }
          );
          break;
        case 'amerikahaven':
          this.selectedArea = 'Amerikahaven';
          this.mapInteractionService.fitBounds(
            [
              [4.75946617, 52.39859806],
              [4.80013275, 52.4308509],
            ],
            { bearing: 0, pitch: 0 }
          );
          break;
        case 'beverwijk':
          this.selectedArea = 'Beverwijk';
          this.mapInteractionService.fitBounds(
            [
              [4.654255, 52.458185],
              [4.670258, 52.478619],
            ],
            { bearing: 0, pitch: 0 }
          );
          break;
        case 'ijmuiden':
          this.selectedArea = 'IJmuiden';
          this.mapInteractionService.fitBounds(
            [
              [4.567242, 52.455267],
              [4.598048, 52.464377],
            ],
            { bearing: 0, pitch: 0 }
          );
          break;
        case 'mercuriushaven':
          this.selectedArea = 'Mercuriushaven';
          this.mapInteractionService.fitBounds(
            [
              [4.85278131, 52.39494661],
              [4.87732889, 52.40652001],
            ],
            { bearing: 0, pitch: 0 }
          );
          break;
        case 'petroleumhaven':
          this.selectedArea = 'Petroleumhaven';
          this.mapInteractionService.fitBounds(
            [
              [4.84957124, 52.40815366],
              [4.86222267, 52.41760877],
            ],
            { bearing: 0, pitch: 0 }
          );
          break;
        case 'tata':
          this.selectedArea = 'Tata';
          this.mapInteractionService.fitBounds(
            [
              [4.584991, 52.467765],
              [4.62637, 52.475681],
            ],
            { bearing: 0, pitch: 0 }
          );
          break;
        case 'velsennoord':
          this.selectedArea = 'Velsen Noord';
          this.mapInteractionService.fitBounds(
            [
              [4.627889, 52.463378],
              [4.653472, 52.469721],
            ],
            { bearing: 0, pitch: 0 }
          );
          break;
        case 'westhaven':
          this.selectedArea = 'Westhaven';
          this.mapInteractionService.fitBounds(
            [
              [4.80435563, 52.39388862],
              [4.83992386, 52.42332483],
            ],
            { bearing: 0, pitch: 0 }
          );
          break;
      }
    }
  }

  toggleSidebar(target: string) {
    this.expandedSidebar = this.expandedSidebar === target ? null : target;

    if (target === 'area-activity') {
      this.isExpandedAreaActivity = !this.isExpandedAreaActivity;
      this.mapInteractionService.isAreaActivityOpen =
        this.isExpandedAreaActivity;
    }
  }

  openVessel(ship: NauticalShipModel) {
    if (!this.searchResults || this.searchResults.length > 0) {
      this.searchResults = null;
    }
    this.onSelectVessel.emit(ship);

    this.searchText = null;
  }

  search() {
    if (!this.searchText) {
      return;
    }

    this.subscriptions$.push(
      this.nauticalShipDataService
        .find(new ShipSearchModel(this.searchText), 0, 20, null, true)
        .subscribe(
          (response) => {
            if (response.hasResult) {
              this.searchResults = response.result.map((x) => {
                const identifiers = new Array<string>();
                if (x.imo && !/^\s*$/.test(x.imo)) {
                  identifiers.push(
                    `${this._translate.instant('nautical.ship.model.imo')}: ${
                      x.imo
                    }`
                  );
                } else if (x.eni && !/^\s*$/.test(x.eni)) {
                  identifiers.push(
                    `${this._translate.instant('nautical.ship.model.eni')}: ${
                      x.eni
                    }`
                  );
                }
                if (x.callSign && !/^\s*$/.test(x.callSign)) {
                  identifiers.push(
                    `${this._translate.instant(
                      'nautical.ship.model.callSign'
                    )}: ${x.callSign}`
                  );
                }
                if (x.mmsi && !/^\s*$/.test(x.mmsi)) {
                  identifiers.push(
                    `${this._translate.instant('nautical.ship.model.mmsi')}: ${
                      x.mmsi
                    }`
                  );
                }

                return Object.assign({}, x, {
                  displayName: x.name.toUpperCase(),
                  displayIdentifiers: identifiers.length
                    ? ` (${identifiers.join(', ')})`
                    : undefined,
                });
              });
            } else {
              this._notification.displayErrorNotification(response.messages);
            }
          },
          () => this._notification.displayErrorNotification(),
          () => {}
        )
    );
  }

  resetSearch() {
    this.searchResults = null;
    this.searchText = null;
  }
}
