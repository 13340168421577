import {
  Component,
  Input,
  Output,
  EventEmitter,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'underscore';
import { ResultWrapper } from '@seahorse/common';
import { NotificationService } from '@seahorse/common';
import { OrderRegistrationsConstant } from '../../../order-registrations/constants/order-registrations.constant';
import { OrderRegistrationCrewMemberModel } from '../../../order-registrations/models/order-registration-crew-member.model';
import { forkJoin } from 'rxjs';
import {
  CustomDataContentService,
  ObjectDefinitionModel,
} from '@seahorse/domain';
import {
  CustomDataFormService,
  FormComponent,
  FormModel,
} from '@seahorse/temp';

@Component({ templateUrl: 'crew-factory-dialog.component.html' })
export class CrewFactoryDialogComponent {
  @ViewChildren(FormComponent) crewMemberForms: QueryList<FormComponent>;
  selectedMember?: number = null;

  @Input() title?: string;
  @Input() skipEdit?: boolean;
  @Input() crewMembers?: OrderRegistrationCrewMemberModel[];

  @Input() objectDefinition: ObjectDefinitionModel;
  @Input() set crewMemberIds(ids: OrderRegistrationCrewMemberModel['__Id'][]) {
    if (ids && ids.length) {
      this._crewMemberIds = _.clone(ids);
      this.crewMembersLoading = true;

      this.customData
        .getCustomerDataByIds(
          OrderRegistrationsConstant.OBJECTKEYCREWMEMBER,
          ids
        )
        .subscribe(
          (r: ResultWrapper<OrderRegistrationCrewMemberModel[]>) => {
            if (r.hasResult && r.result.length) {
              r.result.forEach((crewMember) => {
                const formModel = this._customDataFormService.getFormModel(
                  this.objectDefinition,
                  this.objectDefinition.objectFieldDefinitions
                    .filter((field) => !field.isReadOnly)
                    .map((field) => field.systemCode),
                  crewMember
                );

                this.formModels.push(formModel);
              });
            } else {
              this.notification.showError(
                _.pluck(r.messages, 'message').join('\n'),
                this.translate.instant('shared.terms.failed')
              );
            }
          },

          (e) => {
            this.notification.showError(
              _.pluck(e.error.messages, 'message').join('\n'),
              this.translate.instant('shared.terms.failed')
            );
          },

          () => (this.crewMembersLoading = false)
        );
    } else {
      this._crewMemberIds = [];
    }
  }
  get crewMemberIds(): OrderRegistrationCrewMemberModel['__Id'][] {
    return this._crewMemberIds;
  }

  @Output() onConfirm: EventEmitter<{
    crewMembers: OrderRegistrationCrewMemberModel[];
  }>;

  private _crewMemberIds: OrderRegistrationCrewMemberModel['__Id'][];

  crewMembersLoading: boolean;
  crewMember: OrderRegistrationCrewMemberModel;
  crewMembersRemoved: OrderRegistrationCrewMemberModel[] = [];
  errors: string[] = [];

  formModels: FormModel[] = [];

  constructor(
    private activeModal: NgbActiveModal,
    private translate: TranslateService,
    private notification: NotificationService,
    private customData: CustomDataContentService,
    private _customDataFormService: CustomDataFormService
  ) {
    this.title = this.translate.instant(
      'customModules.orderRegistrations.crewMember.factoryDialogTitle'
    );
    this.skipEdit = false;
    this.crewMembers = [new OrderRegistrationCrewMemberModel()];
    this.crewMemberIds = null;
    this.onConfirm = new EventEmitter<{
      crewMembers: OrderRegistrationCrewMemberModel[];
    }>();
    this._crewMemberIds = null;
    this.crewMembersLoading = false;
    this.crewMember = new OrderRegistrationCrewMemberModel();
  }

  // setNull(attribute: string) {
  //   if(this.crewMember && this.crewMember[attribute])
  //     this.crewMember[attribute] = null;
  // }

  // openCrewMemberFactory(crewMember?: OrderRegistrationCrewMemberModel) {
  //   this.crewMemberCurrent  = crewMember ? crewMember : null;
  //   this.crewMember         = crewMember ? _.clone(crewMember) : new OrderRegistrationCrewMemberModel();
  // }

  addCrewMember() {
    const crewMember = new OrderRegistrationCrewMemberModel();
    crewMember['expanded'] = true;

    const formModel = this._customDataFormService.getFormModel(
      this.objectDefinition,
      this.objectDefinition.objectFieldDefinitions
        .filter((field) => !field.isReadOnly)
        .map((field) => field.systemCode),
      crewMember
    );

    this.formModels.push(formModel);
  }

  deleteCrewMember(formModel: FormModel) {
    if (formModel.value.__Id) {
      this.crewMembersRemoved.push(formModel.value);
    }

    this.formModels.splice(this.formModels.indexOf(formModel), 1);

    //   this.customData.deleteCustomerData(OrderRegistrationsConstant.OBJECTKEYCREWMEMBER, crewMember.__Id).subscribe(
    //     (r: ResultWrapper<OrderRegistrationCrewMemberModel>) => {
    //       if(r.hasResult) {
    //         this.crewMembers = _.reject(this.crewMembers, x => x.__Id == r.result.__Id);

    //       } else {
    //         this.notification.showError(
    //           _.pluck(r.messages, 'message').join('\n'),
    //           this.translate.instant('shared.terms.failed')
    //         );
    //       }
    //     },

    //     e => {
    //       this.notification.showError(
    //         _.pluck(e.error.messages, 'message').join('\n'),
    //         this.translate.instant('shared.terms.failed')
    //       );
    //     },

    //     () => this.crewMembersLoading = false

    //   );

    // } else {
    //   this.crewMembers = _.reject(this.crewMembers, x => x == crewMember);
    // }
  }

  // saveForm() {
  //   if(this.crewMember && this.crewMember.name) {
  //     if(this.crewMemberCurrent) {
  //       this.crewMembers[_.indexOf(this.crewMembers, this.crewMemberCurrent)] = this.crewMember;

  //     } else {
  //       this.crewMembers.push(this.crewMember);

  //       this.crewMemberCurrent = this.crewMember;
  //     }
  //   }
  // }

  confirm() {
    this.errors = [];
    this.crewMembers = [];

    const crewMemberFormsArray = this.crewMemberForms.toArray();

    for (let i = 0; i < crewMemberFormsArray.length; i++) {
      const form = crewMemberFormsArray[i];
      const value = form.value;

      if (!value.name || value.name.length === 0) {
        this.errors.push(
          'Crew member ' +
            (i + 1) +
            ': ' +
            this.translate.instant(
              'customModules.orderRegistrations.crewMember.errors.nameMissing'
            )
        );
      }

      if (this.errors.length === 0) {
        this.crewMembers.push(value);
      }
    }

    if (this.errors.length > 0) {
      return;
    }

    if (this.crewMembers.length) {
      if (this.skipEdit) {
        this.onConfirm.emit({ crewMembers: this.crewMembers });
        this.activeModal.close(true);
      } else {
        this.crewMembersLoading = true;
        const successful: ResultWrapper<OrderRegistrationCrewMemberModel>[] =
          [];
        let messages: ResultWrapper<any>['messages'][] = [];
        let unsuccessful = 0;

        // Delete the removed members
        const deleteResult = [];
        _.each(this.crewMembersRemoved, (x) => {
          if (x.__Id) {
            deleteResult.push(
              this.customData.deleteCustomerData(
                OrderRegistrationsConstant.OBJECTKEYCREWMEMBER,
                x.__Id
              )
            );
          }
        });
        if (deleteResult.length > 0) {
          forkJoin(deleteResult).subscribe((deleteResults) => {});
        }

        // Add or update the remaining list
        _.forEach(this.crewMembers, (x) => {
          if (x.__Id) {
            this.customData
              .updateCustomerData(
                OrderRegistrationsConstant.OBJECTKEYCREWMEMBER,
                x.__Id,
                x
              )
              .subscribe(
                (r: ResultWrapper<OrderRegistrationCrewMemberModel>) => {
                  if (r.hasResult) {
                    successful.push(r);
                  } else {
                    messages = messages.concat(r.messages);

                    unsuccessful++;
                  }
                },

                (e) => {
                  messages = messages.concat(e.error.messages);

                  unsuccessful++;
                },

                () => {
                  if (
                    successful.length + unsuccessful ==
                    this.crewMembers.length
                  ) {
                    this.crewMembersLoading = false;

                    this.onConfirm.emit({
                      crewMembers: _.map(successful, 'result'),
                    });
                    this.activeModal.close();

                    if (this.crewMembers.length > 1) {
                      if (successful.length) {
                        const successfulMessage = `${successful.length}/${
                          this.crewMembers.length
                        } ${this.translate.instant(
                          'customModules.orderRegistrations.crewMember.crewMembersUpdated'
                        )}`;

                        if (unsuccessful) {
                          this.notification.showWarning(
                            successfulMessage,
                            this.translate.instant('shared.terms.success')
                          );
                        } else {
                          this.notification.showSuccess(
                            successfulMessage,
                            this.translate.instant('shared.terms.success')
                          );
                        }
                      }
                    } else {
                      this.notification.showSuccess(
                        this.translate.instant(
                          'customModules.orderRegistrations.crewMember.crewMembersUpdated'
                        ),
                        this.translate.instant('shared.terms.success')
                      );
                    }

                    if (unsuccessful) {
                      this.notification.showError(
                        _.pluck(messages, 'message').join('\n'),
                        this.translate.instant('shared.terms.failed')
                      );
                    }
                  }
                }
              );
          } else {
            this.customData
              .addCustomerData(
                OrderRegistrationsConstant.OBJECTKEYCREWMEMBER,
                x
              )
              .subscribe(
                (r: ResultWrapper<OrderRegistrationCrewMemberModel>) => {
                  if (r.hasResult) {
                    successful.push(r);
                  } else {
                    messages = messages.concat(r.messages);

                    unsuccessful++;
                  }
                },

                (e) => {
                  messages = messages.concat(e.error.messages);

                  unsuccessful++;
                },

                () => {
                  if (
                    successful.length + unsuccessful ==
                    this.crewMembers.length
                  ) {
                    this.crewMembersLoading = false;

                    this.onConfirm.emit({
                      crewMembers: _.map(successful, 'result'),
                    });
                    this.activeModal.close();

                    if (this.crewMembers.length > 1) {
                      if (successful.length) {
                        const successfulMessage = `${successful.length}/${
                          this.crewMembers.length
                        } ${this.translate.instant(
                          'customModules.orderRegistrations.crewMember.crewMembersUpdated'
                        )}`;

                        if (unsuccessful) {
                          this.notification.showWarning(
                            successfulMessage,
                            this.translate.instant('shared.terms.success')
                          );
                        } else {
                          this.notification.showSuccess(
                            successfulMessage,
                            this.translate.instant('shared.terms.success')
                          );
                        }
                      }
                    } else {
                      this.notification.showSuccess(
                        this.translate.instant(
                          'customModules.orderRegistrations.crewMember.crewMembersUpdated'
                        ),
                        this.translate.instant('shared.terms.success')
                      );
                    }

                    if (unsuccessful) {
                      this.notification.showError(
                        _.pluck(messages, 'message').join('\n'),
                        this.translate.instant('shared.terms.failed')
                      );
                    }
                  }
                }
              );
          }
        });
      }
    } else {
      if (this.crewMembersRemoved.length) {
        const deleteResult = [];

        _.each(this.crewMembersRemoved, (x) => {
          if (x.__Id) {
            deleteResult.push(
              this.customData.deleteCustomerData(
                OrderRegistrationsConstant.OBJECTKEYCREWMEMBER,
                x.__Id
              )
            );
          }

          if (this.crewMembersRemoved.length == deleteResult.length) {
            forkJoin(deleteResult).subscribe(() => this.activeModal.close());
            this.notification.showSuccess(
              this.translate.instant(
                'customModules.orderRegistrations.crewMember.crewMembersUpdated'
              ),
              this.translate.instant('shared.terms.success')
            );

            this.onConfirm.emit({ crewMembers: null });
          }
        });
      }
    }
  }
}
