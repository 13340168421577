<table class="table table-clickable table-fixed-header">
  <thead>
    <tr>
      <th>{{ 'nautical.ship.model.name' | translate }}</th>
      <th>{{ 'shared.terms.name' | translate }}</th>
      <th>{{ 'nautical.ship.model.flagCode' | translate }}</th>
      <th>{{ 'nautical.ship.model.imo' | translate }}</th>
      <th>{{ 'nautical.ship.model.callSign' | translate }}</th>
    </tr>
  </thead>
  <tbody>
    <tr
      *ngFor="let ship of ships"
      (click)="selectShip(ship)"
      [ngClass]="{ selected: selectedShip && selectedShip.id === ship.id }"
    >
      <td>{{ ship.name | uppercase }}</td>
      <td>{{ ship.shipType.name }}</td>
      <td>{{ ship.flagCode | uppercase }}</td>
      <td>{{ ship.imo }}</td>
      <td>{{ ship.callSign }}</td>
    </tr>
  </tbody>
</table>
