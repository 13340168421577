<div class="custom-control custom-checkbox d-flex mr-2">
  <input
    id="filterPurchaseInvoicesCheckbox"
    (change)="onFilterChange()"
    type="checkbox"
    [checked]="filterPurchaseInvoices"
    class="custom-control-input"
  />
  <label
    for="filterPurchaseInvoicesCheckbox"
    class="custom-control-label w-100"
  >
    {{ 'customModules.expenses.model.withoutPurchaseInvoice' | translate }}
  </label>
</div>
