import {
  Component,
  EventEmitter,
  OnInit,
  OnDestroy,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as turf from '@turf/turf';
import { ETASData } from '../../../map/models/eta.constants';
import { ETAModel, EtaPointModel } from '../../../map/models/eta.model';
import { FleetGroup } from '../../../map/models/fleet-group.model';
import { NauticalShipModel } from '@seahorse/domain';
import { NauticalShipDataService } from '@seahorse/domain';
import { NauticalVisitOverviewModel } from '@seahorse/domain';
import { NauticalVisitDataService } from '@seahorse/domain';
import * as mapboxgl from 'mapbox-gl';
import * as moment from 'moment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ca-map-popup',
  templateUrl: './map-popup.component.html',
  styleUrls: ['./map-popup.component.scss'],
})
export class MapPopupComponent implements OnInit, OnDestroy {
  private subscriptions$ = Array<Subscription>();
  isLoading = false;
  activeVisit: NauticalVisitOverviewModel = null;
  marker: any;
  ship: NauticalShipModel = null;
  attributes: any[] = [];
  visitData: any[] = [];
  groups: FleetGroup[] = [];

  etas: EtaPointModel[] = [] as EtaPointModel[];

  linestring = {
    type: 'Feature',
    geometry: {
      type: 'LineString',
      coordinates: [],
    },
  };

  constructor(
    private nauticalShipDataService: NauticalShipDataService,
    private nauticalVisitDataService: NauticalVisitDataService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.setAttributes();
    this.getShip(
      this.marker.options.aisInfo.imo,
      this.marker.options.aisInfo.mmsi
    );
  }

  ngOnDestroy() {
    this.subscriptions$.forEach((s) => s.unsubscribe());
  }

  getShip(imo: string, mmsi: string) {
    this.isLoading = true;
    this.visitData = [];

    this.subscriptions$.push(
      this.nauticalShipDataService.getByImo(imo).subscribe(
        (response) => {
          if (response.result) {
            this.getVisitData(response.result);
          } else {
            this.subscriptions$.push(
              this.nauticalShipDataService.getByMmsi(mmsi).subscribe(
                (getByMmsiResponse) => {
                  if (getByMmsiResponse.result) {
                    this.getVisitData(getByMmsiResponse.result);
                  } else {
                    this.isLoading = false;
                  }
                },
                (e) => {
                  this.isLoading = false;
                },
                () => {}
              )
            );
          }
        },
        (e) => {
          this.isLoading = false;
        },
        () => {}
      )
    );
  }

  getVisitData(ship: NauticalShipModel) {
    this.ship = ship;
    this.subscriptions$.push(
      this.nauticalVisitDataService
        .getListOverviewForShip(ship.id, 'expectedinport')
        .subscribe(
          (res) => {
            if (res.result && res.result.length > 0) {
              this.activeVisit = res.result[0];

              if (this.activeVisit.ata) {
                this.visitData.push({
                  fieldName:
                    this.translateService.instant('nautical.terms.ata'),
                  fieldValue: moment(this.activeVisit.ata).format(
                    'DD-MM-YYYY HH:mm'
                  ),
                });
              } else if (this.activeVisit.eta) {
                this.visitData.push({
                  fieldName:
                    this.translateService.instant('nautical.terms.eta'),
                  fieldValue: moment(this.activeVisit.eta).format(
                    'DD-MM-YYYY HH:mm'
                  ),
                });
              }

              if (this.activeVisit.atd) {
                this.visitData.push({
                  fieldName:
                    this.translateService.instant('nautical.terms.atd'),
                  fieldValue: moment(this.activeVisit.atd).format(
                    'DD-MM-YYYY HH:mm'
                  ),
                });
              } else if (this.activeVisit.etd) {
                this.visitData.push({
                  fieldName:
                    this.translateService.instant('nautical.terms.etd'),
                  fieldValue: moment(this.activeVisit.etd).format(
                    'DD-MM-YYYY HH:mm'
                  ),
                });
              }

              if (this.activeVisit.referenceNumber) {
                this.visitData.push({
                  fieldName: this.translateService.instant(
                    'nautical.visit.model.crn'
                  ),
                  fieldValue: this.activeVisit.referenceNumber,
                });
              }

              if (this.activeVisit.currentMovement) {
                if (
                  this.activeVisit.currentMovement.portWayPointTo &&
                  this.activeVisit.currentMovement.portWayPointTo.code
                ) {
                  this.visitData.push({
                    fieldName: this.translateService.instant(
                      'nautical.terms.currentBerth'
                    ),
                    fieldValue:
                      this.activeVisit.currentMovement.portWayPointTo.code,
                  });
                } else if (
                  this.activeVisit.currentMovement.portWayPointFrom &&
                  this.activeVisit.currentMovement.portWayPointFrom.code
                ) {
                  this.visitData.push({
                    fieldName: this.translateService.instant(
                      'nautical.terms.currentBerth'
                    ),
                    fieldValue:
                      this.activeVisit.currentMovement.portWayPointFrom.code,
                  });
                }
              }
            }
          },
          (e) => {
            this.isLoading = false;
          },
          () => {
            this.isLoading = false;
          }
        )
    );
  }

  setAttributes() {
    const aisInfo = this.marker.options.aisInfo;

    if (this.marker.options.shipType) {
      this.attributes.push({
        fieldName: this.translateService.instant(
          'nautical.ship.shipType.shipType'
        ),
        fieldValue: this.marker.options.shipType,
      });
    }

    if (aisInfo.imo) {
      this.attributes.push({
        fieldName: this.translateService.instant('nautical.ship.model.imo'),
        fieldValue: aisInfo.imo,
      });
    }

    if (aisInfo.mmsi) {
      this.attributes.push({
        fieldName: this.translateService.instant('nautical.ship.model.mmsi'),
        fieldValue: aisInfo.mmsi,
      });
    }

    if (aisInfo.heading) {
      this.attributes.push({
        fieldName: this.translateService.instant('nautical.ship.heading'),
        fieldValue: aisInfo.heading + '&deg;',
      });
    }

    if (aisInfo.destination) {
      this.attributes.push({
        fieldName: this.translateService.instant('nautical.ship.destination'),
        fieldValue: aisInfo.destination,
      });
    }

    if (aisInfo.speed != null) {
      this.attributes.push({
        fieldName: this.translateService.instant('nautical.ship.speed'),
        fieldValue: aisInfo.speed + ' kts',
      });
    }

    if (aisInfo.speed != null && aisInfo.speed >= 2) {
      this.calculateEtas(aisInfo);
    }
  }

  calculateEtas(aisInfo) {
    this.etas = [];
    this.linestring.geometry.coordinates = [];

    const etasData = ETASData as ETAModel[];

    etasData.forEach((data) => {
      const boundingBox = new mapboxgl.LngLatBounds(
        new mapboxgl.LngLat(
          data.boundingBox.topLeft.longitude,
          data.boundingBox.topLeft.latitude
        ),
        new mapboxgl.LngLat(
          data.boundingBox.bottomRight.longitude,
          data.boundingBox.bottomRight.latitude
        )
      );

      const currentPoint = new mapboxgl.LngLat(
        aisInfo.longitude,
        aisInfo.latitude
      );

      if (boundingBox.contains(currentPoint)) {
        this.linestring.geometry.coordinates.push([
          currentPoint.lng,
          currentPoint.lat,
        ]);

        data.points.forEach((point) => {
          this.linestring.geometry.coordinates.push([
            point.coordinate.longitude,
            point.coordinate.latitude,
          ]);

          const eta = this.calculateEta(aisInfo);

          if (eta) {
            point.eta = eta;
            this.etas.push(point);
          }

          this.linestring.geometry.coordinates.pop();
        });
      }
    });
  }

  calculateEta(aisInfo): string {
    const distance = +turf.lineDistance(this.linestring.geometry);
    let speed = aisInfo.speed;
    // 6 = min speed, 16 = avg speed
    if (distance < 50 && (speed < 6 || speed > 16)) {
      speed = 6;
    } else if (distance > 50 && speed < 16) {
      speed = 16;
    }
    speed = speed * 1.852;
    const timeInSeconds = (distance / speed) * 3600;
    const eta = new Date();
    eta.setSeconds(eta.getSeconds() + timeInSeconds);

    const currentTime = new Date();
    if (eta < currentTime) {
      return null;
    }

    return moment(eta).format('DD-MM-YYYY hh:mm');
  }
}
