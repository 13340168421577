import { Guid } from 'guid-typescript';
import { BaseModel, ValidationResult } from '@seahorse/common';

export class FileModel extends BaseModel {
  name: string;
  description: string;
  size: number;
  isPrivate: boolean;
  organisationId: Guid;
  createdById: Guid;
  createdOn: Date;
  fileType: string;
  filePurpose: string;
  mimeType: string;
  content: any;
  icon: string;
  objectFile: ObjectFile[]; // Should be removed later
  objectFiles: ObjectFile[];
  hasLinkedObjects?: boolean;
  file: File;
  compress?: boolean;
  inbox: boolean; // ui only
  isPdf?: boolean; // ui only

  static validate(model: FileModel): boolean {
    if (!model.filePurpose) {
      return false;
    }
    return true;
  }

  static fromHtmlInput(file: File): FileModel {
    const object: FileModel = new FileModel();
    object.file = file;
    object.name = file.name;
    object.fileType = file.type;
    object.size = file.size;
    object.description = file.name;

    const regex = /(?:\.([^.]+))?$/;
    let purpose: string = null;
    let type: string = null;

    if (file.type && file.type !== '') {
      type = file.type.toLowerCase();
    } else if (regex.exec(file.name)) {
      type = regex.exec(file.name)[1].toLowerCase();
    }

    object.isPdf = type === 'application/pdf';

    if (type) {
      switch (type) {
        case 'application/pdf': {
          purpose = 'PDF';
          break;
        }

        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
          purpose = 'Word document';
          break;
        }

        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
          purpose = 'Excel document';
          break;
        }

        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation': {
          purpose = 'PowerPoint document';
          break;
        }

        case 'text/plain': {
          purpose = 'Text file';
          break;
        }

        case 'application/x-zip-compressed': {
          purpose = 'ZIP';
          break;
        }

        case 'msg': {
          purpose = 'E-mail';
          break;
        }
      }

      object.filePurpose = purpose ? purpose : type;
    }

    return object;
  }

  validate(errorPrefix?: string): ValidationResult {
    throw new Error('Method not implemented.');
  }
}

export interface ObjectFile {
  objectId: string;
  objectType: string;
}

export class FileUpdateModel extends BaseModel {
  name: string;
  description: string;
  fileType: string;
  filePurpose: string;
  isPrivate: boolean;

  static fromFileModel(file: FileModel): FileUpdateModel {
    const fileUpdateModel = new FileUpdateModel();
    fileUpdateModel.id = file.id;
    fileUpdateModel.name = file.name;
    fileUpdateModel.description = file.description;
    fileUpdateModel.fileType = file.fileType;
    fileUpdateModel.filePurpose = file.filePurpose;
    fileUpdateModel.isPrivate = file.isPrivate;
    return fileUpdateModel;
  }

  static validate(model: FileUpdateModel): boolean {
    if (!model.filePurpose || !model.name || !model.fileType) {
      return false;
    }
    return true;
  }

  validate(errorPrefix?: string): ValidationResult {
    throw new Error('Method not implemented.');
  }
}
