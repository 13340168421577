import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EventModel } from '../../../../../shared/models/event.model';

import { TaskModel, TaskStatus, TaskType } from '../../models/task.model';

@Component({
  selector: 'task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss'],
})
export class TaskListComponent {
  @Input() tasks: TaskModel[];
  @Input() selected: TaskModel;

  @Output() onEvent: EventEmitter<EventModel>;

  TaskStatus = TaskStatus;
  TaskType = TaskType;

  constructor() {
    this.tasks = [];
    this.selected = null;
    this.onEvent = new EventEmitter<EventModel>();
  }
}
