import { ValidationResult } from '@seahorse/common';
import { CustomDataSearchFieldModel } from '@seahorse/domain';

export class CustomDataReportDefinitionModel {
  id: number;
  name: string;
  customDataObjectSystemCode: string;
  reportConfiguration: CustomDataReportConfigurationModel;

  static validate(input: CustomDataReportDefinitionModel): ValidationResult {
    const result: ValidationResult = {
      errors: [],
      isValid: true,
    };

    if (!input.name || input.name === '') {
      result.errors.push('noName');
    }

    if (
      !input.customDataObjectSystemCode ||
      input.customDataObjectSystemCode === ''
    ) {
      result.errors.push('noSystemCode');
    }

    if (input.reportConfiguration) {
      if (
        !input.reportConfiguration.fields ||
        input.reportConfiguration.fields.length === 0
      ) {
        result.errors.push('noFilterFields');
      }

      if (
        !input.reportConfiguration.fieldDisplay ||
        input.reportConfiguration.fieldDisplay.length === 0
      ) {
        result.errors.push('noDisplayFields');
      }
    }

    if (result.errors.length > 0) {
      result.isValid = false;
    }

    return result;
  }
}

export class CustomDataReportConfigurationModel {
  fieldDisplay: CustomDataReportConfigurationDisplayFieldModel[];
  fields: CustomDataSearchFieldModel[];
  dateFrom?: string;
  dateUntil?: string;
  dateRangeField?: string;
  additionalFields: string[];
  groupBy?: string[];
  numberOfExportRows?: number;
  sortField?: string;
  sortAscending?: boolean;
}

export class CustomDataReportConfigurationDisplayFieldModel {
  displayName: string;
  fieldName: string;
  sortOrder: number;
  fieldFormat?: string;
}
