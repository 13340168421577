<div class="modal-header align-items-center">
  <h1 class="modal-title">{{ title }}</h1>
  <button closeModal type="button" class="btn btn-link text-dark">
    <i class="fa-solid fa-times fa-sm fa-fw"></i>
  </button>
</div>
<div class="modal-body">
  <div class="form-row">
    <!-- Ship picker -->
    <div
      *ngIf="
        nauticalShipPickerMode === shipPickerMode.Required ||
        nauticalShipPickerMode === shipPickerMode.Editable
      "
      class="form-group col-12"
    >
      <label
        class="d-flex justify-content-between"
        for="typeahead-nauticalShip"
      >
        <b
          [ngClass]="{
            required: nauticalShipPickerMode === shipPickerMode.Required
          }"
          >{{ 'nautical.visit.pickerDialog.selectNauticalShip' | translate }}</b
        >
        <span *ngIf="nauticalShipLoading || nauticalShipsLoading">
          <i class="fa-regular fa-spinner fa-spin fa-fw"></i>
        </span>
      </label>
      <div class="input-group">
        <input
          [(ngModel)]="nauticalShip"
          [class.is-invalid]="
            nauticalShipLoadingFailed || nauticalShipsLoadingFailed
          "
          [ngbTypeahead]="searchNauticalShip"
          [inputFormatter]="nauticalShipFormatter"
          [resultFormatter]="nauticalShipsFormatter"
          [editable]="false"
          [placeholder]="
            ('nautical.visit.pickerDialog.searchNauticalShip' | translate) +
            '...'
          "
          [required]="nauticalShipPickerMode === shipPickerMode.Required"
          id="typeahead-nauticalShip"
          name="nauticalShip"
          type="text"
          class="form-control"
        />
        <div class="input-group-append">
          <button
            [disabled]="nauticalShipLoading || nauticalShipsLoading"
            class="btn btn-outline-secondary"
            (click)="setNull('nauticalShip')"
            type="button"
          >
            <i class="fa-solid fa-trash fa-fw"></i>
          </button>
        </div>
      </div>
    </div>

    <!-- Ship readonly mode -->
    <div
      *ngIf="nauticalShipPickerMode === shipPickerMode.ReadOnly"
      class="form-group col-12"
    >
      <b>{{ 'nautical.ship.title' | translate }}</b>
      <div>
        <ng-container
          *ngIf="nauticalShip; then showShipName; else noShipName"
        ></ng-container>
        <ng-template #showShipName>{{
          nauticalShip.name | uppercase
        }}</ng-template>
        <ng-template #noShipName>{{
          'nautical.visit.pickerDialog.noShipSet' | translate
        }}</ng-template>
      </div>
    </div>

    <!-- Ship hideShip mode, but no ship is set, so error -->
    <div
      *ngIf="
        nauticalShipPickerMode === shipPickerMode.HideShip &&
        ((!nauticalShip && !nauticalShipLoading) ||
          nauticalShipLoadingFailed === true)
      "
      class="form-group col-12"
    >
      <b>{{ 'nautical.ship.title' | translate }}</b>
      <div>
        <ng-template #noShipName>{{
          'nautical.visit.pickerDialog.noShipSet' | translate
        }}</ng-template>
      </div>
    </div>

    <!-- Visit picker -->
    <div class="form-group col-12">
      <label
        class="d-flex justify-content-between"
        for="typeahead-nauticalVisit"
      >
        <b [ngClass]="{ required: nauticalVisitRequired }">{{
          'nautical.visit.pickerDialog.selectNauticalVisit' | translate
        }}</b>
        <div>
          <em *ngIf="!nauticalVisitsLoading && nauticalShip">
            {{ nauticalVisitsCount }}
            <ng-container
              *ngIf="
                nauticalVisitsCount == 1;
                then nauticalVisitsSingularFound;
                else nauticalVisitsMultipleFound
              "
            ></ng-container>
            <ng-template #nauticalVisitsSingularFound>{{
              'shared.terms.itemFound' | translate
            }}</ng-template>
            <ng-template #nauticalVisitsMultipleFound>{{
              'shared.terms.itemsFound' | translate
            }}</ng-template>
          </em>
          <span *ngIf="nauticalVisitsLoading">
            <i class="fa-regular fa-spinner fa-spin fa-fw"></i>
          </span>
        </div>
      </label>
      <div class="input-group">
        <input
          [(ngModel)]="nauticalVisit"
          [class.is-invalid]="nauticalVisitLoadingFailed"
          [ngbTypeahead]="searchNauticalVisit"
          [inputFormatter]="nauticalVisitFormatter"
          [resultFormatter]="nauticalVisitFormatter"
          [editable]="false"
          [placeholder]="('shared.terms.choose' | translate) + '...'"
          [required]="nauticalVisitRequired"
          [disabled]="
            nauticalShipPickerMode !== shipPickerMode.NoShip && !nauticalShip
          "
          #visitTypeahead="ngbTypeahead"
          (focus)="focusVisit$.next($any($event).target.value)"
          (click)="clickVisit$.next($any($event).target.value)"
          id="typeahead-nauticalVisit"
          name="nauticalVisit"
          type="text"
          class="form-control"
        />
        <div class="input-group-append">
          <button
            [disabled]="nauticalVisitsLoading"
            (click)="setNull('nauticalVisit')"
            type="button"
            class="btn btn-outline-secondary"
          >
            <i class="fa-solid fa-trash fa-fw"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button closeModal type="button" class="btn btn-link flex-fill">
    {{ 'shared.terms.cancel' | translate }}
  </button>
  <button
    [disabled]="
      (nauticalVisitRequired && !nauticalVisit) ||
      (nauticalShipPickerMode === shipPickerMode.Required && !nauticalShip)
    "
    [ngClass]="
      nauticalVisitLoadingFailed ? 'btn-outline-danger' : 'btn-success'
    "
    (click)="onConfirmButtonClick()"
    type="button"
    class="btn flex-fill"
  >
    {{ 'shared.terms.confirm' | translate }}
  </button>
</div>
