<div
  id="sidebar-wrapper"
  class="disable-scrollbars"
  [ngClass]="{ 'sidebar-wrapper-expanded': sidebarOpen }"
>
  <ul class="sidebar-nav">
    <li class="sidebar-brand"></li>

    <li *ngFor="let item of navigationPrimary">
      <a
        routerLink="/{{ item.route }}"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        [attr.title]="item.name | translate"
        (click)="navigate(item.route)"
      >
        <span class="sidebar-icon">
          <img *ngIf="item.iconUrl" [src]="item.iconUrl" />
          <i
            *ngIf="item.fontIcon"
            class="fa-solid {{ item.fontIcon }} fa-lg fa-fw"
          ></i
        ></span>
        <span class="sidebar-link"> {{ item.name | translate }}</span></a
      >
    </li>

    <!-- custom ui modules -->
    <li *ngFor="let module of uiModules">
      <a
        [routerLink]="[
          '/module/' +
            module.systemCode +
            '/' +
            (module.entryRoute ? module.entryRoute : 'overview')
        ]"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        [attr.title]="module.displayName"
      >
        <span class="sidebar-icon">
          <i class="fa-solid {{ module.displayIcon }} fa-lg fa-fw"></i
        ></span>
        <span class="sidebar-link"> {{ module.displayName }}</span></a
      >
    </li>

    <li *ngFor="let item of navigationSecondary">
      <a
        routerLink="/{{ item.route }}"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: false }"
        [attr.title]="item.name | translate"
        (click)="navigate(item.route)"
      >
        <span class="sidebar-icon">
          <img *ngIf="item.iconUrl" [src]="item.iconUrl" />
          <i *ngIf="item.fontIcon" class="fas fa-lg {{ item.fontIcon }}"></i
        ></span>
        <span class="sidebar-link"> {{ item.name | translate }}</span></a
      >
    </li>

    <div *shHasFeature="'uiModuleCreation'">
      <li>
        <a
          routerLinkActive="active"
          [attr.title]="'customModules.newModule' | translate"
          class="cursor-pointer"
          (click)="openNewCustomUiModal()"
        >
          <span class="sidebar-icon">
            <i class="fa-solid fa-plus fa-lg fa-fw"></i
          ></span>
          <span class="sidebar-link">
            {{ 'customModules.newModule' | translate }}
          </span>
        </a>
      </li>
    </div>
    <li>
      <a
        routerLinkActive="active"
        class="cursor-pointer"
        (click)="toggleSidebar()"
      >
        <span class="sidebar-icon">
          <i
            [ngClass]="
              sidebarOpen ? 'sidebar-handle-open' : 'sidebar-handle-closed'
            "
            class="sidebar-handle fa-solid fa-chevron-right fa-lg fa-fw"
          ></i
        ></span>
        <span class="sidebar-link">
          {{ 'layout.sidebar.closeSidebar' | translate }}
        </span>
      </a>
    </li>
  </ul>
</div>
