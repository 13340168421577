import {
  Attribute,
  ChangeDetectionStrategy,
  Component,
  HostBinding,
} from '@angular/core';

@Component({
  selector: 'sh-button',
  template: `
    <button ejs-button [ngClass]="class">
      <ng-content></ng-content>
    </button>
  `,
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  get class() {
    return this.link != null ? 'e-link' : '';
  }

  @HostBinding('class')
  _type = !this.type || this.type === 'submit' ? 'submit' : 'button';

  constructor(
    @Attribute('link') public link: string | null,
    @Attribute('type') public type: string | null
  ) {}
}
