import { Component, Input } from '@angular/core';
import { TaskModel } from '../../models/task.model';

@Component({
  selector: 'ca-follow-up-task-form',
  templateUrl: './follow-up-task-form.component.html',
  styleUrls: ['./follow-up-task-form.component.scss'],
})
export class FollowUpTaskFormComponent {
  @Input() submitted: boolean;
  @Input() task: TaskModel;
  @Input() users: any[];
}
