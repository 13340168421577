import { Injectable } from '@angular/core';
import { ResultWrapper } from '@seahorse/common';
import { DataContextService } from '@seahorse/common';
import { Observable } from 'rxjs';

import { PortWaypointModel } from '../models/port-waypoint.model';
import { DataService } from '../../shared/services/data.service';

@Injectable({
  providedIn: 'root',
})
export class PortWaypointDataService implements DataService<PortWaypointModel> {
  constructor(private dataContext: DataContextService) {}

  search(
    search?: string,
    pageIndex?: number,
    pageSize?: number,
    annotate = false
  ): Observable<ResultWrapper<PortWaypointModel[]>> {
    const queryParams = [`annotate=${annotate}`];

    if (search !== undefined && search !== null) {
      queryParams.push(`search=${encodeURIComponent(search)}`);
    }

    if (pageIndex !== undefined && pageIndex !== null) {
      queryParams.push(`pindex=${pageIndex}`);
    }

    if (pageSize !== undefined && pageSize !== null) {
      queryParams.push(`psize=${pageSize}`);
    }

    const queryString = queryParams.join('&');
    const url = `su/nautical/portwaypoints${
      queryString ? '?' + queryString : ''
    }`;

    return this.dataContext.get<PortWaypointModel[]>(url);
  }

  searchByPortId(
    portId: number,
    search: string,
    pageIndex: number,
    pageSize: number,
    annotate = false
  ): Observable<ResultWrapper<PortWaypointModel[]>> {
    return this.dataContext.get<PortWaypointModel[]>(
      `su/nautical/portwaypoints/port/${portId}/search/?search=${search}&pindex=${pageIndex}&psize=${pageSize}&annotate=${annotate}`
    );
  }

  searchByHarbourId(
    harbourId: number,
    search: string,
    pageIndex: number,
    pageSize: number,
    annotate = false
  ): Observable<ResultWrapper<PortWaypointModel[]>> {
    return this.dataContext.get<PortWaypointModel[]>(
      `su/nautical/portwaypoints/harbour/${harbourId}/search/?search=${search}&pindex=${pageIndex}&psize=${pageSize}&annotate=${annotate}`
    );
  }

  getById(
    portWaypointId: number,
    annotate = false
  ): Observable<ResultWrapper<PortWaypointModel>> {
    return this.dataContext.get<PortWaypointModel>(
      `su/nautical/portwaypoints/${portWaypointId}?annotate=${annotate}`
    );
  }

  add(
    portWaypoint: PortWaypointModel
  ): Observable<ResultWrapper<PortWaypointModel>> {
    return this.dataContext.post<PortWaypointModel>(
      `su/nautical/portwaypoints`,
      portWaypoint
    );
  }

  update(
    portWaypoint: PortWaypointModel
  ): Observable<ResultWrapper<PortWaypointModel>> {
    return this.dataContext.put<PortWaypointModel>(
      `su/nautical/portwaypoints/${portWaypoint.id}`,
      portWaypoint
    );
  }

  delete(portWaypointId: number): Observable<ResultWrapper<PortWaypointModel>> {
    return this.dataContext.delete<PortWaypointModel>(
      `su/nautical/portwaypoints/${portWaypointId}`
    );
  }
}
