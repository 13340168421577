import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { NotificationService } from '@seahorse/common';
import { NauticalPortDataService, NauticalPortModel } from '@seahorse/domain';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({ templateUrl: 'nautical-port-picker-dialog.component.html' })
export class NauticalPortPickerDialogComponent {
  @Input() nauticalPort: NauticalPortModel;
  @Input() isRequired?: boolean;
  @Input() title?: string;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onConfirm: EventEmitter<{ nauticalPort: NauticalPortModel }>;

  nauticalPortsLoading: boolean;
  nauticalPortsLoadingFailed: boolean;

  constructor(
    private translate: TranslateService,
    private modal: NgbActiveModal,
    private notification: NotificationService,
    private nauticalPortData: NauticalPortDataService
  ) {
    this.nauticalPort = null;
    this.isRequired = true;
    this.nauticalPortsLoading = false;
    this.nauticalPortsLoadingFailed = false;
  }

  nauticalPortFormatter = (nauticalPort: NauticalPortModel): string =>
    nauticalPort.name.toUpperCase();

  nauticalPortsFormatter = (nauticalPort: NauticalPortModel): string => {
    if (nauticalPort.unloCode && nauticalPort.name) {
      return (
        nauticalPort.name.toUpperCase() + ' (' + nauticalPort.unloCode + ')'
      );
    } else {
      return nauticalPort.name.toUpperCase();
    }
  };

  searchnauticalPort = (input$: Observable<string>): any =>
    input$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.nauticalPortsLoading = true)),
      switchMap((s) =>
        this.nauticalPortData.getByName(s).pipe(
          tap(() => (this.nauticalPortsLoadingFailed = false)),
          map((r) => r.result),
          catchError((e) => {
            this.nauticalPortsLoadingFailed = true;

            this.notification.showError(
              e,
              this.translate.instant('shared.terms.failed')
            );
            return of([]);
          })
        )
      ),

      tap(() => (this.nauticalPortsLoading = false))
    );

  setNull = () => (this.nauticalPort = null);

  confirm() {
    this.modal.close(this.nauticalPort);
  }
}
