<div class="form-row">
  <div class="form-group col-12">
    <label for="name">
      <b>{{ 'shared.terms.name' | translate }}</b>
      <i class="fa-regular fa-asterisk fa-xs fa-fw text-danger mb-1"></i>
    </label>
    <input
      [(ngModel)]="flow.name"
      [disabled]="loadingFlow"
      required
      type="text"
      placeholder="{{ 'shared.terms.name' | translate }}"
      id="name"
      name="name"
      class="form-control"
    />
  </div>
  <div class="form-group col-12">
    <label for="description">
      <b>{{ 'shared.terms.description' | translate }}</b>
    </label>
    <textarea
      [(ngModel)]="flow.description"
      [disabled]="loadingFlow"
      type="text"
      rows="5"
      placeholder="{{ 'shared.terms.description' | translate }}"
      id="description"
      name="description"
      class="form-control"
    ></textarea>
  </div>
  <div class="form-group col-12">
    <label for="copyEmailAddress">
      <b>{{ 'invoicingFlows.flow.copyEmailAddress' | translate }}</b>
      <i class="fa-regular fa-asterisk fa-xs fa-fw text-danger mb-1"></i>
    </label>
    <input
      [(ngModel)]="flow.copyEmailAddress"
      [disabled]="loadingFlow"
      required
      type="email"
      placeholder="{{ 'invoicingFlows.flow.copyEmailAddress' | translate }}"
      id="copyEmailAddress"
      name="copyEmailAddress"
      class="form-control"
    />
  </div>
  <div class="form-group col-12">
    <div class="custom-control custom-switch">
      <input
        [(ngModel)]="flow.onlineAvailable"
        [disabled]="loadingFlow"
        required
        type="checkbox"
        class="custom-control-input"
        id="onlineAvailable"
        name="onlineAvailable"
      />
      <label for="onlineAvailable" class="custom-control-label">
        {{ 'invoicingFlows.flow.onlineAvailable' | translate }}
      </label>
    </div>
    <div class="custom-control custom-switch">
      <input
        [(ngModel)]="flow.trackPayments"
        [disabled]="loadingFlow"
        required
        type="checkbox"
        class="custom-control-input"
        id="trackPayments"
        name="trackPayments"
      />
      <label for="trackPayments" class="custom-control-label">
        {{ 'invoicingFlows.flow.trackPayments' | translate }}
      </label>
    </div>
    <div class="custom-control custom-switch">
      <input
        [(ngModel)]="flow.enabled"
        [disabled]="loadingFlow"
        required
        type="checkbox"
        class="custom-control-input"
        id="enabled"
        name="enabled"
      />
      <label for="enabled" class="custom-control-label">
        {{ 'invoicingFlows.flow.enabled' | translate }}
      </label>
    </div>
  </div>
  <div class="form-group col-12 col-md-6">
    <label for="startsOn">
      <b>{{ 'invoicingFlows.flow.startsOn' | translate }}</b>
      <i class="fa-regular fa-asterisk fa-xs fa-fw text-danger mb-1"></i>
    </label>
    <temp-date-time-picker
      [(dateTime)]="flow.startsOn"
      [dateTimeFormat]="'YYYY-MM-DD[T00:00:00.000Z]'"
      [hideTime]="true"
      [isReadOnly]="loadingFlow"
      [isContained]="true"
    ></temp-date-time-picker>
  </div>
  <div class="form-group col-12 col-md-6">
    <label for="endsOn">
      <b>{{ 'invoicingFlows.flow.endsOn' | translate }}</b>
    </label>
    <temp-date-time-picker
      [(dateTime)]="flow.endsOn"
      [dateTimeFormat]="'YYYY-MM-DD[T00:00:00.000Z]'"
      [hideTime]="true"
      [isReadOnly]="loadingFlow"
      [isContained]="true"
    ></temp-date-time-picker>
  </div>
</div>
