import { CustomDataBaseModel } from '@seahorse/domain';

export class CustomTeamModel extends CustomDataBaseModel {
  active: boolean;
  name: string;
  disabled: boolean;

  constructor() {
    super();

    this.active = false;
    this.name = null;
    this.disabled = false;
  }
}
