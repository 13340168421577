import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CompanyDataService } from '@seahorse/domain';
import { CompanyModel } from '@seahorse/domain';
import { CompanyFormComponent } from '../company/company.component';

@Component({ templateUrl: 'company-picker-dialog.component.html' })
export class CompanyPickerDialogComponent implements OnInit {
  @Input() allowTextOnly = false;
  @Input() allowNew = true;
  @Input() company?: CompanyModel;
  @Input() companyRequired?: boolean;
  @Input() tagFilter?: string;
  @Input() title?: string;
  @Input() selectedCompanyLoading: boolean;

  @Output() onConfirm: EventEmitter<{ company: CompanyModel }>;
  constructor(
    private activeModal: NgbActiveModal,
    private modal: NgbModal,
    private translate: TranslateService,
    private companyData: CompanyDataService
  ) {
    this.company = null;
    this.companyRequired = true;
    this.title = this.translate.instant('companies.pickerDialog.title');
    this.onConfirm = new EventEmitter<{ company: CompanyModel }>();
    this.selectedCompanyLoading = false;
  }

  ngOnInit() {
    if (
      this.company &&
      this.company.id !== undefined &&
      this.company.id !== null &&
      (this.company.name === undefined || this.company.name === null)
    ) {
      this.selectedCompanyLoading = true;

      this.companyData.getById(this.company.id).subscribe(
        (response) => {
          if (response && response.hasResult) {
            this.company = response.result;
          }
        },
        () => (this.selectedCompanyLoading = false),
        () => (this.selectedCompanyLoading = false)
      );
    }
  }

  addCompany() {
    const modalRef = this.modal.open(CompanyFormComponent, {
      backdrop: 'static',
    });
    modalRef.result.then((modalResult) => {
      if (modalResult && modalResult.id) {
        this.company = modalResult;
      }
    });
  }

  close() {
    this.activeModal.close(this.company);
  }

  dismiss() {
    this.activeModal.dismiss();
  }
}
