import { Injectable } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'underscore';
import { PeriodDatesModel, PeriodInterval } from '../models/period-dates.model';

@Injectable({ providedIn: 'root' })
export class PeriodIntervalCalculationService {
  /**
   * @param periodDates The period to calculate the interval from.
   * @return The interval in days.
   **/
  calculateIntervalInDays(periodDates: PeriodDatesModel): number {
    return Math.abs(
      periodDates.start
        .startOf('day')
        .diff(periodDates.end.startOf('day'), 'days')
    );
  }

  /**
   * @param periodDates The period to calculate the previous period from.
   * @return The start and end date of the previous period in days.
   **/
  calculatePreviousPeriodInDays(
    periodDates: PeriodDatesModel
  ): PeriodDatesModel {
    const interval: number = this.calculateIntervalInDays(periodDates);
    const start: moment.Moment = periodDates.start.subtract(interval, 'days');
    const end: moment.Moment = periodDates.end.subtract(interval, 'days');

    return new PeriodDatesModel({ start: start, end: end });
  }

  /**
   * @param periodDates The period to calculate the next period from.
   * @return The start and end date of the next period in days.
   **/
  calculateNextPeriodInDays(periodDates: PeriodDatesModel): PeriodDatesModel {
    const interval: number = this.calculateIntervalInDays(periodDates);
    const start: moment.Moment = periodDates.start.add(interval, 'days');
    const end: moment.Moment = periodDates.end.add(interval, 'days');

    return new PeriodDatesModel({ start: start, end: end });
  }

  /**
   * @param interval The interval to calculate the start date.
   * @param date (Optional) The date to calculate the start date from. Fallback: current date.
   * @return The start date of the interval.
   **/
  calculateIntervalStart(
    interval: PeriodInterval,
    date?: Date | moment.Moment
  ): moment.Moment {
    const baseDate: moment.Moment = date ? moment(date) : moment();

    switch (interval) {
      case PeriodInterval.WEEK:
        return baseDate.startOf('isoWeek');
      case PeriodInterval.MONTH:
        return baseDate.startOf('month');
      case PeriodInterval.QUARTER:
        return baseDate.startOf('quarter');
      case PeriodInterval.YEAR:
        return baseDate.startOf('year');
      case PeriodInterval.HALFDECADE:
        return baseDate
          .year(Math.floor(+baseDate.format('YYYY') / 5) * 5)
          .startOf('year');
    }
  }

  /**
   * @param interval The interval to calculate the end date.
   * @param date (Optional) The date to calculate the end date from. Fallback: current date.
   * @return The end date of the interval.
   **/
  calculateIntervalEnd(
    interval: PeriodInterval,
    date?: Date | moment.Moment
  ): moment.Moment {
    const baseDate: moment.Moment = date ? moment(date) : moment();

    switch (interval) {
      case PeriodInterval.WEEK:
        return baseDate.endOf('isoWeek');
      case PeriodInterval.MONTH:
        return baseDate.endOf('month');
      case PeriodInterval.QUARTER:
        return baseDate.endOf('quarter');
      case PeriodInterval.YEAR:
        return baseDate.endOf('year');
      case PeriodInterval.HALFDECADE:
        return baseDate
          .year(Math.floor(+baseDate.format('YYYY') / 5) * 5 + 4)
          .endOf('year');
    }
  }

  /**
   * @param interval The interval to calculate the start and end date.
   * @param date (Optional) The date to calculate the interval from. Fallback: current date.
   * @return The start and end date of the interval.
   **/
  calculateCurrentInterval(
    interval: PeriodInterval,
    date?: Date | moment.Moment
  ): PeriodDatesModel {
    const baseDate: moment.Moment = date ? moment(date) : moment();
    const start: moment.Moment = this.calculateIntervalStart(
      interval,
      baseDate
    );
    const end: moment.Moment = this.calculateIntervalEnd(interval, start);

    return new PeriodDatesModel({ start: start, end: end });
  }

  /**
   * @param interval The interval to calculate the start and end date.
   * @param date (Optional) The date to calculate the previous interval from. Fallback: current date.
   * @return The start and end date of the previous interval.
   **/
  calculatePreviousInterval(
    interval: PeriodInterval,
    date?: Date | moment.Moment
  ): PeriodDatesModel {
    const baseDate: moment.Moment = date ? moment(date) : moment();
    const end: moment.Moment = this.calculateIntervalStart(
      interval,
      baseDate
    ).subtract(1, 'days');
    const start: moment.Moment = this.calculateIntervalStart(interval, end);

    return new PeriodDatesModel({ start: start, end: end });
  }

  /**
   * @param interval The interval to calculate the start and end date.
   * @param date (Optional) The date to calculate the next interval from. Fallback: current date.
   * @return The start and end date of the next interval.
   **/
  calculateNextInterval(
    interval: PeriodInterval,
    date?: Date | moment.Moment
  ): PeriodDatesModel {
    const baseDate: moment.Moment = date ? moment(date) : moment();
    const start: moment.Moment = this.calculateIntervalEnd(
      interval,
      baseDate
    ).add(1, 'days');
    const end: moment.Moment = this.calculateIntervalEnd(interval, start);

    return new PeriodDatesModel({ start: start, end: end });
  }

  detectInterval(
    dateStart: Date | moment.Moment,
    dateEnd: Date | moment.Moment
  ): PeriodInterval {
    if (!dateStart || !dateEnd) {
      return undefined;
    }

    if (!moment.isMoment(dateEnd)) {
      dateEnd = moment(dateEnd);
    }
    if (!moment.isMoment(dateStart)) {
      dateStart = moment(dateStart);
    }

    let intervalEnd = this.calculateIntervalEnd(PeriodInterval.WEEK, dateStart);
    if (
      dateStart.isSame(moment(dateStart).startOf('isoWeek')) &&
      intervalEnd.isSame(dateEnd)
    ) {
      return PeriodInterval.WEEK;
    } else if (dateStart.isSame(moment(dateStart).startOf('month'))) {
      const interval = _.find(Object.values(PeriodInterval), (k) => {
        intervalEnd = this.calculateIntervalEnd(k as PeriodInterval, dateStart);
        return intervalEnd && intervalEnd.isSame(dateEnd);
      });
      if (interval) {
        return interval as PeriodInterval;
      }
    }
    return undefined;
  }

  /**
   * @param interval The interval to check.
   * @param periodDates The dates to check the interval with.
   * @return Whether the dates match the interval.
   **/
  isCurrentInterval(
    interval: PeriodInterval,
    periodDates: PeriodDatesModel
  ): boolean {
    return (
      periodDates &&
      periodDates.end &&
      periodDates.end.isSame(
        this.calculateIntervalEnd(interval, periodDates.start)
      )
    );
  }
}
