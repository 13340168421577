import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  CompanyFinancialModel,
  CompanyModel,
} from '@seahorse/domain';
import { CompanyDataService } from '@seahorse/domain';
import { ResultWrapper } from '@seahorse/common';

import * as _ from 'underscore';

@Component({
  selector: 'ca-company-financial-select',
  templateUrl: 'company-financial-select.component.html',
})
export class CompanyFinancialSelectComponent implements OnInit {
  private companies: any[] = [];
  hasCompanies = false;
  isLoading = false;

  @Input() companyId: number = null;
  @Input() companyFinancialId: number = null;

  @Output() companyIdChange = new EventEmitter<number>();
  @Output() companyFinancialIdChange = new EventEmitter<number>();

  constructor(private companyDataService: CompanyDataService) {}

  ngOnInit(): void {
    this.loadCompanies();
  }

  companyChanged(event: any, selectedItem: any) {
    const company = _.find(
      this.companies,
      (c) => c.id == this.companyFinancialId
    );

    if (company) {
      this.companyId = company.companyId;
      this.companyFinancialId = company.id;

      this.companyIdChange.emit(this.companyId);
      this.companyFinancialIdChange.emit(this.companyFinancialId);
    }
  }

  loadCompanies() {
    this.isLoading = true;
    this.companyDataService.getAll(-1, -1).subscribe({
      next: (companyGetResponse: ResultWrapper<any>) => {
        if (companyGetResponse.count) {
          this.hasCompanies = true;
        }

        let companies = [];

        _.each(companyGetResponse.result, (company: CompanyModel) => {
          if (
            company.companyFinancials &&
            company.companyFinancials.length > 0
          ) {
            companies = companies.concat(
              _.map(
                company.companyFinancials,
                (financial: CompanyFinancialModel) => {
                  financial['displayName'] =
                    company.name + ' (' + financial.debtorNumber + ')';
                  return financial;
                }
              )
            );
          } else {
            companies.push({
              companyId: -1,
              id: -1,
              displayName: company.name,
            });
          }
        });

        this.companies = _.sortBy(companies, (company) =>
          company.displayName.toLowerCase()
        );
        this.companyChanged(null, null);
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      },
    });
  }
}
