import { Injectable } from '@angular/core';
import { CustomDataContentService, SearchOperators } from '@seahorse/domain';
import { Observable } from 'rxjs';
import { ResultWrapper } from '@seahorse/common';
import { TaskDefinitionModel } from '../models/task-definition.model';

@Injectable()
export class TaskDefinitionDataService {
  constructor(private customData: CustomDataContentService) {}

  getByListId(
    taskDefinitionListId: TaskDefinitionModel['$customdata_taskdefinitionlist_id']
  ): Observable<ResultWrapper<TaskDefinitionModel[]>> {
    const fields = [
      {
        fieldName: '$customdata_taskdefinitionlist_id',
        searchOperator: SearchOperators.Equals,
        searchValue: taskDefinitionListId,
      },
    ];

    return this.customData.searchActiveCustomerDataByFields(
      'taskDefinition',
      fields,
      null,
      null,
      0,
      500
    );
  }

  add(
    taskDefinition: TaskDefinitionModel
  ): Observable<ResultWrapper<TaskDefinitionModel>> {
    return this.customData.addCustomerData('taskDefinition', taskDefinition);
  }

  update(
    taskDefinition: TaskDefinitionModel
  ): Observable<ResultWrapper<TaskDefinitionModel>> {
    return this.customData.updateCustomerData(
      'taskDefinition',
      taskDefinition.__Id,
      taskDefinition
    );
  }

  delete(
    taskDefinitionId: TaskDefinitionModel['__Id']
  ): Observable<ResultWrapper<TaskDefinitionModel>> {
    return this.customData.deleteCustomerData(
      'taskDefinition',
      taskDefinitionId
    );
  }
}
