import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { SystemMessageModel } from '../../../preference/models/system-message.model';
import * as _ from 'underscore';

@Component({
  selector: 'release-note-card',
  templateUrl: './release-note-card.component.html',
})
export class ReleaseNoteCardComponent {
  private _systemMessages: SystemMessageModel[];

  @Input() get systemMessages(): SystemMessageModel[] {
    return this._systemMessages;
  }
  set systemMessages(value: SystemMessageModel[]) {
    this._systemMessages = _.map(value, (msg) => {
      if (msg.systemMessageTranslations) {
        const translation = _.find(
          msg.systemMessageTranslations,
          (trans) => trans.language === this.translate.currentLang
        );
        if (translation) {
          if (translation.subject) {
            msg.subject = translation.subject;
          }
          if (translation.notes) {
            msg.notes = translation.notes;
          }
        }
      }
      return msg;
    });
  }

  constructor(private translate: TranslateService) {}
}
