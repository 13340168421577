import { Pipe, PipeTransform } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { ExecutionModel } from '../../../../invoicing-and-tariffs/modules/invoicing-flows/models/execution.model';

@Pipe({ name: 'executionStatus' })
export class ExecutionStatusPipe implements PipeTransform {
  executionStatusQuery: string;
  executionStatus: string;

  constructor(private translateService: TranslateService) {
    this.executionStatusQuery = null;
    this.executionStatus = null;
  }

  transform(executionStatus: ExecutionModel['status']): string {
    switch (executionStatus) {
      case 1: {
        this.executionStatusQuery = 'new';

        break;
      }

      case 2: {
        this.executionStatusQuery = 'executing';

        break;
      }

      case 3: {
        this.executionStatusQuery = 'completed';

        break;
      }

      case 4: {
        this.executionStatusQuery = 'error';

        break;
      }

      case 5: {
        this.executionStatusQuery = 'pendingApproval';

        break;
      }

      default: {
        this.executionStatusQuery = 'unknown';

        break;
      }
    }

    this.translateService
      .get(`invoicingFlows.executionStatuses.${this.executionStatusQuery}`)
      .subscribe((translateGetResponse) => {
        this.executionStatus = translateGetResponse;
      });

    return this.executionStatus;
  }
}
