import { FieldMode, FieldType } from '@seahorse/domain';
import { BadgeModel } from '@seahorse/ui';

// TODO: remove in favor of ObjectFieldDefinition
export class FieldAttributeModel {
  fieldCode: string; // the system code of the field
  fieldName: string; // the display text of the field
  fieldType: FieldType; // the type of the field
  fieldValue: any; // value of the field
  fieldFormatting?: object;
  displayValue: any;
  isRequired?: boolean;
  isReadOnly?: boolean;
  options?: any;
  sortOrder?: number;
  groupName?: string;
  mode?: FieldMode;
  badge?: Record<string, BadgeModel>;
  appendText?: string;
  showCopyButton?: boolean;

  constructor(
    fieldName: string,
    fieldType: FieldType,
    fieldValue: any,
    fieldCode?: string,
    isRequired?: boolean,
    options?: any,
    fieldFormatting?: object
  ) {
    this.fieldCode = fieldCode;
    this.fieldName = fieldName;
    this.fieldType = fieldType;
    this.fieldValue = fieldValue;
    this.fieldFormatting = fieldFormatting;
    this.isRequired = isRequired === true ? true : false;
    this.options = options;
  }
}
