<div class="page-with-header">
  <div class="page-header">
    <div class="row">
      <div class="col-sm-4">
        <form class="form-inline mx-3" (ngSubmit)="find()">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa-regular fa-search"></i>
              </span>
            </div>
            <input
              type="text"
              class="form-control"
              placeholder="{{'shared.terms.search' | translate}}"
              [(ngModel)]="search.searchQuery"
              name="searchBox"
            />
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" type="submit">
                {{'shared.terms.search' | translate}}
              </button>
            </div>
          </div>
          <em class="ml-3" *ngIf="search.resultCount !== null">
            <span *ngIf="searchResultCount === 1"
              >{{search.resultCount}} item found
            </span>
            <span *ngIf="searchResultCount !== 1"
              >{{search.resultCount}} items found</span
            >
          </em>
        </form>
      </div>
      <div class="col-sm-8 text-right">
        <div class="form-inline btn-group btn-list">
          <div class="input-group mr-3">
            <div class="input-group-prepend">
              <span class="input-group-text"
                >{{'customers.cvv.page.visit' | translate}}</span
              >
            </div>
            <select
              class="form-control"
              [(ngModel)]="selectedList"
              [disabled]="selectedPageView === 'classic'"
              name="ddl_activities_list"
              (change)="switchList(null)"
              title="Alleen beschikbaar in modern weergave"
            >
              <option [value]="'active'">
                {{'customers.cvv.page.activeList' | translate}}
              </option>
              <option [value]="'history'">
                {{'customers.cvv.page.historyList' | translate}}
              </option>
            </select>
          </div>

          <div class="input-group mr-3">
            <div class="input-group-prepend">
              <span class="input-group-text"
                >{{selectedList === 'active' ? ('customers.cvv.page.forward' |
                translate) : ('customers.cvv.page.back' | translate)}}</span
              >
            </div>
            <select
              class="form-control"
              [(ngModel)]="selectedFilter"
              name="ddl_activities_filter_time"
              (change)="filterByTime(true)"
              title="{{(selectedList === 'active' ? 'komende ' : 'laatste ') + selectedFilter}}"
            >
              <option
                *ngFor="let option of filter.options"
                [value]="option.value"
              >
                {{option.text}}
              </option>
            </select>
          </div>

          <div class="input-group mr-3">
            <div class="input-group-prepend">
              <span class="input-group-text"
                >{{'shared.terms.view' | translate}}</span
              >
            </div>
            <select
              class="form-control"
              [(ngModel)]="selectedPageView"
              name="ddl_activities_view"
              (change)="switchPageView()"
            >
              <option [value]="'classic'">
                {{'customers.cvv.page.classicView' | translate}}
              </option>
              <option [value]="'modern'">
                {{'customers.cvv.page.modernView' | translate}}
              </option>
            </select>
          </div>
          <!-- <div class="btn-group">
            <button type="button" class="btn dropdown-toggle mr-2" id="ddl_activities_display" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-offset="0,1">
              <span class="text-muted">weergave: </span>
              <span *ngIf="selectedView === 'flow'">Reisverloop</span>
              <span *ngIf="selectedView === 'text'">Tekst</span>
            </button>
            <div class="dropdown-menu" aria-labelledby="ddl_activities_display">
              <a class="dropdown-item" (click)="selectedView = 'flow'">Reisverloop</a>
              <a class="dropdown-item" (click)="selectedView = 'text'">Tekst</a>
            </div>
          </div> -->

          <div class="input-group mr-3">
            <button
              type="button"
              class="btn btn-primary"
              (click)="activityItemClicked({action: 'add', type: 'activity', data:null}, null)"
            >
              <i class="fa-regular fa-plus"></i>
              activiteit toevoegen
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid py-3">
    <sh-loading-indicator
      class="text-center"
      *ngIf="isLoading"
    ></sh-loading-indicator>
    <div class="row mx-0">
      <div *ngIf="incomingTimeWarningDisplay" class="col alert alert-warning">
        <strong>Inkomend:</strong>
        {{ incomingTimeWarningDisplay }}
      </div>
      <div *ngIf="outgoingTimeWarningDisplay" class="col alert alert-warning">
        <strong>Uitgaand:</strong>
        {{ outgoingTimeWarningDisplay }}
      </div>
    </div>
    <div
      class="tile-container"
      *ngIf="!isLoading && selectedPageView === 'modern'"
    >
      <!-- Active visits -->
      <ca-custom-cvv-activities-container
        [currentView]="selectedView"
        *ngFor="let visit of activeCvvVisits"
        [activities]="visit.activities"
        [isActive]="true"
        [position]="positions[visit.mmsi]"
        [selectedVisitList]="selectedList"
        [visit]="visit.nauticalVisit"
        [movement]="visit.movement"
        [cvvObject]="visit.visit"
        [loadMembersAnimation]="loadMembersAnimation"
        [shipRequirements]="shipRequirements[visit.nauticalVisit.shipId]"
        [loas]="loas"
        (visitItemClicked)="visitItemClicked($event, visit.nauticalVisit, visit)"
        (activityItemClicked)="activityItemClicked($event, visit.nauticalVisit, visit.visit)"
        [cvvVisit]="visit"
      >
      </ca-custom-cvv-activities-container>
      <div
        class="content-seperator"
        *ngIf="allVisits && allVisits.length > 0 && selectedList === 'active'"
      >
        <span>voorgestelde reizen</span>
      </div>
      <!-- Inactive visits -->
      <ca-custom-cvv-activities-container
        [selectedVisitList]="selectedList"
        *ngFor="let visit of expectedVisits"
        [activities]="[]"
        [isActive]="false"
        [position]="positions[visit.ship.mmsi]"
        [currentView]="selectedView"
        [visit]="visit"
        [movement]="visit.currentMovement"
        (itemActivated)="itemActivated($event, visit)"
        (visitItemClicked)="visitItemClicked($event, visit)"
        [shipRequirements]="shipRequirements[visit.shipId]"
        (incomingTimeWarningDisplay)="addIncomingTimeWarning($event)"
        (outgoingTimeWarningDisplay)="addOutgoingTimeWarning($event)"
      >
      </ca-custom-cvv-activities-container>
    </div>

    <div
      class="container-wrapper"
      *ngIf="!isLoading && selectedPageView === 'classic'"
    >
      <div class="column col-3">
        <h4 class="column-title p-2">Inkomend</h4>
        <div class="column-body">
          <!-- Active visits -->
          <ca-custom-cvv-activities-container
            [selectedVisitList]="selectedList"
            *ngFor="let visit of activeCvvVisits"
            [isClassicView]="true"
            [activities]="getActivities(visit, 'inward')"
            [isActive]="true"
            [position]="positions[visit.mmsi]"
            [currentView]="selectedView"
            [visit]="visit.nauticalVisit"
            [movement]="visit.nauticalVisit.currentMovement"
            [cvvObject]="visit.visit"
            [loadMembersAnimation]="loadMembersAnimation"
            [shipRequirements]="shipRequirements[visit.nauticalVisit.shipId]"
            [loas]="loas"
            (visitItemClicked)="visitItemClicked($event, visit.nauticalVisit, visit)"
            (activityItemClicked)="activityItemClicked($event, visit.nauticalVisit, visit.visit)"
          >
          </ca-custom-cvv-activities-container>
          <div
            class="content-seperator"
            *ngIf="allVisits && allVisits.length > 0 && selectedList === 'active'"
          >
            <span>voorgestelde reizen</span>
          </div>
          <!-- Inactive visits -->
          <ca-custom-cvv-activities-container
            [selectedVisitList]="selectedList"
            *ngFor="let visit of filterExpectedVisits('inward')"
            [activities]="[]"
            [isClassicView]="true"
            [isActive]="false"
            [position]="positions[visit.ship.mmsi]"
            [currentView]="selectedView"
            [visit]="visit"
            [movement]="visit.currentMovement"
            (itemActivated)="itemActivated($event, visit)"
            (itemClicked)="itemClicked($event, visit)"
            [shipRequirements]="shipRequirements[visit.shipId]"
          >
          </ca-custom-cvv-activities-container>
          <!-- <div class="content-seperator" *ngIf="activeNrOfInward === 0 && expectedNrOfInward === 0">
            <span>There are no visits for this movement</span>
          </div> -->
        </div>
      </div>
      <div class="column col-3">
        <h4 class="column-title p-2">Verhaling</h4>
        <div class="column-body">
          <!-- Active visits -->
          <ca-custom-cvv-activities-container
            [selectedVisitList]="selectedList"
            *ngFor="let visit of activeCvvVisits"
            [isClassicView]="true"
            [activities]="getActivities(visit, 'shift')"
            [isActive]="true"
            [position]="positions[visit.mmsi]"
            [currentView]="selectedView"
            [visit]="visit.nauticalVisit"
            [movement]="visit.nauticalVisit.currentMovement"
            [cvvObject]="visit.visit"
            [loadMembersAnimation]="loadMembersAnimation"
            [shipRequirements]="shipRequirements[visit.nauticalVisit.shipId]"
            [loas]="loas"
            (visitItemClicked)="visitItemClicked($event, visit.nauticalVisit, visit)"
            (activityItemClicked)="activityItemClicked($event, visit.nauticalVisit, visit.visit)"
          >
          </ca-custom-cvv-activities-container>
          <div
            class="content-seperator"
            *ngIf="allVisits && allVisits.length > 0 && selectedList === 'active'"
          >
            <span>voorgestelde reizen</span>
          </div>
          <!-- Inactive visits -->
          <ca-custom-cvv-activities-container
            [selectedVisitList]="selectedList"
            *ngFor="let visit of filterExpectedVisits('shift')"
            [activities]="[]"
            [isClassicView]="true"
            [isActive]="false"
            [position]="positions[visit.ship.mmsi]"
            [currentView]="selectedView"
            [visit]="visit"
            [movement]="visit.currentMovement"
            (itemActivated)="itemActivated($event, visit)"
            (itemClicked)="itemClicked($event, visit)"
            [shipRequirements]="shipRequirements[visit.shipId]"
          >
          </ca-custom-cvv-activities-container>
          <!-- <div class="content-seperator" *ngIf="activeNrOfOutward === 0 && expectedNrOfOutward === 0">
            <span>There are no visits for this movement</span>
          </div> -->
        </div>
      </div>
      <div class="column col-3">
        <h4 class="column-title p-2">Uitgaand</h4>
        <div class="column-body">
          <!-- Active visits -->
          <ca-custom-cvv-activities-container
            [selectedVisitList]="selectedList"
            *ngFor="let visit of activeCvvVisits"
            [isClassicView]="true"
            [activities]="getActivities(visit, 'outward')"
            [isActive]="true"
            [position]="positions[visit.mmsi]"
            [currentView]="selectedView"
            [visit]="visit.nauticalVisit"
            [movement]="visit.nauticalVisit.currentMovement"
            [cvvObject]="visit.visit"
            [loadMembersAnimation]="loadMembersAnimation"
            [shipRequirements]="shipRequirements[visit.nauticalVisit.shipId]"
            [loas]="loas"
            (visitItemClicked)="visitItemClicked($event, visit.nauticalVisit, visit)"
            (activityItemClicked)="activityItemClicked($event, visit.nauticalVisit, visit.visit)"
          >
          </ca-custom-cvv-activities-container>
          <div
            class="content-seperator"
            *ngIf="allVisits && allVisits.length > 0 && selectedList === 'active'"
          >
            <span>voorgestelde reizen</span>
          </div>
          <!-- Inactive visits -->
          <ca-custom-cvv-activities-container
            [selectedVisitList]="selectedList"
            *ngFor="let visit of filterExpectedVisits('outward')"
            [activities]="[]"
            [isClassicView]="true"
            [isActive]="false"
            [position]="positions[visit.ship.mmsi]"
            [currentView]="selectedView"
            [visit]="visit"
            [movement]="visit.currentMovement"
            (itemActivated)="itemActivated($event, visit)"
            (itemClicked)="itemClicked($event, visit)"
            [shipRequirements]="shipRequirements[visit.shipId]"
          >
          </ca-custom-cvv-activities-container>
          <!-- <div class="content-seperator" *ngIf="activeNrOfShift === 0 && expectedNrOfShift === 0">
            <span>There are no visits for this movement</span>
          </div> -->
        </div>
      </div>
      <div class="column col-3">
        <h4 class="column-title p-2">Overig</h4>
        <div class="column-body">
          <!-- Active visits -->
          <ca-custom-cvv-activities-container
            [selectedVisitList]="selectedList"
            *ngFor="let visit of activeCvvVisits"
            [isClassicView]="true"
            [activities]="getActivities(visit, 'other')"
            [isActive]="true"
            [position]="positions[visit.mmsi]"
            [currentView]="selectedView"
            [visit]="visit.nauticalVisit"
            [movement]="visit.nauticalVisit.currentMovement"
            [cvvObject]="visit.visit"
            [loadMembersAnimation]="loadMembersAnimation"
            [shipRequirements]="shipRequirements[visit.nauticalVisit.shipId]"
            [loas]="loas"
            (visitItemClicked)="visitItemClicked($event, visit.nauticalVisit, visit)"
            (activityItemClicked)="activityItemClicked($event, visit.nauticalVisit, visit.visit)"
          >
          </ca-custom-cvv-activities-container>
          <div
            class="content-seperator"
            *ngIf="allVisits && allVisits.length > 0 && selectedList === 'active'"
          >
            <span>voorgestelde reizen</span>
          </div>
          <!-- Inactive visits -->
          <ca-custom-cvv-activities-container
            [selectedVisitList]="selectedList"
            *ngFor="let visit of filterExpectedVisits('other')"
            [activities]="[]"
            [isClassicView]="true"
            [isActive]="false"
            [position]="positions[visit.ship.mmsi]"
            [currentView]="selectedView"
            [visit]="visit"
            [movement]="visit.currentMovement"
            (itemActivated)="itemActivated($event, visit)"
            (itemClicked)="itemClicked($event, visit)"
            [shipRequirements]="shipRequirements[visit.shipId]"
          >
          </ca-custom-cvv-activities-container>
          <!-- <div class="content-seperator" *ngIf="activeNrOfOther === 0 && expectedNrOfOther === 0">
            <span>There are no visits for this movement</span>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
