import { Injectable } from '@angular/core';
import {
  DataContextService,
  ResultWrapper
} from '@seahorse/common';
import { Observable } from 'rxjs';
import { NauticalCountryModel } from '../models/nautical-country.model';

@Injectable({
  providedIn: 'root',
})
export class NauticalCountryDataService {
  constructor(private dataContext: DataContextService) {}

  getById(countryId: number, annotate = false): Observable<ResultWrapper<NauticalCountryModel>> {
    return this.dataContext.get<NauticalCountryModel>(
        `nautical/geography/country/${countryId}?annotate=${annotate}`
    );
  }
  
  search(
    query: string,
    pageIndex?: number,
    pageSize?: number,
    annotate = false
  ): Observable<ResultWrapper<NauticalCountryModel[]>> {
    const queryParams = [
      `query=${encodeURIComponent(query)}`,
      `annotate=${annotate}`,
    ];

    if (pageIndex !== undefined && pageIndex >= 0) {
      queryParams.push(`pindex=${pageIndex}`);
    }

    if (pageSize !== undefined && pageSize >= 0) {
      queryParams.push(`psize=${pageSize}`);
    }

    const queryString = queryParams.join('&');
    const url = `nautical/geography/country?${queryString}`;

    return this.dataContext.get<NauticalCountryModel[]>(url);
  }
}
