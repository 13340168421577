import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@seahorse/common';
import { NauticalShipModel, ShipSearchModel } from '@seahorse/domain';
import { Observable, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  tap
} from 'rxjs/operators';
import { NauticalShipDataService } from '@seahorse/domain';

@Component({ templateUrl: 'nautical-ship-picker-dialog.component.html' })
export class NauticalShipPickerDialogComponent {
  @Input() title?: string;
  @Input() nauticalShip: NauticalShipModel;
  @Input() nauticalShipRequired?: boolean;
  @Input() editable?: boolean;

  nauticalShipsLoading: boolean;
  nauticalShipsLoadingFailed: boolean;

  constructor(
    private modal: NgbActiveModal,
    private translate: TranslateService,
    private notification: NotificationService,
    private nauticalShipData: NauticalShipDataService
  ) {
    this.title = this.translate.instant('nautical.ship.pickerDialog.title');
    this.nauticalShip = null;
    this.nauticalShipRequired = true;
    this.editable = false;
    this.nauticalShipsLoading = false;
    this.nauticalShipsLoadingFailed = false;
  }

  dismiss() {
    this.modal.dismiss();
  }

  nauticalShipFormatter = (nauticalShip: NauticalShipModel): string =>
    nauticalShip.name ? nauticalShip.name.toUpperCase() : '';

  nauticalShipsFormatter = (nauticalShip: NauticalShipModel): string => {
    let f = nauticalShip.name.toUpperCase();
    const a = [];

    if (nauticalShip.shipType && nauticalShip.shipType.name) {
      a.push(nauticalShip.shipType.name);
    }

    if (nauticalShip.callSign) {
      a.push(
        `${this.translate.instant('nautical.ship.model.callSign')}: ${
          nauticalShip.callSign
        }`
      );
    }

    if (nauticalShip.fisheryNumber) {
      a.push(nauticalShip.fisheryNumber);
    }

    if (nauticalShip.imo) {
      a.push(
        `${this.translate.instant('nautical.ship.model.imo')}: ${
          nauticalShip.imo
        }`
      );
    } else if (nauticalShip.eni) {
      a.push(
        `${this.translate.instant('nautical.ship.model.eni')}: ${
          nauticalShip.eni
        }`
      );
    }

    if (a.length) {
      f += ` (${a.join(', ')})`;
    }

    return f;
  };

  searchNauticalShip = (input$: Observable<string>): any =>
    input$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.nauticalShipsLoading = true)),
      switchMap((s) =>
        this.nauticalShipData.find(new ShipSearchModel(s), 0, 100).pipe(
          tap(() => (this.nauticalShipsLoadingFailed = false)),
          map((r) => r.result),
          catchError((e) => {
            this.nauticalShipsLoadingFailed = true;

            this.notification.showError(
              e,
              this.translate.instant('shared.terms.failed')
            );
            return of([]);
          })
        )
      ),

      tap(() => (this.nauticalShipsLoading = false))
    );

  setNull = () => (this.nauticalShip = null);

  ok() {
    this.modal.close({ nauticalShip: this.nauticalShip });
  }
}
