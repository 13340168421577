import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shUserDisplay',
  standalone: true,
})
export class UserDisplayPipe implements PipeTransform {
  transform(value: string, users: any) {
    if (!value) {
      return '';
    }

    const userDisplay = users.filter((user) => value.includes(user.id));
    return userDisplay
      .map((user) => `${user.givenName} ${user.surname}`)
      .join(', ');
  }
}
