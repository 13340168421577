<div class="card" style="margin-bottom: 1rem">
  <div class="card-header">
    {{ 'systemMessage.releases' | translate }}
    <div class="btn-list">
      <button
        class="btn btn-outline-secondary btn-sm"
        type="button"
        (click)="action({ action: 'editRelease' })"
      >
        <i class="fa-regular fa-plus"></i>
        {{ 'shared.terms.add' | translate }}
      </button>
    </div>
  </div>

  <div
    class="card-body card-body-table"
    *ngIf="systemMessageReleases && systemMessageReleases.length > 0"
  >
    <ca-system-message-release-list
      [systemMessageReleases]="systemMessageReleases"
      (onEvent)="action($event)"
    >
    </ca-system-message-release-list>
  </div>
  <div
    class="card-body"
    *ngIf="!systemMessageReleases || systemMessageReleases.length === 0"
  >
    <div class="text-muted text-center">
      {{ 'systemMessage.release.noSystemMessageReleases' | translate }}
    </div>
  </div>
</div>
