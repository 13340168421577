import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { DataContextService } from '@seahorse/common';
import { ResultWrapper } from '@seahorse/common';
import { map } from 'rxjs/operators';
import { DataService, CountryModel } from '@seahorse/domain';

@Injectable()
export class CountryDataService implements DataService<CountryModel> {
  constructor(private dataContext: DataContextService) {}

  search(
    search?: string,
    pageIndex?: number,
    pageSize?: number
  ): Observable<ResultWrapper<CountryModel[]>> {
    const queryParams = [];

    if (search !== undefined && search !== null) {
      queryParams.push(`search=${encodeURIComponent(search)}`);
    }

    if (pageIndex !== undefined && pageIndex !== null) {
      queryParams.push(`pindex=${pageIndex}`);
    }

    if (pageSize !== undefined && pageSize !== null) {
      queryParams.push(`psize=${pageSize}`);
    }

    const queryString = queryParams.join('&');
    const url = `su/nautical/countries${queryString ? '?' + queryString : ''}`;

    return this.dataContext.get<CountryModel[]>(url);
  }

  getById(countryId: number): Observable<ResultWrapper<CountryModel>> {
    return this.dataContext.get<CountryModel>(
      `su/nautical/countries/${countryId}`
    );
  }

  add(country: CountryModel): Observable<ResultWrapper<CountryModel>> {
    return this.dataContext.post<CountryModel>(
      `su/nautical/countries`,
      country
    );
  }

  update(country: CountryModel): Observable<ResultWrapper<CountryModel>> {
    return this.dataContext.put<CountryModel>(
      `su/nautical/countries/${country.id}`,
      country
    );
  }

  delete(countryId: number): Observable<ResultWrapper<CountryModel>> {
    return this.dataContext.delete<CountryModel>(
      `su/nautical/countries/${countryId}`
    );
  }

  getAll(): Observable<CountryModel[]> {
    return this.dataContext
      .get<CountryModel[]>(`su/nautical/countries?pindex=${0}&psize=${1000}`)
      .pipe(map((response) => response.result));
  }
}
