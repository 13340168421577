<div class="modal-header">
  <div class="modal-title">{{ title }}</div>
  <button type="button" class="close" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form>
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label for="dropdown">{{ label }}:</label>
          <ng-select
            [items]="sourceData"
            bindValue="id"
            bindLabel="name"
            [clearable]="true"
            [searchable]="true"
            [(ngModel)]="selectedId"
            name="selected"
          >
          </ng-select>
        </div>
      </div>
    </div>
    <div class="d-flex">
      <div class="flex-grow-1">
        <button
          type="button"
          class="btn btn-block btn-link"
          (click)="dismiss()"
        >
          {{ 'shared.terms.cancel' | translate }}
        </button>
      </div>
      <div class="flex-grow-1">
        <button
          type="button"
          class="btn btn-block btn-success"
          (click)="save()"
        >
          {{ 'shared.terms.save' | translate }}
        </button>
      </div>
    </div>
  </form>
</div>
