import { PanelModel } from '@syncfusion/ej2-angular-layouts';

export class TaskWidgetModel {
  bodyType = TaskWidgetType.Expired;
  panel: Partial<PanelModel> | undefined = {
    sizeX: 2,
    sizeY: 3,
  };
}

export enum TaskWidgetType {
  Expired = 1,
  TopActiveTasks = 2,
}
