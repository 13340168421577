<section class="page-header d-flex justify-content-between px-3 py-1">
  <div class="d-flex mx-n1">
    <div class="input-group align-items-center w-auto mx-1">
      <div class="input-group-prepend">
        <span class="input-group-text">{{
          'shared.terms.view' | translate
        }}</span>
      </div>
      <select
        [(ngModel)]="view"
        (change)="onEvent.emit({ action: 'setView', data: view })"
        required
        name="view"
        class="form-control"
      >
        <option *ngFor="let viewOption of views" [ngValue]="viewOption.value">
          {{ viewOption.title | translate }}
        </option>
      </select>
    </div>
    <form
      (ngSubmit)="onEvent.emit({ action: 'setSearch', data: search })"
      class="form-inline mx-1 mt-0"
    >
      <div class="input-group">
        <input
          [(ngModel)]="search"
          [placeholder]="'shared.terms.search' | translate"
          type="text"
          name="search"
          class="form-control"
        />
        <div class="input-group-append">
          <button
            [disabled]="loading"
            type="submit"
            class="btn btn-outline-secondary"
          >
            {{ 'shared.terms.search' | translate }}
          </button>
        </div>
      </div>
      <em *ngIf="count != null">
        {{ count }}
        <ng-container
          *ngIf="count == 1; then itemFound; else itemsFound"
        ></ng-container>
        <ng-template #itemFound>{{
          'shared.terms.itemFound' | translate
        }}</ng-template>
        <ng-template #itemsFound>{{
          'shared.terms.itemsFound' | translate
        }}</ng-template>
      </em>
    </form>
  </div>
  <div class="d-flex mx-n1">
    <button
      (click)="onEvent.emit({ action: 'add' })"
      type="button"
      class="btn btn-primary mx-1 my-1"
    >
      <i class="fa-regular fa-plus fa-fw"></i>
      {{ 'shared.terms.new' | translate }}
    </button>
  </div>
</section>
