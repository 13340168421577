import {
  Component,
  EventEmitter,
  forwardRef,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormControl,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import * as _ from 'underscore';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BaseControl } from '../base-control';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  MultiSelectOption,
  SeahorseFormBuilder,
  SeahorseFormsModule,
} from '@seahorse/forms';

@Component({
  selector: 'temp-list',
  templateUrl: './list.control.html',
  styles: [
    `
      ng-select {
        flex-grow: 1;
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ListControl),
      multi: true,
    },
    {
      provide: BaseControl,
      useExisting: forwardRef(() => ListControl),
    },
  ],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    NgSelectModule,
    SeahorseFormsModule,
    ReactiveFormsModule,
  ],
})
export class ListControl extends BaseControl<any> implements OnInit {
  private _value: any = null;

  @Input() set value(value: any) {
    if (this._value === value) {
      return;
    }

    this.setValue(value);
    this.initControl();
  }

  get value(): any {
    return this._value;
  }

  @Output() valueChange = new EventEmitter<any>();

  @Input() required = false;
  @Input() name: string;
  @Input() sortBy?: 'value' | 'label';
  @Input() mustMatch? = false;
  @Input() max?: number;
  @Input() appendText?: string;

  options: MultiSelectOption[] = [];

  @Input()
  set listItems(value: Array<{ key: any; value: string }>) {
    this.options =
      value?.map((item) => ({
        label: item.value,
        value: item.key,
      })) || [];
  }

  get isMultiple() {
    return this.max !== 1;
  }

  get isSingleSelect() {
    return this.mustMatch && this.max === 1;
  }

  get maxInfo() {
    return this._translateService.instant('shared.general.maxInfo', {
      max: this.max,
    });
  }

  private _translateService = inject(TranslateService);
  private _formBuilder = inject(SeahorseFormBuilder);
  formControl: FormControl;

  ngOnInit() {
    this.initControl();

    if (this.sortBy) {
      this.sortOptions();
    }
  }

  private setValue(value: any) {
    if (!value) {
      this._value = null;
      return;
    }

    if (Array.isArray(value) && value.length === 0) {
      this._value = null;
      return;
    }

    if (!(value instanceof Array)) {
      this._value = [value];
    } else {
      this._value = value;
    }

    this.value.forEach((value) => {
      if (!this.options.find((option) => option.value === value)) {
        this.options.push({ label: value, value });
      }
    });
  }

  private initControl() {
    this.formControl = this._formBuilder.control<any>(
      this.value,
      this.required ? Validators.required : null
    );

    this.formControl.valueChanges.subscribe((value) => {
      if (Array.isArray(value) && value.length === 0) {
        value = null;
      }

      this.valueChange.emit(value);
    });
  }

  private sortOptions() {
    this.options = [
      ...this.options.sort((a, b) => {
        const left = a[this.sortBy].toString();
        const right = b[this.sortBy].toString();

        return left.localeCompare(right);
      }),
    ];
  }

  changeValue(value: any) {
    if (value == null) {
      this._value = null;
    } else {
      this._value = [value];
    }

    this.valueChange.emit(this.value);
  }
}
