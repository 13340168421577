import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BadgeConfigurationModel } from './badge-configuration.model';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'sh-badge',
  templateUrl: './badge.component.html',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  styles: [
    `
      :host .badge {
        font-size: 100%;
        padding-top: 0.4em;
        font-weight: normal;
      }
    `,
  ],
})
export class BadgeComponent {
  @Input() badge!: BadgeConfigurationModel;

  @Output() badgeClicked = new EventEmitter<BadgeConfigurationModel>();

  clickBadge(evt: any, badge: BadgeConfigurationModel) {
    if (!this.badge.disabled && this.badge?.clickable) {
      evt.preventDefault();
      evt.stopPropagation();
      this.badgeClicked.emit(badge);
    }
  }
}
