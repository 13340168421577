<div class="card mb-4">
  <div class="card-title alert alert-primary">
    <strong>{{ 'tasks.task.model.followUpTasks' | translate }}</strong>
  </div>
  <table class="table table-sm table-striped">
    <thead>
      <tr>
        <th>{{ 'tasks.task.model.subject' | translate }}</th>
        <th>{{ 'shared.terms.description' | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let task of followUpTasks" class="card-body-group">
        <td>{{ task.subject }}</td>
        <td>{{ task.description }}</td>
      </tr>
    </tbody>
  </table>
</div>
