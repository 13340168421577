import { NgModule } from '@angular/core';
import { CustomDataServicesModule } from '../../custom-data/services/custom-data-services.module';
import { NauticalCoordinatesDataService } from '../../nautical-geography/services/nautical-coordinates-data.service';
import { AreaActivityDataService } from '../../superuser-nautical/services/area-activity.data.service';

import { GroupService } from './group.service';
import { MapInteractionService } from './map-interaction.service';

@NgModule({
  imports: [CustomDataServicesModule],
  providers: [
    MapInteractionService,
    GroupService,
    AreaActivityDataService,
    NauticalCoordinatesDataService,
  ],
})
export class MapServicesModule {}
