import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'underscore';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  tap,
  map,
} from 'rxjs/operators';
import { NotificationService } from '@seahorse/common';
import { ResultWrapper } from '@seahorse/common';
import { NauticalShipDataService } from '@seahorse/domain';
import { NauticalShipModel } from '@seahorse/domain';
import { ShipSearchModel } from '@seahorse/domain';
import { NauticalVisitDataService } from '@seahorse/domain';
import { NauticalVisitOverviewModel } from '@seahorse/domain';
import { NauticalMovementModel } from '@seahorse/domain';
import { NauticalVisitDetailsModel } from '@seahorse/domain';
import {
  ZeehavenIJmuidenMovementModel,
  ZeehavenIJmuidenVisitMovementType,
} from '../../models/zeehaven-ijmuiden-movement.model';
import { ZeehavenIJmuidenMovementDetailsModel } from '../../models/zeehaven-ijmuiden-movement-details.model';

@Component({ templateUrl: 'suggestion-picker-modal.component.html' })
export class SuggestionPickerModalComponent implements OnInit {
  @Input() zVisit: ZeehavenIJmuidenMovementDetailsModel;

  @Output() onPickSuggestion: EventEmitter<ZeehavenIJmuidenMovementModel>;

  ships: NauticalShipModel[];
  loadingShips: boolean;
  loadingShipsFailed: boolean;
  selectedShip: NauticalShipModel;
  visits: NauticalVisitOverviewModel[];
  loadingVisits: boolean;
  visitCount: number;
  selectedVisitId: NauticalVisitOverviewModel['id'];
  selectedVisit: NauticalVisitDetailsModel;
  loadingDetails: boolean;
  movementCount: number;
  selectedMovement: NauticalMovementModel;
  searchFields: string[];
  searchField: string;

  constructor(
    private shipData: NauticalShipDataService,
    private visitData: NauticalVisitDataService,
    private translate: TranslateService,
    private notify: NotificationService
  ) {
    this.zVisit = null;
    this.onPickSuggestion = new EventEmitter<ZeehavenIJmuidenMovementModel>();
    this.ships = [];
    this.loadingShips = false;
    this.loadingShipsFailed = false;
    this.selectedShip = null;
    this.visits = [];
    this.loadingVisits = false;
    this.visitCount = null;
    this.selectedVisitId = null;
    this.selectedVisit = null;
    this.loadingDetails = false;
    this.movementCount = null;
    this.selectedMovement = null;
    this.searchFields = ['name', 'fisheryNumber'];
    this.searchField = this.searchFields[0];
  }

  ngOnInit() {
    if (this.zVisit && this.zVisit.$nautical_ship) {
      this.selectedShip = this.zVisit.$nautical_ship;

      if (this.zVisit.$nautical_portvisit_id) {
        this.loadingDetails = true;
        this.loadingVisits = true;

        this.visitData.getDetails(this.zVisit.$nautical_portvisit_id).subscribe(
          (rGetDetails: NauticalVisitDetailsModel) => {
            if (rGetDetails) {
              this.movementCount = rGetDetails.portMovements.length;
              this.selectedVisit = rGetDetails;

              if (this.zVisit.$nautical_portmovement_id) {
                _.forEach(rGetDetails.portMovements, (m) => {
                  if (m.id == this.zVisit.$nautical_portmovement_id) {
                    this.selectedMovement = m;
                  }
                });
              }
            } else {
              this.notify.showError(
                this.translate.instant('shared.terms.failed')
              );
            }

            this.visitData
              .getListOverviewForShip(
                this.selectedShip.id,
                null,
                null,
                false,
                0,
                10
              )
              .subscribe(
                (
                  rGetListOverviewForShip: ResultWrapper<
                    NauticalVisitOverviewModel[]
                  >
                ) => {
                  if (rGetListOverviewForShip.hasResult) {
                    this.visitCount = rGetListOverviewForShip.count;
                    this.selectedVisitId = this.zVisit.$nautical_portvisit_id;
                    _.forEach(rGetListOverviewForShip.result, (r) => {
                      if (
                        this.selectedVisit &&
                        !_.contains(this.visits, this.selectedVisit)
                      ) {
                        if (
                          r.id == this.selectedVisit.id ||
                          this.visits.length ==
                          rGetListOverviewForShip.result.length - 1
                        ) {
                          this.visits.push(this.selectedVisit);
                        } else {
                          this.visits.push(r);
                        }
                      }
                    });

                    this.visitCount = this.visits.length;
                  } else {
                    this.notify.showError(
                      _.pluck(rGetListOverviewForShip.messages, 'message').join(
                        '\n'
                      ),
                      this.translate.instant('shared.terms.failed')
                    );
                  }
                },

                (eGetListOverviewForShip) => {
                  this.notify.showError(
                    _.pluck(
                      eGetListOverviewForShip.error.messages,
                      'message'
                    ).join('\n'),
                    this.translate.instant('shared.terms.failed')
                  );
                },

                () => (this.loadingVisits = false)
              );
          },

          (eGetDetails) => {
            this.notify.showError(
              _.pluck(eGetDetails.error.messages, 'message').join('\n'),
              this.translate.instant('shared.terms.failed')
            );
          },

          () => (this.loadingDetails = false)
        );
      } else {
        this.loadVisits();
      }
    }
  }

  searchShip = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.loadingShips = true)),
      switchMap((s) =>
        this.shipData
          .find(this.searchModel(s), 0, 20, ['attributes'], true)
          .pipe(
            tap(() => (this.loadingShipsFailed = false)),
            map((r) => r.result),
            catchError(() => {
              this.loadingShipsFailed = true;

              return of([]);
            })
          )
      ),

      tap(() => (this.loadingShips = false))
    );

  searchModel(text: string): ShipSearchModel {
    const model: ShipSearchModel = new ShipSearchModel(null);
    model[this.searchField] = text;

    return model;
  }

  inputFormatter = (ship: NauticalShipModel): string => ship.name.toUpperCase();

  shipFormatter = (ship: NauticalShipModel): string => {
    let f = ship.name.toUpperCase();
    const a = [];

    if (ship.shipType && ship.shipType.name) {
      a.push(ship.shipType.name);
    }
    if (ship.callSign) {
      a.push(
        `${this.translate.instant('nautical.ship.model.callSign')}: ${ship.callSign
        }`
      );
    }
    if (ship.fisheryNumber) {
      a.push(ship.fisheryNumber);
    }
    if (ship.imo) {
      a.push(
        `${this.translate.instant('nautical.ship.model.imo')}: ${ship.imo}`
      );
    } else if (ship.eni) {
      a.push(
        `${this.translate.instant('nautical.ship.model.eni')}: ${ship.eni}`
      );
    }
    if (a.length) {
      f += ` (${a.join(', ')})`;
    }

    return f;
  };

  shipSelected(value: NauticalShipModel) {
    if (value.id) {
      this.selectedShip = value;
      this.selectedVisit = null;
    }

    this.loadVisits();
  }

  loadVisits() {
    this.loadingVisits = true;
    this.visits = [];
    this.visitCount = null;

    this.visitData
      .getListOverviewForShip(this.selectedShip.id, null, null, false, 0, 10)
      .subscribe(
        (r: ResultWrapper<NauticalVisitOverviewModel[]>) => {
          if (r.hasResult) {
            this.visitCount = r.count;
            this.visits = r.result;
            if (r.count == 1) {
              this.selectedVisitId = r.result[0].id;

              this.loadDetails();
            }
            // else {
            //   this.selectedVisitId = this.zVisit.$nautical_portvisit_id;
            // }
          } else {
            this.visitCount = 0;
          }
        },

        (e) => {
          this.notify.showError(
            _.pluck(e.error.messages, 'message').join('\n'),
            this.translate.instant('shared.terms.failed')
          );
        },

        () => (this.loadingVisits = false)
      );
  }

  visitFormatter(visit: NauticalVisitOverviewModel) {
    const f = { id: visit.id, referenceNumber: visit.referenceNumber };
    const a = [];

    if (visit.ata) {
      a.push(
        `${this.translate.instant('nautical.terms.ata')}: ${moment(
          visit.ata
        ).format('DD-MM-YYYY HH:mm')}`
      );
    } else if (visit.eta) {
      a.push(
        `${this.translate.instant('nautical.terms.eta')}: ${moment(
          visit.eta
        ).format('DD-MM-YYYY HH:mm')}`
      );
    }
    if (visit.atd) {
      a.push(
        `${this.translate.instant('nautical.terms.atd')}: ${moment(
          visit.atd
        ).format('DD-MM-YYYY HH:mm')}`
      );
    } else if (visit.etd) {
      a.push(
        `${this.translate.instant('nautical.terms.etd')}: ${moment(
          visit.etd
        ).format('DD-MM-YYYY HH:mm')}`
      );
    }
    if (a.length) {
      f.referenceNumber += ` (${a.join(', ')})`;
    }

    return f;
  }

  movementFormatter(movement: NauticalMovementModel): string {
    let f: string;

    const m = [];
    const a = [];

    if (movement.portWayPointFrom && movement.portWayPointFrom.name) {
      m.push(movement.portWayPointFrom.name, '>');
    } else {
      m.push(this.translate.instant('shared.terms.unknown'), '>');
    }
    if (movement.portWayPointTo && movement.portWayPointTo.name) {
      m.push(movement.portWayPointTo.name);
    } else {
      m.push(this.translate.instant('shared.terms.unknown'));
    }
    if (m.length) {
      f = m.join(' ');
    }
    if (movement.ata) {
      a.push(
        `${this.translate.instant('nautical.terms.ata')}: ${moment(
          movement.ata
        ).format('DD-MM-YYYY HH:mm')}`
      );
    } else if (movement.eta) {
      a.push(
        `${this.translate.instant('nautical.terms.eta')}: ${moment(
          movement.eta
        ).format('DD-MM-YYYY HH:mm')}`
      );
    }
    if (movement.atd) {
      a.push(
        `${this.translate.instant('nautical.terms.atd')}: ${moment(
          movement.atd
        ).format('DD-MM-YYYY HH:mm')}`
      );
    } else if (movement.etd) {
      a.push(
        `${this.translate.instant('nautical.terms.etd')}: ${moment(
          movement.etd
        ).format('DD-MM-YYYY HH:mm')}`
      );
    }
    if (a.length) {
      f += ` (${a.join(', ')})`;
    }

    return f;
  }

  setNull(attribute: string) {
    switch (attribute) {
      case 'ship': {
        this.selectedShip = null;
        this.selectedVisit = null;
        this.selectedVisitId = null;
        this.visitCount = null;
        this.selectedMovement = null;
        this.movementCount = null;
        break;
      }

      case 'visit': {
        this.selectedVisit = null;
        this.selectedVisitId = null;
        this.selectedMovement = null;
        this.movementCount = null;
        break;
      }

      case 'movement': {
        this.selectedMovement = null;
        break;
      }
    }
  }

  returnSuggestion() {
    if (this.selectedVisit && this.selectedVisit.id) {
      this.zVisit.$nautical_portvisit_id = this.selectedVisit.id;
      this.zVisit.nauticalShip = this.selectedVisit.ship.id;
      this.zVisit.shipName = this.selectedVisit.ship.name.toUpperCase();
      this.zVisit.owner = this.selectedVisit.ship.owner;

      if (this.selectedVisit.portAgent) {
        this.zVisit.agent = this.selectedVisit.portAgent.name;
      }
      if (this.selectedMovement) {
        this.zVisit.$nautical_portmovement_id = this.selectedMovement.id;
      } else {
        this.zVisit.$nautical_portmovement_id = null;
      }
      if (this.selectedVisit.ship.maxDraft) {
        this.zVisit.maxDraft = this.selectedVisit.ship.maxDraft.toString();
      }
      if (this.selectedVisit.ship.gt) {
        this.zVisit.contents = this.selectedVisit.ship.gt.toString();
      }

      if (this.selectedVisit.ship.shipAttributes) {
        _.forEach(this.selectedVisit.ship.shipAttributes, (a) => {
          if (a.category == 'ownership') {
            switch (a.fieldName) {
              case 'groupowner': {
                this.zVisit.owner = a.fieldValue;
                break;
              }

              case 'operator': {
                this.zVisit.operator = a.fieldValue;
                break;
              }

              case 'shipmanager': {
                this.zVisit.shipManager = a.fieldValue;
                break;
              }
            }
          } else if (a.category == 'dimensions') {
            switch (a.fieldName) {
              case 'gt':
                if (!this.zVisit.contents) {
                  this.zVisit.contents = a.fieldValue;
                }
                break;
            }
          }
        });
      }

      switch (this.zVisit.movementType) {
        case ZeehavenIJmuidenVisitMovementType.Incoming: {
          if (this.selectedMovement) {
            if (this.selectedMovement.eta) {
              this.zVisit.etaOrEtd = this.selectedMovement.eta.toString();
            }

            if (this.selectedMovement.portWayPointTo) {
              this.zVisit.destination =
                this.selectedMovement.portWayPointTo.name;
            }
          }

          if (this.selectedVisit.previousPort) {
            this.zVisit.origin = this.selectedVisit.previousPort.name;
          }
          break;
        }

        case ZeehavenIJmuidenVisitMovementType.Shifting: {
          if (this.selectedMovement) {
            if (this.selectedMovement.eta) {
              this.zVisit.etaOrEtd = this.selectedMovement.eta.toString();
            }

            if (this.selectedMovement.portWayPointFrom) {
              this.zVisit.origin = this.selectedMovement.portWayPointFrom.name;
            }
            if (this.selectedMovement.portWayPointTo) {
              this.zVisit.destination =
                this.selectedMovement.portWayPointTo.name;
            }
          }
          break;
        }

        case ZeehavenIJmuidenVisitMovementType.Outgoing: {
          if (this.selectedMovement) {
            if (this.selectedMovement.etd) {
              this.zVisit.etaOrEtd = this.selectedMovement.etd.toString();
            }

            if (this.selectedMovement.portWayPointFrom) {
              this.zVisit.origin = this.selectedMovement.portWayPointFrom.name;
            }
          }

          if (this.selectedVisit.nextPort) {
            this.zVisit.destination = this.selectedVisit.nextPort.name;
          }
          break;
        }
      }
    } else {
      this.zVisit.$nautical_portvisit_id = null;
      this.zVisit.etaOrEtd = moment().format('YYYY-MM-DD[T]HH:mm');

      if (this.selectedShip) {
        this.zVisit.nauticalShip = this.selectedShip.id;
        this.zVisit.shipName = this.selectedShip.name.toUpperCase();
        this.zVisit.owner = this.selectedShip.owner;

        if (this.selectedShip.gt) {
          this.zVisit.contents = this.selectedShip.gt.toString();
        }
        if (this.selectedShip.maxDraft) {
          this.zVisit.maxDraft = this.selectedShip.maxDraft.toString();
        }

        if (this.selectedShip.shipAttributes) {
          _.forEach(this.selectedShip.shipAttributes, (a) => {
            if (a.category == 'ownership') {
              switch (a.fieldName) {
                case 'groupowner': {
                  this.zVisit.owner = a.fieldValue;
                  break;
                }

                case 'operator': {
                  this.zVisit.operator = a.fieldValue;
                  break;
                }

                case 'shipmanager': {
                  this.zVisit.shipManager = a.fieldValue;
                  break;
                }
              }
            } else if (a.category == 'dimensions') {
              switch (a.fieldName) {
                case 'gt':
                  if (!this.zVisit.contents) {
                    this.zVisit.contents = a.fieldValue;
                  }
                  break;
              }
            }
          });
        }
      }
    }

    this.onPickSuggestion.emit(this.zVisit);
  }

  loadDetails() {
    this.selectedMovement = null;
    this.selectedVisit = null;
    this.movementCount = null;

    if (this.selectedVisitId) {
      this.loadingDetails = true;

      this.visitData.getDetails(this.selectedVisitId).subscribe(
        (r: NauticalVisitDetailsModel) => {
          if (r) {
            this.selectedVisit = r;
            this.movementCount = r.portMovements.length;

            if (r.currentMovement) {
              this.selectedMovement = r.currentMovement;
            } else if (r.portMovements.length == 1) {
              this.selectedMovement = r.portMovements[0];
            }
          } else {
            this.notify.showError(
              this.translate.instant('shared.terms.failed')
            );
          }
        },

        (e) => {
          this.notify.showError(
            _.pluck(e.error.messages, 'message').join('\n'),
            this.translate.instant('shared.terms.failed')
          );
        },

        () => (this.loadingDetails = false)
      );
    }
  }
}
