<div *ngIf="!hideCountry" class="form-row">
  <div class="form-group col-12 col-md-4">
    <label [for]="identifier + '_country'">
      <b [ngClass]="{ required: address.country.required }">{{
        'companies.address.model.country' | translate
      }}</b>
    </label>
    <div class="input-group">
      <input
        [(ngModel)]="address.country.value"
        [id]="identifier + '_country'"
        [placeholder]="
          'companies.address.model.placeholder.country' | translate
        "
        type="text"
        class="form-control"
      />
    </div>
  </div>
</div>
<div class="form-row">
  <div class="form-group col-12 col-md-4">
    <label [for]="identifier + '_postalCode'">
      <b [ngClass]="{ required: address.postalCode.required }">{{
        'companies.address.model.postalCode' | translate
      }}</b>
    </label>
    <div class="input-group">
      <input
        [(ngModel)]="address.postalCode.value"
        [id]="identifier + '_postalCode'"
        [placeholder]="
          'companies.address.model.placeholder.postalCode' | translate
        "
        type="text"
        class="form-control"
      />
    </div>
  </div>
  <div
    [ngClass]="hideProvince ? 'col-md-8' : 'col-md-4'"
    class="form-group col-12"
  >
    <label [for]="identifier + '_town'">
      <b [ngClass]="{ required: address.town.required }">{{
        'companies.address.model.city' | translate
      }}</b>
    </label>
    <div class="input-group">
      <input
        [(ngModel)]="address.town.value"
        [id]="identifier + '_town'"
        [placeholder]="'companies.address.model.placeholder.town' | translate"
        type="text"
        class="form-control"
      />
    </div>
  </div>
  <div *ngIf="!hideProvince" class="form-group col-12 col-md-4">
    <label [for]="identifier + '_province'">
      <b [ngClass]="{ required: address.province.required }">{{
        'companies.address.model.province' | translate
      }}</b>
    </label>
    <div class="input-group">
      <input
        [(ngModel)]="address.province.value"
        [id]="identifier + '_province'"
        [placeholder]="
          'companies.address.model.placeholder.province' | translate
        "
        type="text"
        class="form-control"
      />
    </div>
  </div>
</div>
<div class="form-row">
  <div class="form-group col-12 col-md-8">
    <label [for]="identifier + '_street'">
      <b [ngClass]="{ required: address.street.required }">{{
        'companies.address.model.street' | translate
      }}</b>
    </label>
    <div class="input-group">
      <input
        [(ngModel)]="address.street.value"
        [id]="identifier + '_street'"
        [placeholder]="'companies.address.model.placeholder.street' | translate"
        type="text"
        class="form-control"
      />
    </div>
  </div>
  <div class="form-group col-12 col-md-2">
    <label [for]="identifier + '_number'">
      <b [ngClass]="{ required: address.number.required }">{{
        'companies.address.model.number' | translate
      }}</b>
    </label>
    <div class="input-group">
      <input
        [(ngModel)]="address.number.value"
        [id]="identifier + '_number'"
        [placeholder]="'companies.address.model.placeholder.number' | translate"
        [min]="address.number.minimum"
        type="number"
        class="form-control"
      />
    </div>
  </div>
  <div class="form-group col-12 col-md-2">
    <label [for]="identifier + '_addition'">
      <b [ngClass]="{ required: address.addition.required }">{{
        'companies.address.model.addition' | translate
      }}</b>
    </label>
    <div class="input-group">
      <input
        [(ngModel)]="address.addition.value"
        [id]="identifier + '_addition'"
        [placeholder]="
          'companies.address.model.placeholder.addition' | translate
        "
        type="text"
        class="form-control"
      />
    </div>
  </div>
</div>
