import { Component, Input, Output, EventEmitter } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { EventModel } from '../../../../../../shared/models/event.model';
import { ConditionsModalComponent } from '../conditions-modal/conditions-modal.component';

@Component({
  selector: 'conditions-info',
  templateUrl: 'conditions-info.component.html',
})
export class ConditionsInfoComponent {
  @Input() conditions: any[];
  @Input() loadingConditions: boolean;

  @Output() onEvent: EventEmitter<EventModel>;

  constructor(private modal: NgbModal) {
    this.conditions = [];
    this.loadingConditions = false;
    this.onEvent = new EventEmitter<EventModel>();
  }

  addCondition() {
    const conditionsModal = this.modal.open(ConditionsModalComponent);

    // @Input()
    conditionsModal.componentInstance.condition = null;

    // @Output()
    conditionsModal.componentInstance.onEvent.subscribe(
      (conditionsEvent: EventModel) => this.onEvent.emit(conditionsEvent)
    );
  }
}
