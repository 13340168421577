<div class="nautical-map-display-polygon-container">
  <div class="nautical-map" id="nautical-map-polygon-display"></div>
</div>

<table *ngIf="coords" class="table table-bordered">
  <thead>
    <tr>
      <th>Point No.</th>
      <th>Longitude</th>
      <th>Latitude</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let c of coords; let i = index">
      <td>{{ i + 1 }}</td>
      <td>
        <input
          type="number"
          (change)="updateLngLat(i)"
          name="{{ i }}lng"
          [(ngModel)]="c[0]"
        />
      </td>
      <td>
        <input type="number" (change)="updateLngLat(i)" [(ngModel)]="c[1]" />
      </td>
    </tr>
  </tbody>
</table>
