import { Component, Input } from '@angular/core';

@Component({
  selector: 'sh-message',
  template: `<ejs-message
    [content]="content"
    [severity]="severity"
  ></ejs-message>`,
})
export class MessageComponent {
  @Input() content?: string;
  @Input() severity?: string;
}
