<div class="modal-header align-items-center">
  <h1 class="modal-title">{{ 'customUI.memberPicker.title' | translate }}</h1>
  <button
    aria-label="Close"
    (click)="onSelectMembersAction.emit({ members: null, action: 'dismiss' })"
    type="button"
    class="close"
  >
    <span aria-hidden="true">×</span>
  </button>
</div>

<div class="modal-body">
  <!-- Team picker -->
  <div class="input-group mb-3">
    <select
      [(ngModel)]="selectedTeam"
      (change)="selectTeamMembers(selectedTeam.__Id)"
      required
      id="team"
      name="team"
      class="custom-select"
    >
      <option
        *ngFor="let team of teamUIList"
        [ngValue]="team"
        disabled="{{ team.disabled ? 'disabled' : '' }}"
      >
        <ng-container *ngIf="team.name; then teams; else all"></ng-container>

        <ng-template #teams>
          {{ team.name }}
        </ng-template>
        <ng-template #all>
          {{
            team.disabled
              ? '--------------------------'
              : ('customUI.memberPicker.allTeams' | translate)
          }}
        </ng-template>
      </option>
    </select>
    <div class="input-group-append">
      <button
        (click)="openTeamManager()"
        type="button"
        class="btn btn-outline-secondary"
      >
        <i class="fa-regular fa-wrench fa-fw fa-sm"></i>
      </button>
    </div>
  </div>

  <!-- Member picker -->
  <div class="d-flex mb-3">
    <div class="btn-group my-1">
      <button
        (click)="selectAllMembers()"
        type="button"
        class="btn btn-outline-secondary p-0"
      >
        <i class="fa-regular fa-check fa-fw fa-sm text-primary"></i>
      </button>
      <button
        (click)="selectedMembers = []"
        type="button"
        class="btn btn-outline-secondary p-0"
      >
        <i class="fa-solid fa-times fa-fw fa-sm text-danger"></i>
      </button>
    </div>
    <div class="input-group ml-1">
      <div class="input-group-prepend">
        <span class="input-group-text">
          <i class="fa-regular fa-search fa-fw fa-sm"></i>
        </span>
      </div>
      <input
        [(ngModel)]="searchField"
        [placeholder]="'customUI.memberPicker.findMembers' | translate"
        type="text"
        name="searchBox"
        class="form-control"
      />
    </div>
  </div>
  <div style="max-height: 240px; overflow-y: auto" class="border rounded">
    <ng-container
      *ngIf="!loadUsers; then memberPicker; else loading"
    ></ng-container>

    <ng-template #memberPicker>
      <table class="table table-borderless table-hover table-sm mb-0">
        <ng-container
          *ngFor="
            let member of teamMembers | searchMembers: searchField;
            let i = index
          "
        >
          <tr
            *ngIf="
              member.$usermanager_user_givenName !== undefined ||
              member.$usermanager_user_surname !== undefined ||
              isChecked(member.$usermanager_user_id)
            "
          >
            <td scope="row">
              <div class="custom-control custom-checkbox">
                <input
                  [id]="'checkbox-memberPicker-' + i"
                  [checked]="isChecked(member.$usermanager_user_id)"
                  (change)="selectMember(member.$usermanager_user_id)"
                  type="checkbox"
                  class="custom-control-input"
                />
                <label
                  [for]="'checkbox-memberPicker-' + i"
                  class="custom-control-label w-100"
                >
                  <ng-container
                    *ngIf="
                      member.$usermanager_user_givenName === undefined &&
                        member.$usermanager_user_surname === undefined;
                      then memberDeleted;
                      else memberNames
                    "
                  ></ng-container>
                  <ng-template #memberDeleted>
                    ({{ 'user.deleted' | translate }})
                  </ng-template>
                  <ng-template #memberNames>
                    {{ member.$usermanager_user_givenName }}
                    {{ member.$usermanager_user_surname }}
                  </ng-template>
                </label>
              </div>
            </td>
            <td width="1px">
              <button
                (click)="openMemberManager(member.$usermanager_user_id)"
                type="button"
                class="btn btn-link btn-xs text-dark"
              >
                <i class="fa-regular fa-pencil-alt fa-fw fa-sm"></i>
              </button>
            </td>
          </tr>
        </ng-container>
      </table>
    </ng-template>

    <ng-template #loading>
      <sh-loading-indicator
        class="d-flex justify-content-center align-items-center h-100"
      ></sh-loading-indicator>
    </ng-template>
  </div>
</div>

<div class="modal-footer">
  <button
    (click)="onSelectMembersAction.emit({ members: null, action: 'dismiss' })"
    type="button"
    class="btn btn-link w-50"
  >
    {{ 'shared.terms.cancel' | translate }}
  </button>
  <button
    (click)="
      onSelectMembersAction.emit({ members: selectedMembers, action: 'save' })
    "
    type="button"
    class="btn btn-success w-50"
  >
    {{ 'shared.terms.save' | translate }}
  </button>
</div>
