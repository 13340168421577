<div class="modal-header">
  <div class="modal-title">
    {{ 'invoicingFlows.execution.startExecution' | translate }}
  </div>
  <button type="button" class="close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="mb-3">
    {{ 'invoicingFlows.execution.startExecutionDescription' | translate }}
  </div>
  <div class="row">
    <div class="col">
      <div class="form-group">
        <ca-company-financial-select
          [(companyId)]="companyId"
          [(companyFinancialId)]="companyFinancialId"
        >
        </ca-company-financial-select>
      </div>
    </div>
    <div class="col">
      <div class="form-group">
        <label
          ><b>{{ 'invoicingFlows.flow.flow' | translate }}</b></label
        >
        <div class="mb-3 loader-sm" *ngIf="isLoading">
          <sh-loading-indicator></sh-loading-indicator>
        </div>
        <select
          *ngIf="allowFlowSelection"
          class="form-control"
          [(ngModel)]="flow"
        >
          <option *ngFor="let flow of flows" [ngValue]="flow">
            {{ flow.name }}
          </option>
        </select>
        <input
          type="text"
          class="form-control"
          disabled
          *ngIf="flow && !allowFlowSelection"
          [(ngModel)]="flow.name"
        />
      </div>
    </div>
  </div>
  <div class="row" *ngFor="">
    <div class="col"></div>
  </div>
</div>
<div class="card-footer text-right">
  <button type="button" class="btn btn-link" (click)="dismiss()">
    {{ 'shared.terms.cancel' | translate }}
  </button>
  <button type="button" class="btn btn-primary" (click)="ok()">
    {{ 'shared.terms.ok' | translate }}
  </button>
</div>
