import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import {
  CustomDataObjectModel,
  CustomDefaultDataModel
} from '@seahorse/domain';

@Component({
  selector: 'ca-custom-default-data-modal',
  templateUrl: './custom-default-data-modal.component.html',
})
export class CustomDefaultDataModalComponent {
  @Input() title: string;
  @Input() dataObjectKey: string;
  @Input() defaultDataGroup: CustomDefaultDataModel;
  @Input() customDefaultDataSet: CustomDefaultDataModel[];
  @Input() loadingCustomDefaultDataSet: boolean;
  @Input() hideDefaultDataGroupPicker: boolean;
  @Input() defaultDataObjects: CustomDataObjectModel[];

  constructor(
    public _activeModal: NgbActiveModal,
    _translateService: TranslateService
  ) {
    this.title = _translateService.instant('customDefaultData.dialog.title');
    this.customDefaultDataSet = [];
    this.loadingCustomDefaultDataSet = false;
    this.hideDefaultDataGroupPicker = false;
    this.defaultDataObjects = [];
  }

  cancel() {
    this._activeModal.dismiss();
  }

  confirm() {
    const result = this.defaultDataObjects.length
      ? this.defaultDataObjects
      : null;
    this._activeModal.close(result);
  }
}
