import { Component, Input, ChangeDetectorRef, Inject } from '@angular/core';

import { CompanyContactModel } from '@seahorse/domain';
import { ContactDataService } from '../../../../company/services/contact-data.service';
import { InteractionService } from '../../../../shared/services/interaction.service';

@Component({
  selector: 'ca-contact-summary',
  templateUrl: './contact-summary.component.html',
})
export class ContactSummaryComponent {
  isLoading = false;
  isSubmitted = false;
  contact: CompanyContactModel;

  @Input('contact-id') set contactId(value: number) {
    this.isLoading = true;

    this.contactDataService.getById(value).subscribe(
      (result) => {
        this.contact = result.result;
        this.cdr.detectChanges();
      },
      () => {},
      () => {
        this.isLoading = false;
      }
    );
  }

  constructor(
    @Inject('ContactInteractionService')
    private contactInteractionService: InteractionService,
    private contactDataService: ContactDataService,
    private cdr: ChangeDetectorRef
  ) {}

  save() {
    // if (!CompanyContactModel.validate(this.contact)) {
    //     this.isSubmitted = true;
    //     return;
    // }
    // this.contactInteractionService.modify(this.contact);
  }

  delete() {
    // this.contactInteractionService.delete(this.contact);
  }
}
