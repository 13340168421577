import { NgModule } from '@angular/core';
import { InvoiceStatusPipe } from './invoice-status.pipe';
import { InvoiceTypePipe } from './invoice-type.pipe';
import { InvoiceCurrencySymbolPipe } from './invoice-currency-symbol.pipe';
import { InvoiceEventTypePipe } from './invoice-event-type.pipe';
import { InvoiceFlowExecutionStatusPipe } from './invoice-flow-execution-status.pipe';
import { InvoiceLineTypePipe } from './invoice-line-type.pipe';
import { InvoiceStatusCodePipe } from './invoice-status-code.pipe';

@NgModule({
  declarations: [
    InvoiceCurrencySymbolPipe,
    InvoiceEventTypePipe,
    InvoiceFlowExecutionStatusPipe,
    InvoiceStatusCodePipe,
    InvoiceStatusPipe,
    InvoiceTypePipe,
    InvoiceLineTypePipe,
  ],
  exports: [
    InvoiceCurrencySymbolPipe,
    InvoiceEventTypePipe,
    InvoiceFlowExecutionStatusPipe,
    InvoiceStatusCodePipe,
    InvoiceStatusPipe,
    InvoiceTypePipe,
    InvoiceLineTypePipe,
  ],
  providers: [
    InvoiceCurrencySymbolPipe,
    InvoiceEventTypePipe,
    InvoiceFlowExecutionStatusPipe,
    InvoiceStatusCodePipe,
    InvoiceStatusPipe,
    InvoiceTypePipe,
    InvoiceLineTypePipe,
  ],
})
export class InvoicePipesModule {}
