import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import * as _ from 'underscore';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { EventModel } from '../../../shared/models/event.model';
import {
  NauticalShipModel,
  NauticalShipTagModel,
} from '@seahorse/domain';
import { NauticalShipTagComponent } from '../nautical-ship-tag/nautical-ship-tag.component';
import { PreferenceDataService } from '../../../preference/services/preference-data.service';
import { IdentityService } from '../../../core/services/identity.service';
import { PreferenceModel } from '../../../preference/models/preference.model';
import { FieldType } from '@seahorse/domain';
import { Subscription } from 'rxjs';
import { MyFleetModalComponent } from '../../../map/components/my-fleet-modal/my-fleet-modal.component';

@Component({
  selector: 'ca-nautical-ship-details',
  templateUrl: 'nautical-ship-details.component.html',
})
export class NauticalShipDetailsComponent implements OnInit, OnDestroy {
  private subscriptions$ = Array<Subscription>();
  attributes: any[] = [];
  ngbModalOptions: NgbModalOptions = { backdrop: 'static' };

  private preferenceName = 'vessels';
  private preference: PreferenceModel = null;
  ids = [];
  private _ship: NauticalShipModel;

  @Input() additionalAttributes?: string[];
  @Input() allowEdit = false;
  @Input() set ship(ship: NauticalShipModel) {
    this._ship = ship;
    this.createAttributes(ship);
  }
  get ship(): NauticalShipModel {
    return this._ship;
  }
  @Input() showTitle = true;
  @Input() showMyFleet = false;
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onAction = new EventEmitter<EventModel>();

  constructor(
    private translateService: TranslateService,
    private modalService: NgbModal,
    private preferenceDataService: PreferenceDataService,
    private identityService: IdentityService
  ) {}

  ngOnInit() {
    this.setPreferences();
  }

  ngOnDestroy() {
    this.subscriptions$.forEach((s) => s.unsubscribe());
  }

  createAttributes(ship: NauticalShipModel) {
    this.attributes = [];

    if (!ship) {
      return;
    }

    this.attributes.push({
      fieldName: this.translateService.instant(
        'nautical.ship.shipType.shipType'
      ),
      fieldValue: ship.shipType.name,
      fieldCode: 'name',
    });
    this.attributes.push({
      fieldName: this.translateService.instant('nautical.ship.model.flagCode'),
      fieldValue: ship.flagCode,
      fieldCode: 'flagCode',
      fieldType: FieldType.List,
      isRequired: true,
    });
    this.attributes.push({
      fieldName: this.translateService.instant('nautical.ship.model.imo'),
      fieldValue: ship.imo,
      fieldCode: 'imo',
    });
    if (ship.eni || this.allowEdit) {
      this.attributes.push({
        fieldName: this.translateService.instant('nautical.ship.model.eni'),
        fieldValue: ship.eni,
        fieldCode: 'eni',
      });
    }
    if (ship.fisheryNumber || this.allowEdit) {
      this.attributes.push({
        fieldName: this.translateService.instant(
          'nautical.ship.model.fisheryNumber'
        ),
        fieldValue: ship.fisheryNumber,
        fieldCode: 'fisheryNumber',
      });
    }
    if (ship.callSign || this.allowEdit) {
      this.attributes.push({
        fieldName: this.translateService.instant(
          'nautical.ship.model.callSign'
        ),
        fieldValue: ship.callSign,
        fieldCode: 'callSign',
      });
    }
    this.attributes.push({
      fieldName: this.translateService.instant('nautical.ship.model.mmsi'),
      fieldValue: ship.mmsi,
      fieldCode: 'mmsi',
    });

    if (this.additionalAttributes && this.additionalAttributes.length > 0) {
      _.each(this.additionalAttributes, (additionalAttribute) => {
        if (ship[additionalAttribute]) {
          let translatedName = this.translateService.instant(
            'nautical.ship.model.' + additionalAttribute
          );
          if (translatedName === 'nautical.ship.model.' + additionalAttribute) {
            translatedName = additionalAttribute;
          }
          this.attributes.push({
            fieldName: translatedName,
            fieldValue: ship[additionalAttribute],
            fieldCode: additionalAttribute,
          });
        }
      });
    }
  }

  setPreferences() {
    this.preference = _.find(
      this.identityService.getPreferences(),
      (preference) => {
        return (
          this.preferenceName.toLowerCase() === preference.name.toLowerCase()
        );
      }
    );

    if (!this.preference) {
      this.preference = new PreferenceModel();
      this.preference.id = 0;
      this.preference.name = this.preferenceName;
      this.preference.fieldType = 1;
    } else {
      this.ids = JSON.parse(this.preference.fieldValue);
    }

    if (!this.ids.includes(this.ship.baseId)) {
      if (this.ids.length >= 5) {
        this.ids.pop();
      }

      this.ids.unshift(this.ship.baseId);
      this.preference.fieldValue = JSON.stringify(this.ids);

      this.subscriptions$.push(
        this.preferenceDataService.save(this.preference).subscribe(
          (response) => {
            if (response && response.hasResult) {
              this.identityService.setPreferences([response.result]);
              this.preference = response.result;
            }
          },
          () => {},
          () => {}
        )
      );
    }
  }

  addTag() {
    this.modalService.open(NauticalShipTagComponent, this.ngbModalOptions);
  }

  editTag(tag: NauticalShipTagModel) {
    const modalRef = this.modalService.open(
      NauticalShipTagComponent,
      this.ngbModalOptions
    );
    modalRef.componentInstance.editMode = true;
    modalRef.componentInstance.selectedTags.push(tag);
    modalRef.componentInstance.model = tag;
    modalRef.componentInstance.tagId = tag.id;
  }

  editButtonClicked(): void {
    const model = new EventModel();
    model.action = 'editShip';
    this.onAction.emit(model);
  }

  attributeChanged(input): void {
    const model = new EventModel();
    model.action = 'updateAttribute';
    model.data = input;
    this.onAction.emit(model);
  }

  openMyFleet() {
    const ngbModalOptions: NgbModalOptions = { backdrop: 'static' };
    const modalRef = this.modalService.open(
      MyFleetModalComponent,
      ngbModalOptions
    );
    modalRef.componentInstance.shipId = this.ship.id;
    modalRef.result
      .then(() => {})
      .catch(() => {})
      .finally(() => {});
  }
}
