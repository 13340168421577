<div class="nautical-map-display-container">
  <div class="nautical-map" id="nautical-map-display"></div>

  <div #mapPopup></div>
  <div #mapMarker></div>
</div>

<form>
  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label class="control-label"><b>Longitude</b></label>
        <input
          (change)="onChangePositions()"
          #longitude="ngModel"
          type="number"
          name="longitude"
          [(ngModel)]="centerPoint.longitude"
          class="form-control"
        />
      </div>
    </div>
    <div class="col-6">
      <div class="form-group">
        <label class="control-label"><b>Latitude</b></label>
        <input
          (change)="onChangePositions()"
          #latitude="ngModel"
          type="number"
          name="latitude"
          [(ngModel)]="centerPoint.latitude"
          class="form-control"
        />
      </div>
    </div>
  </div>
</form>
