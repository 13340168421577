<div class="modal-header">
  <h1 class="modal-title">{{ 'reports.customdata.editReport' | translate }}</h1>
  <button aria-label="Close" (click)="dismiss()" type="button" class="close">
    <span aria-hidden="true">×</span>
  </button>
</div>
<div class="modal-body">
  <div class="form">
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label
            ><b>{{ 'reports.customdata.name' | translate }}</b></label
          >
          <input type="text" class="form-control" [(ngModel)]="report.name" />
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label
            ><b>{{ 'reports.customdata.dataSource' | translate }}</b></label
          >
          <select
            class="form-control"
            [(ngModel)]="report.customDataObjectSystemCode"
            (change)="objectDefinitionChanged($event)"
          >
            <option
              *ngFor="let definition of definitions"
              [value]="definition.systemCode"
            >
              {{ definition.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label
            ><b>{{ 'reports.customdata.groupBy' | translate }}</b></label
          >
          <input type="text" class="form-control" [(ngModel)]="groupByFields" />
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label
            ><b>{{
              'reports.customdata.numberOfExportRows' | translate
            }}</b></label
          >
          <input
            type="text"
            class="form-control"
            [(ngModel)]="numberOfExportRows"
          />
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="report.customDataObjectSystemCode">
    <h4>{{ 'reports.customdata.filterFields' | translate }}</h4>

    <table
      class="table table-borderless table-sm"
      *ngIf="filterFields && filterFields.length > 0"
    >
      <thead>
        <tr>
          <th>{{ 'reports.customdata.fieldName' | translate }}</th>
          <th>{{ 'reports.customdata.filterCriteria' | translate }}</th>
          <th style="width: 1%"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let field of filterFields">
          <td>
            {{ field.displayName }}
          </td>
          <td>
            <ca-field-operator-form
              [fieldType]="field.fieldType"
              [(operator)]="field.searchOperator"
              [(fieldValue)]="field.searchValue"
            ></ca-field-operator-form>
          </td>
          <td>
            <button
              type="button"
              class="btn btn-outline-secondary"
              (click)="deactivateFilterField(field)"
            >
              <i class="fa-regular fa-minus"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="inactiveFilterFields && inactiveFilterFields.length > 0">
      <div class="text-right">
        <button
          type="button"
          class="btn btn-link"
          *ngIf="filterFields && filterFields.length > 0"
          (click)="showInactiveFilter = !showInactiveFilter"
        >
          {{ 'reports.customdata.showAll' | translate }} &raquo;
        </button>
      </div>
      <div
        *ngIf="showInactiveFilter || !filterFields || filterFields.length === 0"
      >
        <p>{{ 'reports.customdata.addToList' | translate }}</p>
        <div class="text-center">
          <ng-container *ngFor="let field of inactiveFilterFields">
            <button
              type="button"
              class="btn btn-secondary mr-2 mb-2"
              (click)="activateFilterField(field)"
            >
              {{ field.displayName }}
            </button>
          </ng-container>
        </div>
      </div>
    </div>
    <h4>{{ 'reports.customdata.displayFields' | translate }}</h4>
    <table
      class="table table-borderless table-sm"
      *ngIf="activeFields && activeFields.length > 0"
    >
      <thead>
        <tr>
          <th style="width: 1%">
            {{ 'reports.customdata.sortOrder' | translate }}
          </th>
          <th>{{ 'reports.customdata.fieldName' | translate }}</th>
          <th style="width: 1%"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let field of activeFields">
          <td>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="field.sortOrder"
              style="width: 50px"
            />
          </td>
          <td>
            <input type="text" class="form-control" [(ngModel)]="field.name" />
          </td>
          <td>
            <button
              type="button"
              class="btn btn-outline-secondary"
              (click)="deactivateField(field)"
            >
              <i class="fa-regular fa-minus"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="inactiveFields && inactiveFields.length > 0">
      <div class="text-right">
        <button
          type="button"
          class="btn btn-link"
          *ngIf="activeFields && activeFields.length > 0"
          (click)="showInactive = !showInactive"
        >
          {{ 'reports.customdata.showAll' | translate }} &raquo;
        </button>
      </div>
      <div *ngIf="showInactive || !activeFields || activeFields.length === 0">
        <p>{{ 'reports.customdata.addToList' | translate }}</p>
        <div class="text-center">
          <ng-container *ngFor="let field of inactiveFields">
            <button
              type="button"
              class="btn btn-secondary mr-2 mb-2"
              (click)="activateField(field)"
            >
              {{ field.name }}
            </button>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
  <div *ngIf="errors && errors.length > 0" class="alert alert-danger">
    <ul>
      <li *ngFor="let error of errors">
        {{ 'reports.customdata.errors.' + error | translate }}
      </li>
    </ul>
  </div>
</div>
<div class="modal-footer">
  <div class="row">
    <div class="col">
      <button type="button" class="btn btn-link" (click)="dismiss()">
        {{ 'shared.terms.cancel' | translate }}
      </button>
    </div>
    <div class="col">
      <button type="button" class="btn btn-success" (click)="save()">
        {{ 'shared.terms.save' | translate }}
      </button>
    </div>
  </div>
</div>
