import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentDistributionExportButtonComponent } from './export-button/export-button.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TemplateFilesComponent } from './template-files/template-files.component';
import { FilePreviewComponent } from '@seahorse/ui';

@NgModule({
  declarations: [
    ContentDistributionExportButtonComponent,
    TemplateFilesComponent,
  ],
  imports: [CommonModule, FormsModule, TranslateModule, FilePreviewComponent],
  exports: [ContentDistributionExportButtonComponent, TemplateFilesComponent],
})
export class ContentDistributionComponentSharedModule {}
