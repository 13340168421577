<div class="modal-header">
  <div class="modal-title">{{ 'shared.terms.addAttribute' | translate }}</div>
  <button type="button" class="close" closeModal>
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form #addAtrributeForm="ngForm">
    <div class="form-group">
      <label
        ><b>{{ 'nautical.ship.attribute.category' | translate }}</b></label
      >:
      <ng-select
        required
        #category
        name="category"
        [items]="categories"
        [addTag]="true"
        [placeholder]="'nautical.ship.attribute.addCategory' | translate"
        bindValue="name"
        bindLabel="display"
        [(ngModel)]="selectedCategory"
        (change)="setCategory($event)"
      >
      </ng-select>
      <small *ngIf="submitted && model.category == null" class="text-danger">
        {{ 'nautical.ship.errors.categoryRequired' | translate }}</small
      >
    </div>

    <div class="form-group">
      <label
        ><b>{{ 'nautical.ship.attribute.fieldName' | translate }}</b></label
      >:
      <input
        required
        #fieldName="ngModel"
        type="text"
        name="fieldName"
        [(ngModel)]="model.fieldName"
        class="form-control"
      />
      <small
        *ngIf="submitted && (fieldName.value == null || fieldName.value === '')"
        class="text-danger"
      >
        {{ 'nautical.ship.errors.fieldName' | translate }}</small
      >
    </div>

    <div class="form-group">
      <label
        ><b>{{ 'nautical.ship.attribute.fieldValue' | translate }}</b></label
      >:
      <input
        required
        #fieldValue="ngModel"
        type="text"
        name="fieldValue"
        [(ngModel)]="model.fieldValue"
        class="form-control"
      />
      <small
        *ngIf="
          submitted && (fieldValue.value == null || fieldValue.value === '')
        "
        class="text-danger"
      >
        {{ 'nautical.ship.errors.fieldValue' | translate }}</small
      >
    </div>
  </form>

  <div class="d-flex">
    <div class="flex-grow-1">
      <button type="button" class="btn btn-block btn-link" closeModal>
        {{ 'shared.terms.cancel' | translate }}
      </button>
    </div>
    <div class="flex-grow-1">
      <button type="button" class="btn btn-block btn-success" (click)="save()">
        {{ 'shared.terms.save' | translate }}
      </button>
    </div>
  </div>
</div>
