<sh-loading-indicator
  *ngIf="isLoading"
  class="loading-indicator-overlay"
></sh-loading-indicator>

<ul
  class="list-group"
  *ngIf="!isLoading && area.markers && area.markers.length > 0"
>
  <li
    *ngFor="let marker of area.markers"
    class="list-group-item d-flex justify-content-between align-items-center p-2"
  >
    <span
      (click)="onSelectVessel.emit(marker)"
      class="ml-2 mb-2 mt-2 cursor-pointer w-100 text-uppercase"
      >{{ marker.name }}</span
    >

    <div class="dropdown p-0 delete-vessel">
      <button
        type="button"
        class="btn btn-light dropdown-toggle"
        id="rowMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        (click)="getShip(marker)"
      >
        <i class="fa-solid fa-ellipsis-v fa-fw"></i>
      </button>
      <div class="btn dropdown-menu activity" aria-labelledby="rowMenuButton">
        <ca-map-popup-actions
          [ship]="ship"
          [marker]="marker"
          [activeVisit]="activeVisit"
        >
        </ca-map-popup-actions>
      </div>
    </div>
  </li>
</ul>

<div
  class="ml-2 mb-2 mt-2 w-100"
  *ngIf="
    !isLoading && area.polygon && (!area.markers || area.markers.length === 0)
  "
>
  {{ 'map.areaActivity.areaActivityShipsNotFound' | translate }}
</div>

<div class="ml-2 mb-2 mt-2 w-100" *ngIf="!isLoading && !area.polygon">
  {{ 'map.areaActivity.areaActivitiesNotFound' | translate }}
</div>
