import { Component, Output, EventEmitter } from '@angular/core';
import { EventModel } from '../../../../../shared/models/event.model';

@Component({
  selector: 'task-definition-list-details-header',
  templateUrl: './task-definition-list-details-header.component.html',
})
export class TaskDefinitionListDetailsHeaderComponent {
  @Output() onEvent: EventEmitter<EventModel>;

  constructor() {
    this.onEvent = new EventEmitter<EventModel>();
  }
}
