import { Component, Input } from '@angular/core';
import { TaskDefinitionListModel } from '../../models/task-definition-list.model';

@Component({
  selector: 'task-definition-list-info',
  templateUrl: './task-definition-list-info.component.html',
})
export class TaskDefinitionListInfoComponent {
  @Input() taskDefinitionList: TaskDefinitionListModel;
  @Input() loading: boolean;

  constructor() {
    this.taskDefinitionList = null;
    this.loading = false;
  }
}
