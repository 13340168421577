import {
  ConvertObjectMappingModel,
  SourceValueType,
} from '../../layout/models/convert-object-mapping.model';

export class ExpenseHelpers {
  static getInvoiceMapping(source): ConvertObjectMappingModel[] {
    const output = [];
    const constant = source?.toLowerCase();
    switch (constant) {
      case 'portcallfile': {
        const companyField = new ConvertObjectMappingModel(
          'companyId',
          SourceValueType.FieldValue
        );
        companyField.sourceFields = ['customer'];
        output.push(companyField);

        const descriptionField = new ConvertObjectMappingModel(
          'description',
          SourceValueType.FieldValue
        );
        descriptionField.sourceFields = ['name'];
        output.push(descriptionField);
        break;
      }
      default:
        break;
    }

    return output;
  }

  static getInvoiceLineMapping(): ConvertObjectMappingModel[] {
    const amountField = new ConvertObjectMappingModel(
      'amount',
      SourceValueType.FieldValue
    );
    amountField.sourceFields = ['amountActual'];

    const descriptionField = new ConvertObjectMappingModel(
      'description',
      SourceValueType.FieldValue
    );
    descriptionField.sourceFields = ['description'];

    const tariffField = new ConvertObjectMappingModel(
      'tariffId',
      SourceValueType.FieldValue
    );
    tariffField.sourceFields = ['tariff'];

    const totalField = new ConvertObjectMappingModel(
      'totalAmount',
      SourceValueType.FieldValue
    );
    totalField.sourceFields = ['amountActual'];

    const quantityField = new ConvertObjectMappingModel(
      'quantity',
      SourceValueType.FieldValue
    );
    quantityField.sourceFields = ['quantity'];

    const typeField = new ConvertObjectMappingModel(
      'lineType',
      SourceValueType.DefaultValue
    );
    typeField.fixValue = 1;

    return [
      amountField,
      descriptionField,
      tariffField,
      totalField,
      quantityField,
      typeField,
    ];
  }
}
