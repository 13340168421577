import { Component, enableProdMode } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Angulartics2GoogleGlobalSiteTag } from 'angulartics2/gst';
import { Languages } from './core/models/language.model';

import { CaEnvService } from './shared/services/env.service';
import { loadCldr } from '@syncfusion/ej2-base';

import * as numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import * as gregorian from 'cldr-data/main/nl/ca-gregorian.json';
import * as numbers from 'cldr-data/main/nl/numbers.json';
import * as timeZoneNames from 'cldr-data/main/nl/timeZoneNames.json';
import { ApplicationInsightsService } from '@seahorse/common';

loadCldr(
  numberingSystems['default'],
  gregorian['default'],
  numbers['default'],
  timeZoneNames['default']
);

@Component({
  selector: 'ca-root',
  templateUrl: './customer-app.component.html',
})
export class CustomerAppComponent {
  theme: string;

  constructor(
    applicationInsights: ApplicationInsightsService,
    translate: TranslateService,
    environment: CaEnvService,
    angulartics: Angulartics2GoogleGlobalSiteTag
  ) {
    if (!environment.enableDebug) {
      enableProdMode();
    }

    translate.addLangs(Languages.map((language) => language.value));
    translate.setDefaultLang('nl');

    this.theme = environment.theme;
    const bodies = document.getElementsByTagName('body');
    for (let i = 0; i < bodies.length; i++) {
      bodies[i].setAttribute('apptheme', this.theme);
    }

    angulartics.startTracking();
  }
}
