import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationService } from '@seahorse/common';
import {
  CustomDataBaseModel,
  CustomDataContentService,
  ObjectDefinitionConfigurationModel,
  ProxyService,
  SearchOperators,
} from '@seahorse/domain';
import {
  CustomDataFormService,
  DataCollectionConfig,
  DataField,
  DataListComponent,
} from '@seahorse/temp';
import { CardComponent } from '@seahorse/ui';
import { mergeMap } from 'rxjs/operators';
import * as _ from 'underscore';

@Component({
  selector: 'ca-purchase-invoice-list',
  templateUrl: './purchase-invoice-list.component.html',
  standalone: true,
  imports: [CommonModule, CardComponent, DataListComponent, TranslateModule],
})
export class PurchaseInvoiceListComponent implements OnInit {
  @Input() fieldCode?: string;
  @Input() parentId?: string;

  render =
    inject(ProxyService).findObjectDefinitionBySystemCode('purchaseEntry') !=
    null;
  collectionConfig?: DataCollectionConfig;
  items = [];

  private readonly OBJECT_TYPE = 'purchaseEntry';
  private _objectDefinition = inject(ProxyService).objectDefinitions.find(
    (x) => x.systemCode === this.OBJECT_TYPE
  );
  private _customDataService = inject(CustomDataContentService);
  private _customDataFormService = inject(CustomDataFormService);
  private _notificationService = inject(NotificationService);

  ngOnInit() {
    if (!this.render) {
      return;
    }

    this.setCollectionConfig();
    this.loadData();
  }

  onClick(data: CustomDataBaseModel) {
    const fields = this._objectDefinition.objectFieldDefinitions.map(
      (x) => x.systemCode
    );
    this._customDataFormService
      .openFormModal(this._objectDefinition, fields, data)
      .pipe(
        mergeMap((value) =>
          this._customDataService.updateCustomerData(
            this.OBJECT_TYPE,
            value.__Id,
            value
          )
        )
      )
      .subscribe(() => {
        this.loadData();
      });
  }

  private setCollectionConfig() {
    const config: ObjectDefinitionConfigurationModel = JSON.parse(
      this._objectDefinition.templateCode
    );

    let displayFields;

    if (config?.link?.mapping?.displayFields) {
      displayFields = config.link.mapping.displayFields;
    } else {
      displayFields = this._objectDefinition.objectFieldDefinitions.map(
        (x) => x.systemCode
      );
    }

    const fields = displayFields.map((x) =>
      this._objectDefinition.objectFieldDefinitions.find(
        (f) => f.systemCode === x
      )
    );

    this.collectionConfig = {
      displayFields: fields.map((field) =>
        DataField.fromFieldDefinition(field, false)
      ),
      objectDefinition: this._objectDefinition,
    };
  }

  private loadData() {
    const fields = [
      {
        fieldName: this.fieldCode,
        searchOperator: SearchOperators.Contains,
        searchValue: this.parentId,
      },
    ];

    this._customDataService
      .searchActiveCustomerDataByFields(this.OBJECT_TYPE, fields)
      .subscribe((response) => {
        if (response.hasResult) {
          this.items = response.result;
        } else {
          if (response.messages && response.messages.length > 0) {
            this._notificationService.showError(
              _.pluck(response.messages, 'message').join('\n'),
              'shared.terms.failed'
            );
          } else {
            this._notificationService.showError(
              'shared.terms.save',
              'shared.terms.failed'
            );
          }
        }
      });
  }
}
