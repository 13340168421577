import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  forwardRef,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbDate, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IdentityServiceBase } from '@seahorse/auth';
import { HasFeatureDirective } from '@seahorse/ui';
import {
  ChangedEventArgs,
  DatePickerModule,
  DateTimePickerModule,
  MaskedDateTimeService,
} from '@syncfusion/ej2-angular-calendars';
import * as moment from 'moment';
import {
  DateTimePartialModel,
  IncrementValuesListItem,
} from '../../form/form.model';
import { BaseControl } from '../base-control';
import { DateTimeObject } from './date-time-object.model';
import { TimePickerModule } from '@syncfusion/ej2-angular-calendars';

@Component({
  selector: 'temp-date-time-picker',
  templateUrl: 'date-time-picker.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimePickerComponent),
      multi: true,
    },
    {
      provide: BaseControl,
      useExisting: forwardRef(() => DateTimePickerComponent),
    },
    MaskedDateTimeService,
  ],
  styles: [
    `
      :host {
        width: 100%;
      }

      .sf-button {
        padding: revert;
      }
    `,
  ],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    NgbDatepickerModule,
    DatePickerModule,
    DateTimePickerModule,
    TimePickerModule,
  ],
})
export class DateTimePickerComponent
  extends BaseControl<
    moment.Moment | Date | NgbDate | string | DateTimePartialModel
  >
  implements OnInit
{
  sfConfig = {
    datePickerMask: { day: 'dd', month: 'mm', year: 'yyyy' },
    dateTimePickerMask: {
      day: 'dd',
      month: 'mm',
      year: 'yyyy',
      hour: 'hh',
      minute: 'mm',
    },
    timePickerMask: { hour: 'hh', minute: 'mm' },
    firstDayOfWeek: 1,
  };

  locale = inject(TranslateService).getDefaultLang();
  private _identityService = inject(IdentityServiceBase);

  @Input()
  set dateTime(dateTime: moment.Moment | Date | NgbDate | string) {
    this.sfDate = this.dateTimeToMoment(dateTime)?.toDate() ?? null;

    if (this.allowPartialValue === true && !this.partialValue) {
      if (this.partialValue === null || this.sfDate === null) {
        this.partialValue = {
          date: { value: null, isEmpty: true, sfDate: null },
          time: { value: null, isEmpty: true, sfDate: null },
        };
      }
    }

    if (dateTime) {
      if (dateTime instanceof NgbDate) {
        this.dateTimeFormat = 'ngbDate';

        if (this.allowPartialValue) {
          const partialMonth =
            dateTime.month < 10 ? `'0'${dateTime.month}` : dateTime.month;
          const partialDay =
            dateTime.day < 10 ? `'0'${dateTime.day}` : dateTime.day;
          this.partialValue.date.value = `${dateTime.year}-${partialMonth}-${partialDay}`;
          this.partialValue.date.isEmpty = false;
          this.partialValue.date.sfDate =
            this.dateTimeToMoment(this.partialValue.date.value)?.toDate() ??
            null;
        }
      } else if (moment.isMoment(dateTime)) {
        this.dateTimeFormat = 'moment';

        if (this.allowPartialValue) {
          const parts = dateTime.format('YYYY-MM-DDTHH:mm:ss').split('T');
          this.partialValue.date.value = parts[0];
          this.partialValue.date.isEmpty = false;
          this.partialValue.date.sfDate =
            this.dateTimeToMoment(this.partialValue.date.value)?.toDate() ??
            null;
          this.partialValue.time.value = parts[1];
          this.partialValue.time.isEmpty = false;
          this.partialValue.time.sfDate =
            this.dateTimeToMoment(this.partialValue.time.value)?.toDate() ??
            null;
        }
      } else if (moment.isDate(dateTime)) {
        this.dateTimeFormat = 'date';

        if (this.allowPartialValue) {
          const isoString = dateTime.toISOString();
          const parts = isoString
            .substring(0, isoString.lastIndexOf('.'))
            .split('T'); // e.g. 00:00:00.000Z
          this.partialValue.date.value = parts[0];
          this.partialValue.date.isEmpty = false;
          this.partialValue.date.sfDate =
            this.dateTimeToMoment(this.partialValue.date.value)?.toDate() ??
            null;
          this.partialValue.time.value = parts[1];
          this.partialValue.time.isEmpty = false;
          this.partialValue.time.sfDate =
            this.dateTimeToMoment(this.partialValue.time.value)?.toDate() ??
            null;
        }
      } else if (typeof dateTime === 'string') {
        if (dateTime.split('T').length === 2) {
          if (dateTime.split(':').length === 2) {
            this.dateTimeFormat = 'YYYY-MM-DDTHH:mm';
          } else if (dateTime.split(':').length === 3) {
            this.dateTimeFormat = 'YYYY-MM-DDTHH:mm:ss';
          }

          if (this.allowPartialValue) {
            const parts = (
              dateTime.indexOf('.') > -1
                ? dateTime.substring(0, dateTime.lastIndexOf('.')) // e.g. 00:00:00.000Z
                : dateTime
            ).split('T');
            this.partialValue.date.value = parts[0];
            this.partialValue.date.isEmpty = false;
            this.partialValue.date.sfDate =
              this.dateTimeToMoment(this.partialValue.date.value)?.toDate() ??
              null;
            this.partialValue.time.value = parts[1];
            this.partialValue.time.isEmpty = false;
            this.partialValue.time.sfDate =
              this.dateTimeToMoment(this.partialValue.time.value)?.toDate() ??
              null;
          }
        } else if (dateTime.split(':').length === 2) {
          this.dateTimeFormat = 'HH:mm';

          if (this.allowPartialValue) {
            this.partialValue.time.value = dateTime;
            this.partialValue.time.isEmpty = false;
            this.partialValue.time.sfDate =
              this.dateTimeToMoment(dateTime)?.toDate() ?? null;
          }
        } else if (dateTime.split(':').length === 3) {
          this.dateTimeFormat = 'HH:mm:ss';

          if (this.allowPartialValue) {
            const partialTime =
              dateTime.indexOf('.') > -1
                ? dateTime.substring(0, dateTime.lastIndexOf('.')) // e.g. 00:00:00.000Z
                : dateTime;
            this.partialValue.time.value = partialTime;
            this.partialValue.time.isEmpty = false;
            this.partialValue.time.sfDate =
              this.dateTimeToMoment(dateTime)?.toDate() ?? null;
          }
        } else if (dateTime.split('-').length === 3) {
          this.dateTimeFormat = 'YYYY-MM-DD';

          if (this.allowPartialValue) {
            this.partialValue.date.value = dateTime;
            this.partialValue.date.isEmpty = false;
            this.partialValue.date.sfDate =
              this.dateTimeToMoment(dateTime)?.toDate() ?? null;
          }
        }
      }
    }

    if (this.allowPartialValue !== true) {
      // possible undefined or null
      this._dateTime = dateTime;

      if (!dateTime && dateTime !== null && this.dateTimeChange !== undefined) {
        this.dateTimeChange.emit(null);
      }
    } else {
      if (this.sfDate === null) {
        if (this.partialValue === null) {
          this.partialValue = {
            date: { value: null, isEmpty: true, sfDate: null },
            time: { value: null, isEmpty: true, sfDate: null },
          };
        } else {
          const arr = [];
          if (!this.partialValue.date.isEmpty) {
            arr.push(this.partialValue.date.value);
            this.dateTimeFormat = 'YYYY-MM-DD';
          }
          if (!this.partialValue.time.isEmpty) {
            arr.push(this.partialValue.time.value);
            this.dateTimeFormat = 'HH:mm';
          }
          if (arr.length === 0) {
            this._dateTime = null;
            this.dateTimeFormat = 'YYYY-MM-DDTHH:mm';
          } else if (arr.length === 1) {
            this._dateTime = arr[0];
          } else {
            this._dateTime = arr[0] + 'T' + arr[1];
            this.dateTimeFormat = 'YYYY-MM-DDTHH:mm';
          }
        }
      } else if (
        !this.partialValue.date.isEmpty &&
        this.partialValue.time.isEmpty
      ) {
        this._dateTime = this.partialValue.date.value;
      } else if (
        this.partialValue.date.isEmpty &&
        !this.partialValue.time.isEmpty
      ) {
        this._dateTime = this.partialValue.time.value;
      } else {
        this._dateTime =
          this.partialValue.date.value + 'T' + this.partialValue.time.value;
      }

      if (!dateTime && dateTime !== null && this.dateTimeChange !== undefined) {
        this.dateTimeChange.emit(this.partialValue);
      }
    }

    this.setDateTimeObjects();
    this.validate(false);
  }

  get dateTime(): moment.Moment | Date | NgbDate | string {
    return this._dateTime;
  }

  @Input() invalidAfter: moment.Moment | Date | NgbDate | string;
  @Input() invalidBefore: moment.Moment | Date | NgbDate | string;

  private _dateTimeFormat: string; // 'moment', 'date', 'ngbDate ,'YYYY-MM-DDTHH:mm(:ss)' & custom format
  @Input() set dateTimeFormat(val: string) {
    this._dateTimeFormat = val;
  }
  get dateTimeFormat() {
    return this._dateTimeFormat || 'YYYY-MM-DDTHH:mm';
  }

  @Input() partialValue: DateTimePartialModel;
  private _allowPartialValue = false;
  @Input() set allowPartialValue(val: boolean) {
    this._allowPartialValue = val;
    if (val && this.partialValue === null) {
      this.partialValue = {
        date: { value: null, isEmpty: true, sfDate: null },
        time: { value: null, isEmpty: true, sfDate: null },
      };
    }
  }
  get allowPartialValue() {
    return this._allowPartialValue;
  }

  @Input() isReadOnly!: boolean;
  @Input() isContained!: boolean;
  @Input() hideDate!: boolean;
  @Input() hideTime!: boolean;
  @Input() hideClear!: boolean;
  @Input() hideNow!: boolean;
  @Input() hideCalendar!: boolean;
  @Input() required!: boolean;
  @Input() incrementValuesList?: IncrementValuesListItem[];

  @Output() dateTimeChange!: EventEmitter<
    moment.Moment | Date | NgbDate | string | DateTimePartialModel
  >;

  isInvalid!: boolean;
  uniqueStr!: string;
  year!: DateTimeObject;
  month!: DateTimeObject;
  day!: DateTimeObject;
  hour!: DateTimeObject;
  minute!: DateTimeObject;
  ids!: string[];
  ngbDate: NgbDate;

  private _dateTime: moment.Moment | Date | NgbDate | string;

  sfDate: Date | null = null;

  get sfPickerEnabled() {
    return this._identityService.hasFeature('sfDatePicker');
  }

  constructor() {
    super();

    this.dateTime = null;
    this.hideDate = false;
    this.hideTime = false;
    this.invalidBefore = null;
    this.invalidAfter = null;
    this.dateTimeFormat = 'YYYY-MM-DDTHH:mm';
    this.isReadOnly = false;
    this.required = false;
    this.isContained = false;
    this.hideClear = false;
    this.hideNow = false;
    this.hideCalendar = false;
    this.dateTimeChange = new EventEmitter<
      moment.Moment | Date | NgbDate | string | DateTimePartialModel
    >(true);
    this.isInvalid = false;
    this.uniqueStr = null;
    this.year = null;
    this.month = null;
    this.day = null;
    this.hour = null;
    this.minute = null;
    this.ids = [];
    this.ngbDate = new NgbDate(null, null, null);
    this._dateTime = null;
    this.partialValue = {
      date: { value: null, isEmpty: true, sfDate: null },
      time: { value: null, isEmpty: true, sfDate: null },
    };
  }

  ngOnInit() {
    this.setDateTimeObjects();
  }

  onSfDateChange(event: ChangedEventArgs, timeOnly = false, dateOnly = false) {
    if (this.allowPartialValue !== true) {
      // possible undefined or null
      if (!event?.value) {
        this.dateTime = null;
        this.dateTimeChange.emit(null);
        return;
      }
    } else {
      if (!event?.value) {
        if (this.partialValue === undefined || this.partialValue === null) {
          this.partialValue = {
            date: { value: null, isEmpty: true, sfDate: null },
            time: { value: null, isEmpty: true, sfDate: null },
          };
        }

        if (timeOnly) {
          this.partialValue.time.value = null;
          this.partialValue.time.isEmpty = true;
          this.partialValue.time.sfDate = null;
        } else if (dateOnly) {
          this.partialValue.date.value = null;
          this.partialValue.date.isEmpty = true;
          this.partialValue.date.sfDate = null;
        }
        this.dateTimeChange.emit(this.partialValue);
        return;
      }
    }

    const offset = moment(event.value).utcOffset();
    const dateTimeWithOffset = moment(event.value).add(offset);
    const formattedDateTime = dateTimeWithOffset.format(
      timeOnly ? 'HH:mm' : dateOnly ? 'YYYY-MM-DD' : 'YYYY-MM-DDTHH:mm'
    );

    this.dateTime = formattedDateTime;
    if (this.allowPartialValue) {
      this.dateTimeChange.emit(this.partialValue);
    } else {
      this.dateTimeChange.emit(this.dateTime);
    }
  }

  setDateTimeObjects() {
    const dateTimeMoment = this.dateTimeToMoment(this.dateTime);
    this.ids = [];

    if (!this.uniqueStr) {
      this.uniqueStr = Math.random().toString(16).split('.')[1];
    }

    this.year = new DateTimeObject({
      id: `year-${this.uniqueStr}`,
      display: dateTimeMoment ? dateTimeMoment.format('YYYY') : '',
      min: 1,
      max: 9999,
      maxLength: 4,
      isInvalid: false,
    });

    this.month = new DateTimeObject({
      id: `month-${this.uniqueStr}`,
      display: dateTimeMoment ? dateTimeMoment.format('MM') : '',
      min: 1,
      max: 12,
      maxLength: 2,
      isInvalid: false,
    });

    this.day = new DateTimeObject({
      id: `day-${this.uniqueStr}`,
      display: dateTimeMoment ? dateTimeMoment.format('DD') : '',
      min: 1,
      max: 31,
      maxLength: 2,
      isInvalid: false,
    });

    this.hour = new DateTimeObject({
      id: `hour-${this.uniqueStr}`,
      display: dateTimeMoment ? dateTimeMoment.format('HH') : '',
      min: 0,
      max: 23,
      maxLength: 2,
      isInvalid: false,
    });

    this.minute = new DateTimeObject({
      id: `minute-${this.uniqueStr}`,
      display: dateTimeMoment ? dateTimeMoment.format('mm') : '',
      min: 0,
      max: 59,
      maxLength: 2,
      isInvalid: false,
    });

    if (!this.hideDate) {
      this.year.id = `year-${this.uniqueStr}`;
      this.month.id = `month-${this.uniqueStr}`;
      this.day.id = `day-${this.uniqueStr}`;

      this.ids.push(this.day.id, this.month.id, this.year.id);

      if (dateTimeMoment) {
        this.ngbDate = new NgbDate(
          +this.year.display,
          +this.month.display,
          +this.day.display
        );
      }
    }

    if (!this.hideTime) {
      this.hour.id = `hour-${this.uniqueStr}`;
      this.minute.id = `minute-${this.uniqueStr}`;

      this.ids.push(this.hour.id, this.minute.id);
    }
  }

  onDateSelect(ngbDate: NgbDate) {
    const ngbDateMoment = moment(
      `${ngbDate.year}-${ngbDate.month}-${ngbDate.day} 00:00:00`,
      'YYYY-M-D H:m:s'
    );

    this.year.display = ngbDateMoment.format('YYYY');
    this.year.isInvalid = false;
    this.month.display = ngbDateMoment.format('MM');
    this.month.isInvalid = false;
    this.day.display = ngbDateMoment.format('DD');
    this.day.isInvalid = false;

    if (!this.hideTime) {
      this.hour.display = ngbDateMoment.format('HH');
      this.hour.isInvalid = false;
      this.minute.display = ngbDateMoment.format('mm');
      this.minute.isInvalid = false;
    }

    this.validate(true);
  }

  onKeyDown(e: KeyboardEvent, input: string) {
    const nextFocusKeys = /-|:|;|\.|,| /g;
    const nextInput =
      this.ids[
        this.ids.indexOf(
          this.uniqueStr ? `${input}-${this.uniqueStr}` : input
        ) + 1
      ];
    const previousInput =
      this.ids[
        this.ids.indexOf(
          this.uniqueStr ? `${input}-${this.uniqueStr}` : input
        ) - 1
      ];

    if (this[input].display !== '' && e.key.match(nextFocusKeys)) {
      if (nextInput) {
        document.getElementById(nextInput).focus();
      }

      this[input].display = this[input].display
        ? this[input].display.replace(nextFocusKeys, '')
        : '';
    }

    if (
      previousInput &&
      this[input].display === '' &&
      e.key.match(/Backspace/g)
    ) {
      document.getElementById(previousInput).focus();
    }
  }

  onKeyUp(e: KeyboardEvent, input: string) {
    const nextInput =
      this.ids[
        this.ids.indexOf(
          this.uniqueStr ? `${input}-${this.uniqueStr}` : input
        ) + 1
      ];
    const previousInput =
      this.ids[
        this.ids.indexOf(
          this.uniqueStr ? `${input}-${this.uniqueStr}` : input
        ) - 1
      ];

    if (
      (nextInput &&
        this[nextInput.split('-')[0]].display === '' &&
        this[input].display.length === this[input].maxLength &&
        !e.key.match(/\D+/g)) ||
      (this[input].display === '' && e.key.match(/ArrowRight/g))
    ) {
      document.getElementById(nextInput).focus();
    }

    if (this[input].display === '' && e.key.match(/ArrowLeft/g)) {
      if (previousInput) {
        document.getElementById(previousInput).focus();
      } else {
        this.focusLastInput();
      }
    }

    if (e.key.match(/Enter/g)) {
      document.getElementById(this[input].id).blur();
    }
  }

  onFocusOut(input: string) {
    this[input].isInvalid = true;
    const validationStr =
      this[input].display === ''
        ? this[input].min.toString()
        : this[input].display;

    if (!validationStr.match(/\D+/g)) {
      const validationInt = +validationStr;

      if (
        validationInt >= this[input].min &&
        validationInt <= this[input].max
      ) {
        this[input].isInvalid = false;

        if (this[input].display !== '') {
          this[input].display =
            '0'.repeat(
              this[input].maxLength - validationInt.toString().length
            ) + validationInt.toString();
        }

        this.validate(true);
      }
    }
  }

  focusLastInput() {
    document.getElementById(this.ids[this.ids.length - 1]).focus();
  }

  setNull() {
    this.year.display = '';
    this.year.isInvalid = false;
    this.month.display = '';
    this.month.isInvalid = false;
    this.day.display = '';
    this.day.isInvalid = false;
    this.hour.display = '';
    this.hour.isInvalid = false;
    this.minute.display = '';
    this.minute.isInvalid = false;

    this.validate(true);
  }

  setNow() {
    this.year.display = moment().format('YYYY');
    this.year.isInvalid = false;
    this.month.display = moment().format('MM');
    this.month.isInvalid = false;
    this.day.display = moment().format('DD');
    this.day.isInvalid = false;
    this.hour.display = moment().format('HH');
    this.hour.isInvalid = false;
    this.minute.display = moment().format('mm');
    this.minute.isInvalid = false;

    this.validate(true);
  }

  dateTimeToMoment(
    dateTime: moment.Moment | Date | NgbDate | string
  ): moment.Moment {
    let toMoment: moment.Moment = null;

    if (dateTime) {
      if (dateTime instanceof NgbDate) {
        if (dateTime.year && dateTime.month && dateTime.day) {
          toMoment = moment(
            `${dateTime.year}-${dateTime.month}-${dateTime.day}`,
            'YYYY-M-D'
          );
        }
      } else if (moment.isMoment(dateTime)) {
        toMoment = dateTime;
      } else if (moment.isDate(dateTime)) {
        toMoment = moment(dateTime);
      } else if (this.dateTimeFormat) {
        toMoment = moment(dateTime, this.dateTimeFormat);
      } else if (dateTime.split('T').length === 2) {
        if (dateTime.split(':').length === 2) {
          toMoment = moment(dateTime, 'YYYY-MM-DDTHH:mm');
        } else if (dateTime.split(':').length === 3) {
          toMoment = moment(dateTime, 'YYYY-MM-DDTHH:mm:ss');
        }
      } else if (dateTime.split(':').length === 2) {
        toMoment = moment(dateTime, 'HH:mm');
      } else if (dateTime.split(':').length === 3) {
        toMoment = moment(dateTime, 'HH:mm:ss');
      }
    }

    return toMoment;
  }

  validate(returnDateTime: boolean) {
    this.ngbDate = new NgbDate(
      this.year.display !== '' ? +this.year.display : null,
      this.month.display !== '' ? +this.month.display : null,
      this.day.display !== '' ? +this.day.display : null
    );

    if (
      !this.isReadOnly &&
      !this.year.isInvalid &&
      !this.month.isInvalid &&
      !this.day.isInvalid &&
      !this.hour.isInvalid &&
      !this.minute.isInvalid
    ) {
      let dateTimeMoment: moment.Moment;
      let isInvalid = this.dateTime ? true : false;

      if (!this.hideDate && !this.hideTime) {
        if (
          this.year.display === '' ||
          this.month.display === '' ||
          this.day.display === '' ||
          this.hour.display === '' ||
          this.minute.display === ''
        ) {
          isInvalid = false;
        } else {
          dateTimeMoment = moment(
            `${this.year.display}-${this.month.display}-${this.day.display}T${this.hour.display}:${this.minute.display}:00`,
            'YYYY-MM-DDTHH:mm:ss'
          );
        }
      } else if (!this.hideDate) {
        if (
          this.year.display === '' ||
          this.month.display === '' ||
          this.day.display === ''
        ) {
          isInvalid = false;
        } else {
          dateTimeMoment = moment(
            `${this.year.display}-${this.month.display}-${this.day.display}T00:00:00`,
            'YYYY-MM-DDTHH:mm:ss'
          );
        }
      } else if (!this.hideTime) {
        if (this.hour.display === '' || this.minute.display === '') {
          isInvalid = false;
        } else {
          dateTimeMoment = moment(
            `${this.hour.display}:${this.minute.display}:00`,
            'HH:mm:ss'
          );
        }
      }

      let dateTime: moment.Moment | Date | NgbDate | string = null;
      let invalidBeforeMoment: moment.Moment = null;
      let invalidAfterMoment: moment.Moment = null;

      if (dateTimeMoment) {
        isInvalid = !dateTimeMoment.isValid();

        if (!isInvalid) {
          if (this.invalidBefore) {
            invalidBeforeMoment =
              this.invalidBefore === 'now'
                ? moment()
                : this.dateTimeToMoment(this.invalidBefore);

            if (this.hideDate) {
              invalidBeforeMoment.year = dateTimeMoment.year;
              invalidBeforeMoment.month = dateTimeMoment.month;
              invalidBeforeMoment.day = dateTimeMoment.day;
            }

            if (this.hideTime) {
              invalidBeforeMoment.startOf('day');
            }
          }

          if (this.invalidAfter) {
            invalidAfterMoment =
              this.invalidAfter === 'now'
                ? moment()
                : this.dateTimeToMoment(this.invalidAfter);

            if (this.hideDate) {
              invalidAfterMoment.year = dateTimeMoment.year;
              invalidAfterMoment.month = dateTimeMoment.month;
              invalidAfterMoment.day = dateTimeMoment.day;
            }

            if (this.hideTime) {
              invalidAfterMoment.endOf('day');
            }
          }

          switch (this.dateTimeFormat) {
            case 'ngbDate':
              dateTime = new NgbDate(
                +dateTimeMoment.format('YYYY'),
                +dateTimeMoment.format('M'),
                +dateTimeMoment.format('D')
              );
              break;
            case 'moment':
              dateTime = dateTimeMoment;
              break;
            case 'date':
              dateTime = dateTimeMoment.toDate();
              break;
            default: // 'YYYY-MM-DDTHH:mm(:ss)' & custom format
              dateTime = dateTimeMoment.format(this.dateTimeFormat);
              break;
          }
        }

        if (invalidBeforeMoment && !isInvalid) {
          isInvalid = dateTimeMoment.isBefore(invalidBeforeMoment);
        }

        if (invalidAfterMoment && !isInvalid) {
          isInvalid = dateTimeMoment.isSameOrAfter(invalidAfterMoment);
        }
      }

      this.isInvalid = isInvalid;

      if (returnDateTime && !this.isInvalid) {
        if (this.allowPartialValue) {
          this.dateTimeChange.emit(this.partialValue);
          this.emitOnChange(this.partialValue);
        } else {
          this.dateTimeChange.emit(dateTime);
          this.emitOnChange(dateTime);
        }
      }
    }
  }

  incrementValue(ticks: number, source: 'sf' | undefined) {
    if (source === 'sf') {
      this.dateTime = this.dateTimeToMoment(this.dateTime).add(ticks / 1000);
      return;
    }

    if (!this.isReadOnly && !this.isInvalid) {
      const dateTimeMoment = (
        this.dateTimeToMoment(this.dateTime) ?? moment()
      ).add(ticks / 1000);
      this.year.display = dateTimeMoment.format('YYYY');
      this.month.display = dateTimeMoment.format('MM');
      this.day.display = dateTimeMoment.format('DD');
      this.hour.display = dateTimeMoment.format('HH');
      this.minute.display = dateTimeMoment.format('mm');
      this.validate(true);
    }
  }
}
