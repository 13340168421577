import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OrderRegistrationTariffType } from '@seahorse/domain';

@Pipe({ name: 'orderRegistrationTariffType' })
export class OrderRegistrationTariffTypePipe implements PipeTransform {
  orderRegistrationTariffTypes: object;

  constructor(private translate: TranslateService) {
    this.orderRegistrationTariffTypes = {
      [OrderRegistrationTariffType.Regular]:
        'customModules.orderRegistrations.tariffType.regular',
      [OrderRegistrationTariffType.Weekend]:
        'customModules.orderRegistrations.tariffType.weekend',
    };
  }

  transform = (
    orderRegistrationTariffType: OrderRegistrationTariffType
  ): string =>
    this.translate.instant(
      this.orderRegistrationTariffTypes[orderRegistrationTariffType]
    );
}
