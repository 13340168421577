export const ETASData = [
  {
    boundingBox: {
      topLeft: {
        longitude: 4.4,
        latitude: 52.4,
      },
      bottomRight: {
        longitude: 5.1,
        latitude: 52.5,
      },
    },
    points: [
      {
        name: 'Locks',
        coordinate: {
          longitude: 4.601301,
          latitude: 52.46779,
        },
      },
      {
        name: 'IJmuiden',
        coordinate: {
          longitude: 4.584478,
          latitude: 52.459883,
        },
      },
      {
        name: 'Pierheads',
        coordinate: {
          longitude: 4.539434,
          latitude: 52.465656,
        },
      },
      {
        name: 'Velsertunnel',
        coordinate: {
          longitude: 4.653829,
          latitude: 52.46347,
        },
      },
      {
        name: 'Zijkanaal-c',
        coordinate: {
          longitude: 4.730167,
          latitude: 52.432931,
        },
      },
    ],
  },
];
