<div *ngIf="!loading" class="card-body-group card-body-group-no-border">
  <div class="row no-gutters attribute-group">
    <div
      *ngFor="let taskDefinition of taskDefinitions"
      class="col-12 attribute-group-item"
    >
      <dt class="mb-0">{{ taskDefinition.taskType | taskType }}</dt>
      <dd>{{ taskDefinition.subject }}</dd>
    </div>
  </div>
</div>
<sh-loading-indicator
  *ngIf="loading"
  class="d-block text-center"
></sh-loading-indicator>
