import { Component, Input, OnInit } from '@angular/core';
import { TaskModel } from '../../models/task.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateTaskGroupModalForm } from '../task-modal/task-group-modal.form';
import { ComboBoxOption, NewItemConfig } from '@seahorse/forms';
import { TaskGroupModel } from '../../../../../task-group/models/task-group.model';
import { TranslateService } from '@ngx-translate/core';
import { ResultWrapper } from '@seahorse/common';
import { TaskGroupDataService } from '../../../../../task-group/services/task-group-data.service';
import { NotificationService } from '@seahorse/common';

@Component({
  selector: 'ca-follow-up-task-modal',
  templateUrl: './follow-up-task-modal.component.html',
  styleUrls: ['./follow-up-task-modal.component.scss'],
})
export class FollowUpTaskModalComponent implements OnInit {
  saveStarted: boolean;
  task: TaskModel;
  submitted: boolean;
  users: any[];
  form = CreateTaskGroupModalForm();
  newTaskGroupConfig: NewItemConfig;
  taskGroupOptions = new Array<ComboBoxOption>();
  @Input() loadTaskGroupsExternally = false;
  @Input() isLoadingTaskGroupOptions = false;
  @Input() taskGroups = new Array<TaskGroupModel>();

  constructor(
    public modal: NgbActiveModal,
    private translateService: TranslateService,
    private taskGroupDataService: TaskGroupDataService,
    private notificationService: NotificationService
  ) {
    this.translateService.get('taskGroup.addNewTaskGroup').subscribe({
      next: (result) => {
        this.newTaskGroupConfig = { label: result } as NewItemConfig;
      },
    });
  }

  ngOnInit() {
    this.isLoadingTaskGroupOptions = true;
    if (this.loadTaskGroupsExternally) {
      this.taskGroupOptions = this.taskGroups
        .filter(
          (x) =>
            (x.objectType == null || x.objectType == this.task.objectType) &&
            (x.objectId == null || x.objectId == this.task.objectId)
        )
        .map((taskGroup) => {
          return {
            label: taskGroup.name,
            value: taskGroup.id,
          } as ComboBoxOption;
        });
      this.isLoadingTaskGroupOptions = false;
    } else {
      this.taskGroupDataService.getAll().subscribe({
        next: (response: ResultWrapper<Array<TaskGroupModel>>) => {
          if (response.hasResult) {
            this.taskGroupOptions = response.result.map((taskGroup) => {
              return {
                label: taskGroup.name,
                value: taskGroup.id,
              } as ComboBoxOption;
            });
            this.taskGroups = response.result;
          } else {
            this.notificationService.showError(
              response.messages.map((x) => x.message).join('\n'),
              this.translateService.instant('shared.terms.failed')
            );
          }
        },
        error: (e) => {
          this.notificationService.showError(
            e.error.messages.map((x) => x.message).join('\n'),
            this.translateService.instant('shared.terms.failed')
          );
          this.isLoadingTaskGroupOptions = false;
        },
        complete: () => (this.isLoadingTaskGroupOptions = false),
      });
    }

    if (this.task.taskGroupId)
      this.form.setValue({ selectedFormGroupId: this.task.taskGroupId });
  }

  followUp() {
    if (!this.task.subject) {
      this.submitted = true;
      return;
    }

    this.saveStarted = true;

    this.modal.close(this.task);
  }
}
