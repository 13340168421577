import { Injectable } from '@angular/core';
import { ResultWrapper } from '@seahorse/common';
import { DataContextService } from '@seahorse/common';
import { Observable } from 'rxjs';

import { ContentTypeDefinitionModel } from '../models/content-type-definition.model';
import { DataService } from '@seahorse/domain';

@Injectable()
export class ContentTypeDataService
  implements DataService<ContentTypeDefinitionModel>
{
  constructor(private dataContext: DataContextService) {}

  search(
    searchQuery?: string,
    pageIndex?: number,
    pageSize?: number
  ): Observable<ResultWrapper<ContentTypeDefinitionModel[]>> {
    const queryParams = [];

    if (searchQuery !== undefined && searchQuery !== null) {
      queryParams.push(`searchTerm=${encodeURIComponent(searchQuery)}`);
    }

    if (pageIndex !== undefined && pageIndex !== null) {
      queryParams.push(`pindex=${pageIndex}`);
    }

    if (pageSize !== undefined && pageSize !== null) {
      queryParams.push(`psize=${pageSize}`);
    }

    const queryString = queryParams.join('&');
    const url = `contentdistribution/contenttype/search${
      queryString ? '?' + queryString : ''
    }`;

    return this.dataContext.get<ContentTypeDefinitionModel[]>(url);
  }

  getById(id: number): Observable<ResultWrapper<ContentTypeDefinitionModel>> {
    return this.dataContext.get<ContentTypeDefinitionModel>(
      `contentdistribution/contenttype/${id}`
    );
  }

  add(
    model: ContentTypeDefinitionModel
  ): Observable<ResultWrapper<ContentTypeDefinitionModel>> {
    return this.dataContext.post<ContentTypeDefinitionModel>(
      `contentdistribution/contenttype`,
      model
    );
  }

  update(
    model: ContentTypeDefinitionModel
  ): Observable<ResultWrapper<ContentTypeDefinitionModel>> {
    return this.dataContext.put<ContentTypeDefinitionModel>(
      `contentdistribution/contenttype`,
      model
    );
  }

  delete(id: number): Observable<ResultWrapper<ContentTypeDefinitionModel>> {
    return this.dataContext.delete<ContentTypeDefinitionModel>(
      `contentdistribution/contenttype/${id}`
    );
  }

  getAll(): Observable<ResultWrapper<ContentTypeDefinitionModel[]>> {
    return this.dataContext.get<ContentTypeDefinitionModel[]>(
      `contentdistribution/contenttype`
    );
  }
}
