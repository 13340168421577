<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title-bold">Create</h4>
    <button type="button" class="close" closeModal>
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ng-template #form></ng-template>

    <div class="row" *ngIf="errors && errors.length > 0">
      <div class="col-12">
        <div class="alert alert-danger">
          <p>Errors</p>
          <ul>
            <li *ngFor="let error of errors">{{ error }}</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="d-flex">
      <div class="flex-grow-1">
        <button type="button" class="btn btn-block btn-link" closeModal>
          {{ 'shared.terms.cancel' | translate }}
        </button>
      </div>
      <div class="flex-grow-1">
        <button
          type="button"
          class="btn btn-block btn-success"
          (click)="save()"
        >
          {{ 'shared.terms.save' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
