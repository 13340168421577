import { KeyValue } from '@angular/common';

interface ChecklistOption extends KeyValue<any, string> {
  isChecked: boolean;
}

export class ChecklistOptionModel implements ChecklistOption {
  key!: any;
  value!: string;
  isChecked!: boolean;

  constructor(key: any, value: any, isChecked?: boolean) {
    this.key = key;
    this.value = value;
    this.isChecked = isChecked ? isChecked : false;
  }
}
