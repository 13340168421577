<div class="form-group">
  <label class="d-flex justify-content-between" for="typeahead-flags">
    <b [ngClass]="{ required: isRequired }">{{ title }}</b>
    <span *ngIf="flagsLoading">
      <i class="fa-regular fa-spinner fa-spin fa-fw"></i>
    </span>
  </label>
  <div class="input-group">
    <input
      [(ngModel)]="selectedFlag"
      [ngbTypeahead]="searchFlags"
      [inputFormatter]="flagFormatter"
      [resultFormatter]="flagsFormatter"
      [placeholder]="('shared.terms.search' | translate) + '...'"
      [editable]="true"
      [required]="isRequired"
      (focus)="focus$.next($any($event).target.value)"
      (click)="click$.next($any($event).target.value)"
      (selectItem)="itemSelected($event)"
      #typeaheadFlags="ngbTypeahead"
      id="typeahead-flags"
      name="flags"
      type="text"
      class="form-control"
    />
    <div class="input-group-append">
      <button
        (click)="setNull()"
        type="button"
        class="btn bg-transparent ml-n5 br-0"
        style="z-index: 100"
      >
        <i class="fa-solid fa-times fa-xs fa-fw ml-1"></i>
      </button>
    </div>
  </div>
  <ng-container *ngFor="let error of errors">
    <small class="text-danger">{{ error }}</small>
  </ng-container>
</div>
