import { InvoiceTypes } from './invoice.model';

export class InvoiceLineModel {
  amount?: number;
  description: string;
  id: number;
  invoiceId: number;
  lineType: number;
  quantity: number;
  sortOrder: number;
  tariffId?: number;
  tariffSystemCode?: string;
  totalAmount?: number;
  vatCodeId?: number;
  vatAmount: number;
  vatIncludedInTotalAmount: boolean;
  vatPercentage: number;

  constructor() {
    this.amount = null;
    this.description = null;
    this.id = -1;
    this.invoiceId = null;
    this.lineType = null;
    this.quantity = null;
    this.sortOrder = null;
    this.tariffId = null;
    this.tariffSystemCode = null;
    this.totalAmount = null;
    this.vatCodeId = null;
  }
}

export class InvoiceLineViewModel extends InvoiceLineModel {
  companyId: number = null;
  debtorNumber: string = null;
  invoiceDate: string = null;
  invoiceNumber: string = null;
  invocieStatus: number = null;
  invoiceType: InvoiceTypes = null;
  name: string = null;

  constructor() {
    super();
  }
}
