import { ScopeType } from './custom-default-data.model';

export class CustomDataSearchFieldModel {
  fieldName: string;
  searchColumn?: string;
  searchInCustomContent?: boolean;
  searchValue: any;
  searchOperator: SearchOperators;
}

export enum SearchOperators {
  Equals = 0,
  Contains = 1,
  StartsWith = 2,
  EndsWith = 3,
  GreaterThan = 4,
  LesserThan = 5,
  InArray = 6,
  Between = 7,
  NotEquals = 8,
  ContainsAny = 9,
}

export class CustomDataSearchModel {
  scope: ScopeType;
  objectDefinitionSystemCode: string;
  fields: CustomDataSearchFieldModel[];
  pageSize?: number;
}
