import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { InvoicingFlowsRoutes } from './invoicing-flows.routes';
import { InvoicingFlowsPagesModule } from './pages/invoicing-flows-pages.module';
import { InvoicingFlowsComponentsModule } from './components/invoicing-flows-components.module';
import { InvoicingFlowsServicesModule } from './services/invoicing-flows-services.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(InvoicingFlowsRoutes),

    // Child modules
    InvoicingFlowsPagesModule,
    InvoicingFlowsComponentsModule,
    InvoicingFlowsServicesModule,
  ],
})
export class InvoicingFlowsModule {}
