import { Injectable } from '@angular/core';
import { ResultWrapper } from '@seahorse/common';
import { DataContextService } from '@seahorse/common';
import { Observable } from 'rxjs';

import { CompanyContactModel, DataService } from '@seahorse/domain';

@Injectable()
export class ContactDataService implements DataService<CompanyContactModel> {
  constructor(private dataContext: DataContextService) {}

  getById(contactId: number): Observable<ResultWrapper<CompanyContactModel>> {
    return this.dataContext.get<CompanyContactModel>(`contacts/${contactId}`);
  }

  getAll(companyId: number): Observable<ResultWrapper<CompanyContactModel[]>> {
    return this.dataContext.get<CompanyContactModel[]>(
      `contacts/company/${companyId}`
    );
  }

  add(
    contact: CompanyContactModel
  ): Observable<ResultWrapper<CompanyContactModel>> {
    return this.dataContext.post<CompanyContactModel>(`contacts`, contact);
  }

  update(
    contact: CompanyContactModel
  ): Observable<ResultWrapper<CompanyContactModel>> {
    return this.dataContext.put<CompanyContactModel>(
      `contacts/${contact.id}`,
      contact
    );
  }

  delete(contactId: number): Observable<ResultWrapper<CompanyContactModel>> {
    return this.dataContext.delete<CompanyContactModel>(
      `contacts/${contactId}`
    );
  }
}
