<div class="tile-container">
  <div
    *ngFor="let taskDefinitionList of taskDefinitionLists"
    [ngClass]="{
      selected: selected && selected.__Id == taskDefinitionList.__Id
    }"
    (click)="onEvent.emit({ action: 'select', data: taskDefinitionList })"
    class="card tile tile-clickable"
  >
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <b>{{ taskDefinitionList.name }}</b>
          <br />
          {{ taskDefinitionList.description }}
        </div>
      </div>
    </div>
  </div>
</div>
