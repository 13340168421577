<div class="form-row">
  <div class="form-group col-12 col-sm-6 col-md-12">
    <label for="name">
      <b>{{ 'invoicingFlows.stage.name' | translate }}</b>
      <i class="fa-regular fa-asterisk fa-xs fa-fw text-danger mb-1"></i>
    </label>
    <input
      [(ngModel)]="stage.name"
      [disabled]="loadingStage"
      required
      type="text"
      placeholder="{{ 'invoicingFlows.stage.name' | translate }}"
      id="name"
      name="name"
      class="form-control"
    />
  </div>
  <div class="form-group col-12 col-sm-6">
    <label for="stageType">
      <b>{{ 'invoicingFlows.stage.stageType' | translate }}</b>
      <i class="fa-regular fa-asterisk fa-xs fa-fw text-danger mb-1"></i>
    </label>
    <select
      [(ngModel)]="stage.stageType"
      [disabled]="loadingStage"
      required
      id="stageType"
      name="stageType"
      class="custom-select"
    >
      <option *ngFor="let stageType of stageTypes" [ngValue]="stageType">
        {{ stageType | stageType }}
      </option>
    </select>
  </div>
  <div class="form-group col-12 col-sm-6">
    <label for="invoiceType">
      <b>{{ 'invoicing.invoiceModel.invoiceType' | translate }}</b>
      <i class="fa-regular fa-asterisk fa-xs fa-fw text-danger mb-1"></i>
    </label>
    <select
      [(ngModel)]="stage.invoiceType"
      [disabled]="loadingStage"
      required
      id="invoiceType"
      name="invoiceType"
      class="custom-select"
    >
      <option *ngFor="let invoiceType of invoiceTypes" [ngValue]="invoiceType">
        {{ invoiceType | invoiceType }}
      </option>
    </select>
  </div>
  <div class="form-group col-12 col-sm-6">
    <label for="invoiceFlowId">
      <b>{{ 'invoicingFlows.stage.invoiceFlowId' | translate }}</b>
      <i class="fa-regular fa-asterisk fa-xs fa-fw text-danger mb-1"></i>
    </label>
    <select disabled name="invoicingFlowId" class="custom-select">
      <ng-container *ngIf="flow">
        <option>{{ flow.name }}</option>
      </ng-container>
    </select>
  </div>
  <div class="form-group col-12 col-sm-6">
    <label for="previousStageId">
      <b>{{ 'invoicingFlows.stage.previousStageId' | translate }}</b>
    </label>
    <select
      [(ngModel)]="stage.previousStageId"
      [disabled]="loadingStage || loadingFlow || flow.stages.length === 0"
      required
      id="previousStageId"
      name="previousStageId"
      class="custom-select"
    >
      <option [ngValue]="null">{{ 'shared.terms.none' | translate }}</option>
      <ng-container *ngIf="flow">
        <option
          *ngFor="let previousStage of flow.stages"
          [value]="previousStage.id"
        >
          {{ previousStage.name }}
        </option>
      </ng-container>
    </select>
  </div>
  <div class="form-group col-12">
    <div class="custom-control custom-switch">
      <input
        [(ngModel)]="stage.manualApproval"
        [disabled]="loadingStage"
        required
        type="checkbox"
        class="custom-control-input"
        id="manualApproval"
        name="manualApproval"
      />
      <label for="manualApproval" class="custom-control-label">
        {{ 'invoicingFlows.stage.manualApproval' | translate }}
      </label>
    </div>
    <div class="custom-control custom-switch">
      <input
        [(ngModel)]="stage.allPreviousMustSucceed"
        [disabled]="loadingStage || !stage.previousStageId"
        required
        type="checkbox"
        class="custom-control-input"
        id="allPreviousMustSucceed"
        name="allPreviousMustSucceed"
      />
      <label for="allPreviousMustSucceed" class="custom-control-label">
        {{ 'invoicingFlows.stage.allPreviousMustSucceed' | translate }}
      </label>
    </div>
  </div>
</div>
