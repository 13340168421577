<input
  [(ngModel)]="nauticalPort"
  [class.is-invalid]="nauticalPortsLoadingFailed"
  [ngbTypeahead]="searchnauticalPort"
  [inputFormatter]="nauticalPortFormatter"
  [resultFormatter]="nauticalPortsFormatter"
  [editable]="false"
  [required]="isRequired"
  id="typeahead-nauticalPort"
  name="nauticalPort"
  type="text"
  class="form-control"
  (selectItem)="itemSelected($event)"
/>
