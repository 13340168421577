<div class="page-header">
  <div class="row">
    <div class="col">
      <sh-loading-indicator
        *ngIf="saveStarted || isExporting"
        class="text-left d-block"
      ></sh-loading-indicator>
    </div>
    <div class="col text-right">
      <div class="btn-list">
        <button
          *ngIf="showInviteButton"
          class="btn btn-outline-secondary ml-2 text-nowrap"
          (click)="inviteCompany()"
        >
          {{ 'companies.inviteCompany' | translate }}
        </button>
        <div
          class="dropdown"
          *hasPermission="
            'companies.company_addresses_add,companies.company_contacts_add,companies.company_financials_add'
          "
        >
          <button
            class="btn btn-outline-secondary dropdown-toggle"
            type="button"
            id="headerAddNewButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fa-regular fa-plus fa-fw"></i>
            {{ 'shared.terms.add' | translate }}
          </button>
          <div class="dropdown-menu" aria-labelledby="headerAddNewButton">
            <button
              class="btn dropdown-item"
              *hasPermission="'companies.company_addresses_add'"
              (click)="addAddress.emit()"
            >
              {{ 'companies.company.companyAddress.add' | translate }}
            </button>
            <button
              class="btn dropdown-item"
              *hasPermission="'companies.company_contacts_add'"
              (click)="addContact.emit()"
            >
              {{ 'companies.company.companyContact.add' | translate }}
            </button>
            <button
              class="btn dropdown-item"
              *hasPermission="'companies.company_financials_add'"
              (click)="addFinancials.emit()"
            >
              {{ 'companies.company.companyFinancial.add' | translate }}
            </button>
            <button
              class="btn dropdown-item"
              *hasPermission="'invoices.invoices_create'"
              (click)="addInvoice.emit()"
            >
              {{ 'companies.company.companyInvoices.add' | translate }}
            </button>
          </div>
        </div>

        <div class="dropdown">
          <button
            *hasPermission="'companies.company_addresses_delete'"
            type="button"
            class="btn btn-outline-secondary"
            id="headerHamburgerButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fa-regular fa-ellipsis-v fa-fw"></i>
          </button>
          <div class="dropdown-menu" aria-labelledby="headerHamburgerButton">
            <button
              class="btn dropdown-item"
              *hasPermission="'companies.company_addresses_delete'"
              (click)="deactivate.emit()"
            >
              <i class="fa-solid fa-trash fa-fw"></i>
              {{ 'shared.terms.deactivate' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
