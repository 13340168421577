import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shTruncate',
  standalone: true,
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, limit: number): string {
    if (value) {
      // 2024-04-26 JV: Can't assume that the input is a string
      return value.toString().length <= limit
        ? value
        : value.toString().slice(0, limit) + '...';
    }

    return value;
  }
}
