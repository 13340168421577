<div class="card">
  <div class="card-header">
    {{ 'invoicingFlows.flowDetails.statistics' | translate }}
  </div>
  <div class="card-body card-body-table">
    <ng-container
      *ngIf="!loadingStatistics; then showStatistics; else isLoadingStatistics"
    ></ng-container>

    <ng-template #showStatistics>
      <div class="statistics-list">
        <div *ngFor="let statistic of statistics" class="statistics-item">
          <dt>{{ statistic.fieldName }}</dt>
          <dd>{{ statistic.fieldValue }}</dd>
        </div>
      </div>
    </ng-template>

    <ng-template #isLoadingStatistics>
      <sh-loading-indicator
        class="d-block text-center"
      ></sh-loading-indicator>
    </ng-template>
  </div>
</div>
