import {
  Component,
  EventEmitter,
  OnInit,
  OnDestroy,
  Output,
} from '@angular/core';
import { AISMarkerModel } from '@seahorse/domain';
import { AreaActivityModel } from '../../../superuser-nautical/models/area-activity.model';
import { AreaActivityDataService } from '../../../superuser-nautical/services/area-activity.data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ca-map-sidebar-area-activity',
  templateUrl: 'map-sidebar-area-activity.component.html',
})
export class MapSidebarAreaActivityComponent implements OnInit, OnDestroy {
  private subscriptions$ = Array<Subscription>();
  areaActivities: AreaActivityModel[];
  hasAreaActivities = false;

  @Output() onSelectVessel: EventEmitter<any> = new EventEmitter<any>();

  constructor(private areaActivityDataService: AreaActivityDataService) {}

  ngOnInit() {
    this.getAreaActivities();
  }

  ngOnDestroy() {
    this.subscriptions$.forEach((s) => s.unsubscribe());
  }

  openVessel(ship: AISMarkerModel) {
    this.onSelectVessel.emit(ship);
  }

  getAreaActivities() {
    this.subscriptions$.push(
      this.areaActivityDataService.getAll().subscribe(
        (response) => {
          this.areaActivities = response.result;

          if (this.areaActivities && this.areaActivities.length > 0) {
            this.hasAreaActivities = true;
          }
        },
        () => {},
        () => {}
      )
    );
  }

  toggleActiveArea(areaActivity: AreaActivityModel) {
    this.areaActivities.forEach((element) => {
      if (element !== areaActivity) {
        element.isActive = false;
      }
    });

    areaActivity.isActive = !areaActivity.isActive;
  }
}
