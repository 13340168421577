<div class="card">
  <div class="card-header">
    {{ title ? title : ('companies.company.company' | translate) }}
    <div class="btn-list" *ngIf="company">
      <button
        (click)="unlinkCompany()"
        class="btn btn-outline-secondary btn-sm"
        type="button"
      >
        <i class="fa-regular fa-unlink fa-fw"></i>
        {{ 'shared.terms.unlink' | translate }}
      </button>
      <button
        (click)="openCompany()"
        class="btn btn-outline-secondary btn-sm"
        type="button"
      >
        <i class="fa-regular fa-external-link-alt fa-fw"></i>
        {{ 'shared.terms.details' | translate }}
      </button>
    </div>
  </div>

  <div class="card-body">
    <div class="card-body-group card-body-group-no-border">
      <div class="row no-gutters attribute-group py-0">
        <div
          (click)="openCompanyPicker()"
          class="col-12 attribute-group-item attribute-group-item-editable py-0"
        >
          <div class="row no-gutters attribute-group w-100 py-0">
            <ng-container
              *ngIf="company; then companyShow; else companyHide"
            ></ng-container>
            <ng-template #companyShow>
              <div class="col-12 attribute-group-item">
                <dt class="mb-0">
                  {{ 'companies.company.model.name' | translate }}
                </dt>
                <dd>
                  <ng-container *ngIf="company.name">{{
                    company.name
                  }}</ng-container>
                  <i
                    class="fa-regular fa-pencil-alt fa-fw btn-editable text-muted float-right"
                  ></i>
                </dd>
              </div>
            </ng-template>
            <ng-template #companyHide>
              <div class="col-12 attribute-group-item">
                <dt
                  class="mb-0"
                  title="'companies.company.noneSelected' | translate"
                >
                  {{ 'companies.company.noneSelected' | translate }}
                </dt>
                <dd>
                  <i
                    class="fa-regular fa-pencil-alt fa-fw btn-editable text-muted float-right"
                  ></i>
                </dd>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
