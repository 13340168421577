export class ResultWrapper<T> {
  count: number;
  hasResult: boolean;
  messages: ErrorMessage[];
  result: T;
  status: ResultStatus;
}

export enum ResultStatus {
  /// Make everything below 50 an 'OK' code
  NotSet = 0,
  OK = 1,
  Info = 2,
  Warning = 3,
  Created = 4,
  Deleted = 5,
  PasswordResetSuccessfully = 6,
  // Make everything above 50 but below 100 a user error
  UserFault = 50,
  MissingParameters = 51,
  ParameterOutOfRange = 52,
  // Make everything above 100 a system error
  Error = 100,
  NotFound = 101,
  DeleteForbidden = 102,
  NotPermitted = 103,
  AlreadyExists = 104,
}

export class ErrorMessage {
  code: string;
  message: string;
}
