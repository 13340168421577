import { Component, Input, Output, EventEmitter } from '@angular/core';
import * as _ from 'underscore';
import { OrderRegistrationFeeModel } from '../../models/order-registration-fee.model';

@Component({
  templateUrl: 'order-registration-fee-picker-modal.component.html',
})
export class OrderRegistrationFeePickerModalComponent {
  @Input() fee: OrderRegistrationFeeModel;

  @Output() onConfirm: EventEmitter<OrderRegistrationFeeModel>;

  constructor() {
    this.fee = null;
    this.onConfirm = new EventEmitter<OrderRegistrationFeeModel>();
  }

  setNull() {
    if (this.fee && this.fee.value) {
      this.fee.value = null;
    }
  }
}
