import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { PreferenceComponentsModule } from './components/preference-components.module';
import { PreferencePagesModule } from './pages/preference-pages.module';
import { PREFERENCE_ROUTES } from './preference.routes';
import { PreferenceServicesModule } from './services/preference-services.module';

@NgModule({
  imports: [
    PreferenceComponentsModule,
    RouterModule.forChild(PREFERENCE_ROUTES),
    PreferencePagesModule,
    PreferenceServicesModule,
  ],
})
export class PreferenceModule {}
