import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'underscore';
import { NotificationService } from '@seahorse/common';
import { ResultWrapper } from '@seahorse/common';
import { EventModel } from '../../../../../shared/models/event.model';
import { TaskDefinitionDataService } from '../../services/task-definition-data.service';
import { TaskDefinitionModel } from '../../models/task-definition.model';
import { TaskDefinitionListModel } from '../../models/task-definition-list.model';

@Component({
  selector: 'task-definition-list-summary',
  templateUrl: './task-definition-list-summary.component.html',
})
export class TaskDefinitionListSummaryComponent {
  @Input() set taskDefinitionList(taskDefinitionList: TaskDefinitionListModel) {
    this._taskDefinitionList = taskDefinitionList;

    if (taskDefinitionList) {
      this.loadTaskDefinitions(taskDefinitionList.__Id);
    }
  }
  get taskDefinitionList() {
    return this._taskDefinitionList;
  }

  @Output() onEvent: EventEmitter<EventModel>;

  private _taskDefinitionList: TaskDefinitionListModel;

  taskDefinitions: TaskDefinitionModel[];
  loadingTaskDefinitions: boolean;

  constructor(
    private taskDefinitionData: TaskDefinitionDataService,
    private notify: NotificationService,
    private translate: TranslateService
  ) {
    this.taskDefinitionList = null;
    this.onEvent = new EventEmitter<EventModel>();
    this._taskDefinitionList = null;
    this.taskDefinitions = [];
    this.loadingTaskDefinitions = false;
  }

  loadTaskDefinitions(
    listId: TaskDefinitionModel['$customdata_taskdefinitionlist_id']
  ) {
    this.loadingTaskDefinitions = true;

    this.taskDefinitionData.getByListId(listId).subscribe(
      (r: ResultWrapper<TaskDefinitionModel[]>) => {
        if (r.hasResult) {
          this.taskDefinitions = r.count ? r.result : [];
        } else {
          this.notify.showError(
            _.pluck(r.messages, 'message').join('\n'),
            this.translate.instant('shared.terms.failed')
          );
        }
      },
      (e) => {
        this.notify.showError(
          _.pluck(e.error.messages, 'message').join('\n'),
          this.translate.instant('shared.terms.failed')
        );

        this.loadingTaskDefinitions = false;
      },
      () => {
        this.loadingTaskDefinitions = false;
      }
    );
  }
}
