export class BusinessLogicObject {
  businessLogicType: BusinessLogicTypes;

  id: string;
}

export enum BusinessLogicTypes {
  CalculationBracket = 0,
  CalculationPeriod = 1,
  CalculationPricePerUnit = 2,
  ConditionBoolean = 3,
  ConditionDate = 4,
  ConditionNumeric = 5,
  ConditionSchedule = 6,
  ConditionString = 7,
  ConditionTimespan = 8,
  ConditionArray = 12,
  TariffDependency = 9,
  TariffGroupDependency = 10,
  AddressDependency = 11,
  // VAT
  VATApplication = 255,
}

export const BUSINESSLOGICTYPESCALCULATIONS = [
  { id: 0, name: 'Bracket' },
  { id: 1, name: 'Period' },
  { id: 2, name: 'Price Per Unit' },
];

export const BUSINESSLOGICTYPESCALCONDITIONS = [
  { id: 3, name: 'Yes/no' },
  { id: 4, name: 'Date' },
  { id: 5, name: 'Numeric' },
  { id: 6, name: 'Schedule' },
  { id: 7, name: 'Text' },
  { id: 8, name: 'Timespan' },
  { id: 12, name: 'List' },
];

export const BUSINESSLOGICTYPESDEPENDENCIES = [
  { id: 9, name: 'Tariff' },
  { id: 10, name: 'Tariff Group' },
  { id: 11, name: 'Address' },
];
