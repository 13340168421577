import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { LayoutService } from '@seahorse/layout';
import { Subscription } from 'rxjs';
import { IdentityService } from '../../../core/services/identity.service';
import { MessagesInteractionService } from '../../../messages/services/messages-interaction.service';
import { LayoutDisplayService } from '../../services/layout-display.service';
import { MessageModel } from '@seahorse/domain';
import { MessagesDataService } from '../../../messages/services/messages.service';
import { SignalRService } from '@seahorse/common';

@Component({
  selector: 'ca-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  sidebarOpen: boolean;
  @Output() sidebarOpened = new EventEmitter<boolean>();

  messagingSubscription: Subscription;
  numberOfMessages;

  constructor(
    public router: Router,
    public layoutService: LayoutService,
    public layoutDisplayService: LayoutDisplayService,
    public identityService: IdentityService,
    public signalR: SignalRService,
    public messagesInteractionService: MessagesInteractionService,
    private messagesDataService: MessagesDataService
  ) {}

  ngOnInit() {
    this.messagesDataService
      .getNumberOfUnreadMessages()
      .subscribe((response) => {
        this.numberOfMessages = response.result;
      });

    this.checkNumberOfUnreadMessages();
  }

  checkNumberOfUnreadMessages() {
    setInterval(() => {
      this.messagesDataService
        .getNumberOfUnreadMessages()
        .subscribe((response) => {
          this.numberOfMessages = response.result;
        });
    }, 10000);
  }

  getInitials() {
    let initials = '{ }';

    if (
      this.identityService.identity &&
      (this.identityService.identity.givenName ||
        this.identityService.identity.surname)
    ) {
      if (
        this.identityService.identity.givenName &&
        this.identityService.identity.givenName.length > 0 &&
        this.identityService.identity.surname &&
        this.identityService.identity.surname.length > 0
      ) {
        initials =
          this.identityService.identity.givenName.substr(0, 1) +
          this.identityService.identity.surname.substr(0, 1);
      } else if (
        this.identityService.identity.givenName &&
        this.identityService.identity.givenName.length > 0
      ) {
        initials = this.identityService.identity.givenName.substr(0, 2);
      } else if (
        this.identityService.identity.surname &&
        this.identityService.identity.surname.length > 0
      ) {
        initials = this.identityService.identity.surname.substr(0, 2);
      }
    }

    return initials;
  }

  openInboxdata() {
    this.router.navigate(['/storage/inbox/overview']);
  }

  openMessages() {
    this.router.navigate(['/messages/overview'], {
      queryParams: { type: 'outgoing' },
    });
  }

  onToggleSidebar() {
    this.sidebarOpened.emit((this.sidebarOpen = !this.sidebarOpen));

    this.layoutService.toggleSidebar(this.sidebarOpen);
  }

  onSubmit(form: NgForm) {
    // @TODO - call global search method
  }

  logOut() {
    this.identityService.clearIdentity();
  }

  ngOnDestroy() {
    if (this.messagingSubscription) {
      this.messagingSubscription.unsubscribe();
    }
  }

  isForCurrentUser(message: MessageModel): boolean {
    if (
      !message.organisationId ||
      (message.organisationId &&
        message.organisationId.startsWith(
          this.identityService.identity.organisationId
        ))
    ) {
      return true;
    }
    return false;
  }
}
