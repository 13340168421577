import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseControl } from '../base.control';

@Component({
  selector: 'sh-text-input',
  templateUrl: './text-input.control.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextInputControl),
      multi: true,
    },
    {
      provide: BaseControl,
      useExisting: forwardRef(() => TextInputControl),
    },
  ],
})
export class TextInputControl extends BaseControl<string | null> {}
