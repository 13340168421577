import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TaskType } from '../models/task.model';

@Pipe({ name: 'taskType' })
export class TaskTypePipe implements PipeTransform {
  types: Object;

  constructor(private translate: TranslateService) {
    this.types = {
      [TaskType.Call]: 'tasks.task.model.taskTypes.call',
      [TaskType.SendEmail]: 'tasks.task.model.taskTypes.sendEmail',
      [TaskType.Visit]: 'tasks.task.model.taskTypes.visit',
      [TaskType.Process]: 'tasks.task.model.taskTypes.process',
      [TaskType.Website]: 'tasks.task.model.taskTypes.website',
    };
  }

  transform(taskType: TaskType): string {
    let pipe: string;

    this.translate.get(this.types[taskType]).subscribe((r) => (pipe = r));

    return pipe;
  }
}
