import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'underscore';
import { KPActivityTypes } from '../models/kp-activity-types.enum';

@Pipe({
  name: 'kpActivityTypeDisplay',
})
export class KPActivityTypeDisplayPipe implements PipeTransform {
  transform(value: string): string {
    const type = _.find(_.values(KPActivityTypes), (t) => t.code === value);
    return type ? type.name : 'unknown';
  }
}
