<div class="card">
  <table class="table table-clickable">
    <thead>
      <tr>
        <th scope="col">{{ 'shared.terms.name' | translate }}</th>
        <th scope="col">{{ 'shared.terms.description' | translate }}</th>
      </tr>
    </thead>

    <tbody>
      <tr
        *ngFor="let taskDefinitionList of taskDefinitionLists"
        [ngClass]="{
          selected: selected && selected.__Id == taskDefinitionList.__Id
        }"
        (click)="onEvent.emit({ action: 'select', data: taskDefinitionList })"
      >
        <td>
          <b>{{ taskDefinitionList.name }}</b>
        </td>
        <td>{{ taskDefinitionList.description }}</td>
      </tr>
    </tbody>
  </table>
</div>
