import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FileModel } from '@seahorse/domain';

@Component({
  selector: 'ca-file-upload',
  templateUrl: './file-upload.component.html',
})
export class FileUploadComponent {
  isNew: boolean;
  files: FileModel[] = [];

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;
  @Input() submitted: boolean;
  @Input() showError: boolean;
  @Output() onFilesUploaded = new EventEmitter<FileModel[]>();

  showPicker() {
    const element: HTMLElement = document.getElementById(
      'filePickerInput'
    ) as HTMLElement;
    element.click();
  }

  uploadFile(event) {
    const files = event.target.files;

    if (files.length > 0) {
      Array.prototype.forEach.call(files, (f) => {
        const file = new FileModel();

        file.file = f;
        file.name = f.name;
        file.fileType = f.type;
        file.size = f.size;
        file.description = file.name;
        file.filePurpose = this.determineFilePurpose(f.type);

        this.files.push(file);
      });

      this.fileInput.nativeElement.value = '';
      this.onFilesUploaded.emit(this.files);
    }
  }
  determineFilePurpose(type: any): string {
    if (!type) {
      return '';
    }

    switch (type.toLowerCase()) {
      case 'application/pdf':
        return 'PDF';
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return 'Word document';
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return 'Excel document';
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        return 'PowerPoint document';
      case 'text/plain':
        return 'Text file';
      case 'application/x-zip-compressed':
        return 'ZIP';
      default:
        return type;
    }
  }
}
