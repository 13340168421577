import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropDownButtonModule } from '@syncfusion/ej2-angular-splitbuttons';
import { BreadcrumbModule } from '@syncfusion/ej2-angular-navigations';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { DropDownComponent } from './components/drop-down/drop-down.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { SeahorseCommonModule } from '@seahorse/common';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { ButtonComponent } from './components/button/button.component';
import { MessageComponent } from './components/message/message.component';
import { MessageModule } from '@syncfusion/ej2-angular-notifications';
import { DataGridComponent } from './components/grid/data-grid.component';
import { GridModule } from '@syncfusion/ej2-angular-grids';
import { ModalComponent } from './components/modal/modal.component';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorMessagePanelComponent } from './components/error-message-panel/error-message-panel.component';

@NgModule({
  imports: [
    BreadcrumbModule,
    CommonModule,
    DropDownButtonModule,
    SeahorseCommonModule,
    ButtonModule,
    MessageModule,
    GridModule,
    DialogModule,
    TranslateModule,
  ],
  declarations: [
    AvatarComponent,
    BreadcrumbComponent,
    DropDownComponent,
    ErrorMessagePanelComponent,
    ButtonComponent,
    MessageComponent,
    DataGridComponent,
    ModalComponent,
  ],
  exports: [
    AvatarComponent,
    BreadcrumbComponent,
    DropDownComponent,
    ErrorMessagePanelComponent,
    ButtonComponent,
    MessageComponent,
    DataGridComponent,
    ModalComponent,
  ],
})
export class SeahorseUiModule {}
