import { HttpClient } from '@angular/common/http';
import { FactoryProvider, inject } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { BUILD_NO } from '../configuration/tokens';
import { TRANSLATION_PATH } from './translation.tokens';

export class MultiTranslateLoader implements TranslateLoader {
  constructor(
    private _http: HttpClient,
    private _paths: string[],
    private _buildNo: number | null
  ) {}

  getTranslation(lang: string) {
    return forkJoin(
      this._paths.map((path) => {
        let dest = `${path}/${lang}.json`;

        if (this._buildNo) {
          dest = dest + `?v=${this._buildNo}`;
        }

        return this._http.get(dest);
      })
    ).pipe(
      map((response) => {
        return response.reduce((a, b) => {
          return Object.assign(a, b);
        });
      })
    );
  }
}

export const translateLoaderProvider: FactoryProvider = {
  provide: TranslateLoader,
  useFactory: () =>
    new MultiTranslateLoader(
      inject(HttpClient),
      inject(TRANSLATION_PATH),
      inject(BUILD_NO, { optional: true })
    ),
  deps: [HttpClient, TRANSLATION_PATH, BUILD_NO],
};
