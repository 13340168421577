import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { CoreModule } from '../../core/core.module';
import { LayoutComponentsModule } from '../../layout/components/layout-components.module';
import { SharedModule } from '../../shared/shared.module';

import { ReleaseNoteCardComponent } from './release-note-card/release-note-card.component';
import { SystemMessageReleaseCardComponent } from './system-message-release/system-message-release-card/system-message-release-card.component';
import { SystemMessageReleaseFormComponent } from './system-message-release/system-message-release-form/system-message-release-form.component';
import { SystemMessageReleaseListComponent } from './system-message-release/system-message-release-list/system-message-release-list.component';
import { SystemMessageReleaseModalComponent } from './system-message-release/system-message-release-modal/system-message-release-modal.component';
import { SystemMessageFormComponent } from './system-message/system-message-form/system-message-form.component';
import { SystemMessageListComponent } from './system-message/system-message-list/system-message-list.component';
import { SystemMessageModalComponent } from './system-message/system-message-modal/system-message-modal.component';
import { SystemMessageSummaryComponent } from './system-message/system-message-summary/system-message-summary.component';
import { SystemMessageTilesComponent } from './system-message/system-message-tiles/system-message-tiles.component';
import { SystemMessageTranslationModalComponent } from './system-message/system-message-translation-modal/system-message-translation-modal.component';
import { UserLanguageFormComponent } from './user-language/user-language-form/user-language-form.component';
import { UserLanguageModalComponent } from './user-language/user-language-modal/user-language-modal.component';
import { DateTimePickerComponent } from '@seahorse/temp';

@NgModule({
  declarations: [
    UserLanguageFormComponent,
    UserLanguageModalComponent,
    ReleaseNoteCardComponent,
    SystemMessageFormComponent,
    SystemMessageListComponent,
    SystemMessageTilesComponent,
    SystemMessageSummaryComponent,
    SystemMessageModalComponent,
    SystemMessageReleaseFormComponent,
    SystemMessageReleaseModalComponent,
    SystemMessageReleaseListComponent,
    SystemMessageReleaseCardComponent,
    SystemMessageTranslationModalComponent,
  ],
  exports: [
    UserLanguageModalComponent,
    ReleaseNoteCardComponent,
    SystemMessageModalComponent,
    SystemMessageListComponent,
    SystemMessageTilesComponent,
    SystemMessageSummaryComponent,
    SystemMessageReleaseModalComponent,
    SystemMessageTranslationModalComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    LayoutComponentsModule,
    NgSelectModule,
    RouterModule,
    SharedModule,

    NgbModule,
    CoreModule,
    DateTimePickerComponent,
  ],
})
export class PreferenceComponentsModule {}
