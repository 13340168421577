import { FieldType } from '@seahorse/domain';
import { SortOptionModel } from '../../../../layout/models/sort-option.model';

export class InvoiceDisplayConfigModel {
  id: number;
  organizationId: string;
  name: string;
  revenueReportConfiguration: RevenueReportConfiguration;
  overviewPageConfiguration: any;
  detailsPageConfiguration: any;
  detailsCardConfiguration: any;
  flowIds: number[];

  constructor() {
    this.id = null;
    this.organizationId = null;
    this.name = null;
    this.revenueReportConfiguration = null;
    this.overviewPageConfiguration = null;
    this.detailsPageConfiguration = null;
    this.detailsCardConfiguration = null;
    this.flowIds = [];
  }
}

export class RevenueReportConfiguration {
  appendFields: string[];
  selectOptions: OptionFieldModel[];
  filtering: GroupingFieldModel[];
  sorting: SortFieldModel[];

  constructor() {
    this.appendFields = null;
    this.selectOptions = null;
    this.filtering = null;
    this.sorting = null;
  }
}

export class BaseFieldModel {
  category: string;
  fieldName: string;
  fieldType: FieldType;
  label: string;

  constructor() {
    this.category = null;
    this.fieldName = null;
    this.fieldType = null;
    this.label = null;
  }
}

export class SortFieldModel extends BaseFieldModel {
  sortIndex: number;
  sortOption: SortOptionModel;

  constructor() {
    super();
    this.sortIndex = null;
    this.sortOption = null;
  }
}

export class GroupingFieldModel extends BaseFieldModel {
  isDefault: boolean;

  constructor() {
    super();

    this.isDefault = null;
  }
}

export class OptionFieldModel extends GroupingFieldModel {
  intervals: IntervalModel[];
  decimalSeparator: string;
  allowColumn: boolean;
  allowRow: boolean;
  isDefaultColumn: boolean;
  isDefaultRow: boolean;
  disabled: boolean; // Only for UI, no database model

  constructor() {
    super();

    this.intervals = null;
    this.decimalSeparator = null;
    this.allowColumn = null;
    this.allowRow = null;
    this.isDefaultColumn = null;
    this.isDefaultRow = null;
  }
}

export class IntervalModel {
  step: string;
  occurrence: string;

  constructor(step: string, occurrence: string) {
    this.step = step;
    this.occurrence = occurrence;
  }
}
