<div class="form-row">
  <div class="form-group col-12">
    <label for="subject" class="required">
      <b>{{ 'tasks.task.model.subject' | translate }}</b>
    </label>
    <div class="input-group">
      <input
        [(ngModel)]="task.subject"
        type="text"
        placeholder="{{ 'tasks.task.placeholder.subject' | translate }}"
        id="subject"
        name="subject"
        class="form-control"
      />
    </div>
  </div>
  <div class="form-group col-12">
    <label for="description">
      <b>{{ 'shared.terms.description' | translate }}</b>
    </label>
    <div class="input-group">
      <input
        [(ngModel)]="task.description"
        type="text"
        placeholder="{{ 'tasks.task.placeholder.description' | translate }}"
        id="description"
        name="description"
        class="form-control"
      />
    </div>
  </div>
  <div class="form-group col-12 col-sm-6">
    <label for="assignedTo">
      <b>{{ 'tasks.task.model.assignedTo' | translate }}</b>
    </label>

    <!-- <div class="input-group">
      <input
        [(ngModel)]="selectedCompany"
        [disabled]="selectedCompanyLoading"
        [class.is-invalid]="companiesLoadingFailed"
        [ngbTypeahead]="searchCompany"
        [inputFormatter]="companyFormatter"
        [resultFormatter]="companyFormatter"
        [editable]="allowTextOnly"
        [placeholder]="('companies.pickerDialog.searchCompany' | translate) + '...'"
        [required]="isRequired"
        (focus)="focus$.next($any($event).target.value)"
        (click)="click$.next($any($event).target.value)"
        (selectItem)="itemSelected($event)"
        #companyTypeahead="ngbTypeahead"
        id="typeahead-company"
        name="company"
        type="text"
        class="form-control"
      />
      <div class="input-group-append">
        <button (click)="setNull()" type="button" class="btn bg-transparent ml-n5 br-0" style="z-index: 100;">
          <i class="fa-solid fa-times fa-xs fa-fw ml-1"></i>
        </button>
      </div>
    </div> -->

    <div class="input-group">
      <select
        [(ngModel)]="task.assignedTo"
        (change)="onAssignedToChanged()"
        [disabled]="loading"
        id="assignedTo"
        name="assignedTo"
        class="custom-select"
      >
        <option selected [ngValue]="null">
          {{ 'shared.terms.choose' | translate }}...
        </option>
        <optgroup>
          <option *ngFor="let user of users" [ngValue]="user.id">
            {{ user.givenName }}
            {{ user.surname }}
          </option>
        </optgroup>
      </select>
    </div>
  </div>
  <div class="form-group col-12 col-sm-6">
    <div *ngIf="dateIsInPast">
      <i
        class="fa-regular fa-exclamation float-right mt-1 mr-1 text-danger"
        title="{{ 'tasks.task.model.dueDateInPast' | translate }}"
      ></i>
    </div>
    <label for="dueDate">
      <b>{{ 'tasks.task.model.dueDate' | translate }}</b>
    </label>
    <temp-date-time-picker
      [(dateTime)]="task.dueDate"
      [hideTime]="true"
      [isContained]="true"
      [invalidBefore]="minimalDate"
    ></temp-date-time-picker>
  </div>
  <div class="form-group col-12 col-sm-6">
    <label for="taskType" class="required">
      <b>{{ 'tasks.task.model.taskType' | translate }}</b>
    </label>
    <div class="input-group">
      <select
        [(ngModel)]="task.taskType"
        [disabled]="loading"
        id="taskType"
        name="taskType"
        class="custom-select"
        (ngModelChange)="task.additionalInfo = null"
      >
        <option selected [ngValue]="null">
          {{ 'shared.terms.choose' | translate }}...
        </option>
        <optgroup>
          <option *ngFor="let taskType of taskTypes" [ngValue]="taskType">
            {{ taskType | taskType }}
          </option>
        </optgroup>
      </select>
    </div>
  </div>
  <div class="form-group col-12 col-sm-6">
    <label for="status" class="required">
      <b>{{ 'tasks.task.model.status' | translate }}</b>
    </label>
    <div class="input-group">
      <select
        [(ngModel)]="task.status"
        [disabled]="loading"
        id="status"
        name="status"
        class="custom-select"
      >
        <option selected [ngValue]="null">
          {{ 'shared.terms.choose' | translate }}...
        </option>
        <optgroup>
          <option
            *ngFor="let taskStatus of taskStatuses"
            [ngValue]="taskStatus"
          >
            {{ taskStatus | taskStatus }}
          </option>
        </optgroup>
      </select>
    </div>
  </div>
  <ng-container *ngIf="task && task.taskType === TaskType.Call">
    <div class="form-group col-12">
      <label for="callInfo">
        <b>{{ 'tasks.task.model.additionalInfoCall' | translate }}</b>
      </label>
      <div class="input-group">
        <input
          [(ngModel)]="task.additionalInfo"
          type="text"
          placeholder="{{
            'tasks.task.placeholder.additionalInfoCall' | translate
          }}"
          id="callInfo"
          name="callInfo"
          class="form-control"
        />
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="task && task.taskType === TaskType.SendEmail">
    <div class="form-group col-12">
      <label for="emailInfo">
        <b>{{ 'tasks.task.model.additionalInfoEmail' | translate }}</b>
      </label>
      <div class="input-group">
        <input
          [(ngModel)]="task.additionalInfo"
          type="text"
          placeholder="{{
            'tasks.task.placeholder.additionalInfoEmail' | translate
          }}"
          id="emailInfo"
          name="emailInfo"
          class="form-control"
        />
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="task && task.taskType === TaskType.Website">
    <div class="form-group col-12">
      <label for="websiteInfo">
        <b>{{ 'tasks.task.model.additionalInfoWebsite' | translate }}</b>
      </label>
      <div class="input-group">
        <input
          [(ngModel)]="task.additionalInfo"
          type="text"
          placeholder="{{
            'tasks.task.placeholder.additionalInfoWebsite' | translate
          }}"
          id="websiteInfo"
          name="websiteInfo"
          class="form-control"
        />
      </div>
    </div>
  </ng-container>
  <div class="form-group col-12" *ngIf="task && task.id">
    <label for="createdBy">
      <b>{{ 'tasks.task.model.createdBy' | translate }}</b>
    </label>
    <div class="input-group">
      <select
        [(ngModel)]="task.createdBy"
        id="createdBy"
        name="createdBy"
        class="custom-select"
        disabled
      >
        <option selected [ngValue]="null">
          {{ 'shared.terms.choose' | translate }}...
        </option>
        <optgroup>
          <option *ngFor="let user of users" [ngValue]="user.id">
            {{ user.givenName }}
            {{ user.surname }}
          </option>
        </optgroup>
      </select>
    </div>
  </div>
  <div class="form-group col-12">
    <label for="remarks">
      <b>{{ 'tasks.task.model.remarks' | translate }}</b>
    </label>
    <div class="input-group">
      <textarea
        [(ngModel)]="task.remarks"
        type="text"
        id="remarks"
        name="remarks"
        class="form-control"
      ></textarea>
    </div>
  </div>
</div>
