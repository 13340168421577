<button
  class="btn btn-outline-secondary m-1"
  type="button"
  (click)="toggleExpanded()"
>
  <i class="fa-solid fa-list fa-fw"></i>
  {{ getButtonText() | translate }}
</button>
<div
  class="page-header-filter-list"
  [ngClass]="{
    'page-header-filter-list-visible': isExpanded,
    'sidebar-open': sidebarOpen,
    'top-margin': topMargin
  }"
>
  <div
    class="dropdown-menu d-block position-relative border-0 rounded-0 float-none"
    style="top: 0"
  >
    <span class="dropdown-header h6">{{ listTitle | translate }}</span>
    <sh-loading-indicator
      *ngIf="isLoading"
      class="loader-sm px-4"
    ></sh-loading-indicator>
    <ng-container *ngFor="let item of listData">
      <a class="dropdown-item rounded-0" (click)="setSelectedItem(item)">{{
        getDisplayText(item)
      }}</a>
    </ng-container>
  </div>
</div>
