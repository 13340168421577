<div class="card">
  <div class="card-header">
    {{ title ? title : ('nautical.visit.portVisit' | translate) }}
    <div class="btn-list" *ngIf="nauticalVisit">
      <button
        (click)="unlinkNauticalVisit()"
        class="btn btn-outline-secondary btn-sm"
        type="button"
      >
        <i class="fa-regular fa-unlink fa-fw"></i>
        {{ 'shared.terms.unlink' | translate }}
      </button>
      <button
        (click)="openNauticalVisit()"
        class="btn btn-outline-secondary btn-sm"
        type="button"
      >
        <i class="fa-regular fa-external-link-alt fa-fw"></i>
        {{ 'shared.terms.details' | translate }}
      </button>
    </div>
  </div>

  <div class="card-body">
    <ng-container
      *ngIf="isLoading; then showLoading; else showNauticalVisit"
    ></ng-container>
    <ng-template #showLoading>
      <sh-loading-indicator
        class="text-center d-block"
      ></sh-loading-indicator>
    </ng-template>
    <ng-template #showNauticalVisit>
      <div class="card-body-group card-body-group-no-border">
        <div class="row no-gutters attribute-group py-0">
          <div
            (click)="openNauticalVisitPicker()"
            class="col-12 attribute-group-item attribute-group-item-editable py-0"
          >
            <div class="row no-gutters attribute-group w-100 py-0">
              <ng-container
                *ngIf="
                  nauticalVisit;
                  then nauticalVisitShow;
                  else nauticalVisitHide
                "
              ></ng-container>
              <ng-template #nauticalVisitShow>
                <div class="col-12 attribute-group-item" *ngIf="nauticalShip">
                  <dt class="mb-0">
                    {{ 'nautical.ship.model.name' | translate }}
                  </dt>
                  <dd>
                    <ng-container *ngIf="nauticalShip.name">{{
                      nauticalShip.name | uppercase
                    }}</ng-container>
                  </dd>
                </div>
                <div class="col-12 attribute-group-item">
                  <dt class="mb-0">
                    {{ 'nautical.visit.model.referenceNumber' | translate }}
                  </dt>
                  <dd>
                    <ng-container *ngIf="nauticalVisit.referenceNumber">{{
                      nauticalVisit.referenceNumber | uppercase
                    }}</ng-container>
                    <i
                      class="fa-regular fa-pencil-alt fa-fw btn-editable text-muted float-right"
                    ></i>
                  </dd>
                </div>
                <!-- <div class="col-12 attribute-group-item">
                    <dt class="mb-0">{{ 'nautical.terms.port' | translate }}</dt>
                    <dd >
                      <ng-container *ngIf="nauticalVisit.port && nauticalVisit.port.name">{{ nauticalVisit.port.name }}</ng-container>
                    </dd>
                  </div> -->
                <div class="col-12 attribute-group-item">
                  <dt class="mb-0">{{ 'nautical.terms.eta' | translate }}</dt>
                  <dd>
                    <ng-container *ngIf="nauticalVisit.eta">{{
                      nauticalVisit.eta | date: 'dd-MM-yyyy HH:mm'
                    }}</ng-container>
                  </dd>
                </div>
                <div class="col-12 attribute-group-item">
                  <dt class="mb-0">{{ 'nautical.terms.etd' | translate }}</dt>
                  <dd>
                    <ng-container *ngIf="nauticalVisit.etd">{{
                      nauticalVisit.etd | date: 'dd-MM-yyyy HH:mm'
                    }}</ng-container>
                  </dd>
                </div>
                <div class="col-12 attribute-group-item">
                  <dt class="mb-0">{{ 'nautical.terms.ata' | translate }}</dt>
                  <dd>
                    <ng-container *ngIf="nauticalVisit.ata">{{
                      nauticalVisit.ata | date: 'dd-MM-yyyy HH:mm'
                    }}</ng-container>
                  </dd>
                </div>
                <div class="col-12 attribute-group-item">
                  <dt class="mb-0">{{ 'nautical.terms.atd' | translate }}</dt>
                  <dd>
                    <ng-container *ngIf="nauticalVisit.atd">{{
                      nauticalVisit.atd | date: 'dd-MM-yyyy HH:mm'
                    }}</ng-container>
                  </dd>
                </div>
              </ng-template>
              <ng-template #nauticalVisitHide>
                <div class="col-12 attribute-group-item">
                  <dt
                    class="mb-0"
                    title="'nautical.visit.noneSelected' | translate"
                  >
                    {{ 'nautical.visit.noneSelected' | translate }}
                  </dt>
                  <dd>
                    <i
                      class="fa-regular fa-pencil-alt fa-fw btn-editable text-muted float-right"
                    ></i>
                  </dd>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
