import { Injectable } from '@angular/core';
import { ResultWrapper } from '@seahorse/common';
import { DataContextService } from '@seahorse/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';

import { TariffGroupModel } from '../models/tariff-group.model';
import { DataService } from '@seahorse/domain';

@Injectable({ providedIn: 'root' })
export class TariffGroupDataService implements DataService<TariffGroupModel> {
  constructor(private dataContext: DataContextService) {}

  getById(id: number): Observable<ResultWrapper<TariffGroupModel>> {
    return this.dataContext.get<TariffGroupModel>(`invoice/tariff-group/${id}`);
  }

  add(model: TariffGroupModel): Observable<ResultWrapper<TariffGroupModel>> {
    return this.dataContext.post<TariffGroupModel>(
      `invoice/tariff-group`,
      model
    );
  }

  addForInvoicing(
    currencyCode: string
  ): Observable<ResultWrapper<TariffGroupModel>> {
    return this.dataContext.post<TariffGroupModel>(
      `invoice/tariff-group/invoicing`,
      { currencyCode }
    );
  }

  update(model: TariffGroupModel): Observable<ResultWrapper<TariffGroupModel>> {
    return this.dataContext.put<TariffGroupModel>(
      `invoice/tariff-group`,
      model
    );
  }

  delete(id: number): Observable<ResultWrapper<TariffGroupModel>> {
    return this.dataContext.delete<TariffGroupModel>(
      `invoice/tariff-group/${id}`
    );
  }

  getTariffGroups(
    pageIndex: number,
    pageSize: number
  ): Observable<ResultWrapper<TariffGroupModel[]>> {
    return this.dataContext
      .get<TariffGroupModel[]>(
        `invoice/tariff-group?pindex=${pageIndex}&psize=${pageSize}`
      )
      .pipe(map((response) => response));
  }

  getActiveTariffGroupsByDate(
    date: string,
    pageIndex: number,
    pageSize: number
  ): Observable<ResultWrapper<TariffGroupModel[]>> {
    const dateString = moment(date).format('YYYY-MM-DD');
    return this.dataContext
      .get<TariffGroupModel[]>(
        `invoice/tariff-group/details/active?date=${dateString}&pindex=${pageIndex}&psize=${pageSize}`
      )
      .pipe(map((response) => response));
  }

  getTariffGroupsByOrganisation(): Observable<
    ResultWrapper<TariffGroupModel[]>
  > {
    return this.dataContext
      .get<TariffGroupModel[]>(`invoice/tariff-group/all`)
      .pipe(map((response) => response));
  }
}
