import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as turf from '@turf/turf';
import { IdentityService } from '../../../core/services/identity.service';
import { AISMarkerModel } from '@seahorse/domain';
import { PreferenceModel } from '../../../preference/models/preference.model';
import { PreferenceDataService } from '../../../preference/services/preference-data.service';
import * as mapboxgl from 'mapbox-gl';
import * as moment from 'moment';
import { interval, Subscription, Subject } from 'rxjs';
import * as _ from 'underscore';

import { FeatureCollection, GeoJson } from '../../models/geojson.model';
import { MapInteractionService } from '../../services/map-interaction.service';
import { MapLabelComponent } from '../map-label/map-label.component';
import { MapMarkerComponent } from '../map-marker/map-marker.component';
import { MapPopupComponent } from '../map-popup/map-popup.component';
import { max, min } from 'underscore';
import { MapDataService } from '@seahorse/domain';
import { MapMarkersModel } from '../../../map/models/map-markers.model';
import { RouteCalculatorResponseModel } from '@seahorse/domain';
import { takeUntil } from 'rxjs/operators';
import { CoordinatePointModel } from '@seahorse/domain';

@Component({
  selector: 'ca-map-box',
  templateUrl: './map-box.component.html',
  styleUrls: ['./map-box.component.scss'],
})
export class MapBoxComponent implements OnInit, OnDestroy, AfterViewInit {
  private _subscriptions = new Subscription();
  private _destroy$ = new Subject();
  private preferenceName = 'Map';
  private preference: PreferenceModel = null;
  private isPreferenceSaving = false;
  private mapLabelComponent: ComponentRef<any>;
  private mapPopupComponent: ComponentRef<any>;
  private mapMarkerComponent: ComponentRef<any>;
  private points: CoordinatePointModel[];

  private mapMarkers: MapMarkersModel;
  private mapMarkersLoading: boolean;
  private showAllLabels: boolean;

  @Input() allowPopUp = true;
  @Output() markerSelected = new EventEmitter<AISMarkerModel>();

  @ViewChild('mapLabel', { read: ViewContainerRef, static: true })
  labelContainerRef: ViewContainerRef;
  @ViewChild('mapPopup', { read: ViewContainerRef, static: true })
  popupContainerRef: ViewContainerRef;
  @ViewChild('mapMarker', { read: ViewContainerRef, static: true })
  markerContainerRef: ViewContainerRef;

  /// default settings
  map: mapboxgl.Map;
  style = 'mapbox://styles/mapbox/outdoors-v9';
  lat = 52.368255;
  lng = 5.1009965;

  // data
  markerSource: any;
  clusterSource: any;
  markers: mapboxgl.Markerany = [];
  mapboxMarkers: mapboxgl.Marker = [];
  clusterMarkers: mapboxgl.Marker = [];
  currentMarker: mapboxgl.Marker = null;

  // Subscriptions to service calls
  fitBoundsSubscription: Subscription;
  markersLoadedSubscription: Subscription;

  calculationStarted = false;
  popupVisible = false;

  // GeoJSON object to hold our measurement features
  geojson = {
    type: 'FeatureCollection',
    features: [],
  };

  // Used to draw a line between points
  linestring = {
    type: 'Feature',
    geometry: {
      type: 'LineString',
      coordinates: [],
    },
  };

  // zoom levels
  // [Latitude 0 (Equator),
  // ±20 (Mexico City; Mutare, Zimbabwe),
  // ±40 (Cincinnati; Melbourne),
  // ±60 (Anchorage),
  // ±80 (Longyearbyen, Svalbard Norway)]
  zoomLevels = [
    [78271.484, 73551.136, 59959.436, 39135.742, 13591.701], // zoom level 0
    [39135.742, 36775.568, 29979.718, 19567.871, 6795.85], // 1
    [19567.871, 18387.784, 14989.859, 9783.936, 3397.925], // 2
    [9783.936, 9193.892, 7494.929, 4891.968, 1698.963], // 3
    [4891.968, 4596.946, 3747.465, 2445.984, 849.481], // 4
    [2445.984, 2298.473, 1873.732, 1222.992, 424.741], // 5
    [1222.992, 1149.237, 936.866, 611.496, 212.37], // 6
    [611.496, 574.618, 468.433, 305.748, 106.185], // 7
    [305.748, 287.309, 234.217, 152.874, 53.093], // 8
    [152.874, 143.655, 117.108, 76.437, 26.546], // 9
    [76.437, 71.827, 58.554, 38.218, 13.273], // 10
    [38.218, 35.914, 29.277, 19.109, 6.637], // 11
    [19.109, 17.957, 14.639, 9.555, 3.318], // 12
    [9.555, 8.978, 7.319, 4.777, 1.659], // 13
    [4.777, 4.489, 3.66, 2.389, 0.83], // 14
    [2.389, 2.245, 1.83, 1.194, 0.415], // 15
    [1.194, 1.122, 0.915, 0.597, 0.207], // 16
    [0.597, 0.561, 0.457, 0.299, 0.104], // 17
    [0.299, 0.281, 0.229, 0.149, 0.052], // 18
    [0.149, 0.14, 0.114, 0.075, 0.026], // 19
    [0.075, 0.07, 0.057, 0.037, 0.013], // 20
    [0.037, 0.035, 0.029, 0.019, 0.006], // 21
    [0.019, 0.018, 0.014, 0.009, 0.003], // 22
  ];

  constructor(
    private identityService: IdentityService,
    private preferenceDataService: PreferenceDataService,
    private mapInteractionService: MapInteractionService,
    private mapDataService: MapDataService,
    private resolver: ComponentFactoryResolver,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService
  ) {
    this.mapMarkers = new MapMarkersModel();
    this.showAllLabels = false;
    this.mapMarkers = new MapMarkersModel();

    this._subscriptions.add(
      mapInteractionService.routeLoaded$
        .pipe(takeUntil(this._destroy$))
        .subscribe(
          (route) => {
            this.drawRoute(route);
          },
          () => {},
          () => {}
        )
    );

    // Subscript to the service
    this.fitBoundsSubscription = mapInteractionService.fitBoundsCalled$
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (bounds) => {
          const params = {
            bounds:
              bounds.bounds[0][0] +
              ',' +
              bounds.bounds[0][1] +
              ',' +
              bounds.bounds[1][0] +
              ',' +
              bounds.bounds[1][1],
            bearing: bounds.options.bearing,
            lat: null,
            lng: null,
            pitch: bounds.options.pitch,
          };
          this.preference.fieldValue = JSON.stringify(params);

          if (this.isPreferenceSaving === false) {
            this.isPreferenceSaving = true;
            this._subscriptions.add(
              this.preferenceDataService.save(this.preference).subscribe(
                (response) => {
                  if (response && response.hasResult) {
                    this.identityService.setPreferences([response.result]);
                    this.preference = response.result;
                  }
                },
                (e) => {},
                () => {
                  this.isPreferenceSaving = false;
                }
              )
            );
          }
          this.router
            .navigate([], {
              relativeTo: this.activatedRoute,
              queryParams: params,
              queryParamsHandling: 'merge',
            })
            .then(
              () => {},
              () => {}
            )
            .catch(() => {})
            .finally(() => {});
        },
        () => {},
        () => {}
      );

    this._subscriptions.add(this.fitBoundsSubscription);

    this._subscriptions.add(
      this.mapInteractionService.showPolygonCalled$
        .pipe(takeUntil(this._destroy$))
        .subscribe(
          (points) => {
            this.points = points;
            this.showPolygon();
          },
          () => {},
          () => {}
        )
    );

    this._subscriptions.add(
      this.mapInteractionService.popupSelected$
        .pipe(takeUntil(this._destroy$))
        .subscribe(
          (marker) => {
            let mapboxMarker = _.find(
              this.mapboxMarkers,
              (m: any) => m.options.aisInfo.mmsi === marker.mmsi
            );

            if (!mapboxMarker) {
              mapboxMarker = _.find(
                this.mapboxMarkers,
                (m: any) => m.options.aisInfo.imo === marker.imo
              );
            }

            this.showPopup(mapboxMarker, false);
          },
          () => {},
          () => {}
        )
    );
  }

  ngAfterViewInit() {
    const markerFactory =
      this.resolver.resolveComponentFactory(MapMarkerComponent);
    this.mapMarkerComponent =
      this.markerContainerRef.createComponent(markerFactory);
  }

  ngOnInit() {
    this.mapMarkersLoading = true;

    this._subscriptions.add(
      this.preferenceDataService
        .getByName('mapMarkers', 'map')
        .pipe(takeUntil(this._destroy$))
        .subscribe(
          (r) => {
            if (r.hasResult) {
              this.mapMarkers = JSON.parse(r.result.fieldValue);
            }
          },

          (e) => {},

          () => {
            this.mapMarkersLoading = false;

            this.addClusters();
            this.addMarkers();
          }
        )
    );

    this.preference = _.find(
      this.identityService.getPreferences(),
      (preference) => {
        return (
          this.preferenceName.toLowerCase() === preference.name.toLowerCase()
        );
      }
    );

    if (!this.preference) {
      this.preference = new PreferenceModel();
      this.preference.id = 0;
      this.preference.name = this.preferenceName;
      this.preference.fieldType = 1;
    }

    this.initializeMap();
    this.persistPreference = _.debounce(this.persistPreference, 3000);

    this.map.on('moveend', this.mapMoveendListener);

    // add realtime trigger
    this._subscriptions.add(
      interval(30000)
        .pipe(takeUntil(this._destroy$))
        .subscribe(
          () => {
            this.loadData();
            this.updateStaleMarkers();
          },
          () => {},
          () => {}
        )
    );
  }

  mapMoveendListener = () => {
    // this.currentMarker = null;
    this.loadData();

    const bounds = this.map.getBounds();

    this.preference.fieldValue = JSON.stringify({
      bounds:
        bounds._sw.lng +
        ',' +
        bounds._sw.lat +
        ',' +
        bounds._ne.lng +
        ',' +
        bounds._ne.lat,
    });

    this.persistPreference();

    setTimeout(() => {
      this.updateStaleMarkers();
    }, 1000);
  };

  private initializeMap() {
    /// locate the user
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
      });
    }

    this.buildMap();

    this._subscriptions.add(
      this.activatedRoute.queryParams.subscribe(
        (queryParams) => {
          if (_.isEmpty(queryParams) && this.preference.fieldValue) {
            const params = JSON.parse(this.preference.fieldValue);

            this.router
              .navigate([], {
                relativeTo: this.activatedRoute,
                queryParams: params,
                queryParamsHandling: 'merge',
              })
              .then(
                () => {},
                () => {}
              )
              .catch(() => {})
              .finally(() => {});
          }

          this.removeMarkers();

          let zoom = 13;

          if (queryParams.zoom && queryParams.zoom > 0) {
            zoom = queryParams.zoom;
          }

          if (queryParams.lat && queryParams.lng) {
            const lat = +queryParams.lat;
            const lng = +queryParams.lng;
            if (!isNaN(lat) && !isNaN(lng)) {
              this.map.easeTo({
                center: [lng, lat],
                zoom: zoom,
              });
            }
          } else if (queryParams.bounds) {
            const arrBounds = queryParams.bounds.split(',');
            const boundsOptions = { bearing: 0, pitch: 0 };

            if (queryParams.bearing) {
              boundsOptions.bearing = queryParams.bearing;
            }
            if (queryParams.pitch) {
              boundsOptions.pitch = queryParams.pitch;
            }

            this.map.fitBounds(
              [
                [arrBounds[0], arrBounds[1]],
                [arrBounds[2], arrBounds[3]],
              ],
              boundsOptions
            );
          }

          // when the mmsi is defined in the url, show the ship detail in the popup
          if (queryParams.mmsi) {
            this.map.once('idle', (event: any) => {
              const marker = _.find(
                this.mapboxMarkers,
                // tslint:disable-next-line: triple-equals
                (m: any) => m.options.aisInfo.mmsi == queryParams.mmsi
              );
              if (marker) {
                this.showPopup(marker, true);
              }
            });
          }
        },
        () => {},
        () => {}
      )
    );
  }

  drawHistoricTrack(coordinates) {
    const mappedRoute = {
      type: 'geojson',
      data: {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: _.map(coordinates, (mapCoordinate) => {
            return [mapCoordinate.longitude, mapCoordinate.latitude];
          }),
        },
      },
    };
    if (this.map.getLayer('historicTrack')) {
      this.map.removeLayer('historicTrack');
    }
    if (this.map.getLayer('historicTrackPoints')) {
      this.map.removeLayer('historicTrackPoints');
    }
    if (this.map.getSource('historicTrack')) {
      this.map.removeSource('historicTrack');
    }
    if (this.map.getSource('historicTrackPoints')) {
      this.map.removeSource('historicTrackPoints');
    }
    this.map.addSource('historicTrack', mappedRoute);
    this.map.addSource('historicTrackPoints', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: _.map(coordinates, (trackItem) => {
          return {
            type: 'Feature',
            properties: {
              description: moment
                .utc(trackItem.positionDate)
                .local()
                .format('DD-MM-YYYY HH:mm'),
            },
            geometry: {
              type: 'Point',
              coordinates: [trackItem.longitude, trackItem.latitude],
            },
          };
        }),
      },
    });
    this.map.addLayer({
      id: 'historicTrack',
      type: 'line',
      source: 'historicTrack',
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': '#F7941D',
        'line-width': 4,
      },
    });
    this.map.addLayer({
      id: 'historicTrackPoints',
      type: 'circle',
      source: 'historicTrackPoints',
      paint: {
        'circle-color': '#081c39',
        'circle-radius': 3,
        'circle-stroke-width': 1,
        'circle-stroke-color': '#ffffff',
      },
    });
  }

  drawRoute(route: RouteCalculatorResponseModel) {
    const coordinates = _.map(route.routePoints, (rp) => [
      rp.longitude,
      rp.latitude,
    ]);
    const mappedRoute = {
      type: 'geojson',
      data: {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: coordinates,
        },
      },
    };
    if (this.map.getLayer('routeCalculation')) {
      this.map.removeLayer('routeCalculation');
    }
    if (this.map.getSource('routeCalculation')) {
      this.map.removeSource('routeCalculation');
    }
    this.map.addSource('routeCalculation', mappedRoute);
    this.map.addLayer({
      id: 'routeCalculation',
      type: 'line',
      source: 'routeCalculation',
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': '#081c39',
        'line-width': 8,
      },
    });

    const latitudes = _.map(route.routePoints, (rp) => rp.latitude);
    const longitudes = _.map(route.routePoints, (rp) => rp.longitude);
    const marginLat = (_.max(latitudes) - _.min(latitudes)) * 0.1;
    const marginLng = (_.max(longitudes) - _.min(longitudes)) * 0.1;

    this.map.fitBounds([
      [_.min(longitudes) - marginLng, _.max(latitudes) + marginLat],
      [_.max(longitudes) - marginLng, _.min(latitudes) - marginLat],
    ]);
  }

  loadData() {
    const bounds = this.map.getBounds();

    const boundsMargin = 0.1;
    const xMargin = (bounds.getEast() - bounds.getWest()) * boundsMargin;
    const yMargin = (bounds.getNorth() - bounds.getSouth()) * boundsMargin;

    if (this.map.getZoom() < 10) {
      this.mapInteractionService.getClustersForArea(
        bounds.getNorth(),
        bounds.getEast(),
        bounds.getSouth(),
        bounds.getWest(),
        Math.round(this.map.getZoom())
      );
    } else {
      this.mapInteractionService.getPositionsForArea(
        bounds.getNorth() + yMargin,
        bounds.getEast() + xMargin,
        bounds.getSouth() - yMargin,
        bounds.getWest() - xMargin
      );
    }
  }

  loadDataForPolygon() {
    const latitudes = _.map(
      this.points,
      (c: CoordinatePointModel) => c.latitude
    );
    const longitudes = _.map(
      this.points,
      (c: CoordinatePointModel) => c.longitude
    );

    this.mapInteractionService.getPositionsForAreaActivity(
      max(latitudes),
      max(longitudes),
      min(latitudes),
      min(longitudes)
    );
  }

  addClusters() {
    this._subscriptions.add(
      this.mapInteractionService.clustersLoaded$
        .pipe(takeUntil(this._destroy$))
        .subscribe(
          (clusters) => {
            this.removeMarkers();
            this.removeClusters();

            const geojson = _.map(
              clusters,
              (cluster) => new GeoJson([cluster.longitude, cluster.latitude])
            );

            const data = new FeatureCollection(geojson);
            let i = 0;
            data.features.forEach((cluster) =>
              this.clusterMarkers.push(
                this.mapMarkerComponent.instance.getCluster(
                  cluster,
                  clusters[i++]
                )
              )
            );

            if (this.clusterSource !== undefined) {
              this.clusterSource.setData(data);
            }
          },
          () => {},
          () => {}
        )
    );
  }

  addMarkers() {
    this._subscriptions.add(
      this.mapInteractionService.markersLoaded$
        .pipe(takeUntil(this._destroy$))
        .subscribe(
          (markers) => {
            if (this.mapInteractionService.isAreaActivityOpen) {
              this.mapInteractionService.getMarkersForAreaActivity(markers);
            }

            this.removeClusters();

            // create GeoJson
            this.markers = _.map(
              markers,
              (marker) => new GeoJson([marker.longitude, marker.latitude])
            );

            const data = new FeatureCollection(this.markers);
            let i = 0;
            data.features.forEach((marker) =>
              this.addMarker(marker, markers[i++])
            );

            if (this.markerSource !== undefined) {
              this.markerSource.setData(data);
            }
          },
          () => {},
          () => {}
        )
    );
  }

  addMarker(marker: GeoJson, aisInfo: AISMarkerModel) {
    // check if marker already exists on map
    const foundMarker = this.mapboxMarkers.find(
      // tslint:disable-next-line: triple-equals
      (markeResult) => markeResult.options.id == aisInfo.mmsi
    );

    if (foundMarker) {
      this.updateMarker(foundMarker, aisInfo);
    } else {
      // add created marker to mapboxMarkers array
      this.mapboxMarkers.push(this.createMarker(marker, aisInfo));
    }
  }

  createMarker(marker: GeoJson, aisInfo: AISMarkerModel) {
    let markerType = 'icon';
    const shape = [aisInfo.bow, aisInfo.stern, aisInfo.port, aisInfo.starboard];

    if (aisInfo.heading !== 0) {
      if (shape.length === 4) {
        if (
          (shape[0] + shape[1]) * this.defineScale('shape', aisInfo.latitude) >
          20
        ) {
          markerType = 'shape';
        }
      }
    }

    const mapboxMarker = this.mapMarkerComponent.instance.getMarker(marker, {
      id: aisInfo.mmsi,
      aisInfo: aisInfo,
      shipType: this.getShipType(aisInfo.shipType),
      markerType: markerType,
      shape: shape,
      scale: this.defineScale(markerType, aisInfo.latitude),
      isMoving: aisInfo.speed >= 1,
      mapRotation: this.map.getBearing(),
    });

    const userAgentTrident = window.navigator.userAgent.indexOf('Trident/');
    if (userAgentTrident > -1) {
      // fix for IE 11, event 'click' will not trigger, so use 'mousedown' event and ensure all other popups are close
      mapboxMarker.getElement().addEventListener('mousedown', () => {
        _.each(this.mapboxMarkers, (m: any) => {
          if (m.getPopup() && m.getPopup().isOpen()) {
            m.togglePopup();
          }
        });
        this.showPopup(mapboxMarker, true);
      });
    } else {
      mapboxMarker.getElement().addEventListener('click', () => {
        if (this.allowPopUp) {
          this.showPopup(mapboxMarker);
        } else {
          this.markerSelected.emit(mapboxMarker.options.aisInfo);
          this.flyTo(
            new GeoJson([
              mapboxMarker.options.aisInfo.longitude,
              mapboxMarker.options.aisInfo.latitude,
            ])
          );
        }
      });
    }

    if (this.mapMarkers && this.mapMarkers.nameAlwaysOn) {
      if (Math.floor(this.map.getZoom()) > 12) {
        this.showAllLabels = true;
        this.showLabel(mapboxMarker);
      } else if (this.showAllLabels) {
        this.showAllLabels = false;
        this.hideLabel();
      }
    }

    mapboxMarker.getElement().addEventListener('mouseenter', () => {
      if (!this.popupVisible) {
        // fix for IE 11, event 'mouseleave' will not trigger, so ensure all popups are close before show this one
        if (userAgentTrident > -1) {
          _.each(this.mapboxMarkers, (m: any) => {
            if (m.getPopup() && m.getPopup().isOpen()) {
              m.togglePopup();
            }
          });
        }

        this.showLabel(mapboxMarker);
      }
    });

    mapboxMarker.getElement().addEventListener('mouseleave', (elem: any) => {
      if (!this.popupVisible) {
        mapboxMarker.togglePopup();
      }
    });

    return mapboxMarker;
  }

  updateMarker(mapboxMarker: mapboxgl.Marker, aisInfo: AISMarkerModel) {
    // update lat & lon if they changed
    if (
      mapboxMarker.options.aisInfo.latitude !== aisInfo.latitude &&
      mapboxMarker.options.aisInfo.longitude !== aisInfo.longitude
    ) {
      mapboxMarker.setLngLat([aisInfo.longitude, aisInfo.latitude]);

      if (
        this.currentMarker &&
        // tslint:disable-next-line: triple-equals
        mapboxMarker.options.aisInfo.mmsi ==
          this.currentMarker.options.aisInfo.mmsi
      ) {
        this.flyTo(
          new GeoJson([
            mapboxMarker.options.aisInfo.longitude,
            mapboxMarker.options.aisInfo.latitude,
          ])
        );
      }
    }

    let markerType = 'icon';
    let shape = [aisInfo.bow, aisInfo.stern, aisInfo.port, aisInfo.starboard];

    if (aisInfo.heading !== 0) {
      if (shape && shape.length === 4) {
        if (
          (shape[0] + shape[1]) * this.defineScale('shape', aisInfo.latitude) >
          20
        ) {
          markerType = 'shape';
        }
      }
    }

    if (this.mapMarkers && this.mapMarkers.nameAlwaysOn) {
      if (Math.floor(this.map.getZoom()) > 13) {
        this.showAllLabels = true;
        this.showLabel(mapboxMarker);
      } else if (this.showAllLabels) {
        this.showAllLabels = false;
        this.hideLabel();
      }
    } else {
      this.showAllLabels = false;
      this.hideLabel();
    }

    if (Math.floor(this.map.getZoom()) >= 15 && markerType === 'icon') {
      markerType = 'shape';
      shape = [18, 18, 6, 6];
    }

    this.mapMarkerComponent.instance.updateOptions(mapboxMarker, {
      aisInfo: aisInfo,
      shape: shape,
      scale: this.defineScale(markerType, aisInfo.latitude),
      markerType: markerType,
      mapRotation: this.map.getBearing(),
    });
  }

  hideLabel() {
    if (this.mapLabelComponent) {
      this.mapLabelComponent.destroy();
    }
  }

  persistPreference() {
    if (this.isPreferenceSaving === false) {
      this.isPreferenceSaving = true;
      this._subscriptions.add(
        this.preferenceDataService.save(this.preference).subscribe(
          (response) => {
            if (response && response.hasResult) {
              this.identityService.setPreferences([response.result]);
              this.preference = response.result;
            }
          },
          (e) => {},
          () => {
            this.isPreferenceSaving = false;
          }
        )
      );
    }
  }

  showLabel(mapboxMarker) {
    const popupFactory =
      this.resolver.resolveComponentFactory(MapLabelComponent);
    this.mapLabelComponent =
      this.labelContainerRef.createComponent(popupFactory);
    this.mapLabelComponent.instance.marker = mapboxMarker;

    // adding popup on top of marker
    mapboxMarker
      .setPopup(
        new mapboxgl.Popup({ offset: 5, closeButton: false }).setDOMContent(
          this.mapLabelComponent.location.nativeElement
        )
      )
      .addTo(this.map);
    mapboxMarker.togglePopup();
  }

  showPopup(mapboxMarker: mapboxgl.Marker, triggerPopup: boolean = false) {
    if (mapboxMarker && mapboxMarker.options && mapboxMarker.options.aisInfo) {
      this.markerSelected.emit(mapboxMarker.options.aisInfo);
    }

    const aisInfo = mapboxMarker.options.aisInfo;
    this.currentMarker = mapboxMarker;
    this.popupVisible = true;

    // creating map-popup component dinamically
    const popupFactory =
      this.resolver.resolveComponentFactory(MapPopupComponent);
    this.mapPopupComponent =
      this.popupContainerRef.createComponent(popupFactory);
    this.mapPopupComponent.instance.marker = mapboxMarker;

    // adding popup on top of marker
    const markerPopup = new mapboxgl.Popup({
      offset: 5,
      markerId: aisInfo.mmsi,
    }).setDOMContent(this.mapPopupComponent.location.nativeElement);

    setTimeout(() => {
      markerPopup.on('close', (ev: any) => {
        this.popupVisible = false;
        this.currentMarker = null;
      });
    }, 1000);

    mapboxMarker.setPopup(markerPopup).addTo(this.map);

    // since the new version of mapbox, max-width is set 240px on the element. reset it ot max-width to 300px
    markerPopup.setMaxWidth('300px');

    if (triggerPopup) {
      this.flyTo(
        new GeoJson([
          mapboxMarker.options.aisInfo.longitude,
          mapboxMarker.options.aisInfo.latitude,
        ])
      );
    }

    // calculating ETA
    this._subscriptions.add(
      this.mapInteractionService.etaCalculationStarted$
        .pipe(takeUntil(this._destroy$))
        .subscribe(
          () => {
            this.calculationStarted = true;
            this.map.getCanvas().style.cursor = 'crosshair';

            const point = {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [aisInfo.longitude, aisInfo.latitude],
              },
              properties: {
                id: String(new Date().getTime()),
                aisInfo: aisInfo,
              },
            };

            this.geojson.features = [];
            this.geojson.features.push(point);
          },
          () => {},
          () => {}
        )
    );

    if (triggerPopup === true) {
      mapboxMarker.togglePopup();
    }
  }

  updateStaleMarkers() {
    if (!this.map) {
      return;
    }

    const bounds = this.map.getBounds();

    this.mapboxMarkers.forEach((marker) => {
      if (
        marker.options.aisInfo.latitude > bounds.getSouth() &&
        marker.options.aisInfo.longitude < bounds.getWest() &&
        marker.options.aisInfo.latitude > bounds.getNorth() &&
        marker.options.aisInfo.longitude < bounds.getEast()
      ) {
        marker.remove();

        const index = _.findIndex(
          this.mapboxMarkers,
          // tslint:disable-next-line: triple-equals
          (m: any) => m.options.aisInfo.mmsi == marker.options.aisInfo.mmsi
        );
        if (index > -1) {
          this.mapboxMarkers.splice(index, 1);
        }
      }
    });
  }

  calculateETA(event: any) {
    const features = this.map.queryRenderedFeatures(event.point, {
      layers: ['measure-points'],
    });

    // Remove the linestring from the group
    // So we can redraw it based on the points collection
    if (this.geojson.features.length > 1) {
      this.geojson.features.pop();
    }

    // If a feature was clicked, remove it from the map
    if (features.length) {
      const id = features[0].properties.id;
      this.geojson.features = this.geojson.features.filter(function (point) {
        return point.properties.id !== id;
      });
    } else {
      const point = {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [event.lngLat.lng, event.lngLat.lat],
        },
        properties: {
          id: String(new Date().getTime()),
        },
      };

      this.geojson.features.push(point);
    }

    if (this.geojson.features.length > 1) {
      this.linestring.geometry.coordinates = this.geojson.features.map(
        function (point) {
          return point.geometry.coordinates;
        }
      );

      this.geojson.features.push(this.linestring);
      const distance = +turf.lineDistance(this.linestring.geometry);
      let speed = this.geojson.features[0].properties.aisInfo.speed;
      // 6 = min speed, 16 = avg speed
      if (distance < 50 && (speed < 6 || speed > 16)) {
        speed = 6;
      } else if (distance > 50 && speed < 16) {
        speed = 16;
      }
      speed = speed * 1.852;
      const timeInSeconds = (distance / speed) * 3600;
      const eta = new Date();
      eta.setSeconds(eta.getSeconds() + timeInSeconds);

      const currentDateTime = moment();
      const duration = moment.duration(moment(eta).diff(currentDateTime));

      const days = Math.floor(duration.asDays());
      const hours = duration.hours();
      const minutes = duration.minutes();

      const totalTimeString = [
        days.toString().padStart(2, '0'),
        hours.toString().padStart(2, '0'),
        minutes.toString().padStart(2, '0'),
      ].join(':');

      let distanceContainer = document.getElementById('distance');
      if (distanceContainer === null) {
        distanceContainer = document.createElement('div');
        distanceContainer.id = 'distance';
        distanceContainer.style.color = '#fff';
        distanceContainer.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
        distanceContainer.style.fontSize = '11px';
        distanceContainer.style.lineHeight = '18px';
        distanceContainer.style.display = 'flex';
        distanceContainer.style.flexDirection = 'row';
        distanceContainer.style.alignItems = 'flex-start';
        distanceContainer.style.padding = '5px 10px';
        distanceContainer.style.borderRadius = '3px';
        distanceContainer.style.zIndex = '1';
      }
      distanceContainer.innerHTML = '';

      const value = document.createElement('div');
      value.style.whiteSpace = 'pre';

      const distanceIcon = this.createIcon('fa-regular fa-ruler-combined');
      const speedometerIcon = this.createIcon('fa-regular fa-gauge-max');
      const clockIcon = this.createIcon('fa-regular fa-clock');
      const totalTimeIcon = this.createIcon('fa-regular fa-hourglass-clock');

      value.appendChild(distanceIcon);
      value.appendChild(
        document.createTextNode(
          ` ${this.translateService.instant(
            'map.popup.totalDistance'
          )}: ${distance.toLocaleString()}km\n`
        )
      );

      value.appendChild(speedometerIcon);
      value.appendChild(
        document.createTextNode(
          ` ${this.translateService.instant(
            'map.popup.speed'
          )}: ${speed.toFixed(3)}km/h\n`
        )
      );

      value.appendChild(clockIcon);
      value.appendChild(
        document.createTextNode(
          ` ${this.translateService.instant('map.popup.eta')}: ${moment(
            eta
          ).format('DD-MM-YYYY hh:mm')}\n`
        )
      );

      value.appendChild(totalTimeIcon);
      value.appendChild(
        document.createTextNode(
          ` ${this.translateService.instant(
            'map.popup.totalTime'
          )}: ${totalTimeString}`
        )
      );
      distanceContainer.appendChild(value);

      const span = document.createElement('span');
      span.innerHTML = 'x';
      span.style.textAlign = 'right';
      span.title = this.translateService.instant('shared.terms.close');
      span.style.padding = '0 5px 0 5px';
      span.style.borderRadius = '2px';
      span.style.background = 'rgba(0, 0, 0, 0.2)';
      span.style.marginLeft = '10px';
      span.onmouseover = () => {
        span.style.cursor = 'default';
        span.style.color = '#ccc';
      };
      span.onmouseleave = () => {
        span.style.color = '#fff';
      };
      span.onclick = () => {
        distanceContainer.parentNode.removeChild(distanceContainer);
        this.geojson.features = [];
        this.map.getSource('geojson').setData(this.geojson);
      };
      distanceContainer.appendChild(span);

      new mapboxgl.Marker(distanceContainer)
        .setLngLat(this.geojson.features[1].geometry.coordinates)
        .addTo(this.map);
    }

    this.map.getSource('geojson').setData(this.geojson);
  }

  createIcon(className) {
    const icon = document.createElement('i');
    icon.className = className;
    icon.style.marginRight = '5px';
    return icon;
  }

  buildMap() {
    this.mapInteractionService.map = new mapboxgl.Map({
      container: 'nautical-map',
      style: this.style,
      zoom: 7,
      minZoom: 1.8,
      maxZoom: 17,
      center: [this.lng, this.lat],
      bearing: 0,
      pitch: 0,
      pitchWithRotate: false,
    });

    this.map = this.mapInteractionService.map;

    this.map.dragRotate.disable();
    this.map.touchZoomRotate.disableRotation();

    /// Add map controls
    const nav = new mapboxgl.NavigationControl();
    this.map.addControl(nav);

    // disable pitching during rotating the map
    nav._handler._pitchWithRotate = false;

    //// Add Marker on Click
    this.map.on('click', this.mapClickListener);

    /// Add realtime firebase data on map load
    this.map.once('load', this.mapLoadListener);
  }

  mapLoadListener = () => {
    /// register source
    this.map.addSource('firebase', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    });

    this.map.addSource('clusters', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
      clusterRadius: 70,
    });

    /// get source
    this.markerSource = this.map.getSource('firebase');
    this.clusterSource = this.map.getSource('clusters');

    this.map.addSource('geojson', {
      type: 'geojson',
      data: this.geojson,
    });

    // layers
    this.map.addLayer({
      id: 'firebase',
      source: 'firebase',
      type: 'symbol',
    });

    // Add styles to the map
    this.map.addLayer({
      id: 'measure-points',
      type: 'circle',
      source: 'geojson',
      paint: {
        'circle-radius': 5,
        'circle-color': '#000',
      },
      filter: ['in', '$type', 'Point'],
    });

    this.map.addLayer({
      id: 'measure-lines',
      type: 'line',
      source: 'geojson',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': '#000',
        'line-width': 2.5,
      },
      filter: ['in', '$type', 'LineString'],
    });

    this.loadData();
  };

  mapClickListener = (event: any) => {
    if (this.calculationStarted) {
      this.calculateETA(event);
      this.calculationStarted = false;
      this.map.getCanvas().style.cursor = 'grab';
    }
    // this.mapInteractionService.createMarker(newMarker)

    if (this.clusterMarkers.length === 0) {
      return;
    }

    // check if clicked item is cluster
    const isCluster =
      event.originalEvent.path &&
      event.originalEvent.path.find(
        (element) =>
          element.classList &&
          element.classList.contains('mapboxgl-marker') &&
          element.classList.contains('mapboxgl-marker-anchor-center')
      );

    if (isCluster) {
      this.map.flyTo({
        center: [event.lngLat.lng, event.lngLat.lat],
        zoom: this.map.getZoom() + 3,
        bearing: 0,
        speed: 1,
        curve: 1,
        easing: function (t) {
          return t;
        },
      });

      return;
    }
  };

  showPolygon() {
    const points = _.map(this.points, (c: CoordinatePointModel) => [
      c.longitude,
      c.latitude,
    ]);

    const area = this.map.getSource('area');

    if (area) {
      area.setData({
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [points],
            },
          },
        ],
      });
    } else {
      this.map.addSource('area', {
        type: 'geojson',
        data: {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [points],
          },
        },
      });
    }

    if (!this.map.getLayer('area')) {
      this.map.addLayer({
        id: 'area',
        type: 'fill',
        source: 'area',
        paint: {
          'fill-color': '#808080',
          'fill-opacity': 0.2,
        },
      });
    }

    if (!this.map.getLayer('outline')) {
      this.map.addLayer({
        id: 'outline',
        type: 'line',
        source: 'area',
        layout: {},
        paint: {
          'line-color': '#000',
          'line-width': 1,
        },
      });
    }

    const center = this.mapDataService.calculatePolygonCenter(this.points);

    this.map.flyTo({
      center: center,
      zoom: 15,
      bearing: 0,
      speed: 1,
      curve: 1,
      easing: function (t) {
        return t;
      },
    });

    this.loadDataForPolygon();
  }

  /// Helpers

  removeMarker() {
    //   this.mapInteractionService.removeMarker(marker.$key)
  }

  removeMarkers() {
    if (this.mapboxMarkers.length > 0) {
      this.mapboxMarkers.forEach((x) => x.remove());
      this.mapboxMarkers = [];
    }
  }

  removeClusters() {
    if (this.clusterMarkers.length > 0) {
      this.clusterMarkers.forEach((x) => x.remove());
      this.clusterMarkers = [];
    }
  }

  flyTo(data: GeoJson) {
    this.map.flyTo({
      center: data.geometry.coordinates,
    });
  }

  defineScale(markerType, latitude) {
    const mapZoom = Math.floor(this.map.getZoom());

    if (!markerType) {
      markerType = 'icon';
    }
    if (markerType === 'icon') {
      if (mapZoom <= 11) {
        return 0.1;
      }

      if (mapZoom > 11 && mapZoom <= 12) {
        return 0.2;
      }

      if (mapZoom > 12 && mapZoom <= 13) {
        return 0.5;
      } else if (mapZoom > 13 && mapZoom <= 14) {
        return 0.6;
      } else if (mapZoom > 14 && mapZoom <= 17) {
        return 0.75;
      } else if (mapZoom > 17) {
        return 1;
      } else {
        return 0.5;
      }
    } else if (markerType === 'shape') {
      return 1 / this.getMeterPerPixel(latitude);
    }
  }

  getMeterPerPixel(latitude) {
    const zoomLevel = this.map.getZoom();

    // Zoom levels and geographical distance
    let zoomFactors = null;
    if (zoomLevel < 0) {
      zoomFactors = this.zoomLevels[0];
    } else if (zoomLevel > 22) {
      zoomFactors = this.zoomLevels[22]; // max zoomlevel is 22
    } else if (Number.isInteger(zoomLevel)) {
      zoomFactors = this.zoomLevels[zoomLevel];
    } else {
      const floorFactors = this.zoomLevels[Math.floor(zoomLevel)];
      const ceilFactors = this.zoomLevels[Math.ceil(zoomLevel)];

      const zoomRemainder = zoomLevel - Math.floor(zoomLevel);
      zoomFactors = _.map(floorFactors, (f: number, i) => {
        return f - (f - ceilFactors[i]) * zoomRemainder;
      });
    }

    const lat = Math.abs(latitude);
    const factor20 = lat / 20;
    const basisIndex = factor20 > 4 ? 4 : Math.floor(factor20); // max index (Latitude) is 4
    const basisFactor = zoomFactors[basisIndex];

    if (Number.isInteger(factor20) === false || factor20 > 4) {
      let startIndex;
      let eindIndex;
      if (basisIndex < 4) {
        startIndex = basisIndex;
        eindIndex = basisIndex + 1;
      } else {
        startIndex = 0;
        eindIndex = 4;
      }

      const restFacotr =
        ((zoomFactors[startIndex] - zoomFactors[eindIndex]) /
          ((eindIndex - startIndex) * 20)) *
        (latitude - basisIndex * 20);
      return basisFactor - restFacotr;
    }

    return basisFactor;
  }

  getShipType(shipType) {
    if (shipType >= 20 && shipType <= 29) {
      // WIG
      return 'Wing-in-Ground craft';
    } else if (shipType === 30) {
      // Fishing
      return 'Fishing';
    } else if (shipType === 31 || shipType === 32) {
      // Towing
      return 'Towing';
    } else if (shipType === 33) {
      // Dredgin
      return 'Dredgin';
    } else if (shipType === 34) {
      // Diving
      return 'Diving';
    } else if (shipType === 35) {
      // Military
      return 'Military';
    } else if (shipType === 36) {
      // Sailing
      return 'Sailing';
    } else if (shipType === 37) {
      // Pleasure
      return 'Pleasure';
    } else if (shipType >= 41 && shipType <= 49) {
      // High speed craft (HSC)
      return 'High Speed Craft';
    } else if (shipType === 50) {
      // Pilot
      return 'Pilot';
    } else if (shipType === 51) {
      // SAR
      return 'Search and Rescue';
    } else if (shipType === 52) {
      // Tug
      return 'Tug';
    } else if (shipType === 53) {
      // Port Tender
      return 'Port Tender';
    } else if (shipType === 54) {
      // Anti-pollution equipment
      return 'Anti-Pollution Equipment';
    } else if (shipType === 55) {
      // Law enforcement
      return 'Law Enforcement';
    } else if (shipType === 56 || shipType === 57) {
      // Spare - local vessel
      return 'Spare - Local Vessel';
    } else if (shipType === 58) {
      // Medical transport
      return 'Medical Transport';
    } else if (shipType === 39) {
      // Ship according to RR Resolution No. 18
      return 'RR Resolution No.18 (Mob-83)';
    } else if (shipType >= 60 && shipType <= 69) {
      // Passenger
      return 'Passenger';
    } else if (shipType >= 70 && shipType <= 79) {
      // Cargo
      return 'Cargo';
    } else if (shipType >= 80 && shipType <= 89) {
      // Tanker
      return 'Tanker';
    } else if (shipType > 90) {
      // Other
      return 'Other Shiptype';
    } else {
      return 'Unknown Shiptype';
    } // Default
  }

  ngOnDestroy() {
    if (this.mapMoveendListener) {
      this.map.off('moveend', this.mapMoveendListener);
      this.mapMoveendListener = null;
    }

    if (this.mapClickListener) {
      this.map.off('click', this.mapClickListener);
      this.mapClickListener = null;
    }

    if (this.mapLoadListener) {
      this.map.off('load', this.mapLoadListener);
      this.mapLoadListener = null;
    }

    this._destroy$.next();
    this._destroy$.complete();

    this._subscriptions.unsubscribe();
    this.map.remove();
  }
}
