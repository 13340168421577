<div class="modal-header align-items-center">
  <h4 class="modal-title">
    {{
      (isNew ? 'companies.address.addAddress' : 'companies.address.editAddress')
        | translate
    }}
  </h4>
  <button (click)="close()" type="button" class="btn btn-link text-dark">
    <i class="fa-solid fa-times fa-fw fa-sm"></i>
  </button>
</div>

<div class="modal-body">
  <address-form
    [showContactField]="showContactField"
    [model]="address"
    *ngIf="address"
    [submitted]="isSubmitted"
  ></address-form>
</div>

<div class="modal-footer d-flex">
  <div class="flex-grow-1">
    <button (click)="close()" type="button" class="btn btn-block btn-link">
      {{ 'shared.terms.cancel' | translate }}
    </button>
  </div>
  <div class="flex-grow-1">
    <button
      [disabled]="address.type === undefined"
      (click)="save()"
      type="button"
      class="btn btn-block btn-success"
    >
      {{ 'shared.terms.save' | translate }}
    </button>
  </div>
</div>
