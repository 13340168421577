import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveEnd, Router } from '@angular/router';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { filter } from 'rxjs/operators';
import { EnvService } from '../configuration/env.service';

@Injectable()
export class ApplicationInsightsService {
  private appInsights = null;

  constructor(private environment: EnvService, private router: Router) {
    if (this.environment.applicationInsightsKey !== 'X') {
      this.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: this.environment.applicationInsightsKey,
        },
      });
      this.appInsights.loadAppInsights();
      
      this.router.events
        .pipe(filter((event) => event instanceof ResolveEnd))
        .subscribe((event: any) => {
          const activatedComponent = this.getActivatedComponent(
            event.state.root
          );
          if (activatedComponent) {
            this.logPageView(
              `${activatedComponent.name} ${this.getRouteTemplate(
                event.state.root
              )}`,
              event.urlAfterRedirects
            );
          }
        });
    }
  }

  setUserId(userId: string) {
    this.appInsights.setAuthenticatedUserContext(userId);
  }

  clearUserId() {
    this.appInsights.clearAuthenticatedUserContext();
  }

  logPageView(name?: string, uri?: string) {
    this.appInsights.trackPageView({ name, uri });
  }

  private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }

    return snapshot.component;
  }

  private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
    let path = '';
    if (snapshot.routeConfig) {
      path += snapshot.routeConfig.path;
    }

    if (snapshot.firstChild) {
      return path + this.getRouteTemplate(snapshot.firstChild);
    }

    return path;
  }
}
