import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbDate, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Component({
  selector: 'ca-date-time-period-picker',
  templateUrl: 'date-time-period-picker.component.html',
  styles: [
    `
      .dp-hidden {
        width: 0;
        margin: 0;
        border: none;
        padding: 0;
      }

      .custom-day {
        text-align: center;
        padding: 0.185rem 0.25rem;
        display: inline-block;
        height: 2rem;
        width: 2rem;
      }

      .custom-day.focused {
        background-color: #e6e6e6;
      }

      .custom-day.range,
      .custom-day:hover {
        background-color: rgb(2, 117, 216);
        color: white;
      }

      .custom-day.faded {
        background-color: rgba(2, 117, 216, 0.5);
      }
    `,
  ],
})
export class DateTimePeriodPickerComponent {
  @Input()
  set dateTimeStart(dateTimeStart: moment.Moment | Date | NgbDate | string) {
    this._dateTimeStart = dateTimeStart;

    if (dateTimeStart) {
      if (dateTimeStart instanceof NgbDate) {
        this.dateTimeStartFormat = 'ngbDate';
      } else if (moment.isMoment(dateTimeStart)) {
        this.dateTimeStartFormat = 'moment';
      } else if (moment.isDate(dateTimeStart)) {
        this.dateTimeStartFormat = 'date';
      } else if (dateTimeStart.split('T').length === 2) {
        if (dateTimeStart.split(':').length === 2) {
          this.dateTimeStartFormat = 'YYYY-MM-DDTHH:mm';
        } else if (dateTimeStart.split(':').length === 3) {
          this.dateTimeStartFormat = 'YYYY-MM-DDTHH:mm:ss';
        }
      } else if (dateTimeStart.split(':').length === 2) {
        this.dateTimeStartFormat = 'HH:mm';
      } else if (dateTimeStart.split(':').length === 3) {
        this.dateTimeStartFormat = 'HH:mm:ss';
      }
    }

    this.setNgbDatesFromDateTimes(false, true);
  }

  get dateTimeStart(): moment.Moment | Date | NgbDate | string {
    return this._dateTimeStart;
  }

  @Input()
  set dateTimeEnd(dateTimeEnd: moment.Moment | Date | NgbDate | string) {
    this._dateTimeEnd = dateTimeEnd;

    if (dateTimeEnd) {
      if (dateTimeEnd instanceof NgbDate) {
        this.dateTimeEndFormat = 'ngbDate';
      } else if (moment.isMoment(dateTimeEnd)) {
        this.dateTimeEndFormat = 'moment';
      } else if (moment.isDate(dateTimeEnd)) {
        this.dateTimeEndFormat = 'date';
      } else if (dateTimeEnd.split('T').length === 2) {
        if (dateTimeEnd.split(':').length === 2) {
          this.dateTimeEndFormat = 'YYYY-MM-DDTHH:mm';
        } else if (dateTimeEnd.split(':').length === 3) {
          this.dateTimeEndFormat = 'YYYY-MM-DDTHH:mm:ss';
        }
      } else if (dateTimeEnd.split(':').length === 2) {
        this.dateTimeEndFormat = 'HH:mm';
      } else if (dateTimeEnd.split(':').length === 3) {
        this.dateTimeEndFormat = 'HH:mm:ss';
      }
    }

    this.setNgbDatesFromDateTimes(true, false);
  }

  get dateTimeEnd(): moment.Moment | Date | NgbDate | string {
    return this._dateTimeEnd;
  }

  @Input() invalidBefore: moment.Moment | Date | NgbDate | string;
  @Input() invalidAfter: moment.Moment | Date | NgbDate | string;
  @Input() dateTimeStartFormat: string; // 'moment', 'date', 'ngbDate ,'YYYY-MM-DDTHH:mm(:ss)' & custom format
  @Input() dateTimeEndFormat: string; // 'moment', 'date', 'ngbDate ,'YYYY-MM-DDTHH:mm(:ss)' & custom format
  @Input() isReadOnly!: boolean;
  @Input() isContained!: boolean;
  @Input() showTime!: boolean;
  @Input() hideTicker!: boolean;
  @Input() hideDate!: boolean;
  @Input() hideClear!: boolean;
  @Input() hideNow!: boolean;
  @Input() hideCalendar!: boolean;
  @Input() hideInput!: boolean;

  @Output() dateTimeStartChange!: EventEmitter<
    moment.Moment | Date | NgbDate | string
  >;
  @Output() dateTimeEndChange!: EventEmitter<
    moment.Moment | Date | NgbDate | string
  >;

  ngbDateStart: NgbDate;
  ngbDateEnd: NgbDate;
  ngbDateHover: NgbDate;
  isInvalidStart!: boolean;
  isInvalidEnd!: boolean;

  private _dateTimeStart: moment.Moment | Date | NgbDate | string;
  private _dateTimeEnd: moment.Moment | Date | NgbDate | string;

  constructor() {
    this.dateTimeStart = null;
    this.dateTimeEnd = null;
    this.hideDate = false;
    this.showTime = false;
    this.invalidBefore = null;
    this.invalidAfter = null;
    this.dateTimeStartFormat = 'YYYY-MM-DDTHH:mm';
    this.dateTimeEndFormat = 'YYYY-MM-DDTHH:mm';
    this.isReadOnly = false;
    this.isContained = false;
    this.hideTicker = false;
    this.hideClear = false;
    this.hideNow = false;
    this.hideCalendar = false;
    this.hideInput = false;
    this.dateTimeStartChange = new EventEmitter<
      moment.Moment | Date | NgbDate | string
    >();
    this.dateTimeEndChange = new EventEmitter<
      moment.Moment | Date | NgbDate | string
    >();
    this.ngbDateStart = new NgbDate(null, null, null);
    this.ngbDateEnd = new NgbDate(null, null, null);
    this.ngbDateHover = new NgbDate(null, null, null);
    this.isInvalidStart = false;
    this.isInvalidEnd = false;
    this._dateTimeStart = null;
    this._dateTimeEnd = null;
  }

  setNgbDatesFromDateTimes(skipStart?: boolean, skipEnd?: boolean) {
    const dateTimeKeys = [];

    if (!skipStart) {
      dateTimeKeys.push('Start');
    }

    if (!skipEnd) {
      dateTimeKeys.push('End');
    }

    dateTimeKeys.forEach((key) => {
      const ngbDateKey = `ngbDate${key}`;
      const dateTimeKey = `dateTime${key}`;
      const dateTimeKeyFormat = `dateTime${key}Format`;
      const dateTimeMoment = this.dateTimeToMoment(
        this[dateTimeKey],
        this[dateTimeKeyFormat]
      );

      if (dateTimeMoment && !this.hideDate) {
        this[ngbDateKey] = new NgbDate(
          +dateTimeMoment.format('YYYY'),
          +dateTimeMoment.format('M'),
          +dateTimeMoment.format('D')
        );
      } else {
        this[ngbDateKey] = new NgbDate(null, null, null);
      }
    });
  }

  isHovered(date: NgbDate) {
    return (
      this.ngbDateStart &&
      !this.ngbDateEnd &&
      this.ngbDateHover &&
      date.after(this.ngbDateStart) &&
      date.before(this.ngbDateHover)
    );
  }

  isInside(date: NgbDate) {
    return (
      this.ngbDateEnd &&
      date.after(this.ngbDateStart) &&
      date.before(this.ngbDateEnd)
    );
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.ngbDateStart) ||
      (this.ngbDateEnd && date.equals(this.ngbDateEnd)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  onDateSelect(ngbDateKey: NgbDate, ngbDatePicker: NgbInputDatepicker) {
    const ngbDateStartValid =
      this.ngbDateStart != null &&
      this.ngbDateStart.year != null &&
      this.ngbDateStart.month != null &&
      this.ngbDateStart.day != null;

    const ngbDateEndValid =
      this.ngbDateEnd != null &&
      this.ngbDateEnd.year != null &&
      this.ngbDateEnd.month != null &&
      this.ngbDateEnd.day != null;

    const ngbDateKeyMoment = moment(
      `${ngbDateKey.year}-${ngbDateKey.month}-${ngbDateKey.day}`,
      'YYYY-M-D'
    );

    if (ngbDateStartValid) {
      if (ngbDateEndValid) {
        this.ngbDateStart = ngbDateKey;
        this.ngbDateEnd = new NgbDate(null, null, null);
      } else {
        // completed
        const ngbDateStartMoment = moment(
          `${this.ngbDateStart.year}-${this.ngbDateStart.month}-${this.ngbDateStart.day}`,
          'YYYY-M-D'
        );

        if (ngbDateStartMoment.isAfter(ngbDateKeyMoment)) {
          this.ngbDateEnd = this.ngbDateStart;
          this.ngbDateStart = ngbDateKey;
        } else {
          this.ngbDateEnd = ngbDateKey;
        }

        ngbDatePicker.close();
        this.setDateTimesFromNgbDates();
      }
    } else if (ngbDateEndValid) {
      // completed
      const ngbDateEndMoment = moment(
        `${this.ngbDateEnd.year}-${this.ngbDateEnd.month}-${this.ngbDateEnd.day}`,
        'YYYY-M-D'
      );

      if (ngbDateEndMoment.isSameOrBefore(ngbDateKeyMoment)) {
        this.ngbDateStart = this.ngbDateEnd;
        this.ngbDateEnd = ngbDateKey;
      } else {
        this.ngbDateStart = ngbDateKey;
      }

      ngbDatePicker.close();
      this.setDateTimesFromNgbDates();
    } else {
      this.ngbDateStart = ngbDateKey;
      this.ngbDateEnd = new NgbDate(null, null, null);
    }
  }

  setNull() {
    this.dateTimeStartChange.emit(null);
    this.dateTimeEndChange.emit(null);
    this.ngbDateStart = new NgbDate(null, null, null);
    this.ngbDateEnd = new NgbDate(null, null, null);
  }

  setNow() {
    const now = moment();
    const next = this.showTime ? now.clone() : now.clone().add(1, 'weeks');

    const difference =
      this.dateTimeStart && this.dateTimeEnd
        ? this.dateTimeToMoment(this.dateTimeEnd, this.dateTimeEndFormat)
            .startOf('minute')
            .diff(
              this.dateTimeToMoment(
                this.dateTimeStart,
                this.dateTimeStartFormat
              ).startOf('minute')
            )
        : next.diff(now);

    const dateTimeEndMoment = now.clone().add(difference);
    const dateTimeStart = this.momentToDateTime(now, this.dateTimeStartFormat);
    const dateTimeEnd = this.momentToDateTime(
      dateTimeEndMoment,
      this.dateTimeEndFormat
    );

    this.dateTimeStartChange.emit(dateTimeStart);
    this.dateTimeEndChange.emit(dateTimeEnd);
  }

  setPrevious() {
    const dateTimeStartMoment = this.dateTimeToMoment(
      this.dateTimeStart,
      this.dateTimeStartFormat
    ).startOf('minute');
    const dateTimeEndMoment = this.dateTimeToMoment(
      this.dateTimeEnd,
      this.dateTimeEndFormat
    ).startOf('minute');
    const difference = dateTimeEndMoment.diff(dateTimeStartMoment);
    const dateTimeStartMomentSubstracted = dateTimeStartMoment
      .clone()
      .subtract(difference);
    const dateTimeEndMomentSubstracted = dateTimeEndMoment
      .clone()
      .subtract(difference)
      .startOf('minute');
    const dateTimeStart = this.momentToDateTime(
      dateTimeStartMomentSubstracted,
      this.dateTimeStartFormat
    );
    const dateTimeEnd = this.momentToDateTime(
      dateTimeEndMomentSubstracted,
      this.dateTimeEndFormat
    );

    this.dateTimeStartChange.emit(dateTimeStart);
    this.dateTimeEndChange.emit(dateTimeEnd);
  }

  setNext() {
    const dateTimeStartMoment = this.dateTimeToMoment(
      this.dateTimeStart,
      this.dateTimeStartFormat
    ).startOf('minute');
    const dateTimeEndMoment = this.dateTimeToMoment(
      this.dateTimeEnd,
      this.dateTimeEndFormat
    ).startOf('minute');
    const difference = dateTimeEndMoment.diff(dateTimeStartMoment);
    const dateTimeStartMomentAdded = dateTimeStartMoment
      .clone()
      .add(difference);
    const dateTimeEndMomentAdded = dateTimeEndMoment
      .clone()
      .add(difference)
      .startOf('minute');
    const dateTimeStart = this.momentToDateTime(
      dateTimeStartMomentAdded,
      this.dateTimeStartFormat
    );
    const dateTimeEnd = this.momentToDateTime(
      dateTimeEndMomentAdded,
      this.dateTimeEndFormat
    );

    this.dateTimeStartChange.emit(dateTimeStart);
    this.dateTimeEndChange.emit(dateTimeEnd);
  }

  dateTimeToMoment(
    dateTime: moment.Moment | Date | NgbDate | string,
    dateTimeFormat: string
  ): moment.Moment {
    let toMoment: moment.Moment = null;

    if (dateTime) {
      if (dateTime instanceof NgbDate) {
        if (dateTime.year && dateTime.month && dateTime.day) {
          toMoment = moment(
            `${dateTime.year}-${dateTime.month}-${dateTime.day}`,
            'YYYY-M-D'
          );
        }
      } else if (moment.isMoment(dateTime)) {
        toMoment = dateTime;
      } else if (moment.isDate(dateTime)) {
        toMoment = moment(dateTime);
      } else if (dateTimeFormat) {
        toMoment = moment(dateTime, dateTimeFormat);
      } else if (dateTime.split('T').length === 2) {
        if (dateTime.split(':').length === 2) {
          toMoment = moment(dateTime, 'YYYY-MM-DDTHH:mm');
        } else if (dateTime.split(':').length === 3) {
          toMoment = moment(dateTime, 'YYYY-MM-DDTHH:mm:ss');
        }
      } else if (dateTime.split(':').length === 2) {
        toMoment = moment(dateTime, 'HH:mm');
      } else if (dateTime.split(':').length === 3) {
        toMoment = moment(dateTime, 'HH:mm:ss');
      }
    }

    return toMoment;
  }

  momentToDateTime(
    dateTimeMoment: moment.Moment,
    dateTimeFormat: string
  ): moment.Moment | Date | NgbDate | string {
    let toDateTime: moment.Moment | Date | NgbDate | string = null;

    if (dateTimeMoment) {
      switch (dateTimeFormat) {
        case 'ngbDate':
          toDateTime = new NgbDate(
            +dateTimeMoment.format('YYYY'),
            +dateTimeMoment.format('M'),
            +dateTimeMoment.format('D')
          );
          break;
        case 'moment':
          toDateTime = dateTimeMoment;
          break;
        case 'date':
          toDateTime = dateTimeMoment.toDate();
          break;
        default: // 'YYYY-MM-DDTHH:mm(:ss)' & custom format
          toDateTime = dateTimeMoment.format(dateTimeFormat);
          break;
      }
    }

    return toDateTime;
  }

  setDateTimesFromNgbDates(skipStart?: boolean, skipEnd?: boolean) {
    const dateTimeKeys = [];

    if (!skipStart) {
      this.isInvalidStart = true;

      dateTimeKeys.push('Start');
    }

    if (!skipEnd) {
      this.isInvalidEnd = true;

      dateTimeKeys.push('End');
    }

    dateTimeKeys.forEach((key) => {
      const ngbDateKey = `ngbDate${key}`;
      const dateTimeKeyFormat = `dateTime${key}Format`;
      const dateTimeKeyChange = `dateTime${key}Change`;
      const isInvalidKey = `isInvalid${key}`;
      let dateTimeMoment: moment.Moment = null;

      if (
        this[ngbDateKey] &&
        this[ngbDateKey].year &&
        this[ngbDateKey].month &&
        this[ngbDateKey].day
      ) {
        dateTimeMoment = moment(
          `${this[ngbDateKey].year}-${this[ngbDateKey].month}-${this[ngbDateKey].day}`,
          'YYYY-M-D'
        );
      }

      const dateTime = this.momentToDateTime(
        dateTimeMoment,
        this[dateTimeKeyFormat]
      );
      this[isInvalidKey] = false;

      this[dateTimeKeyChange].emit(dateTime);
    });
  }
}
