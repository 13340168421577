import { Directive, HostListener } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Directive({
  selector: 'button[closeModal]',
})
export class CloseModalDirective {
  @HostListener('click', ['$event.target']) onclick() {
    this.modalService?.dismissAll();
  }
  constructor(private modalService: NgbModal) {}
}
