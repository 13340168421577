import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import { CompanyDepartmentModel } from '@seahorse/domain';
import { ContactDataService } from '../../../../company/services/contact-data.service';
import { MultiSelectOption } from '@seahorse/forms';
import { CreateDepartmentForm } from './department.form';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ca-department-form',
  templateUrl: './department-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DepartmentFormComponent implements OnDestroy, OnInit {
  private _changeDetectorRef = inject(ChangeDetectorRef);
  private hasInitializedContacts = false;
  private _model: CompanyDepartmentModel;
  @Input()
  set model(value: CompanyDepartmentModel) {
    this._model = value;
    if (!this.hasInitializedContacts && value?.departmentContacts?.length > 0) {
      const newContacts =
        value.departmentContacts?.map((x) => ({
          label: x.name,
          value: x.id,
        })) || [];
      if (this.contactList.length === 0) {
        this.contactList = newContacts;
      } else {
        newContacts.forEach((x) => {
          if (this.contactList.findIndex((y) => y.value === x.value) === -1) {
            this.contactList.push(x);
          }
        });
      }
      this.form.setValue({
        contacts: value.departmentContacts?.map((x) => x.id) ?? [],
      });
      this.hasInitializedContacts = true;
    }
  }
  get model(): CompanyDepartmentModel {
    return this._model;
  }

  @Input() submitted = false;
  @Input() companyId: number;

  isLoadingContacts = false;
  form = CreateDepartmentForm();
  contactList: MultiSelectOption[] = [];

  constructor(private contactDataService: ContactDataService) {}

  private _subscriptions = new Subscription();
  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  ngOnInit() {
    this.isLoadingContacts = true;
    this._changeDetectorRef.detach();
    this._subscriptions.add(
      this.contactDataService.getAll(this.companyId).subscribe((result) => {
        const newContacts =
          result.result?.map((x) => ({ label: x.nameInfo, value: x.id })) || [];
        if (this.contactList.length === 0) {
          this.contactList = newContacts;
        } else {
          newContacts.forEach((x) => {
            if (this.contactList.findIndex((y) => y.value === x.value) === -1) {
              this.contactList.push(x);
            }
          });
        }
        this._changeDetectorRef.reattach();
        this.isLoadingContacts = false;
      })
    );

    this._subscriptions.add(
      this.form.get('contacts').valueChanges.subscribe((value) => {
        this.model.departmentContacts =
          this.contactList
            ?.filter((x) => value.includes(+x.value))
            ?.map((x) => {
              return { id: x.value, name: x.label };
            }) ?? [];
      })
    );
  }
}
