import { TranslateService } from '@ngx-translate/core';
import { PipeModel } from '@seahorse/common';
import {
  BaseFieldRule,
  FieldDefinitionModel,
  FieldMode,
  FieldType,
  ObjectDefinitionModel,
} from '@seahorse/domain';
import { BadgeModel } from '@seahorse/ui';

export interface DataField<TData = any> {
  key: ItemKey<TData>;
  name: string;
  type: FieldType;
  order?: number;
  pipeModels?: PipeModel[];
  badge?: Record<string, BadgeModel>;
  appendText?: string;
  displayFields?: string[];
  showCopyButton?: boolean;
  mode?: FieldMode;
}

export abstract class DataField {
  static fromFieldDefinition(
    field: FieldDefinitionModel,
    useFieldSort = true
  ): DataField {
    const pipeModels: PipeModel[] = [];
    const badge = {};
    const mode = FieldDefinitionModel.getAdditionalData(field)?.mode;

    if (field.formatting && field.formatting['decimalPipe']) {
      pipeModels.push(
        new PipeModel('decimalPipe', {
          format: field.formatting['decimalPipe'],
        })
      );
    }

    if (
      [FieldType.LinkedObject, FieldType.LinkedObjects].includes(
        field.fieldType
      )
    ) {
      pipeModels.push(
        new PipeModel('tempLinkedObjects', { systemCode: field.systemCode })
      );
    } else if (field.fieldType === FieldType.List) {
      pipeModels.push(
        new PipeModel('tempCustomListDisplay', {
          items: field.fieldRule['items'],
        })
      );

      BaseFieldRule.list(field.fieldRule).items.forEach((item) => {
        if (item.color) {
          badge[item.key] = {
            color: item.color,
            mode: mode?.display,
          };
        }
      });
    }

    if (field.fieldCode === 'user') {
      pipeModels.push(new PipeModel('shUserDisplay'));
    }

    if (field.systemCode === '__DataObjectKey') {
      pipeModels.push(new PipeModel('shCustomDataDefinitionType'));
    }

    return {
      key: field.systemCode,
      name: field.name,
      type: field.fieldType,
      order: useFieldSort ? field.sortOrder : null,
      pipeModels: pipeModels.length ? pipeModels : undefined,
      badge: Object.keys(badge).length ? badge : undefined,
      appendText: field.fieldRule?.['valueDescriptor'],
      mode: mode,
    };
  }

  static fromObjectDefinition(
    objectDefinition: ObjectDefinitionModel,
    includeDefaultSystemField?: boolean,
    translateService?: TranslateService
  ): DataField[] {
    const fieldDefinitions = ObjectDefinitionModel.getAllFieldDefinitions(
      objectDefinition,
      includeDefaultSystemField,
      null,
      translateService
    );

    const templateCode = objectDefinition.templateCode
      ? JSON.parse(objectDefinition.templateCode)
      : null;

    return templateCode?.link?.mapping?.displayFields
      ?.map((x, index) => {
        const found = fieldDefinitions.find((y) => y.systemCode === x);
        if (!found) {
          return null;
        }

        // clone the field definition to avoid changing the original object and set the order of the display fields
        const field = JSON.parse(JSON.stringify(found));
        field.sortOrder = index;
        return DataField.fromFieldDefinition(field);
      })
      .filter((x) => x !== null);
  }
}

export type ItemKey<T> = keyof T;

export class DataListAction {
  key: string;
  name: string;
  icon?: string;
}
