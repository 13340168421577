import { Pipe, PipeTransform } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { InvoiceLineModel } from '@seahorse/domain';

@Pipe({ name: 'invoiceLineType' })
export class InvoiceLineTypePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(invoiceLineTypeValue: InvoiceLineModel['lineType']): string {
    let invoiceLineTypeGet: string;
    let invoiceLineType: string;

    switch (invoiceLineTypeValue) {
      case 1:
        invoiceLineTypeGet =
          'invoicing.invoiceCreator.invoiceLineTypes.regular';

        break;

      case 2:
        invoiceLineTypeGet =
          'invoicing.invoiceCreator.invoiceLineTypes.surcharge';

        break;

      case 3:
        invoiceLineTypeGet = 'shared.terms.description';

        break;

      default:
        invoiceLineTypeGet =
          'invoicing.invoiceCreator.invoiceLineTypes.unknown';

        break;
    }

    this.translateService
      .get(invoiceLineTypeGet)
      .subscribe((translateGetResponse) => {
        invoiceLineType = translateGetResponse;
      });

    return invoiceLineType;
  }
}
