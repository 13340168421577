import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserAccountService } from '../../../core/account/services/user-account.service';
import { NotificationService } from '@seahorse/common';

@Component({
  selector: 'user-invite',
  templateUrl: './user-invite.component.html',
})
export class UserInviteComponent {
  isLoading = false;
  isSubmitted = false;
  emailAddress: string;

  constructor(
    private accountService: UserAccountService,
    private notificationService: NotificationService,
    private translateService: TranslateService
  ) {}

  invite() {
    this.isLoading = true;

    const emails = this.emailAddress.split(/[,;\n+]/).filter((x) => x !== '');

    this.accountService.inviteUsers(emails).subscribe(
      (response) => {
        if (response) {
          this.notificationService.showSuccess(
            emails.length === 1
              ? this.translateService.instant('user.emailSent')
              : this.translateService.instant('user.emailsSent'),
            this.translateService.instant('shared.terms.success')
          );
        }
      },
      (error) => {
        this.notificationService.showError('Something went wrong!', 'Failed');

        this.isLoading = false;
        this.isSubmitted = false;
      },
      () => {
        this.isLoading = false;
        this.isSubmitted = false;
      }
    );
  }
}
