import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CollectionDisplay } from '../../../layout/models/collection-display.model';
import { SelectedSearchModel } from '../../../layout/models/selectedSearch.model';
import { LayoutDisplayService } from '../../../layout/services/layout-display.service';
import { SearchService } from '../../../layout/services/search-service';
import { SystemMessageModel } from '../../../preference/models/system-message.model';
import { ServiceMessageDataService } from '../../../preference/services/service-message-data.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ca-release-notes-overview-page',
  templateUrl: 'release-notes-overview.page.html',
})
export class ReleaseNotesOverviewPage {
  canLoadMore = false;
  collectionDisplay = CollectionDisplay;
  isInitial = true;
  isLoading = false;
  pageIndex = 0;
  pageSize = 20;
  search = '';
  systemMessages: SystemMessageModel[] = [];

  private destroy$ = new Subject();

  constructor(
    private layoutDisplay: LayoutDisplayService,
    private searchService: SearchService,
    private serviceMessageService: ServiceMessageDataService
  ) {}

  ngOnInit() {
    this.layoutDisplay.currentModuleName = 'Release notes';

    this.searchService.searchChanged$
      .pipe(takeUntil(this.destroy$))
      .subscribe((search: SelectedSearchModel) => {
        this.search = search.searchQuery;
        this.findSystemMessages();
      });

    this.loadSystemMessages();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadSystemMessages() {
    this.isLoading = true;
    this.serviceMessageService
      .getLatest('releasenote')
      .subscribe((result) => {
        this.searchService.searchResultCountChanged(result.count);

        if (this.pageIndex > 0) {
          this.systemMessages = this.systemMessages.concat(result.result);
        } else {
          this.systemMessages = result.result;
        }
        this.canLoadMore = this.systemMessages.length < result.count;
      })
      .add(() => {
        this.isLoading = false;
        this.isInitial = false;
      });
  }

  findSystemMessages() {
    this.pageIndex = 0;
    this.loadSystemMessages();
  }

  loadMore() {
    this.pageIndex += 1;
    this.loadSystemMessages();
  }

  reloadPage(model: SystemMessageModel) {
    this.search = model.subject;
    this.findSystemMessages();
  }
}
