import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CompanyOptInStatus } from '@seahorse/domain';

@Pipe({
  name: 'optInStatus',
  standalone: true,
  pure: true,
})
export class OptInStatusPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  statuses = {
    [CompanyOptInStatus.Invited]: this.translateService.instant(
      'companies.company.optInStatus.invited'
    ),
    [CompanyOptInStatus.Registered]: this.translateService.instant(
      'companies.company.optInStatus.registered'
    ),
    [CompanyOptInStatus.Accepted]: this.translateService.instant(
      'companies.company.optInStatus.accepted'
    ),
    [CompanyOptInStatus.Declined]: this.translateService.instant(
      'companies.company.optInStatus.declined'
    ),
    [CompanyOptInStatus.Disabled]: this.translateService.instant(
      'companies.company.optInStatus.disabled'
    ),
    default: this.translateService.instant(
      'companies.company.optInStatus.notInvited'
    ),
  };

  transform(status: CompanyOptInStatus): string {
    return (
      this.statuses[status] ||
      this.translateService.instant('companies.company.optInStatus.notInvited')
    );
  }
}
