import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ResultWrapper } from '@seahorse/common';
import { NotificationService } from '@seahorse/common';
import { ProxyServices } from '../../../../core/services/proxy.service';
import { ObjectDefinitionModel } from '@seahorse/domain';
import { AreYouSureModalComponent } from '@seahorse/ui';
import { LayoutDisplayService } from '../../../../layout/services/layout-display.service';
import { NauticalGeographyPickerDialogComponent } from '../../../../nautical-geography/components/nautical-geography-picker-dialog/nautical-geography-picker-dialog.component';
import { AttributeModel } from '../../../../shared/models/attribute.model';
import { EventModel } from '../../../../shared/models/event.model';
import * as moment from 'moment';
import * as _ from 'underscore';

import { AnchoredPickerModalComponent } from '../../components/anchored-picker-modal/anchored-picker-modal.component';
import { AttributePickerModalComponent } from '../../components/attribute-picker-modal/attribute-picker-modal.component';
import { DosFormPickerModalComponent } from '../../components/dos-form-picker-modal/dos-form-picker-modal.component';
import { SuggestionPickerModalComponent } from '../../components/suggestion-picker-modal/suggestion-picker-modal.component';
import { VisitReasonsPickerModalComponent } from '../../components/visit-reasons-picker-modal/visit-reasons-picker-modal.component';
import { ZeehavenIJmuidenSpecificPickerModalComponent } from '../../components/zeehaven-ijmuiden-specific-picker-modal/zeehaven-ijmuiden-specific-picker-modal.component';
import { ZeehavenIJmuidenMovementDetailsModel } from '../../models/zeehaven-ijmuiden-movement-details.model';
import {
  ZeehavenIJmuidenMovementModel,
  ZeehavenIJmuidenVisitMovementType,
} from '../../models/zeehaven-ijmuiden-movement.model';
import { ZeehavenIJmuidenStatus } from '../../models/zeehaven-ijmuiden-status.model';
import { ZeehavenIJmuidenVisitModel } from '../../models/zeehaven-ijmuiden-visit.model';
import { ZeehavenIJmuidenLogicService } from '../../services/zeehaven-ijmuiden-logic.service';
import { ZeehavenIJmuidenVisitDataService } from '../../services/zeehaven-ijmuiden-visit-data.service';

@Component({ templateUrl: './zeehaven-ijmuiden-details.page.html' })
export class ZeehavenIJmuidenDetailsPage implements OnInit {
  loading: boolean;
  zMovement: ZeehavenIJmuidenMovementDetailsModel;
  zVisit: ZeehavenIJmuidenVisitModel;
  movementTypes: (string | ZeehavenIJmuidenVisitMovementType)[];
  movementDefinition: ObjectDefinitionModel = null;
  private portId = 5; // TODO use UNLOCode

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private modal: NgbModal,
    private notify: NotificationService,
    private layout: LayoutDisplayService,
    private zVisitData: ZeehavenIJmuidenVisitDataService,
    private logicService: ZeehavenIJmuidenLogicService,
    private proxy: ProxyServices
  ) {
    this.loading = false;
    this.zMovement = null;
    this.movementTypes = _.filter(
      _.toArray(ZeehavenIJmuidenVisitMovementType),
      (r) => _.isNumber(r)
    );
    this.movementDefinition = _.find(
      this.proxy.objectDefinitions,
      (def) => def.systemCode === 'zeehavenIJmuidenMovement'
    );
    if (
      this.movementDefinition &&
      this.movementDefinition.objectFieldDefinitions
    ) {
      this.movementDefinition.objectFieldDefinitions = _.sortBy(
        this.movementDefinition.objectFieldDefinitions,
        (f) => f.name.toLowerCase()
      );
    }
  }

  ngOnInit() {
    this.layout.currentModuleName = this.translate.instant(
      'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.details.title'
    );

    this.route.paramMap.subscribe((m) => this.loadDetails(m.get('id')));
  }

  loadDetails(id: ZeehavenIJmuidenMovementModel['__Id']) {
    this.loading = true;

    this.zVisitData.getMovementById(id).subscribe(
      (r: ResultWrapper<ZeehavenIJmuidenMovementDetailsModel>) => {
        if (r.hasResult) {
          this.zMovement = r.result;
          this.zVisitData
            .getVisitsByIds([this.zMovement.zhijVisitId])
            .subscribe(
              (visitResponse) => {
                if (
                  visitResponse &&
                  visitResponse.result &&
                  visitResponse.result.length > 0
                ) {
                  this.zVisit = visitResponse.result[0];
                } else {
                  this.loadZHIJVisit();
                }
              },

              (e) => {
                this.notify.showError(
                  _.pluck(e.error.messages, 'message').join('\n'),
                  this.translate.instant('shared.terms.failed')
                );
              }
            );
        } else {
          this.notify.showError(
            _.pluck(r.messages, 'message').join('\n'),
            this.translate.instant('shared.terms.failed')
          );
        }
      },

      (e) => {
        this.notify.showError(
          _.pluck(e.error.messages, 'message').join('\n'),
          this.translate.instant('shared.terms.failed')
        );
      },

      () => (this.loading = false)
    );
  }

  loadZHIJVisit() {
    this.zVisitData.getVisitsByIds([this.zMovement.zhijVisitId]).subscribe(
      (visitResponse) => {
        if (
          visitResponse &&
          visitResponse.result &&
          visitResponse.result.length > 0
        ) {
          this.zVisit = visitResponse.result[0];
        } else {
          const zhijVisit = new ZeehavenIJmuidenVisitModel();
          zhijVisit.status = this.zMovement.status;
          zhijVisit.nauticalShip = this.zMovement.nauticalShip;
          zhijVisit.$nautical_portvisit_id =
            this.zMovement.$nautical_portvisit_id;
          zhijVisit.shipName = this.zMovement.shipName;
          zhijVisit.agent = this.zMovement.agent;
          zhijVisit.origin = this.zMovement.origin;
          zhijVisit.destination = this.zMovement.destination;
          this.zVisitData.addVisit(zhijVisit).subscribe(
            (addVisitResponse: ResultWrapper<ZeehavenIJmuidenVisitModel>) => {
              if (addVisitResponse.hasResult && addVisitResponse.result) {
                this.zVisit = addVisitResponse.result;
                this.zMovement.zhijVisitId = this.zVisit.__Id;
                this.zVisitData.update(this.zMovement).subscribe();
              }
            },
            (e) => {
              this.notify.showError(
                _.pluck(e.error.messages, 'message').join('\n'),
                this.translate.instant('shared.terms.failed')
              );
            }
          );
        }
      },

      (e) => {
        this.notify.showError(
          _.pluck(e.error.messages, 'message').join('\n'),
          this.translate.instant('shared.terms.failed')
        );
      }
    );
  }

  createSuggestion(request: string): any {
    let g: any;

    switch (request) {
      case 'shipName': {
        if (this.zMovement.$nautical_ship) {
          g = this.zMovement.$nautical_ship.name.toUpperCase();
        }
        break;
      }

      case 'movementType': {
        if (this.zMovement.$nautical_movement) {
          if (
            this.zMovement.$nautical_movement.portWayPointFrom &&
            _.contains(
              this.logicService.waypoints,
              this.zMovement.$nautical_movement.portWayPointFrom.code.toLowerCase()
            )
          ) {
            if (
              this.zMovement.$nautical_movement.portWayPointTo &&
              _.contains(
                this.logicService.waypoints,
                this.zMovement.$nautical_movement.portWayPointTo.code.toLowerCase()
              )
            ) {
              g = ZeehavenIJmuidenVisitMovementType.Shifting;
            } else {
              g = ZeehavenIJmuidenVisitMovementType.Outgoing;
            }
          } else if (
            this.zMovement.$nautical_movement.portWayPointTo &&
            _.contains(
              this.logicService.waypoints,
              this.zMovement.$nautical_movement.portWayPointTo.code.toLowerCase()
            )
          ) {
            g = ZeehavenIJmuidenVisitMovementType.Incoming;
          }
        }
        break;
      }

      case 'etaOrEtd': {
        switch (this.zMovement.movementType) {
          case ZeehavenIJmuidenVisitMovementType.Incoming:
          case ZeehavenIJmuidenVisitMovementType.Shifting: {
            if (this.zMovement.$nautical_movement) {
              g = this.zMovement.$nautical_movement.eta;
            }
            break;
          }

          case ZeehavenIJmuidenVisitMovementType.Outgoing: {
            if (this.zMovement.$nautical_movement) {
              g = this.zMovement.$nautical_movement.etd;
            }
            break;
          }
        }
        break;
      }

      case 'ataOrAtd':
        {
          switch (this.zMovement.movementType) {
            case ZeehavenIJmuidenVisitMovementType.Incoming:
              {
                if (this.zMovement.$nautical_movement) {
                  g = this.zMovement.$nautical_movement.ata;
                }
              }
              break;

            case ZeehavenIJmuidenVisitMovementType.Shifting:
            case ZeehavenIJmuidenVisitMovementType.Outgoing:
              {
                if (this.zMovement.$nautical_movement) {
                  g = this.zMovement.$nautical_movement.atd;
                }
              }
              break;
          }
        }
        break;

      case 'agent': {
        if (
          this.zMovement.$nautical_portvisit &&
          this.zMovement.$nautical_portvisit.portAgent
        ) {
          g = this.zMovement.$nautical_portvisit.portAgent.name;
        }
        break;
      }

      case 'owner': {
        if (this.zMovement.$nautical_ship) {
          g = this.zMovement.$nautical_ship.owner;
        }
        break;
      }

      case 'operator': {
        if (this.zMovement.$nautical_ship.shipAttributes) {
          _.forEach(this.zMovement.$nautical_ship.shipAttributes, (a) => {
            if (a.category === 'ownership' && a.fieldName === 'operator') {
              g = a.fieldValue;
            }
          });
        }
        break;
      }

      case 'shipManager': {
        if (this.zMovement.$nautical_ship.shipAttributes) {
          _.forEach(this.zMovement.$nautical_ship.shipAttributes, (a) => {
            if (a.category === 'ownership' && a.fieldName === 'shipmanager') {
              g = a.fieldValue;
            }
          });
        }
        break;
      }

      case 'origin': {
        switch (this.zMovement.movementType) {
          case ZeehavenIJmuidenVisitMovementType.Incoming: {
            if (
              this.zMovement.$nautical_portvisit &&
              this.zMovement.$nautical_portvisit.previousPort
            ) {
              g = this.zMovement.$nautical_portvisit.previousPort.name;
            }
            break;
          }

          case ZeehavenIJmuidenVisitMovementType.Shifting:
          case ZeehavenIJmuidenVisitMovementType.Outgoing: {
            if (
              this.zMovement.$nautical_movement &&
              this.zMovement.$nautical_movement.portWayPointFrom
            ) {
              g = this.zMovement.$nautical_movement.portWayPointFrom.name;
            }
            break;
          }
        }
        break;
      }

      case 'maxDraft': {
        if (
          this.zMovement.$nautical_ship &&
          this.zMovement.$nautical_ship.maxDraft
        ) {
          g = this.zMovement.$nautical_ship.maxDraft.toString();
        }
        break;
      }

      case 'destination': {
        switch (this.zMovement.movementType) {
          case ZeehavenIJmuidenVisitMovementType.Incoming:
          case ZeehavenIJmuidenVisitMovementType.Shifting: {
            if (
              this.zMovement.$nautical_movement &&
              this.zMovement.$nautical_movement.portWayPointTo
            ) {
              g = this.zMovement.$nautical_movement.portWayPointTo.name;
            }
            break;
          }

          case ZeehavenIJmuidenVisitMovementType.Outgoing: {
            if (
              this.zMovement.$nautical_portvisit &&
              this.zMovement.$nautical_portvisit.nextPort
            ) {
              g = this.zMovement.$nautical_portvisit.nextPort.name;
            }
            break;
          }
        }
        break;
      }
    }

    return g;
  }

  suggestion(): string {
    let f = this.zMovement.$nautical_ship.name.toUpperCase();
    const a = [];

    if (this.zMovement.$nautical_portvisit) {
      if (this.zMovement.$nautical_portvisit.ata) {
        a.push(
          `${this.translate.instant(
            'customModules.portCallFile.fileAppendModel.ata'
          )}: ${moment(this.zMovement.$nautical_portvisit.ata).format(
            'DD-MM-YYYY HH:mm'
          )}`
        );
      } else if (this.zMovement.$nautical_portvisit.eta) {
        a.push(
          `${this.translate.instant(
            'customModules.portCallFile.fileAppendModel.eta'
          )}: ${moment(this.zMovement.$nautical_portvisit.eta).format(
            'DD-MM-YYYY HH:mm'
          )}`
        );
      }
      if (this.zMovement.$nautical_portvisit.atd) {
        a.push(
          `${this.translate.instant(
            'customModules.portCallFile.fileAppendModel.atd'
          )}: ${moment(this.zMovement.$nautical_portvisit.atd).format(
            'DD-MM-YYYY HH:mm'
          )}`
        );
      } else if (this.zMovement.$nautical_portvisit.etd) {
        a.push(
          `${this.translate.instant(
            'customModules.portCallFile.fileAppendModel.etd'
          )}: ${moment(this.zMovement.$nautical_portvisit.etd).format(
            'DD-MM-YYYY HH:mm'
          )}`
        );
      }
      if (a.length) {
        f += ` (${a.join(', ')})`;
      }
    }

    return f;
  }

  updateZeehaven(zhijVisitNewStatus?: ZeehavenIJmuidenStatus) {
    this.loading = true;

    if (this.zMovement.status === ZeehavenIJmuidenStatus.Suggestion) {
      this.zMovement.status = ZeehavenIJmuidenStatus.Active;

      if (!this.zMovement.zhijVisitId) {
        this.zMovement.zhijVisitId = this.zVisit.__Id;
      }
    }

    this.zVisitData
      .update(
        _.omit(this.zMovement, [
          '$nautical_portvisit',
          '$nautical_ship',
          '$nautical_movement',
        ])
      )
      .subscribe(
        (r: ResultWrapper<ZeehavenIJmuidenMovementModel>) => {
          if (r.hasResult) {
            this.notify.showSuccess(
              this.translate.instant(
                'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.details.updated'
              ),
              this.translate.instant('shared.terms.success')
            );

            this.zMovement = _.extend(r.result, {
              $nautical_ship: this.zMovement.$nautical_ship,
              $nautical_portvisit: this.zMovement.$nautical_portvisit,
              $nautical_movement: this.zMovement.$nautical_movement,
            });

            if (
              zhijVisitNewStatus !== undefined &&
              zhijVisitNewStatus !== null
            ) {
              this.zVisit.status = zhijVisitNewStatus;

              if (!this.zVisit.nauticalShip) {
                this.zVisit.nauticalShip = this.zMovement.nauticalShip;
              }

              if (!this.zVisit.$nautical_portvisit_id) {
                this.zVisit.$nautical_portvisit_id =
                  this.zMovement.$nautical_portvisit_id;
              }

              if (!this.zVisit.agent) {
                this.zVisit.agent = this.zMovement.agent;
              }

              if (!this.zVisit.shipName) {
                this.zVisit.shipName = this.zMovement.shipName;
              }

              if (zhijVisitNewStatus === ZeehavenIJmuidenStatus.Completed) {
                this.zVisit.currentBerth = null;
                this.zVisit.currentBerthWaypoint = null;
              } else {
                this.zVisit.currentBerth = this.zMovement.destination;
                this.zVisit.currentBerthWaypoint =
                  this.zMovement.destinationWaypoint;
              }

              this.zVisitData.updateVisit(this.zVisit).subscribe();
            }
          } else {
            this.notify.showError(
              _.pluck(r.messages, 'message').join('\n'),
              this.translate.instant('shared.terms.failed')
            );
          }
        },

        (e) => {
          this.notify.showError(
            _.pluck(e.error.messages, 'message').join('\n'),
            this.translate.instant('shared.terms.failed')
          );
        },

        () => (this.loading = false)
      );
  }

  openAttributePicker(
    name: AttributeModel['name'],
    type: AttributeModel['type'],
    required?: AttributeModel['required']
  ) {
    const a = new AttributeModel();
    a.name = name;
    a.value = _.clone(this.zMovement[name]);
    a.required = required;
    a.type = type;
    a.suggestion = this.createSuggestion(name);

    const m = this.modal.open(AttributePickerModalComponent, {
      backdrop: 'static',
    });
    m.componentInstance.attribute = a;

    m.componentInstance.onPickAttribute.subscribe((c: AttributeModel) => {
      if (this.zMovement[c.name] !== c.value) {
        this.zMovement[c.name] = c.value;

        this.updateZeehaven();
      }
    });
  }

  openDosFormPicker() {
    const m = this.modal.open(DosFormPickerModalComponent, {
      backdrop: 'static',
    });
    m.componentInstance.dosForm = _.clone(this.zMovement.dosForm);
    m.componentInstance.dosFormDate = _.clone(this.zMovement.dosFormDate);

    m.componentInstance.onPickDosForm.subscribe(
      (c: {
        dosForm: ZeehavenIJmuidenMovementModel['dosForm'];
        dosFormDate: ZeehavenIJmuidenMovementModel['dosFormDate'];
      }) => {
        if (
          this.zMovement.dosForm !== c.dosForm ||
          this.zMovement.dosFormDate !== c.dosFormDate
        ) {
          this.zMovement.dosForm = c.dosForm;
          this.zMovement.dosFormDate = c.dosFormDate;

          this.updateZeehaven();
        }
      }
    );
  }

  openAnchoredPicker() {
    const m = this.modal.open(AnchoredPickerModalComponent, {
      backdrop: 'static',
    });
    m.componentInstance.anchored = _.clone(this.zMovement.anchored);
    m.componentInstance.anchoredMessage = _.clone(
      this.zMovement.anchoredMessage
    );

    m.componentInstance.onPickAnchored.subscribe(
      (c: {
        anchored: ZeehavenIJmuidenMovementModel['anchored'];
        anchoredMessage: ZeehavenIJmuidenMovementModel['anchoredMessage'];
      }) => {
        if (
          this.zMovement.anchored !== c.anchored ||
          this.zMovement.anchoredMessage !== c.anchoredMessage
        ) {
          this.zMovement.anchored = c.anchored;
          this.zMovement.anchoredMessage = c.anchoredMessage;

          this.updateZeehaven();
        }
      }
    );
  }

  openGeographyPicker(sourceField: string, selectedType: string) {
    const modalRef = this.modal.open(NauticalGeographyPickerDialogComponent, {
      backdrop: 'static',
    });
    modalRef.componentInstance.filter = 'zeehavenijmuiden';
    modalRef.componentInstance.portId = this.portId;
    if (selectedType === 'waypoint') {
      if (
        sourceField === 'destination' &&
        this.zMovement.__AdditionalData &&
        this.zMovement.__AdditionalData.destinationWaypoint
      ) {
        modalRef.componentInstance.nauticalWaypoint =
          this.zMovement.__AdditionalData.destinationWaypoint;
      } else if (
        sourceField === 'origin' &&
        this.zMovement.__AdditionalData &&
        this.zMovement.__AdditionalData.originWaypoint
      ) {
        modalRef.componentInstance.nauticalWaypoint =
          this.zMovement.__AdditionalData.originWaypoint;
      } else {
        modalRef.componentInstance.nauticalWaypoint = {
          name: this.zMovement[sourceField],
        };
      }
    } else {
      modalRef.componentInstance.nauticalPort = {
        name: this.zMovement[sourceField],
      };
    }
    modalRef.componentInstance.selectedType = selectedType;
    modalRef.result
      .then((modalResult) => {
        if (modalResult.selectedType === 'waypoint') {
          this.zMovement[sourceField] = modalResult.selectedObject.code
            ? modalResult.selectedObject.code +
              ' (' +
              modalResult.selectedObject.name +
              ')'
            : modalResult.selectedObject.name;
          if (sourceField === 'origin') {
            this.zMovement.originWaypoint = modalResult.selectedObject.id;
            this.zMovement.origin = modalResult.selectedObject.name;
          } else if (sourceField === 'destination') {
            this.zMovement.destinationWaypoint = modalResult.selectedObject.id;
            this.zMovement.destination = modalResult.selectedObject.name;
          }
        } else {
          this.zMovement[sourceField] = modalResult.selectedObject.name;
        }
        this.updateZeehaven();
      })
      .catch(() => {});
  }

  openZeehavenIJmuidenSpecificPicker() {
    const m = this.modal.open(ZeehavenIJmuidenSpecificPickerModalComponent, {
      backdrop: 'static',
    });
    m.componentInstance.oilScreen = _.clone(this.zMovement.oilScreen);
    m.componentInstance.oilScreenMessage = _.clone(
      this.zMovement.oilScreenMessage
    );
    m.componentInstance.power = _.clone(this.zMovement.power);
    m.componentInstance.powerMessage = _.clone(this.zMovement.powerMessage);
    m.componentInstance.tug = _.clone(this.zMovement.tug);
    m.componentInstance.tugMessage = _.clone(this.zMovement.tugMessage);
    m.componentInstance.fender = _.clone(this.zMovement.fender);
    m.componentInstance.fenderMessage = _.clone(this.zMovement.fenderMessage);
    m.componentInstance.garbage = _.clone(this.zMovement.garbage);
    m.componentInstance.garbageMessage = _.clone(this.zMovement.garbageMessage);
    m.componentInstance.water = _.clone(this.zMovement.water);
    m.componentInstance.waterMessage = _.clone(this.zMovement.waterMessage);
    m.componentInstance.workPlatform = _.clone(this.zMovement.workPlatform);
    m.componentInstance.workPlatformMessage = _.clone(
      this.zMovement.workPlatformMessage
    );

    m.componentInstance.onPickZeehavenIJmuidenSpecific.subscribe(
      (c: {
        oilScreen: ZeehavenIJmuidenMovementModel['oilScreen'];
        oilScreenMessage: ZeehavenIJmuidenMovementModel['oilScreenMessage'];
        power: ZeehavenIJmuidenMovementModel['power'];
        powerMessage: ZeehavenIJmuidenMovementModel['powerMessage'];
        tug: ZeehavenIJmuidenMovementModel['tug'];
        tugMessage: ZeehavenIJmuidenMovementModel['tugMessage'];
        fender: ZeehavenIJmuidenMovementModel['fender'];
        fenderMessage: ZeehavenIJmuidenMovementModel['fenderMessage'];
        garbage: ZeehavenIJmuidenMovementModel['garbage'];
        garbageMessage: ZeehavenIJmuidenMovementModel['garbageMessage'];
        water: ZeehavenIJmuidenMovementModel['water'];
        waterMessage: ZeehavenIJmuidenMovementModel['waterMessage'];
        workPlatform: ZeehavenIJmuidenMovementModel['workPlatform'];
        workPlatformMessage: ZeehavenIJmuidenMovementModel['workPlatformMessage'];
      }) => {
        if (
          this.zMovement.oilScreen !== c.oilScreen ||
          this.zMovement.oilScreenMessage !== c.oilScreenMessage ||
          this.zMovement.power !== c.power ||
          this.zMovement.powerMessage !== c.powerMessage ||
          this.zMovement.tug !== c.tug ||
          this.zMovement.tugMessage !== c.tugMessage ||
          this.zMovement.fender !== c.fender ||
          this.zMovement.fenderMessage !== c.fenderMessage ||
          this.zMovement.garbage !== c.garbage ||
          this.zMovement.garbageMessage !== c.garbageMessage ||
          this.zMovement.water !== c.water ||
          this.zMovement.waterMessage !== c.waterMessage ||
          this.zMovement.workPlatform !== c.workPlatform ||
          this.zMovement.workPlatformMessage !== c.workPlatformMessage
        ) {
          this.zMovement.oilScreen = c.oilScreen;
          this.zMovement.oilScreenMessage = c.oilScreenMessage;
          this.zMovement.power = c.power;
          this.zMovement.powerMessage = c.powerMessage;
          this.zMovement.tug = c.tug;
          this.zMovement.tugMessage = c.tugMessage;
          this.zMovement.fender = c.fender;
          this.zMovement.fenderMessage = c.fenderMessage;
          this.zMovement.garbage = c.garbage;
          this.zMovement.garbageMessage = c.garbageMessage;
          this.zMovement.water = c.water;
          this.zMovement.waterMessage = c.waterMessage;
          this.zMovement.workPlatform = c.workPlatform;
          this.zMovement.workPlatformMessage = c.workPlatformMessage;

          this.updateZeehaven();
        }
      }
    );
  }

  openVisitReasonsPicker() {
    const m = this.modal.open(VisitReasonsPickerModalComponent, {
      backdrop: 'static',
    });
    m.componentInstance.movement = _.clone(this.zMovement);

    m.result.then((pickerResult) => {
      if (pickerResult) {
        this.zMovement = pickerResult;
        this.updateZeehaven();
      }
    });
  }

  openSuggestionPicker() {
    const m = this.modal.open(SuggestionPickerModalComponent, {
      backdrop: 'static',
    });
    m.componentInstance.zVisit = _.clone(this.zMovement);

    m.componentInstance.onPickSuggestion.subscribe(
      (c: ZeehavenIJmuidenMovementModel) => {
        this.loading = true;

        const visit: ZeehavenIJmuidenMovementModel = _.omit(this.zMovement, [
          '$nautical_portvisit',
          '$nautical_ship',
        ]);
        visit.nauticalShip = c.nauticalShip;
        visit.$nautical_portvisit_id = c.$nautical_portvisit_id;
        visit.$nautical_portmovement_id = c.$nautical_portmovement_id;

        this.zVisitData.update(visit).subscribe(
          (rUpdate: ResultWrapper<ZeehavenIJmuidenMovementModel>) => {
            if (rUpdate.hasResult) {
              this.notify.showSuccess(
                this.translate.instant(
                  'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.details.updated'
                ),
                this.translate.instant('shared.terms.success')
              );

              this.zVisitData.getMovementById(rUpdate.result.__Id).subscribe(
                (
                  rGetById: ResultWrapper<ZeehavenIJmuidenMovementDetailsModel>
                ) => {
                  if (rGetById.hasResult) {
                    this.zMovement = rGetById.result;
                  } else {
                    this.notify.showError(
                      _.pluck(rGetById.messages, 'message').join('\n'),
                      this.translate.instant('shared.terms.failed')
                    );
                  }
                },

                (eGetById) => {
                  this.notify.showError(
                    _.pluck(eGetById.error.messages, 'message').join('\n'),
                    this.translate.instant('shared.terms.failed')
                  );
                },

                () => (this.loading = false)
              );
            } else {
              this.notify.showError(
                _.pluck(rUpdate.messages, 'message').join('\n'),
                this.translate.instant('shared.terms.failed')
              );
            }
          },

          (eUpdate) => {
            this.notify.showError(
              _.pluck(eUpdate.error.messages, 'message').join('\n'),
              this.translate.instant('shared.terms.failed')
            );
          },

          () => {}
        );
      }
    );
  }

  action(event: EventModel) {
    switch (event.action) {
      case 'complete': {
        // Check if there's a visit reason, if not prompt an error
        if (
          this.zMovement.movementType !==
            ZeehavenIJmuidenVisitMovementType.Outgoing &&
          !_.some(
            _.filter(
              this.movementDefinition.objectFieldDefinitions,
              (find) => find.groupName === 'Visit reasons'
            ),
            (some: any) => {
              return this.zMovement[some.systemCode] === true;
            }
          )
        ) {
          this.notify.showError(
            'Selecteer minimaal één bezoekreden om te kunnen voltooien'
          );
          return;
        }

        if (!this.zMovement.etaOrEtd) {
          this.notify.showError('ETA/ETD is verplicht');

          return;
        }

        if (!this.zMovement.ataOrAtd) {
          this.zMovement.ataOrAtd = this.zMovement.etaOrEtd;
        }

        let visitStatus = null;
        if (
          this.zMovement.movementType ===
          ZeehavenIJmuidenVisitMovementType.Incoming
        ) {
          visitStatus = ZeehavenIJmuidenStatus.Active; // active the ZHIJ visit when an incoming movement is completed
        } else if (
          this.zMovement.movementType ===
          ZeehavenIJmuidenVisitMovementType.Outgoing
        ) {
          visitStatus = ZeehavenIJmuidenStatus.Completed; // complete the ZHIJ visit when an outgoning movement is completed
        }
        this.zMovement.status = ZeehavenIJmuidenStatus.Completed;
        this.updateZeehaven(visitStatus);
        break;
      }
      case 'update': {
        this.updateZeehaven();
        break;
      }

      case 'markForRemoval': {
        const m = this.modal.open(AreYouSureModalComponent, {
          backdrop: 'static',
        });
        m.componentInstance.modalHeader = this.translate.instant(
          'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.details.deleteZeehavenIJmuidenVisit'
        );

        m.result.then((y) => {
          if (y) {
            this.zMovement.status = ZeehavenIJmuidenStatus.Deleted;
            this.loading = true;

            this.zVisitData
              .update(
                _.omit(this.zMovement, [
                  '$nautical_portvisit',
                  '$nautical_ship',
                  '$nautical_movement',
                ])
              )
              .subscribe(
                (r: ResultWrapper<ZeehavenIJmuidenMovementModel>) => {
                  if (r.hasResult) {
                    this.notify.showSuccess(
                      this.translate.instant(
                        'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.details.deleted'
                      ),
                      this.translate.instant('shared.terms.success')
                    );

                    this.router.navigateByUrl(`/custom-ui/zeehaven-overview`);
                  } else {
                    this.notify.showError(
                      _.pluck(r.messages, 'message').join('\n'),
                      this.translate.instant('shared.terms.failed')
                    );
                  }
                },

                (e) => {
                  this.notify.showError(
                    _.pluck(e.error.messages, 'message').join('\n'),
                    this.translate.instant('shared.terms.failed')
                  );
                },

                () => (this.loading = false)
              );
          }
        });
        break;
      }

      case 'restore': {
        this.zMovement.status = ZeehavenIJmuidenStatus.Active;
        this.zMovement.ataOrAtd = null;

        this.updateZeehaven();
        break;
      }
    }
  }
}
