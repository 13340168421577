import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { CloseModalDirective } from './directives/close-modal.directive';
import { DisplayNonDirective } from './directives/display-non.directive';
import { EmptyPlaceholderDirective } from '@seahorse/ui';
import { HasPermissionDirective } from './directives/has-permission.directive';
import { HasSubscriptionDirective } from './directives/has-subscription.directive';
import { MustMatchDirective } from './directives/must-match.directive';
import { NullDefaultValueDirective } from './directives/null-default.directive';
import { WindowScrollDirective } from './directives/window-scroll.directive';
import { NameFromUserIdPipe } from './pipes/nameFromUserId.pipe';
import { ShiptypesFilterPipe } from './pipes/shiptypes-filter.pipe';

@NgModule({
  declarations: [
    DisplayNonDirective,
    HasPermissionDirective,
    WindowScrollDirective,
    MustMatchDirective,
    CloseModalDirective,
    NullDefaultValueDirective,
    ShiptypesFilterPipe,
    NameFromUserIdPipe,
    HasSubscriptionDirective,
  ],
  imports: [TranslateModule, EmptyPlaceholderDirective],
  exports: [
    DisplayNonDirective,
    HasPermissionDirective,
    WindowScrollDirective,
    MustMatchDirective,
    CloseModalDirective,
    NullDefaultValueDirective,
    EmptyPlaceholderDirective,
    ShiptypesFilterPipe,
    NameFromUserIdPipe,

    TranslateModule,
    HasSubscriptionDirective,
  ],
  providers: [NameFromUserIdPipe],
})
export class SharedModule {}
