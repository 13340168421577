import { Component, Input } from '@angular/core';

@Component({
  selector: 'sh-avatar',
  template: `
    <div class="e-avatar" [ngClass]="'e-avatar-' + shape">
      <ng-content></ng-content>
    </div>
  `,
  styles: [
    `
      :host[circle] .e-avatar {
        border-radius: 50%;
      }
    `,
  ],
})
export class AvatarComponent {
  @Input() shape: 'circle' | 'square' = 'circle';
}
