import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { GenericUIComponentsModule } from './components/generic-ui-components.module';

@NgModule({
  imports: [
    CommonModule,
    GenericUIComponentsModule,
  ],
})
export class GenericUIModule {}
