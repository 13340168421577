import { Component, Input, Output, EventEmitter } from '@angular/core';
import { EventModel } from '../../../../../shared/models/event.model';
import { TaskDefinitionModel } from '../../models/task-definition.model';

@Component({
  selector: 'task-definition-editor',
  templateUrl: './task-definition-editor.component.html',
})
export class TaskDefinitionEditorComponent {
  @Input() taskDefinitions: TaskDefinitionModel[];
  @Input() loading: boolean;

  @Output() onEvent: EventEmitter<EventModel>;

  constructor() {
    this.taskDefinitions = [];
    this.loading = false;
    this.onEvent = new EventEmitter<EventModel>();
  }
}
