import { Component, Input, OnInit } from '@angular/core';
import { CustomDataBaseModel, FieldDefinitionModel, FileModel } from '@seahorse/domain';

@Component({
  selector: 'ca-object-types-modal',
  templateUrl: './object-types-modal.component.html',
})
export class ObjectTypesModalComponent implements OnInit {
  @Input() object: CustomDataBaseModel;
  @Input() objectFieldDefinitions: FieldDefinitionModel[];

  @Input() editFile: boolean;
  @Input() file: FileModel;
  @Input() matchingLinkedObjects?: any;

  linkedObjects = [];

  ngOnInit() {
    const linkedObjectsKeys = Object.keys(this.object.__AdditionalData);

    for (const key of linkedObjectsKeys) {
      const data = this.object.__AdditionalData[key];

      if (data !== null) {
        let isChecked = false;

        if (this.editFile) {
          for (const item of this.matchingLinkedObjects) {
            if (item.key === key && item.fileId === this.file.id) {
              isChecked = true;
              break;
            }
          }
        }

        this.linkedObjects.push({
          key,
          checked: isChecked,
          initiallyChecked: isChecked,
        });
      }
    }
  }

  getSelectedLinkedObjects() {
    return this.linkedObjects
      .filter((x) => x.checked === true)
      .map((x) => x.key);
  }
}
