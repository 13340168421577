<span class="title"
  ><b>{{ 'messages.attachments' | translate }}</b></span
>
<div class="container">
  <div *ngFor="let template of templates">
    <sh-file-preview
      [file]="template"
      (download)="templateDownload($event)"
      (preview)="templatePreview($event)"
    ></sh-file-preview>
  </div>
  <div *ngFor="let file of files">
    <sh-file-preview
      [file]="file"
      (download)="fileDownload($event)"
      (preview)="filePreview($event)"
      (delete)="fileDelete($event)"
    ></sh-file-preview>
  </div>
</div>
