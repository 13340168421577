import { Component, OnDestroy, Input } from '@angular/core';
import { ErrorMessage } from '@seahorse/common';
import { NotificationService } from '@seahorse/common';
import {
  FleetGroup,
  ShipFleetGroupModel,
} from '../../../map/models/fleet-group.model';
import { GroupService } from '../../../map/services/group.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ca-my-fleet-modal',
  templateUrl: './my-fleet-modal.component.html',
})
export class MyFleetModalComponent implements OnDestroy {
  private subscriptions$ = Array<Subscription>();
  groups: FleetGroup[] = [];
  shipsWithoutGroup: ShipFleetGroupModel[] = [];

  @Input()
  set shipId(shipId: string | number) {
    if (typeof shipId === 'number') {
      this._shipId = shipId;
    } else {
      this._shipId = +shipId.toString().split('_').pop();
    }
  }
  get shipId(): string | number {
    return this._shipId;
  }

  isLoading = false;

  private _shipId: number;

  constructor(
    private groupService: GroupService,
    private _notification: NotificationService
  ) {
    this.getFleetGroups();
  }

  ngOnDestroy() {
    this.subscriptions$.forEach((s) => s.unsubscribe());
  }

  getFleetGroups() {
    this.isLoading = true;
    new Promise<FleetGroup[]>((resolve, errors) => {
      this.subscriptions$.push(
        this.groupService.getFleetGroups().subscribe(
          (response) => {
            if (response.hasResult) {
              resolve(response.result);
            } else {
              errors(response.messages);
            }
          },
          () => errors(),
          () => {}
        )
      );
    })
      .then((fleetGroups) => {
        new Promise<{
          fleetGroups: FleetGroup[];
          shipsWithoutGroups: ShipFleetGroupModel[];
        }>((resolve, errors) => {
          this.subscriptions$.push(
            this.groupService
              .getShipFleetGroups(false, [this.shipId])
              .subscribe(
                (response) => {
                  if (response.hasResult) {
                    resolve({
                      fleetGroups: fleetGroups
                        ? fleetGroups.map((g) => {
                            const shipFleetGroupIndex =
                              response.result.findIndex(
                                (x) => x.groupId === g.id
                              );
                            if (shipFleetGroupIndex >= 0) {
                              g.shipFleetGroups = [
                                response.result[shipFleetGroupIndex],
                              ];
                              g.isChecked = true;
                            }
                            return g;
                          })
                        : [],
                      shipsWithoutGroups: response.result.filter(
                        (x) => x.groupId === null
                      ),
                    });
                  } else {
                    errors(response.messages);
                  }
                },
                () => errors(),
                () => {}
              )
          );
        })
          .then((results) => {
            this.groups = results.fleetGroups;
            this.shipsWithoutGroup = results.shipsWithoutGroups;
          })
          .catch((resultMessages?: ErrorMessage[]) => {
            this._notification.displayErrorNotification(resultMessages);
            this.isLoading = false;
          })
          .finally(() => (this.isLoading = false));
      })
      .catch((resultMessages?: ErrorMessage[]) => {
        this._notification.displayErrorNotification(resultMessages);
        this.isLoading = false;
      })
      .finally(() => {});
  }
}
