import { Injectable } from '@angular/core';
import { DataContextService } from '@seahorse/common';
import { Observable } from 'rxjs';
import { ResultWrapper } from '@seahorse/common';

import { FleetGroup, ShipFleetGroupModel } from '../models/fleet-group.model';

@Injectable()
export class GroupService {
  constructor(private dataContext: DataContextService) {}

  getGroups(findShips?: boolean): Observable<ResultWrapper<FleetGroup[]>> {
    let uri = '/nautical/fleet-groups';
    if (findShips !== undefined && findShips !== null) {
      uri = `${uri}?findShips=${findShips}`;
    }
    return this.dataContext.get<FleetGroup[]>(uri);
  }

  createGroup(group: FleetGroup): Observable<ResultWrapper<FleetGroup>> {
    return this.dataContext.post<FleetGroup>(`/nautical/fleet-groups`, group);
  }

  updateGroup(group: FleetGroup): Observable<ResultWrapper<FleetGroup>> {
    return this.dataContext.put<FleetGroup>(`/nautical/fleet-groups`, group);
  }

  deleteGroup(group: FleetGroup): Observable<ResultWrapper<FleetGroup>> {
    return this.dataContext.delete<FleetGroup>(
      `/nautical/fleet-groups/${group.id}`
    );
  }

  getAllShipsWithoutGroup(
    findShips?: boolean
  ): Observable<ResultWrapper<ShipFleetGroupModel[]>> {
    let uri = '/nautical/fleet-groups/ship';
    if (findShips !== undefined && findShips !== null) {
      uri = `${uri}?findShips=${findShips}`;
    }
    return this.dataContext.get<ShipFleetGroupModel[]>(uri);
  }

  addShipToGroup(
    groupId: number,
    shipId: number
  ): Observable<ResultWrapper<ShipFleetGroupModel>> {
    return this.dataContext.post<ShipFleetGroupModel>(
      `/nautical/fleet-groups/ship`,
      { groupId, shipId }
    );
  }

  removeShipFromGroup(
    id: number
  ): Observable<ResultWrapper<ShipFleetGroupModel>> {
    return this.dataContext.delete<ShipFleetGroupModel>(
      `/nautical/fleet-groups/ship/${id}`
    );
  }

  getFleetGroups(): Observable<ResultWrapper<FleetGroup[]>> {
    return this.dataContext.get<FleetGroup[]>(
      'nautical/fleet-groups/fleet-groups'
    );
  }

  getShipFleetGroups(
    findShips?: boolean,
    shipIds?: Array<number | string>
  ): Observable<ResultWrapper<ShipFleetGroupModel[]>> {
    let uri = 'nautical/fleet-groups/ship-fleet-groups';
    const args = [];
    if (findShips !== undefined && findShips !== null) {
      args.push(`findShips=${findShips}`);
    }
    if (shipIds && shipIds.length) {
      args.push(`shipIds=${shipIds.join(',')}`);
    }
    if (args.length > 0) {
      uri = `${uri}?${args.join('&')}`;
    }
    return this.dataContext.get<ShipFleetGroupModel[]>(uri);
  }
}
