import { Component, Input, Output, EventEmitter } from '@angular/core';
import { EventModel } from '../../../../shared/models/event.model';
import { ZeehavenIJmuidenDisplayModel } from '../../models/zeehaven-ijmuiden-display.model';

@Component({
  selector: 'zeehaven-ijmuiden-visit-list',
  templateUrl: './zeehaven-ijmuiden-visit-list.component.html',
})
export class ZeehavenIJmuidenVisitListComponent {
  @Input() clickable = true;
  @Input() models: ZeehavenIJmuidenDisplayModel[];
  @Input() visitDisplay: string;
  @Input() simpleView: boolean;
  @Input() showType: boolean;

  @Output() onSelectVisit: EventEmitter<EventModel>;

  constructor() {
    this.models = [];
    this.visitDisplay = null;
    this.onSelectVisit = new EventEmitter<EventModel>();
  }
}
