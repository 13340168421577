import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { NotificationService } from '@seahorse/common';
import { Subscription } from 'rxjs';
import {
  TaskWidgetModel,
  TaskWidgetType,
} from '../../models/task-widget.model';
import { TaskGroupDataService } from '../../../task-group/services/task-group-data.service';
import { IdentityService } from '../../../core/services/identity.service';
import { TaskDataService } from '../../../tasks/modules/task/services/task-data.service';
import { TaskGroupModel } from '../../../task-group/models/task-group.model';
import { TaskModel } from '../../../tasks/modules/task/models/task.model';

@Component({
  selector: 'ca-task-widget',
  templateUrl: './task-widget.component.html',
})
export class TaskWidgetComponent implements AfterViewInit, OnDestroy {
  @Input()
  set widget(value: TaskWidgetModel) {
    this._widget = value;
    this.setData();
  }

  get widget(): TaskWidgetModel {
    return this._widget ? this._widget : new TaskWidgetModel();
  }

  isLoading = false;
  data: unknown;
  cachedData: { [type: number]: unknown } = {};
  taskWidgetType = TaskWidgetType;
  _widget: TaskWidgetModel;
  private subscriptions$ = new Subscription();

  constructor(
    private _taskGroupDataService: TaskGroupDataService,
    private _taskDataService: TaskDataService,
    private _notificationService: NotificationService,
    private _identityService: IdentityService
  ) {}

  ngAfterViewInit() {
    this.setData();
  }

  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }

  setData() {
    if (this.cachedData && this.widget.bodyType in this.cachedData) {
      this.data = this.cachedData[this.widget.bodyType.valueOf()];
    } else if (this._identityService.hasPermission('tasks')) {
      this.isLoading = true;
      switch (this.widget.bodyType) {
        case TaskWidgetType.Expired:
          this.data = new Array<TaskGroupModel>();
          this.subscriptions$.add(
            this._taskGroupDataService.getExpired().subscribe({
              next: (response) => {
                if (response.hasResult) {
                  this.cachedData[this.widget.bodyType.valueOf()] =
                    response.result;
                  this.data = response.result;
                } else {
                  this._notificationService.displayErrorNotification(
                    response.messages
                  );
                }
              },
              error: (e) => {
                this._notificationService.displayErrorNotification(e);
                this.isLoading = false;
              },
              complete: () => (this.isLoading = false),
            })
          );
          break;
        case TaskWidgetType.TopActiveTasks:
          this.data = new Array<TaskModel>();
          this.subscriptions$.add(
            this._taskDataService.getTopActiveTasks().subscribe({
              next: (response) => {
                if (response.hasResult) {
                  this.cachedData[this.widget.bodyType.valueOf()] =
                    response.result;
                  this.data = response.result;
                } else {
                  this._notificationService.displayErrorNotification(
                    response.messages
                  );
                }
              },
              error: (e) => {
                this._notificationService.displayErrorNotification(e);
                this.isLoading = false;
              },
              complete: () => (this.isLoading = false),
            })
          );
          break;
        default:
          break;
      }
    }
  }
}
