<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title-bold" *ngIf="isNew">
      {{
        (task.createdFromTaskId
          ? 'tasks.task.followUpAdded'
          : 'tasks.task.addTask'
        ) | translate
      }}
    </h4>
    <h4 class="modal-title-bold" *ngIf="!isNew">
      {{ 'tasks.task.editTask' | translate }}
    </h4>
    <button type="button" class="close" closeModal>
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ca-follow-up-tasks-table
      *ngIf="task.followUpTasks.length > 0"
      [followUpTasks]="task.followUpTasks"
    >
    </ca-follow-up-tasks-table>

    <ca-task-form
      [task]="task"
      (dateChanged)="onDateChanged($event)"
    ></ca-task-form>

    <form [formGroup]="form">
      <div class="form-group">
        <label>
          <b>{{ 'taskGroup.display' | translate }}</b>
        </label>
        <sh-combo-box
          formControlName="selectedFormGroupId"
          [options]="taskGroupOptions"
          [newItemConfig]="newTaskGroupConfig"
          [isLoading]="isLoadingTaskGroupOptions"
        ></sh-combo-box>
      </div>
    </form>

    <sh-loading-indicator
      class="text-center d-block"
      *ngIf="saveStarted"
    ></sh-loading-indicator>
  </div>
  <div class="modal-footer d-flex">
    <!-- <div class="flex-grow-1">
      <button
        type="button"
        *ngIf="!isNew && task.createdFromTaskId === null"
        class="btn btn-block btn-info"
        (click)="followUp()"
      >
        {{ 'tasks.task.followUp' | translate }}
      </button>
    </div> -->
    <div class="flex-grow-1">
      <button
        class="btn btn-block btn-success"
        [disabled]="saveStarted"
        data-offset="0,1"
        (click)="save()"
      >
        {{ 'shared.terms.save' | translate }}
      </button>
    </div>
  </div>
</div>
