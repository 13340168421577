import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ResultWrapper } from '@seahorse/common';
import { NotificationService } from '@seahorse/common';
import * as _ from 'underscore';

import {
  ZeehavenIJmuidenMovementModel,
  ZeehavenIJmuidenVisitMovementType,
} from '../models/zeehaven-ijmuiden-movement.model';
import { ZeehavenIJmuidenOverviewModel } from '../models/zeehaven-ijmuiden-overview.model';
import { ZeehavenIJmuidenStatus } from '../models/zeehaven-ijmuiden-status.model';
import { ZeehavenIJmuidenVisitModel } from '../models/zeehaven-ijmuiden-visit.model';
import { ZeehavenIJmuidenVisitDataService } from '../services/zeehaven-ijmuiden-visit-data.service';

@Injectable()
export class ZeehavenIJmuidenLogicService {
  isLoading = false;
  waypoints = [
    'blstg',
    'ha',
    'habuk',
    'habup',
    'hafer',
    'hahel',
    'hakot',
    'haleo',
    'halog',
    'halwe',
    'halzu',
    'halzw',
    'hanew',
    'hasto',
    'hastw',
    'ijm',
    'vehou',
    'vekbk',
    'velbk',
    'vezvk',
    'vh',
    'vhbin',
    'vhdok',
    'vhft2',
    'vhhal',
    'vhleo',
    'vhnrm',
    'vhstg',
    'vhzzm',
    'vhzzo',
    'vhzzw',
    'vhzzy',
    'ym',
    'ymfct',
    'ymft3',
    'ymmon',
    'ymvol',
    'ztft1',
  ];

  constructor(
    private notificationService: NotificationService,
    private router: Router,
    private translate: TranslateService,
    private zeehavenData: ZeehavenIJmuidenVisitDataService
  ) {}

  addMovementToOverview(
    movement: ZeehavenIJmuidenMovementModel,
    overviewModel: ZeehavenIJmuidenOverviewModel
  ): void {
    if (!overviewModel) {
      return;
    }

    if (movement) {
      switch (movement.movementType) {
        case ZeehavenIJmuidenVisitMovementType.Incoming: {
          if (movement.etaOrEtd !== undefined && movement.etaOrEtd !== null) {
            overviewModel.incoming.push(movement);
          }
          break;
        }

        case ZeehavenIJmuidenVisitMovementType.Shifting: {
          if (movement.etaOrEtd !== undefined && movement.etaOrEtd !== null) {
            overviewModel.shifting.push(movement);
          }
          break;
        }

        case ZeehavenIJmuidenVisitMovementType.Outgoing: {
          if (movement.etaOrEtd !== undefined && movement.etaOrEtd !== null) {
            overviewModel.outgoing.push(movement);
          }
          break;
        }
      }
    }
  }

  addZHVMovementsToZHVOverviewModel(
    movements: ZeehavenIJmuidenMovementModel[],
    overviewModel: ZeehavenIJmuidenOverviewModel
  ): void {
    if (!overviewModel) {
      return;
    }

    if (movements) {
      movements = _.sortBy(movements, 'etaOrEtd');
      _.forEach(movements, (model) => {
        this.addMovementToOverview(model, overviewModel);
      });
    }
  }

  createNewMovementAndRedirect(movement: ZeehavenIJmuidenMovementModel) {
    if (movement) {
      this.isLoading = true;
      movement.status = ZeehavenIJmuidenStatus.Active;
      this.zeehavenData.add(movement).subscribe(
        (r: ResultWrapper<ZeehavenIJmuidenMovementModel>) => {
          if (r.hasResult && r.result) {
            // when movement type is incoming, create an new ZHIJ visit
            if (
              movement.movementType ===
                ZeehavenIJmuidenVisitMovementType.Incoming &&
              !r.result.zhijVisitId
            ) {
              this.isLoading = true;

              const zhijVisit = new ZeehavenIJmuidenVisitModel();
              zhijVisit.status = ZeehavenIJmuidenStatus.Suggestion;
              zhijVisit.nauticalShip = r.result.nauticalShip;
              zhijVisit.$nautical_portvisit_id =
                r.result.$nautical_portvisit_id;
              zhijVisit.shipName = r.result.shipName;
              zhijVisit.agent = r.result.agent;
              zhijVisit.origin = r.result.destination;
              zhijVisit.destination = null;
              this.zeehavenData.addVisit(zhijVisit).subscribe(
                (
                  addVisitResponse: ResultWrapper<ZeehavenIJmuidenVisitModel>
                ) => {
                  if (addVisitResponse.hasResult && addVisitResponse.result) {
                    r.result.zhijVisitId = addVisitResponse.result.__Id;
                    this.zeehavenData.update(r.result).subscribe(
                      (updateResponse) => {},
                      (updateError) => {},
                      () => {
                        this.router.navigateByUrl(
                          `custom-ui/zeehaven/${r.result.__Id}`
                        );
                      }
                    );
                  }
                },
                (e) => {
                  this.router.navigateByUrl(
                    `custom-ui/zeehaven/${r.result.__Id}`
                  );
                }
              );
            } else {
              this.router.navigateByUrl(`custom-ui/zeehaven/${r.result.__Id}`);
            }
          } else {
            this.notificationService.showError(
              _.pluck(r.messages, 'message').join('\n'),
              this.translate.instant('shared.terms.failed')
            );
            this.isLoading = false;
          }
        },

        (e) => {
          this.notificationService.showError(
            _.pluck(e.error.messages, 'message').join('\n'),
            this.translate.instant('shared.terms.failed')
          );
          this.isLoading = false;
        },

        () => {}
      );
    }
  }

  createNewMovementByZHIJVisit(
    zhijVisit: ZeehavenIJmuidenVisitModel,
    movementType: ZeehavenIJmuidenVisitMovementType
  ) {
    const newMovement: ZeehavenIJmuidenMovementModel =
      new ZeehavenIJmuidenMovementModel();

    newMovement.movementType = movementType;
    newMovement.zhijVisitId = zhijVisit.__Id;
    newMovement.nauticalShip = zhijVisit.nauticalShip
      ? zhijVisit.nauticalShip
      : zhijVisit['$nautical_ship_id'];
    newMovement.$nautical_portvisit_id = zhijVisit.$nautical_portvisit_id;
    newMovement.agent = zhijVisit.agent;
    newMovement.shipName = zhijVisit.shipName;

    if (zhijVisit.origin) {
      newMovement.origin = zhijVisit.origin;
    } else if (zhijVisit.currentBerth) {
      newMovement.origin = zhijVisit.currentBerth;
      newMovement.originWaypoint = zhijVisit.currentBerthWaypoint;
    }

    this.createNewMovementAndRedirect(newMovement);
  }
}
