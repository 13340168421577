<div [ngClass]="{ 'form-group': !showInline }">
  <div class="d-flex justify-content-between">
    <label
      class="d-flex justify-content-between"
      for="typeahead-company-financial"
      *ngIf="!showInline"
    >
      <span [class.required]="isRequired">
        <b>{{ title }}</b>
      </span>
      <span *ngIf="companyFinancialsLoading || selectedCompanyFinancialLoading">
        <i class="fa-regular fa-spinner fa-spin fa-fw"></i>
      </span>
    </label>
  </div>
  <ng-template #rt let-r="result" let-t="term">
    <div [ngClass]="{ disabled: isDebtorPicker && !r.financialId }">
      <ngb-highlight [result]="r.debtorNumber" [term]="t"></ngb-highlight>
    </div>
  </ng-template>
  <div class="input-group">
    <input
      [(ngModel)]="selectedCompanyFinancial"
      [disabled]="selectedCompanyFinancialLoading || isReadOnly"
      [class.is-invalid]="companyFinancialsLoadingFailed"
      [ngbTypeahead]="searchCompanyFinancial"
      [inputFormatter]="companyFinancialFormatter"
      [resultTemplate]="rt"
      [editable]="allowTextOnly"
      [placeholder]="
        ('companyFinancials.pickerDialog.searchCompanyFinancial' | translate) +
        '...'
      "
      [required]="isRequired"
      (focus)="focus$.next($any($event).target.value)"
      (click)="click$.next($any($event).target.value)"
      (selectItem)="itemSelected($event)"
      #companyFinancialTypeahead="ngbTypeahead"
      id="typeahead-company-financial"
      name="companyFinancial"
      #tariff="ngModel"
      type="text"
      class="form-control"
      autocomplete="off"
    />
    <div class="input-group-append">
      <button
        (click)="setNull()"
        type="button"
        class="btn bg-transparent ml-n5 br-0"
        style="z-index: 100"
      >
        <i class="fa-solid fa-times fa-xs fa-fw ml-1"></i>
      </button>
      <ng-content select="button"></ng-content>
    </div>
  </div>
  <small *ngIf="tariff.invalid && isRequired && submitted" class="text-danger">
    {{ 'shared.terms.requiredFieldText' | translate }}
  </small>
</div>
