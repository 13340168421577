import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FileSizePipe, TruncatePipe } from '@seahorse/common';
import { FileService } from '@seahorse/domain';
import { FilePreviewModel } from './file-preview.model';

@Component({
  selector: 'sh-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss'],
  standalone: true,
  imports: [CommonModule, TruncatePipe, FileSizePipe, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilePreviewComponent {
  @Input() file?: FilePreviewModel;

  @Output() download = new EventEmitter<any>();
  @Output() preview = new EventEmitter<any>();
  @Output() delete = new EventEmitter<any>();

  constructor(private fileService: FileService) {}

  fileIcon(fileName: string) {
    return this.fileService.getFileTypeIcon(fileName.split('.').pop() || '');
  }

  downloadFile(file: any) {
    this.download.emit(file);
  }

  previewFile(file: any) {
    this.preview.emit(file);
  }

  deleteFile(id: any) {
    this.delete.emit(id);
  }
}
