import { Injectable } from '@angular/core';
import {
  DataContextService,
  ErrorMessage,
  ResultWrapper,
} from '@seahorse/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'underscore';
import { NauticalPortModel } from '../../nautical-geography/models/nautical-port.model';
import {
  NauticalMovementModel,
  NauticalMovementOverviewModel,
} from '../models/nautical-movement.model';
import { NauticalVisitOverviewModel } from '../models/nautical-visit-overview.model';
import {
  NauticalVisitDetailsModel,
  NauticalVisitModel,
} from '../models/nautical-visit.model';

@Injectable({
  providedIn: 'root',
})
export class NauticalVisitDataService {
  constructor(private dataContext: DataContextService) {}

  find(
    query: string,
    port?: string,
    listType?: string,
    pageIndex?: number,
    pageSize?: number,
    annotate = false
  ): Observable<ResultWrapper<NauticalVisitOverviewModel[]>> {
    const queryParams = [
      `q=${encodeURIComponent(query)}`,
      `annotate=${annotate}`,
    ];

    if (port !== undefined && port !== null) {
      queryParams.push(`port=${encodeURIComponent(port)}`);
    }

    if (listType !== undefined && listType !== null) {
      queryParams.push(`list=${encodeURIComponent(listType)}`);
    }

    if (pageIndex !== undefined && pageIndex >= 0) {
      queryParams.push(`pindex=${pageIndex}`);
    }

    if (pageSize !== undefined && pageSize >= 0) {
      queryParams.push(`psize=${pageSize}`);
    }

    const queryString = queryParams.join('&');
    const url = `nautical/visit/find/overview?${queryString}`;

    return this.dataContext.get<NauticalVisitOverviewModel[]>(url);
  }

  get(visitId: number, annotate?: boolean): Observable<NauticalVisitModel> {
    let url = `nautical/visit/${visitId}`;
    if (annotate) url += '?annotate=true';
    return this.dataContext
      .get<NauticalVisitModel>(url)
      .pipe(map((response) => response.result));
  }

  getById(
    nauticalVisitId: NauticalVisitModel['id']
  ): Observable<ResultWrapper<NauticalVisitModel>> {
    return this.dataContext
      .get<NauticalVisitModel[]>(`nautical/visit/?visitIds=${nauticalVisitId}`)
      .pipe(
        map((r: ResultWrapper<NauticalVisitModel[]>) => {
          const w: ResultWrapper<NauticalVisitModel> = _.extend(
            _.omit(r, ['result', 'hasResult']),
            { result: null, hasResult: false }
          );

          if (r.hasResult && r.result && r.result.length === 1) {
            w.hasResult = true;
            w.result = r.result[0];
          } else {
            const errorMessage: ErrorMessage = new ErrorMessage();

            if (r.result.length) {
              errorMessage.code = 'multipleResults';
              errorMessage.message = 'Multiple results have been found';
            } else {
              errorMessage.code = 'noResults';
              errorMessage.message = 'No results have been found';
            }

            w.messages = w.messages.concat(errorMessage);
          }

          return w;
        })
      );
  }

  // tslint:disable-next-line:max-line-length
  getByIds(
    ids: number[],
    pageIndex?: number,
    pageSize?: number,
    role?: string,
    annotate = false
  ): Observable<ResultWrapper<NauticalVisitModel[]>> {
    let url = `nautical/visit/?visitIds=${ids.join()}&annotate=${annotate}`;

    if (pageIndex >= 0) {
      url += '&pindex=' + pageIndex;
    }

    if (pageSize >= 0) {
      url += '&psize=' + pageSize;
    }

    if (role) {
      url += '&roles=' + role;
    }

    return this.dataContext.get<NauticalVisitModel[]>(url);
  }

  // tslint:disable-next-line:max-line-length
  getActiveVisitForPort(
    port: string,
    timelimit: number,
    recentlysailed?: boolean,
    pageIndex?: number,
    pageSize?: number,
    role?: string
  ): Observable<ResultWrapper<NauticalVisitOverviewModel[]>> {
    let url = `nautical/visit/port/${port}/overview/active?timelimit=${timelimit}`;

    if (recentlysailed !== undefined && recentlysailed !== null) {
      url += '&recentlysailed=' + recentlysailed;
    }

    if (pageIndex >= 0) {
      url += `&pindex=${pageIndex}`;
    }

    if (pageSize >= 0) {
      url += '&psize=' + pageSize;
    }

    if (role) {
      url += '&roles=' + role;
    }
    return this.dataContext.get<NauticalVisitOverviewModel[]>(url);
  }

  getDetails(visitId: number): Observable<NauticalVisitDetailsModel> {
    return this.dataContext
      .get<NauticalVisitDetailsModel>(`nautical/visit/${visitId}/details`)
      .pipe(map((response) => response.result));
  }

  // tslint:disable-next-line:max-line-length
  getListForPort(
    port: string,
    listType: string,
    loadDetails?: boolean,
    pageIndex?: number,
    pageSize?: number
  ): Observable<ResultWrapper<NauticalVisitModel[]>> {
    let url = `nautical/visit/list/port/${port}?list=${listType}`;

    if (loadDetails) {
      url += '&loadDetails=' + loadDetails;
    }

    if (pageIndex >= 0) {
      url += '&pindex=' + pageIndex;
    }

    if (pageSize >= 0) {
      url += '&psize=' + pageSize;
    }

    return this.dataContext.get<NauticalVisitModel[]>(url);
  }

  // tslint:disable-next-line:max-line-length
  getListOverviewForPort(
    port: string,
    listType: string,
    loadDetails?: boolean,
    pageIndex?: number,
    pageSize?: number,
    role?: string,
    nauticalFilter?: number,
    dateFrom?: string,
    dateUntil?: string
  ): Observable<ResultWrapper<NauticalVisitOverviewModel[]>> {
    if (!listType) {
      listType = 'nofilter';
    }

    let url = `nautical/visit/list/port/${port}/overview?list=${listType}`;

    if (loadDetails) {
      url += '&loadDetails=' + loadDetails;
    }

    if (pageIndex >= 0) {
      url += '&pindex=' + pageIndex;
    }

    if (pageSize >= 0) {
      url += '&psize=' + pageSize;
    }

    if (role) {
      url += '&roles=' + role;
    }

    if (
      nauticalFilter !== undefined &&
      nauticalFilter !== null &&
      nauticalFilter !== -1
    ) {
      url += '&filter=' + nauticalFilter;
    }

    if (dateFrom) {
      url += '&dateFrom=' + dateFrom;
    }

    if (dateUntil) {
      url += '&dateUntil=' + dateUntil;
    }

    return this.dataContext.get<NauticalVisitOverviewModel[]>(url);
  }

  // tslint:disable-next-line:max-line-length
  getListOverviewForPortByIds(
    ids: number[],
    pageIndex?: number,
    pageSize?: number,
    role?: string
  ): Observable<ResultWrapper<NauticalVisitOverviewModel[]>> {
    let url = 'nautical/visit/list/overview/?visitIds=' + ids.join();

    if (pageIndex >= 0) {
      url += '&pindex=' + pageIndex;
    }

    if (pageSize >= 0) {
      url += '&psize=' + pageSize;
    }

    if (role) {
      url += '&roles=' + role;
    }

    return this.dataContext.get<NauticalVisitOverviewModel[]>(url);
  }

  // tslint:disable-next-line:max-line-length
  getListOverviewForPortByReferences(
    references: string[],
    pageIndex?: number,
    pageSize?: number,
    role?: string
  ): Observable<ResultWrapper<NauticalVisitOverviewModel[]>> {
    let url =
      'nautical/visit/list/overview/?visitReferences=' + references.join();

    if (pageIndex >= 0) {
      url += '&pindex=' + pageIndex;
    }

    if (pageSize >= 0) {
      url += '&psize=' + pageSize;
    }

    if (role) {
      url += '&roles=' + role;
    }

    return this.dataContext.get<NauticalVisitOverviewModel[]>(url);
  }

  getMovementOverviewForPort(
    port: string,
    dateFrom: string,
    dateUntil: string,
    pageIndex?: number,
    pageSize?: number
  ): Observable<ResultWrapper<NauticalMovementOverviewModel[]>> {
    let url = `nautical/movement/port/${port}/overview?dateFrom=${dateFrom}&dateUntil=${dateUntil}`;
    if (pageIndex >= 0) {
      url += `&pindex=${pageIndex}`;
    }

    if (pageSize >= 0) {
      url += `&psize=${pageSize}`;
    }

    return this.dataContext.get<NauticalMovementOverviewModel[]>(url);
  }

  getPortsWithVisits(): Observable<ResultWrapper<NauticalPortModel[]>> {
    return this.dataContext.get<NauticalPortModel[]>(`nautical/visit/ports`);
  }

  getPortVisitCountForShip(shipId: string): Observable<number> {
    return this.dataContext
      .get<number>(`nautical/visit/ship/${shipId}/count`)
      .pipe(map((response) => response.result));
  }

  getPortVisitOverviewForShip(
    shipId: string,
    limit = 50
  ): Observable<ResultWrapper<NauticalVisitOverviewModel[]>> {
    return this.dataContext.get<NauticalVisitOverviewModel[]>(
      `nautical/visit/ship/${shipId}/overview?psize=${limit}`
    );
  }

  getMostRelevantPortVisitOverviewForShip(
    shipId: string,
    limit = 50
  ): Observable<ResultWrapper<NauticalVisitOverviewModel[]>> {
    return this.dataContext.get<NauticalVisitOverviewModel[]>(
      `nautical/visit/ship/${shipId}/overview/relevant?psize=${limit}`
    );
  }

  // tslint:disable-next-line:max-line-length
  getListOverviewForShip(
    shipId: string,
    listType: string,
    search?: string,
    loadDetails?: boolean,
    pageIndex?: number,
    pageSize?: number,
    role?: string
  ): Observable<ResultWrapper<NauticalVisitOverviewModel[]>> {
    if (!listType) {
      listType = 'nofilter';
    }

    let url = `nautical/visit/list/ship/${shipId}/overview?list=${listType}`;

    if (search) {
      url += '&search=' + search;
    }

    if (loadDetails) {
      url += '&loadDetails=' + loadDetails;
    }

    if (pageIndex >= 0) {
      url += '&pindex=' + pageIndex;
    }

    if (pageSize >= 0) {
      url += '&psize=' + pageSize;
    }

    if (role) {
      url += '&roles=' + role;
    }
    return this.dataContext.get<NauticalVisitOverviewModel[]>(url);
  }

  getByReferenceNumber(
    referenceNumber: string,
    annotate?: boolean
  ): Observable<ResultWrapper<NauticalVisitModel>> {
    let url = `nautical/visit/reference-number/${referenceNumber}`;
    if (annotate) url += '?annotate=true';
    return this.dataContext.get<NauticalVisitModel>(url);
  }

  getNauticalMovementById(
    nauticalMovementId: number,
    annotate?: boolean
  ): Observable<ResultWrapper<NauticalMovementModel>> {
    let url = `nautical/movement/${nauticalMovementId}`;
    if (annotate) url += '?annotate=true';
    return this.dataContext.get<NauticalMovementModel>(url);
  }
}
