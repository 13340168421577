import { Injectable } from '@angular/core';
import { DataContextService, ResultWrapper } from '@seahorse/common';
import { Observable } from 'rxjs';
import { DataService } from '../../shared/services/data.service';
import { CompanyFinancialModel } from '../models/company.model';

@Injectable({
  providedIn: 'root',
})
export class FinancialDataService
  implements DataService<CompanyFinancialModel>
{
  constructor(private dataContext: DataContextService) {}

  getFinancials(
    companyId: number,
    pageIndex: number,
    pageSize: number
  ): Observable<ResultWrapper<CompanyFinancialModel[]>> {
    return this.dataContext.get<CompanyFinancialModel[]>(
      `financials/company/${companyId}?pindex=${pageIndex}&psize=${pageSize}`
    );
  }

  getById(
    financialId: number,
    annotate: boolean = false
  ): Observable<ResultWrapper<CompanyFinancialModel>> {
    return this.dataContext.get<CompanyFinancialModel>(
      `financials/${financialId}?annotate=${annotate}`
    );
  }

  search(
    search?: string,
    pageIndex?: number,
    pageSize?: number,
    annotate: boolean = false
  ): Observable<ResultWrapper<CompanyFinancialModel[]>> {
    let queryParams = '';

    if (search !== undefined && search !== null) {
      queryParams += `search=${encodeURIComponent(search)}`;
    }

    if (pageIndex !== undefined && pageIndex !== null) {
      queryParams += `${queryParams ? '&' : ''}pindex=${pageIndex}`;
    }

    if (pageSize !== undefined && pageSize !== null) {
      queryParams += `${queryParams ? '&' : ''}psize=${pageSize}`;
    }

    queryParams += `${queryParams ? '&' : ''}annotate=${annotate}`;

    return this.dataContext.get<CompanyFinancialModel[]>(
      `financials${queryParams ? '?' + queryParams : ''}`
    );
  }

  add(
    companyFinancial: CompanyFinancialModel
  ): Observable<ResultWrapper<CompanyFinancialModel>> {
    return this.dataContext.post<CompanyFinancialModel>(
      `financials`,
      companyFinancial
    );
  }

  update(
    companyFinancial: CompanyFinancialModel
  ): Observable<ResultWrapper<CompanyFinancialModel>> {
    return this.dataContext.put<CompanyFinancialModel>(
      `financials/${companyFinancial.id}`,
      companyFinancial
    );
  }

  delete(
    financialId: number
  ): Observable<ResultWrapper<CompanyFinancialModel>> {
    return this.dataContext.delete<CompanyFinancialModel>(
      `financials/${financialId}`
    );
  }
}
