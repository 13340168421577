import { Injectable } from '@angular/core';
import { KeyValuePair, ResultWrapper } from '@seahorse/common';
import { DataContextService } from '@seahorse/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { InvoiceCancelModel } from '../models/invoice-cancel.model';
import { InvoiceEventModel } from '../models/invoice-event.model';
import { InvoiceLineModel } from '../models/invoice-line.model';
import { InvoiceMetaDataModel } from '../models/invoice-metadata.model';
import { InvoicePaymentModel } from '../models/invoice-payment.model';
import { InvoiceServerSearchModel } from '../models/invoice-search-model';
import { InvoiceModel, InvoiceStatuses } from '../models/invoice.model';
import { DataService } from '../../../shared/services/data.service';

@Injectable({
  providedIn: 'root',
})
export class InvoiceDataService implements DataService<InvoiceModel> {
  constructor(private dataContext: DataContextService) {}

  search(
    model: InvoiceServerSearchModel
  ): Observable<ResultWrapper<InvoiceModel[]>> {
    const urlArgs = [];
    urlArgs.push(`pindex=${model.pageIndex}`);
    urlArgs.push(`psize=${model.pageSize}`);
    urlArgs.push(`annotate=${model.annotate}`);

    if (model.search && model.search !== '') {
      urlArgs.push(`search=${model.search}`);
    }

    if (model.statuses && model.statuses.length) {
      urlArgs.push(`statuses=${model.statuses.join(',')}`);
    }

    if (model.invoiceTypes && model.invoiceTypes.length) {
      urlArgs.push(`invoicetypes=${model.invoiceTypes.join(',')}`);
    }

    if (model.companyId) {
      urlArgs.push(`companyId=${model.companyId}`);
    }

    if (model.dateFrom) {
      urlArgs.push(
        `dateFrom=${
          moment.isMoment(model.dateFrom)
            ? model.dateFrom.format('YYYY-MM-DD')
            : model.dateFrom
        }`
      );
    }

    if (model.dateUntil) {
      urlArgs.push(
        `dateUntil=${
          moment.isMoment(model.dateUntil)
            ? model.dateUntil.format('YYYY-MM-DD')
            : model.dateUntil
        }`
      );
    }

    if (model.appendFields && model.appendFields.length > 0) {
      urlArgs.push('appenddata=1');
      urlArgs.push('appendfields=' + model.appendFields.join(','));
    }

    if (model.includeLines === true) {
      return this.dataContext.post<InvoiceModel[]>(
        `invoice/view/line?${urlArgs.join('&')}`,
        model.searchModel
      );
    } else {
      return this.dataContext.post<InvoiceModel[]>(
        `invoice/view?${urlArgs.join('&')}`,
        model.searchModel
      );
    }
  }

  addInvoiceEvent(
    invoiceId: number,
    invoiceEvent: InvoiceEventModel
  ): Observable<ResultWrapper<InvoiceEventModel>> {
    delete invoiceEvent.id;
    return this.dataContext.post<InvoiceEventModel>(
      'invoice/' + invoiceId + '/event',
      invoiceEvent
    );
  }

  addInvoiceLine(
    invoiceId: number,
    invoiceLine: InvoiceLineModel
  ): Observable<ResultWrapper<InvoiceLineModel>> {
    delete invoiceLine.id;
    return this.dataContext.post<InvoiceLineModel>(
      'invoice/' + invoiceId + '/line',
      invoiceLine
    );
  }

  addInvoiceMetaData(
    invoiceId: number,
    invoiceLineId: number,
    invoiceMetaData: InvoiceMetaDataModel
  ): Observable<ResultWrapper<InvoiceMetaDataModel>> {
    delete invoiceMetaData.id;

    if (!invoiceLineId) {
      invoiceLineId = -1;
    }
    return this.dataContext.post<InvoiceMetaDataModel>(
      `invoice/${invoiceId}/line/${invoiceLineId}/metadata`,
      invoiceMetaData
    );
  }

  addPayment(
    invoiceId: number,
    payment: InvoicePaymentModel
  ): Observable<ResultWrapper<InvoicePaymentModel>> {
    delete payment.id;
    return this.dataContext
      .post<InvoicePaymentModel>('invoice/' + invoiceId + '/payment', payment)
      .pipe(map((response) => response));
  }

  approveInvoice(invoiceId: number): Observable<ResultWrapper<InvoiceModel>> {
    return this.dataContext
      .get<InvoiceModel>('invoice/' + invoiceId + '/approve')
      .pipe(map((response) => response));
  }

  creditInvoice(
    originalInvoiceId: number,
    newFlowId?: number
  ): Observable<ResultWrapper<InvoiceModel>> {
    const request = {
      originalInvoiceId,
      newFlowId,
    };

    return this.dataContext
      .post<InvoiceModel>('invoice/credit', request)
      .pipe(map((response) => response));
  }

  deleteInvoiceLine(
    invoiceId: number,
    invoiceLineId: number
  ): Observable<ResultWrapper<InvoiceLineModel>> {
    return this.dataContext.delete<InvoiceLineModel>(
      'invoice/' + invoiceId + '/line/' + invoiceLineId
    );
  }

  deleteInvoiceMetaData(
    invoiceId: number,
    invoiceLineId: number,
    invoiceMetaDataId: number
  ): Observable<ResultWrapper<InvoiceMetaDataModel>> {
    return this.dataContext.delete<InvoiceMetaDataModel>(
      `invoice/${invoiceId}/line/${invoiceLineId}/metadata/${invoiceMetaDataId}`
    );
  }

  getInvoice(
    invoiceId: number,
    appendFields?: string[],
    annotate = false
  ): Observable<ResultWrapper<InvoiceModel>> {
    const urlArgs = [];
    if (appendFields && appendFields.length > 0) {
      urlArgs.push('appenddata=1');
      urlArgs.push('appendfields=' + appendFields.join(','));
    }

    urlArgs.push(`annotate=${annotate}`);
    return this.dataContext
      .get<InvoiceModel>(`invoice/${invoiceId}?${urlArgs.join('&')}`)
      .pipe(map((response) => response));
  }

  getInvoices(): Observable<ResultWrapper<InvoiceModel[]>> {
    return this.dataContext
      .get<InvoiceModel[]>(`invoice`)
      .pipe(map((response) => response));
  }

  // return a ViewInvoiceModel
  getById(
    id: number,
    annotate?: boolean
  ): Observable<ResultWrapper<InvoiceModel>> {
    let url = `invoice/view/${id}`;
    if (annotate) url += '?annotate=true';
    return this.dataContext.get<InvoiceModel>(url);
  }

  getTotals(
    statuses: InvoiceStatuses[],
    dateFrom: string,
    dateUntil: string,
    groupBy?: string,
    discountSource?: string
  ): Observable<ResultWrapper<any[]>> {
    let url =
      'invoice/totals?statuses=' +
      statuses.join(',') +
      '&dateFrom=' +
      dateFrom +
      '&dateUntil=' +
      dateUntil;

    if (groupBy !== undefined && groupBy !== null && groupBy !== '') {
      url += '&groupby=' + groupBy;
    }

    if (discountSource) {
      url += '&discountsource=' + discountSource;
    }

    return this.dataContext.get<any[]>(url);
  }

  add(invoice: InvoiceModel): Observable<ResultWrapper<InvoiceModel>> {
    return this.dataContext.post<InvoiceModel>('invoice', invoice);
  }

  addWithSourceObject(
    invoice: InvoiceModel,
    sourceObject?: unknown
  ): Observable<ResultWrapper<InvoiceModel>> {
    return this.dataContext.post<InvoiceModel>('invoice/source-object', {
      invoice: invoice,
      sourceObject: sourceObject,
    });
  }

  update(invoice: InvoiceModel): Observable<ResultWrapper<InvoiceModel>> {
    return this.dataContext.put<InvoiceModel>(`invoice/${invoice.id}`, invoice);
  }

  delete(invoiceId: number): Observable<ResultWrapper<InvoiceModel>> {
    return this.dataContext.delete<InvoiceModel>(`invoice/${invoiceId}`);
  }

  cancel(invoice: InvoiceCancelModel): Observable<ResultWrapper<InvoiceModel>> {
    return this.dataContext.put<InvoiceModel>(
      `invoice/${invoice.id}/cancel-invoice`,
      invoice
    );
  }

  split(
    invoiceId: number,
    splitInvoice: unknown
  ): Observable<ResultWrapper<InvoiceModel>> {
    return this.dataContext.post<InvoiceModel>(
      `invoice/${invoiceId}/split`,
      splitInvoice
    );
  }

  updateInvoiceLine(
    invoiceLine: InvoiceLineModel
  ): Observable<ResultWrapper<InvoiceLineModel>> {
    return this.dataContext.put<InvoiceLineModel>(
      `invoice/${invoiceLine.invoiceId}/line/${invoiceLine.id}`,
      invoiceLine
    );
  }

  updateInvoiceLineSortOrder(
    invoiceLineIds: unknown
  ): Observable<ResultWrapper<InvoiceLineModel[]>> {
    return this.dataContext.put<InvoiceLineModel[]>(
      `invoice/line/sortorder`,
      invoiceLineIds
    );
  }

  updateInvoiceMetaData(
    invoiceMetaData: InvoiceMetaDataModel
  ): Observable<ResultWrapper<InvoiceMetaDataModel>> {
    return this.dataContext.put<InvoiceMetaDataModel>(
      `invoice/${invoiceMetaData.invoiceId}/line/${invoiceMetaData.invoiceLineId}/metadata/${invoiceMetaData.id}`,
      invoiceMetaData
    );
  }

  combineInvoices(
    primaryInvoiceId: number,
    invoiceIds: number[]
  ): Observable<ResultWrapper<InvoiceModel>> {
    return this.dataContext.post<InvoiceModel>(
      'invoice/' + primaryInvoiceId + '/combine',
      invoiceIds
    );
  }

  processInvoiceMetaDataList(
    invoiceId: InvoiceModel['id'],
    invoiceLineId: InvoiceLineModel['id'],
    invoiceMetaDataList: InvoiceMetaDataModel[]
  ): Observable<ResultWrapper<KeyValuePair<string, InvoiceMetaDataModel[]>[]>> {
    return this.dataContext.post<
      KeyValuePair<string, InvoiceMetaDataModel[]>[]
    >(
      `invoice/${invoiceId}/meta-data-list/${invoiceLineId}`,
      invoiceMetaDataList
    );
  }

  verifyInvoice(invoiceId: number): Observable<ResultWrapper<InvoiceModel>> {
    return this.dataContext
      .get<InvoiceModel>('invoice/' + invoiceId + '/verify')
      .pipe(map((response) => response));
  }
}
