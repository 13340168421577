<div
  class="page-header-filter-list page-header-filter-list-visible"
  [ngClass]="{
    'sidebar-open': sidebarOpen
  }"
>
  <temp-form [model]="formModel">
    <div *ngIf="showSearchPaginationOptions" actions class="form-group">
      <label
        ><b>{{ 'shared.terms.pageSize' | translate }}</b></label
      >
      <select class="form-control" [(ngModel)]="pageSize">
        <option [value]="20">20</option>
        <option [value]="50">50</option>
        <option [value]="100">100</option>
        <option [value]="250">250</option>
        <option [value]="500">500</option>
      </select>
    </div>
    <div actions class="form-group text-right">
      <button class="btn btn-link" type="button" (click)="reset()">
        {{ 'shared.terms.clear' | translate }}
      </button>
      <button class="btn btn-primary" (click)="search()">
        {{ 'shared.terms.search' | translate }}
      </button>
    </div>
  </temp-form>
</div>
