import { Injectable } from '@angular/core';
import {
  FieldVisibility,
  ObjectDefinitionModel,
  UiModuleCalendarConfiguration,
} from '@seahorse/domain';
import { BehaviorSubject } from 'rxjs';

import {
  UiModule,
  UiModuleDetailsConfiguration,
  UiModuleOverviewConfiguration,
} from '@seahorse/domain';

@Injectable({
  providedIn: 'root',
})
export class UiModulesService {
  currentUiModule$ = new BehaviorSubject<UiModule | undefined>(undefined);

  createModuleFromDefinition(objectDefinition: ObjectDefinitionModel) {
    const fieldDefinitionCodes = objectDefinition.objectFieldDefinitions
      .filter((field) => field.visibility === FieldVisibility.Public)
      .map((x) => x.systemCode);

    const module: UiModule = UiModule.default();
    module.displayName = objectDefinition.name;
    module.customObjectDefinitionId = objectDefinition.id;
    module.systemCode = objectDefinition.systemCode;

    module.uiModuleOverviewConfiguration =
      UiModuleOverviewConfiguration.default();

    module.uiModuleCalendarConfiguration =
      UiModuleCalendarConfiguration.default();

    module.uiModuleDetailsConfiguration =
      UiModuleDetailsConfiguration.default();

    module.uiModuleOverviewConfiguration.displayColumnsCodes =
      fieldDefinitionCodes.join(',');

    module.uiModuleOverviewConfiguration.sortFieldCode =
      objectDefinition.objectFieldDefinitions[0].systemCode;

    module.uiModuleOverviewConfiguration.summaryConfig.fields =
      fieldDefinitionCodes;

    return module;
  }
}
