import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { ContentDistributionServicesModule } from '../../content-distribution/services/content-distribution-services.module';
import { CoreModule } from '../../core/core.module';
import { LayoutComponentsModule } from '../../layout/components/layout-components.module';
import { LayoutPipesModule } from '../../layout/pipes/layout-pipes.module';
import { SharedModule } from '../../shared/shared.module';
import { TaskDefinitionModule } from '../../tasks/modules/task-definition/task-definition.module';
import { TaskComponentsModule } from '../../tasks/modules/task/components/task-components.module';
import { TaskPipesModule } from '../../tasks/modules/task/pipes/task-pipes.module';

import { AddressPostalPipe } from '../pipes/address-postal.pipe';
import { AddressTypePipe } from '../pipes/address-type.pipe';
import { ContactStatusPipe } from '../pipes/contact-status.pipe';
import { AddressFormComponent } from './address/address-form/address-form.component';
import { AddressModalComponent } from './address/address-modal/address-modal.component';
import { CompanyCardComponent } from './company-card/company-card.component';
import { CompanyAddressesCardComponent } from './company-details/company-addresses-card/company-addresses-card.component';
import { CompanyAddressesListComponent } from './company-details/company-addresses-list/company-addresses-list.component';
import { CompanyChildrenListComponent } from './company-details/company-children-list/company-children-list.component';
import { CompanyContactsCardComponent } from './company-details/company-contacts-card/company-contacts-card.component';
import { CompanyContactsListComponent } from './company-details/company-contacts-list/company-contacts-list.component';
import { CompanyDepartmentsCardComponent } from './company-details/company-departments-card/company-departments-card.component';
import { CompanyDepartmentsListComponent } from './company-details/company-departments-list/company-departments-list.component';
import { CompanyDetailsHeaderComponent } from './company-details/company-details-header/company-details-header.component';
import { CompanyFinancialComponent } from './company-details/company-financial/company-financial.component';
import { CompanyFinancialsCardComponent } from './company-details/company-financials-card/company-financials-card.component';
import { CompanyFinancialsListComponent } from './company-details/company-financials-list/company-financials-list.component';
import { CompanyInfoComponent } from './company-details/company-info/company-info.component';
import { CompanyInvoicesCardComponent } from './company-details/company-invoices-card/company-invoices-card.component';
import { CompanyInvoicesListComponent } from './company-details/company-invoices-list/company-invoices-list.component';
import { CompanySnelstartDebtorInformationCardComponent } from './company-details/company-snelstart-debtor-information-card/company-snelstart-debtor-information-card.component';
import { CompanySnelstartDebtorInformationListComponent } from './company-details/company-snelstart-debtor-information-list/company-snelstart-debtor-information-list.component';
import { ParentCompanyPickerComponent } from './company-details/parent-company-picker/parent-company-picker.component';
import { CompanyFinancialSelectComponent } from './company-financial-select/company-financial-select.component';
import { CompanyHeaderComponent } from './company-header/company-header.component';
import { CompanyListComponent } from './company-list/company-list.component';
import { CompanyPickerDialogComponent } from './company-picker-dialog/company-picker-dialog.component';
import { CompanyPickerComponent } from './company-picker/company-picker.component';
import { CompanySummaryComponent } from './company-summary/company-summary.component';
import { CompanyTilesComponent } from './company-tiles/company-tiles.component';
import { CompanyFormComponent } from './company/company.component';
import { ContactFormComponent } from './contact/contact-form/contact-form.component';
import { ContactListComponent } from './contact/contact-list/contact-list.component';
import { ContactModalComponent } from './contact/contact-modal/contact.modal.component';
import { ContactSummaryComponent } from './contact/contact-summary/contact-summary.component';
import { ContactTilesComponent } from './contact/contact-tiles/contact-tiles.component';
import { DepartmentFormComponent } from './department/department-form/department-form.component';
import { DepartmentModalComponent } from './department/department-modal/department.modal.component';
import { FinancialFormComponent } from './financial/financial-form/financial-form.component';
import { FinancialModalComponent } from './financial/financial-modal/financial-modal.component';
import {
  AttributeListComponent,
  DateTimePickerComponent,
} from '@seahorse/temp';
import { SeahorseFormsModule } from '@seahorse/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SeahorseUiModule } from '@seahorse/ui';
import { CompanyFinancialPickerComponent } from './company-financial-picker/company-financial-picker.component';
import { OptInStatusPipe } from '../pipes/opt-in-status.pipe';
import { ContentDistributionComponentSharedModule } from '../../content-distribution/components/content-distribution-component-shared.module';

@NgModule({
  declarations: [
    AddressTypePipe,
    ContactStatusPipe,
    AddressPostalPipe,

    AddressFormComponent,
    AddressModalComponent,

    CompanyAddressesCardComponent,
    CompanyAddressesListComponent,
    CompanyCardComponent,
    CompanyContactsCardComponent,
    CompanyContactsListComponent,
    CompanyDepartmentsCardComponent,
    CompanyDepartmentsListComponent,
    CompanyFinancialComponent,
    CompanyFinancialsCardComponent,
    CompanyFinancialsListComponent,
    CompanyFinancialSelectComponent,
    CompanyHeaderComponent,
    CompanyInfoComponent,
    CompanyInvoicesCardComponent,
    CompanyInvoicesListComponent,
    CompanyListComponent,
    CompanyTilesComponent,
    CompanySummaryComponent,
    CompanyDetailsHeaderComponent,
    CompanyChildrenListComponent,
    ParentCompanyPickerComponent,
    CompanyFormComponent,

    ContactListComponent,
    ContactTilesComponent,
    ContactSummaryComponent,
    ContactFormComponent,
    ContactModalComponent,

    DepartmentModalComponent,
    DepartmentFormComponent,

    FinancialFormComponent,
    FinancialModalComponent,

    CompanyPickerDialogComponent,
    CompanyPickerComponent,
    CompanyFinancialPickerComponent,

    CompanySnelstartDebtorInformationCardComponent,
    CompanySnelstartDebtorInformationListComponent,
  ],
  exports: [
    AddressTypePipe,
    ContactStatusPipe,
    AddressPostalPipe,

    AddressFormComponent,
    AddressModalComponent,

    CompanyAddressesCardComponent,
    CompanyAddressesListComponent,
    CompanyCardComponent,
    CompanyContactsCardComponent,
    CompanyContactsListComponent,
    CompanyDepartmentsCardComponent,
    CompanyDepartmentsListComponent,
    CompanyFinancialComponent,
    CompanyFinancialsCardComponent,
    CompanyFinancialsListComponent,
    CompanyFinancialSelectComponent,
    CompanyHeaderComponent,
    CompanyInfoComponent,
    CompanyInvoicesCardComponent,
    CompanyInvoicesListComponent,
    CompanyListComponent,
    CompanyTilesComponent,
    CompanySummaryComponent,
    CompanyDetailsHeaderComponent,
    CompanyChildrenListComponent,
    ParentCompanyPickerComponent,
    CompanyFormComponent,

    ContactListComponent,
    ContactTilesComponent,
    ContactSummaryComponent,
    ContactFormComponent,
    ContactModalComponent,

    DepartmentModalComponent,
    DepartmentFormComponent,

    FinancialFormComponent,
    FinancialModalComponent,

    CompanyPickerDialogComponent,
    CompanyPickerComponent,
    CompanyFinancialPickerComponent,

    CompanySnelstartDebtorInformationCardComponent,
    CompanySnelstartDebtorInformationListComponent,
  ],
  imports: [
    CommonModule,
    ContentDistributionServicesModule,
    CoreModule,
    FormsModule,
    LayoutComponentsModule,
    LayoutPipesModule,
    NgbModule,
    NgSelectModule,
    RouterModule,
    SharedModule,
    TaskPipesModule,
    TaskComponentsModule,
    TaskDefinitionModule,
    DateTimePickerComponent,
    AttributeListComponent,
    ReactiveFormsModule,
    SeahorseFormsModule,
    TranslateModule,
    SeahorseUiModule,
    OptInStatusPipe,
    ContentDistributionComponentSharedModule,
  ],
})
export class CompanyComponentsModule {}
