<div class="modal-header align-items-center">
  <h1 class="modal-title">{{ 'shared.terms.editAttribute' | translate }}</h1>
  <button closeModal type="button" class="btn btn-link text-dark">
    <i class="fa-solid fa-times fa-sm fa-fw"></i>
  </button>
</div>
<div class="modal-body">
  <label for="dosForm">
    <b>{{
      'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.model.dosForm'
        | translate
    }}</b>
  </label>
  <div class="row no-gutters my-3">
    <div class="col-12 col-sm-4">
      <div class="custom-control custom-checkbox mr-1">
        <input
          [(ngModel)]="dosForm"
          type="checkbox"
          class="custom-control-input"
          id="dosForm"
        />
        <label
          class="custom-control-label"
          for="dosForm"
          style="overflow: initial"
          >{{
            'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.placeholder.dosForm'
              | translate
          }}</label
        >
      </div>
    </div>
    <div class="col-12 col-sm-8">
      <div class="input-group mt-n2">
        <temp-date-time-picker
          [(dateTime)]="dosFormDate"
          [hideTime]="true"
          [isContained]="true"
        ></temp-date-time-picker>
        <button
          (click)="setNull()"
          type="button"
          class="btn bg-transparent br-0"
          style="margin-left: -40px; z-index: 100"
        >
          <i class="fa-solid fa-times fa-fw"></i>
        </button>
        <div class="input-group-append">
          <button
            class="btn btn-outline-secondary"
            (click)="setNow()"
            type="button"
          >
            <i class="fa-regular fa-stopwatch fa-fw"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button closeModal type="button" class="btn btn-link flex-fill">
    {{ 'shared.terms.cancel' | translate }}
  </button>
  <button
    (click)="onPickDosForm.emit({ dosForm: dosForm, dosFormDate: dosFormDate })"
    closeModal
    type="button"
    class="btn btn-success flex-fill"
  >
    {{ 'shared.terms.confirm' | translate }}
  </button>
</div>
