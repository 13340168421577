<sh-control *ngIf="control" [control]="this">
  <ejs-textbox
    type="password"
    floatLabelType="Always"
    [formControl]="control"
    [placeholder]="placeholder"
    [id]="formControlName"
    [attr.name]="formControlName"
  ></ejs-textbox>
</sh-control>
