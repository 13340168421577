export class NauticalShipModel {
  id?: string;
  name: string;
  status?: NauticalShipStatus;
  shipAttributes?: NauticalShipAttributeModel[];
  shipType?: NauticalShipTypeModel;
  shipTypeCategoryId: number;
  shipTypeId?: number;
  shipTags?: NauticalShipTagModel[];
  baseId?: number;

  // Identity
  callSign?: string;
  flagCode?: string;
  mmsi?: string;
  imo?: string;
  eni?: string;
  fisheryNumber?: string;
  owner?: string = null;

  // Dimensions
  loa?: number;
  width?: number;
  gt?: number;
  dwt?: number;
  maxDraft?: number;

  static validate(ship: NauticalShipModel) {
    if (
      ship.name === undefined ||
      ship.name === null ||
      ship.name.trim() === ''
    ) {
      return false;
    }

    if (ship.callSign && ship.callSign.length > 50) {
      return false;
    }

    if (
      ship.flagCode === undefined ||
      ship.flagCode === null ||
      ship.flagCode.trim() === ''
    ) {
      return false;
    }

    if (ship.mmsi && ship.mmsi.length > 9) {
      return false;
    }

    if (ship.imo && ship.imo.length > 7) {
      return false;
    }

    if (ship.eni && ship.eni.length > 8) {
      return false;
    }

    if (ship.fisheryNumber && ship.fisheryNumber.length > 50) {
      return false;
    }

    if (ship.shipTypeId === undefined || ship.shipTypeId === null) {
      return false;
    }

    return true;
  }
}

export class NauticalShipAttributeModel {
  id: number;
  category: string;
  endsOn?: Date;
  fieldName: string;
  fieldValue: string;
  shipId: string;
  sourceId: number;
  startsOn: Date;

  // UI use only
  fieldNameDisplay?: string;
  categoryDisplay?: string;

  static validate(shipAttribute: NauticalShipAttributeModel) {
    if (shipAttribute.fieldName == null) {
      return false;
    }

    shipAttribute.fieldName = shipAttribute.fieldName.trim();
    if (shipAttribute.fieldName === '') {
      shipAttribute.fieldName = null;
      return false;
    }

    if (shipAttribute.fieldValue == null) {
      return false;
    }

    shipAttribute.fieldValue = shipAttribute.fieldValue.trim();
    if (shipAttribute.fieldValue === '') {
      shipAttribute.fieldValue = null;
      return false;
    }

    if (shipAttribute.category == null) {
      return false;
    }

    shipAttribute.category = shipAttribute.category.trim();
    if (shipAttribute.category === '') {
      shipAttribute.category = null;
      return false;
    }

    return true;
  }
}

export class NauticalShipTypeModel {
  id: number;
  name: string;
  systemCode: string;
  category: string;
  shipTypeCategoryId: number;
}

export class NauticalShipTagModel {
  id: number;
  name: string;
}

export class NauticalShipTypeCategory {
  id: number;
  name: string;
  systemCode: string;
}

export class NauticalShipFlagModel {
  flagCode: string;
  flagName: string;
}

export enum NauticalShipStatus {
  Unverified = 0,
  Active = 1,
  Decommissioned = 2,
  BeingBuilt = 3,
  Deactivated = 99,
  Unknown = 255,
}
