import {
  Input,
  Output,
  OnInit,
  Component,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import * as _ from 'underscore';
import { NotificationService } from '@seahorse/common';
import { TariffModel } from '../../models/tariff.model';
import { TariffGroupModel } from '../../models/tariff-group.model';
import { TariffGroupDataService } from '../../services/tariff-group.data.service';
import { TariffDataService } from '../../services/tariff-data.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TariffPickerFormService } from './tariff-picker-form.component.service';

@Component({
  selector: 'ca-tariff-picker-form',
  templateUrl: './tariff-picker-form.component.html',
  standalone: true,
  imports: [TranslateModule, CommonModule, FormsModule],
  styles: [
    `
      .input-wrapper {
        display: flex;
        align-items: center;
      }
    `,
  ],
})
export class TariffPickerFormComponent implements OnInit {
  @Input() selectedTariff: TariffModel;
  @Input() isRequired: boolean;
  @Input() loadingTariffGroups: boolean;
  @Input() hideTariffGroups: boolean;
  @Input() hideUnapprovedTariffs: boolean;
  @Input() loadingTariffs: boolean;
  @Input() skipPreSelect: boolean;
  @Input() set tariffId(val: number) {
    if (val) {
      this.getTariff(val);
    }
  }

  @Input() showLabels = true;

  @Output() selectedTariffChange!: EventEmitter<TariffModel>;

  private _tariffs: TariffModel[] = [];
  set tariffs(val) {
    this._tariffs = val;

    // options are compared by the reference, so we always need to pull selected tariff from current list
    if (this.selectedTariff) {
      this.selectedTariff = this.tariffs.find(
        (x) => x.id == this.selectedTariff.id
      );
    }
  }
  get tariffs() {
    return this._tariffs;
  }
  submitted = false;
  selectedTariffGroup: TariffGroupModel;
  loadingTariff = false;

  get tariffGroups() {
    return this._formService.tariffGroups;
  }

  set tariffGroups(val) {
    this._formService.tariffGroups = val;
  }

  constructor(
    private translate: TranslateService,
    private notification: NotificationService,
    private tariffData: TariffDataService,
    private tariffGroupData: TariffGroupDataService,
    private _formService: TariffPickerFormService,
    private _cdr: ChangeDetectorRef
  ) {
    this.selectedTariff = null;
    this.isRequired = false;
    this.loadingTariffGroups = false;
    this.hideTariffGroups = false;
    this.hideUnapprovedTariffs = true;
    this.selectedTariffGroup = null;
    this.loadingTariffs = false;
    this.skipPreSelect = false;
    this.selectedTariffChange = new EventEmitter<TariffModel>();
    this.selectedTariffGroup = null;
  }

  ngOnInit() {
    if (this.tariffGroups.length == 1) {
      this.selectedTariffGroup = this.tariffGroups[0];

      if (!this.tariffs.length && !this.loadingTariffs) {
        this.loadTariffs();
      }
    } else if (
      (!this.tariffGroups.length || this.tariffGroups.length === 0) &&
      !this.loadingTariffGroups
    ) {
      this.loadingTariffGroups = true;

      this.tariffGroupData.getTariffGroups(-1, -1).subscribe(
        (r) => {
          if (r.hasResult) {
            this.tariffGroups = r.result;

            if (this.selectedTariff && this.selectedTariff.tariffGroupId) {
              this.selectedTariffGroup = _.find(
                r.result,
                (x) => x.id == this.selectedTariff.tariffGroupId
              );
            } else if (!this.skipPreSelect && r.result.length == 1) {
              this.selectedTariffGroup = r.result[0];
            }
          } else {
            this.notification.showError(
              _.pluck(r.messages, 'message').join('\n'),
              this.translate.instant('shared.terms.failed')
            );
          }
        },

        (e) => {
          this.notification.showError(
            _.pluck(e.error.messages, 'message').join('\n'),
            this.translate.instant('shared.terms.failed')
          );
        },

        () => {
          this.loadingTariffGroups = false;

          if (!this.tariffs.length && !this.loadingTariffs) {
            this.loadTariffs();
          }
        }
      );
    } else if (this.selectedTariff && this.selectedTariff.tariffGroupId) {
      this.selectedTariffGroup = _.find(
        this.tariffGroups,
        (x) => x.id == this.selectedTariff.tariffGroupId
      );

      if (
        this.selectedTariffGroup &&
        !this.tariffs.length &&
        !this.loadingTariffs
      ) {
        this.loadTariffs();
      }
    }
  }

  private getTariff(id: number) {
    this.loadingTariff = true;

    this.tariffData.getById(id).subscribe(
      (r) => {
        if (r.result) {
          if (this.tariffs.length) {
            this.selectedTariff = this.tariffs.find((x) => x.id == r.result.id);
          } else {
            this.selectedTariff = r.result;
          }

          if (!this.selectedTariffGroup) {
            this.selectedTariffGroup = _.find(
              this.tariffGroups,
              (x) => x.id == this.selectedTariff.tariffGroupId
            );
          }

          if (
            this.selectedTariffGroup &&
            !this.tariffs.length &&
            !this.loadingTariffs
          ) {
            this.loadTariffs();
          }

          this._cdr.markForCheck();
        } else {
          this.notification.showError(
            _.pluck(r.messages, 'message').join('\n'),
            this.translate.instant('shared.terms.failed')
          );
        }
      },

      (e) => {
        this.notification.showError(
          _.pluck(e.error.messages, 'message').join('\n'),
          this.translate.instant('shared.terms.failed')
        );
      },

      () => (this.loadingTariff = false)
    );
  }

  loadTariffs() {
    if (this.selectedTariffGroup && this.selectedTariffGroup.id) {
      if (
        _.contains(
          _.pluck(this._formService.selectedTariffGroups, 'id'),
          this.selectedTariffGroup.id
        )
      ) {
        this.tariffs = _.where(this._formService.selectedTariffGroups, {
          id: this.selectedTariffGroup.id,
        })[0].tariffs;

        if (
          !this.selectedTariff &&
          !this.skipPreSelect &&
          this.tariffs.length == 1
        ) {
          this.selectedTariff = this.tariffs[0];

          this.selectedTariffChange.emit(this.tariffs[0]);
        }
      } else {
        this.loadingTariffs = true;

        this.tariffData
          .getTariffsByGroupId(this.selectedTariffGroup.id, -1, -1)
          .subscribe(
            (r) => {
              if (r.hasResult) {
                this.tariffs = r.result;

                this._formService.selectedTariffGroups.push({
                  id: this.selectedTariffGroup.id,
                  tariffs: r.result,
                });

                if (
                  !this.selectedTariff &&
                  !this.skipPreSelect &&
                  r.result.length == 1
                ) {
                  this.selectedTariff = r.result[0];

                  this.selectedTariffChange.emit(r.result[0]);
                }

                this._cdr.markForCheck();
              } else {
                this.notification.showError(
                  _.pluck(r.messages, 'message').join('\n'),
                  this.translate.instant('shared.terms.failed')
                );
              }
            },

            (e) => {
              this.notification.showError(
                _.pluck(e.error.messages, 'message').join('\n'),
                this.translate.instant('shared.terms.failed')
              );
            },

            () => {
              if (this.selectedTariff) {
                const id = _.clone(this.selectedTariff).id;

                this.selectedTariff = _.find(this.tariffs, { id: id });
              }

              this.loadingTariffs = false;
            }
          );
      }
    } else {
      this.tariffs = [];

      this.selectedTariffChange.emit(null);
    }

    this._cdr.markForCheck();
  }

  onSelectedTariffGroupChanged() {
    this.selectedTariffChange.emit(null);

    if (this.selectedTariffGroup) {
      this.loadTariffs();
    }
  }

  onSelectedTariffChanged() {
    this.selectedTariffChange.emit(this.selectedTariff);
  }

  validate() {
    this.submitted = true;

    if (this.selectedTariffGroup !== null && this.selectedTariff !== null) {
      return true;
    } else {
      return false;
    }
  }
}
