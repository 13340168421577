<table class="table table-clickable">
  <tbody>
    <tr
      *ngFor="let invoice of invoices"
      (click)="onEvent.emit({ action: 'selectInvoice', data: invoice })"
    >
      <td [ngSwitch]="invoice.status" width="1%">
        <i *ngSwitchCase="1" class="fa-regular fa-cogs fa-fw text-primary"></i>
        <i
          *ngSwitchCase="2"
          class="fa-regular fa-exclamation-triangle fa-fw text-warning"
        ></i>
        <i *ngSwitchCase="3" class="fa-regular fa-paper-plane fa-fw text-primary"></i>
        <i
          *ngSwitchCase="4"
          class="fa-regular fa-exclamation-triangle fa-fw text-warning"
        ></i>
        <i *ngSwitchCase="5" class="fa-regular fa-euro-sign fa-fw text-success"></i>
        <i
          *ngSwitchCase="6"
          class="fa-regular fa-exclamation-circle fa-fw text-danger"
        ></i>
      </td>
      <td style="width: 1%; min-width: 110px">
        {{ invoice.invoiceDate | date: 'dd-MM-yyyy' }}
      </td>
      <td>
        {{ invoice.description }}
      </td>
      <td style="width: 1%" style="white-space: nowrap">
        &euro;
        {{ invoice.totalAmount | number: '1.2-2' }}
      </td>
    </tr>
  </tbody>
</table>
