import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'invoiceFlowExecutionStatus',
})
export class InvoiceFlowExecutionStatusPipe implements PipeTransform {
  transform(value: number): string {
    switch (value) {
      case 1:
        return 'new';
      case 2:
        return 'executing';
      case 3:
        return 'completed';
      case 4:
        return 'error';
      case 5:
        return 'pending approval';
      default:
        return 'unknown';
    }
  }
}
