<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title-bold">{{ 'uiModules.batchUpdate' | translate }}</h4>
    <button type="button" class="close" (click)="closeInnerModal($event)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ng-container *ngIf="singleFieldMode">
      <div class="mb-2">
        <label for="field">
          <b>{{ 'shared.terms.field' | translate }}</b>
        </label>
        <ng-select
          [items]="objectDefinition.objectFieldDefinitions"
          bindLabel="name"
          [clearable]="false"
          (change)="onFieldSelected($event)"
          placeholder="{{ 'uiModules.batchUpdate.select' | translate }}"
        >
        </ng-select>
      </div>
      <div *ngIf="formModel">
        <label for="field">
          <b>{{ 'shared.terms.newValue' | translate }}</b>
        </label>
        <temp-form class="no-labels" [model]="formModel"></temp-form>
      </div>
    </ng-container>
    <ng-containter *ngIf="!singleFieldMode">
      <temp-form [model]="formModel"></temp-form>
    </ng-containter>
  </div>
  <div class="modal-footer d-flex">
    <div class="flex-grow-1">
      <button
        (click)="closeInnerModal($event)"
        type="button"
        class="btn btn-block btn-link"
      >
        {{ 'shared.terms.cancel' | translate }}
      </button>
    </div>
    <div class="flex-grow-1">
      <button type="button" class="btn btn-block btn-success" (click)="save()">
        <ng-container *ngIf="!isSaving">{{
          'shared.terms.save' | translate
        }}</ng-container>
        <i class="fa-regular fa-spinner fa-spin fa-fw" *ngIf="isSaving"></i>
      </button>
    </div>
  </div>
</div>
