import { NauticalPortModel } from '../../nautical-geography/models/nautical-port.model';
import { NauticalVisitBaseModel } from './nautical-visit-base.model';

export class NauticalVisitSortModel {
  name: string;
  datetime?: Date;
}

export class NauticalVisitOverviewModel extends NauticalVisitBaseModel {
  nextPort: NauticalPortModel = null;
  previousPort: NauticalPortModel = null;

  sort?: NauticalVisitSortModel = null;

  constructor() {
    super();
  }
}
