export class ContentMappingModel {
  id: number;
  item1: ContentMapObjectModel;
  item2: ContentMapObjectModel;
  objectRelationship: string;

  constructor() {
    this.id = null;
    this.item1 = new ContentMapObjectModel();
    this.item2 = new ContentMapObjectModel();
    this.objectRelationship = null;
  }
}

export class ContentMapObjectModel {
  key: string;
  value: string;

  constructor() {
    this.key = null;
    this.value = null;
  }
}
