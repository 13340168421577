import { Injectable, inject } from '@angular/core';
import { DataContextService, ResultWrapper } from '@seahorse/common';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FeatureReleaseDataService {
  private _dataContextService = inject(DataContextService);
  
  getAll(): Observable<ResultWrapper<string[]>> {
    return this._dataContextService.get<string[]>('feature-release');
  }
}