<div class="page-with-header page-with-master-detail">
  <ca-overview-header
    [selectedView]="selectedView"
    [showNewButton]="true"
  ></ca-overview-header>
  <div class="container-fluid py-3">
    <div class="row">
      <div
        class="page-master-wrapper"
        [ngClass]="{ 'page-master-detail-visible': selectedSystemMessage && selectedSystemMessage.id }"
      >
        <div class="page-master-wrapper-inner">
          <div
            class="text-muted text-center p-4"
            *ngIf="isInitial && systemMessages.length === 0"
          >
            <i class="fa-duotone fa-boxes fa-10x mb-4"></i>
            <div class="text-lg">
              <p>{{'shared.terms.pageEmpty' | translate}}</p>
              <p>{{'shared.terms.search' | translate}}</p>
            </div>
          </div>
          <div
            class="card"
            *ngIf="!isInitial && !isLoading && (!systemMessages || systemMessages.length === 0)"
          >
            <div class="card-body">
              <div class="text-muted">
                {{'systemMessage.noSystemMessages' | translate}}
              </div>
            </div>
          </div>
          <div
            class="card"
            *ngIf="systemMessages && systemMessages.length > 0 && selectedView === collectionDisplay.List"
          >
            <ca-system-message-list
              [systemMessages]="systemMessages"
              (systemMessageSelected)="selectSystemMessage($event)"
            >
            </ca-system-message-list>
          </div>
          <div
            class="tile-container"
            *ngIf="systemMessages && systemMessages.length > 0 && selectedView === collectionDisplay.Tiles"
          >
            <ca-system-message-tiles
              [systemMessages]="systemMessages"
              (systemMessageSelected)="selectSystemMessage($event)"
            >
            </ca-system-message-tiles>
          </div>
          <sh-loading-indicator
            class="text-center d-block"
            *ngIf="isLoading"
          ></sh-loading-indicator>
          <button
            class="btn btn-primary btn-block btn-lg my-3"
            [disabled]="isLoading"
            *ngIf="canLoadMore"
            (click)="loadMore()"
          >
            {{'shared.terms.loadMore' | translate}}
          </button>
        </div>
      </div>
      <div
        class="page-detail-wrapper"
        *ngIf="selectedSystemMessage && selectedSystemMessage.id"
      >
        <div class="card">
          <div class="card-header">
            <span class="text-uppercase"
              >{{selectedSystemMessage.subject}}</span
            >
            <div class="float-right">
              <button
                type="button"
                class="btn btn-clear"
                (click)="selectSystemMessage(null)"
              >
                <i class="fa-solid fa-times fa-fw"></i>
              </button>
            </div>
          </div>
          <router-outlet name="summary"></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>
