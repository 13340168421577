import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  forwardRef,
  Input,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { SeahorseTemplateDirective } from '@seahorse/common';
import { BaseControl } from '../base.control';
import { DropDownListOption } from './drop-down-list.model';

@Component({
  selector: 'sh-drop-down-list',
  templateUrl: './drop-down-list.control.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropDownListControl),
      multi: true,
    },
    {
      provide: BaseControl,
      useExisting: forwardRef(() => DropDownListControl),
    },
  ],
  styles: [
    `
      .e-list-icon {
        margin-right: 0.5rem;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropDownListControl extends BaseControl<string | number | null> {
  @ContentChild(SeahorseTemplateDirective) template?: SeahorseTemplateDirective;
  @Input() options: DropDownListOption[] = [];

  fieldMapping = { text: 'label', value: 'value', iconCss: 'icon' };

  get templateRef() {
    return this.template?.templateRef;
  }
}
