export type KeysOfType<T, TProp> = {
  [P in keyof T]: T[P] extends TProp ? P : never;
}[keyof T];

export class KeyValuePair<T, U> {
  key: T;
  value: U;

  constructor(key, value) {
    this.key = key;
    this.value = value;
  }
}

export class GroupOption<T, U, V> {
  key: T;
  value: U;
  groupKeys: V;

  constructor(key, value, groupKeys) {
    this.key = key;
    this.value = value;
    this.groupKeys = groupKeys;
  }
}
