import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

export interface ModalOptions {
  closeWithEscape?: boolean;
  size?: 'sm' | 'lg' | 'xl';
  isTable?: boolean;
  hideActions?: boolean;
  deleteAction?: boolean;
}

export interface ModalResponse<TComponent = any> {
  action: ModalAction;
  componentRef?: TComponent;
  modalRef?: NgbModalRef;
}

export enum ModalAction {
  Close,
  Confirm,
  Delete,
}
