import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CompanyComponentsModule } from '../../../../company/components/company-components.module';
import { LayoutComponentsModule } from '../../../../layout/components/layout-components.module';
import { SharedModule } from '../../../../shared/shared.module';

import { InvoicePipesModule } from '../../invoicing/pipes/invoice-pipes.module';
import { InvoicingFlowsPipesModule } from '../pipes/invoicing-flows-pipes.module';
import { CalculationsInfoComponent } from './calculations/calculations-info/calculations-info.component';
import { CalculationsModalComponent } from './calculations/calculations-modal/calculations-modal.component';
import { ConditionsInfoComponent } from './conditions/conditions-info/conditions-info.component';
import { ConditionsModalComponent } from './conditions/conditions-modal/conditions-modal.component';
import { DependenciesInfoComponent } from './dependencies/dependencies-info/dependencies-info.component';
import { DependenciesModalComponent } from './dependencies/dependencies-modal/dependencies-modal.component';
import { ExecutionStartModalComponent } from './executions/execution-start-modal/execution-start-modal.component';
import { ExecutionsCardComponent } from './executions/executions-card/executions-card.component';
import { FlowCreatorHeaderComponent } from './flow-creator-header/flow-creator-header.component';
import { FlowDetailsInfoComponent } from './flow-details/flow-details-info/flow-details-info.component';
import { FlowDetailsModalComponent } from './flow-details/flow-details-modal/flow-details-modal.component';
import { FlowDetailsStagesComponent } from './flow-details/flow-details-stages/flow-details-stages.component';
import { FlowFormComponent } from './flow-form/flow-form.component';
import { InvoicingFlowsHeaderComponent } from './invoicing-flows/invoicing-flows-header/invoicing-flows-header.component';
import { InvoicingFlowsListComponent } from './invoicing-flows/invoicing-flows-list/invoicing-flows-list.component';
import { InvoicingFlowsSummaryComponent } from './invoicing-flows/invoicing-flows-summary/invoicing-flows-summary.component';
import { InvoicingFlowsTilesComponent } from './invoicing-flows/invoicing-flows-tiles/invoicing-flows-tiles.component';
import { StageCreatorFormComponent } from './stage-creator/stage-creator-form/stage-creator-form.component';
import { StageCreatorHeaderComponent } from './stage-creator/stage-creator-header/stage-creator-header.component';
import { StageDetailsFormComponent } from './stage-details/stage-details-form/stage-details-form.component';
import { StageDetailsHeaderComponent } from './stage-details/stage-details-header/stage-details-header.component';
import { StageDetailsInfoComponent } from './stage-details/stage-details-info/stage-details-info.component';
import { StageDetailsModalComponent } from './stage-details/stage-details-modal/stage-details-modal.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { ExecutionStartGenericModalComponent } from './executions/execution-start-generic-modal/execution-start-generic-modal.component';
import { DateTimePickerComponent } from '@seahorse/temp';

@NgModule({
  declarations: [
    FlowFormComponent,
    StatisticsComponent,
    ConditionsInfoComponent,
    ConditionsModalComponent,
    CalculationsInfoComponent,
    CalculationsModalComponent,
    DependenciesInfoComponent,
    DependenciesModalComponent,
    ExecutionsCardComponent,
    ExecutionStartGenericModalComponent,
    ExecutionStartModalComponent,
    InvoicingFlowsHeaderComponent,
    InvoicingFlowsListComponent,
    InvoicingFlowsTilesComponent,
    InvoicingFlowsSummaryComponent,
    FlowCreatorHeaderComponent,
    FlowDetailsInfoComponent,
    FlowDetailsStagesComponent,
    FlowDetailsModalComponent,
    StageCreatorHeaderComponent,
    StageCreatorFormComponent,
    StageDetailsHeaderComponent,
    StageDetailsInfoComponent,
    StageDetailsModalComponent,
    StageDetailsFormComponent,
  ],
  exports: [
    FlowFormComponent,
    StatisticsComponent,
    ConditionsInfoComponent,
    CalculationsInfoComponent,
    DependenciesInfoComponent,
    ExecutionsCardComponent,
    ExecutionStartGenericModalComponent,
    ExecutionStartModalComponent,
    InvoicingFlowsHeaderComponent,
    InvoicingFlowsListComponent,
    InvoicingFlowsTilesComponent,
    InvoicingFlowsSummaryComponent,
    FlowCreatorHeaderComponent,
    FlowDetailsInfoComponent,
    FlowDetailsStagesComponent,
    StageCreatorHeaderComponent,
    StageCreatorFormComponent,
    StageDetailsHeaderComponent,
    StageDetailsInfoComponent,
    StageDetailsFormComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    NgbModule,
    CompanyComponentsModule,
    LayoutComponentsModule,
    InvoicingFlowsPipesModule,
    InvoicePipesModule,
    DateTimePickerComponent
  ],
})
export class InvoicingFlowsComponentsModule {}
