<table class="table table-clickable table-truncated">
  <thead>
    <tr>
      <th>{{ 'systemMessage.subject' | translate }}</th>
      <th style="width: 100px">{{ 'systemMessage.version' | translate }}</th>
      <th>{{ 'systemMessage.notes' | translate }}</th>
      <th style="width: 150px">{{ 'systemMessage.createdOn' | translate }}</th>
    </tr>
  </thead>
  <tbody>
    <tr
      *ngFor="let message of systemMessages"
      (click)="selectSystemMessage(message)"
      [ngClass]="{
        selected:
          selectedSystemMessage && selectedSystemMessage.id === message.id
      }"
    >
      <td>{{ message.subject }}</td>
      <td>{{ message.version }}</td>
      <td>{{ message.notes }}</td>
      <td>{{ message.createdOn | utcToLocalDatePipe }}</td>
    </tr>
  </tbody>
</table>
