import { Pipe, PipeTransform } from '@angular/core';
import { CustomMemberModel } from '../models/custom-member.model';

@Pipe({ name: 'searchMembers' })
export class CustomSearchMembersPipe implements PipeTransform {
  transform(memberModel: CustomMemberModel[], searchField: string): any[] {
    if (!memberModel) {
      return [];
    }

    if (!searchField) {
      return memberModel;
    }

    searchField = searchField.toLocaleLowerCase();

    return memberModel.filter((member) => {
      return (
        (member.$usermanager_user_givenName &&
          member.$usermanager_user_givenName
            .toLocaleLowerCase()
            .includes(searchField)) ||
        (member.$usermanager_user_surname &&
          member.$usermanager_user_surname
            .toLocaleLowerCase()
            .includes(searchField))
      );
    });
  }
}
