<div class="modal-header align-items-center">
  <h1 class="modal-title">{{ 'shared.terms.editAttribute' | translate }}</h1>
  <button (click)="dismiss()" type="button" class="btn btn-link text-dark">
    <i class="fa-solid fa-times fa-sm fa-fw"></i>
  </button>
</div>
<div class="modal-body">
  <ng-container *ngFor="let field of movementDefinition.objectFieldDefinitions">
    <div class="row" *ngIf="field.groupName === 'Visit reasons'">
      <div class="col-5">
        <div class="form-group">
          <div class="custom-control custom-checkbox mb-3">
            <input
              [(ngModel)]="movement[field.systemCode]"
              id="{{ 'chk_' + field.systemCode }}"
              type="checkbox"
              class="custom-control-input"
            />
            <label
              class="custom-control-label"
              for="{{ 'chk_' + field.systemCode }}"
            >
              {{ field.name }}
            </label>
          </div>
        </div>
      </div>
      <ng-container
        *ngIf="
          movement[field.systemCode] === true &&
          field.systemCode === 'containers'
        "
      >
        <div class="col-3 px-1">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" title="Geladen"
                ><i class="fa-regular fa-download fa-fw"></i
              ></span>
            </div>
            <input
              type="text"
              class="form-control"
              type="number"
              [(ngModel)]="movement['containers_loaded']"
            />
          </div>
        </div>
        <div class="col-3 px-1">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" title="Gelost"
                ><i class="fa-regular fa-upload fa-fw"></i
              ></span>
            </div>
            <input
              type="text"
              class="form-control"
              type="number"
              [(ngModel)]="movement['containers_unloaded']"
            />
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
<div class="modal-footer">
  <button type="button" (click)="dismiss()" class="btn btn-link btn-block w-50">
    {{ 'shared.terms.cancel' | translate }}
  </button>
  <button type="button" (click)="ok()" class="btn btn-success btn-block w-50">
    {{ 'shared.terms.confirm' | translate }}
  </button>
</div>
