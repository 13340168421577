import {
  Component,
  EmbeddedViewRef,
  OnDestroy,
  OnInit,
  Type,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { PreferenceDataService } from '../../../preference/services/preference-data.service';
import { DynamicsService } from '@seahorse/common';
import { PreferenceModel } from '../../../preference/models/preference.model';
import {
  DashboardLayoutComponent,
  PanelModel,
} from '@syncfusion/ej2-angular-layouts';
import { WidgetDataService } from '../../../widgets/services/widget-data.service';
import { InvoiceWidgetComponent } from '../../../widgets/components/invoice-widget/invoice-widget.component';
import { WidgetModel, WidgetTypes } from '../../../widgets/models/widget.model';
import { Subscription } from 'rxjs';
import { TaskWidgetComponent } from '../../../widgets/components/task-widget/task-widget.component';

@Component({
  selector: 'ca-custom-dashboard',
  templateUrl: './custom-dashboard.component.html',
  styleUrls: ['./custom-dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomDashboardComponent implements OnInit, OnDestroy {
  @ViewChild('dashboard')
  dashboard: DashboardLayoutComponent;
  cellSpacing: number[] = [20, 20];

  dashboardPreference: PreferenceModel = null;
  hasWidgets = false;

  enableEditing = false;

  showLibrary = false;
  widgetsOnDashboardIds: string[] = [];

  private subscriptions = new Subscription();

  constructor(
    private _preferenceDataService: PreferenceDataService,
    private _widgetDataService: WidgetDataService,
    private _dynamicsService: DynamicsService
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this._preferenceDataService
        .getDashboardPreference(false)
        .subscribe((result) => {
          this.dashboardPreference = result.result;
          if (result.result.fieldValue) {
            this.hasWidgets = true;
            JSON.parse(result.result.fieldValue).forEach((x) => {
              this.widgetsOnDashboardIds.push(x.id.toString());
              this.dashboard.addPanel({
                id: x.id,
                sizeX: x.sizeX,
                sizeY: x.sizeY,
                row: x.row,
                col: x.col,
              });

              this.subscriptions.add(
                this._widgetDataService.getById(x.id).subscribe((res) => {
                  if (res.hasResult) {
                    this.renderWidget(res.result);
                  } else {
                    this.removeWidget(x.id);
                  }
                })
              );
            });
          }
        })
    );
  }

  edit(): void {
    this.enableEditing = true;
    const closeButtons = document.querySelectorAll('.e-close-icon');
    closeButtons.forEach((closeButton) => {
      closeButton.classList.remove('hidden');
    });
  }

  resetToCompany() {
    this.subscriptions.add(
      this._preferenceDataService
        .getDashboardPreference(true)
        .subscribe((result) => {
          this.dashboardPreference = result.result;
          this.hasWidgets = false;
          if (result.result.fieldValue) {
            this.hasWidgets = true;
            this.dashboard.removeAll();
            this.widgetsOnDashboardIds = [];
            JSON.parse(result.result.fieldValue).forEach((x) => {
              this.widgetsOnDashboardIds.push(x.id.toString());
              this.dashboard.addPanel({
                id: x.id,
                sizeX: x.sizeX,
                sizeY: x.sizeY,
                row: x.row,
                col: x.col,
              });

              this.subscriptions.add(
                this._widgetDataService.getById(x.id).subscribe((res) => {
                  this.renderWidget(res.result);
                })
              );
            });

            this.subscriptions.add(
              this._preferenceDataService
                .save(this.dashboardPreference)
                .subscribe()
            );
          }
        })
    );
  }

  save() {
    const closeButtons = document.querySelectorAll('.e-close-icon');
    closeButtons.forEach((closeButton) => {
      closeButton.classList.add('hidden');
    });

    if (this.dashboard.panels?.length) {
      this.dashboardPreference.fieldValue = [];
      this.dashboard.panels.forEach((panel: PanelModel) => {
        this.dashboardPreference.fieldValue.push({
          id: panel.id.toString(),
          sizeX: panel.sizeX,
          sizeY: panel.sizeY,
          row: panel.row,
          col: panel.col,
          enabled: panel.enabled,
          minSizeX: panel.minSizeX,
          minSizeY: panel.minSizeY,
          maxSizeX: panel.maxSizeX,
          maxSizeY: panel.maxSizeY,
        });
      });

      this.dashboardPreference.fieldValue = JSON.stringify(
        this.dashboardPreference.fieldValue
      );
    } else {
      this.dashboardPreference.fieldValue = null;
    }

    this.dashboardPreference.isCompanyPreference = false;
    this.subscriptions.add(
      this._preferenceDataService.save(this.dashboardPreference).subscribe()
    );

    this.enableEditing = false;
    this.showLibrary = false;
  }

  addWidget(widget: WidgetModel) {
    let panel: PanelModel = {
      id: widget.id.toString(),
      sizeX: 1,
      sizeY: 1,
      row: 0,
      col: 0,
    };

    if (widget.configuration['panel']) {
      panel = { ...panel, ...widget.configuration['panel'] };
    }

    this.dashboard.addPanel(panel);

    this.widgetsOnDashboardIds.push(widget.id.toString());

    this.renderWidget(widget, true);

    this.hasWidgets = true;
  }

  removeWidget(id: string) {
    this.dashboard.removePanel(id);

    this.widgetsOnDashboardIds = this.widgetsOnDashboardIds.filter(
      (x) => x !== id
    );
  }

  cancel() {
    this.enableEditing = false;
    this.showLibrary = false;

    this.subscriptions.add(
      this._preferenceDataService
        .getDashboardPreference(false)
        .subscribe((result) => {
          this.dashboardPreference = { ...result.result };
          if (result.result.fieldValue) {
            this.dashboard.removeAll();
            this.widgetsOnDashboardIds = [];
            JSON.parse(result.result.fieldValue).forEach((x) => {
              this.widgetsOnDashboardIds.push(x.id.toString());
              this.dashboard.addPanel({
                id: x.id,
                sizeX: x.sizeX,
                sizeY: x.sizeY,
                row: x.row,
                col: x.col,
              });

              this.subscriptions.add(
                this._widgetDataService.getById(x.id).subscribe((res) => {
                  this.renderWidget(res.result);
                })
              );
            });
          }
        })
    );
  }

  renderWidget(widget: WidgetModel, fromLibrary: boolean = false) {
    let component: Type<unknown>;
    let props: object = {
      widget: fromLibrary
        ? widget.configuration
        : JSON.parse(widget.configuration),
    };
    switch (widget.type) {
      case WidgetTypes.Invoice:
        component = InvoiceWidgetComponent;
        props = { ...props, ...{ noBorder: true } };
        break;
      case WidgetTypes.Task:
        component = TaskWidgetComponent;
        break;
    }
    const componentRef = this._dynamicsService.createComponent({
      component: component,
      props: props,
    });

    const panelContent = document.getElementById(widget.id + '_content');
    panelContent.appendChild(
      (componentRef.hostView as EmbeddedViewRef<any>)
        .rootNodes[0] as HTMLElement
    );

    const closeButton = document.createElement('span');
    closeButton.id = 'close';
    closeButton.classList.add('e-template-icon', 'e-close-icon', 'hidden');
    closeButton.addEventListener('click', () =>
      this.removeWidget(widget.id.toString())
    );

    panelContent.appendChild(closeButton);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
