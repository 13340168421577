import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { IdentityService } from '../../../../../../core/services/identity.service';
import {
  CustomDataContentService,
  FlowModel,
  SearchOperators,
} from '@seahorse/domain';
import { CVVActivityLogicService } from '../../../../../../custom-ui/cvv/services/cvv-activity-logic.service';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'underscore';

import {
  ExecutionModel,
  InvoiceFlowExecutionDataModel,
} from '../../../models/execution.model';
import { ExecutionDataService } from '../../../services/execution-data.service';
import { FlowDataService } from '../../../services/flow-data.service';

@Component({
  selector: 'ca-execution-start-modal',
  templateUrl: 'execution-start-modal.component.html',
})
export class ExecutionStartModalComponent implements OnInit {
  @Input() flow: FlowModel = null;
  @Input() searchTerm: string = null;

  companyId: number = null;
  companyFinancialId: number = null;
  customData: any[] = [];
  isLoading = false;
  flows: any[] = [];
  showFlowSelector = false;

  constructor(
    private activeModal: NgbActiveModal,
    private customContentService: CustomDataContentService,
    private cvvActivityLogic: CVVActivityLogicService,
    private identityService: IdentityService,
    private invoiceExecutionData: ExecutionDataService,
    private invoiceFlowData: FlowDataService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}
  ngOnInit(): void {
    if (this.searchTerm === null) {
      this.searchTerm = 'NLAMS' + moment().year().toString().substring(2);
    } else {
      this.search();
    }

    this.showFlowSelector = this.flow === null;

    if (this.showFlowSelector) {
      this.loadFlows();
    }
  }

  dismiss() {
    this.activeModal.dismiss(false);
  }

  loadFlows() {
    this.invoiceFlowData.getActive().subscribe((flowResult) => {
      if (flowResult.hasResult) {
        this.flows = flowResult.result;
        if (this.flows !== null && this.flows.length > 0) {
          this.flow = this.flows[0];
        }
      }
    });
  }

  ok() {
    const selectedItems = _.filter(this.customData, (item) => item.isSelected);
    if (selectedItems === null || selectedItems.length === 0) {
      return;
    }
    if (this.companyFinancialId === null || this.companyFinancialId === -1) {
      return;
    }

    const sourceData: any = {
      Activities: selectedItems,
      Ship: {
        imo: selectedItems[0].__AdditionalData.$nautical_ship_id.IMO,
        loa: parseInt(
          selectedItems[0].__AdditionalData.$nautical_ship_id.LOA,
          null
        ),
        name: selectedItems[0].__AdditionalData.$nautical_ship_id.Name.toUpperCase(),
      },
      Visit: {
        referenceNumber: selectedItems[0].$nautical_portvisit_referencenumber,
      },
    };

    const executionData = new InvoiceFlowExecutionDataModel();
    executionData.sourceData = JSON.stringify(sourceData);

    const executionModel: ExecutionModel = {
      companyFinancialId: this.companyFinancialId,
      companyId: this.companyId,
      id: -1,
      invoiceFlowId: this.flow.id,
      invoiceType: 2,
      referenceNumber: this.flow.invoiceNumberTemplate,
      startedBySource: 'SHIPM8_UI',
      startedByReference: this.identityService.identity.id.toString(),
      status: 1,
      invoiceFlowExecutionData: executionData,
    };

    this.invoiceExecutionData
      .startExecution(this.flow.id, executionModel)
      .subscribe((executionResult) => {
        this.toastr.success(this.translate.instant('shared.terms.addSuccess'));
        this.activeModal.close();
      });
  }

  search() {
    this.isLoading = true;

    this.customContentService
      .searchCustomerDataByFields('activity', [
        {
          fieldName: '$nautical_portvisit_referenceNumber',
          searchOperator: SearchOperators.Equals,
          searchValue: this.searchTerm,
          // }, {
          //     fieldName: 'activityState',
          //     searchOperator: SearchOperators.NotEquals,
          //     searchValue: 'invoiced'
        },
      ])
      .subscribe(
        (result) => {
          this.isLoading = false;
          this.customData = _.map(result.result, (item) => {
            item.isSelected = false;

            for (const activityType in this.cvvActivityLogic.cvvActivityTypes) {
              if (
                this.cvvActivityLogic.cvvActivityTypes[activityType].id ===
                item.activityType
              ) {
                item.activityTypeUIDisplay =
                  this.cvvActivityLogic.cvvActivityTypes[activityType].name;
              }
            }
            if (
              (_.find(this.cvvActivityLogic.movementTypes),
              (f) => f.id === item.movementType)
            ) {
              item.movementTypeUIDisplay = _.find(
                this.cvvActivityLogic.movementTypes
              ).name;
            }
            return item;
          });
        },
        (error) => {
          this.isLoading = false;
        }
      );
  }
}
