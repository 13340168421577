import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  FileHelpers,
  FileSizePipe,
  NotificationService,
  ResultWrapper,
} from '@seahorse/common';
import {
  CustomDataBaseModel,
  FieldDefinitionModel,
  FileModel,
  FileUpdateModel,
  FileService,
} from '@seahorse/domain';
import { AreYouSureModalComponent } from '@seahorse/ui';
import * as JSZip from 'jszip';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'underscore';
import { FileDragDropDirective } from '../file-drag-drop.directive';
import { BaseFileListCardService } from './files-list-card.service';

@Component({
  selector: 'temp-file-list-card',
  templateUrl: 'file-list-card.component.html',
  styles: [
    `
      .bg-inbox {
        background-color: antiquewhite;
      }
    `,
  ],
  standalone: true,
  imports: [CommonModule, TranslateModule, FileDragDropDirective, FileSizePipe],
})
export class FileListCardComponent implements OnInit, OnDestroy {
  @Input() title?: string;
  @Input() archive?: string;
  @Input() archiveLocally?: boolean;
  @Input() objectType: string;

  private _objectId: string;
  @Input() set objectId(val: string) {
    this._objectId = val;
    this.loadFiles();
  }
  get objectId(): string {
    return this._objectId;
  }
  @Input() object: CustomDataBaseModel;
  @Input() isFileDroppedFromPage?: boolean;
  @Input() objectFieldDefinitions?: FieldDefinitionModel[];
  @Input() linkedObjectsIds?: any;
  @Input() readonly = false;
  @Input() inbox = false;

  private destroy$ = new Subject();

  files: FileModel[];
  matchingLinkedObjects?: any = [];

  loading: boolean;

  private _subscription = new Subscription();

  constructor(
    private translate: TranslateService,
    private notification: NotificationService,
    private filesData: FileService,
    private _modalService: NgbModal,
    public componentService: BaseFileListCardService
  ) {
    this.title = 'files.fileListCard.title';
    this.archive = null;
    this.archiveLocally = false;
    this.objectType = null;
    this.objectId = null;
    this.files = [];
    this.loading = false;
    this.isFileDroppedFromPage = false;

    this.filesData.objectFiles$
      .pipe(takeUntil(this.destroy$))
      .subscribe((filesData) => {
        if (filesData.length !== 0) {
          componentService.filesAdd(this, filesData);
        }
      });

    componentService.reload$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.loadFiles();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this._subscription.unsubscribe();
  }

  ngOnInit() {
    this.loadFiles();
  }

  private loadFiles() {
    if (this.loading) return;

    if (this.objectType && this.objectId) {
      this.loading = true;

      this._subscription.add(
        this.filesData
          .getFiles(this.objectId, this.objectType, 0, 1000)
          .subscribe(
            (r: ResultWrapper<FileModel[]>) => {
              if (r.hasResult) {
                this.files = r.result;
                this.componentService.setMatchingLinkedObjects(
                  this.files,
                  this.linkedObjectsIds
                );

                if (this.inbox && this.objectType) {
                  this.loadInboxFiles();
                }
              } else {
                this.notification.showError(
                  _.pluck(r.messages, 'message').join('\n'),
                  this.translate.instant('shared.terms.failed')
                );
              }
            },
            (e) => {
              this.notification.showError(
                _.pluck(e.error.messages, 'message').join('\n'),
                this.translate.instant('shared.terms.failed')
              );
            },
            () => (this.loading = false)
          )
      );
    }
  }

  private loadInboxFiles() {
    this.loading = true;

    this._subscription.add(
      this.filesData
        .getFiles(
          this.objectId,
          this.objectType.replace('$customcontent_', '$customcontentinbox_'),
          0,
          1000
        )
        .subscribe(
          (r: ResultWrapper<FileModel[]>) => {
            if (r.hasResult) {
              r.result.forEach((file) => {
                file.inbox = true;
              });
              this.files = [...this.files, ...r.result];
            } else {
              this.notification.showError(
                _.pluck(r.messages, 'message').join('\n'),
                this.translate.instant('shared.terms.failed')
              );
            }
          },
          () => (this.loading = false)
        )
    );
  }

  fileIcon(fileType: string): string {
    return this.filesData.getFileTypeIcon(fileType.replace('.', ''));
  }

  fileTypes(type?: string): FileModel[] {
    let f: FileModel[] = [];

    switch (type) {
      case 'images': {
        f = _.filter(this.files, (x) => {
          const t = x.fileType.replace('.', '').toLowerCase();

          return (
            t === 'gif' ||
            t === 'image' ||
            t === 'jpeg' ||
            t === 'jpg' ||
            t === 'png'
          );
        });
        break;
      }

      default: {
        f = _.reject(this.files, (x) => {
          const t = x.fileType.replace('.', '').toLowerCase();

          return (
            t === 'gif' ||
            t === 'image' ||
            t === 'jpeg' ||
            t === 'jpg' ||
            t === 'png'
          );
        });
        break;
      }
    }

    return f;
  }

  fileUploaded(uploadedFile) {
    if (this.readonly) {
      return;
    }

    if (uploadedFile) {
      this.files.push(uploadedFile);
    }
  }

  filesZip() {
    if (this.archive && this.files && this.files.length) {
      this.notification.showInfo(
        `${this.translate.instant('shared.terms.pleaseWait')}...`,
        this.translate.instant('shared.terms.compressing'),
        0
      );

      if (this.archiveLocally) {
        const zip: JSZip = new JSZip();
        let count = 0;

        _.forEach(this.files, (x) => {
          this.filesData.getFileById(x.id).subscribe({
            next: (r: ResultWrapper<FileModel>) => {
              count++;
              zip.file(
                r.result.name + r.result.fileType,
                new Blob(
                  [this.filesData.base64ToArrayBuffer(r.result.content)],
                  { type: r.result.mimeType }
                )
              );
            },
            error: (e) => {
              this.notification.showError(
                _.pluck(e.error.messages, 'message').join('\n'),
                this.translate.instant('shared.terms.failed')
              );
            },
            complete: () => {
              this.notification.clear();

              if (count === this.files.length) {
                zip.generateAsync({ type: 'blob' }).then((content: Blob) => {
                  const link: HTMLAnchorElement = document.createElement('a');
                  link.href = window.URL.createObjectURL(content);
                  link.download = this.archive
                    ? `${this.archive}.zip`
                    : `${this.objectType}+${this.objectId}.zip`;

                  link.click();
                });
              }
            },
          });
        });
      } else {
        this.filesData
          .zipFiles(this.objectType, this.objectId, this.archive)
          .subscribe({
            error: (e) => {
              this.notification.showError(
                _.pluck(e.error.messages, 'message').join('\n'),
                this.translate.instant('shared.terms.failed')
              );
            },
            complete: () => this.notification.clear(),
          });
      }
    }
  }

  fileDownload(file: FileModel) {
    this.filesData.getFileById(file.id).subscribe({
      next: (r: ResultWrapper<FileModel>) => {
        if (r.hasResult) {
          const blob: Blob = new Blob(
            [this.filesData.base64ToArrayBuffer(r.result.content)],
            { type: r.result.mimeType }
          );
          const link: HTMLAnchorElement = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = r.result.name + r.result.fileType;

          link.click();
        } else {
          this.notification.showError(
            _.pluck(r.messages, 'message').join('\n'),
            this.translate.instant('shared.terms.failed')
          );
        }
      },
      error: (e) => {
        this.notification.showError(
          _.pluck(e.error.messages, 'message').join('\n'),
          this.translate.instant('shared.terms.failed')
        );
      },
    });
  }

  filePreview(file: FileModel) {
    this.filesData.getFileById(file.id).subscribe((file) => {
      if (file.hasResult) {
        FileHelpers.openDocumentBlob(
          this.filesData.base64ToArrayBuffer(file.result.content),
          file.result.mimeType
        );
      } else {
        this.notification.displayErrorNotification(file.messages);
      }
    });
  }

  onDeleteClick(file: FileModel) {
    const areYouSure = this._modalService.open(AreYouSureModalComponent, {
      backdrop: 'static',
    });
    areYouSure.result.then((answer: boolean | string) => {
      if (answer) {
        this.fileDelete(file);
      }
    });
  }

  fileDelete(file: FileModel) {
    this.filesData.deleteFile(file.id).subscribe({
      next: (r: ResultWrapper<FileModel>) => {
        if (r.hasResult) {
          this.notification.showSuccess(
            this.translate.instant(
              'customModules.orderRegistrations.fileDeleted'
            ),
            this.translate.instant('shared.terms.success')
          );
          this.files = _.reject(this.files, (x) => x.id === r.result.id);
        } else {
          this.notification.showError(
            _.pluck(r.messages, 'message').join('\n'),
            this.translate.instant('shared.terms.failed')
          );
        }
      },
      error: (e) => {
        this.notification.showError(
          _.pluck(e.error.messages, 'message').join('\n'),
          this.translate.instant('shared.terms.failed')
        );
      },
    });
  }

  updatePrivacy(file: FileModel) {
    file.isPrivate = !file.isPrivate;

    const fileUpdateModel = FileUpdateModel.fromFileModel(file);

    this.filesData
      .updateFile(fileUpdateModel)
      .subscribe((r: ResultWrapper<FileModel>) => {
        if (r.hasResult) {
          this.notification.showSuccess(
            this.translate.instant('files.model.changedPrivacy'),
            this.translate.instant('shared.terms.success')
          );
        } else {
          this.notification.showError(
            _.pluck(r.messages, 'message').join('\n'),
            this.translate.instant('shared.terms.failed')
          );
        }
      });
  }
}
