<div *shEmptyPlaceholder="isLoading">
  <div *ngIf="contact" class="p-relative">
    <div class="card-body">
      <ca-contact-form [model]="contact" [submitted]="isSubmitted"> </ca-contact-form>
      <div class="d-flex">
        <div class="flex-grow-1">
          <button
            type="button"
            class="btn btn-block btn-hover-danger"
            (click)="delete()"
          >
            {{ 'shared.terms.delete' | translate }}
          </button>
        </div>
        <div class="flex-grow-1">
          <button
            type="button"
            class="btn btn-block btn-success"
            (click)="save()"
          >
            {{ 'shared.terms.save' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
