<div *ngIf="file" class="file-container">
  <div class="icon-background">
    <i [ngClass]="['fa ' + fileIcon(file.fileType), 'large-icon']"></i>
  </div>
  <div class="file-details-wrapper">
    <div class="file-details-container">
      <div class="delete-button">
        <button
          *ngIf="file.allowDelete"
          type="button"
          class="close"
          (click)="deleteFile(file.id)"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="file-details">
        <div class="file-name" title="{{ file.name + file.fileType }}">
          {{ file.name + file.fileType | shTruncate: 14 }}
        </div>
        <div class="file-size" *ngIf="file.size; else noSize">
          {{ file.size | fileSize }}
        </div>
        <ng-template #noSize><div class="file-no-size"></div></ng-template>
      </div>
    </div>
    <div class="btn-position">
      <button type="button" class="btn-custom" (click)="downloadFile(file)">
        {{ 'shared.terms.download' | translate }}
      </button>
      <button type="button" class="btn-custom" (click)="previewFile(file)">
        {{ 'shared.terms.view' | translate }}
      </button>
    </div>
  </div>
</div>
