import { ConfigurationModel } from './configuration.model';
import { StageModel } from './stage.model';

export class FlowModel {
  id: number;
  organizationId: string;
  name: string;
  invoiceNumberTemplate: string;
  copyEmailAddress: string;
  onlineAvailable: boolean;
  trackPayments: boolean;
  enabled: boolean;
  description: string;
  startsOn: Date;
  endsOn?: Date;
  stages?: StageModel[];
  systemCode: string;
  invoiceConfiguration?: ConfigurationModel;

  constructor() {
    this.id = null;
    this.organizationId = null;
    this.name = null;
    this.invoiceNumberTemplate = null;
    this.copyEmailAddress = null;
    this.onlineAvailable = false;
    this.trackPayments = false;
    this.enabled = false;
    this.description = null;
    this.startsOn = null;
    this.endsOn = null;
    this.stages = [];
    this.invoiceConfiguration = null;
    this.systemCode = null;
  }
}
