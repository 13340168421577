import { Component, OnDestroy, OnInit } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';

import { ClusterModel } from '@seahorse/domain';
import { Subscription } from 'rxjs';
import { GeoJson } from '../../../map/models/geojson.model';
import { MapInteractionService } from '../../../map/services/map-interaction.service';
import { PreferenceDataService } from '../../../preference/services/preference-data.service';
import { MapMarkersModel } from '../../models/map-markers.model';

@Component({
  selector: 'ca-map-marker',
  template: '',
})
export class MapMarkerComponent implements OnInit, OnDestroy {
  private _subscriptions = new Subscription();
  map: mapboxgl.Map;

  private mapMarkers: MapMarkersModel;
  private mapMarkersLoading: boolean;

  constructor(
    private mapService: MapInteractionService,
    private preferencesData: PreferenceDataService
  ) {
    this.map = mapService.map;

    this.mapMarkers = new MapMarkersModel();
    this.mapMarkersLoading = false;
  }

  ngOnInit() {
    this.mapMarkersLoading = true;

    this._subscriptions.add(
      this.preferencesData.getByName('mapMarkers', 'map').subscribe({
        next: (r) => {
          if (r.hasResult) {
            this.mapMarkers = JSON.parse(r.result.fieldValue);
          }
        },
        complete: () => (this.mapMarkersLoading = false),
      })
    );
  }

  getCluster(cluster: GeoJson, clusterInfo: ClusterModel) {
    let fillColor;

    const fontSize =
      clusterInfo.count >= 1000
        ? 20
        : clusterInfo.count >= 100
        ? 18
        : clusterInfo.count >= 10
        ? 16
        : 14;
    const r =
      clusterInfo.count >= 1000
        ? 50
        : clusterInfo.count >= 100
        ? 32
        : clusterInfo.count >= 10
        ? 24
        : 18;
    const r0 = Math.round(r * 0.6);
    const w = r * 2;

    let html =
      '<svg width="' +
      w +
      '" height="' +
      w +
      '" viewbox="0 0 ' +
      w +
      ' ' +
      w +
      '" text-anchor="middle" style="font: ' +
      fontSize +
      'px sans-serif">';

    if (clusterInfo.count < 150) {
      fillColor = '#fed976';
    } else if (clusterInfo.count > 150 && clusterInfo.count < 1000) {
      fillColor = '#feb24c';
    } else if (clusterInfo.count > 1000 && clusterInfo.count < 2000) {
      fillColor = '#fd8d3c';
    } else if (clusterInfo.count > 2000 && clusterInfo.count < 3500) {
      fillColor = '#fc4e2a';
    } else {
      fillColor = '#e31a1c';
    }

    html +=
      '<circle cx="' +
      r +
      '" cy="' +
      r +
      '" r="' +
      r0 +
      '" fill="' +
      fillColor +
      '" /><text dominant-baseline="central" transform="translate(' +
      r +
      ', ' +
      r +
      ')">' +
      clusterInfo.count.toLocaleString() +
      '</text></svg>';

    const el = document.createElement('div');
    el.innerHTML = html;

    el.addEventListener('click', () => {
      this.map.easeTo({
        center: cluster.geometry.coordinates,
        zoom: 11,
      });
    });

    return new mapboxgl.Marker(el)
      .setLngLat(cluster.geometry.coordinates)
      .addTo(this.map);
  }
  // , shipColors: { type: string, color: string }
  getMarker(marker: GeoJson, markerOptions) {
    const isMoving = markerOptions.aisInfo.speed >= 1;

    const el = document.createElement('canvas');

    // add marker to map
    const mapboxMarker = new mapboxgl.Marker(el)
      .setLngLat(marker.geometry.coordinates)
      .addTo(this.map);

    mapboxMarker.options = markerOptions;

    if (
      mapboxMarker.options.markerType === 'icon' &&
      !this.mapMarkers.shapeAlwaysOn &&
      !isMoving
    ) {
      this.setIcon(mapboxMarker, isMoving);
    } else {
      if (isMoving) {
        mapboxMarker.options.isMoving = isMoving;
        mapboxMarker.options.scale = 1;
        mapboxMarker.options.shape = [10, 10, 2, 2];
      }

      if (
        isMoving ||
        mapboxMarker.options.markerType === 'shape' ||
        this.mapMarkers.shapeAlwaysOn
      ) {
        this.drawShipOutline(mapboxMarker, isMoving);
      }
    }

    this.setRotation(mapboxMarker);

    return mapboxMarker;
  }

  changeMapMarkers(mapMarkers: MapMarkersModel) {
    this.mapMarkers = mapMarkers;
  }

  updateOptions(mapboxMarker: mapboxgl.Marker, newOptions) {
    let markerTypeChanged = false;
    let scaleChanged = false;
    let rotateChange = false;
    const isMoving = newOptions.aisInfo.speed >= 1;

    if (!newOptions) {
      return;
    }

    if (newOptions.markerType !== mapboxMarker.options.markerType) {
      markerTypeChanged = true;
      mapboxMarker.options.markerType = newOptions.markerType;
    }

    if (newOptions.scale !== mapboxMarker.options.scale) {
      scaleChanged = true;
      mapboxMarker.options.scale = newOptions.scale;
    }

    if (newOptions.mapRotation !== mapboxMarker.options.mapRotation) {
      rotateChange = true;
      mapboxMarker.options.mapRotation = newOptions.mapRotation;
    }

    mapboxMarker.options.aisInfo = newOptions.aisInfo;
    mapboxMarker.options.shape = newOptions.shape;

    if (newOptions.markerType === 'icon' && !this.mapMarkers.shapeAlwaysOn) {
      if (markerTypeChanged) {
        if (!isMoving) {
          this.setIcon(mapboxMarker, isMoving);
        } else if (isMoving) {
          mapboxMarker.options.isMoving = isMoving;
          mapboxMarker.options.scale = 1;
          mapboxMarker.options.shape = [10, 10, 2, 2];
          this.drawShipOutline(mapboxMarker, isMoving);
        }
      }
    } else {
      if (rotateChange) {
        this.setRotation(mapboxMarker);
      }

      if (markerTypeChanged || scaleChanged) {
        this.drawShipOutline(mapboxMarker, isMoving);
      }
    }
  }

  private setIcon(mapboxMarker, isMoving) {
    const canvas = mapboxMarker.getElement();
    canvas.width = 15;
    canvas.height = 15;

    const ctx = canvas.getContext('2d');

    const image = new Image();
    image.src = this.getIcon(
      mapboxMarker.options.aisInfo.shipType,
      'stopped',
      isMoving
    );
    image.onload = () => {
      ctx.save();
      ctx.drawImage(image, 0, 0, 15, 15);
      ctx.restore();
    };

    mapboxMarker._anchor = 'center';
    mapboxMarker._update();

    canvas.style.marginLeft = '';
    canvas.style.marginTop = '';
    canvas.style.transformOrigin = '';
  }

  private drawShipOutline(mapboxMarker, isMoving) {
    const canvas = mapboxMarker.getElement();

    const iconScale = mapboxMarker.options.scale;
    canvas.width =
      mapboxMarker.options.shape[2] * iconScale +
      mapboxMarker.options.shape[3] * iconScale +
      2;
    canvas.height =
      mapboxMarker.options.shape[0] * iconScale +
      mapboxMarker.options.shape[1] * iconScale +
      2;

    const ctx = canvas.getContext('2d');

    const x = mapboxMarker.options.shape[2] * iconScale;
    const y = mapboxMarker.options.shape[0] * iconScale;

    const image = new Image();
    image.src = this.getIcon(
      mapboxMarker.options.aisInfo.shipType,
      'sailing',
      isMoving
    );
    image.onload = () => {
      ctx.save();
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
      ctx.restore();

      ctx.fillStyle = '#000000';
      ctx.beginPath();
      ctx.arc(x, y, 2, 0, 2 * Math.PI);
      ctx.fill();
    };

    canvas.style.marginLeft = -x + 'px';
    canvas.style.marginTop = -y + 'px';
    canvas.style.transformOrigin = x + 'px ' + y + 'px';

    mapboxMarker._anchor = 'top';
    mapboxMarker._update();
  }

  // Helpers
  private setRotation(mapboxMarker) {
    let deg = null;
    if (mapboxMarker.options.aisInfo.heading !== 0) {
      deg = mapboxMarker.options.aisInfo.heading;
    } else if (mapboxMarker.options.aisInfo.courseOverGround !== 0) {
      deg = mapboxMarker.options.aisInfo.courseOverGround;
    }

    if (deg !== null) {
      deg = deg - mapboxMarker.options.mapRotation; // correct the degrees with the rotation of the map
      mapboxMarker.setRotation(deg);
    }
  }

  private getIcon(shipType: string, icon: string, isMoving: boolean): string {
    if (!shipType) {
      return;
    }
    if (icon && icon !== 'sailing') {
      return this.getOldIcon(shipType, icon, isMoving);
    }
    switch (shipType.toLowerCase()) {
      case '70':
      case '71':
      case '72':
      case '73':
      case '74':
      case '75':
      case '76':
      case '77':
      case '78':
      case '79':
      case 'bulk carrier':
      case 'cargo':
      case 'general cargo carrier':
      case 'reefer vessel':
        return '/assets/outlines/marker-type-bulk.svg';
      case 'container':
      case 'container carrier':
        return '/assets/outlines/marker-type-container.svg';
      case '80':
      case '81':
      case '82':
      case '83':
      case '84':
      case '85':
      case '86':
      case '87':
      case '88':
      case '89':
      case 'tanker':
        return '/assets/outlines/marker-type-tanker.svg';
      case '50':
      case '51':
      case '53':
      case 'pilot':
      case 'sar':
        return '/assets/outlines/marker-type-pilot.svg';
      case '31':
      case '32':
      case '52':
      case 'tug':
        return '/assets/outlines/marker-type-tug.svg';
      default:
        return this.getOldIcon(shipType, icon, isMoving);
    }
  }

  private getOldIcon(
    shipType: string,
    icon: string,
    isMoving: boolean
  ): string {
    const convertedType = this.aisInfoShipTypeConverter(shipType);

    let markerColor: string;

    switch (convertedType) {
      case 1:
        markerColor = this.mapMarkers.passengerColor;
        break;

      case 2:
        markerColor = this.mapMarkers.cargoColor;
        break;

      case 3:
        markerColor = this.mapMarkers.tankerColor;
        break;

      case 4:
        markerColor = this.mapMarkers.pilotColor;
        break;

      case 5:
        markerColor = this.mapMarkers.pleasureColor;
        break;

      case 6:
        markerColor = this.mapMarkers.fishingColor;
        break;

      default:
        markerColor = this.mapMarkers.defaultColor;
        break;
    }

    return `/assets/outlines/marker-${
      this.mapMarkers.shapeAlwaysOn ? 'sailing' : icon
    }-${
      this.mapMarkers.colorOnSailing && !isMoving ? 'grey' : markerColor
    }.svg`;
  }

  private aisInfoShipTypeConverter(st) {
    if (isNaN(st)) {
      switch (st.toString().toLowerCase()) {
        case 'passenger':
          return 1;
        case 'cargo':
          return 2;
        case 'tanker':
          return 3;
        case 'pilot':
        case 'tug':
        case 'search and rescue vessel':
        case 'port tender':
        case 'hsc':
          return 4;
        case 'fishing':
          return 6;
        default:
          return 7;
      }
    } else {
      if (st >= 20 && st <= 29) {
        // WIG
        return 7;
      } else if (st === 30) {
        // Fishing
        return 6;
      } else if (st === 31 || st === 32) {
        // Towing
        return 2;
      } else if (st === 33) {
        // Dredgin
        return 7;
      } else if (st === 34) {
        // Diving
        return 7;
      } else if (st === 35) {
        // Military
        return 7;
      } else if (st === 36) {
        // Sailing
        return 5;
      } else if (st === 37) {
        // Pleasure
        return 5;
      } else if (st >= 41 && st <= 49) {
        // High speed craft (HSC)
        return 4;
      } else if (st === 50) {
        // Pilot
        return 4;
      } else if (st === 51) {
        // SAR
        return 4;
      } else if (st === 52) {
        // Tug
        return 4;
      } else if (st === 53) {
        // Port Tender
        return 4;
      } else if (st === 54) {
        // Anti-pollution equipment
        return 7;
      } else if (st === 55) {
        // Law enforcement
        return 4;
      } else if (st === 56 || st === 57) {
        // Spare - local vessel
        return 7;
      } else if (st === 58) {
        // Medical transport
        return 4;
      } else if (st === 39) {
        // Ship according to RR Resolution No. 18
        return 2;
      } else if (st >= 60 && st <= 69) {
        // Passenger
        return 1;
      } else if (st >= 70 && st <= 79) {
        // Cargo
        return 2;
      } else if (st >= 80 && st <= 89) {
        // Tanker
        return 3;
      } else if (st > 90) {
        // Other
        return 7;
      } else {
        return 7;
      } // Default
    }
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}
