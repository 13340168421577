<div *shEmptyPlaceholder="isLoading">
  <div *ngIf="company" class="p-relative">
    <div class="card-body">
      <div
        class="card-body-group card-body-group-no-border attribute-group"
        *ngIf="attributes && attributes.length > 0"
      >
        <temp-attribute-list
          [attributes]="attributes"
          columns="2"
        ></temp-attribute-list>
      </div>
    </div>
    <div>
      <ul class="summary-buttons">
        <li>
          <a routerLink="/companies/{{ company.id }}" class="summary-button">
            <i class="fa-regular fa-building fa-fw" aria-hidden="true"></i>
            <span class="summary-button-text">
              {{ 'companies.company.companyDetails' | translate }}
            </span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
