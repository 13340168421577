import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ca-generic-message-modal',
  templateUrl: 'generic-message-modal.component.html',
})
export class GenericMessageModalComponent {
  @Input() modalBody: string = null;
  @Input() modalHeader: string = null;
  @Input() hideOkButton = false;

  constructor(public modal: NgbActiveModal) {}

  dismiss() {
    this.modal.dismiss();
  }

  ok() {
    this.modal.close(true);
  }
}
