import { Component, Input, OnInit } from '@angular/core';
import { SnelstartRelationship } from '../../../../snelstart/models/snelstart-relationship';
import { SnelstartService } from '../../../../snelstart/snelstart.service';

@Component({
  selector: 'ca-company-snelstart-debtor-information-card',
  templateUrl: './company-snelstart-debtor-information-card.component.html',
  styleUrls: ['./company-snelstart-debtor-information-card.component.scss'],
})
export class CompanySnelstartDebtorInformationCardComponent implements OnInit {
  @Input() debtorNumbers: string[];

  relationships: SnelstartRelationship[] = [] as SnelstartRelationship[];
  isLoading: boolean;

  constructor(private snelstartService: SnelstartService) {}

  ngOnInit(): void {
    this.getRelationships();
  }

  getRelationships() {
    this.isLoading = true;
    this.snelstartService.getRelationships(this.debtorNumbers).subscribe(
      (response) => {
        if (response.result)
          this.relationships = response.result;

        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }
}
