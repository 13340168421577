import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { NauticalShipAttributeModel } from '@seahorse/domain';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'nautical-ship-add-attribute',
  templateUrl: './nautical-ship-add-attribute.component.html',
})
export class NauticalShipAddAttributeComponent {
  submitted = false;
  @Input() categories: Array<{ name: string; display: string }> = [];
  @Input() model = new NauticalShipAttributeModel();

  selectedCategory: { name: string | undefined; display: string };
  constructor(private modal: NgbActiveModal) {}

  save() {
    if (!NauticalShipAttributeModel.validate(this.model)) {
      this.submitted = true;
      return;
    }

    this.modal.close(this.model);
  }

  setCategory(category: { name: string | undefined; display: string }) {
    this.model.category = category.name ?? category.display;
  }
}
