import { Injectable } from '@angular/core';
import { ResultWrapper, DataContextService } from '@seahorse/common';
import { Observable } from 'rxjs';
import { DecisionModel } from '../models/inbox-decision.model';
import { CustomDataBaseModel } from '../models/custom-data-base.model';
import { ScopeType } from '../models/custom-default-data.model';

@Injectable({ providedIn: 'root' })
export class InboxDataService {
  constructor(private dataContext: DataContextService) {}

  getCount(): Observable<ResultWrapper<number>> {
    return this.dataContext.get<number>('customdata/customcontentinbox/count');
  }

  getOverview(): Observable<
    ResultWrapper<{ [key: number]: Array<CustomDataBaseModel> }>
  > {
    return this.dataContext.get<{ [key: number]: Array<CustomDataBaseModel> }>(
      'customdata/customcontentinbox/overview'
    );
  }

  update(
    scope: ScopeType,
    objectDataSystemCode: string,
    id: string,
    data: CustomDataBaseModel
  ): Observable<ResultWrapper<CustomDataBaseModel>> {
    return this.dataContext.put<CustomDataBaseModel>(
      `customdata/customcontentinbox/${scope}/${objectDataSystemCode}/data/${id}`,
      data
    );
  }

  approve(
    scope: ScopeType,
    objectDataSystemCode: string,
    id: string,
    request: DecisionModel
  ): Observable<ResultWrapper<CustomDataBaseModel>> {
    return this.dataContext.put<CustomDataBaseModel>(
      `customdata/customcontentinbox/${scope}/${objectDataSystemCode}/data/${id}/approve`,
      request
    );
  }

  reject(
    scope: ScopeType,
    objectDataSystemCode: string,
    id: string,
    request: DecisionModel
  ): Observable<ResultWrapper<CustomDataBaseModel>> {
    return this.dataContext.put<CustomDataBaseModel>(
      `customdata/customcontentinbox/${scope}/${objectDataSystemCode}/data/${id}/decline`,
      request
    );
  }

  addShadowData(
    objectType: string,
    objectContent: any
  ): Observable<ResultWrapper<any>> {
    return this.dataContext.post<any>(
      `customdata/customcontentinbox/custom/${objectType}/shadow`,
      objectContent
    );
  }

  get(objectType: string, id: string): Observable<ResultWrapper<any>> {
    return this.dataContext.get<any>(
      `customdata/customcontentinbox/${objectType}/${id}`
    );
  }
}
