import { Injectable } from '@angular/core';
import { IdentityService } from '../../core/services/identity.service';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import * as _ from 'underscore';

import {
  ExportOptionItemModel,
  ExportOptionModel,
} from '../models/content-distribtion-export-option.model';
import { ContentDistributionDataService } from './content-distribution-data.service';

@Injectable({
  providedIn: 'root',
})
export class ContentDistributionCacheService {
  private _distributionOptions: ExportOptionModel[] = [];

  constructor(
    private contentDistributionData: ContentDistributionDataService,
    private identityService: IdentityService
  ) {}

  private getDistributionOptions(): Observable<ExportOptionModel[]> {
    return new Observable<ExportOptionModel[]>((subscriber) => {
      if (this._distributionOptions && this._distributionOptions.length > 0) {
        subscriber.next(this._distributionOptions);
        subscriber.complete();
      } else {
        this.loadData().subscribe((result) => {
          subscriber.next(result);
          subscriber.complete();
        });
      }
    });
  }

  getByMappingKey(
    moduleCode: string,
    contentType: string,
    systemCode?: string
  ): Observable<ExportOptionItemModel[]> {
    const mappingKey = '$' + moduleCode + '_' + contentType;
    const lowerMappingKey = mappingKey.toLowerCase();

    const systemCodeMappingKey = '$' + moduleCode + '_' + systemCode;

    return this.getDistributionOptions().pipe(
      switchMap((items) => {
        const filteredItems = items.filter(
          (item) => item.mappingKey.toLowerCase() === lowerMappingKey
        );

        let combinedResults = filteredItems;

        if (systemCode) {
          const systemCodeMappingKeyLower = systemCodeMappingKey.toLowerCase();
          const systemCodeMatches = items.filter(
            (item) =>
              item.mappingKey.toLowerCase() === systemCodeMappingKeyLower
          );

          combinedResults = [...combinedResults, ...systemCodeMatches];
        }

        return of(combinedResults);
      }),
      map((combinedItems) => {
        return combinedItems.map((item) => item.options).flat();
      })
    );
  }

  private loadData(): Observable<ExportOptionModel[]> {
    const preference = _.find(this.identityService.getPreferences(), (p) => {
      return 'language' === p.name.toLowerCase();
    });

    const language =
      preference && preference.fieldValue ? preference.fieldValue : null;

    return new Observable<ExportOptionModel[]>((subscriber) => {
      this.contentDistributionData
        .getExportOptions(language)
        .subscribe((result) => {
          if (result.hasResult && result.result) {
            this._distributionOptions = result.result.map(this.mapExportOption);
          }

          subscriber.next(result.result);
          subscriber.complete();
        });
    });
  }

  mapExportOption(exportOption: ExportOptionModel): ExportOptionModel {
    exportOption.options = exportOption.options.map((option) => {
      const moduleKey = exportOption?.mappingKey.match(/^\$(.+?)_/);
      option.isGeneric = moduleKey[1] === 'generic';
      return option;
    });
    return exportOption;
  }

  clearCache() {
    this._distributionOptions = [];
  }
}
