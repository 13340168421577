import { InjectionToken, Type } from '@angular/core';

export type UiModuleCustomComponents = UiModuleCustomComponent<any>[];

export interface UiModuleCustomComponent<T> {
  key: string;
  type: 'form' | 'externalObjectCard' | 'linkedObjectCard';
  component: Type<T>;
  data?: (...args: any[]) => { [key in keyof T]?: T[key] };
}

export const UI_MODULE_CUSTOM_COMPONENTS =
  new InjectionToken<UiModuleCustomComponents>('UiModuleCustomComponents');
