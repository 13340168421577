<div class="card">
  <div class="card-header">
    {{ 'invoicingFlows.flowDetails.executions' | translate }}
    <div class="btn-list" *hasPermission="'custom_ui.cvv'">
      <button
        type="button"
        class="btn btn-outline-secondary btn-sm"
        (click)="newExecution()"
      >
        <i class="fa-regular fa-plus fa-fw"></i>
        {{ 'shared.terms.new' | translate }}
      </button>
    </div>
  </div>
  <ng-container
    *ngIf="executions.length > 0; then showExecutions; else noExecutions"
  ></ng-container>

  <ng-template #showExecutions>
    <div
      class="card-body card-body-table table-responsive"
      style="max-height: 500px"
    >
      <table class="table">
        <thead>
          <tr>
            <th scope="col">
              {{ 'invoicingFlows.execution.status' | translate }}
            </th>
            <th scope="col">
              {{ 'invoicingFlows.execution.startedOn' | translate }}
            </th>
            <th scope="col">
              {{ 'invoicingFlows.execution.startedByReference' | translate }}
            </th>
            <th scope="col" width="1%"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let execution of executions">
            <td>{{ execution.status | executionStatus }}</td>
            <td>{{ execution.startedOn | date: 'dd-MM-yyyy HH:mm' }}</td>
            <td>{{ execution.startedByReference }}</td>
            <td>
              <button
                *ngIf="execution.status === 5"
                [disabled]="loadingExecutions"
                (click)="
                  onEvent.emit({
                    action: 'approveExecution',
                    data: execution.id
                  })
                "
                type="button"
                class="btn btn-hover-success btn-sm"
              >
                <i class="fa-regular fa-clipboard-check fa-fw"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-template>

  <ng-template #noExecutions>
    <div *ngIf="!loadingExecutions" class="card-body">
      <span class="text-muted">{{
        'invoicingFlows.flowDetails.noExecutions' | translate
      }}</span>
    </div>
  </ng-template>

  <sh-loading-indicator
    *ngIf="loadingExecutions"
    class="d-block text-center"
  ></sh-loading-indicator>

  <div
    *ngIf="indexedExecutions && !loadingExecutions"
    class="card-footer card-footer-clickable"
    (click)="onEvent.emit({ action: 'moreExecutions' })"
  >
    <span> {{ 'shared.terms.loadMore' | translate }}</span>
  </div>
</div>
