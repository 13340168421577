import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'underscore';
import * as moment from 'moment';
import { ResultWrapper } from '@seahorse/common';
import { NotificationService } from '@seahorse/common';
import { OrderRegistrationsConstant } from '../../../order-registrations/constants/order-registrations.constant';
import { OrderRegistrationHotelStayModel } from '../../../order-registrations/models/order-registration-hotel-stay.model';
import { CustomDataContentService } from '@seahorse/domain';

@Component({ templateUrl: 'hotel-stay-dialog.component.html' })
export class HotelStayDialogComponent implements OnInit {
  @Input() title?: string;

  @Input() quantity?: number;

  @Input() set hotelStays(hotelStayModels: OrderRegistrationHotelStayModel[]) {
    if (hotelStayModels && hotelStayModels.length) {
      this._hotelStays = _.clone(hotelStayModels);
      this.currentHotelStayIds = _.compact(
        _.pluck(_.clone(hotelStayModels), '__Id')
      );
    } else {
      this._hotelStays = [];
    }
  }
  get hotelStays(): OrderRegistrationHotelStayModel[] {
    return this._hotelStays;
  }

  @Input() set hotelStayIds(ids: OrderRegistrationHotelStayModel['__Id'][]) {
    if (ids && ids.length) {
      this._hotelStayIds = _.compact(_.clone(ids));
      this.hotelStaysLoading = true;

      this.customData
        .getCustomerDataByIds(
          OrderRegistrationsConstant.OBJECTKEYHOTELSTAY,
          ids
        )
        .subscribe(
          (r: ResultWrapper<OrderRegistrationHotelStayModel[]>) => {
            if (r.hasResult && r.result.length) {
              this.hotelStays = r.result;
              this.currentHotelStayIds = _.pluck(r.result, '__Id');
            } else {
              this.notification.showError(
                _.pluck(r.messages, 'message').join('\n'),
                this.translate.instant('shared.terms.failed')
              );
            }
          },

          (e) => {
            this.notification.showError(
              _.pluck(e.error.messages, 'message').join('\n'),
              this.translate.instant('shared.terms.failed')
            );
          },

          () => (this.hotelStaysLoading = false)
        );
    } else {
      this._hotelStayIds = [];
    }
  }
  get hotelStayIds(): OrderRegistrationHotelStayModel['__Id'][] {
    return this._hotelStayIds;
  }

  @Output() onConfirm: EventEmitter<{
    hotelStays: OrderRegistrationHotelStayModel[];
  }>;

  currentHotelStayIds: OrderRegistrationHotelStayModel['__Id'][];
  hotelStaysLoading: boolean;
  errorList: string[];

  private _hotelStays: OrderRegistrationHotelStayModel[];
  private _hotelStayIds: OrderRegistrationHotelStayModel['__Id'][];

  constructor(
    private activeModal: NgbActiveModal,
    private translate: TranslateService,
    private notification: NotificationService,
    private customData: CustomDataContentService
  ) {
    this.title = this.translate.instant(
      'customModules.orderRegistrations.hotelStay.factoryDialogTitle'
    );
    this.hotelStays = [];
    this.hotelStayIds = [];
    this.currentHotelStayIds = [];
    this.hotelStaysLoading = false;
    this.errorList = [];
    this.onConfirm = new EventEmitter<{
      hotelStays: OrderRegistrationHotelStayModel[];
    }>();
    this.hotelStaysLoading = false;
    this._hotelStays = [];
    this._hotelStayIds = [];
  }

  ngOnInit() {
    if (this.quantity && this.quantity > this.hotelStays.length) {
      _.times(this.quantity - this.hotelStays.length, () =>
        this.addHotelStay()
      );
    }
  }

  addHotelStay() {
    new Promise<void>((res) => {
      this.hotelStays.push(new OrderRegistrationHotelStayModel());
      setTimeout(() => res(), 1);
    }).then(
      () =>
        new Promise(() => {
          (<HTMLElement>(
            document.getElementById(
              `sectionLinkId_${_.findLastIndex(this.hotelStays)}`
            )
          )).click();
        })
    );
  }

  removeHotelStay(hotelStay: OrderRegistrationHotelStayModel) {
    this.hotelStays = _.without(this.hotelStays, hotelStay);

    if (hotelStay.__Id) {
      this.hotelStayIds = _.without(this.hotelStayIds, hotelStay.__Id);
    }
  }

  checkInDateChanged(event, i: number) {
    this.hotelStays[i].checkInDate = event ? moment(event).toDate() : null;
  }

  checkOutDateChanged(event, i: number) {
    this.hotelStays[i].checkOutDate = event ? moment(event).toDate() : null;
  }

  confirm() {
    this.errorList = [];

    const messageLists: ResultWrapper<any>['messages'][][] = [];
    const hotelStaysUpdated: OrderRegistrationHotelStayModel[] = [];
    let hotelStayUpdateFailCount = 0;
    const hotelStaysAdded: OrderRegistrationHotelStayModel[] = [];
    let hotelStayAddFailCount = 0;
    let hotelStayDeletedCount = 0;
    let hotelStayDeleteFailCount = 0;

    new Promise<void>((err) => {
      if (this.hotelStays.length) {
        let i = 0;

        _.forEach(this.hotelStays, (hotelStay) => {
          i++;

          if (hotelStay) {
            if (!hotelStay.roomOrName) {
              this.errorList.push(
                this.translate.instant(
                  'customModules.orderRegistrations.hotelStay.error.roomOrNameMissing'
                )
              );
            }

            if (hotelStay.checkInDate && hotelStay.checkOutDate) {
              if (
                moment(hotelStay.checkInDate).isAfter(hotelStay.checkOutDate)
              ) {
                this.errorList.push(
                  this.translate.instant(
                    'customModules.orderRegistrations.hotelStay.error.checkInDateAfterCheckOutDate'
                  )
                );
              }
            }
          } else {
            this.errorList.push(
              this.translate.instant(
                'customModules.orderRegistrations.hotelStay.error.hotelStayMissing'
              )
            );
          }

          if (this.hotelStays.length == i && this.errorList.length == 0) {
            err();
          }
        });
      } else {
        err();
      }
    }).then(() =>
      new Promise<void>((upd) => {
        this.hotelStaysLoading = true;

        const hotelStayUpdates = _.reject(this.hotelStays, (x) => !x.__Id);

        if (hotelStayUpdates.length) {
          _.forEach(hotelStayUpdates, (hotelStayUpdate) => {
            let updMessages: ResultWrapper<any>['messages'][] = [];

            this.customData
              .updateCustomerData(
                OrderRegistrationsConstant.OBJECTKEYHOTELSTAY,
                hotelStayUpdate.__Id,
                hotelStayUpdate
              )
              .subscribe(
                (updR: ResultWrapper<OrderRegistrationHotelStayModel>) => {
                  if (updR.hasResult) {
                    hotelStaysUpdated.push(updR.result);
                  } else {
                    updMessages = updMessages.concat(updR.messages);

                    hotelStayUpdateFailCount++;
                  }
                },

                (updE) => {
                  updMessages = updMessages.concat(updE.error.messages);

                  hotelStayUpdateFailCount++;
                },

                () => {
                  messageLists.push(updMessages);

                  if (
                    hotelStaysUpdated.length + hotelStayUpdateFailCount ==
                    hotelStayUpdates.length
                  ) {
                    upd();
                  }
                }
              );
          });
        } else {
          upd();
        }
      }).then(() =>
        new Promise<void>((add) => {
          const hotelStayAdds = _.filter(this.hotelStays, (x) => !x.__Id);

          if (hotelStayAdds.length) {
            _.forEach(hotelStayAdds, (hotelStayAdd) => {
              let addMessages: ResultWrapper<any>['messages'][] = [];

              this.customData
                .addCustomerData(
                  OrderRegistrationsConstant.OBJECTKEYHOTELSTAY,
                  hotelStayAdd
                )
                .subscribe(
                  (addR: ResultWrapper<OrderRegistrationHotelStayModel>) => {
                    if (addR.hasResult) {
                      hotelStaysAdded.push(addR.result);
                    } else {
                      addMessages = addMessages.concat(addR.messages);

                      hotelStayAddFailCount++;
                    }
                  },

                  (addE) => {
                    addMessages = addMessages.concat(addE.error.messages);

                    hotelStayAddFailCount++;
                  },

                  () => {
                    messageLists.push(addMessages);

                    if (
                      hotelStaysAdded.length + hotelStayAddFailCount ==
                      hotelStayAdds.length
                    ) {
                      add();
                    }
                  }
                );
            });
          } else {
            add();
          }
        }).then(() =>
          new Promise<void>((del) => {
            const hotelStayIdDeletes = _.compact(
              _.difference(this.currentHotelStayIds, this.hotelStayIds)
            );

            if (hotelStayIdDeletes.length) {
              _.forEach(hotelStayIdDeletes, (hotelStayIdDelete) => {
                let delMessages: ResultWrapper<any>['messages'][] = [];

                this.customData
                  .deleteCustomerData(
                    OrderRegistrationsConstant.OBJECTKEYHOTELSTAY,
                    hotelStayIdDelete
                  )
                  .subscribe(
                    (delR: ResultWrapper<OrderRegistrationHotelStayModel>) => {
                      if (delR.hasResult) {
                        hotelStayDeletedCount++;
                      } else {
                        delMessages = delMessages.concat(delR.messages);

                        hotelStayDeleteFailCount++;
                      }
                    },

                    (delE) => {
                      delMessages = delMessages.concat(delE.error.messages);

                      hotelStayDeleteFailCount++;
                    },

                    () => {
                      messageLists.push(delMessages);

                      if (
                        hotelStayDeletedCount + hotelStayDeleteFailCount ==
                        hotelStayIdDeletes.length
                      ) {
                        del();
                      }
                    }
                  );
              });
            } else {
              del();
            }
          }).then(
            () =>
              new Promise(() => {
                this.hotelStaysLoading = false;

                const message = `${
                  hotelStaysUpdated.length +
                  hotelStaysAdded.length +
                  hotelStayDeletedCount
                }/${
                  this.hotelStays.length +
                  _.compact(
                    _.difference(this.currentHotelStayIds, this.hotelStayIds)
                  ).length
                } ${this.translate.instant(
                  'customModules.orderRegistrations.crewMember.crewMembersUpdated'
                )}`;

                if (
                  hotelStayUpdateFailCount +
                  hotelStayAddFailCount +
                  hotelStayDeleteFailCount
                ) {
                  this.notification.showWarning(
                    message,
                    this.translate.instant('shared.terms.failed')
                  );

                  _.forEach(messageLists, (messageList) => {
                    this.notification.showError(
                      _.pluck(messageList, 'message').join('\n')
                    );
                  });
                } else {
                  this.notification.showSuccess(
                    message,
                    this.translate.instant('shared.terms.success')
                  );
                }

                this.activeModal.close(
                  this.onConfirm.emit({
                    hotelStays: _.union(hotelStaysUpdated, hotelStaysAdded),
                  })
                );
              })
          )
        )
      )
    );
  }
}
