import { BaseModel } from '@seahorse/common';
import { ValidationResult } from '@seahorse/common';
import { SystemMessageChannelModel } from './system-message-channel.model';
import { SystemMessageModel } from './system-message.model';

export class SystemMessageReleaseModel extends BaseModel {
  id: number;
  systemMessageId: number;
  systemMessageChannelId: number;
  releasedOn: Date;
  endsOn: Date;
  systemMessage: SystemMessageModel;
  systemMessageChannel: SystemMessageChannelModel;
  subject: string;

  static validate(model: SystemMessageReleaseModel) {
    if (!model.releasedOn || !model.systemMessageChannelId) {
      return false;
    }

    return true;
  }

  validate(errorPrefix?: string): ValidationResult {
    const result: ValidationResult = new ValidationResult();

    if (!errorPrefix) {
      errorPrefix = '';
    }

    if (this.releasedOn == null) {
      result.errors.push(errorPrefix + 'Released on date is required!');
    }

    if (this.systemMessageChannelId == null) {
      result.errors.push(errorPrefix + 'System message channel is required!');
    }

    result.isValid = result.errors.length === 0;
    return result;
  }
}
