import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ShipModel } from '../../../superuser-nautical/models/ship.model';

import * as _ from 'underscore';

@Component({
  selector: 'ca-recent-vessels-card',
  templateUrl: './recent-vessels-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecentVesselsCardComponent {
  private _vessels: ShipModel[] = [];

  @Input() set vessels(vessels: ShipModel[]) {
    this._vessels = _.map(vessels, (vessel) => {
      const displayTags = [];

      if (vessel.imo) {
        displayTags.push('IMO ' + vessel.imo);
      }
      if (vessel.loa) {
        displayTags.push(vessel.loa + 'm');
      }
      if (vessel.shipType && vessel.shipType.name) {
        displayTags.push(vessel.shipType.name);
      }
      if (vessel.fisheryNumber) {
        displayTags.push(vessel.fisheryNumber);
      }
      if (vessel.callSign) {
        displayTags.push(vessel.callSign);
      }
      if (vessel.gt && vessel.gt > 0) {
        displayTags.push('GT: ' + vessel.gt + 't');
      }

      vessel['displayTags'] = displayTags.join(', ');

      return vessel;
    });
  }
  get vessels(): ShipModel[] {
    return this._vessels;
  }

  constructor(private router: Router, private route: ActivatedRoute) {}

  openShipDetails(ship: ShipModel) {
    this.router
      .navigate(['/nautical/vessels/', ship.id], {
        queryParams: { issueType: ship.issueType },
        relativeTo: this.route,
      })
      .then(
        () => {},
        () => {}
      )
      .catch(() => {})
      .finally(() => {});
  }
}
