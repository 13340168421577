<section class="page-header d-flex justify-content-between px-3 py-1">
  <div class="d-flex mx-n1">
    <div class="input-group align-items-center w-auto mx-1">
      <div class="input-group-prepend">
        <span class="input-group-text">{{
          'shared.terms.view' | translate
        }}</span>
      </div>
      <select
        [(ngModel)]="view"
        (change)="onEvent.emit({ action: 'setView', data: view })"
        required
        name="view"
        class="form-control"
      >
        <option *ngFor="let viewOption of views" [ngValue]="viewOption.value">
          {{ viewOption.title | translate }}
        </option>
      </select>
    </div>

    <form
      (ngSubmit)="onEvent.emit({ action: 'setSearch', data: search })"
      class="form-inline mx-1 mt-0"
    >
      <button
        class="btn btn-outline-secondary mr-2"
        type="button"
        (click)="toggleExpand()"
      >
        <i class="fa-regular fa-filter fa-fw"></i>
      </button>

      <input
        [(ngModel)]="search"
        [placeholder]="'shared.terms.search' | translate"
        type="text"
        name="search"
        class="form-control"
      />
      <div class="input-group-append">
        <button
          [disabled]="loading"
          type="submit"
          class="btn btn-outline-secondary"
        >
          {{ 'shared.terms.search' | translate }}
        </button>
      </div>

      <em *ngIf="count != null">
        {{ count }}
        <ng-container
          *ngIf="count == 1; then itemFound; else itemsFound"
        ></ng-container>
        <ng-template #itemFound>{{
          'shared.terms.itemFound' | translate
        }}</ng-template>
        <ng-template #itemsFound>{{
          'shared.terms.itemsFound' | translate
        }}</ng-template>
      </em>
    </form>

    <ca-tasks-advanced-search
      [showAdvancedSearch]="expanded"
      [loading]="loading"
      [search]="search"
      [active]="active"
      [expired]="expired"
      [assignedTo]="assignedTo"
      (onEvent)="action($event)"
      *ngIf="expanded"
    >
    </ca-tasks-advanced-search>
  </div>

  <div class="d-flex mx-n1">
    <div class="input-group mx-1 my-1">
      <div class="custom-control custom-switch mr-3 mt-2">
        <input
          [(ngModel)]="groupBy"
          (change)="onEvent.emit({ action: 'toggleGroupBy', data: groupBy })"
          type="checkbox"
          class="custom-control-input"
          id="groupBy"
        />
        <label class="custom-control-label" for="groupBy">{{
          'tasks.task.groupByObject' | translate
        }}</label>
      </div>

      <button
        (click)="onEvent.emit({ action: 'add' })"
        type="button"
        class="btn btn-primary"
      >
        <i class="fa-regular fa-plus fa-fw"></i>
        {{ 'shared.terms.new' | translate }}
      </button>
      <div class="input-group-append">
        <button
          type="button"
          class="btn btn-primary dropdown-toggle"
          data-toggle="dropdown"
        ></button>
        <div class="dropdown-menu">
          <button
            (click)="onEvent.emit({ action: 'addFromTaskDefinitionList' })"
            type="button"
            class="btn dropdown-item"
          >
            <i class="fa-regular fa-plus fa-fw"></i>
            {{ 'tasks.task.addFromTaskDefinitionList' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
