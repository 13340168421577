<ng-container
  *shEmptyPlaceholder="isLoading; items: 'attributes:1'"
  [ngSwitch]="widget.bodyType"
>
  <ca-expired-object-task-group-display-card
    *ngSwitchCase="taskWidgetType.Expired"
    [taskGroups]="data"
  ></ca-expired-object-task-group-display-card>
  <ca-tasks-card
    *ngSwitchCase="taskWidgetType.TopActiveTasks"
    [tasks]="data"
    [allowNavigateToTaskPage]="true"
  ></ca-tasks-card>
</ng-container>
