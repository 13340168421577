<table
  class="table table-truncated"
  [ngClass]="{ 'table-clickable': allowSelection }"
>
  <thead>
    <tr>
      <th
        style="width: 1%"
        class="pl-3 pr-0 checkbox-cell"
        (click)="checkAll($event)"
        *ngIf="showCheckboxes"
      >
        <div class="custom-control custom-checkbox">
          <input
            type="checkbox"
            class="custom-control-input"
            [id]="'customCheckAll' + idDiscriminator"
          />
          <label
            class="custom-control-label"
            [for]="'customCheckAll' + idDiscriminator"
          ></label>
        </div>
      </th>
      <th
        class="text-truncate"
        style="max-width: 700px"
        *ngFor="let column of columns"
        title="{{ column.displayName }}"
        [style.width]="column.width ? column.width : hasFixWidth ? '' : '1%'"
      >
        {{ column.displayName }}
      </th>
      <th style="width: 1%" *ngIf="connectedDataIconConfig"></th>
    </tr>
  </thead>
  <tbody>
    <tr
      *ngFor="let entry of entries"
      [ngClass]="{
        selected: selectedEntry && selectedEntry.__Id === entry.__Id,
        approved: approvedField && entry[approvedField]
      }"
    >
      <td
        class="pl-3 pr-0 checkbox-cell"
        style="width: 1%"
        (click)="toggleChecked(entry)"
        *ngIf="showCheckboxes"
      >
        <div class="custom-control custom-checkbox">
          <input
            type="checkbox"
            class="custom-control-input"
            [(ngModel)]="entry['isChecked']"
            [ngModelOptions]="{ standalone: true }"
          />
          <label class="custom-control-label"></label>
        </div>
      </td>
      <td
        *ngFor="let column of columns"
        [ngClass]="{ 'text-truncate': column.allowOverflow === true }"
        title="{{
          entry.__AdditionalData && entry.__AdditionalData[column.fieldName]
            ? (entry.__AdditionalData[column.fieldName]
              | customValueDisplay: fields[column.fieldName]:column
              | async)
            : (entry[column.fieldName]
              | customValueDisplay: fields[column.fieldName]:column
              | async)
        }}"
        [style.width]="column.width"
        (click)="selectEntry(entry)"
      >
        {{
          entry.__AdditionalData && entry.__AdditionalData[column.fieldName]
            ? (entry.__AdditionalData[column.fieldName]
              | customValueDisplay: fields[column.fieldName]:column
              | async)
            : (entry[column.fieldName]
              | customValueDisplay: fields[column.fieldName]:column
              | async)
        }}
      </td>
      <td
        style="white-space: nowrap; width: 1%"
        *ngIf="connectedDataIconConfig"
      >
        <sh-badge-list
          [badges]="connectedDataBadges[entry.__Id]"
          (badgeClicked)="badgeClicked($event, entry)"
        ></sh-badge-list>
      </td>
    </tr>
  </tbody>
  <tfoot *ngIf="footers && footers.length">
    <tr>
      <td *ngIf="showCheckboxes"></td>
      <td *ngFor="let column of columns">
        <ng-container *ngFor="let footer of footers">
          <ng-container *ngIf="footer.key === column.fieldName">
            {{ footer.value }}
          </ng-container>
        </ng-container>
      </td>
      <td style="width: 1%" *ngIf="connectedDataIconConfig"></td>
    </tr>
  </tfoot>
</table>
