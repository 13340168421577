import { Component, Input, Output, EventEmitter } from '@angular/core';

import { EventModel } from '../../../../shared/models/event.model';
import { CompanyAddressModel } from '@seahorse/domain';

@Component({
  selector: 'ca-company-addresses-list',
  templateUrl: 'company-addresses-list.component.html',
})
export class CompanyAddressesListComponent {
  @Input() addresses: CompanyAddressModel[];
  @Input() showHeader: boolean;

  @Output() onEvent: EventEmitter<EventModel>;

  constructor() {
    this.addresses = [];
    this.showHeader = false;
    this.onEvent = new EventEmitter<EventModel>();
  }
}
