import { Injectable } from '@angular/core';

@Injectable()
export class NauticalShipLogicService {
  defaultShipFields = [
    this.field('dimensions', 'gt'),
    this.field('dimensions', 'loa'),
    this.field('dimensions', 'nt'),
    this.field('dimensions', 'maxdraught'),
    this.field('dimensions', 'dwt'),
    this.field('dimensions', 'width'),
  ];

  field(category, fieldName) {
    return {
      category: category,
      fieldName: fieldName,
      fieldValue: null,
      id: null,
      shipId: null,
      sourceId: 0,
      startsOn: null,
      endsOn: null,
      owner: null,
    };
  }
}
