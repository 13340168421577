import { CustomDataBaseModel } from '@seahorse/domain';
import { NauticalShipModel } from '@seahorse/domain';

export class CVVActiveVisitModel extends CustomDataBaseModel {
  $nautical_portmovement_id: number;
  $nautical_portvisit_id: number;
  $nautical_ship_id: number;
  heavyEquipment: boolean;
  motorboat: number;
  persons: string;
  remarks: string;
  status: string;
  winches: string;

  // for display
  $nautical_ship: NauticalShipModel;

  constructor() {
    super();

    this.$nautical_portmovement_id = null;
    this.$nautical_portvisit_id = null;
    this.$nautical_ship_id = null;
    this.heavyEquipment = null;
    this.motorboat = null;
    this.persons = null;
    this.remarks = null;
    this.status = null;
    this.winches = null;
  }
}

export enum CVVActiveVisitStatus {
  Active = 'Active',
  Completed = 'Completed',
  Ignored = 'Ignored',
}
