<div class="page-with-header page-with-master-detail">
  <!-- Header -->
  <invoicing-flows-header
    [viewOptions]="viewOptions"
    [selectedView]="selectedView"
    [resultCount]="invoicingFlowsCount"
    [search]="invoicingFlowsQuery.search"
    (onEvent)="action($event)"
  ></invoicing-flows-header>
  <!-- Content -->
  <div class="container-fluid py-3">
    <div
      [ngClass]="{ 'page-master-detail-visible' : selectedInvoicingFlow }"
      class="page-master-wrapper"
    >
      <div class="page-master-wrapper-inner">
        <ng-container
          *ngIf="invoicingFlowsCount > 0; then showInvoicingFlows; else noInvoicingFlows"
        ></ng-container>

        <ng-template #showInvoicingFlows>
          <ng-container [ngSwitch]="selectedView">
            <invoicing-flows-list
              *ngSwitchCase="CollectionDisplay.List"
              [invoicingFlows]="invoicingFlows"
              [selectedInvoicingFlow]="selectedInvoicingFlow"
              (onEvent)="action($event)"
            ></invoicing-flows-list>
            <invoicing-flows-tiles
              *ngSwitchCase="CollectionDisplay.Tiles"
              [invoicingFlows]="invoicingFlows"
              [selectedInvoicingFlow]="selectedInvoicingFlow"
              (onEvent)="action($event)"
            ></invoicing-flows-tiles>
          </ng-container>
        </ng-template>

        <ng-template #noInvoicingFlows>
          <div *ngIf="!loadingInvoicingFlows" class="card-body">
            <span class="text-muted"
              >{{ 'invoicingFlows.overview.noInvoicingFlows' | translate
              }}</span
            >
          </div>
        </ng-template>

        <sh-loading-indicator
          *ngIf="loadingInvoicingFlows"
          class="d-block text-center"
        ></sh-loading-indicator>

        <button
          *ngIf="indexedInvoicingFlows"
          [disabled]="loadingInvoicingFlows"
          (click)="moreInvoicingFlows()"
          type="button"
          class="btn btn-primary btn-block btn-lg my-3"
        >
          {{ 'shared.terms.loadMore' | translate }}
        </button>
      </div>
    </div>
    <div *ngIf="selectedInvoicingFlow" class="page-detail-wrapper">
      <invoicing-flows-summary
        [selectedInvoicingFlow]="selectedInvoicingFlow"
        (onEvent)="action($event)"
      ></invoicing-flows-summary>
    </div>
  </div>
</div>
