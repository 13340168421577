import { Component, Input, Output, EventEmitter } from '@angular/core';
import * as _ from 'underscore';
import { AttributeModel } from '../../../shared/models/attribute.model';
import {
  OrderRegistrationStatus,
  OrderRegistrationCurrency,
} from '@seahorse/domain';

@Component({ templateUrl: 'attribute-picker-modal.component.html' })
export class AttributePickerModalComponent {
  @Input() set attribute(a: AttributeModel) {
    if (a) {
      this._attribute = _.clone(a);

      switch (a.type) {
        case 'dateTime':
          {
          }
          break;

        case 'orderRegistrationStatus':
          {
            this.typeList = _.filter(_.toArray(OrderRegistrationStatus), (r) =>
              _.isNumber(r)
            );
          }
          break;

        case 'orderRegistrationCurrency':
          {
            this.typeList = _.toArray(OrderRegistrationCurrency);
          }
          break;
      }
    } else {
      this._attribute = null;
    }
  }
  get attribute() {
    return this._attribute;
  }

  @Output() onConfirm: EventEmitter<AttributeModel>;

  private _attribute: AttributeModel;

  typeList: (
    | string
    | OrderRegistrationStatus
    | OrderRegistrationCurrency
  )[];

  constructor() {
    this.attribute = null;
    this.onConfirm = new EventEmitter<AttributeModel>();
    this._attribute = null;
    this.typeList = [];
  }

  setNull() {
    this.attribute.value = null;

    if (this.attribute.type == 'dateTime') {
    }
  }
}
