import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@seahorse/common';
import { NauticalWaypointModel } from '@seahorse/domain';
import { NauticalGeographyDataService } from '../../../nautical-geography/services/nautical-geography-data.service';
import { Observable, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  switchMap,
  catchError,
  tap,
  map,
} from 'rxjs/operators';
import * as _ from 'underscore';

@Component({
  selector: 'ca-nautical-waypoint-picker',
  templateUrl: 'nautical-waypoint-picker.component.html',
})
export class NauticalWaypointPickerComponent implements OnInit {
  @Input() allowTypeAhead = true;
  @Input() filter: string = null;
  @Input() isRequired = false;
  @Input() nauticalWaypoint: NauticalWaypointModel;
  @Input() onlyWithGeography = false;
  @Input() portId: number = null;

  @Output() nauticalWaypointChange = new EventEmitter<NauticalWaypointModel>();

  nauticalWaypointsLoading = false;
  nauticalWaypointsLoadingFailed = false;
  waypoints: NauticalWaypointModel[] = [];

  constructor(
    private notification: NotificationService,
    private nauticalGeographyData: NauticalGeographyDataService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    if (!this.allowTypeAhead) {
      this.loadWaypoints();
    }
  }

  codeSelected(selectedCode: string) {
    this.nauticalWaypoint = _.find(
      this.waypoints,
      (wp) => wp.code === selectedCode
    );
    this.nauticalWaypointChange.emit(this.nauticalWaypoint);
  }

  itemSelected(itemEvent) {
    if (itemEvent && itemEvent.item) {
      this.nauticalWaypointChange.emit(itemEvent.item);
    } else {
      this.nauticalWaypointChange.emit(null);
    }
  }

  loadWaypoints() {
    this.nauticalWaypointsLoadingFailed = false;
    this.nauticalWaypointsLoading = true;
    this.nauticalGeographyData
      .getWaypointsByPort(this.portId, null, this.filter)
      .subscribe(
        (result) => {
          this.waypoints = result;
          this.nauticalWaypointsLoading = false;
        },
        (error) => {
          this.nauticalWaypointsLoading = false;
          this.nauticalWaypointsLoadingFailed = true;
        }
      );
  }

  nauticalWaypointFormatter = (
    nauticalWaypoint: NauticalWaypointModel
  ): string =>
    nauticalWaypoint && nauticalWaypoint.name
      ? nauticalWaypoint.name.toUpperCase()
      : '';

  nauticalWaypointsFormatter = (
    nauticalWaypoint: NauticalWaypointModel
  ): string => {
    if (nauticalWaypoint.code && nauticalWaypoint.name) {
      return (
        nauticalWaypoint.name.toUpperCase() + ' (' + nauticalWaypoint.code + ')'
      );
    } else {
      return nauticalWaypoint.name.toUpperCase();
    }
  };

  searchnauticalWaypoint = (input$: Observable<string>): any =>
    input$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.nauticalWaypointsLoading = true)),
      switchMap((s) =>
        this.nauticalGeographyData
          .getWaypointsByPort(this.portId, s, this.filter)
          .pipe(
            tap(() => (this.nauticalWaypointsLoadingFailed = false)),
            map((r) => r),
            catchError((e) => {
              this.nauticalWaypointsLoadingFailed = true;

              this.notification.showError(
                e,
                this.translate.instant('shared.terms.failed')
              );
              return of([]);
            })
          )
      ),

      tap(() => (this.nauticalWaypointsLoading = false))
    );

  setNull = () => (this.nauticalWaypoint = null);
}
