import { Component } from '@angular/core';

@Component({
  selector: 'sh-card',
  template: `
    <div class="card">
      <div class="card-header">
        <ng-content select="[header]"></ng-content>
      </div>
      <div class="card-body">
        <ng-content></ng-content>
      </div>
      <div>
        <ng-content select="[footer]"></ng-content>
      </div>
    </div>
  `,
  styles: [
    `
      :host[no-padding] .card-body {
        padding: 0;
      }
      :host[no-header] .card-header {
        display: none;
      }
    `,
  ],
  standalone: true,
})
export class CardComponent {}
