import {
  Component,
  OnInit,
  Input,
  Output,
  OnDestroy,
  EventEmitter,
  Inject,
} from '@angular/core';

import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import { CompanyAddressModel } from '@seahorse/domain';
import { AddressModalComponent } from '../../address/address-modal/address-modal.component';
import { InteractionService } from '../../../../shared/services/interaction.service';
import { CompanyDataService } from '@seahorse/domain';
import { EventModel } from '../../../../shared/models/event.model';

@Component({
  selector: 'company-addresses-card',
  templateUrl: 'company-addresses-card.component.html',
})
export class CompanyAddressesCardComponent implements OnInit, OnDestroy {
  @Input()
  set companyId(companyId: number | null) {
    this._companyId = companyId;
    if (companyId) this.reloadAddresses();
    else this.addresses = [];
  }
  get companyId(): number | null {
    return this._companyId;
  }

  @Output() onEvent: EventEmitter<EventModel>;

  canLoadMore = false;
  pageIndex = 0;
  pageSize = 5;
  addresses: CompanyAddressModel[] = [];
  isLoading = false;

  private subscription: Subscription;
  private _companyId: number | null;

  constructor(
    @Inject('AddressInteractionService')
    private addressInteractionService: InteractionService,
    private companyDataService: CompanyDataService,
    private modalService: NgbModal
  ) {
    this.onEvent = new EventEmitter<EventModel>();

    this.subscription = this.addressInteractionService.modifyCalled$.subscribe(
      () => {
        this.reloadAddresses();
      }
    );

    this.addressInteractionService.deleteCalled$.subscribe(() => {
      this.reloadAddresses();
    });
  }

  ngOnInit() {
    this.pageIndex = 0;
    this.loadAddresses();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  reloadAddresses() {
    this.pageIndex = 0;
    this.loadAddresses();
  }

  loadMore() {
    this.pageIndex += 1;
    this.loadAddresses();
  }

  loadAddresses() {
    this.isLoading = true;
    this.companyDataService
      .getAddresses(this.companyId, this.pageIndex, this.pageSize)
      .subscribe(
        (result) => {
          if (this.pageIndex > 0) {
            this.addresses = this.addresses.concat(result.result);
          } else {
            this.addresses = result.result;
          }
          this.canLoadMore = this.addresses.length < result.count;
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        }
      );
  }

  itemAction(action: any) {
    if (action.action) {
      switch (action.action) {
        case 'delete':
          this.delete(action.address);
          break;
        case 'edit':
        case 'select':
          this.selectAddress(action.address);
          break;
      }
    }
  }

  selectAddress(address: CompanyAddressModel) {
    const ngbModalOptions: NgbModalOptions = { backdrop: 'static' };
    const modalRef = this.modalService.open(
      AddressModalComponent,
      ngbModalOptions
    );

    if (address != null) {
      modalRef.componentInstance.address = address;
    }
  }

  delete(address: CompanyAddressModel) {
    // this.addressInteractionService.delete(address);
  }
}
