import * as _ from 'underscore';

export class MapMarkersModel {
  passengerColor!: MapMarkerColor;
  cargoColor!: MapMarkerColor;
  tankerColor!: MapMarkerColor;
  pilotColor!: MapMarkerColor;
  pleasureColor!: MapMarkerColor;
  fishingColor!: MapMarkerColor;
  defaultColor!: MapMarkerColor;
  shapeAlwaysOn!: boolean;
  nameAlwaysOn!: boolean;
  colorOnSailing!: boolean;
  useLargePopup!: boolean;
  showTrackOnSelection?: boolean;

  constructor(override?: Partial<MapMarkersModel>) {
    this.passengerColor = MapMarkerColor.LIGHTBLUE;
    this.cargoColor = MapMarkerColor.GREEN;
    this.tankerColor = MapMarkerColor.RED;
    this.pilotColor = MapMarkerColor.ORANGE;
    this.pleasureColor = MapMarkerColor.PURPLE;
    this.fishingColor = MapMarkerColor.DARKBLUE;
    this.defaultColor = MapMarkerColor.GREY;
    this.shapeAlwaysOn = false;
    this.nameAlwaysOn = false;
    this.colorOnSailing = false;
    this.showTrackOnSelection = false;
    this.useLargePopup = false;

    if (override) {
      const candidateModel: MapMarkersModel = Object.assign(
        new MapMarkersModel(),
        override
      );

      Object.assign(this, _.pick(candidateModel, _.identity));
    }
  }
}

export enum MapMarkerColor {
  CYAN = 'cyan',
  DARKBLUE = 'darkblue',
  GREEN = 'green',
  GREY = 'grey',
  LIGHTBLUE = 'lightblue',
  MAGENTA = 'magenta',
  ORANGE = 'orange',
  PINK = 'pink',
  PURPLE = 'purple',
  RED = 'red',
  YELLOW = 'yellow',
}
