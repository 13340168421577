<form>
  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label class="control-label required"
          ><b>{{ 'systemMessage.release.releasedOn' | translate }}</b></label
        >
        <div class="input-group">
          <temp-date-time-picker
            [(dateTime)]="model.releasedOn"
            [dateTimeFormat]="'YYYY-MM-DD[T00:00:00.000Z]'"
            [isContained]="true"
            [hideTime]="true"
            [hideNow]="true"
          ></temp-date-time-picker>
        </div>
        <small *ngIf="submitted && !model.releasedOn" class="text-danger">{{
          'systemMessage.release.errors.releasedOnEmpty' | translate
        }}</small>
      </div>
    </div>
    <div class="col-6">
      <div class="form-group">
        <label class="control-label"
          ><b>{{ 'systemMessage.release.endsOn' | translate }}</b></label
        >
        <div class="input-group">
          <temp-date-time-picker
            [(dateTime)]="model.endsOn"
            [dateTimeFormat]="'YYYY-MM-DD[T00:00:00.000Z]'"
            [isContained]="true"
            [hideTime]="true"
            [hideNow]="true"
          ></temp-date-time-picker>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="form-group">
        <label class="control-label required"
          ><b>{{
            'systemMessage.release.systemMessageChannel' | translate
          }}</b></label
        >
        <ng-select
          #channel
          name="channel"
          [items]="systemMessageChannels"
          placeholder="Select a channel"
          bindValue="id"
          bindLabel="description"
          [clearable]="true"
          required
          [searchable]="true"
          [(ngModel)]="model.systemMessageChannelId"
        >
        </ng-select>
        <small
          *ngIf="submitted && !model.systemMessageChannelId"
          class="text-danger"
          >{{ 'systemMessage.release.errors.channelEmpty' | translate }}</small
        >
      </div>
    </div>
  </div>
</form>
