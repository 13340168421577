import { Injectable } from '@angular/core';
import * as _ from 'underscore';
import { Observable } from 'rxjs';
import { ResultWrapper } from '@seahorse/common';
import { QueryModel } from '../../../../shared/models/query.model';
import {
  CustomDataContentService,
  CustomDataSearchFieldModel,
  SearchOperators,
} from '@seahorse/domain';
import { TaskDefinitionListModel } from '../models/task-definition-list.model';
import { TaskDefinitionModel } from '../models/task-definition.model';
import { TaskDefinitionDataService } from './task-definition-data.service';

@Injectable()
export class TaskDefinitionListDataService {
  constructor(
    private customData: CustomDataContentService,
    private taskDefinitionData: TaskDefinitionDataService
  ) {}

  getAll(): Observable<ResultWrapper<TaskDefinitionListModel[]>> {
    return this.customData.getAllCustomerData('taskDefinitionList');
  }

  search(
    query: QueryModel
  ): Observable<ResultWrapper<TaskDefinitionListModel[]>> {
    const fields = [new CustomDataSearchFieldModel()];
    fields.push({
      fieldName: 'name',
      searchOperator: SearchOperators.Contains,
      searchValue: query.search,
    });

    return this.customData.searchActiveCustomerDataByFields(
      'taskDefinitionList',
      fields,
      null,
      null,
      0,
      500
    );
  }

  getById(
    taskDefintionListId: TaskDefinitionListModel['__Id']
  ): Observable<ResultWrapper<TaskDefinitionListModel>> {
    return new Observable<ResultWrapper<TaskDefinitionListModel>>((s) => {
      this.customData
        .getCustomerDataByIds('taskDefinitionList', [taskDefintionListId])
        .subscribe(
          (r: ResultWrapper<TaskDefinitionListModel[]>) => {
            const w: ResultWrapper<TaskDefinitionListModel> = _.extend(
              _.omit(r, 'result'),
              { result: r.hasResult ? r.result[0] : null }
            );

            s.next(w);
          },
          (e) => {
            s.error(e);
          },
          () => {
            s.complete();
          }
        );
    });
  }

  add(
    taskDefinitionList: TaskDefinitionListModel
  ): Observable<ResultWrapper<TaskDefinitionListModel>> {
    return this.customData.addCustomerData(
      'taskDefinitionList',
      taskDefinitionList
    );
  }

  update(
    taskDefinitionList: TaskDefinitionListModel
  ): Observable<ResultWrapper<TaskDefinitionListModel>> {
    return this.customData.updateCustomerData(
      'taskDefinitionList',
      taskDefinitionList.__Id,
      taskDefinitionList
    );
  }

  delete(
    taskDefinitionListId: TaskDefinitionListModel['__Id']
  ): Observable<
    ObservableWrapper<
      ResultWrapper<
        TaskDefinitionListModel | TaskDefinitionModel | TaskDefinitionModel[]
      >
    >
  > {
    return new Observable<
      ObservableWrapper<
        ResultWrapper<
          TaskDefinitionListModel | TaskDefinitionModel | TaskDefinitionModel[]
        >
      >
    >((s) => {
      this.customData
        .deleteCustomerData('taskDefinitionList', taskDefinitionListId)
        .subscribe(
          (rTaskDefinitionList: ResultWrapper<TaskDefinitionListModel>) => {
            s.next({ title: 'taskDefinitionList', value: rTaskDefinitionList });

            if (rTaskDefinitionList.hasResult) {
              this.taskDefinitionData
                .getByListId(rTaskDefinitionList.result.__Id)
                .subscribe(
                  (rTaskDefinitions: ResultWrapper<TaskDefinitionModel[]>) => {
                    s.next({
                      title: 'taskDefinitions',
                      value: rTaskDefinitions,
                    });

                    if (
                      rTaskDefinitions.hasResult &&
                      rTaskDefinitions &&
                      rTaskDefinitions.result.length
                    ) {
                      _.forEach(rTaskDefinitions.result, (r) => {
                        this.taskDefinitionData.delete(r.__Id).subscribe(
                          (
                            rTaskDefinition: ResultWrapper<TaskDefinitionModel>
                          ) => {
                            s.next({
                              title: 'taskDefinition',
                              value: rTaskDefinition,
                            });
                          },
                          (e) => {
                            s.error(e);
                          }
                        );
                      });
                    }
                  },
                  (e) => {
                    s.error(e);
                  }
                );
            }
          },
          (e) => {
            s.error(e);
          }
        );
    });
  }
}

export class ObservableWrapper<ResultWrapper> {
  title: string;
  value: ResultWrapper;

  constructor() {
    this.title = null;
    this.value = null;
  }
}
