<div class="row">
  <div class="col-5">
    <select
      class="form-control"
      [(ngModel)]="operator"
      (change)="changeOperator()"
    >
      <option *ngFor="let operatorType of operatorTypes" [value]="operatorType">
        {{ operatorType | fieldOperator }}
      </option>
    </select>
  </div>
  <div class="col-7">
    <ng-container *ngIf="operator != 7">
      <input
        type="text"
        [(ngModel)]="value1"
        (change)="changeFieldValue()"
        class="form-control"
      />
    </ng-container>
    <ng-container *ngIf="operator == 7">
      <div class="form-inline">
        <input
          type="text"
          [(ngModel)]="value1"
          (change)="changeFieldValue()"
          class="mr-2 form-control"
          style="max-width: 100px"
        />
        {{ 'shared.terms.and' | translate }}
        <input
          type="text"
          [(ngModel)]="value2"
          (change)="changeFieldValue()"
          class="ml-2 form-control"
          style="max-width: 100px"
        />
      </div>
    </ng-container>
  </div>
</div>
