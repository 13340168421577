<div class="modal-header">
  <div class="modal-title">
    {{ 'customers.kp.completeDialogTitle' | translate }}
  </div>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="modal.close(null)"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form *ngIf="activity">
    <div class="form-group">
      {{ 'customers.kp.completeDialogText' | translate }}
    </div>
    <div
      class="row"
      *ngIf="
        activityTypeGroup === 'meren' ||
        activityTypeGroup === 'ontm' ||
        activity.activityType === 'trossen'
      "
    >
      <div class="col-12">
        <div class="form-group input-group">
          <div class="input-group-prepend">
            <div class="input-group-text">
              <input
                type="checkbox"
                id="completeActivityMotorboat1"
                aria-label="Checkbox for following text input"
                name="completeActivityMotorboat1"
                [(ngModel)]="activity.motorboat1"
              />
            </div>
          </div>
          <label
            class="form-control"
            for="completeActivityMotorboat1"
            title="{{
              (activity.activityType === 'verhalen'
                ? 'customers.kp.fields.motorboat1'
                : 'customers.kp.fields.motorboat'
              ) | translate
            }}"
          >
            {{
              (activity.activityType === 'verhalen'
                ? 'customers.kp.fields.motorboat1'
                : 'customers.kp.fields.motorboat'
              ) | translate
            }}
          </label>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="activity.activityType === 'verhalen'">
      <div class="col-12">
        <div class="form-group input-group">
          <div class="input-group-prepend">
            <div class="input-group-text">
              <input
                type="checkbox"
                id="completeActivityMotorboat2"
                aria-label="Checkbox for following text input"
                name="completeActivityMotorboat2"
                [(ngModel)]="activity.motorboat2"
              />
            </div>
          </div>
          <label
            class="form-control"
            for="completeActivityMotorboat2"
            title="{{ 'customers.kp.fields.motorboat2' | translate }}"
          >
            {{ 'customers.kp.fields.motorboat2' | translate }}
          </label>
        </div>
      </div>
    </div>
    <div
      class="row"
      *ngIf="activityTypeGroup === 'meren' || activityTypeGroup === 'ontm'"
    >
      <div class="col-12">
        <div class="form-group input-group">
          <div class="input-group-prepend">
            <div class="input-group-text">
              <input
                type="checkbox"
                id="completeActivityHasWaitingTime"
                aria-label="Checkbox for following text input"
                name="completeActivityHasWaitingTime"
                [(ngModel)]="activity.hasWaitingTime"
              />
            </div>
          </div>
          <label
            class="form-control"
            for="completeActivityHasWaitingTime"
            title="{{ 'customers.kp.fields.hasWaitingTime' | translate }}"
          >
            {{ 'customers.kp.fields.hasWaitingTime' | translate }}
          </label>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="activity.hasWaitingTime">
      <div
        class="col-12"
        title="{{ 'customers.kp.fields.hasWaitingTime' | translate }}"
      >
        <div class="form-group input-group">
          <input
            type="text"
            id="completeActivityWaitingTime"
            class="form-control"
            name="completeActivityWaitingTime"
            [(ngModel)]="activity.waitingTime"
          />
          <div class="input-group-append">
            <label class="input-group-text" for="completeActivityWaitingTime">
              min
            </label>
          </div>
        </div>
        <div class="form-group">
          <textarea
            rows="3"
            class="form-control"
            name="completeActivityWaitingTimeReason"
            placeholder="{{
              'customers.kp.fields.waitingTimeReason' | translate
            }}..."
            [(ngModel)]="activity.waitingTimeReason"
          ></textarea>
        </div>
      </div>
    </div>
    <div
      class="row"
      *ngIf="activityTypeGroup === 'meren' || activityTypeGroup === 'ontm'"
    >
      <div class="col-12">
        <div class="form-group input-group">
          <div class="input-group-prepend">
            <div class="input-group-text">
              <input
                type="checkbox"
                id="completeActivityCancelledOrder"
                aria-label="Checkbox for following text input"
                name="completeActivityCancelledOrder"
                [(ngModel)]="activity.cancelledOrder"
              />
            </div>
          </div>
          <label
            class="form-control"
            for="completeActivityCancelledOrder"
            title="{{ 'customers.kp.fields.cancelledOrder' | translate }}"
          >
            {{ 'customers.kp.fields.cancelledOrder' | translate }}
          </label>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="activity.cancelledOrder">
      <div class="col-12">
        <div class="form-group">
          <textarea
            rows="3"
            class="form-control"
            name="completeActivityCancelledReason"
            placeholder="{{
              'customers.kp.fields.cancelledReason' | translate
            }}..."
            [(ngModel)]="activity.cancelledReason"
          ></textarea>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="form-group input-group">
          <div class="input-group-prepend">
            <div class="input-group-text">
              <input
                type="checkbox"
                id="completeActivityWeekendTariffOff"
                aria-label="Checkbox for following text input"
                name="completeActivityWeekendTariffOff"
                [(ngModel)]="activity.weekendTariffOff"
              />
            </div>
          </div>
          <label
            class="form-control"
            for="completeActivityWeekendTariffOff"
            title="{{ 'customers.kp.fields.weekendTariffOff' | translate }}"
          >
            {{ 'customers.kp.fields.weekendTariffOff' | translate }}
          </label>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="activity.activityType === 'materiaal'">
      <div
        class="col-12"
        title="{{ 'customers.kp.fields.plastic' | translate }}"
      >
        <div class="form-group input-group">
          <div class="input-group-prepend">
            <div class="input-group-text">
              <input
                type="checkbox"
                id="completeActivityPlastic"
                aria-label="Checkbox for following text input"
                name="completeActivityPlastic"
                [(ngModel)]="activity.plastic"
              />
            </div>
          </div>
          <label class="form-control" for="completeActivityPlastic">
            {{ 'customers.kp.fields.plastic' | translate }}
          </label>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="activity.subType === 'palletsaan'">
      <div
        class="col-12"
        title="{{ 'customers.kp.palletsFromBoard' | translate }}"
      >
        <div class="form-group input-group">
          <input
            type="number"
            id="completeActivityPallets"
            class="form-control"
            name="completeActivityPallets"
            [(ngModel)]="quantity"
          />
          <div class="input-group-append">
            <label class="input-group-text" for="completeActivityPallets">
              {{ 'customers.kp.palletsFromBoard' | translate }}
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div
        class="col-12"
        title="{{ 'customers.kp.fields.invoiceWarning' | translate }}"
      >
        <div class="form-group input-group">
          <div class="input-group-prepend">
            <div class="input-group-text">
              <input
                type="checkbox"
                id="completeActivityInvoiceWarning"
                aria-label="Checkbox for following text input"
                name="completeActivityInvoiceWarning"
                [(ngModel)]="activity.invoice_warning"
              />
            </div>
          </div>
          <label class="form-control" for="completeActivityInvoiceWarning">
            {{ 'customers.kp.fields.invoiceWarning' | translate }}
          </label>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="errors && errors.length > 0">
      <div class="col-12">
        <div class="alert alert-danger">
          <p>{{ 'customers.kp.fieldsErrors' | translate }}</p>
          <ul>
            <li *ngFor="let error of errors">{{ error | translate }}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="d-flex">
      <div class="flex-grow-1">
        <button
          type="button"
          class="btn btn-block btn-link"
          (click)="modal.close(null)"
        >
          {{ 'shared.terms.cancel' | translate }}
        </button>
      </div>
      <div class="flex-grow-1">
        <button
          type="button"
          class="btn btn-block btn-success"
          (click)="save()"
        >
          {{ 'shared.terms.save' | translate }}
        </button>
      </div>
    </div>
  </form>
</div>
