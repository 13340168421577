<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title-bold">
      {{ isNew ? 'Add' : 'Edit' }} my fleet group
    </h4>
    <button type="button" class="close" closeModal>
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ca-group-form [model]="group" [submitted]="isSubmitted"> </ca-group-form>

    <sh-loading-indicator
      class="text-center d-block"
      *ngIf="saveStarted"
    ></sh-loading-indicator>
  </div>

  <div class="modal-footer">
    <button class="btn btn-success" [disabled]="saveStarted" (click)="save()">
      <i class="fa-regular fa-save"></i> {{ 'shared.terms.save' | translate }}
    </button>
  </div>
</div>
