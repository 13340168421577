import { Injectable } from '@angular/core';
import { DataContextService } from '@seahorse/common';

import { UiModuleDefinition } from '@seahorse/domain';

@Injectable({
  providedIn: 'root',
})
export class UiModuleDefinitionsDataService {
  private readonly _baseUrl = 'customdata/uimodule-definition';

  constructor(private _dataContextService: DataContextService) {}

  getDefinitionById(id: number) {
    return this._dataContextService.get<UiModuleDefinition>(
      `${this._baseUrl}/${id}`
    );
  }

  getAllDefinitions() {
    return this._dataContextService.get<UiModuleDefinition[]>(
      `${this._baseUrl}`
    );
  }

  searchDefinitions(search?: string) {
    const url =
      search !== undefined && search !== null
        ? `${this._baseUrl}/search?search=${encodeURIComponent(search)}`
        : `${this._baseUrl}/search`;

    return this._dataContextService.get<UiModuleDefinition[]>(url);
  }

  update(moduleDefinition: UiModuleDefinition) {
    return this._dataContextService.put<UiModuleDefinition>(
      `${this._baseUrl}/definitions/${moduleDefinition.id}`,
      moduleDefinition
    );
  }

  add(uiModuleDefinition: UiModuleDefinition) {
    return this._dataContextService.post<UiModuleDefinition>(
      `${this._baseUrl}`,
      uiModuleDefinition
    );
  }
}
