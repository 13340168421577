import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ContentDistributionRecipientModel } from '../../../content-distribution/models/content-distribution-recipient.model';
import { OrderRegistrationsConstant } from '../../../order-registrations/constants/order-registrations.constant';
import { EventModel } from '../../../shared/models/event.model';

@Component({
  selector: 'ca-order-registration-details-header',
  templateUrl: 'order-registration-details-header.component.html',
})
export class OrderRegistrationDetailsHeaderComponent {
  @Input() orderId: string;
  @Input() portCallFileId: string;
  @Input() loading: boolean;
  @Input() hasDate: boolean;
  @Input() hasCompany: boolean;
  @Input() recipients: ContentDistributionRecipientModel[] =
    [] as ContentDistributionRecipientModel[];
  @Input() orderRegistrationSystemCode?: string;

  @Output() onEvent: EventEmitter<EventModel>;

  mappingKey = OrderRegistrationsConstant.OBJECTTYPEID;

  constructor() {
    this.loading = false;
    this.hasDate = false;
    this.hasCompany = false;
    this.onEvent = new EventEmitter<EventModel>();
  }
}
