<div class="card">
  <table class="table table-clickable">
    <thead>
      <tr>
        <th class="icon" scope="col"></th>
        <th class="icon" scope="col"></th>
        <th scope="col">{{ 'tasks.task.model.subject' | translate }}</th>
        <th scope="col">{{ 'shared.terms.description' | translate }}</th>
        <th scope="col">{{ 'tasks.task.model.assignedTo' | translate }}</th>
        <th scope="col">{{ 'tasks.task.model.dueDate' | translate }}</th>
      </tr>
    </thead>

    <tbody>
      <tr
        *ngFor="let task of tasks"
        [ngClass]="{ selected: selected && selected.id == task.id }"
        (click)="onEvent.emit({ action: 'select', data: task })"
      >
        <td class="icon" title="{{ task.taskType | taskType }}">
          <ng-container [ngSwitch]="task.taskType">
            <i
              *ngSwitchCase="TaskType.Call"
              class="fa-solid fa-phone-alt fa-fw"
            ></i>
            <i
              *ngSwitchCase="TaskType.SendEmail"
              class="fa-solid fa-envelope fa-fw"
            ></i>
            <i
              *ngSwitchCase="TaskType.Visit"
              class="fa-solid fa-exchange-alt fa-fw"
            ></i>
            <i
              *ngSwitchCase="TaskType.Process"
              class="fa-solid fa-microchip fa-fw"
            ></i>
            <i
              *ngSwitchCase="TaskType.Website"
              class="fa-regular fa-globe-pointer fa-fw"
            ></i>
          </ng-container>
        </td>
        <td class="icon" title="{{ task.status | taskStatus }}">
          <ng-container [ngSwitch]="task.status">
            <i
              *ngSwitchCase="TaskStatus.Todo"
              class="fa-solid fa-list-ul fa-fw text-primary"
            ></i>
            <i
              *ngSwitchCase="TaskStatus.Done"
              class="fa-solid fa-check fa-fw text-success"
            ></i>
            <i
              *ngSwitchCase="TaskStatus.Cancelled"
              class="fa-solid fa-times fa-fw text-danger"
            ></i>
            <i
              *ngSwitchCase="TaskStatus.InProgress"
              class="fa-solid fa-spinner fa-fw text-info"
            ></i>
          </ng-container>
        </td>
        <td>
          <b>{{ task.subject }}</b>
        </td>
        <td>{{ task.description | shTruncate: 30 }}</td>
        <td>{{ task.assignedTo | nameFromUserId | async }}</td>
        <td>{{ task.dueDate | utcToLocalDatePipe }}</td>
      </tr>
    </tbody>
  </table>
</div>
