<div
  class="card tile tile-clickable"
  *ngFor="let visit of visits"
  (click)="selectVisit(visit)"
  [ngClass]="{ selected: selectedVisit && selectedVisit.id === visit.id }"
>
  <div class="card-body">
    <div class="row seperated-columns">
      <div class="col-5">
        <strong>
          {{ visit.ship.name | uppercase }}
        </strong>
        <span class="text-muted" *ngIf="getShipIdentification(visit.ship)">{{
          getShipIdentification(visit.ship)
        }}</span>
        <ul class="list-inline list-inline-seperated">
          <li class="list-inline-item" *ngIf="visit.referenceNumber">
            {{ visit.referenceNumber | uppercase }}
          </li>
        </ul>
      </div>
      <div class="col-7">
        <div class="row align-items-stretch">
          <div class="col" *ngIf="visit.currentMovement">
            <div
              *ngIf="visit.currentMovement.etd && !visit.currentMovement.atd"
            >
              <span class="text-muted mr-1">{{
                'nautical.terms.etd' | translate
              }}</span>
              <strong>{{
                visit.currentMovement.etd | date: 'dd-MM-yyyy HH:mm'
              }}</strong>
            </div>
            <div *ngIf="visit.currentMovement.atd">
              <span class="text-muted mr-1">{{
                'nautical.terms.atd' | translate
              }}</span>
              <strong>{{
                visit.currentMovement.atd | date: 'dd-MM-yyyy HH:mm'
              }}</strong>
            </div>
            <div>
              <span class="text-muted mr-1">{{
                'shared.terms.from' | translate
              }}</span>
              <span
                *ngIf="visit.currentMovement.portWayPointFrom"
                class="mr-1"
                >{{ visit.currentMovement.portWayPointFrom.name }}</span
              >
              <span
                class="text-muted"
                *ngIf="
                  !visit.currentMovement.portWayPointFrom && !visit.previousPort
                "
              >
                {{ 'nautical.visit.unknownOrigin' | translate }}
              </span>
              <span
                *ngIf="
                  !visit.currentMovement.portWayPointFrom && visit.previousPort
                "
              >
                {{ visit.previousPort.unloCode }} ({{
                  visit.previousPort.name
                }})
              </span>
            </div>
          </div>
          <div class="col" *ngIf="!visit.currentMovement">
            <div *ngIf="visit.eta && !visit.ata">
              <span class="text-muted mr-1">{{
                'nautical.terms.eta' | translate
              }}</span>
              <strong>
                {{ visit.eta | date: 'dd-MM-yyyy HH:mm' }} </strong
              >>
            </div>
            <div *ngIf="visit.ata">
              <span class="text-muted mr-1">{{
                'nautical.terms.ata' | translate
              }}</span>
              <strong>{{ visit.ata | date: 'dd-MM-yyyy HH:mm' }}</strong>
            </div>
            <div>
              <span class="text-muted mr-1">{{
                'shared.terms.from' | translate
              }}</span>
              <strong>{{ visit.previousPort?.unloCode }}</strong>
            </div>
          </div>
          <div class="col-1 text-center pt-2">
            <i
              class="fa-regular fa-arrow-right fa-2x align-middle"
              aria-hidden="true"
            ></i>
          </div>
          <div class="col" *ngIf="visit.currentMovement">
            <div
              *ngIf="visit.currentMovement.eta && !visit.currentMovement.ata"
            >
              <span class="text-muted mr-1">{{
                'nautical.terms.eta' | translate
              }}</span>
              <strong>{{
                visit.currentMovement.eta | date: 'dd-MM-yyyy HH:mm'
              }}</strong>
            </div>
            <div *ngIf="visit.currentMovement.ata">
              <span class="text-muted mr-1">{{
                'nautical.terms.ata' | translate
              }}</span>
              <strong>{{
                visit.currentMovement.ata | date: 'dd-MM-yyyy HH:mm'
              }}</strong>
            </div>
            <div>
              <span class="text-muted mr-1">{{
                'shared.terms.to' | translate
              }}</span>
              <span class="mr-1" *ngIf="visit.currentMovement.portWayPointTo">{{
                visit.currentMovement.portWayPointTo.name
              }}</span>
              <span
                class="text-muted"
                *ngIf="!visit.currentMovement.portWayPointTo && !visit.nextPort"
                >{{ 'nautical.visit.unknownDestination' | translate }}</span
              >
              <span
                *ngIf="!visit.currentMovement.portWayPointTo && visit.nextPort"
              >
                {{ visit.nextPort.unloCode }} ({{ visit.nextPort.name }})
              </span>
            </div>
          </div>
          <div class="col" *ngIf="!visit.currentMovement">
            <div *ngIf="visit.etd && !visit.atd">
              <span class="text-muted mr-1">{{
                'nautical.terms.etd' | translate
              }}</span>
              <strong>{{ visit.etd | date: 'dd-MM-yyyy HH:mm' }}</strong>
            </div>
            <div *ngIf="visit.atd">
              <span class="text-muted mr-1">{{
                'nautical.terms.atd' | translate
              }}</span>
              <strong>{{ visit.atd | date: 'dd-MM-yyyy HH:mm' }}</strong>
            </div>
            <div>
              <span class="text-muted mr-1">{{
                'shared.terms.to' | translate
              }}</span>
              <span *ngIf="visit.nextPort"
                >{{ visit.nextPort.unloCode }} ({{ visit.nextPort.name }})</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
