import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { PortOfAmsterdamZHGPage } from './pages/zhg/zhg.page';
import { CustomPortOfAmsterdamPagesModule } from './pages/port-of-amsterdam-pages.module';

const routes: Routes = [
  {
    path: 'port-of-amsterdam/zhg/new',
    component: PortOfAmsterdamZHGPage,
    data: {
      ui: {
        headerTitle: 'Opgave zeehavengeld - Port of Amsterdam',
      },
    },
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    // Child Modules
    CustomPortOfAmsterdamPagesModule,
    /*
        CustomCVVComponentsModule,
        CustomCVVPagesModule,
        CustomCVVPipesModule,
        CustomCVVServicesModule
        */
  ],
})
export class CustomPortOfAmsterdamModule {}
