import { Component, Input } from '@angular/core';

import { UserModel } from '@seahorse/domain';

@Component({
  selector: 'user-form',
  templateUrl: './user-form.component.html',
})
export class UserFormComponent {
  @Input() model: UserModel;
  @Input() submitted = false;
}
