export class PagedDataModel {
  key: string;
  pIndex: number;
  pSize: number;

  constructor(key: string, pIndex: number, pSize: number) {
    this.key = key;
    this.pIndex = pIndex;
    this.pSize = pSize;
  }
}
