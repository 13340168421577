<div class="modal-header align-items-center">
  <h1 class="modal-title">{{ 'shared.terms.select' | translate }}</h1>
  <button closeModal type="button" class="btn btn-link text-dark">
    <i class="fa-solid fa-times fa-sm fa-fw"></i>
  </button>
</div>
<div class="modal-body p-0">
  <div style="overflow-y: auto; max-height: 500px">
    <zeehaven-ijmuiden-visit-list
      [models]="visits"
      [visitDisplay]="'inPort'"
      [simpleView]="true"
      (onSelectVisit)="onSelect($event)"
    ></zeehaven-ijmuiden-visit-list>
  </div>
</div>
<div class="modal-footer">
  <button
    closeModal
    type="button"
    class="btn btn-link btn-block w-50"
    (click)="dismiss(null)"
  >
    {{ 'shared.terms.cancel' | translate }}
  </button>
</div>
