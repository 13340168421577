import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import * as _ from 'underscore';
import { DataContextService, ResultWrapper } from '@seahorse/common';
import {
  FileModel,
  FileService,
  MessageModel,
  MessagesSearchModel,
} from '@seahorse/domain';
import { CloneLinkedObjectsRequest } from '@seahorse/domain';

@Injectable({
  providedIn: 'root',
})
export class MessagesDataService {
  private setMessageStatus = new Subject<MessageModel>();
  setMessageStatusCalled$ = this.setMessageStatus.asObservable();

  constructor(
    private dataContext: DataContextService,
    private fileService: FileService
  ) {}

  getById(messageId: string): Observable<ResultWrapper<MessageModel>> {
    return this.dataContext.get<MessageModel>(`/messages/${messageId}`);
  }

  search(
    search: MessagesSearchModel,
    pageIndex: number,
    pageSize: number
  ): Observable<ResultWrapper<MessageModel[]>> {
    return this.dataContext.post<MessageModel[]>(
      `/messages/search?pindex=${pageIndex}&psize=${pageSize}`,
      search
    );
  }

  searchWithContentMaps(
    search: MessagesSearchModel,
    pageIndex: number,
    pageSize: number
  ): Observable<ResultWrapper<MessageModel[]>> {
    return this.dataContext.post<MessageModel[]>(
      `/messages/search/details?pindex=${pageIndex}&psize=${pageSize}`,
      search
    );
  }

  update(message: MessageModel): Observable<ResultWrapper<MessageModel>> {
    this.setMessageStatus.next(message);

    return this.dataContext.put<MessageModel>(`/messages`, message);
  }

  import(
    objectType: string,
    objectId: string | number,
    emails: FileModel | FileModel[]
  ): Observable<ResultWrapper<MessageModel[]>> {
    const files: FileModel[] = _.isArray(emails) ? emails : [emails];

    return new Observable<ResultWrapper<MessageModel[]>>((s) => {
      const successful: FileModel[] = [];
      let unsuccesful = 0;
      let messages: ResultWrapper<any>['messages'] = [];

      //#region content
      // const formDatas = [];
      // let count = 0;

      // _.forEach(files, file => {
      //   const formData = new FormData();
      //   formData.append('description', file.description);
      //   formData.append('filePurpose', file.filePurpose);
      //   formData.append('file', file.file, file.name);
      //   formData.append('fileName', file.name);
      //   formData.append('size', file.file.size.toString());

      //   formDatas.push(formData);
      // });

      // if(files.length == formDatas.length) {
      //   return this.httpClient.post<ResultWrapper<MessageModel>>(`${ this.environment.apiHost }/messages/import`, files);
      // }
      // _.forEach(files, file => {
      //   this.fileService.addFile(file, objectType, objectId.toString()).subscribe(
      //     (rAdd: ResultWrapper<FileModel>) => {
      //       if(rAdd.hasResult) {
      //         this.fileService.getFileById(rAdd.result.id).subscribe(
      //           (rGet: ResultWrapper<FileModel>) => {
      //             if(rGet.hasResult) {
      //               successful.push(rGet.result);

      //             } else {
      //               messages = messages.concat(rGet.messages);

      //               unsuccesful++;
      //             }

      //           }, e => {
      //             messages = messages.concat(e.error.messages);

      //             unsuccesful++;

      //           }, () => {
      //             if(successful.length + unsuccesful == files.length) {
      //               this.httpClient.post<ResultWrapper<MessageModel[]>>(`${ this.environment.apiHost }/messages/import`, successful)
      //               .subscribe(
      //                 (r: ResultWrapper<MessageModel[]>) => {
      //                   // add messages etc
      //                   s.next(r);
      //                 }
      //               );
      //             }
      //           }
      //         );

      //       } else {
      //         messages = messages.concat(rAdd.messages);

      //         unsuccesful++;
      //       }

      //     }, e => {
      //       messages = messages.concat(e.error.messages);

      //       unsuccesful++;

      //     }, () => {}
      //   );
      // });
      //#endregion

      const contentMapObjects = [
        {
          key: objectType,
          value: objectId.toString(),
        },
      ];

      _.forEach(files, (file) => {
        file.objectFile = [
          { objectId: objectId.toString(), objectType: objectType },
        ];
        this.fileService.addFile(file).subscribe(
          (r: ResultWrapper<FileModel>) => {
            if (r.hasResult) {
              successful.push(r.result);
            } else {
              messages = messages.concat(r.messages);
              unsuccesful++;
            }
          },
          (e) => {
            messages = messages.concat(e.error.messages);
            unsuccesful++;
          },
          () => {
            if (successful.length + unsuccesful === files.length) {
              const data = {
                files: successful,
                contentMapObjects: contentMapObjects,
              };

              this.dataContext
                .post<MessageModel[]>(`/messages/import`, data)
                .subscribe(
                  (r: ResultWrapper<MessageModel[]>) => {
                    const w: ResultWrapper<MessageModel[]> = r;
                    w.messages = w.messages.concat(messages);

                    s.next(w);
                  },
                  (e) => s.error(e),
                  () => s.complete()
                );
            }
          }
        );
      });
    });

    //#region complicated thing
    // if(_.isArray(files)) {
    //   return new Observable<ResultWrapper<MessageModel[]>>(s => {
    //     _.forEach(files, file => {

    //     }
    //   );

    // } else {
    //   return this.httpClient.post<ResultWrapper<MessageModel>>(`${ this.environment.apiHost }/messages/import`, files)
    // .pipe(map((r: ResultWrapper<MessageModel>) => _.extend(_.omit(r, 'result'), { result: [r.result] })));
    // }
    //#endregion
  }

  add(message: MessageModel): Observable<ResultWrapper<MessageModel>> {
    return this.dataContext.post<MessageModel>(`/messages`, message);
  }

  delete(messageId: number): Observable<ResultWrapper<MessageModel>> {
    return this.dataContext.delete<MessageModel>(`/messages/${messageId}`);
  }

  clone(clonedObjectParams: CloneLinkedObjectsRequest) {
    return this.dataContext.post<MessageModel>(
      'messages/clone',
      clonedObjectParams
    );
  }

  getNumberOfUnreadMessages(): Observable<ResultWrapper<number>> {
    return this.dataContext.get<number>(`/messages/unread-messages`);
  }
}
