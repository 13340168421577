import { Injectable } from '@angular/core';
import { DataContextService } from '@seahorse/common';
import { Observable } from 'rxjs';

import { ResultWrapper } from '@seahorse/common';
import { CompanyExternalKeyModel } from '@seahorse/domain';

@Injectable()
export class CompanyExternalKeyDataService {
  constructor(private dataContext: DataContextService) {}

  getFinancialKeys(
    sourceKey: string
  ): Observable<ResultWrapper<CompanyExternalKeyModel[]>> {
    return this.getKeys(sourceKey, 'financial');
  }

  getKeys(
    sourceKey: string,
    keyType: string
  ): Observable<ResultWrapper<CompanyExternalKeyModel[]>> {
    return this.dataContext.get<CompanyExternalKeyModel[]>(
      `company/externalkey/${sourceKey}/${keyType}`
    );
  }
}
