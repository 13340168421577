<div class="modal-header align-items-center">
  <h1 class="modal-title">
    {{
    'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.details.chooseShipVisitMovement'
    | translate
    }}
  </h1>
  <button closeModal type="button" class="btn btn-link text-dark">
    <i class="fa-solid fa-times fa-sm fa-fw"></i>
  </button>
</div>
<div class="modal-body">
  <div class="form-row">
    <div class="form-group col-12">
      <label class="d-flex justify-content-between" for="typeahead-http">
        <b class="required">{{
          'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.details.selectShip'
          | translate
          }}</b>
        <span *ngIf="loadingShips">
          <i class="fa-regular fa-spinner fa-spin fa-fw"></i>
        </span>
      </label>
      <div class="input-group">
        <div class="input-group-prepend">
          <select [(ngModel)]="searchField" class="form-control">
            <option *ngFor="let searchFieldOption of searchFields" [ngValue]="searchFieldOption">
              {{ 'nautical.ship.model.' + searchFieldOption | translate }}
            </option>
          </select>
        </div>
        <input [(ngModel)]="selectedShip" [class.is-invalid]="loadingShipsFailed" [ngbTypeahead]="searchShip" [inputFormatter]="inputFormatter" [resultFormatter]="shipFormatter" [editable]="false"
          [placeholder]="
            ('customModules.portCallFile.fileForm.searchShip' | translate) +
            '...'
          " (selectItem)="shipSelected($event.item)" required id="typeahead-http" name="ships" type="text" class="form-control" />
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" (click)="setNull('ship')" type="button">
            <i class="fa-solid fa-times fa-fw"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="form-group col-12">
      <label class="d-flex justify-content-between" for="visits">
        <b>{{
          'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.details.selectVisit'
          | translate
          }}</b>
        <div>
          <em *ngIf="selectedShip && visitCount">
            {{ visitCount }}
            <ng-container *ngIf="visitCount == 1; then visitFound; else visitsFound"></ng-container>
            <ng-template #visitFound>{{
              'shared.terms.itemFound' | translate
              }}</ng-template>
            <ng-template #visitsFound>{{
              'shared.terms.itemsFound' | translate
              }}</ng-template>
          </em>
          <span *ngIf="loadingVisits">
            <i class="fa-regular fa-spinner fa-spin fa-fw"></i>
          </span>
        </div>
      </label>
      <div class="input-group">
        <select [(ngModel)]="selectedVisitId" [disabled]="loadingVisits || !selectedShip || !visitCount" (change)="loadDetails()" required id="visits" name="visits" class="custom-select">
          <option selected [ngValue]="null">
            <ng-container *ngIf="loadingVisits; then loadVisits; else pickVisits"></ng-container>
            <ng-template #loadVisits>{{ 'shared.terms.loading' | translate }}...</ng-template>
            <ng-template #pickVisits>
              <ng-container *ngIf="visitCount; then chooseVisit; else noVisits"></ng-container>
              <ng-template #chooseVisit>{{ 'shared.terms.choose' | translate }}...</ng-template>
              <ng-template #noVisits>{{
                'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.details.noVisits'
                | translate
                }}</ng-template>
            </ng-template>
          </option>
          <optgroup *ngIf="visits.length">
            <option *ngFor="let visit of visits" [value]="visit.id">
              {{ visitFormatter(visit).referenceNumber }}
            </option>
          </optgroup>
        </select>
        <div class="input-group-append">
          <button [disabled]="loadingVisits || !visitCount" (click)="setNull('visit')" type="button" class="btn btn-outline-secondary">
            <i class="fa-solid fa-times fa-fw"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="form-group col-12">
      <label class="d-flex justify-content-between" for="movements">
        <b>{{
          'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.details.selectMovement'
          | translate
          }}</b>
        <div>
          <em *ngIf="selectedVisit && movementCount">
            {{ movementCount }}
            <ng-container *ngIf="
                !loadingVisit && movementCount == 1;
                then movementFound;
                else movementsFound
              "></ng-container>
            <ng-template #movementFound>{{
              'shared.terms.itemFound' | translate
              }}</ng-template>
            <ng-template #movementsFound>{{
              'shared.terms.itemsFound' | translate
              }}</ng-template>
          </em>
          <span *ngIf="loadingDetails">
            <i class="fa-regular fa-spinner fa-spin fa-fw"></i>
          </span>
        </div>
      </label>
      <div class="input-group">
        <select [(ngModel)]="selectedMovement" [disabled]="loadingDetails || !selectedVisit || !movementCount" required id="movements" name="movements" class="custom-select">
          <option selected [ngValue]="null">
            <ng-container *ngIf="loadingDetails; then loadMovements; else pickMovements"></ng-container>
            <ng-template #loadMovements>{{ 'shared.terms.loading' | translate }}...</ng-template>
            <ng-template #pickMovements>
              <ng-container *ngIf="movementCount; then chooseMovement; else noMovements"></ng-container>
              <ng-template #chooseMovement>{{ 'shared.terms.choose' | translate }}...</ng-template>
              <ng-template #noMovements>{{
                'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.details.noMovements'
                | translate
                }}</ng-template>
            </ng-template>
          </option>
          <optgroup *ngIf="
              selectedVisit &&
              selectedVisit.portMovements &&
              selectedVisit.portMovements.length
            ">
            <option *ngFor="let movement of selectedVisit.portMovements" [ngValue]="movement">
              {{ movementFormatter(movement) }}
            </option>
          </optgroup>
        </select>
        <div class="input-group-append">
          <button [disabled]="loadingDetails || !movementCount" (click)="setNull('movement')" type="button" class="btn btn-outline-secondary">
            <i class="fa-solid fa-times fa-fw"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button closeModal type="button" class="btn btn-link flex-fill">
    {{ 'shared.terms.cancel' | translate }}
  </button>
  <button [disabled]="!selectedShip" (click)="returnSuggestion()" closeModal type="button" class="btn btn-success flex-fill">
    {{ 'shared.terms.confirm' | translate }}
  </button>
</div>
