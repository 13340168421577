import { NgModule } from '@angular/core';
import { NauticalGeographyDataService } from './nautical-geography-data.service';
import { NauticalCoordinatesDataService } from './nautical-coordinates-data.service';
import { NauticalObjectFilterDataService } from './nautical-object-filter-data.service';

@NgModule({
  providers: [
    NauticalCoordinatesDataService,
    NauticalGeographyDataService,
    NauticalObjectFilterDataService,
  ],
})
export class NauticalGeographyServicesModule {}
