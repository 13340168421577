export class RegisterAccountModel {
  companyName: string;
  appKey: string;
  appSecret: string;
  productType: string;
  sendConfirmationEmails: boolean;
  requireAccountConfirmation: boolean;
  users: RegisterUserModel[];

  constructor() {
    this.appKey = '73706541B2824100B9254615C51A042C';
    this.appSecret = 'F200A5395BD84FB8A86F90E299481490';
    // this.productType = "Basic test account";
    // this.productType = 'Onboarding SHIPM8';
    this.productType = 'Onboarding users';
    this.sendConfirmationEmails = false;
    this.requireAccountConfirmation = true;
    this.users = [];
  }
}

export class RegisterUserModel {
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
}

export class RegisterResponse {
  isSuccess: boolean;
  isUnauthorized: boolean;
  errorMessage: string;
}
