import { Injectable } from '@angular/core';
import { TariffGroupModel } from '../../models/tariff-group.model';
import { TariffModel } from '../../models/tariff.model';

@Injectable({
  providedIn: 'root',
})
export class TariffPickerFormService {
  selectedTariffGroups: {
    id: TariffGroupModel['id'];
    tariffs: TariffModel[];
  }[] = [];

  tariffGroups: TariffGroupModel[] = [];
}
