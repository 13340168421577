import { Injectable } from '@angular/core';
import { ResultWrapper } from '@seahorse/common';
import { DataContextService } from '@seahorse/common';
import { Observable } from 'rxjs';

import { TaskModel, TaskSearchModel } from '../models/task.model';
import { CloneLinkedObjectsRequest } from '@seahorse/domain';

@Injectable({
  providedIn: 'root',
})
export class TaskDataService {
  constructor(private dataContext: DataContextService) {}

  getAll(id, type) {
    return this.dataContext.get<TaskModel[]>(`tasks/${id}/${type}`); // doesnt work yet
  }

  search(
    model: TaskSearchModel,
    pageIndex: number,
    pageSize: number
  ): Observable<ResultWrapper<TaskModel[]>> {
    return this.dataContext.get<TaskModel[]>(
      `tasks/${model.objectId}/${model.objectType}?&isActive=${model.isActive}&isExpired=${model.isExpired}&&assignedTo=${model.assignedTo}&searchTerm=${model.searchQuery}&pindex=${pageIndex}&psize=${pageSize}`
    );
  }

  getById(taskId: number): Observable<ResultWrapper<TaskModel>> {
    return this.dataContext.get<TaskModel>(`tasks/${taskId}`);
  }

  getTopActiveTasks(): Observable<ResultWrapper<TaskModel[]>> {
    return this.dataContext.get<TaskModel[]>('tasks');
  }

  add(task: TaskModel): Observable<ResultWrapper<TaskModel>> {
    return this.dataContext.post<TaskModel>(`tasks`, task);
  }

  update(task: TaskModel): Observable<ResultWrapper<TaskModel>> {
    return this.dataContext.put<TaskModel>(`tasks/${task.id}`, task);
  }

  delete(taskId: number): Observable<ResultWrapper<TaskModel>> {
    return this.dataContext.delete<TaskModel>(`tasks/${taskId}`);
  }

  getFollowUpTasks(
    taskId: number
  ): Observable<ResultWrapper<TaskModel['id'][]>> {
    return this.dataContext.get<TaskModel['id'][]>(`tasks/followups/${taskId}`);
  }

  sendAssignedToNotification(
    task: TaskModel
  ): Observable<ResultWrapper<TaskModel>> {
    return this.dataContext.post<TaskModel>(`tasks/sendnotification`, task);
  }

  clone(clonedObjectParams: CloneLinkedObjectsRequest) {
    return this.dataContext.post<TaskModel>('tasks/clone', clonedObjectParams);
  }
}
