export class PreferenceModel {
  id: number = null;
  name: string = null;
  fieldType: number = null; // StringField = 1, NumericField = 2, BooleanField = 3, DateTimeField = 4
  fieldValue: any = null;
  lastUpdate?: Date = null;
  category: string = null;
  isCompanyPreference?: boolean = null;

  static validate(preference: PreferenceModel) {
    if (!preference) {
      return false;
    }

    if (preference.fieldType == null || preference.fieldType < 0) {
      return false;
    }

    preference.name = preference.name.trim();
    if (preference.name === null || preference.name === '') {
      preference.name = null;
      return false;
    }

    return true;
  }
}
