<div class="card">
  <div class="card-header" *ngIf="hideTitle !== true || readOnly !== true">
    <span *ngIf="hideTitle !== true">
      {{ 'invoicing.invoiceCreator.invoiceLine' | translate }}
    </span>

    <div class="btn-list" *ngIf="readOnly !== true">
      <button
        (click)="
          onEvent.emit({
            action: 'editInvoiceLines',
            data: { tariffsObject: tariffsObject }
          })
        "
        [disabled]="!tariffsObject || tariffsObject.loadingTariffs"
        type="button"
        class="btn btn-outline-secondary btn-sm"
      >
        <i class="fa-regular fa-plus fa-fw"></i>
      </button>
    </div>
  </div>

  <ng-container
    *ngIf="
      invoice.invoiceLines && invoice.invoiceLines.length > 0;
      then invoiceLineTable;
      else noInvoiceLinesFound
    "
  ></ng-container>

  <ng-template #invoiceLineTable>
    <div class="card-body card-body-table">
      <table class="table">
        <thead>
          <tr>
            <th>{{ 'shared.terms.description' | translate }}</th>
            <th>{{ 'invoicing.invoiceLineModel.amount' | translate }}</th>
            <th>{{ 'invoicing.invoiceLineModel.quantity' | translate }}</th>
            <th width="1%" *ngIf="readOnly !== true">{{ 'shared.terms.actions' | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let invoiceLine of invoice.invoiceLines">
            <td>{{ invoiceLine.description }}</td>
            <td style="white-space: nowrap">
              {{ invoice.currency | invoiceCurrencySymbol }}
              {{ invoiceLine.amount | number: '1.2-2' }}
            </td>
            <td>{{ invoiceLine.quantity }}</td>
            <td *ngIf="readOnly !== true">
              <div class="btn-group w-100">
                <button
                  (click)="
                    onEvent.emit({
                      action: 'editInvoiceLines',
                      data: {
                        tariffsObject: tariffsObject,
                        invoiceLine: invoiceLine
                      }
                    })
                  "
                  type="button"
                  class="btn btn-hover-success btn-sm"
                >
                  <i class="fa-regular fa-pencil-alt fa-fw"></i>
                </button>
                <button
                  (click)="
                    onEvent.emit({
                      action: 'removeFromInvoiceLines',
                      data: {
                        tariffsObject: tariffsObject,
                        invoiceLine: invoiceLine
                      }
                    })
                  "
                  type="button"
                  class="btn btn-hover-danger btn-sm"
                >
                  <i class="fa-solid fa-trash fa-fw"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-template>

  <ng-template #noInvoiceLinesFound>
    <div class="card-body">
      <p class="m-0">
        {{ 'invoicing.invoiceCreator.invoiceLinesNotFound' | translate }}
      </p>
    </div>
  </ng-template>
</div>
