import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'underscore';
import { KPActivityLogicService } from '../services/kp-activity-logic.service';

@Pipe({
  name: 'kpSubtypeTypeDisplay',
})
export class KPSubtypeTypeDisplayPipe implements PipeTransform {
  transform(input: any, typeName: string): string {
    if (!input) {
      return '';
    }

    if (input instanceof Array) {
      const names = [];
      _.each(input, (i) => {
        names.push(this.getDisplayName(i, typeName));
      });
      return names.join(', ');
    } else {
      return this.getDisplayName(input, typeName);
    }
  }

  getDisplayName(value: string, typeName: string) {
    const values = KPActivityLogicService.subTypes[typeName];
    if (values && values.length > 0) {
      const type = _.find(values, (v) => v['code'] === value);
      if (type) {
        return type['name'];
      }
    }
    return 'unknown';
  }
}
