import {
  ConnectedDataInformationModel,
  DataObjectAddConfigModel,
  DataObjectSeedData,
  ObjectDefinitionModel,
} from '../models/object-definition.model';

export class ObjectDefinitionHelpers {
  static getSeedData(
    objectDefinition?: Partial<ObjectDefinitionModel>,
    addConfig?: Partial<DataObjectAddConfigModel>
  ) {
    if (!objectDefinition?.systemCode || !addConfig?.seedData) {
      return undefined;
    }

    return addConfig.seedData[objectDefinition.systemCode];
  }

  static applySeedData(source: any, target: any, seedData: DataObjectSeedData) {
    if (!source || !target || !seedData) {
      return target;
    }

    seedData.forEach((seed) => {
      target[seed.targetField] = source[seed.sourceField] ?? seed.defaultValue;
    });

    return target;
  }

  static getConnectedDataConfig(
    objectDefinition: ObjectDefinitionModel
  ): ConnectedDataInformationModel[] {
    return (
      objectDefinition?.connectedDataConfig?.connectedObjectInformation ?? []
    );
  }
}
