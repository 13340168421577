import { Component } from '@angular/core';
import { Observable, of } from 'rxjs';
import * as _ from 'underscore';
import {
  distinctUntilChanged,
  switchMap,
  tap,
  map,
  catchError,
  debounceTime,
} from 'rxjs/operators';

import { NauticalShipTagModel } from '@seahorse/domain';
import { NauticalShipTagDataService } from '../../../nautical-ship/services/nautical-ship-tag-data.service';
import { NauticalShipInteractionService } from '../../../nautical-ship/services/nautical-ship-interaction.service';

@Component({
  selector: 'ship-tag',
  templateUrl: './nautical-ship-tag.component.html',
  styleUrls: ['./nautical-ship-tag.component.scss'],
})
export class NauticalShipTagComponent {
  model: any;
  searching: boolean;
  searchFailed: boolean;
  editMode = false;
  tagId: number;
  selectedTags: NauticalShipTagModel[] = [];

  constructor(
    private shipTagDataService: NauticalShipTagDataService,
    private shipInteractionService: NauticalShipInteractionService
  ) {}

  searchShipTags = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.searching = true)),
      switchMap((term) =>
        this.shipTagDataService.search(term, 0, 100).pipe(
          tap(() => (this.searchFailed = false)),
          map((response) => response.result),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })
        )
      ),
      tap(() => (this.searching = false))
    );

  resultShipTagsFormatter = (result: NauticalShipTagModel) => {
    return result.name;
  };

  addTag() {
    this.searching = false;
    if (typeof this.model == 'string') {
      const name = this.model;
      this.model = new NauticalShipTagModel();
      this.model.name = name;
    }

    if (this.model != undefined) {
      this.selectedTags.push(this.model);
    }
  }

  editTag() {
    this.searching = false;
    if (typeof this.model == 'string') {
      const name = this.model;
      this.model = new NauticalShipTagModel();
      this.model.name = name;
    }

    if (this.model != undefined) {
      const index = _.findIndex(this.selectedTags, (x) => x.id === this.tagId);
      this.model.id = this.tagId;
      this.selectedTags[index] = this.model;
    }
    this.editMode = false;
  }

  removeTag(tag: NauticalShipTagModel) {
    const index = _.findIndex(this.selectedTags, (x) => x.name === tag.name);
    if (index > -1) {
      this.selectedTags.splice(index, 1);
    }
  }

  save() {
    this.shipInteractionService.modifyTags(this.selectedTags);
  }
}
