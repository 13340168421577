import { CustomDataBaseModel } from '@seahorse/domain';
import { TaskModel } from '../../../../tasks/modules/task/models/task.model';
import { TaskDefinitionListModel } from './task-definition-list.model';

export class TaskDefinitionModel extends CustomDataBaseModel {
  $customdata_taskdefinitionlist_id: TaskDefinitionListModel['__Id'];

  subject: TaskModel['subject'];
  taskType: TaskModel['taskType'];
  daysRequired?: number;
  description?: TaskModel['description'];
  assignedTo?: TaskModel['assignedTo'];
  remarks?: TaskModel['remarks'];

  constructor() {
    super();

    this.$customdata_taskdefinitionlist_id = null;

    this.subject = null;
    this.taskType = null;
    this.daysRequired = null;
    this.description = null;
    this.assignedTo = null;
    this.remarks = null;
  }
}
