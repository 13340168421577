import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import * as _ from 'underscore';

import { ProxyServices } from '../../../../core/services/proxy.service';
import { ObjectDefinitionModel } from '@seahorse/domain';
import { ZeehavenIJmuidenMovementModel } from '../../models/zeehaven-ijmuiden-movement.model';

@Component({ templateUrl: 'visit-reasons-picker-modal.component.html' })
export class VisitReasonsPickerModalComponent {
  @Input() movement: ZeehavenIJmuidenMovementModel;

  movementDefinition: ObjectDefinitionModel = null;

  constructor(
    private activeModal: NgbActiveModal,
    private proxy: ProxyServices
  ) {
    this.movementDefinition = _.find(
      this.proxy.objectDefinitions,
      (def) => def.systemCode === 'zeehavenIJmuidenMovement'
    );
    if (
      this.movementDefinition &&
      this.movementDefinition.objectFieldDefinitions
    ) {
      this.movementDefinition.objectFieldDefinitions = _.sortBy(
        this.movementDefinition.objectFieldDefinitions,
        (f) => f.name.toLowerCase()
      );
    }
  }

  dismiss() {
    this.activeModal.close(null);
  }

  ok() {
    this.activeModal.close(this.movement);
  }
}
