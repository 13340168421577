export class CustomDefaultDataModel {
  id: number;
  name: string;
  systemCode: string;
  dataObjectKey: CustomDefaultDataObjectKeyModel;
  targetDataObjectKey: CustomDefaultDataObjectKeyModel;
  defaultDataEvent: number;
  organisationId: string;
  configuration: CustomDefaultDataConfigurationModel;
  objects!: CustomDataObjectModel[];
  importDefinitionSystemCode?: string;

  constructor() {
    this.objects = [];
  }
}

export class CustomDefaultDataObjectKeyModel {
  scopeCode: 'sh' | 'ct' | 'st';
  dataObjectName: string;
  scope: ScopeType;
}

export class CustomDefaultDataConfigurationModel {
  objectDisplayField: string;
}

export class CustomDataObjectModel {
  id: string;
  organisationId: string;
  dataObjectKey: CustomDefaultDataObjectKeyModel;
  fields: object;
}

export enum ScopeType {
  Seahorse = 0,
  System = 1,
  Custom = 2,
}
