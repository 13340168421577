import { registerLocaleData } from '@angular/common';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import localeNL from '@angular/common/locales/nl';
import { APP_INITIALIZER, inject, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MsalAngularConfiguration,
  MsalModule,
  MsalService,
  MSAL_CONFIG,
  MSAL_CONFIG_ANGULAR,
} from '@azure/msal-angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { Angulartics2Module } from 'angulartics2';
import { Configuration, Logger, LogLevel } from 'msal';
import { SignalRConfiguration, SignalRModule } from 'ng2-signalr';
import { ToastrModule } from 'ngx-toastr';

import {
  ApplicationInsightsService,
  BUILD_NO,
  EnvService,
  envServiceProvider,
  HUB_URL,
  NotificationService,
  SeahorseTranslateModule,
  SignalRService,
} from '@seahorse/common';
import { ContentMappingModule } from './content-mapping/content-mapping.module';
import { AccountModule } from './core/account/account.module';
import { CoreModule } from './core/core.module';
import { IdentityService } from './core/services/identity.service';
import { CustomerAppRoutingModule } from './customer-app-routing.module';
import { CustomerAppComponent } from './customer-app.component';
import { GenericUIModule } from './generic-ui/generic-ui.module';
import { MapModule } from './map/map.module';
import { PreferenceModule } from './preference/preference.module';
import { CaEnvService } from './shared/services/env.service';
import { ThemesModule } from './themes/themes.module';
import { registerLicense } from '@syncfusion/ej2-base';
import { SEAHORSE_BUILD_NO } from '../build';
import { CaNotificationService } from './core/services/notification.service';
import { ProxyServices } from './core/services/proxy.service';
import { ProxyService, UsersDataService } from '@seahorse/domain';
import { UserDataService } from './user/services/user-data.service';
import {
  BaseFileListCardService,
  CUSTOM_FORM_CONTROLS,
  UI_MODULE_CUSTOM_COMPONENTS,
} from '@seahorse/temp';
import { FileListCardService } from './layout/components/file-list-card/file-list-card.service';
import { uiModuleCustomComponents } from './custom-modules/models/pages-overrides';
import { AppInitializerService } from './core/services/app-initializer.service';
import {
  IdentityServiceBase,
  authRequestInterceptor,
  responseInterceptor,
  AUTH_ROUTES,
} from '@seahorse/auth';
import { controlFactory } from './core/models/form-control-factory';

export function initializeApp(appInitializerService: AppInitializerService) {
  registerLocaleData(localeNL, 'nl-NL');
  return () => {
    return appInitializerService.Init();
  };
}

@NgModule({
  declarations: [CustomerAppComponent],
  imports: [
    FormsModule,
    NgbModule,
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      closeButton: true,
      newestOnTop: true,
      progressBar: true,
      positionClass: 'toast-bottom-right',
    }),
    CoreModule,
    SeahorseTranslateModule.forRoot(),
    MsalModule,
    Angulartics2Module.forRoot(),
    ReactiveFormsModule,

    AccountModule,
    ContentMappingModule,
    PreferenceModule,
    ThemesModule,
    GenericUIModule,

    CustomerAppRoutingModule, // Has to be at the end for wild card to work
    MapModule, // ensure other modules are loaded before import the MapModule
    SignalRModule.forRoot(createSignalRConfig),
  ],
  providers: [
    AppInitializerService,
    ApplicationInsightsService,
    envServiceProvider(CaEnvService),
    provideHttpClient(
      withInterceptors([authRequestInterceptor, responseInterceptor])
    ),
    { provide: EnvService, useExisting: CaEnvService },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppInitializerService],
      multi: true,
    },
    {
      provide: MSAL_CONFIG,
      useFactory: MSALConfigFactory,
      deps: [CaEnvService],
    },
    {
      provide: MSAL_CONFIG_ANGULAR,
      useFactory: MSALAngularConfigFactory,
      deps: [CaEnvService],
    },
    { provide: BUILD_NO, useValue: SEAHORSE_BUILD_NO },
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
    {
      provide: NotificationService,
      useFactory: () => inject(CaNotificationService),
    },
    { provide: ProxyService, useFactory: () => inject(ProxyServices) },
    { provide: UsersDataService, useFactory: () => inject(UserDataService) },
    {
      provide: BaseFileListCardService,
      useFactory: () => inject(FileListCardService),
    },
    {
      provide: IdentityServiceBase,
      useFactory: () => inject(IdentityService),
    },
    {
      provide: UI_MODULE_CUSTOM_COMPONENTS,
      useValue: uiModuleCustomComponents,
    },
    {
      provide: CUSTOM_FORM_CONTROLS,
      useValue: controlFactory,
    },
    {
      provide: HUB_URL,
      useFactory: () =>
        inject(CaEnvService).apiHostV2 + '/signalr/communicationhub',
    },
    {
      provide: AUTH_ROUTES,
      useValue: {
        login: 'account/login',
        unauthorized: 'not-authorized',
      },
    },
    MsalService,
    SignalRService,
  ],
  bootstrap: [CustomerAppComponent],
})
export class CustomerAppModule {
  constructor(_envService: CaEnvService) {
    // Registering Syncfusion license key
    registerLicense(_envService.sfKey);
  }
}

export function createSignalRConfig(): SignalRConfiguration {
  const c = new SignalRConfiguration();
  // c.hubName = 'messaging';
  // c.url = EnvService. '/signalr';
  // c.withCredentials = true;

  return c;
}

export function MSALConfigFactory(env: CaEnvService): Configuration {
  const auth = {
    auth: {
      clientId: env.clientId,
      authority: env.authority,
      redirectUri: env.redirectUrl,
      postLogoutRedirectUri: env.postLogoutRedirectUri,
    },
    system: {
      // todo: remove logger
      logger: new Logger(loggerCallback, {
        correlationId: '1234',
        level: LogLevel.Verbose,
        piiLoggingEnabled: true,
      }),
    },
  };

  return auth as Configuration;
}

export function MSALAngularConfigFactory(
  env: CaEnvService
): MsalAngularConfiguration {
  const config = {
    consentScopes: [env.scopeUri],
    protectedResourceMap: [
      [env.apiHost, [env.scopeUri]],
      ['https://graph.microsoft.com/v1.0/me', ['user.read']],
    ],
  };

  return config as MsalAngularConfiguration;
}

export function loggerCallback(logLevel, message) {
  console.log(message);
}
