import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NauticalPortModel } from '@seahorse/domain';
import * as _ from 'underscore';
import { CollectionDisplay } from '../../../layout/models/collection-display.model';

import { TranslateService } from '@ngx-translate/core';
import { NauticalVisitDataService } from '@seahorse/domain';
import { NauticalObjectFilterGroupModel } from '../../../nautical-geography/models/nautical-object-filter-group.model';
import { NauticalObjectFilterDataService } from '../../../nautical-geography/services/nautical-object-filter-data.service';
import { NauticalVisitInteractionService } from '../../services/nautical-visit-interaction.service';

@Component({
  selector: 'ca-nautical-visit-header',
  templateUrl: './nautical-visit-header.component.html',
})
export class NauticalVisitHeaderComponent implements OnInit {
  nauticalFilterItems: NauticalObjectFilterGroupModel[] = [];
  ports: NauticalPortModel[] = [];
  selectedFilter = 'no filter';
  selectedNauticalFilter = -1;
  selectedView = CollectionDisplay.List;
  view = CollectionDisplay;
  selectedPortText = this.translateService.instant('nautical.visit.selectPort');

  @Output() printEvent: EventEmitter<boolean>;

  constructor(
    private nauticalObjectFilterData: NauticalObjectFilterDataService,
    private nauticalVisitData: NauticalVisitDataService,
    public nauticalVisitInteractionService: NauticalVisitInteractionService,
    private translateService: TranslateService
  ) {
    this.printEvent = new EventEmitter<boolean>();
  }

  ngOnInit() {
    this.loadPorts();
    this.loadNauticalFilters();
  }

  loadNauticalFilters() {
    this.nauticalFilterItems = [];

    this.nauticalObjectFilterData.get().subscribe((result) => {
      if (result.hasResult) {
        this.nauticalFilterItems = [
          {
            id: -1,
            name: this.translateService.instant('shared.terms.selectFilter'),
          },
        ].concat(result.result);
      }
    });
  }

  loadPorts() {
    this.nauticalVisitData.getPortsWithVisits().subscribe(
      (response) => {
        this.ports = _.map(_.sortBy(response.result, 'unloCode'), (item) => {
          item['displayText'] = item.unloCode + ' - ' + item.name;
          return item;
        });

        if (this.ports && this.ports.length > 0) {
          this.setSelectedFilter('inport');
          let portIndex = _.findIndex(
            this.ports,
            (p) => p.unloCode === 'NLAMS'
          );
          if (portIndex === -1) {
            portIndex = 0;
          }
          const selectedPort = this.ports[portIndex];
          this.setSelectedPort(selectedPort);
          this.selectedPortText = selectedPort['displayText'];
        }
      },
      (error) => {}
    );
  }

  setSelectedFilter(filter: string) {
    switch (filter) {
      case 'departing':
      case 'expected':
      case 'inport':
      case 'sailed':
        this.selectedFilter = 'nautical.visit.filter.' + filter;
        break;
      default:
        this.selectedFilter = 'nautical.visit.filter.noFilter';
        break;
    }

    this.nauticalVisitInteractionService.setSelectedFilter(filter);
  }

  setSelectedNauticalFilter() {
    this.nauticalVisitInteractionService.setSelectedNauticalFilter(
      this.selectedNauticalFilter
    );
  }

  setSelectedPort(port: NauticalPortModel) {
    this.nauticalVisitInteractionService.setSelectedPort(port);
  }

  setSelectedView() {
    this.nauticalVisitInteractionService.setSelectedView(this.selectedView);
  }
}
