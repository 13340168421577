import { CollectionDisplay } from '../../../../layout/models/collection-display.model';

export class InvoicingFlowsOverviewStateModel {
  id: number;
  view: CollectionDisplay;
  search: string;
  pIndex: number;
  pSize: number;

  constructor() {
    this.id = null;
    this.view = CollectionDisplay.List;
    this.search = '';
    this.pIndex = 0;
    this.pSize = 20;
  }
}
