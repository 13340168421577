import { Component, Input, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService, ResultWrapper } from '@seahorse/common';
import { NauticalShipDataService, NauticalShipModel, NauticalVisitBaseModel, NauticalVisitDataService, NauticalVisitModel, NauticalVisitOverviewModel, ShipSearchModel } from '@seahorse/domain';
import * as moment from 'moment';
import { merge, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  tap
} from 'rxjs/operators';
import * as _ from 'underscore';

export enum ShipPickerMode {
  Required, // It is required to set a ship; via the picker or input
  Editable, // It is NOT Required, but enabled to changed the ship via the picker
  ReadOnly, // Disabled to choose a ship, ship is only set via the input.
  // Ship name will show in the dialog. Only the visits of the given ship will shown.
  HideShip, // Disabled to choose a ship, ship is only set via the input.
  // No ship name will show in the dialog. Only the visits of the given ship will shown.
  NoShip, // Show only the visit picker, ship is not relevant in this dialog. User searchs the visits by reference nr
}
@Component({ templateUrl: 'nautical-visit-picker-dialog.component.html' })
export class NauticalVisitPickerDialogComponent {
  @Input() set nauticalVisit(v: NauticalVisitBaseModel) {
    if (v) {
      this._nauticalVisit = _.clone(v);

      if (v.ship) {
        if (!this.nauticalShip || this.nauticalShip.id !== v.ship.id) {
          this.nauticalShip = v.ship;
        }
      } else if (v.shipId) {
        if (
          !this.nauticalShip ||
          this.nauticalShip.id !== v.shipId.toString()
        ) {
          const ship = new NauticalShipModel();
          ship.id = v.shipId.toString();
          this.nauticalShip = ship;
        }
      }
      this.loadShip(true);

      if (this._nauticalVisit.id && !this._nauticalVisit.referenceNumber) {
        this.loadVisit(this._nauticalVisit.id);
      }
    } else {
      this._nauticalVisit = null;
    }
  }
  get nauticalVisit() {
    return this._nauticalVisit;
  }

  @Input() nauticalVisitRequired?: boolean;

  @Input() set nauticalShip(s: NauticalShipModel) {
    if (s && s.id) {
      if (!this._nauticalShip || this._nauticalShip.id !== s.id) {
        this._nauticalShip = _.clone(s);
        this.loadShip();
      }
    } else {
      this._nauticalShip = null;
    }
  }
  get nauticalShip() {
    return this._nauticalShip;
  }

  @Input() nauticalShipPickerMode: ShipPickerMode;
  @Input() title?: string;
  @Input() nauticalVisitLoading: boolean;
  @Input() nauticalShipLoading: boolean;

  @ViewChild('visitTypeahead', { static: true }) visitTypeahead: NgbTypeahead;

  private _nauticalVisit: NauticalVisitBaseModel = null;
  private _nauticalShip: NauticalShipModel = null;
  private visitIsSetById = false;

  focusVisit$ = new Subject<string>();
  clickVisit$ = new Subject<string>();
  nauticalVisitLoadingFailed: boolean;
  nauticalVisits: NauticalVisitOverviewModel[];
  nauticalVisitsCount: number;
  nauticalVisitsLoading: boolean;
  nauticalShipLoadingFailed: boolean;
  nauticalShipsLoading: boolean;
  nauticalShipsLoadingFailed: boolean;
  shipPickerMode;

  constructor(
    private activeModal: NgbActiveModal,
    private translate: TranslateService,
    private notification: NotificationService,
    private nauticalVisitData: NauticalVisitDataService,
    private nauticalShipData: NauticalShipDataService
  ) {
    this.nauticalVisitRequired = true;
    this.nauticalShipPickerMode = ShipPickerMode.Editable;
    this.title = this.translate.instant('nautical.visit.pickerDialog.title');
    this._nauticalVisit = null;
    this._nauticalShip = null;
    this.nauticalVisitLoadingFailed = false;
    this.nauticalVisits = [];
    this.nauticalVisitsCount = null;
    this.nauticalVisitsLoading = false;
    this.nauticalShipLoading = false;
    this.nauticalShipLoadingFailed = false;
    this.nauticalShipsLoading = false;
    this.nauticalShipsLoadingFailed = false;
    this.shipPickerMode = ShipPickerMode;
  }

  loadShip(skipLoadVisits?: boolean) {
    if (this.nauticalShip && this.nauticalShipLoading === false) {
      // only id is given, load ship properties by id if the mode is NOT hideShip and noShip
      if (
        (this.nauticalShip.name === undefined ||
          this.nauticalShip.name === null) &&
        this.shipPickerMode !== ShipPickerMode.HideShip &&
        this.shipPickerMode !== ShipPickerMode.NoShip
      ) {
        this.nauticalShipLoading = true;
        this.nauticalShipLoadingFailed = false;
        this.nauticalShipData.getById(this.nauticalShip.id).subscribe(
          (ship: NauticalShipModel) => {
            if (ship) {
              this._nauticalShip = ship;
            }
          },
          (e) => {
            this.nauticalShipLoading = false;
            this.nauticalShipLoadingFailed = true;
            this.notification.showError(
              _.pluck(e.error.messages, 'message').join('\n'),
              this.translate.instant('shared.terms.failed')
            );
          },
          () => (this.nauticalShipLoading = false)
        );
      }

      if (skipLoadVisits !== true) {
        this.loadVisits();
      }
    }
  }

  loadVisits() {
    this.nauticalVisits = [];
    this.nauticalVisitsCount = null;

    if (this.nauticalShip && this.nauticalVisitsLoading === false) {
      this.nauticalVisitsLoading = true;
      this.nauticalVisitData
        .getListOverviewForShip(this.nauticalShip.id, null, null, false, 0, 100)
        .subscribe(
          (r: ResultWrapper<NauticalVisitOverviewModel[]>) => {
            if (r.hasResult) {
              this.nauticalVisits = r.result;
              this.nauticalVisitsCount = r.count;
            }
          },
          (e) => {
            this.nauticalVisitsLoading = false;
            this.notification.showError(
              _.pluck(e.error.messages, 'message').join('\n'),
              this.translate.instant('shared.terms.failed')
            );
          },
          () => (this.nauticalVisitsLoading = false)
        );
    }
  }

  loadVisit(id) {
    this.nauticalVisitData.getById(id).subscribe(
      (r: ResultWrapper<NauticalVisitModel>) => {
        if (r.hasResult && r.result) {
          this.nauticalVisits = [r.result];
          this._nauticalVisit = r.result;
          this.visitIsSetById = true;
        }
      },
      (e) => {
        this.notification.showError(
          _.pluck(e.error.messages, 'message').join('\n'),
          this.translate.instant('shared.terms.failed')
        );
      }
    );
  }

  nauticalVisitFormatter = (
    nauticalVisit: NauticalVisitModel | NauticalVisitOverviewModel
  ): string => {
    let f = '';
    const a: string[] = [];

    if (nauticalVisit) {
      f = nauticalVisit.referenceNumber;
      if (nauticalVisit.ata) {
        a.push(
          `${this.translate.instant('nautical.terms.ata')}: ${moment(
            nauticalVisit.ata
          ).format('DD-MM-YYYY HH:mm')}`
        );
      } else if (nauticalVisit.eta) {
        a.push(
          `${this.translate.instant('nautical.terms.eta')}: ${moment(
            nauticalVisit.eta
          ).format('DD-MM-YYYY HH:mm')}`
        );
      }
      if (nauticalVisit.atd) {
        a.push(
          `${this.translate.instant('nautical.terms.atd')}: ${moment(
            nauticalVisit.atd
          ).format('DD-MM-YYYY HH:mm')}`
        );
      } else if (nauticalVisit.etd) {
        a.push(
          `${this.translate.instant('nautical.terms.etd')}: ${moment(
            nauticalVisit.etd
          ).format('DD-MM-YYYY HH:mm')}`
        );
      }
      if (a.length) {
        f += ` (${a.join(', ')})`;
      }
    }

    return f;
  }

  nauticalShipFormatter = (nauticalShip: NauticalShipModel): string =>
    nauticalShip.name ? nauticalShip.name.toUpperCase() : ''

  nauticalShipsFormatter = (nauticalShip: NauticalShipModel): string => {
    let f: string = nauticalShip.name.toUpperCase();
    const a: string[] = [];

    if (nauticalShip.shipType && nauticalShip.shipType.name) {
      a.push(nauticalShip.shipType.name);
    }
    if (nauticalShip.callSign) {
      a.push(
        `${this.translate.instant('nautical.ship.model.callSign')}: ${
          nauticalShip.callSign
        }`
      );
    }
    if (nauticalShip.fisheryNumber) {
      a.push(nauticalShip.fisheryNumber);
    }
    if (nauticalShip.imo) {
      a.push(
        `${this.translate.instant('nautical.ship.model.imo')}: ${
          nauticalShip.imo
        }`
      );
    } else if (nauticalShip.eni) {
      a.push(
        `${this.translate.instant('nautical.ship.model.eni')}: ${
          nauticalShip.eni
        }`
      );
    }
    if (a.length) {
      f += ` (${a.join(', ')})`;
    }

    return f;
  }

  searchNauticalShip = (input$: Observable<string>): any =>
    input$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.nauticalShipsLoading = true)),
      switchMap((s) =>
        this.nauticalShipData.find(new ShipSearchModel(s), 0, 100).pipe(
          tap(() => (this.nauticalShipsLoadingFailed = false)),
          map((r) => r.result),
          catchError((e) => {
            this.nauticalShipsLoadingFailed = true;
            this.notification.showError(
              e,
              this.translate.instant('shared.terms.failed')
            );
            return of([]);
          })
        )
      ),
      tap(() => (this.nauticalShipsLoading = false))
    )

  searchNauticalVisit = (input$: Observable<string>): any => {
    const debouncedText$ = input$.pipe(
      debounceTime(300),
      distinctUntilChanged()
    );

    const inputFocus$ = this.focusVisit$;

    return merge(debouncedText$, inputFocus$).pipe(
      tap(() => (this.nauticalVisitLoading = true)),
      switchMap((s) => {
        if (
          this.nauticalShipPickerMode !== ShipPickerMode.NoShip ||
          (this.nauticalShip && (s === undefined || s === null || s === ''))
        ) {
          const search = this.visitIsSetById === true ? null : s;
          this.visitIsSetById = false;
          return this.nauticalVisitData
            .getListOverviewForShip(
              this.nauticalShip.id,
              null,
              search,
              false,
              0,
              100
            )
            .pipe(
              tap(() => (this.nauticalVisitLoadingFailed = false)),
              map((r) => r.result),
              catchError((e) => {
                this.nauticalVisitLoadingFailed = true;
                this.notification.showError(
                  e,
                  this.translate.instant('shared.terms.failed')
                );
                return of([]);
              })
            );
        } else {
          const search =
            this.visitIsSetById === true
              ? this.nauticalVisit.referenceNumber
              : s;
          this.visitIsSetById = false;
          return s === undefined || s === null || s === ''
            ? of([])
            : this.nauticalVisitData
                .getListOverviewForPortByReferences([search], 0, 100)
                .pipe(
                  tap(() => (this.nauticalVisitLoadingFailed = false)),
                  map((r) => r.result),
                  catchError((e) => {
                    this.nauticalVisitLoadingFailed = true;
                    this.notification.showError(
                      e,
                      this.translate.instant('shared.terms.failed')
                    );
                    return of([]);
                  })
                );
        }
      }),
      tap(() => (this.nauticalVisitLoading = false))
    );
  }

  setNull(attribute: string) {
    switch (attribute) {
      case 'nauticalVisit': {
        this.nauticalVisit = null;
        break;
      }

      case 'nauticalShip': {
        this.nauticalVisit = null;
        this.nauticalVisits = [];
        this.nauticalVisitsCount = null;
        this.nauticalShip = null;
        break;
      }
    }
  }

  onConfirmButtonClick() {
    if (
      (this.nauticalShipPickerMode === ShipPickerMode.Required &&
        !this.nauticalShip) ||
      (this.nauticalVisitRequired && !this.nauticalVisit)
    ) {
      return;
    }
    this.activeModal.close({
      nauticalVisit: this.nauticalVisit,
      nauticalShip: this.nauticalShip,
    });
  }
}
