import { Component, EventEmitter, Output } from '@angular/core';
import { CoordinatePointModel } from '@seahorse/domain';

@Component({
  selector: 'map-popup-centerpoint',
  templateUrl: './map-popup-centerpoint.component.html',
  styleUrls: ['./map-popup-centerpoint.component.scss'],
})
export class MapPopupCenterpointComponent {
  coordinates: CoordinatePointModel;
  @Output() onCoordinatesUpdated = new EventEmitter();

  updateCenterpoint() {
    this.onCoordinatesUpdated.emit();
  }
}
