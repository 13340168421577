<div class="page-with-master-detail">
  <div class="container-fluid py-3">
    <div class="row">
      <div class="col-md-12 col-lg-6 mb-4">
        <div class="card">
          <div class="card-header">
            <span>{{ 'shared.branding.shipm8' | translate }}</span>
          </div>

          <div class="card-body">
            <form #invoiceForm="ngForm" *ngIf="customDataDefinition">
              <div class="column">
                <h5 class="mb-3 ml-3">
                  {{ 'account.register.invoicing.title' | translate }}
                </h5>

                <div class="custom-control custom-switch ml-3 cursor-pointer">
                  <input
                    [(ngModel)]="createInvoices"
                    type="checkbox"
                    class="custom-control-input cursor-pointer"
                    id="createInvoices"
                    name="createInvoices"
                  />
                  <label for="createInvoices" class="custom-control-label">
                    {{
                      'account.register.invoicing.createInvoices' | translate
                    }}
                    <b>{{ customDataDefinition.name }}</b>
                  </label>
                </div>

                <p class="mb-2 note">
                  {{ 'account.register.invoicing.note' | translate }}
                </p>
              </div>

              <div *ngIf="createInvoices" class="invoice-form">
                <p class="mb-2 mt-2">
                  {{ 'account.register.invoicing.emailAddress' | translate }}
                </p>
                <div class="col-9">
                  <div class="form-group">
                    <input
                      required
                      class="form-control"
                      name="email"
                      #email="ngModel"
                      [(ngModel)]="invoiceEmail"
                    />
                    <small
                      *ngIf="
                        saveStarted &&
                        (email.value == null || email.value == '')
                      "
                      class="text-danger"
                      >{{
                        'account.register.invoicing.errors.emailRequired'
                          | translate
                      }}</small
                    >
                  </div>
                </div>

                <p class="mb-2 mt-3">
                  {{
                    'account.register.invoicing.tariffCalculated' | translate
                  }}
                </p>
                <div class="col-9">
                  <ng-select
                    required
                    name="type"
                    (change)="initializeBusinessLogic($event)"
                    [items]="businessLogicTypesList"
                    bindLabel="name"
                    bindValue="id"
                    [clearable]="false"
                    [searchable]="false"
                  >
                  </ng-select>
                  <small *ngIf="saveStarted && !hasBL" class="text-danger">{{
                    'invoicing.tariffs.businessLogic.errors.businessLogicTypeEmpty'
                      | translate
                  }}</small>
                </div>

                <div
                  *ngIf="
                    hasBL &&
                    item.businessLogicType ===
                      BusinessLogicTypes.CalculationBracket
                  "
                >
                  <div
                    class="col-9 mt-3 row no-margin bracket-items"
                    *ngFor="let bracketItem of bracketModels; let isLast = last"
                  >
                    <div class="form-group mr-2">
                      <input
                        required
                        class="form-control"
                        name="{{ bracketItem.id }}"
                        #minValue="ngModel"
                        placeholder="From"
                        [(ngModel)]="bracketItem.minValue"
                      />
                      <small
                        *ngIf="
                          saveStarted &&
                          (minValue.value == null || minValue.value <= 0)
                        "
                        class="text-danger"
                        >{{
                          'account.register.invoicing.errors.fromRequired'
                            | translate
                        }}</small
                      >
                    </div>
                    <div class="form-group mr-2">
                      <input
                        required
                        class="form-control"
                        name="{{ bracketItem.id }}"
                        #maxValue="ngModel"
                        placeholder="To"
                        [(ngModel)]="bracketItem.maxValue"
                      />
                      <small
                        *ngIf="
                          saveStarted &&
                          (maxValue.value == null || maxValue.value <= 0)
                        "
                        class="text-danger"
                        >{{
                          'account.register.invoicing.errors.toRequired'
                            | translate
                        }}</small
                      >
                    </div>
                    <div class="form-group mr-2">
                      <input
                        required
                        class="form-control"
                        name="{{ bracketItem.id }}"
                        #bracketAmount="ngModel"
                        placeholder="Amount"
                        [(ngModel)]="bracketItem.amount"
                      />
                      <small
                        *ngIf="
                          saveStarted &&
                          (bracketAmount.value == null ||
                            bracketAmount.value <= 0)
                        "
                        class="text-danger"
                        >{{
                          'account.register.invoicing.errors.amountRequired'
                            | translate
                        }}</small
                      >
                    </div>
                    <div class="form-group mt-1">
                      <button
                        class="btn btn-outline-secondary btn-sm mr-2"
                        type="button"
                        (click)="removeRow(bracketItem)"
                      >
                        <i class="fa-regular fa-minus"></i>
                      </button>
                      <button
                        class="btn btn-outline-secondary btn-sm"
                        type="button"
                        (click)="addRow()"
                        *ngIf="isLast"
                        [disabled]="
                          !bracketItem.minValue || !bracketItem.maxValue
                        "
                      >
                        <i class="fa-regular fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>

                <div
                  class="col-9 mt-3 row no-margin"
                  *ngIf="
                    hasBL &&
                    item.businessLogicType ===
                      BusinessLogicTypes.CalculationPeriod
                  "
                >
                  <div class="form-group mr-2">
                    <input
                      required
                      type="number"
                      class="form-control"
                      name="periodQuantity"
                      #periodQuantity="ngModel"
                      placeholder="Period quantity"
                      [(ngModel)]="item.periodQuantity"
                    />
                    <small
                      *ngIf="
                        saveStarted &&
                        (periodQuantity.value == null ||
                          periodQuantity.value <= 0)
                      "
                      class="text-danger"
                      >{{
                        'account.register.invoicing.errors.periodQuantityRequired'
                          | translate
                      }}</small
                    >
                  </div>
                  <div class="row-section-lg">
                    <ng-select
                      #period="ngModel"
                      required
                      [(ngModel)]="item.period"
                      name="period"
                      [items]="timePeriods"
                      placeholder="Period"
                      bindLabel="name"
                      bindValue="id"
                      [clearable]="false"
                      [searchable]="false"
                    >
                    </ng-select>
                    <small
                      *ngIf="saveStarted && period.value == null"
                      class="text-danger"
                      >{{
                        'account.register.invoicing.errors.periodRequired'
                          | translate
                      }}</small
                    >
                  </div>
                </div>

                <p class="mb-2 mt-3">
                  {{ 'account.register.invoicing.tariffIs' | translate }}
                </p>
                <div class="col-9 row no-margin">
                  <div
                    class="form-group row-section mr-2"
                    *ngIf="
                      hasBL &&
                      item.businessLogicType !=
                        BusinessLogicTypes.CalculationBracket
                    "
                  >
                    <input
                      required
                      class="form-control"
                      name="amount"
                      type="number"
                      #amount="ngModel"
                      [(ngModel)]="invoicingTariff.amount"
                    />
                    <small
                      *ngIf="
                        saveStarted &&
                        (amount.value == null || amount.value <= 0)
                      "
                      class="text-danger"
                      >{{
                        'account.register.invoicing.errors.amountRequired'
                          | translate
                      }}</small
                    >
                  </div>
                  <div class="row-section-lg">
                    <ng-select
                      #currency="ngModel"
                      required
                      [(ngModel)]="invoicingTariff.currencyCode"
                      name="currency"
                      [items]="currencies"
                      bindLabel="name"
                      bindValue="name"
                      [clearable]="false"
                      [searchable]="false"
                    >
                    </ng-select>
                    <small
                      *ngIf="saveStarted && currency.value == null"
                      class="text-danger"
                      >{{
                        'account.register.invoicing.errors.currencyRequired'
                          | translate
                      }}</small
                    >
                  </div>
                  <p class="mb-3 mt-2 text-center row-section-m">
                    {{ 'account.register.invoicing.basedOn' | translate }}
                  </p>
                  <div class="row-section-lg">
                    <ng-select
                      [disabled]="!hasBL"
                      #basedOn="ngModel"
                      required
                      name="basedOn"
                      [(ngModel)]="invoicingTariff.basedOn"
                      [items]="selectedFieldDefinitions"
                      bindLabel="name"
                      bindValue="name"
                      [clearable]="false"
                      [searchable]="false"
                    >
                    </ng-select>
                    <small
                      *ngIf="saveStarted && basedOn.value == null"
                      class="text-danger"
                      >{{
                        'account.register.invoicing.errors.basedOnRequired'
                          | translate
                      }}</small
                    >
                  </div>
                </div>

                <p class="mb-2 mt-1">
                  {{ 'account.register.invoicing.differentTariff' | translate }}
                </p>
                <div class="col-9">
                  <ng-select
                    #tariffOptions="ngModel"
                    required
                    name="tariffOptions"
                    (change)="setWeekendSpecification()"
                    [(ngModel)]="invoicingTariff.tariffOption"
                    [items]="differentTariffs"
                    bindLabel="name"
                    bindValue="id"
                    [clearable]="false"
                    [searchable]="false"
                  >
                  </ng-select>
                  <small
                    *ngIf="saveStarted && tariffOptions.value == null"
                    class="text-danger"
                  >
                    {{
                      'account.register.invoicing.errors.tariffOptionRequired'
                        | translate
                    }}</small
                  >
                </div>

                <div
                  class="col-12 row left-margin"
                  *ngIf="
                    invoicingTariff.tariffOption &&
                    invoicingTariff.tariffOption !== TariffOption.None
                  "
                >
                  <p class="mb-3 mt-3 text-center row-section-m">
                    {{
                      'account.register.invoicing.weekendStartsOn' | translate
                    }}
                  </p>
                  <div class="row no-margin weekend-row">
                    <ng-select
                      #weekendStart="ngModel"
                      class="weekend-dropdown"
                      required
                      name="weekendStart"
                      [(ngModel)]="
                        invoicingTariff.weekendCalculations.weekendStarts
                      "
                      [items]="weekendStartsDays"
                      bindLabel="name"
                      bindValue="id"
                      [clearable]="false"
                      [searchable]="false"
                    >
                    </ng-select>
                    <ngb-timepicker
                      class="weekend-timepicker ml-2"
                      [(ngModel)]="
                        invoicingTariff.weekendCalculations.weekendStartsHour
                      "
                      name="weekendStartHour"
                    >
                    </ngb-timepicker>
                  </div>
                  <p class="mb-3 mt-3 ml-2 mr-2 text-center row-section-sm">
                    {{ 'account.register.invoicing.until' | translate }}
                  </p>
                  <div class="row no-margin weekend-row">
                    <ng-select
                      #weekendEnd="ngModel"
                      class="weekend-dropdown"
                      required
                      name="weekendEnd"
                      [(ngModel)]="
                        invoicingTariff.weekendCalculations.weekendEnds
                      "
                      [items]="weekendEndsDays"
                      bindLabel="name"
                      bindValue="id"
                      [clearable]="false"
                      [searchable]="false"
                    >
                    </ng-select>
                    <ngb-timepicker
                      class="weekend-timepicker ml-2"
                      [(ngModel)]="
                        invoicingTariff.weekendCalculations.weekendEndsHour
                      "
                      name="weekendEndHour"
                    >
                    </ngb-timepicker>
                  </div>
                </div>
                <!--
                <p class="mb-2"
                  [ngClass]="{ 'mt-3' : !invoicingTariff.tariffOption || invoicingTariff.tariffOption === TariffOption.None }">
                  The invoice should be sent to:</p>
                <div class="col-5">
                  <ng-select #invoiceRecipientFields="ngModel" required name="invoiceRecipientFields"
                    [(ngModel)]="invoiceRecipient" [items]="invoiceRecipientFieldDefinitions" bindLabel="name"
                    bindValue="name" [clearable]="false" [searchable]="false">
                  </ng-select>
                </div> -->
              </div>
            </form>

            <div class="d-flex d-flex justify-content-end align-items-center">
              <button type="button" class="btn btn-primary" (click)="save()">
                {{ 'shared.terms.save' | translate }}
              </button>
            </div>

            <div
              class="d-flex d-flex justify-content-end align-items-center mt-2"
            >
              <sh-loading-indicator
                class="text-center loader-sm mr-4"
                *ngIf="isLoading"
              ></sh-loading-indicator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
