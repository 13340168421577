<table class="table" *ngIf="!loadAttributes">
  <tbody>
    <tr *ngFor="let attribute of attributes">
      <td style="width: 150px" class="text-muted">
        {{ attribute.createdOn | date: 'dd-MM-yyyy HH:mm' }}
      </td>
      <td>
        {{ attribute.category }} {{ attribute.fieldName }}:
        {{ attribute.fieldValue }}
      </td>
    </tr>
  </tbody>
</table>
