import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CustomDataBaseModel, ObjectDefinitionModel } from '@seahorse/domain';
import { IdentityService } from '../../../core/services/identity.service';
import { TableColumnModel } from '../../../layout/models/table.model';
import { EventModel } from '../../../shared/models/event.model';

@Component({
  selector: 'ca-custom-content-list-card',
  templateUrl: './custom-content-list-card.component.html',
})
export class CustomContentListCardComponent {
  @Input() allowSelection = true;
  @Input() columns: TableColumnModel[];
  @Input() definition: ObjectDefinitionModel = null;
  @Input() entries: CustomDataBaseModel[];
  @Input() loading: boolean;
  @Input() title: string;

  @Output() onEvent: EventEmitter<EventModel>;

  constructor(
    public identityService: IdentityService,
    public translate: TranslateService,
    public modal: NgbModal
  ) {
    this.columns = [];
    this.entries = [];
    this.loading = false;
    this.title = null;
    this.onEvent = new EventEmitter<EventModel>();
  }

  onSelected(entry) {
    const event = new EventModel();
    event.action = 'selected';
    event.data = entry;
    this.onEvent.emit(event);
  }
}
