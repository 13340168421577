<div class="modal-header">
  {{ 'shared.terms.manageItems' | translate }}
  <button type="button" class="close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<sh-loading-indicator *ngIf="isLoading"></sh-loading-indicator>
<div class="modal-body" *ngIf="!areas || areas.length === 0">
  <p class="text-muted">0 {{ 'shared.terms.itemsFound' | translate }}</p>
</div>
<table class="table" *ngIf="areas && areas.length > 0">
  <tbody>
    <tr *ngFor="let area of areas; let i = index">
      <ng-container *ngIf="editIndex === i">
        <td>
          <input type="text" class="form-control" [(ngModel)]="area.name" />
        </td>
        <td class="btn-list btn-list-nowrap text-right">
          <button class="btn btn-success mr-1" (click)="save()">
            <i class="fa-regular fa-save fa-fw"></i>
            {{ 'shared.terms.save' | translate }}
          </button>
          <button class="btn btn-outline-secondary" (click)="cancelEdit()">
            {{ 'shared.terms.cancel' | translate }}
          </button>
        </td>
      </ng-container>
      <ng-container *ngIf="editIndex !== i">
        <td>{{ area.name }}</td>
        <td class="btn-list btn-list-nowrap text-right">
          <button
            class="btn btn-outline-secondary btn-sm mr-1"
            (click)="setEdit(i, area)"
            title="{{ 'shared.terms.edit' | translate }}"
          >
            <i class="fa-regular fa-pencil-alt fa-fw"></i>
          </button>
          <button
            class="btn btn-outline-secondary btn-sm"
            title="{{ 'shared.terms.delete' | translate }}"
            (click)="delete(i)"
          >
            <i class="fa-solid fa-trash fa-fw"></i>
          </button>
        </td>
      </ng-container>
    </tr>
  </tbody>
</table>
<div class="modal-footer text-right">
  <button
    type="button"
    class="btn btn-outline-secondary"
    (click)="addCurrentArea()"
  >
    <i class="fa-regular fa-plus fa-fw mr-1"></i>
    {{ 'map.areas.addCurrent' | translate }}
  </button>
</div>
