import { NgModule } from '@angular/core';
import { OrderRegistrationTariffTypePipe } from './order-registration-tariff-type.pipe';
import { OrderRegistrationCurrencyTranslatePipe } from './order-registration-currency-translate.pipe';
import { OrderRegistrationStatusLogPipe } from './order-registration-status-log.pipe';
import { OrderRegistrationStatusPipe } from '@seahorse/domain';

@NgModule({
  imports: [OrderRegistrationStatusPipe],
  declarations: [
    OrderRegistrationTariffTypePipe,
    OrderRegistrationCurrencyTranslatePipe,
    OrderRegistrationStatusLogPipe,
  ],
  exports: [
    OrderRegistrationTariffTypePipe,
    OrderRegistrationCurrencyTranslatePipe,
    OrderRegistrationStatusLogPipe,
    OrderRegistrationStatusPipe,
  ],
  providers: [
    OrderRegistrationTariffTypePipe,
    OrderRegistrationCurrencyTranslatePipe,
    OrderRegistrationStatusLogPipe,
    OrderRegistrationStatusPipe,
  ],
})
export class OrderRegistrationsPipesModule {}
