<div class="modal-header align-items-center">
  <h1 class="modal-title">{{ 'shared.terms.editAttribute' | translate }}</h1>
  <button closeModal type="button" class="btn btn-link text-dark">
    <i class="fa-solid fa-times fa-sm fa-fw"></i>
  </button>
</div>
<div class="modal-body">
  <div class="form-row">
    <div class="form-group col-12">
      <label [for]="attribute.name" class="d-flex justify-content-between">
        <b [ngClass]="{ required: attribute.required }">{{
          'customModules.orderRegistrations.model.' + attribute.name | translate
        }}</b>
      </label>
      <ng-container [ngSwitch]="attribute.type">
        <div *ngSwitchCase="'text'" class="form-group">
          <input
            [(ngModel)]="attribute.value"
            [id]="attribute.name"
            [name]="attribute.name"
            [required]="attribute.required"
            [placeholder]="
              'customModules.orderRegistrations.placeholder.' + attribute.name
                | translate
            "
            type="text"
            class="form-control"
          />
        </div>
        <div *ngSwitchCase="'quantity'" class="form-group">
          <input
            [(ngModel)]="attribute.value"
            [id]="attribute.name"
            [name]="attribute.name"
            [required]="attribute.required"
            [placeholder]="
              'customModules.orderRegistrations.placeholder.' + attribute.name
                | translate
            "
            min="0"
            type="number"
            class="form-control"
          />
        </div>
        <div *ngSwitchCase="'boolean'" class="form-group">
          <div class="custom-control custom-switch">
            <input
              [(ngModel)]="attribute.value"
              [id]="attribute.name"
              [name]="attribute.name"
              type="checkbox"
              class="custom-control-input"
            />
            <label [for]="attribute.name" class="custom-control-label">
              {{
                'customModules.orderRegistrations.placeholder.' + attribute.name
                  | translate
              }}
            </label>
          </div>
        </div>
        <div *ngSwitchCase="'textarea'" class="form-group">
          <textarea
            [(ngModel)]="attribute.value"
            [id]="attribute.name"
            [name]="attribute.name"
            [required]="attribute.required"
            [placeholder]="
              'customModules.orderRegistrations.placeholder.' + attribute.name
                | translate
            "
            rows="5"
            class="form-control"
          ></textarea>
        </div>
        <div *ngSwitchCase="'dateTime'" class="form-group">
          <temp-date-time-picker
            [(dateTime)]="attribute.value"
            [isContained]="true"
          ></temp-date-time-picker>
        </div>
        <div *ngSwitchCase="'orderRegistrationStatus'" class="form-group">
          <select
            [(ngModel)]="attribute.value"
            [id]="attribute.name"
            [name]="attribute.name"
            [required]="attribute.required"
            class="custom-select"
          >
            <option selected [ngValue]="null">
              {{ 'shared.terms.choose' | translate }}...
            </option>
            <optgroup *ngIf="typeList.length">
              <option
                *ngFor="let typeListOption of typeList"
                [ngValue]="typeListOption"
              >
                {{ typeListOption | shOrderRegistrationStatus }}
              </option>
            </optgroup>
          </select>
        </div>
        <div *ngSwitchCase="'orderRegistrationCurrency'" class="form-group">
          <select
            [(ngModel)]="attribute.value"
            [id]="attribute.name"
            [name]="attribute.name"
            [required]="attribute.required"
            class="custom-select"
          >
            <option selected [ngValue]="null">
              {{ 'shared.terms.choose' | translate }}...
            </option>
            <optgroup *ngIf="typeList.length">
              <option
                *ngFor="let typeListOption of typeList"
                [ngValue]="typeListOption"
              >
                {{ typeListOption | orderRegistrationCurrencyTranslate }}
              </option>
            </optgroup>
          </select>
        </div>
      </ng-container>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button closeModal type="button" class="btn btn-link flex-fill">
    {{ 'shared.terms.cancel' | translate }}
  </button>
  <button
    [disabled]="attribute.required && !attribute.value"
    (click)="onConfirm.emit(attribute)"
    closeModal
    type="button"
    class="btn btn-success flex-fill"
  >
    {{ 'shared.terms.confirm' | translate }}
  </button>
</div>
