export function transformToCamelCase(
  str: string,
  removeSpecialCharacters: boolean = false
) {
  if (!str) {
    return str;
  }
  let output = str.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
    return index === 0 ? word.toLowerCase() : word.toUpperCase();
  });

  if (removeSpecialCharacters) {
    output = output.replace(/[^a-zA-Z0-9_]/g, '');
  } else {
    output = output.replace(/\s+/g, '');
  }
  return output;
}
