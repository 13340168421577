import { NgModule } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@seahorse/common';
import { InteractionService } from '../../shared/services/interaction.service';
import { AccountDataService } from '../../superuser-nautical/services/account-data.service';

import { ContentTypeDataService } from './content-type.service';
import { DistributionServiceDataService } from './distribution-service.service';
import { DocumentTypeDataService } from './document-type.service';
import { ExportTemplateDataService } from './export-template.service';

@NgModule({
  providers: [
    AccountDataService,
    ContentTypeDataService,
    {
      provide: 'ContentTypeInteractionService',
      useFactory: (
        dataService: ContentTypeDataService,
        modalService: NgbModal,
        notificationService: NotificationService,
        translateService: TranslateService
      ) =>
        new InteractionService(
          dataService,
          modalService,
          notificationService,
          translateService
        ),
      deps: [
        ContentTypeDataService,
        NgbModal,
        NotificationService,
        TranslateService,
      ],
    },
    DocumentTypeDataService,
    {
      provide: 'DocumentTypeInteractionService',
      useFactory: (
        dataService: DocumentTypeDataService,
        modalService: NgbModal,
        notificationService: NotificationService,
        translateService: TranslateService
      ) =>
        new InteractionService(
          dataService,
          modalService,
          notificationService,
          translateService
        ),
      deps: [
        DocumentTypeDataService,
        NgbModal,
        NotificationService,
        TranslateService,
      ],
    },
    {
      provide: 'DistributionServiceInteractionService',
      useFactory: (
        dataService: DistributionServiceDataService,
        modalService: NgbModal,
        notificationService: NotificationService,
        translateService: TranslateService
      ) =>
        new InteractionService(
          dataService,
          modalService,
          notificationService,
          translateService
        ),
      deps: [
        DistributionServiceDataService,
        NgbModal,
        NotificationService,
        TranslateService,
      ],
    },
    ExportTemplateDataService,
    {
      provide: 'ExportTemplateInteractionService',
      useFactory: (
        dataService: ExportTemplateDataService,
        modalService: NgbModal,
        notificationService: NotificationService,
        translateService: TranslateService
      ) =>
        new InteractionService(
          dataService,
          modalService,
          notificationService,
          translateService
        ),
      deps: [
        ExportTemplateDataService,
        NgbModal,
        NotificationService,
        TranslateService,
      ],
    },
  ],
})
export class ContentDistributionServicesModule {}
