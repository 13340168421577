import { inject } from '@angular/core';
import { FormModel, SeahorseFormBuilder } from '@seahorse/forms';

export type TaskGroupModalForm = FormModel<typeof CreateTaskGroupModalForm>;

export function CreateTaskGroupModalForm() {
  const fb = inject(SeahorseFormBuilder);

  return fb.group({
    selectedFormGroupId: fb.control<number | string | null>(null),
  });
}
