import { Component, Input, Output, EventEmitter } from '@angular/core';
import { OrderRegistrationModel } from '@seahorse/domain';
import { navigateToPfcProcedure } from '../../../port-call-file/procedures/navigate-to-details.procedure';
import { EventModel } from '../../../shared/models/event.model';

@Component({
  selector: 'ca-order-registration-summary',
  templateUrl: 'order-registration-summary.component.html',
})
export class OrderRegistrationSummaryComponent {
  @Input() loading = false;
  @Input() orderRegistration: OrderRegistrationModel = null;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onEvent: EventEmitter<EventModel> = new EventEmitter<EventModel>();

  private _navigateToDetails = navigateToPfcProcedure();

  openPortCallFile() {
    this._navigateToDetails(this.orderRegistration?.portCallFile);
  }
}
