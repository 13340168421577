import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { CollectionDisplay } from '../../../layout/models/collection-display.model';
import { SelectedSearchModel } from '../../../layout/models/selectedSearch.model';
import { SearchService } from '../../../layout/services/search-service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ca-overview-header',
  templateUrl: './overview-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverviewHeaderComponent implements OnDestroy {
  currentModuleName = '';
  search = new SelectedSearchModel('', '');
  CollectionDisplay = CollectionDisplay;

  @Input() searchResultCount: number = null;
  @Input() showSearch = true;
  @Input() showViewSelector = true;

  @Input() showNewButton = false;
  @Input() showFilterList = false;
  @Input() showDatePickerFilter = false;
  @Input() showInactiveFilter = false;

  @Input() date: NgbDate = null;
  @Input() selectedView = CollectionDisplay.Tiles;

  @Input() set searchInput(searchInput: string) {
    this.search = new SelectedSearchModel('', searchInput);
  }

  private subscription: Subscription;

  @Input() filters = Array<Object>();

  @Output() newButtonClicked = new EventEmitter<boolean>();
  @Output() viewChanged = new EventEmitter<CollectionDisplay>();

  showInactive = false;

  constructor(
    private searchService: SearchService,
    private cdr: ChangeDetectorRef
  ) {
    this.subscription = this.searchService.searchResultCountChanged$.subscribe(
      (c) => {
        this.searchResultCount = c;
        this.cdr.markForCheck();
      }
    );
    this.currentModuleName = this.searchService.currentModuleName;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  changeSelectedView() {
    this.searchService.setSelectedView(this.selectedView);
    this.viewChanged.emit(Number(this.selectedView));
  }

  toggleInactiveAccounts() {
    this.searchService.toggleInactiveAccounts(this.showInactive);
  }

  open() {
    this.searchService.open();
    this.newButtonClicked.emit(true);
  }

  find() {
    let searchQuery = this.search.searchQuery;
    if (this.date) {
      const date = this.date.year + '-' + this.date.month + '-' + this.date.day;
      searchQuery += ',' + date;
    }
    const search = new SelectedSearchModel(
      this.search.selectedOption,
      searchQuery
    );
    this.searchService.searchChanged(search);
  }

  setSelectedFilter(event: string) {
    this.searchService.setSelectedFilter(event);
  }
}
