import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  NgbModal,
  NgbModalOptions,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ResultWrapper } from '@seahorse/common';
import { NotificationService } from '@seahorse/common';
import { UserModel } from '@seahorse/domain';
import { UserDataService } from '../../../../user/services/user-data.service';
import { forkJoin } from 'rxjs';
import * as _ from 'underscore';

import { MemberManagerModalComponent } from '../../components/member-manager-modal/member-manager-modal.component';
import { TeamManagerModalComponent } from '../../components/team-manager-modal/team-manager-modal.component';
import { CustomMemberModel } from '../../models/custom-member.model';
import { CustomTeamModel } from '../../models/custom-team.model';
import { CustomMemberDataService } from '../../services/custom-member-data.service';
import { CustomTeamDataService } from '../../services/custom-team-data.service';

@Component({ templateUrl: './member-picker-modal.component.html' })
export class MemberPickerModalComponent implements OnInit {
  private _teams: CustomTeamModel[] = null;

  @Input() set users(users: UserModel[]) {
    if (users) {
      this.allUsers = [];
      this.convertUsersToMemberModel(users);
    }
  }

  @Input() set teams(teams: CustomTeamModel[]) {
    this._teams = teams;
    if (this.teamUIList === null) {
      this.resetTeamList(null);
    }
  }
  get teams() {
    return this._teams;
  }

  @Input() set members(members: string[]) {
    if (members) {
      this.selectedMembers = members;
    }
  }

  @Output() onSelectAction: EventEmitter<string>;
  @Output() onSelectMembersAction: EventEmitter<object>;

  private teamManagerModal: NgbModalRef;

  allUsers: CustomMemberModel[] = null;
  selectedMembers: string[];
  selectedTeam: CustomTeamModel;
  teamMembers: CustomMemberModel[];
  loadUsers: boolean;
  teamUIList: CustomTeamModel[];
  searchField: string;

  constructor(
    private modalService: NgbModal,
    private translateService: TranslateService,
    private userDataService: UserDataService,
    private notificationService: NotificationService,
    private memberDataService: CustomMemberDataService,
    private teamDataService: CustomTeamDataService
  ) {
    this.onSelectAction = new EventEmitter<string>();

    this.onSelectMembersAction = new EventEmitter<{
      members: string[];
      action: string;
    }>();

    this.teamManagerModal = null;
    this.selectedMembers = [];
    this.selectedTeam = null; // new CustomTeamModel();
    this.teamMembers = [];
    this.loadUsers = false;
    this.teamUIList = null;
  }

  ngOnInit() {
    if (this.allUsers === null) {
      // Get users
      this.loadUsers = true;
      this.userDataService
        .getByOrganisation(false)
        .subscribe((usersResponse: ResultWrapper<any>) => {
          this.allUsers = [];
          if (usersResponse.hasResult) {
            this.convertUsersToMemberModel(usersResponse.result);
          } else {
            this.notificationService.showError(
              this.translateService.instant('user.loadUsersFailed')
            );
          }

          this.loadUsers = false;
          this.loadAllMembers();
        });
    } else {
      this.loadAllMembers();
    }

    if (this._teams === null) {
      // get teams
      this.teamDataService.get().subscribe((teamGetResponse) => {
        if (teamGetResponse.hasResult) {
          this._teams = teamGetResponse.result;
          this.resetTeamList(null);
        }
      });
    }
  }

  convertUsersToMemberModel(users) {
    _.each(users, (user: UserModel) => {
      if (user.enabled) {
        this.allUsers.push(
          this.createNewMember(user.id, user.givenName, user.surname)
        );
      }
    });
  }

  loadAllMembers() {
    this.loadUsers = true;
    this.memberDataService.get().subscribe((memebersResponse) => {
      if (memebersResponse.hasResult) {
        _.each(memebersResponse.result, (member: CustomMemberModel) => {
          const index = _.findIndex(
            this.allUsers,
            (user) => user.$usermanager_user_id === member.$usermanager_user_id
          );
          if (index > -1) {
            this.allUsers[index] = member;
          } else {
            this.allUsers.push(member);
          }
        });
      }

      this.loadUsers = false;
      if (this.selectedTeam) {
        this.selectTeamMembers(this.selectedTeam.__Id);
      } else {
        this.selectTeamMembers(null);
      }
    });
  }

  // Select team members
  selectTeamMembers(selectedTeamId: CustomTeamModel['__Id']) {
    if (this.allUsers === null) {
      return;
    }

    let currentMembers;
    if (selectedTeamId) {
      currentMembers = _.filter(
        this.allUsers,
        (user) =>
          _.contains(user.$customdata_membergroup_ids, selectedTeamId) &&
          user.isActive
      );
    } else {
      currentMembers = this.allUsers;
    }

    this.teamMembers = _.sortBy(currentMembers, '$usermanager_user_givenName');
  }

  // Select member
  selectMember(
    selectedMemberUserId: CustomMemberModel['$usermanager_user_id']
  ) {
    if (this.selectedMembers.indexOf(selectedMemberUserId) > -1) {
      this.selectedMembers.splice(
        this.selectedMembers.indexOf(selectedMemberUserId),
        1
      );
    } else {
      this.selectedMembers.push(selectedMemberUserId);
    }
  }

  isChecked(selectedMemberUserId: CustomMemberModel['$usermanager_user_id']) {
    return this.selectedMembers.indexOf(selectedMemberUserId) > -1;
  }

  // Select all members
  selectAllMembers() {
    for (let i = 0; i < this.teamMembers.length; i++) {
      if (
        this.selectedMembers.indexOf(
          this.teamMembers[i].$usermanager_user_id
        ) <= -1
      ) {
        this.selectMember(this.teamMembers[i].$usermanager_user_id);
        this.isChecked(this.teamMembers[i].$usermanager_user_id);
      }
    }
  }

  // Open member manager
  openMemberManager(
    selectedMemberUserId: CustomMemberModel['$usermanager_user_id']
  ) {
    const user = _.find(
      this.allUsers,
      (item) => item.$usermanager_user_id === selectedMemberUserId
    );
    if (user) {
      const member = _.clone(user);
      const ngbModalOptions: NgbModalOptions = {
        backdrop: 'static',
        size: 'sm',
      };
      const memberManagerModal: NgbModalRef = this.modalService.open(
        MemberManagerModalComponent,
        ngbModalOptions
      );
      memberManagerModal.componentInstance.member = member;
      memberManagerModal.componentInstance.teams = _.map(this.teams, (t) =>
        _.clone(t)
      );

      // Output
      memberManagerModal.componentInstance.onSelectMemberAction.subscribe(
        (onSelectMemberAction: {
          member: CustomMemberModel;
          action: string;
        }) => {
          switch (onSelectMemberAction.action) {
            case 'save':
              if (onSelectMemberAction.member.__Id) {
                if (
                  onSelectMemberAction.member.$customdata_membergroup_ids
                    .length > 0 ||
                  onSelectMemberAction.member.isActive ||
                  onSelectMemberAction.member.remarks
                ) {
                  this.memberDataService
                    .update(onSelectMemberAction.member)
                    .subscribe((memberUpdateResponse: ResultWrapper<any>) => {
                      if (
                        memberUpdateResponse.hasResult &&
                        memberUpdateResponse.result
                      ) {
                        const index = _.findIndex(
                          this.allUsers,
                          (item) =>
                            item.$usermanager_user_id ===
                            memberUpdateResponse.result.$usermanager_user_id
                        );
                        if (index > -1) {
                          this.allUsers[index] = memberUpdateResponse.result;
                        }

                        this.notificationService.showSuccess(
                          this.translateService.instant(
                            'customUI.memberManager.updated'
                          ),
                          this.translateService.instant('shared.terms.success')
                        );

                        if (this.selectedTeam) {
                          this.selectTeamMembers(this.selectedTeam.__Id);
                        } else {
                          this.selectTeamMembers(null);
                        }

                        memberManagerModal.dismiss();
                      } else {
                        this.notificationService.showError(
                          this.translateService.instant('shared.terms.failed')
                        );
                      }
                    });
                } else {
                  this.memberDataService
                    .delete(onSelectMemberAction.member.__Id)
                    .subscribe((memberDeleteResponse: ResultWrapper<any>) => {
                      if (memberDeleteResponse.hasResult) {
                        const index = _.findIndex(
                          this.allUsers,
                          (item) =>
                            item.$usermanager_user_id ===
                            memberDeleteResponse.result.$usermanager_user_id
                        );
                        if (index > -1) {
                          this.allUsers[index] = this.createNewMember(
                            onSelectMemberAction.member.$usermanager_user_id,
                            onSelectMemberAction.member
                              .$usermanager_user_givenName,
                            onSelectMemberAction.member
                              .$usermanager_user_surname
                          );
                        }

                        this.notificationService.showSuccess(
                          this.translateService.instant(
                            'customUI.memberManager.updated'
                          ),
                          this.translateService.instant('shared.terms.success')
                        );

                        if (this.selectedTeam) {
                          this.selectTeamMembers(this.selectedTeam.__Id);
                        } else {
                          this.selectTeamMembers(null);
                        }

                        memberManagerModal.dismiss();
                      } else {
                        this.notificationService.showError(
                          this.translateService.instant('shared.terms.failed')
                        );
                      }
                    });
                }
              } else {
                if (
                  onSelectMemberAction.member.$customdata_membergroup_ids
                    .length > 0 ||
                  onSelectMemberAction.member.isActive ||
                  onSelectMemberAction.member.remarks
                ) {
                  this.memberDataService
                    .add(onSelectMemberAction.member)
                    .subscribe((memberAddResponse: ResultWrapper<any>) => {
                      if (
                        memberAddResponse.hasResult &&
                        memberAddResponse.result
                      ) {
                        const index = _.findIndex(
                          this.allUsers,
                          (item) =>
                            item.$usermanager_user_id ===
                            memberAddResponse.result.$usermanager_user_id
                        );
                        if (index > -1) {
                          this.allUsers[index] = memberAddResponse.result;
                        } else {
                          this.allUsers.push(memberAddResponse.result);
                        }

                        this.notificationService.showSuccess(
                          this.translateService.instant(
                            'customUI.memberManager.updated'
                          ),
                          this.translateService.instant('shared.terms.success')
                        );

                        if (this.selectedTeam) {
                          this.selectTeamMembers(this.selectedTeam.__Id);
                        } else {
                          this.selectTeamMembers(null);
                        }

                        memberManagerModal.dismiss();
                      } else {
                        this.notificationService.showError(
                          this.translateService.instant('shared.terms.failed')
                        );
                      }
                    });
                } else {
                  memberManagerModal.dismiss();
                }
              }

              break;

            case 'dismiss':
              memberManagerModal.dismiss();

              break;
          }
        }
      );
    }
  }

  // Open team manager
  openTeamManager() {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      size: 'sm',
    };
    this.teamManagerModal = this.modalService.open(
      TeamManagerModalComponent,
      ngbModalOptions
    );

    // Input
    this.teamManagerModal.componentInstance.teams = _.map(this.teams, (t) =>
      _.clone(t)
    );
    if (this.selectedTeam) {
      this.teamManagerModal.componentInstance.team = _.clone(this.selectedTeam);
    }

    // Output
    this.teamManagerModal.componentInstance.onSelectTeamAction.subscribe(
      (onSelectTeamAction: {
        members?: string[];
        team: CustomTeamModel;
        action: string;
      }) => {
        switch (onSelectTeamAction.action) {
          case 'save':
            if (onSelectTeamAction.team.__Id) {
              this.teamManagerModal.close();
              this.teamManagerModal = null;
              // Push the update of the current team
              this.teamDataService.update(onSelectTeamAction.team).subscribe(
                (teamUpdateResponse: ResultWrapper<any>) => {
                  if (
                    teamUpdateResponse.hasResult &&
                    teamUpdateResponse.result
                  ) {
                    const index = _.findIndex(
                      this.teams,
                      (t) => t.__Id === teamUpdateResponse.result.__Id
                    );
                    if (index > -1) {
                      this.teams[index] = teamUpdateResponse.result;

                      this.changeMembers(
                        onSelectTeamAction.team.__Id,
                        onSelectTeamAction.members
                      );
                    }
                    this.handleAfterAddedOrUpdatedTeam(
                      teamUpdateResponse.result,
                      false
                    );
                  } else {
                    this.requestFailed();
                  }
                },
                (error) => {
                  this.requestFailed();
                }
              );
            } else {
              // Push the new of the current team
              this.teamDataService.add(onSelectTeamAction.team).subscribe(
                (teamAddResponse: ResultWrapper<any>) => {
                  if (teamAddResponse.hasResult) {
                    this.teams.push(teamAddResponse.result);
                    this.teamManagerModal.componentInstance.teams = _.clone(
                      this.teams
                    );
                    this.teamManagerModal.componentInstance.team = null;

                    this.changeMembers(
                      teamAddResponse.result.__Id,
                      onSelectTeamAction.members
                    );
                    this.handleAfterAddedOrUpdatedTeam(
                      teamAddResponse.result,
                      true
                    );
                  } else {
                    this.requestFailed();
                  }
                },
                (error) => {
                  this.requestFailed();
                }
              );
            }
            break;

          case 'delete':
            // need show confirm popup?
            this.teamManagerModal.close();
            this.teamManagerModal = null;
            this.teamDataService
              .delete(onSelectTeamAction.team.__Id)
              .subscribe((teamDeleteResponse: ResultWrapper<any>) => {
                if (teamDeleteResponse.hasResult) {
                  this.memberDataService
                    .getByMembergroupId([onSelectTeamAction.team.__Id])
                    .subscribe(
                      (
                        memberGetByMembergroupIdResponse: ResultWrapper<any>
                      ) => {
                        if (
                          memberGetByMembergroupIdResponse.hasResult &&
                          memberGetByMembergroupIdResponse.result.length > 0
                        ) {
                          const memberRequests = [];
                          const deleteMemberIds = [];
                          for (
                            let i = 0;
                            i < memberGetByMembergroupIdResponse.result.length;
                            i++
                          ) {
                            memberGetByMembergroupIdResponse.result[
                              i
                            ].$customdata_membergroup_ids.splice(
                              memberGetByMembergroupIdResponse.result[
                                i
                              ].$customdata_membergroup_ids.indexOf(
                                onSelectTeamAction.team.__Id
                              ),
                              1
                            );

                            if (
                              memberGetByMembergroupIdResponse.result[i]
                                .$customdata_membergroup_ids.length > 0 ||
                              memberGetByMembergroupIdResponse.result[i]
                                .isActive ||
                              memberGetByMembergroupIdResponse.result[i].remarks
                            ) {
                              memberRequests.push(
                                this.memberDataService.update(
                                  memberGetByMembergroupIdResponse.result[i]
                                )
                              );
                            } else {
                              memberRequests.push(
                                this.memberDataService.delete(
                                  memberGetByMembergroupIdResponse.result[i]
                                    .__Id
                                )
                              );
                              deleteMemberIds.push(
                                memberGetByMembergroupIdResponse.result[i].__Id
                              );
                            }
                          }

                          forkJoin(memberRequests).subscribe(
                            (membersUpdateResponse: any) => {
                              let hasError = false;
                              _.each(
                                membersUpdateResponse,
                                (
                                  response: ResultWrapper<CustomMemberModel>
                                ) => {
                                  if (response.hasResult && response.result) {
                                    const userIndex = _.findIndex(
                                      this.allUsers,
                                      (user) =>
                                        user.$usermanager_user_id ===
                                        response.result.$usermanager_user_id
                                    );
                                    if (userIndex > -1) {
                                      if (
                                        _.contains(
                                          deleteMemberIds,
                                          (id) => id === response.result.__Id
                                        )
                                      ) {
                                        const deletedMember =
                                          this.allUsers[userIndex];
                                        this.allUsers[userIndex] =
                                          this.createNewMember(
                                            deletedMember.$usermanager_user_id,
                                            deletedMember.$usermanager_user_givenName,
                                            deletedMember.$usermanager_user_surname
                                          );
                                      } else {
                                        this.allUsers[userIndex] =
                                          response.result;
                                      }
                                    } else {
                                      this.allUsers.push(
                                        this.createNewMember(
                                          response.result.$usermanager_user_id,
                                          response.result
                                            .$usermanager_user_givenName,
                                          response.result
                                            .$usermanager_user_surname
                                        )
                                      );
                                    }
                                  } else {
                                    hasError = true;
                                  }
                                }
                              );

                              if (hasError) {
                                this.requestFailed();
                              }
                            }
                          );
                        }
                      }
                    );

                  const index = _.findIndex(
                    this.teams,
                    (t) => t.__Id === onSelectTeamAction.team.__Id
                  );
                  if (index > -1) {
                    this.teams.splice(index, 1);
                  }

                  if (
                    this.selectedTeam &&
                    this.selectedTeam.__Id === onSelectTeamAction.team.__Id
                  ) {
                    this.resetTeamList(null);
                  } else {
                    this.resetTeamList(this.selectedTeam.__Id);
                  }

                  this.notificationService.showSuccess(
                    this.translateService.instant(
                      'customUI.teamManager.deleted'
                    ),
                    this.translateService.instant('shared.terms.success')
                  );
                } else {
                  this.requestFailed();
                }
              });

            break;

          case 'dismiss':
            this.teamManagerModal.dismiss();
            this.teamManagerModal = null;

            break;
        }
      }
    );
  }

  handleAfterAddedOrUpdatedTeam(
    currentTeam: CustomTeamModel,
    isTeamNew: boolean
  ) {
    const allRequests = [];
    if (currentTeam.active) {
      // If this team is now the active team, let's deactivate all others
      _.each(
        _.filter(
          this.teams,
          (team) => team.active && currentTeam.__Id !== team.__Id
        ),
        (filteredTeam) => {
          filteredTeam.active = false;
          allRequests.push(this.teamDataService.update(filteredTeam));
        }
      );
    }

    const message =
      isTeamNew === true
        ? 'customUI.teamManager.added'
        : 'customUI.teamManager.updated';
    if (allRequests.length > 0) {
      forkJoin(allRequests).subscribe((teamUpdateResponse: any) => {
        // sort team list
        this.resetTeamList(isTeamNew === true ? currentTeam.__Id : null);
        this.notificationService.showSuccess(
          this.translateService.instant(message),
          this.translateService.instant('shared.terms.success')
        );
      });
    } else {
      // sort team list
      this.resetTeamList(isTeamNew === true ? currentTeam.__Id : null);
      this.notificationService.showSuccess(
        this.translateService.instant(message),
        this.translateService.instant('shared.terms.success')
      );
    }
  }

  requestFailed() {
    this.notificationService.showError(
      this.translateService.instant('shared.terms.failed')
    );
  }

  resetTeamList(selectedTeamId) {
    if (this.teams && this.teams.length > 0) {
      this.teamUIList = _.sortBy(
        [new CustomTeamModel()].concat(this.teams),
        (t) => (t.name ? t.name.toLowerCase() : '')
      );
    } else {
      this.teamUIList = [new CustomTeamModel()];
    }

    if (_.any(this.teamUIList, (team) => team.active)) {
      this.teamUIList = _.sortBy(
        this.teamUIList,
        (team) => team.active !== true
      );
      if (!selectedTeamId) {
        this.selectedTeam = this.teamUIList[0];
      } else {
        this.selectedTeam = _.find(
          this.teamUIList,
          (t) => t.__Id === selectedTeamId
        );
      }

      if (this.selectedTeam) {
        this.selectTeamMembers(this.selectedTeam.__Id);
      }

      // add divider between active and inactive
      const divider = new CustomTeamModel();
      divider.disabled = true;
      const index = _.findIndex(this.teamUIList, (team) => !team.name);
      this.teamUIList.splice(index, 0, divider);
    } else {
      if (selectedTeamId) {
        this.selectedTeam = _.find(
          this.teamUIList,
          (t) => t.__Id === selectedTeamId
        );
      } else {
        this.selectedTeam = this.teamUIList[0];
      }

      if (this.selectedTeam) {
        this.selectTeamMembers(this.selectedTeam.__Id);
      }
    }
  }

  createNewMember(userId, givenName, surName) {
    const member = new CustomMemberModel();
    member.$usermanager_user_id = userId;
    member.$usermanager_user_givenName = givenName;
    member.$usermanager_user_surname = surName;

    return member;
  }

  changeMembers(teamId, members) {
    this.memberDataService
      .getByMembergroupId([teamId])
      .subscribe((memberResponse: ResultWrapper<CustomMemberModel[]>) => {
        if (memberResponse.hasResult) {
          const existingMembers: string[] = [];
          const clickedMembers: string[] = [];
          let addMembers: string[] = [];
          let removeMembers: string[] = [];

          memberResponse.result.forEach((existingMember) => {
            existingMembers.push(existingMember.$usermanager_user_id);
          });

          members.forEach((clickedMember) => {
            clickedMembers.push(clickedMember);
          });

          if (existingMembers.length > 0) {
            clickedMembers.forEach((clickedMember) => {
              if (existingMembers.indexOf(clickedMember) <= -1) {
                addMembers.push(clickedMember);
              }
            });
          } else {
            addMembers = clickedMembers;
          }

          if (clickedMembers.length > 0) {
            existingMembers.forEach((existingMember) => {
              if (clickedMembers.indexOf(existingMember) <= -1) {
                removeMembers.push(existingMember);
              }
            });
          } else {
            removeMembers = existingMembers;
          }

          if (addMembers.length > 0) {
            addMembers.forEach((addMember) => {
              this.memberDataService
                .getByUserId(addMember)
                .subscribe(
                  (getByAddResponse: ResultWrapper<CustomMemberModel[]>) => {
                    if (getByAddResponse.result.length > 0) {
                      const updateAddMember = getByAddResponse.result[0];
                      updateAddMember.isActive = true;

                      if (updateAddMember.$customdata_membergroup_ids) {
                        updateAddMember.$customdata_membergroup_ids.push(
                          teamId
                        );
                      } else {
                        updateAddMember.$customdata_membergroup_ids = [teamId];
                      }

                      this.memberDataService
                        .update(updateAddMember)
                        .subscribe(() => {
                          this.loadAllMembers();
                          if (this.teamManagerModal) {
                            this.teamManagerModal.componentInstance.users =
                              this.allUsers;
                          }
                        });
                    } else {
                      this.userDataService
                        .get(addMember)
                        .subscribe((getResponse: ResultWrapper<UserModel>) => {
                          if (getResponse.hasResult) {
                            const newMember = this.createNewMember(
                              getResponse.result.id,
                              getResponse.result.givenName,
                              getResponse.result.surname
                            );
                            newMember.isActive = true;
                            newMember.$customdata_membergroup_ids = [teamId];

                            this.memberDataService
                              .add(newMember)
                              .subscribe(() => {
                                this.loadAllMembers();
                                if (this.teamManagerModal) {
                                  this.teamManagerModal.componentInstance.users =
                                    this.allUsers;
                                }
                              });
                          }
                        });
                    }
                  }
                );
            });
          }

          if (removeMembers.length > 0) {
            removeMembers.forEach((removeMember) => {
              this.memberDataService
                .getByUserId(removeMember)
                .subscribe(
                  (getByRmResponse: ResultWrapper<CustomMemberModel[]>) => {
                    if (
                      getByRmResponse.result[0].$customdata_membergroup_ids !==
                      teamId
                    ) {
                      const updateRmMember = getByRmResponse.result[0];
                      const updateRmGroup: string[] = [];

                      updateRmMember.$customdata_membergroup_ids.forEach(
                        (updateRmMemberGroup) => {
                          if (
                            updateRmGroup.indexOf(updateRmMemberGroup) <= -1 &&
                            updateRmMemberGroup !== teamId
                          ) {
                            updateRmGroup.push(updateRmMemberGroup);
                          }
                        }
                      );

                      updateRmMember.$customdata_membergroup_ids =
                        updateRmGroup;

                      this.memberDataService
                        .update(updateRmMember)
                        .subscribe(() => {
                          this.loadAllMembers();
                        });
                    } else {
                      this.memberDataService
                        .delete(removeMember)
                        .subscribe(() => {
                          this.loadAllMembers();
                        });
                    }
                  }
                );
            });
          }
        }
      });
  }
}
