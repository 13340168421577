import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CompanyServicesModule } from '../../../company/services/company-services.module';
import { LayoutComponentsModule } from '../../../layout/components/layout-components.module';
import { MapServicesModule } from '../../../map/services/map-services.module';
import { NauticalVisitServicesModule } from '../../../nautical-visit/services/nautical-visit-services.module';

import { CustomKPComponentsModule } from '../components/kp-components.module';
import { KPActivitiesPage } from './kp-activities/kp-activities.page';

@NgModule({
  declarations: [KPActivitiesPage],
  exports: [],
  imports: [
    CommonModule,
    CompanyServicesModule,
    CustomKPComponentsModule,
    FormsModule,
    LayoutComponentsModule,
    MapServicesModule,
    NauticalVisitServicesModule,
    RouterModule,
    TranslateModule,
  ],
})
export class CustomKPPagesModule {}
