import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  private sidebarToggleSource = new BehaviorSubject<boolean | null>(null);
  sidebarToggle$ = this.sidebarToggleSource.asObservable();

  toggleSidebar(sidebarOpen: boolean) {
    this.sidebarToggleSource.next(sidebarOpen);
  }
}
