<div class="card">
  <div class="card-header">
    {{ title ? title : ('nautical.ship.title' | translate) }}
    <div class="btn-list" *ngIf="nauticalShip">
      <button
        (click)="unlinkNauticalShip()"
        class="btn btn-outline-secondary btn-sm"
        type="button"
      >
        <i class="fa-regular fa-unlink fa-fw"></i>
        {{ 'shared.terms.unlink' | translate }}
      </button>
      <button
        (click)="openNauticalShip()"
        class="btn btn-outline-secondary btn-sm"
        type="button"
      >
        <i class="fa-regular fa-external-link-alt fa-fw"></i>
        {{ 'shared.terms.details' | translate }}
      </button>
    </div>
  </div>

  <div class="card-body">
    <ng-container
      *ngIf="isLoading; then showLoading; else showNauticalShip"
    ></ng-container>
    <ng-template #showLoading>
      <sh-loading-indicator
        class="text-center d-block"
      ></sh-loading-indicator>
    </ng-template>
    <ng-template #showNauticalShip>
      <div class="card-body-group card-body-group-no-border">
        <div class="row no-gutters attribute-group py-0">
          <div
            (click)="openNauticalShipPicker()"
            class="col-12 attribute-group-item attribute-group-item-editable py-0"
          >
            <div class="row no-gutters attribute-group w-100 py-0">
              <ng-container
                *ngIf="
                  nauticalShip;
                  then nauticalShipShow;
                  else nauticalShipHide
                "
              ></ng-container>
              <ng-template #nauticalShipShow>
                <div class="col-12 attribute-group-item">
                  <dt class="mb-0">
                    {{ 'nautical.ship.model.name' | translate }}
                  </dt>
                  <dd>
                    <ng-container *ngIf="nauticalShip.name">{{
                      nauticalShip.name | uppercase
                    }}</ng-container>
                    <i
                      class="fa-regular fa-pencil-alt fa-fw btn-editable text-muted float-right"
                    ></i>
                  </dd>
                </div>
                <div class="col-12 attribute-group-item">
                  <dt class="mb-0">
                    {{ 'nautical.ship.model.imo' | translate }}
                  </dt>
                  <dd>{{ nauticalShip.imo }}</dd>
                </div>
              </ng-template>
              <ng-template #nauticalShipHide>
                <div class="col-12 attribute-group-item">
                  <dt
                    class="mb-0"
                    title="'nautical.ship.noneSelected' | translate"
                  >
                    {{ 'nautical.ship.noneSelected' | translate }}
                  </dt>
                  <dd>
                    <i
                      class="fa-regular fa-pencil-alt fa-fw btn-editable text-muted float-right"
                    ></i>
                  </dd>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
