import { NgModule } from '@angular/core';

import { NauticalMovementTypePipe } from './nautical-movement-type.pipe';
import { NauticalVisitEventTypePipe } from './nautical-visit-event-type.pipe';
import { NauticalVisitStatusPipe } from './nautical-visit-status.pipe';
import { WaypointTypePipe } from './waypoint-type.pipe';

@NgModule({
  declarations: [
    NauticalMovementTypePipe,
    NauticalVisitEventTypePipe,
    NauticalVisitStatusPipe,
    WaypointTypePipe,
  ],
  exports: [
    NauticalMovementTypePipe,
    NauticalVisitEventTypePipe,
    NauticalVisitStatusPipe,
    WaypointTypePipe,
  ],
})
export class NauticalVisitPipesModule {}
