import {
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { IdentityServiceBase } from '../services/identity.service';

export const authRequestInterceptor: HttpInterceptorFn = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn
) => {
  const identityService = inject(IdentityServiceBase);

  const authRequest = request.clone({
    headers: request.headers.set(
      'Authorization',
      `Bearer ${identityService.token}`
    ),
  });

  return next(authRequest);
};
