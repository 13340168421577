import { inject } from '@angular/core';
import { CanActivateFn, ActivatedRouteSnapshot, Router } from '@angular/router';
import { IdentityServiceBase } from '../services/identity.service';
import { AUTH_ROUTES } from '../tokens/auth-routes.token';

export const permissionsGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot
): boolean => {
  const identityService = inject(IdentityServiceBase);
  const router = inject(Router);
  const routes = inject(AUTH_ROUTES);

  const permissions = route.data['allowedPermissions'];
  const url = route.data['redirect'];

  if (permissions && !identityService.hasPermission(permissions)) {
    if (!url) {
      router.navigate([routes.unauthorized]);
    } else {
      router.navigate([url]);
    }
    return false;
  } else {
    return true;
  }
};
