import { Input, Output, Component, EventEmitter } from '@angular/core';
import { OrderRegistrationModel } from '@seahorse/domain';
import * as _ from 'underscore';

@Component({
  selector: 'ca-order-registration-picker-dialog-form',
  templateUrl: './order-registration-picker-dialog-form.component.html',
})
export class OrderRegistrationPickerDialogFormComponent {
  @Input() selectedOrderRegistration: OrderRegistrationModel;
  @Input() orderRegistrations: OrderRegistrationModel[];
  @Input() isRequired: boolean;
  @Input() loadingOrderRegistrations: boolean;

  @Output()
  selectedOrderRegistrationChange!: EventEmitter<OrderRegistrationModel>;

  constructor() {
    this.selectedOrderRegistration = null;
    this.orderRegistrations = [];
    this.isRequired = false;
    this.loadingOrderRegistrations = false;
    this.selectedOrderRegistrationChange =
      new EventEmitter<OrderRegistrationModel>();
  }
}
