import { Injectable } from '@angular/core';
import { ResultWrapper } from '@seahorse/common';
import { DataContextService } from '@seahorse/common';
import { DataService } from '../../shared/services/data.service';
import { HarbourModel } from '../models/harbour.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HarbourDataService implements DataService<HarbourModel> {
  constructor(private dataContext: DataContextService) {}

  search(
    search?: string,
    pageIndex?: number,
    pageSize?: number,
    annotate = false
  ): Observable<ResultWrapper<HarbourModel[]>> {
    const queryParams = [`annotate=${annotate}`];

    if (search !== undefined && search !== null) {
      queryParams.push(`search=${encodeURIComponent(search)}`);
    }

    if (pageIndex !== undefined && pageIndex !== null) {
      queryParams.push(`pindex=${pageIndex}`);
    }

    if (pageSize !== undefined && pageSize !== null) {
      queryParams.push(`psize=${pageSize}`);
    }

    const queryString = queryParams.join('&');
    const url = `su/nautical/harbours/search${
      queryString ? '?' + queryString : ''
    }`;

    return this.dataContext.get<HarbourModel[]>(url);
  }

  searchByPortId(
    portId: number,
    search: string,
    pageIndex: number,
    pageSize: number,
    annotate = false
  ): Observable<ResultWrapper<HarbourModel[]>> {
    return this.dataContext.get<HarbourModel[]>(
      `su/nautical/harbours/${portId}/search/?search=${search}&pindex=${pageIndex}&psize=${pageSize}&annotate=${annotate}`
    );
  }

  getById(
    harbourId: number,
    annotate = false
  ): Observable<ResultWrapper<HarbourModel>> {
    return this.dataContext.get<HarbourModel>(
      `su/nautical/harbours/${harbourId}?annotate=${annotate}`
    );
  }

  add(harbour: HarbourModel): Observable<ResultWrapper<HarbourModel>> {
    return this.dataContext.post<HarbourModel>(`su/nautical/harbours`, harbour);
  }

  update(harbour: HarbourModel): Observable<ResultWrapper<HarbourModel>> {
    return this.dataContext.put<HarbourModel>(
      `su/nautical/harbours/${harbour.id}`,
      harbour
    );
  }

  delete(harbourId: number): Observable<ResultWrapper<HarbourModel>> {
    return this.dataContext.delete<HarbourModel>(
      `su/nautical/harbours/${harbourId}`
    );
  }

  getAll(): Observable<ResultWrapper<HarbourModel[]>> {
    return this.dataContext.get<HarbourModel[]>(`su/nautical/harbours`);
  }
}
