<div class="modal-header">
  <h4 class="modal-title">
    {{
      (isNew
        ? 'companies.department.addDepartment'
        : 'companies.department.editDepartment'
      ) | translate
    }}
  </h4>
  <button closeModal type="button" class="btn btn-link text-dark">
    <i class="fa-solid fa-times fa-fw fa-sm"></i>
  </button>
</div>

<div class="modal-body">
  <ca-department-form
    [model]="department"
    [companyId]="companyId"
    [submitted]="isSubmitted"
  ></ca-department-form>
</div>

<div class="modal-footer d-flex">
  <div class="flex-grow-1">
    <button closeModal type="button" class="btn btn-block btn-link">
      {{ 'shared.terms.cancel' | translate }}
    </button>
  </div>
  <div class="flex-grow-1">
    <button
      (click)="onSelectDepartment.emit(this.department)"
      type="button"
      class="btn btn-block btn-success"
    >
      {{ 'shared.terms.save' | translate }}
    </button>
  </div>
</div>
