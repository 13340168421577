import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { IdentityServiceBase } from '../services/identity.service';
import { UsersDataService } from '@seahorse/domain';
import { AUTH_ROUTES } from '../tokens/auth-routes.token';

export const authGuard: CanActivateFn = (): Observable<boolean> => {
  const identityService = inject(IdentityServiceBase);
  const userService = inject(UsersDataService);
  const router = inject(Router);
  const routes = inject(AUTH_ROUTES);

  if (!identityService.isAuthenticated) {
    router.navigate([routes.login]);
    return of(false);
  }

  if (identityService.userPermissions.length > 0) {
    return of(true);
  } else {
    return userService.setUserIdentity().pipe(mergeMap(() => of(true)));
  }
};
