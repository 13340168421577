<sh-card>
  <ng-container header>
    <span class="text-uppercase"
      >{{ title | translate }}: {{ ship?.name }}</span
    >
    <div class="btn-list">
      <a
        *ngIf="aisMarker"
        [routerLink]="[
          '/map?lat=' +
            aisMarker.latitude +
            '&lng=' +
            aisMarker.longitude +
            '&mmsi=' +
            aisMarker.mmsi
        ]"
        (click)="
          navigate(
            '/map?lat=' +
              aisMarker.latitude +
              '&lng=' +
              aisMarker.longitude +
              '&mmsi=' +
              aisMarker.mmsi
          )
        "
        class="btn btn-outline-secondary btn-sm"
      >
        <i class="fa-regular fa-map-marked-alt fa-fw"></i>
        {{ 'nautical.ship.findOnMap' | translate }}
      </a>
      <button
        (click)="openDetails()"
        class="btn btn-outline-secondary btn-sm"
        type="button"
      >
        <i class="fa-regular fa-ship fa-fw"></i>
        {{ 'shared.terms.details' | translate }}
      </button>
      <ng-content></ng-content>
    </div>
  </ng-container>
  <sh-map-direction-indicator
    *ngIf="aisMarker"
    class="float-right btn"
    [position]="aisMarker"
  ></sh-map-direction-indicator>
  <temp-attribute-list
    *shEmptyPlaceholder="isLoading"
    [attributes]="attributes"
    columns="2"
  >
  </temp-attribute-list>
</sh-card>
