import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ca-field-operator-form',
  templateUrl: 'field-operator-form.component.html',
})
export class FieldOperatorFormComponent {
  private _fieldType: number = null;

  @Input() operator: number = null;
  @Output() operatorChange = new EventEmitter<number>();

  @Input() set fieldType(val: number) {
    this._fieldType = val;
    this.setFieldValue();
    this.setOperators();
  }
  get fieldType(): number {
    return this._fieldType;
  }
  @Input() set fieldValue(val: any) {
    this.setFieldValue(val);
  }
  get fieldValue(): any {
    return this.getFieldValue();
  }
  @Output() fieldValueChange = new EventEmitter<any>();

  operatorTypes: any[] = [];
  value1: string = null;
  value2: string = null;

  changeFieldValue() {
    this.fieldValueChange.emit(this.getFieldValue());
  }

  changeOperator() {
    this.operatorChange.emit(this.operator);
  }

  private getFieldValue() {
    // == in stead of === is on purpose
    if (this.operator == 7) {
      return (
        (this.value1 ? this.value1 : '') +
        ',' +
        (this.value2 ? this.value2 : '')
      );
    }

    return this.value1 ? this.value1 : '';
  }

  private setFieldValue(input: string = null) {
    if (input === null && this.value1 === null) {
      return;
    }

    // == in stead of === is on purpose
    if (this.operator == 7) {
      let values = [];
      if (input !== null) {
        values = input.split(',');
      } else {
        values = this.value1.split(',');
      }
      this.value1 = values[0];
      this.value2 = values.length > 1 ? values[1] : null;
    } else if (input !== null) {
      this.value1 = input;
    }
  }

  private setOperators() {
    /*
        Equals = 0,
        Contains = 1,
        StartsWith = 2,
        EndsWith = 3,
        GreaterThan = 4,
        LesserThan = 5,
        InArray = 6,
        Between = 7,
        NotEquals = 8
        */
    switch (this.fieldType) {
      case 0: // SingleLineText
      case 1: // MultiLineText
      case 6: // File
      case 10: // AutoNumbering
        this.operatorTypes = [0, 1, 2, 3, 6, 8]; // string
        break;
      case 2: // DateTime
      case 11: // Date
        this.operatorTypes = [0, 4, 5, 7, 8]; // date
        break;
      case 3: // Integer
      case 7: // Decimal
        this.operatorTypes = [0, 4, 5, 7, 8]; // number
        break;
      case 4: // Boolean
        this.operatorTypes = [0, 8]; // boolean
        break;
      case 5: // List
        this.operatorTypes = [0, 6, 8]; // array
        break;
      case 8:
      case 9:
      default:
        this.operatorTypes = [];
        break;
    }
  }
}
