import { BaseModel } from '@seahorse/common';
import { ValidationResult } from '@seahorse/common';
import { SystemMessageReleaseModel } from './system-message-release.model';
import { SystemMessageTranslationModel } from './system-message-translation.model';

export class SystemMessageModel extends BaseModel {
  id: number;
  version: string;
  subject: string;
  notes: string;
  createdOn: Date;
  systemMessageType: SystemMessageType;
  systemMessageReleases: SystemMessageReleaseModel[];
  systemMessageTranslations: SystemMessageTranslationModel[];

  static validate(model: SystemMessageModel) {
    if (
      !model.version ||
      !model.subject ||
      !model.notes ||
      !model.systemMessageType
    ) {
      return false;
    }

    model.version = model.version.trim();
    if (model.version === '') {
      model.version = null;
      return false;
    }

    model.subject = model.subject.trim();
    if (model.subject === '') {
      model.subject = null;
      return false;
    }

    model.notes = model.notes.trim();
    if (model.notes === '') {
      model.notes = null;
      return false;
    }

    return true;
  }

  validate(errorPrefix?: string): ValidationResult {
    const result: ValidationResult = new ValidationResult();

    if (!errorPrefix) {
      errorPrefix = '';
    }

    if (this.subject == null) {
      result.errors.push(errorPrefix + 'Subject is required!');
    }

    this.subject = this.subject.trim();
    if (this.subject === '') {
      this.subject = null;
      result.errors.push(errorPrefix + 'Subject is required!');
    }

    if (this.version == null) {
      result.errors.push(errorPrefix + 'Version is required!');
    }

    this.version = this.version.trim();
    if (this.version === '') {
      this.version = null;
      result.errors.push(errorPrefix + 'Version is required!');
    }

    if (this.notes == null) {
      result.errors.push(errorPrefix + 'Notes are required!');
    }

    this.notes = this.notes.trim();
    if (this.notes === '') {
      this.notes = null;
      result.errors.push(errorPrefix + 'Notes are required!');
    }

    if (this.systemMessageType == null) {
      result.errors.push(errorPrefix + 'System message type is required!');
    }

    result.isValid = result.errors.length === 0;
    return result;
  }
}

export enum SystemMessageType {
  ReleaseNote = 1,

  Maintenance = 2,

  Outage = 3,
}
