<div class="card" [class.border-0]="noBorder">
  <div class="card-header">
    {{ widget.title }}
    <div
      class="btn-list form-inline"
      *ngIf="intervalOptions && intervalOptions.length > 0"
    >
      <select
        class="form-control ml-1"
        [(ngModel)]="widget.intervalType"
        (change)="setStartPeriod(); loadTotals()"
        *ngIf="widget.intervals.length > 1"
      >
        <option *ngFor="let interval of widget.intervals" [value]="interval">
          {{ 'shared.datepicker.' + interval | translate }}
        </option>
      </select>
      <select
        class="form-control ml-1"
        [(ngModel)]="widget.intervalValue"
        (change)="intervalChanged($event)"
      >
        <option *ngFor="let item of intervalOptions" [value]="item.value">
          {{ item.name }}
        </option>
      </select>
      <button
        *ngIf="widget.reload"
        type="button"
        class="btn btn-outline-secondary"
        (click)="loadTotals()"
      >
        <i class="fa-regular fa-sync"></i>
      </button>
    </div>
  </div>
  <div class="card-body">
    <div *ngIf="widget.bodyType === invoiceWidgetBodyTypes.Details">
      <div
        class="text-xl"
        *ngIf="primaryValue && primaryValue.amountType === 'currency'"
      >
        {{ primaryValue.amount | currency: 'EUR':'symbol':'1.2-2':'nl-NL' }}
      </div>
      <div
        class="text-xl"
        *ngIf="primaryValue && primaryValue.amountType === 'decimal'"
      >
        {{ primaryValue.amount | number: '1.0-2':'nl-NL' }}
      </div>
      <div *ngIf="secondaryItems && secondaryItems.length > 0">
        <div class="attribute-group">
          <div class="attribute-group-item" *ngFor="let item of secondaryItems">
            <dt>{{ 'invoicing.totals.' + item.key | translate }}</dt>
            <dd *ngIf="item.amountType === 'currency'">
              {{ item.amount | currency: 'EUR':'symbol':'1.2-2':'nl-NL' }}
            </dd>
            <dd *ngIf="item.amountType === 'decimal'">
              {{ item.amount | number: '1.0-2':'nl-NL' }}
            </dd>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div [attr.hidden]="items && items.length > 0 ? null : ''">
        <canvas
          id="invoiceChart"
          width="200"
          height="150"
          #invoiceChart
        ></canvas>
      </div>
      <div
        *ngIf="
          (!items || items.length === 0) &&
          (widget.bodyType === invoiceWidgetBodyTypes.Chart ||
            widget.bodyType === invoiceWidgetBodyTypes.MetaData)
        "
      >
        <div class="text-muted text-center">
          {{ 'shared.terms.noResult' | translate }}
        </div>
      </div>
    </div>
  </div>
</div>
