<div class="modal-header">
  <h1 class="modal-title">
    {{ 'shared.terms.pickerTitle' | translate }}
    <span *ngIf="selectedType === 'port'">{{
      'nautical.geography.port.port' | translate
    }}</span>
    <span *ngIf="selectedType === 'waypoint'">{{
      'nautical.geography.waypoint.waypoint' | translate
    }}</span>
  </h1>
  <button type="button" class="btn btn-link text-dark" (click)="dismiss()">
    <i class="fa-solid fa-times fa-sm fa-fw"></i>
  </button>
</div>
<div class="modal-body">
  <ul class="nav nav-tabs">
    <li class="nav-item">
      <a
        class="nav-link"
        [ngClass]="{ active: selectedType === 'port' }"
        (click)="selectedType = 'port'"
        >{{ 'nautical.geography.port.port' | translate }}</a
      >
    </li>
    <li class="nav-item">
      <a
        class="nav-link"
        [ngClass]="{ active: selectedType === 'waypoint' }"
        (click)="selectedType = 'waypoint'"
        >{{ 'nautical.geography.waypoint.waypoint' | translate }}</a
      >
    </li>
  </ul>

  <div class="px-3 pt-3">
    <div class="form-group" *ngIf="filter && selectedType === 'waypoint'">
      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          id="chkFilter"
          name="chkFilter"
          [(ngModel)]="useFilter"
        />
        <label class="form-check-label" for="chkFilter">
          {{ 'shared.terms.filter' | translate }}: '{{ filter }}'
        </label>
      </div>
    </div>
    <div class="form-group">
      <label
        ><b>{{
          'nautical.geography.' + selectedType + '.' + selectedType | translate
        }}</b></label
      >
      <ca-nautical-port-picker
        *ngIf="selectedType === 'port'"
        [(nauticalPort)]="nauticalPort"
      >
      </ca-nautical-port-picker>
      <ca-nautical-waypoint-picker
        [allowTypeAhead]="false"
        [filter]="useFilter ? filter : null"
        [portId]="portId"
        *ngIf="selectedType === 'waypoint'"
        [(nauticalWaypoint)]="nauticalWaypoint"
      >
      </ca-nautical-waypoint-picker>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-link flex-fill" (click)="dismiss()">
    {{ 'shared.terms.cancel' | translate }}
  </button>
  <button (click)="confirm()" type="button" class="btn btn-success flex-fill">
    {{ 'shared.terms.confirm' | translate }}
  </button>
</div>
