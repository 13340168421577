import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  CompanyContactDataService,
  CompanyDataService,
  CustomDataAppendDataModel,
  CustomDataContentService,
  FinancialDataService,
  InvoiceDataService,
  NauticalCountryDataService,
  NauticalHarbourDataService,
  NauticalPortDataService,
  NauticalShipDataService,
  NauticalVisitDataService,
  ProxyService,
} from '@seahorse/domain';
import { FieldValuePipe } from '@seahorse/temp';
import { Observable, Subscription, of } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';

@Injectable({ providedIn: 'root' })
export class ContentMappingLogicService implements OnDestroy {
  private subscriptions$ = new Subscription();

  constructor(
    private _customDataContentService: CustomDataContentService,
    private _nauticalShipDataService: NauticalShipDataService,
    private _nauticalVisitDataService: NauticalVisitDataService,
    private _nauticalPortDataService: NauticalPortDataService,
    private _nauticalHarbourDataService: NauticalHarbourDataService,
    private _nauticalCountryDataService: NauticalCountryDataService,
    private _invoiceDataService: InvoiceDataService,
    private _companyDataService: CompanyDataService,
    private _companyContactDataService: CompanyContactDataService,
    private _financialDataService: FinancialDataService,
    private _proxyService: ProxyService,
    private _fieldValuePipe: FieldValuePipe,
    private _translateService: TranslateService
  ) {}

  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }

  getObjectDisplay(
    key: string,
    value: string
  ): Observable<{ title: string; display: string; link: string }> {
    const objectDisplay = { title: key, display: value, link: null };

    if (!key || !/^\$\S+_\S+_\S+$/.test(key)) return of(objectDisplay);

    key = key.toLowerCase();

    const objectDefinition = this._proxyService.objectDefinitions.find(
      (x) =>
        key.substring(0, key.lastIndexOf('_')) === x.mappingKey.toLowerCase()
    );
    if (objectDefinition?.name)
      objectDisplay.title = this._translateService.instant(
        objectDefinition.name
      );

    if (!value) return of(objectDisplay);

    const dataSubscription = key.startsWith('$customcontent_')
      ? this.getFromCustomData(objectDefinition.systemCode, value)
      : this.getFromSeahorseData(key, value);

    return new Observable((observer) => {
      this.subscriptions$.add(
        dataSubscription.subscribe((fromData) => {
          if (fromData?.displayName) {
            objectDisplay.display = fromData.displayName;
          } else if (fromData?.object) {
            let displayFields: string[];
            try {
              displayFields = JSON.parse(objectDefinition.templateCode).link
                ?.mapping?.displayFields;
              // eslint-disable-next-line no-empty
            } catch {}
            if (!displayFields?.length)
              displayFields =
                this._proxyService.objectKeyMapping[key]?.displayFields;

            if (displayFields?.length) {
              objectDisplay.display = '';
              displayFields.forEach((displayField) => {
                const fieldDefinitionIndex =
                  objectDefinition.objectFieldDefinitions.findIndex(
                    (fieldDefinition) =>
                      fieldDefinition.systemCode === displayField
                  );
                if (fieldDefinitionIndex !== -1) {
                  const fieldValue = this._fieldValuePipe.transform(
                    fromData.object[displayField],
                    objectDefinition.objectFieldDefinitions[
                      fieldDefinitionIndex
                    ].fieldType
                  );
                  if (fieldValue) {
                    if (objectDisplay.display !== '') {
                      objectDisplay.display += ' | ';
                    }
                    objectDisplay.display += `${objectDefinition.objectFieldDefinitions[fieldDefinitionIndex].name}: ${fieldValue}`;
                  }
                }
              });
            }
          }

          objectDisplay.link = fromData?.link;
          observer.next(objectDisplay);
          observer.complete();
        })
      );
    });
  }

  private getFromCustomData(
    systemCode: string,
    value: string
  ): Observable<{ object?: unknown; displayName?: string; link?: string }> {
    return new Observable<{
      object?: unknown;
      displayName?: string;
      link?: string;
    }>((subscription) => {
      let link = null;
      if (
        this._proxyService.uiModules?.findIndex(
          (x) => x.systemCode === systemCode
        ) !== -1
      ) {
        link = `/module/${systemCode}/details/${value}`;
      } else {
        switch (systemCode) {
          case '$customcontent_st-portcallfile':
            link = `/port-call-file/${value}`;
            break;
          case '$customcontent_st-orderregistration':
            link = `/order-registrations/${value}`;
            break;
        }
      }

      this._customDataContentService
        .getCustomerDataById(
          systemCode,
          value,
          new CustomDataAppendDataModel(true)
        )
        .subscribe((response) => {
          subscription.next({
            object: response?.result,
            displayName: response?.result?.__DisplayName,
            link: link,
          });
          subscription.complete();
        });
    });
  }

  private getFromSeahorseData(
    key: string,
    value: string
  ): Observable<{ object?: unknown; displayName?: string; link?: string }> {
    return new Observable<{
      object?: unknown;
      displayName?: string;
      link?: string;
    }>((subscription) => {
      const observers = {
        $companies_company_id: this._companyDataService
          .getById(+value, true)
          .pipe(
            map((response) =>
              response?.result
                ? {
                    object: response.result,
                    displayName: response.result['__DisplayName'],
                    link: `/companies/${response.result.id}`,
                  }
                : null
            )
          ),
        $companies_companycontact_id: this._companyContactDataService
          .getById(+value, true)
          .pipe(
            map((response) =>
              response?.result
                ? {
                    object: response.result,
                    display: response.result['__DisplayName'],
                    link: `/companies/${response.result.companyId}`,
                  }
                : null
            )
          ),
        $companies_financial_id: this._financialDataService
          .getById(+value, true)
          .pipe(
            map((response) =>
              response?.result
                ? {
                    object: response.result,
                    display: response.result['__DisplayName'],
                    link: `/companies/${response.result.companyId}`,
                  }
                : null
            )
          ),
        $invoicing_invoice_id: this._invoiceDataService
          .getById(+value, true)
          .pipe(
            map((response) =>
              response?.result
                ? {
                    object: response.result,
                    displayName: response.result['__DisplayName'],
                    link: `/invoicing-and-tariffs/invoicing/invoice/${response.result.id}`,
                  }
                : null
            )
          ),
        $nautical_ship_id: this._nauticalShipDataService
          .getById(value, true)
          .pipe(
            map((response) =>
              response
                ? {
                    object: response,
                    displayName: response['__DisplayName'],
                    link: `/nautical/vessels/${response.id}`,
                  }
                : null
            )
          ),
        $nautical_port_id: this._nauticalPortDataService
          .getById(+value, true)
          .pipe(
            map((response) =>
              response?.result
                ? {
                    object: response.result,
                    displayName: response.result['__DisplayName'],
                    link: null,
                  }
                : null
            )
          ),
        $nautical_portvisit_id: this._nauticalVisitDataService
          .get(+value, true)
          .pipe(
            map((response) =>
              response
                ? {
                    object: response,
                    displayName: response['__DisplayName'],
                    link: `/nautical/visit/${response.id}`,
                  }
                : null
            )
          ),
        $nautical_portvisit_referencenumber: this._nauticalVisitDataService
          .getByReferenceNumber(value, true)
          .pipe(
            map((response) =>
              response?.result
                ? {
                    object: response.result,
                    displayName: response.result['__DisplayName'],
                    link: `/nautical/visit/${response.result.id}`,
                  }
                : null
            )
          ),
        $nautical_portmovement_id: this._nauticalVisitDataService
          .getNauticalMovementById(+value, true)
          .pipe(
            map((response) =>
              response?.result
                ? {
                    object: response.result,
                    displayName: response.result['__DisplayName'],
                    link: `/nautical/visit/${response.result.portVisitId}`,
                  }
                : null
            )
          ),
        $nautical_harbour_id: this._nauticalHarbourDataService
          .getById(+value, true)
          .pipe(
            map((response) =>
              response?.result
                ? {
                    object: response.result,
                    displayName: response.result['__DisplayName'],
                    link: null,
                  }
                : null
            )
          ),
        $nautical_country_id: this._nauticalCountryDataService
          .getById(+value, true)
          .pipe(
            map((response) =>
              response?.result
                ? {
                    object: response.result,
                    displayName: response.result['__DisplayName'],
                    link: null,
                  }
                : null
            )
          ),
      };

      if (!observers[key]) subscription.complete();
      observers[key].subscribe(subscription);
    });
  }
}
