<div class="modal-header">
  <h4 class="modal-title">
    {{
      (financial && financial.id
        ? 'companies.financial.editFinancial'
        : 'companies.financial.addFinancial'
      ) | translate
    }}
  </h4>
  <button closeModal type="button" class="btn btn-link text-dark">
    <i class="fa-solid fa-times fa-fw fa-sm"></i>
  </button>
</div>

<div class="modal-body">
  <ca-financial-form
    [model]="financial"
    [submitted]="isSubmitted"
  ></ca-financial-form>
</div>

<div class="modal-footer d-flex">
  <div class="flex-grow-1">
    <button closeModal type="button" class="btn btn-block btn-link">
      {{ 'shared.terms.cancel' | translate }}
    </button>
  </div>
  <div class="flex-grow-1">
    <button
      (click)="onSelectCompanyFinancial.emit(this.financial)"
      type="button"
      class="btn btn-block btn-success"
    >
      {{ 'shared.terms.save' | translate }}
    </button>
  </div>
</div>
