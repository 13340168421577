import { inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ErrorMessage } from '../http/result-wrapper.model';

export abstract class NotificationService {
  protected translateService = inject(TranslateService);

  abstract showInfo(message?: string, title?: string, timeout?: number): void;
  abstract showSuccess(message?: string, title?: string, timeout?: number): void;
  abstract showWarning(message?: string, title?: string, timeout?: number): void;
  abstract showError(
    message?: string | ErrorMessage | string[] | ErrorMessage[],
    title?: string,
    timeout?: number
  ): void;
  abstract clear(): void;

  displayErrorNotification(resultMessages?: (string | ErrorMessage)[]) {
    const errorMessages: string[] = [];
    if (resultMessages && resultMessages.length) {
      resultMessages.forEach((resultMessage) => {
        if (typeof resultMessage === 'string') {
          errorMessages.push(resultMessage);
        } else if (
          resultMessage instanceof ErrorMessage &&
          resultMessage.message
        ) {
          errorMessages.push(resultMessage.message);
        }
      });
    } else {
      errorMessages.push(
        this.translateService.instant(
          'shared.errorMessages.anErrorHasOccurred'
        )
      );
    }

    this.showError(
      errorMessages.join('\n'),
      this.translateService.instant('shared.terms.failed')
    );
  }
}
