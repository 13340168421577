import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { DataContextService, ResultWrapper } from '@seahorse/common';
import { NauticalShipTagModel, NauticalShipModel } from '@seahorse/domain';

@Injectable()
export class NauticalShipTagDataService {
  constructor(private dataContext: DataContextService) {}

  search(
    search: string,
    pageIndex: number = 0,
    pageSize: number = 50
  ): Observable<ResultWrapper<NauticalShipTagModel[]>> {
    return this.dataContext.get<NauticalShipTagModel[]>(
      `ship-tag?search=${search}&pindex=${pageIndex}&psize=${pageSize}`
    );
  }

  add(
    tags: NauticalShipTagModel[],
    shipId: string
  ): Observable<ResultWrapper<NauticalShipModel>> {
    return this.dataContext.post<NauticalShipModel>(
      `ship-tag?shipid=${shipId}`,
      tags
    );
  }
}
