import { Component, Input } from '@angular/core';
import { FleetGroup } from '../../../map/models/fleet-group.model';

@Component({
  selector: 'ca-group-form',
  templateUrl: './group-form.component.html',
  styleUrls: ['./group-form.component.scss'],
})
export class GroupFormComponent {
  @Input() model: FleetGroup;
  @Input() submitted = false;
}
