import { Component, Input } from '@angular/core';
import { TaskType } from '../../../tasks/modules/task/models/task.model';
import {
  TaskGroupDisplayItem,
  DisplayGrouping,
  FindDueDate,
  SortTaskGroupDisplay,
} from '../../models/task-display.model';
import { Router } from '@angular/router';
import { TaskGroupModel } from '../../../task-group/models/task-group.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ca-expired-object-task-group-display-card',
  templateUrl: './expired-object-task-group-display-card.component.html',
})
export class ExpiredObjectTaskGroupDisplayCardComponent {
  TaskType = TaskType;
  DisplayGrouping = DisplayGrouping;
  isInitializing = false;
  taskGroupDisplay = new Array<TaskGroupDisplayItem>();

  @Input() set taskGroups(value: Array<TaskGroupModel>) {
    this.isInitializing = true;
    this.taskGroupDisplay = SortTaskGroupDisplay(
      value.map((x) => {
        const taskGroupDisplayItem = new TaskGroupDisplayItem(
          x,
          this._translateService.instant('taskGroupDisplay.ungroupedTitle')
        );
        if (!taskGroupDisplayItem.dueDate)
          taskGroupDisplayItem.dueDate = FindDueDate(
            taskGroupDisplayItem.tasks
          );

        return taskGroupDisplayItem;
      })
    );
    this.isInitializing = false;
  }

  constructor(
    private _router: Router,
    private _translateService: TranslateService
  ) {}

  goToTasksOverview() {
    this._router.navigateByUrl('/tasks/overview');
  }
}
