import { BreadcrumbItemModel } from '@syncfusion/ej2-angular-navigations';

export interface SeahorseBreadcrumb extends BreadcrumbItemModel {
  routerLink?: string;
}

export const homeCrumb: SeahorseBreadcrumb = {
  iconCss: 'e-icons e-home',
  routerLink: '/',
};
