import { BaseModel } from '@seahorse/common';
import { ValidationResult } from '@seahorse/common';
import { SelectedSearchModel } from '../../../../layout/models/selectedSearch.model';

export class TaskModel extends BaseModel {
  objectId: string;
  objectType: string;
  assignedTo: string;
  subject: string;
  description: string;
  dueDate: Date;
  completedOn: string;
  createdOn: string;
  createdBy: string;
  createdFromTaskId: TaskModel['id'];
  taskType: TaskType;
  status: TaskStatus;
  remarks: string;
  followUpTasks: TaskModel['id'][];
  assignedToChanged: boolean;
  taskGroupId: number;
  additionalInfo: string;

  constructor() {
    super();

    this.objectId = null;
    this.objectType = null;
    this.assignedTo = null;
    this.subject = null;
    this.description = null;
    this.dueDate = null;
    this.completedOn = null;
    this.createdOn = null;
    this.createdBy = null;
    this.createdFromTaskId = null;
    this.taskType = null;
    this.status = TaskStatus.Todo;
    this.remarks = null;
    this.followUpTasks = [];
    this.assignedToChanged = false;
    this.taskGroupId = null;
    this.additionalInfo = null;
  }

  static validate(task: TaskModel) {
    if (!task.subject || !task.taskType || !task.status) {
      return false;
    }

    return true;
  }

  validate(errorPrefix?: string): ValidationResult {
    throw new Error('Method not implemented.');
  }
}

export class TaskSearchModel extends SelectedSearchModel {
  isActive: boolean;
  isExpired: boolean;
  assignedToMe: boolean;
  assignedTo: string;
  objectId: string;
  objectType: string;
  groupBy: boolean;

  constructor(
    selectedOption: string,
    searchQuery: string,
    objectId: string,
    objectType: string,
    groupBy: boolean
  ) {
    super(selectedOption, searchQuery);
    this.isActive = true;
    this.isExpired = false;
    this.assignedToMe = false;
    this.assignedTo = null;
    this.objectId = objectId;
    this.objectType = objectType;
    this.groupBy = groupBy;
  }
}

export enum TaskType {
  Call = 1,
  SendEmail = 2,
  Visit = 3,
  Process = 4,
  Website = 5,
}

export enum TaskStatus {
  Todo = 1,
  Done = 2,
  Cancelled = 3,
  InProgress = 4,
}

export class TaskGroup {
  name: string;
  shipName?: string; // only for PCF
  type?: string;
  tasks: TaskModel[];
}

export class TaskAssignedModel {
  userId: string;
  subject: string;

  constructor(userId: string, subject: string) {
    this.userId = userId;
    this.subject = subject;
  }
}
