<div class="wizard-container">
  <button closeModal type="button" class="button-right btn btn-link text-dark">
    <i class="fa-solid fa-times fa-sm fa-fw"></i>
  </button>
  <div class="wizard-progress">
    <div
      class="wizard-progress-bar"
      [style.width.%]="currentStep * 33.33"
    ></div>
  </div>
  <div *ngIf="currentStep === 1">
    <temp-file-import-dialog
      [linkToObject]="linkToObject"
      (linkToObjectChange)="onLinkToObjectChange($event)"
      (confirm)="onConfirmFiles($event)"
      [objectId]="objectId"
      [hasParent]="true"
      [filesDropped]="filesDropped"
      [objectMappingKey]="objectMappingKey"
      [hasLinkedObjects]="hasLinkedObjects"
    ></temp-file-import-dialog>
  </div>
  <div *ngIf="currentStep === 2">
    <ca-object-types-modal
      [object]="object"
      [objectFieldDefinitions]="objectFieldDefinitions"
      [editFile]="editFile"
      [file]="file"
      [matchingLinkedObjects]="matchingLinkedObjects"
    ></ca-object-types-modal>
  </div>
  <div *ngIf="currentStep === 3">
    <ca-file-import-linked-objects-modal
      [object]="object"
      [objectMappingKey]="objectMappingKey"
      [objectFieldDefinitions]="objectFieldDefinitions"
      [selected]="selectedLinkedObjects"
      [files]="files"
      [editFile]="editFile"
      [file]="file"
      [matchingLinkedObjects]="matchingLinkedObjects"
      (confirm)="onConfirmFiles($event)"
    ></ca-file-import-linked-objects-modal>
  </div>
  <div *ngIf="linkToObject === true" class="buttons modal-footer">
    <button
      *ngIf="
        (editFile === false && currentStep !== 1) ||
        (editFile !== false && currentStep !== 2)
      "
      class="btn btn-secondary"
      (click)="prevStep()"
    >
      {{ 'shared.terms.previous' | translate }}
    </button>
    <button
      *ngIf="currentStep !== 3 && linkToObject === true"
      class="btn btn-success"
      (click)="nextStep()"
    >
      {{ 'shared.terms.next' | translate }}
    </button>
    <button
      *ngIf="currentStep === 3"
      class="btn btn-success"
      (click)="finish()"
    >
      {{ 'shared.terms.confirm' | translate }}
    </button>
  </div>
</div>
