import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  CustomDataBaseModel,
  FieldDefinitionModel,
  FileModel,
} from '@seahorse/domain';
import { ToastrService } from 'ngx-toastr';
import { FileListCardService } from '../file-list-card/file-list-card.service';
import { FileImportLinkedObjectsModalComponent } from '../file-import-linked-objects-modal/file-import-linked-objects-modal.component';
import { ObjectTypesModalComponent } from '../object-types-modal/object-types-modal.component';
import { FileImportDialogComponent } from '@seahorse/temp';

@Component({
  selector: 'ca-file-import-wizard',
  templateUrl: './file-import-wizard.component.html',
  styleUrls: ['./file-import-wizard.component.scss'],
})
export class FileImportWizardComponent implements OnInit {
  @Input() filesDropped?: FileList;
  @Input() objectId?: string;
  @Input() objectMappingKey?: string;
  @Input() title?: string;
  @Input() object: CustomDataBaseModel;
  @Input() objectFieldDefinitions?: FieldDefinitionModel[];
  @Input() linkedObjectsIds?: any;

  //Edit file
  @Input() editFile = false;
  @Input() file: FileModel;
  @Input() files: FileModel[];

  @Output() confirm: EventEmitter<any>;

  @ViewChild(FileImportDialogComponent)
  fileImportDialog: FileImportDialogComponent;

  @ViewChild(ObjectTypesModalComponent)
  objectTypesModalComponent!: ObjectTypesModalComponent;

  @ViewChild(FileImportLinkedObjectsModalComponent)
  fileImportLinkedObjectsModalComponent: FileImportLinkedObjectsModalComponent;

  linkToObject = false;
  hasLinkedObjects = false;

  currentStep = 1;
  selectedLinkedObjects: any;
  matchingLinkedObjects?: any;

  nextStep() {
    if (this.currentStep + 1 === 3) {
      this.getSelectedLinkedObjects();

      if (this.selectedLinkedObjects.length > 0) {
        this.currentStep++;
      } else {
        this._toastService.info(
          this._translateService.instant('uiModules.batchUpdate.info')
        );
      }
    } else {
      this.currentStep++;
    }
  }

  prevStep() {
    this.currentStep--;
    if (this.currentStep === 1) {
      this.linkToObject = false;
    }
  }

  finish() {
    this.fileImportLinkedObjectsModalComponent.uploadFilesToLinkedObjects();
  }

  constructor(
    private _fileImportWizardCommonService: FileListCardService,
    private _toastService: ToastrService,
    private _translateService: TranslateService
  ) {
    this.filesDropped = null;
    this.confirm = new EventEmitter<any>();
  }

  ngOnInit() {
    if (this.object && Object.keys(this.object.__AdditionalData).length !== 0) {
      const linkedObjects = Object.keys(this.object.__AdditionalData);

      linkedObjects.forEach((key) => {
        if (this.object.__AdditionalData[key] !== null) {
          this.hasLinkedObjects = true;
        }
      });
    }

    if (this.editFile) {
      this.currentStep = 2;
      this.linkToObject = true;

      this.matchingLinkedObjects =
        this._fileImportWizardCommonService.setMatchingLinkedObjects(
          this.files,
          this.linkedObjectsIds
        );
    }
  }

  onConfirmFiles(files: any) {
    this.confirm.emit(files);
  }

  onLinkToObjectChange(value: boolean) {
    this.linkToObject = value;
    this.files = this.fileImportDialog.files;

    this.currentStep = 2;
  }

  getSelectedLinkedObjects() {
    this.selectedLinkedObjects =
      this.objectTypesModalComponent.getSelectedLinkedObjects();
  }
}
