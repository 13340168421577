import { Component, Input, Output, EventEmitter } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'underscore';

import { EventModel } from '../../../../../../shared/models/event.model';
import { StageDetailsModalComponent } from '../stage-details-modal/stage-details-modal.component';
import { StageModel } from '@seahorse/domain';

@Component({
  selector: 'stage-details-info',
  templateUrl: 'stage-details-info.component.html',
})
export class StageDetailsInfoComponent {
  @Input() stage: StageModel;
  @Input() loadingStage: boolean;
  @Input() previousStage: StageModel;
  @Input() loadingPreviousStage: boolean;

  @Output() onEvent: EventEmitter<EventModel>;

  constructor(private modal: NgbModal) {
    this.stage = null;
    this.loadingStage = false;
    this.previousStage = null;
    this.loadingPreviousStage = false;
    this.onEvent = new EventEmitter<EventModel>();
  }

  editStage() {
    const stageDetailsModal = this.modal.open(StageDetailsModalComponent);

    // @Input()
    stageDetailsModal.componentInstance.stage = _.clone(this.stage);
    stageDetailsModal.componentInstance.loadingStage = this.loadingStage;

    // @Output()
    stageDetailsModal.componentInstance.onEvent.subscribe(
      (stageDetailsEvent: EventModel) => this.onEvent.emit(stageDetailsEvent)
    );
  }
}
