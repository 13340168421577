export class CustomDataAppendDataModel {
  appendData = false;
  appendFields: string[] = [];

  constructor(appendData?: boolean) {
    this.appendData = appendData === true ? true : false;
    this.appendFields = [];
  }

  setUrlArgs(uri: string): string {
    if (
      this.appendData === false &&
      (!this.appendFields || this.appendFields.length === 0)
    ) {
      return uri;
    }

    const args = [];
    if (this.appendData === true) {
      args.push('appenddata=1');
    }
    if (this.appendFields && this.appendFields.length > 0) {
      args.push('appendfields=' + this.appendFields.join(','));
    }

    return uri + (uri.indexOf('?') > -1 ? '&' : '?') + args.join('&');
  }
}
