import { Component } from '@angular/core';

@Component({
  selector: 'map-label',
  templateUrl: './map-label.component.html',
  styleUrls: ['./map-label.component.scss'],
})
export class MapLabelComponent {
  marker: any;
}
