import { inject } from '@angular/core';
import { FormModel, SeahorseFormBuilder } from '@seahorse/forms';

export type CreateContactForm = FormModel<typeof CreateContactForm>;

export function CreateContactForm() {
  const fb = inject(SeahorseFormBuilder);

  return fb.group({
    departments: fb.nonNullable.control<number[]>([]),
  });
}
