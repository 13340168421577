<form #groupForm="ngForm">
  <div class="row">
    <div class="col-12">
      <div class="form-group">
        <label class="control-label required"><b>Name</b></label>
        <input
          required
          #name="ngModel"
          type="text"
          name="name"
          [(ngModel)]="model.name"
          class="form-control"
        />
        <small
          *ngIf="(name.value == null || name.value === '') && submitted"
          class="text-danger"
          >Group name is required!</small
        >
      </div>
    </div>
  </div>
</form>
