import { Injectable } from '@angular/core';
import { ResultWrapper } from '@seahorse/common';
import { DataContextService } from '@seahorse/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  NauticalShipAttributeModel,
  NauticalShipFlagModel,
  NauticalShipModel,
  NauticalShipTypeCategory,
  NauticalShipTypeModel,
} from '../models/nautical-ship.model';
import { ShipSearchModel } from '../models/ship-search.model';

@Injectable({ providedIn: 'root' })
export class NauticalShipDataService {
  constructor(private dataContext: DataContextService) {}

  add(ship: NauticalShipModel): Observable<ResultWrapper<NauticalShipModel>> {
    return this.dataContext.post<NauticalShipModel>('nautical/vessels', ship);
  }

  find(
    shipSearchModel: ShipSearchModel,
    pageIndex: number = 0,
    pageSize: number = 50,
    includes: string[] = null,
    withCustomerShips = false,
    annotate = false,
    includeDeactivated = false
  ): Observable<ResultWrapper<NauticalShipModel[]>> {
    let url = `nautical/vessels/find?pindex=${pageIndex}&psize=${pageSize}&includes=${
      includes ? includes.join(',') : includes
    }&annotate=${annotate}&includeDeactivated=${includeDeactivated}`;

    if (withCustomerShips === true) {
      url = `${url}&customer=true`;
    }

    return this.dataContext.post<NauticalShipModel[]>(url, shipSearchModel);
  }

  getById(shipId: string, annotate?: boolean): Observable<NauticalShipModel> {
    let url = `nautical/vessels/${shipId}`;
    if (annotate) url += '?annotate=true';
    return this.dataContext
      .get<NauticalShipModel>(url)
      .pipe(map((response) => response.result));
  }

  getByImo(imo: string): Observable<ResultWrapper<NauticalShipModel>> {
    return this.dataContext.get<NauticalShipModel>(
      `nautical/vessels/imo/${imo}`
    );
  }

  getByMmsi(mmsi: string): Observable<ResultWrapper<NauticalShipModel>> {
    return this.dataContext.get<NauticalShipModel>(
      `nautical/vessels/mmsi/${mmsi}`
    );
  }

  /**
   * Get the ships by ids (string version) e.g. base_xxxx or customer_xxxx
   * Remarks: This is GET method. For POST method see @function getShipsByIds
   * @param shipIds The id strings of the ships
   * @param annotate Include the annotate or not
   */
  getShipsByIdStrings(
    shipIds: string[],
    annotate = false
  ): Observable<ResultWrapper<NauticalShipModel[]>> {
    return this.dataContext
      .get<NauticalShipModel[]>(
        `nautical/vessels/ids?ids=${shipIds.join()}&annotate=${annotate}`
      )
      .pipe(map((response) => response));
  }

  /**
   * Get the ships by id (int version)
   * Remarks: This is POST method. For GET method see @function getShipsByIdStrings
   * @param shipIds The ids of the ships
   * @param annotate Include the annotate or not
   */
  getShipsByIds(
    ids: number[],
    annotate = false
  ): Observable<ResultWrapper<NauticalShipModel[]>> {
    return this.dataContext.post<NauticalShipModel[]>(
      `nautical/vessels/ids?annotate=${annotate}`,
      ids
    );
  }

  getVessels(getBy: string): Observable<ResultWrapper<NauticalShipModel[]>> {
    return this.dataContext
      .get<NauticalShipModel[]>(`nautical/vessels?sortyBy=${getBy}`)
      .pipe(map((response) => response));
  }

  getShipFlagCodes(): Observable<ResultWrapper<NauticalShipFlagModel[]>> {
    return this.dataContext.get<NauticalShipFlagModel[]>('nautical/shipflag');
  }

  getShipTypes(): Observable<ResultWrapper<NauticalShipTypeModel[]>> {
    return this.dataContext.get<NauticalShipTypeModel[]>('nautical/shiptypes');
  }

  getShipTypeCategories(): Observable<
    ResultWrapper<NauticalShipTypeCategory[]>
  > {
    return this.dataContext.get<NauticalShipTypeCategory[]>(
      'nautical/shiptypes/categories'
    );
  }

  update(
    ship: NauticalShipModel
  ): Observable<ResultWrapper<NauticalShipModel>> {
    return this.dataContext.put<NauticalShipModel>(
      'nautical/vessels/' + ship.id,
      ship
    );
  }

  setAttribute(
    shipId: string,
    attribute: NauticalShipAttributeModel
  ): Observable<ResultWrapper<NauticalShipModel>> {
    return this.dataContext.put<NauticalShipModel>(
      'nautical/vessels/' + shipId + '/attribute',
      attribute
    );
  }

  deleteCustomerShip(
    customerShipId: number
  ): Observable<ResultWrapper<NauticalShipModel>> {
    return this.dataContext.delete<NauticalShipModel>(
      `nautical/vessels/customer-ship/${customerShipId}`
    );
  }
}
