import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { DataContextService } from '@seahorse/common';
import { ResultWrapper } from '@seahorse/common';
import { InvoiceModel } from '@seahorse/domain';

@Injectable()
export class CompanyInvoicesDataService {
  constructor(private dataContextService: DataContextService) {}

  getByCompanyId(
    companyId: InvoiceModel['companyId'],
    pageIndex: number,
    pageSize: number
  ): Observable<ResultWrapper<InvoiceModel[]>> {
    return this.dataContextService.get<InvoiceModel[]>(
      `invoice/company/${companyId}?pindex=${pageIndex}&psize=${pageSize}`
    );
  }
}
