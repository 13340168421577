import { NgModule } from '@angular/core';
import { KPDataService } from './kp-data.service';
import { CoreModule } from '../../../core/core.module';
import { CustomDataServicesModule } from '../../../custom-data/services/custom-data-services.module';
import { KPActivityLogicService } from './kp-activity-logic.service';

@NgModule({
  imports: [CoreModule, CustomDataServicesModule],
  providers: [KPDataService, KPActivityLogicService],
})
export class CustomKPServicesModule {}
