<ng-container
  *ngIf="
    taskDefinitions.length;
    then showTaskDefinitions;
    else noTaskDefinitions
  "
></ng-container>
<ng-template #showTaskDefinitions>
  <table class="table table-clickable">
    <thead>
      <tr>
        <th scope="col">{{ 'tasks.definition.model.subject' | translate }}</th>
        <th scope="col">
          {{ 'tasks.definition.model.daysRequired' | translate }}
        </th>
        <th scope="col">{{ 'tasks.definition.model.taskType' | translate }}</th>
        <th scope="col">{{ 'shared.terms.description' | translate }}</th>
        <th scope="col" width="1%"></th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let taskDefinition of taskDefinitions"
        class="card-body-group"
      >
        <td
          (click)="
            onEvent.emit({ action: 'editTaskDefinition', data: taskDefinition })
          "
          scope="row"
        >
          <b>{{ taskDefinition.subject }}</b>
        </td>
        <td
          (click)="
            onEvent.emit({ action: 'editTaskDefinition', data: taskDefinition })
          "
        >
          <ng-container *ngIf="taskDefinition.daysRequired">{{
            taskDefinition.daysRequired
          }}</ng-container>
        </td>
        <td
          (click)="
            onEvent.emit({ action: 'editTaskDefinition', data: taskDefinition })
          "
        >
          {{ taskDefinition.taskType | taskType }}
        </td>
        <td
          (click)="
            onEvent.emit({ action: 'editTaskDefinition', data: taskDefinition })
          "
        >
          <ng-container>{{
            taskDefinition.description | shTruncate: 30
          }}</ng-container>
        </td>
        <td class="btn-list">
          <div class="dropdown p-0">
            <button
              type="button"
              class="btn btn-light dropdown-toggle"
              id="rowMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="fa-solid fa-ellipsis-v fa-fw"></i>
            </button>
            <div class="btn dropdown-menu" aria-labelledby="rowMenuButton">
              <button
                class="dropdown-item"
                (click)="
                  onEvent.emit({
                    action: 'deleteTaskDefinition',
                    data: taskDefinition.__Id
                  })
                "
              >
                <i class="fa-solid fa-trash fa-fw"></i>
                {{ 'shared.terms.delete' | translate }}
              </button>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</ng-template>
<ng-template #noTaskDefinitions>
  <div *ngIf="!loading" class="text-center text-muted py-3">
    {{ 'tasks.definition.noTaskDefinitions' | translate }}
  </div>
</ng-template>
<sh-loading-indicator
  *ngIf="loading"
  class="d-block text-center"
></sh-loading-indicator>
