import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[numbersOnly]',
})
export class NumbersOnlyDirective {
  regex = /^[0-9.,]*$/;

  constructor(private el: ElementRef) {}

  @HostListener('keyup', ['$event']) onKeyUp(event: any) {
    const isValid = this.regex.test(event.currentTarget.value);

    if (!isValid) {
      this.el.nativeElement.value = 0;
    } else {
      const point = '.';

      if (this.el.nativeElement.value.includes(',')) {
        this.el.nativeElement.value = this.el.nativeElement.value.replace(
          ',',
          '.'
        );
      }

      if (
        this.el.nativeElement.value.indexOf(point) !==
        this.el.nativeElement.value.lastIndexOf(point)
      ) {
        this.el.nativeElement.value = this.el.nativeElement.value.substring(
          0,
          this.el.nativeElement.value.length - 1
        );
      }

      if (this.el.nativeElement.value.startsWith('0')) {
        this.el.nativeElement.value = this.el.nativeElement.value.replace(
          /^0+/,
          ''
        );
      }
    }
  }

  @HostListener('blur', ['$event']) onBlur(event: any) {
    if (this.el.nativeElement.value.endsWith('.')) {
      this.el.nativeElement.value = this.el.nativeElement.value.substring(
        0,
        this.el.nativeElement.value.length - 1
      );
    }
  }
}
