<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title-bold">
      {{ 'customers.beverwijk.actionModalTitle' | translate }}
    </h4>
    <button type="button" class="close" (click)="cancel()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="d-flex justify-content-between">
      <button
        type="button"
        class="btn btn-primary"
        (click)="goTo('binnenvaart')"
      >
        {{ 'customers.beverwijk.binnenvaart' | translate }}
      </button>
      <button type="button" class="btn btn-primary" (click)="goTo('kadegeld')">
        {{ 'customers.beverwijk.kadegeld' | translate }}
      </button>
      <button type="button" class="btn btn-primary" (click)="goTo('zeevaart')">
        {{ 'customers.beverwijk.zeevaart' | translate }}
      </button>
    </div>
  </div>
</div>
