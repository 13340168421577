<div class="tile-container">
  <div
    *ngFor="let orderRegistration of orderRegistrations"
    [ngClass]="{
      selected: selected && selected.__Id == orderRegistration.__Id
    }"
    (click)="onEvent.emit({ action: 'select', data: orderRegistration })"
    class="card tile tile-clickable"
  >
    <div class="card-body">
      <div class="row seperated-columns">
        <div class="col-12 col-sm-6">
          <b>{{ orderRegistration.po | uppercase }}</b>
          <br />
          <b>{{
            'customModules.orderRegistrations.model.comments' | translate
          }}</b>
          <ng-container *ngIf="orderRegistration.comments">
            &nbsp;
            <span class="text-muted">{{ orderRegistration.comments }}</span>
          </ng-container>
        </div>
        <div class="col-12 col-sm-6">
          <b>{{
            'customModules.orderRegistrations.model.status' | translate
          }}</b>
          &nbsp;
          <span class="text-muted">{{
            orderRegistration.status | shOrderRegistrationStatus
          }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
