import { CommonModule } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'sh-pdf-viewer',
  template: `
    <div class="content-wrapper">
      <iframe
        *ngIf="source"
        [src]="source"
        [title]="title"
        style="height:800px;display:block;width:100%"
      >
      </iframe>
    </div>
  `,
  standalone: true,
  imports: [CommonModule]
})
export class ShPdfViewerComponent {
  @Input() title = '';
  @Input() set base64Content(val: string) {
    if (!val) {
      this.source = undefined;
    }

    const src = `data:application/pdf;base64,${val}`;
    this.source = this._sanitizer.bypassSecurityTrustResourceUrl(src) ;
  }

  private _sanitizer = inject(DomSanitizer);
  source?: SafeResourceUrl;
}
