import { Component, Input } from '@angular/core';
import { TaskDefinitionListModel } from '../../../../../task-definition-list/models/task-definition-list.model';

@Component({
  selector: 'task-definition-list-form',
  templateUrl: './task-definition-list-form.component.html',
})
export class TaskDefinitionListFormComponent {
  @Input() taskDefinitionList: TaskDefinitionListModel;

  constructor() {
    this.taskDefinitionList = null;
  }
}
