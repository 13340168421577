import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import * as _ from 'underscore';
import { NotificationService } from '@seahorse/common';
import { ResultWrapper } from '@seahorse/common';
import { UserDataService } from '../../../../user/services/user-data.service';
import { UserModel } from '@seahorse/domain';
import { CustomMemberDataService } from '../../services/custom-member-data.service';
import { CustomMemberModel } from '../../models/custom-member.model';
import { CustomTeamModel } from '../../models/custom-team.model';

@Component({ templateUrl: './team-manager-modal.component.html' })
export class TeamManagerModalComponent implements OnInit {
  @Input() set team(team: CustomTeamModel) {
    this._team = team;

    this.setSelectedTeam();
  }

  @Input() set teams(teams: TeamDisplayModel[]) {
    if (teams && teams.length > 0) {
      const tempList = _.map(teams, (team) => {
        team.displayName = team.name;

        return team;
      });

      this.teamList = _.sortBy([new TeamDisplayModel()].concat(tempList), (t) =>
        t.name ? t.name.toLowerCase() : ''
      );
    } else {
      this.teamList = [new TeamDisplayModel()];
    }

    this.setSelectedTeam();
  }

  @Input() set users(inputUsers: CustomMemberModel[]) {
    this.allUsers = inputUsers;
    this.loadAllMembers();
  }

  @Output() onSelectTeamAction: EventEmitter<object>;

  private _team: CustomTeamModel;

  allUsers: CustomMemberModel[] = null;
  selectedMembers: string[];
  teamMembers: CustomMemberModel[];
  loadTeamUsers: boolean;
  selectedTeam: CustomTeamModel;
  teamList: TeamDisplayModel[];
  searchField: string;

  constructor(
    private userDataService: UserDataService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private memberDataService: CustomMemberDataService
  ) {
    this.onSelectTeamAction = new EventEmitter<{
      team: CustomTeamModel;
      action: string;
    }>();

    this._team = null;
    this.allUsers = null;
    this.selectedMembers = [];
    this.selectedTeam = null; // new CustomTeamModel();
    this.teamMembers = [];
    this.loadTeamUsers = false;
    this.teamList = [new TeamDisplayModel()];
    this.selectedTeam = this.teamList[0];
  }

  ngOnInit() {
    if (this.allUsers === null) {
      // Get users
      this.loadTeamUsers = true;
      this.userDataService
        .getByOrganisation(false)
        .subscribe((usersResponse: ResultWrapper<any>) => {
          this.allUsers = [];

          if (usersResponse.hasResult) {
            this.convertUsersToMemberModel(usersResponse.result);
          } else {
            this.notificationService.showError(
              this.translateService.instant('user.loadTeamUsersFailed')
            );
          }

          this.loadTeamUsers = false;
          this.loadAllMembers();
        });
    } else {
      this.loadAllMembers();
    }
  }

  convertUsersToMemberModel(users) {
    _.each(users, (user: UserModel) => {
      this.allUsers.push(
        this.createNewMember(user.id, user.givenName, user.surname)
      );
    });
  }

  loadAllMembers() {
    this.loadTeamUsers = true;
    this.memberDataService.get().subscribe((memebersResponse) => {
      if (memebersResponse.hasResult) {
        _.each(memebersResponse.result, (member: CustomMemberModel) => {
          const index = _.findIndex(
            this.allUsers,
            (user) => user.$usermanager_user_id === member.$usermanager_user_id
          );
          if (index > -1) {
            this.allUsers[index] = member;
          } else {
            this.allUsers.push(member);
          }
        });
      }

      this.loadTeamUsers = false;
      if (this.selectedTeam) {
        this.selectTeamMembers(this.selectedTeam.__Id);
      } else {
        this.selectTeamMembers(null);
      }
    });
  }

  // Select team members
  selectTeamMembers(selectedTeamId: CustomTeamModel['__Id']) {
    if (this.allUsers === null) {
      return;
    }

    let currentMembers;
    if (selectedTeamId) {
      currentMembers = _.filter(this.allUsers, (user) =>
        _.contains(user.$customdata_membergroup_ids, selectedTeamId)
      );
    } else {
      currentMembers = this.allUsers;
    }

    _.each(this.allUsers, (a: CustomMemberModel) => {
      a.isChecked = false;
    });

    this.teamMembers = _.sortBy(this.allUsers, '$usermanager_user_givenName');

    if (selectedTeamId) {
      _.each(currentMembers, (m: CustomMemberModel) => {
        m.isChecked = true;
      });
    }

    this.selectAllMembers(currentMembers);
  }

  // Select member
  selectMember(
    selectedMemberUserId: CustomMemberModel['$usermanager_user_id']
  ) {
    if (this.selectedMembers.indexOf(selectedMemberUserId) > -1) {
      this.selectedMembers.splice(
        this.selectedMembers.indexOf(selectedMemberUserId),
        1
      );
    } else {
      this.selectedMembers.push(selectedMemberUserId);
    }
  }

  isChecked(selectedMemberUserId: CustomMemberModel['$usermanager_user_id']) {
    return this.selectedMembers.indexOf(selectedMemberUserId) > -1;
  }

  // Select all members
  selectAllMembers(currentMembers) {
    this.selectedMembers = [];

    if (this.selectedTeam.__Id) {
      for (let i = 0; i < currentMembers.length; i++) {
        if (
          this.selectedMembers.indexOf(
            currentMembers[i].$usermanager_user_id
          ) <= -1
        ) {
          this.selectMember(currentMembers[i].$usermanager_user_id);
          this.isChecked(currentMembers[i].$usermanager_user_id);
        }
      }
    }
  }

  createNewMember(userId, givenName, surName) {
    const member = new CustomMemberModel();
    member.$usermanager_user_id = userId;
    member.$usermanager_user_givenName = givenName;
    member.$usermanager_user_surname = surName;

    return member;
  }

  saveTeam() {
    this.onSelectTeamAction.emit({
      team: _.clone(this.selectedTeam),
      members: this.selectedMembers,
      action: 'save',
    });
    // ensure the team model is reset
    this.teamList[0] = new TeamDisplayModel();
    this.selectedTeam = this.teamList[0];
  }

  setSelectedTeam() {
    if (this._team) {
      if (this._team.__Id && this.teamList && this.teamList.length > 0) {
        const foundTeam = _.find(
          this.teamList,
          (t) => t.__Id === this._team.__Id
        );
        if (foundTeam) {
          this.selectedTeam = foundTeam;
        }
      }
    } else if (this.teamList && this.teamList.length > 0) {
      this.selectedTeam = this.teamList[0];
    }
  }
}

class TeamDisplayModel extends CustomTeamModel {
  displayName: string;
}
