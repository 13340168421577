import { Injectable } from '@angular/core';
import * as _ from 'underscore';

@Injectable({ providedIn: 'root' })
export class ChartDataService {
  defaultBgColors = [
    // [R, G, B, PERC]
    [247, 148, 29, 97], // '#F7941D'
    [8, 28, 57, 22], // '#081C39'
    [255, 165, 56, 100], // '#FFA538'
    [92, 157, 255, 100], // '#5C9DFF'
    [158, 108, 9, 62], // '#9E6C09'
    [240, 145, 29, 94], // '#F0911D'
    [61, 130, 235, 92], // '#3D82EB'
    [0, 110, 163, 64], // '#006EA3'
    [235, 176, 61, 92], // '#EBB03D'
    [29, 171, 240, 94], // '#1DABF0'
  ];

  /**
   * @param numberOfColor The number of amount background colors.
   * @return An array of background colors.
   **/
  getBackgroundColors(numberOfColor: number): string[] {
    const output = [];
    if (numberOfColor && numberOfColor > 0) {
      for (let i = 0; i < numberOfColor; i++) {
        const color = this.getRgbColorByGradientPercentage(i);
        output.push(`rgb(${color[0]}, ${color[1]}, ${color[2]})`);
      }
    }
    return output;
  }

  private getRgbColorByGradientPercentage(nr: number) {
    const reminder = nr % 100; // make sure number is under 100; range 0-99
    const digit = reminder % 10;
    const color = this.defaultBgColors[digit];
    const percStep = reminder - digit;
    const percentage =
      color[3] - percStep >= 0
        ? color[3] - percStep
        : 100 - (percStep - color[3]);
    const red = Math.round((color[0] * percentage) / color[3]);
    const green = Math.round((color[1] * percentage) / color[3]);
    const blue = Math.round((color[2] * percentage) / color[3]);
    return [red, green, blue];
  }
}
