import {
  Component, EventEmitter,
  Inject, Input, OnInit, Output
} from '@angular/core';

import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import { CompanyFinancialModel, FinancialDataService } from '@seahorse/domain';
import { EventModel } from '../../../../shared/models/event.model';
import { InteractionService } from '../../../../shared/services/interaction.service';
import { FinancialModalComponent } from '../../financial/financial-modal/financial-modal.component';

@Component({
  selector: 'company-financial',
  templateUrl: 'company-financial.component.html',
})
export class CompanyFinancialComponent implements OnInit {
  canLoadMore = false;
  pageIndex = 0;
  pageSize = 5;
  financials: CompanyFinancialModel[] = [];

  private subscription: Subscription;

  @Input() companyId: number;

  @Output() onEvent: EventEmitter<EventModel>;

  constructor(
    @Inject('FinancialInteractionService')
    private financialInteractionService: InteractionService,
    private financialDataService: FinancialDataService,
    private modalService: NgbModal
  ) {
    this.onEvent = new EventEmitter<EventModel>();

    this.subscription =
      this.financialInteractionService.modifyCalled$.subscribe(() => {
        this.reloadFinancial();
      });

    this.financialInteractionService.deleteCalled$.subscribe(() => {
      this.reloadFinancial();
    });
  }

  ngOnInit() {
    this.pageIndex = 0;
    this.loadFinancials();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  reloadFinancial() {
    this.pageIndex = 0;
    this.loadFinancials();
  }

  loadMore() {
    this.pageIndex += 1;
    this.loadFinancials();
  }

  loadFinancials() {
    this.financialDataService
      .getFinancials(this.companyId, this.pageIndex, this.pageSize)
      .subscribe(
        (result) => {
          if (this.pageIndex > 0) {
            this.financials = this.financials.concat(result.result);
          } else {
            this.financials = result.result;
          }
          this.canLoadMore = this.financials.length < result.count;
        },
        () => {}
      );
  }

  selectFinancial(financial: CompanyFinancialModel) {
    const ngbModalOptions: NgbModalOptions = { backdrop: 'static' };
    const modalRef = this.modalService.open(
      FinancialModalComponent,
      ngbModalOptions
    );

    if (financial != null) {
      modalRef.componentInstance.financialId = financial.id;
    }
  }

  delete(financial: CompanyFinancialModel) {
    this.financialInteractionService.delete(financial);
  }
}
