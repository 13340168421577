<ca-language-picker class="d-flex justify-content-end m-2"></ca-language-picker>

<div class="login-wrapper">
  <div class="login-form">
    <!-- Template for Login Logo -->
    <ng-template #loginLogo>
      <div class="login-logo">{{ 'shared.branding.shipm8' | translate }}</div>
    </ng-template>

    <!-- Activation Loading Section -->
    <section *ngIf="!activated && isLoading && !isTokenExpired">
      <ng-container *ngTemplateOutlet="loginLogo"></ng-container>
      <p class="text-center">
        <br /><br />{{ 'accounts.activate.activating' | translate }}
      </p>
      <div>
        <sh-loading-indicator
          class="d-flex justify-content-center w-100"
          *ngIf="isLoading"
        ></sh-loading-indicator>
      </div>
    </section>

    <!-- Token Expired Section -->
    <section *ngIf="isTokenExpired && !activated">
      <ng-container *ngTemplateOutlet="loginLogo"></ng-container>
      <p class="text-center">
        <br /><br />{{ 'accounts.activate.tokenExpired' | translate }}
      </p>
      <div class="d-flex justify-content-between mt-4">
        <button
          type="button"
          class="btn btn-primary btn-lg"
          (click)="resendToken()"
        >
          {{ 'accounts.activate.resendToken' | translate }}
        </button>
      </div>
    </section>

    <!-- Ask User to Enable MFA Section -->
    <section *ngIf="activated && !mfaSetupCode">
      <ng-container *ngTemplateOutlet="loginLogo"></ng-container>
      <p class="text-center">
        <br /><br />{{ 'accounts.activate.success' | translate }} <br /><br />
        {{ 'accounts.activate.mfaPrompt' | translate }}
      </p>
      <div class="d-flex justify-content-between mt-4">
        <button
          type="button"
          class="btn btn-primary btn-lg"
          (click)="initMfa()"
        >
          {{ 'accounts.activate.mfaYes' | translate }}
        </button>
        <button type="submit" class="btn btn-link" (click)="backToLogin()">
          {{ 'accounts.activate.mfaNo' | translate }}
        </button>
      </div>
    </section>

    <!-- Complete MFA Setup Section -->
    <section *ngIf="mfaSetupCode">
      <ng-container *ngTemplateOutlet="loginLogo"></ng-container>
      <temp-mfa-setup #mfaSetup [setupCode]="mfaSetupCode"></temp-mfa-setup>
      <div class="d-flex justify-content-between mt-2">
        <button
          type="button"
          class="btn btn-primary btn-lg"
          (click)="completeMfa()"
        >
          {{ 'shared.terms.confirm' | translate }}
        </button>
        <button type="submit" class="btn btn-link" (click)="backToLogin()">
          {{ 'user.mfa.backToLogin' | translate }}
        </button>
      </div>
    </section>
  </div>
</div>
