import { EventEmitter, Injectable, Output, Directive } from '@angular/core';
import { ResultWrapper } from '@seahorse/common';
import { PagedDataModel } from '../../layout/models/paged-data.model';
import { Observable, Subject } from 'rxjs';

@Directive()
@Injectable({
  providedIn: 'root',
})
export class PagedDataService<T> {
  // Private properties
  private initKey: string;
  private initPageIndex: number;
  private initPageSize: number;
  private isFirstLoad = true;
  private pagedModel: PagedDataModel = null;

  private pagedDataSource = new Subject<PagedDataModel>();

  // Public properties
  canLoadMore = false;
  isLoading = false;

  pagedModelChanged$ = this.pagedDataSource.asObservable();
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onRequestSuccess = new EventEmitter<ResultWrapper<T[]>>();
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onRequestFailed = new EventEmitter<any>();
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onRequestCompleted = new EventEmitter();

  /**
   * Initialize the paged data object
   * @param key The unique key for this paged data service. Max 3 characters; because this key will be used in the url.
   * @param pIndex The default page index.
   * @param pSize The default page size.
   * @returns Returns a paged data model.
   */
  init(key: string, pIndex: number, pSize: number): PagedDataModel {
    if (!key || key.length > 3) {
      throw new Error('The parameter key is invalid, max 3 characters.');
    }

    this.isFirstLoad = true;
    this.canLoadMore = false;
    this.isLoading = false;

    this.initKey = key;
    this.initPageIndex = pIndex;
    this.initPageSize = pSize;
    this.pagedModel = new PagedDataModel(
      this.initKey,
      this.initPageIndex,
      this.initPageSize
    );
    return this.pagedModel;
  }

  /**
   * Gets the key of this data service.
   */
  getKey() {
    return this.pagedModel.key;
  }

  /**
   * Gets the paging.
   */
  getPaging() {
    if (this.isFirstLoad && this.pagedModel.pIndex <= 5) {
      // If the page index is smaller than 6, load all entries for the first time. otherwise load only the current page
      return {
        pIndex: 0,
        pSize: (this.pagedModel.pIndex + 1) * this.pagedModel.pSize,
      };
    } else {
      return {
        pSize: this.pagedModel.pSize,
        pIndex: this.pagedModel.pIndex,
      };
    }
  }

  /**
   * Load the entries with the given request.
   * @param request The request to call.
   */
  loadEntries(request: Observable<ResultWrapper<any[]>>) {
    this.isLoading = true;

    if (request) {
      request.subscribe(
        (response) => {
          if (response.hasResult) {
            const paging = this.getPaging();
            const previousCount = paging.pIndex * paging.pSize;
            if (
              response.result.length >= paging.pSize &&
              previousCount + response.result.length < response.count
            ) {
              this.canLoadMore = true;
            } else {
              this.canLoadMore = false;
            }
          }

          this.onRequestSuccess.emit(response);
        },
        (error: any) => {
          this.onRequestFailed.emit(error);
        },
        () => {
          this.isFirstLoad = false;
          this.isLoading = false;
          this.onRequestCompleted.emit();
        }
      );
    } else {
      this.isLoading = false;
    }
  }

  /**
   * Reset the pagedModel to the default values.
   */
  resetPagedDataModel() {
    this.pagedModel.key = this.initKey;
    this.pagedModel.pIndex = this.initPageIndex;
    this.pagedModel.pSize = this.initPageSize;
  }

  /**
   * Set the paging with the given values.
   * The event pagedModelChanged$ will be triggered if the given values are different than current values.
   * @param pIndex The new page index.
   * @param pSize The new page size.
   * @returns Returns a boolean indicates whether the paging is changed or not.
   */
  setPaging(pIndex: number, pSize: number): boolean {
    let isChanged = false;
    if (pIndex && pIndex !== this.pagedModel.pIndex) {
      this.pagedModel.pIndex = pIndex;
      isChanged = true;
    }

    if (pSize && pSize !== this.pagedModel.pSize) {
      this.pagedModel.pSize = pSize;
      isChanged = true;
    }

    if (isChanged === true) {
      this.pagedDataSource.next(this.pagedModel);
    }

    return isChanged;
  }
}
