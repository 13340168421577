<div class="page-header">
  <div class="row">
    <div class="col-sm-9 btn-group">
      <form class="form form-inline">
        <ca-filter-list
          button-displayText="{{ 'shared.terms.selectFilter' | translate }}"
          [list-data]="filters"
          list-displayMember="displayText"
          list-title="{{ 'shared.terms.availableFilters' | translate }}'"
          (selected)="setSelectedFilter($event)"
        >
        </ca-filter-list>
        <div class="input-group">
          <select
            class="form-control"
            style="max-width: 150px"
            [(ngModel)]="sortBy"
            name="ddl_header_data_sortField"
            (change)="onSortChanged()"
          >
            <option
              [value]="sortField.systemCode"
              *ngFor="let sortField of sortFields"
            >
              {{ sortField.name }}
            </option>
          </select>
          <div class="input-group-append">
            <button
              type="button"
              class="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split mr-0"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span
                *ngIf="sortOrder === 'asc'"
                title="{{ 'shared.terms.ascending' | translate }}"
              >
                <i class="fa-regular fa-fw fa-sort-alpha-up"></i
              ></span>
              <span
                *ngIf="sortOrder === 'desc'"
                title="{{ 'shared.terms.descending' | translate }}"
              >
                <i class="fa-regular fa-fw fa-sort-alpha-down-alt"></i
              ></span>
              <span class="sr-only">Toggle Dropdown</span>
            </button>
            <div class="dropdown-menu">
              <a
                class="dropdown-item"
                (click)="sortOrder = 'asc'; onSortChanged()"
                ><i class="fa-regular fa-sort-alpha-up"></i>
                {{ 'shared.terms.ascending' | translate }}</a
              >
              <a
                class="dropdown-item"
                (click)="sortOrder = 'desc'; onSortChanged()"
                ><i class="fa-regular fa-sort-alpha-down-alt"></i>
                {{ 'shared.terms.descending' | translate }}</a
              >
            </div>
          </div>
        </div>
      </form>
      <form class="form form-inline" (ngSubmit)="find()">
        <div class="input-group">
          <div
            class="input-group-prepend"
            *ngIf="!searchFields || searchFields.length === 0"
          >
            <span class="input-group-text">
              <i class="fa-solid fa-search"></i>
            </span>
          </div>
          <div
            class="input-group-prepend"
            *ngIf="searchFields && searchFields.length > 0"
          >
            <select
              class="form-control"
              style="max-width: 150px"
              [(ngModel)]="searchField"
              name="ddl_header_data_searchField"
            >
              <option
                [value]="field.systemCode"
                *ngFor="let field of searchFields"
              >
                {{ field.name }}
              </option>
            </select>
          </div>
          <input
            type="text"
            class="form-control"
            style="max-width: 150px"
            placeholder="{{ 'shared.terms.search' | translate }}"
            [(ngModel)]="searchQuery"
            name="searchBox"
          />
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" type="submit">
              {{ 'shared.terms.search' | translate }}
            </button>
          </div>
        </div>
        <em *ngIf="searchResultCount !== null">
          <span *ngIf="searchResultCount === 1"
            >{{ searchResultCount }}
            {{ 'shared.terms.itemFound' | translate }}</span
          >
          <span *ngIf="searchResultCount !== 1"
            >{{ searchResultCount }}
            {{ 'shared.terms.itemsFound' | translate }}</span
          >
        </em>
      </form>
    </div>
    <div class="col-3 text-right pt-1">
      <ca-date-period-picker
        [date-period-picker-from]="dateFrom"
        [date-period-picker-to]="dateUntil"
        (onDateSelected)="onDateChanged($event)"
      ></ca-date-period-picker>
    </div>
  </div>
</div>
