<!-- Buttons to export documents to the client -->
<ng-container *ngIf="renderAs === 'button'">
  <button
    *ngIf="exportOptions && exportOptions.length === 1"
    (click)="optionSelected(exportOptions[0])"
    type="button"
    class="btn btn-outline-secondary ml-2 text-nowrap"
    [ngClass]="buttonClass"
  >
    <span *ngIf="isExporting">
      <i class="fa-regular fa-spinner fa-fw fa-spin"></i>
    </span>
    <i class="fa-regular fa-fw" [ngClass]="getButtonIcon(exportOptions[0])"></i>
    {{ 'shared.terms.export' | translate }} {{ exportOptions[0].templateName }}
  </button>
  <ng-container *ngIf="exportOptions && exportOptions.length > 1">
    <ng-container
      *ngTemplateOutlet="
        dropdownMenu;
        context: {
          tmplExportOptions: exportOptions,
          buttonText: 'shared.terms.export'
        }
      "
    ></ng-container>
  </ng-container>

  <!-- Buttons to send content to destinations -->
  <button
    *ngIf="sendOptions && sendOptions.length === 1"
    (click)="optionSelected(sendOptions[0])"
    type="button"
    class="btn btn-outline-secondary ml-2 text-nowrap"
    [ngClass]="buttonClass"
  >
    <span *ngIf="isSending">
      <i class="fa-regular fa-spinner fa-fw fa-spin"></i>
    </span>
    <i class="fas fa-fw" [ngClass]="getButtonIcon(sendOptions[0])"></i>
    {{ 'shared.terms.send' | translate }} {{ sendOptions[0].templateName }}
  </button>
  <ng-container *ngIf="sendOptions && sendOptions.length > 1">
    <ng-container
      *ngTemplateOutlet="
        dropdownMenu;
        context: {
          tmplExportOptions: sendOptions,
          buttonText: 'shared.terms.send'
        }
      "
    ></ng-container>
  </ng-container>

  <!-- Buttons to store as file -->
  <button
    *ngIf="storeOptions && storeOptions.length === 1"
    (click)="optionSelected(storeOptions[0])"
    type="button"
    class="btn btn-outline-secondary ml-2 text-nowrap"
    [ngClass]="buttonClass"
  >
    <span *ngIf="isStoring">
      <i class="fa-regular fa-spinner fa-fw fa-spin"></i>
    </span>
    <i class="fa-regular fa-fw" [ngClass]="getButtonIcon(storeOptions[0])"></i>
    {{ 'shared.terms.store' | translate }} {{ storeOptions[0].templateName }}
  </button>
  <ng-container *ngIf="storeOptions && storeOptions.length > 1">
    <ng-container
      *ngTemplateOutlet="
        dropdownMenu;
        context: {
          tmplExportOptions: storeOptions,
          buttonText: 'shared.terms.store'
        }
      "
    ></ng-container>
  </ng-container>
</ng-container>

<div *ngIf="renderAs === 'dropdown'">
  <ng-container *ngIf="exportOptions && exportOptions.length > 0">
    <div class="dropdown-divider"></div>
    <ng-container
      *ngTemplateOutlet="
        dropdownList;
        context: {
          tmplExportOptions: exportOptions,
          buttonText: 'shared.terms.export'
        }
      "
    ></ng-container>
  </ng-container>
  <ng-container *ngIf="sendOptions && sendOptions.length > 0">
    <div class="dropdown-divider"></div>
    <ng-container
      *ngTemplateOutlet="
        dropdownList;
        context: {
          tmplExportOptions: sendOptions,
          buttonText: 'shared.terms.send'
        }
      "
    ></ng-container>
  </ng-container>
</div>

<ng-template
  let-tmplExportOptions="tmplExportOptions"
  let-buttonText="buttonText"
  #dropdownList
>
  <h6 class="dropdown-header">{{ buttonText | translate }}</h6>
  <ng-container *ngFor="let tmplExportOption of tmplExportOptions">
    <ng-container
      *ngTemplateOutlet="
        dropdownItem;
        context: { tmplExportOption: tmplExportOption }
      "
    ></ng-container>
  </ng-container>
</ng-template>

<ng-template let-tmplExportOption="tmplExportOption" #dropdownItem>
  <button
    (click)="optionSelected(tmplExportOption)"
    type="button"
    class="dropdown-item text-nowrap"
  >
    <i class="fas fa-fw" [ngClass]="getButtonIcon(tmplExportOption)"></i>
    {{ tmplExportOption.templateName }}
  </button>
</ng-template>

<ng-template
  let-tmplExportOptions="tmplExportOptions"
  let-buttonText="buttonText"
  #dropdownMenu
>
  <div class="dropdown d-inline-block">
    <button
      class="btn btn-outline-secondary dropdown-toggle ml-2 text-nowrap"
      type="button"
      id="dropdownMenuButton"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      [ngClass]="buttonClass"
    >
      <span *ngIf="isExporting && buttonText === 'shared.terms.export'">
        <i class="fa-regular fa-spinner fa-fw fa-spin"></i>
      </span>
      <span *ngIf="isSending && buttonText === 'shared.terms.send'">
        <i class="fa-regular fa-spinner fa-fw fa-spin"></i>
      </span>
      <span *ngIf="isStoring && buttonText === 'shared.terms.store'">
        <i class="fa-regular fa-spinner fa-fw fa-spin"></i>
      </span>
      {{ buttonText | translate }}
    </button>
    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
      <ng-container *ngFor="let tmplExportOption of tmplExportOptions">
        <ng-container
          *ngTemplateOutlet="
            dropdownItem;
            context: { tmplExportOption: tmplExportOption }
          "
        ></ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>
