import { Component, Input, Output, EventEmitter } from '@angular/core';

import { EventModel } from '../../../../../../shared/models/event.model';

@Component({
  selector: 'dependencies-modal',
  templateUrl: 'dependencies-modal.component.html',
})
export class DependenciesModalComponent {
  @Input() dependency: any;

  @Output() onEvent: EventEmitter<EventModel>;

  constructor() {
    this.dependency = null;
    this.onEvent = new EventEmitter<EventModel>();
  }
}
