<div class="modal-header">
  <div class="modal-title">
    {{ activity.__Id ? 'Activiteit bewerken ' : 'Activiteit toevoegen ' }}
    <span
      class="text-muted"
      *ngIf="
        activity &&
        activity.$nautical_ship_id &&
        activity.$nautical_portvisit_referencenumber
      "
      >({{ activity.$nautical_portvisit_referencenumber }})</span
    >
  </div>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="modal.dismiss()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form *ngIf="activity && !activity.$nautical_ship_id">
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label>Selecteer een schip:</label>
          <input
            id="typeahead-http"
            name="addActivityShipSearch"
            type="text"
            class="form-control"
            [class.is-invalid]="searchFailed"
            [(ngModel)]="tempShip"
            [ngbTypeahead]="searchShip"
            [inputFormatter]="displayShipFormatter"
            [resultFormatter]="searchShipFormatter"
            placeholder="Zoek een schip..."
            [editable]="false"
            (selectItem)="setShip($event)"
          />
          <sh-loading-indicator *ngIf="searching"></sh-loading-indicator>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="tempShip && tempShip.id">
      <div class="col">
        <div class="form-group">
          <label>Selecteer een reis:</label>
          <select
            *ngIf="shipVisits && shipVisits.length > 0"
            class="form-control"
            name="addActivitySelectVisit"
            [(ngModel)]="tempVisit"
          >
            <option [ngValue]="visit" *ngFor="let visit of shipVisits">
              {{ visitDisplay(visit) }}
            </option>
          </select>
          <div
            *ngIf="!loadingVisits && (!shipVisits || shipVisits.length === 0)"
          >
            Geen reizen gevonden
          </div>
          <sh-loading-indicator *ngIf="loadingVisits"></sh-loading-indicator>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col text-right">
        <button
          type="button"
          class="btn btn-primary"
          *ngIf="tempShip && tempShip.id"
          (click)="setShipAndVisit()"
        >
          Volgende
          <i class="fa-regular fa-chevron-right"></i>
        </button>
      </div>
    </div>
  </form>
  <form *ngIf="activity && activity.$nautical_ship_id">
    <div
      class="row"
      *ngIf="!tempShip && !activity.$nautical_portvisit_referencenumber"
    >
      <div class="col">
        <div class="form-group">
          <label>Selecteer een reis:</label>
          <select
            *ngIf="shipVisits && shipVisits.length > 0"
            class="form-control"
            name="addActivitySelectVisit"
            [(ngModel)]="tempVisit"
          >
            <option [ngValue]="visit" *ngFor="let visit of shipVisits">
              {{ visitDisplay(visit) }}
            </option>
          </select>
          <div
            *ngIf="!loadingVisits && (!shipVisits || shipVisits.length === 0)"
          >
            Geen reizen gevonden
          </div>
          <sh-loading-indicator *ngIf="loadingVisits"></sh-loading-indicator>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label for="addActivitySelectType">Type activiteit:</label>
          <select
            class="form-control"
            id="addActivitySelectType"
            [(ngModel)]="activity.activityType"
            name="addActivitySelectType"
          >
            <option
              [value]="activityType.id"
              *ngFor="let activityType of getActivityTypes()"
            >
              {{ activityType.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label for="addActivitySelectMovementType">Type reisdeel:</label>
          <select
            class="form-control"
            id="addActivitySelectMovementType"
            name="addActivitySelectMovementType"
            [(ngModel)]="activity.movementType"
          >
            <option
              [value]="movementType.id"
              *ngFor="let movementType of cvvActivityLogic.movementTypes"
              title="{{ movementType.name }}"
            >
              {{ movementType.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <!-- Members -->
        <div class="text-dark">{{ 'customUI.members' | translate }}:&nbsp;</div>

        <ng-container
          *ngIf="!loadMembersAnimation; then picker; else loading"
        ></ng-container>

        <ng-template #picker>
          <button
            (click)="
              onItemClicked.emit({
                type: 'activity',
                action: 'openMemberPicker',
                data: { activity: activity }
              })
            "
            type="button"
            class="btn btn-hover-light border w-100 mb-3 text-left"
          >
            <ng-container
              *ngIf="
                !activity.people || activity.people.length === 0;
                then noMembers;
                else members
              "
            >
            </ng-container>

            <ng-template #members>
              <div class="mx-n2 mt-n1 mb-n2">
                <div
                  *ngFor="let member of activity.people"
                  class="d-inline-flex dark-background text-white rounded p-1 mx-1 mb-1"
                >
                  <span *ngIf="member" class="text-nowrap">
                    {{ member | nameFromUserId | async }}
                  </span>
                </div>
              </div>
            </ng-template>

            <ng-template #noMembers>
              <span class="text-muted">{{
                'customUI.noMembers' | translate
              }}</span>
            </ng-template>
          </button>
        </ng-template>

        <ng-template #loading>
          <i class="fa-regular fa-spinner fa-2x fa-fw fa-spin"></i>
        </ng-template>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label for="addActivityDateStart">Start:</label>
          <div class="input-group">
            <input
              class="form-control"
              placeholder="dd-mm-jjjj uu:mm"
              id="addActivityDateStart"
              name="addActivityDateStart"
              [(ngModel)]="activity.startsOnDisplay"
              [ngModelOptions]="{ updateOn: 'blur' }"
            />
            <div class="input-group-append">
              <button
                class="btn btn-outline-secondary"
                (click)="setNow('startsOn')"
                type="button"
                title="Nu"
              >
                <i class="fa-regular fa-stopwatch"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label for="addActivityDateEnd">Einde:</label>
          <div class="input-group">
            <input
              class="form-control"
              placeholder="dd-mm-jjjj uu:mm"
              id="addActivityDateEnd"
              name="addActivityDateEnd"
              [(ngModel)]="activity.endsOnDisplay"
              [ngModelOptions]="{ updateOn: 'blur' }"
            />
            <button
              class="btn btn-outline-secondary"
              (click)="addMinutes(10, 'endsOnDisplay')"
              type="button"
              title="+ 10 min"
            >
              <span>+10</span>
            </button>
            <div class="input-group-append">
              <button
                class="btn btn-outline-secondary"
                (click)="setNow('endsOn')"
                type="button"
                title="Nu"
              >
                <i class="fa-regular fa-stopwatch"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label>Klanten:</label>
          <select
            class="form-control"
            [(ngModel)]="activity.$companies_company_id"
            name="addActivityCompanyId"
          >
            <option
              [value]="company.id"
              *ngFor="let company of cvvActivityLogic.companies"
              title="{{
                company.financialCount === 0 ? 'Geen debiteur nummer' : ''
              }}"
              [disabled]="company.financialCount === 0"
            >
              {{ company.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="isActivityType([4, 2, 1, 3])">
      <div class="col">
        <div class="form-group">
          <label for="addActivityBerthFrom"
            >Ligplaats <span *ngIf="isActivityType([1])">(start)</span>:</label
          >
          <select
            class="form-control"
            [(ngModel)]="activity.berth1"
            *ngIf="isActivityType([3])"
            name="addActivityBerth1Lock"
          >
            <option value="middensluis">Middensluis</option>
            <option value="noordersluis">Noordersluis</option>
            <option value="zeesluis">Zeesluis IJmuiden</option>
            <option value="zuidersluis">Zuidersluis</option>
          </select>
          <select
            class="form-control"
            [(ngModel)]="activity.berth1"
            name="addActivityBerth1"
            *ngIf="isActivityType([4, 2, 1])"
          >
            <option
              [value]="berth.code"
              *ngFor="let berth of cvvActivityLogic.berths"
            >
              {{ berth.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-6" *ngIf="isActivityType([1])">
        <div class="form-group">
          <label for="addActivityBerthFrom">Ligplaats (einde):</label>
          <select
            class="form-control"
            [(ngModel)]="activity.berth2"
            name="addActivityBerth2"
          >
            <option
              [value]="berth.code"
              *ngFor="let berth of cvvActivityLogic.berths"
            >
              {{ berth.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="isActivityType([4, 2, 1, 3])">
      <div class="col-12">
        <h4>Extra gegevens</h4>
      </div>
      <!-- <div class="col-4">
        <div class="form-group">
          <label>Motorboten:</label>
          <input type="text" class="form-control" value="0" [(ngModel)]="activity.motorboat"
            name="addActivityMotorboat" />
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <label>Winchwagens:</label>
          <div class="row">
            <div class="col pr-0"><input type="text" class="form-control" value="0" title="Normaal"
                name="addActivityWinchMiddle" [(ngModel)]="activity.winchMiddle" /></div>
            <div class="col p-0"><input type="text" class="form-control" value="0" title="Voor"
                name="addActivityWinchFront" [(ngModel)]="activity.winchFront" /></div>
            <div class="col pl-0 pr-3"><input type="text" class="form-control" value="0" title="Achter"
                name="addActivityWinchAft" [(ngModel)]="activity.winchAft" /></div>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <label>Personen:</label>
          <div class="row">
            <div class="col pr-0"><input type="text" class="form-control" value="0" title="Normaal"
                name="addActivityPersonsMiddle" [(ngModel)]="activity.personsMiddle" /></div>
            <div class="col p-0"><input type="text" class="form-control" value="0" title="Voor"
                name="addActivityPersonsFront" [(ngModel)]="activity.personsFront" /></div>
            <div class="col pl-0 pr-3"><input type="text" class="form-control" value="0" title="Achter"
                name="addActivityPersonsAft" [(ngModel)]="activity.personsAft" /></div>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="input-group">
          <div class="input-group-prepend">
            <div class="input-group-text">
              <input type="checkbox" id="addActivityHeavyMaterial" aria-label="Checkbox for following text input"
                name="addActivityHeavyMaterial" [(ngModel)]="activity.heavyMaterial">
            </div>
          </div>
          <label class="form-control" for="addActivityHeavyMaterial" title="Zwaar materieel">
            Zwaar mat.
          </label>
        </div>
      </div> -->
      <div class="col-6">
        <div class="input-group form-group">
          <div class="input-group-prepend">
            <div class="input-group-text">
              <input
                type="checkbox"
                id="addActivityPilot"
                aria-label="Checkbox for following text input"
                name="addActivityPilot"
                [(ngModel)]="activity.pilot"
              />
            </div>
          </div>
          <label
            class="form-control"
            for="addActivityPilot"
            title="Loods aan boord"
          >
            Loods a/b
          </label>
        </div>
      </div>
      <div class="col-6">
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            value="0"
            name="addActivityReceipts"
            [(ngModel)]="activity.receipts"
          />
          <div class="input-group-append">
            <span class="input-group-text" id="basic-addon1">bonnen</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="isActivityType([5])">
      <div class="col-6">
        <div class="form-group">
          <label for="addActivityGetOnFromRunners">Opgestapt op:</label>
          <select
            class="form-control"
            [(ngModel)]="activity.getOn"
            name="addActivityGetOn"
          >
            <option
              [value]="berth.code"
              *ngFor="let berth of cvvActivityLogic.berths"
            >
              {{ berth.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label for="addActivityGetOffFromRunners">Afgestapt op:</label>
          <select
            class="form-control"
            [(ngModel)]="activity.getOff"
            name="addActivityGetOff"
          >
            <option
              [value]="berth.code"
              *ngFor="let berth of cvvActivityLogic.berths"
            >
              {{ berth.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label>Aantal personen:</label>
          <input
            type="text"
            class="form-control"
            value="0"
            [(ngModel)]="activity.numberOfRunners"
            name="addActivityNumberOfRunners"
          />
        </div>
      </div>
    </div>
    <div class="row" *ngIf="isActivityType([6])">
      <div class="col-6">
        <div class="form-group">
          <label for="addActivitySelectTenderType">Type tender:</label>
          <select
            class="form-control"
            id="addActivitySelectTenderType"
            [(ngModel)]="activity.tenderType"
            name="addActivitySelectTenderType"
          >
            <option
              [value]="tenderType.id"
              *ngFor="let tenderType of cvvActivityLogic.tenderTypes"
            >
              {{ tenderType.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label>&nbsp;</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <input
                  type="checkbox"
                  aria-label="Checkbox for following text input"
                />
              </div>
            </div>
            <span class="form-control">Toev. aan kranenlijst</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="isActivityType([6])">
      <div class="col-6">
        <div class="form-group">
          <label for="addActivityPierOutbound">Buiten Pieren:</label>
          <div class="input-group">
            <input
              class="form-control"
              placeholder="dd-mm-jjjj uu:mm"
              id="addActivityPierOutbound"
              name="addActivityPierOutbound"
              [(ngModel)]="activity.pierOutboundDisplay"
              [ngModelOptions]="{ updateOn: 'blur' }"
            />
            <div class="input-group-append">
              <button
                class="btn btn-outline-secondary"
                (click)="setNow('pierOutbound')"
                type="button"
                title="Nu"
              >
                <i class="fa-regular fa-stopwatch"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label for="addActivityPierInbound">Binnen Pieren:</label>
          <div class="input-group">
            <input
              class="form-control"
              placeholder="dd-mm-jjjj uu:mm"
              id="addActivityPierInbound"
              name="addActivityPierInbound"
              [(ngModel)]="activity.pierInboundDisplay"
              [ngModelOptions]="{ updateOn: 'blur' }"
            />
            <button
              class="btn btn-outline-secondary"
              (click)="addMinutes(10, 'pierInboundDisplay')"
              type="button"
              title="+ 10 min"
            >
              <span>+10</span>
            </button>
            <div class="input-group-append">
              <button
                class="btn btn-outline-secondary"
                (click)="setNow('pierInbound')"
                type="button"
                title="Nu"
              >
                <i class="fa-regular fa-stopwatch"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h4>Opmerkingen</h4>
        <div class="form-group">
          <textarea
            rows="3"
            class="form-control"
            name="addActivityRemarks"
            [(ngModel)]="activity.remarks"
          ></textarea>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="errors && errors.length > 0">
      <div class="col-12">
        <div class="alert alert-danger">
          <p>De volgende velden zijn fout:</p>
          <ul>
            <li *ngFor="let error of errors">{{ error }}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="d-flex">
      <div class="flex-grow-1">
        <button
          type="button"
          class="btn btn-block btn-link"
          (click)="dismiss(null)"
        >
          Annuleren
        </button>
      </div>
      <div class="flex-grow-1">
        <button
          type="button"
          class="btn btn-block btn-success"
          (click)="save()"
        >
          Opslaan
        </button>
      </div>
    </div>
  </form>
</div>
