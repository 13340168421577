import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EventModel } from '../../../../../shared/models/event.model';
import { QueryModel } from '../../../../../shared/models/query.model';
import { ViewModel } from '../../../../../shared/models/view.model';

@Component({
  selector: 'ca-task-overview-header',
  templateUrl: 'task-overview-header.component.html',
})
export class TaskOverviewHeaderComponent {
  @Input() loading: boolean;
  @Input() views: ViewModel[];
  @Input() view: ViewModel['value'];
  @Input() search: QueryModel['search'];
  @Input() active: boolean;
  @Input() expired: boolean;
  @Input() assignedTo: string;
  @Input() count: number;
  @Input() groupBy: boolean;

  @Output() onEvent: EventEmitter<EventModel>;

  expanded: boolean;

  constructor() {
    this.loading = false;
    this.views = [];
    this.view = null;
    this.search = null;
    this.active = false;
    this.count = 0;
    this.onEvent = new EventEmitter<EventModel>();
    this.expanded = false;
    this.groupBy = false;
  }

  toggleExpand() {
    this.expanded = !this.expanded;
  }

  action(event: EventModel) {
    this.onEvent.emit(event);
  }
}
