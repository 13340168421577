import { Directive, Input } from '@angular/core';
import {
  NG_VALIDATORS,
  Validator,
  UntypedFormGroup,
  ValidationErrors,
} from '@angular/forms';

@Directive({
  // eslint-disable-next-line
  selector: 'form[mustMatch]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: MustMatchDirective, multi: true },
  ],
})
export class MustMatchDirective implements Validator {
  @Input() mustMatch: string[] = [];

  validate(formGroup: UntypedFormGroup): ValidationErrors {
    return this.checkValues(this.mustMatch[0], this.mustMatch[1])(formGroup);
  }

  private checkValues(controlName: string, matchingControlName: string) {
    return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (!control || !matchingControl) {
        return null;
      }

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return null;
      }

      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }
}
