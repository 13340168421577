import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ResultWrapper } from '@seahorse/common';
import { CustomTeamModel } from '../models/custom-team.model';
import { CustomDataContentService } from '@seahorse/domain';

@Injectable()
export class CustomTeamDataService {
  constructor(private customDataContentService: CustomDataContentService) {}

  get(): Observable<ResultWrapper<any>> {
    return this.customDataContentService.getAllCustomerData('membergroup');
  }

  add(team: CustomTeamModel): Observable<ResultWrapper<any>> {
    return this.customDataContentService.addCustomerData('membergroup', team);
  }

  update(team: CustomTeamModel): Observable<ResultWrapper<any>> {
    return this.customDataContentService.updateCustomerData(
      'membergroup',
      team.__Id,
      team
    );
  }

  delete(id: CustomTeamModel['__Id']): Observable<ResultWrapper<any>> {
    return this.customDataContentService.deleteCustomerData('membergroup', id);
  }
}
