import {
  NauticalShipTypeCategory,
} from './nautical-ship.model';

export class ShipSearchModel {
  name: string;
  callSign: string;
  eni: string;
  imo: string;
  fisheryNumber: string;
  flagCode: string;
  mmsi: string;
  shipTypeCategories: NauticalShipTypeCategory[];
  shipTags: string;
  fromLOA: number;
  toLOA: number;
  fromGT: number;
  toGT: number;
  fromDWT: number;
  toDWT: number;

  constructor(name: string) {
    this.name = name;
  }
}
