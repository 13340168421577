import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SystemMessageModel } from '../../../../preference/models/system-message.model';

@Component({
  selector: 'ca-system-message-list',
  templateUrl: './system-message-list.component.html',
})
export class SystemMessageListComponent {
  @Input() systemMessages: SystemMessageModel[];
  @Output() systemMessageSelected = new EventEmitter<SystemMessageModel>();
  selectedSystemMessage: SystemMessageModel;

  selectSystemMessage(message: SystemMessageModel) {
    this.selectedSystemMessage = message;
    this.systemMessageSelected.emit(message);
  }
}
