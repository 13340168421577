import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NauticalVisitModel } from '@seahorse/domain';
import { NotificationService } from '@seahorse/common';
import { TranslateService } from '@ngx-translate/core';

import * as _ from 'underscore';
import * as moment from 'moment';

@Component({
  selector: 'ca-nautical-visit-list',
  templateUrl: './nautical-visit-list.component.html',
})
export class NauticalVisitListComponent {
  private _visits: NauticalVisitModel[] = [];
  
  @Input() selectedVisit: NauticalVisitModel = null;
  @Input() displayFields: string[] = [];
  @Input() isClickable = true;
  @Input() isCopyable = false;
  @Input() positions = {};
  @Input() get visits(): NauticalVisitModel[] {
    return this._visits;
  }
  set visits(visits: NauticalVisitModel[]) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this._visits = _.map(visits, (v: any) => {
      if (!v.atd && !v.ata && v.eta && v.etd) {
        v.portStay = this.portStay(v.etd, v.eta);
      } else if (!v.atd && v.ata && v.etd) {
        v.portStay = this.portStay(v.etd, v.ata);
      } else if (v.atd && v.ata) {
        v.portStay = this.portStay(v.atd, v.ata);
      } else {
        v.portStay = null;
      }

      return v;
    });
  }

  @Output() visitSelected = new EventEmitter<NauticalVisitModel>();

  constructor(
    private notificationService: NotificationService,
    private translateService: TranslateService
  ) {}

  copy(sourceText) {
    // Let's pull this into something more generic later
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = sourceText;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.notificationService.showInfo(
      this.translateService.instant('shared.terms.copied'),
      null
    );
  }

  portStay(maxDate: Date, minDate: Date): string {
    let totalMinutes = moment(maxDate).diff(minDate, 'minutes');
    if (totalMinutes <= 0) {
      return null;
    }

    const days = Math.floor(totalMinutes / 60 / 24);
    totalMinutes -= days * 24 * 60;
    const hours = Math.floor(totalMinutes / 60);
    totalMinutes -= hours * 60;

    return (
      (days > 0 ? days + 'd ' : '') +
      (days > 0 || hours > 0 ? hours + 'h ' : '') +
      totalMinutes +
      'm'
    );
  }

  showColumn(columnName: string, explicit: boolean = false) {
    return (
      (!explicit && this.displayFields.length === 0) ||
      this.displayFields.indexOf(columnName) > -1
    );
  }

  selectVisit(visit: NauticalVisitModel) {
    if (!this.isClickable) {
      return;
    }

    this.selectedVisit = visit;
    this.visitSelected.emit(visit);
  }
}
