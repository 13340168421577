import { Component, OnInit } from '@angular/core';
import { PreloaderService } from '../../../core/services/preloader.service';
import { LayoutDisplayService } from '../../../layout/services/layout-display.service';

@Component({
  selector: 'ca-default-theme',
  templateUrl: './default-theme.component.html',
  styleUrls: ['./default-theme.component.scss'],
})
export class DefaultThemeComponent implements OnInit {
  toggleSidebar: boolean;

  status = {
    isLoading: true,
    preloaded: false,
  };

  constructor(
    public layoutDisplay: LayoutDisplayService,
    private preloader: PreloaderService
  ) {}

  ngOnInit() {
    this.preloader.preload().subscribe((response) => {
      this.status.isLoading = false;
      this.status.preloaded = true;
    });
  }

  onSidebarToggle(event: boolean) {
    this.toggleSidebar = event;
  }
}
