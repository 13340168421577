<div class="card" *ngIf="task">
  <div class="card-header">
    <button
      (click)="event.emit({ action: 'select', data: null })"
      type="button"
      class="btn btn-clear float-right"
    >
      <i class="fa-solid fa-times fa-fw"></i>
    </button>
    <span class="text-uppercase">{{ 'tasks.task.task' | translate }}</span>
  </div>
  <div class="card-body">
    <!-- <button *ngIf="task.createdFromTaskId" (click)="event.emit({ action: 'select', data: task.createdFromTaskId })"
      type="button" class="btn btn-link p-0 border-0 mb-2">
      <i class="fa-solid fa-long-arrow-alt-left fa-fw mr-1">
      </i>{{ 'tasks.task.viewPrevious' | translate }}
    </button> -->

    <!-- Removed for now, requires new implementation -->
    <!-- <div class="mx-n4 mb-3">
      <table class="table table-sm table-clickable">
        <thead>
          <tr>
            <th scope="col">
              {{ 'tasks.task.model.followUpTasks' | translate }}
            </th>
          </tr>
        </thead>
        <ng-container
          *ngIf="loading; then showLoading; else noLoading"
        ></ng-container>
        <ng-template #noLoading>
          <ng-container
            *ngIf="followUpCount; then showFollowUp; else noFollowUp"
          ></ng-container>
          <ng-template #showFollowUp>
            <ng-container>
              <tbody *ngFor="let followUp of task.followUpTasks">
                <tr (click)="event.emit({ action: 'select', data: followUp })">
                  <td>{{ followUp.subject }}</td>
                </tr>
              </tbody>
            </ng-container>
          </ng-template>
          <ng-template #noFollowUp>
            <ng-container>
              <div class="text-muted text-center">
                {{ 'tasks.task.model.noFollowUpTasks' | translate }}
              </div>
            </ng-container>
          </ng-template>
        </ng-template>
        <ng-template #showLoading>
          <sh-loading-indicator class="d-flex loader-sm justify-content-center">
          </sh-loading-indicator>
        </ng-template>
      </table>
    </div> -->
    <ca-task-form [task]="task" [loading]="loading"></ca-task-form>

    <form [formGroup]="form">
      <div class="form-group">
        <label>
          <b>{{ 'taskGroup.display' | translate }}</b>
        </label>
        <sh-combo-box
          formControlName="selectedFormGroupId"
          [options]="taskGroupOptions"
          [newItemConfig]="newTaskGroupConfig"
          [isLoading]="isLoadingTaskGroupOptions"
        ></sh-combo-box>
      </div>
    </form>
  </div>
  <div *ngIf="task.objectId && task.objectType">
    <ul class="summary-buttons">
      <li *ngIf="task.objectType.toLowerCase().includes('company')">
        <a routerLink="/companies/{{ task.objectId }}" class="summary-button">
          <i class="fa-regular fa-address-book fa-fw"></i>
          <span class="summary-button-text">
            {{ 'companies.company.company' | translate }}
          </span>
        </a>
      </li>
      <li *ngIf="task.objectType.toLowerCase().includes('portcallfile')">
        <a
          [routerLink]="
            task.objectType.includes('portCallFile')
              ? '/port-call-file/' + task.objectId
              : '/module/portcallfile/details/' + task.objectId
          "
          class="summary-button"
        >
          <i class="fa-regular fa-file-alt fa-fw"></i>
          <span class="summary-button-text">
            {{ 'layout.sidebar.portCallFile' | translate }}
          </span>
        </a>
      </li>
    </ul>
  </div>

  <ul class="summary-buttons">
    <!-- <li>
      <button
        [disabled]="loading"
        (click)="event.emit({ action: 'add', data: task })"
        type="button"
        class="btn btn-info w-100"
      >
        <span class="summary-button-text">{{
          'tasks.task.followUp' | translate
        }}</span>
      </button>
    </li> -->
    <li>
      <button
        [disabled]="loading"
        (click)="event.emit({ action: 'delete', data: task })"
        type="button"
        class="btn btn-danger w-100"
      >
        <span class="summary-button-text">{{
          'shared.terms.delete' | translate
        }}</span>
      </button>
    </li>
    <li>
      <button
        [disabled]="loading"
        (click)="save()"
        type="button"
        class="btn btn-success w-100"
      >
        <span class="summary-button-text">{{
          'shared.terms.save' | translate
        }}</span>
      </button>
    </li>
  </ul>

  <div [hidden]="loading">
    <temp-file-list-card [objectType]="taskObjectType" [objectId]="task.id">
    </temp-file-list-card>
  </div>
</div>
