import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'invoiceStatus',
})
export class InvoiceStatusPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: number): string {
    switch (value) {
      case 1:
        return this.translate.instant(
          'invoicing.invoiceModel.invoiceStatuses.inProgress'
        );
      case 2:
        return this.translate.instant(
          'invoicing.invoiceModel.invoiceStatuses.pendingApproval'
        );
      case 3:
        return this.translate.instant(
          'invoicing.invoiceModel.invoiceStatuses.open'
        );
      case 4:
        return this.translate.instant(
          'invoicing.invoiceModel.invoiceStatuses.firstReminder'
        );
      case 5:
        return this.translate.instant(
          'invoicing.invoiceModel.invoiceStatuses.paid'
        );
      case 6:
        return this.translate.instant(
          'invoicing.invoiceModel.invoiceStatuses.cancelled'
        );
      case 7:
        return this.translate.instant(
          'invoicing.invoiceModel.invoiceStatuses.exported'
        );
      case 8:
        return this.translate.instant(
          'invoicing.invoiceModel.invoiceStatuses.pendingVerification'
        );
      default:
        return this.translate.instant(
          'invocing.invoiceModel.invoiceStatuses.unknown'
        );
    }
  }
}
