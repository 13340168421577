<div class="card">
  <div class="card-header">
    {{ 'files.file.files' | translate }}
    <div class="btn-list">
      <button
        class="btn btn-outline-secondary btn-sm"
        type="button"
        (click)="add()"
      >
        <i class="fa-regular fa-plus fa-fw"></i>
        {{ 'shared.terms.add' | translate }}
      </button>
    </div>
  </div>

  <div class="card-body card-body-table" *ngIf="files && files.length > 0">
    <ca-nautical-ship-files-list
      [shipId]="shipId"
      [files]="files"
    ></ca-nautical-ship-files-list>
  </div>
  <div class="card-body" *ngIf="!files || files.length === 0">
    <div class="text-muted text-center">
      {{ 'files.file.noFiles' | translate }}
    </div>
  </div>
  <div
    class="card-footer card-footer-clickable"
    (click)="loadMore()"
    *ngIf="canLoadMore"
  >
    <span> {{ 'shared.terms.loadMore' | translate }}</span>
  </div>
</div>
