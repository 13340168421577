import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { DataContextService } from '@seahorse/common';
import { ResultWrapper } from '@seahorse/common';
import { FieldTypeDefinitionModel } from '@seahorse/domain';

@Injectable()
export class FieldTypeDefinitionsDataService {
  constructor(private dataContextService: DataContextService) {}

  get(): Observable<ResultWrapper<FieldTypeDefinitionModel[]>> {
    return this.dataContextService.get<FieldTypeDefinitionModel[]>(
      `customdata/fieldtypedefinitions`
    );
  }
}
