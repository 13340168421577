import { Component, Input, Output, EventEmitter } from '@angular/core';

import { EventModel } from '../../../../../../shared/models/event.model';

@Component({
  selector: 'stage-creator-header',
  templateUrl: 'stage-creator-header.component.html',
})
export class StageCreatorHeaderComponent {
  @Input() loadingStage: boolean;

  @Output() onEvent: EventEmitter<EventModel>;

  constructor() {
    this.loadingStage = false;
    this.onEvent = new EventEmitter<EventModel>();
  }
}
