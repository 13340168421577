import { Pipe, PipeTransform } from '@angular/core';
import { FieldStatusModel } from '@seahorse/domain';

@Pipe({
  name: 'tempStatusProgress',
  standalone: true,
})
export class StatusProgressPipe implements PipeTransform {
  transform(input: FieldStatusModel, mode: string = null): string {
    // mode:
    if (input) {
      const progress = !input.Progress ? 0 : input.Progress;
      const total = !input.Total ? 0 : input.Total;

      mode = mode === undefined || mode === null ? '' : mode.toString();
      switch (mode.toLowerCase()) {
        case 'fraction':
          return `${this.getProgress(input, true)}`; // e.g. 0.33

        case 'percentage':
          return `${this.getProgress(input, false)}%`; // e.g. 33.33%

        default: // as plain text
          return `${progress}/${total}`; // e.g. 1/3
      }
    }
    return null;
  }

  private getProgress(statusModel, isFraction = true) {
    let result = 0;
    if (statusModel && statusModel.Total > 0) {
      result =
        (!statusModel.Progress ? 0 : statusModel.Progress) / statusModel.Total;
    }

    return (isFraction === true ? result : result * 100).toFixed(2);
  }
}
