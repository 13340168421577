import { BaseModel } from '@seahorse/common';
import { ValidationResult } from '@seahorse/common';
import { TaskDefinitionModel } from './task-definition.model';
import { TaskGroupDefinitionModel } from './task-group-definition.model';

export class TaskDefinitionListModel extends BaseModel {
  id = 0;
  title = '';
  taskGroupDefinitions = new Array<TaskGroupDefinitionModel>();
  ungroupedTaskDefinitions = new Array<TaskDefinitionModel>();
  objectType: string | null = null;

  constructor() {
    super();
  }

  validate(errorPrefix?: string): ValidationResult {
    return new ValidationResult(errorPrefix === null);
  }
}
