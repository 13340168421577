import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CustomDataServicesModule } from '../../../custom-data/services/custom-data-services.module';
import { LayoutComponentsModule } from '../../../layout/components/layout-components.module';
import { NauticalVisitComponentsModule } from '../../../nautical-visit/components/nautical-visit-components.module';
import { SharedModule } from '../../../shared/shared.module';

import { PortOfAmsterdamZHGPage } from './zhg/zhg.page';

@NgModule({
  declarations: [PortOfAmsterdamZHGPage],
  imports: [
    CommonModule,
    CustomDataServicesModule,
    FormsModule,
    LayoutComponentsModule,
    NauticalVisitComponentsModule,
    SharedModule,
    TranslateModule,
  ],
})
export class CustomPortOfAmsterdamPagesModule {}
