<div class="modal-header align-items-center">
  <h1 class="modal-title">{{ title }}</h1>
  <button closeModal type="button" class="btn btn-link text-dark">
    <i class="fa-solid fa-times fa-sm fa-fw"></i>
  </button>
</div>
<div class="modal-body">
  <div
    *ngIf="parts && parts.length"
    class="tablist tablist-accordion mb-3"
    id="partPicker"
    role="tablist"
    aria-multiselectable="false"
  >
    <div
      *ngFor="let part of parts; let i = index"
      [id]="'sectionHeaderId_' + i"
      class="tablist-item"
    >
      <a
        [id]="'sectionLinkId_' + i"
        [href]="'#sectionContentId_' + i"
        [attr.aria-controls]="'sectionContentId_' + i"
        class="tablist-header"
        data-toggle="collapse"
        aria-expanded="false"
      >
        <div class="dropdown float-right">
          <button
            [id]="'rowMenuButton_' + i"
            type="button"
            class="btn btn-light dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis-v fa-fw"></i>
          </button>
          <div
            [attr.aria-labelledby]="'rowMenuButton_' + i"
            class="btn dropdown-menu"
          >
            <button (click)="removePart(part)" class="dropdown-item">
              <i class="fa-solid fa-trash fa-fw"></i>
              {{ 'shared.terms.delete' | translate }}
            </button>
          </div>
        </div>
        {{ part.name ? part.name : ('shared.terms.unknown' | translate) }}
      </a>
      <div
        [id]="'sectionContentId_' + i"
        [attr.aria-labelledby]="'sectionHeaderId_' + i"
        class="collapse"
        data-parent="#partPicker"
      >
        <div class="form-row p-3">
          <div class="form-group col-12">
            <label class="d-flex justify-content-between" [for]="'name_' + i">
              <b class="required">{{
                'customModules.orderRegistrations.part.model.name' | translate
              }}</b>
            </label>
            <div class="input-group">
              <input
                [(ngModel)]="part.name"
                [id]="'name_' + i"
                [name]="'name_' + i"
                [disabled]="partsLoading"
                [placeholder]="
                  'customModules.orderRegistrations.part.placeholder.name'
                    | translate
                "
                required
                type="text"
                class="form-control"
              />
            </div>
          </div>
          <div class="form-group col-12">
            <label
              class="d-flex justify-content-between"
              [for]="'quantity_' + i"
            >
              <b class="required">{{
                'customModules.orderRegistrations.part.model.quantity'
                  | translate
              }}</b>
            </label>
            <div class="input-group">
              <input
                [(ngModel)]="part.quantity"
                [id]="'quantity_' + i"
                [name]="'quantity_' + i"
                [disabled]="partsLoading"
                [placeholder]="
                  'customModules.orderRegistrations.part.placeholder.quantity'
                    | translate
                "
                required
                type="number"
                min="1"
                class="form-control"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mb-3 text-right">
    <button (click)="addPart()" type="button" class="btn btn-outline-secondary">
      <i class="fa-regular fa-plus fa-fw"></i>
      {{ 'customModules.orderRegistrations.part.addPart' | translate }}
    </button>
  </div>
  <div class="mb-3 text-danger" *ngIf="errorList && errorList.length">
    <ul style="list-style-type: square">
      <li *ngFor="let error of errorList">{{ error }}</li>
    </ul>
  </div>
</div>
<div class="modal-footer">
  <button closeModal type="button" class="btn btn-link flex-fill">
    {{ 'shared.terms.cancel' | translate }}
  </button>
  <button
    [disabled]="partsLoading"
    (click)="confirm()"
    type="button"
    class="btn btn-success flex-fill"
  >
    <span *ngIf="partsLoading">
      <i class="fa-regular fa-spinner fa-spin fa-fw"></i>
    </span>
    <ng-container *ngIf="!partsLoading">
      {{ 'shared.terms.save' | translate }}
    </ng-container>
  </button>
</div>
