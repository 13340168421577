import * as _ from 'underscore';
import { BaseModel } from '@seahorse/common';
import { OrderRegistrationModel } from '../../order-registrations/models/order-registration.model';
import { TariffAmountTypes, TariffTypes } from '../../invoicing-and-tariffs/tariffs/models/tariff.model';
import { CustomDataBaseModel } from '../../custom-data/models/custom-data-base.model';

export class ExpenseModel extends CustomDataBaseModel {
  amount: number;
  quantity: number;
  isBudget: boolean;
  currency: string[];
  charged: boolean;
  description?: string;
  remarks?: string;
  amountActual?: number;
  commissionFee?: number;
  payByDate?: Date;
  tariffType: TariffTypes;
  commissionFeeType?: TariffAmountTypes;

  orderRegistration?: OrderRegistrationModel['__Id'];
  portCallFile?: CustomDataBaseModel['__Id'];
  customer?: number;
  supplier?: number;
  tariff?: BaseModel['id'];
  po?: string;

  constructor(override?: Partial<ExpenseModel>) {
    super();

    this.__DataObjectKey = 'expense';

    this.amount = 0;
    this.quantity = 1;
    this.isBudget = false;
    this.currency = [];
    this.charged = false;
    this.description = null;
    this.remarks = null;
    this.amountActual = 0;
    this.commissionFee = 0;
    this.orderRegistration = null;
    this.portCallFile = null;
    this.customer = null;
    this.supplier = null;
    this.tariff = null;
    this.tariffType = TariffTypes.Regular;
    this.commissionFeeType = TariffAmountTypes.Percentage;

    if (override) {
      const candidateModel: ExpenseModel = Object.assign(
        new ExpenseModel(),
        override
      );

      Object.assign(this, _.pick(candidateModel, _.identity));
    }
  }
}
