/* eslint-disable no-case-declarations */
/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  NgbModal,
  NgbModalOptions,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { merge, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import * as _ from 'underscore';

import { KeyValuePair, NotificationService, PipeModel } from '@seahorse/common';
import {
  CompanyModel,
  CustomDataAdditionalDataModel,
  CustomDataBaseModel,
  FieldDefinitionModel,
  FieldType,
  FieldTypeDefinitionModel,
  NauticalShipModel,
  ObjectKeyMappingModel,
} from '@seahorse/domain';
import {
  EditAttributeModal,
  FieldAttributeModel,
  ModalService,
} from '@seahorse/temp';
import { CompanyPickerDialogComponent } from '../../../company/components/company-picker-dialog/company-picker-dialog.component';
import { ProxyServices } from '../../../core/services/proxy.service';
import { CustomContentPickerModalComponent } from '../../../custom-content/components/custom-content-picker-modal/custom-content-picker-modal.component';
import { CustomValueDisplayPipe } from '../../../custom-content/pipes/custom-value-display.pipe';
import { RenderOptionModel } from '../../../layout/models/table.model';
import { NauticalShipPickerDialogComponent } from '../../../nautical-ship/components/nautical-ship-picker-dialog/nautical-ship-picker-dialog.component';
import { CrewFactoryDialogComponent } from '../../../order-registrations/components/crew-factory-dialog-component/crew-factory-dialog.component';
import { HotelStayDialogComponent } from '../../../order-registrations/components/hotel-stay-dialog-component/hotel-stay-dialog.component';
import { PartDialogComponent } from '../../../order-registrations/components/part-dialog-component/part-dialog.component';
import { OrderRegistrationCrewMemberModel } from '../../../order-registrations/models/order-registration-crew-member.model';
import { OrderRegistrationHotelStayModel } from '../../../order-registrations/models/order-registration-hotel-stay.model';
import { OrderRegistrationPartModel } from '../../../order-registrations/models/order-registration-part.model';
import { UserPickerModalComponent } from '../../../user/components/user-picker-modal/user-picker-modal.component';
import { CompanyFormComponent } from '../../../company/components/company/company.component';
import { OrderRegistrationsConstant } from '../../../order-registrations/constants/order-registrations.constant';

@Component({
  selector: 'ca-custom-content-edit-value',
  templateUrl: './custom-content-edit-value.component.html',
})
export class CustomContentEditValueComponent implements OnInit {
  private _entry: CustomDataBaseModel = null;

  @Input() title?: string;

  @Input() set entry(entry: CustomDataBaseModel) {
    if (!entry) {
      this._entry = new CustomDataBaseModel();
    } else {
      this._entry = entry;
    }

    if (!this._entry.__AdditionalData) {
      this._entry.__AdditionalData = {};
    }

    this.setCurrentValue();
    this.setAdditionalDataModel();
  }
  get entry(): CustomDataBaseModel {
    return this._entry;
  }

  @Input() fieldDefinition: FieldDefinitionModel;
  @Input() isReadOnly: boolean;
  @Input() inlineEdit: boolean;
  @Input() renderOption: RenderOptionModel;

  @Input() submitted = false;
  @Input() orderRegistrationRequiredFields = false;

  @Output() onFieldChanged: EventEmitter<unknown>;

  currentValue = null;
  fieldTypeDefinitions: FieldTypeDefinitionModel[] = [];
  fieldTypeDefinition: FieldTypeDefinitionModel = null;
  fieldType = FieldType;
  focusItem = new Subject<string>();
  tagFilter = null;

  private additionalDataModel: CustomDataAdditionalDataModel = null;
  private searchTerm: string = null;

  private set company(value: CompanyModel | null) {
    if (value) {
      if (value.id !== this.entry[this.fieldDefinition.systemCode]) {
        this.entry[this.fieldDefinition.systemCode] = value.id;
        this.currentValue = this.resolveValue(value);
        this.entry.__AdditionalData[this.fieldDefinition.systemCode] = value;
        this.onFieldChanged.emit({
          field: this.fieldDefinition,
          value: this.entry[this.fieldDefinition.systemCode],
        });
      }
    } else if (this.entry[this.fieldDefinition.systemCode]) {
      this.entry[this.fieldDefinition.systemCode] = null;
      this.currentValue = null;
      this.entry.__AdditionalData[this.fieldDefinition.systemCode] = null;
      this.onFieldChanged.emit({
        field: this.fieldDefinition,
        value: this.entry[this.fieldDefinition.systemCode],
      });
    }
  }

  private get company(): CompanyModel | null {
    let company: CompanyModel | null = null;
    if (this.entry[this.fieldDefinition.systemCode]) {
      if (
        this.entry.__AdditionalData &&
        this.entry.__AdditionalData[this.fieldDefinition.systemCode]
      ) {
        company = this.entry.__AdditionalData[this.fieldDefinition.systemCode];
      } else {
        company = new CompanyModel();
      }
      company.id = this.entry[this.fieldDefinition.systemCode];
    }
    return company;
  }

  private subscriptions$ = new Subscription();

  constructor(
    private customValueDisplayPipe: CustomValueDisplayPipe,
    private proxyServices: ProxyServices,
    private modal: NgbModal,
    private notificationService: NotificationService,
    private translate: TranslateService,
    private _modalService: ModalService
  ) {
    this.title = null;
    this.fieldTypeDefinitions = this.proxyServices.fieldTypeDefinitions;
    this.onFieldChanged = new EventEmitter<unknown>();
  }

  ngOnInit(): void {
    this.setAdditionalDataModel();

    if (!this.additionalDataModel) {
      this.additionalDataModel = new CustomDataAdditionalDataModel();
    }

    if (this.additionalDataModel?.tag) {
      this.tagFilter = this.additionalDataModel.tag;
    }

    if (this.fieldDefinition) {
      if (
        this.fieldDefinition.fieldType === FieldType.LinkedObject ||
        this.fieldDefinition.fieldType === FieldType.LinkedObjects
      ) {
        let pipes = [];
        let addPipe = false;
        if (
          !this.renderOption ||
          !this.renderOption.pipes ||
          this.renderOption.pipes.length === 0
        ) {
          addPipe = true; // no render option
        } else {
          pipes = this.renderOption.pipes;
          const index = _.findIndex(
            this.renderOption.pipes,
            (p) => p.name === 'keyValue'
          );
          if (index === -1) {
            addPipe = true; // no keyValuePipe
          } else {
            if (!this.renderOption.pipes[index].params) {
              this.renderOption.pipes[index].params = {};
            }

            // keyValuePair exists but displayFields is not set
            if (
              this.renderOption.pipes[index].params['displayFields'] ===
              undefined
            ) {
              this.renderOption.pipes[index].params['displayFields'] =
                !this.additionalDataModel.displayFields ||
                this.additionalDataModel.displayFields.length === 0
                  ? null // show all properties
                  : this.additionalDataModel.displayFields;
            }
          }
        }

        if (addPipe === true) {
          let foundPipes = null;

          // dirty fix for old code, field system code startswith $ e.g $nautical_ship_id
          let key = null;
          if (this.entry.__AdditionalData) {
            const keys = _.keys(this.entry.__AdditionalData);
            key = _.find(
              keys,
              (k) =>
                k.toLowerCase() ===
                this.fieldDefinition.systemCode.toLowerCase()
            );
          }

          if (key) {
            const mapping = this.proxyServices.objectKeyMapping[key];
            if (mapping) {
              foundPipes = [
                new PipeModel('keyValue', {
                  displayFields:
                    !mapping.displayFields || mapping.displayFields.length === 0
                      ? null // show all properties
                      : mapping.displayFields,
                }),
              ];
            }
          }

          if (foundPipes === null) {
            pipes.push(
              new PipeModel('keyValue', {
                displayFields:
                  !this.additionalDataModel.displayFields ||
                  this.additionalDataModel.displayFields.length === 0
                    ? null // show all properties
                    : this.additionalDataModel.displayFields,
              })
            );
          } else {
            pipes = foundPipes;
          }
        }

        this.renderOption = new RenderOptionModel(
          this.fieldDefinition.fieldType,
          pipes
        );
      } else if (!this.renderOption) {
        this.renderOption = new RenderOptionModel(
          this.fieldDefinition.fieldType,
          null
        );
      }
    }

    if (this.currentValue == null) {
      this.setCurrentValue();
    }
  }

  itemFormatter = (item: KeyValuePair<unknown, unknown>) =>
    typeof item !== 'object' ? item : item.value;
  onItemSelected($event) {
    if ($event && $event.item) {
      this.entry[this.fieldDefinition.systemCode] = [$event.item.key];
    } else {
      this.entry[this.fieldDefinition.systemCode] = null;
    }
    this.onFieldChanged.emit({
      field: this.fieldDefinition,
      value: this.entry[this.fieldDefinition.systemCode],
    });
  }

  onItemChanged() {
    if (
      this.searchTerm === undefined ||
      this.searchTerm === null ||
      this.searchTerm === ''
    ) {
      this.entry[this.fieldDefinition.systemCode] = null;
    } else {
      this.entry[this.fieldDefinition.systemCode] = [this.searchTerm];
    }
    this.onFieldChanged.emit({
      field: this.fieldDefinition,
      value: this.entry[this.fieldDefinition.systemCode],
    });
  }

  searchItem = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const inputFocus$ = this.focusItem;

    // in case of fieldType = FieldType.List
    // options: {items: KeyValuePair<any, any>[], mustMatch: boolean}
    if (
      this.fieldDefinition.fieldRule === undefined ||
      this.fieldDefinition.fieldRule === null
    ) {
      this.fieldDefinition.fieldRule = { items: [], mustMatch: false };
    }

    if (
      this.fieldDefinition.fieldRule.items === undefined ||
      this.fieldDefinition.fieldRule.items === null
    ) {
      this.fieldDefinition.fieldRule.items = [];
    }

    return merge(debouncedText$, inputFocus$).pipe(
      map((term: string) => {
        if (this.fieldDefinition.fieldRule.mustMatch === false) {
          this.currentValue = term;
          this.searchTerm = term;
        }
        return (
          !term || term === ''
            ? this.fieldDefinition.fieldRule.items.slice(0, 15)
            : this.fieldDefinition.fieldRule.items.filter(
                (v) => v.value.toLowerCase().indexOf(term.toLowerCase()) > -1
              )
        ).slice(0, 15);
      })
    );
  };

  setAdditionalDataModel() {
    if (
      this.fieldDefinition &&
      this.fieldDefinition.additionalData !== undefined
    ) {
      try {
        this.additionalDataModel = JSON.parse(
          this.fieldDefinition.additionalData
        );
      } catch (error) {
        this.additionalDataModel = null;
      }
    }
  }

  onBooleanChange() {
    this.entry[this.fieldDefinition.systemCode] = this.currentValue;

    this.onFieldChanged.emit({
      field: this.fieldDefinition,
      value: this.entry[this.fieldDefinition.systemCode],
    });
  }

  onDateChange(event) {
    this.currentValue = event;
    this.entry[this.fieldDefinition.systemCode] = event;

    this.onFieldChanged.emit({
      field: this.fieldDefinition,
      value: this.entry[this.fieldDefinition.systemCode],
    });
  }

  onLinkedObjectFieldClick() {
    let code = this.additionalDataModel.mappingKey;
    if (code === null) {
      code = this.fieldDefinition.systemCode;
    }

    switch (code) {
      case '$nautical_ship_id':
        const shipModal = this.modal.open(NauticalShipPickerDialogComponent, {
          backdrop: 'static',
        });
        shipModal.componentInstance.nauticalShip = this.entry['$nautical_ship'];
        shipModal.result.then((o: { nauticalShip: NauticalShipModel }) => {
          if (o && o.nauticalShip) {
            if (
              o.nauticalShip.baseId &&
              o.nauticalShip.baseId !==
                this.entry[this.fieldDefinition.systemCode]
            ) {
              this.entry[this.fieldDefinition.systemCode] =
                o.nauticalShip.baseId;
              this.currentValue = this.resolveValue(o.nauticalShip);
              this.entry.__AdditionalData[this.fieldDefinition.systemCode] =
                o.nauticalShip;
              this.onFieldChanged.emit({
                field: this.fieldDefinition,
                value: this.entry[this.fieldDefinition.systemCode],
              });
            }
          } else {
            this.entry[this.fieldDefinition.systemCode] = null;
            this.currentValue = null;
            this.entry.__AdditionalData[this.fieldDefinition.systemCode] = null;
            this.onFieldChanged.emit({
              field: this.fieldDefinition,
              value: this.entry[this.fieldDefinition.systemCode],
            });
          }
        });
        break;

      case '$nautical_portvisit_id':
      case '$nautical_portvisit_referencenumber':
        // const visitModal = this.modal.open(NauticalVisitPickerDialogComponent, { backdrop: 'static' });
        // visitModal.componentInstance.nauticalShipId = this.entry['$nautical_ship_id'];
        // visitModal.componentInstance.nauticalVisitId = this.entry['$nautical_portvisit_id'];
        // visitModal.componentInstance.nauticalShip = this.entry['$nautical_ship'];
        // visitModal.componentInstance.nauticalVisit = this.entry['$nautical_portvisit'];
        // visitModal.componentInstance.onConfirm.subscribe((result) => {
        //    if (result) {
        //        const visit: NauticalVisitModel = result.nauticalVisit;
        //        if (visit) {
        //            switch (this.fieldDefinition.systemCode) {
        //                case '$nautical_portvisit_id':
        //                    this.entry[this.fieldDefinition.systemCode] = visit.id;
        //                    break;

        //                case '$nautical_portvisit_referencenumber':
        //                    this.entry[this.fieldDefinition.systemCode] = visit.referenceNumber;
        //                    break;

        //                default:
        //                    break;
        //            }

        //            this.entry['$nautical_portvisit'] = visit;
        //            this.currentValue = this.getDisplayValue(visit);
        //        }
        //    }
        // });
        break;

      case '$nautical_portmovement_id':
        // const movementModal = this.modal.open(NauticalMovementPickerDialogComponent, { backdrop: 'static' });
        // for (let index = 0; index < this.additionalDataModel.dependencies.length; index++) {
        //     const dependency = this.additionalDataModel.dependencies[index];
        //     const mappingModel: ObjectKeyMappingModel = this.proxyServices.objectKeyMapping[dependency.mappingKey];
        //     if (mappingModel) {
        //         const objectKey = `$${mappingModel.moduleKey}_${mappingModel.objectKey}`;
        //         switch (objectKey) {
        //             case '$nautical_ship':
        //                 movementModal.componentInstance.nauticalShip = this.entry[`${this.fieldDefinition.systemCode}_${objectKey}`];
        //                 break;
        //             case '$nautical_portvisit':
        //                 movementModal.componentInstance.nauticalVisit = this.entry[`${this.fieldDefinition.systemCode}_${objectKey}`];
        //                 break;

        //             default:
        //                 break;
        //         }
        //     }
        // }
        // movementModal.componentInstance.nauticalShipPickable = true;
        // movementModal.componentInstance.nauticalVisitRetrieval  = true;
        // movementModal.componentInstance.nauticalShipRetrieval  = true;
        // movementModal.componentInstance.nauticalMovement = this.entry[`${this.fieldDefinition.systemCode}_$nautical_portmovement`];
        // movementModal.componentInstance.onConfirm.subscribe((result) => {
        //     if (result) {
        //        const movement: NauticalMovementModel = result.nauticalMovement;
        //        if (movement) {
        //            this.entry[this.fieldDefinition.systemCode] = movement.id;
        //            this.entry.__AdditionalData[this.fieldDefinition.systemCode] = movement;
        //            this.entry[`${this.fieldDefinition.systemCode}_$nautical_portmovement`] = movement;
        //            this.currentValue = this.getDisplayValue(movement);
        //        }

        //        for (let index = 0; index < this.additionalDataModel.dependencies.length; index++) {
        //         const dependency = this.additionalDataModel.dependencies[index];
        //         if (dependency.autoUpdateDependency !== true) // skip update the dependency
        //             continue;

        //         const mappingModel: ObjectKeyMappingModel = this.proxyServices.objectKeyMapping[dependency.mappingKey];
        //         if (mappingModel) {
        //             const objectKey = `$${mappingModel.moduleKey}_${mappingModel.objectKey}`;
        //             switch (objectKey) {
        //                 case '$nautical_ship':
        //                     const ship: NauticalShipModel = result.nauticalShip;
        //                     if (ship) {
        //                         this.entry[dependency.dependencyCode] = ship.baseId;
        //                         this.entry.__AdditionalData[dependency.dependencyCode] = ship;
        //                     }
        //                     break;

        //                 case '$nautical_portvisit':
        //                     const visit: NauticalVisitModel = result.nauticalVisit;
        //                     if (visit) {
        //                         this.entry[dependency.dependencyCode] = visit.id;
        //                         this.entry.__AdditionalData[dependency.dependencyCode] = visit;
        //                     }
        //                     break;

        //                 default:
        //                     break;
        //             }
        //         }
        //     }
        //    }
        // });
        break;
      case '$usermanager_user_id':
        this.openUserPicker(false);
        break;

      case '$companies_company_id':
        {
          const m: NgbModalRef = this.modal.open(CompanyPickerDialogComponent, {
            backdrop: 'static',
          });
          if (
            this.fieldDefinition.additionalDataModel &&
            this.fieldDefinition.additionalDataModel.tag &&
            this.fieldDefinition.additionalDataModel.tag !== ''
          ) {
            m.componentInstance.tagFilter =
              this.fieldDefinition.additionalDataModel.tag;
          }
          m.componentInstance.companyRequired = false;
          if (this.entry[this.fieldDefinition.systemCode]) {
            if (
              this.entry.__AdditionalData &&
              this.entry.__AdditionalData[this.fieldDefinition.systemCode]
            ) {
              m.componentInstance.company =
                this.entry.__AdditionalData[this.fieldDefinition.systemCode];
            } else {
              m.componentInstance.company = new CompanyModel();
            }
            m.componentInstance.company.id =
              this.entry[this.fieldDefinition.systemCode];
          } else {
            m.componentInstance.company = null;
          }

          m.componentInstance.onConfirm.subscribe(
            (o: { company: CompanyModel }) => {
              if (o && o.company) {
                if (
                  o.company.id !== this.entry[this.fieldDefinition.systemCode]
                ) {
                  this.entry[this.fieldDefinition.systemCode] = o.company.id;
                  this.currentValue = this.resolveValue(o.company);
                  this.entry.__AdditionalData[this.fieldDefinition.systemCode] =
                    o.company;
                  this.onFieldChanged.emit({
                    field: this.fieldDefinition,
                    value: this.entry[this.fieldDefinition.systemCode],
                  });
                }
              } else {
                this.entry[this.fieldDefinition.systemCode] = null;
                this.currentValue = null;
                this.entry.__AdditionalData[this.fieldDefinition.systemCode] =
                  null;
                this.onFieldChanged.emit({
                  field: this.fieldDefinition,
                  value: this.entry[this.fieldDefinition.systemCode],
                });
              }
            }
          );
        }
        break;
      default:
        if (code && code.startsWith('$customcontent_')) {
          const mapping = this.proxyServices.objectKeyMapping[code];
          if (mapping) {
            this.openCustomContentPicker(false, code, mapping);
          } else {
            this.notificationService.showError(
              `${this.translate.instant(
                'customData.objectDefinitions.notFound'
              )} (${code})`
            );
          }
        }
        break;
    }
  }

  onLinkedObjectsFieldClick() {
    let code = this.additionalDataModel.mappingKey;
    if (code === null) {
      code = this.fieldDefinition.systemCode;
    }

    switch (code) {
      case '$usermanager_user_id':
        this.openUserPicker(true);
        break;
      case '$companies_company_id':
        {
          // See the comments section in user story 3175
          this.notificationService.showInfo(
            'Please contact info@shipm8.nl if you see this message',
            'Multiple companies picker not found'
          );
        }
        break;

      case '$customcontent_orderRegistrationCrewMember___id':
        {
          const m: NgbModalRef = this.modal.open(CrewFactoryDialogComponent, {
            backdrop: 'static',
          });

          m.componentInstance.objectDefinition =
            this.proxyServices.objectDefinitions.find(
              (x) =>
                x.systemCode === OrderRegistrationsConstant.OBJECTKEYCREWMEMBER
            );

          if (
            this.entry[this.fieldDefinition.systemCode] &&
            this.entry[this.fieldDefinition.systemCode].length
          ) {
            m.componentInstance.crewMemberIds =
              this.entry[this.fieldDefinition.systemCode];
          }

          m.componentInstance.onConfirm.subscribe(
            (o: { crewMembers: OrderRegistrationCrewMemberModel[] }) => {
              this.entry[this.fieldDefinition.systemCode] = _.map(
                o.crewMembers,
                (x) => x.__Id
              );
              this.currentValue = _.map(o.crewMembers, (x) =>
                this.getDisplayValue(x, false, ' ')
              ).join(', ');

              this.onFieldChanged.emit({
                field: this.fieldDefinition,
                value: this.entry[this.fieldDefinition.systemCode],
              });
            }
          );
        }
        break;

      case '$customcontent_orderRegistrationHotelStay___id':
        {
          const m: NgbModalRef = this.modal.open(HotelStayDialogComponent, {
            backdrop: 'static',
          });

          if (
            this.entry[this.fieldDefinition.systemCode] &&
            this.entry[this.fieldDefinition.systemCode].length
          ) {
            m.componentInstance.hotelStayIds =
              this.entry[this.fieldDefinition.systemCode];
          }

          if (this.entry.__Id && this.entry['quantity']) {
            m.componentInstance.quantity = this.entry['quantity'];
          }

          m.componentInstance.onConfirm.subscribe(
            (o: { hotelStays: OrderRegistrationHotelStayModel[] }) => {
              this.entry[this.fieldDefinition.systemCode] = _.pluck(
                o.hotelStays,
                '__Id'
              );
              this.currentValue = _.pluck(o.hotelStays, 'roomOrName').join(
                ', '
              );

              this.onFieldChanged.emit({
                field: this.fieldDefinition,
                value: this.entry[this.fieldDefinition.systemCode],
              });
            }
          );
        }
        break;

      case '$customcontent_orderRegistrationPart___id':
        {
          const m: NgbModalRef = this.modal.open(PartDialogComponent, {
            backdrop: 'static',
          });

          if (
            this.entry[this.fieldDefinition.systemCode] &&
            this.entry[this.fieldDefinition.systemCode].length
          ) {
            m.componentInstance.partsIds =
              this.entry[this.fieldDefinition.systemCode];
          }

          if (this.entry.__Id && this.entry['quantity']) {
            m.componentInstance.quantity = this.entry['quantity'];
          }

          m.componentInstance.onConfirm.subscribe(
            (o: { parts: OrderRegistrationPartModel[] }) => {
              this.entry[this.fieldDefinition.systemCode] = _.pluck(
                o.parts,
                '__Id'
              );
              this.currentValue = _.pluck(o.parts, 'name').join(', ');

              this.onFieldChanged.emit({
                field: this.fieldDefinition,
                value: this.entry[this.fieldDefinition.systemCode],
              });
            }
          );
        }
        break;

      default:
        if (code && code.startsWith('$customcontent_')) {
          const mapping = this.proxyServices.objectKeyMapping[code];
          if (mapping) {
            this.openCustomContentPicker(true, code, mapping);
          } else {
            this.notificationService.showError(
              `${this.translate.instant(
                'customData.objectDefinitions.notFound'
              )} (${code})`
            );
          }
        }
        break;
    }
  }

  onNumberChange(newValue: Event) {
    const value = (newValue.target as HTMLInputElement).value;

    if (value == null || value === '' || isNaN(+value)) {
      this.entry[this.fieldDefinition.systemCode] = null;
    }

    this.entry[this.fieldDefinition.systemCode] = value;

    this.onFieldChanged.emit({
      field: this.fieldDefinition,
      value,
    });
  }

  onEditClick() {
    if (this.fieldDefinition.isReadOnly === true) {
      return;
    }

    switch (this.fieldDefinition.fieldType) {
      case FieldType.AutoNumbering:
        return;

      case FieldType.LinkedObject:
        this.onLinkedObjectFieldClick();
        break;

      case FieldType.LinkedObjects:
        this.onLinkedObjectsFieldClick();
        break;

      default:
        const ngbModalOptions: NgbModalOptions = {
          backdrop: 'static',
        };

        const modalRef = this.modal.open(EditAttributeModal, ngbModalOptions);
        modalRef.componentInstance.attribute = new FieldAttributeModel(
          this.fieldDefinition.name,
          this.fieldDefinition.fieldType,
          this.fieldDefinition.fieldType === FieldType.Boolean
            ? this.currentValue
            : this.entry[this.fieldDefinition.systemCode],
          this.fieldDefinition.systemCode,
          this.fieldDefinition.isRequired,
          this.fieldDefinition.fieldRule
        );
        modalRef.componentInstance.modalHeader = this.translate.instant(
          'customData.shared.editField'
        );
        modalRef.result.then((modalResult) => {
          if (modalResult && modalResult.fieldValue !== undefined) {
            this.entry[this.fieldDefinition.systemCode] =
              modalResult.fieldValue;
            this.setCurrentValue();
            this.onFieldChanged.emit({
              field: this.fieldDefinition,
              value: this.entry[this.fieldDefinition.systemCode],
            });
          }
        });
        break;
    }
  }

  private openCustomContentPicker(
    allowMultiple: boolean,
    mappingKey: string,
    mapping: ObjectKeyMappingModel
  ) {
    const value = this.entry[this.fieldDefinition.systemCode];

    const modal = this.modal.open(CustomContentPickerModalComponent, {
      backdrop: 'static',
    });
    modal.componentInstance.selectedObjectIds =
      allowMultiple === true || !value ? value : [value];
    modal.componentInstance.allowMultiple = allowMultiple;
    modal.componentInstance.mappingModel = mapping;
    modal.result.then((result) => {
      if (result) {
        // except an array
        if (result.length === 0) {
          this.entry[this.fieldDefinition.systemCode] = null;
          this.currentValue = null;
        } else {
          let key;
          if (mapping.keys.length === 1) {
            key = mapping.keys[0];
          } else {
            const substrings = mappingKey.split('_');
            substrings.splice(0, 2);
            const field = substrings.join('_');
            const prop = _.find(_.keys(result[0]), (k) => {
              return k.toLowerCase() === field.toLowerCase();
            });

            key = prop === undefined || prop === null ? field : prop;
          }

          this.entry[this.fieldDefinition.systemCode] =
            allowMultiple === true
              ? _.map(result, (u) => u[key])
              : result[0][key];
          this.currentValue =
            allowMultiple === true
              ? _.map(result, (obj) => {
                  return this.getDisplayValue(obj, false, ' ');
                }).join(', ')
              : this.getDisplayValue(result[0], true);
        }

        this.onFieldChanged.emit({
          field: this.fieldDefinition,
          value: this.entry[this.fieldDefinition.systemCode],
        });
      }
    });
  }

  private openUserPicker(allowMultiple: boolean) {
    const value = this.entry[this.fieldDefinition.systemCode];
    const userModal = this.modal.open(UserPickerModalComponent, {
      backdrop: 'static',
    });
    userModal.componentInstance.selectedUserIds =
      allowMultiple === true || !value ? value : [value];
    userModal.componentInstance.allowMultiple = allowMultiple;
    userModal.result.then((result) => {
      if (result) {
        if (result.length === 0) {
          this.entry[this.fieldDefinition.systemCode] = null;
          this.currentValue = null;
        } else {
          this.entry[this.fieldDefinition.systemCode] =
            allowMultiple === true
              ? _.map(result, (u) => u['id'])
              : result[0]['id'];
          this.currentValue =
            allowMultiple === true
              ? _.map(result, (obj) => {
                  return this.getDisplayValue(obj, false, ' ');
                }).join(', ')
              : this.getDisplayValue(result[0], true);
        }

        this.onFieldChanged.emit({
          field: this.fieldDefinition,
          value: this.entry[this.fieldDefinition.systemCode],
        });
      }
    });
  }

  updateValue(value: unknown) {
    this.entry[this.fieldDefinition.systemCode] = value;
  }

  private setCurrentValue() {
    if (!this.fieldDefinition) {
      return;
    }

    this.fieldTypeDefinition = this.fieldTypeDefinitions.find(
      (x) => x.id === this.fieldDefinition.fieldTypeDefinitionId
    );

    if (!this.fieldTypeDefinition) {
      return;
    }

    switch (this.fieldTypeDefinition.fieldType) {
      case FieldType.Boolean:
        const value =
          this.entry[this.fieldDefinition.systemCode] != true ? false : true;

        this.currentValue = value;

        if (this.fieldDefinition.isRequired === true) {
          this.entry[this.fieldDefinition.systemCode] = value;
        }

        break;
      default:
        // dirty fix for old code, field system code startswith $ e.g $nautical_ship_id
        let key = null;

        if (this.entry.__AdditionalData) {
          const keys = _.keys(this.entry.__AdditionalData);
          key = _.find(
            keys,
            (k) =>
              k.toLowerCase() === this.fieldDefinition.systemCode.toLowerCase()
          );
        }

        if (key) {
          this.currentValue = this.resolveValue(
            this.entry.__AdditionalData[key]
          );
        } else {
          this.currentValue =
            this.entry[this.fieldDefinition.systemCode] ??
            this.fieldDefinition.defaultValue;
        }

        break;
    }
  }

  // TODO remove this and use 'resolveValue' method
  private getDisplayValue(
    source: unknown,
    showFieldName: boolean = true,
    separator: string = ' | '
  ): string {
    let output = '';
    if (
      source !== undefined &&
      source !== null &&
      this.isReadOnly !== true &&
      this.inlineEdit === true
    ) {
      // only for edit inline mode

      let mapping: ObjectKeyMappingModel = null;
      if (this.additionalDataModel) {
        mapping =
          this.proxyServices.objectKeyMapping[
            this.additionalDataModel.mappingKey
          ];
      }

      if (!mapping) {
        mapping =
          this.proxyServices.objectKeyMapping[this.fieldDefinition.systemCode];
      }

      if (mapping) {
        const values = _.map(mapping.displayFields, (field) => {
          if (source[field] !== undefined && source[field] !== null) {
            return (showFieldName === true ? field + ': ' : '') + source[field];
          }

          if (
            source[field.toLowerCase()] !== undefined &&
            source[field.toLowerCase()] !== null
          ) {
            return (
              (showFieldName === true ? field + ': ' : '') +
              source[field.toLowerCase()]
            );
          }

          return null;
        });

        output = _.filter(values, (value) => value !== null).join(separator);
      }
    }

    return output;
  }

  private resolveValue(source: unknown) {
    let result = source;
    if (this.isReadOnly !== true && this.inlineEdit === true) {
      this.customValueDisplayPipe
        .transform(source, this.fieldDefinition, this.renderOption)
        .subscribe((response) => {
          result = response;
        });
    }

    return result;
  }

  addCompany() {
    this.subscriptions$.add(
      this._modalService.openModal(CompanyFormComponent).subscribe({
        next: (response: unknown) => {
          this.company = response as CompanyModel;
        },
      })
    );
  }
}
