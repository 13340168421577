<div class="card mb-4">
  <div class="card-header">
    {{ 'nautical.ship.aisTrack' | translate }}
    <div class="btn-list">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">
            {{ 'shared.terms.history' | translate }}
          </span>
        </div>
        <select class="form-control" [(ngModel)]="selectedTimespan">
          <option [value]="24">
            24 {{ 'shared.datepicker.hours' | translate }}
          </option>
          <option [value]="48">
            48 {{ 'shared.datepicker.hours' | translate }}
          </option>
          <option [value]="168">
            7 {{ 'shared.datepicker.days' | translate }}
          </option>
          <option [value]="720">
            30 {{ 'shared.datepicker.days' | translate }}
          </option>
        </select>
      </div>
    </div>
  </div>
  <div class="card-body" [class.card-body-table]="shipTrack.length">
    <ca-map-box-display-track
      *ngIf="shipTrack.length"
      [track]="shipTrack"
    ></ca-map-box-display-track>
    <div *ngIf="!shipTrack?.length" class="text-muted text-center">
      {{ 'nautical.ship.aisTrack.noData' | translate }}
    </div>
  </div>
</div>
