<div class="row">
  <div class="col-12">
    <div class="form-group">
      <label
        class="d-flex justify-content-between"
        [ngClass]="{ required: isRequired }"
        for="orderRegistration"
      >
        <b>{{
          'customModules.portCallFile.orderRegistrations.title' | translate
        }}</b>
        <span *ngIf="loadingOrderRegistrations">
          <i class="fa-regular fa-spinner fa-spin fa-fw"></i>
        </span>
      </label>
      <select
        [(ngModel)]="selectedOrderRegistration"
        [disabled]="loadingOrderRegistrations"
        (change)="
          selectedOrderRegistrationChange.emit(selectedOrderRegistration)
        "
        id="orderRegistration"
        name="orderRegistration"
        class="form-control"
      >
        <option [value]="null">
          {{ 'shared.terms.select' | translate }}...
        </option>
        <option
          *ngFor="let orderRegistration of orderRegistrations"
          [ngValue]="orderRegistration"
        >
          {{ orderRegistration.po }}
        </option>
      </select>
    </div>
  </div>
</div>
