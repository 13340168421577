import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  CustomDataBaseModel,
  CustomDataDefinitionTypePipe,
  FieldMode,
  FieldType,
  OrderRegistrationStatusPipe,
  ProxyService,
  UserDisplayPipe,
} from '@seahorse/domain';
import * as _ from 'underscore';

import { GeneralPipeResolverPipe, PipeModel } from '@seahorse/common';
import { CustomListDisplayPipe } from './custom-list-display.pipe';
import { LinkedObjectsPipe } from './linked-objects.pipe';
import { StatusProgressPipe } from './status-progress.pipe';

@Pipe({
  name: 'fieldValue',
  standalone: true,
})
export class FieldValuePipe implements PipeTransform {
  constructor(
    private proxy: ProxyService,
    private translateService: TranslateService
  ) {}

  transform(
    input: any,
    type: FieldType,
    pipeModels?: PipeModel[],
    obj?: any | CustomDataBaseModel,
    users?: any[],
    mode?: FieldMode,
    displayFields?: string[]
  ): string {
    let result = input;
    const pipes = !pipeModels ? [] : _.clone(pipeModels);

    if (type) {
      switch (type) {
        case FieldType.AutoNumbering:
          pipes.push(new PipeModel('uppercase'));
          break;

        case FieldType.Boolean:
          result =
            // possible result is a string, so don't use triple-equals
            // tslint:disable-next-line: triple-equals
            result == true
              ? this.translateService.instant('shared.terms.yes')
              : this.translateService.instant('shared.terms.no');
          break;

        case FieldType.Date:
          if (!_.some(pipes, (pipe) => pipe.name === 'date')) {
            pipes.push(new PipeModel('date', { format: 'dd-MM-yyyy' }));
          }
          break;

        case FieldType.DateTime:
          if (!_.some(pipes, (pipe) => pipe.name === 'date')) {
            pipes.push(new PipeModel('date', { format: 'dd-MM-yyyy HH:mm' }));
          }
          break;

        case FieldType.Decimal:
          if (!_.some(pipes, (pipe) => pipe.name === 'number')) {
            pipes.push(new PipeModel('number', { digitsInfo: '1.2-2' }));
          }
          break;

        case FieldType.ProgressBar:
          return '';

        case FieldType.Time:
          if (!_.some(pipes, (pipe) => pipe.name === 'time')) {
            pipes.push(new PipeModel('time', { format: 'HH:mm' }));
          }
          break;

        case FieldType.Status:
          pipes.push(new PipeModel('tempStatusProgress'));
          break;

        default:
          break;
      }
    }

    if (result != null) {
      const tName = result.constructor.name;
      switch (tName) {
        case 'Object':
          if (
            !_.some(
              pipes,
              (pipe) => pipe.name === 'keyValue' || pipe.name === 'json'
            )
          ) {
            pipes.push(new PipeModel('keyValue')); // default KeyValue
          }
          break;
        case 'Array':
          if (result.length > 0 && result[0].constructor.name === 'String') {
            result = result.join(', ');
          }
          break;
      }
    }

    if (pipes) {
      pipes.forEach((pipe) => {
        switch (pipe.name) {
          case 'shCustomDataDefinitionType':
            result = new CustomDataDefinitionTypePipe(this.proxy).transform(
              result
            );
            break;
          case 'tempCustomListDisplay':
            result = new CustomListDisplayPipe().transform(
              input,
              pipe.params['items']
            );
            break;
          case 'shOrderRegistrationStatus':
            result = new OrderRegistrationStatusPipe(
              this.translateService
            ).transform(result);
            break;
          case 'decimalPipe':
            result = new DecimalPipe('en-US').transform(
              input,
              pipe.params['format']
            );
            break;
          case 'tempLinkedObjects':
            result = new LinkedObjectsPipe().transform(
              type,
              obj,
              pipe.params['systemCode'],
              displayFields
            );
            break;
          case 'tempStatusProgress':
            result = new StatusProgressPipe().transform(input);
            break;
          case 'shUserDisplay':
            result = new UserDisplayPipe().transform(input, users);
            break;
          default:
            result = new GeneralPipeResolverPipe().transform(result, pipe);
            break;
        }
      });
    }

    return result;
  }
}
