import { FieldDefinitionModel } from '@seahorse/domain';
import { FieldType } from '@seahorse/domain';
import * as _ from 'underscore';
import { CustomDataBaseModel } from '@seahorse/domain';
import { PeriodStatisticsModel } from '../../reports/models/period-statistics.model';

export class GenericDataExportModel {
  fields: GenericDataExportFieldModel[];
  data: any[];
  recipients?: string[] = [];

  title?: string;
  linkedObjectConfigurations?: any[];
  linkedObjectsConfigurations?: any[];

  static fromStatisticsTable(
    referenceColumnName: string,
    tableData: any
  ): GenericDataExportModel {
    if (tableData && tableData.intervals && tableData.tableData) {
      const exportData = {
        fields: [{ code: '__display', display: referenceColumnName }].concat(
          _.map(tableData.intervals, (interval) => ({
            code: interval.key,
            display: interval.description,
          }))
        ),
        data: _.map(tableData.tableData, (td) => {
          td.amounts['__display'] = td.description;
          return td.amounts;
        }),
      };

      return exportData;
    }
  }

  static fromPeriodStatistics(
    statistics: PeriodStatisticsModel[]
  ): GenericDataExportModel {
    const exportData: GenericDataExportModel = {
      fields: [
        {
          code: 'yHeader',
          display: '',
          fieldType: 1,
        },
      ],
      data: [],
      title: '',
    };

    const yHeaderMap = new Map<string, CustomDataBaseModel>();

    statistics.forEach((x, i) => {
      const index = `item-${i}`;
      exportData.fields.push({
        code: index,
        display: x.interval,
        fieldType: 2,
      });

      x.statistics.forEach((y) => {
        if (!yHeaderMap.has(y.key)) {
          const xItem = new CustomDataBaseModel();
          xItem['yHeader'] = y.key;
          yHeaderMap.set(y.key, xItem);
        }

        const item = yHeaderMap.get(y.key);
        if (item) {
          item[index] = y.amount ?? 0;
        }
      });
    });

    yHeaderMap.forEach((item) => {
      exportData.fields.forEach((field) => {
        if (field.code !== 'yHeader' && item[field.code] === undefined) {
          item[field.code] = 0;
        }
      });
    });

    exportData.data = Array.from(yHeaderMap.values());

    return exportData;
  }

  static getExportData(
    checkedIds: any[],
    displayFields: FieldDefinitionModel[]
  ): CustomDataBaseModel[] {
    if (!checkedIds?.length) {
      return [];
    }

    const linkedObjectKeys = displayFields
      .filter((field) => field.fieldType === FieldType.LinkedObject)
      .map((field) => field.systemCode);

    const linkedObjectsKeys = displayFields
      .filter((field) => field.fieldType === FieldType.LinkedObjects)
      .map((field) => field.systemCode);

    const data = checkedIds.map((original) => {
      const item = Object.assign({}, original);

      Object.keys(item).forEach((key) => {
        if (linkedObjectKeys.includes(key)) {
          const linkedObject = item.__AdditionalData[key];
          if (linkedObject) {
            item[key] =
              linkedObject['__DisplayName'] ||
              linkedObject[Object.keys(linkedObject)[0]];
          }
        }

        if (linkedObjectsKeys.includes(key)) {
          item[key] = item.__AdditionalData[key]
            ?.map((obj) => obj['__DisplayName'])
            .join(',');
        }
      });

      return item;
    });

    return data;
  }
}

export enum GenericDataExportFieldTypes {
  General = 0,
  Text = 1,
  Number = 2,
  Date = 3,
  DateTime = 4,
  Percentage = 5,
  Currency = 6,
  Time = 7,
}

export type GenericDataExportFieldTypeDictionary = Record<
  FieldType,
  GenericDataExportFieldTypes
>;

export const genericDataExportFieldTypeDictionary: GenericDataExportFieldTypeDictionary =
  {
    [FieldType.AutoNumbering]: GenericDataExportFieldTypes.Number,
    [FieldType.Boolean]: GenericDataExportFieldTypes.Text,
    [FieldType.Currency]: GenericDataExportFieldTypes.Currency,
    [FieldType.Date]: GenericDataExportFieldTypes.Date,
    [FieldType.DateTime]: GenericDataExportFieldTypes.DateTime,
    [FieldType.Decimal]: GenericDataExportFieldTypes.Number,
    [FieldType.File]: undefined,
    [FieldType.Integer]: GenericDataExportFieldTypes.Number,
    [FieldType.LinkedObject]: GenericDataExportFieldTypes.Text,
    [FieldType.LinkedObjects]: GenericDataExportFieldTypes.Text,
    [FieldType.List]: GenericDataExportFieldTypes.Text,
    [FieldType.MultiLineText]: GenericDataExportFieldTypes.Text,
    [FieldType.SingleLineText]: GenericDataExportFieldTypes.Text,
    [FieldType.ProgressBar]: GenericDataExportFieldTypes.Percentage,
    [FieldType.StatusCount]: GenericDataExportFieldTypes.Number,
    [FieldType.Json]: GenericDataExportFieldTypes.Text,
    [FieldType.Object]: GenericDataExportFieldTypes.Text,
    [FieldType.Time]: GenericDataExportFieldTypes.Time,
    [FieldType.Status]: GenericDataExportFieldTypes.Percentage,
    [FieldType.ExternalObject]: GenericDataExportFieldTypes.Text,
  };

export class GenericDataExportFieldModel {
  code: string;
  display: string;
  format?: string;
  fieldType?: GenericDataExportFieldTypes;
  sort?: number;

  static fromFieldDefinition(field: FieldDefinitionModel) {
    return {
      code: field.systemCode,
      display: field.name,
      fieldType: genericDataExportFieldTypeDictionary[field.fieldType],
    } as GenericDataExportFieldModel;
  }
}
