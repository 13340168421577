import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ResultWrapper } from '@seahorse/common';
import { NotificationService } from '@seahorse/common';
import { EventModel } from '../../../../../shared/models/event.model';
import * as _ from 'underscore';

import { TaskConstants } from '../../models/task.constants';
import { TaskModel } from '../../models/task.model';
import { TaskDataService } from '../../services/task-data.service';
import { CreateTaskGroupModalForm } from '../task-modal/task-group-modal.form';
import { ComboBoxOption, NewItemConfig } from '@seahorse/forms';
import { TaskGroupModel } from '../../../../../task-group/models/task-group.model';
import { TaskGroupModelService } from '../../../../../task-group/services/task-group-model.service';

@Component({
  selector: 'ca-task-summary',
  templateUrl: 'task-summary.component.html',
})
export class TaskSummaryComponent implements OnInit {
  @Input() task: TaskModel;
  @Input() loading = false;
  @Input() isLoadingTaskGroupOptions = false;
  @Input() taskGroups = new Array<TaskGroupModel>();
  @Output() event: EventEmitter<EventModel>;

  taskObjectType = TaskConstants.OBJECTTYPEID;
  followUpCount: number;

  form = CreateTaskGroupModalForm();
  taskGroupOptions = new Array<ComboBoxOption>();
  newTaskGroupConfig: NewItemConfig;

  constructor(
    private taskDataService: TaskDataService,
    private translate: TranslateService,
    private notify: NotificationService,
    private taskGroupModelService: TaskGroupModelService
  ) {
    this.task = null;
    this.loading = false;
    this.event = new EventEmitter<EventModel>();

    this.translate.get('taskGroup.addNewTaskGroup').subscribe({
      next: (result) => {
        this.newTaskGroupConfig = { label: result } as NewItemConfig;
      },
    });
  }

  ngOnInit() {
    this.loading = true;
    this.taskDataService.getFollowUpTasks(this.task.id).subscribe(
      (r: ResultWrapper<TaskModel['id'][]>) => {
        if (r.hasResult) {
          this.task.followUpTasks = r.result;
          this.followUpCount = r.count;
        } else {
          this.notify.showError(
            _.pluck(r.messages, 'message').join('\n'),
            this.translate.instant('shared.terms.failed')
          );
        }
      },
      (e) => {
        this.notify.showError(
          _.pluck(e.error.messages, 'message').join('\n'),
          this.translate.instant('shared.terms.failed')
        );
      },
      () => (this.loading = false)
    );

    this.isLoadingTaskGroupOptions = true;
    this.taskGroupOptions = this.taskGroups
      .filter(
        (x) =>
          (x.objectType == null || x.objectType == this.task.objectType) &&
          (x.objectId == null || x.objectId == this.task.objectId)
      )
      .map((taskGroup) => {
        return {
          label: taskGroup.name,
          value: taskGroup.id,
        } as ComboBoxOption;
      });
    this.isLoadingTaskGroupOptions = false;

    if (this.task.taskGroupId)
      this.form.setValue({ selectedFormGroupId: this.task.taskGroupId });
  }

  save() {
    const formValue = this.form.getRawValue();
    if (formValue.selectedFormGroupId) {
      if (typeof formValue.selectedFormGroupId === 'string') {
        this.event.emit({
          action: 'addTaskGroup',
          data: this.taskGroupModelService.createFromTask(
            this.task,
            formValue.selectedFormGroupId
          ).outputTaskGroup,
        });
      } else {
        const newTaskGroupId = formValue.selectedFormGroupId;
        if (this.task.taskGroupId) {
          if (this.task.taskGroupId === newTaskGroupId) {
            this.taskGroupModelService
              .updateTask(this.task, newTaskGroupId, null)
              .subscribe({
                next: (response) => {
                  if (response.taskGroupHasChanged())
                    this.event.emit({
                      action: 'updateTaskGroupWithTasks',
                      data: response.outputTaskGroup,
                    });
                  else
                    this.event.emit({
                      action: 'update',
                      data: response.outputTask,
                    });
                },
              });
          } else {
            this.taskGroupModelService
              .removeTask(this.task, this.task.taskGroupId, null)
              .subscribe({
                next: (removeResponse) => {
                  this.taskGroupModelService
                    .addTask(this.task, newTaskGroupId, null)
                    .subscribe({
                      next: (addResponse) => {
                        const data: object[] = [addResponse.outputTask];
                        if (removeResponse.taskGroupHasChanged())
                          data.push(removeResponse.outputTaskGroup);
                        if (addResponse.taskGroupHasChanged())
                          data.push(addResponse.outputTaskGroup);
                        if (data.length > 1)
                          this.event.emit({
                            action: 'updateTaskGroups',
                            data: data,
                          });
                        else
                          this.event.emit({
                            action: 'update',
                            data: addResponse.outputTask,
                          });
                      },
                    });
                },
              });
          }
        } else {
          this.taskGroupModelService
            .addTask(this.task, newTaskGroupId, null)
            .subscribe({
              next: (response) => {
                if (response.taskGroupHasChanged())
                  this.event.emit({
                    action: 'updateTaskGroupWithTasks',
                    data: response.outputTaskGroup,
                  });
                else
                  this.event.emit({
                    action: 'update',
                    data: response.outputTask,
                  });
              },
            });
        }
      }
    } else if (this.task.taskGroupId) {
      this.taskGroupModelService
        .removeTask(this.task, this.task.taskGroupId, null)
        .subscribe({
          next: (response) => {
            if (response.taskGroupHasChanged())
              this.event.emit({
                action: 'updateTaskGroups',
                data: [response.outputTask, response.outputTaskGroup],
              });
            else
              this.event.emit({ action: 'update', data: response.outputTask });
          },
        });
    } else this.event.emit({ action: 'update', data: this.task });
  }
}
