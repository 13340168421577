import { CoordinatePointModel } from "../../nautical-geography/models/coordinate.model";

export class RouteCalculatorRequestModel {
  from: CoordinatePointModel;
  to: CoordinatePointModel;
  speedInKnots = 20;
}

export class RouteCalculatorResponseModel {
  routePoints: CoordinatePointModel[];
}
