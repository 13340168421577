<section class="page-header d-flex justify-content-between pl-3 py-1">
  <button
    routerLink="/custom-ui/zeehaven-overview"
    type="button"
    class="btn btn-outline-secondary my-1"
  >
    <i class="fa-solid fa-long-arrow-alt-left fa-fw"></i>
    {{
      'customers.zeehavenIJmuiden.zeehavenIJmuidenVisit.overview.title'
        | translate
    }}
  </button>
  <div class="col text-right px-0">
    <div class="btn-list">
      <div class="dropdown">
        <button
          type="button"
          class="btn btn-success"
          *ngIf="status === 'active'"
          (click)="onEvent.emit({ action: 'complete' })"
        >
          {{ 'shared.terms.complete' | translate }}
        </button>
      </div>
      <div class="dropdown m-1">
        <button
          id="headerHamburgerButton"
          type="button"
          data-toggle="dropdown"
          class="btn btn-outline-secondary"
        >
          <i class="fa-regular fa-ellipsis-v fa-fw"></i>
        </button>
        <div class="dropdown-menu" aria-labelledby="headerHamburgerButton">
          <ng-container
            *ngIf="
              !loading && status === 'deleted';
              then showRestore;
              else showDelete
            "
          ></ng-container>
          <ng-template #showRestore>
            <button
              (click)="onEvent.emit({ action: 'restore' })"
              type="button"
              class="btn dropdown-item"
            >
              <i class="fa-solid fa-trash-restore fa-fw"></i>
              {{ 'shared.terms.restore' | translate }}
            </button>
          </ng-template>
          <ng-template #showDelete>
            <button
              (click)="onEvent.emit({ action: 'markForRemoval' })"
              type="button"
              class="btn dropdown-item"
            >
              <i class="fa-solid fa-trash fa-fw"></i>
              {{ 'shared.terms.delete' | translate }}
            </button>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</section>
