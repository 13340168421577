/* eslint-disable @typescript-eslint/no-explicit-any */
import { AbstractControl, FormGroup, ɵRawValue, ɵValue } from '@angular/forms';
import { FormValidation } from './form.model';
import { Subject } from 'rxjs';

export class SeahorseFormGroup<
  TControl extends {
    [K in keyof TControl]: AbstractControl<any>;
  } = any
> extends FormGroup<TControl> {
  private _detectChangesSubject = new Subject<void>();
  detectChanges$ = this._detectChangesSubject.asObservable();

  private _isSubmitted = false;
  get isSubmitted() {
    return this._isSubmitted;
  }

  private _validation?: FormValidation<TControl>;
  get validation() {
    return this._validation;
  }

  get error() {
    return this.errors ? Object.values(this.errors)[0] : '';
  }

  submit() {
    this._isSubmitted = true;
    Object.values(this.controls).forEach((control) => control.markAsDirty());
    this._detectChangesSubject.next();
  }

  addValidation(validation?: FormValidation<unknown>) {
    this._validation = { ...validation };
    return this;
  }

  override reset(...args: Parameters<FormGroup<TControl>['reset']>) {
    super.reset(...args);
    this._isSubmitted = false;
    this._detectChangesSubject.next();
  }

  override patchValue(
    value: 0 extends 1 & TControl
      ? { [key: string]: any }
      : Partial<{ [K in keyof TControl]: ɵValue<TControl[K]> }>,
    options?:
      | { onlySelf?: boolean | undefined; emitEvent?: boolean | undefined }
      | undefined
  ): void {
    super.patchValue(value, options);
    this._detectChangesSubject.next();
  }

  override setValue(
    value: 0 extends 1 & TControl
      ? { [key: string]: any }
      : { [K in keyof TControl]: ɵRawValue<TControl[K]> },
    options?:
      | { onlySelf?: boolean | undefined; emitEvent?: boolean | undefined }
      | undefined
  ): void {
    super.setValue(value, options);
    this._detectChangesSubject.next();
  }
}
