import { NgModule } from '@angular/core';
import { TaskStatusPipe } from './task-status.pipe';
import { TaskTypePipe } from './task-type.pipe';

@NgModule({
  declarations: [TaskStatusPipe, TaskTypePipe],
  exports: [TaskStatusPipe, TaskTypePipe],
  providers: [TaskStatusPipe, TaskTypePipe],
})
export class TaskPipesModule {}
