export const KPActivityTypes = {
  Meren: { id: 1, name: 'Meren', code: 'meren', group: 'meren' },
  Ontmeren: { id: 2, name: 'Ontmeren', code: 'ontmeren', group: 'ontm' },
  Verhalen: { id: 3, name: 'Verhalen', code: 'verhalen', group: 'ontm' },
  Communicatievaren: {
    id: 4,
    name: 'Communicatievaren',
    code: 'communicatie',
    group: 'comm',
  },
  Materiaal: {
    id: 5,
    name: 'Materiaalvervoer',
    code: 'materiaal',
    group: 'mat',
  },
  DosFormulier: { id: 6, name: 'DoS formulier', code: 'dos', group: 'mat' },
  Standby: {
    id: 7,
    name: 'Standby/assistentie',
    code: 'standby',
    group: 'mat',
  },
  Boord: { id: 8, name: 'Boord/boord', code: 'boord', group: 'mat' },
  Slepen: { id: 9, name: 'Slepen/plaatsen', code: 'slepen', group: 'mat' },
  Verhuur: { id: 10, name: 'Verhuur', code: 'verhuur', group: 'mat' },
  Trossen: { id: 11, name: 'Trossen', code: 'trossen', group: 'mat' },
  Overig: { id: 999, name: 'Overig', code: 'overig', group: 'mat' },
};
