import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SystemMessageTranslationModel } from '../../../../preference/models/system-message-translation.model';

@Component({
  selector: 'ca-system-message-translation-modal',
  templateUrl: 'system-message-translation-modal.component.html',
})
export class SystemMessageTranslationModalComponent {
  @Input() translation: SystemMessageTranslationModel;

  constructor(private activeModal: NgbActiveModal) {}

  dismiss() {
    this.activeModal.dismiss();
  }

  ok() {
    this.activeModal.close(this.translation);
  }
}
