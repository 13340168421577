import { Injectable } from '@angular/core';
import { ResultWrapper } from '@seahorse/common';
import { DataContextService } from '@seahorse/common';
import { Observable } from 'rxjs';

import { ContentMappingExternalModel } from '../models/content-mapping-external.model';

@Injectable()
export class ContentMappingExternalDataService {
  constructor(private dataContext: DataContextService) {}

  getAll(
    objectKey: string,
    objectValue: string,
    serviceObjectType: string
  ): Observable<ResultWrapper<ContentMappingExternalModel[]>> {
    return this.dataContext.get<ContentMappingExternalModel[]>(
      `contentmappingexternal/${objectKey}/${objectValue}/${serviceObjectType}`
    );
  }

  getAllByRelationship(
    objectKey: string,
    objectValue: string,
    relationship: string
  ): Observable<ResultWrapper<ContentMappingExternalModel[]>> {
    return this.dataContext.get<ContentMappingExternalModel[]>(
      `contentmappingexternal/${objectKey}/${objectValue}/${relationship}`
    );
  }

  addOrUpdate(
    contentmappingexternal: ContentMappingExternalModel
  ): Observable<ResultWrapper<ContentMappingExternalModel>> {
    return this.dataContext.post<ContentMappingExternalModel>(
      'contentmappingexternal',
      contentmappingexternal
    );
  }

  delete(
    id: ContentMappingExternalModel['id']
  ): Observable<ResultWrapper<ContentMappingExternalModel>> {
    return this.dataContext.delete<ContentMappingExternalModel>(
      `contentmappingexternal/${id}`
    );
  }
}
