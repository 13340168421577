import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  MapMarkerColor,
  MapMarkersModel,
} from '../../../map/models/map-markers.model';
import { PreferenceModel } from '../../../preference/models/preference.model';
import { PreferenceDataService } from '../../../preference/services/preference-data.service';
import { Subscription } from 'rxjs';
import * as _ from 'underscore';

@Component({
  selector: 'ca-map-sidebar-configuration',
  templateUrl: 'map-sidebar-configuration.component.html',
})
export class MapSidebarConfigurationComponent implements OnInit, OnDestroy {
  private subscriptions$ = Array<Subscription>();
  mapMarkerColorList: MapMarkerColor[];
  mapMarkers: MapMarkersModel = new MapMarkersModel();
  mapMarkersLoading: boolean;

  constructor(
    private preferencesData: PreferenceDataService,
    private router: Router
  ) {
    this.mapMarkerColorList = _.toArray(MapMarkerColor);
    this.mapMarkers = new MapMarkersModel();
    this.mapMarkersLoading = false;
  }

  ngOnInit() {
    this.mapMarkersLoading = true;

    this.subscriptions$.push(
      this.preferencesData.getByName('mapMarkers', 'map').subscribe(
        (r) => {
          if (r.hasResult) {
            this.mapMarkers = JSON.parse(r.result.fieldValue);
          }
        },

        (e) => {},

        () => (this.mapMarkersLoading = false)
      )
    );
  }

  ngOnDestroy() {
    this.subscriptions$.forEach((s) => s.unsubscribe());
  }

  saveMapMarkers() {
    const colorPreferenceModel: PreferenceModel = new PreferenceModel();
    colorPreferenceModel.name = 'mapMarkers';
    colorPreferenceModel.category = 'map';
    colorPreferenceModel.fieldType = 1;
    colorPreferenceModel.fieldValue = JSON.stringify(
      new MapMarkersModel(this.mapMarkers)
    );

    this.subscriptions$.push(
      this.preferencesData.save(colorPreferenceModel).subscribe(
        () => {
          const currentUrl: string = this.router.url;

          this.router
            .navigateByUrl('/', { skipLocationChange: true })
            .then(
              () => this.router.navigateByUrl(currentUrl),
              () => {}
            )
            .catch(() => {})
            .finally(() => {});
        },
        () => {},
        () => {}
      )
    );
  }
}
