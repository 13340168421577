<div class="card">
  <div class="card-header">
    {{ 'invoicingFlows.businessLogic.conditions' | translate }}
    <div class="btn-list">
      <button
        *ngIf="!loadingConditions"
        [disabled]="loadingConditions"
        (click)="addCondition()"
        type="button"
        class="btn btn-outline-secondary btn-sm"
      >
        <i class="fa-regular fa-plus fa-fw"></i>
        {{ 'shared.terms.add' | translate }}
      </button>
    </div>
  </div>
  <ng-container
    *ngIf="conditions.length > 0; then showConditions; else noConditions"
  ></ng-container>

  <ng-template #showConditions>
    <div class="card-body card-body-table table-responsive">
      <table class="table">
        <tbody>
          <tr *ngFor="let condition of conditions">
            <td>{{ condition }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-template>

  <ng-template #noConditions>
    <div *ngIf="!loadingConditions" class="card-body">
      <span class="text-muted">{{
        'invoicingFlows.noConditions' | translate
      }}</span>
    </div>
  </ng-template>
</div>
