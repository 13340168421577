import { Component, Input } from '@angular/core';
import * as _ from 'underscore';

@Component({
  selector: 'ca-data-display',
  templateUrl: 'data-display.component.html',
})
export class DataDisplayComponent {
  @Input() data: any = null;

  isArray(obj) {
    return _.isArray(obj);
  }

  isObject(obj) {
    return _.isObject(obj) && !_.isArray(obj);
  }

  isProperty(obj) {
    return !this.isArray(obj) && !this.isObject(obj);
  }
}
