import { Component, Input } from '@angular/core';

import { NauticalVisitDetailsModel } from '@seahorse/domain';

@Component({
  selector: 'visit-attributes',
  templateUrl: 'visit-attributes.component.html',
})
export class VisitAttributesComponent {
  @Input() attributes: NauticalVisitDetailsModel['portVisitAttributes'];
  @Input() loadAttributes: boolean;

  constructor() {
    this.attributes = null;
    this.loadAttributes = false;
  }
}
