import { NgModule } from '@angular/core';

import { ExecutionStatusPipe } from './execution-status.pipe';
import { StageTypePipe } from './stage-type.pipe';
import { LogicTypePipe } from './logic-type.pipe';

@NgModule({
  declarations: [ExecutionStatusPipe, StageTypePipe, LogicTypePipe],
  exports: [ExecutionStatusPipe, StageTypePipe, LogicTypePipe],
  providers: [ExecutionStatusPipe, StageTypePipe, LogicTypePipe],
})
export class InvoicingFlowsPipesModule {}
