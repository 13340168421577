import { Injectable } from '@angular/core';
import { NauticalCityModel } from '../models/nautical-city.model';
import { NauticalPortModel } from '@seahorse/domain';
import { DataContextService } from '@seahorse/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NauticalWaypointModel } from '@seahorse/domain';
import { NauticalHarbourModel } from '../models/harbour.model';

@Injectable()
export class NauticalGeographyDataService {
  constructor(private dataContext: DataContextService) {}

  getCities(query?: string): Observable<NauticalCityModel[]> {
    let url = 'nautical/geography/city';
    if (query !== undefined && query !== null) {
      const encodedQuery = encodeURIComponent(query);
      url += `?query=${encodedQuery}`;
    }

    return this.dataContext
      .get<NauticalCityModel[]>(url)
      .pipe(map((response) => response.result));
  }

  getCitiesByName(query?: string): Observable<NauticalCityModel[]> {
    let url = 'nautical/geography/city';

    if (query !== undefined && query !== null) {
      const encodedQuery = encodeURIComponent(query);
      url += `?name=${encodedQuery}`;
    }

    return this.dataContext
      .get<NauticalCityModel[]>(url)
      .pipe(map((response) => response.result));
  }

  getCitiesByUnlocode(query: string): Observable<NauticalCityModel[]> {
    return this.dataContext
      .get<NauticalCityModel[]>('nautical/geography/city?unlo=' + query)
      .pipe(map((response) => response.result));
  }

  getHarboursByPort(id: number): Observable<NauticalHarbourModel[]> {
    const args = ['pindex=0', 'psize=9999'];
    return this.dataContext
      .get<NauticalHarbourModel[]>(
        'nautical/geography/port/' + id + '/harbour' + '?' + args.join('&')
      )
      .pipe(map((response) => response.result));
  }

  getWaypointsByPort(
    portId: number,
    query: string,
    filter?: string
  ): Observable<NauticalWaypointModel[]> {
    const args = ['pindex=0', 'psize=9999'];
    if (query && query !== '') {
      args.push('query=' + query);
    }
    if (filter && filter !== '') {
      args.push('filter=' + filter);
    }

    return this.dataContext
      .get<NauticalWaypointModel[]>(
        'nautical/portwaypoints/port/' + portId + '?' + args.join('&')
      )
      .pipe(map((response) => response.result));
  }
}
