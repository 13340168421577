import { Component, inject, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BreadcrumbClickEventArgs } from '@syncfusion/ej2-angular-navigations';
import { homeCrumb, SeahorseBreadcrumb } from './breadcrumb.model';
import { BreadcrumbComponent as SFBreadcrumbComponent } from '@syncfusion/ej2-angular-navigations';

@Component({
  selector: 'sh-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
  @ViewChild(SFBreadcrumbComponent)
  private _breadcrumbRef?: SFBreadcrumbComponent;

  @Input() maxItems = 3;

  private _crumbs: SeahorseBreadcrumb[] = [];

  @Input() set crumbs(value: SeahorseBreadcrumb[]) {
    this._crumbs = [homeCrumb, ...value];
    this._breadcrumbRef?.refresh();
  }

  get crumbs() {
    return this._crumbs;
  }

  private _router = inject(Router);

  onClick(crumb: BreadcrumbClickEventArgs) {
    const item = crumb.item as SeahorseBreadcrumb;

    if (item.routerLink) {
      this._router.navigate([item.routerLink]);
    }
  }
}
