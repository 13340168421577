<div class="tile-container">
  <div
    *ngFor="let invoicingFlow of invoicingFlows"
    [ngClass]="{
      selected:
        selectedInvoicingFlow && selectedInvoicingFlow.id === invoicingFlow.id
    }"
    (click)="
      onEvent.emit({ action: 'selectInvoicingFlow', data: invoicingFlow })
    "
    class="card tile tile-clickable"
  >
    <div class="card-body">
      <div class="row seperated-columns">
        <div class="col-6">
          <b>{{ invoicingFlow.name }}</b>
          &nbsp;
          <span class="text-muted">{{ invoicingFlow.description }}</span>
          <br />
          <span *ngIf="invoicingFlow.invoiceConfiguration" class="text-muted">
            {{ invoicingFlow.invoiceConfiguration.invoiceDescriptionTemplate}
          </span>
        </div>
        <div class="col-6">
          <b>{{ 'invoicingFlows.flow.startsOn' | translate }}</b>
          &nbsp;
          <span class="text-muted">{{
            invoicingFlow.startsOn | date: 'dd-MM-yyyy'
          }}</span>
          <br />
          <b>{{ 'invoicingFlows.flow.endsOn' | translate }}</b>
          &nbsp;
          <span class="text-muted">{{
            invoicingFlow.endsOn | date: 'dd-MM-yyyy'
          }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
