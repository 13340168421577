<div class="modal-header">
  <div class="modal-title">
    {{ 'invoicingFlows.execution.startExecution' | translate }}
  </div>
  <button type="button" class="close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="form-group" *ngIf="showFlowSelector">
    <label
      ><b>{{ 'invoicingFlows.flow.flow' | translate }}</b></label
    >
    <select class="form-control" [(ngModel)]="flow">
      <option *ngFor="let flow of flows" [ngValue]="flow">
        {{ flow.name }}
      </option>
    </select>
  </div>
  <!-- For now this is all custom for CVV. This will have to be made generic at some point -->
  <div class="form-group">
    <label
      ><b>{{ 'nautical.visit.model.referenceNumber' | translate }}</b></label
    >
    <input type="text" class="form-control" [(ngModel)]="searchTerm" />
  </div>
  <div class="form-group">
    <ca-company-financial-select
      [(companyId)]="companyId"
      [(companyFinancialId)]="companyFinancialId"
    >
    </ca-company-financial-select>
  </div>
  <div class="mb-3 text-right">
    <button type="button" class="btn btn-primary" (click)="search()">
      {{ 'shared.terms.search' | translate }}
    </button>
  </div>
  <div class="mb-3" *ngIf="isLoading">
    <sh-loading-indicator></sh-loading-indicator>
  </div>
  <div
    class="mb-3"
    *ngIf="!isLoading && (!customData || customData.length === 0)"
  >
    <p class="text-center">{{ 'shared.terms.noResult' | translate }}</p>
  </div>
  <div class="mb-3" *ngIf="!isLoading && customData && customData.length > 0">
    <table class="table table-condensed">
      <thead>
        <tr>
          <th style="width: 1%"></th>
          <th>Type</th>
          <th>Datum</th>
          <th>Schip</th>
          <th>Klant</th>
          <th>Ligplaats</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of customData">
          <td><input type="checkbox" [(ngModel)]="item.isSelected" /></td>
          <td>
            {{ item.activityTypeUIDisplay }} ({{ item.movementTypeUIDisplay }})
          </td>
          <td>{{ item.startsOn | date: 'dd-MM-yyyy HH:mm' }}</td>
          <td>{{ item.__AdditionalData.$nautical_ship_id.Name }}</td>
          <td>{{ item.__AdditionalData.$companies_company_id.Name }}</td>
          <td>{{ item.berth1 }}</td>
          <td>{{ item.activityState }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="card-footer text-right">
  <button type="button" class="btn btn-primary" (click)="ok()">
    Start facturatie
  </button>
</div>
