<ng-container>
  <div class="d-flex justify-content-between">
    <label class="d-flex justify-content-between" for="typeahead-{{name}}">
      <b [ngClass]="{ required: required }">{{ placeholder }}</b>
      <span *ngIf="isLoading">
        <i class="fa-regular fa-spinner fa-spin fa-fw"></i>
      </span>
    </label>
    <div *ngIf="fieldDefinition">
      <button
        type="button"
        class="btn btn-link btn-sm"
        (click)="clearTagFilter()"
        *ngIf="fieldDefinition.scope === ScopeType.Custom && tagFilter && tagFilter.length > 0"
      >
        <i class="fa-regular fa-close fa-fw"></i>
        {{ tagFilter }}
      </button>
      <button
        type="button"
        class="btn btn-link btn-sm"
        (click)="createNewObject()"
        *ngIf="!hideAddButton"
      >
        <i class="fa-regular fa-plus fa-fw"></i>
        {{ 'shared.terms.new' | translate }}
      </button>
    </div>
  </div>

  <div class="input-group">
    <div class="items form-control" *ngIf="selectedItems.length">
      <span class="selected-item" *ngFor="let item of selectedItems">
        {{item.__DisplayName}}
        <span class="close-selected" (click)="removeItem(item)">&nbsp;x</span>
      </span>
    </div>
    <input
      #input
      [ngModel]="selectedItems"
      (selectItem)="selectItem($event)"
      [class.is-invalid]="hasLoadingFailed"
      [ngbTypeahead]="dataSearch"
      [inputFormatter]="dataFormatter"
      [resultFormatter]="dataFormatter"
      [placeholder]="('shared.terms.search' | translate) + ' ' + placeholder + '...'"
      [required]="required"
      id="typeahead-{{name}}"
      [name]="name"
      type="text"
      class="form-control"
    />
  </div>
</ng-container>
