import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { LayoutComponentsModule } from '../../../layout/components/layout-components.module';
import { SharedModule } from '../../../shared/shared.module';

import { CustomGeneralPipesModule } from '../pipes/custom-pipes.module';
import { CustomUIDatetimePickerModal } from './datetime-picker-modal.component/datetime-picker-modal.component';
import { CustomUIListPickerModal } from '../../cvv/components/movement-type-picker-modal/movement-type-picker-modal.component';
import { MemberManagerModalComponent } from './member-manager-modal/member-manager-modal.component';
import { MemberPickerModalComponent } from './member-picker-modal/member-picker-modal.component';
import { TeamManagerModalComponent } from './team-manager-modal/team-manager-modal.component';

@NgModule({
  declarations: [
    CustomUIDatetimePickerModal,
    CustomUIListPickerModal,
    MemberManagerModalComponent,
    MemberPickerModalComponent,
    TeamManagerModalComponent,
  ],
  imports: [
    CommonModule,
    CustomGeneralPipesModule,
    LayoutComponentsModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,

    TranslateModule,
    SharedModule,
    NgbModule,
  ],
  exports: [
    CustomUIDatetimePickerModal,
    CustomUIListPickerModal,
    MemberManagerModalComponent,
    MemberPickerModalComponent,
    TeamManagerModalComponent,
  ],
})
export class CustomGeneralComponentsModule {}
