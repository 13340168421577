import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  Input,
  OnInit,
  OnDestroy,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MapInteractionService } from '../../../map/services/map-interaction.service';
import { CaEnvService } from '../../../shared/services/env.service';
import * as mapboxgl from 'mapbox-gl';
import { Subscription } from 'rxjs';

import { MapPopupCenterpointComponent } from '../map-popup-centerpoint/map-popup-centerpoint.component';
import { CoordinatePointModel } from '@seahorse/domain';

@Component({
  selector: 'ca-map-box-display-centerpoint',
  templateUrl: './map-box-display-centerpoint.component.html',
  styleUrls: ['./map-box-display-centerpoint.component.scss'],
})
export class MapBoxDisplayCenterpointComponent implements OnInit, OnDestroy {
  private subscriptions$ = Array<Subscription>();
  private mapPopupComponent: ComponentRef<any>;
  centerPoint: CoordinatePointModel;

  @ViewChild('mapMarker', { read: ViewContainerRef, static: true })
  markerContainerRef: ViewContainerRef;
  @ViewChild('mapPopup', { read: ViewContainerRef, static: true })
  popupContainerRef: ViewContainerRef;

  @Output() onCoordinatesUpdated = new EventEmitter<any>();

  @Input() zoomLevel = 0;
  @Input() set center(centerPoint: CoordinatePointModel) {
    this.centerPoint = centerPoint;

    if (!centerPoint) {
      this.centerPoint = new CoordinatePointModel();
    }
  }

  map: mapboxgl.Map;
  style = 'mapbox://styles/mapbox/outdoors-v9';
  currentMarker: mapboxgl.Marker;
  popupVisible = false;

  constructor(
    private mapInteractionService: MapInteractionService,
    private environment: CaEnvService,
    private resolver: ComponentFactoryResolver
  ) {}

  ngOnInit(): void {
    this.buildMap();

    this.map.on('click', (e) => {
      this.centerPoint.latitude = e.lngLat.lat;
      this.centerPoint.longitude = e.lngLat.lng;

      this.updateMarker();
    });
  }

  ngOnDestroy() {
    this.subscriptions$.forEach((s) => s.unsubscribe());
  }

  updateMarker() {
    if (this.currentMarker) {
      this.currentMarker.setLngLat([
        this.centerPoint.longitude,
        this.centerPoint.latitude,
      ]);
    } else {
      this.currentMarker = new mapboxgl.Marker(this.markerContainerRef.element)
        .setLngLat([this.centerPoint.longitude, this.centerPoint.latitude])
        .addTo(this.map);
    }
    this.showPopup(true);
  }

  buildMap() {
    this.mapInteractionService.map = new mapboxgl.Map({
      attributionControl: false,
      container: 'nautical-map-display',
      style: this.style,
      zoom: this.zoomLevel,
    });

    this.map = this.mapInteractionService.map;
    this.map.addControl(new mapboxgl.NavigationControl());

    this.map.on('load', (event) => {
      if (this.centerPoint.latitude && this.centerPoint.longitude) {
        this.currentMarker = new mapboxgl.Marker()
          .setLngLat([this.centerPoint.longitude, this.centerPoint.latitude])
          .addTo(this.map);

        this.flyTo();

        this.showPopup(true);
      }
    });
  }

  showPopup(triggerPopup: boolean = false) {
    this.popupVisible = true;

    const popupFactory = this.resolver.resolveComponentFactory(
      MapPopupCenterpointComponent
    );
    this.mapPopupComponent =
      this.popupContainerRef.createComponent(popupFactory);
    this.mapPopupComponent.instance.coordinates = this.centerPoint;

    this.subscriptions$.push(
      this.mapPopupComponent.instance.onCoordinatesUpdated.subscribe(
        (response) => {
          this.onCoordinatesUpdated.emit(this.centerPoint);
        },
        () => {},
        () => {}
      )
    );

    const markerPopup = new mapboxgl.Popup({ offset: 5 }).setDOMContent(
      this.mapPopupComponent.location.nativeElement
    );
    this.currentMarker.setPopup(markerPopup).addTo(this.map);

    if (triggerPopup === true) {
      this.currentMarker.togglePopup();
    }
  }

  removeMarkerPopup() {
    this.currentMarker.togglePopup();
    this.currentMarker.remove();
  }

  flyTo() {
    this.map.flyTo({
      center: [this.centerPoint.longitude, this.centerPoint.latitude],
    });
  }

  onChangePositions() {
    if (this.centerPoint.latitude && this.centerPoint.longitude) {
      this.flyTo();
      this.updateMarker();
    }
  }
}
