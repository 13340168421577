<div class="page-with-header">
  <!-- Header -->
  <stage-details-header
    [loadingStage]="loadingStage"
    (onEvent)="action($event)"
  ></stage-details-header>
  <!-- Content -->
  <div class="container-fluid py-3">
    <ng-container
      *ngIf="!loadingStage && stage; then showStage; else initializingStage"
    ></ng-container>

    <ng-template #showStage>
      <div class="row my-n2">
        <div class="col-12 col-lg-6 col-xl-8 py-2">
          <stage-details-info
            [stage]="stage"
            [loadingStage]="loadingStage"
            [previousStage]="previousStage"
            [loadingPreviousStage]="loadingPreviousStage"
            (onEvent)="action($event)"
          ></stage-details-info>
        </div>
        <div class="col-12 col-lg-6 col-xl-4 py-2">
          <div class="row my-n2">
            <div class="col-12 py-2">
              <calculations-info
                [calculations]="stage.businessLogic.calculations"
                [loadingCalculations]="loadingStage"
                (onEvent)="action($event)"
              ></calculations-info>
            </div>
            <div class="col-12 py-2">
              <conditions-info
                [conditions]="stage.businessLogic.conditions"
                [loadingConditions]="loadingStage"
                (onEvent)="action($event)"
              ></conditions-info>
            </div>
            <div class="col-12 py-2">
              <dependencies-info
                [dependencies]="stage.businessLogic.dependencies"
                [loadingDependencies]="loadingStage"
                (onEvent)="action($event)"
              ></dependencies-info>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template #initializingStage>
      <sh-loading-indicator
        *ngIf="loadingStage"
        class="d-block text-center"
      ></sh-loading-indicator>
    </ng-template>
  </div>
</div>
