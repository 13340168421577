// 3rd party modules
import { Component, Input, OnInit } from '@angular/core';
import {
  NgbActiveModal,
  NgbModal,
  NgbModalRef,
  NgbModalOptions,
} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import * as _ from 'underscore';
import {
  map,
  debounceTime,
  distinctUntilChanged,
  tap,
  switchMap,
  catchError,
} from 'rxjs/operators';
import { of, Observable, Subject, merge } from 'rxjs';

// ShipM8 modules
import { FileModel, NauticalShipDataService } from '@seahorse/domain';
import { NauticalShipModel } from '@seahorse/domain';
import { NauticalShipAttributeModel } from '@seahorse/domain';
import { NauticalVisitDataService } from '@seahorse/domain';
import { NauticalVisitOverviewModel } from '@seahorse/domain';

// Custom UI modules
import { MemberPickerModalComponent } from '../../../general/components/member-picker-modal/member-picker-modal.component';
import { KPActivityLogicService } from '../../services/kp-activity-logic.service';
import {
  KPActivityDisplayModel,
  KPActivityModel,
} from '../../models/kp-activity.model';
import { KPActivityTypes } from '../../models/kp-activity-types.enum';
import { ShipSearchModel } from '@seahorse/domain';
import { KPStatus } from '../../models/kp-status.model';

@Component({
  selector: 'ca-custom-kp-add-activity-modal',
  templateUrl: 'add-activity-modal.component.html',
})
export class KPAddActivityModal implements OnInit {
  private _activity: KPActivityDisplayModel = null;
  private _preseletedShipId: string = null; // id startswith prefix base_ or customer_
  dropdownSettings = {};
  activityTypes = [];
  subTypes = [];
  selectedSubType: any = null;
  subTypeQuantity: any = null;

  errors: string[] = [];
  loadingVisits = false;
  loadMembersAnimation: boolean;
  searching = false;
  searchFailed = false;
  tempBerth1 = null;
  tempBerth2 = null;
  tempCompany = null;
  tempShip: any = null;
  tempVisit: NauticalVisitOverviewModel = null;
  shipVisits: NauticalVisitOverviewModel[] = [];
  focusBerth1 = new Subject<string>();
  focusBerth2 = new Subject<string>();
  focusCompany = new Subject<string>();
  selectedMaterialTypes = [];
  hasShip = false;
  hasSubject = false;
  includedFiles = [];

  @Input() set activity(value: KPActivityDisplayModel) {
    this._activity = value;
    this.setActivityDefaults();
  }
  get activity(): KPActivityDisplayModel {
    return this._activity;
  }

  @Input() set selectedShipId(value: string) {
    this._preseletedShipId = value;
    this.loadShipById();
  }

  constructor(
    public modal: NgbActiveModal,
    public kpActivityLogic: KPActivityLogicService,
    private modalService: NgbModal,
    private nauticalShipData: NauticalShipDataService,
    private nauticalVisitData: NauticalVisitDataService
  ) {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'code',
      textField: 'name',
      itemsShowLimit: 5,
      allowSearchFilter: true,
    };
  }

  ngOnInit() {
    const types = _.values(KPActivityTypes);
    this.activityTypes = _.sortBy(types, 'name');

    if (this.activity.activityType && this.activity.activityType !== '') {
      const subTypes =
        KPActivityLogicService.subTypes[this.activity.activityType];
      this.subTypes = !subTypes ? null : _.sortBy(subTypes, 'name');

      if (
        this.activity.subType &&
        this.activity.subType !== '' &&
        this.subTypes
      ) {
        this.selectedSubType = _.find(
          this.subTypes,
          (t) => t.code === this.activity.subType
        );

        if (this.selectedSubType) {
          this.subTypeQuantity = this.activity.quantity;
        }
      }
    }

    if (this.activity.berth1) {
      this.tempBerth1 = _.find(
        this.kpActivityLogic.berths,
        (b) => b.code === this.activity.berth1
      );
    }

    if (this.activity.berth2) {
      this.tempBerth2 = _.find(
        this.kpActivityLogic.berths,
        (b) => b.code === this.activity.berth2
      );
    }

    if (this.activity.$companies_company) {
      this.tempCompany = _.find(
        this.kpActivityLogic.companies,
        (c) => c.id === this.activity.$companies_company.id
      );
      this.loadPONumber(this.activity.$nautical_portvisit_id, this.tempCompany);
    } else if (this.activity.$companies_company_id) {
      this.tempCompany = _.find(
        this.kpActivityLogic.companies,
        (c) => c.id === this.activity.$companies_company_id
      );
      this.loadPONumber(this.activity.$nautical_portvisit_id, this.tempCompany);
    }
  }

  addMinutes(fieldName: string, num: number) {
    switch (fieldName) {
      case 'startsOnDisplay':
        if (this.activity.startsOnDisplay) {
          this.activity.startsOnDisplay = moment(
            this.activity.startsOnDisplay,
            'DD-MM-YYYY HH:mm'
          )
            .add(num, 'm')
            .format('DD-MM-YYYY HH:mm');
        }
        return;

      case 'endsOnDisplay':
        if (this.activity.endsOnDisplay) {
          this.activity.endsOnDisplay = moment(
            this.activity.endsOnDisplay,
            'DD-MM-YYYY HH:mm'
          )
            .add(num, 'm')
            .format('DD-MM-YYYY HH:mm');
        }
        return;
    }
  }

  /**
   * Reset the subtype and quantity
   */
  changeActivityType() {
    const types = KPActivityLogicService.subTypes[this.activity.activityType];
    this.subTypes = !types ? null : _.sortBy(types, 'name');
    this.selectedSubType = null;
    this.subTypeQuantity = null;

    if (!this.activity.__Id && this.activity.status != KPStatus.Suggestion) {
      switch (this.activity.activityType) {
        case KPActivityTypes.Meren.code:
        case KPActivityTypes.Ontmeren.code:
        case KPActivityTypes.Verhalen.code:
          if (!this.tempCompany) {
            let agentId;

            if (this.tempVisit) {
              agentId = this.tempVisit.currentMovement
                ? this.tempVisit.currentMovement.portAgentId
                : this.tempVisit.portAgentId;
            } else if (
              this.activity.$nautical_portvisit &&
              this.activity.$nautical_portvisit.portAgentId
            ) {
              agentId = this.activity.$nautical_portvisit.portAgentId;
            }

            if (agentId) {
              const companyIds = _.map(
                _.filter(
                  this.kpActivityLogic.companyFinancialKeys,
                  (key) => key.sourceValue === agentId.toString()
                ),
                'companyId'
              );
              this.tempCompany = _.find(
                this.kpActivityLogic.companies,
                (c) => c.financialCount > 0 && companyIds.indexOf(c.id) > -1
              );
            }
          }

          break;

        default: {
          this.tempCompany = null;

          break;
        }
      }
    }
  }

  /**
   * Reset the quantity
   */
  changeSubType() {
    this.subTypeQuantity = null;
  }

  companySelected(evt) {
    this.activity.orderNumber = null;
    this.loadPONumber(this.activity.$nautical_portvisit_id, evt.item);
  }

  displayNameFormatter = (item) => item.name.toUpperCase();

  hasBerth2() {
    return KPActivityModel.hasBerth2(this.activity.activityType);
  }

  isActivityType(types: string[]) {
    if (
      this.activity.activityType === undefined ||
      this.activity.activityType === null
    ) {
      return false;
    }

    return types.indexOf(this.activity.activityType) > -1;
  }

  /**
   * The ship is set via the code (preselected ship id)
   */
  loadShipById() {
    if (this._preseletedShipId) {
      this.searching = true;
      this.nauticalShipData.getById(this._preseletedShipId).subscribe(
        (result) => {
          if (result) {
            this.tempShip = result;
            this.shipVisits = [];
            this.loadVisitsForShip(this.tempShip.baseId);
          }
        },
        (e) => {
          this.searching = false;
        },
        () => {
          this.searching = false;
        }
      );
    }
  }

  loadVisitsForShip(vesselId) {
    this.loadingVisits = true;
    this.nauticalVisitData
      .getMostRelevantPortVisitOverviewForShip(vesselId, 10)
      .subscribe(
        (result) => {
          this.shipVisits = result.result;
          this.loadingVisits = false;
        },
        (e) => {
          this.loadingVisits = false;
        }
      );
  }

  onFocus(event, focusSubject) {
    focusSubject.next(event.target.value);
  }

  openMemberPicker() {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      size: 'sm',
    };
    const memberPickerModal: NgbModalRef = this.modalService.open(
      MemberPickerModalComponent,
      ngbModalOptions
    );
    memberPickerModal.componentInstance.users = this.kpActivityLogic.people;
    memberPickerModal.componentInstance.teams = this.kpActivityLogic.teams;
    memberPickerModal.componentInstance.members = _.clone(this.activity.people);
    memberPickerModal.componentInstance.onSelectMembersAction.subscribe(
      (onSelectMembersAction: { members: any; action: string }) => {
        this.loadMembersAnimation = true;

        switch (onSelectMembersAction.action) {
          case 'save':
            this.activity.people = onSelectMembersAction.members;
            break;
        }

        memberPickerModal.dismiss();
        this.loadMembersAnimation = false;
      }
    );
  }

  save() {
    if (this.activity.startsOnDisplay === '') {
      this.activity.startsOn = null;
    } else if (this.activity.startsOnDisplay) {
      this.activity.startsOn = moment
        .utc(this.activity.startsOnDisplay, 'DD-MM-YYYY HH:mm')
        .toDate();
    }

    if (this.activity.endsOnDisplay === '') {
      this.activity.endsOn = null;
    } else if (this.activity.endsOnDisplay) {
      this.activity.endsOn = moment
        .utc(this.activity.endsOnDisplay, 'DD-MM-YYYY HH:mm')
        .toDate();
    }

    if (
      this.activity.sailedTimeDisplay &&
      this.activity.sailedTimeDisplay !== ''
    ) {
      this.activity.sailedTime = moment
        .utc(this.activity.sailedTimeDisplay, 'DD-MM-YYYY HH:mm')
        .toDate();
    } else {
      this.activity.sailedTime = null;
    }

    if (
      this.activity.orderTimeDisplay &&
      this.activity.orderTimeDisplay !== ''
    ) {
      this.activity.orderTime = moment
        .utc(this.activity.orderTimeDisplay, 'DD-MM-YYYY HH:mm')
        .toDate();
    } else {
      this.activity.orderTime = null;
    }

    if (this.tempCompany) {
      this.activity.$companies_company = this.tempCompany;
      this.activity.$companies_company_id = this.tempCompany.id;
    } else {
      this.activity.$companies_company = null;
      this.activity.$companies_company_id = null;
    }

    if (this.tempBerth1) {
      this.activity.berth1 = this.tempBerth1.code;
    } else {
      this.activity.berth1 = null;
    }

    if (this.tempBerth2) {
      this.activity.berth2 = this.tempBerth2.code;
    } else {
      this.activity.berth2 = null;
    }

    // no sub type is selected, reset subtype and quantity fields
    if (!this.selectedSubType) {
      this.activity.subType = null;
      this.activity.quantity = null;
    } else {
      this.activity.subType = this.selectedSubType.code;
      if (!this.selectedSubType.display) {
        this.activity.quantity = null; // no quantity field for this selected subtype, reset the quantity field
      } else if (this.selectedSubType.display === 'checkbox') {
        this.activity.quantity = this.subTypeQuantity === true ? 1 : 0; // quantity field is checkbox in ui, convert the value
      } else {
        this.activity.quantity = this.subTypeQuantity; // quantity field is input field
      }
    }

    const validationResult = KPActivityModel.validate(
      this.activity,
      false,
      _.keys(KPActivityLogicService.subTypes)
    );

    if (!validationResult.isValid) {
      this.errors = validationResult.errors;
    } else {
      // activity type is not communicatie, reset mail property
      if (
        this.activity.activityType !== KPActivityTypes.Communicatievaren.code
      ) {
        this.activity.mail = null;
      }

      // activity type is not material, reset douane and plastic property
      if (this.activity.activityType !== KPActivityTypes.Materiaal.code) {
        this.activity.douane = null;
        this.activity.plastic = null;
      }

      if (this.tempShip) {
        const loaField =
          this.tempShip.shipAttributes &&
          this.tempShip.shipAttributes.length > 0
            ? _.find(
                this.tempShip.shipAttributes,
                (a: NauticalShipAttributeModel) =>
                  a.category === 'dimensions' && a.fieldName === 'loa'
              )
            : null;
        this.activity.$nautical_ship = {
          name: this.tempShip.name,
          mmsi: this.tempShip.mmsi,
          loa: loaField ? loaField.fieldValue : 0,
        };
      } else if (this.tempVisit && this.tempVisit.id) {
        this.activity.$nautical_portvisit_id = this.tempVisit.id;

        if (this.tempVisit.currentMovement) {
          this.activity.$nautical_portmovement_id =
            this.tempVisit.currentMovement.id;
        }
      }

      this.modal.close(this.activity);
    }
  }

  searchBerthFormatter = (result) => {
    let output = result.name.toUpperCase();

    if (result.code) {
      output += ' (' + result.code + ')';
    }

    return output;
  };

  searchNameFormatter = (result) => {
    return result.name.toUpperCase();
  };

  searchBerth1 = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const inputFocus$ = this.focusBerth1;

    return merge(debouncedText$, inputFocus$).pipe(
      map((term: string) =>
        (!term || term === ''
          ? this.kpActivityLogic.berths.slice(0, 15)
          : this.kpActivityLogic.berths.filter(
              (v) =>
                (v.name &&
                  v.name.toLowerCase().indexOf(term.toLowerCase()) > -1) ||
                (v.code &&
                  v.code.toLowerCase().indexOf(term.toLowerCase()) > -1)
            )
        ).slice(0, 15)
      )
    );
  };

  searchBerth2 = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const inputFocus$ = this.focusBerth2;

    return merge(debouncedText$, inputFocus$).pipe(
      map((term: string) =>
        (!term || term === ''
          ? this.kpActivityLogic.berths.slice(0, 15)
          : this.kpActivityLogic.berths.filter(
              (v) =>
                (v.name &&
                  v.name.toLowerCase().indexOf(term.toLowerCase()) > -1) ||
                (v.code &&
                  v.code.toLowerCase().indexOf(term.toLowerCase()) > -1)
            )
        ).slice(0, 15)
      )
    );
  };

  searchCompany = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const inputFocus$ = this.focusCompany;

    return merge(debouncedText$, inputFocus$).pipe(
      map((term: string) =>
        (!term || term === ''
          ? this.kpActivityLogic.companies.slice(0, 15)
          : this.kpActivityLogic.companies.filter(
              (v) => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1
            )
        ).slice(0, 15)
      )
    );
  };

  searchShip = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.searching = true)),
      switchMap((term) =>
        this.nauticalShipData
          .find(new ShipSearchModel(term), 0, 10, ['attributes'])
          .pipe(
            tap(() => (this.searchFailed = false)),
            map((result) => result.result),
            catchError(() => {
              this.searchFailed = true;
              return of([]);
            })
          )
      ),
      tap(() => (this.searching = false))
    );

  searchShipFormatter = (result: NauticalShipModel) => {
    let output = result.name.toUpperCase();

    const attributes = [];
    if (result.shipType && result.shipType.name) {
      attributes.push(result.shipType.name);
    }
    if (result.callSign) {
      attributes.push('Callsign: ' + result.callSign);
    }
    if (result.fisheryNumber) {
      attributes.push(result.fisheryNumber);
    }
    if (result.imo) {
      attributes.push('IMO: ' + result.imo);
    } else if (result.eni) {
      attributes.push('ENI: ' + result.eni);
    }

    if (attributes.length > 0) {
      output += ' (' + attributes.join(', ') + ')';
    }

    return output;
  };

  selectMainType(event) {
    if (event && event.target && event.target.value) {
      if (event.target.value === 'subject') {
        this.hasSubject = true;
        this.hasShip = false;
      } else {
        this.hasSubject = false;
        this.hasShip = true;
      }
    }
  }

  setActivityDefaults() {
    if (!this._activity) {
      return;
    }

    if (
      !this.activity.$nautical_portvisit_id &&
      this.activity.$nautical_ship_id
    ) {
      this.loadVisitsForShip(this.activity.$nautical_ship_id);
    } else if (
      this.activity.$nautical_portvisit_id &&
      this.activity.$nautical_ship_id
    ) {
      this.loadPONumber(this.activity.$nautical_portvisit_id);
    }

    if (this.activity.startsOn) {
      this.activity.startsOnDisplay = moment(this.activity.startsOn).format(
        'DD-MM-YYYY HH:mm'
      );
    }

    if (this.activity.endsOn) {
      this.activity.endsOnDisplay = moment(this.activity.endsOn).format(
        'DD-MM-YYYY HH:mm'
      );
    }

    if (this.activity.sailedTime) {
      this.activity.sailedTimeDisplay = moment(this.activity.sailedTime).format(
        'DD-MM-YYYY HH:mm'
      );
    }

    if (this.activity.orderTime) {
      this.activity.orderTimeDisplay = moment(this.activity.orderTime).format(
        'DD-MM-YYYY HH:mm'
      );
    }

    if (this.activity.subject) {
      this.hasShip = false;
      this.hasSubject = true;
      this.activity.$nautical_ship_id = -1;
    } else {
      this.hasShip = true;
      this.hasSubject = false;
    }
  }

  loadPONumber(portVisitId: number, company?: any) {
    this.activity.orderNumberLocked = false;
    // this.activity.orderNumber = '';

    if (!company && this.tempCompany) {
      company = this.tempCompany;
    }

    if (
      company &&
      company.companyFinancials &&
      company.companyFinancials.length > 0
    ) {
      const customerFinancial = _.find(
        company.companyFinancials,
        (fin) => fin.customerReference !== null
      );
      if (customerFinancial !== undefined) {
        this.activity.orderNumber = customerFinancial.customerReference;
        this.activity.orderNumberLocked = true;
        return;
      }
    }

    if (this.activity.orderNumber) {
      return;
    }

    if (!portVisitId) {
      return;
    }
    if (!this.activity.__Id && company) {
      const agentId = this.tempVisit.currentMovement
        ? this.tempVisit.currentMovement.portAgentId
        : this.tempVisit.portAgentId;
      if (agentId) {
        const companyIds = _.map(
          _.filter(
            this.kpActivityLogic.companyFinancialKeys,
            (key) => key.sourceValue === agentId.toString()
          ),
          'companyId'
        );
        if (companyIds.indexOf(company.id) === -1) {
          return;
        }
      }
    }

    if (!this.activity.orderNumber || this.activity.orderNumber.length === 0) {
      this.nauticalVisitData
        .getDetails(portVisitId)
        .subscribe((portVisitResult) => {
          try {
            const orderNumber = this.kpActivityLogic.getPONumberByEvents(
              portVisitResult.portVisitEvents
            );
            if (orderNumber) {
              this.activity.orderNumber = orderNumber;
            }
          } catch {}
        });
    }
  }

  setNow(target) {
    switch (target) {
      case 'startsOn':
        this.activity.startsOn = new Date();
        this.activity.startsOnDisplay = moment(this.activity.startsOn).format(
          'DD-MM-YYYY HH:mm'
        );
        break;
      case 'endsOn':
        this.activity.endsOn = new Date();
        this.activity.endsOnDisplay = moment(this.activity.endsOn).format(
          'DD-MM-YYYY HH:mm'
        );
        break;
      case 'sailedTime':
        this.activity.sailedTime = new Date();
        this.activity.sailedTimeDisplay = moment(
          this.activity.sailedTime
        ).format('DD-MM-YYYY HH:mm');
        break;
      case 'orderTime':
        this.activity.orderTime = new Date();
        this.activity.orderTimeDisplay = moment(this.activity.orderTime).format(
          'DD-MM-YYYY HH:mm'
        );
        break;
    }
  }

  /**
   * The ship is selected via the UI (dropdown list)
   */
  setShip(event: any) {
    this.shipVisits = [];

    if (event && event.item) {
      const ship: NauticalShipModel = event.item;
      this.loadVisitsForShip(ship.baseId); // use the original ship id (baseId), skip use the id property because it is prefix with 'base_' or 'customer_'
    }
  }

  setShipAndVisit() {
    if (this.hasSubject) {
      if (this.activity.subject && this.activity.subject.trim().length > 0) {
        this.activity.$nautical_ship_id = -1;
      }
    } else {
      if (this.tempVisit && this.tempVisit.id) {
        this.activity.$nautical_portvisit_id = this.tempVisit.id;
        this.loadPONumber(this.activity.$nautical_portvisit_id);

        if (!this.activity.__Id) {
          const agentId = this.tempVisit.currentMovement
            ? this.tempVisit.currentMovement.portAgentId
            : this.tempVisit.portAgentId;
          if (agentId) {
            const companyIds = _.map(
              _.filter(
                this.kpActivityLogic.companyFinancialKeys,
                (key) => key.sourceValue === agentId.toString()
              ),
              'companyId'
            );
            this.tempCompany = _.find(
              this.kpActivityLogic.companies,
              (c) => c.financialCount > 0 && companyIds.indexOf(c.id) > -1
            );
          }
        }

        if (this.tempVisit.currentMovement) {
          this.activity.$nautical_portmovement_id =
            this.tempVisit.currentMovement.id;
        }
      }
      // use the original ship id (baseId), skip use the id because it is prefix with 'base_' or 'customer_'
      this.activity.$nautical_ship_id = this.tempShip.baseId; // parseInt(this.tempShip.id.replace('customer_', '').replace('base_', ''));
    }
  }

  visitDisplay(visit: NauticalVisitOverviewModel) {
    let output = visit.referenceNumber + ' ';

    if (visit.eta || visit.ata) {
      if (visit.ata) {
        output += 'ATA ' + moment(visit.ata).format('DD-MM-YYYY HH:mm');
      } else if (visit.eta) {
        output += 'ETA ' + moment(visit.eta).format('DD-MM-YYYY HH:mm');
      }
    }
    if (visit.eta || visit.ata || visit.etd || visit.atd) {
      output += ' - ';
    }
    if (visit.etd || visit.atd) {
      if (visit.atd) {
        output += 'ATD ' + moment(visit.atd).format('DD-MM-YYYY HH:mm');
      } else if (visit.etd) {
        output += 'ETD ' + moment(visit.etd).format('DD-MM-YYYY HH:mm');
      }
    }

    return output;
  }

  fileUploaded(file: FileModel) {
    this.includedFiles.push(file);
  }

  fileDelete(id: any) {
    this.includedFiles = this.includedFiles.filter((file) => file.id !== id);
  }
}
