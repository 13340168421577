import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  AddressTypes,
  CompanyAddressModel,
  CompanyContactModel,
  CompanyDataService,
  CountryModel,
} from '@seahorse/domain';
import { CountryDataService } from '../../../../superuser-nautical/services/country-data.service';

@Component({
  selector: 'address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressFormComponent implements OnInit {
  addressTypes: Array<any> = [
    { value: 1, label: 'Email' },
    { value: 2, label: 'Phone' },
    { value: 3, label: 'Fax' },
    { value: 4, label: 'Postal' },
  ];

  contacts: CompanyContactModel[] = [];
  AddressTypes = AddressTypes;
  countries: CountryModel[] = [] as CountryModel[];
  isCountryLoading = false;

  @Input() model: CompanyAddressModel;
  @Input() submitted = false;
  @Input() showContactField = true;

  constructor(
    private countryDataService: CountryDataService,
    private companyDataService: CompanyDataService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.isCountryLoading = true;
    this.countryDataService.getAll().subscribe(
      (response) => {
        this.countries = response;
        this.isCountryLoading = false;
      },
      () => {
        this.isCountryLoading = false;
      }
    );

    this.loadContacts();
  }

  loadContacts() {
    this.companyDataService
      .getContacts(+localStorage.getItem('company_id'), '')
      .subscribe((response) => {
        this.contacts = response.result;
        this.cdr.detectChanges();
      });
  }

  changeAddressType(address) {
    this.model.name = this.addressTypes.find(
      (x) => x.value === address.value
    ).label;
  }
}
