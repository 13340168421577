<table class="table table-sm">
  <thead>
    <tr>
      <th>Name</th>
      <th>Size</th>
      <th>File purpose</th>
      <th>Description</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let file of files" class="card-body-group">
      <td>
        <img class="icon" [src]="file.icon" />
        <span class="vertical-align-middle">
          {{ file.name }}
        </span>
      </td>
      <td>{{ file.size | fileSize }}</td>
      <td class="text-truncate">{{ file.filePurpose }}</td>
      <td class="text-truncate">{{ file.description }}</td>
      <td class="btn-list">
        <div class="dropdown p-0">
          <button
            type="button"
            class="btn btn-light dropdown-toggle"
            id="rowMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis-v fa-fw"></i>
          </button>
          <div class="btn dropdown-menu" aria-labelledby="rowMenuButton">
            <button class="dropdown-item" (click)="delete(file)">
              <i class="fa-solid fa-trash fa-fw"></i>
              {{ 'shared.terms.delete' | translate }}
            </button>
            <button class="dropdown-item" (click)="download(file)">
              <i class="fa-regular fa-download fa-fw"></i>
              Download
            </button>
          </div>
        </div>
      </td>
    </tr>
  </tbody>
</table>
