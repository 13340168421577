import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Languages } from '../../../../core/models/language.model';

@Component({
  selector: 'ca-language-picker',
  templateUrl: './language-picker.component.html',
})
export class LanguagePickerComponent {
  get languages() {
    return Languages;
  }

  get currentLanguage() {
    return Languages.find(
      (x) => x.value === this._translateService.getDefaultLang()
    );
  }

  set currentLanguage(language) {
    if (language) this._translateService.setDefaultLang(language.value);
  }

  constructor(private _translateService: TranslateService) {}
}
