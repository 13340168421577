<div
  class="alert alert-dismissible fade show"
  [class.alert-info]="message.messageType === MessageTypes.Info"
  [class.alert-warning]="message.messageType === MessageTypes.Warning"
  [class.alert-danger]="message.messageType === MessageTypes.Error"
  role="alert"
>
  {{ message.message }}
  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
