<div *ngIf="progress.Progress" class="row mx-3">
  <progress
    class="col-12 status-bar"
    id="taskProgress"
    [title]="progress | tempStatusProgress: 'percentage'"
    [value]="progress | tempStatusProgress: 'fraction'"
    max="1"
  ></progress>
  <pre class="col-12">[{{ progress | tempStatusProgress }}]</pre>
</div>
<code class="alert alert-dark terminal-emulator">
  <sh-loading-indicator
    *ngIf="isLoading"
    class="text-center"
  ></sh-loading-indicator>
  <span *ngFor="let message of messages">
    {{ message.messageType }}
    {{ message.timestamp | date: 'HH:mm:ss' }}
    {{ message.messageCode }}
    <ng-container *ngIf="message.data"> - {{ message.data }} </ng-container>
  </span>
</code>
