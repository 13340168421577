import { inject } from '@angular/core';
import { FormModel, SeahorseFormBuilder } from '@seahorse/forms';

export type CreateDepartmentForm = FormModel<typeof CreateDepartmentForm>;

export function CreateDepartmentForm() {
  const fb = inject(SeahorseFormBuilder);

  return fb.group({
    contacts: fb.nonNullable.control<number[]>([]),
  });
}
