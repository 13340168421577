export class ObjectKeyMappingModel {
  moduleName: string;
  moduleKey: string;
  objectName: string;
  objectKey: string;
  keys: string[];
  displayFields: string[];

  constructor(
    moduleName: string,
    moduleKey: string,
    objectName: string,
    objectKey: string,
    keys: string[],
    displayFields: string[]
  ) {
    this.moduleName = moduleName;
    this.moduleKey = moduleKey;
    this.objectName = objectName;
    this.objectKey = objectKey;
    this.keys = keys;
    this.displayFields = displayFields;
  }
}
