<div
  *ngIf="activity"
  tempFileDragDrop
  [objectId]="activity.__Id"
  [objectType]="'$customcontent_ct-kp-activity_id'"
  (fileUploaded)="fileUploaded($event, activity.__Id)"
>
  <div class="modal-header">
    <div class="modal-title">
      Activiteit toevoegen
      <span
        class="text-muted"
        *ngIf="
          activity &&
          activity.$nautical_portvisit &&
          activity.$nautical_portvisit.referenceNumber
        "
        >({{ activity.$nautical_portvisit.referenceNumber }})</span
      >
    </div>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.close(null)"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form *ngIf="activity && !activity.$nautical_ship_id">
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label for="addActivitySelectType">Hoofd type:</label>
            <select
              class="form-control"
              id="addActivitySelectMainType"
              name="addActivitySelectType"
              (change)="selectMainType($event)"
            >
              <option [value]="'ship'">Schip</option>
              <option [value]="'subject'">
                Losse activiteit met omschrijving
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="hasSubject">
        <div class="col">
          <div class="form-group">
            <label class="control-label">Omschrijving</label>
            <div class="form-group">
              <input
                type="text"
                name="addActivitySubject"
                [(ngModel)]="activity.subject"
                class="form-control"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="hasShip">
        <div class="col">
          <div class="form-group">
            <label>Selecteer een schip:</label>
            <input
              id="typeahead-http"
              name="addActivityShipSearch"
              type="text"
              class="form-control"
              [class.is-invalid]="searchFailed"
              [(ngModel)]="tempShip"
              [ngbTypeahead]="searchShip"
              [inputFormatter]="displayNameFormatter"
              [resultFormatter]="searchShipFormatter"
              placeholder="Zoek een schip..."
              [editable]="false"
              (selectItem)="setShip($event)"
            />
            <sh-loading-indicator *ngIf="searching"></sh-loading-indicator>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="tempShip && tempShip.id">
        <div class="col">
          <div class="form-group">
            <label>Selecteer een reis:</label>
            <select
              *ngIf="shipVisits && shipVisits.length > 0"
              class="form-control"
              name="addActivitySelectVisit"
              [(ngModel)]="tempVisit"
            >
              <option [ngValue]="visit" *ngFor="let visit of shipVisits">
                {{ visitDisplay(visit) }}
              </option>
            </select>
            <div
              *ngIf="!loadingVisits && (!shipVisits || shipVisits.length === 0)"
            >
              Geen reizen gevonden
            </div>
            <sh-loading-indicator *ngIf="loadingVisits"></sh-loading-indicator>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col text-right">
          <button
            type="button"
            class="btn btn-primary"
            *ngIf="
              (hasShip && tempShip && tempShip.id) ||
              (hasSubject && activity.subject && activity.subject.length > 0)
            "
            (click)="setShipAndVisit()"
          >
            Volgende
            <i class="fa-regular fa-chevron-right"></i>
          </button>
        </div>
      </div>
    </form>
    <form *ngIf="activity && activity.$nautical_ship_id">
      <div
        class="row"
        *ngIf="
          hasShip === true && !tempShip && !activity.$nautical_portvisit_id
        "
      >
        <div class="col">
          <div class="form-group">
            <label>Selecteer een reis:</label>
            <select
              *ngIf="shipVisits && shipVisits.length > 0"
              class="form-control"
              name="addActivitySelectVisit"
              [(ngModel)]="tempVisit"
            >
              <option [ngValue]="visit" *ngFor="let visit of shipVisits">
                {{ visitDisplay(visit) }}
              </option>
            </select>
            <div
              *ngIf="!loadingVisits && (!shipVisits || shipVisits.length === 0)"
            >
              Geen reizen gevonden
            </div>
            <sh-loading-indicator *ngIf="loadingVisits"></sh-loading-indicator>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label for="addActivitySelectType">Type dienst:</label>
            <select
              class="form-control"
              id="addActivitySelectType"
              [(ngModel)]="activity.activityType"
              name="addActivitySelectType"
              (change)="changeActivityType()"
            >
              <option
                [ngValue]="activityType.code"
                *ngFor="let activityType of activityTypes"
              >
                {{ activityType.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label>Agent:</label>
            <input
              id="typeahead-company"
              name="addActivityCompanyId"
              type="text"
              class="form-control"
              [(ngModel)]="tempCompany"
              [ngbTypeahead]="searchCompany"
              [inputFormatter]="displayNameFormatter"
              [resultFormatter]="searchNameFormatter"
              (selectItem)="companySelected($event)"
              placeholder="Select de agent..."
              [editable]="false"
              (click)="this.focusCompany.next($event.target.value)"
            />
          </div>
        </div>
      </div>
      <div class="row" *ngIf="subTypes && subTypes.length > 0">
        <div class="col-6">
          <div class="form-group">
            <label for="addSelectSubType">Sub type:</label>
            <select
              class="form-control"
              id="addSelectSubType"
              [(ngModel)]="selectedSubType"
              name="addSelectSubType"
              (change)="changeSubType()"
            >
              <option [ngValue]="subType" *ngFor="let subType of subTypes">
                {{ subType.name }}
              </option>
            </select>
          </div>
        </div>
        <div
          class="col-6"
          *ngIf="selectedSubType && selectedSubType.display === 'text'"
        >
          <label class="control-label">{{ selectedSubType.displayText }}</label>
          <div class="form-group">
            <input
              type="text"
              name="addActivityQuantityText"
              [(ngModel)]="subTypeQuantity"
              class="form-control"
            />
          </div>
        </div>
        <div
          class="col-6"
          *ngIf="selectedSubType && selectedSubType.display === 'checkbox'"
        >
          <label class="control-label">&nbsp;</label>
          <div class="form-group input-group">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <input
                  type="checkbox"
                  id="addActivityQuantityBoolean"
                  aria-label="Checkbox for following text input"
                  name="addActivityQuantityBoolean"
                  [(ngModel)]="subTypeQuantity"
                />
              </div>
            </div>
            <label
              class="form-control"
              for="addActivityQuantityBoolean"
              title="{{ selectedSubType.displayText }}"
            >
              {{ selectedSubType.displayText }}
            </label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <!-- Members -->
          <div class="text-dark">
            {{ 'customUI.members' | translate }}:&nbsp;
          </div>
          <ng-container
            *ngIf="!loadMembersAnimation; then picker; else loading"
          ></ng-container>

          <ng-template #picker>
            <button
              (click)="openMemberPicker()"
              type="button"
              class="btn btn-hover-light border w-100 mb-3 text-left"
            >
              <ng-container
                *ngIf="
                  !activity.people || activity.people.length === 0;
                  then noMembers;
                  else members
                "
              >
              </ng-container>

              <ng-template #members>
                <div class="mx-n2 mt-n1 mb-n2">
                  <div
                    *ngFor="let member of activity.people"
                    class="d-inline-flex dark-background text-white rounded p-1 mx-1 mb-1"
                  >
                    <span *ngIf="member" class="text-nowrap">
                      {{ member | nameFromUserId | async }}
                    </span>
                  </div>
                </div>
              </ng-template>

              <ng-template #noMembers>
                <span class="text-muted">{{
                  'customUI.noMembers' | translate
                }}</span>
              </ng-template>
            </button>
          </ng-template>

          <ng-template #loading>
            <i class="fa-regular fa-spinner fa-2x fa-fw fa-spin"></i>
          </ng-template>
        </div>
      </div>
      <div class="row">
        <div class="col-12" *ngIf="isActivityType(['meren'])">
          <div class="form-group">
            <label for="addActivitySailedTime"> Opvaren: </label>
            <div class="input-group">
              <input
                class="form-control"
                placeholder="dd-mm-jjjj uu:mm"
                id="addActivitySailedTime"
                name="addActivitySailedTime"
                [(ngModel)]="activity.sailedTimeDisplay"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary"
                  (click)="setNow('sailedTime')"
                  type="button"
                  title="Nu"
                >
                  <i class="fa-regular fa-stopwatch"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12" *ngIf="isActivityType(['ontmeren', 'verhalen'])">
          <div class="form-group">
            <label for="addActivityOrderTime"> Besteltijd: </label>
            <div class="input-group">
              <input
                class="form-control"
                placeholder="dd-mm-jjjj uu:mm"
                id="addActivityOrderTime"
                name="addActivityOrderTime"
                [(ngModel)]="activity.orderTimeDisplay"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary"
                  (click)="setNow('orderTime')"
                  type="button"
                  title="Nu"
                >
                  <i class="fa-regular fa-stopwatch"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label for="addActivityDateStart">
              {{ activity.activityType !== 'meren' ? 'Van' : 'Start' }}:
            </label>
            <div class="input-group">
              <input
                class="form-control"
                placeholder="dd-mm-jjjj uu:mm"
                id="addActivityDateStart"
                name="addActivityDateStart"
                [(ngModel)]="activity.startsOnDisplay"
              />
              <button
                class="btn btn-outline-secondary"
                (click)="addMinutes('startsOnDisplay', 15)"
                type="button"
                title="+ 15 min"
              >
                <span>+15</span>
              </button>
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary"
                  (click)="setNow('startsOn')"
                  type="button"
                  title="Nu"
                >
                  <i class="fa-regular fa-stopwatch"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="addActivityDateEnd">
              {{ activity.activityType !== 'meren' ? 'Tot' : 'Einde' }}:
            </label>
            <div class="input-group">
              <input
                class="form-control"
                placeholder="dd-mm-jjjj uu:mm"
                id="addActivityDateEnd"
                name="addActivityDateEnd"
                [(ngModel)]="activity.endsOnDisplay"
              />
              <button
                class="btn btn-outline-secondary"
                (click)="addMinutes('endsOnDisplay', 30)"
                type="button"
                title="+ 30 min"
              >
                <span>+30</span>
              </button>
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary"
                  (click)="setNow('endsOn')"
                  type="button"
                  title="Nu"
                >
                  <i class="fa-regular fa-stopwatch"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label for="addActivityBerthFrom"
              >Ligplaats<span *ngIf="activity.activityType !== 'meren'">
                (van)</span
              >:</label
            >
            <input
              id="typeahead-berth1"
              name="addActivityBerth1"
              type="text"
              class="form-control"
              [(ngModel)]="tempBerth1"
              [ngbTypeahead]="searchBerth1"
              [inputFormatter]="displayNameFormatter"
              [resultFormatter]="searchBerthFormatter"
              placeholder="Select het ligplaats 1..."
              [editable]="false"
              (click)="onFocus($event, focusBerth1)"
            />
          </div>
        </div>
        <div class="col-6" *ngIf="hasBerth2()">
          <div class="form-group">
            <label for="addActivityBerthFrom">Ligplaats naar:</label>
            <input
              id="typeahead-berth2"
              name="addActivityBerth2"
              type="text"
              class="form-control"
              [(ngModel)]="tempBerth2"
              [ngbTypeahead]="searchBerth2"
              [inputFormatter]="displayNameFormatter"
              [resultFormatter]="searchBerthFormatter"
              placeholder="Select het ligplaats 2..."
              [editable]="false"
              (click)="onFocus($event, focusBerth2)"
            />
          </div>
        </div>
      </div>
      <div class="row" *ngIf="activity.activityType === 'boord'">
        <div class="col-6">
          <div class="form-group">
            <label for="addActivitySelectBoardType">Aan/van boord:</label>
            <select
              [(ngModel)]="activity.boardType"
              class="form-control"
              id="addActivitySelectBoardType"
              name="addActivitySelectBoardType"
            >
              <option [value]="'onBoard'">Aan boord</option>
              <option [value]="'offBoard'">Van boord</option>
              <option [value]="'onOffBoard'">Aan/van boord</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="activity.activityType === 'communicatie'">
        <div class="col-6">
          <div class="form-group input-group">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <input
                  type="checkbox"
                  id="addActivityMail"
                  aria-label="Checkbox for following text input"
                  name="addActivityMail"
                  [(ngModel)]="activity.mail"
                />
              </div>
            </div>
            <label class="form-control" for="addActivityMail" title="Mail">
              Comm. mail
            </label>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="activity.activityType === 'materiaal'">
        <div class="col-6">
          <label class="control-label">&nbsp;</label>
          <div class="form-group input-group">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <input
                  type="checkbox"
                  id="addActivityDouane"
                  aria-label="Checkbox for following text input"
                  name="addActivityDouane"
                  [(ngModel)]="activity.douane"
                />
              </div>
            </div>
            <label class="form-control" for="addActivityDouane" title="Douane">
              Douane
            </label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label class="control-label">Order/PO nummer</label>
          <div class="form-group">
            <input
              type="text"
              name="addActivityOrderNumber"
              [(ngModel)]="activity.orderNumber"
              class="form-control"
              [disabled]="activity.orderNumberLocked"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <h4>Opmerkingen</h4>
          <div class="form-group">
            <textarea
              rows="3"
              class="form-control"
              name="addActivityRemarks"
              [(ngModel)]="activity.remarks"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="errors && errors.length > 0">
        <div class="col-12">
          <div class="alert alert-danger">
            <p>De volgende velden zijn fout:</p>
            <ul>
              <li *ngFor="let error of errors">{{ error }}</li>
            </ul>
          </div>
        </div>
      </div>
      <ca-template-files
        *ngIf="activity.__Id"
        [files]="includedFiles"
        [objectIds]="[activity.__Id]"
        [objectType]="'$customcontent_ct-kp-activity_id'"
        [searchFields]="null"
        [exportOption]="null"
        (delete)="fileDelete($event)"
      ></ca-template-files>
      <div class="d-flex">
        <div class="flex-grow-1">
          <button
            type="button"
            class="btn btn-block btn-link"
            (click)="modal.close(null)"
          >
            Annuleren
          </button>
        </div>
        <div class="flex-grow-1">
          <button
            type="button"
            class="btn btn-block btn-success"
            (click)="save()"
          >
            Opslaan
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
