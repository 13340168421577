<div class="card">
  <div class="card-header">
    {{ 'nautical.ship.details' | translate }}
  </div>
  <div *ngIf="!loadShip" class="card-body">
    <div class="row no-gutters attribute-group w-100 pt-0 mt-n1">
      <div class="attribute-group-item col-12 col-md-6">
        <dt class="mb-0">{{ 'nautical.ship.model.name' | translate }}</dt>
        <dd *ngIf="ship.name">{{ ship.name }}</dd>
      </div>
      <div class="attribute-group-item col-12 col-md-6">
        <dt class="mb-0">{{ 'nautical.ship.model.callSign' | translate }}</dt>
        <dd *ngIf="ship.callSign">{{ ship.callSign }}</dd>
      </div>
      <div class="attribute-group-item col-12 col-md-6">
        <dt class="mb-0">{{ 'nautical.ship.model.flagCode' | translate }}</dt>
        <dd *ngIf="ship.flagCode">{{ ship.flagCode }}</dd>
      </div>
      <div class="attribute-group-item col-12 col-md-6">
        <dt class="mb-0">{{ 'nautical.ship.model.mmsi' | translate }}</dt>
        <dd *ngIf="ship.mmsi">{{ ship.mmsi }}</dd>
      </div>
      <div class="attribute-group-item col-12 col-md-6">
        <dt class="mb-0">{{ 'nautical.ship.model.imo' | translate }}</dt>
        <dd *ngIf="ship.imo">{{ ship.imo }}</dd>
      </div>
      <div class="attribute-group-item col-12 col-md-6">
        <dt class="mb-0">{{ 'nautical.ship.model.eni' | translate }}</dt>
        <dd *ngIf="ship.eni">{{ ship.eni }}</dd>
      </div>
      <div class="attribute-group-item col-12 col-md-6">
        <dt class="mb-0">
          {{ 'nautical.ship.model.fisheryNumber' | translate }}
        </dt>
        <dd *ngIf="ship.fisheryNumber">{{ ship.fisheryNumber }}</dd>
      </div>
      <div class="attribute-group-item col-12 col-md-6">
        <dt class="mb-0">{{ 'shared.terms.name' | translate }}</dt>
        <dd *ngIf="ship.shipType && ship.shipType.category">
          {{ ship.shipType.category }}
        </dd>
      </div>
    </div>
  </div>
</div>
