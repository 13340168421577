import { Component, Input, OnInit } from '@angular/core';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ResultWrapper } from '@seahorse/common';
import { NotificationService } from '@seahorse/common';
import { UserModel } from '@seahorse/domain';
import { UserDataService } from '../../../../../user/services/user-data.service';
import * as moment from 'moment';
import * as _ from 'underscore';

import { TaskModel, TaskStatus, TaskType } from '../../models/task.model';

@Component({
  selector: 'ca-task-form',
  templateUrl: './task-form.component.html',
})
export class TaskFormComponent implements OnInit {
  @Input() task: TaskModel;
  @Input() loading: boolean;

  TaskType = TaskType;
  dateIsInPast = false;
  loadingUsers: boolean;
  taskTypes: (string | TaskType)[];
  taskStatuses: (string | TaskStatus)[];
  users: UserModel[];
  minimalDate: NgbDate;

  constructor(
    private userData: UserDataService,
    private translate: TranslateService,
    private notify: NotificationService,
    private calendar: NgbCalendar
  ) {
    this.task = null;
    this.users = [];
    this.loading = false;
    this.loadingUsers = false;
    this.taskTypes = _.filter(_.toArray(TaskType), (t) => _.isNumber(t));
    this.taskStatuses = _.filter(_.toArray(TaskStatus), (t) => _.isNumber(t));
    this.minimalDate = this.calendar.getToday();
  }

  ngOnInit() {
    this.loadingUsers = true;

    this.userData.getByOrganisation(false).subscribe(
      (r: ResultWrapper<UserModel[]>) => {
        if (r.hasResult) {
          this.users = r.result;
        } else {
          this.notify.showError(
            _.pluck(r.messages, 'message').join('\n'),
            this.translate.instant('shared.terms.failed')
          );
        }
      },
      (e) => {
        this.notify.showError(
          _.pluck(e.error.messages, 'message').join('\n'),
          this.translate.instant('shared.terms.failed')
        );
      },
      () => (this.loadingUsers = false)
    );
  }

  onDateChanged(date) {
    try {
      if (date) {
        this.dateIsInPast = moment(date).isBefore(moment(), 'day');

        if (this.dateIsInPast) {
          this.notify.showWarning(
            this.translate.instant('tasks.task.model.dueDateInPast'),
            this.translate.instant('shared.terms.warning')
          );
        }
      }
    } catch {}
  }

  onAssignedToChanged() {
    this.task.assignedToChanged = true;
  }
}
