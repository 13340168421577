import { CustomDataBaseModel } from "@seahorse/domain";

export class CVVShipRequirementsModel extends CustomDataBaseModel {
  $nautical_ship_id: number;
  heavyEquipment: boolean;
  motorboat: number;
  persons: string;
  remarks: string;
  winches: string;

  constructor() {
    super();

    this.$nautical_ship_id = null;
    this.heavyEquipment = false;
    this.motorboat = 0;
    this.persons = null;
    this.remarks = null;
    this.winches = null;
  }
}
