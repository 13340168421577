<ng-container *ngIf="fieldTypeDefinition">
  <ng-container
    *ngIf="isReadOnly === true; then readOnlyMode; else editMode"
  ></ng-container>
  <ng-template #editMode>
    <ng-container
      *ngIf="inlineEdit !== true; then popupMode; else inlineMode"
    ></ng-container>
  </ng-template>
</ng-container>

<!-- readonly mode -->
<ng-template #readOnlyMode>
  <div class="attribute-group-item">
    <dt *ngIf="title">{{ title }}</dt>
    <dd>
      {{
        currentValue | customValueDisplay: fieldDefinition:renderOption | async
      }}
    </dd>
  </div>
</ng-template>

<!-- edit mode via popup -->
<ng-template #popupMode>
  <div
    [ngClass]="{ 'attribute-group-item-editable': !isReadOnly }"
    (click)="onEditClick()"
    class="attribute-group-item"
  >
    <dt *ngIf="title">{{ title }}</dt>
    <dd>
      {{
        currentValue | customValueDisplay: fieldDefinition:renderOption | async
      }}
      <i
        *ngIf="fieldTypeDefinition.fieldType !== fieldType.AutoNumbering"
        class="fa-regular fa-pencil-alt fa-fw btn-editable text-muted float-right"
      ></i>
    </dd>
  </div>
</ng-template>

<!-- inline edit mode -->
<ng-template #inlineMode>
  <div class="attribute-group-item">
    <dt
      *ngIf="title"
      [class.required]="
        (fieldDefinition.systemCode === 'contact' ||
          fieldDefinition.systemCode === 'supplier' ||
          fieldDefinition.systemCode === 'customerReference') &&
        orderRegistrationRequiredFields
      "
    >
      {{ title }}
    </dt>
    <dd>
      <ng-container [ngSwitch]="fieldTypeDefinition.fieldType">
        <!-- Auto numbering -->
        <ng-container *ngSwitchCase="fieldType.AutoNumbering">
          <span
            class="text-muted"
            *ngIf="currentValue === undefined || currentValue === null"
            >{{
              'customData.fieldDefinition.generateAutomatic' | translate
            }}</span
          >
          <ng-container
            *ngIf="currentValue !== undefined && currentValue !== null"
            >{{
              currentValue | customValueDisplay: fieldDefinition | async
            }}</ng-container
          >
        </ng-container>

        <!-- Boolean -->
        <ng-container *ngSwitchCase="fieldType.Boolean">
          <select
            class="form-control"
            [(ngModel)]="currentValue"
            name="editAttributeValueBoolean"
            (change)="onBooleanChange($event)"
          >
            <option value="true">{{ 'shared.terms.yes' | translate }}</option>
            <option value="false">{{ 'shared.terms.no' | translate }}</option>
          </select>
        </ng-container>

        <!-- Date -->
        <ng-container *ngSwitchCase="fieldType.Date">
          <temp-date-time-picker
            [dateTime]="currentValue"
            (dateTimeChange)="updateValue($event)"
            [hideTime]="true"
          ></temp-date-time-picker>
        </ng-container>

        <!-- DateTime -->
        <ng-container *ngSwitchCase="fieldType.DateTime">
          <temp-date-time-picker
            [dateTime]="currentValue"
            (dateTimeChange)="updateValue($event)"
          ></temp-date-time-picker>
        </ng-container>

        <!-- Time -->
        <ng-container *ngSwitchCase="fieldType.Time">
          <temp-date-time-picker
            [dateTime]="currentValue"
            (dateTimeChange)="updateValue($event)"
            [hideDate]="true"
            [dateTimeFormat]="fieldDefinition.fieldRule?.format ?? 'HH:mm'"
            [invalidBefore]="fieldDefinition.fieldRule?.minTime"
            [invalidAfter]="fieldDefinition.fieldRule?.maxTime"
          ></temp-date-time-picker>
        </ng-container>

        <!-- Integer -->
        <input
          *ngSwitchCase="fieldType.Integer"
          name="editAttributeValueNumber"
          class="form-control"
          [ngModel]="currentValue"
          type="text"
          (change)="onNumberChange($event)"
        />

        <!-- LinkedObject -->
        <ng-container *ngSwitchCase="fieldType.LinkedObject">
          <ng-container
            *ngIf="
              additionalDataModel?.mappingKey === '$companies_company_id';
              else linkedObjectFallback
            "
          >
            <ca-company-picker
              [(selectedCompany)]="company"
              [isRequired]="false"
              [allowTextOnly]="false"
              [showInline]="true"
              [tagFilter]="tagFilter"
            >
              <button
                class="btn btn-outline-secondary"
                (click)="addCompany()"
                *hasPermission="'companies.companies_add'"
                title="{{ 'companies.company.newCompany' | translate }}"
              >
                <i class="fas fa-plus"></i>
                {{ 'shared.terms.new' | translate }}
              </button>
            </ca-company-picker>
          </ng-container>
          <ng-template #linkedObjectFallback>
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                placeholder="{{ fieldDefinition.name }}"
                [(ngModel)]="currentValue"
                title="{{ currentValue }}"
                readonly
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary"
                  type="button"
                  (click)="onLinkedObjectFieldClick()"
                  title="{{ fieldDefinition.description }}"
                >
                  <i class="fa-regular fa-pencil-alt"></i>
                </button>
              </div>
            </div>
          </ng-template>
          <small
            *ngIf="
              submitted &&
              orderRegistrationRequiredFields &&
              fieldDefinition.systemCode === 'supplier' &&
              !entry[fieldDefinition.systemCode]
            "
            class="text-danger"
          >
            {{ 'shared.terms.requiredFieldText' | translate }}
          </small>
        </ng-container>

        <!-- LinkedObjects -->
        <ng-container *ngSwitchCase="fieldType.LinkedObjects">
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              placeholder="{{ fieldDefinition.name }}"
              [(ngModel)]="currentValue"
              title="{{ currentValue }}"
              readonly
            />
            <div class="input-group-append">
              <button
                class="btn btn-outline-secondary"
                type="button"
                (click)="onLinkedObjectsFieldClick()"
                title="{{ fieldDefinition.description }}"
              >
                <i class="fa-regular fa-pencil-alt"></i>
              </button>
            </div>
          </div>
        </ng-container>

        <!-- List -->
        <ng-container *ngSwitchCase="fieldType.List">
          <input
            id="typeahead-list"
            name="editAttributeValueList"
            type="text"
            class="form-control"
            [(ngModel)]="currentValue"
            [ngbTypeahead]="searchItem"
            [inputFormatter]="itemFormatter"
            [resultFormatter]="itemFormatter"
            (selectItem)="onItemSelected($event)"
            (change)="onItemChanged($event)"
            placeholder="{{ 'shared.terms.select' | translate }}"
            [editable]="false"
            (click)="this.focusItem.next($event.target.value)"
          />
          <small
            *ngIf="
              submitted &&
              orderRegistrationRequiredFields &&
              fieldDefinition.systemCode === 'contact' &&
              !entry[fieldDefinition.systemCode]
            "
            class="text-danger"
          >
            {{ 'shared.terms.requiredFieldText' | translate }}
          </small>
        </ng-container>

        <!-- MultiLine -->
        <textarea
          *ngSwitchCase="fieldType.MultiLineText"
          name="editAttributeValueMultiline"
          class="form-control"
          [ngModel]="currentValue"
          (ngModelChange)="updateValue($event)"
          rows="5"
          placeholder="{{ fieldDefinition.description }}"
        ></textarea>

        <!-- Default -->
        <input
          *ngSwitchDefault
          name="editAttributeValueString"
          type="text"
          class="form-control"
          [ngModel]="currentValue"
          (ngModelChange)="updateValue($event)"
          placeholder="{{ fieldDefinition.description }}"
        />
        <small
          *ngIf="
            submitted &&
            orderRegistrationRequiredFields &&
            fieldDefinition.systemCode === 'customerReference' &&
            !entry[fieldDefinition.systemCode]
          "
          class="text-danger"
        >
          {{ 'shared.terms.requiredFieldText' | translate }}
        </small>
      </ng-container>
    </dd>
  </div>
</ng-template>
