<div class="modal-header align-items-center">
  <h1 class="modal-title">{{ 'shared.terms.select' | translate }}</h1>
  <button closeModal type="button" class="btn btn-link text-dark">
    <i class="fa-solid fa-times fa-sm fa-fw"></i>
  </button>
</div>
<div class="modal-body">
  <ng-container *ngIf="isLoading; then loading; else content"></ng-container>
</div>
<div class="modal-footer">
  <button
    closeModal
    type="button"
    class="btn btn-link btn-block w-50"
    (click)="dismiss(null)"
  >
    {{ 'shared.terms.cancel' | translate }}
  </button>
</div>

<ng-template #loading>
  <sh-loading-indicator class="text-center d-block"></sh-loading-indicator>
</ng-template>
<ng-template #content>
  <div
    style="overflow-y: auto; max-height: 500px"
    *ngIf="zhijVisits && zhijVisits.length > 0"
  >
    <zeehaven-ijmuiden-visit-list
      [models]="zhijVisits"
      [visitDisplay]="'inPort'"
      [simpleView]="true"
      (onSelectVisit)="onSelect($event)"
    ></zeehaven-ijmuiden-visit-list>
  </div>
  <div
    style="overflow-y: auto; max-height: 500px"
    *ngIf="zhijMovements && zhijMovements.length > 0"
  >
    <zeehaven-ijmuiden-visit-list
      [models]="zhijMovements"
      [visitDisplay]="'outgoing'"
      [showType]="true"
      (onSelectVisit)="onSelect($event)"
    ></zeehaven-ijmuiden-visit-list>
  </div>
  <div class="" *ngIf="showCreateNewButtons">
    <ul class="summary-buttons">
      <li>
        <a class="summary-button" (click)="onSelectMovementType(2)">
          <i class="fa-regular fa-anchor fa-fw" aria-hidden="true"></i>
          <span class="summary-button-text">
            <!-- Shifting -->
            {{ 'shared.terms.create' | translate }}&nbsp;{{
              2 | zeehavenIJmuidenVisitMovementType
            }}
          </span>
        </a>
      </li>
    </ul>
    <ul class="summary-buttons">
      <li>
        <a class="summary-button" (click)="onSelectMovementType(3)">
          <i class="fa-duotone fa-exchange-alt fa-fw" aria-hidden="true"></i>
          <span class="summary-button-text">
            <!-- Outgoing -->
            {{ 'shared.terms.create' | translate }}&nbsp;{{
              3 | zeehavenIJmuidenVisitMovementType
            }}
          </span>
        </a>
      </li>
    </ul>
  </div>
</ng-template>
