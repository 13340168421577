<div class="card">
  <div class="card-header">
    <span>
      {{ 'invitation.inviteUsers' | translate }}
    </span>
  </div>

  <div class="card-body">
    <p>{{ 'invitation.inviteText' | translate }}</p>

    <form
      #inviteUserForm="ngForm"
      (ngSubmit)="inviteUserForm.submitted && inviteUserForm.valid && invite()"
    >
      <div class="form-group">
        <label class="control-label required"
          ><b>{{ 'invitation.emailAddress' | translate }}</b></label
        >
        <textarea
          required
          class="form-control"
          name="email"
          #email="ngModel"
          rows="3"
          [(ngModel)]="emailAddress"
        >
        </textarea>
        <span
          *ngIf="
            inviteUserForm.submitted &&
            (email.value == null || email.value == '')
          "
          class="text-danger"
        >
          {{ 'user.errors.emailEmpty' | translate }}</span
        >
      </div>
      <div class="d-flex">
        <sh-loading-indicator
          class="text-center"
          *ngIf="isLoading"
        ></sh-loading-indicator>

        <button
          type="submit"
          class="btn btn-primary"
          (click)="isSubmitted = true"
        >
          {{ 'invitation.invite' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
