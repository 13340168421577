<div class="container-fluid p-3">
  <div
    class="text-muted text-center p-4"
    *ngIf="isInitial && systemMessages.length === 0"
  >
    <i class="fa-duotone fa-boxes fa-10x mb-4"></i>
    <div class="text-lg">
      <p>{{'shared.terms.pageEmpty' | translate}}</p>
      <p>{{'shared.terms.search' | translate}}</p>
    </div>
  </div>
  <div
    class="card"
    *ngIf="!isInitial && !isLoading && (!systemMessages || systemMessages.length === 0)"
  >
    <div class="card-body">
      <div class="text-muted">
        {{'systemMessage.noSystemMessages' | translate}}
      </div>
    </div>
  </div>
  <div *ngIf="systemMessages.length > 0">
    <release-note-card [systemMessages]="systemMessages"></release-note-card>
  </div>
</div>
<sh-loading-indicator
  class="text-center d-block"
  *ngIf="isLoading"
></sh-loading-indicator>
<button
  class="btn btn-primary btn-block btn-lg my-3"
  [disabled]="isLoading"
  *ngIf="canLoadMore"
  (click)="loadMore()"
>
  {{'shared.terms.loadMore' | translate}}
</button>
