import { FlowModel } from './flow.model';
import { LogicModel } from './logic.model';

export class StageModel {
  id: number;
  name: string;
  stageType: number;
  invoiceFlowId: FlowModel['id'];
  invoiceType: number;
  manualApproval: boolean;
  previousStageId?: number;
  allPreviousMustSucceed: boolean;
  businessLogic: LogicModel;

  constructor() {
    this.id = null;
    this.name = null;
    this.stageType = null;
    this.invoiceFlowId = null;
    this.invoiceType = null;
    this.manualApproval = false;
    this.previousStageId = null;
    this.allPreviousMustSucceed = false;
    this.businessLogic = new LogicModel();
  }
}

export enum StageTypes {
  CalculateTariffs = 0,
  Notifications = 2,
  FinalizeInvoice = 3,
  Distribution = 4,
  CreditInvoice = 5,
}
