<div class="modal-header align-items-center">
  <h1 class="modal-title">
    {{ 'customModules.orderRegistrations.details.editFee' | translate }}
  </h1>
  <button closeModal type="button" class="btn btn-link text-dark">
    <i class="fa-solid fa-times fa-sm fa-fw"></i>
  </button>
</div>
<div class="modal-body">
  <div class="form-row">
    <div
      [ngClass]="{ 'col-sm-4': fee.percentageName }"
      class="form-group col-12"
    >
      <label for="fee.name">
        <b>{{
          'customModules.orderRegistrations.model.' + fee.name | translate
        }}</b>
      </label>
      <div class="input-group">
        <div class="input-group-prepend">
          <span *ngIf="fee.percentageValue" class="input-group-text">%</span>
          <span *ngIf="!fee.percentageValue" class="input-group-text">
            {{ fee.currency | currencySymbol }}
          </span>
        </div>
        <input
          [(ngModel)]="fee.value"
          [placeholder]="
            'customModules.orderRegistrations.placeholder.' + fee.name
              | translate
          "
          [id]="fee.name"
          [name]="fee.name"
          class="form-control"
        />
        <div class="input-group-append" *ngIf="fee.percentageName">
          <button
            type="button"
            class="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span class="sr-only">Select type</span>
          </button>
          <div class="dropdown-menu">
            <a class="dropdown-item" (click)="fee.percentageValue = true">%</a>
            <a class="dropdown-item" (click)="fee.percentageValue = false">{{
              fee.currency | currencySymbol
            }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button closeModal type="button" class="btn btn-link btn-block w-50">
    {{ 'shared.terms.cancel' | translate }}
  </button>
  <button
    (click)="onConfirm.emit(fee)"
    closeModal
    type="button"
    class="btn btn-success btn-block w-50"
  >
    {{ 'shared.terms.confirm' | translate }}
  </button>
</div>
