<div class="card">
  <div class="card-header">
    {{ 'companies.company.companyInvoices.title' | translate }}

    <div class="btn-list">
      <button
        (click)="onEvent.emit({ action: 'createInvoice' })"
        type="button"
        class="btn btn-outline-secondary btn-sm"
      >
        <i class="fa-regular fa-plus fa-fw"></i>
        {{ 'shared.terms.add' | translate }}
      </button>
    </div>
  </div>

  <div
    class="card-body"
    [ngClass]="{ 'card-body-table': invoices && invoices.length > 0 }"
  >
    <ng-container
      *ngIf="invoices && invoices.length > 0; then list; else noInvoicesFound"
    ></ng-container>

    <ng-template #list>
      <company-invoices-list
        [invoices]="invoices"
        (onEvent)="onEvent.emit($event)"
      ></company-invoices-list>
    </ng-template>

    <ng-template #noInvoicesFound>
      <ng-container *ngIf="!isLoading">
        <div class="text-muted text-center">
          {{ 'companies.company.companyInvoices.notFound' | translate }}
        </div>
      </ng-container>
    </ng-template>

    <sh-loading-indicator
      *ngIf="isLoading"
      class="d-flex justify-content-center w-100"
    ></sh-loading-indicator>
  </div>

  <div
    *ngIf="canLoadMore"
    (click)="loadMore()"
    class="card-footer card-footer-clickable"
  >
    {{ 'shared.terms.loadMore' | translate }}
  </div>
</div>
