import { Component, Input } from '@angular/core';
import { SnelstartRelationship } from '../../../../snelstart/models/snelstart-relationship';

@Component({
  selector: 'ca-company-snelstart-debtor-information-list',
  templateUrl: './company-snelstart-debtor-information-list.component.html',
  styleUrls: ['./company-snelstart-debtor-information-list.component.scss'],
})
export class CompanySnelstartDebtorInformationListComponent {
  @Input() relationships: SnelstartRelationship[];
}
