import { Injectable } from '@angular/core';
import * as _ from 'underscore';
import {
  ConvertObjectMappingModel,
  SourceValueType,
} from '../models/convert-object-mapping.model';

@Injectable({ providedIn: 'root' })
export class ObjectMappingService {
  /**
   * @param targetType The new target object type.
   * @param sourceObject The source object to map.
   * @param mappings The mapping contains which field needs to map.
   * @return The new object with the mapped fields.
   **/
  convertObjectByType<T>(
    targetType: { new (): T },
    sourceObject: any,
    mappings: ConvertObjectMappingModel[]
  ): T {
    const newModel = new targetType();
    return this.convertObject(newModel, sourceObject, mappings);
  }

  /**
   * @param targetObject The target object to map.
   * @param sourceObject The source object.
   * @param mappings The mapping contains which field needs to map.
   * @return The new object with the mapped fields.
   **/
  convertObject(
    targetObject: any,
    sourceObject: any,
    mappings: ConvertObjectMappingModel[]
  ): any {
    const newModel =
      targetObject === undefined || targetObject === null
        ? new Object()
        : targetObject;
    if (mappings) {
      _.each(mappings, (mapping) => {
        switch (mapping.sourceValueType) {
          case SourceValueType.DefaultValue:
            newModel[mapping.targetField] = mapping.fixValue;
            break;
          case SourceValueType.FieldValue:
            if (sourceObject !== undefined && sourceObject !== null) {
              if (mapping.sourceFields && mapping.sourceFields.length > 0) {
                if (mapping.sourceFields.length === 1) {
                  // only 1 field so keep the same type
                  newModel[mapping.targetField] =
                    sourceObject[mapping.sourceFields[0]];
                } else {
                  // multiple field, so convert to string by seperator
                  const fields = _.filter(
                    _.map(mapping.sourceFields, (f) => sourceObject[f]),
                    (field) => {
                      return field !== undefined && field !== null;
                    }
                  );
                  newModel[mapping.targetField] = fields.join(
                    mapping.sourceFieldSeperator
                  );
                }
              }
            }
            break;
        }
      });
    }

    return newModel;
  }
}
