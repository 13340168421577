import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'underscore';

import { ResultWrapper } from '@seahorse/common';
import { NotificationService } from '@seahorse/common';
import { EventModel } from '../../../../../shared/models/event.model';
import { LayoutDisplayService } from '../../../../../layout/services/layout-display.service';
import { StageDataService } from '../../services/stage-data.service';
import { StageModel } from '@seahorse/domain';

@Component({ templateUrl: 'stage-details.page.html' })
export class StageDetailsPage implements OnInit {
  stage: StageModel;
  loadingStage: boolean;
  previousStage: StageModel;
  loadingPreviousStage: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private notification: NotificationService,
    private layoutDisplay: LayoutDisplayService,
    private stageData: StageDataService,
    private modal: NgbModal
  ) {
    this.stage = null;
    this.loadingStage = false;
    this.previousStage = null;
    this.loadingPreviousStage = false;
  }

  ngOnInit() {
    this.translate
      .get('invoicingFlows.stageDetails.title')
      .subscribe(
        (translateGetResponse) =>
          (this.layoutDisplay.currentModuleName = translateGetResponse)
      );

    this.route.paramMap.subscribe((routeParamMap) =>
      this.loadStage(parseInt(routeParamMap.get('id')))
    );
  }

  loadStage(stageId: StageModel['id']) {
    this.loadingStage = true;

    this.stageData.getById(stageId).subscribe(
      (stageGetById: ResultWrapper<StageModel>) => {
        if (stageGetById.hasResult) {
          this.stage = stageGetById.result;

          if (this.stage.previousStageId) {
            this.loadPreviousStage(stageGetById.result.previousStageId);
          } else {
            this.previousStage = null;
          }
        } else {
          this.notification.showError(
            _.pluck(stageGetById.messages, 'message').join('\n'),
            this.translate.instant('shared.terms.failed')
          );
        }

        this.loadingStage = false;
      },
      (stageGetByIdError) => {
        this.notification.showError(
          _.pluck(stageGetByIdError.error.messages, 'message').join('\n'),
          this.translate.instant('shared.terms.failed')
        );

        this.loadingStage = false;
      }
    );
  }

  loadPreviousStage(id: StageModel['id']) {
    this.loadingPreviousStage = true;

    this.stageData.getById(id).subscribe(
      (stageGetById: ResultWrapper<StageModel>) => {
        if (stageGetById.hasResult) {
          this.previousStage = stageGetById.result;
        } else {
          this.notification.showError(
            _.pluck(stageGetById.messages, 'message').join('\n'),
            this.translate.instant('shared.terms.failed')
          );
        }

        this.loadingPreviousStage = false;
      },
      (stageGetByIdError) => {
        this.notification.showError(
          _.pluck(stageGetByIdError.error.messages, 'message').join('\n'),
          this.translate.instant('shared.terms.failed')
        );

        this.loadingPreviousStage = false;
      }
    );
  }

  action(event: EventModel) {
    switch (event.action) {
      case 'viewFlow': {
        this.router.navigate([
          `invoicing-and-tariffs/invoicing-flows/flow/${this.stage.invoiceFlowId}`,
        ]);

        break;
      }

      case 'viewPreviousStage': {
        this.router.navigate([
          `invoicing-and-tariffs/invoicing-flows/stage/${this.previousStage.id}`,
        ]);

        break;
      }

      case 'saveStage': {
        this.loadingStage = true;

        this.stageData.update(event.data).subscribe(
          (stageUpdate: ResultWrapper<StageModel>) => {
            if (stageUpdate.hasResult) {
              this.notification.showSuccess(
                this.translate.instant(
                  'invoicingFlows.stageDetails.stageUpdated'
                ),
                this.translate.instant('shared.terms.success')
              );
            } else {
              this.notification.showError(
                _.pluck(stageUpdate.messages, 'message').join('\n'),
                this.translate.instant('shared.terms.failed')
              );
            }

            this.loadingStage = false;
          },
          (stageUpdateError) => {
            this.notification.showError(
              _.pluck(stageUpdateError.error.messages, 'message').join('\n'),
              this.translate.instant('shared.terms.failed')
            );

            this.loadingStage = false;
          }
        );

        this.modal.dismissAll();

        break;
      }

      case 'deleteStage': {
        this.loadingStage = true;

        this.stageData.delete(this.stage.id).subscribe(
          (stageDelete: ResultWrapper<StageModel>) => {
            if (stageDelete.hasResult) {
              this.notification.showSuccess(
                this.translate.instant(
                  'invoicingFlows.stageDetails.stageDeleted'
                ),
                this.translate.instant('shared.terms.success')
              );

              this.router.navigate([
                `invoicing-and-tariffs/invoicing-flows/flow/${stageDelete.result.invoiceFlowId}`,
              ]);
            } else {
              this.notification.showError(
                _.pluck(stageDelete.messages, 'message').join('\n'),
                this.translate.instant('shared.terms.failed')
              );
            }

            this.loadingStage = false;
          },
          (stageDeleteError) => {
            this.notification.showError(
              _.pluck(stageDeleteError.error.messages, 'message').join('\n'),
              this.translate.instant('shared.terms.failed')
            );

            this.loadingStage = false;
          }
        );

        break;
      }
    }
  }
}
