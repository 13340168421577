<div class="card">
  <div class="card-header">Service connections</div>
  <div class="list-group" *ngIf="subscriptions && subscriptions.length > 0">
    <div
      class="list-group-item list-group-item-action cursor-pointer"
      *ngFor="let subscription of subscriptions"
      (click)="openConnection(subscription)"
    >
      <div class="d-flex justify-content-between align-items-center">
        <strong>{{ subscription.serviceConnection.applicationName }}</strong>
      </div>
    </div>
  </div>
  <div class="card-footer card-footer-clickable">
    <a routerLink="/service-connections/tokens/overview"
      >{{ 'shared.terms.more' | translate }}
      {{ 'serviceConnection.serviceConnections' | translate }} &raquo;</a
    >
  </div>
</div>
