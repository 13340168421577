import { Component, Input } from '@angular/core';

import { NauticalVisitDetailsModel } from '@seahorse/domain';

@Component({
  selector: 'visit-info',
  templateUrl: './visit-info.component.html',
})
export class VisitInfoComponent {
  @Input() loadVisit: boolean;
  @Input() visit: NauticalVisitDetailsModel;

  constructor() {
    this.visit = null;
    this.loadVisit = false;
  }
}
