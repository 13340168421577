import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'sh-are-you-sure-modal',
  templateUrl: 'are-you-sure-modal.component.html',
  standalone: true,
  imports: [CommonModule, TranslateModule, FormsModule],
})
export class AreYouSureModalComponent {
  @Input() modalHeader: string;
  @Input() remarksRequired = false;

  @Input() description = 'shared.general.areYouSure.description';

  remarks: string | null = null;

  constructor(
    public modal: NgbActiveModal,
    private _translateService: TranslateService
  ) {
    this.modalHeader = this._translateService.instant(
      'shared.general.areYouSure.title'
    );
  }

  close() {
    this.modal.close();
  }

  no() {
    // TODO use 'close' instead of 'dismiss'. dismiss causes js error. but need to check all references not sure what is the impact
    this.modal.dismiss(false);
  }

  yes() {
    if (this.remarksRequired) {
      if (this.remarks) {
        this.modal.close(this.remarks);
      }
    } else {
      this.modal.close(true);
    }
  }
}
