<div class="card-header">
  <div class="row">
    <div class="col-sm-6" *ngIf="showTitle">
      {{ ship?.name }}
      <small class="text-muted">{{ 'shared.terms.details' | translate }}</small>
    </div>
    <div class="col-sm-6 text-right" *ngIf="allowEdit">
      <button
        class="btn btn-sm btn-outline-secondary"
        *ngIf="ship && ship.id.startsWith('customer_')"
        type="button"
        (click)="editButtonClicked()"
      >
        <i class="fa-regular fa-pencil-alt fa-fw"></i>
        {{ 'shared.terms.edit' | translate }}
      </button>
      <button
        *ngIf="showMyFleet"
        type="button"
        class="btn btn-sm btn-outline-secondary ml-1"
        (click)="openMyFleet()"
      >
        <i class="fa-regular fa-star"></i>
        {{ 'map.myFleet' | translate }}
      </button>
      <button
        [hidden]="!allowEdit"
        type="button"
        class="btn btn-sm btn-outline-secondary ml-1"
        (click)="addTag()"
      >
        <i class="fa-regular fa-tags"></i>
        {{ 'nautical.ship.model.tags' | translate }}
      </button>
    </div>
  </div>
</div>
<div class="card-body">
  <ul
    class="list-inline list-inline-seperated"
    *ngIf="ship.shipTags.length > 0"
  >
    <li class="list-inline-item">
      <i class="fa-regular fa-tags text-muted"></i>
    </li>
    <li
      class="list-inline-item"
      *ngFor="let tag of ship.shipTags"
      (click)="editTag(tag)"
    >
      {{ tag.name }}
    </li>
  </ul>
  <div
    class="card-body-group card-body-group-no-border attribute-group"
    *ngIf="attributes && attributes.length > 0"
  >
    <temp-attribute-list
      [attributes]="attributes"
      modalHeader="nautical.ship.editShipField"
      [allowEdit]="allowEdit"
      (attributeChanged)="attributeChanged($event)"
    >
    </temp-attribute-list>
  </div>
</div>
