import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { NgbPopover, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'underscore';
import {
  PeriodDatesModel,
  PeriodInterval,
} from '../../../layout/models/period-dates.model';
import { PeriodIntervalCalculationService } from '../../../layout/services/period-interval-calculation.service';

@Component({
  selector: 'ca-date-period-picker',
  templateUrl: 'date-period-picker.component.html',
})
export class DatePeriodPickerComponent implements OnInit {
  // tslint:disable-next-line: no-input-rename
  @Input('date-period-picker-from') datePickerFrom;
  // tslint:disable-next-line: no-input-rename
  @Input('date-period-picker-to') datePickerTo;

  @Input() periodInterval: PeriodInterval;
  @Input() placement: string;
  @Input() usePeriodInterval: boolean;

  // tslint:disable-next-line: no-output-on-prefix
  @Output() onDateSelected: EventEmitter<any> = new EventEmitter();

  @ViewChild('p', { static: true }) popover: NgbPopover;

  dateFrom: moment.Moment = null;
  dateCalendarFrom: NgbDateStruct = null;
  dateTo: moment.Moment = null;
  dateCalendarTo: NgbDateStruct = null;

  periodIntervalList: (string | PeriodInterval)[];
  selectionConfirmed: boolean;

  constructor(
    public translate: TranslateService,
    public periodIntervalCalculation: PeriodIntervalCalculationService
  ) {
    this.usePeriodInterval = false;
    this.periodInterval = null;
    this.periodIntervalList = _.filter(_.toArray(PeriodInterval), (r) =>
      _.isNumber(r)
    );
    this.selectionConfirmed = false;
    this.placement = 'bottom-left';
  }

  ngOnInit() {
    if (this.datePickerFrom) {
      this.dateFrom = moment.isMoment(this.datePickerFrom)
        ? this.datePickerFrom
        : moment(this.datePickerFrom);
    } else {
      this.dateFrom = this.periodInterval
        ? this.periodIntervalCalculation.calculateIntervalStart(
            this.periodInterval
          )
        : null;
    }

    if (this.datePickerTo) {
      this.dateTo = moment.isMoment(this.datePickerTo)
        ? this.datePickerTo
        : moment(this.datePickerTo);
    } else {
      this.dateTo = this.periodInterval
        ? this.periodIntervalCalculation.calculateIntervalEnd(
            this.periodInterval
          )
        : null;
    }

    this.dateCalendarFrom = this.toDatePickerModel(this.dateFrom);
    this.dateCalendarTo = this.toDatePickerModel(this.dateTo);
    this.selectionConfirmed = true;
  }

  dateDisplay() {
    // if(this.selectionConfirmed && this.periodInterval) {
    if (this.periodInterval) {
      switch (this.periodInterval) {
        case PeriodInterval.WEEK:
          return `${this.dateFrom.format('YYYY')} ${this.translate.instant(
            'shared.datepicker.week'
          )} ${this.dateFrom.isoWeek()}`;
        case PeriodInterval.MONTH:
          return `${this.dateFrom.format('YYYY')} ${this.translate.instant(
            'shared.month.' + this.dateFrom.format('MMMM').toLowerCase()
          )}`;
        case PeriodInterval.QUARTER:
          return `${this.dateFrom.format('YYYY')} Q${this.dateFrom.quarter()}`;
        case PeriodInterval.YEAR:
          return this.dateFrom.format('YYYY');
        case PeriodInterval.HALFDECADE:
          return `${this.dateFrom.format('YYYY')} - ${(
            +this.dateTo.format('YYYY') + 1
          ).toString()}`;
      }
    } else {
      if (this.dateFrom && this.dateTo) {
        return this.dateFrom && this.dateFrom.isSame(this.dateTo, 'day')
          ? this.translate.instant('shared.datepicker.today')
          : `${this.dateFrom.format('DD-MM-YYYY')} - ${this.dateTo.format(
              'DD-MM-YYYY'
            )} (${this.intervalInDays()})`;
      } else {
        return this.translate.instant('shared.datepicker.pickDate');
      }
    }
  }

  next() {
    const periodDates: PeriodDatesModel = new PeriodDatesModel({
      start: this.dateFrom,
      end: this.dateTo,
    });
    const nextPeriod: PeriodDatesModel = this.periodInterval
      ? this.periodIntervalCalculation.calculateNextInterval(
          this.periodInterval,
          periodDates.start
        )
      : this.periodIntervalCalculation.calculateNextPeriodInDays(periodDates);

    this.dateFrom = nextPeriod.start;
    this.dateTo = nextPeriod.end;

    this.return();
  }

  previous() {
    const periodDates: PeriodDatesModel = new PeriodDatesModel({
      start: this.dateFrom,
      end: this.dateTo,
    });
    const previousPeriod: PeriodDatesModel = this.periodInterval
      ? this.periodIntervalCalculation.calculatePreviousInterval(
          this.periodInterval,
          periodDates.start
        )
      : this.periodIntervalCalculation.calculatePreviousPeriodInDays(
          periodDates
        );

    this.dateFrom = previousPeriod.start;
    this.dateTo = previousPeriod.end;

    this.return();
  }

  onChangePeriodInterval() {
    if (this.periodInterval) {
      const periodDates: PeriodDatesModel =
        this.periodIntervalCalculation.calculateCurrentInterval(
          this.periodInterval
        );

      this.datePickerFrom = periodDates.start;
      this.datePickerTo = periodDates.end;
      this.dateCalendarFrom = this.toDatePickerModel(periodDates.start);
      this.dateCalendarTo = this.toDatePickerModel(periodDates.end);
    }
  }

  intervalInDays(): string {
    if (this.dateFrom && this.dateTo) {
      const periodDates: PeriodDatesModel = new PeriodDatesModel({
        start: this.dateFrom,
        end: this.dateTo,
      });
      const interval: number =
        this.periodIntervalCalculation.calculateIntervalInDays(periodDates);
      const dayOrDays: string = (
        interval === 1
          ? this.translate.instant('shared.datepicker.day')
          : this.translate.instant('shared.datepicker.days')
      ).toLowerCase();

      return `${interval} ${dayOrDays}`;
    }
  }

  return() {
    this.datePickerFrom = this.dateFrom;
    this.datePickerTo = this.dateTo;

    this.dateCalendarFrom = this.toDatePickerModel(this.dateFrom);
    this.dateCalendarTo = this.toDatePickerModel(this.dateTo);

    const periodDates: PeriodDatesModel = new PeriodDatesModel({
      start: this.dateFrom,
      end: this.dateTo,
    });

    if (
      !this.periodIntervalCalculation.isCurrentInterval(
        this.periodInterval,
        periodDates
      )
    ) {
      this.periodInterval = null;
    }

    this.onDateSelected.emit([this.datePickerFrom, this.datePickerTo]);
  }

  setFromCalendar() {
    const periodDates: PeriodDatesModel = new PeriodDatesModel({
      start: this.fromDatePickerModel(this.dateCalendarFrom).startOf('day'),
      end: this.fromDatePickerModel(this.dateCalendarTo).endOf('day'),
    });

    this.dateFrom = periodDates.start;
    this.dateTo = periodDates.end;

    // Detect if the selected range is an interval
    const foundInterval = this.periodIntervalCalculation.detectInterval(
      this.dateFrom,
      this.dateTo
    );
    if (foundInterval) {
      this.periodInterval = foundInterval;
    }

    this.selectionConfirmed = true;

    this.return();
    this.popover.close();
  }

  fromDatePickerModel(source: NgbDateStruct) {
    return moment([source.year, source.month - 1, source.day]);
  }

  toDatePickerModel(date: moment.Moment): NgbDateStruct {
    return {
      day: +date.format('DD'),
      month: date.month() + 1,
      year: date.year(),
    };
  }
}
