import { Component, Input, Output, EventEmitter } from '@angular/core';

import { CompanyContactModel } from '@seahorse/domain';

@Component({
  selector: 'ca-company-contacts-list',
  templateUrl: 'company-contacts-list.component.html',
})
export class CompanyContactsListComponent {
  @Input() contacts: CompanyContactModel[];
  @Input() showHeader: boolean;

  @Output() onEvent: EventEmitter<CompanyContactModel>;

  constructor() {
    this.contacts = [];
    this.showHeader = false;
    this.onEvent = new EventEmitter<CompanyContactModel>();
  }
}
