import * as _ from 'underscore';
import { Inject } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

import { ResultStatus, ResultWrapper } from '@seahorse/common';
import { ConfirmComponent } from '../../layout/components/dialogs/confirm.component';
import { NotificationService } from '@seahorse/common';
import { BaseModel } from '@seahorse/common';
import { DataService } from '@seahorse/domain';

export class InteractionService {
  private deleteObject = new Subject();
  private modifyObject = new Subject();

  deleteCalled$ = this.deleteObject.asObservable();
  modifyCalled$ = this.modifyObject.asObservable();

  constructor(
    @Inject('DataService') private dataService: DataService<BaseModel>,
    private modalService: NgbModal,
    private notification: NotificationService,
    private translate: TranslateService
  ) {}

  modify(model: BaseModel): Observable<ResultWrapper<BaseModel>> {
    return new Observable<ResultWrapper<BaseModel>>((s) => {
      if (model.id === 0 || model.id === undefined) {
        return (
          this.dataService
            .add(model)
            .subscribe((addR: ResultWrapper<BaseModel>) => {
              if (addR.hasResult) {
                this.modifyObject.next(addR.result);
              } else {
                this.notification.showError(
                  _.pluck(addR.messages, 'message').join('\n'),
                  this.translate.instant('shared.terms.failed')
                );
              }

              s.next(addR);
            }),
          (addE) => {
            this.notification.showError(
              _.pluck(addE.error.messages, 'message').join('\n'),
              this.translate.instant('shared.terms.failed')
            );

            s.error(addE);
          },
          () => s.complete()
        );
      } else {
        return (
          this.dataService
            .update(model)
            .subscribe((updateR: ResultWrapper<BaseModel>) => {
              if (updateR.hasResult) {
                this.modifyObject.next(updateR.result);

                this.notification.showSuccess(
                  this.translate.instant('shared.terms.success')
                );
              } else {
                this.notification.showError(
                  _.pluck(updateR.messages, 'message').join('\n'),
                  this.translate.instant('shared.terms.failed')
                );
              }

              s.next(updateR);
            }),
          (updateR) => {
            this.notification.showError(
              _.pluck(updateR.error.messages, 'message').join('\n'),
              this.translate.instant('shared.terms.failed')
            );

            s.error(updateR);
          },
          () => s.complete()
        );
      }
    });
  }

  delete(model: BaseModel) {
    const ngbModalOptions: NgbModalOptions = { backdrop: 'static' };
    this.modalService
      .open(ConfirmComponent, ngbModalOptions)
      .result.then((isConfirmed) => {
        if (isConfirmed) {
          this.dataService.delete(model.id).subscribe((response) => {
            if (
              response.status === ResultStatus.Deleted ||
              response.status === ResultStatus.OK
            ) {
              this.notification.showSuccess(
                this.translate.instant('shared.terms.deleteSuccess'),
                this.translate.instant('shared.terms.success')
              );
              this.deleteObject.next(model);
            }
            if (response.status === ResultStatus.DeleteForbidden) {
              this.notification.showError(
                this.translate.instant('shared.terms.deleteForbidden'),
                this.translate.instant('shared.terms.error')
              );
            }
          });
        }
      })
      .catch(() => {});
  }
}
