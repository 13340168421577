<sh-control *ngIf="control" [control]="this">
  <ejs-combobox
    #object
    floatLabelType="Always"
    [formControl]="control"
    [placeholder]="placeholder"
    [dataSource]="options"
    [fields]="fieldMapping"
    [id]="formControlName"
    [attr.name]="formControlName"
    [itemTemplate]="templateRef"
    [valueTemplate]="templateRef"
    [allowFiltering]="newItemConfig !== undefined"
    [readonly]="isLoading"
  >
    <ng-template #noRecordsTemplate *ngIf="newItemConfig !== undefined">
      <div *ngIf="newItemConfig.label !== undefined" id="nodata">
        {{newItemConfig.label}}
      </div>
      <button (click)="setInputAsValue()" class="e-control e-btn">
        {{'shared.terms.add'|translate}}
      </button>
    </ng-template>
  </ejs-combobox>
</sh-control>
