import { Injectable } from '@angular/core';
import { ResultWrapper } from '@seahorse/common';
import { DataContextService } from '@seahorse/common';
import { Observable } from 'rxjs/internal/Observable';
import { SystemMessageModel } from '../models/system-message.model';

@Injectable()
export class ServiceMessageDataService {
  constructor(private dataContext: DataContextService) {}

  getLatest(
    messageType: string
  ): Observable<ResultWrapper<SystemMessageModel[]>> {
    return this.dataContext.get<SystemMessageModel[]>(
      `servicemessage/${messageType}`
    );
  }
}
