<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title-bold">
      {{ isNew ? 'Add' : 'Edit' }}
      {{ 'systemMessage.release.systemMessageRelease' | translate }}
    </h4>
    <button type="button" class="close" closeModal>
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ca-system-message-release-form
      [model]="systemMessageRelease"
      [isNew]="isNew"
      [systemMessageChannels]="systemMessageChannels"
      [submitted]="isSubmitted"
    >
    </ca-system-message-release-form>

    <sh-loading-indicator
      class="text-center d-block"
      *ngIf="saveStarted"
    ></sh-loading-indicator>
  </div>

  <div class="modal-footer d-flex">
    <div class="flex-grow-1">
      <button closeModal type="button" class="btn btn-block btn-link">
        {{ 'shared.terms.cancel' | translate }}
      </button>
    </div>
    <div class="flex-grow-1">
      <button (click)="save()" type="button" class="btn btn-block btn-success">
        {{ 'shared.terms.save' | translate }}
      </button>
    </div>
  </div>
</div>
