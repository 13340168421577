import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CVVActivitiesPage } from './pages/cvv-activities/cvv-activities.page';
import { CommonModule } from '@angular/common';
import { CustomCVVComponentsModule } from './components/cvv-components.module';
import { CustomCVVPagesModule } from './pages/cvv-pages.module';
import { CustomCVVServicesModule } from './services/cvv-services.module';
import { CustomCVVPipesModule } from './pipes/cvv-pipes.module';

const routes: Routes = [
  {
    path: 'activities',
    component: CVVActivitiesPage,
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    // Child Modules
    CustomCVVComponentsModule,
    CustomCVVPagesModule,
    CustomCVVPipesModule,
    CustomCVVServicesModule,
  ],
})
export class CustomCVVModule {}
