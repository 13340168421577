<div class="card">
  <div class="card-header">
    {{ 'nautical.ship.recentShips' | translate }}
  </div>
  <div class="list-group">
    <div
      class="list-group-item list-group-item-action"
      *ngFor="let vessel of vessels"
      (click)="openShipDetails(vessel)"
    >
      <div class="d-flex justify-content-between align-items-center">
        <strong>{{ vessel.name }}</strong>
        <!-- <span class="badge badge-primary badge-pill">{{ vessel.flagCode }}</span> -->
      </div>

      <div class="tags">
        <small>
          {{ vessel.displayTags }}
        </small>
      </div>
    </div>
  </div>
  <div class="card-footer card-footer-clickable">
    <a routerLink="/nautical/vessels"
      >{{ 'shared.terms.more' | translate }}
      {{ 'nautical.ship.ships' | translate }} &raquo;</a
    >
  </div>
</div>
