<div
  class="card tile tile-clickable"
  *ngFor="let company of companies"
  (click)="selectCompany(company)"
  [ngClass]="{ selected: selectedCompany && selectedCompany.id === company.id }"
>
  <div class="card-body">
    <div class="row seperated-columns">
      <div class="col-5">
        <strong>
          {{ company.name | uppercase }}
        </strong>
        <ul class="list-inline list-inline-seperated">
          <li class="list-inline-item">
            <span class="text-muted">{{
              'companies.company.model.number' | translate
            }}</span>
            {{ company.number }}
          </li>
        </ul>
      </div>
      <div class="col-7">
        <div class="attribute-group-item">
          <dt>{{ 'companies.company.model.types' | translate }}</dt>
          <ul class="list-inline list-inline-seperated">
            <li
              class="list-inline-item"
              *ngFor="let companyType of company.companyTypes"
            >
              {{ companyType.name }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
