import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MapMarkerColor } from '../models/map-markers.model';

@Pipe({ name: 'mapMarkerColorTranslate' })
export class MapMarkerColorTranslatePipe implements PipeTransform {
  mapMarkerColors: Object;

  constructor(private translate: TranslateService) {
    this.mapMarkerColors = {
      [MapMarkerColor.CYAN]: 'map.marker.color.cyan',
      [MapMarkerColor.DARKBLUE]: 'map.marker.color.darkblue',
      [MapMarkerColor.GREEN]: 'map.marker.color.green',
      [MapMarkerColor.GREY]: 'map.marker.color.grey',
      [MapMarkerColor.LIGHTBLUE]: 'map.marker.color.lightblue',
      [MapMarkerColor.MAGENTA]: 'map.marker.color.magenta',
      [MapMarkerColor.ORANGE]: 'map.marker.color.orange',
      [MapMarkerColor.PINK]: 'map.marker.color.pink',
      [MapMarkerColor.PURPLE]: 'map.marker.color.purple',
      [MapMarkerColor.RED]: 'map.marker.color.red',
      [MapMarkerColor.YELLOW]: 'map.marker.color.yellow',
    };
  }

  transform = (mapMarkerColor: MapMarkerColor): string =>
    this.translate.instant(this.mapMarkerColors[mapMarkerColor]);
}
