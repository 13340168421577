import { Injectable, inject } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { PreferenceModel } from '../../preference/models/preference.model';
import { SystemMessageModel } from '../../preference/models/system-message.model';
import { ProxyService } from '@seahorse/domain';
import { IdentityServiceBase } from '@seahorse/auth';
import * as _ from 'underscore';

const TOKEN_KEY = 'seahorse-token';

@Injectable({
  providedIn: 'root',
})
export class IdentityService extends IdentityServiceBase {
  private preferences: PreferenceModel[] = [];

  private _numberOfInboxData = 0;
  private _latestReleaseNote: SystemMessageModel = new SystemMessageModel();
  private _proxyService = inject(ProxyService);

  get numberOfInboxData(): number {
    return this._numberOfInboxData;
  }

  set numberOfInboxData(value: number) {
    this._numberOfInboxData = value;
  }

  get latestReleaseNote(): SystemMessageModel {
    return this._latestReleaseNote;
  }

  set latestReleaseNote(value: SystemMessageModel) {
    this._latestReleaseNote = value;
  }

  get token(): string {
    return localStorage.getItem(TOKEN_KEY);
  }

  set token(value: string) {
    value
      ? localStorage.setItem(TOKEN_KEY, value)
      : localStorage.removeItem(TOKEN_KEY);
  }

  constructor(private authService: MsalService) {
    super();
  }

  override clearIdentity() {
    localStorage.clear();
    window.sessionStorage.clear();
    // use location.replace in stead of router.navigate, this case the html will reload correctly after login with other permissions
    window.location.replace('/account/login');

    if (this.authService.getAccount()) {
      this.authService.logout();
    }
  }

  getPreferences(): PreferenceModel[] {
    // always return a copy, use the method 'setPermissions' to update the preferences
    return _.map<PreferenceModel[], any>(this.preferences, _.clone);
  }

  override hasPermission(permission: string) {
    if (permission.indexOf(',') > -1) {
      return (
        _.intersection(
          permission.split(',').map((p) => p.trim()),
          this.permissions
        ).length > 0
      );
    } else {
      return this.permissions.indexOf(permission) > -1;
    }
  }

  override hasFeature(feature: string): boolean {
    if (!this.isAuthenticated) {
      return false;
    }

    return this._proxyService.enabledFeatures.indexOf(feature) !== -1;
  }

  override setPermissions(permissions: any) {
    if (!permissions) {
      return;
    }

    for (const prop in permissions) {
      if (permissions[prop].length === 0) {
        this.permissions.push(prop);
      } else {
        const mapped = _.map(permissions[prop], (p) =>
          prop === p ? prop : prop + '.' + p
        );
        Array.prototype.push.apply(this.permissions, mapped);
      }
    }
  }

  setPreferences(input: PreferenceModel[]) {
    if (!input) {
      return;
    }

    _.each(input, (preference) => {
      const index = _.findIndex(this.preferences, (p) => {
        return p['id'] === preference['id'];
      });

      if (index === -1) {
        this.preferences.push(preference);
      } else {
        this.preferences[index].fieldValue = preference.fieldValue;
      }
    });
  }
}
