<div
  class="attribute-group attribute-group-border"
  [class]="'attribute-group-col-' + columns"
  *ngFor="let group of attributeGroups | keyvalue"
>
  <div
    class="attribute-group-title mb-2"
    *ngIf="showGroupTitles && group.key && group.key !== '-1'"
  >
    {{ group.key | translate }}
  </div>
  <div
    class="attribute-group-item"
    *ngFor="let attribute of group.value"
    [ngClass]="{
      'attribute-group-item-editable': allowEdit && !attribute.isReadOnly
    }"
    (click)="!isNewSetup && editField(attribute)"
  >
    <!-- old field edit -->
    <ng-container *ngIf="!isNewSetup">
      <dt>
        {{
          (displayField !== undefined && displayField !== null
            ? attribute[displayField]
            : attribute.fieldName
          ) | translate
        }}
      </dt>
      <dd shEllipsis>
        {{ attribute.fieldValue | fieldValue: attribute.fieldType }}
        <i
          class="fa-regular fa-pencil-alt fa-fw btn-editable text-muted float-right"
        ></i>
      </dd>
    </ng-container>

    <!-- new field edit -->
    <ng-container *ngIf="isNewSetup">
      <dt
        (click)="
          allowEdit &&
            !attribute.isReadOnly &&
            clickLabel($event, 'field#' + attribute.fieldCode)
        "
      >
        {{
          (displayField !== undefined && displayField !== null
            ? attribute[displayField]
            : attribute.fieldName
          ) | translate
        }}
        <span
          *ngIf="allowEdit && attribute.isRequired"
          class="text-danger font-weight-bolder"
          >*</span
        >
      </dt>

      <ng-container>
        <ng-template
          #formRef="fieldForm"
          tempFieldForm
          [model]="formModels[attribute.fieldCode]"
          [objectValue]="objectValue"
          [definition]="objectDefinition"
          (submit)="submitForm(attribute, $event)"
        >
          <dd
            [id]="'field#' + attribute.fieldCode"
            shEllipsis
            (click)="allowEdit && !attribute.isReadOnly && formRef.show()"
          >
            <ng-container
              *ngIf="
                attribute.fieldType === FieldType.List &&
                  attribute.badge &&
                  attribute.fieldValue?.length;
                else default
              "
            >
              <span [shBadge]="attribute.badge[attribute.fieldValue[0]]">
                <ng-container *ngTemplateOutlet="default"></ng-container>
              </span>
            </ng-container>
            <ng-container *ngIf="attribute.fieldType === FieldType.ProgressBar">
              <progress
                class="status-bar"
                [title]="attribute.displayValue ? attribute.displayValue : 0"
                [value]="getTaskProgressFraction(attribute.displayValue)"
                max="1"
              ></progress>
            </ng-container>
            <ng-template #default>
              <span
                (click)="
                  allowEdit &&
                    !attribute.isReadOnly &&
                    clickLabel($event, 'field#' + attribute.fieldCode)
                "
                *tempFieldMode="attribute.mode; value: attribute.displayValue"
                title="{{
                  attribute.displayValue
                    | fieldValue: attribute.fieldType
                    | shAppendText: attribute.appendText
                }}"
              >
                {{
                  attribute.displayValue
                    | fieldValue: attribute.fieldType
                    | shTruncate: 30
                    | shAppendText: attribute.appendText
                }}</span
              >
            </ng-template>
            <i
              (click)="
                allowEdit &&
                  !attribute.isReadOnly &&
                  clickLabel($event, 'field#' + attribute.fieldCode)
              "
              class="fa-regular fa-pencil-alt fa-fw btn-editable text-muted float-right"
            ></i>

            <ng-container
              *ngIf="
                attribute.showCopyButton &&
                attribute.displayValue !== undefined &&
                attribute.displayValue !== null &&
                attribute.displayValue !== ''
              "
            >
              <temp-copy-content-button
                [content]="
                  attribute.displayValue
                    | fieldValue: attribute.fieldType
                    | shAppendText: attribute.appendText
                "
              ></temp-copy-content-button>
            </ng-container>
          </dd>
        </ng-template>
      </ng-container>
    </ng-container>
  </div>
</div>
