import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { NotificationService } from '@seahorse/common';
import { NauticalGeographyDataService } from '../../services/nautical-geography-data.service';
import { NauticalWaypointModel } from '@seahorse/domain';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({ templateUrl: 'nautical-waypoint-picker-dialog.component.html' })
export class NauticalWaypointPickerDialogComponent {
  @Input() nauticalWaypoint: NauticalWaypointModel;
  @Input() isRequired?: boolean;
  @Input() title?: string;

  @Output() onConfirm: EventEmitter<{
    nauticalWaypoint: NauticalWaypointModel;
  }>;

  filter?: string;
  portId: number;
  nauticalWaypointsLoading: boolean;
  nauticalWaypointsLoadingFailed: boolean;

  constructor(
    private translate: TranslateService,
    private modal: NgbActiveModal,
    private notification: NotificationService,
    private nauticalGeographyData: NauticalGeographyDataService
  ) {
    this.nauticalWaypoint = null;
    this.isRequired = true;
    this.nauticalWaypointsLoading = false;
    this.nauticalWaypointsLoadingFailed = false;
  }

  nauticalWaypointFormatter = (
    nauticalWaypoint: NauticalWaypointModel
  ): string => nauticalWaypoint.name.toUpperCase();

  nauticalWaypointsFormatter = (
    nauticalWaypoint: NauticalWaypointModel
  ): string => {
    if (nauticalWaypoint.code && nauticalWaypoint.name) {
      return (
        nauticalWaypoint.name.toUpperCase() + ' (' + nauticalWaypoint.code + ')'
      );
    }
    if (nauticalWaypoint.number && nauticalWaypoint.name) {
      return (
        nauticalWaypoint.name.toUpperCase() +
        ' (' +
        nauticalWaypoint.number +
        ')'
      );
    } else {
      return nauticalWaypoint.name.toUpperCase();
    }
  };

  searchNauticalWaypoint = (input$: Observable<string>): any =>
    input$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.nauticalWaypointsLoading = true)),
      switchMap((s) =>
        this.nauticalGeographyData
          .getWaypointsByPort(this.portId, s, this.filter)
          .pipe(
            tap(() => (this.nauticalWaypointsLoadingFailed = false)),
            map((r) => r),
            catchError((e) => {
              this.nauticalWaypointsLoadingFailed = true;

              this.notification.showError(
                e,
                this.translate.instant('shared.terms.failed')
              );
              return of([]);
            })
          )
      ),

      tap(() => (this.nauticalWaypointsLoading = false))
    );

  setNull = () => (this.nauticalWaypoint = null);

  confirm() {
    this.modal.close(this.nauticalWaypoint);
  }
}
