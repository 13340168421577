<div class="card">
  <div class="card-header">
    {{ title ? title : ('customData.content.title' | translate) }}
  </div>

  <div
    class="card-body"
    [ngClass]="{ 'card-body-table': entries && entries.length > 0 }"
  >
    <sh-loading-indicator
      *ngIf="loading"
      class="d-block text-center"
    ></sh-loading-indicator>
    <ng-container *ngIf="!loading">
      <ca-custom-content-list
        *ngIf="entries && entries.length > 0"
        [allowSelection]="allowSelection"
        [entryId]="null"
        [entries]="entries"
        [columns]="columns"
        [definition]="definition"
        (onEntrySelected)="onSelected($event)"
      >
      </ca-custom-content-list>
      <div
        class="text-muted text-center"
        *ngIf="!entries || entries.length === 0"
      >
        {{ 'shared.terms.noResult' | translate }}
      </div>
    </ng-container>
  </div>
</div>
