export interface LanguageModel {
  label: string;
  value: string;
  flag: string;
  isDefault: boolean;
}

export const Languages: LanguageModel[] = [
  {
    label: 'English',
    value: 'en',
    flag: 'flag-icon flag-icon-gb',
    isDefault: true,
  },
  {
    label: 'Nederlands',
    value: 'nl',
    flag: 'flag-icon flag-icon-nl',
    isDefault: false,
  },
];
