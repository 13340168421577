import * as moment from 'moment';

export class PeriodDatesModel {
  start!: moment.Moment;
  end!: moment.Moment;

  constructor(override?: PeriodDatesModel) {
    this.start = moment().startOf('day');
    this.end = moment().endOf('day');

    if (override) {
      if (override.start.isSameOrBefore(override.end)) {
        this.start = override.start.startOf('day');
        this.end = override.end.endOf('day');
      } else {
        this.start = override.end.startOf('day');
        this.end = override.start.endOf('day');
      }
    }
  }
}

export enum PeriodInterval {
  WEEK = 1,
  MONTH = 2,
  QUARTER = 3,
  YEAR = 4,
  HALFDECADE = 5,
}
