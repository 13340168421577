<div class="form-row">
  <div class="form-group col-12 col-sm-9">
    <label for="subject" class="required">
      <b>{{ 'tasks.definition.model.subject' | translate }}</b>
    </label>
    <input
      [(ngModel)]="taskDefinition.subject"
      [placeholder]="'tasks.definition.placeholder.subject' | translate"
      required
      type="text"
      id="subject"
      name="subject"
      class="form-control"
    />
  </div>
  <div class="form-group col-12 col-sm-3">
    <label for="daysRequired">
      <b>{{ 'tasks.definition.model.daysRequired' | translate }}</b>
    </label>
    <input
      [(ngModel)]="taskDefinition.daysRequired"
      [placeholder]="'tasks.definition.placeholder.daysRequired' | translate"
      required
      type="number"
      id="daysRequired"
      name="daysRequired"
      class="form-control"
    />
  </div>
  <div class="form-group col-12">
    <label for="description">
      <b>{{ 'shared.terms.description' | translate }}</b>
    </label>
    <input
      [(ngModel)]="taskDefinition.description"
      [placeholder]="'tasks.definition.placeholder.description' | translate"
      required
      type="text"
      id="description"
      name="description"
      class="form-control"
    />
  </div>
  <div class="form-group col-12 col-sm-6">
    <label for="assignedTo">
      <b>{{ 'tasks.definition.model.assignedTo' | translate }}</b>
    </label>
    <div class="input-group">
      <select
        [(ngModel)]="taskDefinition.assignedTo"
        [disabled]="loadingUsers"
        id="assignedTo"
        name="assignedTo"
        class="custom-select"
      >
        <option selected [ngValue]="null">
          {{ 'shared.terms.choose' | translate }}...
        </option>
        <optgroup>
          <option *ngFor="let user of users()" [value]="user.value">
            {{ user.title }}
          </option>
        </optgroup>
      </select>
    </div>
  </div>
  <div class="form-group col-12 col-sm-6">
    <label for="taskType" class="required">
      <b>{{ 'tasks.definition.model.taskType' | translate }}</b>
    </label>
    <div class="input-group">
      <select
        [(ngModel)]="taskDefinition.taskType"
        id="taskType"
        name="taskType"
        class="custom-select"
      >
        <option selected [ngValue]="null">
          {{ 'shared.terms.choose' | translate }}...
        </option>
        <optgroup>
          <option *ngFor="let taskType of taskTypes" [ngValue]="taskType">
            {{ taskType | taskType }}
          </option>
        </optgroup>
      </select>
    </div>
  </div>
  <div class="form-group col-12">
    <label for="remarks">
      <b>{{ 'tasks.definition.model.remarks' | translate }}</b>
    </label>
    <div class="input-group">
      <textarea
        [(ngModel)]="taskDefinition.remarks"
        [placeholder]="'tasks.definition.placeholder.remarks' | translate"
        type="text"
        id="remarks"
        name="remarks"
        class="form-control"
      ></textarea>
    </div>
  </div>
</div>
