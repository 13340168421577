<div class="form-group">
  <label class="d-flex justify-content-between" for="tariff" *ngIf="showLabels">
    <span [class.required]="isRequired">
      <b>{{ 'invoicing.tariffGroups.model.tariff' | translate }}</b>
    </span>
    <div
      class="custom-control custom-checkbox ml-2 text-overflow"
      style="max-width: 290px"
      title="{{ 'invoicing.tariffs.hideUnapproved' | translate }}"
    >
      <input
        [(ngModel)]="hideUnapprovedTariffs"
        type="checkbox"
        class="custom-control-input"
        id="hideUnapprovedTariffs"
      />
      <label class="custom-control-label" for="hideUnapprovedTariffs">{{
        'invoicing.tariffs.hideUnapproved' | translate
      }}</label>
    </div>
  </label>
  <div class="input-group">
    <ng-container *ngIf="!hideTariffGroups">
      <span *ngIf="loadingTariffGroups">
        <i class="fa-regular fa-spinner fa-spin fa-fw"></i>
      </span>
      <ng-container
        *ngIf="
          !loadingTariffGroups && tariffGroups && tariffGroups.length > 0;
          then showSelectTariffGroups;
          else showNoTariffGroupText
        "
      ></ng-container>
    </ng-container>
    <span *ngIf="loadingTariffs || loadingTariff">
      <i class="fa-regular fa-spinner fa-spin fa-fw"></i>
    </span>
    <ng-container
      *ngIf="
        selectedTariff || (!loadingTariffs && tariffs && tariffs.length > 0);
        then showSelectTariffs;
        else showNoTariffText
      "
    ></ng-container>
  </div>
</div>

<ng-template #showSelectTariffGroups>
  <select
    [(ngModel)]="selectedTariffGroup"
    [disabled]="loadingTariffGroups"
    (change)="onSelectedTariffGroupChanged()"
    id="tariffGroup"
    name="tariffGroup"
    class="form-control"
    #tariffGroup="ngModel"
    required
  >
    <option [value]="null">
      {{ 'shared.terms.select' | translate }}
      {{ 'invoicing.tariffGroups.model.tariffGroup' | translate }}...
    </option>
    <option *ngFor="let tariffGroup of tariffGroups" [ngValue]="tariffGroup">
      {{ tariffGroup.name }}
    </option>
  </select>
  <small
    *ngIf="tariffGroup.invalid && submitted && isRequired"
    class="text-danger"
  >
    {{ 'shared.terms.requiredFieldText' | translate }}
  </small>
</ng-template>
<ng-template #showNoTariffGroupText>
  <span class="input-group-text">{{
    'invoicing.tariffs.noTariffGroupsAvailable' | translate
  }}</span>
</ng-template>

<ng-template #showSelectTariffs>
  <select
    [(ngModel)]="selectedTariff"
    [disabled]="loadingTariffs || !selectedTariffGroup"
    (change)="onSelectedTariffChanged(selectedTariff)"
    id="tariff"
    name="tariff"
    class="form-control"
    #tariff="ngModel"
    required
  >
    <option [value]="null">
      {{ 'shared.terms.select' | translate }}
      {{ 'invoicing.tariffGroups.model.tariff' | translate }}...
    </option>
    <option
      *ngFor="let tariff of tariffs"
      [ngValue]="tariff"
      [ngClass]="{ 'text-danger': tariff.approved !== true }"
      [hidden]="tariff.approved !== true && hideUnapprovedTariffs"
    >
      {{ tariff.name }}
    </option>
  </select>
  <small *ngIf="tariff.invalid && submitted && isRequired" class="text-danger">
    {{ 'shared.terms.requiredFieldText' | translate }}
  </small>
</ng-template>
<ng-template #showNoTariffText>
  <span class="input-group-text">{{
    'invoicing.tariffs.noTariffsAvailable' | translate
  }}</span>
</ng-template>
