<form #contactForm="ngForm">
  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label class="control-label required"
          ><b>{{ 'shared.terms.name' | translate }}</b></label
        >
        <input
          required
          #nameInfo="ngModel"
          type="text"
          name="nameInfo"
          [(ngModel)]="model.nameInfo"
          class="form-control"
        />
        <small
          *ngIf="(nameInfo.value == null || nameInfo.value === '') && submitted"
          class="text-danger"
          >{{ 'companies.contact.errors.nameEmpty' | translate }}</small
        >
      </div>
    </div>

    <div class="col-6">
      <div class="form-group">
        <label class="control-label"
          ><b>{{ 'companies.contact.model.function' | translate }}</b></label
        >
        <input
          #function="ngModel"
          type="text"
          name="function"
          [(ngModel)]="model.function"
          class="form-control"
        />
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label class="control-label required"
          ><b>{{ 'companies.contact.model.status' | translate }}</b></label
        >
        <ng-select
          required
          #status
          name="status"
          [items]="contactStatuses"
          placeholder="Select status"
          bindValue="value"
          [clearable]="false"
          [(ngModel)]="model.status"
          [searchable]="false"
          [class.has-danger]="submitted && model.status == null"
        >
          <ng-template ng-label-tmp let-item="item">
            <span [innerHTML]="item.icon"></span>{{ item.label }}
          </ng-template>
          <ng-template ng-option-tmp let-item="item">
            <span [innerHTML]="item.icon"></span>{{ item.label }}
          </ng-template>
        </ng-select>
        <small *ngIf="submitted && model.status == null" class="text-danger">{{
          'companies.contact.errors.statusEmpty' | translate
        }}</small>
      </div>
    </div>

    <div class="col-6">
      <div class="form-group">
        <label
          ><b>{{ 'companies.contact.model.isPrimary' | translate }}</b></label
        >
        <div class="custom-control custom-radio">
          <input
            id="true"
            type="radio"
            class="custom-control-input"
            [value]="true"
            name="isPrimaryContact"
            [(ngModel)]="model.isPrimaryContact"
          />
          <label for="true" class="custom-control-label">{{
            'shared.terms.yes' | translate
          }}</label>
        </div>

        <div class="custom-control custom-radio">
          <input
            id="false"
            type="radio"
            class="custom-control-input"
            [value]="false"
            name="isPrimaryContact"
            [(ngModel)]="model.isPrimaryContact"
          />
          <label for="false" class="custom-control-label">{{
            'shared.terms.no' | translate
          }}</label>
        </div>
      </div>
    </div>
  </div>

  <form *ngIf="!isLoadingDepartments" [formGroup]="form">
    <div class="form-group">
      <label>
        <b> {{ 'companies.contact.model.selectDepartments' | translate }}: </b>
      </label>
      <sh-multi-select
        formControlName="departments"
        [options]="departmentList"
      ></sh-multi-select>
    </div>
  </form>
</form>
