import { NgModule } from '@angular/core';
import { CustomDataServicesModule } from '../../custom-data/services/custom-data-services.module';

import { MapCustomActionsService } from './map-custom-actions.service';

@NgModule({
  imports: [CustomDataServicesModule],
  providers: [MapCustomActionsService],
})
export class MapCustomActionServicesModule {}
