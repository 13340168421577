import { Component, inject, Input } from '@angular/core';
import { AISMarkerModel } from '@seahorse/domain';
import { Router } from '@angular/router';
import * as mapboxgl from 'mapbox-gl';

@Component({
  selector: 'ca-map-box-ship-display',
  templateUrl: './map-box-ship-display.component.html',
  styleUrls: ['./map-box-ship-display.component.scss'],
})
export class MapBoxShipDisplayComponent {
  @Input() set aisMarker(aisMarker: AISMarkerModel) {
    if (!aisMarker) {
      return;
    }
    if (!this.map) {
      this.buildMap(aisMarker);
    }
  }

  // default settings
  map: mapboxgl.Map;
  style = 'mapbox://styles/mapbox/outdoors-v9';

  // data
  source: unknown;
  markers: unknown[] = [];

  private _router = inject(Router);

  buildMap(aisMarker: AISMarkerModel) {
    this.map = new mapboxgl.Map({
      attributionControl: false,
      container: 'nautical-map-ship-display',
      style: this.style,
      bearing: 15,
      center: [aisMarker.longitude, aisMarker.latitude],
      zoom: 8,
    });

    /// Add map controls
    this.map.addControl(new mapboxgl.NavigationControl());

    /// Add realtime firebase data on map load
    this.map.on('load', () => {
      const marker = new mapboxgl.Marker()
        .setLngLat([aisMarker.longitude, aisMarker.latitude])
        .addTo(this.map);

      marker.getElement().addEventListener('click', () => {
        this._router
          .navigate(['/map'], {
            queryParams: {
              lat: aisMarker.latitude,
              lng: aisMarker.longitude,
              mmsi: aisMarker.mmsi,
            },
          })
          .then()
          .catch()
          .finally();
      });
    });
  }
}
