export class UserModel {
  id: string;
  phone: string;
  email: string;
  surname: string;
  givenName: string;
  logonName: string;
  allowLogon: boolean;
  enabled: boolean;
  organisationId: string;
  organisationName: string;
  mfa: boolean;

  static validate(user: UserModel) {
    if (user.givenName == null) {
      return false;
    }

    user.givenName = user.givenName.trim();
    if (user.givenName === '') {
      user.givenName = null;
      return false;
    }

    if (user.surname == null) {
      return false;
    }

    user.surname = user.surname.trim();
    if (user.surname === '') {
      user.surname = null;
      return false;
    }

    if (!user.id) {
      if (user.logonName == null) {
        return false;
      }

      user.logonName = user.logonName.trim();
      if (user.logonName === '') {
        user.logonName = null;
        return false;
      }
    }

    if (user.email == null) {
      return false;
    }

    user.email = user.email.trim();
    if (user.email === '') {
      user.email = null;
      return false;
    }

    return true;
  }
}

export class ChangePasswordModel {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export class ActiveUsersModel {
  activeUsers: number;
}

export class UserInfoModel {
  user: UserModel;
  permissions: any;
}

export class AdminUserModel extends UserModel {
  password?: string;
  emailAddress: string;
  telephoneNumber: string;
  sendEmail?: boolean;

  static override validate(user: AdminUserModel): boolean {
    if (
      !user.givenName ||
      !user.surname ||
      !user.emailAddress ||
      !user.logonName
    ) {
      return false;
    }

    const emailRegex = /\S+@\S+\.\S+$/;

    if (!emailRegex.test(user.emailAddress)) {
      return false;
    }

    if (user.logonName && !emailRegex.test(user.logonName)) {
      return false;
    }

    return true;
  }

  constructor(override?: Partial<AdminUserModel>) {
    super();

    if (override) {
      Object.assign(this, override);
    }
  }
}
