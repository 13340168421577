<table class="table table-clickable">
  <thead *ngIf="showHeader">
    <tr>
      <th scope="col">{{ 'shared.terms.name' | translate }}</th>
      <th scope="col"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let department of departments">
      <td
        (click)="onEvent.emit({ action: 'editDepartment', data: department })"
      >
        {{ department.name }}
      </td>
      <td class="btn-list">
        <div class="dropdown p-0">
          <button
            type="button"
            class="btn btn-light dropdown-toggle"
            id="rowMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis-v fa-fw"></i>
          </button>
          <div class="btn dropdown-menu" aria-labelledby="rowMenuButton">
            <button
              class="dropdown-item"
              (click)="
                onEvent.emit({ action: 'deleteDepartment', data: department })
              "
            >
              <i class="fa-solid fa-trash fa-fw"></i>
              {{ 'shared.terms.delete' | translate }}
            </button>
          </div>
        </div>
      </td>
    </tr>
  </tbody>
</table>
