<div class="page-with-header">
  <section class="page-header">
    <div class="d-flex justify-content-between px-3 py-1">
      <div class="custom-control custom-switch">
        <input
          [(ngModel)]="enableCustomDashboard"
          type="checkbox"
          class="custom-control-input"
          id="enableCustomDashboard"
          name="enableCustomDashboard"
          (change)="handleCustomDashboardPreference()"
        />
        <label for="enableCustomDashboard" class="custom-control-label">
          {{ 'widgets.enable' | translate }}
        </label>
      </div>
      <button type="button" class="btn btn-primary" (click)="createWidget()">
        <i class="fas fa-plus fa-fw"></i>
        {{ 'shared.terms.add' | translate }}
      </button>
    </div>
  </section>
  <div class="container-fluid py-3">
    <div class="pr-2">
      <ejs-dashboardlayout
        id="dashboard"
        columns="4"
        #dashboard
        [cellSpacing]="cellSpacing"
        [allowResizing]="true"
        [hidden]="!dashboardPreference?.fieldValue || !enableCustomDashboard"
        (change)="widgetChange()"
      >
      </ejs-dashboardlayout>
      <div
        *ngIf="dashboardPreference && !dashboardPreference?.fieldValue"
        class="text-center mt-5"
      >
        <p class="font-weight-bold">
          {{ 'widgets.dashboard.empty' | translate }}
        </p>
        <p>{{ 'widgets.dashboard.choose' | translate }}</p>
      </div>
    </div>
  </div>
</div>
