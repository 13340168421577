import { Injectable } from '@angular/core';
import { ResultWrapper } from '@seahorse/common';
import { DataContextService } from '@seahorse/common';
import { Observable } from 'rxjs';
import { CustomDefaultDataModel } from '@seahorse/domain';

@Injectable()
export class CustomDefaultDataService {
  constructor(private dataContext: DataContextService) {}

  getDefaultDataGroups(
    objectKey: string
  ): Observable<ResultWrapper<CustomDefaultDataModel[]>> {
    return this.dataContext.get<CustomDefaultDataModel[]>(
      'customdata/defaults/group?target=' + objectKey
    );
  }
}
