import { Component, Input } from '@angular/core';
import { TaskDefinitionModel } from '../../models/task-definition.model';

@Component({
  selector: 'task-definition-info',
  templateUrl: './task-definition-info.component.html',
})
export class TaskDefinitionInfoComponent {
  @Input() taskDefinitions: TaskDefinitionModel[];
  @Input() loading: boolean;

  constructor() {
    this.taskDefinitions = [];
    this.loading = false;
  }
}
