import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';

import { NotificationService } from '@seahorse/common';

export const responseInterceptor: HttpInterceptorFn = (
  request: HttpRequest<any>,
  next: HttpHandlerFn
) => {
  const router = inject(Router);
  const notificationService = inject(NotificationService);

  const handleError = (error: HttpErrorResponse) => {
    switch (error.status) {
      case 100:
        notificationService.showError('Unknown error!', 'Failed');
        break;
      case 401:
        // window.location.href = '/account/login'
        break;
      case 403:
        break;
      case 404:
        // router.navigate(['not-found']);
        break;
      case 490:
        // notificationService.showError('Not enough permissions!', 'Denied');
        break;
      case 500:
        notificationService.showError('Something went wrong!', 'Failed');
        break;
      default:
        break;
    }
  };

  return next(request).pipe(
    tap(
      (event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          //
        }
      },
      (err: any) => {
        handleError(err);
      }
    )
  );
};
