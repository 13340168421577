import { Injectable } from '@angular/core';
import { CollectionDisplay } from '../../layout/models/collection-display.model';
import { SelectedSearchModel } from '../../layout/models/selectedSearch.model';
import { ShipIssuesFilterModel } from '../../superuser-nautical/models/ship-data-issues.model';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  currentModuleName = '';

  private searchResultCountSource = new Subject<number>();
  private searchSource = new Subject<SelectedSearchModel>();
  private selectedViewSource = new Subject<CollectionDisplay>();
  private openComponent = new Subject();
  private selectedFilterSource = new Subject<any>();
  private selectedFiltersSource = new Subject<any>();
  private toggleInactiveSource = new Subject<boolean>();

  searchChanged$ = this.searchSource.asObservable();
  searchResultCountChanged$ = this.searchResultCountSource.asObservable();
  selectedViewCalled$ = this.selectedViewSource.asObservable();
  openComponent$ = this.openComponent.asObservable();
  selectedFilterChanged$ = this.selectedFilterSource.asObservable();
  selectedFiltersChanged$ = this.selectedFiltersSource.asObservable();
  toggleInactiveChanged$ = this.toggleInactiveSource.asObservable();

  searchChanged(search: SelectedSearchModel) {
    this.searchSource.next(search);
  }

  searchResultCountChanged(count: number) {
    this.searchResultCountSource.next(count);
  }

  setSelectedView(view: CollectionDisplay) {
    this.selectedViewSource.next(Number(view));
  }

  open() {
    this.openComponent.next();
  }

  setSelectedFilter(filter: string) {
    this.selectedFilterSource.next(filter);
  }

  setSelectedFilters(filter: ShipIssuesFilterModel) {
    this.selectedFiltersSource.next(filter);
  }

  toggleInactiveAccounts(showInactive: boolean) {
    this.toggleInactiveSource.next(showInactive);
  }
}
