import { inject } from '@angular/core';
import { FormModel, SeahorseFormBuilder } from '@seahorse/forms';

export type ExpenseListForm = FormModel<typeof CreateExpenseListForm>;

export function CreateExpenseListForm() {
  const fb = inject(SeahorseFormBuilder);

  return fb.group({
    customer: fb.control<string | undefined>(undefined),
  });
}
