import { UpperCasePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import * as _ from 'underscore';

import {
  CoordinatePointModel,
  NauticalVisitDetailsModel,
} from '@seahorse/domain';
import { NauticalVisitDataService } from '@seahorse/domain';
import { MapDataService } from '@seahorse/domain';
import { tap, concatMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ca-nautical-visit-summary',
  templateUrl: './nautical-visit-summary.component.html',
})
export class NauticalVisitSummaryComponent {
  attributes: any[] = [];
  isLoading = false;
  visit: NauticalVisitDetailsModel;
  shipPosition: CoordinatePointModel = null;

  @Input('visit-id') set visitid(value: number) {
    this.isLoading = true;
    this.attributes = [];

    this.visitData
      .getDetails(value)
      .pipe(
        tap((result) => {
          if (result.portMovements) {
            result.portMovements = _.sortBy(
              result.portMovements,
              'movementType'
            );
          }

          if (result.portVisitAttributes) {
            result.portVisitAttributes = _.sortBy(
              result.portVisitAttributes,
              'createdOn'
            );
          }

          if (result.portVisitEvents) {
            result.portVisitEvents = _.sortBy(
              result.portVisitEvents,
              'createdOn'
            );
          }

          this.visit = result;

          if (this.visit) {
            if (this.visit.ship) {
              this.attributes.push({
                fieldName: this.translate.instant('nautical.terms.ship'),
                fieldValue: this.uppercasePipe.transform(this.visit.ship.name),
              });
            }

            if (this.visit.referenceNumber) {
              this.attributes.push({
                fieldName: this.translate.instant(
                  'nautical.terms.referenceNumber'
                ),
                fieldValue: this.visit.referenceNumber,
              });
            }

            if (this.visit.portAgent) {
              this.attributes.push({
                fieldName: this.translate.instant('nautical.terms.portAgent'),
                fieldValue: this.uppercasePipe.transform(
                  this.visit.portAgent.name
                ),
              });
            }
          }
        }),
        concatMap((result) =>
          this.mapDataService.getMarkerForMMSI(result.ship.mmsi)
        )
      )
      .subscribe(
        (response) => {
          if (response && response.latitude && response.longitude) {
            const point = new CoordinatePointModel();
            point.latitude = response.latitude;
            point.longitude = response.longitude;
            this.shipPosition = point;
          }
        },
        (error) => {},
        () => {
          this.isLoading = false;
        }
      );
  }

  constructor(
    private visitData: NauticalVisitDataService,
    private uppercasePipe: UpperCasePipe,
    private mapDataService: MapDataService,
    private translate: TranslateService
  ) {}

  visitAttributesForMovement(movementId: number) {
    if (!this.visit || !this.visit.portVisitAttributes) {
      return [];
    }

    return _.filter(
      this.visit.portVisitAttributes,
      (item) => item.portMovementId === movementId
    );
  }

  visitAttributesForVisit() {
    if (!this.visit || !this.visit.portVisitAttributes) {
      return [];
    }

    return _.filter(
      this.visit.portVisitAttributes,
      (item) => !item.portMovementId
    );
  }

  visitEventsForMovement(movementId: number) {
    if (!this.visit || !this.visit.portVisitEvents) {
      return [];
    }

    return _.filter(
      this.visit.portVisitEvents,
      (item) => item.portMovementId === movementId
    );
  }

  visitEventsForVisit() {
    if (!this.visit || !this.visit.portVisitEvents) {
      return [];
    }

    return _.filter(this.visit.portVisitEvents, (item) => !item.portMovementId);
  }
}
