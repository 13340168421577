import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

import { KPActivityModel } from '../../models/kp-activity.model';

@Component({
  selector: 'ca-custom-kp-complete-activity-modal',
  templateUrl: 'complete-activity-modal.component.html',
})
export class KPCompleteActivityModal {
  errors = [];
  quantity: number = null;

  @Input() activity: KPActivityModel = null;
  @Input() activityTypeGroup: string = null;

  constructor(
    public modal: NgbActiveModal,
    public translateService: TranslateService
  ) {}

  save() {
    this.errors = [];

    if (this.activity.hasWaitingTime) {
      if (!this.activity.waitingTime || isNaN(this.activity.waitingTime)) {
        this.errors.push('customers.kp.errors.waitingTimeRequired');
      }
      if (
        !this.activity.waitingTimeReason ||
        this.activity.waitingTimeReason.trim() === ''
      ) {
        this.errors.push('customers.kp.errors.waitingTimeReasonRequired');
      }
    } else {
      this.activity.waitingTime = null;
    }

    if (this.activity.cancelledOrder) {
      if (
        !this.activity.cancelledReason ||
        this.activity.cancelledReason.trim() === ''
      ) {
        this.errors.push('customers.kp.errors.cancelledReasonRequired');
      }
    } else {
      this.activity.cancelledReason = null;
    }

    if (this.errors.length > 0) {
      return;
    }

    if (!this.activity.cancelledOrder) {
      this.activity.cancelledOrder = false;
    }
    if (!this.activity.hasWaitingTime) {
      this.activity.hasWaitingTime = false;
    }
    if (!this.activity.waitingTime) {
      this.activity.waitingTime = 0;
    }
    if (!this.activity.motorboat1) {
      this.activity.motorboat1 = false;
    }
    if (!this.activity.motorboat2) {
      this.activity.motorboat2 = false;
    }
    if (!this.activity.plastic) {
      this.activity.plastic = false;
    }
    if (!this.activity.weekendTariffOff) {
      this.activity.weekendTariffOff = false;
    }

    this.modal.close({ activity: this.activity, pallets: this.quantity });
  }
}
