import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'underscore';

import { EventModel } from '../../../shared/models/event.model';
import { NauticalShipDataService, NauticalShipStatus } from '@seahorse/domain';
import { NauticalShipModel } from '@seahorse/domain';
import { NauticalShipTypeModel } from '@seahorse/domain';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { DropDownListOption } from '@seahorse/forms';
import { CreateNauticalShipModelForm } from './nautical-ship-model.form';

@Component({
  selector: 'ca-nautical-ship-modal',
  templateUrl: 'nautical-ship-modal.component.html',
})
export class NauticalShipModalComponent implements OnInit, OnDestroy {
  private subscriptions$ = Array<Subscription>();
  shipTypes: NauticalShipTypeModel[] = [];
  isNew = false;
  isInvalid = false;
  ship: NauticalShipModel;
  flagCodeErrors: string[];

  form = CreateNauticalShipModelForm();
  statusDropDownList: DropDownListOption[] = Object.keys(NauticalShipStatus)
    .filter((x) => isNaN(Number(x)))
    .map((x) => {
      return {
        label: x,
        value: NauticalShipStatus[x],
      };
    });

  @Input() shipId: string = null;

  constructor(
    private shipDataService: NauticalShipDataService,
    private modalService: NgbActiveModal,
    private translate: TranslateService
  ) {
    this.ship = new NauticalShipModel();
    this.flagCodeErrors = [];
  }

  ngOnInit() {
    this.loadShipTypes();

    this.isNew = this.shipId == null || this.shipId === undefined;

    if (!this.isNew) {
      this.subscriptions$.push(
        this.shipDataService.getById(this.shipId).subscribe(
          (result) => {
            this.ship = result;
            this.form.patchValue({status: result.status});
          }
        )
      );
    } else
      this.form.patchValue({status: 0});
  }

  ngOnDestroy() {
    this.subscriptions$.forEach((s) => s.unsubscribe());
  }

  loadShipTypes() {
    this.subscriptions$.push(
      this.shipDataService.getShipTypes().subscribe(
        (result: { result: unknown }) => {
          this.shipTypes = _.sortBy(result.result, 'name');
        }
      )
    );
  }

  changeShipType(newValue: NauticalShipTypeModel) {
    if (newValue) {
      this.ship.shipType = newValue;
      this.ship.shipTypeId = newValue.id;
      this.ship.shipTypeCategoryId = newValue.shipTypeCategoryId;
    }
  }

  close() {
    this.modalService.close(null);
  }

  save() {
    this.flagCodeErrors = [];
    this.isInvalid = !NauticalShipModel.validate(this.ship);
    this.ship.status = this.form.getRawValue().status;

    if (this.isInvalid === true) {
      if (!this.ship.flagCode) {
        this.flagCodeErrors.push(
          this.translate.instant('nautical.ship.errors.flagCodeEmpty')
        );
      }

      if (this.ship.flagCode && this.ship.flagCode.length !== 3) {
        this.flagCodeErrors.push(
          this.translate.instant('nautical.ship.errors.flagCodeMaxLength')
        );
      }

      return;
    }

    const result = new EventModel();
    result.action = this.isNew === true ? 'add' : 'update';
    result.data = this.ship;
    this.modalService.close(result);
  }
}
