import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nauticalVisitEventType',
})
export class NauticalVisitEventTypePipe implements PipeTransform {
  transform(value: number): string {
    switch (value) {
      case 1:
        return 'time registration';
      case 2:
        return 'activity';
      default:
        return 'unknown';
    }
  }
}
