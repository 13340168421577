import { Injectable } from '@angular/core';
import { ErrorMessage, ResultWrapper } from '@seahorse/common';
import {
  CustomDataAppendDataModel,
  CustomDataContentService,
  CustomDataSearchFieldModel,
  DataObjectKeyModel,
  ObjectState,
  SearchOperators,
} from '@seahorse/domain';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as _ from 'underscore';

import { OrderRegistrationsConstant } from '../constants/order-registrations.constant';
import {
  OrderRegistrationStatusLog,
  OrderRegistrationStatusLogModel,
} from '../models/order-registration-status-log.model';
import {
  OrderRegistrationModel,
  OrderRegistrationStatus,
} from '@seahorse/domain';
import { OrderRegistrationStatusLogPipe } from '../pipes/order-registration-status-log.pipe';
import { OrderRegistrationStatusLogDataService } from './order-registration-status-log-data.service';

@Injectable({
  providedIn: 'root',
})
export class OrderRegistrationsDataService {
  private keyModel: DataObjectKeyModel;

  constructor(
    private customData: CustomDataContentService,
    private orderRegistrationStatusLogData: OrderRegistrationStatusLogDataService,
    private orderRegistrationStatusLogPipe: OrderRegistrationStatusLogPipe
  ) {
    this.keyModel = new DataObjectKeyModel();

    this.keyModel.parseKey(OrderRegistrationsConstant.OBJECTTYPEKEY);
  }

  getAll(
    orderRegistrationStatus: OrderRegistrationStatus,
    pageIndex: number,
    pageSize: number,
    orderby: string,
    sortorder: string
  ): Observable<ResultWrapper<OrderRegistrationModel[]>> {
    const append = new CustomDataAppendDataModel();
    append.appendData = true;
    const keyModel = new DataObjectKeyModel();
    keyModel.parseKey('st-orderRegistration');

    return this.customData.searchDataByObjectKey(
      keyModel,
      orderRegistrationStatus ? orderRegistrationStatus.toString() : null,
      orderRegistrationStatus ? 'status' : null,
      null,
      orderby,
      sortorder,
      pageIndex,
      pageSize,
      append
    );
  }

  getLatest(limit?: number) {
    const searchFields: CustomDataSearchFieldModel[] = [
      {
        fieldName: 'status',
        searchOperator: SearchOperators.InArray,
        searchValue: [
          OrderRegistrationStatus.Ordered,
          OrderRegistrationStatus.AwaitingShipment,
          OrderRegistrationStatus.Pending,
          OrderRegistrationStatus.Shipping,
        ].join(','),
      },
    ];

    return this.customData.searchDataByObjectKeyAndFields(
      this.keyModel,
      searchFields,
      null,
      '__LastEditedOn',
      'desc',
      0,
      limit ? limit : 5,
      new CustomDataAppendDataModel(true)
    );
  }

  getUpcoming(limit?: number) {
    const searchFields: CustomDataSearchFieldModel[] = [
      {
        fieldName: 'status',
        searchOperator: SearchOperators.InArray,
        searchValue: [
          OrderRegistrationStatus.Ordered,
          OrderRegistrationStatus.AwaitingShipment,
          OrderRegistrationStatus.Pending,
          OrderRegistrationStatus.Shipping,
        ].join(','),
      },
      {
        fieldName: 'deliveryDate',
        searchOperator: SearchOperators.NotEquals,
        searchValue: '',
      },
    ];

    return this.customData.searchDataByObjectKeyAndFields(
      this.keyModel,
      searchFields,
      null,
      'deliveryDate',
      'asc',
      0,
      limit ? limit : 5,
      new CustomDataAppendDataModel(true)
    );
  }

  search(
    searchField: CustomDataSearchFieldModel,
    orderType: string,
    orderRegistrationStatus: OrderRegistrationStatus,
    pageIndex: number,
    pageSize: number,
    orderby: string,
    sortorder: string,
    additionalSearchFields?: CustomDataSearchFieldModel[]
  ): Observable<ResultWrapper<OrderRegistrationModel[]>> {
    const states = _.filter(_.keys(ObjectState), (k) => {
      return isNaN(Number(k)) && k !== 'Deleted';
    });
    const append = new CustomDataAppendDataModel(true);

    let searchFields = [
      {
        fieldName: 'status',
        searchOperator: SearchOperators.Equals,
        searchValue: orderRegistrationStatus,
      },
    ];

    if (additionalSearchFields && additionalSearchFields.length) {
      searchFields = searchFields.concat(additionalSearchFields);
    }

    if (searchField !== undefined && searchField !== null) {
      searchFields.push(searchField);
    }

    const keyModel = new DataObjectKeyModel();

    if (!orderType) {
      keyModel.parseKey('st-orderRegistration');
    } else {
      keyModel.parseKey('ct-' + orderType);
    }

    return this.customData.searchDataByObjectKeyAndFields(
      keyModel,
      searchFields,
      states,
      orderby,
      sortorder,
      pageIndex,
      pageSize,
      append
    );
  }

  get(
    id: OrderRegistrationModel['__Id']
  ): Observable<ResultWrapper<OrderRegistrationModel>> {
    const append = new CustomDataAppendDataModel();
    {
      append.appendData = true;
    }

    return new Observable<ResultWrapper<OrderRegistrationModel>>((s) => {
      let requiresOrderRegistrationStatusLog = false;

      const keyModel = new DataObjectKeyModel();
      keyModel.parseKey('st-orderRegistration');

      this.customData.getDataByIds(keyModel, [id], append).subscribe(
        (rCustom: ResultWrapper<OrderRegistrationModel[]>) => {
          const w: ResultWrapper<OrderRegistrationModel> = _.extend(
            _.omit(rCustom, ['result', 'hasResult']),
            { result: null, hasResult: false }
          );

          if (
            rCustom.hasResult &&
            rCustom.result &&
            rCustom.result.length === 1
          ) {
            w.hasResult = true;
            w.result = _.first(rCustom.result);

            s.next(w);
          } else {
            w.hasResult = false;
            const errorMessage = new ErrorMessage();

            if (rCustom.result) {
              if (rCustom.result.length) {
                errorMessage.code = 'multipleResults';
                errorMessage.message = 'Multiple results have been found';
                w.messages = w.messages.concat(errorMessage);

                s.next(w);
              } else {
                requiresOrderRegistrationStatusLog = true;

                this.orderRegistrationStatusLogData
                  .getCurrentByOrderRegistrationId(id)
                  .subscribe(
                    (
                      rOrderRegistrationStatusLogData: ResultWrapper<OrderRegistrationStatusLogModel>
                    ) => {
                      if (rOrderRegistrationStatusLogData.hasResult) {
                        errorMessage.code = 'lastLog';
                        errorMessage.message = `Last log: ${this.orderRegistrationStatusLogPipe.transform(
                          rOrderRegistrationStatusLogData.result
                            .orderRegistrationStatusLog
                        )} on ${
                          rOrderRegistrationStatusLogData.result.__CreatedOn
                        }`;
                        w.messages = w.messages.concat(errorMessage);

                        s.next(w);
                      } else {
                        errorMessage.code = 'noResults';
                        errorMessage.message = 'No results have been found';
                        w.messages = w.messages.concat(errorMessage);

                        s.next(w);
                      }
                    },
                    (eOrderRegistrationStatusLogData) =>
                      s.error(eOrderRegistrationStatusLogData),
                    () => s.complete()
                  );
              }
            } else {
              errorMessage.code = 'noResults';
              errorMessage.message = 'No results have been found';
              w.messages = w.messages.concat(errorMessage);

              s.next(w);
            }
          }
        },
        (eCustom) => s.error(eCustom),

        () => {
          if (!requiresOrderRegistrationStatusLog) {
            s.complete();
          }
        }
      );
    });
  }

  add(
    orderRegistration: OrderRegistrationModel
  ): Observable<ResultWrapper<OrderRegistrationModel>> {
    const keyModel: DataObjectKeyModel = new DataObjectKeyModel();

    keyModel.parseKey(orderRegistration.__DataObjectKey);

    return this.customData
      .addCustomerData(keyModel.SystemCode, orderRegistration)
      .pipe(
        tap((response: ResultWrapper<OrderRegistrationModel>) => {
          if (response.hasResult && response.result) {
            const log: OrderRegistrationStatusLogModel =
              new OrderRegistrationStatusLogModel();
            {
              log.$customcontent_orderregistration_id = response.result.__Id;
              log.$customcontent_orderregistration_status =
                response.result.status;
              log.orderRegistrationStatusLog =
                OrderRegistrationStatusLog.OrderRegistrationStatusCreated;
            }

            this.orderRegistrationStatusLogData.add(log).subscribe();
          }
        })
      );
  }

  update(
    orderRegistration: OrderRegistrationModel,
    currentOrderRegistrationStatus?: OrderRegistrationModel['status']
  ): Observable<ResultWrapper<OrderRegistrationModel>> {
    const keyModel: DataObjectKeyModel = new DataObjectKeyModel();

    keyModel.parseKey(orderRegistration.__DataObjectKey);

    return new Observable<ResultWrapper<OrderRegistrationModel>>((s) => {
      let requiresOrderRegistrationStatusLog = false;

      if (currentOrderRegistrationStatus != null) {
        this.customData
          .updateCustomerData(
            keyModel.SystemCode,
            orderRegistration.__Id,
            orderRegistration
          )
          .subscribe(
            (rCustom: ResultWrapper<OrderRegistrationModel>) => {
              if (
                rCustom.hasResult &&
                rCustom.result &&
                currentOrderRegistrationStatus !== rCustom.result.status
              ) {
                requiresOrderRegistrationStatusLog = true;
                const w: ResultWrapper<OrderRegistrationModel> = rCustom;

                const l: OrderRegistrationStatusLogModel =
                  new OrderRegistrationStatusLogModel();
                {
                  l.$customcontent_orderregistration_id = rCustom.result.__Id;
                  l.$customcontent_orderregistration_status =
                    rCustom.result.status;
                  l.orderRegistrationStatusLog =
                    OrderRegistrationStatusLog.OrderRegistrationStatusUpdated;
                }

                this.orderRegistrationStatusLogData.add(l).subscribe(
                  (
                    rOrderRegistrationStatusLog: ResultWrapper<OrderRegistrationStatusLogModel>
                  ) => {
                    w.messages = w.messages.concat(
                      rOrderRegistrationStatusLog.messages
                    );

                    s.next(w);
                  },
                  (eOrderRegistrationStatusLog) =>
                    s.error(eOrderRegistrationStatusLog),
                  () => s.complete()
                );
              } else {
                s.next(rCustom);
              }
            },

            (eCustom) => s.error(eCustom),

            () => {
              if (!requiresOrderRegistrationStatusLog) {
                s.complete();
              }
            }
          );
      } else {
        this.customData
          .getCustomerDataByIds(keyModel.SystemCode, [orderRegistration.__Id])
          .subscribe(
            (
              rGetCustomerDataByIds: ResultWrapper<OrderRegistrationModel[]>
            ) => {
              const c: ResultWrapper<OrderRegistrationModel> = _.extend(
                _.omit(rGetCustomerDataByIds, 'result'),
                { result: null }
              );
              const w: ResultWrapper<OrderRegistrationModel> = _.extend(
                _.omit(rGetCustomerDataByIds, [
                  'result',
                  'hasResult',
                  'messages',
                ]),
                { result: null, hasResult: false, messages: [] }
              );

              if (
                rGetCustomerDataByIds.hasResult &&
                rGetCustomerDataByIds.result &&
                rGetCustomerDataByIds.result.length === 1
              ) {
                c.hasResult = true;
                c.result = _.first(rGetCustomerDataByIds.result);

                this.customData
                  .updateCustomerData(
                    keyModel.SystemCode,
                    orderRegistration.__Id,
                    orderRegistration
                  )
                  .subscribe(
                    (
                      rUpdateCustomerData: ResultWrapper<OrderRegistrationModel>
                    ) => {
                      w.hasResult = rUpdateCustomerData.hasResult;
                      w.result = rUpdateCustomerData.result;
                      w.messages = c.messages.concat(
                        rUpdateCustomerData.messages
                      );

                      if (
                        rUpdateCustomerData.hasResult &&
                        rUpdateCustomerData.result &&
                        c.result.status !== orderRegistration.status
                      ) {
                        requiresOrderRegistrationStatusLog = true;

                        const l: OrderRegistrationStatusLogModel =
                          new OrderRegistrationStatusLogModel();
                        {
                          l.$customcontent_orderregistration_id =
                            rUpdateCustomerData.result.__Id;
                          l.$customcontent_orderregistration_status =
                            rUpdateCustomerData.result.status;
                          l.orderRegistrationStatusLog =
                            OrderRegistrationStatusLog.OrderRegistrationStatusUpdated;
                        }

                        this.orderRegistrationStatusLogData.add(l).subscribe(
                          (
                            rOrderRegistrationStatusLog: ResultWrapper<OrderRegistrationStatusLogModel>
                          ) => {
                            w.messages = w.messages.concat(
                              rOrderRegistrationStatusLog.messages
                            );

                            s.next(w);
                          },
                          (eOrderRegistrationStatusLog) =>
                            s.error(eOrderRegistrationStatusLog),
                          () => s.complete()
                        );
                      } else {
                        s.next(w);
                      }
                    }
                  );
              } else {
                c.hasResult = false;
                const errorMessage = new ErrorMessage();

                if (rGetCustomerDataByIds.result) {
                  if (rGetCustomerDataByIds.result.length) {
                    errorMessage.code = 'multipleResults';
                    errorMessage.message = 'Multiple results have been found';
                    c.messages = c.messages.concat(errorMessage);

                    s.next(c);
                  } else {
                    errorMessage.code = 'noResults';
                    errorMessage.message = 'No results have been found';
                    c.messages = c.messages.concat(errorMessage);

                    s.next(c);
                  }
                } else {
                  errorMessage.code = 'noResults';
                  errorMessage.message = 'No results have been found';
                  c.messages = c.messages.concat(errorMessage);

                  s.next(c);
                }
              }
            },
            (eGetCustomerDataByIds) => s.error(eGetCustomerDataByIds),

            () => {
              if (!requiresOrderRegistrationStatusLog) {
                s.complete();
              }
            }
          );
      }
    });
  }

  delete(
    objectKey: string,
    id: OrderRegistrationModel['__Id']
  ): Observable<ResultWrapper<OrderRegistrationModel>> {
    return new Observable<ResultWrapper<OrderRegistrationModel>>((s) => {
      let assemblingResponse = true;
      const keyModel: DataObjectKeyModel = new DataObjectKeyModel();

      keyModel.parseKey(objectKey);

      this.customData.deleteCustomerData(keyModel.SystemCode, id).subscribe(
        (rCustom: ResultWrapper<OrderRegistrationModel>) => {
          if (rCustom.hasResult && rCustom.result) {
            const w: ResultWrapper<OrderRegistrationModel> = rCustom;

            const l: OrderRegistrationStatusLogModel =
              new OrderRegistrationStatusLogModel();
            {
              l.$customcontent_orderregistration_id = rCustom.result.__Id;
              l.$customcontent_orderregistration_status =
                OrderRegistrationStatus.Cancelled;
              l.orderRegistrationStatusLog =
                OrderRegistrationStatusLog.OrderRegistrationDeleted;
            }

            this.orderRegistrationStatusLogData.add(l).subscribe(
              (
                rOrderRegistrationStatusLog: ResultWrapper<OrderRegistrationStatusLogModel>
              ) => {
                w.messages = w.messages.concat(
                  rOrderRegistrationStatusLog.messages
                );

                s.next(w);
              },
              (eOrderRegistrationStatusLog) =>
                s.error(eOrderRegistrationStatusLog),
              () => (assemblingResponse = false)
            );
          } else {
            assemblingResponse = false;

            s.next(rCustom);
          }
        },
        (eCustom) => s.error(eCustom),

        () => {
          if (!assemblingResponse) {
            s.complete();
          }
        }
      );
    });
  }
}
