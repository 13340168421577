import { BaseModel } from '@seahorse/common';
import { ValidationResult } from '@seahorse/common';
import { Guid } from 'guid-typescript';
import {
  AddressTypes,
  CompanyContactStatuses,
  CompanyOptInStatus,
  InvoiceMethods,
} from './company.enums';

export class CompanyModel {
  id: number;
  organisationId: string;
  name: string;
  parentCompanyId: number;
  parentCompany: CompanyModel;
  number: string;
  remarks: string;
  financialCount: number;
  companyAddresses: CompanyAddressModel[];
  companyContacts: CompanyContactModel[];
  companyFinancials: CompanyFinancialModel[];
  companyTypes: CompanyTypeModel[];
  companyReference: CompanyReferenceModel;

  // use for company picker UI purpose
  financialId?: number;

  constructor() {
    this.id = null;
    this.organisationId = null;
    this.name = null;
    this.parentCompanyId = null;
    this.parentCompany = null;
    this.number = null;
    this.remarks = null;
    this.financialCount = null;
    this.companyAddresses = null;
    this.companyContacts = null;
    this.companyFinancials = null;
    this.companyTypes = null;
  }

  validate(errorPrefix?: string): ValidationResult {
    const result: ValidationResult = new ValidationResult();

    if (!errorPrefix) {
      errorPrefix = '';
    }

    if (this.name == null) {
      result.errors.push(errorPrefix + 'Name is required!');
    } else {
      this.name = this.name.trim();
      if (this.name === '') {
        this.name = null;
        result.errors.push(errorPrefix + 'Name is required!');
      }
    }

    result.isValid = result.errors.length == 0;
    return result;
  }
}

export class CompanyTypeModel {
  id: number;
  organisationId: Guid;
  name: string;
  systemCode: string;
}

export class CompanyAddressModel extends BaseModel {
  companyId: number;
  type: AddressTypes;
  name: string;
  value: string;
  contactId: number;
  postalModel?: AddressPostalModel;
  isPrimary: boolean;

  validate(): ValidationResult {
    // if (address.name == null) {
    //     return false;
    // }

    // address.name = address.name.trim();
    // if (address.name === '') {
    //     address.name = null;
    //     return false;
    // }

    // if (address.value == null) {
    //     return false;
    // }

    // address.value = address.value.trim();
    // if (address.value === '') {
    //     address.value = null;
    //     return false;
    // }

    // if (address.type == null || address.type === undefined) {
    //     return false;
    // }

    // return true;
    return new ValidationResult(true);
  }
}

export class CompanyContactModel extends BaseModel {
  companyId: number;
  nameInfo: string;
  function: string;
  status: CompanyContactStatuses;
  isPrimaryContact: boolean;
  departmentContacts: any[];
  companyAddresses: CompanyAddressModel[];

  static validate(contact: CompanyContactModel) {
    if (!contact.nameInfo) {
      return new ValidationResult(false);
    }

    contact.nameInfo = contact.nameInfo.trim();
    if (contact.nameInfo === '') {
      contact.nameInfo = null;
      return new ValidationResult(false);
    }

    if (contact.status == null) {
      return new ValidationResult(false);
    }

    return new ValidationResult(true);
  }

  validate(): ValidationResult {
    return new ValidationResult(true);
  }
}

export class CompanyDepartmentModel extends BaseModel {
  name: string;
  companyId: number;
  departmentContacts: any[];

  validate(): ValidationResult {
    return new ValidationResult(true);
  }
}

export class DepartmentContactModel extends BaseModel {
  name: string;

  validate(): ValidationResult {
    return new ValidationResult(true);
  }
}

export class CompanyFinancialModel extends BaseModel {
  companyId: number;
  debtorNumber: string;
  cocNumber: string;
  vatNumber: string;
  startsOn?: Date;
  endsOn?: Date;
  emailAddress: string;
  iban: string;
  bic: string;
  allowSepa?: boolean;
  invoiceMethod: InvoiceMethods;
  addressing: string;
  customerReference: string;
  alternateTemplateId?: number;
  displayName: string;

  validate(): ValidationResult {
    // if (obj.debtorNumber == null || obj.debtorNumber === undefined) {
    //     return false;
    // }

    // return true;
    return new ValidationResult(true);
  }
}

export class CompanyExternalKeyModel {
  id: number;
  companyId: number;
  sourceKey: string;
  sourceValue: string;
  keyType: string;
}

export class AddressPostalModel {
  addressLine1 = '';
  addressLine2 = '';
  zip = '';
  city = '';
  state = '';
  country = '';

  validate(): ValidationResult {
    return new ValidationResult(true);
  }
}

export class CompanyReferenceModel {
  id: number;
  companyId: number;
  organisationId: string;
  status: CompanyOptInStatus;
  domain: string;
  invitationAddress: string;
  invitedOn?: Date;
  invitedBy?: string;
  remarks: string;
}
