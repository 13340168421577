<section class="page-header d-flex justify-content-between px-3 py-1">
  <div class="d-flex mx-n1">
    <button
      class="btn btn-outline-secondary m-1"
      title="{{ 'map.marker.markerOptions' | translate }}"
      (click)="toggleSidebar('options')"
    >
      <i class="fa-regular fa-cog fa-fw"></i>
    </button>
    <button
      class="btn btn-outline-secondary m-1"
      title="{{ 'map.areaActivity.areaActivity' | translate }}"
      (click)="toggleSidebar('area-activity')"
      *hasPermission="'map.map_area_activity'"
    >
      <i class="fa-regular fa-draw-polygon fa-fw"></i>
    </button>
    <button
      class="btn btn-outline-secondary m-1"
      title="{{ 'map.route.calculator' | translate }}"
      (click)="toggleSidebar('route-calculator')"
    >
      <i class="fa-regular fa-route fa-fw"></i>
    </button>
    <button
      class="btn btn-outline-secondary m-1"
      (click)="toggleSidebar('fleet')"
      *hasPermission="'ships.fleetlist'"
    >
      <i class="fa-solid fa-list fa-fw"></i>
      {{ 'map.myFleet' | translate }}
    </button>

    <div
      class="page-header-filter-list pt-1"
      [ngClass]="{
        'page-header-filter-list-visible': expandedSidebar === 'options',
        'sidebar-open': sidebarOpen
      }"
    >
      <ca-map-sidebar-configuration></ca-map-sidebar-configuration>
    </div>

    <div
      class="page-header-filter-list pt-1"
      [ngClass]="{
        'page-header-filter-list-visible': expandedSidebar === 'fleet',
        'sidebar-open': sidebarOpen
      }"
    >
      <ca-map-sidebar-fleet
        (onSelectVessel)="openVessel($event)"
      ></ca-map-sidebar-fleet>
    </div>

    <div
      class="page-header-filter-list"
      [ngClass]="{
        'page-header-filter-list-visible': expandedSidebar === 'area-activity',
        'sidebar-open': sidebarOpen
      }"
    >
      <ca-map-sidebar-area-activity
        (onSelectVessel)="openVessel($event)"
      ></ca-map-sidebar-area-activity>
    </div>

    <div
      class="page-header-filter-list"
      [ngClass]="{
        'page-header-filter-list-visible':
          expandedSidebar === 'route-calculator',
        'sidebar-open': sidebarOpen
      }"
    >
      <ca-map-route-calculator
        (calculatedRoutes)="calculatedRoutes($event)"
      ></ca-map-route-calculator>
    </div>

    <form class="form-inline mx-1 mt-0" (ngSubmit)="search()">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <i class="fa-solid fa-search fa-fw"></i>
          </span>
        </div>
        <input
          #searchTerm
          [(ngModel)]="searchText"
          type="text"
          class="form-control"
          [placeholder]="'map.findVessels' | translate"
          aria-label="Find"
          name="searchBox"
          id="myInput"
        />
        <ul class="search-panel" *ngIf="searchResults">
          <li>
            <div class="d-flex justify-content-between align-items-center">
              {{ searchResults.length }}
              {{ 'map.vesselsFound' | translate }}
              <span (click)="resetSearch()">
                <i class="fa-solid fa-times fa-fw"></i>
              </span>
            </div>
          </li>
          <li *ngFor="let result of searchResults">
            <div (click)="openVessel(result)">
              <strong>{{ result.displayName }}</strong>
              {{ result.displayIdentifiers }}
            </div>
          </li>
        </ul>
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" type="submit">
            {{ 'shared.terms.search' | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>

  <div class="d-flex mx-n1">
    <div class="dropdown">
      <button
        class="btn btn-outline-secondary m-1"
        id="ddl_header_map_areas"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        data-offset="0,1"
      >
        <i class="fa-regular fa-expand fa-fw mr-1"></i>
        <span>{{ selectedArea }}</span>
      </button>
      <div
        class="dropdown-menu dropdown-menu-with-footer"
        aria-labelledby="ddl_header_map_areas"
      >
        <div class="dropdown-menu-container">
          <div class="dropdown-menu-items">
            <ng-container *ngIf="areas && areas.length > 0">
              <h6 class="dropdown-header">
                {{ 'map.areas.areas' | translate }}
              </h6>
              <a
                class="dropdown-item"
                *ngFor="let area of areas"
                (click)="showArea(area)"
                >{{ area.name }}</a
              >
            </ng-container>
            <ng-container>
              <h6 class="dropdown-header">Regio's</h6>
              <a class="dropdown-item" (click)="showArea('anzk')"
                >ANZK-gebied</a
              >
              <a class="dropdown-item" (click)="showArea('europa')">Europa</a>
              <a class="dropdown-item" (click)="showArea('nederland')"
                >Nederland</a
              >
              <ng-container *hasPermission="'!custom_ui.cvv'">
                <div class="dropdown-divider"></div>
                <h6 class="dropdown-header">Havengebied</h6>
                <a class="dropdown-item" (click)="showArea('beverwijk')"
                  >Beverwijk</a
                >
                <a class="dropdown-item" (click)="showArea('ijmuiden')"
                  >IJmuiden</a
                >
                <a class="dropdown-item" (click)="showArea('tata')">Tata</a>
                <a class="dropdown-item" (click)="showArea('velsennoord')"
                  >Velsen Noord</a
                >
              </ng-container>
              <ng-container *hasPermission="'custom_ui.koperenploeg'">
                <div class="dropdown-divider"></div>
                <h6 class="dropdown-header">Havengebied</h6>
                <a class="dropdown-item" (click)="showArea('afrikahaven')"
                  >Afrikahaven</a
                >
                <a class="dropdown-item" (click)="showArea('amerikahaven')"
                  >Amerikahaven</a
                >
                <a class="dropdown-item" (click)="showArea('mercuriushaven')"
                  >Mercuriushaven</a
                >
                <a class="dropdown-item" (click)="showArea('petroleumhaven')"
                  >Petroleumhaven</a
                >
                <a class="dropdown-item" (click)="showArea('westhaven')"
                  >Westhaven</a
                >
              </ng-container>
              <div class="dropdown-divider"></div>
              <h6 class="dropdown-header">Sluizencomplex</h6>
              <a class="dropdown-item" (click)="showArea('noordersluis')"
                >Noordersluis</a
              >
              <a class="dropdown-item" (click)="showArea('middensluis')"
                >Middenluis</a
              >
              <a class="dropdown-item" (click)="showArea('zuidersluis')"
                >Zuidersluis</a
              >
              <a class="dropdown-item" (click)="showArea('piersluis')"
                >Pieren tot sluis</a
              >
              <a class="dropdown-item" (click)="showArea('sluiszijkanaal')"
                >Sluis tot Zijkanaal A</a
              >
            </ng-container>
          </div>
          <div class="dropdown-menu-footer text-right" (click)="editList()">
            {{ 'shared.terms.manageItems' | translate }}
            <i class="fa-regular fa-chevron-right fa-fw"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
