import { Component, Input, Output, EventEmitter } from '@angular/core';

import * as _ from 'underscore';

import { CustomMemberModel } from '../../models/custom-member.model';
import { CustomTeamModel } from '../../models/custom-team.model';

@Component({ templateUrl: './member-manager-modal.component.html' })
export class MemberManagerModalComponent {
  private _teams: CustomTeamModel[] = [];

  @Input() set teams(teams: CustomTeamModel[]) {
    if (teams) {
      this._teams = _.sortBy(teams, (t) =>
        t.name ? t.name.toLowerCase() : ''
      );
    }
  }
  get teams() {
    return this._teams;
  }

  @Input() set member(member: CustomMemberModel) {
    if (member) {
      this.selectedMember = member;
    }
  }

  @Output() onSelectMemberAction: EventEmitter<object>;

  selectedMember: CustomMemberModel;

  constructor() {
    this.member = null;

    this.onSelectMemberAction = new EventEmitter<{
      member: CustomMemberModel;
      action: string;
    }>();

    this.selectedMember = new CustomMemberModel();
  }

  // Select team
  selectTeam(selectedTeamId: CustomTeamModel['__Id']) {
    if (
      this.selectedMember.$customdata_membergroup_ids.indexOf(selectedTeamId) >
      -1
    ) {
      this.selectedMember.$customdata_membergroup_ids.splice(
        this.selectedMember.$customdata_membergroup_ids.indexOf(selectedTeamId),
        1
      );
    } else {
      this.selectedMember.$customdata_membergroup_ids.push(selectedTeamId);
    }
  }

  isChecked(selectedTeamId: CustomTeamModel['__Id']) {
    return (
      this.selectedMember.$customdata_membergroup_ids.indexOf(selectedTeamId) >
      -1
    );
  }
}
